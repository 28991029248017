<div class="contentPage">
  <div class="flex flex-between align-center gap-2 headerResponsive">
    <div class="flex gap-2 align-center titleResponsive">
      <h1>Relatório de Solicitantes</h1>
      <app-button (click)="generatePdf()" [placeholder]="'Gerar Relatório'" [matIcon]="'insert_drive_file'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="getDoctors()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <select-prop [form]="form.filter" [placeholder]="'Selecione um filtro'" [options]="filterOptions"></select-prop>
        <input-prop #autoFocus *ngIf="form.filter.value != 'Filtrar por Telefone'" [placeholder]="'Digite aqui'" [form]="form.search"></input-prop>
        <input-prop *ngIf="form.filter.value === 'Filtrar por Telefone'" mask="(00) 0000-0000" [placeholder]="'Digite aqui os dados'" [form]="form.search"></input-prop>        
        <select-prop [options]="isActiveOptions" [form]="form.isActive" [placeholder]="'Situação'" class="w-100"></select-prop>  
      </div>
      <app-button (click)="getDoctors()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [indexNumber]="index" [pageNumber]="page" [Itens]="registerDoctors" (changePage)="getDoctors($event)"></app-table>
  </div>
</div>