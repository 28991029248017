import {
   Directive,
   ElementRef,
   EventEmitter,
   HostListener,
   Output
} from "@angular/core";

@Directive({
   selector: "[appCloseFilter]",
   standalone: true
})
export class CloseFilterDirective {
   @Output() clickOutside = new EventEmitter<MouseEvent>();

   constructor(private elementRef: ElementRef) {}

   // @HostListener("document:click", ["$event"])
   // onClick(event: MouseEvent) {
   //    const clickedInside = this.elementRef.nativeElement.contains(
   //       event.target as any
   //    );

   //    if (!clickedInside) {
   //       this.clickOutside.emit(event);
   //    }
   // }
}
