import { Injectable } from "@angular/core";
import { TableFormComponent } from "./modules_new/shared/components/form/table/table.component";

@Injectable({
   providedIn: "root"
})
export class TableRegistryService {
   private currentsTables: any = [];

   registerTable(table: TableFormComponent, component: string = ""): void {
      this.currentsTables[component] = table;
   }

   unregisterTable(): void {
      this.currentsTables = [];
   }

   getTable(component: string = ""): TableFormComponent | null {
      return this.currentsTables[component];
   }
}
