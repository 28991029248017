import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { DefaultFormats, LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { SwAlSetttings } from '@app/util/swal.settings';
import { HeaderMapping, ReportFilter } from '@app/modules/reports/reports-model';
import { RegistrationReportComponent } from '@app/components/form/registration-report/registration-report.component';
import { HttpClient } from '@angular/common/http';
import { TenantAuthService } from '@app/services/auth/tenantAuth/tenant-auth.service';
import { EType, filter, register } from '../recipients-report.module';
import { Util } from '@app/util/util';
import { ReportRecipientsService } from '@app/services/report-recipients/report-Recipients.service';

@Component({
  selector: 'app-recipients-report',
  templateUrl: './recipients-report.component.html',
  styleUrls: ['./recipients-report.component.scss']
})

export class RecipientsReportComponent implements OnInit {
  index: number = 50;
  page: number = 1;
  total: number = 0;
  receivedData!: register[];
  sumRecords!: number;
  reportDescription: string = 'Relatório de Cadastro de Recipientes';
  receivedDataForPrint!: object[];
  recipients: Array<register> = [];
  reportObject: Array<Object> = [];
  isActiveOptions: Array<{ label: string, value: string | null | boolean }> = [{ label: 'Todos', value: 'todos' }, { label: 'Somente Ativos', value: true }, { label: 'Somente Inativos', value: false }]

  headerMapping: HeaderMapping = {
    description: { title: 'Descrição', width: '*' },
    type: { title: 'Tipo', width: 'auto' },
    createdAt: { title: 'Criado em', width: 'auto' },
    updatedAt: { title: 'Atualizado em', width: 'auto' },
    isActive: { title: 'Ativo', width: 'auto' }
  };

  reportFilters: ReportFilter[][] = [];

  filter = new UntypedFormGroup({
    description: new UntypedFormControl(null),
    type: new UntypedFormControl('todos'),
    isActive: new UntypedFormControl('todos')
  })

  situation = [
    {
      value: 0,
      label: 'Todos'
    },
    {
      value: 1,
      label: 'Ativos'
    },
    {
      value: 2,
      label: 'inativos'
    }
  ]

  getType = [
    {
      value: 'todos',
      label: 'Todos'
    },
    {
      value: 0,
      label: 'Coleta'
    },
    {
      value: 1,
      label: 'Triagem'
    },
    {
      value: 2,
      label: 'Lamina'
    },
    {
      value: 3,
      label: 'Seringa'
    }
  ];

  resolveRecipients = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Descrição',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Tipo',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'type',
      type: 'recipienteType'
    },
    {
      label: '_hide_TypeColor',
      retrive: 'type',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'createdAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'updatedAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'isActive',
      type: 'boolean'
    }
  ];

  constructor(
    private _reportRecipientsService: ReportRecipientsService,
    private _localeDate: LocalaDatePipe,
    private _http: HttpClient,
    private _tenantAuth: TenantAuthService,
    private _util: Util,
  ) { }

  ngOnInit() {
    this.get();
  }

  async generatePdf() {
    await this.getDataForPrint();

    this.reportFilters = [];
    var filters = [];
    var filterActive = 'Todos';
    if (this.filter.controls['isActive'].value != 'todos') {
      if (this.filter.controls['isActive'].value == 'true') {
        filterActive = 'Somente Ativos';
      } else {
        filterActive = 'Somente Inativos';
      }
    }

    var obj = {
      title: 'Ativos: ', content: filterActive
    }

    filters.push(obj);

    if (this.filter.controls['type'].value != 'todos') {
      var filterActive = 'Todos';
      switch (this.filter.controls['type'].value) {
        case '0':
          filterActive = 'Coleta'
          break;

        case '1':
          filterActive = 'Triagem'
          break;

        case '2':
          filterActive = 'Lamina'
          break;

        case '3':
          filterActive = 'Seringa'
          break;
      }
      var obj = {
        title: 'Tipo: ', content: filterActive
      }

      filters.push(obj);
    }

    if (this.filter.controls['description'].value) {
      var obj2 = {
        title: 'Descrição: ', content: this.filter.controls['description'].value
      }

      filters.push(obj2);
    }

    this.reportFilters = [filters];

    const reportComponent = new RegistrationReportComponent(this._http, this._localeDate, this._tenantAuth);

    reportComponent.headerMapping = this.headerMapping;
    reportComponent.reportFilters = this.reportFilters;
    reportComponent.receivedData = this.receivedDataForPrint;
    reportComponent.reportDescription = this.reportDescription;
    reportComponent.sumRecords = this.sumRecords;

    await reportComponent.ngOnInit();
  }

  async getDataForPrint() {
    this.reportObject = [];
    const queryObject = {
      description: this.filter.controls['description'].value,
      typeRecipient: this.filter.controls['type'].value == 'todos' ? null : this.filter.controls['type'].value,
      isActive: this.filter.controls['isActive'].value === 'todos' ? null : JSON.parse(this.filter.controls['isActive'].value)
    };
    await this._reportRecipientsService.getPagination<filter>(queryObject).then(
      (res) => {
        res.data.forEach((x) => {
          const obj = {
            description: x.description,
            type: this.getReportType(x.type),
            isActive: x.isActive ? 'Ativo' : 'Inativo',
            createdAt: x.createdAt,
            updatedAt: x.updatedAt
          }
          this.reportObject.push(obj)
        })
        this.sumRecords = res.sumRecords;
        this.receivedDataForPrint = this.reportObject;
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  getReportType(type: EType) {
    switch (type) {
      case EType.Coleta:
        return 'Coleta'
      case EType.Triagem:
        return 'Triagem'
      case EType.Lamina:
        return 'Lamina'
      case EType.Seringa:
        return 'Seringa'
      default:
        return ''
    }
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    const filterObject = {
      isActive: this.filter.controls['isActive'].value === 'todos' ? null : JSON.parse(this.filter.controls['isActive'].value),
      numberRegistry: this.index,
      page: this.page,
      description: this.filter.controls['description'].value ?? null,
      typeRecipient: this.filter.controls['type'].value == 'todos' ? null : this.filter.controls['type'].value,
    };

    await this._reportRecipientsService.getPagination<register>(filterObject).then(
      (res) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.receivedData = res.data;
          this.resolve(res.data, this.resolveRecipients);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.recipients = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.recipients.push(obj);
    });
  }

  resetFilter() {
    this.filter.controls['description'].reset();
    this.filter.controls['type'].setValue('todos');
    this.filter.controls['isActive'].setValue('todos');
  }
}