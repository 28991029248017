<div class="contentPage">
  <div class="flex flex-column">
    <div class="flex flex-between flex-direction-column-mobile align-center">
      <h1>Rastreabilidade</h1>
      <div class="flex align-center ml-20">
        <span>Dashboard e Gráficos</span>
        <checkbox-prop [form]="dashboardCheckBox"></checkbox-prop>
      </div>
    </div>
  </div>
  <!-- <app-filter [advanced]="true" (beforeClose)="getRequisitions()">
    <div class="query aniOpen">
      <select-prop [form]="formUnity.requisitionUnity" placeholder="Selecione a Unidade" [options] = "unityOptions" (change)="getByIdUnity()"></select-prop>
      <select-prop [optionAll]='true' [form]="form.requisitionCollectionPlace" placeholder="Selecione o Local de Coleta" [options]="collectionPlaceOptions"></select-prop>
      <input-prop [placeholder]="'Data Inicial'" type="datetime-local" [form]="formDate.initialDate"></input-prop>
      <input-prop [placeholder]="'Data Final'" type="datetime-local" [form]="formDate.finalDate"></input-prop>
    </div>
    <div class="advancedQuery">
      <input-prop [form]="form.searchByRequisitionNumber" placeholder="Número da Requisição"></input-prop>
      <input-prop [form]="form.searchByCustomerName" placeholder="Nome do Cliente"></input-prop>
      <input-prop [placeholder]="'CPF do Cliente'" [form]="form.searchByCustomerCPF" mask="000.000.000-00"></input-prop>
      <input-prop [placeholder]="'Data de Nascimento do Cliente'" type="date" [form]="form.searchByCustomerBirthDate"></input-prop>
    </div>
    <div class="queryBtn">
      <button class="btn flex gp-12 align-center" (click)="getRequisitions()">Pesquisar <mat-icon>search</mat-icon></button>
    </div>
  </app-filter> -->
  <div class="flex flex-wrap gp-12">
    <b>Número: <b class="orangeFont">{{number}}</b></b>
    <b>Cliente: <b class="orangeFont">{{client}}</b></b>
    <b>Inclusão: <b class="orangeFont">{{createdAt}} ~usuarioinclusao~</b></b>
    <b>Alteração: <b class="orangeFont">{{createdAt}} ~usuarioalteracao~</b></b>
    <b>Unidade: <b class="orangeFont">{{unityDescription}}</b></b>
    <b>Tempo de atendimento: <b class="orangeFont">~tempoatendimento~</b></b>
    <b>Chegada: <b class="orangeFont">~chegada~</b></b>
  </div>
  <div *ngIf="!dashboardCheckBox.value" class="flex mt-32">
    <app-table class="w-100" [Pagination]="false" [backPagination]="false" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Dropdown]="true" [ClickInItem]="ActionsExtra" (parentFun)="Actions($event)" [Itens]="registerRequests">
      <ng-template let-rowDetail #header>
        <app-table [Pagination]="false" [backPagination]="false" [ClickInItem]="ActionsExtra" (parentFun)="ActionsExam($event)" [Itens]="getExamsAgreement(rowDetail.id)" [noShadow]="true"></app-table>
      </ng-template>
    </app-table>
  </div>
  <div *ngIf="dashboardCheckBox.value">
    <app-dashboard-cards [columns]="columnsDashboard"></app-dashboard-cards>
    <div>
      <div class="flex flex-wrap flex-evenly mt-32">
        <app-chartjs-pie [name]="'graphicsExam'" [labels]="labelsExa" [values]="valuesExa" [backgroundColor]="backgroundColorExa"></app-chartjs-pie>
        <div>
          <div class="mt-12 text-center">
            <span>Tempo de Atendimento</span>
          </div>
          <app-chartjs-bar [hour]="true" [name]="'graphicsExamBarChart'" [labels]="labelsExa" [values]="valuesBarChart" [backgroundColor]="backgroundColorExa"></app-chartjs-bar>
        </div>
        <app-chartjs-pie [name]="'graphicsReq'" [requisition]="true" [labels]="labelsReq" [values]="valuesReq" [backgroundColor]="backgroundColorReq"></app-chartjs-pie>
      </div>
    </div>
  </div>
</div>

<ng-template #dialogModalTraceability let-traceability>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>Rastreabilidade</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="flex flex-evenly mw-800" >
        <div class="w-24" *ngFor="let card of cardsRequest">
          <app-cards
            [header]="card.type"
            [linha2]="card.changeTitle"
            [linha3]="card.changeBefore"
            [linha4]="card.changeAfter"
            [linha5]="card.date"
            [linha6]="card.name">
          </app-cards>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalTraceabilityExam let-traceability>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>Rastreabilidade</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <mat-tab-group (selectedTabChange)="myTabSelectedTabChange($event)">
        <mat-tab label="Atendimento">
          <div class="flex flex-between mt-12">
            <span class="line">Solicitante: Dr Doutorado Doutrinado</span>
            <span class="line">Convênio: Convênio Convêniencia</span>
          </div>
          <app-table [Padding]="false" [Order]="false" [Pagination]="false" [backPagination]="false" [Itens]="itensService" [noShadow]="true"></app-table>
        </mat-tab>
        <mat-tab label="Web">
          <div class="flex flex-between mt-12">
            <span class="line">Visualização</span>
            <span class="line">Liberado: 01/01/2000 - Usuário: Gabriel</span>
          </div>
          <app-table [Padding]="false" [Order]="false" [Pagination]="false" [backPagination]="false" [Itens]="itensWeb" [noShadow]="true"></app-table>
        </mat-tab>
        <mat-tab label="Setor Técnico">
          <app-table [Padding]="false" [Order]="false" [Pagination]="false" [backPagination]="false" [Itens]="itensTechnicalSector" [noShadow]="true"></app-table>
        </mat-tab>
        <mat-tab label="Email e SMS">
          <div class="flex flex-between mt-12">
            <span class="line">Emails Enviados</span>
            <span class="line">Data Envio SMS: 01/01/2000</span>
          </div>
          <app-table [Padding]="false" [Order]="false" [Pagination]="false" [backPagination]="false" [Itens]="itensEmail" [noShadow]="true"></app-table>
        </mat-tab>
        <mat-tab label="Financeiro">
          <div class="flex flex-between mt-12">
            <span class="line">Data Fatura: 23/12/2023</span>
            <span class="line">Titulo Financeiro: 1234567891</span>
          </div>
          <div class="flex flex-between gp-3 mt-12">
            <div class="w-100" *ngFor="let card of cardsFinancial">
              <app-cards [headerOrange]="true"
                [header]="card.type"
                [linha2]="card.changeTitle"
                [linha3]="card.changeBefore"
                [linha4]="card.changeAfter">
              </app-cards>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Concent Apoio">
          <div class="flex flex-between mt-12">
            <span class="line">Lote Recebido: 654987 - Gerado em: 12/12/2023 12:00</span>
          </div>
          <app-table [Padding]="false" [Order]="false" [Pagination]="false" [backPagination]="false" [Itens]="itensAgreement" [noShadow]="true"></app-table>
          <div class="flex align-center gp-12 mt-12">
            <span class="line">Etiquetas Relacionadas</span>
            <svg (click)="openTags()" width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6053 6.1738V17.2264C15.6053 17.6452 15.4389 18.0468 15.1428 18.3429C14.8467 18.639 14.4451 18.8054 14.0263 18.8054H4.55262C4.13386 18.8054 3.73224 18.639 3.43613 18.3429C3.14002 18.0468 2.97367 17.6452 2.97367 17.2264V2.48959C2.97367 2.07083 3.14002 1.66922 3.43613 1.37311C3.73224 1.077 4.13386 0.910645 4.55262 0.910645H10.3421L15.6053 6.1738ZM10.3421 6.70012C10.2025 6.70012 10.0686 6.64467 9.96993 6.54596C9.87123 6.44726 9.81578 6.31339 9.81578 6.1738V1.96328H4.55262C4.41303 1.96328 4.27916 2.01873 4.18046 2.11743C4.08175 2.21613 4.0263 2.35 4.0263 2.48959V17.2264C4.0263 17.366 4.08175 17.4999 4.18046 17.5986C4.27916 17.6973 4.41303 17.7528 4.55262 17.7528H14.0263C14.1659 17.7528 14.2998 17.6973 14.3985 17.5986C14.4972 17.4999 14.5526 17.366 14.5526 17.2264V6.70012H10.3421ZM10.8684 2.92538L13.5905 5.64749H10.8684V2.92538Z" fill="#FE7434"/>
              <path d="M1.92104 4.06854V18.2791C1.92104 18.6978 2.08739 19.0994 2.3835 19.3955C2.67961 19.6917 3.08122 19.858 3.49999 19.858H13.5V20.9106H3.49999C2.80205 20.9106 2.1327 20.6334 1.63918 20.1399C1.14566 19.6464 0.868408 18.977 0.868408 18.2791V4.06854H1.92104Z" fill="#FE7434"/>
              <path d="M5.07893 8.80538H13.5V9.85801H5.07893V8.80538Z" fill="#FE7434"/>
              <path d="M5.07893 11.437H13.5V12.4896H5.07893V11.437Z" fill="#FE7434"/>
              <path d="M5.07893 14.0685H13.5V15.1212H5.07893V14.0685Z" fill="#FE7434"/>
            </svg>
          </div>
          <div *ngIf="tags" class="flex flex-wrap gp-12">
            <span class="tickets">093456</span>
            <span class="tickets">093456</span>
            <span class="tickets">093456</span>
            <span class="tickets">093456</span>
          </div>
        </mat-tab>
        <mat-tab label="Apoio Terceiro">
          <div class="flex flex-between mt-12">
            <span class="line">Laboratório Utilizado: Concent Lab.</span>
          </div>
          <div class="flex flex-evenly" >
            <div class="w-100" *ngFor="let card of cardsTag">
              <app-cards
                [header]="card.type"
                [linha2]="card.changeTitle"
                [linha3]="card.changeBefore"
                [linha4]="card.changeAfter">
              </app-cards>
            </div>
          </div>
          <div>
            <div class="flex align-center gp-12 mt-12">
              <span class="line">Etiquetas Relacionadas</span>
              <svg (click)="openoutSourcedTags()" width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6053 6.1738V17.2264C15.6053 17.6452 15.4389 18.0468 15.1428 18.3429C14.8467 18.639 14.4451 18.8054 14.0263 18.8054H4.55262C4.13386 18.8054 3.73224 18.639 3.43613 18.3429C3.14002 18.0468 2.97367 17.6452 2.97367 17.2264V2.48959C2.97367 2.07083 3.14002 1.66922 3.43613 1.37311C3.73224 1.077 4.13386 0.910645 4.55262 0.910645H10.3421L15.6053 6.1738ZM10.3421 6.70012C10.2025 6.70012 10.0686 6.64467 9.96993 6.54596C9.87123 6.44726 9.81578 6.31339 9.81578 6.1738V1.96328H4.55262C4.41303 1.96328 4.27916 2.01873 4.18046 2.11743C4.08175 2.21613 4.0263 2.35 4.0263 2.48959V17.2264C4.0263 17.366 4.08175 17.4999 4.18046 17.5986C4.27916 17.6973 4.41303 17.7528 4.55262 17.7528H14.0263C14.1659 17.7528 14.2998 17.6973 14.3985 17.5986C14.4972 17.4999 14.5526 17.366 14.5526 17.2264V6.70012H10.3421ZM10.8684 2.92538L13.5905 5.64749H10.8684V2.92538Z" fill="#FE7434"/>
                <path d="M1.92104 4.06854V18.2791C1.92104 18.6978 2.08739 19.0994 2.3835 19.3955C2.67961 19.6917 3.08122 19.858 3.49999 19.858H13.5V20.9106H3.49999C2.80205 20.9106 2.1327 20.6334 1.63918 20.1399C1.14566 19.6464 0.868408 18.977 0.868408 18.2791V4.06854H1.92104Z" fill="#FE7434"/>
                <path d="M5.07893 8.80538H13.5V9.85801H5.07893V8.80538Z" fill="#FE7434"/>
                <path d="M5.07893 11.437H13.5V12.4896H5.07893V11.437Z" fill="#FE7434"/>
                <path d="M5.07893 14.0685H13.5V15.1212H5.07893V14.0685Z" fill="#FE7434"/>
              </svg>
            </div>
            <div *ngIf="outsourcedTags" class="flex flex-wrap gp-12">
              <span class="tickets">093456</span>
              <span class="tickets">093456</span>
              <span class="tickets">093456</span>
              <span class="tickets">093456</span>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      <span class="line mt-12">Histórico</span>
      <div class="flex flex-between gp-3 mt-12">
        <div class="w-100" *ngFor="let card of cardsExam">
          <app-cards
            [header]="card.type"
            [linha2]="card.changeTitle"
            [linha3]="card.changeBefore"
            [linha4]="card.changeAfter"
            [linha5]="card.date"
            [linha6]="card.name">
          </app-cards>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

