<div
    class="table-wp"
    [ngClass]="{
        paddingDefault: !ignorePadding,
        shadowTable: shadow,
        roundBorder: roundBorder
    }"
>
    <div *ngIf="filterRows?.value" class="text-end">
        {{ ("forms-table.filter" | translate) + '"' + filterRows.value + '"' }}
    </div>
    <table class="w-full">
        <thead>
            <th
                scope="col"
                *ngIf="canSelectRows"
                class="max-w-14"
                [ngStyle]="{
                    'border-bottom': isAdm
                        ? '1px solid #404040'
                        : '1px solid #fe6344'
                }"
            >
                <forms-checkbox
                    *ngIf="!selectOnlyOne"
                    (changed)="selectRows($event)"
                    [form]="formSelectAll"
                    [radio]="true"
                ></forms-checkbox>
            </th>
            <th
                scope="col"
                *ngFor="let column of columns"
                (click)="sortColumn(column)"
                [ngClass]="{ sorted: wasSorted(column) }"
                [ngStyle]="{
                    'border-bottom': isAdm
                        ? '1px solid #404040'
                        : '1px solid #fe6344'
                }"
            >
                <span class="flex justify-between items-center">
                    {{ column ? (column.label | translate) : "" }}
                    <ui-svg-selector
                        *ngIf="column.label"
                        class="ml-2 sort-icon"
                        [fill]="isAdm ? '#404040' : 'var(--primary-color)'"
                        [svg]="'table-sort-icon'"
                    ></ui-svg-selector>
                </span>
            </th>
            <th
                scope="col"
                *ngIf="hasDropdownOrActions"
                class="w-10"
                [ngStyle]="{
                    'border-bottom': isAdm
                        ? '1px solid #404040'
                        : '1px solid #fe6344'
                }"
            ></th>
        </thead>
        <tbody *ngIf="!loadingTable; else loading">
            <tr
                class="noBorder"
                *ngIf="(rows | async)?.length === 0; else hasData"
                class="order"
            >
                <td
                    [attr.colspan]="
                        canSelectRows ? columns.length + 1 : columns.length
                    "
                    style="justify-content: center !important"
                >
                    <div class="flex justify-center py-8">
                        <div
                            class="flex flex-col justify-center items-center gap-3"
                        >
                            <svg
                                width="50"
                                height="60"
                                viewBox="0 0 46 60"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0.5 48.78C0.5 50.82 1.5 52.7 3.5 54.42C5.5 56.14 8.24 57.5 11.72 58.5C15.2 59.5 18.96 60 23 60C27.04 60 30.8 59.5 34.28 58.5C37.76 57.5 40.5 56.14 42.5 54.42C44.5 52.7 45.5 50.82 45.5 48.78V42.42C45.5 44.46 44.48 46.26 42.44 47.82C40.4 49.38 37.7 50.54 34.34 51.3C30.98 52.06 27.2 52.46 23 52.5C18.8 52.54 15.02 52.14 11.66 51.3C8.3 50.46 5.6 49.3 3.56 47.82C1.52 46.34 0.5 44.54 0.5 42.42V48.78ZM0.5 37.5C0.5 39.54 1.5 41.42 3.5 43.14C5.5 44.86 8.24 46.24 11.72 47.28C15.2 48.32 18.96 48.82 23 48.78C27.04 48.74 30.8 48.24 34.28 47.28C37.76 46.32 40.5 44.94 42.5 43.14C44.5 41.34 45.5 39.46 45.5 37.5V31.2C45.5 33.2 44.48 34.98 42.44 36.54C40.4 38.1 37.7 39.28 34.34 40.08C30.98 40.88 27.2 41.28 23 41.28C18.8 41.28 15.02 40.88 11.66 40.08C8.3 39.28 5.6 38.1 3.56 36.54C1.52 34.98 0.5 33.2 0.5 31.2V37.5ZM0.5 26.28C0.5 28.32 1.5 30.2 3.5 31.92C5.5 33.64 8.24 35 11.72 36C15.2 37 18.96 37.5 23 37.5C27.04 37.5 30.8 37 34.28 36C37.76 35 40.5 33.64 42.5 31.92C44.5 30.2 45.5 28.32 45.5 26.28V19.92C45.5 21.96 44.48 23.76 42.44 25.32C40.4 26.88 37.7 28.04 34.34 28.8C30.98 29.56 27.2 29.96 23 30C18.8 30.04 15.02 29.64 11.66 28.8C8.3 27.96 5.6 26.8 3.56 25.32C1.52 23.84 0.5 22.04 0.5 19.92V26.28ZM0.5 15C0.5 17.04 1.5 18.92 3.5 20.64C5.5 22.36 8.24 23.74 11.72 24.78C15.2 25.82 18.96 26.32 23 26.28C27.04 26.24 30.8 25.74 34.28 24.78C37.76 23.82 40.5 22.44 42.5 20.64C44.5 18.84 45.5 16.96 45.5 15V11.28C45.5 9.24 44.5 7.36 42.5 5.64C40.5 3.92 37.76 2.56 34.28 1.56C30.8 0.56 27.04 0.04 23 -4.92873e-08C18.96 -0.04 15.2 0.48 11.72 1.56C8.24 2.64 5.5 4 3.5 5.64C1.5 7.28 0.5 9.16 0.5 11.28V15Z"
                                    fill="#BFBFBF"
                                />
                            </svg>
                            <span class="text-gray-400 text-lg">{{
                                "forms-table.norecords" | translate
                            }}</span>
                        </div>
                    </div>
                </td>
            </tr>
            <ng-template #hasData>
                <ng-container
                    *ngFor="
                        let row of rows | async | filterRows : filterRows;
                        trackBy: rowIdentity
                    "
                >
                    <tr
                        [ngClass]="
                            (greenRow && row.situation && row.situation == 6) ||
                            (greenRow &&
                                row.typeAnalyte &&
                                row.typeAnalyte === 1)
                                ? 'greenRow'
                                : (greenRow &&
                                      row.typeAnalyte &&
                                      row.typeAnalyte) === 2
                                ? 'lightGreenLine'
                                : ''
                        "
                    >
                        <td *ngIf="canSelectRows">
                            <forms-checkbox
                                (changed)="selectRows($event, row)"
                                [radio]="true"
                                [row]="row"
                            ></forms-checkbox>
                        </td>
                        <td
                            *ngFor="let column of columns"
                            [scope]="column ? (column.label | translate) : ''"
                        >
                            <table-column
                                (userInteraction)="columnClick($event)"
                                [column]="column"
                                [row]="row"
                                [disabled]="column.componentExtra?.disabled"
                            ></table-column>
                        </td>
                        <td
                            *ngIf="hasDropdownOrActions || extra"
                            class="flex items-center"
                            [scope]="column ? (column.label | translate) : ''"
                        >
                            <div class="flex items-center justify-center">
                                <div
                                    *ngIf="extra"
                                    (click)="extraAction($event, row)"
                                >
                                    <svg
                                        width="22"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        (click)="emit(row.id)"
                                    >
                                        <g clip-path="url(#clip0_2_97)">
                                            <rect
                                                x="0.501465"
                                                y="0.0512695"
                                                width="21"
                                                height="21"
                                                rx="10.5"
                                                fill="#808080"
                                            />
                                            <rect
                                                x="3.50146"
                                                y="3.05127"
                                                width="15"
                                                height="15"
                                                rx="7.5"
                                                fill="#F5F5F5"
                                            />
                                            <rect
                                                x="5.50146"
                                                y="9.05127"
                                                width="11"
                                                height="3"
                                                rx="1.5"
                                                fill="#808080"
                                            />
                                            <rect
                                                x="9.50146"
                                                y="5.05127"
                                                width="3"
                                                height="11"
                                                rx="1.5"
                                                fill="#808080"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2_97">
                                                <rect
                                                    width="21"
                                                    height="21"
                                                    fill="white"
                                                    transform="translate(0.501465 0.0512695)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div
                                    class="flex items-center justify-between"
                                    style="min-width: 40px"
                                >
                                    <table-actions
                                        [actions]="actions"
                                        *ngIf="actions?.length > 0"
                                        (clicked)="actionClick($event, row)"
                                    ></table-actions>

                                    <ui-svg-selector
                                        *ngIf="hasDropdown"
                                        [ngClass]="{
                                            isOpen: isDropdownOpen(row)
                                        }"
                                        class="cursor-pointer"
                                        (click)="toggleDropdown(row)"
                                        [fill]="'#404040'"
                                        [svg]="'icon-arrow-dropdown'"
                                    ></ui-svg-selector>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="isDropdownOpen(row)">
                        <td
                            [attr.colspan]="columns.length + 2"
                            [scope]="column ? (column.label | translate) : ''"
                        >
                            <div class="w-full">
                                <ng-container
                                    *ngIf="
                                        dropdownTemplateRef &&
                                        isDropdownOpen(row)
                                    "
                                    [ngTemplateOutlet]="dropdownTemplateRef"
                                    [ngTemplateOutletContext]="{
                                        $implicit: row
                                    }"
                                >
                                </ng-container>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </ng-template>
        </tbody>
        <ng-template #loading>
            <tbody>
                <tr class="loading">
                    <td
                        [attr.colspan]="
                            canSelectRows ? columns.length + 1 : columns.length
                        "
                        style="justify-content: center !important"
                    >
                        <span class="flex flex-col justify-center items-center">
                            <ui-loading></ui-loading>
                            <p class="animate-pulse text-xl">
                                {{ "forms-table.loading" | translate }}
                            </p>
                        </span>
                    </td>
                </tr>
            </tbody>
        </ng-template>
    </table>
    <ng-content
        class="mt-4"
        *ngIf="(rows | async) !== null"
        select="[pagination]"
    ></ng-content>
</div>
