import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { HttpService } from '@app/services/http.service';
import { Guid } from '@app/util/guid';

@Injectable({
  providedIn: 'root'
})

export class TitlePortionService extends BaseService {
  constructor(HttpService: HttpService) {
    super(HttpService, 'v1/laboratory/billing/titleportion');
  }

  postMultiple(body: Object): Promise<Object> {
    return this.postByPath(body, 'multipleportions')
  }
  deleteAll(titleId: Guid){
    return this.deleteByPath(undefined, `titleid/${titleId}?all=true`)
  }

  deletePortion(titleId: Guid, portionId: Guid): Promise<Object> {
    return this.deleteByPath(undefined, `titleid/${titleId}?id=${portionId}`)
  }
}
