import { DefaultFormats } from "@app/pipe/localeDate.pipe"

export enum ETypeComunication {
  "Alvaro" = 0,
  "Cap Brasil" = 1,
  "Concent" = 2,
  "DB Diagnósticos Protocolo 1" = 3,
  "DB Diagnósticos Protocolo 2" = 4,
  "DB Diagnósticos Protocolo 3" = 5,
  "São Marcos" = 6,
  "Maricondi" = 7,
  "Hermes Pardini" = 8,
  "Shift" = 9,
  "Unilab" = 10,
  "Zenite" = 11,
  "Lab Rede" = 12,
  "Gimenes" = 13,
  "Atitude" = 14,
  "CDA Naoun" = 15,
  "Cerpa" = 16,
  "Fleury" = 17,
  "Solução" = 18,
  "Solução Matrix" = 19,
  "Madya" = 20,
  "Matrix Connect" = 21,
  "Multilab" = 22,
  "NeoVita Salus" = 23,
  "Patho" = 24,
  "Genoa" = 25,
  "SysLab WebService" = 26,
  "SysLab Arquivo" = 27
}

export const resolveLaboratory: Array<Object> = [
  {
    label: "id",
    retrive: "id",
    method: '',
    after: '',
    before: ''
  },
  {
    label: "Laboratório de Apoio",
    retrive: "name",
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Tipo de Comunicação',
    retrive: 'nameComunication',
    method: '',
    after: '',
    before: ''
  },
  {
    label: "Alterado em",
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'dateUpdate',
    dateFormat: DefaultFormats.full
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'active'
  },
]

export interface Actions {
  action: string,
  id: string,
  context: string | null,
  object: ResolveSupportLaboratory
}

export interface ResolveSupportLaboratory {
  Ativo: string,
  id: string,
}

