import { DefaultFormats } from "@app/pipe/localeDate.pipe"

export interface OptionMaterial {
  label: string,
  value: string,
  analyteId: string,
  analyteMaterialId: string
}

export interface ResolveObject {
  acceptValueReference: boolean
  analyteId: string
  codeInSupportLaboratory: string
  id: string
  isActive: boolean
  materialDescription: string
  materialId: string
  materialInSupportLaboratory: string
  name: string,
  value: number,
  hasRelationship: boolean
  createdAt: string
  updatedAt: string
  reportId: string
  requerid: boolean
}

export interface Actions {
  action: string,
  id: string,
  context: string | null,
  object: any
}

export const resolveCommunicationCredentials: Array<Object> = [
  {
    label: "id",
    method: '',
    retrive: "id",
    after: '',
    before: '',
  },
  {
    label: "Login",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'codeLogin',
    type: 'description',
  },
  {
    label: "Web Service",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'urlService',
    type: 'description',
  },
  {
    label: "Complemento",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'urlComplement',
    type: 'description',
  },
  {
    label: "Empresa",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'companyName',
    type: 'globalUnity',
  },
  {
    label: "Unidade",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'name',
    type: 'globalUnity',
  },
  {
    label: "Criado em",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full
  },
  {
    label: "Alterado em",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full
  },
  {
    label: "Ativo",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'active',
  },
]

export const resolveCollectionTime: Array<Object> = [
  {
    label: "id",
    method: '',
    retrive: "id",
    after: '',
    before: ''
  },
  {
    label: "Atributo",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'description',
    type: 'description'
  },
  {
    label: "_hide_sampleTimeDescription",
    method: '',
    retrive: "sampleTimeDescription",
    after: '',
    before: ''
  },
  {
    label: "_hide_analyteMaterialRecipientId",
    method: '',
    retrive: "analyteMaterialRecipientId",
    after: '',
    before: ''
  },
  {
    label: "Tempo de Curva",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'sampleTimeDescription',
    type: 'description'
  },
  {
    label: "Criado em",
    method: "formatField",
    retrive: '',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full
  },
  {
    label: "Alterado em",
    method: "formatField",
    retrive: '',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full
  },
  {
    label: "Ativo",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'active'
  },
]

export const resolveAtributeRelationship: Array<Object> = [//atributeSupportLaboratory
  {
    label: "id",
    method: '',
    retrive: "id",
    after: '',
    before: ''
  },
  {
    label: "Codigo do apoio",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'atributeSupportLaboratory',
    type: 'name'
  },
  {
    label: "Criado em",
    method: "formatField",
    retrive: '',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full
  },
  {
    label: "Alterado em",
    method: "formatField",
    retrive: '',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full
  },
]

export const resolveExamsAdditionalInformation: Array<Object> = [
  {
    label: "id",
    method: '',
    retrive: "id",
    after: '',
    before: ''
  },
  {
    label: "Nome",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'name',
    type: 'name'
  },
  {
    label: "Código",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'codeInSupportLaboratory',
    type: 'description'
  },
  {
    label: "Obrigatório",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'requerid',
    type: 'boolean'
  },
  {
    label: "Criado em",
    method: "formatField",
    retrive: '',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full
  },
  {
    label: "Alterado em",
    method: "formatField",
    retrive: '',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full
  },
  {
    label: "Ativo",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'active'
  },
  {
    label: "_hide_questionId",
    method: '',
    retrive: "questionId",
    after: '',
    before: ''
  },
];

export const resolveToFromExams: Array<Object> = [
  {
    label: "id",
    method: '',
    retrive: "id",
    after: '',
    before: '',
  },
  {
    label: "Exame",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'name',
    type: 'name',
  },
  {
    label: "Exame no Apoio",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'codeInSupportLaboratory',
    type: 'description',
  },
  {
    label: "Material",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'materialDescription',
    type: 'description',
  },
  {
    label: "Material no Apoio",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'materialInSupportLaboratory',
    type: 'description',
  },
  {
    label: "Aceita Referências",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'acceptValueReference',
    type: 'boolean',
  },
  {
    label: "Tem relacionamento",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'hasRelationship',
    type: 'boolean',
  },
  {
    label: "Valor",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'value',
    type: 'money',
  },
  {
    label: "Ativo",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'active',
  },
  {
    label: "_hide_analyteId",
    method: '',
    retrive: 'analyteId',
    after: '',
    before: '',
  },
  {
    label: "_hide_materialId",
    method: '',
    retrive: 'materialId',
    after: '',
    before: '',
  },
  {
    label: "_hide_analyteReportId",
    method: '',
    retrive: 'reportId',
    after: '',
    before: '',
  },
  {
    label: "_hide_hasRelationship",
    method: '',
    retrive: 'hasRelationship',
    after: '',
    before: '',
  },
  {
    label: "_hide_reference",
    method: '',
    retrive: 'acceptValueReference',
    after: '',
    before: '',
  },
  {
    label: "_hide_value",
    method: '',
    retrive: 'value',
    after: '',
    before: '',
  },
  {
    label: "_hide_hasRelationship",
    method: '',
    retrive: 'hasRelationship',
    after: '',
    before: '',
  },
  {
    label: "_hide_analyteMaterialId",
    method: '',
    retrive: 'analyteMaterialId',
    after: '',
    before: '',
  },
]

export enum CredentialOperation {
  Create = 1,
  Update = 2
}