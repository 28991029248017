import { VaccineService } from '@app/services/vaccines/vaccine.service';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Guid } from '@app/util/guid';
import { SipniService } from '@app/services/vaccines/sipni.service';
import { VaccinesAdministrationService } from '@app/services/vaccines/vaccines-administration.service';
import { VaccineApplicationService } from '@app/services/vaccines/vaccine-application.service';
import { SchemmaService } from '@app/services/vaccines/schemma.service';
import { ProductService } from '@app/services/stock/product';
import { ResolveVaccine, Vaccine, Actions} from './vaccine.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';


@Component({
  selector: 'app-vaccine',
  templateUrl: './vaccine.component.html',
  styleUrls: ['./vaccine.component.scss']
})
export class VaccineComponent implements OnInit {

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  editId: string = '';

  total: number = 0;
  page: number = 1;
  index: number = 50;

  itens: Array<object> = []

  sipni: Array<object> = []
  administration: Array<object> = []
  application: Array<object> = []
  products: Array<object> = []
  schemmas: Array<object> = []

  filter = {
    Search: new UntypedFormControl(),
    isActive: new UntypedFormControl(false)
  }

  form = {
    acronym: new UntypedFormControl(''),
    product: new UntypedFormControl(''),
    adverseEvent: new UntypedFormControl(''),
    orientation: new UntypedFormControl(''),
    administration: new UntypedFormControl(''),
    application: new UntypedFormControl(''),
    schemma: new UntypedFormControl(''),
    active: new UntypedFormControl(false)
  }

  resolveLaboratory: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Sigla",
      method: '',
      retrive: "acronym",
      after: '',
      before: '',
    },
    {
      label: "Produto",
      method: '',
      retrive: "description",
      after: '',
      before: '',
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ]

  ActionsExtra = {
    "Ativo": "PutActive"
  }


  constructor(
    private _sipniService: SipniService,
    private _vaccinesAdmService: VaccinesAdministrationService,
    private _vaccineService: VaccineService,
    private _schemmaService: SchemmaService,
    private _vaccineApplicationService: VaccineApplicationService,
    private _productService: ProductService,
    private _dialog: MatDialog,
    private _dataPipe: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe
  ) { }


  ngOnInit() {
    this.get()
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let search: string = this.filter.Search.value ? this.filter.Search.value : null;

    let isActive = this.filter.isActive.value ? this.filter.isActive.value: null;

    await this._vaccineService.getPagination({page: this.page, index: this.index, search, isActive}).then(
      (res:any) => {
        this.itens = [];
        this.total = res.sumRecords;
        if(res.data.length > 0) {
          this.resolve(res.data, this.resolveLaboratory);
        }
      }
    ).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.itens = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.itens.push(obj);
    });
  }

  async openDialogModal(id?: string) {
    if(this.sipni.length === 0 && this.administration.length === 0 && this.application.length === 0 && this.schemmas.length === 0 && this.products.length === 0) {
      await this.getSipni()
      await this.getAdmnVaccines()
      await this.getAppllicationVaccines()
      await this.getSchemma()
      await this.getProducts()
    }

    if (id) {
      this.editId = id
      this.getById()
    }

    const myTempDialog = this._dialog.open(this.dialogModal, {
      width: '660px'
    });

    myTempDialog.afterClosed().subscribe((res) => {
      this.resetForms()
      this.editId = ''
    });

  }

  getById() {
    this._vaccineService.getById(new Guid(this.editId)).then((res:any) => {
      this.setValueOnForms(res.data[0])
    })
  }

  closeDialog() {
    this._dialog.closeAll();
  }

  getActive(item: Vaccine) {
    return this._activePipe.transform(item.isActive)
  }

  getDate(item: Vaccine) {
    return this._dataPipe.transform(item.updatedAt);
  }

  post() {
    let obj = {
      sipniId: this.form.acronym.value,
      productId: this.form.product.value,
      adverseEvents: this.form.adverseEvent.value,
      orientation: this.form.orientation.value,
      administrationIds: this.form.administration.value,
      applicationIds: this.form.application.value,
      schemmaIds: this.form.schemma.value
    }

    this._vaccineService.post(obj).then((res:any) => {
      SwAlSetttings.Sucesso('Vacina Cadastrada!')
      this.get()
      this.closeDialog()
    }).catch(err => {
      SwAlSetttings.dispararErro(err)})
  }

  put() {
    let obj = {
      sipniId: this.form.acronym.value,
      productId: this.form.product.value,
      adverseEvents: this.form.adverseEvent.value,
      orientation: this.form.orientation.value,
      administrationIds: this.form.administration.value,
      applicationIds: this.form.application.value,
      schemmaIds: this.form.schemma.value,
      isActive: this.form.active.value
    }

    this._vaccineService.put(obj, new Guid(this.editId)).then((res:any) => {
      SwAlSetttings.Sucesso('Vacina Editada!')
      this.get()
      this.closeDialog()
    }).catch(err => SwAlSetttings.Error(err.error))
  }

  delete(idToDelete: string) {
    this._vaccineService.delete(new Guid(idToDelete)).then((res:any) => {
      SwAlSetttings.Sucesso('Vacina Excluída!')
      this.get()
      this.closeDialog()
    }).catch(err => SwAlSetttings.Error(err.error))
  }

  setValueOnForms(itens: ResolveVaccine) {
    this.form.acronym.setValue(itens.sipniId)
    this.form.product.setValue(itens.productId)
    this.form.adverseEvent.setValue(itens.adverseEvents)
    this.form.orientation.setValue(itens.orientation)
    this.form.active.setValue(itens.isActive)

    let administration : Array<object> = []
    itens.administration.forEach((element: any) => {
      administration.push(element.administrationId)
    });
    this.form.administration.setValue(administration)

    let application : Array<object> = []
    itens.application.forEach((element: any) => {
      application.push(element.applicationId)
    });
    this.form.application.setValue(application)

    let schemma : Array<object> = []
    itens.schemma.forEach((element: any) => {
      schemma.push(element.schemmaId)
    });
    this.form.schemma.setValue(schemma)

  }

  resetForms() {
    this.form.acronym.setValue(''),
    this.form.product.setValue(''),
    this.form.adverseEvent.setValue(''),
    this.form.orientation.setValue(''),
    this.form.administration.setValue(''),
    this.form.application.setValue(''),
    this.form.schemma.setValue(''),
    this.form.active.setValue(false)
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.openDialogModal(emit.object.id)
        break;
      case 'Excluir':
        this.delete(emit.object.id)
        break;
      case 'PutActive':
        this.putActive(emit.object.id,this._checkboxSvgPipe.transform(emit.object.Ativo))
        break;
    }
  }

  putActive(id: string, value: string) {
    this._vaccineService.patchActive(id, value).then((x:any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.get();
    }).catch(err => SwAlSetttings.printError(err))
  } 

  async getSipni() {
    await this._sipniService.getPagination({isActive:true}).then((res:any) => {
      if(res.data.length > 0) {
        res.data.forEach((x:any) => {
          this.sipni.push({
            value: x.id,
            label: x.acronym
          })
        });
      }
    })
  }

  async getSchemma() {
    await this._schemmaService.getPagination({isActive:true}).then((res:any) => {
      if(res.data.length > 0) {
        res.data.forEach((x:any) => {
          this.schemmas.push({
            value: x.id,
            label: x.dose
          })
        });
      }
    })
  }

  async getAdmnVaccines() {
    await this._vaccinesAdmService.getPagination({isActive:true}).then((res:any) => {
      if(res.data.length > 0) {
        res.data.forEach((x:any) => {
          this.administration.push({
            value: x.id,
            label: x.description
          })
        });
      }
    })
  }

  async getAppllicationVaccines() {
    await this._vaccineApplicationService.getPagination({isActive:true}).then((res:any) => {
      if(res.data.length > 0) {
        res.data.forEach((x:any) => {
          this.application.push({
            value: x.id,
            label: x.description
          })
        });
      }
    })
  }

  async getProducts() {
    await this._productService.getPagination({isActive:true, typeProduct: 5}).then((res:any) => {
      if(res.data.length > 0) {
        res.data.forEach((x:any) => {
          this.products.push({
            value: x.id,
            label: x.description
          })
        });
      }
    })
  }

  resetFilter(){
    this.filter.Search.reset();
    this.filter.isActive.reset();
  }

}
