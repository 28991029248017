<div class="contentPage w-100">
  <div id="header" class="flex flex-between align-center w-100 gap-2 headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
      <button (click)="redirectIntegracaoB2B()" class="btn-reverse">
        Voltar
      </button>
      <h1>Integração B2B</h1>
    </div>
    <app-filter class="filterResponsive" [advanced]="true">
      <div class="query">  
        <input-prop [placeholder]="'Nome do Arquivo'"></input-prop>
        <input-prop type="date" [placeholder]="'Data'"></input-prop>
      </div>
      <div class="advancedQuery">
        <input-prop [placeholder]="'Situação'"></input-prop>
      </div>
      <button class="queryBtn">Teste</button>
    </app-filter>
  </div>
  <div class="mt-32 gap-4 flex">
    <div id="aling" class="border paddManagement">
      <div id="management">
        <h2>Gerenciar Arquivos</h2>
      </div>
      <div id="management" class="flex gap-4 ">
        <div class="mt-32">
          <button class="flex gp-12 align-center">
            <mat-icon>cloud_download</mat-icon>
              Dowload
          </button>
        </div>
        <div>
          <file-prop multiple="true" dashed="true" form="" formName=""></file-prop>
        </div>
      </div>
    </div>             
  </div>
  <div class="flex mt-32 gap-4">
    <app-table class="w-100" [Itens]="register"></app-table>
  </div> 
</div>