import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "prazoDate"
})
export class PrazoDatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value) return null;
        if (!args) return value;
        value = value.filter((y: any) => {
            return parseInt(args) === this.checkDate(y.deadlineDate)
        })
        return value;
    }

    checkDate(date: string) {
        const DateCard = new Date(date);
        var DateCardOnlyDay = new Date(DateCard.getFullYear() + '-' + (DateCard.getMonth() + 1) + '-' + DateCard.getDate()).getTime();

        const today = new Date();
        var dateOnlyDay = new Date(today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()).getTime();

        if (DateCardOnlyDay > dateOnlyDay) {
            return 3;
        } else if (DateCardOnlyDay == dateOnlyDay) {
            return 2;
        } else {
            return 1;
        }
    }
}