<modal-layout [title]="'Resetar Senha Usuário: ' + _usrObj.name">
    <form [formGroup]="form" class="my-12 flex flex-col gap-10">
        <forms-input-text
            type="text"
            class="w-full"
            [placeholder]="'Código de Acesso'"
            requiredInput="true"
            [form]="form.controls.accessCode"
        ></forms-input-text>
        <forms-input-text
            type="password"
            class="w-full"
            [placeholder]="'Nova Senha'"
            requiredInput="true"
            [form]="form.controls.newPassword"
        ></forms-input-text>
        <forms-input-text
            type="password"
            class="w-full"
            [placeholder]="'Confirmar nova senha'"
            requiredInput="true"
            [form]="form.controls.confirmNewPassword"
        ></forms-input-text>
        <forms-button
            #button
            class="self-center"
            (click)="resetPassword()"
            classExtra="btn btn-primary"
            [text]="'Resetar Senha'"
        ></forms-button>
        <forms-toggle
            [form]="form.controls.isActive"
            [placeholder]="'Ativo'"
        ></forms-toggle>
        <forms-toggle
            [form]="form.controls.isVerified"
            [placeholder]="'Verificado'"
        ></forms-toggle>
    </form>
</modal-layout>
