<div class="flex align-center">
    <label class="switch" [ngClass]="disabled ? 'pointer-events-none' : ''">
        <input
            #checkboxElement
            *ngIf="form; else withoutForm"
            type="checkbox"
            [formControl]="form"
            (change)="onChange(form.value)"
        />
        <ng-template #withoutForm>
            <input type="checkbox" [disabled]="disabled" />
        </ng-template>
        <span *ngIf="!isAdm" class="slider round"></span>
        <span *ngIf="isAdm" class="slider round adm"></span>
    </label>

    <label class="ml-3" *ngIf="placeholder">
        {{ placeholder | translate }}
    </label>
</div>
