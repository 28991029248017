import { Guid } from "@app/util/guid"

export interface Actions {
    action: string,
    id: string,
    context: string | null,
    object: ResolveCompany
  }
  
export interface ResolveCompany {
    Ativo: string,
    id: Guid,
    Nome: string,    
    "Razão Social": string,
    "Criado em": string,
    "Alterado em": string   
}

export interface ResolveUnits {
  Descrição: string,
  id: Guid,       
  "Criado em": string,
  "Alterado em": string   
}
  
export interface GetCompany{
    id: string,
    companyName: string,
    createdAt: string,
    updatedAt: string,    
    name: string,
    socialReason: string,
    isActive: boolean    
    externalId: string
}

export type GetUnity = {
    createdAt: string,
    updatedAt: string,    
    description: string,    
    socialReason: string,
    name: string,
    isActive: boolean,
    id:string
}

export const resolveUnitiesObj = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'company.description',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'company.createdAt',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'company.updatedAt',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ]

  export const resolveCompanys = [
    {
      label: "_hide_socialReason",
      retrive: 'socialReason',
      method: '',
      after: '',
      before: ''
    },    
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'company.name',
      retrive: 'companyName',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'company.socialReason',
      retrive: 'socialReason',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'company.createdAt',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'company.updatedAt',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ];
