import { Inject, Injectable } from "@angular/core";
import { Guid } from "../../../util/guid";
import { IHttpService } from "../../interfaces/IHttpService";
import { AdminHttpAuthService } from "./http-auth.service";
import { QueryParams } from "./interfaces/services.types";

@Injectable({
   providedIn: "root"
})
export abstract class AdminBaseAuthService implements IHttpService {
   protected path: string;

   //@ts-nocheck
   constructor(
      protected HttpAuthService: AdminHttpAuthService,
      @Inject(String) path: string
   ) {
      this.path = path;
   }

   get(queryString: string, tenantId?: string): Promise<Object> {
      return this.HttpAuthService.get(
         queryString,
         `api/${this.path}`,
         tenantId
      );
   }

   getSelectTenant(
      queryString: string | QueryParams,
      tenantId: string
   ): Promise<Object> {
      if (!tenantId) throw new Error("Empresa inválida");
      const queryParam =
         typeof queryString === "string"
            ? queryString
            : this.createQueryParam(queryString);
      return this.HttpAuthService.get(queryParam, `api/${this.path}`, tenantId);
   }

   getAll(tenantId?: string): Promise<Object> {
      return this.HttpAuthService.get("", `api/${this.path}`, tenantId);
   }

   getById(id: Guid, tenantId?: string): Promise<Object> {
      return this.HttpAuthService.get("", `api/${this.path}/${id}`, tenantId);
   }

   getByPath(
      queryString: string,
      path: string,
      tenantId?: string
   ): Promise<Object> {
      return this.HttpAuthService.get(
         queryString,
         `api/${this.path}/${path}`,
         tenantId
      );
   }

   post(body: Object, tenantId?: string): Promise<Object> {
      if (!body) throw new Error("Objeto inválido");
      return this.HttpAuthService.post(body, `api/${this.path}`, tenantId);
   }

   postToPath(
      body: Object,
      path?: string | undefined,
      tenantId?: string | undefined
   ): Promise<Object> {
      if (!body) throw new Error("Objeto inválido");
      return this.HttpAuthService.post(
         body,
         `api/${this.path}/${path}`,
         tenantId
      );
   }

   put(body: Object, id: Guid, tenantId?: string): Promise<Object> {
      if (!body) throw new Error("Objeto inválido");
      return this.HttpAuthService.put(body, id, `api/${this.path}`, tenantId);
   }

   delete(id?: Guid, tenantId?: string): Promise<Object> {
      //@ts-ignore
      return this.HttpAuthService.delete(id, `api/${this.path}`, tenantId);
   }

   deleteWithBody(body: Object, path: string): Promise<Object> {
      return this.HttpAuthService.deleteWithBody(
         `api/${this.path}/${path}`,
         body
      );
   }

   patch(body: Object, id: Guid): Promise<Object> {
      if (!body) throw new Error("Objeto inválido");
      return this.HttpAuthService.put(body, id, `api/${this.path}`);
   }

   patchActive(id: string, value: string) {
      let valueActive: boolean;
      if (value.includes("checkbox")) {
         valueActive = value.includes("true") ?? false;
      } else {
         valueActive = value.includes("active") ?? false;
      }
      let infosToPut = [
         {
            Opt: "replace",
            Patch: "/IsActive",
            Value: !valueActive
         }
      ];

      return this.HttpAuthService.patch(
         infosToPut,
         new Guid(id),
         `api/${this.path}`
      );
   }

   patchWithPath(body: Object, path: string): Promise<Object> {
      return this.HttpAuthService.patchWithPath(
         body,
         `api/${this.path}/${path}`
      );
   }

   /**
    * Método para fazer GET de Endpoints que podem fazer páginação
    * @param page Página que deseja acessar
    * @param index Número de dados que devem ser retornados por Requisição
    * @param queryObject Objeto que representa o QueryParam
    * @returns Retorna requisição páginada
    */
   getPagination(queryObject?: QueryParams): Promise<Object> {
      const queryParams = this.createQueryParam(queryObject);
      return this.HttpAuthService.get(queryParams, `api/${this.path}`);
   }

   /**
    * Método para transformar dados em QueryParam para ser usado em URL
    * @param params Objeto que será transformado em QueryParam usando {chave: valor}
    * @param initialValue Valor inicial para ser usado na Query
    * @returns retorna valor em formato de "?chave=valor"
    */
   private createQueryParam(
      params?: QueryParams,
      initialValue: string = "?"
   ): string {
      let queryParams: string[] = [initialValue];

      if (params) {
         Object.keys(params).forEach((param) => {
            let paramValue = params[param];
            if (
               (typeof paramValue === "string" && paramValue.length == 0) ||
               paramValue === null
            )
               return;
            queryParams.push(`${param}=${paramValue}`);
         });
      }

      if (queryParams.length === 1 && queryParams[0] === "?") return "";

      return queryParams.join("&").replace("?&", "?");
   }
}
