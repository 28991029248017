import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Util } from '@app/util/util';
import { SectorActions, resolveSector } from './sector-list.model';
import { SectorService } from '@app/services/sector/sector.service';
import { Guid } from '@app/util/guid';
import { CreateSector, UpdateSector } from '@app/services/sector/sector.service.module';
import { ToastService } from '@app/services/translate/toast.service';

@Component({
  selector: 'app-sector-list',
  templateUrl: './sector-list.component.html',
  styleUrls: ['./sector-list.component.scss']
})
export class SectorListComponent implements OnInit {

  total: number = 0;
  page: number = 1;
  index: number = 50;

  sectorList : Array<object> = [];

  collectionPlaceForm = new UntypedFormGroup({
    externalId : new UntypedFormControl(null),
    description : new UntypedFormControl(null),
  });

  formFilter = {
    Search : new UntypedFormControl(null),
    externalId : new UntypedFormControl(null),
    description : new UntypedFormControl(null),
    IsActive : new UntypedFormControl(null),
  }

  ActionsExtra = {
    'Ativo': 'setActive',
  };

  @ViewChild('dialogRegisterSector')
  dialogRegisterSector! : TemplateRef<unknown>

  constructor(
    private _dialog : MatDialog,
    private _util : Util,
    private _sectorService : SectorService,
    private _toastService: ToastService
  ) { }

  ngOnInit() {
    this.getSector();
  }

  async getSector(paramPage?: Array<number>){
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }
    const filterObj = {
      page: this.page, 
      index: this.index,
      Description: this.formFilter.description.value,
      externalId: this.formFilter.externalId.value,
      IsActive: this.formFilter.IsActive.value ? this.formFilter.IsActive.value : null
    }
    await this._sectorService.getPagination(filterObj).then(
      (res) => {
        this.total = res.sumRecords
        this.resolve(res.data, resolveSector);
      }
    )
  }

  resolve(data: any, columns: any) {
    this.sectorList = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.sectorList.push(obj);
    });
  }

  createSector(){
    const createobj : CreateSector = {
      externalId : this.collectionPlaceForm.controls['externalId'].value,
      description : this.collectionPlaceForm.controls['description'].value,
    }
    this._sectorService.post(createobj).then(() => {
      this.closeModal();
      this.getSector();
      this._toastService.fireSuccess('hospitalSectors.msgPost');
    })
  }

  updateSector(id:string){
    const updateObj : UpdateSector = {
      externalId : this.collectionPlaceForm.controls['externalId'].value,
      description : this.collectionPlaceForm.controls['description'].value,
    }
    this._sectorService.put(updateObj, new Guid(id)).then(() => {
      this.closeModal();
      this.getSector();
      this._toastService.fireSuccess('hospitalSectors.msgPut');
    })
  }

  actions(emit: SectorActions) {
    switch (emit.action) {
      case 'Editar':
        this.collectionPlaceForm.controls['externalId'].setValue(emit.object['hospitalSectors.code']);
        this.collectionPlaceForm.controls['description'].setValue(emit.object['hospitalSectors.description']);
        this.openDialogRegisterSector(emit.object.id);
        break;
      case 'Excluir':
        this.deleteSector(emit.object.id);
        break;
      case 'setActive':
        this.putActive(emit.object.id, emit.object.Ativo);
        break
    }
  }

  deleteSector(id : string){
    this._toastService.fireConfirmation('hospitalSectors.msgDelete').then(
      async (r) => {
        if (r.isConfirmed) {
          await this._sectorService.delete(new Guid(id));
          this.getSector();
          this._toastService.fireSuccess("hospitalSectors.msgDeleteSuccess");
        }
      }
    )
  }

  putActive(id: string, value: string) {
    this._sectorService.patchActive(id, value).then(() => {
      this._toastService.fireSuccess('global.msgSituationSuccess');
      this.getSector();
    })
  }

  openDialogRegisterSector(id? : string){
    const myTempDialog = this._dialog.open(this.dialogRegisterSector, {
      width: '700px',
      data: id
    });
    myTempDialog.afterClosed().subscribe(() => {
      this.collectionPlaceForm.reset();
    });
  }

  closeModal(){
    this._dialog.closeAll();
  }

  resetFilter(){
    this.formFilter.IsActive.reset();
    this.formFilter.Search.reset();
    this.formFilter.description.reset();
    this.formFilter.externalId.reset();
  }

}
