import { Component, Input, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

/**
 * Modal Component is only to display content above 'body-concent'
 */
@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('overlay',[
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms', style({ opacity: 0.5 }))
      ]),
      transition(':leave', [
        animate('400ms', style({ opacity: 0 }))
      ])
    ]),
    trigger('modal',[
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('250ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ModalComponent implements OnInit {

  /** Define size of content displayed */
  @Input('size') size: 'large' | 'small' = 'large'
  /** Define title of Modal */
  @Input('title') title: string = ""
  showContent: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  toggle(): void {
    this.showContent = !this.showContent
  }

  close(): void {
    this.showContent = false
  }

}
