<div [class]="classe">
    <div class="flex align-center">
        <label *ngIf="label">
            {{label}}
        </label>
        <div class="sd" [ngClass]="{border: border}">
            <svg class="abs" width="13" *ngIf="notSelect && !form.value && !hideSvg"  height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.715988 0.248954C0.998628 -0.0178237 1.45085 -0.0119791 1.72605 0.262009L6.5 5.0149L11.2739 0.262009C11.5492 -0.0119796 12.0014 -0.0178242 12.284 0.248953C12.5667 0.515731 12.5727 0.954109 12.2975 1.2281L6.5 7L0.702521 1.2281C0.427319 0.954109 0.433348 0.515732 0.715988 0.248954Z" fill="#808080"/>
            </svg>
            <!-- <svg class="abs ok" *ngIf="!notSelect || form.value" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve"> <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata> <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M4630.9,5002.4c-1544.5-122.9-2904.7-932-3750.7-2226.7c-385.1-590-649.4-1311-749.7-2044.4c-36.9-272.4-41-930-8.2-1188.1C241-1376.5,597.5-2238.9,1140.3-2931.2c176.2-221.2,669.8-704.7,889-868.6c714.9-534.6,1509.7-856.3,2406.9-970.9c237.6-30.7,889-30.7,1126.7,0c897.2,114.7,1690,434.2,2406.9,968.9c249.9,186.4,751.8,688.3,938.2,938.2c534.7,717,854.2,1509.7,968.9,2406.9c30.7,237.6,30.7,889,0,1126.7c-102.4,788.7-356.4,1493.3-774.3,2128.3c-184.4,280.6-325.7,454.8-600.2,737.4c-665.7,684.2-1456.4,1130.8-2396.7,1354C5677.6,4990.1,5067.2,5037.2,4630.9,5002.4z M7818.3,2480.8c147.5-69.6,258.1-184.4,329.8-340c45.1-100.4,51.2-133.1,43-288.8c-6.1-131.1-20.5-202.8-55.3-270.4C8103,1515.9,7554,952.6,6243-360.4C4161.8-2443.7,4290.8-2331.1,3985.6-2331.1c-288.8,0-284.7-2-1243.4,956.6c-731.3,733.3-839.9,850.1-889,958.7c-145.4,317.5-4.1,704.7,317.5,868.5c176.2,90.1,438.4,86,616.6-10.2c59.4-32.8,307.3-262.2,647.3-604.3l551-551L5562.9,862.5c1112.3,1110.3,1603.9,1589.6,1667.4,1620.3C7418.8,2577,7619.6,2575,7818.3,2480.8z"/></g></g> </svg> -->
            <input #input
                (keyup.enter)="searchApi ? searchApiEmit($event) : '' " 
                (keydown.tab)="onInputChange($event)"
                [matAutocomplete]="auto" 
                [class]="noLabelUp ? 'noLabelUp' : ''"  
                [tabindex]="tabindexcomponent" 
                [type]="type" 
                [required]=requiredInput 
                [placeholder]="placeholder | translate"        
                [(ngModel)]="valueOption"          
                (change)="CheckValue()" 
                (input)="valueChange($event)"                
                [formControl]="myControl"
                (keydown.backspace)="backspacePressed()"
                (keydown.arrowdown)="changeOption()"
                (keydown.arrowup)="changeOption()"
            >
            <label for="">{{placeholder | translate }}</label>
            <span></span>
            <span class="abs" *ngIf="color !== 'unset'" [ngStyle]="{'background': color}"  style="width: 33px; height: 16px; border-radius: 100px; top: 16px;"></span>
            <mat-autocomplete [style]="'width:100%;'" #auto="matAutocomplete" (optionSelected)="getPosts($event)">
                <mat-option stop-propagation *ngFor="let option of options | searchFilter: valueOption; let index;" [value]="option" (mousedown)="clickOption($event)">
                  {{option.label}}
                </mat-option>
            </mat-autocomplete>
        </div>

        <div *ngIf="searchApi" class="svg-icon" (click)="searchApiEmit($event)" matTooltip="Pesquisar" [matTooltipPosition]="'above'">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
              d="M11.6732 9.51212C12.5196 8.25295 12.8669 6.72374 12.6474 5.22249C12.4279 3.72124 11.6574 2.35548 10.4859 1.39134C9.31439 0.427204 7.82591 -0.0662417 6.31047 0.00715382C4.79503 0.0805493 3.3612 0.715528 2.28836 1.78836C1.21553 2.8612 0.580549 4.29503 0.507154 5.81047C0.433758 7.32591 0.927204 8.81439 1.89134 9.98588C2.85548 11.1574 4.22124 11.9279 5.72249 12.1474C7.22374 12.3669 8.75295 12.0196 10.0121 11.1732L13.5253 14.6849C13.7479 14.8923 14.0423 15.0052 14.3465 14.9998C14.6507 14.9945 14.941 14.8712 15.1561 14.6561C15.3712 14.441 15.4945 14.1507 15.4998 13.8465C15.5052 13.5423 15.3923 13.2479 15.1849 13.0253L11.6732 9.51212ZM6.60538 9.86282C5.86223 9.86282 5.13577 9.64245 4.51786 9.22957C3.89996 8.8167 3.41836 8.22987 3.13397 7.54329C2.84957 6.85671 2.77516 6.10121 2.92015 5.37234C3.06513 4.64347 3.42299 3.97396 3.94847 3.44847C4.47396 2.92299 5.14347 2.56513 5.87234 2.42015C6.60121 2.27516 7.35671 2.34957 8.04329 2.63397C8.72987 2.91836 9.3167 3.39996 9.72957 4.01786C10.1424 4.63577 10.3628 5.36223 10.3628 6.10538C10.3628 7.10192 9.96694 8.05763 9.26229 8.76229C8.55763 9.46694 7.60192 9.86282 6.60538 9.86282Z" />
            </svg>
        </div>
    </div>
</div>
