import { Component, inject, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FormGroup, FormControl } from "@angular/forms";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { LoginFacade } from "./data/login/login.facade";
import { environment } from "@env/environment";
import { SwAlSetttings } from "@app/util/swal.settings";
import { JwtHelperService } from "@auth0/angular-jwt";
import { UserTenantsFacade } from "./data/userTenants/userTenants.facade";
import { CompanyFacade } from "../administrativeData/data/facade/company-facade";
import { RequestOptions } from "../data/services/types/http.types";
import { InputSearchFormComponent } from "../shared/components/form/input-select/search/input-search.component";
const helper = new JwtHelperService();
@Component({
   selector: "login",
   templateUrl: "./login.component.html",
   styleUrls: ["./login.component.scss"],
   standalone: true,
   imports: [
      TranslateModule,
      CommonModule,
      InputTextFormComponent,
      ButtonFormComponent,
      InputSearchFormComponent
   ]
})
export class LoginComponent implements OnInit {
   form = new FormGroup({
      username: new FormControl<string | null>(null),
      password: new FormControl<string | null>(null),
      validation: new FormControl<string | null>(null),
      chooseTenant: new FormControl<string | null>(null),
      chooseUnity: new FormControl<string | null>(null),
      chooseCompany: new FormControl<string | null>(null)
   });
   isAres: boolean = false;
   multipleTenant: boolean = false;
   multipleUnitys: boolean = false;
   tenantsOfUser: Array<object> = [];
   companyOptions: Array<Object> = [];
   unityOptions: Array<Object> = [];

   private _loginFacade = inject(LoginFacade);
   private _userTenantsFacade = inject(UserTenantsFacade);
   private _companyFacade = inject(CompanyFacade);

   ngOnInit(): void {
      if (environment.service == "ares") {
         this.isAres = true;
      }
      localStorage.removeItem("TenantId");
      localStorage.removeItem("user");
      localStorage.removeItem("userImage");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("admin");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("refreshToken");
      sessionStorage.removeItem("accessType");
      sessionStorage.removeItem("userImage");
      sessionStorage.removeItem("TenantId");
      sessionStorage.removeItem("personId");
      sessionStorage.removeItem("personName");
   }

   async Login(tenantSelected?: string) {
      localStorage.removeItem("company");
      localStorage.removeItem("unity");

      let form: any = {
         accessCode: this.form.controls.username.value,
         password: this.form.controls.password.value
      };

      if (tenantSelected) form.tenantId = tenantSelected;

      if (
         this.form.controls.chooseUnity.value &&
         this.unityOptions.length > 0
      ) {
         form.unityId = this.form.controls.chooseUnity.value;
      }

      if (this.multipleUnitys) {
         form.companyId = this.form.controls.chooseCompany.value;
      }

      if (this.form.controls.validation.value)
         form.codeToken = this.form.controls.validation.value;

      if (
         this.form.controls.username.value &&
         this.form.controls.password.value
      ) {
         const response: any = await this._loginFacade.post(form);
         if (response.success) {
            localStorage.setItem(
               "token",
               response.accessToken ? response.accessToken : ""
            );
            localStorage.setItem(
               "refreshToken",
               response.refreshToken ? response.refreshToken : ""
            );
            const decodedToken = helper.decodeToken(response.accessToken);
            localStorage.setItem("TenantId", decodedToken.tid.replace(";", ""));
            await this.getTenantsUser();
            window.location.href = "/";

            if (this.multipleUnitys) {
               if (this.form.controls.chooseUnity.value) {
                  let unityInfos = this.unityOptions.filter(
                     (x: any) =>
                        x.value === this.form.controls.chooseUnity.value
                  )[0];

                  localStorage.setItem("unity", JSON.stringify(unityInfos));
               }
               let companyInfos: any = this.companyOptions.filter(
                  (x: any) => x.value === this.form.controls.chooseCompany.value
               )[0];

               companyInfos = {
                  name: companyInfos.label,
                  id: companyInfos.value
               };

               localStorage.setItem("company", JSON.stringify(companyInfos));
            }
         } else {
            if (response.error.multipleTenant) {
               this.multipleTenant = true;
               this.getTenantsUser();
            } else {
               if (response.error.accessToken == "validation required") {
                  SwAlSetttings.Sucesso(response.error.errors);
               } else if (
                  response.error.multipleCompanyUnities &&
                  response.error.httpStatusCode === 302
               ) {
                  this.multipleUnitys = true;
                  this.getCompany(response.error.id);
               } else {
                  SwAlSetttings.printError(response);
               }
            }
         }
      }
   }

   async getTenantsUser() {
      this.tenantsOfUser = [];
      localStorage.removeItem("tenantsOfUser");
      const response: any = await this._userTenantsFacade.get(1, 999999, {
         accessCode: this.form.controls.username.value
      });

      response.data.forEach((element: any) => {
         this.tenantsOfUser.push({
            label: element.companyName,
            value: element.tenantId
         });
      });

      localStorage.setItem("tenantsOfUser", JSON.stringify(this.tenantsOfUser));
   }

   async getCompany(tenantId?: string) {
      this.companyOptions = [];

      let tenant = this.form.controls.chooseTenant.value ?? tenantId;

      if (this.multipleUnitys && typeof tenant === "string") {
         let request: RequestOptions = {
            path: "/companies",
            query: {
               accessCode: this.form.controls.username.value,
               isActive: true
            },
            //@ts-ignore
            headers: { TenantId: tenantId }
         };

         this.companyOptions = await this._companyFacade.getCompanyOptions(
            false,
            request
         );
      }
   }

   filterUnitys() {
      this.unityOptions = [];

      if (this.form.controls.chooseCompany.value) {
         let companys: any = this.companyOptions.filter(
            (x: any) => x.value === this.form.controls.chooseCompany.value
         )[0];
         if (companys.unities.length > 0) {
            this.unityOptions = companys.unities.map((res: any) => ({
               value: res.value,
               label: res.label
            }));
         }
      }
   }

   navigateToForgotPassword() {
      window.location.href = "/EsqueciMinhaSenha";
   }

   clearForms() {
      this.form.controls.chooseCompany.setValue("");
      this.form.controls.chooseTenant.setValue("");
      this.form.controls.chooseUnity.setValue("");
   }
}
