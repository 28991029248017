import { Injectable } from '@angular/core';
import { BaseService } from './../base.service';
import { HttpService } from './../http.service';
import { Guid } from '@app/util/guid';
import { HttpResponsePageable } from '../services.types';
import { FileDocumentGetById, FileDocumentPost, FileDocumentPostObject } from './fileDocument.types';

@Injectable({
  providedIn: 'root'
})
export class FileDocumentService extends BaseService {

  constructor(httpService: HttpService) {
    super(httpService, 'v1/accesscontrol/filedocument');
  }
  
  override getById(id: Guid) {
    return super.getById(id) as Promise<HttpResponsePageable<FileDocumentGetById>>
  }

  override post(body: FileDocumentPostObject) {
    return super.post(body) as Promise<HttpResponsePageable<FileDocumentPost>>
  }
}
