import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AccessControlUserAuth } from '@app/services/auth/acesscontrol-user-auth.service';
import { ProfilePathAuth } from '@app/services/auth/profile-path.service';
import { CompanyService } from '@app/services/company/company.service';
import { Guid } from '@app/util/guid';
import { UnityService } from '@app/services/shared/unity.service';
import { GroupsService } from '@app/services/groups/groups.service';
import { Util } from '@app/util/util';
import { ModuleAuthService } from '@app/services/auth/module-auth-service';
import { ToastService } from '@app/services/translate/toast.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  @ViewChild('dialogModalUser')
  dialogModalUser!: TemplateRef<any>

  @ViewChild('dialogModalModules')
  dialogModalModules!: TemplateRef<any>

  total: number = 0;
  page: number = 1;
  numberRegistry: number = 50;
  editId: string = ''
  dropId: string = ''

  userOptionsId: any = {};
  groups: Array<any> = [];
  userOptions: Array<any> = [];
  modulesOptions: Array<any> = [];
  modulesRecursosOptions: Array<any> = [];
  modulesRecursosTableOptions: any = {};
  companyOptions: Array<any> = [];
  unityOptions: Array<any> = [];
  userDelete: any

  formFilter = {
    name: new UntypedFormControl(null),
  }

  form = {
    name: new UntypedFormControl(null),
    isActive: new UntypedFormControl(null)
  }

  formUser = {
    name: new UntypedFormControl(null),
    company: new UntypedFormControl(null),
    unity: new UntypedFormControl(null)
  }

  formModules = {
    modules:  new UntypedFormControl(null),
    papers: new UntypedFormControl(null)
  }

  resolveUserTable = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: '',
    },
    {
      label: 'groups.name',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'name',
      type: 'name'
    },
    {
      label: 'groups.company',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'companyName',
      type: 'name'
    },
    {
      label: 'groups.unity',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'unityName',
      type: 'name'
    },
    {
      label: '_hide_user',
      retrive: 'userId',
      method: '',
      after: '',
      before: '',
    },
  ]

  resolveRecursosTable = [
    {
      label: 'id',
      retrive: 'resourceId',
      method: '',
      after: '',
      before: '',
    },
    {
      label: 'groups.name',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'resourceName',
      type: 'name'
    },
    // {
    //   label: 'Descrição',
    //   retrive: '',
    //   method: 'formatField',
    //   after: '',
    //   before: '',
    //   fieldName: 'description',
    //   type: 'description'
    // },
  ]

  constructor(
    private _groupsService: GroupsService,
    private _accessControlUserAuth: AccessControlUserAuth,
    private _profilePathAuth: ProfilePathAuth,
    private _moduleAuthService: ModuleAuthService,
    private _dialog: MatDialog,
    private _companyService: CompanyService,
    private _unityService: UnityService,
    private _util: Util,
    private _toastService: ToastService
  ){}

    ngOnInit() {
      this.get();  
      this.getCompanies();
  }


  async get(params?: number[]) {
    if (params) {
      this.numberRegistry = params[1]
      this.page = params[0]
    }
  
    const queryObject = {
      search: this.formFilter.name.value ?? null,
      numberRegistry: this.numberRegistry,
      page: this.page,
    }
  
    await this._groupsService.getPagination(queryObject).then(
      (response: any) => {
        if (response.data.length != 0) {
          this.groups = response.data.map((group: any) => ({
            id: group.id,
            "groups.name": group.name,
          }
          ))
          this.total = response.sumRecords
        } 
      }
      ).catch(
        (err) => {
          this._toastService.fireError(err);
        }
      );
  }

  async post() {
    const obj = {
      name: this.form.name.value
    }

    await this._groupsService.post(obj).then((res: any) => {
      if (res.success) {
        this._toastService.fireSuccess('groups.msgPostGroup')
        this._dialog.closeAll()
        this.get();
      }
    }).catch(
      (err) => {
        this._toastService.fireError(err);
    });
  }

  async put() {
    const obj = {
      name: this.form.name.value
    }
    await this._groupsService.put(obj, new Guid(this.editId)).then((res: any) => {
      if (res.success) {
        this.get();
        this._toastService.fireSuccess('groups.msgPutGroup')
        this._dialog.closeAll()
      }
    }).catch(
      (err) => {
        this._toastService.fireError(err);
    });
  }
  
  async openDialog() {
    const myTempDialog = this._dialog.open(this.dialogModal, {
      width: '580px'
    });

    myTempDialog.afterClosed().subscribe(() => {
      this.form.name.reset()
      this.editId = '';
    });
  }

  async openDialogUser(id?: Guid) {
    this.getUser();    
    const myTempDialog = this._dialog.open(this.dialogModalUser, {
      width: '580px',
      data: id
    });

    myTempDialog.afterClosed().subscribe(() => {
      this.formUser.company.setValue('');
    });
  }

  async openDialogModules(id?: Guid) {
    this.modulesRecursosOptions = [];
    await this.getModules();
    const myTempDialog = this._dialog.open(this.dialogModalModules, {
      width: '580px',
      data: id
    });
    myTempDialog.afterClosed().subscribe(() => {
      this.formModules.modules.reset();
    });
  }
  
  delete(id: string) {
    this._toastService.fireConfirmation("groups.msgDeleteGroup").then(
      async (resposta) => {
        if (resposta.isConfirmed) {
          await this._groupsService.delete(new Guid(id)).then(
            async (res: any) => {
              if (res.success) {
                await this.get();
                this._toastService.fireSuccess("groups.msgDeleteGroupSuccess");
              }
            });
        }
      }).catch(
        (err) => {
          this._toastService.fireError(err);
      });
  }

  async actions(emit: any) {
    switch (emit.action) {
      case 'Editar':
        this.editId = emit.object.id;
        this.openDialog();
        this.form.name.setValue(emit.object['groups.name'])
        break;
      case 'Excluir':
        this.delete(emit.object.id)
        this.get();
        break;
      case 'OpenDropdown':
        this.dropId = emit.object.id        
        this.getResourceById(emit.object.id);
        break;  
      }
    }

  actionsUser(emit: any){
      switch (emit.action) {
        case 'Excluir':
        this._toastService.fireConfirmation("groups.msgDeleteUser").then(
          async (resposta) => {
            if (resposta.isConfirmed) {
              await this._groupsService.deleteB(undefined, {usersId: [emit.object['_hide_user']]} ,`${this.dropId}/user`).then(
                async (res: any) => {
                  if (res.success) {
                    this.getResourceById(new Guid(this.dropId));
                    this._toastService.fireSuccess('groups.msgDeleteUserSuccess') 
                  }
                });
            }
          }).catch(
            (err) => {
              this._toastService.fireError(err);
          });   
      break;
    }
  }

  actionsRecursos(emit: any){
    switch (emit.action) {
      case 'Excluir':
        this._toastService.fireConfirmation("groups.msgDeleteRecursos").then(
        async (resposta) => {
          if (resposta.isConfirmed) {
            await this._groupsService.deletePermission(this.dropId, `${emit.object.id}`).then(
              async (res: any) => {
                if (res.success) {
                  this.getResourceById(new Guid(this.dropId));
                  this._toastService.fireSuccess('groups.msgDeleteRecursosSuccess') 
                }
              });
          }
        }).catch(
          (err) => {
            this._toastService.fireError(err);
        });   
    break;
  }
}


  close() {
    this._dialog.closeAll()
    this.form.name.reset()
  }

  async getUser() {
    await this._accessControlUserAuth.getPagination().then(response => {
      if (response.success) {
        this.userOptions = response.data.map((res:any) => (
          {
            value: res.id,
            label: res.name
          }
        ))
      }
    })
  }

  postUser(id: Guid) {
    let obj = {
      usersId: this.formUser.name.value,
      companyId: this.formUser.company.value,
      unityId: this.formUser.unity.value
    }
    this._groupsService.postToPath(obj, `${id}/user`).then((res: any) => {
      this._toastService.fireSuccess('groups.msgPostUser')  
      this.getResourceById(new Guid(this.dropId))
      this.close()
      this.userOptions = []
    })
  }

  postModules(id: Guid) {
    let obj = {
      resourceId: this.formModules.papers.value,
    }
    this._groupsService.postToPath(obj, `${id}/policy`).then((res: any) => {
      this.getResourceById(new Guid(this.dropId));
      this._toastService.fireSuccess('groups.msgPostPermissions')  
      this.close()
    })
  }

  async getModules() {
    await this._profilePathAuth.getByPath('', 'modules').then((response: any) => {
      if (response.success) {
        this.modulesOptions = response.data.map((res:any) => (
          {
            value: res.id,
            label: res.description
          }
        ))
      }
    })
  }

  async getResource() {    
    const value = this.formModules.modules.value
    if (!value) {
      return
    }
    this.modulesRecursosOptions = await this._moduleAuthService.getByPath('', `${value}/resource`).then(
      (res: any) => {
        if (res.data.length != 0) {
          return res.data.map((element: any) => {            
            return element.resources.map((resource: any) => ({
              label: resource.description != '' ? resource.description : resource.name,
              value: resource.id
            }))
          })[0]
        } else {
          return []
        }
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    );
  }

  async getCompanies() {
    await this._companyService.getResume().then(response => {
      if (response.success) {
        this.companyOptions = response.data.map((company) => (
          {
            value: company.id,
            label: company.socialReason
          }
        ))
      }
    })
  }
  
  async getUnity() {
    this.unityOptions = [];
    const value = this.formUser.company.value
    if (!value) {
      return
    }
    await this._unityService.getByPath('', `?page=1&index=999999&companyId=${value}`).then(
      (res: any) => {
        if (res.data.length != 0) {
          res.data.forEach((element: any) => {
            this.unityOptions.push({
              label: element.name,
              value: element.id
            })
          })
        }
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    );
  }

  resolveUser(data: any, columns: any, id: string) {
    let user: Array<Object> = [];
    if (data) {
      data.forEach((x: any) => {
        let obj: any = {};
        columns.forEach((y: any) => {
          if (!y.retrive) {
            obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
          } else {
            obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
          }
        });
        user.push(obj);
      });
    }
    this.userOptionsId[id] = user
  }

  resolveResource(data: any, columns: any, id: string) {
    let resource: Array<Object> = [];
    if (data) {
      data.forEach((x: any) => {
        let obj: any = {};
        columns.forEach((y: any) => {
          if (!y.retrive) {
            obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
          } else {
            obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
          }
        });
        resource.push(obj);
      });
    }
    this.modulesRecursosTableOptions[id] = resource

  }

  async getResourceById(id: Guid) {
    //@ts-ignore
    this.userOptionsId[id] = [];
    //@ts-ignore
    this.modulesRecursosTableOptions[id] = [];
    await this._groupsService.getById(id).then(
        (response: any) => {
          if (response.data.length != 0) {          
            this.resolveResource(response.data[0].policies, this.resolveRecursosTable,id.toString())
            this.resolveUser(response.data[0].users, this.resolveUserTable,id.toString())
          }           
        }
    ).catch(
        (err) => {
            this._toastService.fireError(err);
        }
    );    
  }
}