export interface ReportFilter {
   title: string;
   content: string;
}

export interface HeaderMapping {
   [key: string]: {
      title: string;
      width: string;
   };
}

export abstract class AbstractReport {
   abstract reportFilters: ReportFilter[][];
   abstract headerMapping: HeaderMapping;
   abstract receivedData: any;
   abstract reportDescription: string;
   abstract sumRecords: number;

   abstract getData(paramPage?: number[]): Promise<void>;
}