import { Injectable } from '@angular/core';
import { HttpResponsePageable } from '../services.types';
import { QueryParams } from '../interfaces/services.types';
import { BaseAuthService } from '../auth/base-auth.service';
import { HttpAuthService } from '@services/auth/http-auth.service';
import { CompanyGet, HttpResponse } from './company.type';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseAuthService {
  constructor(HttpAuthService: HttpAuthService) {
      super(HttpAuthService, 'v1/shared/company');
  }

  override getPagination<T>(queryString?: QueryParams) {
      return super.getPagination(queryString) as Promise<HttpResponsePageable<T>>    
  }  

  override patchActive(id: string, value: string) {
    return super.patchActive(id, value) as Promise<HttpResponse>
  }

  override getResume() {
    return super.getResume() as Promise<HttpResponsePageable<CompanyGet>>
  }
}