import { IHttpService } from "../../admin/data/services/interfaces/IHttpService";
import { HttpClient } from "@angular/common/http";
import type { RequestOptions } from "../types/http.types";
import {
   getHeaders,
   getPathWithQuery
} from "@app/modules_new/helpers/http.helper";
import { Guid } from "@app/util/guid";

/**
 * Abstração para comunicações Rest com APIs
 */
export abstract class ApiRestService implements IHttpService {
   constructor(protected http: HttpClient, protected baseURL: string) {}

   get<T>(options?: RequestOptions & { withoutTenant?: boolean }) {
      const { _path, _headers } = this.getOptions(options);

      const url = `${this.baseURL}${_path}`;
      const requestOptions = { headers: _headers };

      return this.http.get<T>(url, requestOptions);
   }

   post<T>(body: object, options?: RequestOptions) {
      const { _path, _headers } = this.getOptions(options);

      const url = `${this.baseURL}${_path}`;
      const requestOptions = { headers: _headers };

      return this.http.post<T>(url, body, requestOptions);
   }

   put<T>(body: object, id: Guid, options?: RequestOptions) {
      const { _path, _headers } = this.getOptions(options);

      const url = `${this.baseURL}${_path}${id}`;
      const requestOptions = { headers: _headers };

      return this.http.put<T>(url, body, requestOptions);
   }

   delete<T>(id?: Guid, options?: RequestOptions & { body?: object }) {
      const body = options?.body;
      const { _path, _headers } = this.getOptions(options);

      let normalizePath = "";
      if (id) {
         normalizePath = `${_path}/${id}`;
      } else {
         normalizePath = `${_path}`;
      }

      const url = `${this.baseURL}${normalizePath}`;

      const requestOptions = body
         ? { headers: _headers, body: body }
         : { headers: _headers };

      return this.http.delete<T>(url, requestOptions);
   }

   patch<T>(body: object, id: Guid, options?: RequestOptions) {
      const { _path, _headers } = this.getOptions(options);

      const url = `${this.baseURL}${_path}/${id}`;
      const requestOptions = { headers: _headers };

      return this.http.patch<T>(url, body, requestOptions);
   }

   /**
    * Método usado para gerar valores de QueriesParam e Header
    *
    * @param options Tipo contendo valores para gerar Query e Header
    * @returns retorna `{ _path: string, _headers: HttpHeaders }` para ser usado em requisições
    */
   private getOptions(options?: RequestOptions, tenantId?: string) {
      const _path: string = getPathWithQuery(options?.query, options?.path);
      let _headers = getHeaders(options?.headers);

      _headers = _headers.set("Content-Type", "application/json");

      if (tenantId || _headers.has("tenantId")) {
         _headers = _headers.set(
            "tenantId",
            tenantId || _headers.get("tenantId")!
         );
      } else {
         if (localStorage.getItem("TenantId")) {
            _headers = _headers.set(
               "tenantId",
               localStorage.getItem("TenantId") as string
            );
         }
         if (sessionStorage.getItem("TenantId")) {
            _headers = _headers.set(
               "tenantId",
               sessionStorage.getItem("TenantId") as string
            );
         }
      }

      return { _path, _headers };
   }
}
