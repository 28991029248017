<mat-menu #menu="matMenu" xPosition="before">
    <div class="menu">
        <button
            *ngFor="let action of actions"
            mat-menu-item
            class="actions-button flex w-full items-center"
            (click)="select(action.event)"
        >
            <span class="flex w-full items-center py-4 gap-4">
                <ui-svg-selector [svg]="action.svg"></ui-svg-selector>
                <p class="text-white w-max">{{ action.label | translate }}</p>
            </span>
        </button>
    </div>
</mat-menu>

<button
    style="padding: 0px !important"
    class="svg-button"
    [matMenuTriggerFor]="menu"
>
    <svg
        width="6"
        height="20"
        viewBox="0 0 6 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            class="svg"
            d="M0.500001 2.5C0.500001 2.00555 0.656399 1.5222 0.949416 1.11108C1.24243 0.699953 1.65891 0.379521 2.14618 0.190302C2.63345 0.0010832 3.16963 -0.0484251 3.68691 0.0480379C4.20419 0.144501 4.67935 0.382603 5.05229 0.732234C5.42523 1.08187 5.6792 1.52732 5.7821 2.01228C5.88499 2.49723 5.83218 2.99989 5.63035 3.45671C5.42851 3.91352 5.08672 4.30397 4.64819 4.57867C4.20966 4.85338 3.69408 5 3.16667 5C2.45967 4.99924 1.78186 4.73561 1.28194 4.26693C0.782021 3.79825 0.50081 3.16281 0.500001 2.5ZM3.16667 7.5C2.63925 7.5 2.12368 7.64662 1.68515 7.92133C1.24662 8.19603 0.904823 8.58648 0.702989 9.04329C0.501155 9.50011 0.448347 10.0028 0.55124 10.4877C0.654134 10.9727 0.90811 11.4181 1.28105 11.7678C1.65399 12.1174 2.12914 12.3555 2.64643 12.452C3.16371 12.5484 3.69989 12.4989 4.18716 12.3097C4.67443 12.1205 5.0909 11.8 5.38392 11.3889C5.67694 10.9778 5.83333 10.4945 5.83333 10C5.83253 9.33719 5.55131 8.70175 5.05139 8.23307C4.55147 7.76439 3.87366 7.50076 3.16667 7.5ZM3.16667 15C2.63925 15 2.12368 15.1466 1.68515 15.4213C1.24662 15.696 0.904823 16.0865 0.702989 16.5433C0.501155 17.0001 0.448347 17.5028 0.55124 17.9877C0.654134 18.4727 0.90811 18.9181 1.28105 19.2678C1.65399 19.6174 2.12914 19.8555 2.64643 19.952C3.16371 20.0484 3.69989 19.9989 4.18716 19.8097C4.67443 19.6205 5.0909 19.3 5.38392 18.8889C5.67694 18.4778 5.83333 17.9945 5.83333 17.5C5.83253 16.8372 5.55131 16.2017 5.05139 15.7331C4.55147 15.2644 3.87366 15.0008 3.16667 15Z"
        />
    </svg>
</button>
