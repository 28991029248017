import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RegistrationReportComponent } from '@app/components/form/registration-report/registration-report.component';
import { DefaultFormats, LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { TenantAuthService } from '@app/services/auth/tenantAuth/tenant-auth.service';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Util } from '@app/util/util';
import { HeaderMapping } from '@app/modules/reports/reports-model';
import { UnitMeasureService } from '@app/services/stock/unit-measure.service';

@Component({
  selector: 'app-unit-measure-report',
  templateUrl: './unit-measure-report.component.html',
  styleUrls: ['./unit-measure-report.component.scss']
})
export class UnitMeasureReportComponent implements OnInit {

  filter = new UntypedFormGroup({
    description: new UntypedFormControl(null),
    isActive: new UntypedFormControl(null)
  })

  isActiveOptions: Array<{ label: string, value: string | null | boolean }> = [{ label: 'Todos', value: 'todos' }, { label: 'Somente Ativos', value: true }, { label: 'Somente Inativos', value: false }]

  index: number = 50
  page: number = 1
  sumRecords: number = 0

  rawData: Array<any> = []
  resolvedUnitMeasure: Array<any> = []

  resolveProducts: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: 'Unidade de Medida',
      method: '',
      retrive: 'name',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'formatField',
      fieldName: 'createdAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'formatField',
      fieldName: 'updatedAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'formatField',
      fieldName: 'isActive',
      type: 'boolean'
    }
  ]

  constructor(
    private _unitMeasureService: UnitMeasureService,
    private _util: Util,
    private _http: HttpClient,
    private _localeDate: LocalaDatePipe,
    private _tenantAuth: TenantAuthService,) { }

  ngOnInit(): void {
    this.get();
    this.filter.controls['isActive'].setValue('todos')
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    const filterObject = {
      isActive: this.filter.controls['isActive'].value === 'todos' ? null : JSON.parse(this.filter.controls['isActive'].value),
      numberRegistry: this.index,
      page: this.page,
      search: this.filter.controls['description'].value ?? null,
    };

    await this._unitMeasureService.getPagination<any>(filterObject).then(
      (res) => {
        if (res.data.length != 0) {
          this.sumRecords = res.sumRecords;
          this.rawData = res.data;
          this.resolve(res.data, this.resolveProducts);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  private resolve(data: any, columns: any) {
    this.resolvedUnitMeasure = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat, y.options);
        } else {
          obj[y.label] = x[y.retrive.toString()];
        }
      });
      this.resolvedUnitMeasure.push(obj);
    });
  }

  async generatePdf() {

    const reportComponent = new RegistrationReportComponent(this._http, this._localeDate, this._tenantAuth);

    const headerMapping: HeaderMapping = {
      name: { title: 'Unidade de Medida', width: 'auto' },
      isActive: { title: 'Ativo', width: 'auto' },
      createdAt: { title: 'Criado em', width: 'auto' },
      updatedAt: { title: 'Atualizado em', width: 'auto' }
    };

    const resolvedHeaderMapping: HeaderMapping = {
      ['Unidade de Medida']: { title: 'Unidade de Medida', width: '*' },
      ['Criado em']: { title: 'Criado em', width: 'auto' },
      ['Alterado em']: { title: 'Atualizado em', width: 'auto' },
      ['Ativo']: { title: 'Ativo', width: 'auto' },
    };

    let resolvedProductsToPrint: Array<Object> = []
    this.resolvedUnitMeasure.forEach((x) => {
      const obj = { ...x }
      obj['Ativo'] = obj['Ativo'].includes('Sim') ? 'Ativo' : 'Inativo'
      resolvedProductsToPrint.push(obj)
    })

    var filters = [];
    var filterActive = 'Todos';
    if (this.filter.controls['isActive'].value != 'todos') {
      if (this.filter.controls['isActive'].value == 'true') {
        filterActive = 'Somente Ativos';
      } else {
        filterActive = 'Somente Inativos';
      }
    }

    var obj = {
      title: 'Ativos: ', content: filterActive
    }

    filters.push(obj);

    if (this.filter.controls['description'].value) {
      var obj2 = {
        title: 'Descrição: ', content: this.filter.controls['description'].value
      }

      filters.push(obj2);
    }

    reportComponent.reportFilters = [filters];
    reportComponent.headerMapping = resolvedHeaderMapping;
    reportComponent.receivedData = resolvedProductsToPrint;
    reportComponent.reportDescription = 'Relatório de Cadastro de Unidades de Medida';
    reportComponent.sumRecords = this.sumRecords;

    await reportComponent.ngOnInit();
  }

  resetFilter() {
    this.filter.controls['description'].reset();
    this.filter.controls['isActive'].reset('todos')
  }
}