import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { Guid } from '@app/util/guid';
import { BearerPutObject, HttpResponse } from './bearer.types';
import { QueryParams } from '../interfaces/services.types';
import { BearerPostObject } from '@app/modules/registers/components/financial/bearer/bearer-list/bearer.model';

@Injectable({
  providedIn: 'root'
})
export class BearerService extends BaseService{

  constructor(private http: HttpClient, httpService: HttpService) { 
    super(httpService, 'v1/lab/financial/bearer')
  }

  override getPagination<T>(queryString?: QueryParams) {
    return super.getPagination<T>(queryString) as Promise<HttpResponsePageable<T>>
  }

  override post(body: BearerPostObject) {
    return super.post(body) as Promise<HttpResponse>
  }

  override put(body: BearerPutObject, id?: Guid | undefined, path?: string | undefined) {
      return super.put(body, id, path) as Promise<HttpResponse>
  }

  override patchActive(id: string, value: string) {
    return super.patchActive(id, value) as Promise<HttpResponse>
  }

  override delete(id: Guid) {
    return super.delete(id) as Promise<HttpResponse>
  }
}
