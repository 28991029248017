<div class="flex flex-column p-7">
    <div class="flex flex-column gap-8">
        <div class="flex flex-between align-center" #fullContent>
            <h1>{{ "filter.title" | translate }}</h1>
            <div class="flex gp-12 hover">
                <mat-icon
                    class="icon"
                    (click)="clearFiltersEmit()"
                    matTooltip="{{ 'filter.refresh' | translate }}"
                    [matTooltipPosition]="'above'"
                    >refresh</mat-icon
                >
                <mat-icon
                    class="icon"
                    (click)="close()"
                    matTooltip="{{ 'filter.close' | translate }}"
                    [matTooltipPosition]="'above'"
                    >close</mat-icon
                >
            </div>
        </div>
        <div class="flex flex-column gap-8">
            <div *ngFor="let input of formFilter; let i = index">
                <forms-input-text
                    class="w-full"
                    *ngIf="input.type === 'text'"
                    [type]="type"
                    [placeholder]="input.placeholder"
                    [form]="input.form"
                    [mask]="input.mask || mask"
                    (keyup.enter)="input.click && search()"
                ></forms-input-text>

                <forms-input-text
                    class="w-full"
                    *ngIf="input.type === 'date'"
                    type="date"
                    [placeholder]="input.placeholder"
                    [form]="input.form"
                    (keyup.enter)="input.click && search()"
                ></forms-input-text>

                <forms-input-text
                    class="w-full"
                    *ngIf="input.type === 'datetime-local'"
                    type="datetime-local"
                    [placeholder]="input.placeholder"
                    [form]="input.form"
                    (keyup.enter)="input.click && search()"
                ></forms-input-text>

                <form-select-prop
                    *ngIf="input.type === 'option'"
                    class="min-w-[260px] flex w-100"
                    [placeholder]="input.placeholder"
                    [options]="input.options"
                    [form]="input.form"
                    (change)="
                        searchMask(
                            input.options,
                            input.form.value,
                            input.placeholder
                        )
                    "
                    [mask]="mask"
                    (valueChanged)="
                        input.onValueChanged ? input.onValueChanged() : null
                    "
                >
                </form-select-prop>
                <forms-input-select
                    *ngIf="input.type === 'select'"
                    class="w-100"
                    [placeholder]="input.placeholder"
                    [options]="input.options"
                    [form]="input.form"
                    (valueChanged)="
                        input.onValueChanged ? input.onValueChanged() : null
                    "
                ></forms-input-select>

                <forms-input-search
                    *ngIf="input.type === 'search'"
                    class="w-100"
                    [placeholder]="input.placeholder"
                    [options]="input.options"
                    [form]="input.form"
                ></forms-input-search>

                <forms-input-search
                    *ngIf="input.type === 'searchGET'"
                    class="min-w-[260px] flex w-100"
                    [placeholder]="input.placeholder"
                    [options]="input.options"
                    [form]="input.form"
                    (search)="searchGET($event, input.placeholder, i)"
                ></forms-input-search>

                <div class="flex flex-center">
                    <forms-toggle
                        class="flex flex-between"
                        *ngIf="input.type === 'toggle'"
                        [placeholder]="input.placeholder | translate"
                        [form]="input.form"
                        [largeStyle]="true"
                    >
                    </forms-toggle>
                </div>
            </div>
        </div>
        <div class="flex flex-center">
            <button (click)="search()" class="btn">
                {{ "default.search" | translate }}
            </button>
        </div>
    </div>
</div>
