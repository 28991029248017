// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
   production: false,
   baseURL: "https://homolog.chronos.concentsistemas.com.br/",
   baseAuthURL: "https://staging-auth-dev.quaent.com.br/",
   NG_DEPLOY_AWS_ACCESS_KEY_ID: "",
   NG_DEPLOY_AWS_SECRET_ACCESS_KEY: "",
   NG_DEPLOY_AWS_BUCKET: "",
   NG_DEPLOY_AWS_REGION: "",
   service: "chronos"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
