import { Injectable } from "@angular/core";
import { IHttpService } from "../../admin/data/services/interfaces/IHttpService";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { ApiRestService } from "./api.service";

@Injectable({
   providedIn: "root"
})
export abstract class ApiBaseService
   extends ApiRestService
   implements IHttpService
{
   constructor(private _http: HttpClient) {
      super(_http, environment.baseURL);
   }
}
