<app-list [title]="'doctor.title'" [urlButton]="'Cadastros/Atendimento/Solicitantes/Novo'"> 
  <div class="headerFilter">
    <app-filter (beforeClose)="getDoctors()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <select-prop [form]="form.filter" [placeholder]="'doctor.filter.select-filter'" [options]="'doctor.filter.options' | translate" (change)="resetSelect()"></select-prop>
        <input-prop #autoFocus *ngIf="form.filter.value === 'Name'" [placeholder]="'doctor.filter.type-here'" [form]="form.search"></input-prop>
        <input-prop *ngIf="form.filter.value === 'Registration Number'" [placeholder]="'doctor.filter.type-here'" [form]="form.search" type="number"></input-prop>
        <input-prop *ngIf="form.filter.value === 'CPF'" mask="000.000.000-00" [placeholder]="'doctor.filter.type-here'" [form]="form.search"></input-prop>
        <input-prop *ngIf="form.filter.value === 'Phone'" mask="(00) 0000-0000" [placeholder]="'doctor.filter.type-here'" [form]="form.search"></input-prop>
        <input-prop *ngIf="form.filter.value === 'Email'" [placeholder]="'doctor.filter.type-here'" [form]="form.search"></input-prop>
        <checkbox-prop [label]="'doctor.filter.isActive'" class="flex flex-center" [gap]="1" [form]="form.isActive"></checkbox-prop>
      </div>
      <app-button class="queryBtn" (click)="getDoctors()" [placeholder]="'doctor.filter.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="list">
    <app-table [Pagination]="true" [SortPagination]="true" (sortPage)="getSortedPage($event)" [ClickInItem]="ActionsExtra" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="registerDoctors" [Actions]="['Editar', 'Excluir']" (parentFun)="actions($event)" (changePage)="getDoctors($event)"></app-table>
  </div>
</app-list>