<div class="search">
    <input
        [style.width]="widthImput"
        id="placeholder"
        type="text"
        (keyup.enter)="onEnterPressed()"
        [placeholder]="placeholder"
        [formControl]="form"
        (keyup)="onChange($event)"
    />
    <div class="search-icon" id="icon">
        <ui-svg-selector
            (click)="onClick()"
            [fill]="'#BFBFBF'"
            [hover]="'#404040'"
            [svg]="'icon-filter'"
        ></ui-svg-selector>
    </div>
</div>
