import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import Token from "../../util/Token";
import { HttpAuthService } from '../auth/http-auth.service';
import { objectAlterTenant } from '@app/components/dashboard/header/header.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class RefreshService {
    public baseURL: string | undefined;

    constructor(private http: HttpClient, private HttpAuth: HttpAuthService) {
        this.baseURL = environment.baseAuthURL;
    }
    // login, register
    refreshToken() {

      const request = this.http.post( this.baseURL + 'api/v1/public/user/refresh', {
          id: Token.sub,
          accessToken : localStorage.getItem('token'),
          refreshToken : localStorage.getItem('refreshToken')
      }, httpOptions);
      return request;
    }

    alterTenant(obj: objectAlterTenant) {
      return this.HttpAuth.post(obj, 'api/v1/public/user/refresh')
    }
}
