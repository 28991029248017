import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { ExamsSupportLaboratoryGetAll, ExamsSupportLaboratoryGetById, ExamsSupportLaboratoryPostobject, ExamsSupportLaboratoryPutObject } from './exams-support-laboratory';
import { HttpResponsePageable } from '../services.types';
import { Guid } from '@app/util/guid';

@Injectable({
  providedIn: 'root'
})
export class ExamsSupportLaboratoryService extends BaseService {

constructor(private http: HttpClient, httpService: HttpService) {
  super(httpService, 'v1/lab/parameters/examssupportlaboratory')
 }

 override getAll() {
  return super.getAll() as Promise<HttpResponsePageable<ExamsSupportLaboratoryGetAll>>
}

override post(body: ExamsSupportLaboratoryPostobject) {
  return super.post(body) as Promise< HttpResponsePageable<ExamsSupportLaboratoryPostobject>>
}

override put(body: object, id?: Guid | undefined, path?: string | undefined) {
  return super.put(body, id, path) as Promise<HttpResponsePageable<ExamsSupportLaboratoryPutObject>>
}

override getById(id: Guid) {
  return super.getById(id) as Promise<HttpResponsePageable<ExamsSupportLaboratoryGetById>>
}

}
