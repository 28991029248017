<content-filter-layout
    [title]="'ip_address.title' | translate"
    [createButton]="{
        title: 'default.register' | translate,
        action: true
    }"
    [formFilter]="formFilter"
    (clearFilters)="resetFilter()"
    (searchFilters)="getIpAdress(true)"
    (clickButton)="openModal()"
>
    <forms-table
        #ipAddress
        [actions]="actions"
        [rows]="data"
        [columns]="columns"
    >
        <ng-container ngProjectAs="[pagination]">
            <forms-pagination
                (changePage)="changePage($event)"
                [totalPerPage]="30"
                [amount]="total | async"
                [page]="page"
            ></forms-pagination>
        </ng-container>
    </forms-table>
</content-filter-layout>
