import { Injectable } from '@angular/core';

import { HttpResponsePageable } from '../services.types';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Guid } from '@app/util/guid';
import { ExamsAdditionalInformationLaboratoryServiceGetAll, ExamsAdditionalInformationLaboratoryServiceGetById, ExamsAdditionalInformationLaboratoryServicePost } from './exams-additional-information-laboratory';


@Injectable({
  providedIn: 'root'
})
export class ExamsAdditionalInformationLaboratoryService extends BaseService {

constructor(private http: HttpClient, httpService: HttpService) { 
  super(httpService, 'v1/lab/parameters/examsadditionalinformationlaboratory')
 }

override getAll() {
  return super.getAll() as Promise<HttpResponsePageable<ExamsAdditionalInformationLaboratoryServiceGetAll>>
}

override post(body: ExamsAdditionalInformationLaboratoryServicePost) {
  return super.post(body) as Promise< HttpResponsePageable<ExamsAdditionalInformationLaboratoryServicePost>>
}

override put(body: object, id?: Guid | undefined, path?: string | undefined) {
  return super.put(body, id, path) as Promise<HttpResponsePageable<ExamsAdditionalInformationLaboratoryServiceGetById>>
}

}
