import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { CollectionPlaceOriginPost, CollectionPlaceOriginPut } from './collection-place-origin.service.module';
import { QueryParams } from '../interfaces/services.types';
import { Guid } from '@app/util/guid';

@Injectable({
  providedIn: 'root'
})
export class CollectionPlaceOriginService extends BaseService {

constructor(
  private http: HttpClient,
  httpService: HttpService
) {
  super(httpService, 'v1/lab/attendance/collectionplaceorigin');
 }

 override post(body: CollectionPlaceOriginPost) {
  return super.post(body);
}

override put(body: Object, id?: Guid | undefined, path?: string | undefined) {
  return super.put(body, id, path) as Promise<HttpResponsePageable<CollectionPlaceOriginPut>>
}

override getPagination<CollectionPlaceOriginPagination>(queryObject?: QueryParams | undefined){
  return super.getPagination(queryObject) as Promise<HttpResponsePageable<CollectionPlaceOriginPagination>> 
}

}
