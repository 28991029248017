<div class="contentPageManual">
  <div class="flex align-center flex-direction-column-mobile gap-4">
    <div class="flex gap-1 align-center flex-direction-column-mobile">
      <button (click)="back()" class="btn-reverse">Voltar</button>
      <h1>Manual de Exames</h1>
    </div>
    <input-search [noLabelUp]="true" class="w-25 ml-10" (changeForm)="getExamName()" [options]="exams" placeholder="Selecione um exame" [form]="form.exam"></input-search>        
  </div> 
  <div>    
  </div>
</div>
<div style="padding: 32px;padding-top: 5px;">
  <div *ngIf="form.exam.value" style="background-color: white;" class="contentPageManual">
    <div class="flex align-center flex-between w-100 flex-direction-column-mobile">
      <div class="title">
        <h1>Exame : {{examName}}</h1>
      </div>    
    </div>
    <div>
      <h2 class="mt-20">Material</h2>
      <div style="border-radius: 0px;" class="box flex align-center flex-between w-100 flex-direction-column-mobile">{{materials}}</div>
    </div>
    <div class="align-center w-100 mt-20 flex-direction-column-mobile">    
      <h2>Descrição</h2>
      <div style="border-radius: 0px;" class="box flex align-center flex-between w-100 flex-direction-column-mobile">{{description}}</div>
    </div>
    <div class="w-100">
      <div style="gap: 2px;" class="flex flex-between w-100 mt-20">
        <div style="min-width: 50%;">
          <h2>Preparação</h2>
          <div style="border-radius: 0px;flex-wrap: wrap;" class="box flex">
            {{customerPreparation}}
          </div>
        </div>
        <div style="min-width: 50%;">
          <h2>Coleta</h2>
          <div style="border-radius: 0px;flex-wrap: wrap;" class="box flex">
            {{collect}}
          </div>
        </div>        
      </div>
      <div style="gap: 2px;" class="flex flex-between w-100 mt-20">
        <div style="min-width: 50%;">          
          <h2>Distribuição/Triagem</h2>
          <div style="border-radius: 0px;flex-wrap: wrap;" class="box flex">
            {{distribuition}}
          </div>          
        </div>  
        <div style="min-width: 50%;">          
          <h2>Estabilidade da Amostra</h2>
          <div style="border-radius: 0px;flex-wrap: wrap;" class="box flex">
            {{estabilish}}
          </div>          
        </div>    
      </div>
      <div style="gap: 2px;" class="flex flex-between w-100 mt-20">
        <div style="min-width: 50%;">
          <h2>Rejeição</h2>
          <div style="border-radius: 0px;flex-wrap: wrap;" class="box">
            {{reject}}
          </div>
        </div>
        <div style="min-width: 50%;">
          <h2>Adicional</h2>
          <div style="border-radius: 0px;flex-wrap: wrap;" class="box">
            {{additional}}
          </div>
        </div>        
      </div>
      <div style="gap: 2px;" class="flex flex-between w-100 mt-20">
        <div style="min-width: 50%;">
          <h2>Interpretação</h2>
          <div style="border-radius: 0px;flex-wrap: wrap;" class="box">
            {{interpretation}}
          </div>
        </div>
        <div style="min-width: 50%;">
          <h2>Patologias</h2>
          <div style="border-radius: 0px;flex-wrap: wrap;" class="box">
            {{pathologies}}
          </div>
        </div>        
      </div>
      <div style="gap: 2px;" class="flex flex-between w-100 mt-20">
        <div style="min-width: 50%;">
          <h2>Links</h2>
          <div style="border-radius: 0px;flex-wrap: wrap;" class="box">            
            <ng-container *ngFor="let link of linksHtml">
              <a href="{{link}}" target="_blank">{{link}}</a><span>&nbsp;</span>
            </ng-container>
          </div>
        </div>        
      </div>
    </div>
  </div>
</div>