import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { HttpResponse, HttpResponsePageable } from '../services.types';
import { QueryParams } from '../interfaces/services.types';
import { BaseAuthService } from '../auth/base-auth.service';
import { HttpAuthService } from '../auth/http-auth.service';
import { Guid } from '@app/util/guid';

@Injectable({
  providedIn: 'root'
})
export class EndpointRedirectService extends BaseAuthService {

  constructor(private http: HttpClient, httpService: HttpAuthService) {
    super(httpService, 'v1/admin/endpointredirect');
  }

  override getPagination(queryObject?: QueryParams) {
    return super.getPagination(queryObject) as Promise<HttpResponsePageable<any>>
  }

  override delete(id?: Guid | undefined): Promise<HttpResponse> {
    return super.delete(id) as Promise<HttpResponse>
  }

  override post(body: Object): Promise<HttpResponse> {
    return super.post(body) as Promise<HttpResponse>
  }

  override put(body: Object, id: Guid): Promise<HttpResponse> {
    return super.put(body, id) as Promise<HttpResponse>
  }
  override patchActive(id: string, value: string): Promise<HttpResponse> {
    return super.patchActive(id, value) as Promise<HttpResponse>
  }
}
