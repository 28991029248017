<div class="contentPage">
  <div class="flex flex-between align-center headerResponsive">
    <div class="flex gap-2 align-center titleResponsive">
      <h1>{{'unituParameters.title' | translate}}</h1>
      <app-button (click)="navigateToSaveUnityParameters()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="searchParameters()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus (keyup.enter)="searchParameters()" [form]="filter.search" [placeholder]="'unituParameters.search'"></input-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" [form]="filter.active" [label]="'unituParameters.isActive'"></checkbox-prop>
      </div>
      <app-button class="queryBtn" (click)="searchParameters()" [placeholder]="'unituParameters.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [Dropdown]="true" [pageNumber]="page" [indexNumber]="index" [Itens]="unityParameters" [Actions]="['Editar']" (parentFun)="actions($event)" (changePage)="getParameters($event)">
      <ng-template let-rowDetail #header>
        <div class="w-100 flex align-center">
          <div class="flex align-center flex-between w-100">
            <h2>{{'unituParameters.titleParameters' | translate}}</h2>
            <app-button (click)="openDialogModalAdditionalParameter(rowDetail.id)" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
          </div>
        </div>
        <div class="mt-32">
          <app-table [sumRecords]="additionalParameters[rowDetail.id]?.sumRecords" [pageNumber]="additionalParameters[rowDetail.id]?.page" [indexNumber]="additionalParameters[rowDetail.id]?.index" [ClickInItem]="ActionsExtra" *ngIf="additionalParameters[rowDetail.id]?.itens" [noShadow]="true" [Itens]="additionalParameters[rowDetail.id]?.itens" (parentFun)="actionsCollection($event, rowDetail.id)"></app-table>
        </div>
      </ng-template>
    </app-table>
  </div>
</div>

<ng-template #dialogModalAdditionalParameter let-emit>
  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1>{{"unituParameters.newParameters" | translate}}</h1>
      <svg (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="mt-32">
      <input-user-multiple-new [form]="additionalParameter.name" [hideOptions]="hideParameters" [options]="additionalDatas"></input-user-multiple-new>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="postAdditionalParameter()" [placeholder]="'global.save'" [class]="'btn'"></app-button>
    </div>
  </ng-container>
</ng-template>