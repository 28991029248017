import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { ValidatorErrorMessage } from '@app/util/Validators';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Subscription, first, skip, skipUntil, skipWhile } from 'rxjs';
import { dateMask, datetimeMask, formatDateToString, formatDatetimeToString, timeMask } from './input-prop.model';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'input-prop',
  templateUrl: './input-prop.component.html',
  styleUrls: ['./input-prop.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class InputPropComponent implements OnInit, AfterContentInit {

  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() changeValueEmitter: EventEmitter<string> = new EventEmitter();

  @Input() onFocus: boolean = false;
  @Input() type: string = 'text';
  @Input() readonly: boolean = false;
  @Input() form: UntypedFormControl = new UntypedFormControl({ value: null, disabled: this.readonly });
  @Input() placeholder: string = '';
  @Input() placeholderMenu: boolean = false;
  @Input() label: string = '';
  @Input() mask: any = '';
  @Input() className: string = '';
  @Input() requiredInput?: string;
  @Input() pattern: string = '';
  @Input() prefix = '';
  @Input() noLabelUp = false;
  @Input() labelAfter: string = '';

  @Input() tabindexcomponent: string = '';

  @Input() max?: string;
  @Input() min?: string;
  @Input() maxlength?: string;
  @Input() minlength?: string;
  @Input() value?: string;

  @Input() disableFutureDate: boolean = false;
  @Input() warningCustom!: string

  @ViewChild("input") input!: ElementRef;

  PropValue: String = '';
  adapter = false;
  classe: string = '';

  private _dateForm: UntypedFormControl = new UntypedFormControl()

  masks = {
    dateMask,
    datetimeMask,
    timeMask
  }

  constructor(private _dateAdapter: DateAdapter<Date>) {
    this._dateAdapter.setLocale('pt');
  }

  ngAfterContentInit() {
    setTimeout(() => {
      if (this.onFocus) {
        this.input.nativeElement.focus();
      }
    });

    if (this._isDateInput()) {

      let handleDateInitialChange: Subscription | null
      let skipValue = 1

      if (this.form.value) {
        if (this.type === "datetime-local") {
          const dateFormatted = formatDatetimeToString(this.form.value)
          this._dateForm.setValue(dateFormatted)
        }

        if (this.type === "date") {
          const dateFormatted = formatDateToString(this.form.value)
          this._dateForm.setValue(dateFormatted)
        }
      } else {
        skipValue = 2 // _dateForm.valueChanges execute 1 time before this
        handleDateInitialChange = this.form.valueChanges.pipe(first()).subscribe(response => {
          if (this.form.value) {
            setTimeout(() => {
              if (this.type === "datetime-local") {
                const dateFormatted = formatDatetimeToString(this.form.value)
                this._dateForm.setValue(dateFormatted)
              }

              if (this.type === "date") {
                const dateFormatted = formatDateToString(this.form.value)
                this._dateForm.setValue(dateFormatted)
              }
            }, 100)
          }
        })
      }

      this._dateForm.valueChanges
        .pipe(skip(skipValue))
        .subscribe(response => {
          if (response) {
            if (this.form.value === null) {
              handleDateInitialChange?.unsubscribe()
              handleDateInitialChange = null
            }
            this.form.setValue(response)
          }
          if (handleDateInitialChange) {
            handleDateInitialChange.unsubscribe()
            handleDateInitialChange = null
          }
        })

      if (this.type === "datetime-local" || this.type === "date") {
        this.form.valueChanges.subscribe(response => {
          if (response == null){
            this._dateForm.setValue(response);
          }
        })
      }
    }
  }

  ngOnInit(): void {
    this.classe = this.className + ' input-row';

    if (this.prefix) {
      this.form.setValue('111');
    }

    if (!this.form) {
      this.form = new UntypedFormControl();
      if (this.value) {
        this.form.setValue(this.value + 'Z');
      }
    }
  }

  onFocusOut() {
    const formInUse = this._isDateInput() ? this._dateForm : this.form
    if (this.form.invalid && (formInUse.dirty || formInUse.touched)) {
      const casting = this.form.errors as ValidatorErrorMessage
      if (this.form.errors && casting.type === 'focusout') {
        this._dateForm.setValue(null)
        SwAlSetttings.warningMessage(casting.message, casting.title);
      }
    }
  }

  validate(s: String) {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    return s.match(rgx);
  }

  onChange(value: String) {
    this.form.setValue(value);
  }

  onDateChange(event: any) {
    let value = ""

    if (this.type === "datetime-local") {
      value = new Date(event.value?._d).toLocaleDateString()
    }

    if (this.type === "date") {
      value = new Date(event.value?._d).toLocaleString()
    }

    if (!this.form.value) {
      this.form.setValue(new Date(event.value._d))
    } else {
      this._dateForm.setValue(value)
    }
  }

  dateFilter = (d: Date | null): boolean => {
    const date = (d || new Date());
    return date <= new Date()
  };

  focusInput() {
    if (this.input) this.input.nativeElement.focus()
  }

  showError(errorName: string): boolean {
    if (this.form.touched && this.form.errors?.[errorName] && this.requiredInput) {
      return true
    }
    return false
  }

  OnDown() {
    try {
      if (this.form.value) {
        let date = new Date(this.form.value + 'Z');
        setTimeout(() => {
          this.form.setValue(date.toISOString());
        })
      }
    }
    catch (e) {

    }
  }

  private _isDateInput() {
    return this.type === 'time' || this.type === 'date' || this.type === 'datetime-local'
  }

  get dateForm() {
    return this._dateForm
  }

  changeValueEmit(){
    this.changeValueEmitter.emit(this.form.value);
  }
}
