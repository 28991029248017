<section class="flex login flex-center p-relative">
    <div class="content-login">
        <div
            class="flex flex-wrap flex-direction-column login-column resposniveLogin"
        >
            <div class="col resposniveLogin">
                <div class="flex flex-center">
                    <img
                        src="/assets/images/background/logo_cnt2.svg"
                        alt="Logo AE"
                    />
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="538"
                    height="523"
                    viewBox="0 0 538 523"
                    class="ilustrationLogin w-full"
                >
                    <path
                        class="rotate2x"
                        d="M232.781 110.012C234.914 115.124 237.758 119.845 241.135 124.07L236.158 134.471L246.183 141.997L256.208 149.523L264.811 141.855C269.824 143.914 275.156 145.298 280.666 145.937L284.506 156.8L296.913 155.025L309.319 153.25L309.959 141.748C315.079 139.619 319.807 136.779 324.037 133.406L334.453 138.376L341.99 128.365L349.526 118.354L341.848 109.764C343.909 104.758 345.296 99.4334 345.936 93.931L356.814 90.0971L355.037 77.7078L353.259 65.3186L341.741 64.6796C339.608 59.5677 336.764 54.8463 333.387 50.6219L338.364 40.2206L328.339 32.6948L318.314 25.169L309.711 32.8368C304.698 30.7778 299.365 29.3934 293.855 28.7544L290.016 17.8916L277.609 19.6666L265.202 21.4415L264.562 32.9433C259.443 35.0733 254.715 37.9132 250.484 41.2856L240.068 36.3157L232.496 46.291L224.96 56.3018L232.638 64.8926C230.576 69.898 229.19 75.2229 228.55 80.7253L217.672 84.5592L219.449 96.9484L221.227 109.338L232.781 110.012ZM281.057 43.9126C305.053 40.5046 327.308 57.1538 330.756 81.1158C334.169 105.078 317.496 127.3 293.5 130.744C269.504 134.187 247.214 117.502 243.801 93.505C240.353 69.543 257.061 47.3205 281.057 43.9126Z"
                        fill="#7D2800"
                    />
                    <path
                        class="rotate"
                        d="M349.206 34.5053C350.024 36.4222 351.055 38.1972 352.335 39.7592L350.486 43.6641L354.254 46.4685L358.023 49.273L361.258 46.3975C363.142 47.1785 365.133 47.6755 367.195 47.924L368.652 51.9709L373.309 51.2964L377.966 50.6219L378.215 46.3265C380.135 45.51 381.912 44.4806 383.476 43.2026L387.387 45.0485L390.195 41.2856L393.004 37.5227L390.124 34.2923C390.906 32.4108 391.404 30.4229 391.653 28.3639L395.706 26.9084L395.03 22.258L394.355 17.6076L390.053 17.3591C389.236 15.4422 388.205 13.6672 386.925 12.1052L388.773 8.20033L385.005 5.39589L381.237 2.59145L378.002 5.46688C376.118 4.6859 374.127 4.18891 372.065 3.94042L370.643 0L365.986 0.674486L361.329 1.34897L361.08 5.64438C359.16 6.46086 357.383 7.49034 355.819 8.76831L351.908 6.92235L349.135 10.6498L346.327 14.4127L349.206 17.6431C348.424 19.5246 347.927 21.5125 347.678 23.5715L343.625 25.027L344.301 29.6774L344.976 34.3278L349.206 34.5053ZM367.301 9.76229C376.295 8.48432 384.614 14.7322 385.894 23.7135C387.174 32.6948 380.917 41.0016 371.923 42.2796C362.929 43.5576 354.61 37.3097 353.33 28.3284C352.05 19.3471 358.307 11.0403 367.301 9.76229Z"
                        fill="#7D2800"
                    />
                    <path
                        d="M139.498 217.823H137.116V466.389H139.498V217.823Z"
                        fill="#F3F3F3"
                    />
                    <path
                        d="M163.849 187.223H138.289V193.719H163.849V187.223Z"
                        fill="white"
                    />
                    <path
                        d="M158.766 199.186H138.289V205.683H158.766V199.186Z"
                        fill="white"
                    />
                    <path
                        d="M166.338 481.121H139.498V489.748H166.338V481.121Z"
                        fill="white"
                    />
                    <path
                        d="M236.798 131.17C236.798 131.17 288.701 192.619 255.781 232.414C222.862 272.208 213.797 233.195 213.797 233.195C213.797 233.195 194.102 236.851 228.23 187.081C244.334 163.616 236.798 131.17 236.798 131.17Z"
                        fill="url(#paint0_linear_4_775)"
                    />
                    <path
                        d="M350.593 83.2102C350.593 83.2102 389.165 140.045 322.011 205.505C254.857 270.966 259.87 310.05 259.87 310.05C259.87 310.05 191.223 175.863 350.593 83.2102Z"
                        fill="#CB4000"
                    />
                    <path
                        d="M247.32 285.343C219.165 287.899 251.835 211.824 295.846 191.98C339.857 172.136 377.078 173.804 402.816 152.86C402.816 152.86 386.036 272.847 247.32 285.343Z"
                        fill="#FFB999"
                    />
                    <path
                        d="M72.4507 217.823H70.0688V466.389H72.4507V217.823Z"
                        fill="#F3F3F3"
                    />
                    <path
                        d="M241.135 217.823H238.753V466.389H241.135V217.823Z"
                        fill="#F3F3F3"
                    />
                    <path
                        d="M116.852 187.223H69.4289V193.719H116.852V187.223Z"
                        fill="white"
                    />
                    <path
                        d="M99.7529 199.186H69.4289V205.683H99.7529V199.186Z"
                        fill="white"
                    />
                    <path
                        d="M270.535 187.223H241.135V193.719H270.535V187.223Z"
                        fill="white"
                    />
                    <path
                        d="M275.405 199.186H241.135V205.683H275.405V199.186Z"
                        fill="white"
                    />
                    <path
                        d="M98.0821 481.121H71.2419V489.748H98.0821V481.121Z"
                        fill="white"
                    />
                    <path
                        d="M267.975 481.121H241.135V489.748H267.975V481.121Z"
                        fill="white"
                    />
                    <path
                        d="M527.951 262.162C496.027 246.471 463.464 245.158 431.824 254.494C382.623 268.978 332.605 308.666 279.564 350.271C263.531 362.873 249.062 370.719 235.696 374.872C221.76 379.238 209.033 379.522 197.089 376.966C170.391 371.216 147.354 351.159 122.505 329.539C99.5041 309.518 75.6857 288.786 46.9969 278.563C30.0041 272.492 14.7887 269.688 1.3509 268.978C0.924297 272.741 0.462148 276.539 0 280.338C12.9046 280.728 27.3734 283.355 43.3353 289.212C69.9977 298.975 93.0696 318.783 115.324 338.13C132.21 352.791 149.274 367.666 167.938 377.641C181.731 385.025 196.377 389.746 212.446 389.746C229.226 389.746 247.534 384.599 268.01 371.997C273.876 368.376 279.955 364.151 286.212 359.217C371.461 292.336 443.2 237.064 520.024 271.924C531.862 277.285 543.806 284.81 555.964 294.75V279.912C546.721 272.67 537.372 266.777 527.951 262.162Z"
                        fill="#CB4000"
                    />
                    <path
                        d="M21.2944 502.492H275.049C286.816 502.492 296.344 492.978 296.344 481.228V132.199C296.344 120.449 286.816 110.935 275.049 110.935H21.2944C9.52737 110.935 0 120.449 0 132.199V481.192C0 492.943 9.52737 502.492 21.2944 502.492Z"
                        fill="#7D2800"
                    />
                    <path
                        d="M278.818 67.8391H31.8527C23.9606 67.8391 17.5261 74.2289 17.5261 82.1453V485.949H278.818V67.8391Z"
                        fill="white"
                    />
                    <path
                        d="M254.466 146.115H177.429V155.842H254.466V146.115Z"
                        fill="#FE6344"
                    />
                    <path
                        d="M248.138 186.904H177.429V196.63H248.138V186.904Z"
                        fill="#FE6344"
                    />
                    <path
                        d="M238.611 166.491H177.429V176.218H238.611V166.491Z"
                        fill="#FE6344"
                    />
                    <path
                        d="M94.7759 208.523C86.0307 208.523 77.5698 206.712 69.6777 203.127C48.0634 193.293 34.0568 171.639 34.0568 147.925C34.0568 114.521 61.288 87.3281 94.7404 87.3281C128.193 87.3281 155.424 114.521 155.424 147.925C155.424 171.639 141.453 193.293 119.803 203.127C111.982 206.712 103.521 208.523 94.7759 208.523ZM94.7759 90.5231C63.0655 90.5231 37.2918 116.26 37.2918 147.925C37.2918 170.396 50.5163 190.915 71.0286 200.216C78.4941 203.624 86.4928 205.328 94.7759 205.328C103.059 205.328 111.058 203.624 118.523 200.216C139 190.915 152.26 170.396 152.26 147.925C152.26 116.26 126.486 90.5231 94.7759 90.5231Z"
                        fill="#FE6344"
                    />
                    <path
                        d="M122.611 232.911H34.0923V241.572H122.611V232.911Z"
                        fill="#FFDCCC"
                    />
                    <path
                        d="M122.611 256.269H34.0923V264.931H122.611V256.269Z"
                        fill="#FFDCCC"
                    />
                    <path
                        d="M122.611 279.592H34.0923V288.254H122.611V279.592Z"
                        fill="#FFDCCC"
                    />
                    <path
                        d="M155.46 232.911H140.671V241.572H155.46V232.911Z"
                        fill="#FE6344"
                    />
                    <path
                        d="M155.46 256.269H140.671V264.931H155.46V256.269Z"
                        fill="#FE6344"
                    />
                    <path
                        d="M155.46 279.592H140.671V288.254H155.46V279.592Z"
                        fill="#FE6344"
                    />
                    <path
                        d="M556 377.073V389.107C543.309 391.699 531.542 392.551 518.85 392.551C468.903 392.551 425.176 372.068 380.099 349.774C377.682 348.567 375.229 347.36 372.811 346.153C313.941 316.937 253.08 286.692 171.99 294.963C120.194 300.253 87.7015 321.304 59.0128 339.905C39.9225 352.295 21.5788 363.548 0 369.157V357.974C18.5215 352.685 34.8389 342.142 52.8627 330.463C82.6179 311.186 116.355 289.354 170.853 283.781C255.213 275.155 317.567 306.146 377.86 336.071C383.903 339.053 389.875 342.035 395.883 344.946C446.649 369.583 496.703 389.675 556 377.073Z"
                        fill="#CB4000"
                    />
                    <path
                        d="M326.846 492.375H203.132C188.45 492.375 176.576 480.482 176.576 465.857V250.731C176.576 236.07 188.485 224.213 203.132 224.213H326.881C341.563 224.213 353.437 236.105 353.437 250.731V465.857C353.437 480.482 341.528 492.375 326.846 492.375Z"
                        fill="white"
                    />
                    <path
                        d="M332.746 264.043C328.409 251.938 316.82 244.519 304.626 244.874C301.676 244.98 298.69 245.513 295.739 246.542C286.852 249.702 280.453 256.802 277.857 265.144C276.044 270.93 276.044 277.356 278.248 283.497C278.462 284.1 278.711 284.704 278.959 285.307C280.133 287.97 281.626 290.384 283.439 292.514C291.011 301.424 303.595 305.152 315.256 300.998C330.293 295.638 338.114 279.095 332.746 264.043ZM312.803 294.218C304.058 297.342 294.637 294.537 288.949 287.863C287.598 286.266 286.46 284.455 285.572 282.468C285.394 282.042 285.216 281.58 285.038 281.119C283.403 276.504 283.403 271.711 284.754 267.345C286.709 261.061 291.509 255.772 298.156 253.394C300.36 252.613 302.6 252.187 304.804 252.116C313.94 251.832 322.65 257.405 325.885 266.493C329.973 277.782 324.072 290.171 312.803 294.218Z"
                        fill="#FFDCCC"
                    />
                    <path
                        d="M284.754 267.345L277.822 265.179C280.453 256.837 286.816 249.737 295.704 246.578C298.654 245.513 301.641 244.98 304.591 244.909L304.805 252.151C302.601 252.222 300.361 252.613 298.157 253.429C291.509 255.772 286.71 261.061 284.754 267.345Z"
                        fill="#7D2800"
                    />
                    <path
                        d="M285.572 282.432L278.924 285.307C278.675 284.704 278.427 284.1 278.213 283.497C276.009 277.356 276.009 270.93 277.822 265.144L284.754 267.309C283.404 271.64 283.368 276.468 285.039 281.083C285.217 281.545 285.394 282.006 285.572 282.432Z"
                        fill="#CB4000"
                    />
                    <path
                        d="M283.439 292.514C281.626 290.384 280.097 287.97 278.96 285.307L285.608 282.432C286.461 284.42 287.634 286.23 288.985 287.828L283.439 292.514Z"
                        fill="#FE6344"
                    />
                    <path
                        d="M305.231 431.813H289.625V482.896H305.231V431.813Z"
                        fill="#CB4000"
                    />
                    <path
                        d="M282.017 446.155H266.411V482.932H282.017V446.155Z"
                        fill="#CB4000"
                    />
                    <path
                        d="M327.734 408.135H312.128V482.932H327.734V408.135Z"
                        fill="#CB4000"
                    />
                    <path
                        d="M200.181 426.559L198.83 425.529L224.249 392.48L255.639 394.184L279.315 356.519L304.805 353.892L320.127 323.647L321.655 324.428L305.907 355.525L280.311 358.152L256.563 395.923L225.066 394.219L200.181 426.559Z"
                        fill="#FE6344"
                    />
                    <path
                        d="M248.28 262.233H203.914C201.106 262.233 198.83 259.961 198.83 257.157C198.83 254.352 201.106 252.08 203.914 252.08H248.28C251.089 252.08 253.364 254.352 253.364 257.157C253.364 259.961 251.089 262.233 248.28 262.233Z"
                        fill="#FE6344"
                    />
                    <path
                        d="M248.28 279.912H203.914C201.106 279.912 198.83 277.64 198.83 274.835C198.83 272.031 201.106 269.759 203.914 269.759H248.28C251.089 269.759 253.364 272.031 253.364 274.835C253.364 277.64 251.089 279.912 248.28 279.912Z"
                        fill="#FFDCCC"
                    />
                    <path
                        d="M248.28 297.59H203.914C201.106 297.59 198.83 295.318 198.83 292.514C198.83 289.709 201.106 287.437 203.914 287.437H248.28C251.089 287.437 253.364 289.709 253.364 292.514C253.364 295.318 251.089 297.59 248.28 297.59Z"
                        fill="#FFDCCC"
                    />
                    <!-- <path
                        d="M144.759 39.6527H180.131V33.2628H144.759V39.6527Z"
                        fill="#7D2800"
                    /> -->
                    <!-- <path
                        d="M144.759 31.4523H180.131V30.2809H144.759V31.4523Z"
                        fill="#7D2800"
                    /> -->
                    <path
                        d="M102.704 114.698H86.8839V181.117H102.704V114.698Z"
                        fill="#FE6344"
                    />
                    <path
                        d="M61.5368 155.806V140.009H128.015V155.806H61.5368Z"
                        fill="#FE6344"
                    />
                    <path
                        d="M248.671 330.569C248.671 330.569 251.018 325.493 251.409 324.747C251.8 324.002 254.039 322.759 256.706 322.085C259.301 321.446 262.82 320.132 263.211 320.381C263.602 320.629 264.1 320.665 264.562 321.801C265.024 322.901 264.847 324.108 264.847 324.108C264.847 324.108 266.091 325.209 265.7 326.664C265.7 326.664 266.802 327.765 266.126 329.362C266.126 329.362 267.193 330.462 266.589 331.563C266.589 331.563 264.527 332.734 263.851 333.409C263.211 334.083 258.732 336.213 254.075 337.633C254.11 337.633 249.276 337.491 248.671 330.569Z"
                        fill="#8E4F3A"
                    />
                    <path
                        d="M254.111 337.633C254.111 337.633 248.387 338.627 246.432 337.527C244.476 336.391 246.752 330.569 246.752 330.569L250.378 328.368L254.111 337.633Z"
                        fill="#8E4F3A"
                    />
                    <path
                        d="M256.243 328.12C256.243 328.12 256.172 330.924 255.746 331.705C255.319 332.486 261.043 333.693 261.043 333.693C261.043 333.693 259.621 328.333 256.243 328.12Z"
                        fill="#5E3527"
                    />
                    <path
                        d="M262.5 320.629C262.5 320.629 256.243 323.682 256.599 324.747C256.954 325.812 257.737 325.99 257.737 325.99L255.71 328.404C255.71 328.404 255.284 330.746 257.985 330.533C257.985 330.533 257.346 332.699 259.941 332.166C259.941 332.166 260.154 333.764 261.398 333.728C262.642 333.693 266.588 331.563 266.588 331.563C266.588 331.563 267.299 330.711 266.197 329.327C266.197 329.327 266.979 327.836 265.806 326.629C265.806 326.629 266.162 324.712 264.882 323.434C264.882 323.434 265.024 321.268 263.176 320.381C263.14 320.345 262.749 320.487 262.5 320.629Z"
                        fill="#7A4432"
                    />
                    <path
                        d="M251.586 324.641C251.586 324.641 256.386 324.747 258.945 325.954C259.514 326.238 260.047 326.593 260.012 327.232C259.976 327.871 259.016 328.652 256.919 328.723C254.857 328.794 252.191 329.823 252.191 329.823C252.191 329.823 250.058 329.22 251.586 324.641Z"
                        fill="#8E4F3A"
                    />
                    <path
                        d="M264.882 323.434C264.882 323.434 257.737 325.883 257.239 326.487C256.741 327.09 255.817 324.463 256.279 324.002C256.741 323.54 262.287 320.558 262.927 320.381C263.531 320.203 265.629 321.623 264.882 323.434Z"
                        fill="#8E4F3A"
                    />
                    <path
                        d="M261.576 320.665C261.576 320.665 272.703 318.393 272.952 319.742C273.165 321.091 272.099 321.339 262.962 324.002C254.075 326.558 261.576 320.665 261.576 320.665Z"
                        fill="#8E4F3A"
                    />
                    <path
                        d="M187.099 390.882C187.099 390.882 197.089 434.901 199.364 448.32C201.319 459.715 202.848 471.607 201.923 479.843C200.786 490.032 186.317 542.89 186.921 545.907C187.526 548.889 169.68 547.079 169.68 547.079C169.68 547.079 182.797 487.405 180.593 476.435C178.958 468.306 164.703 414.312 162.534 417.081C160.365 419.85 157.628 468.058 156.028 476.045C154.393 484.032 149.452 547.256 149.452 547.256C149.452 547.256 138.36 548.889 135.374 546.369C135.374 546.369 134.023 492.41 135.054 477.394C135.445 471.678 128.335 408.845 137.436 383.569C142.271 370.222 187.099 390.882 187.099 390.882Z"
                        fill="#2D3133"
                    />
                    <path
                        d="M252.369 339.799C252.369 339.799 217.743 347.467 210.846 345.763C207.647 344.982 203.452 340.58 199.47 335.432C198.155 333.728 196.875 331.953 195.631 330.178C190.725 323.114 186.921 316.369 186.921 316.369C186.921 316.369 172.452 299.436 184.077 291.91C184.077 291.91 188.272 290.348 192.147 294.466C196.022 298.62 200.572 307.246 201.568 309.482C202.599 311.683 216.783 332.131 217.53 332.592C218.276 333.054 224.462 331.421 229.439 330.995C237.544 330.32 249.702 327.161 249.702 327.161L252.369 339.799Z"
                        fill="#DBDFF0"
                    />
                    <path
                        d="M173.235 288.147C173.235 288.147 188.13 291.236 189.872 292.798C191.792 294.466 195.169 310.725 195.382 315.269C195.595 319.813 193.605 338.734 191.685 345.798C189.765 352.863 190.121 387.581 191.329 397.343C191.329 397.343 161.361 405.827 131.89 402.029C131.89 402.029 136.831 365.5 135.658 355.809C134.13 343.42 130.504 314.914 130.504 314.914C130.504 314.914 133.099 295.034 135.729 292.833C138.36 290.632 156.419 288.183 156.419 288.183H173.235V288.147Z"
                        fill="#BCC0CF"
                    />
                    <path
                        d="M167.084 242.211C167.084 242.211 185.002 235.608 183.864 259.357C182.762 283.106 178.567 285.52 166.764 282.219C154.997 278.918 154.002 263.901 152.473 256.695C150.945 249.453 161.219 243.241 167.084 242.211Z"
                        fill="#8E4F3A"
                    />
                    <path
                        d="M175.581 291.378C175.581 291.378 171.884 293.827 157.913 287.65C157.913 287.65 158.304 278.101 155.566 269.155C152.829 260.209 174.301 281.048 174.301 281.048C174.088 281.19 174.017 282.113 174.159 283.355C174.265 284.42 174.514 285.733 174.834 287.047C175.652 290.029 175.581 288.751 175.581 291.378Z"
                        fill="#8E4F3A"
                    />
                    <path
                        d="M154.962 268.445C154.962 268.445 154.926 262.091 157.557 261.523C160.188 260.955 160.543 258.825 160.543 258.825L162.072 259.18C162.072 259.18 161.787 258.08 164.312 256.589C166.836 255.098 181.909 256.411 183.793 254.246C185.677 252.08 182.94 239.229 178.816 237.951C174.692 236.673 165.165 236.815 156.419 239.265C156.419 239.265 149.807 240.933 149.309 248.708C148.99 253.749 152.829 265.002 154.962 268.445Z"
                        fill="#2D3133"
                    />
                    <path
                        d="M174.87 287.047C161.005 284.704 158.908 276.823 158.872 276.717C164.276 283.674 174.159 283.355 174.159 283.355C174.301 284.42 174.514 285.733 174.87 287.047Z"
                        fill="#633029"
                    />
                    <path
                        d="M174.265 294.466C174.265 294.466 176.79 298.194 178.318 307.743C179.847 317.292 179.598 333.764 179.598 333.764L176.079 334.19C176.079 334.19 170.746 300.608 171.67 294.786L174.265 294.466Z"
                        fill="#434343"
                    />
                    <path
                        d="M160.543 258.825C159.761 256.731 155.282 256.979 155.744 260.316C156.206 263.617 159.903 268.729 161.361 267.771C162.854 266.812 161.645 261.7 160.543 258.825Z"
                        fill="#8E4F3A"
                    />
                    <path
                        d="M190.085 292.762C190.085 292.762 198.546 303.945 195.418 333.16C189.765 385.983 204.59 470.756 204.59 470.756L189.303 475.548C189.303 475.548 174.692 367.169 178.425 327.836C180.131 309.908 185.144 303.945 177.216 288.999C177.181 288.999 184.895 289.39 190.085 292.762Z"
                        fill="#DBDFF0"
                    />
                    <path
                        d="M152.331 288.751C152.331 288.751 176.541 313.174 178.425 331.669C180.309 350.165 183.402 475.548 183.402 475.548L125.775 472.211C125.775 472.211 131.072 406.04 132.352 397.769C133.632 389.498 136.334 341.964 130.504 319.919C127.375 308.062 131.108 293.046 138.253 291.413C145.435 289.709 152.331 288.751 152.331 288.751Z"
                        fill="#DBDFF0"
                    />
                    <path
                        d="M144.155 291.91C144.155 291.91 153.469 294.466 148.35 311.577C144.332 324.96 143.799 327.836 143.799 327.836L122.292 322.475C122.292 322.475 128.584 293.082 138.893 291.484C138.858 291.484 142.128 291.023 144.155 291.91Z"
                        fill="#DBDFF0"
                    />
                    <path
                        d="M214.65 382.362L172.488 425.92L144.51 398.905L185.57 354.283L214.65 382.362Z"
                        fill="#616161"
                    />
                    <path
                        d="M210.669 383.214L173.234 421.909L148.35 397.911L184.824 358.258L210.669 383.214Z"
                        fill="#E0CEC7"
                    />
                    <path
                        d="M202.065 376.754L204.696 374.02L194.636 364.329L191.614 366.672L202.065 376.754Z"
                        fill="#616161"
                    />
                    <path
                        d="M157.913 285.023C157.913 285.023 165.2 289.425 172.452 290.419L168.613 296.525L156.419 288.147L157.913 285.023Z"
                        fill="#A7AAB8"
                    />
                    <path
                        d="M174.87 287.047C174.87 287.047 174.088 289.567 172.452 290.384L176.079 295.425L175.936 288.751L174.87 287.047Z"
                        fill="#A7AAB8"
                    />
                    <path
                        d="M172.452 290.384L175.296 294.36C175.296 294.36 173.483 296.632 169.608 294.928L172.452 290.384Z"
                        fill="#434343"
                    />
                    <path
                        d="M199.506 335.432C198.191 333.728 196.911 331.953 195.667 330.178C195.667 330.178 196.129 317.292 195.738 313.6C195.738 313.6 199.932 327.481 199.506 335.432Z"
                        fill="#A7AAB8"
                    />
                    <path
                        d="M140.102 326.913C140.102 326.913 137.294 345.159 137.471 346.331C137.685 347.502 124.744 342.674 140.102 326.913Z"
                        fill="#A7AAB8"
                    />
                    <path
                        d="M190.867 320.381H184.077V330.746H190.867V320.381Z"
                        fill="#C6CAD9"
                    />
                    <path
                        d="M160.65 370.009C160.65 370.009 161.254 371.251 162.321 371.642C164.098 372.352 166.942 371.216 168.08 373.772C169.182 376.328 162.072 382.327 160.294 382.611C158.517 382.93 155.993 380.019 154.322 374.375C152.651 368.802 160.65 370.009 160.65 370.009Z"
                        fill="#8E4F3A"
                    />
                    <path
                        d="M167.582 372.991C167.582 372.991 170.639 375.724 173.661 379.203C176.327 382.256 178.887 386.338 178.425 387.119C177.394 388.823 174.941 384.989 172.097 382.149C169.466 379.522 169.928 379.984 169.928 379.984C169.928 379.984 169.928 379.7 172.204 383.143C174.657 386.835 176.79 390.882 176.221 391.77C175.936 392.196 174.799 393.261 174.23 391.521C174.728 392.338 174.799 392.16 174.479 392.409C172.772 393.687 168.329 388.894 166.551 386.977C167.902 388.468 168.72 389.711 167.191 389.817C165.449 389.959 159.441 382.859 157.059 380.516C154.429 377.961 167.582 372.991 167.582 372.991Z"
                        fill="#8E4F3A"
                    />
                    <path
                        d="M145.257 293.046C147.212 293.792 147.959 297.271 147.141 307.281C146.323 317.292 142.804 321.907 141.488 324.25C140.173 326.558 132.921 340.864 134.236 342.745C135.552 344.627 147.852 356.767 148.563 357.726C149.274 358.72 163.032 371.145 163.032 371.145L156.028 380.907C156.028 380.907 126.344 357.761 124.034 355.454C121.723 353.147 114.791 348.283 117.35 340.225C119.945 332.024 124.638 312.819 125.455 311.293C126.273 309.802 132.352 288.076 145.257 293.046Z"
                        fill="#DBDFF0"
                    />
                    <path
                        d="M402.816 265.676C402.816 265.676 391.262 289.993 399.225 291.449C410.139 293.437 414.547 273.309 414.547 273.309L402.816 265.676Z"
                        fill="#163342"
                    />
                    <path
                        d="M339.75 326.096C339.75 326.096 336.124 324.96 335.84 322.83C335.591 321.02 333.956 318.854 333.458 318.18C332.782 317.292 331.751 313.778 330.614 314.594C329.476 315.411 332.036 320.452 332.356 321.623C332.676 322.795 327.734 320.558 326.526 320.878C325.317 321.197 322.082 329.575 325.672 330.675C329.227 331.811 333.778 330.675 334.382 330.995C334.986 331.314 336.942 331.918 336.942 331.918L339.75 326.096Z"
                        fill="#EEA886"
                    />
                    <path
                        d="M327.414 320.949C327.414 320.949 316.252 318.215 315.363 318.641C314.474 319.067 314.368 319.706 315.398 319.99C316.429 320.274 326.099 322.937 326.099 322.937C326.099 322.937 312.377 321.907 311.559 322.12C311.097 322.227 310.813 323.398 311.346 323.611C311.879 323.824 325.424 325.599 325.424 325.599C325.424 325.599 312.59 324.676 311.95 325.031C311.417 325.351 311.417 326.487 312.341 326.629C313.301 326.771 325.139 328.297 325.139 328.297C325.139 328.297 315.754 328.191 315.398 328.829C315.185 329.22 314.901 330.214 317.318 330.178C319.736 330.143 325.459 330.604 325.459 330.604L327.414 320.949Z"
                        fill="#EEA886"
                    />
                    <path
                        d="M435.379 365.216C435.379 365.216 442.916 375.014 444.978 386.658C447.04 398.302 443.769 437.351 443.84 443.102C443.947 448.853 449.279 463.159 448.213 473.986C447.146 484.813 442.916 538.737 442.916 538.737L431.327 536.429C431.327 536.429 432.002 481.476 431.327 476.932C430.651 472.389 429.3 455.81 429.051 453.929C428.767 452.047 412.059 402.881 412.059 394.503C412.059 386.125 417.142 351.549 435.379 365.216Z"
                        fill="#2D3133"
                    />
                    <path
                        d="M397.412 369.05C397.412 369.05 385.005 416.335 383.512 422.903C382.055 429.47 379.921 434.866 379.921 437.635C379.921 444.096 382.339 454.461 382.339 454.461L398.088 529.72H413.09C413.09 529.72 404.735 484.565 404.487 475.725C404.273 466.886 397.448 454.319 398.763 450.556C400.114 446.829 420.342 403.626 420.342 403.626C420.342 403.626 435.699 355.312 397.412 369.05Z"
                        fill="#2D3133"
                    />
                    <path
                        d="M402.567 288.68C402.567 288.68 397.448 289.177 393.573 293.295C389.698 297.448 386.072 304.016 385.076 306.217C384.081 308.417 369.861 328.865 369.15 329.327C368.403 329.788 362.218 328.155 357.241 327.729C349.135 327.055 337.013 322.937 337.013 322.937L335.2 332.095C335.2 332.095 365.026 341.716 371.923 340.012C380.348 337.953 399.723 313.103 399.723 313.103C399.723 313.103 414.156 296.206 402.567 288.68Z"
                        fill="#DBDFF0"
                    />
                    <path
                        d="M411.49 285.094C411.49 285.094 404.877 288.218 402.567 288.68C400.256 289.141 391.333 300.714 389.769 304.939C388.205 309.163 389.769 315.517 393.004 322.972C396.239 330.427 393.181 332.912 394.675 340.331C396.132 347.786 395.67 375.618 395.386 379.771C395.101 383.924 438.472 390.172 447.04 382.646C447.04 382.646 441.245 357.548 434.384 350.378C432.891 348.816 433.069 338.485 434.064 335.929C435.059 333.338 446.506 314.133 446.506 314.133C446.506 314.133 447.36 294.644 442.347 290.774C438.01 287.402 426.243 286.23 424.11 285.485C421.977 284.775 415.791 284.455 411.49 285.094Z"
                        fill="#2F4563"
                    />
                    <path
                        d="M408.326 277.462C408.326 277.462 409.641 289.603 409.357 293.543C409.357 293.543 416.253 300.43 424.074 285.485C424.074 285.485 420.768 279.344 423.257 270.93C425.745 262.517 408.326 277.462 408.326 277.462Z"
                        fill="#EEA886"
                    />
                    <path
                        d="M389.058 325.706C389.058 325.706 387.387 314.701 389.164 307.991C389.164 307.991 389.164 313.742 392.328 321.943L389.058 325.706Z"
                        fill="#AAADBA"
                    />
                    <path
                        d="M428.66 254.672C428.66 254.672 430.189 252.861 430.758 254.672C431.327 256.518 429.478 261.949 429.265 262.765C429.265 262.765 428.34 265.818 427.985 265.428C427.594 265.073 428.66 254.672 428.66 254.672Z"
                        fill="#EEA987"
                    />
                    <path
                        d="M402.105 254.92C402.105 254.92 400.576 253.11 400.007 254.92C399.438 256.766 401.287 262.197 401.5 263.014C401.5 263.014 402.425 266.067 402.78 265.676C403.171 265.286 402.105 254.92 402.105 254.92Z"
                        fill="#EEA987"
                    />
                    <path
                        d="M413.018 237.241C413.018 237.241 399.083 239.371 401.109 261.31C403.136 283.248 415.471 281.509 416.289 281.474C417.107 281.438 428.625 281.935 429.62 255.169C429.62 255.204 430.082 235.395 413.018 237.241Z"
                        fill="#EEA987"
                    />
                    <path
                        d="M426.954 253.891C426.954 253.891 411.49 258.47 404.06 257.015C404.06 257.015 403.598 259.748 402.531 259.925C401.465 260.103 400.931 254.707 400.931 254.707C400.931 254.707 400.576 236.851 416.716 236.709C432.891 236.531 429.585 256.021 429.585 256.021L429.371 259.357C429.371 259.322 427.061 260.245 426.954 253.891Z"
                        fill="#163342"
                    />
                    <path
                        d="M421.764 279.415C421.764 279.415 416.893 284.029 409.357 279.805C409.357 279.805 411.561 283.497 415.791 283.426C420.022 283.319 421.764 279.415 421.764 279.415Z"
                        fill="#A77860"
                    />
                    <path
                        d="M429.371 286.585C429.371 286.585 424.643 300.572 411.632 299.543C398.621 298.513 405.482 287.757 405.482 287.757C405.482 287.757 408.717 285.911 409.641 285.733C410.565 285.556 424.039 285.378 424.039 285.378L429.371 286.585Z"
                        fill="#EEA886"
                    />
                    <path
                        d="M405.482 287.721C405.482 287.721 398.656 306.536 398.656 325.706C398.656 344.875 398.123 376.115 395.741 385.983C393.359 395.852 386.605 426.346 386.605 426.346L381.308 423.613C381.308 423.613 394.07 374.908 395.03 371.393C395.99 367.879 394.284 330.782 393.395 327.694C392.471 324.605 388.596 310.76 389.2 306.926C389.947 302.099 394.497 294.928 401.82 288.786L405.482 287.721Z"
                        fill="#DBDFF0"
                    />
                    <path
                        d="M426.812 285.982C426.812 285.982 422.581 299.507 420.448 316.866C418.315 334.19 427.025 414.702 432.891 432.949L455.572 429.292C455.572 429.292 450.701 372.387 444.053 362.057C437.441 351.691 435.308 342.532 442.454 320.949C449.599 299.33 439.325 288.644 439.325 288.644C439.325 288.644 428.803 286.195 426.812 285.982Z"
                        fill="#DBDFF0"
                    />
                    <path
                        d="M402.318 343.384L429.833 390.989L460.406 373.346L434.099 325.067L402.318 343.384Z"
                        fill="#616161"
                    />
                    <path
                        d="M405.66 345.017L430.082 387.297L457.242 371.642L433.851 328.759L405.66 345.017Z"
                        fill="#E0CEC7"
                    />
                    <path
                        d="M414.654 341.254L412.947 338.272L423.932 331.953L426.065 334.651L414.654 341.254Z"
                        fill="#616161"
                    />
                    <path
                        d="M415.969 374.979C415.969 374.979 417.213 374.979 417.782 374.73C417.782 374.73 418.742 374.162 419.453 373.807C419.666 373.701 419.88 373.594 420.022 373.559C420.591 373.381 421.87 373.097 423.079 372.281C424.288 371.429 428.589 366.459 428.589 366.459L426.563 363.37L425.07 360.211C425.07 360.211 416.04 360.779 414.796 361.453C414.689 361.524 414.547 361.595 414.44 361.666C413.445 362.305 413.018 363.228 412.521 363.619C412.343 363.761 410.708 365.358 410.779 366.033C410.85 366.991 411.276 367.595 411.845 367.914C411.845 367.914 412.094 369.76 412.983 370.612C412.983 370.612 413.161 372.316 414.583 373.062C414.618 373.026 414.974 374.766 415.969 374.979Z"
                        fill="#EEA886"
                    />
                    <path
                        d="M434.597 288.68C434.597 288.68 442.951 286.301 447.751 295.496C452.55 304.69 452.514 308.595 453.154 313.103C453.759 317.328 456.496 328.901 456.745 330.107C457.029 331.314 463.179 345.55 460.371 350.52C457.527 355.525 428.625 370.257 428.803 371.358C428.98 372.458 423.79 360.211 423.79 360.211C423.79 360.211 435.486 353.714 437.406 351.691C440.534 348.425 447.075 344.911 445.724 340.899C444.125 336.142 439.396 320.132 438.259 317.789C437.192 315.446 427.558 291.662 434.597 288.68Z"
                        fill="#DBDFF0"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_4_775"
                            x1="215.483"
                            y1="137.64"
                            x2="248.174"
                            y2="245.475"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#40D5E6" />
                            <stop offset="1" stop-color="#5577FF" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div class="col">
                <div class="flex items-center justify-center">
                    <h1>Seja bem vindo ao <strong>Concent</strong></h1>
                </div>
                <div style="margin-bottom: 32px; margin-top: 32px">
                    <p>Faça seu login para acessar o sistema</p>
                </div>
                <form
                    *ngIf="!multipleTenant && !multipleUnitys"
                    action=""
                    class="gap-[32px]"
                    style="display: flex; flex-direction: column"
                >
                    <forms-input-text
                        [form]="form.controls.username"
                        type="text"
                        placeholder="Email ou Código de Acesso"
                        (keydown.enter)="Login()"
                    ></forms-input-text>
                    <forms-input-text
                        type="password"
                        [form]="form.controls.password"
                        placeholder="Senha"
                        (keydown.enter)="Login()"
                    ></forms-input-text>
                    <forms-input-text
                        type="text"
                        [form]="form.controls.validation"
                        *ngIf="validation"
                        placeholder="Código de validação"
                    ></forms-input-text>
                    <div class="flex flex-end" *ngIf="validation">
                        <a
                            class="pointer"
                            style="
                                font-size: calc(12px * var(--proportional));
                                line-height: calc(12px * var(--proportional));
                            "
                            (click)="password()"
                            >Reenviar Token</a
                        >
                    </div>
                    <div class="flex flex-center">
                        <forms-button class="btn" (click)="Login()"
                            >Login</forms-button
                        >
                    </div>
                    <a
                        *ngIf="!isAres"
                        class="pointer"
                        (click)="navigateToForgotPassword()"
                        >Esqueci minha senha</a
                    >
                </form>

                <div class="mt-[32px]">
                    <forms-input-search
                        *ngIf="multipleTenant"
                        placeholder="Escolha o grupo empresarial desejado para Login"
                        (valueChanged)="
                            getCompany();
                            form.controls.chooseCompany.setValue('')
                        "
                        [options]="tenantsOfUser"
                        [form]="form.controls.chooseTenant"
                    ></forms-input-search>
                    <div
                        class="mt-[32px]"
                        *ngIf="
                            (multipleTenant &&
                                form.controls.chooseTenant.value &&
                                multipleUnitys) ||
                            (multipleUnitys && !multipleTenant)
                        "
                    >
                        <forms-input-search
                            placeholder="Escolha a empresa"
                            (changeForm)="
                                filterUnitys();
                                form.controls.chooseUnity.setValue('')
                            "
                            [options]="companyOptions"
                            [form]="form.controls.chooseCompany"
                        ></forms-input-search>
                    </div>
                    <div
                        class="mt-[32px]"
                        *ngIf="
                            multipleUnitys && form.controls.chooseCompany.value
                        "
                    >
                        <forms-input-search
                            placeholder="Escolha a unidade"
                            [options]="
                                form.controls.chooseCompany.value
                                    ? unityOptions
                                    : []
                            "
                            [form]="form.controls.chooseUnity"
                        ></forms-input-search>
                    </div>
                    <div
                        *ngIf="multipleTenant || multipleUnitys"
                        class="flex flex-center mt-default gp-12"
                    >
                        <forms-button
                            class="btn btn-reverse"
                            (click)="
                                clearForms();
                                multipleTenant = false;
                                multipleUnitys = false
                            "
                        >
                            Voltar
                        </forms-button>
                        <forms-button
                            class="btn"
                            (click)="Login(form.controls.chooseTenant.value)"
                        >
                            Login
                        </forms-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
