import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';

@Injectable({
  providedIn: 'root'
})

export class RequisitionTagsService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/report/requisition/tag')
  }
  override getByPath(queryString: string, path: string, headers?: String[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<any>>
  }
}