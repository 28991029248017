import { AsyncPipe } from "@angular/common";
import { Component, inject, Input, OnInit, ViewChild } from "@angular/core";
import { PageableComponent } from "@app/modules_new/common/components/pageable";
import { PaginationTableComponent } from "@app/modules_new/shared/components/form/table/pagination/pagination.component";
import { TableFormComponent } from "@app/modules_new/shared/components/form/table/table.component";
import { ContentFilterLayoutComponent } from "@app/modules_new/shared/layouts/content-filter/content-filter.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, FormGroup } from "@angular/forms";
import { PaginationEvent } from "@app/modules_new/shared/components/form/table/pagination/pagination.model";
import { tableActions, tableColumns } from "./permission-users-ui";
import { PermissionGroupsUsersFacade } from "../../data/facade/permission-groups-users.facade";
import { PermissionUsersGroupsModal } from "./permission-users-ui-modal/permission-users-ui-modal.component";

@Component({
   selector: "permission-users-ui",
   templateUrl: "./permission-users-ui.component.html",
   standalone: true,
   imports: [
      ContentFilterLayoutComponent,
      TranslateModule,
      TableFormComponent,
      PaginationTableComponent,
      AsyncPipe,
      PermissionUsersGroupsModal
   ]
})
export class PermissionUsersGroupsComponent
   extends PageableComponent<any[]>
   implements OnInit
{
   constructor() {
      super(tableColumns, tableActions);
   }

   @Input() group!: any;

   @ViewChild("permissionsUsers") permissionsUsersTable!: TableFormComponent;

   private _dialog = inject(MatDialog);
   private _permissionGroupsUsersFacade = inject(PermissionGroupsUsersFacade);

   formFilterGroup = new FormGroup({
      description: new FormControl(null)
   });

   formFilter: Array<object> = [
      {
         placeholder: "Descrição",
         type: "text",
         form: this.formFilterGroup.controls["description"],
         click: true
      }
   ];

   users = [];

   ngOnInit() {
      this.get();
   }

   get(search?: boolean) {
      this.page = search === true ? 1 : this.page;
      const { data$, total$ } = this._permissionGroupsUsersFacade.get(
         this.group.id
      );
      this.data = data$;
      this.total = total$;
      data$.subscribe((res: any) => {
         if (res) {
            this.users = res;
         }
      });
   }

   ngOnDestroy(): void {
      this.unsubscribeAll();
   }

   ngAfterViewInit() {
      this.permissionsUsersTable.userInteractions$.subscribe(
         async (response) => {
            switch (response?.eventType) {
               case "ACTIONS_PRESSED":
                  switch (response.payload.event) {
                     case "DELETE": {
                        const user: any = this.users.filter(
                           (x: any) => x.id == response.payload.data.id
                        )[0];

                        const obj: any = {
                           body: {
                              usersId: [user.userId]
                           }
                        };

                        const deleteUser: any =
                           await this._permissionGroupsUsersFacade.delete(
                              this.group.id,
                              obj
                           );

                        if (deleteUser.success) {
                           this.get();
                        }
                     }
                  }
                  break;
            }
         }
      );
   }

   openModal(data?: any) {
      const dialog = this._dialog.open(PermissionUsersGroupsModal, {
         width: "calc(900px * var(--proportional))",
         data: this.group.id
      });
      dialog.afterClosed().subscribe(() => {
         this.get();
      });
   }

   changePage(event: PaginationEvent) {
      this.page = event.page;
      this.numberRegistry = event.numberRegistry;
      this.get();
   }

   resetFilter() {
      this.formFilterGroup.controls.description.reset(null);
   }
}
