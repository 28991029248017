import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { Location } from '@angular/common';
import { SwAlSetttings } from '@app/util/swal.settings';
import { MatDialog } from '@angular/material/dialog';
import { CustomersService } from '@app/services/customers/customers.service';
import { RequisitionService } from '@app/services/requisitions/requisition.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyteService } from '@app/services/analyte/analyte.service';
import { AgreementsService } from '@app/services/agreements/agreements.service';
import { DoctorsService } from '@app/services/doctors/doctors.service';
import { PriceTableService } from '@app/services/pricetable/pricetable.service';
import { AgreementsParametersService } from '@app/services/agreement-parameters/agreement-parameters.service';
import { AgreementRequestService } from '@app/services/agreement-request/agreement-request.service';
import { UnityService } from '@app/services/auth/unity/unity-service.service';
import { InputSearchComponent } from '@app/components/form/input-search/input-search.component';
import { Guid } from '@app/util/guid';
import decodedTokenResult from '@app/util/TokenResult';

interface AllAgreementRequestExams {
  addition: number
  analyteDeadline: Date
  analyteId: string
  balance: number
  code: string
  createdAt: Date
  description: string
  discont: number
  id: string
  isActive: boolean
  isUrgent: boolean
  materialId: string
  name: string
  status: number
  updatedAt: Date
  value: number
}
export interface GetCep {
  cep: string,
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}
@Component({
  selector: 'app-create-requisition',
  templateUrl: './create-requisition.component.html',
  styleUrls: ['./create-requisition.component.scss']
})

export class CreateExternalRequisitionComponent implements OnInit {
  // @ViewChild('agreementField', {static: false}) agreementField!: ElementRef;

  /*
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (!this.agreementRequestForm.description.value) {
        SwAlSetttings.alertaMsg("Por favor, informe um convênio válido!");
        //this.agreementField.nativeElement.focus();
        return;
      }

      if (!this.agreementRequestForm.name.value) {
        SwAlSetttings.alertaMsg("Por favor, informe um solicitante válido!");
        return;
      }

      this.saveAllChanges(false);
    }
  }
  */
  alterFields = true;
  //#region Global Variables / Variáveis Globais
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  newPayment: Array<any> = [];
  isEditable = false;
  title: string = "Novo Pedido";
  dateNow: Date = new Date();
  disable: boolean = false;
  total: number = 0;
  page: number = 1;
  index: number = 999999;
  urlAction: string = "";
  Extras: Array<Object> = [
    {
      type: 'svg',
      action: 'Select',
      content: '<svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.61338 22.74C2.61338 23.7267 3.41338 24.5 4.40005 24.5H16.4267C17.4134 24.5 18.2134 23.7267 18.2134 22.74L18.9067 8.23333H1.94672L2.61338 22.74ZM13.6 11.14C13.6 10.6333 13.8934 10.2333 14.4 10.2333C14.9067 10.2333 15.2 10.6333 15.2 11.14V21.1667C15.2 21.6733 14.9067 22.0733 14.4 22.0733C13.8934 22.0733 13.6 21.6733 13.6 21.1667V11.14ZM9.60005 11.14C9.60005 10.6333 9.89338 10.2333 10.4 10.2333C10.9067 10.2333 11.2 10.6333 11.2 11.14V21.1667C11.2 21.6733 10.9067 22.0733 10.4 22.0733C9.89338 22.0733 9.60005 21.6733 9.60005 21.1667V11.14ZM5.60005 11.14C5.60005 10.6333 5.89338 10.2333 6.40005 10.2333C6.90671 10.2333 7.20005 10.6333 7.20005 11.14V21.1667C7.20005 21.6733 6.90671 22.0733 6.40005 22.0733C5.89338 22.0733 5.60005 21.6733 5.60005 21.1667V11.14Z" fill="#FF6344"/><path d="M19.2267 3.7H14.4V1.32667C14.4 0.846667 14.16 0.5 13.6534 0.5H7.20005C6.69338 0.5 6.40005 0.846667 6.40005 1.32667V3.7H1.62672C1.12005 3.7 0.800049 4.07333 0.800049 4.58V5.59333C0.800049 6.1 1.12005 6.63333 1.62672 6.63333H19.2534C19.76 6.63333 20.0267 6.07333 20.0267 5.59333V4.58C20 4.07333 19.7334 3.7 19.2267 3.7ZM12.8 3.7H8.00005V2.1H12.8V3.7Z" fill="#FF6344"/></svg>'
    }
  ];

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  @ViewChild('tabs')
  tabGroup!: MatTabGroup;
  //#endregion

  //#region Analytes and Exams Variables / Variáveis dos Exames ou Analitos
  isUrgentChecked: boolean = true;
  newExam = [];
  examOptions: Array<Object> = [];
  materialExamOptions: Array<Object> = [];
  examPrice: Array<Object> = [];
  examPriceList: Array<Object> = [];
  examSituation = {
    0: "<span class='span-styles' style='background: #F8F8FF; color: #000000;'>Não Coletado</span>",
    1: "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Aguardando Coleta</span>",
    2: "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Coletado Parcial</span>",
    3: "<span class='span-styles' style='background: #FF0000; color: #F8F8FF;'>Coletado</span>",
    4: "<span class='span-styles' style='background: #363636; color: #F8F8FF;'>Solic. Recoleta</span>",
    5: "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Recoletado</span>",
    6: "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Triado</span>",
    7: "<span class='span-styles' style='background: #F8F8FF; color: #00FFFF;'>Digitado Parcial</span>",
    8: "<span class='span-styles' style='background: #F8F8FF; color: #00FFFF;'>Digitado</span>",
    9: "<span class='span-styles' style='background: #00FFFF; color: #000000;'>Conferido</span>",
    10: "<span class='span-styles' style='background: #F8F8FF; color: #00FF00;'>Liberado</span>",
    11: "<span class='span-styles' style='background: #F8F8FF; color: #0000FF;'>Entregue</span>",
    12: "<span class='span-styles' style='background: #F8F8FF; color: #00008B;'>Impresso</span>",
    20: "<span class='span-styles' style='background: #0000FF; color: #C0C0C0;'>Suspenso de Fatura</span>",
    21: "<span class='span-styles' style='background: #00FF00; color: #F8F8FF;'>Exclusivo de Fatura</span>",
    30: "<span class='span-styles' style='background: #F8F8FF; color: #C0C0C0;'>Cancelado</span>",
    91: "<span class='span-styles' style='background: #F8F8FF; color: #C0C0C0;'>Em Coleta</span>",
  }
  allAgreementRequestExams: Array<Object> = [];
  //#endregion

  //#region Agreements and Plans Variables / Variáveis dos Convênios e Planos
  agreementPlan: boolean = true;
  typeInvoiceIsPrivate: boolean = false;
  newAgreementPlan = [];
  agreementOptions: Array<Object> = [];
  agreementPriceTable: string = "";
  agreementPlanOptions: Array<Object> = [];
  agreementControlPlan: boolean = false;
  //#endregion

  //#region Requisition Variables / Variáveis das Requisições
  isNewRequisition: boolean = false;
  isEditRequisition: boolean = false;
  newRequisitionId: string = "";
  requisitionRequestArray: Array<Object> = [];
  requisitionId: string = "";
  requisitionNumber: number = 0;
  //#endregion
  agreementId: string = "";
  analytesCollects: Array<Object> = [];
  //#region AgreementRequest Variables / Variáveis referentes aos Pedidos Médicos
  agreementRequestId: string = "";
  agreementRequestExists: boolean = false;
  agreementsRequestArray: Array<any> = [];
  agreementsRequestArrayExams: Array<any> = [];
  analytesAgreementRequestArray: Array<any> = [];
  //#endregion

  //#region Unity Variables / Variáveis referentes às Unidades.
  unities: Array<any> = [];
  unity: Array<Object> = [];
  unityOptions: Array<any> = [];
  unityIndex: number = 0;
  unityId: string = "";
  //#endregion

  //#region CollectionPlace Variables / Variáveis referentes aos Locais de Coleta.
  collectionPlaceOptions: Array<any> = [];
  collectionPlaceIndex: number = 0;
  collectionPlaceId: string = "";
  //#endregion
  priceTableId: Guid = new Guid(Guid.getEmpty());
  //#region Doctor Variables / Variáveis referentes aos Médicos(Solicitantes)
  doctorOptions: Array<Object> = [];
  genderType = [
    {
      value: 0,
      label: 'Não Informado'
    },
    {
      value: 1,
      label: 'Feminino'
    },
    {
      value: 2,
      label: 'Masculino'
    }
  ]
  //#endregion

  //#region Customer Variables / Variáveis referentes aos Clientes.
  customerName: string = "";
  customerId: string = "";
  customerBirthDate: string = "";
  customerCpf: string = "";
  alterCustomer: boolean = true;
  firstCustomer: boolean = true;
  customerOptions: Array<Object> = [];
  //#endregion

  //#region QueryParameter Variables / Variáveis de parâmetro para as queries (consultas)
  getParameters: string = (`?page=${this.page}&index=${this.index}`);
  getParametersAgreement: string = (`?page=${this.page}&registerIndex=${this.index}`);
  getParametersDoctors: string = (`?IsActive=true&page=${this.page}&index=9999`); //${this.index}`
  //#endregion

  //#region Agreement Parameters Variable / Variáveis das permissões dos Convênios
  agreementHasParameters: boolean = false;
  isCpfMandatory: boolean = false;
  isValidDoctorMandatory: boolean = false;

  //#region Attendance Guide / Guia de Atendimento
  isAttendanceGuideMandatory: boolean = false;
  isAttendanceGuideOnlyNumbers: boolean = false;
  attendanceGuideMinimumLength: number = 0;
  attendanceGuideMaximumLength: number = 0;
  //#endregion

  //#region Attendance Card / Carteirinha de Atendimento
  isAttendanceCardMandatory: boolean = false;
  isAttendanceCardValidatedByMOD11: boolean = false;
  attendanceCardMinimumLength: number = 0;
  attendanceCardMaximumLength: number = 0;
  //#endregion

  //#region Authorization / Autorização
  isAuthorizationMandatory: boolean = false;
  authorizationMinimumLength: number = 0;
  authorizationMaximumLength: number = 0;
  //#endregion
  //#endregion


  formCollect = {
    hasPriority: new UntypedFormControl(false, [Validators.required]),
    includeCustomerToCollect: new UntypedFormControl(false, [Validators.required])
  }


  //#region Forms / Formulários
  agreementRequestForm = {
    agreementId: new UntypedFormControl(null, [Validators.required]),
    description: new UntypedFormControl(null, [Validators.required]),
    typeInvoice: new UntypedFormControl(null, [Validators.required]),
    index: new UntypedFormControl(null, [Validators.required]),
    priceTableId: new UntypedFormControl(null, [Validators.required]),
    doctorId: new UntypedFormControl(null, [Validators.required]),
    name: new UntypedFormControl(null, [Validators.required]),
    registrationNumber: new UntypedFormControl(null, [Validators.required]),
    registrationState: new UntypedFormControl(null, [Validators.required]),
    council: new UntypedFormControl(null, [Validators.required]),
    code: new UntypedFormControl(null, [Validators.required]),
    authorization: new UntypedFormControl(null, [Validators.required]),
    cid: new UntypedFormControl(null, [Validators.required]),
    cardNumber: new UntypedFormControl(null, [Validators.required]),
    cardValidity: new UntypedFormControl(new Date(), [Validators.required]),

    analyte: new UntypedFormControl(null, [Validators.required]),
    price: new UntypedFormControl(null, [Validators.required]),
    isUrgent: new UntypedFormControl(null, [Validators.required]),
    analyteDeadline: new UntypedFormControl(null, [Validators.required]),
    materialCollected: new UntypedFormControl(null, [Validators.required]),

    plan: new UntypedFormControl(null, [Validators.required])
  }

  form = {
    agreementInformation: new UntypedFormControl(null, [Validators.required]),
    doctorInformation: new UntypedFormControl(null, [Validators.required]),
    payments: new UntypedFormControl(null, [Validators.required]),
    valorBruto: new UntypedFormControl(0, [Validators.required]),
    acrescimo: new UntypedFormControl(0, [Validators.required]),
    desconto: new UntypedFormControl(0, [Validators.required]),
    saldoAberto: new UntypedFormControl(0, [Validators.required]),
    valorLiquido: new UntypedFormControl(0, [Validators.required]),
    searchCustomer: new UntypedFormControl(null, [Validators.required])
  }

  doctorForm = {
    registrationNumber: new UntypedFormControl(null, [Validators.required]),
    council: new UntypedFormControl(null, [Validators.required]),
    registrationState: new UntypedFormControl(null, [Validators.required]),
    name: new UntypedFormControl(null, [Validators.required]),
  }

  formCustomer = {
    customerName: new UntypedFormControl(null, [Validators.required]),
    customerGenderType: new UntypedFormControl(null, [Validators.required]),
    customerBirthDate: new UntypedFormControl(null, [Validators.required]),
    customerMotherName: new UntypedFormControl(null, [Validators.required]),
    customerResponsibleName: new UntypedFormControl(null, [Validators.required]),
    customerSocialName: new UntypedFormControl(null, [Validators.required]),
    customerCnpjCpf: new UntypedFormControl(null, [Validators.required]),
    customerRg: new UntypedFormControl(null, [Validators.required]),
    customerPassport: new UntypedFormControl(null, [Validators.required]),
    customerCellphone: new UntypedFormControl(null, [Validators.required]),
    customerResidentialPhone: new UntypedFormControl(null, [Validators.required]),
    customerEmail: new UntypedFormControl(null, [Validators.required]),
    addressCEP: new UntypedFormControl(null, [Validators.required]),
    address: new UntypedFormControl("", [Validators.required]),
    addressNumber: new UntypedFormControl("", [Validators.required]),
    addressComplement: new UntypedFormControl("", [Validators.required]),
    addressDistrict: new UntypedFormControl("", [Validators.required]),
    addressCity: new UntypedFormControl("", [Validators.required]),
    addressState: new UntypedFormControl("", [Validators.required]),
    addressCountry: new UntypedFormControl("", [Validators.required]),
    continuousMedication: new UntypedFormControl(false, [Validators.required]),
    medication: new UntypedFormControl(null, [Validators.required]),
    sendSmsEmail: new UntypedFormControl(false, [Validators.required])
  };

  createRequisitionForm = {
    customerName: new UntypedFormControl(null, [Validators.required]),
    birthDate: new UntypedFormControl(null, [Validators.required]),
    genderType: new UntypedFormControl(null, [Validators.required]),
    customerId: new UntypedFormControl(null, [Validators.required]),
    unityId: new UntypedFormControl(null, [Validators.required]),
    clinicalInformation: new UntypedFormControl(null, [Validators.required])
  }

  requisitionForm = {
    customerId: new UntypedFormControl(null, [Validators.required]),
    customerName: new UntypedFormControl(null, [Validators.required]),
    tenantId: new UntypedFormControl(null, [Validators.required]),
    tenantCompanyName: new UntypedFormControl(null),
    unityId: new UntypedFormControl(null, [Validators.required]),
    unityName: new UntypedFormControl(null, [Validators.required]),
    collectionPlaceId: new UntypedFormControl(null, [Validators.required]),
    collectionPlaceDescription: new UntypedFormControl(null, [Validators.required]),
    birthDate: new UntypedFormControl(null, [Validators.required]),
    genderType: new UntypedFormControl(null, [Validators.required]),
    clinicalInformation: new UntypedFormControl(null)
  }

  formUnity = {
    requisitionUnity: new UntypedFormControl(null, [Validators.required]),
    requisitionUnityName: new UntypedFormControl(null, [Validators.required]),
    requisitionUnityId: new UntypedFormControl(null, [Validators.required]),
    requisitionCollectionPlace: new UntypedFormControl(null, [Validators.required]),
    requisitionCollectionPlaceDescription: new UntypedFormControl(null, [Validators.required]),
    requisitionCollectionPlaceId: new UntypedFormControl(null, [Validators.required])
  };

  //#endregion

  //#region Register / Resolve
  registerExams: Array<Object> = [];
  registerQuestions: Array<Object> = [];
  registerExamsValues: Array<Object> = [];
  registerTeste: Array<Object> = [];
  registerTesteFilter: Array<{ id: string, data: Array<Object> }> = [];

  //#endregion

  resolveQuestions: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Descrição",
      method: '',
      retrive: "name",
      after: '',
      before: '',
    },
    {
      label: "Data de Criação",
      method: 'getQuestionDate',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "Resposta",
      method: 'getQuestionAnswer',
      retrive: '',
      after: '',
      before: '',
    },
  ];

  money: Array<Object> = [
    {
      label: 'Pix',
      value: 1
    },
    {
      label: 'Crédito',
      value: 2
    },
    {
      label: 'Cartão Mastercard Débito',
      value: 3
    },
    {
      label: 'Dinheiro',
      value: 4
    }
  ];

  @ViewChild(InputSearchComponent) teste_input!: InputSearchComponent;
  @ViewChildren("teste_div") teste_div!: QueryList<ElementRef>;

  constructor(
    private _dialog: MatDialog,
    private _customerService: CustomersService,
    private _requisitionService: RequisitionService,
    private _route: ActivatedRoute,
    private _analyteService: AnalyteService,
    private _agreementService: AgreementsService,
    private _doctorService: DoctorsService,
    private _priceTableService: PriceTableService,
    private _agreementParameters: AgreementsParametersService,
    private _agreementRequestService: AgreementRequestService,
    private _agreementExamsService: AgreementRequestService,
    private _location: Location,
    private _formBuilder: UntypedFormBuilder,
    private _unityService: UnityService,
    private _router: Router,
  ) { }

  async ngOnInit() {
    let tenantId = sessionStorage.getItem('TenantId');
    if (tenantId) await this.getUnity(new Guid(tenantId));
    let customerId: string = '';
    await this.getDoctors();
    this.agreementId = decodedTokenResult.xid;
    await this.getAgreementsById(new Guid(this.agreementId));
    if (this._route.snapshot.params['id']) {
      let urlParams: string[] = this._route.snapshot.params['id'].split('$');
      this.agreementRequestId = urlParams[1];
      if (this.agreementRequestId != "" && this.agreementRequestId != undefined) {
        this.title = "Editar pedido";
      }
      if (urlParams[0] != "") {
        this.nextTab();
      }
      this.requisitionId = urlParams[0];
      await this.getRequisitionById(urlParams[0]);
    }
  }

  backPage() {
    this._router.navigateByUrl("Externo/Convenio");
  }

  async changeCustomer(customerId: string) {//TODO - ajustar o custumer do acesso externo
    if (customerId) {
      await this._customerService.getById(new Guid(customerId)).then(
        (res) => {
          //@ts-ignore
          let splitAdress = res.data[0].address;
          // let addressAll = splitAdress.split(',');
          // let splitAddressAll = addressAll[4].split('-')

          if (res.data.length != 0) {
            this.formCustomer.customerName.setValue(res.data[0].name);
            this.formCustomer.customerGenderType.setValue(res.data[0].genderType);
            this.formCustomer.customerBirthDate.setValue(res.data[0].birthDate);
            this.formCustomer.customerMotherName.setValue(res.data[0].motherName);
            this.formCustomer.customerResponsibleName.setValue(res.data[0].nameResponsible);
            this.formCustomer.customerSocialName.setValue(res.data[0].socialName);
            this.formCustomer.customerCnpjCpf.setValue(res.data[0].cpf);
            this.formCustomer.customerRg.setValue(res.data[0].rg);
            this.formCustomer.customerPassport.setValue(res.data[0].passportNumber);
            this.formCustomer.customerCellphone.setValue(res.data[0].cellPhone);
            this.formCustomer.customerResidentialPhone.setValue(res.data[0].residencePhone);
            this.formCustomer.customerEmail.setValue(res.data[0].email);
            // this.formCustomer.addressCEP.setValue(addressAll[5])
            // this.formCustomer.address.setValue(addressAll[2])
            // this.formCustomer.addressNumber.setValue(addressAll[1])
            // this.formCustomer.addressComplement.setValue(addressAll[6])
            // this.formCustomer.addressDistrict.setValue(addressAll[2])
            // this.formCustomer.addressCity.setValue(addressAll[3])
            // this.formCustomer.addressState.setValue(splitAddressAll[0])
            // this.formCustomer.addressCountry.setValue(splitAddressAll[1])
            this.customerId = res.data[0].id;
            this.customerBirthDate = new Date(res.data[0].birthDate).toLocaleDateString();
            this.customerName = res.data[0].name ? res.data[0].name.toUpperCase() : res.data[0].name;
            this.customerCpf = res.data[0].cpf;
          } else {
            SwAlSetttings.alertaMsg('Nenhum dado retornado!');
          }
        }
      )
    }
  }

  async getCustomers() {
    if (this.form.searchCustomer.value) {
      this.requisitionForm.customerName.setValue('')
      this.firstCustomer = false;
      this.alterCustomer = this.alterCustomer ? false : true
      this.customerOptions = [];
      let getCustomerParameters: string = (`?Name=${this.form.searchCustomer.value}&page=${this.page}&numberRegistry=${9999}`);

      await this._customerService.getByPath('', getCustomerParameters).then(
        (res) => {
          if (res.data.length != 0) {
            res.data.forEach((element: any) => {
              this.customerOptions.push({
                label: element.name,
                value: element.id
              })
            })
          } else {
            SwAlSetttings.alertaMsg('Cliente não encontrado, preencha os campos para cadastrar!');
            this.alterCustomer = true;
            this.formCustomer.customerName.setValue(this.form.searchCustomer.value);
            this.alterFields = false;
          }
        }
      )
    } else {
      setTimeout(() => {
        SwAlSetttings.alertaMsg("É necessário informar um cliente para realizar a busca!");
      }, 100);
    }
  }

  async getUnity(tenantId: Guid) {
    await this._unityService.getByPath('', this.getParameters).then(
      (res) => {
        //@ts-ignore
        if (res.data.length != 0) {
          //@ts-ignore
          this.unity = res.data;
          this.unity.forEach((unity) => {
            this.unityOptions.push({
              //@ts-ignore
              value: unity.id,
              //@ts-ignore
              label: unity.description
            });
            //@ts-ignore
            this.formUnity.requisitionUnityId = unity.id;
            //@ts-ignore
            this.formUnity.requisitionUnityName = unity.description;

            //@ts-ignore
            unity.collectionPlace.forEach((collectionPlace: any) => {
              this.collectionPlaceOptions.push({
                value: collectionPlace.id,
                label: collectionPlace.description
              });
            });
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  getQuestionDate(item: any) {
    let datetime = item.createdAt.split('T');
    let date = datetime[0].split('-');
    let time = datetime[1].split(':');
    return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}h${time[1]}`;
  }

  getQuestionAnswer(item: any) {
    if (!item.answer) {
      let spanAnswer = "<span class='span-styles' style='color: red;'>Não Respondida</span>";
      return spanAnswer;
    } else {
      return item.answer
    }
  }

  alterValue() {
    let valorBruto: number = this.form.valorBruto.value;
    let acrescimo: number = parseFloat(this.form.acrescimo.value);
    let desconto: number = parseFloat(this.form.desconto.value);

    if (!acrescimo) {
      acrescimo = 0;
      this.form.acrescimo.setValue(acrescimo.toFixed(2));
    } else {
      this.form.acrescimo.setValue(parseFloat(this.form.acrescimo.value.replace(",", ".")).toFixed(2));

    }

    if (!desconto) {
      desconto = 0;
      this.form.desconto.setValue(desconto.toFixed(2));
    } else {
      this.form.desconto.setValue(parseFloat(this.form.desconto.value.replace(",", ".")).toFixed(2));
    }

    if (desconto > valorBruto) {
      this.form.desconto.setValue(valorBruto);
    }

    this.form.valorLiquido.setValue((parseFloat(this.form.valorBruto.value) + parseFloat(this.form.acrescimo.value) - parseFloat(this.form.desconto.value)).toFixed(2));
    this.getPercentage();
    this.totalValue();
  }

  getPercentage() {
    if (this.form.desconto.value) {
      let percentual = ((parseFloat(this.form.desconto.value)) * 100) / (parseFloat(this.form.valorBruto.value) + parseFloat(this.form.acrescimo.value));
      return percentual.toFixed(2) + '%';
    } else {
      return '0%';
    }
  }

  payment() {
    let initial: number = 0;
    this.newPayment = [];
    let i = 0;
    if (this.form.payments.value) {
      this.form.payments.value.forEach((element: any) => {
        const formPayment = {
          label: new UntypedFormControl(element, [Validators.required]),
          value: new UntypedFormControl(initial.toFixed(2), [Validators.required]),
          dueDate: new UntypedFormControl(null, [Validators.required]),
          transaction: new UntypedFormControl(null, [Validators.required]),
          parcel: new UntypedFormControl(null, [Validators.required]),
          percentage: new UntypedFormControl(null, [Validators.required])
        };
        //@ts-ignore
        this.newPayment.push(formPayment);
      });
    }
  }

  totalValue(index?: number) {

    let valor: number = 0;

    this.newPayment.forEach((x: any) => {
      valor = parseFloat(x.value.value.replace(",", ".")) + valor
    });


    if (this.form.valorBruto.value >= valor) {
      this.form.saldoAberto.setValue(this.form.valorLiquido.value ? (this.form.valorLiquido.value - valor).toFixed(2) : (this.form.valorBruto.value - valor).toFixed(2));
    } else if (this.newPayment.length == 1) {
      this.newPayment[0].value.setValue(this.form.valorBruto.value);
      this.form.saldoAberto.setValue(0);
    } else if (this.newPayment.length > 1 && index) {
      this.newPayment[index].value.setValue(this.form.saldoAberto.value);
      this.form.saldoAberto.setValue(0);
    }
  }

  getPayment(label: string) {
    //@ts-ignore
    let name = this.money.filter(x => x.value === label);
    //@ts-ignore
    return name[0].label;
  }

  getAgreementName() {
    if (this.agreementOptions.length > 0) {
      //@ts-ignore
      let agreementName = this.agreementOptions.filter((x) => x.value === this.agreementRequestForm.description.value);
      //@ts-ignore
      return agreementName[0].label;
    }
  }

  getAgreementPlanName() {
    if (this.agreementPlanOptions.length > 0) {
      //@ts-ignore
      let planName = this.agreementPlanOptions.filter((x) => x.value === this.agreementRequestForm.plan.value);
      //@ts-ignore
      return planName[0].label;
    }
  }

  myTabSelectedTabChange(changeEvent: MatTabChangeEvent) {
    let total = 0;
    if (changeEvent.index === 2 && this.examPrice.length != 0 && this.newExam.length != 0) {
      this.examPrice.forEach((price: any) => {
        this.newExam.forEach((exam: any) => {
          total = price.id === exam.inp.value ? price.value + total : total;
        })
      })
      this.form.valorBruto.setValue(total);
    }
  }

  nextTab() {
    this.tabGroup.selectedIndex = this.tabGroup.selectedIndex! + 1;
  }

  returnToVisualization() {
    this._router.navigateByUrl(`Externo/Convenio/VisualizarRequisicao/${this.requisitionId}`)
  }

  async getAnalytesById(analyteId: Guid, index: number, getExams: boolean = false) {
    this.materialExamOptions = []
    if (analyteId) {
      await this._analyteService.getById(analyteId).then(
        (res) => {
          if (res.data.length != 0) {
            res.data[0].materiais.forEach((analyteMaterials) => {
              this.materialExamOptions.push({
                label: analyteMaterials.externalId + ' - ' + analyteMaterials.description,
                value: analyteMaterials.materialId
              });

              if (this.title != 'Novo Pedido') {
                if (res.data[0].materiais.length == 1) {
                  //@ts-ignore
                  this.newExam[index].mat.setValue(this.materialExamOptions[0].value)
                }
              } else {
                if (res.data[0].materiais.length == 1) {
                  //@ts-ignore
                  this.newExam[index].mat.setValue(this.materialExamOptions[0].value)
                }
              }
            });
          } else {
            SwAlSetttings.alertaMsg(`Não foi possível encontrar o Analito com Id(${analyteId})!`);
          }
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );
    }

    if (getExams) {
      this.getByIdPriceTable(this.priceTableId);
    }
  }

  async getAgreements() {
    this.agreementOptions = [];
    await this._agreementService.getByPath('', this.getParametersAgreement).then(
      (res) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          res.data.forEach((agreements) => {
            this.agreementOptions.push({
              label: agreements.description + ' - ' + agreements.externalId,
              value: agreements.id,
            });
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async getAgreementsById(agreementId: Guid) {
    this.agreementPlanOptions = [];
    if (agreementId) {
      await this._agreementService.getById(agreementId).then(
        async (res) => {
          if (res.data.length != 0) {
            this.agreementControlPlan = res.data[0].controlPlan;
            if (this.agreementControlPlan) {
              res.data[0].agreementPlan.forEach((agreementPlans) => {
                this.agreementPlanOptions.push({
                  label: agreementPlans.externalId ? agreementPlans.externalId + ' - ' + agreementPlans.description : agreementPlans.description,
                  value: agreementPlans.id
                });
              });
              //@ts-ignore
              this.agreementRequestForm.plan.setValue(this.agreementPlanOptions[0].value);
            } else {
              this.agreementRequestForm.plan.setValue(null);
            }
            await this.getAgreementParameters(new Guid(res.data[0].id));
            await this.getByIdPriceTable(new Guid(res.data[0].priceTableId));
            this.priceTableId = new Guid(res.data[0].priceTableId);
          } else {
            SwAlSetttings.alertaMsg(`Não foi possível encontrar o Convênio com Id(${agreementId})!`);
          }
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );
    }
  }

  async getAgreementParameters(agreementId: Guid) {
    let headers = [sessionStorage.getItem('token')];
    let getAgreementParameters: string = `AgreementId=${agreementId}&page=${this.page}&registerIndex=${this.index}`

    await this._agreementParameters.getByPath(getAgreementParameters, '', headers).then(
      (res) => {
        if (res.data.length != 0) {
          res.data.forEach((agreementParameters) => {
            this.agreementHasParameters = agreementParameters.isActive;
            if (this.agreementHasParameters) {
              //#region Informações referentes à Guia
              this.isAttendanceGuideOnlyNumbers = agreementParameters.isAttendanceGuideOnlyNumbers
              this.isAttendanceGuideMandatory = agreementParameters.isAttendanceGuideMandatory
              this.attendanceGuideMinimumLength = agreementParameters.attendanceGuideMinimumLength
              this.attendanceGuideMaximumLength = agreementParameters.attendanceGuideMaximumLength
              //#endregion

              //#region Informações referentes à Carteirinha
              this.isAttendanceCardMandatory = agreementParameters.isAttendanceCardMandatory
              this.isAttendanceCardValidatedByMOD11 = agreementParameters.isAttendanceCardValidatedByMOD11
              this.attendanceCardMinimumLength = agreementParameters.attendanceCardMinimumLength
              this.attendanceCardMaximumLength = agreementParameters.attendanceCardMaximumLength
              //#endregion

              //#region Informações referentes à Autorização
              this.isAuthorizationMandatory = agreementParameters.isAuthorizationMandatory
              this.authorizationMinimumLength = agreementParameters.authorizationMinimumLength
              this.authorizationMaximumLength = agreementParameters.authorizationMaximumLength
              //#endregion
            }
          });
        } else {
          this.resetAgreementParameters();
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resetAgreementParameters() {
    //#region Attendance Guide / Guia de Atendimento
    this.attendanceGuideMinimumLength = 0;
    this.attendanceGuideMaximumLength = 999;
    //#endregion

    //#region Attendance Card / Carteirinha de Atendimento
    this.attendanceCardMinimumLength = 0;
    this.attendanceCardMaximumLength = 999;
    //#endregion

    //#region Authorization / Autorização
    this.authorizationMinimumLength = 0;
    this.authorizationMaximumLength = 999;
    //#endregion
  }

  async getByIdPriceTable(priceTableId: Guid) {
    this.examOptions = [];
    await this._priceTableService.getById(priceTableId).then(
      (res) => {
        if (res.data.length != 0) {
          res.data[0].priceTableExams.forEach((priceTableExams) => {
            this.examPrice.push({
              id: priceTableExams.analyteId,
              code: priceTableExams.code + ' - ' + priceTableExams.name,
              value: priceTableExams.price
            });
            this.examOptions.push({
              label: priceTableExams.code + ' - ' + priceTableExams.name + ' - ' + priceTableExams.billingCode,
              value: priceTableExams.analyteId,
              price: priceTableExams.price
            });
          });
        } else {
          this.examOptions = [];
          SwAlSetttings.alertaMsg(`Não foi possível encontrar a Tabela de Preço com Id(${priceTableId})!`);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async getRequisitionById(id: string) {
    let exams: any = [];
    await this._requisitionService.getById(new Guid(id)).then(
      (res) => {
        const requisition = res.data[0];

        this.customerBirthDate = new Date(requisition.birthDate).toLocaleDateString();
        this.customerCpf = requisition.cpf;
        this.customerName = requisition.name ? requisition.name.toUpperCase() : requisition.name;
        this.requisitionNumber = requisition.number;

        this.formCustomer.customerName.setValue(requisition.name);
        this.formCustomer.customerBirthDate.setValue(requisition.birthDate);
        this.requisitionForm.unityId.setValue(requisition.unityId);
        this.formCustomer.customerGenderType.setValue(requisition.genderType);
        this.requisitionForm.clinicalInformation.setValue(requisition.clinicalInformation);
        this.requisitionForm.collectionPlaceId.setValue(requisition.collectionPlaceId);
        if (this.agreementRequestId != "" && this.agreementRequestId != undefined) {
          requisition.agreementRequest.filter(x => x.id === this.agreementRequestId ? exams.push(x) : '');

          this.agreementRequestForm.description.setValue(exams[0].agreementId);
          //this.agreementPlanRow();
          this.agreementRequestForm.code.setValue(exams[0].code);
          this.agreementRequestForm.authorization.setValue(exams[0].authorization);
          this.agreementRequestForm.cardNumber.setValue(exams[0].cardNumber);
          this.agreementRequestForm.cid.setValue(exams[0].cid);
          this.agreementRequestForm.cardValidity.setValue(exams[0].cardValidity);
          this.agreementRequestForm.name.setValue(exams[0].doctorId);

          exams[0].agreementsExamsRequest.forEach((x: any) => {
            let i: number = 0;
            this.getAnalytesById(x.analyteId, i++);

            const formExam = {
              id: new UntypedFormControl(x.id),
              inp: new UntypedFormControl(x.analyteId, [Validators.required]),
              mat: new UntypedFormControl(x.materialId, [Validators.required]),
              val: new UntypedFormControl(x.value, [Validators.required]),
              urg: new UntypedFormControl(x.isUrgent, [Validators.required]),
              prz: new UntypedFormControl(x.analyteDeadline, [Validators.required]),
              col: new UntypedFormControl(x.status > 2 && x.status != 4 ? true : false, [Validators.required])
            };

            //@ts-ignore
            this.newExam.push(formExam);
          });
        }

        this.requisitionRequestArray = res.data;
        this.customerId = requisition.customerId;
        this.getCustomerById(requisition.customerId);
        //@ts-ignore
        this.agreementsRequestArray = requisition.agreementRequest;
        this.agreementsRequestArray.forEach((agreementRequest) => {
          agreementRequest.formAgreementDescription = this.createAgreementForm(agreementRequest);
          this.registerExamsValues = agreementRequest.agreementsExamsRequest;
        });

      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async getDoctors() {
    await this._doctorService.getByPath('', this.getParametersDoctors).then(
      (res) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          res.data.forEach((doctors) => {
            this.doctorOptions.push({
              label: doctors.externalId ? doctors.name + ' - ' + doctors.council + '/' + doctors.registrationState + ': ' + doctors.registrationNumber + ' - .' + doctors.externalId : doctors.name + ' - ' + doctors.council + '/' + doctors.registrationState + ': ' + doctors.registrationNumber,
              value: doctors.id,
            })
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  createAgreementForm(requestItem: any): UntypedFormGroup {
    return this._formBuilder.group({
      agreementId: [requestItem.agreementId, Validators.required],
      description: [requestItem.description],
      typeInvoice: [requestItem.typeInvoice],
      index: [requestItem.index],
      priceTableId: [requestItem.priceTableId],
      doctorId: [requestItem.doctorId],
      name: [requestItem.name],
      registrationNumber: [requestItem.registrationNumber],
      registrationState: [requestItem.registrationState],
      council: [requestItem.council],
      code: [requestItem.code],
      authorization: [requestItem.authorization],
      cid: [requestItem.cid],
      cardNumber: [requestItem.cardNumber],
      cardValidity: [requestItem.cardValidity],
    });
  }

  closeModal() {
    this._dialog.closeAll();
  }

  async getCustomerById(id: string) {
    await this._customerService.getById(new Guid(id)).then(
      (res) => {
        if (res.data.length != 0) {
          const customer = res.data[0];
          this.customerName = customer.name ? customer.name.toUpperCase() : customer.name;
          this.customerCpf = customer.cpf;
          this.customerBirthDate = new Date(customer.birthDate).toLocaleDateString();
          this.formCustomer.customerBirthDate.setValue(customer.birthDate);
          this.formCustomer.customerCellphone.setValue(customer.cellPhone);
          this.formCustomer.customerCnpjCpf.setValue(customer.cpf);
          this.formCustomer.customerEmail.setValue(customer.email);
          this.formCustomer.customerGenderType.setValue(customer.genderType);
          this.formCustomer.customerMotherName.setValue(customer.motherName ? customer.motherName.toUpperCase() : '');
          this.formCustomer.customerName.setValue(customer.name ? customer.name.toUpperCase() : '');
          this.formCustomer.customerPassport.setValue(customer.passportNumber);
          this.formCustomer.customerResidentialPhone.setValue(customer.residencePhone);
          this.formCustomer.customerRg.setValue(customer.rg);
          this.formCustomer.customerSocialName.setValue(customer.socialName ? customer.socialName.toUpperCase() : '');
          this.formCustomer.customerResponsibleName.setValue(customer.nameResponsible ? customer.nameResponsible.toUpperCase() : '');
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  getUrgentSituation(item: any) {
    let situation: boolean = item.isUrgent;
    let stylizedSpanForUrgentExam: string = "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Sim</span>";
    let stylizedSpanForNotUrgentExam: string = "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Não</span>";
    return situation ? stylizedSpanForUrgentExam : stylizedSpanForNotUrgentExam;
  }

  convertExamSituation(item: any) {
    //@ts-ignore
    return item.status ? this.examSituation[item.status] : this.examSituation[0];
  }

  generateDeadLine(item: any) {
    let dateOfCreation: Date = new Date(item.createdAt);
    if (item.isUrgent) {
      let calculatedDate: number = dateOfCreation.setDate(dateOfCreation.getDate() + item.urgentDeadline);
      let convertedCalculatedDate: Date = new Date(calculatedDate);

      var formattedDay = convertedCalculatedDate.getDate() < 10 ? "0" + convertedCalculatedDate.getDate() : convertedCalculatedDate.getDate().toString();
      var formattedMonth = convertedCalculatedDate.getMonth() < 10 ? "0" + (convertedCalculatedDate.getMonth() + 1) : (convertedCalculatedDate.getMonth() + 1).toString();
      var formattedHour = convertedCalculatedDate.getHours() < 10 ? "0" + (convertedCalculatedDate.getHours()) : (convertedCalculatedDate.getHours()).toString();
      var formattedMinutes = convertedCalculatedDate.getMinutes() < 10 ? "0" + (convertedCalculatedDate.getMinutes()) : (convertedCalculatedDate.getMinutes()).toString();
      return formattedDay + "/" + formattedMonth + "/" + convertedCalculatedDate.getFullYear() + " às " + formattedHour + ":" + formattedMinutes;

    } else {
      let calculatedDate: number = dateOfCreation.setDate(dateOfCreation.getDate() + item.numberDays);
      let convertedCalculatedDate: Date = new Date(calculatedDate);

      var formattedDay = convertedCalculatedDate.getDate() < 10 ? "0" + convertedCalculatedDate.getDate() : convertedCalculatedDate.getDate().toString();
      var formattedMonth = convertedCalculatedDate.getMonth() < 10 ? "0" + (convertedCalculatedDate.getMonth() + 1) : (convertedCalculatedDate.getMonth() + 1).toString();
      return formattedDay + "/" + formattedMonth + "/" + convertedCalculatedDate.getFullYear() + " às " + convertedCalculatedDate.getHours() + ":" + convertedCalculatedDate.getMinutes();
    }
  }

  examForm() {
    const formExam = {
      id: new UntypedFormControl(null),
      inp: new UntypedFormControl(null, [Validators.required]),
      mat: new UntypedFormControl(null, [Validators.required]),
      val: new UntypedFormControl(0, [Validators.required]),
      urg: new UntypedFormControl(false, [Validators.required]),
      prz: new UntypedFormControl(Date.now(), [Validators.required]),
      col: new UntypedFormControl(false, [Validators.required]),
    };

    //@ts-ignore
    this.newExam.push(formExam);
  }

  agreementPlanForm() {
    const formAgreementPlan = {
      agreementPlan: new UntypedFormControl(null, [Validators.required]),
    };
    //@ts-ignore
    this.newAgreementPlan.push(formAgreementPlan);
  }

  closeLength(index: number) {
    this.newExam.splice(index, 1);
  }

  getAnalytePrice(analyteId: Guid, index: number) {
    this.examPrice.forEach((examPrice) => {
      //@ts-ignore
      if (examPrice.id === analyteId.toString()) {
        this.examPriceList.push({
          //@ts-ignore
          "id": examPrice.id,
          //@ts-ignore
          "code": examPrice.code,
          //@ts-ignore
          "value": examPrice.value
        });
        //@ts-ignore
        this.newExam[index].val.setValue(examPrice.value);
      }
    });
  }

  async agreementPlanRow() {
    if (this.agreementRequestForm.description.value) {
      this.examOptions = [];
      this.agreementPlanOptions = [];
      this.agreementPlanForm();
      let agreementIdGuid = new Guid(this.agreementRequestForm.description.value);
      await this.getAgreementsById(agreementIdGuid);
      this.resetAgreementParameters();
    } else {
      this.examOptions = [];
      this.agreementPlanOptions = [];
      this.newExam = [];
      this.agreementRequestForm.description.setValue('')
    }
  }

  removeExamRow(item: any) {
    //@ts-ignore
    if (!this.newExam[item].id.value) {
      this.newExam.splice(item, 1);
    } else {
      setTimeout(() => {
        SwAlSetttings.ALERT_DELETE.fire("Deseja realmente excluir este exame?").then(async (x) => {
          if (x.isConfirmed) {
            //@ts-ignore
            if (this.newExam.length > 0 && this.newExam[item].id.value) {
              //@ts-ignore
              await this._agreementExamsService.deleteByPath(undefined, `agreementrequest/${this.agreementRequestId}/exam/${this.newExam[item].id.value}`);
              this.newExam.splice(item, 1);
              SwAlSetttings.Sucesso('Item excluído com sucesso!');
            } else {
              this.newExam.splice(item, 1);
            };
          }
        }).catch(
          (err: any) => {
            SwAlSetttings.printError(err);
          }
        );
      }, 200);
    }
  }

  resolve(data: any, columns: any) {
    this.registerExams = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerExams.push(obj);
    });
    return this.registerExams;
  }

  resolve3(idRequest: string, data: any, columns: any) {
    this.registerTeste = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerTeste.push(obj);
    });
    this.registerTesteFilter.push({ id: idRequest, data: this.registerTeste });
    return this.registerTeste;
  }

  actions(emit: any) {
    //@ts-ignore
    switch (emit.action) {
      case 'Select':
        break;
      case 'OpenDropdown':
        this.registerTeste = this.registerTesteFilter.filter(item => item.id === emit.id).map(item => item.data)[0];
        break;
      case 'Editar':
        break;
      case 'Excluir':
        break;
    }
  }

  examsActions(emit: any) {
    //@ts-ignore
    switch (emit.action) {
      case 'Select':
        break;
      case 'OpenDropdown':
        this.registerTeste = this.registerTesteFilter.filter(item => item.id === emit.id).map(item => item.data)[0];
        break;
      case 'Editar':
        break;
      case 'Excluir':
        break;
    }
  }

  resetAllFields() {
    //#region Agreement Parameters
    this.agreementHasParameters = false;

    this.isCpfMandatory = false;
    this.isValidDoctorMandatory = false;

    this.isAttendanceGuideMandatory = false;
    this.isAttendanceGuideOnlyNumbers = false;
    this.attendanceGuideMinimumLength = 0;
    this.attendanceGuideMaximumLength = 0;

    this.isAttendanceCardMandatory = false;
    this.isAttendanceCardValidatedByMOD11 = false;
    this.attendanceCardMinimumLength = 0;
    this.attendanceCardMaximumLength = 0;

    this.isAuthorizationMandatory = false;
    this.authorizationMinimumLength = 0;
    this.authorizationMaximumLength = 0;
    //#endregion

    //#region Clean AgreementFormRequest
    this.agreementRequestForm.agreementId.setValue(null);
    this.agreementRequestForm.description.setValue(null);
    this.agreementRequestForm.typeInvoice.setValue(null);
    this.agreementRequestForm.index.setValue(null);
    this.agreementRequestForm.priceTableId.setValue(null);
    this.agreementRequestForm.doctorId.setValue(null);
    this.agreementRequestForm.name.setValue(null);
    this.agreementRequestForm.registrationNumber.setValue(null);
    this.agreementRequestForm.registrationState.setValue(null);
    this.agreementRequestForm.council.setValue(null);
    this.agreementRequestForm.code.setValue(null);
    this.agreementRequestForm.authorization.setValue(null);
    this.agreementRequestForm.cid.setValue(null);
    this.agreementRequestForm.cardNumber.setValue(null);
    this.agreementRequestForm.cardValidity.setValue(null);
    this.agreementRequestForm.analyte.setValue(null);
    this.agreementRequestForm.price.setValue(null);
    this.agreementRequestForm.plan.setValue(null);
    //#endregion

    //#region Arrays
    this.newExam = [];
    this.materialExamOptions = [];
    this.doctorOptions = [];
    this.examOptions = [];
    this.agreementOptions = [];
    this.agreementPlanOptions = [];
    this.examPriceList = [];
    this.examPrice = [];
    this.analytesAgreementRequestArray = [];
    //#endregion
  };

  back() {
    this._location.back();
  }

  async saveAllChanges(isFinished: boolean) {
    if (this.requisitionForm.customerName.value || this.customerId) {
      if (this.requisitionForm.customerName.value) {
        this.customerId = this.requisitionForm.customerName.value;
      }
    } else {
      let customer = {
        "isActive": true,
        "name": this.formCustomer.customerName.value,
        "birthDate": this.formCustomer.customerBirthDate.value,
        "genderType": parseFloat(this.formCustomer.customerGenderType.value),
        "motherName": this.formCustomer.customerMotherName.value,
        "nameResponsible": this.formCustomer.customerResponsibleName.value,
        "socialName": this.formCustomer.customerSocialName.value,
        "cpf": this.formCustomer.customerCnpjCpf.value,
        "rg": this.formCustomer.customerRg.value,
        "passportNumber": this.formCustomer.customerPassport.value,
        "cellPhone": this.formCustomer.customerCellphone.value,
        "residencePhone": this.formCustomer.customerResidentialPhone.value,
        "email": this.formCustomer.customerEmail.value,
        "address": this.formCustomer.address.value + ',' + this.formCustomer.addressNumber.value + ',' + this.formCustomer.addressDistrict.value + ',' + this.formCustomer.addressCity.value + ',' + this.formCustomer.addressState.value + '-' + this.formCustomer.addressCountry.value + ',' + this.formCustomer.addressCEP.value + ',' + this.formCustomer.addressComplement.value,
        "continuousMedication": this.formCustomer.continuousMedication.value,
        "medication": this.formCustomer.medication.value,
        "sendEmailSms": this.formCustomer.sendSmsEmail.value
      };

      await this._customerService.post(customer).then(
        (res: any) => {
          this.customerId = res.id;
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );
    }

    let analytes: Array<Object> = [];

    for (const exam of this.newExam) {
      //@ts-ignore
      if (!exam.val.value || exam.val.value <= 0) {
        //@ts-ignore
        let testeValue = this.examPrice.filter((x) => x.id === exam.inp.value);
        //@ts-ignore
        exam.val.setValue(testeValue[0].value);
      }

      //@ts-ignore
      if (!exam.col.value) exam.col.value = false;

      let analyte = {};

      //@ts-ignore
      if (exam.id.value) {
        analyte = {
          //@ts-ignore
          "id": exam.id.value,
          //@ts-ignore
          "analyteId": exam.inp.value,
          //@ts-ignore
          "materialId": exam.mat.value,
          //@ts-ignore
          "analyteDeadline": exam.prz.value,
          //@ts-ignore
          "isUrgent": exam.urg.value,
          //@ts-ignore
          "value": exam.val.value,
          //@ts-ignore
          "isCollected": exam.col.value,
          "addition": 0,
          "discont": 0
        }
      } else {
        analyte = {
          //@ts-ignore
          "analyteId": exam.inp.value,
          //@ts-ignore
          "materialId": exam.mat.value,
          //@ts-ignore
          "analyteDeadline": exam.prz.value,
          //@ts-ignore
          "isUrgent": exam.urg.value,
          //@ts-ignore
          "value": exam.val.value,
          //@ts-ignore
          "isCollected": exam.col.value,
          "addition": 0,
          "discont": 0
        }
      }

      analytes.push(analyte);
    }

    if (this.requisitionId == "") {
      let requisition = {
        //      "personId" : decodedTokenResult.pid,
        "name": this.customerName,
        "birthDate": this.formCustomer.customerBirthDate.value,
        "genderType": parseInt(this.formCustomer.customerGenderType.value),
        "customerId": this.customerId,
        "unityId": this.requisitionForm.unityId.value,
        "collectionPlaceId": this.requisitionForm.collectionPlaceId.value,
        "clinicalInformation": this.requisitionForm.clinicalInformation.value ? this.requisitionForm.clinicalInformation.value : ''
      }

      await this._requisitionService.post(requisition).then(
        (res) => {
          this.requisitionId = res.id;
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );
    }

    let agreementRequest = {
      "requisitionId": this.requisitionId,
      "agreementId": this.agreementId,
      "agreementPlanId": this.agreementRequestForm.plan.value,
      "doctorId": this.agreementRequestForm.name.value,
      "code": this.agreementRequestForm.code.value ? this.agreementRequestForm.code.value : '',
      "authorization": this.agreementRequestForm.authorization.value ? this.agreementRequestForm.authorization.value : '',
      "cid": this.agreementRequestForm.cid.value ? this.agreementRequestForm.cid.value : '',
      "cardNumber": this.agreementRequestForm.cardNumber.value ? this.agreementRequestForm.cardNumber.value : '',
      "cardValidity": this.agreementRequestForm.cardValidity.value,
      analytes
    }

    if (this.agreementRequestId == "" || this.agreementRequestId == undefined) {
      await this._agreementRequestService.post(agreementRequest).then(
        (res) => {
          SwAlSetttings.Sucesso('Pedido médico incluso com sucesso!');
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );
    } else {
      await this._agreementRequestService.put(agreementRequest, new Guid(this.agreementRequestId)).then(
        (res) => {
          SwAlSetttings.Sucesso('Pedido médico editado com sucesso!');
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );
    }

    this.returnToVisualization();
  }

  async finishRequisition() {
    let splitDate = this.customerBirthDate.split("/");
    let formattedBirthDate: string = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;

    let analytesCollects = this.analytesCollects.map((x) => (
      {
        //@ts-ignore
        id: x.id,
        //@ts-ignore
        situation: x.checkbox.value ? 1 : 0
      }
    ));

    let finishBody = {
      name: this.customerName,
      birthDate: new Date(this.customerBirthDate).toISOString(),
      genderType: this.formCustomer.customerGenderType.value,
      customerId: this.customerId,
      unityId: this.requisitionForm.unityId.value,
      clinicalInformation: this.requisitionForm.clinicalInformation.value,
      isPriority: this.formCollect.hasPriority.value,
      analytesCollects
    }

    await this._requisitionService.putWithPath(finishBody, new Guid(this.requisitionId), 'finish').then(
      (res) => {
        SwAlSetttings.Sucesso("Requisição finalizada com sucesso!");
        setTimeout(() => {
          this._router.navigateByUrl('Cadastros/Requisicoes');
        }, 1000);
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  clearCepForm() {
    this.formCustomer.addressCEP.setValue("");
    this.formCustomer.address.setValue("");
    this.formCustomer.addressDistrict.setValue("");
    this.formCustomer.addressCity.setValue("");
    this.formCustomer.addressState.setValue("");
  }

  async getByIdDoctor() {
    await this._doctorService.getById(this.agreementRequestForm.name.value).then(
      (res) => {
        if (res.data.length != 0) {
          let doctor = res.data[0];
          let ind = this.doctorOptions.findIndex((x: any) => x.value == doctor.id);
          //@ts-ignore
          this.doctorOptions[ind].label = doctor.externalId ? doctor.name + ' - ' + doctor.council + '/' + doctor.registrationState + ': ' + doctor.registrationNumber + ' - .' + doctor.externalId : doctor.name + ' - ' + doctor.council + '/' + doctor.registrationState + ': ' + doctor.registrationNumber;
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }
}
