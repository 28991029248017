import { Component, inject, OnInit } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { MatTabsModule } from "@angular/material/tabs";
import { ContentReturnLayoutComponent } from "@app/modules_new/shared/layouts/concent-return/content-return.component";
import { ButtonGroupFormComponent } from "@app/modules_new/shared/components/form/button-group/button-group.component";
import { FormGroup, FormControl, UntypedFormControl } from "@angular/forms";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { InputSelectFormComponent } from "@app/modules_new/shared/components/form/input-select/base/input-select.component";
import { AgreementSuppportFacade } from "@app/modules_new/registers/data/facades/agreement-support-facade";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { ActivatedRoute } from "@angular/router";
import { Guid } from "@app/util/guid";
import { UsersAdminUsersFacade } from "@app/modules_new/admin/data/facades/users-admin-users.facade";
import { ToggleFormComponent } from "@app/modules_new/shared/components/form/toggle/toggle.component";
import { InputSelectMultplesFormComponent } from "@app/modules_new/shared/components/form/input-select/multiples/multiples.component";
import { DepartmentsFacade } from "@app/modules_new/administrativeData/data/facade/departments.facade";
import { ModulesFacade } from "@app/modules_new/admin/data/facades/modules.facade";
import { TooltipPosition } from "@angular/material/tooltip";
import { SwAlSetttings } from "@app/util/swal.settings";
import { RequestOptions } from "@app/modules_new/data/services/types/http.types";

@Component({
   selector: "users-new",
   styleUrls: ["./users-new.component.scss"],
   templateUrl: "./users-new.component.html",
   standalone: true,
   providers: [ContentReturnLayoutComponent],
   imports: [
      ContentReturnLayoutComponent,
      TranslateModule,
      MatTabsModule,
      CommonModule,
      ButtonGroupFormComponent,
      InputTextFormComponent,
      InputSelectFormComponent,
      ButtonFormComponent,
      ToggleFormComponent,
      InputSelectMultplesFormComponent
   ]
})
export class UsersNewComponent implements OnInit {
   companyOptions: any = [];
   unityOptions: any = [];
   companyPermissionsOptions: any = [];
   unityPermissionsOptions: any = [];
   departmentOptions: any = [];
   moduleOptions: any = [];
   rolesOptions: any = [];
   permissionsUser: any = [];
   rolePolicies: any = [];
   modulesByToken: Array<Object> = [];
   positionOptions: TooltipPosition[] = [
      "after",
      "before",
      "above",
      "below",
      "left",
      "right"
   ];
   position = new UntypedFormControl(this.positionOptions[3]);

   stateOptions: Array<Object> = [
      {
         value: "AC",
         label: "AC - Acre"
      },
      {
         value: "AL",
         label: "AL - Alagoas"
      },
      {
         value: "AP",
         label: "AP - Amapá"
      },
      {
         value: "AM",
         label: "AM - Amazonas"
      },
      {
         value: "BA",
         label: "BA - Bahia"
      },
      {
         value: "CE",
         label: "CE - Ceará"
      },
      {
         value: "DF",
         label: "DF - Distrito Federal"
      },
      {
         value: "ES",
         label: "ES - Espirito Santo"
      },
      {
         value: "GO",
         label: "GO - Goiás"
      },
      {
         value: "MA",
         label: "MA - Maranhão"
      },
      {
         value: "MT",
         label: "MT - Mato Grosso"
      },
      {
         value: "MS",
         label: "MS - Mato Grosso do Sul"
      },
      {
         value: "MG",
         label: "MG - Minas Gerais"
      },
      {
         value: "PA",
         label: "PA - Pará"
      },
      {
         value: "PB",
         label: "PB - Paraíba"
      },
      {
         value: "PR",
         label: "PR - Paraná"
      },
      {
         value: "PE",
         label: "PE - Pernambuco"
      },
      {
         value: "PI",
         label: "PI - Piauí"
      },
      {
         value: "RJ",
         label: "RJ - Rio de Janeiro"
      },
      {
         value: "RN",
         label: "RN - Rio Grande do Norte"
      },
      {
         value: "RS",
         label: "RS - Rio Grande do Sul"
      },
      {
         value: "RO",
         label: "RO - Rondônia"
      },
      {
         value: "SC",
         label: "SC - Santa Catarina"
      },
      {
         value: "SP",
         label: "SP - São Paulo"
      },
      {
         value: "SE",
         label: "SE - Sergipe"
      },
      {
         value: "TO",
         label: "TO - Tocantins"
      }
   ];

   public formPermission = new FormGroup({
      company: new FormControl<string | null>(null),
      unity: new FormControl<string | null>(null),
      module: new FormControl<string | null>(null),
      roles: new FormControl<any>(null)
   });

   public form = new FormGroup({
      email: new FormControl<string | null>(null),
      name: new FormControl<string | null>(null),
      type: new FormControl<number | null>(1),
      cellPhone: new FormControl<string | null>(null),
      cpf: new FormControl<string | null>(null),
      active: new FormControl<string | null>(null),
      admissionData: new FormControl<string | null>(null),
      resignationData: new FormControl<string | null>(null),
      alternativeEmail: new FormControl<string | null>(null),
      CEP: new FormControl<string | null>(null),
      address: new FormControl<string | null>(null),
      number: new FormControl<string | null>(null),
      complement: new FormControl<string | null>(null),
      neighborhood: new FormControl<string | null>(null),
      city: new FormControl<string | null>(null),
      state: new FormControl<string | null>(null),
      phone: new FormControl<string | null>(null),
      discountPercentage: new FormControl<string | null>(null),
      defaultCompany: new FormControl<string | null>(null),
      defaultUnity: new FormControl<string | null>(null),
      password: new FormControl<string | null>(null),
      confirmPassword: new FormControl<string | null>(null),
      department: new FormControl<any>(null)
   });

   title: string = "Novo Usuário";
   editUserName!: string;
   private _agreementSuppportFacade = inject(AgreementSuppportFacade);
   private _usersFacade = inject(UsersAdminUsersFacade);
   private _modulesFacade = inject(ModulesFacade);
   private _departmentFacade = inject(DepartmentsFacade);
   private _translateService = inject(TranslateService);
   private _route = inject(ActivatedRoute);

   _userId!: Guid;

   async ngOnInit() {
      this.getModules();
      const data = await this._agreementSuppportFacade.getCompanyOptions();

      this.companyOptions = data;

      this.companyPermissionsOptions.push({ label: "Todos", value: null });

      data.forEach((element: any) => {
         this.companyPermissionsOptions.push(element);
      });

      this.departmentOptions =
         await this._departmentFacade.getDepartmentOptions();

      if (this._route.snapshot.params["id"]) {
         this._userId = this._route.snapshot.params["id"];
         this.getPermissionsUser();
      }

      if (this._userId != undefined) {
         this.title = "Editar Usuário";
         await this.getUser();
      }

      setTimeout(() => {
         this.formPermission.controls.company.setValue(null);
         setTimeout(() => {
            this.formPermission.controls.unity.setValue(null);
         });
      });
   }

   async getUser() {
      const data = await this._usersFacade.getById(this._userId);
      this.editUserName = data.name;
      this.form.patchValue({
         email: data.accessCode,
         name: data.name,
         type: null,
         cellPhone: data.cellPhone,
         cpf: data.cpf,
         active: data.isActive,
         admissionData: data.admissionData.slice(0, 10),
         resignationData: data.admissionData.slice(0, 10),
         alternativeEmail: data.alternativeEmail,
         CEP: data.address.zipCode,
         address: data.address.street,
         number: data.address.number,
         complement: data.address.complement,
         neighborhood: data.address.neighborhood,
         city: data.address.city,
         state: data.address.state,
         phone: data.telephone,
         discountPercentage: data.discountPercentage,
         defaultCompany: data.companyId ?? null,
         defaultUnity: data.unityId ?? null,
         confirmPassword: "",
         password: "",
         department: data.departments.map((dep: any) => ({
            label: dep.department.name,
            value: dep.department.id
         }))
      });
      //@ts-ignore
      var is = this.form.controls.email.value.split("@");
      setTimeout(() => {
         if (is.length > 1) this.form.controls.type.setValue(1);
         else this.form.controls.type.setValue(2);
      });
   }

   async getModules() {
      const response = await this._usersFacade.getProfile();
      this.modulesByToken = response.data[0].modules;
      response.data[0].modules.forEach((module: any) => {
         if (module.name != "admin" && module.name != "default") {
            const obj = {
               value: module.id,
               label: this._translateService.instant(`modules.${module.name}`)
            };
            this.moduleOptions.push(obj);
         }
      });
   }

   changeCompany() {
      this.form.controls["defaultUnity"].setValue(null);
      this.unityOptions = this.companyOptions
         .filter(
            (x: any) => x.value == this.form.controls["defaultCompany"].value
         )[0]
         .unities.map((res: any) => ({
            value: res.value,
            label: res.label
         }));
   }

   changeCompanyPermission() {
      this.formPermission.controls["unity"].setValue(null);
      this.unityPermissionsOptions = [];

      this.unityPermissionsOptions.push({ label: "Todos", value: null });

      const data = this.companyPermissionsOptions
         .filter(
            (x: any) => x.value == this.formPermission.controls["company"].value
         )[0]
         .unities?.map((res: any) => ({
            value: res.value,
            label: res.label
         }));

      data?.forEach((element: any) => {
         this.unityPermissionsOptions.push(element);
      });
   }

   async create() {
      let departments: any = [];
      this.form.controls.department?.value?.forEach((element: any) => {
         departments.push(element.value);
      });
      const obj = {
         accessCode: this.form.controls.email.value,
         type: this.form.controls.type.value,
         name: this.form.controls.name.value,
         departments: departments,
         address: {
            zipCode: this.form.controls.CEP.value,
            street: this.form.controls.address.value,
            number: this.form.controls.number.value,
            complement: this.form.controls.complement.value,
            neighborhood: this.form.controls.neighborhood.value,
            city: this.form.controls.city.value,
            state: this.form.controls.state.value,
            country: "Brasil"
         },
         telephone: this.form.controls.phone.value,
         cellPhone: this.form.controls.cellPhone.value,
         alternativeEmail: this.form.controls.alternativeEmail.value,
         cpf: {
            value: this.form.controls.cpf.value
         },
         isActive: this.form.controls.active.value,
         unityId: this.form.controls.defaultUnity.value,
         discountPercentage: this.form.controls.discountPercentage.value,
         companyId: this.form.controls.defaultCompany.value,
         admissionData: this.form.controls.admissionData.value
      };

      const success = await this._usersFacade.postUser(obj);
   }

   async update() {
      let departments: any = [];

      this.form.controls.department?.value?.forEach((element: any) => {
         departments.push(element.value);
      });

      const obj = {
         accessCode: this.form.controls.email.value,
         type: this.form.controls.type.value,
         name: this.form.controls.name.value,
         departments: departments,
         address: {
            zipCode: this.form.controls.CEP.value,
            street: this.form.controls.address.value,
            number: this.form.controls.number.value,
            complement: this.form.controls.complement.value,
            neighborhood: this.form.controls.neighborhood.value,
            city: this.form.controls.city.value,
            state: this.form.controls.state.value,
            country: "Brasil"
         },
         telephone: this.form.controls.phone.value,
         cellPhone: this.form.controls.cellPhone.value,
         alternativeEmail: this.form.controls.alternativeEmail.value,
         cpf: {
            value: this.form.controls.cpf.value
         },
         isActive: this.form.controls.active.value,
         unityId: this.form.controls.defaultUnity.value,
         discountPercentage: this.form.controls.discountPercentage.value,
         companyId: this.form.controls.defaultCompany.value,
         admissionData: this.form.controls.admissionData.value
      };

      const success = await this._usersFacade.putUser(obj, this._userId);
   }

   async getPermissionsUser() {
      this.permissionsUser = [];
      let search = {};

      if (this.formPermission.controls.company.value) {
         //@ts-ignore
         search.companyId = this.formPermission.controls.company.value;
      }

      if (this.formPermission.controls.unity.value) {
         //@ts-ignore
         search.unityId = this.formPermission.controls.unity.value;
      }

      const obj = {
         path: "/" + this._userId + "/policy",
         query: search
      };

      var returnVar = await this._usersFacade.getAccessControl(obj);
      this.rolePolicies = returnVar.data[0].rolePolicies;

      // @ts-ignore
      returnVar.data[0].rolePolicies.forEach((y: any) => {
         y.resources.forEach((x: any) => {
            const obj: any = {
               id: x.id,
               name: x.name,
               isRole: true,
               access: true
            } as any;

            // @ts-ignore
            this.permissionsUser.push(obj);
         });
      });

      // @ts-ignore
      returnVar.data[0].userPolicies.forEach((y: any) => {
         const index = this.permissionsUser.findIndex(
            (element: any, index: number) => element.name === y.name
         );

         let obj = {};

         if (index != -1) {
            this.permissionsUser.splice(index, 1);
            obj = {
               id: y.id,
               name: y.name,
               isRole: false,
               access: y.access === "Allowed" ? true : false,
               access1: y.access
            };
         } else {
            obj = {
               id: y.id,
               name: y.name,
               isRole: false,
               access: y.access === "Allowed" ? true : false,
               access1: y.access
            };
         }
         // @ts-ignore
         this.permissionsUser.push(obj);
      });
   }

   async changeModule() {
      this.rolesOptions = [];
      if (this.formPermission.controls.module.value) {
         const obj = {
            query: {
               resume: true,
               isActive: true
            },
            path: "/" + this.formPermission.controls.module.value + "/role"
         };

         this.rolesOptions = await this._modulesFacade.getModuleOptions(obj);
      }
   }

   CheckIsItemModule(moduleName: string) {
      return this.permissionsUser.findIndex(
         (x: any) => x.name.split(":")[0] == moduleName
      ) != -1
         ? true
         : false;
   }

   CheckInSd(moduleName: string, array: Array<any>) {
      //@ts-ignore
      const index = array.findIndex((element: any, index: number) => {
         if (element.moduleName === moduleName) {
            return true;
         }
      });

      if (index != -1) return true;
      else return false;
   }

   CheckIsUserRole(resourceName: string) {
      const index = this.permissionsUser.findIndex(
         //@ts-ignore
         (element: any, index: number) => {
            if (element.name === resourceName && element.isRole === false) {
               return true;
            }
         }
      );

      if (index != -1) {
         // @ts-ignore
         return this.permissionsUser[index].access === true ? true : false;
      } else {
         return true;
      }
   }

   getRole(role: string, module: string): string {
      if (module === "occurrences") {
         return `roles.${role.replace("occurrencies:", "")}`;
      }

      if (module === "documents" && role.startsWith("document:")) {
         return `roles.${role.replace("document:", "")}`;
      }

      return `roles.${role.replace(`${module}:`, "")}`;
   }

   NegatePolicy(ResourceName: string, ResourceId: string) {
      const index = this.permissionsUser.findIndex(
         //@ts-ignore
         (element: any, index: number) => {
            if (element.name === ResourceName) {
               return true;
            }
         }
      );

      if (index != -1) {
         if (this.permissionsUser[index].isRole) {
            this.AddDeniedResourceRole(this.permissionsUser[index].id);
         } else {
            this.removeResource(this.permissionsUser[index].id);
         }
      } else {
         this.AddDeniedResourceRole(ResourceId);
      }
   }

   AddDeniedResourceRole(ResourceId: string) {
      const request: RequestOptions = {
         path: "/" + this._userId + "/policy"
      };

      const obj = {
         resourceId: ResourceId,
         accessPolicy: 0
      };

      this._usersFacade
         .postAccessControl(obj, request)
         .then(async (x: any) => {
            await this.getPermissionsUser();
            SwAlSetttings.Sucesso("Permissão removida com sucesso!");
         })
         .catch((err: any) => {
            SwAlSetttings.printError(err);
         });
   }

   GarantedPolicy(ResourceName: string, ResourceId: string) {
      const index = this.permissionsUser.findIndex(
         //@ts-ignore
         (element: any, index: number) => {
            if (element.name === ResourceName) {
               return true;
            }
         }
      );

      if (index != -1) {
         //@ts-ignore
         this.removeResource(this.permissionsUser[index].id);
      } else {
         //@ts-ignore
         this.addResource(ResourceId);
      }
   }

   removeResource(ResourceId: string) {
      const id = ResourceId;
      let ind = this.permissionsUser.findIndex((x: any) => x.id == id);
      let ind2 = this.permissionsUser.findIndex(
         (x: any) =>
            this.permissionsUser[ind].name == x.name && x.isRole === false
      );

      this._usersFacade
         .deleteAccessControl(
            //@ts-ignore
            this._userId + "/policy/" + this.permissionsUser[ind2].id
         )
         .then(async (x: any) => {
            await this.getPermissionsUser();
         })
         .catch((err: any) => {
            SwAlSetttings.printError(err);
         });
   }

   async deleteRole(policy: string, policyName: string) {
      let ls = await SwAlSetttings.Confirmar(
         "Tem certeza que deseja excluir o papel " + policyName + " do usuário?"
      );
      if (ls.isConfirmed) {
         const request: any = {
            body: {
               rolesId: [policy]
            }
         };

         this._usersFacade
            .deleteAccessControl(
               //@ts-ignore
               this._userId + "/rolepolicy",
               request
            )
            .then(async (x: any) => {
               SwAlSetttings.Sucesso("Papel excluído com sucesso!");
               await this.getPermissionsUser();
               await this.getModules();
            })
            .catch((err: any) => {
               SwAlSetttings.printError(err);
            });
      }
   }

   addRoletoUser() {
      let ids: any = [];
      this.formPermission.controls.roles.value.forEach((element: any) => {
         ids.push(element.value);
      });
      const post: any = {
         rolesId: ids
      } as any;

      if (this.formPermission.controls.company.value) {
         post.companyId = this.formPermission.controls.company.value;
      }

      if (this.formPermission.controls.unity.value) {
         post.unityId = this.formPermission.controls.unity.value;
      }

      const request: RequestOptions = {
         path: "/" + this._userId + "/rolepolicy"
      };

      this._usersFacade
         .postAccessControl(post, request)
         .then(async (x) => {
            await this.getUser();
            await this.getPermissionsUser();
            await this.getModules();
            SwAlSetttings.Sucesso("Papel adicionado com sucesso!");
         })
         .catch((error) => {
            SwAlSetttings.printError(error);
         });
   }
}
