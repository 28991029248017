import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IpAddressesReleasedFacade } from "@app/modules_new/administrativeData/data/facade/ip-addresses-released-facade";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ModalLayoutComponent } from "@app/modules_new/shared/components/form/modal/modal.component";
import { TextAreaFormComponent } from "@app/modules_new/shared/components/form/textarea/textarea.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "app-ip-addresses-released-modal",
   templateUrl: "./ip-addresses-released-modal.component.html",
   styleUrls: ["./ip-addresses-released-modal.component.css"],
   standalone: true,
   imports: [
      ModalLayoutComponent,
      TranslateModule,
      InputTextFormComponent,
      ReactiveFormsModule,
      TextAreaFormComponent,
      ButtonFormComponent,
      CommonModule
   ]
})
export class IpAddressesReleasedModalComponent implements OnInit {
   constructor() {}
   public _sector = inject(MAT_DIALOG_DATA);

   form = new FormGroup({
      search: new FormControl(),
      description: new FormControl(this._sector?.description, [
         Validators.required
      ]),
      ipAddress: new FormControl(this._sector?.ipAddress, [
         Validators.required,
         Validators.pattern(
            /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/
         )
      ])
   });

   public _IpAddressesReleasedFacade = inject(IpAddressesReleasedFacade);

   title: string = "ip_address.modal.new";

   ngOnInit() {
      if (this._sector) this.title = "ip_address.modal.edit";
   }

   submit() {
      if (this._sector) {
         this._IpAddressesReleasedFacade.put(this.form.value, this._sector.id);
      } else {
         this._IpAddressesReleasedFacade.post(this.form.value);
      }
   }
}
