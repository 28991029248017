<app-form [title]="edit ? 'doctor.register.edit' : 'doctor.register.new' ">
  <div class="bodyForm">
    <div class="contextPage mt-32" [ngClass]="disable? 'disable':''">
      <div class="flex flex-between align-center">
        <h2>{{'doctor.register.general-information' | translate}}</h2>
      </div>
      <div class="flex mt-32 gap-form">
        <div class="w-100">
          <input-prop placeholder="global.form.name" [form]="form.doctorName"></input-prop>
        </div>
        <div class="w-100">
          <input-prop placeholder="global.form.cpf" [form]="form.doctorCPF" [mask]="'000.000.000-00'"></input-prop>
        </div>
        <div class="w-100">
          <select-prop placeholder="global.form.biological-sex" [form]="form.doctorGender" [options]="gender"></select-prop>
        </div>
      </div>
      <div class="flex mt-32 gap-form">
        <div class="w-100">
          <select-prop placeholder="global.form.select-council" [form]="form.doctorCouncil" [options]="council"></select-prop>
        </div>
        <div class="w-100">
          <input-prop placeholder="global.form.registration-number" [form]="form.doctorRegistrationNumber" type="number"></input-prop>
        </div>
        <div class="w-100">
          <select-prop placeholder="global.form.registration-state" [form]="form.doctorRegistrationState" [options]="registerUF"></select-prop>
        </div>
      </div>
      <div class="flex mt-32 gap-form">
        <div class="w-100">
          <input-prop placeholder="global.form.phone" [form]="form.doctorCellphone" type="phone" [mask]="'(00) 0 0000-0000'"></input-prop>
        </div>
        <div class="w-100">
          <input-prop placeholder="global.form.office-phone" [form]="form.doctorOfficePhone" type="phone" [mask]="'(00) 0000-0000'"></input-prop>
        </div>
        <div class="w-100">
          <input-prop placeholder="global.form.email" type="email" [form]="form.doctorEmail"></input-prop>
        </div>
      </div>
      <div class="flex flex-between align-center gap-form">
        <h2 class="flex gap-form mt-32">{{'doctor.register.address' | translate}}</h2>
        <checkbox-prop class="mt-32" [gap]="'1'" [label]="'doctor.register.reports-address'" [form]="form.allowsDelivery"></checkbox-prop>
      </div>
        <div class="flex gap-form mt-32 ">
          <div class="w-100">
            <input-prop class="w-100" (focusout)="getCep()" placeholder="global.address.zip-code" mask="00.000-000" [form]="form.addressCEP"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="global.address.street" [form]="form.address"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="global.address.number" [form]="form.addressNumber" type="number"></input-prop>
          </div>
        </div>
        <div class="flex gap-form mt-32">
          <div class="w-100">
            <input-prop placeholder="global.address.complement" [form]="form.addressComplement"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="global.address.neighborhood" [form]="form.addressDistrict"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="global.address.city" [form]="form.addressCity"></input-prop>
          </div>
        </div>
        <div class="flex gap-form mt-32">
          <div class="w-100">
            <input-prop placeholder="global.address.state" [form]="form.addressState"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="global.address.country" [form]="form.addressCountry"></input-prop>
          </div>
          <div class="w-100">
          </div>
        </div>
      <div class="flex flex-center mt-32 btn-moblie">
        <app-button (click)="doctorId? updateDoctor():createDoctor()" [placeholder]="'global.save'"></app-button>
      </div>
    </div>
  </div>
</app-form>
