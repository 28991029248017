import { HttpClient } from '@angular/common/http';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { Injectable } from '@angular/core';
import { Guid } from '@app/util/guid';
import { GetTemplate, PostTemplate } from './template.types';

@Injectable({
  providedIn: 'root'
})

export class TemplateService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/template');
  }

  override post(body: Object) {
    return super.post(body) as Promise<HttpResponsePageable<PostTemplate>>
  }

  override getByPath(queryString: string, path: string, headers?: String[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetTemplate>>
  }
}