import { Injectable } from '@angular/core';
import { HttpAuthService } from './http-auth.service';
import { BaseAuthService } from './base-auth.service';

@Injectable({
    providedIn: 'root'
})
export class ForgotPasswordService extends BaseAuthService {
    constructor(HttpAuthService: HttpAuthService) {
        super(HttpAuthService, 'v1/public/user/forgot');
    }
}
