import { DefaultFormats } from "@app/pipe/localeDate.pipe"
import { Guid } from "@app/util/guid"

export enum EType {
  VISUALIZAR = 0,
  EDITAR = 1,
  NOVO = 2
}

export interface ActionsQuestion {
  action: string,
  id: Guid,
  context: string | null,
  object: ObjectQuestion
}

export interface ObjectQuestion {
  Ativo: string,
  'Atualizado em': string,
  'Criado em': string,
  'Código': string,
  Pergunta: string,
  id: Guid
}

export interface ActionsMaterial {
  action: string,
  id: string,
  context: string | null,
  object: ObjectMaterial
}

export interface ObjectMaterial {
  Ativo: string,
  'Atualizado Em': string,
  'Criado Em': string,
  'Código': string,
  'Material': string,
  Prazo: string,
  'Prazo Urgente': string,
  id: Guid,
  _hide_id: Guid
}

export interface ActionsRecipient {
  action: string,
  id: Guid,
  context: string | null,
  object: ObjectRecipient
}

export interface ObjectRecipient {
  'Código': string,
  Recipiente: string,
  id: string,
  _hide_id: Guid
}

export interface ActionsAttributes {
  action: string,
  id: Guid,
  context: string | null,
  object: ObjectAttributes
}

export interface ObjectAttributes {
  Ativo: string,
  'Atualizado em': string,
  'Criado em': string,
  Nome: string,
  Obrigatório: string,
  Tipo: string,
  id: Guid
}

export interface ActionsReferenceAtribute {
  action: string,
  id: Guid,
  context: string | null,
  object: ObjectReferenceAtribute
}

export interface ObjectReferenceAtribute {
  Ativo: string,
  Gênero: string,
  'Idade máxima': string,
  'Idade mínima': string,
  Nome: string,
  'Tipo (máxima)': string,
  'Tipo (mínima)': string,
  'Valor máximo': string,
  'Valor mínimo': string,
  id: Guid
}

export interface ActionsReport {
  action: string,
  id: Guid,
  context: string | null,
  object: ObjectReport
}

export interface ObjectReport {
  Ativo: string,
  'Atualizado em': string,
  'Criado em': string,
  'Descritivo Material': string,
  Material: string,
  Metodologia: string,
  Nome: string,
  id: Guid,
  _hide_materialId: Guid,
  _hide_methodologyId: Guid
}

export interface ActionsAtributeReport {
  action: string,
  id: Guid,
  context: string | null,
  object: ObjectAtributeReport
}

export interface ObjectAtributeReport {
  Ativo: string,
  Nome: string,
  Ordem: string,
  id: Guid
}

export interface ActionsParameters {
  action: string,
  id: Guid,
  context: string | null,
  object: ObjectAtributeReport
}

export interface ActionsConditional {
  action: string,
  id: Guid,
  context: string | null,
  object: ObjectConditional
}

export interface ObjectConditional {
  Ativo: string,
  Atributo: string,
  'Condição Númerico Final': string,
  'Condição Númerico Inicial': string,
  'Condição Texto': string,
  Texto: string,
  Tipo: string,
  'Valor Final': string,
  'Valor Inicial': string,
  id: Guid,
  _hide_atributeAnalyteId: Guid
}

export interface ActionsConditionsText {
  action: string,
  id: Guid,
  context: string | null,
  object: ObjectConditionsText
}

export interface ObjectConditionsText {
  Ativo: string,
  Parâmetro: string,
  id: Guid
}

export interface ActionsOptionsList {
  action: string,
  id: Guid,
  context: string | null,
  object: ObjectOptionsList
}

export interface ObjectOptionsList {
  id: Guid,
  Valor: string,
  Ativo: string
}

export interface Get {
  isActive: boolean,
  parameter: number,
  createdAt: string,
  updatedAt: string,
  normalDeadLine: number,
  urgentDeadLine: number,
  emergencyDeadLine: string,
  externalId: string,
  typeAttribute: number,
  isRequired: boolean,
  genderType: number,
  typeAtributeReference: string,
  typeAgeMax: number,
  typeAgeMin: number,
  typeAnalisys: number,
  typeBlockedText: number,
  typeBlockedNumericFrom: number,
  typeBlockedNumericTo: number,
  isAuxiliar: string
}

export interface ConditionalParameter {
  "Ativo": string
  "Atributo": string
  "Condição Númerico Final": string
  "Condição Númerico Inicial": string
  "Condição Texto": string
  "Texto": string
  "Tipo": string
  "Valor Final": number | string
  "Valor Inicial": number | string
  _hide_edit: string
  _hide_atributeAnalyteId: string
  id: string
}

export const resolveSupportLaboratories: Array<Object> = [
  {
    label: "id",
    method: '',
    retrive: "id",
    after: '',
    before: ''
  },
  {
    label: 'analyteLaboratories.name',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'supportLaboratoryName',
    type: 'name',
  },
  {
    label: 'analyteLaboratories.codeInSupportLaboratory',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'codeInSupportLaboratory',
    type: 'name',
  },
  {
    label: 'analyteLaboratories.typeComunicationName',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'typeComunicationName',
    type: 'name',
  },
  {
    label: 'analyteLaboratories.materialInSupportLaboratory',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'materialInSupportLaboratory',
    type: 'name',
  },
];

export const resolveQuestions: Array<Object> = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: "analyteQuestion.code",
    method: '',
    retrive: 'questionExternalId',
    after: '',
    before: '',
    fieldName: '',
    type: 'description',
  },
  {
    label: "analyteQuestion.description",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'questionName',
    type: 'description',
  },
  {
    label: "analyteQuestion.isrequired",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'isRequired',
    type: 'boolean',
  },
  {
    label: 'analyteQuestion.createdAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'createdAt'
  },
  {
    label: 'analyteQuestion.updatedAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'updatedAt'
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'checkbox',
    sort: 'isActive'
  },
];

export const resolveOptionsList = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Valor',
    retrive: 'description',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'getActive',
    after: '',
    before: ''
  }
];

export const resolveParameters = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'analyteParameters.description',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'parameterDescription',
    type: 'description',
  },
  {
    label: 'analyteParameters.createdAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'createdAt'
  },
  {
    label: 'analyteParameters.updatedAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'updatedAt'
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'checkbox',
    sort: 'isActive'
  },
];

export const resolveConditionalParameter = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: "Parâmetro",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'resultText',
    type: 'description',
  },
  {
    label: 'Criado em',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'createdAt'
  },
  {
    label: 'Alterado em',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'updatedAt'
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'checkbox',
    sort: 'isActive'
  },
]

export const resolveRecipients = [
  {
    label: 'id',
    retrive: 'recipientId',
    method: '',
    after: '',
    before: ''
  },
  {
    label: '_hide_id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'analyteMaterial.code',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'recipientExternalId',
    type: 'description',
  },
  {
    label: 'analyteMaterial.description',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'description',
    type: 'description',
  },
  {
    label: 'analyteMaterial.createdAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'createdAt'
  },
  {
    label: 'analyteMaterial.updatedAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'updatedAt'
  },
];

export const resolveAtribute = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'analyteAtributo.description',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'name',
    type: 'description',
  },
  {
    label: 'analyteAtributo.type',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'typeDescription',
    type: 'description',
  },
  {
    label: 'analyteAtributo.isRequired',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isRequired',
    type: 'boolean',
  },
  {
    label: 'analyteAtributo.createdAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'createdAt'
  },
  {
    label: 'analyteAtributo.updatedAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'updatedAt'
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'checkbox',
    sort: 'isActive'
  },
];

export const resolveAtributeReport = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'reports.name',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'atributeAnalyteName',
    type: 'description',
  },
  {
    label: 'reports.orderNumber',
    retrive: 'orderNumber',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'orderNumber',
    type: 'description',
  },
  {
    label: 'reports.createdAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'createdAt'
  },
  {
    label: 'reports.updatedAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'updatedAt'
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'checkbox',
    sort: 'isActive'
  },
];

export const resolveAtributeReference = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'analyteAtributo.resolveAtributeReference.name',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'name',
    type: 'description',
  },
  {
    label: 'analyteAtributo.resolveAtributeReference.typeMin',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'ageMin',
    interpolationFields: 'ageMin, typeAgeMin',
    type: 'concatenationAge',
  },
  {
    label: 'analyteAtributo.resolveAtributeReference.typeMax',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'ageMax',
    interpolationFields: 'ageMax, typeAgeMax',
    type: 'concatenationAge',
  },
  {
    label: 'analyteAtributo.resolveAtributeReference.valueMin',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'valueMin',
    type: 'description',
  },
  {
    label: 'analyteAtributo.resolveAtributeReference.valueMax',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'valueMax',
    type: 'description',
  },
  {
    label: 'analyteAtributo.resolveAtributeReference.gender',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'genderType',
    type: 'genderType',
  },
  {
    label: 'analyteAtributo.resolveAtributeReference.createdAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'createdAt'
  },
  {
    label: 'analyteAtributo.resolveAtributeReference.updatedAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'updatedAt'
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'checkbox',
    sort: 'isActive'
  },
];

export const resolveConditional = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: '_hide_atributeAnalyteId',
    retrive: 'atributeAnalyteId',
    method: '',
    after: '',
    before: ''
  },
  {
    label: "analyteCondition.atributeAnalyteName",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'atributeAnalyteName',
    type: 'description',
  },
  {
    label: "analyteCondition.type",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'typeAnalisysDescription',
    type: 'description',
  },
  {
    label: "analyteCondition.typeBlockedText",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'typeBlockedTextDescription',
    type: 'description',
  },
  {
    label: "analyteCondition.textAnalisys",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'textAnalisys',
    type: 'description',
  },
  {
    label: "analyteCondition.typeBlockedNumericFrom",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'typeBlockedNumericFromDescription',
    type: 'description',
  },
  {
    label: "analyteCondition.startNumber",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'startNumber',
    type: 'description',
  },
  {
    label: "analyteCondition.typeBlockedNumericTo",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'typeBlockedNumericToDescription',
    type: 'description',
  },
  {
    label: "analyteCondition.finishNumber",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'finishNumber',
    type: 'description',
  },
  {
    label: 'analyteCondition.createdAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'createdAt'
  },
  {
    label: 'analyteCondition.updatedAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'updatedAt'
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'checkbox',
    sort: 'isActive'
  },
];

export const resolveReport = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: '_hide_methodologyId',
    retrive: 'methodologyId',
    method: '',
    after: '',
    before: ''
  },
  {
    label: '_hide_materialId',
    retrive: 'materialId',
    method: '',
    after: '',
    before: ''
  },
  {
    label: "reports.name",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'name',
    type: 'description',
  },
  {
    label: "reports.material",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'materialDescription',
    type: 'description',
  },
  {
    label: "reports.description",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'materialReportName',
    type: 'description',
  },
  {
    label: "reports.methodologyName",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'methodologyName',
    type: 'description',
  },
  {
    label: 'reports.createdAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'createdAt'
  },
  {
    label: 'reports.updatedAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'updatedAtt'
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'checkbox',
    sort: 'isActive'
  },
];

export const resolveMaterials = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: '_hide_id',
    retrive: 'materialId',
    method: '',
    after: '',
    before: ''
  },
  {
    label: '_hide_isDefaultMaterial',
    retrive: 'isDefaultMaterial',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'analyteMaterial.code',
    retrive: '',
    method: 'getCode',
    after: '',
    before: '',
    fieldName: 'externalId',
    type: 'description',
  },
  {
    label: "analyteMaterial.description",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'description',
    type: 'description',
  },
  {
    label: "analyteMaterial.normalDeadLine",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'normalDeadLine',
    type: 'day',
  },
  {
    label: "analyteMaterial.urgentDeadLine",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'urgentDeadLine',
    type: 'day',
  },
  {
    label: "analyteMaterial.emergencyDeadLine",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'emergencyDeadLine',
    type: 'description',
  },
  {
    label: "analyteMaterial.material",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'isDefaultMaterial',
    type: 'boolean',
  },
  {
    label: 'analyteMaterial.createdAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'createdAt'
  },
  {
    label: 'analyteMaterial.updatedAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'updatedAt'
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'checkbox',
    sort: 'isActive'
  },
];