import { DefaultFormats } from "@app/pipe/localeDate.pipe";

export interface Actions {
    action: string,
    id: string,
    context: string | null,
    object: ObjectAnalyte
}

export interface ObjectAnalyte{
    Ativo: string,
    Código: string,
    Departamento: string,
    Nome: string,
    Tercerizado: string,
    id: string
}

export interface ResolveAnalytes{
    analyteOrdemReport: number,
    analyteSequenceReport: number,
    code : string,
    createdAt : string,
    departmentId: string,
    departmentName: string,
    id: string,
    isActive: boolean,
    isOutsourced: boolean,
    name: string,
    nameInvoice: string | null,
    restrictGender: number,
    typeResultWeb: number,
    updatedAt: string
}

export const resolveAnalytes = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'analyte.code',
      retrive: 'code',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'code',
      type: 'description',
    },
    {
      label: 'analyte.name',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'name',
      type: 'description',
    },
    {
      label: 'analyte.nameInvoice',
      method: 'formatField',
      retrive: '',
      after: '',
      before: '',
      fieldName: 'nameInvoice',
      type: 'description',
    },
    {
      label: 'analyte.departmentName',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'sectorName',
      type: 'description',
    },
    {
      label: 'analyte.isOutsourced',
      method: 'formatField',
      retrive: '',
      after: '',
      before: '',
      fieldName: 'isOutsourced',
      type: 'boolean',
    },
    {
      label: 'analyte.createdAt',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'createdAt',
      type: 'date',
      dateFormat: DefaultFormats.full,
      sort: 'createdAt'
    },
    {
      label: 'analyte.updatedAt',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'updatedAt',
      type: 'date',
      dateFormat: DefaultFormats.full,
      sort: 'updatedAt'
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'isActive',
      type: 'checkbox',
      sort: 'isActive'
    },
  ];