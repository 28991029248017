import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { trigger, transition, style, animate } from '@angular/animations';
import { SlideInOutAnimation } from 'src/app/animations';
import { environment } from "../../../../../environments/environment";

import Menu from './menu.json';
import MenuHomolog from './menu-homolog.json';

@Component({
  selector: 'menu-admin',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [SlideInOutAnimation]
})
export class MenuAdminComponent implements OnInit {

  @Input() menuopen : boolean = false;
 
  name = 'Angular 5';
  animationState = 'in';

  MenuObject : Array<{name: string, svg: string, href:string, subMenu:Array<{name: string, svg: string, href:string}>}> = environment.production ? Menu : MenuHomolog;
  newSteps:Array<SafeHtml> = [];
  MenuActive : Array<Number> = [];

  @Output() closeMenuEmit : EventEmitter<any> = new EventEmitter();

  constructor(private _sanitizer:DomSanitizer) {  
  }

  ngOnInit(): void {
    for (let i = 0; i < this.MenuObject.length; i++) {
      this.newSteps.push(this._sanitizer.bypassSecurityTrustHtml(this.MenuObject[i].svg));
    }
  }

  closeMenu() {
    this.closeMenuEmit.emit({changeMenuOpen : false});
    return true;
  }

  toggleShowDiv(index : Number) {
    const _indexOf = this.MenuActive.indexOf(index);
    if(_indexOf == -1)
      this.MenuActive.push(index);
    else 
      this.MenuActive.splice(_indexOf, 1);
  }
}
