import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FileDocumentService } from 'src/app/services/files/fileDocument.service';
import { SwAlSetttings } from 'src/app/util/swal.settings';
import { FormFile } from './file-multiple.types';
import { GActions } from '@app/util/GActions';
import { FileDocumentPostObject } from '@app/services/files/fileDocument.types';
import { Guid } from '@app/util/guid';

@Component({
  selector: 'file-prop-multiple',
  templateUrl: './file-prop.component.html',
  styleUrls: ['./file-prop.component.scss']
})
export class FilePropMultipleComponent implements OnInit {
  constructor(private _fileDocumentService: FileDocumentService, private _GActions : GActions) { }
  
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef | undefined;
  files: any[] = [];

  @Input() dashed: boolean = true;
  @Input() formFiles = new UntypedFormControl([])
  @Input() resetOnUpdate: boolean = false;

  @Output() onDeleteFile = new EventEmitter<Guid>();

  _hasInitialValues: boolean = false

  ngOnInit(): void {
    if(this.formFiles.value) {
      (this.formFiles.value as FormFile[]).forEach(file => {
        this.files.push({
          name: file.name,
          size: 0
        });
      })
      this._hasInitialValues = true
    }
  } 

  /**
   * on file drop handler
   */
  //@ts-ignore
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  //@ts-ignore
  fileBrowseHandler(event : ChangeEvent) {
    this.prepareFilesList(event.target.files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      return;
    }
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    SwAlSetttings.loader();
    if(this._hasInitialValues && this.resetOnUpdate) {
      this.files = [];
    }
    for (const item of files) {
      const reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onload = () => {
        //@ts-ignore
        let base64 = reader.result.toString();
        base64 = base64.substring(base64.lastIndexOf(',') + 1);
        const fileUpload = {
          base64: base64,
          name: item.name
        };

        this.upload(fileUpload)

        SwAlSetttings.closeLoader();
      };
      this.files.push(item);
    }
    //@ts-ignore
    this.fileDropEl.nativeElement.value = "";
    // this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  //@ts-ignore
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  
  onChange(value : String) { 
    this.formFiles.setValue([...value])
  }

  upload(fileUpload: FileDocumentPostObject | undefined): void {
    this._fileDocumentService.post(fileUpload!)
    .then((res) => {
      if(this._hasInitialValues && this.resetOnUpdate) {
        this.formFiles.setValue([])
      }
      this.formFiles.setValue([
        ...this.formFiles.value,
        { id: res.id, name: fileUpload?.name }
      ])
    }).catch((err) => {  
      SwAlSetttings.printError(err);
    });
  }

  async download(index: number) {
    SwAlSetttings.loader();
    const fileSelected = (this.formFiles.value as FormFile[])[index]
    await this._GActions.downloadTypeFile(fileSelected.id);
    SwAlSetttings.closeLoader();
  }

  async Excluir(index: number) {
    await SwAlSetttings.ALERT_CONFIRM.fire().then((r) => {
      if(r.isConfirmed) {
        const fileSelected = (this.formFiles.value as FormFile[])[index]
        this._GActions.deleteFileId(fileSelected.id).then((x) => {
          this.files = [];
          this.formFiles.setValue([
            ...(this.formFiles.value as FormFile[]).filter(item => item.id !== fileSelected.id)
          ])
          this.onDeleteFile.emit(new Guid(fileSelected.id))
          SwAlSetttings.Sucesso('Arquivo excluido!');
        }).catch(error => SwAlSetttings.printError(error));
      }
    })
  }

}
