import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http.service';
import { BaseService } from '@app/services/base.service';
import { HttpResponsePageable } from '@app/services/services.types';
import { AnalyteMaterial } from './analyte-material';
import { Guid } from '@app/util/guid';

@Injectable({
  providedIn: 'root'
})

export class AnalyteMaterialService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/analytematerial');
  }

  override getByPath(queryString: string, path: string, headers?: String[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<any>>
  }

  override getById(id: Guid){
    return super.getById(id) as Promise<HttpResponsePageable<AnalyteMaterial>>
  }
}