import { Component, OnInit } from '@angular/core';
import { AnalyteService } from '@app/services/analyte/analyte.service';
import { SwAlSetttings } from '@app/util/swal.settings';
import { GetAllAnalytes } from '@app/services/analyte/analyte.types';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DepartmentService } from '@app/services/department/department.service';
import { DefaultFormats, LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { Util } from '@app/util/util';
import { RegistrationReportComponent } from '@app/components/form/registration-report/registration-report.component';
import { HttpClient } from '@angular/common/http';
import { TenantAuthService } from '@app/services/auth/tenantAuth/tenant-auth.service';
import { AbstractReport, HeaderMapping, ReportFilter } from '@app/modules/reports/reports-model';
import { GroupProductService } from '@app/services/stock/group-product.service';

@Component({
  selector: 'app-group-product-report',
  templateUrl: './group-product-report.component.html',
  styleUrls: ['./group-product-report.component.scss']
})
export class GroupProductReportComponent extends AbstractReport implements OnInit {
  override sumRecords!: number;
  filterForm = new UntypedFormGroup({
    code: new UntypedFormControl(null),
    name: new UntypedFormControl(null),
    isOutsourced: new UntypedFormControl('todos'),
    department: new UntypedFormControl('todos'),
    isActive: new UntypedFormControl('todos'),
  });

  reportObject: Array<Object> = [];
  resolvedAnalytes: Array<Object> = [];
  index: number = 50;
  page: number = 1;
  total: number = 0;

  departmentOptions: Array<{ label: string, value: string | null }> = [{ label: 'Todos', value: 'todos' }];
  isActiveOptions: Array<{ label: string, value: string | null | boolean }> = [{ label: 'Todos', value: 'todos' }, { label: 'Somente Ativos', value: true }, { label: 'Somente Inativos', value: false }]
  isOutsourcedOptions: Array<{ label: string, value: string | null | boolean }> = [{ label: 'Todos', value: 'todos' }, { label: 'Somente Terceirizados', value: true }, { label: 'Somente Internos', value: false }]

  override reportDescription: string = 'Relatório de Grupo de Estoques';
  override reportFilters: ReportFilter[][] = [];

  override headerMapping: HeaderMapping = {
    description: { title: 'Nome', width: '*' },    
    createdAt: { title: 'Criado em', width: 'auto' },
    updatedAt: { title: 'Atualizado em', width: 'auto' },
    isActive: { title: 'Ativo', width: 'auto' }
  };

  resolveStock = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: ''
    },
    {
      label: 'Nome',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'formatField',
      fieldName: 'createdAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'formatField',
      fieldName: 'updatedAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'formatField',
      fieldName: 'isActive',
      type: 'boolean'

    }
  ]

  override receivedData!: any[];
  receivedDataForPrint!: object[];

  constructor(    
    private _groupService: GroupProductService,
    private _localeDate: LocalaDatePipe,
    private _http: HttpClient,
    private _tenantAuth: TenantAuthService,
    private _util: Util,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    await this.getData();    
  }

  resolveGenericLocal(data: any, columns: any) {
    this.resolvedAnalytes = [];
    data.forEach((x: any) => {
        let obj: any = {};
        columns.forEach((y: any) => {
          if (!y.retrive) {
            obj[y.label] = this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat, y.options);
          } else {
            obj[y.label] = x[y.retrive.toString()];
          }
        });
      this.resolvedAnalytes.push(obj);
    });
  }

  override async getData(paramPage?: number[]): Promise<void> {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    const queryObject = {      
      Search: this.filterForm.controls['name'].value,      
      isActive: this.filterForm.controls['isActive'].value === 'todos' ? null : JSON.parse(this.filterForm.controls['isActive'].value),      
    };

    await this._groupService.getPagination<any>(queryObject).then(
      (res) => {
        this.total = res.sumRecords;
        this.receivedData = res.data;
        this.resolveGenericLocal(res.data, this.resolveStock);
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  async getDataForPrint() {
    this.reportObject = []
    const queryObject = {      
      name: this.filterForm.controls['name'].value,      
      isActive: this.filterForm.controls['isActive'].value === 'todos' ? null : JSON.parse(this.filterForm.controls['isActive'].value),      
    };

    await this._groupService.getPagination<any>(queryObject).then(
      (res) => {
        res.data.forEach((x) => {
          const obj = {
            description: x.description,
            createdAt: x.createdAt,
            updatedAt: x.updatedAt,
            isActive: x.isActive ? 'Ativo': 'Inativo',
          }
          this.reportObject.push(obj)
        })
        this.sumRecords = res.sumRecords;
        this.receivedDataForPrint = this.reportObject;
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  async generatePdf() {
    await this.getDataForPrint();        
    var filters = [];
    var filterActive = 'Todos';
    if (this.filterForm.controls['isActive'].value != 'todos'){
      if (this.filterForm.controls['isActive'].value == 'true'){
        filterActive = 'Somente Ativos';
      } else {
        filterActive = 'Somente Inativos';
      }
    }   

    var obj = {
      title: 'Ativos: ', content : filterActive
    }
    
    filters.push(obj)

    if (this.filterForm.controls['name'].value){
      obj = {
        title: 'Descrição: ', content : this.filterForm.controls['name'].value
      } 
      filters.push(obj)
    }   
    
    this.reportFilters = [filters]
    const reportComponent = new RegistrationReportComponent(this._http, this._localeDate, this._tenantAuth);
    reportComponent.headerMapping = this.headerMapping;
    reportComponent.reportFilters = this.reportFilters;
    reportComponent.receivedData = this.receivedDataForPrint;
    reportComponent.reportDescription = this.reportDescription;
    reportComponent.sumRecords = this.sumRecords;

    await reportComponent.ngOnInit();
  }  

  resetFilter(){
    this.filterForm.controls['name'].reset();
    this.filterForm.controls['isActive'].reset('todos');
  }
}