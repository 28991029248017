import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { SupportLaboratoryService } from "@app/services/support-laboratory/support-laboratory.service";
import { Guid } from "@app/util/guid";
import { SwAlSetttings } from "@app/util/swal.settings";
import {
   Actions,
   ResolveSupportLaboratory,
   resolveLaboratory
} from "./support-laboratory.model";
import { CheckboxSvgPipe } from "@app/pipe/checkbox-Svg.pipe";
import { Util } from "@app/util/util";
import { ExamsSupportLaboratoryService } from "@app/services/exams-support-laboratory/exams-support-laboratory.service";
import { MatDialog } from "@angular/material/dialog";
import { AnalyteService } from "@app/services/analyte/analyte.service";
import {
   OptionMaterial,
   resolveToFromExams
} from "./save-laboratory/add-laboratory.model";
import {
   ExamsSupportLaboratoryPostobject,
   ExamsSupportLaboratoryPutObject
} from "@app/services/exams-support-laboratory/exams-support-laboratory";
import { ReportService } from "@app/services/report/report.service";
import { GetByPathReport } from "@app/services/report/report.service.model";
import { AnalyteMaterialService } from "@app/services/analyte-material/analyte-material.service";

@Component({
   selector: "app-support-laboratory",
   templateUrl: "./support-laboratory.component.html",
   styleUrls: ["./support-laboratory.component.scss"]
})
export class SupportLaboratoryComponent implements OnInit {
   total: number = 0;
   page: number = 1;
   index: number = 50;

   pageExam: number = 50;
   indexExam: number = 1;
   totalExam: any = {};

   formOptionsAnalyte: Array<Object> = [];

   formOptionsMaterial: Array<OptionMaterial> = [];

   analyteReportOptions: Array<{ value: string; label: string }> = [];

   apoioId: any = {};

   registerLaboratory: Array<ResolveSupportLaboratory> = [];

   registerExams: any = {};

   search = new UntypedFormControl();
   isActive = new UntypedFormControl();

   ActionsExtra = {
      Ativo: "PutActive"
   };

   toFromExamsFormGroup = new UntypedFormGroup({
      analyteId: new UntypedFormControl(null),
      materialId: new UntypedFormControl(null),
      analyteReportId: new UntypedFormControl(null),
      codeInSupportLaboratory: new UntypedFormControl(null),
      material: new UntypedFormControl(null),
      value: new UntypedFormControl(null),
      reference: new UntypedFormControl(null),
      hasRelationship: new UntypedFormControl(null)
   });

   constructor(
      private _router: Router,
      private _supportLaboratoryService: SupportLaboratoryService,
      private _checkboxSvgPipe: CheckboxSvgPipe,
      private _util: Util,
      private _examsSupportLaboratoryService: ExamsSupportLaboratoryService,
      private _dialog: MatDialog,
      private _analyteService: AnalyteService,
      private _reportService: ReportService,
      private _analyteMaterialService: AnalyteMaterialService
   ) {}

   @ViewChild("dialogExam")
   dialogExam!: TemplateRef<any>;

   ngOnInit() {
      this.getSupportLaboratory();
   }

   resolve(data: any, columns: any) {
      this.registerLaboratory = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registerLaboratory.push(obj);
      });
   }

   async getSupportLaboratory(paramPage?: number[]) {
      this.registerLaboratory = [];
      if (paramPage) {
         this.page = paramPage[0];
         this.index = paramPage[1];
      }

      const search: string = this.search.value ? this.search.value : null;
      const isActive: string = this.isActive.value ? this.isActive.value : null;

      await this._supportLaboratoryService
         .getPagination({
            page: this.page,
            index: this.index,
            search,
            isActive
         })
         .then((res) => {
            this.total = res.sumRecords;
            if (res.data.length > 0) {
               this.resolve(res.data, resolveLaboratory);
            }
         });
   }

   actions(emit: Actions) {
      switch (emit.action) {
         case "View":
            this._router.navigateByUrl(
               "CadastrosOld/Laboratorios-de-Apoio/Laboratorio-Apoio/" +
                  emit.object.id +
                  "$View"
            );
            break;
         case "Editar":
            this._router.navigateByUrl(
               "CadastrosOld/Laboratorios-de-Apoio/Laboratorio-Apoio/" +
                  emit.object.id +
                  "$Edit"
            );
            break;
         case "Excluir":
            this.deleteSupportLab(emit.object.id);
            break;
         case "PutActive":
            this.putActive(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
         case "OpenDropdown":
            this.apoioId = emit.id;
            this.getExam(emit.object.id);
            this.getExamsOption();
            break;
      }
   }

   async getExamsOption() {
      this.formOptionsAnalyte = [];
      this.formOptionsMaterial = [];
      await this._analyteService.getResume().then((res) => {
         res.data.forEach((analyte) => {
            this.formOptionsAnalyte.push({
               label: `${analyte.code} - ${analyte.name}`,
               value: analyte.id
            });
         });
      });
   }
   getMaterialsAndReports() {
      this.getMaterial();
      this.getAnalyteReports();
   }

   async getMaterial() {
      if (this.toFromExamsFormGroup.controls["analyteId"].value) {
         this.formOptionsMaterial = [];

         await this._analyteMaterialService
            .getPagination({
               analyteId: this.toFromExamsFormGroup.controls["analyteId"].value
            })
            .then((x: any) => {
               x.data.forEach((res: any) => {
                  this.formOptionsMaterial.push({
                     label: res.description,
                     value: res.materialId,
                     analyteId:
                        this.toFromExamsFormGroup.controls["analyteId"].value,
                     analyteMaterialId: res.id
                  });
               });
               if (this.formOptionsMaterial.length === 1)
                  this.toFromExamsFormGroup.controls["materialId"].setValue(
                     (this.formOptionsMaterial[0] as any).value
                  );
            });
      }
   }

   async getAnalyteReports() {
      const queryObject = {
         analyteId: this.toFromExamsFormGroup.controls["analyteId"].value
      };

      await this._reportService
         .getPagination<GetByPathReport>(queryObject)
         .then((res) => {
            if (res.data.length > 0) {
               this.analyteReportOptions = res.data.map((analyteReport) => ({
                  value: analyteReport.id,
                  label: analyteReport.name
               }));
               if (this.analyteReportOptions.length === 1)
                  this.toFromExamsFormGroup.controls[
                     "analyteReportId"
                  ].setValue(this.analyteReportOptions[0].value);
            } else {
               SwAlSetttings.warningMessage(
                  "O Exame selecionado não possui Laudo configurado!"
               );
            }
         });
   }

   async getExam(id: string, paramPage?: number[]) {
      this.apoioId = id;
      if (paramPage) {
         this.pageExam = paramPage[0];
         this.indexExam = paramPage[1];
      }

      const queryObject = {
         supportLaboratoryId: id,
         index: this.indexExam,
         page: this.pageExam
      };

      await this._examsSupportLaboratoryService
         .getPagination({ supportLaboratoryId: id })
         .then((res) => {
            this.totalExam[id] = res.data.length;
            this.resolveExams(res.data, resolveToFromExams, id);
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   resolveExams(data: any, columns: any, id: string) {
      const arrayFilter: Array<object> = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         arrayFilter.push(obj);
      });
      this.registerExams[id] = arrayFilter;
   }

   actionsExam(emit: Actions) {
      switch (emit.action) {
         case "Editar":
            this.openDialogExam(true, emit);
            break;
         case "Excluir":
            this.deleteExam(emit.object.id);
            break;
         case "PutActive":
            this.patchActiveExams(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
      }
   }

   patchActiveExams(id: string, value: string) {
      this._examsSupportLaboratoryService.patchActive(id, value).then(() => {
         SwAlSetttings.Sucesso("Situação Alterada!");
      });
   }

   async openDialogExam(edit: boolean, rowDetail: any) {
      if (edit) {
         this.getSupportLaboratory();
         this.setExams(rowDetail);
      }
      const myTempDialog = this._dialog.open(this.dialogExam, {
         width: "70%",
         data: { edit, rowDetail }
      });
      myTempDialog.afterClosed().subscribe(() => {
         this.toFromExamsFormGroup.reset();
         this.formOptionsMaterial = [];
         this.analyteReportOptions = [];
         this.toFromExamsFormGroup.controls["reference"].reset(null);
         this.toFromExamsFormGroup.controls["hasRelationship"].reset(null);
      });
   }

   async setExams(emit: any) {
      this.toFromExamsFormGroup.controls["analyteId"].setValue(
         emit.object._hide_analyteId
      );
      this.toFromExamsFormGroup.controls["codeInSupportLaboratory"].setValue(
         emit.object["Exame no Apoio"]
      );
      await this.getMaterial();
      this.toFromExamsFormGroup.controls["materialId"].setValue(
         emit.object._hide_materialId
      );
      this.toFromExamsFormGroup.controls["material"].setValue(
         emit.object["Material no Apoio"]
      );
      this.toFromExamsFormGroup.controls["analyteReportId"].setValue(
         emit.object._hide_analyteReportId
      );
      await this.getAnalyteReports();
      this.toFromExamsFormGroup.controls["value"].setValue(
         emit.object._hide_value
      );
      this.toFromExamsFormGroup.controls["reference"].setValue(
         emit.object._hide_reference.includes("true") ? true : false
      );
      this.toFromExamsFormGroup.controls["hasRelationship"].setValue(
         emit.object._hide_hasRelationship.includes("true") ? true : false
      );
   }

   close() {
      this._dialog.closeAll();
   }

   async postExam() {
      const materialObject = this.formOptionsMaterial.find(
         (material) =>
            material.value ===
            this.toFromExamsFormGroup.controls["materialId"].value
      );
      const postExam: ExamsSupportLaboratoryPostobject = {
         supportLaboratoryId: this.apoioId,
         analyteId: this.toFromExamsFormGroup.controls["analyteId"].value,
         analyteMaterialId: materialObject
            ? materialObject.analyteMaterialId
            : null,
         codeInSupportLaboratory:
            this.toFromExamsFormGroup.controls["codeInSupportLaboratory"].value,
         materialInSupportLaboratory:
            this.toFromExamsFormGroup.controls["material"].value,
         value: this.toFromExamsFormGroup.controls["value"].value,
         acceptValueReference:
            this.toFromExamsFormGroup.controls["reference"].value,
         reportId: this.toFromExamsFormGroup.controls["analyteReportId"].value,
         hasRelationship:
            this.toFromExamsFormGroup.controls["hasRelationship"].value
      };
      await this._examsSupportLaboratoryService
         .post(postExam)
         .then(async (res) => {
            if (res.success) {
               setTimeout(() => {
                  SwAlSetttings.Sucesso(
                     "De/Para de Exames criado com sucesso!"
                  );
               });
               this.close();
               this.getExam(this.apoioId);
            }
         });
   }

   async updateExam(rowDetail: any) {
      const materialObject = this.formOptionsMaterial.find(
         (material) =>
            material.value ===
            this.toFromExamsFormGroup.controls["materialId"].value
      );
      const updateToFromExamsObject: ExamsSupportLaboratoryPutObject = {
         analyteId: this.toFromExamsFormGroup.controls["analyteId"].value,
         analyteMaterialId: materialObject
            ? materialObject.analyteMaterialId
            : null,
         supportLaboratoryId: this.apoioId,
         codeInSupportLaboratory:
            this.toFromExamsFormGroup.controls["codeInSupportLaboratory"].value,
         materialInSupportLaboratory:
            this.toFromExamsFormGroup.controls["material"].value,
         value: this.toFromExamsFormGroup.controls["value"].value,
         acceptValueReference:
            this.toFromExamsFormGroup.controls["reference"].value,
         hasRelationship:
            this.toFromExamsFormGroup.controls["hasRelationship"].value,
         reportId: this.toFromExamsFormGroup.controls["analyteReportId"].value
      };
      await this._examsSupportLaboratoryService
         .put(updateToFromExamsObject, new Guid(rowDetail.object.id))
         .then(async () => {
            this.getExam(this.apoioId);
            this.close();
            SwAlSetttings.Sucesso("De/Para de Exames atualizado com sucesso!");
         });
   }

   deleteExam(id: string) {
      SwAlSetttings.ALERT_CONFIRM.fire(
         "Deseja excluir o De/Para de Exames?"
      ).then(async (r) => {
         if (r.isConfirmed) {
            await this._examsSupportLaboratoryService.delete(new Guid(id));
            this.getExam(this.apoioId);
            SwAlSetttings.Sucesso("De/Para de Exames excluído com sucesso!");
         }
      });
   }

   putActive(id: string, value: string) {
      this._supportLaboratoryService
         .patchActive(id, value)
         .then(async () => {
            await this.getSupportLaboratory();
            SwAlSetttings.Sucesso("Situação alterada com sucesso!");
         })
         .catch((err) => SwAlSetttings.printError(err));
   }

   deleteSupportLab(id: string) {
      SwAlSetttings.ALERT_CONFIRM.fire(
         "Deseja realmente excluir o Laboratório de Apoio?"
      )
         .then(async (r) => {
            if (r.isConfirmed) {
               await this._supportLaboratoryService.delete(new Guid(id));
               await this.getSupportLaboratory();
               SwAlSetttings.Sucesso(
                  "Laboratório de Apoio excluído com sucesso!"
               );
            }
         })
         .catch((error) => {
            SwAlSetttings.printError(error);
         });
   }

   createSupportLab() {
      this._router.navigateByUrl(
         "/CadastrosOld/Laboratorios-de-Apoio/Laboratorio-Apoio/Novo"
      );
   }

   resetFilter() {
      this.search.reset();
      this.isActive.reset();
   }
}
