import { Component, OnInit, inject } from "@angular/core";
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ForgotPasswordFacade } from "@app/modules_new/administrativeData/data/facade/forgotPassword.facade";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ModalLayoutComponent } from "@app/modules_new/shared/components/form/modal/modal.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "passwordModal",
   templateUrl: "./passwordModal.modal.html",
   standalone: true,
   imports: [
      TranslateModule,
      ModalLayoutComponent,
      InputTextFormComponent,
      ButtonFormComponent,
      ReactiveFormsModule
   ]
})
export class PasswordModalComponent implements OnInit {
   public _modalData: any = inject(MAT_DIALOG_DATA);
   private _forgotPasswordFacade = inject(ForgotPasswordFacade);
   title: string = "Atualizar Senha";

   public form = new FormGroup({
      password: new FormControl<string>("", [Validators.required]),
      confirmPassword: new FormControl<string>("", [Validators.required]),
      actualPassword: new FormControl<string>("", [Validators.required])
   });

   ngOnInit(): void {}

   async submit() {
      const obj = {
         typeForgotPassword: 1,
         password: this.form.controls.actualPassword.value,
         newPassword: this.form.controls.password.value,
         confirmPassword: this.form.controls.confirmPassword.value,
         identification: this._modalData
      };

      this._forgotPasswordFacade.changePassword(obj);
   }
}
