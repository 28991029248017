import { Component, inject } from "@angular/core";
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UsersFacade } from "@app/modules_new/admin/data/facades/users-admin.facade";
import { ButtonGroupFormComponent } from "@app/modules_new/shared/components/form/button-group/button-group.component";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ModalLayoutComponent } from "@app/modules_new/shared/components/form/modal/modal.component";
import { TranslateModule } from "@ngx-translate/core";
@Component({
   selector: "user-modal",
   templateUrl: "./user-modal.component.html",
   standalone: true,
   imports: [
      TranslateModule,
      ModalLayoutComponent,
      InputTextFormComponent,
      ButtonFormComponent,
      ReactiveFormsModule,
      ButtonGroupFormComponent
   ]
})
export class UserModalComponent {
   public _userId: any = inject(MAT_DIALOG_DATA);

   private _usersFacade = inject(UsersFacade);
   public form = new FormGroup({
      email: new FormControl<string | null>(null, [Validators.required]),
      name: new FormControl<string | null>(null, [Validators.required]),
      type: new FormControl<number | null>(1, [Validators.required]),
      password: new FormControl<string | null>(null, [Validators.required]),
      confirmPassword: new FormControl<string | null>(null, [
         Validators.required
      ]),
      cellPhone: new FormControl<string | null>(null, [Validators.required]),
      cpf: new FormControl<string | null>(null, [Validators.required]),
      active: new FormControl<string | null>(null, [Validators.required])
   });

   createUser() {
      let obj = {
         name: this.form.controls.name.value,
         type: this.form.controls.type.value,
         accessCode: this.form.controls.email.value,
         password: this.form.controls.password.value,
         confirmPassword: this.form.controls.confirmPassword.value,
         cellPhone: this.form.controls.cellPhone.value,
         cpf: {
            value: this.form.controls.cpf.value
         }
      };
      this._usersFacade.postUser(obj, this._userId.tenantId);
   }

   CheckIsEmail() {
      if (this.form.controls.email.value) {
         var is = this.form.controls.email.value.split("@");

         if (is.length > 1) this.form.controls.type.setValue(1);
         else this.form.controls.type.setValue(2);
      }
   }
}
