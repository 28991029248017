import { NONE_TYPE } from '@angular/compiler';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PieChart } from './pie-chart';

declare const google: any;

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
  @ViewChild('chartElement', {static: false}) chartElement?: ElementRef;

  private _data: any;

  @Input()
  set data(data: PieChart) {
    this._data = data;
    this.loadGraph();
  }

  @Input() width : string = '252';
  @Input() pieSliceText : string = 'none';
  @Input() sliceVisibilityThreshold : string = 'none';
  @Input() height : string = '260';

  get data(): PieChart {
    return this._data;
  }

  constructor() {
    google.charts.load('current', { packages: ['corechart'] });
  }

  ngOnInit() {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.loadGraph();
  }

  loadGraph() {
    if (this.hasChart) {
      google.charts.setOnLoadCallback(() => {
        this.drawChart(this);
      });
    }
  }

  drawChart(scope: PieChartComponent) {
    const data = google.visualization.arrayToDataTable(scope.data.values);

    let total = 0;

    scope.data.values.forEach((element, index) => {
      if (index > 0) {
        total += element[1];
      }
    });

    const options = {
      width: this.width,
      height: this.height,
      pieHole: 0.4,
      legend:'none',
      pieSliceText: this.pieSliceText,
      sliceVisibilityThreshold: this.sliceVisibilityThreshold,
      colors: [
        '#fe7434', 
        '#6788dc', 
        '#ffc000', 
        '#07bf56', 
        '#eb5757',
        '#CA78F1',
        '#56CCF2',
        '#BCEB57'
      ],
      chartArea: {
        width: '100%',
        height: '100%',
      },
    };

    const chart = new google.visualization.PieChart(
      this.chartElement!.nativeElement
    );

    chart.draw(data, options);
  }

  get hasChart(): boolean {
    return this.data.values.length > 1;
  }
}
