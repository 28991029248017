<div class="contentPage">
  <div class="flex flex-between align-center headerResponsive">
    <div class="flex align-center gp-12 titleResponsive">
      <h1>{{title}}</h1>      
      <input-prop (keydown.enter)="get()" placeholder="Requisição" [form]="form.requisition"></input-prop>
    </div>        
  </div>
  <div class="mt-32">
    <app-table [Itens]="registers" [Pagination]="false" [backPagination]="false" [Actions]="actionsList" [Dropdown]="true" (parentFun)="actions($event)">
      <ng-template let-rowDetail #header>
        <app-table *ngIf="exams[rowDetail.id].length > 0" [noShadow]="true" [Pagination]="false" [backPagination]="false" [Itens]="exams[rowDetail.id]" [Actions]="actionsList" (parentFun)="actionsExam($event, rowDetail.id)"></app-table>
      </ng-template>
    </app-table>    
  </div>
</div>

<ng-template #dialogModal let-modal>
  <ng-container>    
    <div class="flex flex-between w-100 align-center">
      <h1>{{title}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="modal">
      <div *ngFor="let exam of examsModal; let i = index">
        <div *ngIf="form.type.value == 1" class="w-100 mt-32 gp-12 align-center">
          <div *ngIf="examsModal.length > 0" class="w-100 box">
            <b>Descrição: {{exam.name}}</b> - <b>Material: {{exam.material}}</b>
            <div class="mt-32">
              <input-prop [form]="examsFormGroup[exam.id]"></input-prop>
            </div>
            <div *ngFor="let atribute of exam.atributeAnalyte; let j = index">
              <div class="flex flex-between w-100 mt-32">
                <div class="flex flex-center">
                  <span class="flex align-center">{{atribute.name}}</span>
                </div>
                <div class="flex flex-center">
                  <span class="multiline-text">{{atribute.valueReference}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="form.type.value != 1"  class="box w-100 mt-32">
          <div class="flex flex-between">
            <div class="flex align-center">
              <span class="multiline-text"><b>Descrição: {{exam.name}}</b><br><b>Material: {{exam.material}}</b></span>
            </div>
            <div class="flex align-center gap-form">
              <span>{{actionsList[0]}}</span>
              <checkbox-prop [form]="checkboxFormGroup[exam.id]"></checkbox-prop>
            </div>
          </div>
          <div *ngFor="let atribute of exam.atributeAnalyte; let j = index">
            <div *ngIf="form.type.value == 2 || form.type.value == 3" class="flex flex-between w-100 mt-32">
              <div class="flex flex-center">
                <span class="flex align-center">{{atribute.name}}</span>
              </div>
              <div class="flex flex-center"> 
                <span class="multiline-text">{{atribute.valueReference}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-32 flex flex-center pd-5">
      <button (click)="save()" class="btn">{{actionsList[0]}}</button>
    </div>    
  </ng-container>
</ng-template>