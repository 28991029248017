import { Component, Input, OnInit, Output , EventEmitter, ViewChild, AfterViewChecked} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import { options } from './input-suboptions.model';
import { InputPropComponent } from '../input-prop/input-prop.component';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';

@Component({
  selector: 'app-input-suboptions',
  templateUrl: './input-suboptions.component.html',
  styleUrls: ['./input-suboptions.component.scss']
})
export class InputSuboptionsComponent implements OnInit, AfterViewChecked{

  /**
  * Options, array que será recebido para criar as opções selecionáveis, sua estrutura deverá ser:
  * options: [
  *   {
  *     disabled?: boolean,     // caso queira que o grupo venha desabilitado, não permitindo sua seleção
  *     name: string,           // nome do Grupo
  *     subOptions: [           // array com as opções referente ao grupo descrito a cima
  *       {
  *         value: string,      // valor da opção seecionada
  *         label: string,      // texto de exibição da opção
  *         disabled?: boolean  // caso queira desabilitar a opção específica
  *       }
  *     ]
  *   }]
  **/
  
  @Input() options: Array<options> = [];
  AllOptions: Array<options> = [];
  AllSubOptions: Array<any> = [];

  /**
   * formControl recebido da página que está sendo utilizada
   **/
  @Input() form: UntypedFormControl = new UntypedFormControl('');

   /**
   * nome a ser exibido no placeholder do input
   **/
  @Input() placeholder: string = '';

   /**
   * evento para retorno caso haja alterações de valores no componente
   **/
  @Output() changeForm = new EventEmitter<any>();  

  search = new UntypedFormControl('');

  @ViewChild('inputSuboptions', { static: false }) inputSuboptions!: InputPropComponent;

  @ViewChild('matSelect') matSelect!: any;
  
  constructor() { }

  ngOnInit() {
  }

  
  ngAfterViewChecked(){
    this.AllSubOptions = [];
    this.options.forEach((subOption: any) => {
      subOption.subOptions.forEach((option: any) => {
        this.AllSubOptions.push(option);
      });
    });
  }    

  focusInputSuboptions() {
    if (this.inputSuboptions) {
      this.clearValue();
      this.inputSuboptions.focusInput();
    }
  }

  changeSearch(search:string){    
    if (this.AllOptions.length == 0) {      
      this.AllOptions = [...this.options];             
    }
    if (search === '') {      
      this.options = [...this.AllOptions];      
    } else {      
      this.options = []; 
      this.AllOptions.forEach((option: any) => {
        if (option.subOptions.findIndex((subOption:any) => subOption.label.toLowerCase().includes(search.toLowerCase())) >= 0){
          this.options.push(option);
        }
      });
    }     
  }

  setValue(event:any){      
    this.search.setValue(this.AllSubOptions.filter((x:any) => x.value == event.value)[0].label);
    this.changeForm.emit({ change: true });
  }

  clearValue(){    
    this.search.setValue("");
  }
}
