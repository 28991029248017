import { Injectable } from '@angular/core';
import { BaseAuthService } from '../auth/base-auth.service';
import { HttpAuthService } from '@services/auth/http-auth.service';
import { Guid } from '@app/util/guid';

@Injectable({
  providedIn: 'root'
})
export class GroupsService extends BaseAuthService {
  constructor(HttpAuthService: HttpAuthService) {
      super(HttpAuthService, 'v1/accesscontrol/group');
  }

  deletePermission(groupId: string, permissionId: string) {
    return this.HttpAuthService.delete(new Guid(permissionId)!,`api/${this.path}/${groupId}/policy`);
  }
}
