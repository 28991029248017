<div class="contentPage">
  <div class="flex flex-between align-center">
    <div class="w-100 flex ">
      <h1>Log de Envio SMS</h1>
      <!-- <button class="btn" (click)="navigate()">Inserir</button> -->
    </div>
    <div class="w-100 gp-24 flex">
      <div class="w-100">
        <select-prop [form]="form.filter" placeholder="Selecione um filtro" [options] = "filterOptions"></select-prop>
      </div>
      <div class="w-100">
        <!-- <div class="search" *ngIf="this.form.filter.value !='Filtrar por Data de Nascimento' && this.form.filter.value != 'Filtrar por CPF'"> -->
        <div class="search">
          <input-prop type="text" placeholder="Digite aqui para procurar algum Registro" [form]="form.search"></input-prop>
          <div class="searchSVG">
            <svg (click)="getControlMessages()" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.6732 9.51212C12.5196 8.25295 12.8669 6.72374 12.6474 5.22249C12.4279 3.72124 11.6574 2.35548 10.4859 1.39134C9.31439 0.427204 7.82591 -0.0662417 6.31047 0.00715382C4.79503 0.0805493 3.3612 0.715528 2.28836 1.78836C1.21553 2.8612 0.580549 4.29503 0.507154 5.81047C0.433758 7.32591 0.927204 8.81439 1.89134 9.98588C2.85548 11.1574 4.22124 11.9279 5.72249 12.1474C7.22374 12.3669 8.75295 12.0196 10.0121 11.1732L13.5253 14.6849C13.7479 14.8923 14.0423 15.0052 14.3465 14.9998C14.6507 14.9945 14.941 14.8712 15.1561 14.6561C15.3712 14.441 15.4945 14.1507 15.4998 13.8465C15.5052 13.5423 15.3923 13.2479 15.1849 13.0253L11.6732 9.51212ZM6.60538 9.86282C5.86223 9.86282 5.13577 9.64245 4.51786 9.22957C3.89996 8.8167 3.41836 8.22987 3.13397 7.54329C2.84957 6.85671 2.77516 6.10121 2.92015 5.37234C3.06513 4.64347 3.42299 3.97396 3.94847 3.44847C4.47396 2.92299 5.14347 2.56513 5.87234 2.42015C6.60121 2.27516 7.35671 2.34957 8.04329 2.63397C8.72987 2.91836 9.3167 3.39996 9.72957 4.01786C10.1424 4.63577 10.3628 5.36223 10.3628 6.10538C10.3628 7.10192 9.96694 8.05763 9.26229 8.76229C8.55763 9.46694 7.60192 9.86282 6.60538 9.86282Z"
                fill="#808080" />
            </svg>
          </div>
        </div>
        <!--
        <div class="search" *ngIf="this.form.filter.value=='Filtrar por Data de Nascimento'">
          <input-prop type="text" placeholder="Digite aqui para procurar algum Registro" [form]="form.search" mask="00/00/0000"></input-prop>
          <div class="searchSVG">
            <button class="btn-search" (click)="getControlMessages()"></button>
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.6732 9.51212C12.5196 8.25295 12.8669 6.72374 12.6474 5.22249C12.4279 3.72124 11.6574 2.35548 10.4859 1.39134C9.31439 0.427204 7.82591 -0.0662417 6.31047 0.00715382C4.79503 0.0805493 3.3612 0.715528 2.28836 1.78836C1.21553 2.8612 0.580549 4.29503 0.507154 5.81047C0.433758 7.32591 0.927204 8.81439 1.89134 9.98588C2.85548 11.1574 4.22124 11.9279 5.72249 12.1474C7.22374 12.3669 8.75295 12.0196 10.0121 11.1732L13.5253 14.6849C13.7479 14.8923 14.0423 15.0052 14.3465 14.9998C14.6507 14.9945 14.941 14.8712 15.1561 14.6561C15.3712 14.441 15.4945 14.1507 15.4998 13.8465C15.5052 13.5423 15.3923 13.2479 15.1849 13.0253L11.6732 9.51212ZM6.60538 9.86282C5.86223 9.86282 5.13577 9.64245 4.51786 9.22957C3.89996 8.8167 3.41836 8.22987 3.13397 7.54329C2.84957 6.85671 2.77516 6.10121 2.92015 5.37234C3.06513 4.64347 3.42299 3.97396 3.94847 3.44847C4.47396 2.92299 5.14347 2.56513 5.87234 2.42015C6.60121 2.27516 7.35671 2.34957 8.04329 2.63397C8.72987 2.91836 9.3167 3.39996 9.72957 4.01786C10.1424 4.63577 10.3628 5.36223 10.3628 6.10538C10.3628 7.10192 9.96694 8.05763 9.26229 8.76229C8.55763 9.46694 7.60192 9.86282 6.60538 9.86282Z"
                fill="#808080" />
            </svg>
          </div>
        </div>
        <div class="search" *ngIf="this.form.filter.value=='Filtrar por CPF'">
          <input-prop type="text" placeholder="Digite aqui para procurar algum Registro" [form]="form.search" mask="000.000.000-00"></input-prop>
          <div class="searchSVG">
            <button class="btn-search" (click)="getControlMessages()"></button>
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.6732 9.51212C12.5196 8.25295 12.8669 6.72374 12.6474 5.22249C12.4279 3.72124 11.6574 2.35548 10.4859 1.39134C9.31439 0.427204 7.82591 -0.0662417 6.31047 0.00715382C4.79503 0.0805493 3.3612 0.715528 2.28836 1.78836C1.21553 2.8612 0.580549 4.29503 0.507154 5.81047C0.433758 7.32591 0.927204 8.81439 1.89134 9.98588C2.85548 11.1574 4.22124 11.9279 5.72249 12.1474C7.22374 12.3669 8.75295 12.0196 10.0121 11.1732L13.5253 14.6849C13.7479 14.8923 14.0423 15.0052 14.3465 14.9998C14.6507 14.9945 14.941 14.8712 15.1561 14.6561C15.3712 14.441 15.4945 14.1507 15.4998 13.8465C15.5052 13.5423 15.3923 13.2479 15.1849 13.0253L11.6732 9.51212ZM6.60538 9.86282C5.86223 9.86282 5.13577 9.64245 4.51786 9.22957C3.89996 8.8167 3.41836 8.22987 3.13397 7.54329C2.84957 6.85671 2.77516 6.10121 2.92015 5.37234C3.06513 4.64347 3.42299 3.97396 3.94847 3.44847C4.47396 2.92299 5.14347 2.56513 5.87234 2.42015C6.60121 2.27516 7.35671 2.34957 8.04329 2.63397C8.72987 2.91836 9.3167 3.39996 9.72957 4.01786C10.1424 4.63577 10.3628 5.36223 10.3628 6.10538C10.3628 7.10192 9.96694 8.05763 9.26229 8.76229C8.55763 9.46694 7.60192 9.86282 6.60538 9.86282Z"
                fill="#808080" />
            </svg>
          </div>
        </div>
        -->
      </div>
    </div>
  </div>
  <div class="mt-24">
    <!-- <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="registerControlMessages" [Actions]="['View','Editar', 'Excluir']" (parentFun)="actions($event)" (changePage)="getControlMessages($event)"></app-table> -->
    <app-table [Pagination]="true" [Dropdown]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="registerControlMessages" (parentFun)="actions($event)" (changePage)="getControlMessages($event)">
      <ng-template let-rowDetail #header>
        <div class="flex-between gp-24">
          <div class="mb-32">
            <h2>Assunto</h2>
            <div class="box w-100">
              <span>{{rowDetail._hide_title}}</span>
            </div>
          </div>
          <div class="mb-32">
            <h2>Mensagem</h2>
            <div class="box">
              <iframe srcdoc="{{rowDetail._hide_message}}" width="100%" height="100%" frameborder="0"></iframe>
            </div>
          </div>
          <div class="mb-32">
            <h2>Observação</h2>
            <div style="width: 100%; height: 100px;" class="box">
              <span>{{rowDetail._hide_observation}}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </app-table>
  </div>
</div>
