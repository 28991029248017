import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { DiscountAuthorizationService } from '@app/services/discountAuthorization/discountAuthorization.service';
import { MaterialService } from '@app/services/materials/material.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { ActionsDiscountAuthorization, ResolvedDiscountAuthorization, resolveDiscountsAuthorization } from './discount-authorization.model';

@Component({
  selector: 'app-discount-authorization',
  templateUrl: './discount-authorization.component.html',
  styleUrls: ['./discount-authorization.component.scss']
})
export class DiscountAuthorizationComponent implements OnInit {

  total: number = 0;
  page: number = 1;
  index: number = 50;

  getParameters: string = (`?page=${this.page}&index=${this.index}`);

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  @ViewChild('dialogModalAnalysis')
  dialogModalAnalysis!: TemplateRef<any>

  @ViewChild('dialogModalDiscount')
  dialogModalDiscount!: TemplateRef<any>

  filterForm = {
    situation: new UntypedFormControl(null)    
  }  

  reason = new UntypedFormControl(null);

  form = {
    value: new UntypedFormControl(null),
    reason: new UntypedFormControl(null)
  }

  typeDiscount = {    
    1: "<span class='span-styles' style='background: #0000FF; color: #C0C0C0;'>Requisição</span>",    
    2: "<span class='span-styles' style='background: #00FF00; color: #F8F8FF;'>Orçamento</span>"
  }
   
  situationDiscount = {    
    1: "<span class='span-styles' style='background: #F8F8FF; color: #000000;'>Solicitado</span>",
    2: "<span class='span-styles' style='background: #F8F8FF; color: #0000FF;'>Autorizado Parcialmente</span>",
    3: "<span class='span-styles' style='background: #F8F8FF; color: #00FF00;'>Autorizado</span>",
    4: "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Não Autorizado</span>",
  }

  discountsAuthorization: Array<ResolvedDiscountAuthorization> = [];

  resolveDiscountsAuthorization = resolveDiscountsAuthorization;

  ActionsExtra = {
    "Ativo": "PutActive",
    "Análise": "AproveDiscount"
  }  

  constructor(
    private _dialog: MatDialog,
    private _materialService: MaterialService,     
    private _localaDatePipe: LocalaDatePipe,     
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _discountAuthorizationService: DiscountAuthorizationService
  ) { }

  async ngOnInit() {
    await this.get();
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let filterObject = {
      index: this.index,
      page: this.page,
      situation: this.filterForm.situation.value ? this.filterForm.situation.value : null,   
    }
    await this._discountAuthorizationService.getPagination(filterObject).then(
      (res) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolve(res.data, this.resolveDiscountsAuthorization);
        } else {
          SwAlSetttings.alertaMsg('Não foi encontrado nenhuma solicitação de desconto!');
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );    
  }

  resolve(data: any, columns: any) {
    this.discountsAuthorization = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.discountsAuthorization.push(obj);
    });
  }  

  getCreated(item: any) {
    return this._localaDatePipe.transform(item.createdAt);
  }

  getUpdated(item: any) {
    return this._localaDatePipe.transform(item.updatedAt);
  }

  getSolicitationDate(item: any) {    
    return this._localaDatePipe.transform(item.createdAt);
  }

  getActive(item: any) {
    return this._activePipe.transform(item.isActive)
  }  

  putActive(id: Guid, value: string) {
    this._materialService.patchActive(id.toString(), value).then((x:any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.get();
    }).catch(err => SwAlSetttings.printError(err))
  }

  async Actions(emit: ActionsDiscountAuthorization) {        
    switch (emit.action) {      
      case 'PutActive':        
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
      case 'AproveDiscount':   
        switch (emit.object['Situação']) {      
          case "<span class='span-styles' style='background: #F8F8FF; color: #000000;'>Solicitado</span>":
            this.reason.setValue(emit.object._hide_reason);        
            const myTempDialog = this._dialog.open(this.dialogModalAnalysis, {
              width: '660px',
              data: {id: emit.object.id, value: emit.object['Desconto Solicitado']}
            });

            myTempDialog.afterClosed().subscribe(() => {
              this.reason.reset();
            }) 
            break;            
          case "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Não Autorizado</span>":
            const data : any = await this.getById(emit.object.id);            
            this.reason.setValue(data.deniedReason);            
            const dialog = this._dialog.open(this.dialogModalDiscount, {
              width: '660px',
              data:{situation:data.situation, value:this.getValue(data), valuePercentual: data.valuePercentual.toFixed(2)}
            });
            
            dialog.afterClosed().subscribe(() => {
              this.reason.reset();
            }) 
            break;
          case "<span class='span-styles' style='background: #F8F8FF; color: #0000FF;'>Autorizado Parcialmente</span>":
            const dataPartial : any = await this.getById(emit.object.id);                        
            const dialogPartial = this._dialog.open(this.dialogModalDiscount, {
              width: '660px',
              data:{situation:dataPartial.situation, value:this.getValue(dataPartial), valueReturn: this.getValueReturn(dataPartial), 
                valuePercentual: dataPartial.valuePercentual.toFixed(2), valueReturnPercentual: dataPartial.valueReturnPercentual.toFixed(2)}
            });

            dialogPartial.afterClosed().subscribe(() => {
              this.reason.reset();
            }) 
            break;
          case "<span class='span-styles' style='background: #F8F8FF; color: #00FF00;'>Autorizado</span>":
            const dataAuthorized : any = await this.getById(emit.object.id);            
            const dialogAuthorized = this._dialog.open(this.dialogModalDiscount, {
              width: '660px',
              data:{situation:dataAuthorized.situation, value:this.getValue(dataAuthorized), valuePercentual: dataAuthorized.valuePercentual.toFixed(2)}
            });

            dialogAuthorized.afterClosed().subscribe(() => {
              this.reason.reset();
            }) 
            break;            
        };        
        break;
    }
  }

  resetFilter(){    
    this.filterForm.situation.reset();
  }
  
  getValue(item: any) {
    let valor = item.value.toFixed(2).toString().split('.')
    let real = valor[0]
    let centavo = valor[1]
  
    return 'R$ ' + real + ',' + centavo;
  }
  
  getValueReturn(item: any) {
    let valor = item.valueReturn.toFixed(2).toString().split('.')
    let real = valor[0]
    let centavo = valor[1]
  
    return 'R$ ' + real + ',' + centavo;
  }

  getTotalValue(item: any) {    
    let valor = item.requisitionValue.toFixed(2).toString().split('.')
    let real = valor[0]
    let centavo = valor[1]
  
    return 'R$ ' + real + ',' + centavo;
  }

  getSituation(item:any){    
    //@ts-ignore
    return this.situationDiscount[item.situation];
  }

  getButton(item:any){
    return 'Análise|button'
  }  

  getType(item:any){    
    //@ts-ignore
    return this.typeDiscount[item.typeDiscount];
  }  

  close() {    
    this._dialog.closeAll()
  }

  saveDiscount(discountInfo:any){    
    let obj = {};
    if (discountInfo.discount){
      obj = {
        //@ts-ignore
        "valueReturn": this.form.value.value.replace(",", "."),
      }
    } else {
      obj = {        
        "deniedReason": this.form.reason.value
      }
    }

    this._discountAuthorizationService.put(obj, discountInfo.id).then(
      async (res) => {
        if (res.success) {
          setTimeout(() => {
            SwAlSetttings.Sucesso("Desconto alterado com sucesso!");
          });        
          this.close();
          this.get();
        }
      }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );    
  }

  getNumber(item:any){    
    if (item.typeDiscount == 1){
      return item.requisitionNumber;
    } else {
      return item.budgetNumber;
    }
  }

  analysisDiscount(aproved:boolean, id: string, value?:string){    
    if (value){
      this.form.value.setValue(value.substr(3));
    }
    this._dialog.closeAll();
    const myTempDialog = this._dialog.open(this.dialogModal, {
      width: '660px',
      data: {discount: aproved, id: id}
    });
    myTempDialog.afterClosed().subscribe(() => {
      
    })    
  }

  async getById(id:Guid){
    let data = {};
    await this._discountAuthorizationService.getById(id).then(
      (res : any) => {
        if (res.data.length != 0) {
          data = res.data[0];
        } 
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    ); 
    
    return data;
  }
}