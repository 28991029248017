<div class="contentPage">
  <div class="flex align-center flex-between w-100">
    <div class="flex align-center w-100 gap-2 headerResponsive">
      <app-button (click)="back()" [placeholder]="'Voltar'" [class]="'flex gap-2 align-center btn-reverse'"></app-button>
      <h1>{{title}}</h1>
    </div>
  </div>
  <mat-tab-group class="mt-32">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex align-center">
          <h2>Dados Gerais</h2>
        </div>
      </ng-template>
      <div class="contextPage">
        <div class="box">
          <h2>Laboratório de Apoio</h2>
          <div class="flex gap-form mt-32">
            <input-prop class="w-100" [placeholder]="'Nome do apoio'" [form]="supportLaboratoryFormGroup.controls['name']"></input-prop>
            <input-search [placeholder]="'Tipo de Comunicação'" class="w-100" [ngClass]="{ 'bloker' : blocked}" [options]="onOptionLaboratory" [form]="supportLaboratoryFormGroup.controls['typeCommunication']"></input-search>
            <input-prop class="w-100" [mask]="'(00) 0000-0000'" [placeholder]="'Telefone'" [form]="supportLaboratoryFormGroup.controls['telephone']"></input-prop>
          </div>
          <div class="flex gap-form mt-32">
            <input-prop class="w-100" [mask]="'(00) 0 0000-0000'" [placeholder]="'Celular'" [form]="supportLaboratoryFormGroup.controls['cellPhone']"></input-prop>
            <input-prop class="w-100" [placeholder]="'E-mail'" [form]="supportLaboratoryFormGroup.controls['email']"></input-prop>
            <input-prop  (keyup)="cpfCnpj($event)" [mask]="'00.000.000/0000-00'" class="w-100" [placeholder]="'CNPJ'" [form]="supportLaboratoryFormGroup.controls['cpf']"></input-prop>
          </div>
          <div class="flex gap-form mt-32">
            <input-search class="w-100" [placeholder]="'Razão Social'" [form]="supportLaboratoryFormGroup.controls['personId']" [options]="persons" [searchApi]="true" (search)="getPersons($event.value)" (changeForm)="getPersonLabels()"></input-search>
          </div>
          <div class="flex gap-form mt-32">
            <div class="flex gap-2 w-100 align-center">
              <input-prop class="w-100" [placeholder]="'Assessor'" [form]="supportLaboratoryFormGroup.controls['nameAgent']"></input-prop>
              <input-prop class="w-100" [placeholder]="'Celular Assessor'" [mask]="'(00) 0 0000-0000'" [form]="supportLaboratoryFormGroup.controls['phoneAgent']"></input-prop>
            </div>
          </div>
        </div>
        <div class="mt-32 box">
          <h2>Endereço</h2>
          <div class="flex gap-form mt-32">
            <input-prop class="w-100" (focusout)="getCep()" mask="00.000-000" [placeholder]="'CEP'" [form]="supportLaboratoryFormGroup.controls['zipCode']"></input-prop>
            <input-prop class="w-100" [placeholder]="'Endereço'" [form]="supportLaboratoryFormGroup.controls['street']"></input-prop>
            <input-prop class="w-100" [placeholder]="'Número'" [form]="supportLaboratoryFormGroup.controls['number']"></input-prop>
          </div>
          <div class="flex gap-form mt-32">
            <input-prop class="w-100" [placeholder]="'Bairro'" [form]="supportLaboratoryFormGroup.controls['neighborhood']"></input-prop>
            <input-prop class="w-100" [placeholder]="'Cidade'" [form]="supportLaboratoryFormGroup.controls['city']"></input-prop>
            <input-prop class="w-100" [placeholder]="'UF'" [form]="supportLaboratoryFormGroup.controls['state']"></input-prop>
          </div>
          <div class="flex gap-form mt-32">
            <input-prop class="w-100" [placeholder]="'Pais'" [form]="supportLaboratoryFormGroup.controls['country']"></input-prop>
            <input-prop class="w-100" [placeholder]="'Complemento'" [form]="supportLaboratoryFormGroup.controls['complement']"></input-prop>
            <div class="w-100"></div>
          </div>
        </div>
          <div class="mt-32 flex flex-center">
            <app-button (click)="title === 'Novo Laboratório de Apoio' ? createSupportLaboratory() : updateSupportLaboratory()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center'"></app-button>
          </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="title && supportLaboratoryId">
      <ng-template mat-tab-label>
        <div class="flex align-center">
          <h2>Comunicação</h2>
        </div>
      </ng-template>
      <div class="contextPage">
        <div class="box">
          <div class="flex align-center flex-between gap-form">
            <h2>Configurações de Comunicação</h2>
            <app-button (click)="newConfiguration()"  [placeholder]="'Incluir Configuração'" [class]="'flex gap-2 align-center'"></app-button>
          </div>
          <app-table [sumRecords]="credentialsTotal" [pageNumber]="credentialsPage" [indexNumber]="credentialsIndex" [Pagination]="true" [backPagination]="true" [noShadow]="true" [Itens]="communicationCredentialRegister" [Actions]="['Editar' , 'Excluir']" (parentFun)="communicationCredentialsActions($event)" (changePage)="getCommunicationCredentials($event)"></app-table>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="title && supportLaboratoryId">
      <ng-template mat-tab-label>
        <div class="flex align-center">
          <h2>De/Para de Exames</h2>
        </div>
      </ng-template>
      <div class="contextPage">
        <div class="box" >
          <div class="flex align-center flex-between gap-form">
            <h2>De/Para de Exames</h2>
            <app-button (click)="openDialogExam(false)"  [placeholder]="'Incluir De/Para de Exame'" [class]="'flex gap-2 align-center'"></app-button>
          </div>
          <app-table [Dropdown]="true" [noShadow]="true" [Itens]="registerExam" [Actions]="['Editar' , 'Excluir']" [ClickInItem]="ActionsExtra" (parentFun)="actionsExam($event)">
            <ng-template let-rowDetail #header>
              <mat-tab-group>
                <mat-tab class="contentPage" label="De/Para de Perguntas">
                  <div class="contentPage">
                    <div class="w-100 flex flex-between align-center">
                      <div class="flex align-center">
                        <h2>De/Para de Perguntas</h2>
                      </div>
                      <app-button (click)="openDialogExamsAdditionalInformationLaboratory(false, rowDetail)" [placeholder]="'Incluir De/Para de Pergunta'" [class]="'flex gap-2 align-center'"></app-button>
                    </div> 
                    <app-table [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true" [pageNumber]="examsAdditionalInformationTable[rowDetail.id].paramPage != undefined ? examsAdditionalInformationTable[rowDetail.id].paramPage[0] : 1" [sumRecords]="examsAdditionalInformationTotal[rowDetail.id]" [indexNumber]="examsAdditionalInformationTable[rowDetail.id].paramPage != undefined ? examsAdditionalInformationTable[rowDetail.id].paramPage[1] : 50" [noShadow]="true" [Itens]="registerExamsAdditionalInformation[rowDetail.id]" [Actions]="['Editar' , 'Excluir']" (changePage)="getExamsAdditionalInformation(rowDetail.id, $event)" (parentFun)="actionsExamsAdditionalInformationLaboratory($event, rowDetail)"></app-table>
                  </div>
                </mat-tab>
                <mat-tab *ngIf="isHasRelationship[rowDetail.id]" label="De/Para de Atributos"> 
                  <div class="contentPage">
                    <div class="w-100 flex flex-between align-center">
                      <div class="flex align-center">
                        <h2>De/Para de Atributos</h2>
                      </div>
                      <app-button (click)="openDialogAtributeRelationship(false, rowDetail)" [placeholder]="'Incluir De/Para de Atributo'" [class]="'flex gap-2 align-center'"></app-button>
                    </div>      
                    {{atributeRelationshipPage[rowDetail.id]|json}}
                    <app-table  [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true" [pageNumber]="atributeRelationshipPage[rowDetail.id] != undefined ? atributeRelationshipPage[rowDetail.id].paramPage[0] : 1" [sumRecords]="atributeRelationshipTotal[rowDetail.id]" [indexNumber]="atributeRelationshipPage[rowDetail.id] != undefined ? atributeRelationshipPage[rowDetail.id].paramPage[0] : 50" [noShadow]="true" [Itens]="registerAtributeRelationship[rowDetail.id]" [Actions]="['Editar' , 'Excluir']" (changePage)="getAtributeRelationship(rowDetail.id, $event)" (parentFun)="actionsAtributeRelationship($event, rowDetail)"></app-table>
                  </div>
                </mat-tab>
                <mat-tab label="Tempos para Curva">
                  <div class="contentPage">
                    <div class="w-100 flex flex-between align-center">
                      <div class="flex align-center">
                        <h2>Tempos para Curva</h2>
                      </div>
                      <app-button (click)="openDialogSupportLaboratoryCollectionTime(false, rowDetail)" [placeholder]="'Incluir Tempo de Curva'" [class]="'flex gap-2 align-center'"></app-button>
                    </div>
                    <app-table *ngIf="registerColectionTime[rowDetail.id]" [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true" [pageNumber]="collectionTimeTabel[rowDetail.id] != undefined ? collectionTimeTabel[rowDetail.id].paramPage[0] : 1" [sumRecords]="collectionTimeTotal[rowDetail.id]" [indexNumber]="collectionTimeTabel[rowDetail.id] != undefined ? collectionTimeTabel[rowDetail.id].paramPage[1] : 50" [noShadow]="true" [Itens]="registerColectionTime[rowDetail.id]" [Actions]="['Editar' , 'Excluir']" (changePage)="getCollectionTime(rowDetail.id, $event)" (parentFun)="actionsCollectionTime($event, rowDetail)"></app-table>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </ng-template>
          </app-table>
        </div>
        <div *ngIf="title != 'Visualizar '" class="mt-32 flex flex-center gap-form align-center">
          <app-button (click)="!supportLaboratoryId ? postLaboratory() : editLaboratory()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center'"></app-button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #dialogExam let-edit>
  <ng-container>
    <div class="flex flex-between">
      <h1 class="mb-32">{{edit.editar ? 'Editar De/Para de Exame' : 'Novo De/Para de Exame'}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="flex gap-form">
      <div class="w-100">
        <input-search [readonly]="edit.editar" [placeholder]="'Selecione o Exame'" [options]="formOptionsAnalyte" [form]="toFromExamsFormGroup.controls['analyteId']" (changeForm)="toFromExamsFormGroup.controls['analyteId'].value ? getMaterialsAndReports() : ''"></input-search>
      </div>
      <div class="w-100">
        <input-prop [placeholder]="'Informe o Código do Exame no Apoio'" [form]="toFromExamsFormGroup.controls['codeInSupportLaboratory']"></input-prop>
      </div>
    </div>
    <div class="flex gap-form mt-32">
      <div class="w-100">
        <input-search [placeholder]="'Selecione o Material do Exame'" [options]="formOptionsMaterial" [form]="toFromExamsFormGroup.controls['materialId']"></input-search>
      </div>
      <div class="w-100">
        <input-prop [placeholder]="'Informe o Código do Material no Apoio'" [form]="toFromExamsFormGroup.controls['material']"></input-prop>
      </div>
    </div>
    <div class="flex mt-32 gap-form">
      <input-search class="w-100" [placeholder]="'Selecione o Laudo do Exame'" [form]="toFromExamsFormGroup.controls['analyteReportId']" [options]="analyteReportOptions"></input-search>
      <input-prop-currency class="w-100" (keyup.enter)="edit.editar ? updateToFromExams(edit.id) : postExam()" [placeholder]="'Valor'" [form]="toFromExamsFormGroup.controls['value']"></input-prop-currency>
    </div>
    <div class="flex flex-between mt-32 gap-form">
      <checkbox-prop class="flex flex-center" label="Receber Valores de Referência do Apoio" [gap]="1" [form]="toFromExamsFormGroup.controls['reference']"></checkbox-prop>
      <checkbox-prop class="flex flex-center" label="Possui Relacionamento (De/Para de Atributos)" [gap]="1" [form]="toFromExamsFormGroup.controls['hasRelationship']"></checkbox-prop>
    </div>
    <div class="flex flex-center mt-32">
      <app-button (click)="edit.editar ? updateToFromExams(edit.id) : postExam()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogExamsAdditionalInformationLaboratory let-additionalInformation>
  <ng-container>
    <div class="flex flex-between">
      <h1>{{additionalInformation.edit ? 'Editar De/Para de Perguntas' : 'Novo De/Para de Perguntas'}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="flex gap-form mt-32 align-center flex-center">
      <input-search class="w-100" [placeholder]="'Selecione a Pergunta do Exame'" [options]="analyteQuestionOptions" [form]="examsAdditionalInformationFormGroup.controls['examsSupportLaboratoryId']"></input-search>
      <input-prop class="w-100" [placeholder]="'Informe o Código da Pergunta no Apoio'" (keyup.enter)="additionalInformation.edit ? updateExamsAdditionalInformation(additionalInformation.rowDetail.id) : createExamsAdditionalInformation(additionalInformation.rowDetail.id)" [form]="examsAdditionalInformationFormGroup.controls['codeInSupportLaboratory']"></input-prop>
    </div>
    <div class="flex mt-32">
      <checkbox-prop class="mandatoryQuestion flex" [label]="'Pergunta Obrigatória'" (keyup.enter)="additionalInformation.edit ? updateExamsAdditionalInformation(additionalInformation.rowDetail.id) : createExamsAdditionalInformation(additionalInformation.rowDetail.id)" [form]="examsAdditionalInformationFormGroup.controls['requerid']" [gap]="1"></checkbox-prop>
    </div>
    <div class="flex flex-center mt-32">
      <app-button (click)="additionalInformation.edit ? updateExamsAdditionalInformation(additionalInformation.emit.object.id) : createExamsAdditionalInformation(additionalInformation.rowDetail.id)" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>
  
<ng-template #dialogAtributeRelationship let-additionalInformation>
  <ng-container>
    <div class="flex flex-between">
      <h1>{{additionalInformation.edit ? 'Editar De/Para de Atributos' : 'Nova De/Para de Atributos'}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="flex gap-form mt-32 align-center flex-center">
      <input-search class="w-100" [placeholder]="'Selecione o Atributo'" [options]="optionExamsAdditional" [form]="formExamsAdditiona.controls['examsSupportLaboratoryId']"></input-search>
      <input-prop class="w-100" [placeholder]="'Código do Apoio'" (keyup.enter)="postAtributeRelationship()" [form]="formExamsAdditiona.controls['atributeSupportLaboratory']"></input-prop>
    </div>
    <div class="flex flex-center mt-32">
      <app-button (click)="additionalInformation.edit ? updateAtributeRelationship(additionalInformation.emit.object.id) : creatAtributeRelationship(additionalInformation.rowDetail)" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogSupportLaboratoryCollectionTime let-additionalInformation>
  <ng-container>
    <div class="flex flex-between">
      <h1>{{additionalInformation.edit ? 'Editar Tempos para Curva' : 'Novo Tempos para Curva'}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="flex gap-form mt-32 align-center flex-center">
      <input-search class="w-100" [placeholder]="'Selecione o Recipiente'" [options]="optionCollectionTime" [form]="formCollectionTime.controls['analyteMaterialRecipientId']"></input-search>
      <input-prop class="w-100" [placeholder]="' Tempo de Curva'" [form]="formCollectionTime.controls['sampleTimeDescription']" (keyup.enter)="additionalInformation.edit ? updateCollectionTime(additionalInformation.emit.id) : createCollectionTime(additionalInformation.rowDetail)"></input-prop>
    </div>
    <div class="flex flex-center mt-32">
      <app-button (click)="additionalInformation.edit ? updateCollectionTime(additionalInformation.emit.id) : createCollectionTime(additionalInformation.rowDetail)" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>
  
<!-- <ng-template #dialogInformationCreate>
  <ng-container>
    <div class="flex flex-between">
      <h1>{{informationId ? 'Editar Informações Adicionais' : 'Nova Informação Adicional'}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="flex gap-form mt-32 align-center flex-center">
      <input-prop class="w-100" [placeholder]="'Código do Apoio'" (keyup.enter)="postInformation()" [form]="formInformation.codeInSupportLaboratory"></input-prop>
      <checkbox-prop label="Requerido" (keyup.enter)="postInformation()" [form]="formInformation.requerid" [gap]="1"></checkbox-prop>
    </div>
    <div class="flex flex-center mt-32">
      <app-button (click)="informationId ? putInformation() : postInformation()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template> -->

<ng-template #dialogCommunicationCredentials let-credential>
  <ng-container>
    <div class="flex flex-between">
      <h1>{{ credential.operation === ECredentialOperation.Create ? 'Nova Configuração de Comunicação' : 'Editar Configuração de Comunicação'}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="box mt-32">
      <h2>Credenciais</h2>
      <div class="flex gap-form mt-32">
        <input-search class="w-100" [placeholder]="'Selecione a Empresa'" [form]="supportLaboratoryCredentialsFormGroup.controls['companyId']" (changeForm)="getUnities()" [options]="companyOptions"></input-search>
      </div>
      <div class="flex gap-form mt-32">
        <input-search [readonly]='credential.operation === ECredentialOperation.Update' class="w-100" [placeholder]="'Selecione a Unidade'" [options]="unityOptions" [form]="supportLaboratoryCredentialsFormGroup.controls['unityId']"></input-search>
      </div>
      <div class="flex gap-form mt-32">
        <input-prop class="w-100" [placeholder]="'Login'" [form]="supportLaboratoryCredentialsFormGroup.controls['codeLogin']"></input-prop>
        <input-prop class="w-100" [type]="'password'" [placeholder]="'Senha'" [form]="supportLaboratoryCredentialsFormGroup.controls['password']"></input-prop>
        <input-prop class="w-100" [type]="'password'" [placeholder]="'Chave de Acesso'" [form]="supportLaboratoryCredentialsFormGroup.controls['apiKey']"></input-prop>
      </div>
      <div class="flex gap-form mt-32">
        <input-prop class="w-100" [placeholder]="'Web Service'" [form]="supportLaboratoryCredentialsFormGroup.controls['urlService']"></input-prop>
        <input-prop class="w-100" [placeholder]="'Complemento'" [form]="supportLaboratoryCredentialsFormGroup.controls['urlComplement']"></input-prop>
      </div>
    </div>
    <div class="flex flex-center mt-32">
      <app-button (click)="credential.operation === ECredentialOperation.Create ? createCommunicationCredential() : updateCommunicationCredential(credential.id)" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>