import { AsyncPipe } from "@angular/common";
import {
   AfterViewInit,
   Component,
   inject,
   OnInit,
   ViewChild
} from "@angular/core";
import { PageableComponent } from "@app/modules_new/common/components/pageable";
import { PaginationTableComponent } from "@app/modules_new/shared/components/form/table/pagination/pagination.component";
import { PaginationEvent } from "@app/modules_new/shared/components/form/table/pagination/pagination.model";
import { TableFormComponent } from "@app/modules_new/shared/components/form/table/table.component";
import { ContentFilterLayoutComponent } from "@app/modules_new/shared/layouts/content-filter/content-filter.component";
import { TranslateModule } from "@ngx-translate/core";
import { UserResponse } from "@app/modules_new/admin/data/services/accesscontrol-user-authNew/accesscontrol-user-authNew.types";
import { UsersAdminUsersFacade } from "@app/modules_new/admin/data/facades/users-admin-users.facade";
import { tableDefinitions, tableActions } from "./users";
import { FormGroup, FormControl } from "@angular/forms";
import { QueryParams } from "@app/modules_new/data/services/types/http.types";
import { Router } from "@angular/router";
import { UsersUIComponent } from "../../ui/users-ui/users-ui.component";

@Component({
   selector: "users",
   templateUrl: "./users.component.html",
   standalone: true,
   imports: [
      ContentFilterLayoutComponent,
      TableFormComponent,
      PaginationTableComponent,
      TranslateModule,
      AsyncPipe,
      UsersUIComponent
   ]
})
export class UsersComponent
   extends PageableComponent<UserResponse[]>
   implements OnInit, AfterViewInit
{
   private _router = inject(Router);
   private _usersFacade = inject(UsersAdminUsersFacade);

   @ViewChild("users") userTable!: TableFormComponent;

   formFilterGroup = new FormGroup({
      description: new FormControl(null),
      isActive: new FormControl(null)
   });

   formFilter: Array<object> = [
      {
         placeholder: "Descrição",
         type: "text",
         form: this.formFilterGroup.controls["description"],
         click: true
      },
      {
         placeholder: "agreements.filter.isActive",
         type: "toggle",
         form: this.formFilterGroup.controls["isActive"]
      }
   ];

   constructor() {
      super(tableDefinitions, tableActions);
   }

   ngOnInit() {
      this.get();
   }

   get() {
      const filters: QueryParams = {
         Search: this.formFilterGroup.controls["description"].value,
         isActive: this.formFilterGroup.controls["isActive"].value
            ? this.formFilterGroup.controls["isActive"].value
            : null
      };
      const { data$, total$ } = this._usersFacade.getUsersWithoutDropDown(
         1,
         30,
         filters
      );
      this.data = data$;
      this.total = total$;
   }

   ngAfterViewInit() {
      this.userTable.userInteractions$.subscribe(async (response) => {
         switch (response?.eventType) {
            case "IS_ACTIVE_CHANGE": {
               const success = await this._usersFacade.patch(
                  response.payload.data.id,
                  response.payload.data.isActive
               );

               if (!success) {
                  this.userTable.changeActive(
                     response.payload.data.id,
                     response.payload.data.isActive
                  );
               }
               break;
            }
            case "ACTIONS_PRESSED": {
               switch (response.payload.event) {
                  case "EDIT": {
                     this._router.navigateByUrl(
                        "/Usuarios/Editar/" + response.payload.data.id
                     );
                     break;
                  }
               }
            }
         }
      });
   }

   changePage(event: PaginationEvent) {
      this.page = event.page;
      this.numberRegistry = event.numberRegistry;
      this._usersFacade.getUsersWithoutDropDown(
         event.page,
         event.numberRegistry
      );
   }

   resetFilter() {
      this.formFilterGroup.controls.description.reset();
      this.formFilterGroup.controls.isActive.reset();
   }
}
