<div style="height: 100%;display: grid;margin: 0px;" class="card caution"  [ngClass]="checkDate()">  
  <h2 *ngIf="!headerOrange">{{header}}</h2>          
  <h2 *ngIf="headerOrange" style="color: #FF6344 !important;">{{header}}</h2>          
  <div *ngIf="!hideLines" class="infos">
    <p *ngIf="!headerOrange" style="color: #FF6344 !important;font-size: calc(13px * var(--proportional));">{{linha2}}</p>      
    <p *ngIf="headerOrange">{{linha2}}</p> 
    <p>{{linha3}}</p> 
    <p>{{linha4}}</p>    
  </div>   
  <div style="align-self:flex-end;" class="infos">              
    <p>{{linha5}}</p>
    <p>{{linha6}}</p>        
  </div>  
</div>