import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Guid } from '@app/util/guid';
import { Util } from '@app/util/util';
import { Notifications } from '@store/notification/notification.model';
import { SwAlSetttings } from "@util/swal.settings"
import { NotificationMessage } from './notification.model';
import { addAllNotification, removeNotification } from '@store/notification/notification.actions';
import { HttpAuthService } from '../http-auth.service';
import { HttpResponseModel } from '../models/httpResponse.model';
import { BaseAuthService } from '../base-auth.service';

@Injectable({
  providedIn: 'root'
})
export class PersonNotificationService extends BaseAuthService  {

  constructor(HttpAuth: HttpAuthService, private store: Store, private util: Util) {
    super(HttpAuth, 'v1/profile/personNotification');
  }

  async getAlternative(queryString: string) {

    let responseInitial = super.get(queryString) as Promise<HttpResponseModel<NotificationMessage>>
    responseInitial.catch((error) => SwAlSetttings.printError(error))

    const data = (await responseInitial).data;
    const count = (await responseInitial).count;
    data?.sort(Util.sortDate);

    const notifications = new Notifications();
    notifications.amount = count!;
    notifications.notificationsAvailable = data!

    this.store.dispatch(addAllNotification(notifications))

    return responseInitial
  }

  override put(body: Object, id: Guid) {
      let responseInitial = super.put(body, id)
      responseInitial.catch((error) => SwAlSetttings.printError(error))
      .finally(() => {
        this.store.dispatch(removeNotification({ id: id.toString() }))
      })
      return responseInitial
  }


}
