<content-return-layout [title]="title | translate" [backPath]="'/Usuarios'">
    <mat-tab-group dynamicHeight>
        <mat-tab label="Usuários">
            <div class="bg-white rounded-md p-8">
                <div class="userDiv gap-4">
                    <div class="box typeUserDiv">
                        <div class="flex w-full items-center justify-between">
                            <h2 class="text-lg">Tipo</h2>
                            <forms-button-group
                                [form]="form.controls.type"
                                [options]="[
                                    { label: 'E-mail', value: 1 },
                                    { label: 'Cód. Acesso', value: 2 }
                                ]"
                            >
                            </forms-button-group>
                        </div>
                        <div class="grid gap-8 mt-8">
                            <forms-input-text
                                type="text"
                                class="w-full"
                                [placeholder]="'Nome'"
                                [form]="form.controls.name"
                            ></forms-input-text>
                            <forms-input-text
                                [placeholder]="
                                    form.controls.type.value == 1
                                        ? 'E-mail'
                                        : 'Código de acesso'
                                "
                                type="text"
                                class="w-full"
                                [form]="form.controls.email"
                            ></forms-input-text>
                            <forms-input-text
                                mask="(00) 00000-0000"
                                type="cellphone"
                                class="w-full"
                                [placeholder]="'Celular'"
                                [form]="form.controls.cellPhone"
                            ></forms-input-text>
                            <forms-input-text
                                mask="000.000.000-00"
                                type="text"
                                class="w-full"
                                [placeholder]="'CPF'"
                                [form]="form.controls.cpf"
                            ></forms-input-text>
                            <forms-input-text
                                *ngIf="_userId == undefined"
                                type="password"
                                class="w-full"
                                [placeholder]="'Senha'"
                                [form]="form.controls.password"
                            ></forms-input-text>
                            <forms-input-text
                                *ngIf="_userId == undefined"
                                type="password"
                                class="w-full"
                                [placeholder]="'Repetir senha'"
                                [form]="form.controls.confirmPassword"
                            ></forms-input-text>
                            <forms-toggle
                                class="w-full"
                                [form]="form.controls.active"
                                [placeholder]="'Usuário ativo' | translate"
                            ></forms-toggle>
                            <forms-input-select-multiples
                                style="
                                    background-color: #f4f4f4;
                                    display: flex;
                                    align-items: center;
                                    box-shadow: inset 0 0 2px #404040;
                                    width: 100%;
                                    border-radius: calc(
                                            4px * var(--proportional)
                                        )
                                        calc(4px * var(--proportional)) 0px 0px;
                                "
                                class="w-full"
                                [placeholder]="'Departamentos'"
                                [form]="form.controls.department"
                                [optionStyle]="
                                    'border border-[var(--primary-color)] text-[var(--primary-color)] px-3 py-1 rounded-3xl'
                                "
                                [options]="departmentOptions"
                            ></forms-input-select-multiples>
                        </div>
                    </div>
                    <div class="box w-full">
                        <div class="flex w-full items-center justify-between">
                            <h2 class="text-lg">Dados de cadastro</h2>
                        </div>
                        <div class="grid gap-8 mt-8">
                            <div class="flex gap-form">
                                <forms-input-text
                                    type="date"
                                    class="w-full"
                                    [placeholder]="'Data de admissão'"
                                    [form]="form.controls.admissionData"
                                ></forms-input-text>
                                <forms-input-text
                                    type="date"
                                    class="w-full"
                                    [placeholder]="'Data de demissão'"
                                    [form]="form.controls.resignationData"
                                ></forms-input-text>
                            </div>
                            <forms-input-text
                                type="text"
                                class="w-full"
                                [placeholder]="'E-mail pessoal'"
                                [form]="form.controls.alternativeEmail"
                            ></forms-input-text>
                            <div class="flex gap-form">
                                <forms-input-text
                                    type="text"
                                    class="w-full"
                                    [placeholder]="'CEP'"
                                    [form]="form.controls.CEP"
                                ></forms-input-text>
                                <forms-input-text
                                    type="text"
                                    class="w-full"
                                    [placeholder]="'Endereço'"
                                    [form]="form.controls.address"
                                ></forms-input-text>
                            </div>
                            <div class="flex gap-form">
                                <div class="flex gap-form w-full">
                                    <forms-input-text
                                        type="text"
                                        class="w-full"
                                        [placeholder]="'Número'"
                                        [form]="form.controls.number"
                                    ></forms-input-text>
                                    <forms-input-text
                                        type="text"
                                        class="w-full"
                                        [placeholder]="'Complemento'"
                                        [form]="form.controls.complement"
                                    ></forms-input-text>
                                </div>
                                <forms-input-text
                                    type="text"
                                    class="w-full"
                                    [placeholder]="'Bairro'"
                                    [form]="form.controls.neighborhood"
                                ></forms-input-text>
                            </div>
                            <div class="flex gap-form">
                                <forms-input-text
                                    type="text"
                                    class="w-full"
                                    [placeholder]="'Cidade'"
                                    [form]="form.controls.city"
                                ></forms-input-text>
                                <forms-input-select
                                    class="w-full"
                                    placeholder="Estado"
                                    [form]="form.controls.state"
                                    [options]="stateOptions"
                                ></forms-input-select>
                            </div>
                            <div class="flex gap-form">
                                <forms-input-text
                                    type="text"
                                    class="w-full"
                                    [placeholder]="'Telefone Fixo'"
                                    [form]="form.controls.phone"
                                ></forms-input-text>
                                <forms-input-text
                                    type="number"
                                    class="w-full"
                                    [placeholder]="
                                        'Percentual de desconto máximo'
                                    "
                                    [form]="form.controls.discountPercentage"
                                ></forms-input-text>
                            </div>
                            <div class="flex gap-form">
                                <forms-input-select
                                    class="w-full"
                                    placeholder="Empresa Padrão"
                                    [form]="form.controls.defaultCompany"
                                    [options]="companyOptions"
                                    (valueChanged)="changeCompany()"
                                ></forms-input-select>
                                <forms-input-select
                                    class="w-full"
                                    placeholder="Unidade Padrão"
                                    [form]="form.controls.defaultUnity"
                                    [options]="unityOptions"
                                ></forms-input-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-center mt-8">
                    <forms-button
                        #button
                        class="self-center"
                        (click)="_userId ? update() : create()"
                        classExtra="btn btn-primary"
                        [text]="'default.save' | translate"
                    ></forms-button>
                </div>
            </div>
        </mat-tab>
        <mat-tab *ngIf="_userId" label="Permissões">
            <div class="bg-white rounded-md p-8">
                <div class="flex align-center flex-between">
                    <div class="titulo">
                        <div class="flex align-center">
                            <h2 class="text-lg">
                                Configurar permissões de “{{ editUserName }}”
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="flex flex-center">
                    <div class="w-100">
                        <div
                            class="flex gap-form align-center flex-direction-column-mobile mt-8"
                        >
                            <forms-input-select
                                class="w-full"
                                placeholder="Empresa Padrão"
                                [form]="formPermission.controls.company"
                                [options]="companyPermissionsOptions"
                                (valueChanged)="changeCompanyPermission()"
                            ></forms-input-select>
                            <forms-input-select
                                class="w-full"
                                placeholder="Unidade Padrão"
                                [form]="formPermission.controls.unity"
                                [options]="unityPermissionsOptions"
                            ></forms-input-select>
                        </div>
                        <div class="flex align-center mt-8 gap-form">
                            <h2 style="min-width: 190px" class="text-lg">
                                Adicionar papeis aos módulos
                            </h2>
                            <div class="w-100">
                                <forms-input-select
                                    class="w-full"
                                    placeholder="Módulos"
                                    [form]="formPermission.controls.module"
                                    [options]="moduleOptions"
                                    (valueChanged)="changeModule()"
                                ></forms-input-select>
                            </div>
                            <div class="w-full">
                                <forms-input-select-multiples
                                    style="
                                        background-color: #f4f4f4;
                                        display: flex;
                                        align-items: center;
                                        box-shadow: inset 0 0 2px #404040;
                                        width: 100%;
                                        border-radius: calc(
                                                4px * var(--proportional)
                                            )
                                            calc(4px * var(--proportional)) 0px
                                            0px;
                                    "
                                    class="w-full"
                                    [placeholder]="'Papéis'"
                                    [form]="formPermission.controls.roles"
                                    [optionStyle]="
                                        'border border-[var(--primary-color)] text-[var(--primary-color)] px-3 py-1 rounded-3xl'
                                    "
                                    [options]="rolesOptions"
                                ></forms-input-select-multiples>
                            </div>
                            <forms-button
                                class="self-center"
                                classExtra="btn btn-primary"
                                (click)="addRoletoUser()"
                                [text]="
                                    formPermission.controls.roles.value
                                        ?.length > 1
                                        ? 'Adicionar papeis'
                                        : ('Adicionar papel' | translate)
                                "
                            >
                            </forms-button>
                        </div>
                        <div class="modulos mt-8">
                            <h2>Módulos</h2>
                            <div class="flex itens flex-column gap-4">
                                <ng-template
                                    ngFor
                                    let-item
                                    [ngForOf]="modulesByToken"
                                >
                                    <div
                                        class="col border"
                                        *ngIf="CheckIsItemModule(item.name)"
                                    >
                                        <h4 style="text-transform: capitalize">
                                            {{
                                                "modules." + item.name
                                                    | translate
                                            }}
                                        </h4>
                                        <div
                                            class="infos mt-32"
                                            *ngFor="let policy of rolePolicies"
                                        >
                                            <ng-template
                                                [ngIf]="
                                                    CheckInSd(
                                                        item.name,
                                                        policy.resources
                                                    )
                                                "
                                            >
                                                <div
                                                    class="flex flex-between"
                                                    *ngIf="policy.name"
                                                >
                                                    <span
                                                        style="
                                                            text-transform: capitalize;
                                                        "
                                                        >{{ policy.name }}</span
                                                    >
                                                    <a
                                                        href="#"
                                                        onclick="return false"
                                                        class="deletar"
                                                        [matTooltip]="
                                                            'Deletar papel ' +
                                                            policy.name
                                                        "
                                                        (click)="
                                                            deleteRole(
                                                                policy.id,
                                                                policy.name
                                                            )
                                                        "
                                                        [matTooltipPosition]="
                                                            position.value
                                                        "
                                                        aria-label="Button that displays a tooltip in various positions"
                                                    >
                                                        <svg
                                                            width="17"
                                                            height="20"
                                                            viewBox="0 0 17 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M1.51111 18.5333C1.51111 19.3556 2.17778 20 3 20H13.0222C13.8444 20 14.5111 19.3556 14.5111 18.5333L15.0889 6.44444H0.955555L1.51111 18.5333ZM10.6667 8.86667C10.6667 8.44444 10.9111 8.11111 11.3333 8.11111C11.7556 8.11111 12 8.44444 12 8.86667V17.2222C12 17.6444 11.7556 17.9778 11.3333 17.9778C10.9111 17.9778 10.6667 17.6444 10.6667 17.2222V8.86667ZM7.33333 8.86667C7.33333 8.44444 7.57778 8.11111 8 8.11111C8.42222 8.11111 8.66667 8.44444 8.66667 8.86667V17.2222C8.66667 17.6444 8.42222 17.9778 8 17.9778C7.57778 17.9778 7.33333 17.6444 7.33333 17.2222V8.86667ZM4 8.86667C4 8.44444 4.24444 8.11111 4.66667 8.11111C5.08889 8.11111 5.33333 8.44444 5.33333 8.86667V17.2222C5.33333 17.6444 5.08889 17.9778 4.66667 17.9778C4.24444 17.9778 4 17.6444 4 17.2222V8.86667Z"
                                                                fill="white"
                                                            />
                                                            <path
                                                                d="M15.3556 2.66667H11.3333V0.688889C11.3333 0.288889 11.1333 0 10.7111 0H5.33333C4.91111 0 4.66667 0.288889 4.66667 0.688889V2.66667H0.688889C0.266667 2.66667 0 2.97778 0 3.4V4.24444C0 4.66667 0.266667 5.11111 0.688889 5.11111H15.3778C15.8 5.11111 16.0222 4.64444 16.0222 4.24444V3.4C16 2.97778 15.7778 2.66667 15.3556 2.66667ZM10 2.66667H6V1.33333H10V2.66667Z"
                                                                fill="white"
                                                            />
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div
                                                    class="optionscase flex-wrap"
                                                >
                                                    <ng-template
                                                        ngFor
                                                        let-resource
                                                        [ngForOf]="
                                                            policy.resources
                                                        "
                                                    >
                                                        <a
                                                            href="#"
                                                            matTooltip="Info about the action"
                                                            [matTooltipPosition]="
                                                                position.value
                                                            "
                                                            aria-label="Button that displays a tooltip in various positions"
                                                            (click)="
                                                                CheckIsUserRole(
                                                                    resource.name
                                                                )
                                                                    ? NegatePolicy(
                                                                          resource.name,
                                                                          resource.id
                                                                      )
                                                                    : GarantedPolicy(
                                                                          resource.name,
                                                                          resource.id
                                                                      )
                                                            "
                                                            [ngClass]="
                                                                CheckIsUserRole(
                                                                    resource.name
                                                                )
                                                                    ? 'act'
                                                                    : ''
                                                            "
                                                            onclick="return false"
                                                            *ngIf="
                                                                resource.moduleName ===
                                                                item.name
                                                            "
                                                        >
                                                            <span>
                                                                {{
                                                                    getRole(
                                                                        resource.name,
                                                                        item.name
                                                                    )
                                                                        | translate
                                                                }}
                                                            </span>

                                                            <svg
                                                                *ngIf="
                                                                    CheckIsUserRole(
                                                                        resource.name
                                                                    )
                                                                "
                                                                width="21"
                                                                height="21"
                                                                viewBox="0 0 21 21"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M18.6818 10.6478C18.6818 6.12913 15.0187 2.46601 10.5 2.46601C5.98131 2.46601 2.31818 6.12913 2.31818 10.6478C2.31818 15.1665 5.98131 18.8296 10.5 18.8296C15.0187 18.8296 18.6818 15.1665 18.6818 10.6478ZM10.5 0.647827C16.0228 0.647827 20.5 5.12498 20.5 10.6478C20.5 16.1707 16.0228 20.6478 10.5 20.6478C4.97715 20.6478 0.5 16.1707 0.5 10.6478C0.5 5.12498 4.97715 0.647827 10.5 0.647827Z"
                                                                    fill="#EB5757"
                                                                />
                                                                <path
                                                                    d="M15.0455 10.6477C15.0455 11.1498 14.6384 11.5568 14.1364 11.5568L6.86364 11.5568C6.36156 11.5568 5.95454 11.1498 5.95455 10.6477C5.95455 10.1456 6.36156 9.73862 6.86364 9.73862H14.1364C14.6384 9.73862 15.0455 10.1456 15.0455 10.6477Z"
                                                                    fill="#EB5757"
                                                                />
                                                            </svg>
                                                            <svg
                                                                *ngIf="
                                                                    !CheckIsUserRole(
                                                                        resource.name
                                                                    )
                                                                "
                                                                width="21"
                                                                height="21"
                                                                viewBox="0 0 21 21"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M18.6818 10.6478C18.6818 6.12913 15.0187 2.46601 10.5 2.46601C5.98131 2.46601 2.31818 6.12913 2.31818 10.6478C2.31818 15.1665 5.98131 18.8296 10.5 18.8296C15.0187 18.8296 18.6818 15.1665 18.6818 10.6478ZM10.5 0.647827C16.0228 0.647827 20.5 5.12498 20.5 10.6478C20.5 16.1707 16.0228 20.6478 10.5 20.6478C4.97715 20.6478 0.5 16.1707 0.5 10.6478C0.5 5.12498 4.97715 0.647827 10.5 0.647827ZM15.0455 10.6478C15.0455 11.1499 14.6384 11.5569 14.1364 11.5569L11.4091 11.5569V14.2842C11.4091 14.7863 11.0021 15.1933 10.5 15.1933C9.99792 15.1933 9.59091 14.7863 9.59091 14.2842L9.59091 11.5569H6.86364C6.36156 11.5569 5.95455 11.1499 5.95455 10.6478C5.95455 10.1458 6.36156 9.73874 6.86364 9.73874H9.59091V7.01146C9.59091 6.50939 9.99792 6.10237 10.5 6.10237C11.0021 6.10237 11.4091 6.50939 11.4091 7.01146V9.73874H14.1364C14.6384 9.73874 15.0455 10.1458 15.0455 10.6478Z"
                                                                    fill="#07BF56"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </ng-template>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</content-return-layout>
