<div class="contentPage">
    <div class="flex align-center w-100 flex-direction-column-mobile">
      <div class="flex align-center w-100 gp-12 flex-direction-column-mobile">
        <h1>{{'departments.title' | translate}}</h1>
        <app-button (click)="openDialog()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
      </div>
      <app-filter class="filterResponsive" (beforeClose)="getDepartments()" [cleanButton]="true" (clearFilters)="clearFilter()">
        <div class="query">
          <input-prop #autoFocus (keyup.enter)="getDepartments()" class="w-100" [placeholder]="'groups.description'" [form]="searchForm"></input-prop>
        </div>
        <app-button (click)="getDepartments()" class="queryBtn" [placeholder]="'groups.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
      </app-filter>
    </div>
    <div class="mt-32">
      <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="numberRegistry" [Itens]="departments" [Actions]="['Editar']"  (parentFun)="actions($event)" (changePage)="getDepartments($event)"></app-table>   
    </div>
</div>
  
<ng-template #dialogModal let-departmentData>
    <ng-container>
        <div class="flex flex-between w-100 align-center">
            <h1>{{departmentData.id ? ('departments.modal.edit' | translate) : ('departments.modal.new' | translate)}}</h1>
            <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
            </svg>
        </div>
        <div class="mt-32 flex align-center gp-12" (keyup.enter)="departmentData.id ? updateDepartment() : createDepartment()">
            <input-prop [placeholder]="'departments.table-columns.externalId'" [form]="departmentForm.controls.externalId"></input-prop>
            <input-prop class="w-100" [placeholder]="'departments.table-columns.department'" [form]="departmentForm.controls.name"></input-prop>
        </div>
        <div class="mt-32 flex flex-center">
            <app-button (click)="departmentData.id ? updateDepartment(departmentData.id) : createDepartment()" [placeholder]="'global.save'" [class]="'btn'"></app-button>
        </div>
    </ng-container>
</ng-template>