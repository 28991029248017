import { DefaultFormats } from "@app/pipe/localeDate.pipe";
import { EAgreementExamsRoutine } from "@app/util/EAgreementExamsRoutine";
import { ETypePayments } from "@app/util/ETypePayments";

export enum EType {
   VISUALIZAR = 0,
   EDITAR = 1,
   NOVO = 2
}

export interface sectorOption {
   value: string;
   label: string;
   externalId: string;
}

export interface GetAllTypePayments {
   description: string;
   id: string;
   type: ETypePayments;
}

export interface Resolve {
   label: string;
   retrive: string;
   method: string;
   after: string;
   before: string;
}

export interface Actions {
   action: string;
   context: string | null;
   object: ResolvedCustomerObject;
}

interface ResolvedCustomerObject {
   id: string;
   Nome: string;
   Sexo: string;
   Nascimento: string;
   "Nome da Mãe": string;
   "CNPJ/CPF": string;
   Celular: string;
   "E-mail": string;
   "Data de Cadastro": string;
}

export interface ResolvedAgreementRequestObject {
   id: string;
   Convênio: string;
   Solicitante: string;
   Conselho: string;
   Autorização: string;
   Carteirinha: string;
   CID: string;
   Guia: string;
   "Criado em": string;
   "Última Alteração": string;
}

export interface SelectOptions {
   label: string;
   value: string;
}

export interface ExamOptions {
   code: string;
   value: string;
   label: string;
   information: string;
}

export interface UnityOptions {
   value: string;
   label: string;
}

export const resolveFinancial: Array<Object> = [
   {
      label: "id",
      method: "",
      retrive: "id",
      after: "",
      before: ""
   },
   {
      label: "Convênio",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "description",
      type: "description"
   },
   {
      label: "Plano",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "description",
      type: "description"
   },
   {
      label: "Valor Total Bruto",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "totalPrice",
      type: "money"
   },
   {
      label: "Alterado em",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "updatedAt",
      type: "dateUpdate",
      dateFormat: DefaultFormats.full
   }
];

export const resolveQuestions: Array<Object> = [
   {
      label: "id",
      method: "",
      retrive: "id",
      after: "",
      before: ""
   },
   {
      label: "requestResume.questions.name",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "name",
      type: "description"
   },
   {
      label: "requestResume.questions.answer",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "answer",
      type: "description"
   },
   {
      label: "requestResume.questions.updatedAt",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "updatedAt",
      type: "dateUpdate",
      dateFormat: DefaultFormats.full
   }
];

export const resolveInvoiceProgressHistory: Array<Object> = [
   {
      label: "id",
      method: "",
      retrive: "id",
      after: "",
      before: ""
   },
   {
      label: "_hide_agreementRequestId",
      method: "",
      retrive: "agreementRequestId",
      after: "",
      before: ""
   },
   {
      label: "financial.resolveInvoiceProgressHistory.personName",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "personName",
      type: "name"
   },
   {
      label: "financial.resolveInvoiceProgressHistory.situation",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "situation",
      type: "invoiceStatus"
   },
   {
      label: "financial.resolveInvoiceProgressHistory.value",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "value",
      type: "money"
   },
   {
      label: "financial.resolveInvoiceProgressHistory.createdAt",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "createdAt",
      type: "date",
      dateFormat: DefaultFormats.full
   },
   {
      label: "Ativo",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "isActive",
      type: "boolean"
   },
   {
      label: "Hash Transação",
      method: "",
      retrive: "hashProcess",
      after: "",
      before: ""
   }
];

export const resolveAgreementRequest: Array<Object> = [
   {
      label: "id",
      method: "",
      retrive: "id",
      after: "",
      before: ""
   },
   {
      label: "requisitionMedicalRequest.resolveAgreementRequest.description",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "description",
      type: "description"
   },
   {
      label: "requisitionMedicalRequest.resolveAgreementRequest.agreementPlanDescription",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "agreementPlanDescription",
      type: "description"
   },
   {
      label: "requisitionMedicalRequest.resolveAgreementRequest.name",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "name",
      type: "name"
   },
   {
      label: "requisitionMedicalRequest.resolveAgreementRequest.council",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "council",
      type: "doctorCouncil",
      interpolationFields: "council, registrationState, registrationNumber"
   },
   {
      label: "requisitionMedicalRequest.resolveAgreementRequest.authorization",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "authorization",
      type: "description"
   },
   {
      label: "requisitionMedicalRequest.resolveAgreementRequest.cardNumber",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "cardNumber",
      type: "description"
   },
   {
      label: "requisitionMedicalRequest.resolveAgreementRequest.cid",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "cid",
      type: "description"
   },
   {
      label: "requisitionMedicalRequest.resolveAgreementRequest.code",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "code",
      type: "description"
   },
   {
      label: "_hide_agreementPlanId",
      method: "",
      retrive: "agreementPlanId",
      after: "",
      before: ""
   },
   {
      label: "_hide_agreementId",
      method: "",
      retrive: "agreementId",
      after: "",
      before: ""
   }
];

export const resolveExams: Array<Object> = [
   {
      label: "id",
      method: "",
      retrive: "id",
      after: "",
      before: ""
   },
   {
      label: "_hide_id",
      method: "",
      retrive: "id",
      after: "",
      before: ""
   },
   {
      label: "_hide_materialId",
      method: "",
      retrive: "materialId",
      after: "",
      before: ""
   },
   {
      label: "Código",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "code",
      type: "name"
   },
   {
      label: "Descrição do Exame",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "name",
      type: "name"
   },
   {
      label: "Material",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "description",
      type: "name"
   },
   {
      label: "Código de Fatura",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "invoiceCode",
      type: "name"
   },
   {
      label: "Caráter de Atendimento",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "routine",
      type: "routineType"
   },
   {
      label: "Previsão de Entrega",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "analyteDeadline",
      type: "date",
      dateFormat: DefaultFormats.full
   },
   {
      label: "Situação",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "status",
      type: "examSituation"
   },
   {
      label: "Deve Guia?",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "requestPending",
      type: "boolean"
   }
];

export const resolveExamsWithValue: Array<Object> = [
   {
      label: "id",
      method: "",
      retrive: "id",
      after: "",
      before: ""
   },
   {
      label: "_hide_id",
      method: "",
      retrive: "id",
      after: "",
      before: ""
   },
   {
      label: "_hide_materialId",
      method: "",
      retrive: "materialId",
      after: "",
      before: ""
   },
   {
      label: "requisitionMedicalRequest.resolveExamsWithValue.code",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "code",
      type: "name"
   },
   {
      label: "requisitionMedicalRequest.resolveExamsWithValue.name",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "name",
      type: "name"
   },
   {
      label: "requisitionMedicalRequest.resolveExamsWithValue.invoiceCode",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "invoiceCode",
      type: "name"
   },
   {
      label: "requisitionMedicalRequest.resolveExamsWithValue.requestCode",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "requestCode",
      type: "agreementRequestCode"
   },
   {
      label: "requisitionMedicalRequest.resolveExamsWithValue.value",
      method: "",
      retrive: "",
      after: "",
      before: "",
      fieldName: "value",
      type: "money"
   },
   {
      label: "requisitionMedicalRequest.resolveExamsWithValue.description",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "description",
      type: "name"
   },
   {
      label: "requisitionMedicalRequest.resolveExamsWithValue.status",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "status",
      type: "examSituation"
   },
   {
      label: "requisitionMedicalRequest.resolveExamsWithValue.analyteDeadline",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "analyteDeadline",
      type: "date",
      dateFormat: DefaultFormats.full
   }
];

export const resolveMedication: Array<Object> = [
   {
      label: "id",
      retrive: "idProduto",
      method: "",
      after: "",
      before: ""
   },
   {
      label: "Nome",
      retrive: "nomeProduto",
      method: "",
      after: "",
      before: ""
   },
   {
      label: "_hide_RazaoSocial",
      retrive: "razaoSocial",
      method: "",
      after: "",
      before: ""
   },
   {
      label: "Razão Social",
      retrive: "razaoSocial",
      method: "",
      after: "",
      before: ""
   },
   {
      label: "extra",
      retrive: "getExtra",
      method: "",
      after: "",
      before: ""
   }
];

export const resolveCollectResumeExams: Array<Object> = [
   {
      label: "id",
      method: "",
      retrive: "id",
      after: "",
      before: ""
   },
   {
      label: "_hide_status",
      method: "",
      retrive: "situation",
      after: "",
      before: ""
   },
   {
      label: "_hide_routine",
      method: "",
      retrive: "routine",
      after: "",
      before: ""
   },
   {
      label: "requestResume.resolveCollectResumeExams.analyteCode",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "analyteCode",
      type: "description"
   },
   {
      label: "requestResume.resolveCollectResumeExams.analyteName",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "analyteName",
      type: "description"
   },
   {
      label: "requestResume.resolveCollectResumeExams.materialDescription",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "materialDescription",
      type: "description"
   },
   {
      label: "requestResume.resolveCollectResumeExams.routine",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "routine",
      type: "routine"
   },
   {
      label: "requestResume.resolveCollectResumeExams.analyteDeadLine",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "analyteDeadLine",
      type: "date",
      dateFormat: DefaultFormats.full
   },
   {
      label: "requestResume.resolveCollectResumeExams.actionCollect",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "actionCollect",
      type: "examCollectSituation"
   },
   // {
   //   label: "Ação",
   //   method: 'formatField',
   //   retrive: '',
   //   after: '',
   //   before: '',
   //   fieldName: 'actionCollect',
   //   type: 'actionCollect'
   // },
   {
      label: "_hide_actionCollect",
      method: "",
      retrive: "actionCollect",
      after: "",
      before: ""
   },
   {
      label: "_hide_analyteDeadline",
      method: "",
      retrive: "analyteDeadLine",
      after: "",
      before: ""
   },
   {
      label: "requestResume.resolveCollectResumeExams.collectDate",
      method: "",
      retrive: "",
      after: "",
      before: "",
      fieldName: "collectionDate",
      type: "date",
      dateFormat: DefaultFormats.full
   }
];

export const resolveAgreementExamsRequest: Array<Object> = [
   {
      label: "id",
      method: "",
      retrive: "id",
      after: "",
      before: ""
   },
   {
      label: "requisitionMedicalRequest.addExam.tableExam.code",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "code",
      type: "name"
   },
   {
      label: "requisitionMedicalRequest.addExam.tableExam.name",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "name",
      type: "description"
   },
   {
      label: "requisitionMedicalRequest.addExam.tableExam.invoiceCode",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "invoiceCode",
      type: "description"
   },
   {
      label: "requisitionMedicalRequest.addExam.tableExam.description",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "description",
      type: "description"
   },
   // {
   //   label: "Caráter de Atendimento",
   //   method: "formatField",
   //   retrive: '',
   //   after: '',
   //   before: '',
   //   fieldName: 'routine',
   //   type: 'routine'
   // },
   {
      label: "requisitionMedicalRequest.addExam.tableExam.collectionPlaceOriginDescription",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "collectionPlaceOriginDescription",
      type: "description"
   },
   {
      label: "_hide_analyteName",
      method: "",
      retrive: "name",
      after: "",
      before: ""
   },
   {
      label: "_hide_requestPending",
      method: "",
      retrive: "requestPending",
      after: "",
      before: ""
   },
   {
      label: "_hide_analyteId",
      method: "",
      retrive: "analyteId",
      after: "",
      before: ""
   },
   {
      label: "_hide_materialId",
      method: "",
      retrive: "materialId",
      after: "",
      before: ""
   },
   {
      label: "_hide_routine",
      method: "",
      retrive: "routine",
      after: "",
      before: ""
   },
   {
      label: "_hide_collectionPlaceOriginId",
      method: "",
      retrive: "collectionPlaceOriginId",
      after: "",
      before: ""
   },
   {
      label: "requisitionMedicalRequest.addExam.tableExam.collectionPlaceInstruction",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "collectionPlaceInstructionDescription",
      type: "description"
   },
   {
      label: "_hide_collectionPlaceInstructionId",
      method: "",
      retrive: "collectionPlaceInstructionId",
      after: "",
      before: ""
   },
   {
      label: "requisitionMedicalRequest.addExam.tableExam.requestPending",
      method: "formatField",
      retrive: "",
      after: "",
      before: "",
      fieldName: "requestPending",
      type: "boolean"
   }
];

export const resolveAgreementExams: Array<Object> = [
   {
      label: "id",
      method: "",
      retrive: "id",
      after: "",
      before: ""
   },
   {
      label: "_hide_agreementExamsSupportLaboratoryId",
      method: "",
      retrive: "agreementExamsSupportLaboratoryId",
      after: "",
      before: ""
   },
   {
      label: "Selecionar",
      method: "returnCheckBox",
      retrive: "",
      after: "",
      before: ""
   },
   {
      label: "Exame",
      method: "getExam",
      retrive: "",
      after: "",
      before: ""
   },
   {
      label: "Exame Apoio",
      method: "",
      retrive: "supportLaboratoryAnalyteCode",
      after: "",
      before: ""
   },
   {
      label: "Material",
      method: "getMaterial",
      retrive: "",
      after: "",
      before: ""
   },
   {
      label: "Material Apoio",
      method: "",
      retrive: "supportLaboratoryMaterialCode",
      after: "",
      before: ""
   }
];

export interface ResumeCollectExams {
   id: string;
   Código: string;
   "Descrição do Exame": string;
   Material: string;
   "Caráter de Atendimento": string;
   "Previsão de Entrega": string;
   Ação: string;
   "Data de Coleta": string;
   _hide_actionCollect: number;
   _hide_analyteDeadline: string;
   _hide_routine: number;
   _hide_status: number;
   _hide_collectDate: Date | null;
   Situação: string;
}
