import { CommonModule } from "@angular/common";
import {
   Component,
   ElementRef,
   EventEmitter,
   inject,
   Input,
   Output,
   ViewChild
} from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { DataComponent } from "@app/modules_new/common/components/data";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "forms-toggle",
   templateUrl: "./toggle.component.html",
   styleUrls: ["./toggle.component.scss"],
   standalone: true,
   imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule]
})
export class ToggleFormComponent extends DataComponent {
   @Input() form!: FormControl;
   @Input() placeholder!: string;
   @Input() disabled = false;

   @Input() largeStyle = false;

   @Output() changed = new EventEmitter();

   @ViewChild("checkboxElement") checkboxElement!: ElementRef;

   withoutFormValue = false;
   private _router = inject(Router);
   isAdm: boolean = this._router.url.includes("/Admin") ? true : false;

   onChange(value: string) {
      if (this.form) {
         this.form.setValue(value);
         this.changed.emit(value);
      } else {
         this.changed.emit(this.withoutFormValue);
      }
   }

   focusCheckbox() {
      this.checkboxElement.nativeElement.focus();
   }
}
