<div class="contentPage">
  <mat-tab-group (selectedIndexChange)="myTabSelectedTabChange($event)">
    <mat-tab label="Envio">
      <div style="margin-top: 15px;">
        <div class="w-100 flex align-center flex-between headerResponsive">
          <div class="flex align-center gap-2">
            <h1>Laboratório de Apoio - Envio</h1>      
          </div>
          <app-filter (beforeClose)="get()" [cleanButton]="true" [topUnset]="true" (clearFilters)="resetFilter()">
            <div class="query">
              <input-prop [placeholder]="'Data Inicial'" type="datetime-local" [form]="supportLaboratory.initialDate"></input-prop>
              <input-prop [placeholder]="'Data Final'" type="datetime-local" [form]="supportLaboratory.finalDate"></input-prop>        
            </div>
            <div class="queryBtn">
              <button class="flex gap-2 align-center" (click)="get()">
                <mat-icon>search</mat-icon>
                Pesquisar
              </button>
            </div>
          </app-filter>
        </div>
        <div style="margin-top: 24px;" class="flex gap-3 flex-direction-column-mobile w-100">
          <div class="flex gap-3 flex-direction-column-mobile w-100">
            <div class="w-100">
              <input-search [options]="supportLaboratoryOptions" placeholder="Selecione o laboratório de apoio" [form]="supportLaboratory.description" (changeForm)="get()"></input-search>          
            </div>
          </div>
          <div class="flex gap-3 flex-direction-column-mobile w-100 flex-between">
            <div class="w-100">
              <input-prop placeholder="Requisição" [form]="supportLaboratory.requisition"></input-prop>
            </div>    
            <div class="w-100">
              <select-prop placeholder="Situação" [form]="supportLaboratory.situation" [options]="situation" (change)="get()"></select-prop>
            </div>
            <div style="max-width: 160px;" class="w-100">
              <checkbox-prop (click)="checkboxAll($event)" [form]="supportLaboratory.checkbox" [label]="'Selecionar todos'"></checkbox-prop>
            </div>            
            <div style="min-width: 170px;" class="flex flex-center mb-32">
              <button *ngIf="supportLaboratory.situation.value == 0" class="flex gap-3 align-center buttonValidate" (click)="send(1)">Validar</button>
              <button *ngIf="supportLaboratory.situation.value == 1" class="flex gap-3 align-center buttonValidate" (click)="send(3)">Enviar</button>
              <button *ngIf="supportLaboratory.situation.value == 2" class="flex gap-3 align-center buttonValidate" (click)="send(3)">Enviar</button>              
            </div>
          </div>  
        </div>  
        <app-table style="margin-top: 10px;" class="w-100" [checkBoxTrue]="checkBoxTrue" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [ClickInItem]="ActionsExtra" [indexNumber]="index" [Itens]="registerExams$ | async" (changePage)="get($event)" (parentFun)="Actions($event)"></app-table>    
      </div>
    </mat-tab>
    <mat-tab label="Envio por recipiente">
      <div class="mt-32">
        <div id="header" style="margin-bottom: 2px;" class="flex align-center w-100 flex-direction-column-mobile flex-between">
          <h1>Envio por recipiente</h1>
          <button (click)="openDialogModalUserValidation()" *ngIf="loggedUser === ''">Autenticar Usuário</button>
          <div *ngIf="loggedUser != ''" class="flex flex-between align-center gap-2">
            <h1>Usuário Atual:</h1>
            <b>{{loggedUserName}}</b>
            <button (click)="finish()">Finalizar</button>
          </div>
        </div>
        <div *ngIf="loggedUser != ''" style="margin-top: 24px;" class="flex gap-3 flex-direction-column-mobile w-100">
          <div class="flex gap-3 flex-direction-column-mobile w-100">
            <div class="w-100">
              <input-search [options]="supportLaboratoryOptions" placeholder="Selecione o laboratório de apoio" [form]="supportLaboratory.description"></input-search>          
            </div>
          </div>
          <div class="flex gap-3 flex-direction-column-mobile w-100 flex-between">
            <div class="w-100">
              <select-prop placeholder="Situação" [form]="supportLaboratory.situation" [options]="situation"></select-prop>
            </div>
            <div class="w-100">
              <input-prop *ngIf="loggedUser != ''" (keydown.enter)="form.codeBarFilter.value ? putCodeBarRequisition(form.codeBarFilter.value) : ''" type="number" placeholder="Informe o Código de Barras" [form]="form.codeBarFilter"></input-prop>
            </div>
            <div style="min-width: 170px;">
              <button *ngIf="supportLaboratory.situation.value == 1" class="flex gap-3 align-center buttonValidate w-100" (click)="validation()">Validar</button>
              <button *ngIf="supportLaboratory.situation.value == 2" class="flex gap-3 align-center buttonValidate w-100" (click)="send()">Enviar</button>
              <button *ngIf="supportLaboratory.situation.value == 3" class="flex gap-3 align-center buttonValidate w-100" (click)="removeSend()">Remover Envio</button>
              <button *ngIf="supportLaboratory.situation.value == 4" class="flex gap-3 align-center buttonValidate w-100" (click)="validation()">Validar</button>
            </div>
          </div>
        </div>
        <div *ngIf="loggedUser != ''" style="margin-top: 10px;" class="gap-4 data-table">
          <app-table style="margin-top: 10px;" class="w-100" [Pagination]="false" [backPagination]="false" [Itens]="registerRecipients" [ClickInItem]="ActionsExtra" (parentFun)="Actions($event)"></app-table>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Lotes Enviados">
      <div style="margin-top: 15px;" class="w-100 flex align-center flex-between headerResponsive">
        <div class="flex align-center gap-2">
          <h1>Laboratório de Apoio - Envio</h1>      
        </div>
        <app-filter (beforeClose)="get()" [cleanButton]="true" [topUnset]="true" (clearFilters)="resetFilter()">
          <div class="query">
            <input-prop [placeholder]="'Data Inicial'" type="datetime-local" [form]="supportLaboratory.initialDate"></input-prop>
            <input-prop [placeholder]="'Data Final'" type="datetime-local" [form]="supportLaboratory.finalDate"></input-prop>        
            <select-prop [placeholder]="'Selecione a Unidade'" [form]="supportLaboratory.unity" [options]="unityOptions"></select-prop>
          </div>
          <div class="queryBtn">
            <button class="flex gap-2 align-center" (click)="get()">
              <mat-icon>search</mat-icon>
              Pesquisar
            </button>
          </div>
        </app-filter>
      </div>
      <div style="margin-top: 24px;" class="flex gap-3 flex-direction-column-mobile w-100">
        <div class="flex gap-3 flex-direction-column-mobile w-100">
          <div class="w-100">
            <input-search [options]="supportLaboratoryOptions" placeholder="Selecione o laboratório de apoio" [form]="supportLaboratory.description" (changeForm)="get()"></input-search>          
          </div>
        </div>
        <div class="flex gap-3 flex-direction-column-mobile w-100 flex-between">
          <div class="w-100">
            <input-prop placeholder="Requisição" [form]="supportLaboratory.requisition"></input-prop>
          </div>              
          <div style="max-width: 160px;" class="w-100">
            <checkbox-prop (click)="checkboxAll($event)" [form]="supportLaboratory.checkbox" [label]="'Selecionar todos'"></checkbox-prop>
          </div>                      
        </div>  
      </div>  
      <div style="margin-top: 10px;" class="gap-4 data-table">
        <app-table style="margin-top: 10px;" class="w-100" [Itens]="registerSends"></app-table>    
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #dialogUserValidation let-userValidation>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h2>Autenticação por Código de Barras</h2>
        <svg class="close-svg" (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="flex w-100 mt-32 gap-2 align-center">
        <div class="w-100">
          <input-prop (keydown.enter)="validateUserCodebar()" type="number" [form]="form.userCodeBar" [placeholder]="'Código de Barras'"></input-prop>
        </div>
      </div>
      <div class="flex flex-center gap-2 mt-32">
        <button (click)="validateUserCodebar()" class="btn">Validar</button>
      </div>
    </div>
  </ng-container>
</ng-template>