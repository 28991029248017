import { Injectable, inject } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import { lastValueFrom } from "rxjs";
import { ForgotPasswordService } from "./forgotPassword.service";

@Injectable({
   providedIn: "root"
})
export class ForgotPasswordFacade extends MultiObservableFacade {
   private _forgotPasswordService = inject(ForgotPasswordService);

   async post(obj: any) {
      try {
         const response: any = await lastValueFrom(
            this._forgotPasswordService.post(obj)
         );

         return response.success;
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      return false;
   }
}
