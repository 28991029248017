<div class="contentPage">
   <div class="title flex flex-between align-center">
      <h1>Configurar Protocolo</h1>
      <button class="btn" (click)="getHtmlStructure()">Criar Modelo</button>
   </div>

   <div class="flex flex-between mt-32" style="height: 100%">

      <!-- #region Preview do Protocolo -->
      <!-- TODO Implementar funcionalidade para imprimir no tamanho A5 (620px não se adequa exatamente), necessário realizar mais testes
      [ngStyle]="{'width': this.form.selectFormatOfPaper.value === 'a4' ? '1240px' : '620px'}"
      -->

      <div class="flex fixed-width" style="max-height: 100%">
         <div class="pd-12 flex flex-column gp-12">
            <button class="zoom-btn" title="Aumentar Zoom" (click)="zoomIn()"><mat-icon>add</mat-icon></button>
            <button class="zoom-btn" title="Diminuir Zoom" (click)="zoomOut()"><mat-icon>remove</mat-icon></button>
            <button class="zoom-btn" title="Voltar Zoom para o valor padrão" (click)="resetZoom()"><mat-icon>replay</mat-icon></button>
         </div>

         <div class="w-100" style="overflow: auto; background-color: rgb(218, 218, 218); height: 100%;">

            <div class="print-page" id="protocolHtml"  [style.transform]="'scale(' + currentScale + ')'" #printPage>
               <div class="print-page-content">

                  <!-- #region Primeiro Cabeçalho -->
                  <div>
                     <ng-container *ngFor="let headerItem of FirstHeaderHtmlElements; let i = index">
                        <div id={{i}} [innerHTML]="headerItem | sanitizeHtml" style="word-wrap: break-word; width: 100%; font-family: 'Inter'; font-size: 20px; font-weight: 600; line-height: 12px; color: #404040;"></div>
                     </ng-container>
                  </div>
                  <!-- #endregion Primeiro Cabeçalho -->

                  <!-- #region Segundo Cabeçalho -->
                  <div *ngIf="FirstHeaderHtmlElements.length > 0" style="border-bottom: 1px black solid"></div>
               
                  <div style="background-color: white; overflow: hidden;" id="grid" class="grid-container row">
                     <ng-container *ngFor="let headerItem of SecondHeaderHtmlElements;">
                     <!-- <div class="col"> -->
                        <div [innerHTML]="headerItem | sanitizeHtml"></div>
                     <!-- </div> -->
                     </ng-container>
                  </div>
                  <!-- #endregion Segundo Cabeçalho -->

                  <div *ngIf="SecondHeaderHtmlElements.length > 0" style="border-bottom: 1px black solid"></div>

                  <!-- #region Corpo -->
                  <div *ngIf="bodyView" #dyntab class="w-100 flex">
                     <div style="background-color: white;" class="w-inherit table-container">
                        <table style="width: 100%;"> <!-- NOTE Removido conversão para A5 [ngStyle]="{'width': this.form.selectFormatOfPaper.value === 'a4' ? '1240px' : '620px'}" -->
                           <thead>
                              <tr>
                                 <th *ngFor="let x of tableData.headers; let i = index;">
                                    <!-- <span>{{ x.label + i }} </span> -->
                                    <div [innerHtml]="generateHeader(x, i) | sanitizeHtml" style="text-align: left;"></div>
                                 </th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let row of tableData.data">
                                 <td *ngFor="let h of tableData.headers">
                                    <div [innerHtml]="generateBody(row[h.key]) | sanitizeHtml" style="text-align: left;"></div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
                  <!-- #endregion Corpo -->

                  <div *ngIf="bodyView" style="border-bottom: 1px black solid"></div>

                  <!-- #region Rodapé -->
                  <div style="background-color: white;">
                     <ng-container *ngFor="let footerItem of FooterHtmlElements; let i = index">
                        <div id={{i}} [innerHTML]="footerItem | sanitizeHtml"></div>
                     </ng-container>
                  </div>
                  <!-- #endregion Rodapé -->
               </div>
            </div>
         </div>
      </div>
      <!-- #endregion Preview do Protocolo -->


      <!-- #region Botões e Inputs do Usuário -->
      
      <div class="input-fields fixed-width">
         <mat-tab-group [(selectedIndex)]="selectedIndex">
         
            <mat-tab>
               <ng-template mat-tab-label>
                  <div class="flex align-center gap-1">
                     <h2>Configurar Protocolo</h2>
                  </div>
               </ng-template>
               <div class="flex mt-32 input-subfield" style="gap: 10px;">
                  <fieldset class="box w-100" style="padding: 15px;">
                     <legend>Informe neste bloco as informações referentes ao Modelo do Protocolo</legend>
                     <div class="flex flex-column mt-16 gap-form w-100">
                        <input-prop placeholder="Informe o nome do modelo" [form]="form.templateDescription"></input-prop>
                        <select-prop placeholder="Informe a Unidade" [form]="form.unity" [options]="unityOptions"></select-prop>
                     </div>
                  </fieldset>
               </div>
            </mat-tab>

            <mat-tab>
               <ng-template mat-tab-label>
                  <div class="flex align-center gap-1">
                     <h2>Primeiro Cabeçalho</h2>
                  </div>
               </ng-template>
               <div class="input-subfield">
                  <fieldset class="box mt-32" style="padding: 15px;">
                     <legend>Use este bloco para inserir as informações no protocolo</legend>
                     <div class="flex flex-between w-100">
                        <div class="flex flex-column gap-form mt-16 w-100">
                           <input-prop class="w-100" placeholder="Descrição do Campo" [form]="form.inputForm"></input-prop>
                           <div class="flex gap-form">
                              <select-prop class="w-100" placeholder="Tipo da Descrição" [form]="form.selectTextTypeForm" [options]="SelectTypeOptions"></select-prop>
                              <select-prop class="w-100" placeholder="Tipo de Dado" [form]="form.selectVariableForm" [options]="SelectOptions"></select-prop>
                           </div>
                           <!-- NOTE removido temporariamente até a correção do tamanho do A5 
                           <select-prop placeholder="Formato do Papel" [form]="form.selectFormatOfPaper" [options]="SelectFormatOfPaper"></select-prop>
                           -->
                           <div>
                              <checkbox-prop [gap]="1" label="Centralizar os dados" [form]="form.isCentralized"></checkbox-prop>
                           </div>
                        </div>
                     </div>
                     <!--class="flex align-center gap-3"-->
                  </fieldset>
               </div>
            </mat-tab>
            <mat-tab>
               <ng-template mat-tab-label>
                  <div class="flex align-center gap-1">
                     <h2>Segundo Cabeçalho</h2>
                  </div>
               </ng-template>
               <div style="overflow: auto;">
                  <fieldset class="box mt-32" style="padding: 15px;">
                     <legend>Use este bloco para inserir as informações no protocolo</legend>
                     <div class="flex flex-between w-100">
                        <div class="flex flex-column gap-form mt-16 w-100">
                           <input-prop class="w-100" placeholder="Descrição do Campo" [form]="form.inputForm"></input-prop>
                           <div class="flex gap-form">
                              <select-prop class="w-100" placeholder="Tipo da Descrição" [form]="form.selectTextTypeForm" [options]="SelectTypeOptions"></select-prop>
                              <select-prop class="w-100" placeholder="Tipo de Dado" [form]="form.selectVariableForm" [options]="SelectOptions"></select-prop>
                           </div>
                           <!-- NOTE removido temporariamente até a correção do tamanho do A5 
                           <select-prop placeholder="Formato do Papel" [form]="form.selectFormatOfPaper" [options]="SelectFormatOfPaper"></select-prop>
                           -->
                           <div>
                              <checkbox-prop [gap]="1" label="Centralizar os dados" [form]="form.isCentralized"></checkbox-prop>
                           </div>
                        </div>
                     </div>
                     <!--class="flex align-center gap-3"-->
                  </fieldset>
                  <fieldset class="box mt-32">
                     <legend>Use este bloco para manipular as colunas do Segundo Cabeçalho</legend>
                     <div class="flex flex-column" style="padding-top: 10px;">
                        <div class="w-100 flex flex-column flex-between flex-direction-column-mobile align-center gp-12">
                           <div class="flex flex-direction-column-mobile gp-12">
                              <input-prop placeholder="Quantidade de colunas" [form]="form.numberOfColumnsForm"></input-prop>
                              <input-prop placeholder="Largura das colunas" [form]="form.widthOfColumnsForm"></input-prop>
                              <input-prop placeholder="Espaçamento entre colunas" [form]="form.gapOfColumnsForm"></input-prop>
                              <input-prop placeholder="Margem interna das Colunas" [form]="form.paddingOfColumnsForm"></input-prop>
                           </div>

                           <button (click)="grid(form.numberOfColumnsForm, form.widthOfColumnsForm, form.gapOfColumnsForm, form.paddingOfColumnsForm)">Definir Colunas</button>
                        </div>
                     </div>
                  </fieldset>
               </div>
            </mat-tab>
            <mat-tab>
               <ng-template mat-tab-label>
                  <div class="flex align-center gap-1">
                     <h2>Corpo</h2>
                  </div>
               </ng-template>

               <div class="w-100">
                  <fieldset class="box mt-32">
                     <legend>Use este bloco para manipular as colunas do Corpo do Protocolo</legend>
                     <div class="flex flex-direction-column-mobile">
                        <div  style="box-shadow: #32325d80 0px 4px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
                                 border-radius: 20px;
                                 padding: 10px 15px;
                                 font-family: 'Inter';
                                 font-weight: 600;
                                 align-content: center;"
                              *ngFor="let header of allHeaders; let i=index;"
                              [draggable]="header.isSelected"
                              (dragstart)="onDragstart(i)"
                              (dragover)="onDragover(i)"
                              (dragend)="onDragend()"
                              [ngClass]="{ 'dragged': dragTrace.src === i, 'dragged-over': dragTrace.dest === i }"
                              class="header-selection">
                           <span>
                              {{ header.label }}
                           </span>
                           <input type="checkbox" (click)="toggleHeader(i)" [checked]="header.isSelected"/>
                        </div>
                     </div>
                  </fieldset>
                  <fieldset class="box mt-32">
                     <legend>Use este bloco para manipular os textos do Cabeçalho / Corpo da tabela disposta no Corpo do Protocolo</legend>
                     <div class="w-100 flex flex-betweeen" style="padding: 10px; gap: 20px;">
                        <div class="flex flex-direction-column-mobile" style="gap: 10px;">
                           <input-prop placeholder="Fonte" [form]="form.bodyThFontSize"></input-prop>
                           <input-prop placeholder="Densidade" [form]="form.bodyThFontWeight"></input-prop>
                           <input id="color_head" placeholder="Cor" type="color" style="height: calc(48px * var(--proportional));">
                           <input-prop placeholder="Espaçamento Interno" [form]="form.bodyThPadding"></input-prop>
                        </div>
                        <div class="flex flex-direction-column-mobile" style="gap: 10px;">
                           <input-prop placeholder="Fonte" [form]="form.bodyTdFontSize"></input-prop>
                           <input-prop placeholder="Densidade" [form]="form.bodyTdFontWeight"></input-prop>
                           <input-prop type="color" placeholder="Cor" [form]="form.bodyTdColor"></input-prop>
                           <input-prop placeholder="Espaçamento Interno" [form]="form.bodyTdPadding"></input-prop>
                        </div>
                     </div>
                  </fieldset>
               </div>

            </mat-tab>
            <mat-tab>
               <ng-template mat-tab-label>
                  <div class="flex align-center gap-1">
                     <h2>Rodapé</h2>
                  </div>
               </ng-template>
               <fieldset class="box mt-32" style="padding: 15px;">
                  <legend>Use este bloco para inserir as informações no Rodapé do protocolo</legend>
                  <div class="flex flex-between w-100">
                     <div class="flex flex-column gap-form mt-16 w-100">
                        <input-prop class="w-100" placeholder="Descrição do Campo" [form]="form.inputForm"></input-prop>
                        <div class="flex gap-form">
                           <select-prop class="w-100" placeholder="Tipo da Descrição" [form]="form.selectTextTypeForm" [options]="SelectTypeOptions"></select-prop>
                           <select-prop class="w-100" placeholder="Tipo de Dado" [form]="form.selectVariableForm" [options]="SelectOptions"></select-prop>
                        </div>
                        <!-- NOTE removido temporariamente até a correção do tamanho do A5 
                        <select-prop placeholder="Formato do Papel" [form]="form.selectFormatOfPaper" [options]="SelectFormatOfPaper"></select-prop>
                        -->
                        <div>
                           <checkbox-prop [gap]="1" label="Centralizar os dados" [form]="form.isCentralized"></checkbox-prop>
                        </div>
                     </div>
                  </div>
               </fieldset>
            </mat-tab>

         </mat-tab-group>

         
         <div class="flex flex-center gap-form align-center mt-32">
            <div class="flex flex-column gap-form align-center w-100">
               <div class="flex gp-12 w-100">
                  <button [disabled]="selectedIndex == 0" class="btn w-100" (click)="createNewElement()">Inserir</button>
                  <button class="btn w-100" (click)="breakLine()">Nova Linha</button>
                  <button class="btn w-100" (click)="removeElement()">Remover</button>
                  <button class="btn w-100" (click)="clearHtmlStructure()">Limpar</button>
               </div>
               <!-- 
               <div class="flex gp-12 w-100">
               
                  <button class="btn w-100" (click)="downloadProtocolPreview()">Baixar Template</button>
               </div> -->
            </div>
         </div>
      </div>
      <!-- #endregion -->

   </div>
</div>