import { HttpClient } from "@angular/common/http";
import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { Guid } from "../../../util/guid";
import { AdminIHttpService } from "./interfaces/AdminIHttpService";

@Injectable({ providedIn: "root" })
export abstract class AdminHttpAuthService implements AdminIHttpService {
   public baseURL: string;
   protected headers = {
      "Content-Type": "",
      Authorization: "",
      tenantId: ""
   };

   constructor(protected http: HttpClient) {
      this.baseURL = environment.baseAuthURL;
      this.setHeaders();
   }

   // TODO: Ajustar Normalize

   get(queryString: string, path?: string, tenantId?: string): Promise<Object> {
      this.setHeaders(tenantId);
      let parans = Location.normalizeQueryParams(queryString);
      let normalize;
      normalize = `${path}${parans}`;
      const url = `${this.baseURL}${normalize}`;

      const options = { headers: this.headers };
      //@ts-ignore
      return this.http.get(url, options).toPromise();
   }

   post(body: Object, path?: string, tenantId?: string): Promise<Object> {
      this.setHeaders(tenantId);
      const url = `${this.baseURL}${path}`;

      const options = { headers: this.headers };
      //@ts-ignore
      return this.http.post(url, body, options).toPromise();
   }

   put(
      body: Object,
      id: Guid,
      path?: string,
      tenantId?: string
   ): Promise<Object> {
      this.setHeaders(tenantId);
      const url = `${this.baseURL}${path}/${id}`;

      const options = { headers: this.headers };
      //@ts-ignore
      return this.http.put(url, body, options).toPromise();
   }

   delete(id: Guid, path?: string, tenantId?: string): Promise<Object> {
      this.setHeaders(tenantId);
      let normalize;
      if (id != null) {
         normalize = `${path}/${id}`;
      } else {
         normalize = `${path}`;
      }
      const url = `${this.baseURL}${normalize}`;

      const options = { headers: this.headers };
      //@ts-ignore
      return this.http.delete(url, options).toPromise();
   }

   deleteWithBody(path: string, body: Object): Promise<Object> {
      this.setHeaders();
      const url = `${this.baseURL}${path}`;
      const options = { headers: this.headers, body };
      return this.http.delete(url, options).toPromise() as Promise<Object>;
   }

   patch(body: Object, id: Guid, path?: string): Promise<Object> {
      this.setHeaders();
      const url = `${this.baseURL}${path}/${id}`;
      const options = { headers: this.headers };

      return this.http.patch(url, body, options).toPromise() as Promise<{}>;
   }

   patchWithPath(body: Object, path: string): Promise<Object> {
      this.setHeaders();
      const url = `${this.baseURL}${path}`;
      const options = { headers: this.headers };

      return this.http.patch(url, body, options).toPromise() as Promise<{}>;
   }

   private setHeaders(tenantId?: string) {
      this.headers["Content-Type"] = "application/json";

      if (localStorage.getItem("token")) {
         // @ts-ignore
         this.headers["Authorization"] = localStorage.getItem("token");
      }
      if (tenantId) this.headers["tenantId"] = tenantId;
   }
}
