import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http.service';
import { BaseService } from '@app/services/base.service';
import { HttpResponsePageable } from '@app/services/services.types';
import { GetByIdAgreementExamsRequest } from './agreement-exams-request.types';

@Injectable({
  providedIn: 'root'
})

export class AgreementExamsRequestService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/agreementexamsrequest');
  }

  override getByPath(queryString: string, path: string, headers?: String[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetByIdAgreementExamsRequest>>
  }
}