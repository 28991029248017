<div class="contentPage">
    <div class="flex align-center w-100 flex-direction-column-mobile">
      <div class="flex align-center w-100 gp-12 flex-direction-column-mobile">
        <h1>{{'groups.title' | translate}}</h1>
        <app-button (click)="openDialog()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
      </div>
      <app-filter class="filterResponsive" (beforeClose)="get()" [cleanButton]="true" (clearFilters)="close()">
        <div class="query">
          <input-prop #autoFocus (keyup.enter)="get()" class="w-100" [placeholder]="'groups.description'" [form]="formFilter.name"></input-prop>
        </div>
        <app-button (click)="get()" class="queryBtn" [placeholder]="'groups.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
      </app-filter>
    </div>
    <div class="mt-32">
      <app-table [Dropdown]="true" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="numberRegistry" [Itens]="groups" [Actions]="['Editar', 'Excluir']"  (parentFun)="actions($event)" (changePage)="get($event)">
        <ng-template let-rowDetail #header>
            <mat-tab-group>
              <mat-tab label="Permissões">
                <div class="contentPage">
                  <div class="w-100 flex flex-between align-center">
                    <div class="flex align-center">
                      <h2>{{'groups.titlePermissions' | translate}}</h2>
                    </div>
                    <app-button (click)="openDialogModules(rowDetail.id)" [placeholder]="'groups.btnPermissions'" [class]="'flex gap-2 align-center'"></app-button>
                  </div>                   
                    <app-table  [Itens]="modulesRecursosTableOptions[rowDetail.id]" [Actions]="['Excluir']"  (parentFun)="actionsRecursos($event)" (changePage)="getResourceById($event)"></app-table>
                </div>
              </mat-tab>
              <mat-tab class="contentPage" label="Usuários">
                <div class="contentPage">
                  <div class="w-100 flex flex-between align-center">
                    <div class="flex align-center">
                      <h2>{{'groups.titleUser' | translate}}</h2>
                    </div>
                    <app-button (click)="openDialogUser(rowDetail.id)" [placeholder]="'groups.btnUser'" [class]="'flex gap-2 align-center'"></app-button>
                  </div> 
                  <app-table [Itens]="userOptionsId[rowDetail.id]" [Actions]="['Excluir']"  (parentFun)="actionsUser($event)" (changePage)="getById($event)"></app-table>
                </div>
              </mat-tab>
                </mat-tab-group>
            </ng-template>
        </app-table>   
    </div>
</div>
  

  <ng-template #dialogModal>
    <ng-container>
      <div>
        <div class="flex flex-between w-100 align-center">
          <h1>{{editId ? ('groups.titleEdit' | translate) : ('groups.titleNew' | translate)}}</h1>
          <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
          </svg>
        </div>
        <div class="mt-32 flex align-center">
          <input-prop (keyup.enter)="editId ? put() : post()" class="w-100" [placeholder]="'groups.name'" [form]="form.name"></input-prop>
        </div>
        <div class="mt-32 flex flex-center">
          <app-button (click)="editId ? put() : post()" [placeholder]="'global.save'" [class]="'btn'"></app-button>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #dialogModalUser let-user>
    <ng-container>
      <div>
        <div class="flex flex-between w-100 align-center">
          <h1>{{'groups.btnPermissions' | translate}}</h1>
          <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
          </svg>
        </div>
        <div class="mt-32 flex align-center">
          <select-prop class="w-100" [placeholder]="'groups.companyId'" [form]="formUser.company" [options]="companyOptions" (changeValue)="getUnity()"></select-prop>
        </div>
        <div class="mt-32 flex align-center">
          <select-prop class="w-100" [placeholder]="'groups.unityId'" [form]="formUser.unity" [options]="unityOptions"></select-prop>
         </div>
        <div class="mt-32 flex align-center">
            <input-user-multiple-new class="w-100" [form]="formUser.name" [options]="userOptions" ></input-user-multiple-new>
        </div>
        <div class="mt-32 flex flex-center">
          <app-button (click)="postUser(user)" [placeholder]="'global.save'" [class]="'btn'"></app-button>
        </div>
      </div>
    </ng-container>
  </ng-template>


  <ng-template #dialogModalModules let-modules>
    <ng-container>
      <div>
        <div class="flex flex-between w-100 align-center">
          <h1>{{'groups.btnPermissions' | translate}}</h1>
          <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
          </svg>
        </div>
        <div class="mt-32 flex align-center">
            <select-prop class="w-100" [placeholder]="'groups.modules'" [form]="formModules.modules" [options]="modulesOptions" (changeValue)="getResource()"></select-prop>
        </div>
        <div class="mt-32 flex align-center">
            <input-user-multiple-new class="w-100" [placeholder]="'groups.papers'" [form]="formModules.papers" [options]="modulesRecursosOptions" ></input-user-multiple-new>
        </div>
        <div class="mt-32 flex flex-center">
          <app-button (click)="postModules(modules)" [placeholder]="'global.save'" [class]="'btn'"></app-button>
        </div>
      </div>
    </ng-container>
  </ng-template>