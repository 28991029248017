import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalAccessComponent } from './external-access.component';
import { CommonComponentModule } from '@app/components/common-component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ExternalAccessRoutingModule } from './external-access-rounting.module';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { ExternalCustomerComponent } from './customer/customer.component';
import { AgreementComponent } from './agreement/agreement.component';
import { SendMessageComponent } from './agreement/send-message/send-message.component';
import { CreateExternalRequisitionComponent } from './agreement/create-requisition/create-requisition.component';
import { ManualExamsComponent } from './agreement/manual-exams/manual-exams.component';
import { B2bIntegrationComponent } from './agreement/b2b-integration/b2b-integration.component';
import { ViewExternalRequisitionComponent } from './agreement/view-requisition/view-requisition.component';
@NgModule({
  imports: [
    CommonModule,
    CommonComponentModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatTabsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatIconModule,
    MatTooltipModule
  ],
  declarations: [
    ExternalAccessComponent,
    ExternalCustomerComponent,
    AgreementComponent,
    SendMessageComponent,
    CreateExternalRequisitionComponent,
    ManualExamsComponent,
    B2bIntegrationComponent,
    ViewExternalRequisitionComponent
  ],
  exports: [
    ExternalAccessRoutingModule,
  ],
  providers: [
    LocalaDatePipe
  ]
})
export class ExternalAccessModule { }