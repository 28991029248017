import { FormsModule, ReactiveFormsModule, FormControl } from "@angular/forms";
import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   ViewChild
} from "@angular/core";
import { NgFor, NgIf } from "@angular/common";
import { InputTextFormComponent } from "../../input-text/input-text.component";
import { OptionSelect, OptionToInputSelect } from "../input-select.types";
import { TranslateModule } from "@ngx-translate/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelect, MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { DataComponent } from "@app/modules_new/common/components/data";
import { SearchFilterPipe } from "@app/modules_new/shared/pipe/search-filter.pipe";

@Component({
   selector: "forms-input-select-multiples",
   templateUrl: "./multiples.component.html",
   styleUrls: ["./multiples.component.scss"],
   standalone: true,
   imports: [
      FormsModule,
      ReactiveFormsModule,
      InputTextFormComponent,
      NgFor,
      NgIf,
      MatFormFieldModule,
      MatSelectModule,
      MatOptionModule,
      SearchFilterPipe,
      TranslateModule
   ]
})
export class InputSelectMultplesFormComponent
   extends DataComponent
   implements OnInit
{
   @Input() form!: FormControl<OptionSelect[]>;

   @Input() type = "text";
   @Input() placeholder = "default.select";
   @Input() showPlaceholder: boolean = false;
   @Input() className = "";
   @Input() requiredInput = "";
   @Input() tabindexcomponent = "";
   @Input() options: Array<OptionToInputSelect> = [];
   @Input() optionAll = false;
   @Input() forcingDisable = false;
   /**
    * Classes de estilo tailwind
    */
   @Input() optionStyle = "";

   @Output() changeForm = new EventEmitter<any>();
   @ViewChild("focusableInputs") focusInput!: any;
   @ViewChild("selectRef") selectRef!: MatSelect;

   formValue = new FormControl<string[]>([]);
   formSearch = new FormControl("");

   classes = "";

   ngOnInit(): void {
      this.classes = this.className + " input-row";

      if (this.form) {
         if (this.form.value !== null) {
            this.formValue.setValue(this.form?.value.map((item) => item.value));
         }

         this.addSubscriptions([
            this.formValue.valueChanges.subscribe((response) => {
               if (response) {
                  const newValues = this.options.filter((item) =>
                     response.includes(item.value)
                  );
                  this.form.setValue(newValues);
                  this.changeForm.emit({ change: true });
               }
            }),
            this.form.valueChanges.subscribe((valuesArray) => {
               if (valuesArray) {
                  this.formValue.setValue(
                     valuesArray.map((v) => v.value),
                     { emitEvent: false }
                  );
               } else {
                  this.formValue = new FormControl<string[]>([]);
               }
               this.changeForm.emit({ change: true });
            })
         ]);
      }
   }

   get defaulForm() {
      return this.form !== null ? this.form : this.formValue;
   }

   get hasOptionStyle() {
      return this.optionStyle !== "";
   }

   onOpen(isOpen: boolean) {
      this.focusInput.inputElement?.nativeElement.focus();
      this.focusInput.inputElement?.nativeElement.click();
      if (!isOpen) this.formSearch.reset();
   }
}
