import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnInit,
   Output,
   SimpleChanges,
   inject
} from "@angular/core";
import { ColumnDefinition, ColumnEvent } from "../table.model";
import {
   JsonPipe,
   NgClass,
   NgIf,
   NgSwitch,
   NgSwitchCase,
   NgSwitchDefault
} from "@angular/common";
import { FormControl } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { DynamicPipe } from "@app/modules_new/shared/pipe/dynamic-pipe.pipe";
import { LocaleDatePipe } from "@app/modules_new/shared/pipe/localeDate.pipe";
import { HtmlSanitizerPipe } from "@app/modules_new/shared/pipe/sanitizer-html.pipe";
import { SpanPipe } from "@app/modules_new/shared/pipe/span.pipe";
import { SvgSelectorUIComponent } from "../../../ui/svg-selector/svg-selector.component";
import { ToggleFormComponent } from "../../toggle/toggle.component";
import { ButtonFormComponent } from "../../button/button.component";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { CheckboxFormComponent } from "../../checkbox/checkbox.component";

/**
 * Componente responsável por controlar exibição de colunas
 */
@Component({
   selector: "table-column",
   templateUrl: "./column.component.html",
   styleUrls: ["./column.component.scss"],
   standalone: true,
   providers: [LocaleDatePipe, SpanPipe, HtmlSanitizerPipe, TranslateModule],
   imports: [
      NgIf,
      NgClass,
      NgSwitch,
      NgSwitchCase,
      NgSwitchDefault,
      DynamicPipe,
      ButtonFormComponent,
      SvgSelectorUIComponent,
      ToggleFormComponent,
      MatTooltipModule,
      HtmlSanitizerPipe,
      CheckboxFormComponent
   ]
})
export class TableColumnComponent implements OnInit, OnChanges {
   private _router = inject(Router);

   /**
    * Form que será usado para controlar ações em casos de `component`
    */
   formAction!: FormControl;

   /**
    * Informações para controlar renderização de coluna
    */
   @Input() column!: ColumnDefinition;
   /**
    * Linha recebida (objeto)
    */
   @Input() row!: any;

   @Output() userInteraction = new EventEmitter<ColumnEvent>();

   ngOnInit(): void {
      if (this.column.component) {
         this.formAction = new FormControl(this.row[this.column.key!]);
      }
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (this.column.component && this.formAction) {
         if (
            changes["row"].currentValue[this.column.key!] !==
            changes["row"].previousValue[this.column.key!]
         ) {
            this.formAction.setValue(
               changes["row"].currentValue[this.column.key!]
            );
         }
      }
   }

   checkbox(event: boolean, column: string) {
      let parentEvent = "IS_ACTIVE_CHANGE";
      if (this.column.componentExtra?.event) {
         parentEvent = this.column.componentExtra.event;
      }
      this.userInteraction.emit({
         parentEvent: parentEvent,
         payload: {
            event,
            column,
            data: this.row
         }
      });
   }

   buttonClicked() {
      this.userInteraction.emit({
         parentEvent: "BUTTON_PRESSED",
         payload: {
            event: this.column.componentExtra?.event,
            data: this.row
         }
      });
   }

   svgClicked() {
      this.userInteraction.emit({
         parentEvent: "SVG_PRESSED",
         payload: {
            event: this.column.componentExtra?.event,
            data: this.row
         }
      });
   }

   spanClicked() {
      this.userInteraction.emit({
         parentEvent: "SPAN_PRESSED",
         payload: {
            event: this.column.componentExtra?.event,
            data: this.row
         }
      });
   }

   redirect() {
      this._router.navigateByUrl(this.column.redirectTo!(this.row));
   }

   isSvgKeyObject(row: any): boolean {
      return (
         //@ts-ignore
         typeof this.column.componentExtra.svgkey === "object" && //@ts-ignore
         this.column.componentExtra.svgkey !== null
      );
   }
}
