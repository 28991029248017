import {
   Component,
   ElementRef,
   OnInit,
   QueryList,
   TemplateRef,
   ViewChild,
   ViewChildren
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ActivatedRoute, Router } from "@angular/router";
import { SimpleReportComponent } from "@app/components/form/simple-report/simple-report/simple-report.component";
import { GetActiveFilterPipe } from "@app/pipe/active.pipe";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { AnalyteService } from "@app/services/analyte/analyte.service";
import { AnalyteConditionalService } from "@app/services/analyte-conditional/analyte-conditional.service";
import { AnalyteConditionalParameterService } from "@app/services/analyte-conditional-parameter/analyte-conditional-parameter.service";
import { AnalyteMaterialService } from "@app/services/analyte-material/analyte-material.service";
import { AnalyteMaterialRecipientService } from "@app/services/analyte-material-recipient/analyte-material-recipient.service";
import { AnalyteParametersService } from "@app/services/analyte-parameters/analyte-parameters.service";
import { AnalyteQuestionService } from "@app/services/analyte-question/analyte-question.service";
import { AtributeAnalyteService } from "@app/services/atribute-analyte/atribute-analyte.service";
import { AtributeReportService } from "@app/services/atribute-report/atribute-report.service";
import { AnalyteInstructionService } from "@app/services/analyte-instruction/analyte-instruction.service";
import { MaterialService } from "@app/services/materials/material.service";
import { MethodologyService } from "@app/services/methodology/methodology.service";
import { QuestionsService } from "@app/services/questions/questions.service";
import { RecipientsService } from "@app/services/recipients/recipients.service";
import { ReferenceAtributeAnalyteService } from "@app/services/reference-atribute-analyte/reference-atribute-analyte.service";
import { ReportService } from "@app/services/report/report.service";
import { UnitMeasureService } from "@app/services/stock/unit-measure.service";
import { Guid } from "@app/util/guid";
import { SwAlSetttings } from "@app/util/swal.settings";
import {
   ActionsAtributeReport,
   ActionsAttributes,
   ActionsConditional,
   ActionsMaterial,
   ActionsOptionsList,
   ActionsQuestion,
   ActionsRecipient,
   ActionsReferenceAtribute,
   ActionsReport,
   Get,
   ConditionalParameter,
   resolveSupportLaboratories,
   resolveQuestions,
   resolveOptionsList,
   resolveParameters,
   resolveConditional,
   resolveRecipients,
   resolveAtributeReport,
   resolveAtributeReference,
   resolveAtribute,
   resolveConditionalParameter,
   resolveMaterials,
   resolveReport,
   EType
} from "./save-analyte.model";
import { CheckboxSvgPipe } from "@app/pipe/checkbox-Svg.pipe";
import { SupportLaboratoryService } from "@app/services/support-laboratory/support-laboratory.service";
import { Util } from "@app/util/util";
import { AttributeOptionsListService } from "@app/services/attribute-options-list/atribute-options-list.service";
import { AtributeAnalytePostObject } from "@app/services/atribute-analyte/atribute-analyte.service.module";
import { InputPropComponent } from "@app/components/form/input-prop/input-prop.component";
import { InputSearchComponent } from "@app/components/form/input-search/input-search.component";
import { ButtonComponent } from "@app/components/form/button/button.component";
import { ToastService } from "@app/services/translate/toast.service";
import { QueryParams } from "@app/services/interfaces/services.types";
import { SectorService } from "@app/services/sector/sector.service";
import { FormGroup, UntypedFormControl, Validators } from "@angular/forms";

@Component({
   selector: "app-save-analyte",
   templateUrl: "./save-analyte.component.html",
   styleUrls: ["./save-analyte.component.scss"]
})
export class SaveAnalyteComponent implements OnInit {
   eType = EType;
   type: EType = EType.VISUALIZAR;

   titleName!: string;

   analyteMaterialId: any;
   recipienteId: any;

   parameterId: string = "";

   @ViewChildren(InputPropComponent) inputsProp!: QueryList<ElementRef>;
   @ViewChildren(InputSearchComponent) inputsSearch!: QueryList<ElementRef>;

   @ViewChild("dialogModalAdditionalParameter")
   dialogModalAdditionalParameter!: TemplateRef<any>;

   hideParameters: Array<any> = [];
   atributeList: Array<any> = [];
   atributeAnalytes: Array<any> = [];
   allSupportLaboratories: Array<Object> = [];
   analyteInstructions: Array<{
      id: string;
      description: string;
      typeAnalyteInstructions: number;
      typeAnalyteInstructionsDescription: string;
      createdAt: string;
      updatedAt: string;
      isActive: boolean;
   }> = [];

   totalSupportLaboratories: number = 0;
   pageSupportLaboratories: number = 1;
   indexSupportLaboratories: number = 50;
   getParametersSupportLaboratories: string = `?page=${this.pageSupportLaboratories}&index=${this.indexSupportLaboratories}`;

   SupportLaboratoriesFilter = new UntypedFormControl();

   total: number = 0;
   page: number = 1;
   index: number = 50;

   form = {
      numberdays: new UntypedFormControl(null),
      code: new UntypedFormControl(null),
      defaultSupportLaboratory: new UntypedFormControl(null),
      name: new UntypedFormControl(null),
      outsourced: new UntypedFormControl(false),
      outsourcedvalue: new UntypedFormControl(null),
      sectorId: new UntypedFormControl(null),
      urgentdays: new UntypedFormControl(null),
      methodology: new UntypedFormControl(null),
      question: new UntypedFormControl(null),
      questionCode: new UntypedFormControl(null),
      materialName: new UntypedFormControl(null),
      materialId: new UntypedFormControl(null),
      materialCode: new UntypedFormControl(null),
      recipientCode: new UntypedFormControl(null),
      reportName: new UntypedFormControl(null, [Validators.required]),
      reportMaterial: new UntypedFormControl(null),
      reportMaterialName: new UntypedFormControl(null),
      reportMethodology: new UntypedFormControl(null),
      reportActive: new UntypedFormControl(null),
      active: new UntypedFormControl(true),
      deadline: new UntypedFormControl(null),
      urgentDeadline: new UntypedFormControl(null),
      emergencyDeadline: new UntypedFormControl(null),
      newAtribute: new UntypedFormControl(null),
      typeResultWeb: new UntypedFormControl(null),
      nameInvoice: new UntypedFormControl(null),
      restrictGender: new UntypedFormControl(null),
      sequenceReport: new UntypedFormControl(null),
      orderReport: new UntypedFormControl(null),
      isDefaultMaterial: new UntypedFormControl(false),
      newRecipient: new UntypedFormControl(null),
      information: new UntypedFormControl(null),
      isRequired: new UntypedFormControl(false)
   };

   formAdditionalParameter = new FormGroup({
      newAtribute: new UntypedFormControl(),
      column: new UntypedFormControl(),
      lineAfter: new UntypedFormControl()
   });

   formManual = {
      description: new UntypedFormControl(null),
      preparation: new UntypedFormControl(null),
      collect: new UntypedFormControl(null),
      distribution: new UntypedFormControl(null),
      estabilish: new UntypedFormControl(null),
      reject: new UntypedFormControl(null),
      additional: new UntypedFormControl(null),
      interpretation: new UntypedFormControl(null),
      pathologies: new UntypedFormControl(null),
      links: new UntypedFormControl(null)
   };

   formAttribute = {
      name: new UntypedFormControl(null),
      analyteId: new UntypedFormControl(null),
      code: new UntypedFormControl(null),
      decimalPlaces: new UntypedFormControl(null),
      isRequired: new UntypedFormControl(false),
      isResult: new UntypedFormControl(false),
      isAuxiliar: new UntypedFormControl(false),
      lineAfter: new UntypedFormControl(null),
      typeAttribute: new UntypedFormControl(null),
      isHidden: new UntypedFormControl(false),
      isHiddenWhenNull: new UntypedFormControl(false),
      isUpperCase: new UntypedFormControl(false),
      valueDefault: new UntypedFormControl(null),
      unitMeasureId: new UntypedFormControl(null),
      questionId: new UntypedFormControl(null),
      applyOldResult: new UntypedFormControl(false),
      image: new UntypedFormControl(null),
      imageName: new UntypedFormControl(null),
      optionsList: new UntypedFormControl(null),
      attributeMinAbsurdValue: new UntypedFormControl(null),
      attributeMaxAbsurdValue: new UntypedFormControl(null),
      attributeMinControlValue: new UntypedFormControl(null),
      attributeMaxControlValue: new UntypedFormControl(null),
      attributeMinCriticalValue: new UntypedFormControl(null),
      attributeMaxCriticalValue: new UntypedFormControl(null),
      attributeUpValue: new UntypedFormControl(null),
      attributeDownValue: new UntypedFormControl(null)
   };

   formReference = {
      isActive: new UntypedFormControl(false),
      atributeAnalyteId: new UntypedFormControl(null),
      ageMin: new UntypedFormControl(0),
      typeAgeMin: new UntypedFormControl(0),
      ageMax: new UntypedFormControl(150),
      typeAgeMax: new UntypedFormControl(0),
      genderType: new UntypedFormControl(0),
      valueMin: new UntypedFormControl(0),
      valueMax: new UntypedFormControl(99999),
      name: new UntypedFormControl(null)
   };

   formParameters = {
      parameter: new UntypedFormControl(null)
   };

   formConditional = {
      atributeAnalyteId: new UntypedFormControl(null),
      typeAnalisys: new UntypedFormControl(null),
      typeBlockedText: new UntypedFormControl(null),
      textAnalisys: new UntypedFormControl(null),
      typeBlockedNumericFrom: new UntypedFormControl(null),
      startNumber: new UntypedFormControl(null),
      typeBlockedNumericTo: new UntypedFormControl(null),
      finishNumber: new UntypedFormControl(null),
      isActive: new UntypedFormControl(false)
   };

   formConditionalParameters = {
      parameter: new UntypedFormControl(null)
   };

   formFormula = {
      if: new UntypedFormControl(""),
      typeComparation: new UntypedFormControl(null),
      conditional: new UntypedFormControl(null),
      value: new UntypedFormControl(""),
      and: new UntypedFormControl(""),
      typeResult: new UntypedFormControl(null),
      else: new UntypedFormControl(""),
      formula: new UntypedFormControl(null)
   };

   ActionsExtra = {
      Ativo: "PutActiveParameter"
   };

   ActionsExtraReferenceValue = {
      Ativo: "PutActiveReferenceValue"
   };

   ActionsExtraAtribute = {
      Ativo: "PutActiveAtribute"
   };

   ActionsExtraReport = {
      Ativo: "PutActiveReport"
   };

   ActionsExtraAtributeReport = {
      Ativo: "PutActiveAtributeReport"
   };

   ActionsExtraConditional = {
      Ativo: "PutActiveActionsExtraAnalyteConditional"
   };

   ActionsExtraParameterConditional = {
      Ativo: "PutActiveActionsExtraAnalyteParameterConditional"
   };

   ActionsExtraQuestions = {
      Ativo: "PutActiveActionsQuestions"
   };

   ActionsMatRecipient = {
      Ativo: "PutActiveActionsMatRecipient"
   };

   ActionsExtraDataType = {
      Ativo: "PutActiveDataType"
   };

   columnOoption = [
      {
         value: 1,
         label: "1"
      },
      {
         value: 2,
         label: "2"
      },
      {
         value: 3,
         label: "3"
      }
   ];

   lineAfterOption = [
      {
         value: 0,
         label: "0"
      },
      {
         value: 1,
         label: "1"
      },
      {
         value: 2,
         label: "2"
      },
      {
         value: 3,
         label: "3"
      },
      {
         value: 4,
         label: "4"
      },
      {
         value: 5,
         label: "5"
      },
      {
         value: 6,
         label: "6"
      },
      {
         value: 7,
         label: "7"
      },
      {
         value: 8,
         label: "8"
      },
      {
         value: 9,
         label: "9"
      },
      {
         value: 10,
         label: "10"
      }
   ];

   constructor(
      private _route: ActivatedRoute,
      private _analyteService: AnalyteService,
      private _dialog: MatDialog,
      private _questionsService: QuestionsService,
      private _materialService: MaterialService,
      private _recipientsService: RecipientsService,
      private _methodologyService: MethodologyService,
      private _sectorService: SectorService,
      private _router: Router,
      private _manualExamsService: AnalyteInstructionService,
      private _analyteQuestionService: AnalyteQuestionService,
      private _analyteMaterial: AnalyteMaterialService,
      private _analyteMaterialRecipient: AnalyteMaterialRecipientService,
      private _reportService: ReportService,
      private _atributeAnalyteService: AtributeAnalyteService,
      private _atributeReportService: AtributeReportService,
      private _referenceAtributeAnalyteService: ReferenceAtributeAnalyteService,
      private _analyteParametersService: AnalyteParametersService,
      private _analyteConditionalService: AnalyteConditionalService,
      private _analyteConditionalParameters: AnalyteConditionalParameterService,
      private _localeDatePipe: LocalaDatePipe,
      private _unitMeasureService: UnitMeasureService,
      private _attributeOptionsList: AttributeOptionsListService,
      private _activePipe: GetActiveFilterPipe,
      private _checkboxSvgPipe: CheckboxSvgPipe,
      private _supportLaboratoryService: SupportLaboratoryService,
      private _util: Util,
      private _toastService: ToastService
   ) {}

   referenceAtributeAnalyteId: string = "";
   supportLaboratoryOptions: Array<Object> = [];
   optionsList: Array<Object> = [];
   allOptionsList: Array<Object> = [];
   parameters: Array<Object> = [];
   attributes: Array<Object> = [];
   AllAttributes: Array<{
      label: string;
      value: string;
      isNumeric: boolean;
      typeAttribute: number;
   }> = [];
   hasAnalyte: boolean = false;
   allSectors: Array<Object> = [];
   AllMethodologys: Array<{
      label: string;
      value: string;
   }> = [];
   AllQuestions: Array<{
      label: string;
      value: string;
      externalId: string;
   }> = [];
   questions: Array<Object> = [];
   analyte: Object = {};
   AllMaterialsExam: Array<Object> = [];
   AllMaterialsReport: Array<Object> = [];
   AllMaterials: Array<{
      label: string;
      value: string;
      externalId: string;
   }> = [];
   recipientsOptions: Array<{
      label: string;
      value: string;
      externalId: string;
   }> = [];
   materials: Array<Object> = [];
   materialsParameters = {
      total: 0,
      page: 1,
      index: 50
   };
   recipients: { [key: string]: Array<Object> } = {};
   recipientsParams: {
      [key: string]: {
         total: number;
         page: number;
         index: number;
      };
   } = {};
   referenceAtributes: { [key: string]: Array<Object> } = {};
   atributesReport: Array<Object> = [];
   reports: Array<Object> = [];
   AllReports: Array<Object> = [];
   newAtributes: Array<Object> = [];
   conditions: Array<Object> = [];
   //conditionsText: Array<Object> = [];
   conditionsText: { [key: string]: Array<Object> } = {};
   unitMeasure: Array<Object> = [];
   totalCondition: number = 0;
   pageCondition: number = 1;
   indexCondition: number = 50;
   totalConditionParameters: number = 0;
   pageConditionParameters: number = 1;
   indexConditionParameters: number = 50;
   analyteId: Guid = new Guid(Guid.getEmpty());
   reportId: Guid = new Guid(Guid.getEmpty());
   attributeId: Guid = new Guid(Guid.getEmpty());
   conditionId: Guid = new Guid(Guid.getEmpty());
   attributeReferenceId: Guid = new Guid(Guid.getEmpty());
   conditionalParameterId: Guid = new Guid(Guid.getEmpty());
   materialId: Guid = new Guid(Guid.getEmpty());
   conditionalNumeric: number = 0;
   title: string = "Novo";
   titleModal: string = "";
   titleSubModal: string = "";
   editMaterial: boolean = false;
   recipientsList: Array<Object> = [];

   @ViewChild("dialogModalParameters")
   dialogModalParameters!: TemplateRef<any>;

   @ViewChild("dialogModalConditionalParameters")
   dialogModalConditionalParameters!: TemplateRef<any>;

   @ViewChild("dialogModalConditional")
   dialogModalConditional!: TemplateRef<any>;

   @ViewChild("dialogModalReport")
   dialogModalReport!: TemplateRef<any>;

   @ViewChild("dialogModalQuestion")
   dialogModalQuestion!: TemplateRef<any>;

   @ViewChild("dialogModalMaterial")
   dialogModalMaterial!: TemplateRef<any>;

   @ViewChild("dialogModalAttribute")
   dialogModalAttribute!: TemplateRef<any>;

   @ViewChild("dialogModalAttributeReference")
   dialogModalAttributeReference!: TemplateRef<any>;

   typeResult = [
      {
         value: 0,
         label: " "
      },
      {
         value: 1,
         label: "Expressão Matematica"
      },
      {
         value: 2,
         label: "Texto"
      }
   ];

   typeComparation = [
      {
         value: 0,
         label: "Texto"
      },
      {
         value: 1,
         label: "Valor"
      }
   ];

   typeConditional = [
      {
         value: " ",
         label: " "
      },
      {
         value: ">",
         label: "> Maior"
      },
      {
         value: ">=",
         label: ">= Maior ou igual"
      },
      {
         value: "<",
         label: "< Menor"
      },
      {
         value: "<=",
         label: "<= Menor ou igual"
      },
      {
         value: "<>",
         label: "<> Diferente"
      },
      {
         value: "=",
         label: "= Igual"
      }
   ];

   typeConditionalText = [
      {
         value: "<>",
         label: "<> Diferente"
      },
      {
         value: "=",
         label: "= Igual"
      }
   ];

   typeAtributeReference = [
      {
         value: 0,
         label: "Anos"
      },
      {
         value: 1,
         label: "Meses"
      },
      {
         value: 2,
         label: "Dias"
      }
   ];

   typeAtribute = [
      {
         value: 0,
         label: "Númerico"
      },
      {
         value: 1,
         label: "Texto"
      },
      {
         value: 2,
         label: "Descritivo 1"
      },
      {
         value: 3,
         label: "Descritivo 2"
      },
      {
         value: 4,
         label: "Descritivo 3"
      },
      {
         value: 5,
         label: "Imagem 576 x 265 Pixels"
      },
      {
         value: 6,
         label: "Imagem 200 x 400 Pixels"
      },
      {
         value: 7,
         label: "Imagem 760 x 110 Pixels"
      },
      {
         value: 8,
         label: "Imagem 760 x 1024 Pixels"
      },
      {
         value: 9,
         label: "Imagem 336 x 336 Pixels"
      },
      {
         value: 10,
         label: "Imagem 435 x 300 Pixels"
      },
      {
         value: 11,
         label: "Pergunta"
      },
      {
         value: 12,
         label: "Fórmula"
      },
      {
         value: 13,
         label: "Lista de Opções"
      }
   ];

   typeAnalyteParameters = [
      {
         value: 1,
         label: "Exame especial, exige senha sempre para impressão"
      },
      {
         value: 2,
         label: "Não imprime resultados anteriores no laudo"
      },
      {
         value: 3,
         label: "Permite digitar valores de resultados absurdos mediante senha"
      },
      {
         value: 4,
         label: "Permite informar quantidade superior a um"
      }
   ];

   typeAnalyteParametersFilter = [
      {
         value: 1,
         label: "Exame especial, exige senha sempre para impressão"
      },
      {
         value: 2,
         label: "Não imprime resultados anteriores no laudo"
      },
      {
         value: 3,
         label: "Permite digitar valores de resultados absurdos mediante senha"
      },
      {
         value: 4,
         label: "Permite informar quantidade superior a um"
      }
   ];

   genderType = [
      {
         value: 0,
         label: "Todos"
      },
      {
         value: 1,
         label: "Feminino"
      },
      {
         value: 2,
         label: "Masculino"
      }
   ];

   restrictGender = [
      {
         value: 0,
         label: "Sem restrições"
      },
      {
         value: 1,
         label: "Masculino"
      },
      {
         value: 2,
         label: "Feminino"
      }
   ];

   typeResultWeb = [
      {
         value: 0,
         label: "Não libera para visualização"
      },
      {
         value: 1,
         label: "Libera para visualização"
      },
      {
         value: 2,
         label: "Libera mediante senha de autorização"
      },
      {
         value: 3,
         label: "Libera mediante senha de autorização se o resultado estiver na faixa de controle"
      },
      {
         value: 4,
         label: "Libera somente para convênio se for apoiado"
      },
      {
         value: 5,
         label: "Exclusivo para web, não imprime mediante recepção"
      }
   ];

   typeAnalisis = [
      {
         value: 1,
         label: "Comparar com o resultado de outro exame (atributo)"
      },
      {
         value: 2,
         label: "Comparar com o resultado anterior"
      }
   ];

   typeBlockedText = [
      {
         value: 0,
         label: "Não se aplica"
      },
      {
         value: 1,
         label: "Diferente de"
      },
      {
         value: 2,
         label: "Igual a"
      }
   ];

   typeBlockedNumeric = [
      {
         value: 0,
         label: "Não se aplica"
      },
      {
         value: 1,
         label: "Maior ou Igual"
      },
      {
         value: 2,
         label: "Menor ou Igual"
      },
      {
         value: 3,
         label: "Igual"
      },
      {
         value: 4,
         label: "Diferente"
      }
   ];

   listAtributes: Array<Object> = [];

   urgentDeadLine(item: any) {
      if (item.urgentDeadLine > 1) {
         return item.urgentDeadLine + " dias";
      } else if (item.urgentDeadLine === 1) {
         return item.urgentDeadLine + " dia";
      } else {
         return "Não informado";
      }
   }

   normalDeadLine(item: any) {
      if (item.normalDeadLine > 1) {
         return item.normalDeadLine + " dias";
      } else if (item.normalDeadLine === 1) {
         return item.normalDeadLine + " dia";
      } else {
         return "Não informado";
      }
   }

   generatePdf() {
      const simpleReport = new SimpleReportComponent(this._localeDatePipe);
      simpleReport.atributes = this.listAtributes;

      simpleReport.generatePdf();
   }

   async ngOnInit() {
      this.getSupportLaboratories();
      this.getSectors();

      this.analyteId = new Guid(Guid.getEmpty());
      this.title = "analyte.titleNew";
      if (this._route.snapshot.params["id"] != undefined) {
         this.title = "analyte.titleEdit";
         this.hasAnalyte = true;
         this.analyteId = this._route.snapshot.params["id"];
         await this.getAnalyteById(this.analyteId);
      }
   }

   async getSectors() {
      await this._sectorService.getPagination().then((res) => {
         this.allSectors = res.data.map((sector: any) => ({
            label: sector.description,
            value: sector.id,
            externalId: sector.externalId
         }));
      });
   }

   getSupportLaboratories() {
      this._supportLaboratoryService.get("?resume=true").then((res: any) => {
         this.supportLaboratoryOptions = res.data.map((x: any) => ({
            value: x.id,
            label: x.name
         }));
      });
   }

   myTabSelectedTabChange(changeEvent: MatTabChangeEvent) {
      if (this._route.snapshot.params["id"] != undefined) {
         switch (changeEvent.index) {
            case 1:
               this.getQuestionsAnalyte();
               break;
            case 2:
               this.type = EType.VISUALIZAR;
               this.getAnalyteMaterials(this.analyteId);
               break;
            case 3:
               this.getAtributes();
               break;
            case 4:
               this.getReports();
               break;
            case 5:
               this.getAnalyteInstructions();
               break;
            case 6:
               this.getParameters();
               break;
            case 7:
               this.getConditions();
               break;
            case 8:
               this.supportLaboratories(false);
               break;
         }
      }
   }

   async getAnalyteInstructions() {
      const query: QueryParams = {
         analyteId: this.analyteId.toString()
      };
      await this._manualExamsService.getPagination(query).then((res: any) => {
         if (res.data.length === 0) {
            return this._toastService.warningMessage("manual.msgInfo") as any;
         }
         const response = res.data[0].analyteInstructions;
         this.analyteInstructions = response;
         this.formManual.preparation.setValue(
            response.find((x: any) => x.typeAnalyteInstructions === 1)
               ?.description ?? null
         );
         this.formManual.collect.setValue(
            response.find((x: any) => x.typeAnalyteInstructions === 2)
               ?.description ?? null
         );
         this.formManual.distribution.setValue(
            response.find((x: any) => x.typeAnalyteInstructions === 3)
               ?.description ?? null
         );
         this.formManual.estabilish.setValue(
            response.find((x: any) => x.typeAnalyteInstructions === 4)
               ?.description ?? null
         );
         this.formManual.reject.setValue(
            response.find((x: any) => x.typeAnalyteInstructions === 5)
               ?.description ?? null
         );
         this.formManual.additional.setValue(
            response.find((x: any) => x.typeAnalyteInstructions === 6)
               ?.description ?? null
         );
         this.formManual.interpretation.setValue(
            response.find((x: any) => x.typeAnalyteInstructions === 7)
               ?.description ?? null
         );
         this.formManual.pathologies.setValue(
            response.find((x: any) => x.typeAnalyteInstructions === 8)
               ?.description ?? null
         );
         this.formManual.links.setValue(
            response.find((x: any) => x.typeAnalyteInstructions === 9)
               ?.description ?? null
         );
         this.formManual.description.setValue(
            response.find((x: any) => x.typeAnalyteInstructions === 10)
               ?.description ?? null
         );
      });
   }

   getConditions(paramPage?: number[]) {
      this.pageCondition = 1;

      if (paramPage) {
         this.pageCondition = paramPage[0];
         this.indexCondition = paramPage[1];
      }

      this._analyteConditionalService
         .getPagination({
            page: this.pageCondition,
            index: this.indexCondition,
            analyteId: this.analyteId.toString()
         })
         .then((res: any) => {
            this.totalCondition = res.sumRecords;
            this.resolveConditionals(res.data, resolveConditional);
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async getAnalyteById(id: Guid) {
      await this._analyteService.getById(id).then(async (res) => {
         res.data.forEach((analyte) => {
            this.titleName = analyte.name;
            this.form.code.setValue(analyte.code);
            this.form.name.setValue(analyte.name);
            this.form.active.setValue(analyte.isActive);
            this.form.sectorId.setValue((analyte as any).sectortId);
            this.form.outsourced.setValue(analyte.isOutsourced);
            this.form.outsourcedvalue.setValue(analyte.valueOutsourced);
            this.form.typeResultWeb.setValue(analyte.typeResultWeb);
            this.form.nameInvoice.setValue(analyte.nameInvoice);
            this.form.restrictGender.setValue(analyte.restrictGender);
            this.form.sequenceReport.setValue(analyte.analyteSequenceReport);
            this.form.orderReport.setValue(analyte.analyteOrdemReport);
            this.form.information.setValue(analyte.information);
         });
      });
   }

   resolveMaterial(data: any, columns: any) {
      this.materials = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.materials.push(obj);
      });
   }

   getActive(item: Get) {
      return this._activePipe.transform(item.isActive);
   }

   async ActionsConditionsText(emit: any, obj: any) {
      switch (emit.action) {
         case "Editar":
            this.addConditionalParameter(obj, emit.object.id);
            break;
         case "Excluir":
            this._toastService
               .fireConfirmation("analyteCondition.msgDelete")
               .then(async (res) => {
                  if (res.isConfirmed) {
                     await this._analyteConditionalParameters.delete(
                        emit.object.id
                     );
                     this._toastService.fireSuccess(
                        "analyteCondition.msgDeleteSuccess"
                     );
                     await this.getConditionalParameters(obj.id);
                  }
               })
               .catch((error) => {
                  this._toastService.fireError(error);
               });
            break;
         case "PutActiveActionsExtraAnalyteParameterConditional":
            this.putActiveAnalyteParameterConditional(
               emit.object.id.toString(),
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
      }
   }

   async ActionsConditional(emit: ActionsConditional) {
      switch (emit.action) {
         case "Editar":
            this.addAnalyteCondition(emit.object.id.toString());
            break;
         case "Excluir":
            this._toastService
               .fireConfirmation("analyteCondition.msgDeleteCondition")
               .then(async (res) => {
                  if (res.isConfirmed) {
                     await this._analyteConditionalService.delete(
                        emit.object.id
                     );
                     this._toastService.fireSuccess(
                        "analyteCondition.msgDeleteSuccessCondition"
                     );
                     await this.getConditions();
                  }
               })
               .catch((error) => {
                  this._toastService.fireError(error);
               });
            break;
         case "OpenDropdown":
            this.getConditionalParameters(emit.id);
            break;
         case "PutActiveActionsExtraAnalyteConditional":
            this._analyteConditionalService
               .patchActive(emit.object.id.toString(), emit.object.Ativo)
               .then(() => {
                  this._toastService.fireSuccess("global.msgSituationSuccess");
               });
      }
   }

   putActiveAnalyteParameterConditional(id: string, value: string) {
      this._analyteConditionalParameters
         .patchActive(id, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getConditionalParameters(id);
         })
         .catch((err) => this._toastService.fireError(err));
   }

   putActiveActionsQuestions(id: string, value: string) {
      this._analyteQuestionService
         .patchActive(id, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getQuestionsAnalyte();
         })
         .catch((err) => this._toastService.fireError(err));
   }

   async ActionsQuestion(emit: ActionsQuestion) {
      switch (emit.action) {
         case "Excluir":
            await this._analyteQuestionService
               .delete(emit.object.id)
               .then(async (res) => {
                  this._toastService.fireSuccess("analyteQuestion.msgDelete");
                  this.close();
                  await this.getQuestionsAnalyte();
               })
               .catch((err) => {
                  this._toastService.fireError(err);
               });
            break;
         case "PutActiveActionsQuestions":
            this._analyteQuestionService
               .patchActive(emit.object.id.toString(), emit.object.Ativo)
               .then(() => {
                  this._toastService.fireSuccess("global.msgSituationSuccess");
               });
            break;
      }
   }

   async ActionsMaterial(emit: ActionsMaterial) {
      switch (emit.action) {
         case "Editar":
            this.materialId = emit.object.id;
            this.openEditMaterial(emit.object["_hide_id"].toString());
            break;
         case "Excluir":
            setTimeout(() => {
               this._toastService
                  .fireConfirmation("analyteMaterial.msgDelete")
                  .then(async (res) => {
                     if (res.isConfirmed) {
                        await this._analyteMaterial
                           .delete(emit.object.id)
                           .then(async () => {
                              this._toastService.fireSuccess(
                                 "analyteMaterial.msgDeleteSuccess"
                              );
                              await this.getAnalyteMaterials(this.analyteId);
                              this.close();
                           })
                           .catch((err) => {
                              this._toastService.fireError(err);
                           });
                     }
                  });
            });
            this.getAnalyteMaterials(this.analyteId);
            break;
         case "PutActiveActionsMatRecipient":
            this._analyteMaterial
               .patchActive(emit.object.id.toString(), emit.object.Ativo)
               .then(() => {
                  this._toastService.fireSuccess("global.msgSituationSuccess");
               });
            break;
         case "OpenDropdown":
            this.getAnalyteMaterialRecipients(emit.object.id);
            break;
      }
   }

   async ActionsRecipientModalList(emit: any) {
      switch (emit.action) {
         case "Excluir":
            this.recipientsList = this.recipientsList.filter(
               (x: any) =>
                  x["_hide_recipientId"] != emit.object["_hide_recipientId"]
            );
            this._toastService.fireSuccess(
               "analyteMaterial.msgDeleteRecipientSuccess"
            );
            break;
      }
   }

   putActiveActionsMatRecipient(id: Guid, value: string) {
      this._analyteMaterial
         .patchActive(id.toString(), value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getAnalyteMaterials(this.analyteId);
         })
         .catch((err) => this._toastService.fireError(err));
   }

   async ActionsRecip(emit: ActionsRecipient) {
      switch (emit.action) {
         case "Excluir":
            setTimeout(() => {
               this._toastService
                  .fireConfirmation("analyteMaterial.msgDeleteRecipient")
                  .then(async (res) => {
                     if (res.isConfirmed) {
                        await this._analyteMaterialRecipient
                           .delete(emit.object._hide_id)
                           .then(async (res) => {
                              this._toastService.fireSuccess(
                                 "analyteMaterial.msgDeleteRecipientSuccess"
                              );
                              await this.getAnalyteMaterialRecipients(
                                 this.analyteMaterialId
                              );
                              this.close();
                           })
                           .catch((err) => {
                              this._toastService.fireError(err);
                           });
                     }
                  });
            });
            break;
      }
   }

   searchRecipients(search: string) {
      const query = {
         isAcive: true,
         resume: true,
         description: search
      };
      this._recipientsService.getPagination(query).then((res: any) => {
         this.recipientsOptions = res.data.map((element: any) => ({
            label: element.description,
            value: element.id,
            externalId: element.externalId
         }));
      });
   }

   setFocusTo(form: UntypedFormControl) {
      const elements = [
         ...this.inputsProp.toArray(),
         ...this.inputsSearch.toArray()
      ];
      const targetInput: any = elements.find(
         (element: any) => element.form === form
      );
      if (targetInput) {
         targetInput.input.nativeElement.focus();
      }
   }

   setRecipientCode() {
      const recipient = this.recipientsOptions.find(
         (recipient) => recipient.value === this.form.newRecipient.value
      );
      if (recipient) {
         this.form.recipientCode.setValue(recipient.externalId);
      }
   }

   getRecipientByCode() {
      const query = {
         isAcive: true,
         resume: true,
         externalId: this.form.recipientCode.value
      };
      this._recipientsService.getPagination(query).then((res: any) => {
         this.recipientsOptions = res.data.map((element: any) => ({
            label: element.description,
            value: element.id,
            externalId: element.externalId
         }));
         this.form.newRecipient.setValue(
            this.recipientsOptions[0]?.value ?? ""
         );
         if (this.recipientsOptions.length == 0) {
            this._toastService.warningMessage(
               "analyteMaterial.msgInfoRecipient"
            );
            this.form.recipientCode.setValue("");
            this.setFocusTo(this.form.recipientCode);
         }
      });
   }

   async getAnalyteMaterialRecipients(
      analyteMaterialId: any,
      params?: number[]
   ) {
      if (params) {
         this.recipientsParams[analyteMaterialId] = {
            page: params[0],
            index: params[1],
            total: 0
         };
      } else {
         this.recipientsParams[analyteMaterialId] = {
            page: 1,
            index: 10,
            total: 0
         };
      }

      const query = {
         page: this.recipientsParams[analyteMaterialId].page,
         numberRegistry: this.recipientsParams[analyteMaterialId].index,
         AnalyteMaterialId: analyteMaterialId
      };

      await this._analyteMaterialRecipient.getPagination(query).then((res) => {
         this.resolveRecipient(res.data, resolveRecipients, analyteMaterialId);
         this.recipientsParams[analyteMaterialId].total = res.sumRecords;
      });
      this.analyteMaterialId = analyteMaterialId;
   }

   async getAtributesReference(id: any) {
      this.referenceAtributes[id] = [];
      await this._referenceAtributeAnalyteService
         .get("?atributeAnalyteId=" + id)
         .then((res: any) => {
            if (res.data.length != 0) {
               this.resolveAtributesReference(
                  res.data,
                  resolveAtributeReference,
                  id
               );
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async getAtributesReport(id: any) {
      this.atributesReport[id] = [];
      await this._atributeReportService
         .get("?ReportId=" + id)
         .then((res: any) => {
            if (res.data.length != 0) {
               this.listAtributes = res.data;
               this.resolveAtributesReport(res.data, resolveAtributeReport, id);
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   resolveReports(data: any, columns: any) {
      this.reports = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.reports.push(obj);
      });
   }

   resolveRecipient(data: any, columns: any, analyteMaterialId: string) {
      this.recipients[analyteMaterialId] = [];
      const recips: Array<Object> = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         recips.push(obj);
      });
      this.recipients[analyteMaterialId.toString()] = recips;
   }

   resolveAtributesReference(data: any, columns: any, id: Guid) {
      this.referenceAtributes[id.toString()] = [];
      const referenceAtributes: Array<Object> = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         referenceAtributes.push(obj);
      });
      this.referenceAtributes[id.toString()] = referenceAtributes;
   }

   resolveAtributesReport(data: any, columns: any, id: number) {
      this.atributesReport[id] = [];
      const atributesReport: Array<Object> = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         atributesReport.push(obj);
      });
      this.atributesReport[id] = atributesReport;
   }

   resolveQuestion(data: any, columns: any) {
      this.questions = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.questions.push(obj);
      });
   }

   async addQuestion() {
      this.form.question.reset();
      const myTempDialog = this._dialog.open(this.dialogModalQuestion, {
         width: "760px"
      });
   }

   getQuestions(searchTerm: string) {
      const query = {
         isActive: true,
         resume: true,
         search: searchTerm
      };
      this._questionsService.getPagination(query).then((res: any) => {
         this.AllQuestions = res.data.map((question: any) => ({
            label: question.name,
            value: question.id,
            externalId: question.externalId
         }));
      });
   }

   getQuestionByCode() {
      const query = {
         isActive: true,
         resume: true,
         externalId: this.form.questionCode.value
      };
      this._questionsService.getPagination(query).then((res: any) => {
         this.AllQuestions = res.data.map((question: any) => ({
            //TODO: alinhar com back para retornar 1 pergunta por código
            label: question.name,
            value: question.id,
            externalId: question.externalId
         }));
         this.form.question.setValue(this.AllQuestions[0]?.value ?? null);
         if (this.AllQuestions.length == 0) {
            this._toastService.warningMessage("analyteQuestion.msgInfo");
            this.form.questionCode.setValue("");
            this.setFocusTo(this.form.questionCode);
         }
      });
   }

   setQuestionCode() {
      const question = this.AllQuestions.find(
         (question) => question.value == this.form.question.value
      );
      this.form.questionCode.setValue(question?.externalId);
   }

   async openEditMaterial(materialId: string) {
      this.type = EType.EDITAR;
      this.editMaterial = true;
      await this._materialService
         .getById(new Guid(materialId))
         .then((res: any) => {
            this.form.materialName.setValue(res.data[0].description, {
               emitEvent: false
            });
            this.form.materialCode.setValue(res.data[0].externalId, {
               emitEvent: false
            });
         });

      const analyteMaterial: any = this.AllMaterialsExam.find(
         (x: any) => x.materialId == materialId
      );
      await this._analyteMaterialRecipient
         .get("?AnalyteMaterialId=" + analyteMaterial.id)
         .then((res: any) => {
            this.recipientsList = res.data.map((element: any) => ({
               id: element.id,
               "analyteMaterial.code": element.recipientExternalId ?? "",
               "analyteMaterial.description": element.description,
               _hide_recipientId: element["recipientId"]
            }));
         });

      this.form.isDefaultMaterial.setValue(analyteMaterial.isDefaultMaterial);
      this.form.deadline.setValue(analyteMaterial.normalDeadLine);
      this.form.urgentDeadline.setValue(analyteMaterial.urgentDeadLine);
      this.form.emergencyDeadline.setValue(
         this.formatTime(analyteMaterial.emergencyDeadLine)
      );
   }

   backToVisualization() {
      this.type = EType.VISUALIZAR;
   }

   async addMaterial() {
      this.type = EType.NOVO;
      this.editMaterial = false;
      this.resetFormMaterial();
   }

   formatTime(time: string) {
      if (time) {
         const timeSplitted = time.split(":");
         return `${timeSplitted[0]}:${timeSplitted[1]}`;
      }
      return null;
   }

   async saveQuestion() {
      const postObj = {
         analyteId: this.analyteId,
         questions: [
            {
               id: this.form.question.value,
               isRequired: this.form.isRequired.value
            }
         ]
      };
      await this._analyteQuestionService
         .post(postObj)
         .then(async (res: any) => {
            if (res.success) {
               await this.getQuestionsAnalyte();
               this.form.question.reset();
               this._dialog.closeAll();
               this._toastService.fireSuccess("analyteQuestion.msgPost");
            }
         });
   }

   async openModalRecipient(id: Guid) {
      this.recipientsList = [];
      this.recipientsOptions = [];
      this.form.newRecipient.reset();
      this.form.recipientCode.reset();
      const myTempDialog = this._dialog.open(this.dialogModalMaterial, {
         width: "660px",
         data: id
      });
   }

   async saveMaterial() {
      const recipients: Array<any> = [];

      this.recipientsList.forEach((element: any) => {
         const recipient = {
            recipientId: element["_hide_recipientId"]
         };
         recipients.push(recipient);
      });

      let materialSave = {
         analyteId: this.analyteId,
         materialId: this.form.materialId.value,
         normalDeadLine: parseInt(this.form.deadline.value),
         urgentDeadLine: parseInt(this.form.urgentDeadline.value),
         emergencyDeadLine: this.form.emergencyDeadline.value
            ? `${this.form.emergencyDeadline.value}:00`
            : null,
         isDefaultMaterial: this.form.isDefaultMaterial.value ?? false,
         analyteMaterialRecipient: recipients
      };

      const hasDefaultMaterial: boolean = this.materials.some(
         (x: any) => x["_hide_isDefaultMaterial"] == "true"
      );

      if (this.form.isDefaultMaterial.value == true && hasDefaultMaterial) {
         setTimeout(() => {
            this._toastService
               .fireConfirmation("analyteMaterial.msgMaterial")
               .then(async (res) => {
                  if (res.isConfirmed) this.postMaterial(materialSave);
               });
         });
      } else {
         this.postMaterial(materialSave);
      }
   }

   async postMaterial(materialSave: Object) {
      //@ts-ignore
      if (this.materialId.guid == Guid.getEmpty()) {
         await this._analyteMaterial.post(materialSave).then((res: any) => {
            if (res.success) {
               this._toastService.fireSuccess("analyteMaterial.msgPost");
               this.close();
               this.type = EType.VISUALIZAR;
            }
         });
      } else {
         await this._analyteMaterial
            .put(materialSave, this.materialId)
            .then((res: any) => {
               if (res.success) {
                  this.saveMaterialRecipients();
                  this.close();
                  this._toastService.fireSuccess("analyteMaterial.msgPut");
                  this.type = EType.VISUALIZAR;
               }
            });
      }
      await this.getAnalyteMaterials(this.analyteId);
      await this.getAnalyteMaterialRecipients(this.materialId);
   }

   getMaterialByCode() {
      const query = {
         isActive: true,
         resume: true,
         externalId: this.form.materialCode.value
      };
      this._materialService.getPagination(query).then((res) => {
         this.AllMaterials = res.data.map((element: any) => ({
            label: element.description,
            value: element.id,
            externalId: element.externalId
         }));
         this.form.materialId.setValue(this.AllMaterials[0]?.value);
         if (this.AllMaterials.length == 0) {
            this._toastService.warningMessage(
               "analyteMaterial.msgInfoMaterial"
            );
            this.setFocusTo(this.form.materialCode);
            this.form.materialCode.setValue("");
         }
      });
   }

   setMaterialCode() {
      const material = this.AllMaterials.find(
         (material) => material.value == this.form.materialId.value
      );
      this.form.materialCode.setValue(material?.externalId ?? "");
   }

   getMaterials(emit: any) {
      this._materialService
         .get(`?isActive=true&resume=true&search=${emit.value}`)
         .then((res: any) => {
            if (res.data.length != 0) {
               this.AllMaterials = res.data.map((element: any) => ({
                  label: element.description,
                  value: element.id,
                  externalId: element.externalId
               }));
            }
         });
   }

   async saveMaterialRecipients() {
      const body = {
         analyteMaterialRecipients: this.recipientsList.map((element: any) => ({
            isActive: true,
            analyteMaterialRecipientsId: element.id,
            recipientId: element["_hide_recipientId"]
         }))
      };
      await this._analyteMaterialRecipient.put(body, this.materialId).then();
   }

   async newRecipient() {
      const body = {
         analyteMaterialId: this.analyteMaterialId,
         recipientId: this.form.newRecipient.value
      };
      await this._analyteMaterialRecipient.post(body).then(async (res) => {
         SwAlSetttings.Sucesso("Recipiente cadastrado com sucesso!");
         this.close();
         this.getAnalyteMaterialRecipients(this.analyteMaterialId);
      });
   }

   resetFormMaterial() {
      this.recipientsList = [];
      this.AllMaterials = [];
      this.form.materialCode.reset(null, { emitEvent: false });
      this.form.materialId.reset(null, { emitEvent: false });
      this.form.recipientCode.reset(null, { emitEvent: false });
      this.form.newRecipient.reset(null, { emitEvent: false });
      this.form.deadline.reset();
      this.form.urgentDeadline.reset();
      this.form.emergencyDeadline.reset();
      this.form.isDefaultMaterial.reset();
      this.recipientsOptions = [];
   }

   close() {
      this._dialog.closeAll();
   }

   async addReport(edit: boolean = false, id: string = "") {
      this.resetFormReports();

      if (this.AllMaterialsExam.length == 0) {
         await this.getAnalyteMaterials(this.analyteId);
      }

      this.AllMaterialsReport = [];

      this.AllMaterialsExam.forEach((material: any) => {
         this.AllMaterialsReport.push({
            label: material.description,
            value: material.materialId,
            id: material.id
         });
      });

      this.AllMethodologys = [];
      await this._methodologyService
         .getAll()
         .then((res: any) => {
            if (res.data.length != 0) {
               res.data.forEach((element: any) => {
                  this.AllMethodologys.push({
                     label: element.name,
                     value: element.id
                  });
               });
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });

      if (edit) {
         var report: any = this.AllReports.filter((x: any) => x.id === id)[0];
         this.form.reportName.setValue(report.name);
         this.form.reportMaterial.setValue(report.materialId);
         this.form.reportMaterialName.setValue(report.materialReportName);
         this.form.reportMethodology.setValue(report.methodologyId);
         this.form.reportActive.setValue(report.isActive);
         this.reportId = new Guid(id);
      } else {
         this.reportId = new Guid(Guid.getEmpty());
      }
      this.titleModal = id?.toString() ?? "";
      const myTempDialog = this._dialog.open(this.dialogModalReport, {
         width: "660px"
      });
   }

   async addAttribute(edit: boolean = false, id: string = "") {
      await this.getUnitMeasure();

      this.AllQuestions = [];
      await this._questionsService
         .get("?isActive=true&page=1&registerIndex=99999&resume=true")
         .then((res: any) => {
            if (res.data.length != 0) {
               res.data.forEach((element: any) => {
                  this.AllQuestions.push({
                     label: element.name,
                     value: element.id,
                     externalId: element.externalId
                  });
               });
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });

      if (edit) {
         await this._atributeAnalyteService
            .getById(new Guid(id))
            .then((res: any) => {
               if (res.data.length != 0) {
                  this.formAttribute.name.setValue(res.data[0].name);
                  this.formAttribute.analyteId.setValue(res.data[0].analyteId);
                  this.formAttribute.code.setValue(res.data[0].code);
                  this.formAttribute.decimalPlaces.setValue(
                     res.data[0].decimalPlaces
                  );
                  this.formAttribute.isRequired.setValue(
                     res.data[0].isRequired
                  );
                  this.formAttribute.isResult.setValue(res.data[0].isResult);
                  this.formAttribute.isAuxiliar.setValue(
                     res.data[0].isAuxiliar
                  );
                  this.formAttribute.lineAfter.setValue(res.data[0].lineAfter);
                  this.formAttribute.typeAttribute.setValue(
                     res.data[0].typeAttribute
                  );
                  this.formAttribute.isHidden.setValue(res.data[0].isHidden);
                  this.formAttribute.isHiddenWhenNull.setValue(
                     res.data[0].isHiddenWhenNull
                  );
                  this.formAttribute.isUpperCase.setValue(
                     res.data[0].isUpperCase
                  );
                  this.formAttribute.valueDefault.setValue(
                     res.data[0].valueDefault
                  );
                  this.formAttribute.unitMeasureId.setValue(
                     res.data[0].unitMeasureId
                  );
                  this.formAttribute.questionId.setValue(
                     res.data[0].questionId
                  );
                  this.formAttribute.attributeMinAbsurdValue.setValue(
                     res.data[0].attributeMinAbsurdValue
                  );
                  this.formAttribute.attributeMaxAbsurdValue.setValue(
                     res.data[0].attributeMaxAbsurdValue
                  );
                  this.formAttribute.attributeMinControlValue.setValue(
                     res.data[0].attributeMinControlValue
                  );
                  this.formAttribute.attributeMaxControlValue.setValue(
                     res.data[0].attributeMaxControlValue
                  );
                  this.formAttribute.attributeMinCriticalValue.setValue(
                     res.data[0].attributeMinCriticalValue
                  );
                  this.formAttribute.attributeMaxCriticalValue.setValue(
                     res.data[0].attributeMaxCriticalValue
                  );
                  this.formAttribute.attributeUpValue.setValue(
                     res.data[0].attributeUpValue
                  );
                  this.formAttribute.attributeDownValue.setValue(
                     res.data[0].attributeDownValue
                  );
               }
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });

         this.attributeId = new Guid(id);
      } else {
         this.attributeId = new Guid(Guid.getEmpty());
      }

      const queryString = "?atributeAnalyteId=" + this.analyteId;
      await this._attributeOptionsList
         .get(queryString + id)
         .then((response) => {
            if (response.data.length != 0) {
               this.allOptionsList = response.data;
               this.resolveOptions(this.allOptionsList, resolveOptionsList);
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
      this.titleModal = id?.toString() ?? "";
      const myTempDialog = this._dialog.open(this.dialogModalAttribute, {
         width: "660px",
         maxHeight: "750px"
      });

      myTempDialog.afterClosed().subscribe((res) => {
         this.resetFormAttribute();
      });
   }

   async saveReport() {
      let material: any = this.AllMaterialsReport.filter(
         (x: any) => x.value == this.form.reportMaterial.value
      );

      if (!this.form.reportName.value) {
         this._toastService.warningMessage("reports.msgInfo");
         return;
      }

      const report = {
         isActive: this.form.reportActive.value,
         analyteId: this.analyteId,
         methodologyId: this.form.reportMethodology.value,
         analyteMaterialId: material[0]?.id,
         name: this.form.reportName.value,
         description: material[0]?.label,
         materialReportName: this.form.reportMaterialName.value,
         methodologyName:
            this.AllMethodologys.filter(
               (x: any) => x.value == this.form.reportMethodology.value
            )[0]?.label ?? []
      };

      if (this.reportId.toString() == Guid.getEmpty()) {
         await this._reportService.post(report).then(async (res) => {
            this._toastService.fireSuccess("reports.msgPost");
            this.resetFormReports();
            this.close();
         });
      } else {
         await this._reportService
            .put(report, this.reportId)
            .then(async (res) => {
               this._toastService.fireSuccess("reports.msgPut");
               this.resetFormReports();
               this.close();
               this.getReports();
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });
      }
      this.reportId = new Guid(Guid.getEmpty());
      await this.getReports();
   }

   async includeAttribute() {
      this.formAttribute.optionsList.reset();

      const attribute: AtributeAnalytePostObject = {
         analyteId: this.analyteId.toString(),
         name: this.formAttribute.name.value,
         externalId: this.formAttribute.code.value,
         typeAttribute: parseInt(this.formAttribute.typeAttribute.value),
         decimalPlaces: this.formAttribute.decimalPlaces.value
            ? parseInt(this.formAttribute.decimalPlaces.value)
            : 0,
         isRequired: this.formAttribute.isRequired.value ?? false,
         isResult: this.formAttribute.isResult.value ?? false,
         isAuxiliar: this.formAttribute.isAuxiliar.value ?? false,
         isHidden: this.formAttribute.isHidden.value ?? false,
         isHiddenWhenNull: this.formAttribute.isHiddenWhenNull.value ?? false,
         isUpperCase: this.formAttribute.isUpperCase.value
            ? this.formAttribute.isUpperCase.value
            : false,
         applyOldResult: this.formAttribute.applyOldResult.value,
         valueDefault: this.formAttribute.valueDefault.value,
         unitMeasureId: this.formAttribute.unitMeasureId.value
            ? this.formAttribute.unitMeasureId.value
            : null,
         questionId: this.formAttribute.questionId.value
            ? this.formAttribute.questionId.value
            : null,
         fileImageResultId: this.formAttribute.image.value,
         attributeMinAbsurdValue:
            this.formAttribute.attributeMinAbsurdValue.value,
         attributeMaxAbsurdValue:
            this.formAttribute.attributeMaxAbsurdValue.value,
         attributeMinControlValue:
            this.formAttribute.attributeMinControlValue.value,
         attributeMaxControlValue:
            this.formAttribute.attributeMaxControlValue.value,
         attributeMinCriticalValue:
            this.formAttribute.attributeMinCriticalValue.value,
         attributeMaxCriticalValue:
            this.formAttribute.attributeMaxCriticalValue.value,
         attributeUpValue: this.formAttribute.attributeUpValue.value,
         attributeDownValue: this.formAttribute.attributeDownValue.value
      };

      let attributeId: Guid;

      if (this.attributeId.toString() === Guid.getEmpty().toString()) {
         await this._atributeAnalyteService
            .post(attribute)
            .then(async (response) => {
               attributeId = new Guid(response.id);
               await this.getAtributes();
               this.close();
               this.resetFormAttribute();
               this._toastService.fireSuccess("analyteAtributo.msgPost");
            });
      } else {
         attributeId = this.attributeId;
         await this._atributeAnalyteService
            .put(attribute, this.attributeId)
            .then(async () => {
               this._toastService.fireSuccess("analyteAtributo.msgPut");
               await this.getAtributes();
               this.resetFormAttribute();
               this.close();
            });
      }

      if (this.formAttribute.typeAttribute.value == 13) {
         const options: any = [];
         this.optionsList.forEach((option: any) => {
            if (option.id == "null") {
               options.push({
                  Description: option.Valor
               });
            }
         });

         const optionsList = {
            //@ts-ignore
            AtributeAnalyteId: attributeId.guid,
            AttributeOptionsData: options
         };

         await this._attributeOptionsList.post(optionsList).then((res) => {});
      }

      this.attributeId = new Guid(Guid.getEmpty());
   }

   async ActionsReport(emit: ActionsReport) {
      switch (emit.action) {
         case "Excluir":
            this._toastService
               .fireConfirmation("reports.msgDelete")
               .then(async (res) => {
                  if (res.isConfirmed) {
                     await this._reportService.delete(emit.object.id);
                     this._toastService.fireSuccess("report.msgDeleteSuccess");
                     await this.getReports();
                  }
               })
               .catch((error) => {
                  this._toastService.fireError(error);
               });
            break;
         case "Editar":
            this.addReport(true, emit.object.id.toString());
            break;
         case "OpenDropdown":
            if (this.attributes.length == 0) {
               await this.getAtributes();
            }
            this.newAtributes = [];
            this.attributes.forEach((atribute: any) => {
               this.newAtributes.push({
                  value: atribute.id,
                  label: atribute.Descrição
               });
            });
            this.reportId = new Guid(emit.object.id.toString());
            this.getAtributesReport(emit.object.id);
            break;
         case "PutActiveReport":
            this._reportService
               .patchActive(emit.object.id.toString(), emit.object.Ativo)
               .then(() => {
                  this._toastService.fireSuccess("global.msgSituationSuccess");
               });
            break;
         case "Visualizar":
            await this.getAtributesReport(emit.object.id);
            this.generatePdf();
            break;
      }
   }

   putActiveReport(id: Guid, value: string) {
      this._reportService
         .patchActive(id.toString(), value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getReports();
         })
         .catch((err) => this._toastService.fireError(err));
   }

   async ActionsAttributes(emit: ActionsAttributes) {
      switch (emit.action) {
         case "Editar":
            this.attributeId = emit.object.id;
            this.addAttribute(true, emit.object.id.toString());
            break;
         case "Excluir":
            this._toastService
               .fireConfirmation("analyteAtributo.msgDelete")
               .then(async (res) => {
                  if (res.isConfirmed) {
                     await this._atributeAnalyteService.delete(emit.object.id);
                     this._toastService.fireSuccess(
                        "analyteAtributo.msgDeleteSuccess"
                     );
                     await this.getAtributes();
                  }
               })
               .catch((error) => {
                  this._toastService.fireError(error);
               });
            break;

         case "OpenDropdown":
            this.referenceAtributeAnalyteId = emit.object.id.toString();
            this.getAtributesReference(emit.object.id);
            break;
         case "PutActiveAtribute":
            this._atributeAnalyteService
               .patchActive(emit.object.id.toString(), emit.object.Ativo)
               .then(() => {
                  this._toastService.fireSuccess("global.msgSituationSuccess");
               });
            break;
      }
   }

   putActiveAtribute(id: string, value: string) {
      this._atributeAnalyteService
         .patchActive(id, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getAtributes();
         })
         .catch((err) => this._toastService.fireError(err));
   }

   async addRecipient() {
      this.setFocusTo(this.form.recipientCode);
      const recipient = this.recipientsOptions.find(
         (x: any) => x.value == this.form.newRecipient.value
      );
      const isRecipientAlreadyAdded: boolean = this.recipientsList.some(
         (x: any) => x["_hide_recipientId"] == recipient?.value ?? ""
      );
      if (!recipient) {
         return this._toastService.warningMessage("analyteAtributo.msgInfo");
      }
      if (isRecipientAlreadyAdded) {
         return this._toastService.warningMessage(
            "analyteAtributo.msgInfoSuccess"
         );
      }
      this.recipientsList.push({
         _hide_recipientId: recipient.value,
         "analyteAtributo.code": recipient.externalId,
         "analyteAtributo.name": recipient.label,
         id: ""
      });
      this.recipienteId = recipient.value;
      this.form.newRecipient.reset();
      this.form.recipientCode.reset();
      this._toastService.fireSuccess("analyteAtributo.msgPostRecipient");
   }

   async createAnalyte() {
      const analyteObj = {
         code: this.form.code.value,
         name: this.form.name.value,
         isOutsourced: this.form.outsourced.value,
         valueOutsourced: this.form.outsourcedvalue.value,
         typeResultWeb: this.form.typeResultWeb.value,
         nameInvoice: this.form.nameInvoice.value,
         restrictGender: this.form.restrictGender.value,
         analyteSequenceReport:
            this.form.sequenceReport.value == ""
               ? 0
               : this.form.sequenceReport.value,
         analyteOrdemReport:
            this.form.orderReport.value == "" ? 0 : this.form.orderReport.value,
         sectorId: this.form.sectorId.value,
         selectRecipients: false,
         information: this.form.information.value,
         addQuestions: [],
         addMaterialRecipient: [],
         addReports: []
      };
      //TODO - atualmente o post não esta alinhado com a logica da tela selectRecipients, addQuestions, addMaterialRecipient, addReports são informados apos o cadastro do analyte
      await this._analyteService.post(analyteObj).then(async () => {
         this._toastService.fireSuccess("analyteEdit.msgPost");
         this.back();
      });
   }

   getCode(item: any) {
      return item.externalId ?? "";
   }

   async updateAnalyte() {
      const analyteObj = {
         code: this.form.code.value,
         name: this.form.name.value,
         isOutsourced: this.form.outsourced.value,
         valueOutsourced: this.form.outsourcedvalue.value,
         sectorId: this.form.sectorId.value,
         typeResultWeb: this.form.typeResultWeb.value,
         nameInvoice: this.form.nameInvoice.value,
         restrictGender: this.form.restrictGender.value,
         analyteSequenceReport:
            this.form.sequenceReport.value == ""
               ? 0
               : this.form.sequenceReport.value,
         analyteOrdemReport:
            this.form.orderReport.value == "" ? 0 : this.form.orderReport.value,
         defaultSupportLaboratoryId: this.form.defaultSupportLaboratory.value,
         information: this.form.information.value
      };
      await this._analyteService
         .put(analyteObj, this.analyteId)
         .then(async () => {
            this._toastService.fireSuccess("analyteEdit.msgPut");
            this.back();
         });
   }

   getAnalyteInstructionsList(): Array<any> {
      const forms: { [key: number]: string } = {
         1: this.formManual.preparation.value,
         2: this.formManual.collect.value,
         3: this.formManual.distribution.value,
         4: this.formManual.estabilish.value,
         5: this.formManual.reject.value,
         6: this.formManual.additional.value,
         7: this.formManual.interpretation.value,
         8: this.formManual.pathologies.value,
         9: this.formManual.links.value,
         10: this.formManual.description.value
      };

      const analyteInstructions: Array<any> = Object.keys(forms)
         .map((key) => ({
            typeAnalyteInstructions: parseInt(key),
            description: forms[parseInt(key)],
            isActive: true
         }))
         .filter((obj) => obj.description != null && obj.description != "");

      return analyteInstructions;
   }

   submitAnalyteInstructions() {
      if (this.analyteInstructions.length > 0) {
         return this.updateAnalyteInstructions();
      }
      return this.createAnalyteInstructions();
   }

   async createAnalyteInstructions() {
      const analyteInstructions = this.getAnalyteInstructionsList();

      const analyteInstructionsPostObject = {
         analyteId: this.analyteId,
         analyteInstructions
      };

      await this._manualExamsService
         .post(analyteInstructionsPostObject)
         .then(async (res: any) => {
            if (res.success) {
               await this.getAnalyteInstructions();
               this._toastService.fireSuccess("manual.msgPost");
            }
         });
   }

   async updateAnalyteInstructions() {
      const analyteInstructionsNew = this.getAnalyteInstructionsList();
      const analyteInstructions: Array<Object> = [];

      analyteInstructionsNew.forEach((instruction) => {
         const existingAnalyteInstruction = this.analyteInstructions.find(
            (analyteInstruction) =>
               analyteInstruction.typeAnalyteInstructions ==
               instruction.typeAnalyteInstructions
         );

         if (existingAnalyteInstruction) {
            instruction.analyteInstructionId = existingAnalyteInstruction.id;
         }
         analyteInstructions.push(instruction);
      });

      await this._manualExamsService
         .put({ analyteInstructions }, this.analyteId)
         .then((res: any) => {
            if (res.success) {
               this._toastService.fireSuccess("manual.msgPost");
            }
         });
   }

   back() {
      this._router.navigateByUrl("/Cadastros/Exames/Exames-Laboratoriais");
   }

   async getAnalyteMaterials(analyteId: Guid, params?: number[]) {
      if (params) {
         this.materialsParameters.page = params[0];
         this.materialsParameters.index = params[1];
      }

      const query = {
         AnalyteId: analyteId.toString(),
         numberRegistry: this.materialsParameters.index,
         page: this.materialsParameters.page
      };

      if (analyteId) {
         await this._analyteMaterial.getPagination(query).then((res: any) => {
            if (res.data.length != 0) {
               this.AllMaterialsExam = res.data;
               this.resolveMaterial(res.data, resolveMaterials);
               this.materialsParameters.total = res.sumRecords;
            }
         });
      }
   }

   async getQuestionsAnalyte() {
      this.questions = [];
      await this._analyteQuestionService
         .get("?AnalyteId=" + this.analyteId)
         .then((res: any) => {
            if (res.data.length != 0) {
               this.resolveQuestion(res.data, resolveQuestions);
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async getReports() {
      this.AllReports = [];
      this.reports = [];
      await this._reportService
         .get("?AnalyteId=" + this.analyteId)
         .then((res: any) => {
            if (res.data.length != 0) {
               this.AllReports = res.data;
               this.resolveReports(res.data, resolveReport);
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   resetFormAttribute() {
      this.formAttribute.name.reset();
      this.formAttribute.analyteId.reset();
      this.formAttribute.code.reset();
      this.formAttribute.decimalPlaces.reset();
      this.formAttribute.isRequired.reset();
      this.formAttribute.isResult.reset();
      this.formAttribute.isAuxiliar.reset();
      this.formAttribute.lineAfter.reset();
      this.formAttribute.typeAttribute.reset();
      this.formAttribute.isHidden.reset();
      this.formAttribute.isHiddenWhenNull.reset();
      this.formAttribute.isUpperCase.reset();
      this.formAttribute.valueDefault.reset();
      this.formAttribute.unitMeasureId.reset();
      this.formAttribute.questionId.reset();
      this.formAttribute.attributeMinAbsurdValue.reset();
      this.formAttribute.attributeMaxAbsurdValue.reset();
      this.formAttribute.attributeMinControlValue.reset();
      this.formAttribute.attributeMaxControlValue.reset();
      this.formAttribute.attributeMinCriticalValue.reset();
      this.formAttribute.attributeMaxCriticalValue.reset();
      this.formAttribute.attributeUpValue.reset();
      this.formAttribute.attributeDownValue.reset();
   }

   resetFormReports() {
      this.form.reportName.reset();
      this.form.reportMaterial.reset();
      this.form.reportMaterialName.reset();
      this.form.reportMethodology.reset();
   }

   async getAtributes() {
      this.attributes = [];
      await this._atributeAnalyteService
         .get("?AnalyteId=" + this.analyteId)
         .then((res: any) => {
            if (res.data.length != 0) {
               this.resolveAtributes(res.data, resolveAtribute);
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   resolveAtributes(data: any, columns: any) {
      this.attributes = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.attributes.push(obj);
      });
   }

   getType(item: Get) {
      let type = [];
      type[0] = `<span class="span-styles" style="background:#44aad9; color:#FFF;">Númerico</span>`;
      type[1] = `<span class="span-styles" style="background:#285e95; color:#FFF;">Texto</span>`;
      type[2] = `<span class="span-styles" style="background:#62997a; color:#FFF;">Descritivo 1</span>`;
      type[3] = `<span class="span-styles" style="background:#62997a; color:#FFF;">Descritivo 2</span>`;
      type[4] = `<span class="span-styles" style="background:#62997a; color:#FFF;">Descritivo 3</span>`;
      type[5] = `<span class="span-styles" style="background:#b25ffc; color:#FFF;">Imagem 576 x 265 Pixels</span>`;
      type[6] = `<span class="span-styles" style="background:#b25ffc; color:#FFF;">Imagem 200 x 400 Pixels</span>`;
      type[7] = `<span class="span-styles" style="background:#b25ffc; color:#FFF;">Imagem 760 x 110 Pixels</span>`;
      type[8] = `<span class="span-styles" style="background:#b25ffc; color:#FFF;">Imagem 760 x 1024 Pixels</span>`;
      type[9] = `<span class="span-styles" style="background:#b25ffc; color:#FFF;">Imagem 336 x 336 Pixels</span>`;
      type[10] = `<span class="span-styles" style="background:#b25ffc; color:#FFF;">Imagem 435 x 300 Pixels</span>`;
      type[11] = `<span class="span-styles" style="background:#e6ac27; color:#FFF;">Pergunta</span>`;
      type[12] = `<span class="span-styles" style="background:#999175; color:#FFF;">Fórmula</span>`;
      type[13] = `<span class="span-styles" style="background:#404040; color:#FFF;">Lista de Opções</span>`;

      return type[item.typeAttribute];
   }

   getAuxiliar(item: Get) {
      return !item.isAuxiliar
         ? '<svg style="max-width: calc(34px * var(--proportional));" width="34" height="21" viewBox="0 0 34 21" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.475647" y="0.647827" width="33.3333" height="20" rx="10" fill="#808080"/> <path d="M10.4756 18.6478C14.8939 18.6478 18.4756 15.0661 18.4756 10.6478C18.4756 6.22955 14.8939 2.64783 10.4756 2.64783C6.05737 2.64783 2.47565 6.22955 2.47565 10.6478C2.47565 15.0661 6.05737 18.6478 10.4756 18.6478Z" fill="white"/> </svg>'
         : '<svg style=\'max-width: calc(34px * var(--proportional));height: auto\' width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.384644" width="33.3333" height="20" rx="10" fill="#FE6344"/> <path d="M23.3846 18C27.8029 18 31.3846 14.4183 31.3846 10C31.3846 5.58172 27.8029 2 23.3846 2C18.9664 2 15.3846 5.58172 15.3846 10C15.3846 14.4183 18.9664 18 23.3846 18Z" fill="white"/> </svg>';
   }

   getRequired(item: Get) {
      return item.isRequired
         ? "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Sim</span>"
         : "<span class='span-styles' style='background: #EB5757; color: #FFFFFF;'>Não</span>";
   }

   async ActionsReferenceAtribute(
      emit: ActionsReferenceAtribute,
      referenceAtributeId: string
   ) {
      switch (emit.action) {
         case "Excluir":
            this._toastService
               .fireConfirmation("analyteAtributo.msgDeleteValue")
               .then(async (res) => {
                  if (res.isConfirmed) {
                     await this._referenceAtributeAnalyteService.delete(
                        emit.object.id
                     );
                     this._toastService.fireSuccess(
                        "analyteAtributo.msgDeleteSuccessValue"
                     );
                     await this.getAtributesReference(referenceAtributeId);
                  }
               })
               .catch((error) => {
                  this._toastService.fireError(error);
               });
            break;
         case "Editar":
            await this.includeAtributeReference(
               referenceAtributeId,
               emit.object.id.toString(),
               true
            );
            this.getAtributesReference(referenceAtributeId);
            break;
         case "PutActiveReferenceValue":
            this._referenceAtributeAnalyteService
               .patchActive(emit.object.id.toString(), emit.object.Ativo)
               .then(() => {
                  this._toastService.fireSuccess("global.msgSituationSuccess");
               });
            break;
      }
   }

   putActiveReferenceValue(id: Guid, value: string) {
      this._referenceAtributeAnalyteService
         .patchActive(id.toString(), value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getAtributesReference(this.referenceAtributeAnalyteId);
         })
         .catch((err) => this._toastService.fireError(err));
   }

   async includeAtributeReference(
      id: string,
      atributeId: string = "",
      edit: boolean = false
   ) {
      this.formReference.atributeAnalyteId.setValue(id);

      if (edit) {
         await this._referenceAtributeAnalyteService
            .getById(new Guid(atributeId))
            .then((res: any) => {
               if (res.data.length != 0) {
                  this.formReference.isActive.setValue(res.data[0].isActive);
                  this.formReference.atributeAnalyteId.setValue(
                     res.data[0].atributeAnalyteId
                  );
                  this.formReference.ageMin.setValue(res.data[0].ageMin);
                  this.formReference.typeAgeMin.setValue(
                     res.data[0].typeAgeMin
                  );
                  this.formReference.ageMax.setValue(res.data[0].ageMax);
                  this.formReference.typeAgeMax.setValue(
                     res.data[0].typeAgeMax
                  );
                  this.formReference.genderType.setValue(
                     res.data[0].genderType
                  );
                  this.formReference.valueMin.setValue(res.data[0].valueMin);
                  this.formReference.valueMax.setValue(res.data[0].valueMax);
                  this.formReference.name.setValue(res.data[0].name);
               }
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });

         this.attributeReferenceId = new Guid(atributeId);
      } else {
         this.attributeReferenceId = new Guid(Guid.getEmpty());
      }

      this.titleSubModal = atributeId ?? "";
      const myTempDialog = this._dialog.open(
         this.dialogModalAttributeReference,
         {
            width: "660px"
         }
      );

      myTempDialog.afterClosed().subscribe((res) => {
         this.resetFormAttributeReference();
      });
   }

   resetFormAttributeReference() {
      this.formReference.isActive.reset();
      this.formReference.atributeAnalyteId.reset();
      this.formReference.ageMin.reset(0);
      this.formReference.typeAgeMin.reset(0);
      this.formReference.ageMax.reset(150);
      this.formReference.typeAgeMax.reset(0);
      this.formReference.genderType.reset(0);
      this.formReference.valueMin.reset(0);
      this.formReference.valueMax.reset(99999);
      this.formReference.name.reset();
   }

   async saveAttributeReference() {
      const obj = {
         isActive: this.formReference.isActive.value
            ? this.formReference.isActive.value
            : true,
         atributeAnalyteId: this.formReference.atributeAnalyteId.value,
         name: this.formReference.name.value,
         ageMin: this.formReference.ageMin.value,
         typeAgeMin: this.formReference.typeAgeMin.value,
         ageMax: this.formReference.ageMax.value,
         typeAgeMax: this.formReference.typeAgeMax.value,
         genderType: this.formReference.genderType.value,
         valueMin: this.formReference.valueMin.value,
         valueMax: this.formReference.valueMax.value
      };

      //@ts-ignore
      if (this.attributeReferenceId.guid != new Guid(Guid.getEmpty())) {
         await this._referenceAtributeAnalyteService
            .put(obj, this.attributeReferenceId)
            .then(() => {
               this._toastService.fireSuccess("analyteAtributo.msgPutValue");
               this.getAtributesReference(
                  this.formReference.atributeAnalyteId.value
               );
               this.close();
               this.resetFormAttributeReference();
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });
      } else {
         await this._referenceAtributeAnalyteService
            .post(obj)
            .then(() => {
               this._toastService.fireSuccess("analyteAtributo.msgPostValue");
               this.getAtributesReference(
                  this.formReference.atributeAnalyteId.value
               );
               this.close();
               this.resetFormAttributeReference();
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });
      }
   }

   getGender(item: Get) {
      return this.genderType[item.genderType].label;
   }

   getTypeMax(item: any) {
      const idade =
         item.ageMax.toString() +
         " " +
         this.typeAtributeReference[item.typeAgeMax].label.toString();
      return idade;
   }

   getTypeMin(item: any) {
      const idade =
         item.ageMin.toString() +
         " " +
         this.typeAtributeReference[item.typeAgeMin].label.toString();
      return idade;
   }

   async ActionsParameters(emit: any) {
      switch (emit.action) {
         case "PutActiveParameter":
            this._analyteParametersService
               .patchActive(emit.object.id, emit.object.Ativo)
               .then(() => {
                  this._toastService.fireSuccess("global.msgSituationSuccess");
               });
            break;
      }
   }

   putActiveParameter(id: string, value: string) {
      this._analyteParametersService
         .patchActive(id, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getParameters();
         })
         .catch((err) => this._toastService.fireError(err));
   }

   filterOptionparamter(options: any) {
      this.typeAnalyteParametersFilter =
         this.typeAnalyteParametersFilter.filter((res) => {
            return res.value !== options;
         });
      this.typeAnalyteParametersFilter.length === 0 ? this.close() : null;
   }

   modalParameter() {
      if (this.typeAnalyteParametersFilter.length > 0) {
         const myTempDialog = this._dialog.open(this.dialogModalParameters, {
            width: "660px"
         });
      }
      this.typeAnalyteParametersFilter.length === 0
         ? this._toastService.warningMessage("analyteParameters.msgInfo")
         : null;
   }

   async newParameter() {
      const obj = {
         AnalyteId: this.analyteId,
         Parameter:
            this.formParameters.parameter.value === null
               ? this.typeAnalyteParametersFilter[0].value
               : this.formParameters.parameter.value
      };
      await this._analyteParametersService
         .post(obj)
         .then((res) => {
            this._toastService.fireSuccess("analyteParameters.msgPost");
            this.formParameters.parameter.reset();
            this.getParameters();
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async getParameters() {
      const queryString = "?analyteId=";
      await this._analyteParametersService
         .get(queryString + this.analyteId)
         .then((response) => {
            this.resolve(response.data, resolveParameters);
            response.data.forEach((response) => {
               this.filterOptionparamter(response.parameter);
            });
         });
   }

   resolve(data: any, columns: any) {
      this.parameters = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.parameters.push(obj);
      });
   }

   resolveOptions(data: any, columns: any) {
      this.optionsList = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.optionsList.push(obj);
      });
   }

   getTypeParameter(item: Get) {
      let type = [];
      type[1] = `<span class="span-styles" style="background:#404040; color:#FFF;">Exame especial, exige senha sempre para impressão</span>`;
      type[2] = `<span class="span-styles" style="background:#285e95; color:#FFF;">Não imprime resultados anteriores no laudo</span>`;
      type[3] = `<span class="span-styles" style="background:#62997a; color:#FFF;">Permite digitar valores de resultados absurdos mediante senha</span>`;
      type[4] = `<span class="span-styles" style="background:#b25ffc; color:#FFF;">Permite informar quantidade superior a um</span>`;
      return type[item.parameter];
   }

   async addAnalyteCondition(id: string = "") {
      this.resetConditionalForm();
      this.AllAttributes = [];
      await this._atributeAnalyteService
         .get("?AnalyteId=" + this.analyteId)
         .then((res: any) => {
            if (res.data.length != 0) {
               res.data.forEach((element: any) => {
                  this.AllAttributes.push({
                     label: element.name,
                     value: element.id,
                     isNumeric: element.isNumeric,
                     typeAttribute: element.typeAttribute
                  });
               });
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });

      if (id != "") {
         this._analyteConditionalService
            .getById(new Guid(id))
            .then((res: any) => {
               this.formConditional.atributeAnalyteId.setValue(
                  res.data[0].atributeAnalyteId
               );
               this.formConditional.typeAnalisys.setValue(
                  res.data[0].typeAnalisys
               );
               this.formConditional.typeBlockedText.setValue(
                  res.data[0].typeBlockedText
               );
               this.formConditional.textAnalisys.setValue(
                  res.data[0].textAnalisys
               );
               this.formConditional.typeBlockedNumericFrom.setValue(
                  res.data[0].typeBlockedNumericFrom
               );
               this.formConditional.startNumber.setValue(
                  res.data[0].startNumber
               );
               this.formConditional.typeBlockedNumericTo.setValue(
                  res.data[0].typeBlockedNumericTo
               );
               this.formConditional.finishNumber.setValue(
                  res.data[0].finishNumber
               );
               this.formConditional.isActive.setValue(res.data[0].isActive);
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });
         this.conditionId = new Guid(id);
      } else {
         this.conditionId = new Guid(Guid.getEmpty());
      }

      this.titleModal = id?.toString() ?? "";
      const myTempDialog = this._dialog.open(this.dialogModalConditional, {
         width: "660px"
      });
   }

   saveConditional() {
      const obj = {
         analyteId: this.analyteId,
         atributeAnalyteId: this.formConditional.atributeAnalyteId.value,
         typeAnalisys: this.formConditional.typeAnalisys.value,
         typeBlockedText: this.formConditional.typeBlockedText.value,
         textAnalisys: this.formConditional.textAnalisys.value,
         typeBlockedNumericFrom:
            this.formConditional.typeBlockedNumericFrom.value ?? 0,
         startNumber: this.formConditional.startNumber.value ?? 0,
         typeBlockedNumericTo:
            this.formConditional.typeBlockedNumericTo.value ?? 0,
         finishNumber: this.formConditional.finishNumber.value ?? 0,
         isActive: this.formConditional.isActive.value
      };

      //@ts-ignore
      if (this.conditionId.guid == new Guid(Guid.getEmpty())) {
         this._analyteConditionalService
            .post(obj)
            .then((res: any) => {
               this._toastService.fireSuccess(
                  "Condição cadastrada com sucesso!"
               );
               this.resetConditionalForm();
               this.getConditions();
               this._dialog.closeAll();
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });
      } else {
         this._analyteConditionalService
            .put(obj, this.conditionId)
            .then((res: any) => {
               this._toastService.fireSuccess(
                  "Condição atualizada com sucesso!"
               );
               this.resetConditionalForm();
               this.getConditions();
               this._dialog.closeAll();
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });
      }
   }

   checkType() {
      if (
         this.AllAttributes.filter(
            (x) => x.value == this.formConditional.atributeAnalyteId.value
         )[0].typeAttribute == 0
      ) {
         this.conditionalNumeric = 1;
      } else {
         this.conditionalNumeric = 2;
      }
   }

   resolveConditionals(data: any, columns: any) {
      this.conditions = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.conditions.push(obj);
      });
   }

   resetConditionalForm() {
      this.formConditional.atributeAnalyteId.reset();
      this.formConditional.typeAnalisys.reset();
      this.formConditional.typeBlockedText.reset();
      this.formConditional.textAnalisys.reset();
      this.formConditional.typeBlockedNumericFrom.reset();
      this.formConditional.startNumber.reset();
      this.formConditional.typeBlockedNumericTo.reset();
      this.formConditional.finishNumber.reset();
      this.formConditional.isActive.reset();
   }

   resolveConditionalParameters(data: any, columns: any, id: string) {
      this.conditionsText[id] = [];
      const conditionsText: Array<Object> = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         conditionsText.push(obj);
      });
      this.conditionsText[id] = conditionsText;
   }

   async getConditionalParameters(id: any, paramPage?: number[]) {
      if (paramPage) {
         this.pageConditionParameters = paramPage[0];
         this.indexConditionParameters = paramPage[1];
      }
      this.conditionsText[id] = [];
      await this._analyteConditionalParameters
         .getPagination({
            page: this.pageConditionParameters,
            index: this.indexConditionParameters,
            analyteConditionalReleaseId: id
         })
         .then((res: any) => {
            if (res.data.length != 0) {
               this.totalConditionParameters = res.sumRecords;
               this.resolveConditionalParameters(
                  res.data,
                  resolveConditionalParameter,
                  id
               );
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async addConditionalParameter(obj: ConditionalParameter, id: string = "") {
      this.resetConditionalParametersForm();

      obj._hide_edit = id;

      if (id != "") {
         await this._analyteConditionalParameters
            .getById(new Guid(id))
            .then((res: any) => {
               if (res.data.length != 0) {
                  this.formConditionalParameters.parameter.setValue(
                     res.data[0].resultText
                  );
               }
            });
      }
      const myTempDialog = this._dialog.open(
         this.dialogModalConditionalParameters,
         {
            width: "660px",
            data: obj
         }
      );
   }

   async saveConditionalParameter(obj: ConditionalParameter) {
      if (obj._hide_edit != "") {
         const resultText = this.formConditionalParameters.parameter.value;

         await this._analyteConditionalParameters
            .put({ resultText }, new Guid(obj._hide_edit))
            .then((res: any) => {
               if (res.success) {
                  this._toastService.fireSuccess(
                     "analyteCondition.msgPutParameters"
                  );
                  this.getConditionalParameters(obj.id);
                  this.resetConditionalParametersForm();
                  this.close();
               }
            });
      } else {
         const postParameter = {
            analyteConditionalReleaseId: obj.id,
            analyteId: this.analyteId,
            atributeAnalyteId: obj._hide_atributeAnalyteId,
            resultText: this.formConditionalParameters.parameter.value
         };

         await this._analyteConditionalParameters
            .post(postParameter)
            .then((res: any) => {
               if (res.success) {
                  this.getConditionalParameters(obj.id);
                  this.resetConditionalParametersForm();
                  this._dialog.closeAll();
                  this._toastService.fireSuccess(
                     "analyteCondition.msgPostParameters"
                  );
               }
            });
      }
   }

   resetConditionalParametersForm() {
      this.formConditionalParameters.parameter.reset();
   }

   async removeAtributeReport(id: Guid) {
      await this._atributeReportService.delete(id).then(async (res: any) => {
         if (res.success) {
            await this.getAtributesReport(this.reportId);
            this._toastService.fireSuccess("reports.msgDeleteSuccessAtributo");
         }
      });
   }

   getStringReference(data: any) {
      const genderType = data[0].genderType;
      let stringReference = "";
      switch (genderType) {
         case 0: //Não Informado
            stringReference = "Não Informado:\n";
            break;
         case 1: //Feminino
            stringReference = "Feminino:\n";
            break;
         case 2: //Masculino
            stringReference = "Masculino:\n";
            break;
      }

      data.forEach((element: any) => {
         const line =
            "De " +
            element.ageMin +
            " " +
            this.typeAtributeReference[element.typeAgeMin].label +
            " até " +
            element.ageMax +
            " " +
            this.typeAtributeReference[element.typeAgeMax].label +
            ": Superior a " +
            element.valueMin +
            " e Inferior a " +
            element.valueMax +
            "\n";
         stringReference += line;
      });
      return stringReference;
   }

   async ActionsAtributeReport(
      emit: ActionsAtributeReport,
      atributeReportId: Guid
   ) {
      switch (emit.action) {
         case "Excluir":
            this._toastService
               .fireConfirmation("analyteAtributo.msgDelete")
               .then(async (res) => {
                  if (res.isConfirmed) {
                     await this._atributeReportService.delete(emit.object.id);
                     this._toastService.fireSuccess(
                        "analyteAtributo.msgDeleteSuccess"
                     );
                     await this.getAtributesReference(emit.object.id);
                     await this.getAtributesReport(this.reportId);
                  }
               })
               .catch((error) => {
                  this._toastService.fireError(error);
               });
            break;
         case "PutActiveAtributeReport":
            this._atributeReportService
               .patchActive(emit.object.id.toString(), emit.object.Ativo)
               .then(() => {
                  this._toastService.fireSuccess("global.msgSituationSuccess");
               });
            break;
      }
   }

   putActiveAtributeReport(id: Guid, value: string) {
      this._atributeReportService
         .patchActive(id.toString(), value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getAtributesReport(this.reportId);
         })
         .catch((err) => this._toastService.fireError(err));
   }

   async getUnitMeasure() {
      this.unitMeasure = [];
      await this._unitMeasureService
         .getPagination({ resume: true })
         .then((res: any) => {
            res.data.forEach((unitMeasure: any) => {
               this.unitMeasure.push({
                  value: unitMeasure.id,
                  label: unitMeasure.name
               });
            });
         });
   }

   addOptionList() {
      var options = {
         id: null,
         isActive: true,
         description: this.formAttribute.optionsList.value
            ? this.formAttribute.optionsList.value
            : this._toastService.warningMessage("analyteCondition.msgInfo")
      };
      if (this.formAttribute.optionsList.value != null)
         this.allOptionsList.push(options);
      this.resolveOptions(this.allOptionsList, resolveOptionsList);

      this.formAttribute.optionsList.reset();
   }

   async ActionsOptionsList(emit: ActionsOptionsList) {
      switch (emit.action) {
         case "Excluir":
            if (emit.object.id.toString() == "null") {
               var index = this.allOptionsList.findIndex(
                  (x: any) =>
                     x.id == emit.object.id &&
                     x.Description == emit.object.Valor
               );
               this.allOptionsList.splice(index, 1);
               this.resolveOptions(this.allOptionsList, resolveOptionsList);
            } else {
               await this._attributeOptionsList.delete(emit.object.id).then();
               await this._attributeOptionsList
                  .get("?atributeAnalyteId=" + this.attributeId)
                  .then((res: any) => {
                     if (res.data.length != 0) {
                        this.allOptionsList = res.data;
                        this.resolveOptions(
                           this.allOptionsList,
                           resolveOptionsList
                        );
                     }
                  });
            }
            break;
         case "PutActiveDataType":
            this.putActiveDataType(
               emit.object.id.toString(),
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
      }
   }

   async putActiveDataType(id: string, value: string) {
      await this._attributeOptionsList.patchActive(id, value).then(async () => {
         await this.getParameters();
         this._toastService.fireSuccess("global.msgSituationSuccess");
      });
   }

   saveFormula() {
      var conditionTmp = "";
      if (this.formFormula.if.value == "") {
         switch (this.formFormula.typeResult.value) {
            case "1":
               this.formFormula.formula.setValue(
                  this.formFormula.else.value + ";"
               );
               break;

            case "2":
               this.formFormula.formula.setValue(
                  "*" + this.formFormula.else.value + "*;"
               );
               break;

            default:
               this.formFormula.formula.setValue(
                  "!" + this.formFormula.else.value + "!;"
               );
               break;
         }
      } else {
         if (this.formFormula.and.value != "") {
            if (this.formFormula.and.value.substring(0, 3) == "AND") {
               conditionTmp = "[" + this.formFormula.and.value + "]";
            } else {
               conditionTmp = "[AND " + this.formFormula.and.value + "]";
            }
         }

         var formula = "";

         if (this.formFormula.formula.value != null) {
            formula = "\n";
            var lines =
               (this.formFormula.formula.value.match(/\n/g) || []).length + 1;
            for (let i = 1; i <= lines; i++) {
               formula += "   ";
            }
         }

         if (this.formFormula.typeComparation.value == 1) {
            switch (this.formFormula.typeResult.value) {
               case "1":
                  formula +=
                     "SE " +
                     this.formFormula.if.value +
                     " " +
                     this.formFormula.conditional.value +
                     " " +
                     this.formFormula.value.value +
                     " " +
                     conditionTmp +
                     "; " +
                     this.formFormula.else.value +
                     ";";
                  break;

               case "2":
                  formula +=
                     "SE " +
                     this.formFormula.if.value +
                     " " +
                     this.formFormula.conditional.value +
                     " " +
                     this.formFormula.value.value +
                     " " +
                     conditionTmp +
                     "; *" +
                     this.formFormula.else.value +
                     "*;";
                  break;

               default:
                  formula +=
                     "SE " +
                     this.formFormula.if.value +
                     " " +
                     this.formFormula.conditional.value +
                     " " +
                     this.formFormula.value.value +
                     " " +
                     conditionTmp +
                     '; "' +
                     this.formFormula.else.value +
                     '";';
                  break;
            }
         } else {
            switch (this.formFormula.typeResult.value) {
               case "1":
                  formula +=
                     "SE " +
                     this.formFormula.if.value +
                     " " +
                     this.formFormula.conditional.value +
                     " " +
                     this.formFormula.value.value +
                     " " +
                     conditionTmp +
                     "; " +
                     this.formFormula.else.value +
                     ";";
                  break;

               case "2":
                  formula +=
                     "SE " +
                     this.formFormula.if.value +
                     " " +
                     this.formFormula.conditional.value +
                     ' "' +
                     this.formFormula.value.value +
                     '" ' +
                     conditionTmp +
                     "; *" +
                     this.formFormula.else.value +
                     "*;";
                  break;

               default:
                  formula +=
                     "SE " +
                     this.formFormula.if.value +
                     " " +
                     this.formFormula.conditional.value +
                     ' "' +
                     this.formFormula.value.value +
                     '" ' +
                     conditionTmp +
                     '; "' +
                     this.formFormula.else.value +
                     '";';
                  break;
            }
         }

         if (this.formFormula.formula.value != null) {
            this.formFormula.formula.setValue(
               this.formFormula.formula.value + formula
            );
         } else {
            this.formFormula.formula.setValue(formula);
         }
      }
   }

   clearFormula() {
      this.formFormula.formula.reset();
   }

   getDefaultMaterial(item: any) {
      return item.isDefaultMaterial
         ? '<span class="span-styles" style="background: #07BF56; color: #FFFFFF;">Sim</span>'
         : '<span class="span-styles" style="background: #EB5757; color: #FFFFFF;">Não</span>';
   }

   openDialogModalAtributeReport(parameterId: string) {
      this.parameterId = parameterId;
      if (this.attributes.length === 0) {
         this._toastService.warningMessage("reports.msgInfoAtributo");
      } else {
         const myTempDialog = this._dialog.open(
            this.dialogModalAdditionalParameter,
            {
               width: "800px"
            }
         );
         myTempDialog.afterClosed().subscribe(() => {
            this.parameterId = "";
            this.form.newAtribute.reset();
            this.atributeList = [];
         });
      }
   }

   actionsExamsAdditionalParameter(event: any) {
      switch (event.action) {
         case "Excluir":
            this.atributeList.splice(event.object.Atributo, 1);
            break;
      }
   }

   includeAtributeList() {
      if (
         this.formAdditionalParameter.controls["column"].value != null &&
         this.formAdditionalParameter.controls["lineAfter"].value != null &&
         this.formAdditionalParameter.controls["newAtribute"].value != null
      ) {
         const listObj = {
            Atributo: "",
            Coluna: this.formAdditionalParameter.controls["column"].value,
            "Linha após":
               this.formAdditionalParameter.controls["lineAfter"].value,
            id: this.formAdditionalParameter.controls["newAtribute"].value
         };
         this.newAtributes.forEach((x: any) => {
            if (
               x.value ===
               this.formAdditionalParameter.controls["newAtribute"].value
            )
               listObj.Atributo = x.label;
         });
         this.atributeList.push(listObj);
         this.formAdditionalParameter.reset();
      } else {
         this._toastService.warningMessage("reports.msgInfoAll");
      }
   }

   creatAtributeReport() {
      this.atributeList.forEach((x) => {
         const creatObj = {
            column: x.Coluna,
            lineAfter: x["Linha após"],
            atributeAnalyteId: x.id
         };
         this.atributeAnalytes.push(creatObj);
      });
      let creatAtributeReportObject = {
         reportId: this.parameterId,
         atributeAnalytes: this.atributeAnalytes
      };
      this._atributeReportService
         .post(creatAtributeReportObject)
         .then(() => {
            this._toastService.fireSuccess("analyteAtributo.msgPostParameters");
            this._dialog.closeAll();
            this.getAtributesReport(this.reportId);
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   clearFilter() {
      this.SupportLaboratoriesFilter.reset("");
   }

   async supportLaboratories(filter: boolean, paramPage?: number[]) {
      if (paramPage) {
         this.pageSupportLaboratories = paramPage[0];
         this.indexSupportLaboratories = paramPage[1];
         this.getParametersSupportLaboratories =
            this.getParametersSupportLaboratories;
      }
      this.allSupportLaboratories = [];
      if (filter) {
         await this._analyteService.getById(this.analyteId).then((res) => {
            res.data.forEach((register) => {
               let filter = register.examsSupportLaboratories.filter((filter) =>
                  filter.supportLaboratoryName
                     .toUpperCase()
                     .includes(
                        this.SupportLaboratoriesFilter.value
                           .toString()
                           .toUpperCase()
                     )
               );
               this.totalSupportLaboratories = filter.length;
               this.resolveSupportLaboratories(
                  filter,
                  resolveSupportLaboratories
               );
               if (filter.length === 0)
                  SwAlSetttings.warningMessage(
                     `Apoio de nome ${this.SupportLaboratoriesFilter.value} não encontrado.`
                  );
            });
         });
      } else {
         await this._analyteService.getById(this.analyteId).then((res) => {
            res.data.forEach((sup) => {
               this.totalSupportLaboratories =
                  sup.examsSupportLaboratories.length;
               this.resolveSupportLaboratories(
                  sup.examsSupportLaboratories,
                  resolveSupportLaboratories
               );
            });
         });
      }
   }

   resolveSupportLaboratories(data: any, columns: any) {
      this.allSupportLaboratories = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.allSupportLaboratories.push(obj);
      });
   }
}
