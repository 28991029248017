import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { QuestionsService } from '@app/services/questions/questions.service';
import { Guid } from '@app/util/guid';
import { GetListQuestions, Actions, resolveQuestions} from './questions.model';
import { DialogComponent } from '@app/components/form/dialog/dialog.component';
import { Util } from '@app/util/util';
import { ToastService } from '@app/services/translate/toast.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  orderBy: {sort: string, order: string} = {sort: '', order: 'asc'}

  total: number = 0;
  page: number = 1;
  index: number = 50;

  getParameters: string = (`?page=${this.page}&index=${this.index}`);

  questionId: string = ''

  @ViewChild(DialogComponent) dialog!: DialogComponent

  questions: Array<any> = [];

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  form = {
    questionName: new UntypedFormControl("", [Validators.required]),
    isActive: new UntypedFormControl(null),
    externalId: new UntypedFormControl(null),
    genderType: new UntypedFormControl(null),
  }

  genderType: Array<any> = [
    {
      label: 'questions.allSexes',
      value: null
    },
    {
      label: 'questions.feminine',
      value: '1'
    },
    {
      label: 'questions.masculine',
      value: '2'
    }
  ];

  formFilter = {
    name: new UntypedFormControl(null),
    isActive: new UntypedFormControl(null),
    externalId: new UntypedFormControl(null)    
  }

  constructor(
    private _questionsService: QuestionsService,
    private _pipeDate: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _util: Util,
    private _toastService: ToastService
  ) { }

  async ngOnInit() {
    await this.get();
  }

  async post(){
    let question = {
      name: this.form.questionName.value,
      externalId: this.form.externalId.value,
      genderType: this.form.genderType.value,
    }
    await this._questionsService.post(question).then(
      (res:any) => {
        if(res.sucess)
        this._toastService.fireSuccess('questions.msgPost')
          this.dialog.closeDialog()
          this.get();
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    );
  }

  async put(){
    let obj = {
      name: this.form.questionName.value,
      externalId: this.form.externalId.value,
      genderType: this.form.genderType.value,
    }
    await this._questionsService.put(obj, new Guid(this.questionId)).then(
      (res:any) => {
        if(res.sucess)
        this._toastService.fireSuccess('questions.msgPut')
          this.dialog.closeDialog()
          this.get();
        }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    );
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let Search: string = this.formFilter.name.value ? this.formFilter.name.value : null;
    let ExternalId: string = this.formFilter.externalId.value ? this.formFilter.externalId.value : null;
    let isActive: string = this.formFilter.isActive.value ? this.formFilter.isActive.value : null;
    let orderBy: string = this.orderBy.sort ? `${this.orderBy.sort},${this.orderBy.order}` : '';

    await this._questionsService.getPagination({page: this.page, index: this.index, isActive, Search, ExternalId, orderBy}).then(
      (res : any) => {
        if(res.data.lenght != 0){
          this.total = res.sumRecords;
          this.resolve(res.data, resolveQuestions);
        } else {
          this._toastService.warningMessage("questions.msgNotQuestions");
        }
      }
    )
  }

  resolve(data: any, columns: any) {
    this.questions = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.questions.push(obj);
    });
  }

  getCreated(item: GetListQuestions) {
    return this._pipeDate.transform(item.createdAt);
  }

  getUpdated(item: GetListQuestions) {
    return this._pipeDate.transform(item.updatedAt);
  }

  getActive(item: GetListQuestions) {
    return this._activePipe.transform(item.isActive)
  }

  getExternalId(item: GetListQuestions) {
    return item.externalId ?? ''
  }


  Actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.questionId = emit.object.id ?? ''
        this.dialog.openDialogModal(true, emit);
        this.form.questionName.setValue(emit.object['questions.name'])
        this.form.externalId.setValue(emit.object['questions.externalId'])
        this.form.genderType.setValue(emit.object._hide_genderTypeDescription)
        switch (emit.object._hide_genderTypeDescription) {
          case 'Todos os sexos':
            this.form.genderType.setValue(null)
            break;
          case 'Feminino':
            this.form.genderType.setValue(1)
            break;
          case 'Masculino':
            this.form.genderType.setValue(2)
            break;
          default:
            break;
        }
      break;
      case 'Excluir':
        this.delete(emit.object.id);
      break;  
      case 'PutActive':
        this._questionsService.patchActive(emit.object.id, emit.object.Ativo).then((res: any) => {
          if (res.success) {
           this._toastService.fireSuccess('global.msgSituationSuccess');
           this.get()
         }
      })
    }
  }  

  delete(id: string){
    this._toastService.fireConfirmation('questions.msgDelete').then(
      async (res:any) => {
      if(res.isConfirmed){
        await this._questionsService.delete(new Guid (id));
        this._toastService.fireSuccess('questions.msgDeleteSuccess')
        await this.get();
      }
    }).catch(
      (error) => {
        this._toastService.fireError(error);
    });
  }

  resetFilter(){
    this.formFilter.name.reset();
    this.formFilter.externalId.reset();
    this.formFilter.isActive.reset();
  }

  afterClose() {
    this.form.questionName.reset()
    this.form.externalId.reset()
    this.questionId = ''
  }
}
