import { inject, Injectable } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import { Guid } from "@app/util/guid";
import { GroupsService } from "../services/groups/groups.service";
import { RequestOptions } from "@app/modules_new/data/services/types/http.types";

@Injectable({
   providedIn: "root"
})
export class PermissionGroupsUsersFacade extends MultiObservableFacade {
   private _groupsService = inject(GroupsService);

   private controls = this.controlGroup({
      itens: this.emptyObservable(),
      itensAmount: this.emptyObservable()
   });

   createTabsGroup(id: string) {
      this.controls.add(id, {
         itens: this.createNullableObservableControl<any[]>(),
         itensAmount: this.createObservableControl<number>(0)
      });
   }

   get(id: string) {
      this._groupsService.getById<any>(new Guid(id)).subscribe({
         next: (response) => {
            this.controls.get(id).itens.subject.next(response.data[0].users);

            this.controls
               .get(id)
               .itensAmount.subject.next(response.data[0].users.length);
         },
         error: (err) => {
            this.controls.get(id).itens.subject.next([]);
         }
      });
      return {
         data$: this.controls.get(id).itens.observable$,
         total$: this.controls.get(id).itensAmount.observable$
      };
   }

   delete(id: string, request?: any) {
      return new Promise((resolve) => {
         this.ToastPopUp("Deseja realmente excluir este usuário?")
            .then((response) => {
               if (response.isConfirmed) {
                  //@ts-ignore
                  this._groupsService.delete(id + "/user", request).subscribe({
                     next: (res) => {
                        this.ToastSuccess("Usuário excluido com sucesso!");
                        resolve(res);
                     },
                     error: (error) => {
                        this.ToastErrorGeneric(
                           error,
                           "toast-msg.creation.error"
                        );
                     }
                  });
               }
            })
            .catch((error) => {
               this.ToastErrorGeneric(error, "toast-msg.change.error");
            });
      });
   }
}
