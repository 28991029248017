
class Notifications {
    notificationsAvailable: Array<NotificationMessage>
    amount: number
    constructor(amount: number = 0, notificationsAvailable: Array<NotificationMessage> = new Array()) {
        this.amount = amount
        this.notificationsAvailable = notificationsAvailable
    }
}

class NotificationMessage {
    id!: string 
    personId!: string
    module!: string
    notification!: string
    name!: string
    read!: boolean
    createdAt!: string
}

enum NotificationActionTypes {
    Add = "ADD",
    Remove = "REMOVE",
    Clear = "CLEAR",
    AddAll = "ADD_ALL"
}

export { Notifications, NotificationMessage, NotificationActionTypes}