import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { ActivatedRoute } from '@angular/router';
import { RequisitionService } from '@app/services/requisitions/requisition.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { GetViewTraceability, Actions } from './view-traceability.model';

@Component({
  selector: 'app-view-traceability',
  templateUrl: './view-traceability.component.html',
  styleUrls: ['./view-traceability.component.scss']
})
export class ViewTraceabilityComponent implements OnInit {
  total: number = 0;
  number: number = 0;
  client: string = "";
  page: number = 1;
  index: number = 50;
  registerRequests: Array<any> = [];
  itensService: Array<any> = [];
  itensTechnicalSector: Array<any> = [];
  itensEmail: Array<any> = [];
  itensWeb: Array<any> = [];
  itensAgreement: Array<any> = [];
  AllregisterRequests: Array<any> = [];
  cardsRequest: Array<any> = [];
  cardsExam: Array<any> = [];
  cardsFinancial: Array<any> = [];
  cardsTag: Array<any> = [];
  labelsExa : Array<string> = [];
  labelsReq : Array<string> = [];
  valuesExa : Array<number> = [];
  valuesReq : Array<number> = [];
  valuesBarChart : Array<number> = [];
  backgroundColorExa : Array<string> = [];
  backgroundColorReq : Array<string> = [];
  createdAt: string = "";
  updatedAt: string = "";
  unityDescription: string = "";
  tags: boolean = false;
  outsourcedTags: boolean = false;
  dashboardCheckBox = new UntypedFormControl(false, [Validators.required]);

  @ViewChild('dialogModalTraceability')
  dialogModalTraceability!: TemplateRef<any>
  @ViewChild('dialogModalTraceabilityExam')
  dialogModalTraceabilityExam!: TemplateRef<any>

  columnsDashboard: Array<any> = [
    {
      isTitulo: true,
      titulo: 'Não Coletado',
      contador: 0,
      cor: 'black',
      cardStatus: 'mostrar',
      type: 0,
      cards: [
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        }
      ]
    },
    {
      isTitulo: true,
      titulo: 'Aguardando coleta',
      contador: 0,
      cor: 'rgb(235, 87, 87)',
      cardStatus: 'mostrar',
      type: 1,
      cards: [
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'GLICOSE',
          date:'12/12/1996',
          name:"Gabriel"
        }
      ]
    },
    {
      isTitulo: true,
      titulo: 'Coletado',
      contador: 0,
      cor: 'rgb(255, 192, 0)',
      cardStatus: 'mostrar',
      type: 2,
      cards: [
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'TRIGLICERIDIOS',
          date:'12/12/1996',
          name:"Gabriel"
        }
      ]
    },
    {
      isTitulo: true,
      titulo: 'Triado',
      contador: 0,
      cor: 'rgb(7, 191, 180)',
      cardStatus: 'mostrar',
      type: 3,
      cards: [
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        }
      ]
    },
    {
      isTitulo: true,
      titulo: 'Digitado',
      contador: 0,
      cor: '#B63399',
      cardStatus: 'mostrar',
      type: 4,
      cards: [
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO CALCIFICADO',
          date:'12/12/1996',
          name:"Gabriel"
        }
      ]
    },
    {
      isTitulo: true,
      titulo: 'Conferido',
      contador: 0,
      cor: '#B63399',
      cardStatus: 'mostrar',
      type: 5,
      cards: [
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        }
      ]
    },
    {
      isTitulo: true,
      titulo: 'Liberado',
      contador: 0,
      cor: '#B63399',
      cardStatus: 'mostrar',
      type: 6,
      cards: [
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        }
      ]
    },
    {
      isTitulo: true,
      titulo: 'Visualizado',
      contador: 0,
      cor: '#B63399',
      cardStatus: 'mostrar',
      type: 7,
      cards: [
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        }
      ]
    },
    {
      isTitulo: true,
      titulo: 'Impresso',
      contador: 0,
      cor: '#B63399',
      cardStatus: 'mostrar',
      type: 8,
      cards: [
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        }
      ]
    },
    {
      isTitulo: true,
      titulo: 'Entregue',
      contador: 0,
      cor: '#B63399',
      cardStatus: 'mostrar',
      type: 9,
      cards: [
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        },
        {
          header:'CALCIO INOCICOCICO ',
          date:'12/12/1996',
          name:"Gabriel"
        }
      ]
    }
  ];

  ActionsExtra = {
    "Rastreabilidade": 'traceability'
  };


  examSituation = {
    0: "<span class='span-styles' style='background: #F8F8FF;color: #000000;'>Não Coletado</span>",
    1: "<span class='span-styles' style='background: #F8F8FF;color: #FF0000;'>Aguardando Coleta</span>",
    2: "<span class='span-styles' style='background: #F8F8FF;color: #FF0000;'>Coletado Parcial</span>",
    3: "<span class='span-styles' style='background: #FF0000;color: #F8F8FF;'>Coletado</span>",
    4: "<span class='span-styles' style='background: #363636;color: #F8F8FF;'>Solic. Recoleta</span>",
    5: "<span class='span-styles' style='background: #FFFF00;color: #000000;'>Recoletado</span>",
    6: "<span class='span-styles' style='background: #FFFF00;color: #000000;'>Triado</span>",
    7: "<span class='span-styles' style='background: #F8F8FF;color: #00FFFF;'>Digitado Parcial</span>",
    8: "<span class='span-styles' style='background: #F8F8FF;color: #00FFFF;'>Digitado</span>",
    9: "<span class='span-styles' style='background: #00FFFF;color: #000000;'>Conferido</span>",
    10: "<span class='span-styles' style='background: #F8F8FF;color: #00FF00;'>Liberado</span>",
    11: "<span class='span-styles' style='background: #F8F8FF;color: #0000FF;'>Entregue</span>",
    12: "<span class='span-styles' style='background: #F8F8FF;color: #00008B;'>Impresso</span>",
    20: "<span class='span-styles' style='background: #0000FF;color: #C0C0C0;'>Suspenso de Fatura</span>",
    21: "<span class='span-styles' style='background: #00FF00;color: #F8F8FF;'>Exclusivo de Fatura</span>",
    30: "<span class='span-styles' style='background: #F8F8FF;color: #C0C0C0;'>Cancelado</span>",
    91: "<span class='span-styles' style='background: #F8F8FF;color: #C0C0C0;'>Em Coleta</span>",
  }

  resolveAgreementRequest: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: 'id',
      after: '',
      before: '',
    },
    {
      label: "Convênio",
      method: '',
      retrive: 'description',
      after: '',
      before: '',
    },
    {
      label: "Solicitante",
      method: '',
      retrive: "name",
      after: '',
      before: '',
    },
    {
      label: "Conselho",
      method: 'getRegister',
      retrive: "",
      after: '',
      before: '',
    },
    {
      label: "Autorização",
      method: 'getAuthorization',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "Carteirinha",
      method: 'getCardNumber',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "CID",
      method: 'getCID',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "Guia",
      method: 'getCode',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "_hide_agreementId",
      method: '',
      retrive: 'agreementId',
      after: '',
      before: '',
    },
    {
      label: 'Rastreabilidade',
      retrive: '',
      method: 'getTraceability',
      after: '',
      before: ''
    }
  ];

  resolveExams: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "_hide_materialId",
      method: '',
      retrive: "materialId",
      after: '',
      before: '',
    },
    {
      label: "Mnemônico",
      method: '',
      retrive: "code",
      after: '',
      before: '',
    },
    {
      label: "Descrição do Exame",
      method: '',
      retrive: "name",
      after: '',
      before: '',
    },
    {
      label: "Material",
      method: '',
      retrive: "description",
      after: '',
      before: '',
    },
    {
      label: "Urgente",
      method: "getUrgentSituation",
      retrive: "",
      after: '',
      before: '',
    },
    {
      label: "Previsão de Entrega",
      method: "generateDeadLine",
      retrive: "",
      after: '',
      before: '',
    },
    {
      label: "Situação",
      method: "convertExamSituation",
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: 'Rastreabilidade',
      retrive: '',
      method: 'getTraceability',
      after: '',
      before: ''
    }
  ];

  resolveServices: Array<Object> = [
    {
      label: "Ação",
      method: '',
      retrive: "action",
      after: '',
      before: '',
    },
    {
      label: "Data",
      method: '',
      retrive: "date",
      after: '',
      before: '',
    },
    {
      label: "Usuário",
      method: "",
      retrive: "user",
      after: '',
      before: '',
    }
  ];

  resolveEmails: Array<Object> = [
    {
      label: "Tipo",
      method: '',
      retrive: "type",
      after: '',
      before: '',
    },
    {
      label: "Envio",
      method: '',
      retrive: "date",
      after: '',
      before: '',
    },
    {
      label: "Visualização",
      method: '',
      retrive: "viewDate",
      after: '',
      before: '',
    },
    {
      label: "Usuário",
      method: "",
      retrive: "user",
      after: '',
      before: '',
    }
  ];

  resolveWebs: Array<Object> = [
    {
      label: "Tipo",
      method: '',
      retrive: "type",
      after: '',
      before: '',
    },
    {
      label: "Visualização",
      method: '',
      retrive: "date",
      after: '',
      before: '',
    }
  ];

  resolveAgreements: Array<Object> = [
    {
      label: "Lote de Transporte",
      method: '',
      retrive: "transportLot",
      after: '',
      before: '',
    },
    {
      label: "Envio",
      method: '',
      retrive: "sendDate",
      after: '',
      before: '',
    },
    {
      label: "Recebimento",
      method: '',
      retrive: "receiveDate",
      after: '',
      before: '',
    },
    {
      label: "Usuário Recebimento",
      method: '',
      retrive: "userReceive",
      after: '',
      before: '',
    },
    {
      label: "Etiqueta Auxiliar",
      method: '',
      retrive: "tagAux",
      after: '',
      before: '',
    }
  ];

  constructor(
    private _requisitionService: RequisitionService, 
    private _route: ActivatedRoute, 
    private _dialog: MatDialog) { }

  async tests(){
    let outsourced = {
      type:"Lote Enviado",
      changeTitle: 'Enviado em: 12/02/2023 08:00',
      changeBefore: 'Gabriel',
    }
    this.cardsTag.push(outsourced);
    outsourced = {
      type:"Remessa",
      changeTitle: '12/02/2023 18:00',
      changeBefore: 'Gabriel',
    }
    this.cardsTag.push(outsourced);


    let cardFinancial = {
      type : 'Pré-Conferência',
      changeTitle : '23/05/2023 08:00',
      changeBefore: 'Gabriel',
      changeAfter: ''
    }
    this.cardsFinancial.push(cardFinancial);
    cardFinancial = {
      type : 'Lote Envio',
      changeTitle : '321564987',
      changeBefore: 'Env: 321321321',
      changeAfter: 'Rec: 321321321'
    }
    this.cardsFinancial.push(cardFinancial);
    cardFinancial = {
      type : 'Conferido',
      changeTitle : '12/12/2023 12:00',
      changeBefore: 'Gabriel',
      changeAfter: ''
    }
    this.cardsFinancial.push(cardFinancial);
    cardFinancial = {
      type : 'Integrado',
      changeTitle : '12/12/2023 12:00',
      changeBefore: 'Gabriel',
      changeAfter: ''
    }
    this.cardsFinancial.push(cardFinancial);

    let card = {
      type : 'Inclusão',
      changeTitle : 'Pedido Médico',
      changeBefore: '',
      changeAfter: '',
      date : '23/05/2023 08:00',
      name : 'Gabriel'
    }
    this.cardsRequest.push(card);
    card = {
      type : 'Alteração',
      changeTitle : 'Médico',
      changeBefore: 'De "Dr Bene Benedito"',
      changeAfter: 'Para "Dr Gab Gab"',
      date : '23/05/2023 08:05',
      name : 'Gabriel'
    }
    this.cardsRequest.push(card);
    card = {
      type : 'Alteração',
      changeTitle : 'Convênio',
      changeBefore: 'De "Convenio1"',
      changeAfter: 'Para "Convenio2"',
      date : '23/05/2023 08:05',
      name : 'Gabriel'
    }
    this.cardsRequest.push(card);

    let card2 = {
      type : 'Inclusão',
      changeTitle : 'Exame',
      changeBefore: '',
      changeAfter: '',
      date : '23/05/2023 08:00',
      name : 'Gabriel'
    }
    this.cardsExam.push(card2);

    card2 = {
      type : 'Alteração',
      changeTitle : 'Material',
      changeBefore: 'De "Raspado ocular D"',
      changeAfter: 'Para "Plasma – citrato, pobre plaquetas"',
      date : '23/05/2023 08:00',
      name : 'Gabriel'
    }
    this.cardsExam.push(card2);

    card2 = {
      type : 'Alteração',
      changeTitle : 'Prazo de Entrega',
      changeBefore: 'De "24/05/2023 12:00"',
      changeAfter: 'Para "26/05/2023 12:00"',
      date : '23/05/2023 08:00',
      name : 'Gabriel'
    }
    this.cardsExam.push(card2);

    let testes = [];
    let obj = {
      action: 'Entrada',
      date: '01/01/2000 12:12',
      user: 'Gabriel'
    }
    testes.push(obj);
    obj = {
      action: 'Coleta',
      date: '01/01/2000 12:12',
      user: 'Gabriel'
    }
    testes.push(obj);
    obj = {
      action: 'Triagem',
      date: '01/01/2000 12:12',
      user: 'Gabriel'
    }
    testes.push(obj);
    obj = {
      action: 'Recoleta',
      date: '01/01/2000 12:12',
      user: 'Gabriel'
    }
    testes.push(obj);
    obj = {
      action: 'Impressão',
      date: '01/01/2000 12:12',
      user: 'Gabriel'
    }
    testes.push(obj);
    this.resolveServ(testes, this.resolveServices)

    let testes2 = [];
    let obj2 = {
      action: 'Recebimento',
      date: '01/01/2000 12:12',
      user: 'Gabriel'
    }
    testes2.push(obj2);
    obj2 = {
      action: 'Digitação',
      date: '01/01/2000 12:12',
      user: 'Gabriel'
    }
    testes2.push(obj2);
    obj2 = {
      action: 'Conferencia',
      date: '01/01/2000 12:12',
      user: 'Gabriel'
    }
    testes2.push(obj2);
    obj2 = {
      action: 'Liberação',
      date: '01/01/2000 12:12',
      user: 'Gabriel'
    }
    testes2.push(obj2);
    this.resolveTechnicalSector(testes2, this.resolveServices)

    let testes3 = [];
    let obj3 = {
      type: 'Cliente',
      date: '01/01/2000 12:12',
      viewDate : '01/01/2000 21:21',
      user: 'Gabriel'
    }
    testes3.push(obj3);
    obj3 = {
      type: 'Solicitante',
      date: '01/01/2000 12:12',
      viewDate : '01/01/2000 21:21',
      user: 'Gabriel'
    }
    testes3.push(obj3);
    obj3 = {
      type: 'Convênio',
      date: '01/01/2000 12:12',
      viewDate : '01/01/2000 21:21',
      user: 'Gabriel'
    }
    testes3.push(obj3);

    this.resolveEmail(testes3, this.resolveEmails)

    let testes4 = [];
    let obj4 = {
      type: 'Cliente',
      date: '01/01/2000 12:12'
    }
    testes4.push(obj4);
    obj4 = {
      type: 'Solicitante',
      date: '01/01/2000 12:12'
    }
    testes4.push(obj4);
    obj4 = {
      type: 'Convênio',
      date: '01/01/2000 12:12'
    }
    testes4.push(obj4);
    obj4 = {
      type: 'Unidade',
      date: '01/01/2000 12:12'
    }
    testes4.push(obj4);
    obj4 = {
      type: 'Plano',
      date: '01/01/2000 12:12'
    }
    testes4.push(obj4);
    obj4 = {
      type: 'Destino de Entrega',
      date: '01/01/2000 12:12'
    }
    testes4.push(obj4);
    obj4 = {
      type: 'Local de Coleta',
      date: '01/01/2000 12:12'
    }
    testes4.push(obj4);

    this.resolveWeb(testes4, this.resolveWebs)

    let testes5 = [];
    let obj5 = {
      transportLot: '123456798',
      sendDate: '12/12/2023 12:12',
      receiveDate: '12/12/2023 12:12',
      userReceive: 'Gabriel',
      tagAux: '112231322133'
    }
    testes5.push(obj5);

    this.resolveAgreement(testes5, this.resolveAgreements);
  }

  dashboardData(){
    this.labelsExa = [
      'Não Coletado',
      'Aguardando Coleta',
      'Coletado',
      'Triado',
      'Digitado',
      'Conferido',
      'Liberado',
      'Visualizado',
      'Impresso',
      'Entregue'
    ];

    this.labelsReq = [
      'Concluido',
      'Não Concluido'
    ];

    this.backgroundColorExa = [
      '#000000',
      '#bfbfbf',
      '#CB1B18',
      '#FFC000',
      '#33FFF9',
      '#9B8056',
      '#07bf56',
      '#FF6344',
      '#7346A5',
      '#006E9D'
    ];

    this.backgroundColorReq = [
      '#FF6344',
      '#bfbfbf'
    ];

    this.valuesExa = [1,2,3,4,5,6,7,8,9,10];

    this.valuesReq = [49.99,50.01];

    this.valuesBarChart = [80,120,150,110,160,90,170,200,50,200];
  }

  async ngOnInit() {
    this.dashboardData();
    await this.getRequisitionById(this._route.snapshot.params['id']);
    await this.tests();//GAB //REMOVER
  }

  async getRequisitionById(id: string) {
    this.number = 0;
    this.client = "";
    await this._requisitionService.getById(new Guid(id)).then(
      async (res) => {
        const requisition = res.data[0];
        this.number = requisition.number;
        this.client = requisition.name;
        this.createdAt = new Date(requisition.createdAt).toLocaleDateString();
        this.updatedAt = new Date(requisition.updatedAt).toLocaleDateString();
        this.unityDescription = requisition.unityDescription;
        this.AllregisterRequests = requisition.agreementRequest;
        this.resolve(this.AllregisterRequests, this.resolveAgreementRequest);
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.registerRequests = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerRequests.push(obj);
    });
  }

  resolveServ(data: any, columns: any) {
    this.itensService = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.itensService.push(obj);
    });
  }

  resolveTechnicalSector(data: any, columns: any) {
    this.itensTechnicalSector = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.itensTechnicalSector.push(obj);
    });
  }

  resolveEmail(data: any, columns: any) {
    this.itensEmail = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.itensEmail.push(obj);
    });
  }

  resolveWeb(data: any, columns: any) {
    this.itensWeb = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.itensWeb.push(obj);
    });
  }

  resolveAgreement(data: any, columns: any) {
    this.itensAgreement = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.itensAgreement.push(obj);
    });
  }

  getRegister(item: GetViewTraceability) {
    return `${item.council}/${item.registrationState}: ${item.registrationNumber}`;
  }

  getAuthorization(item: GetViewTraceability) {
    return item.authorization ? item.authorization : "";
  }

  getCardNumber(item: GetViewTraceability) {
    return item.cardNumber ? item.cardNumber : "";
  }

  getCID(item: GetViewTraceability) {
    return item.cid ? item.cid : "";
  }

  getCode(item: GetViewTraceability) {
    return item.code ? item.code : "";
  }

  getExamsAgreement(id: string) {
    let exams: any = [];
    this.AllregisterRequests.forEach((x: any) => {
      if (id === x.id) {
        exams.push(x.agreementsExamsRequest)
      }
    });
    return this.resolveExamsColumns(exams[0], this.resolveExams);
  }

  resolveExamsColumns(data: any, columns: any) {
    let registerExams: any = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      registerExams.push(obj);
    });

    return registerExams;
  }

  getUrgentSituation(item: GetViewTraceability) {
    let situation: boolean = item.isUrgent;
    return situation ? "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Sim</span>" : "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Não</span>";
  }

  generateDeadLine(item: GetViewTraceability) {
    let deadline = new Date(item.analyteDeadline).toLocaleString();
    if (item.isUrgent) {
      return "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>"+deadline+"</span>"
    } else {
      return deadline;
    }
  }

  convertExamSituation(item: any) {
    //@ts-ignore
    return item.status ? this.examSituation[item.status] : this.examSituation[0];
  }

  myTabSelectedTabChange(changeEvent: MatTabChangeEvent){

  }

  async Actions(action: Actions) {
    switch (action.action) {
      case 'traceability':
        const myTempDialog = this._dialog.open(this.dialogModalTraceability, {
          width: '800px'
        });
        break;
    }
  }

  async ActionsExam(action: Actions) {
    switch (action.action) {
      case 'traceability':
        const myTempDialog = this._dialog.open(this.dialogModalTraceabilityExam, {
          width: '800px'
        });
        break;
    }
  }

  getTraceability() {
    return "<div style='width: 100%; display: flex; justify-content: center'><svg width=\"37\" height=\"31\" viewBox=\"0 0 37 31\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0.00146484 3.47402C0.00146484 1.63307 1.49385 0.140686 3.3348 0.140686H33.3348C35.1757 0.140686 36.6681 1.63307 36.6681 3.47402V26.8074C36.6681 28.6483 35.1757 30.1407 33.3348 30.1407H3.3348C1.49385 30.1407 0.00146484 28.6483 0.00146484 26.8074V3.47402ZM33.3348 3.47402H3.3348V26.8074H33.3348V3.47402ZM18.3348 6.80735C19.2553 6.80735 20.0015 7.55354 20.0015 8.47402V21.8074C20.0015 22.7278 19.2553 23.474 18.3348 23.474C17.4143 23.474 16.6681 22.7278 16.6681 21.8074V8.47402C16.6681 7.55354 17.4143 6.80735 18.3348 6.80735ZM11.6681 10.1407C12.5886 10.1407 13.3348 10.8869 13.3348 11.8074V21.8074C13.3348 22.7278 12.5886 23.474 11.6681 23.474C10.7477 23.474 10.0015 22.7278 10.0015 21.8074V11.8074C10.0015 10.8869 10.7477 10.1407 11.6681 10.1407ZM25.0015 14.3074C25.9219 14.3074 26.6681 15.0535 26.6681 15.974V21.8074C26.6681 22.7278 25.9219 23.474 25.0015 23.474C24.081 23.474 23.3348 22.7278 23.3348 21.8074V15.974C23.3348 15.0535 24.081 14.3074 25.0015 14.3074Z\" fill=\"#808080\"/></svg></div>"
  }

  close() {
    this._dialog.closeAll()
  }

  openTags(){
    this.tags = !this.tags;
  }

  openoutSourcedTags(){
    this.outsourcedTags = !this.outsourcedTags;
  }
}
