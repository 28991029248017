import { Component } from '@angular/core';

@Component({
  selector: 'ui-loading',
  template: ` <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>`,
  styleUrls: ['./loading.component.scss'],
  standalone: true,
})
export class LoadingUIComponent {}
