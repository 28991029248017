import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ButtonGroupOption } from "./button-group.model";
import { NgClass, NgForOf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "forms-button-group[options]",
   templateUrl: "button-group.component.html",
   styleUrls: ["button-group.component.scss"],
   standalone: true,
   imports: [NgClass, NgForOf, TranslateModule]
})
export class ButtonGroupFormComponent implements OnInit {
   @Input() options!: ButtonGroupOption[];
   @Input() form!: FormControl;
   @Output() selectOption = new EventEmitter<number | string>();
   selectedValue!: number | string;

   ngOnInit(): void {
      if (this.form) {
         this.selectedValue = this.form.value;
      }
   }

   changeValue(value: number | string) {
      this.selectedValue = value;
      this.form.setValue(value);
      this.selectOption.emit(value);
   }
}
