import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UntypedFormControl } from "@angular/forms";
import { SwAlSetttings } from "src/app/util/swal.settings";
import { FileDocumentAuthService } from "../../../services/auth/fileDocument-auth.service";
import { FileDocumentRecordService } from "@app/services/documents/file-document.service";
import { UsersPublicService } from "@app/modules_new/admin/data/services/auth-users.service";
import { lastValueFrom } from "rxjs";
import decodedToken from "@app/util/Token";
import { RequestOptions } from "@app/modules_new/data/services/types/http.types";

@Component({
   selector: "file-prop-image",
   templateUrl: "./file-prop.component.html",
   styleUrls: ["./file-prop.component.scss"]
})
export class FilePropImageComponent implements OnInit {
   constructor(
      private _fileDocumentService: FileDocumentAuthService,
      private _sanitizer: DomSanitizer,
      private _fileDocumentRecordService: FileDocumentRecordService,
      private _usersPublicService: UsersPublicService
   ) {}
   imagePath: any = null;

   //@ts-ignore
   fileUpload;
   //@ts-ignore
   @Input() form: UntypedFormControl;
   //@ts-ignore
   @Input() formName: UntypedFormControl;
   //@ts-ignore
   fileInit = true;

   @Input() readonly: boolean = false;

   ngOnInit(): void {
      if (this.form.value) {
         this.getUrlImage();
      }
   }

   @ViewChild("fileDropRef", { static: false }) fileDropEl:
      | ElementRef
      | undefined;
   files: any[] = [];

   /**
    * on file drop handler
    */
   //@ts-ignore
   onFileDropped($event) {
      this.prepareFilesList($event);
   }

   /**
    * handle file from browsing
    */
   //@ts-ignore
   fileBrowseHandler(event: ChangeEvent) {
      this.prepareFilesList(event.target.files);
   }

   /**
    * Delete file from files list
    * @param index (File index)
    */
   deleteFile(index: number) {
      if (this.files[index].progress < 100) {
         return;
      }
      this.files.splice(index, 1);
   }

   /**
    * Simulate the upload process
    */
   uploadFilesSimulator(index: number) {
      setTimeout(() => {
         if (index === this.files.length) {
            return;
         } else {
            const progressInterval = setInterval(() => {
               if (this.files[index].progress === 100) {
                  clearInterval(progressInterval);
                  this.uploadFilesSimulator(index + 1);
               } else {
                  this.files[index].progress += 5;
               }
            }, 200);
         }
      }, 1000);
   }

   /**
    * Convert Files list to normal array list
    * @param files (Files List)
    */
   prepareFilesList(files: Array<any>) {
      SwAlSetttings.loader();
      this.fileInit = false;
      this.files = [];
      for (const item of files) {
         const reader = new FileReader();
         reader.readAsDataURL(item);
         reader.onload = () => {
            //@ts-ignore
            let base64 = reader.result.toString();
            base64 = base64.substring(base64.lastIndexOf(",") + 1);
            const fileUpload = {
               base64: base64,
               name: item.name,
               isImage: true
            };

            this.upload(fileUpload);
         };
         this.files.push(item);
      }
      //@ts-ignore
      this.fileDropEl.nativeElement.value = "";
      this.uploadFilesSimulator(0);
   }

   /**
    * format bytes
    * @param bytes (File size in bytes)
    * @param decimals (Decimals point)
    */
   //@ts-ignore
   formatBytes(bytes, decimals = 2) {
      if (bytes === 0) {
         return "0 Bytes";
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
   }

   onChange(value: String) {
      this.form.setValue(value);
   }

   upload(fileUpload: { base64: string; name: any; isImage: boolean }): void {
      if (localStorage.getItem("token")) {
         //@ts-ignore
         this._fileDocumentService
            .post(fileUpload)
            .then((res) => {
               //@ts-ignore
               this.form.setValue(res.id);
               this.getUrlImage();
            })
            .catch((err) => {
               SwAlSetttings.printError(err);
               SwAlSetttings.closeLoader();
            });
      } else {
         //@ts-ignore
         this._fileDocumentRecordService
            .post(fileUpload)
            .then((res) => {
               //@ts-ignore
               this.form.setValue(res.id);
               this.getUrlImage();
            })
            .catch((err) => {
               SwAlSetttings.printError(err);
               SwAlSetttings.closeLoader();
            });
      }
   }

   async getUrlImage() {
      if (localStorage.getItem("token")) {
         const request: RequestOptions = {
            path: "/" + decodedToken.sub + "/image"
         };

         const image: any = await lastValueFrom(
            this._usersPublicService.get(request)
         );
         //@ts-ignore
         if (image.data.length > 0) {
            //@ts-ignore
            localStorage.setItem("userImage", image.data[0].url);
            this.imagePath = image.data[0].url;
            SwAlSetttings.Sucesso("Imagem alterada com sucesso!");
         }
         SwAlSetttings.closeLoader();
      } else {
         this._fileDocumentRecordService
            .getById(this.form.value)
            .then((x: any) => {
               this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(
                  "data:image/jpg;base64," + x.data[0].fileBase64
               );
               SwAlSetttings.closeLoader();
            });
      }
   }
}
