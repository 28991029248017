<div class="contentPage">
  <div class="flex flex-between align-center headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
      <h1>Relatório de Materiais</h1>
      <app-button (click)="generatePdf()" [placeholder]="'Gerar Relatório'" [matIcon]="'insert_drive_file'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="getMaterials()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus [placeholder]="'Pesquisar por Descrição'" [form]="filter.controls['search']"></input-prop>
        <select-prop [placeholder]="'Situação'" [form]="filter.controls['isActive']" [options]="isActiveOptions"></select-prop>
      </div>
      <app-button class="queryBtn" (click)="getMaterials()" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [indexNumber]="index" [pageNumber]="page" [Itens]="materials" (changePage)="getMaterials($event)"></app-table>
  </div>
</div>