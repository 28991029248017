import { SwAlSetttings } from '@app/util/swal.settings';
import { UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EquipmentsInterfacingService } from '@app/services/equipments/equipments.service';
import { ActivatedRoute } from '@angular/router';
import { Guid } from '@app/util/guid';
import { MatDialog } from '@angular/material/dialog';
import { MaterialService } from '@app/services/materials/material.service';
import { EquipmentsMaterialService } from '@app/services/equipments/equipmentsMaterial.service';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { EquipmentsTextService } from '@app/services/equipments/equipmentsText.service';
import { Actions, ActionsExams, ActionsRecipients, ActionsText, GetRecipients, ObjectActions } from '@app/services/equipments/equipments.types';
import { AnalyteService } from '@app/services/analyte/analyte.service';
import { EquipmentsExamService } from '@app/services/equipments/equipmentsExam.service';
import { RecipientsService } from '@app/services/recipients/recipients.service';
import { EquipmentsRecipientsService } from '@app/services/equipments/equipmentsRecipient.service';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';


@Component({
  selector: 'app-save-equipment',
  templateUrl: './save-equipment.component.html',
  styleUrls: ['./save-equipment.component.scss']
})
export class SaveEquipmentComponent implements OnInit {

  total: number = 0;
  page: number = 1;
  index: number = 50;

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  @ViewChild('dialogModalText')
  dialogModalText!: TemplateRef<any>

  @ViewChild('dialogModalExam')
  dialogModalExam!: TemplateRef<any>

  @ViewChild('dialogModalRecipients')
  dialogModalRecipients!: TemplateRef<any>

  equipment = {
    code: new UntypedFormControl(),
    name: new UntypedFormControl(),
    active: new UntypedFormControl()
  }

  exam = {
    analyte: new UntypedFormControl(),
    analyteEnabled: new UntypedFormControl(false),
    active: new UntypedFormControl(false),
    analyteBlockedValidate: new UntypedFormControl(false)
  }

  recipient = {
    name: new UntypedFormControl(),
    active: new UntypedFormControl(false)
  }

  material = {
    code: new UntypedFormControl(),
    nameMaterialEquipment: new UntypedFormControl(),
    active: new UntypedFormControl(),
    materialId: new UntypedFormControl(),
    description: new UntypedFormControl()
  }

  text = {
    code: new UntypedFormControl(),
    text: new UntypedFormControl(),
    active: new UntypedFormControl(),
    translate: new UntypedFormControl()
  }

  comunication = {
    protocol: new UntypedFormControl(),
    typeComunication: new UntypedFormControl(),
    serialNumber: new UntypedFormControl(),
    bitSecond: new UntypedFormControl(),
    parity: new UntypedFormControl(),
    bitStop: new UntypedFormControl(),
    sizeBit: new UntypedFormControl(),
    ipAddress: new UntypedFormControl(),
    port: new UntypedFormControl(),
    sizeFrame: new UntypedFormControl(),
    timeBuffer: new UntypedFormControl(),
    sendWorkList: new UntypedFormControl(false),
    validateResultAutomatic: new UntypedFormControl(false),
    processImage: new UntypedFormControl(false),
  }

  allRecipients: Array<object> = []
  recipients: Array<object> = []

  examsEquipments: Array<object> = []
  materialsEquipments: Array<object> = []
  textsEquipments: Array<object> = []

  examsToFilter: Array<object> = []
  materialsToFilter: Array<object> = []
  textsToFilter: Array<object> = []

  examOptions: Array<Object> = [];

  materials: Array<object> = []

  typeProtocol: Array<object> = [
    {
      label: 'UriSed',
      value: 1
    },
    {
      label: 'AlinIQ',
      value: 2
    },
    {
      label: 'Alinity',
      value: 3
    },
    {
      label: 'Architect',
      value: 4
    },
    {
      label: 'AXSYM',
      value: 5
    },
    {
      label: 'CellDyn_1700',
      value: 6
    },
    {
      label: 'CellDyn_3000',
      value: 7
    },
    {
      label: 'CellDyn_3000_TXT',
      value: 8
    },
    {
      label: 'CellDyn_3200',
      value: 9
    },
    {
      label: 'CellDyn_3200_TXT',
      value: 10
    },
    {
      label: 'CellDyn_3500',
      value: 11
    },
    {
      label: 'CellDyn_3500_TXT',
      value: 12
    },
    {
      label: 'CellDyn_Ruby',
      value: 13
    },
    {
      label: 'NexGen',
      value: 14
    }
    ,
    {
      label: 'HBL',
      value: 15
    },
    {
      label: 'AlifaxTeste1',
      value: 16
    },
    {
      label: 'AUTOSoft',
      value: 17
    },
    {
      label: 'AutoMate2500',
      value: 18
    },
    {
      label: 'DXH',
      value: 19
    }
  ]

  typeComunication: Array<object> = [
    {
      value: 1,
      label: 'Serial (RS-232)'
    },
    {
      value: 2,
      label: "Tcp/Ip Client"
    },
    {
      value: 3,
      label: "Tcp/Ip Server"
    },
    {
      value: 4,
      label: "WebServer"
    },
    {
      value: 5,
      label: "Arquivos"
    }
  ]

  bitSeconds: Array<object> = [
    {
      value: '1200',
      label: '1200'
    },
    {
      value: '2400',
      label: '2400'
    },
    {
      value: '4800',
      label: '4800'
    },
    {
      value: '9600',
      label: '9600'
    },
    {
      value: '19200',
      label: '19200'
    },
    {
      value: '38400',
      label: '38400'
    },
    {
      value: '57600',
      label: '57600'
    },
    {
      value: '115200',
      label: '115200'
    }
  ]

  parity: Array<object> = [
    {
      value: 'P',
      label: 'Par'
    },
    {
      value: 'I',
      label: 'Impar'
    },
    {
      value: 'N',
      label: 'Nenhum'
    }
  ]

  bitSize: Array<object> = [
    {
      value: '5',
      label: '5'
    },
    {
      value: '6',
      label: '6'
    },
    {
      value: '7',
      label: '7'
    },
    {
      value: '8',
      label: '8'
    }
  ]

  bitStop: Array<object> = [
    {
      value: '1',
      label: '1'
    },
    {
      value: '1.5',
      label: '1.5'
    },
    {
      value: '2',
      label: '2'
    }
  ]

  resolveRecipientsToTable = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_recipientId',
      retrive: 'recipientId',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Recipiente',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Tipo de Recipiente',
      retrive: '', // recipientType	
      method: 'getTypeColor',
      after: '',
      before: '',
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getCreatedAtDate',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getUpdatedAtDate',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ]

  resolveExams = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_analyteId',
      retrive: 'analyteId',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Exame',
      retrive: '',
      method: 'getNameAnalyte',
      after: '',
      before: ''
    },
    {
      label: 'Bloqueado para liberação automática',
      retrive: '',
      method: 'getBlockedValidate',
      after: '',
      before: ''
    },
    {
      label: 'Habilitado',
      retrive: '',
      method: 'getEnabled',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getCreatedAtDate',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getUpdatedAtDate',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    },
    {
      label: '_hide_getNameAnalyte',
      retrive: 'analyteBlockedValidate',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_getBlockedValidate',
      retrive: 'analyteEnabled',
      method: '',
      after: '',
      before: ''
    },
  ]

  resolveMaterials = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_materialId',
      retrive: 'materialId',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Material no Equipamento',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Material',
      retrive: 'materialEquipment',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getCreatedAtDate',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getUpdatedAtDate',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ]

  resolveTexts = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Texto',
      retrive: 'textEquipment',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Tradução',
      retrive: 'textTranslated',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getCreatedAtDate',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getUpdatedAtDate',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ]

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  recipientPutId: string = '';
  recipientId: string = '';
  equipmentId: string = '';
  examId: string = '';
  materialId: string = '';
  textId: string = '';

  constructor(
    private _equipmentsRecipientsService: EquipmentsRecipientsService,
    private _recipientsService: RecipientsService,
    private _equipmentsExamService: EquipmentsExamService,
    private _analyteService: AnalyteService,
    private _equipmentsInterfacingService: EquipmentsInterfacingService,
    private _equipmentsTextService: EquipmentsTextService,
    private _equipmentsMaterialService: EquipmentsMaterialService,
    private _materialService: MaterialService,
    private _dialog: MatDialog,
    private _location: Location,
    private _route: ActivatedRoute,
    private _localeDatePipe: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe
  ) { }



  ngOnInit() {
    if (this._route.snapshot.params['id'] != 'Novo') {
      this.equipmentId = this._route.snapshot.params['id'];
      this.getByIdEquipment();
    }
  }

  back() {
    this._location.back();
  }

  closeDialog() {
    this._dialog.closeAll();
  }

  getCreatedAtDate(item: ObjectActions) {
    return this._localeDatePipe.transform(item.createdAt);
  }

  getUpdatedAtDate(item: ObjectActions) {
    return this._localeDatePipe.transform(item.updatedAt);
  }

  resolveAll(data: any, columns: any) {
    const arrayFiltered: Array<object> = []
    data.forEach((x: any) => {
      if (!x.isDeleted) {
        let obj: any = {};
        columns.forEach((y: any) => {
          if (!y.retrive) {
            //@ts-ignore
            obj[y.label] = y.before + this[y.method](x) + y.after;
          } else {
            obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
          }
        });
        arrayFiltered.push(obj);
      }
    });
    return arrayFiltered
  }

  myTabSelectedTabChange(changeEvent: MatTabChangeEvent) {
    if (changeEvent.index === 1 && this.materialsEquipments.length === 0) {
      this.resolveExamsEquipments(this.examsToFilter, this.resolveExams);
      if (this.examOptions.length === 0) {
        this.getAnalytes()
      }
    }

    if (changeEvent.index === 2 && this.materialsEquipments.length === 0) {
      this.resolve(this.materialsToFilter, this.resolveMaterials)
      if (this.materials.length === 0) {
        this.getMaterial()
      }
    }

    if (changeEvent.index === 3 && this.textsEquipments.length === 0) {
      this.resolveText(this.textsToFilter, this.resolveTexts)
    }
  }

  getActive(item: ObjectActions) {
    return this._activePipe.transform(item.isActive)
  }

  getTypeColor(item: GetRecipients) {
    const typeColor: Array<String> = [
      '<span class="span-styles" style="background:#EB5757; color:#FFF;">Coleta</span>',
      '<span class="span-styles" style="background:#FFC000; color:#FFF;">Triagem</span>',
      '<span class="span-styles" style="background:#006E9D; color:#FFF;">Lamina</span>',
      '<span class="span-styles" style="background:#07BF56; color:#FFF;">Seringa</span>'
    ]
    return typeColor[item.recipientType]
  }

  //#region Equipments

  getByIdEquipment(resolve?: boolean) {
    this._equipmentsInterfacingService.getById(new Guid(this.equipmentId)).then((x: any) => {
      let response = x.data[0];

      this.materialsToFilter = [];
      this.materialsToFilter = response.equipmentInterfaceMaterials;
      if (resolve && this.materialsToFilter.length > 0) {
        this.resolve(this.materialsToFilter, this.resolveMaterials)
      }

      this.textsToFilter = [];
      this.textsToFilter = response.equipmentInterfaceTexts;
      if (resolve && this.textsToFilter.length > 0) {
        this.resolveText(this.textsToFilter, this.resolveTexts)
      }

      this.examsToFilter = [];
      this.examsToFilter = response.equipmentInterfaceAnalytes;
      if (resolve && this.examsToFilter.length > 0) {
        this.resolveExamsEquipments(this.examsToFilter, this.resolveExams)
      }

      this.equipment.code.setValue(response.code);
      this.equipment.name.setValue(response.name);
      this.equipment.active.setValue(response.isActive);

      this.comunication.bitSecond.setValue(response.bitSecond);
      this.comunication.bitStop.setValue(response.bitStop)
      this.comunication.parity.setValue(response.parity)
      this.comunication.port.setValue(response.port)
      this.comunication.processImage.setValue(response.processImage)
      this.comunication.sendWorkList.setValue(response.sendWorkList)
      this.comunication.serialNumber.setValue(response.serialPort)
      this.comunication.sizeBit.setValue(response.sizeBit)
      this.comunication.sizeFrame.setValue(response.sizeFrame)
      this.comunication.timeBuffer.setValue(response.timeBuffer)
      this.comunication.typeComunication.setValue(response.typeComunication)
      this.comunication.protocol.setValue(response.typeProtocol)
      this.comunication.ipAddress.setValue(response.url)
      this.comunication.validateResultAutomatic.setValue(response.validateResultAutomatic)

    })
  }

  postEquipment() {
    let obj = {
      code: this.equipment.code.value,
      name: this.equipment.name.value,
      typeProtocol: this.comunication.protocol.value,
      typeComunication: this.comunication.typeComunication.value,
      url: this.comunication.ipAddress.value,
      port: this.comunication.port.value,
      serialPort: this.comunication.serialNumber.value,
      bitSecond: this.comunication.bitSecond.value,
      sizeBit: this.comunication.sizeBit.value,
      parity: this.comunication.parity.value,
      bitStop: this.comunication.bitStop.value,
      sizeFrame: this.comunication.sizeFrame.value,
      timeBuffer: this.comunication.timeBuffer.value,
      sendWorkList: this.comunication.sendWorkList.value,
      validateResultAutomatic: this.comunication.validateResultAutomatic.value,
      processImage: this.comunication.processImage.value,
    }

    this._equipmentsInterfacingService.post(obj).then((x: any) => {
      this.back()
      SwAlSetttings.Sucesso('Equipamento Cadastrado!')
    }).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  putEquipment() {
    let obj = {
      code: this.equipment.code.value,
      name: this.equipment.name.value,
      typeProtocol: this.comunication.protocol.value,
      typeComunication: this.comunication.typeComunication.value,
      url: this.comunication.ipAddress.value,
      port: this.comunication.port.value,
      serialPort: this.comunication.serialNumber.value,
      bitSecond: this.comunication.bitSecond.value,
      sizeBit: this.comunication.sizeBit.value,
      parity: this.comunication.parity.value,
      bitStop: this.comunication.bitStop.value,
      sizeFrame: this.comunication.sizeFrame.value,
      timeBuffer: this.comunication.timeBuffer.value,
      sendWorkList: this.comunication.sendWorkList.value,
      validateResultAutomatic: this.comunication.validateResultAutomatic.value,
      processImage: this.comunication.processImage.value,
      isActive: this.equipment.active.value
    }

    this._equipmentsInterfacingService.put(obj, new Guid(this.equipmentId)).then((x: any) => {
      this.back();
      SwAlSetttings.Sucesso('Equipamento Cadastrado!')
    }).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  //#endregion

  //#region Material

  openDialogModal(itensToEdit?: { id: string, Ativo: string, Material: string, _hide_materialId: string }) {
    if (itensToEdit) {
      this.material.materialId.setValue(itensToEdit._hide_materialId)
      this.material.nameMaterialEquipment.setValue(itensToEdit.Material)
      this.materialId = itensToEdit.id
    }


    const myTempDialog = this._dialog.open(this.dialogModal, {
      width: 'calc(660px * var(--proportional))',
    });
    myTempDialog.afterClosed().subscribe((res: any) => {
      this.materialId = '';
      this.resetFormsMaterial();
    });
  }

  async getMaterial() {
    await this._materialService.getPagination({ page: 1, index: 9999999 }).then(
      (res: any) => {
        this.materials = [];
        if (res.data.length != 0) {
          res.data.forEach((element: any) => {
            this.materials.push({
              value: element.id,
              label: element.description
            })
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.materialsEquipments = []
    this.materialsEquipments = this.resolveAll(data, columns);
  }

  resetFormsMaterial() {
    this.material.materialId.setValue('')
    this.material.nameMaterialEquipment.setValue('')
  }

  postMaterial() {
    let obj = {
      code: null,
      materialId: this.material.materialId.value,
      materialEquipment: this.material.nameMaterialEquipment.value,
      equipmentInterfaceId: this.equipmentId,
    }

    this._equipmentsMaterialService.post(obj).then((x: any) => {
      SwAlSetttings.Sucesso('Material cadastrado!');
      this.getByIdEquipment(true);
      this.resetFormsMaterial();
      this.closeDialog();
    }).catch((err) => SwAlSetttings.printError(err))
  }

  putMaterial() {
    let obj = {
      code: null,
      materialId: this.material.materialId.value,
      materialEquipment: this.material.nameMaterialEquipment.value,
      equipmentInterfaceId: this.equipmentId,
    }

    this._equipmentsMaterialService.put(obj, new Guid(this.materialId)).then((x: any) => {
      SwAlSetttings.Sucesso('Material Editado!');
      this.getByIdEquipment(true);
      this.resetFormsMaterial();
      this.closeDialog();
    }).catch((err) => SwAlSetttings.printError(err))
  }

  deleteMaterial(materialId: string) {
    this._equipmentsMaterialService.delete(new Guid(materialId)).then((x: any) => {
      SwAlSetttings.Sucesso('Material Excluido!');
      this.getByIdEquipment(true);
      this.resetFormsMaterial();
      this.closeDialog();
    }).catch((err) => SwAlSetttings.Error(err))
  }

  putActiveMaterial(id: string, value: string) {

    this._equipmentsMaterialService.patchActive(id, value).then((res: any) => {
      if (res.success) {
        SwAlSetttings.Sucesso('Situação alterada!');
        this.getByIdEquipment(true);
      }
    })
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.openDialogModal(emit.object);
        break;
      case 'Excluir':
        this.deleteMaterial(emit.object.id);
        break;
      case 'PutActive':
        this.putActiveMaterial(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  //#endregion

  //#region Text

  resolveText(data: any, columns: any) {
    this.textsEquipments = [];
    this.textsEquipments = this.resolveAll(data, columns);
  }

  openDialogModalText(itensToEdit?: { id: string, Ativo: string, Texto: string, Tradução: string }) {
    if (itensToEdit) {
      this.text.active.setValue(itensToEdit.Ativo.includes('active') ? true : false);
      this.text.text.setValue(itensToEdit.Texto)
      this.text.translate.setValue(itensToEdit.Tradução)
      this.textId = itensToEdit.id
    }

    const myTempDialog = this._dialog.open(this.dialogModalText, {
      width: 'calc(660px * var(--proportional))',
    });
    myTempDialog.afterClosed().subscribe((res: any) => {
      this.textId = '';
      this.resetFormsText();
    });
  }

  resetFormsText() {
    this.text.code.setValue('')
    this.text.text.setValue('')
    this.text.translate.setValue('')
    this.text.active.setValue(false)
  }

  postText() {
    let obj = {
      code: null,
      textEquipment: this.text.text.value,
      textTranslated: this.text.translate.value,
      equipmentInterfaceId: this.equipmentId
    }

    this._equipmentsTextService.post(obj).then((x: any) => {
      SwAlSetttings.Sucesso('Texto cadastrado!');
      this.getByIdEquipment(true);
      this.resetFormsText();
      this.closeDialog();
    }).catch((err) => SwAlSetttings.printError(err))
  }

  putText() {
    let obj = {
      code: null,
      textEquipment: this.text.text.value,
      textTranslated: this.text.translate.value,
      equipmentInterfaceId: this.equipmentId,
      isActive: this.text.active.value
    }

    this._equipmentsTextService.put(obj, new Guid(this.textId)).then((x: any) => {
      SwAlSetttings.Sucesso('Texto Editado!');
      this.getByIdEquipment(true);
      this.resetFormsText();
      this.closeDialog();
    }).catch((err) => SwAlSetttings.printError(err))
  }

  deleteText(textId: string) {
    this._equipmentsTextService.delete(new Guid(textId)).then((x: any) => {
      SwAlSetttings.Sucesso('Texto Excluído!');
      this.getByIdEquipment(true);
      this.resetFormsText();
      this.closeDialog();
    }).catch((err) => SwAlSetttings.Error(err))
  }

  actionsText(emit: ActionsText) {
    switch (emit.action) {
      case 'Editar':
        this.openDialogModalText(emit.object)
        break;
      case 'Excluir':
        this.deleteText(emit.object.id)
        break;
      case 'PutActive':
        this.putActiveText(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  putActiveText(id: string, value: string) {
    this._equipmentsTextService.patchActive(id, value).then((x: any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.getByIdEquipment(true);
      this.resetFormsText();
    }).catch(err => SwAlSetttings.printError(err))
  }

  //#endregion

  //#region Exames

  openDialogModalExam(itensToEdit?: { 'Bloqueado para liberação automática': string, _hide_analyteId: string, Ativo: string, Exame: string, Habilitado: string, id: string, _hide_getNameAnalyte: boolean, _hide_getBlockedValidate: boolean }) {
    if (itensToEdit) {
      this.exam.active.setValue(itensToEdit.Ativo.includes('true') ? true : false);
      this.exam.analyteBlockedValidate.setValue(itensToEdit._hide_getNameAnalyte)
      this.exam.analyteEnabled.setValue(itensToEdit._hide_getBlockedValidate)
      this.exam.analyte.setValue(itensToEdit._hide_analyteId)
      this.examId = itensToEdit.id
    }

    const myTempDialog = this._dialog.open(this.dialogModalExam, {
      width: 'calc(660px * var(--proportional))',
    });
    myTempDialog.afterClosed().subscribe((res: any) => {
      this.examId = ''
      this.resetFormsExam();
    });
  }

  async getAnalytes() {
    this.examOptions = [];
    await this._analyteService.getResume().then(
      (res: any) => {
        if (res.data.length != 0) {
          res.data.forEach((analyte: any) => {
            this.examOptions.push({
              label: analyte.code + ' - ' + analyte.name,
              value: analyte.id
            });
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  formatPort() {
    return this.comunication.port.value > 65535 ? this.comunication.port.setValue(65535) : ''
  }

  resolveExamsEquipments(data: any, columns: any) {
    this.examsEquipments = []
    this.examsEquipments = this.resolveAll(data, columns)
  }

  resetFormsExam() {
    this.exam.analyte.setValue(''),
      this.exam.analyteEnabled.setValue(false),
      this.exam.analyteBlockedValidate.setValue(false)
  }

  postExam() {
    let obj = {
      analyteId: this.exam.analyte.value,
      analyteEnabled: this.exam.analyteEnabled.value,
      analyteBlockedValidate: this.exam.analyteBlockedValidate.value,
      equipmentInterfaceId: this.equipmentId
    }

    this._equipmentsExamService.post(obj).then((x: any) => {
      SwAlSetttings.Sucesso('Exame cadastrado!');
      this.getByIdEquipment(true);
      this.closeDialog();
    }).catch((err) => SwAlSetttings.Error(err))
  }

  putExam() {
    let obj = {
      analyteId: this.exam.analyte.value,
      analyteEnabled: this.exam.analyteEnabled.value,
      analyteBlockedValidate: this.exam.analyteBlockedValidate.value,
      isActive: this.exam.active.value,
      equipmentInterfaceId: this.equipmentId
    }

    this._equipmentsExamService.put(obj, new Guid(this.examId)).then((x: any) => {
      SwAlSetttings.Sucesso('Exame editado!');
      this.getByIdEquipment(true);
      this.closeDialog();
    }).catch((err) => SwAlSetttings.Error(err))
  }

  deleteExam(examId: string) {
    this._equipmentsExamService.delete(new Guid(examId)).then((x: any) => {
      SwAlSetttings.Sucesso('Exame editado!');
      this.getByIdEquipment(true);
      this.closeDialog();
    }).catch((err) => SwAlSetttings.Error(err))
  }

  putActiveExam(id: string, value: string) {
    this._equipmentsExamService.patchActive(id, value).then((x: any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.getByIdEquipment(true);
    }).catch(err => SwAlSetttings.printError(err))
  }

  actionsExams(emit: ActionsExams) {
    switch (emit.action) {
      case 'Editar':
        this.openDialogModalExam(emit.object);
        break;
      case 'Excluir':
        this.deleteExam(emit.object.id);
        break;
      case 'OpenDropdown':
        if (this.allRecipients.length === 0) {
          this.getAllRecipients();
        }
        this.getRecipients(emit.id)
        break;
      case 'PutActive':
        this.putActiveExam(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  getNameAnalyte(item: ObjectActions) {
    return `${item.code} - ${item.name}`
  }

  getEnabled(item: ObjectActions) {
    return item.analyteEnabled ? "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Sim</span>" : "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Não</span>";
  }

  getBlockedValidate(item: ObjectActions) {
    return item.analyteBlockedValidate ? "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Sim</span>" : "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Não</span>";
  }

  //#endregion

  //#region Recipientes

  getAllRecipients() {
    this._recipientsService.getResume().then((res: any) => {
      res.data.forEach((element: any) => {
        this.allRecipients.push({
          value: element.id,
          label: element.description
        })
      });
    })
  }

  async getRecipients(analyteId: any) {
    this.recipients[analyteId] = [];
    await this._equipmentsRecipientsService.getPagination({ equipmentInterfaceAnalyteId: analyteId }).then((x: any) => {
      if (x.data.length > 0) {
        this.resolveRecipients(x.data, this.resolveRecipientsToTable, analyteId)
      }
    })
  }

  resolveRecipients(data: any, columns: any, analyteId: string) {
    //@ts-ignore
    this.recipients[analyteId] = []
    //@ts-ignore
    this.recipients[analyteId] = this.resolveAll(data, columns)
  }

  openDialogModalRecipients(analyteId: string, itensToEdit?: { Ativo: string, Recipiente: string, id: string, _hide_recipientId: string }) {
    if (itensToEdit) {
      this.recipient.name.setValue(itensToEdit._hide_recipientId)
      this.recipientPutId = itensToEdit.id
    }
    this.examId = analyteId

    const myTempDialog = this._dialog.open(this.dialogModalRecipients, {
      width: 'calc(660px * var(--proportional))',
    });
    myTempDialog.afterClosed().subscribe((res: any) => {
      this.examId = ''
      this.recipientId = ''
      this.recipientPutId = ''
      this.recipient.name.setValue('')
    });
  }

  postRecipient() {
    let obj = {
      equipmentInterfaceAnalyteId: this.examId,
      recipientId: this.recipient.name.value,
      codeEquipment: this.equipment.code.value
    }

    this._equipmentsRecipientsService.post(obj).then((res: any) => {
      SwAlSetttings.Sucesso('Recipiente cadastrado!')
      this.getRecipients(this.examId)
      this.closeDialog()
    })
  }

  putRecipient() {
    let obj = {
      equipmentInterfaceAnalyteId: this.examId,
      recipientId: this.recipient.name.value,
      codeEquipment: this.equipment.code.value,
    }

    this._equipmentsRecipientsService.put(obj, new Guid(this.recipientPutId)).then((res: any) => {
      SwAlSetttings.Sucesso('Recipiente alterado!')
      this.getRecipients(this.examId)
      this.closeDialog()
    })
  }

  deleteRecipient(recipientId: string, analyteId?: string) {
    SwAlSetttings.ALERT_CONFIRM.fire('Deseja realmente excluir esse Recipiente?').then(
      async (res) => {
        if (res.isConfirmed) {
          await this._equipmentsRecipientsService.delete(new Guid(recipientId));
          SwAlSetttings.Sucesso('Recipiente excluído com sucesso!');
          if (analyteId) this.getRecipients(analyteId);
        }
      }
    ).catch(
      (error) => {
        SwAlSetttings.printError(error);
      }
    );
  }

  putActiveRecipient(recipientId: string, value: string, analyteId: string) {
    this._equipmentsRecipientsService.patchActive(recipientId, value).then((res: any) => {
      if (res.success) {
        SwAlSetttings.Sucesso('Situação alterada!')
        this.getRecipients(analyteId)
      }
    })
  }

  actionsRecipients(emit: ActionsRecipients, analyteId: string) {
    switch (emit.action) {
      case 'Excluir':
        if (analyteId) this.deleteRecipient(emit.object.id, analyteId);
        break;
      case 'PutActive':
        this.putActiveRecipient(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo), analyteId)
        break
    }
  }



  //#endregion

}
