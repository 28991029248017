import {Component, Input, OnInit, Output} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'notify-checkbox-prop',
  templateUrl: './notifyCheck-prop.component.html',
  styleUrls: ['./notifyCheck-prop.component.scss']
})
export class NotifyCheckboxPropComponent implements OnInit {

  //@ts-ignore
  @Input() form: FormControl;
  @Input() placeholder : string = '';
  @Input() radio : boolean = false;
  @Input() name : string = '';
  @Input() label : string = '';
  @Input() mask : any = '';
  @Input() className : string = '';
  @Input() requiredInput : string = '';
  @Input() tabindexcomponent : string = '';
  @Input() pattern : string = '';
  @Input() dark : boolean = false;
  @Input() gap = 0;

  @Input() disabledSd = false;

  constructor() { }

  ngOnInit(): void {
  }

  onChange(value : String) {
    this.form.setValue(value);
  }

}
