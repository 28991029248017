import { DefaultFormats } from "@app/pipe/localeDate.pipe"

export interface Actions {
    action: string,
    id: string,
    context: string | null,
    object: ResolveListQuestions
}
  
export interface ResolveListQuestions{
    id: string
    Ativo: string
    ['questions.name']:string
    ['questions.externalId']:number
    _hide_genderTypeDescription: string
}
  
export interface GetListQuestions {
    createdAt: string,
    updatedAt: string,
    isActive: boolean,
    externalId: number
}

export const resolveQuestions: Array<Object> = [
    {
        label: 'id',
        retrive: 'id',
        method: '',
        after: '',
        before: ''
    },
    {
        label: "questions.externalId",
        method: 'formatField',
        retrive: '',
        after: '',
        before: '',
        fieldName: 'externalId',
        type: 'description',
    },
    {
        label: "questions.name",
        method: 'formatField',
        retrive: '',
        after: '',
        before: '',
        fieldName: 'name',
        type: 'description',
    },
    {
        label: "questions.genderTypeDescription",
        method: 'formatField',
        retrive: '',
        after: '',
        before: '',
        fieldName: 'genderTypeDescription',
        type: 'description',
    },

    {
        label: 'questions.createdAt',
        retrive: '',
        method: 'formatField',
        after: '',
        before: '',
        fieldName: 'createdAt',
        type: 'date',
        dateFormat: DefaultFormats.full,
        sort: 'createdAt'
      },
      {
        label: 'questions.updatedAt',
        retrive: '',
        method: 'formatField',
        after: '',
        before: '',
        fieldName: 'updatedAt',
        type: 'date',
        dateFormat: DefaultFormats.full,
        sort: 'updatedAt'
      },
      {
        label: 'Ativo',
        retrive: '',
        method: 'formatField',
        after: '',
        before: '',
        fieldName: 'isActive',
        type: 'checkbox',
        sort: 'isActive'
      },
      {
        label: '_hide_genderTypeDescription',
        retrive: 'genderTypeDescription',
        method: '',
        after: '',
        before: ''
    },
];