import { AsyncPipe, CommonModule } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { UsersAdminUsersFacade } from "@app/modules_new/admin/data/facades/users-admin-users.facade";
import { RequestOptions } from "@app/modules_new/data/services/types/http.types";
import { DynamicPipe } from "@app/modules_new/shared/pipe/dynamic-pipe.pipe";
import { LocaleDatePipe } from "@app/modules_new/shared/pipe/localeDate.pipe";
import { HtmlSanitizerPipe } from "@app/modules_new/shared/pipe/sanitizer-html.pipe";
import { SpanPipe } from "@app/modules_new/shared/pipe/span.pipe";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "users-ui",
   styleUrls: ["./users-ui.component.scss"],
   templateUrl: "./users-ui.component.html",
   standalone: true,
   providers: [LocaleDatePipe, SpanPipe, HtmlSanitizerPipe, TranslateModule],
   imports: [TranslateModule, AsyncPipe, CommonModule, DynamicPipe]
})
export class UsersUIComponent implements OnInit {
   private _usersFacade = inject(UsersAdminUsersFacade);
   @Input() user!: string;
   userData!: any;

   ngOnInit() {
      this.get();
   }

   async get() {
      const request: RequestOptions = {
         path: "/" + this.user
      };

      const response = await this._usersFacade.getAccessControl(request);

      this.userData = response.data[0];
   }
}
