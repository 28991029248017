import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MenuItemFacade } from "@app/modules_new/admin/data/facades/menuItem.facade";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ModalLayoutComponent } from "@app/modules_new/shared/components/form/modal/modal.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "menuItemModal",
   templateUrl: "./modal.component.html",
   standalone: true,
   imports: [
      ModalLayoutComponent,
      InputTextFormComponent,
      ReactiveFormsModule,
      ButtonFormComponent,
      CommonModule,
      TranslateModule
   ]
})
export class MenuItemModal implements OnInit {
   private _menuItemFacade = inject(MenuItemFacade);

   public _dialogData = inject(MAT_DIALOG_DATA);

   formGroup = new FormGroup({
      description: new FormControl<string | null>(null, [Validators.required]),
      url: new FormControl<string | null>(null)
   });

   async ngOnInit() {
      if (this._dialogData?.obj?.description) {
         this.formGroup.setValue({
            description: this._dialogData?.obj?.description,
            url: this._dialogData?.obj?.url
         });
      }
   }

   postMenu() {
      let obj: any = {
         description: this.formGroup.controls.description.value,
         url: this.formGroup.controls.url.value
      };

      if (this._dialogData?.obj?.parentId) {
         obj.parentId = this._dialogData.obj.parentId;
      }

      this._menuItemFacade.post(obj);
   }

   putMenu() {
      let obj: any = {
         description: this.formGroup.controls.description.value,
         url: this.formGroup.controls.url.value
      };

      if (this._dialogData?.obj?.parentId) {
         obj.parentId = this._dialogData.obj.parentId;
      }

      this._menuItemFacade.put(this._dialogData.obj.id, obj);
   }
}
