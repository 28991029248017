export type TypeDocument = {
    id: string
    externalId: string
    description: string
    type: number
    createdAt: string
    updatedAt: string
    isActive: boolean
}

export type Actions = {
    action: string,
    id: string,
    context: string | null,
    object: ResolvedTypeDocument
}

export type ResolvedTypeDocument = {
    id: string
    "Tipo": string 
    "Código": string
    "Descrição": string
    "Criado em": string
    "Alterado em": string
    "Ativo": string
    "_hide_type": number
}

export const documentTypeOptions = [
    {
      value: 1,
      label: 'Normal'
    },
    {
      value: 2,
      label: 'Adiantamento'
    }
]

export type PaginationInfo = {
    page: number,
    sumRecords: number,
    index: number,
}

export const spanOptions: {[key: number]: string} = {
    1: '<span class="span-styles" style="background: #1c89ad; color: #FFFFFF;">Normal</span>',
    2: '<span class="span-styles" style="background: #1cad51; color: #FFFFFF;">Adiantamento</span>'
}