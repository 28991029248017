import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AccessControlUserAuth } from '@app/services/auth/acesscontrol-user-auth.service';
import { DepartmentAuthService } from '@app/services/auth/department.service';
import { ModuleAuthService } from '@app/services/auth/module-auth-service';
import { UnityService } from '@app/services/shared/unity.service';
import { SwAlSetttings } from '@app/util/swal.settings';
import axios from 'axios';
import { ProfileTenantAuth } from '@app/services/auth/profile-user-tenant.service';
import { TranslateService } from "@ngx-translate/core";
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { CompanyService } from '@app/services/company/company.service';
import { permission, resume, unityResponse, user } from './new-user.model';
import { Guid } from '@app/util/guid';
import { Location } from '@angular/common';

interface Permission {
  unityId: string,
  companyId: string,
  rolesId: string[]
}

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  constructor(
    private _departmentService: DepartmentAuthService,
    private _accessControlUserAuth: AccessControlUserAuth,
    private _router: Router,
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _unityService: UnityService,
    private _moduleService: ModuleAuthService,
    private _profileTenantAuth: ProfileTenantAuth,
    private _translateService: TranslateService,
    private _companyService: CompanyService,
    private _location: Location
  ) {
  }

  alterCritical: boolean = false;
  acessCode: string = '';
  cellPhone: string = '';
  disable = false;
  codThis = 0;
  options: Array<object> = [];
  edit = false;
  editUserId = '';
  editUserName = '';
  userAccessActive: boolean = false;
  unityOptions: Array<Object> = [];
  permissionsUsers: Array<Object> = [];

  optionsModule: Array<Object> = [];
  moduleForm = new UntypedFormControl(null);

  unities: Array<Object> = [];
  companies: Array<Object> = [];
  formCompany = {
    company: new UntypedFormControl(null),
    unity: new UntypedFormControl(null)
  }

  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new UntypedFormControl(this.positionOptions[3]);
  optionsPapeis: Array<Object> = [];
  roleForm = new UntypedFormControl([]);
  rolePolicies = [];
  permissionsUser = [];
  modulesByToken: Array<Object> = [];

  tenantsOfUser: Array<object> = []

  permissions = [
    { value: 0, label: 'Permite o usuário cancelar a digitação de resultados.' },
    { value: 1, label: 'Permite o usuário cancelar a conferência de resultados.' },
    { value: 2, label: 'Permite o usuário cancelar a liberação de resultados.' },
    { value: 3, label: 'Permite o usuário cancelar a impressão de resultados.' },
    { value: 4, label: 'Permite o usuário cancelar a entrega de resultados.' },
    { value: 5, label: 'Permite o usuário cancelar um exame da requisição.' },
    { value: 6, label: 'Permite usuário cancelar a coleta de um exame.' },
    { value: 7, label: 'Permite o usuário cancelar a triagem de um exame.' },
    { value: 8, label: 'Permite o usuário a assinalar um exame como exclusivo de faturamento.' },
    { value: 9, label: 'Permite o usuário a assinalar um exame como suspenso de faturamento.' },
    { value: 10, label: 'Permite o usuário trocar o cliente de uma requisição.' },
    { value: 11, label: 'Permite o usuário alterar o médico solicitante de um pedido médico.' },
    { value: 12, label: 'Permite excluir exame da requisição.' },
  ];
  form = {
    personName: new UntypedFormControl(null),
    email: new UntypedFormControl(null),
    active: new UntypedFormControl(null),
    CEP: new UntypedFormControl(null),
    address: new UntypedFormControl(null),
    number: new UntypedFormControl(null),
    complement: new UntypedFormControl(null),
    admissionData: new UntypedFormControl(null),
    resignationData: new UntypedFormControl(null),
    neighborhood: new UntypedFormControl(null),
    city: new UntypedFormControl(null),
    state: new UntypedFormControl(null),
    phone: new UntypedFormControl(null),
    cellPhone: new UntypedFormControl(null),
    department: new UntypedFormControl(''),
    password: new UntypedFormControl(null),
    confirmPassword: new UntypedFormControl(null),
    naturality: new UntypedFormControl(null),
    alternativeEmail: new UntypedFormControl(null),
    emailPerson: new UntypedFormControl(null),
    civilState: new UntypedFormControl(null),
    file: new UntypedFormControl(null),
    fileName: new UntypedFormControl(null),
    cpf: new UntypedFormControl(null),
    passwordConfirm: new UntypedFormControl(null),
    discountPercentage: new UntypedFormControl(0),
    defaultCompany: new UntypedFormControl(null),
    defaultUnity: new UntypedFormControl(null),
  }

  resolvePermissions: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Descrição",
      method: '',
      retrive: "description",
      after: '',
      before: '',
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ]

  permission = {
    selectedPermission: new UntypedFormControl([])
  }

  async ngOnInit() {
    this.getDepartment();
    //@ts-ignore
    this.tenantsOfUser = await JSON.parse(localStorage.getItem('tenantsOfUser'));

    this.form.active.setValue(true);
    if(this.tenantsOfUser && this.tenantsOfUser.length === 1) {
      //@ts-ignore
      this.form.defaultCompany.setValue(this.tenantsOfUser[0].value)
    }

    if (this._route.snapshot.params['id']) {
      this.editUserId = this._route.snapshot.params['id'];
      this.edit = true;
      await this.getUserEdit();
    }
  }

  openDialogModal(): void {
    const myTempDialog = this._dialog.open(this.dialogModal, {
      width: '660px',
    });
    myTempDialog.afterClosed().subscribe((res) => {

    });
  }

  ToggleCod(value: number) {
    this.codThis = value
  }

  CheckIsEmail() {
    if (this.form.email.value) {
      var is = this.form.email.value.split('@');

      if (is.length > 1)
        this.codThis = 0;
      else
        this.codThis = 1;
    }
  }

  async getDepartment() {
    await this._departmentService.getResume(true).then((x: any) => {
      if (x.data.length > 0) {
        x.data.forEach((y: resume) => {
          this.options.push({
            value: y.id,
            label: y.name
          })
        })
      }
    })
  }

  async getUserEdit() {
    await this._accessControlUserAuth.getById(new Guid(this.editUserId)).then((x: any) => {
      let infos = x.data[0]

      this.form.personName.setValue(infos.name);
      this.editUserName = infos.name;
      this.form.email.setValue(infos.accessCode);
      this.acessCode = infos.accessCode;
      this.CheckIsEmail();
      this.form.cpf.setValue(infos.cpf);
      this.form.cellPhone.setValue(infos.cellPhone);
      this.cellPhone = infos.cellPhone;
      this.form.alternativeEmail.setValue(infos.alternativeEmail);
      this.form.CEP.setValue(infos.address.zipCode);
      this.form.address.setValue(infos.address.street);
      this.form.active.setValue(infos.isActive);
      this.form.number.setValue(infos.address.number);
      this.form.state.setValue(infos.address.state);
      this.form.neighborhood.setValue(infos.address.neighborhood);
      this.form.complement.setValue(infos.address.complement);
      this.form.city.setValue(infos.address.city);
      this.form.phone.setValue(infos.telephone);
      this.form.defaultCompany.setValue(infos.companyId);

      if(this.unityOptions.length == 0) {
        this.unityOptions.push({
          value: infos.unityId,
          label: infos.unityName
        })

      }

      this.form.defaultUnity.setValue(infos.unityId);
      this.form.discountPercentage.setValue(infos.discountPercentage);
      this.userAccessActive = infos.userAccessActive

      if (infos.admissionData && infos.admissionData.split("T")[0] != '0001-01-01')
        this.form.admissionData.setValue(infos.admissionData);
      

      if (infos.resignationData && infos.resignationData.split("T")[0] != '0001-01-01')
        this.form.resignationData.setValue(infos.resignationData);

      if (infos.departments) {
        const values = infos.departments.map((x: any) => x.departmentId)
        this.form.department.setValue(values);
      }
    }
    ).catch((error: ErrorEvent) => SwAlSetttings.printError(error))
  }

  async postUser() {
    const post: user = {
      "accessCode": this.form.email.value ?? null,
      "type": this.codThis == 0 ? 1 : 2,
      "password": this.form.password.value?? null,
      "confirmPassword": this.form.confirmPassword.value?? null,
      "name": this.form.personName.value?? null,
      "address": {
        "zipCode": this.form.CEP.value ?? null,
        "street": this.form.address.value ?? null,
        "number": this.form.number.value ?? null,
        "complement": this.form.complement.value ?? null,
        "neighborhood": this.form.neighborhood.value ?? null,
        "city": this.form.city.value ?? null,
        "state": this.form.state.value ?? null,
        "country": "Brasil"
      },
      "telephone": this.form.phone.value ?? null,
      "cellPhone": this.form.cellPhone.value ?? null,
      "alternativeEmail": this.form.alternativeEmail.value ?? null,
      "cpf": {
        "value": this.form.cpf.value ?? null,
      },
      "departmentResposible": true,
      "discountPercentage": this.form.discountPercentage.value ?? null,
      "companyId": this.form.defaultCompany.value ?? null,
      "unityId": this.form.defaultUnity.value ?? null
    } as user
    if (this.form.department.value) {
      post.departments = this.form.department.value;
    } else {
      post.departments = []
    }

    if (this.form.admissionData.value) {
      post.admissionData = new Date(this.form.admissionData.value).toISOString();      
    }

    if (this.form.resignationData.value) {
      post.resignationData = new Date(this.form.resignationData.value).toISOString();
    }

    await this._accessControlUserAuth.post(post).then((x: any) => {
      this.disable = true;
      if (x.id) {
        this._location.back()
      }
      SwAlSetttings.Sucesso('Usuário cadastrado com sucesso!');
    }).catch((error: ErrorEvent) => SwAlSetttings.printError(error))
  }

  compare() {
    if (this.form.email.value == this.acessCode && this.form.cellPhone.value == this.cellPhone) {
      this.alterCritical = false;
      this.EditUser();
    } else {
      this.alterCritical = true;
      this.openDialogModal();
    }
  }

  async EditUser() {
    const put: user = {
      "accessCode": this.form.email.value ?? null,
      "type": this.codThis == 0 ? 1 : 2,
      "name": this.form.personName.value ?? null,
      "departments": this.form.department.value ?? [],
      "address": {
        "zipCode": this.form.CEP.value ?? null,
        "street": this.form.address.value ?? null,
        "number": this.form.number.value ?? null,
        "complement": this.form.complement.value ?? null,
        "neighborhood": this.form.neighborhood.value ?? null,
        "city": this.form.city.value ?? null,
        "state": this.form.state.value ?? null,
        "country": "Brasil"
      },
      "telephone": this.form.phone.value ?? null,
      "cellPhone": this.form.cellPhone.value ?? null,
      "alternativeEmail": this.form.alternativeEmail.value ?? null,
      "cpf": {
        "value": this.form.cpf.value ?? null,
      },
      "previousPassword": null,
      "departmentResposible": true,
      "isActive": this.form.active.value ?? null,
      "unityId": this.form.defaultUnity.value ?? null,
      "discountPercentage": this.form.discountPercentage.value ?? null,
      "companyId": this.form.defaultCompany.value ?? null
    } as user

    if (this.alterCritical) {
      put.previousPassword = this.form.passwordConfirm.value;
    }

    if (this.form.admissionData.value) {
      put.admissionData = new Date(this.form.admissionData.value).toISOString();      
    }
    if (this.form.resignationData.value) {
      put.resignationData = new Date(this.form.resignationData.value).toISOString();
    }


    await this._accessControlUserAuth.put(put, new Guid(this.editUserId)).then(() => {
      this._dialog.closeAll();
      this.alterCritical = false;
      this.disable = true;
      this._location.back()
      SwAlSetttings.Sucesso('Usuário editado com sucesso!');
    }).catch((error: ErrorEvent) => SwAlSetttings.printError(error))
  }

  Cep() {
    axios.get('https://viacep.com.br/ws/' + this.form.CEP.value.replace('-', '') + '/json/').then((x: any) => {
      this.form.address.setValue(x.data.logradouro)
      this.form.neighborhood.setValue(x.data.bairro)
      this.form.city.setValue(x.data.localidade)
      this.form.state.setValue(x.data.uf)
    })
  }

  includePermission() {
    var permissions: Array<Object> = [];
    this.permission.selectedPermission.value.forEach((permission: any) => {
      var obj = {
        id: null,
        isActive: true,
        description: this.permissions.filter(x => x.value === permission)[0].label
      }
      permissions.push(obj);
    });
    this.resolve(permissions, this.resolvePermissions);
    this.permission.selectedPermission.reset();
  }

  resolve(data: any, columns: any) {
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.permissionsUsers.push(obj);
    });
  }

  getActive(item: any) {
    return !item.isActive ? "<svg style=\"max-width: calc(34px * var(--proportional));\" width=\"34\" height=\"21\" viewBox=\"0 0 34 21\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <rect x=\"0.475647\" y=\"0.647827\" width=\"33.3333\" height=\"20\" rx=\"10\" fill=\"#808080\"/> <path d=\"M10.4756 18.6478C14.8939 18.6478 18.4756 15.0661 18.4756 10.6478C18.4756 6.22955 14.8939 2.64783 10.4756 2.64783C6.05737 2.64783 2.47565 6.22955 2.47565 10.6478C2.47565 15.0661 6.05737 18.6478 10.4756 18.6478Z\" fill=\"white\"/> </svg>" : "<svg style='max-width: calc(34px * var(--proportional));height: auto' width=\"34\" height=\"20\" viewBox=\"0 0 34 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <rect x=\"0.384644\" width=\"33.3333\" height=\"20\" rx=\"10\" fill=\"#FE7434\"/> <path d=\"M23.3846 18C27.8029 18 31.3846 14.4183 31.3846 10C31.3846 5.58172 27.8029 2 23.3846 2C18.9664 2 15.3846 5.58172 15.3846 10C15.3846 14.4183 18.9664 18 23.3846 18Z\" fill=\"white\"/> </svg>";
  }

  ActionsExtra = {
    "Ativo": 'setActive'
  };

  async Actions(action: any) {
    switch (action.action) {
      case 'setActive':
        break
    }
  }

  async getUnity() {
    await this._unityService.getResume().then(
      async (res: any) => {
        res.data.forEach((unity: unityResponse) => {
          this.unityOptions.push({
            value: unity.id,
            label: unity.description,
            isActive: unity.isActive
          });
        });
      }
    ).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  getRoleByModule() {
    this.optionsPapeis = [];

    if (this.moduleForm.value) {
      this._moduleService.getByPath('', this.moduleForm.value + '/role').then((x: any) => {
        this.optionsPapeis = [];
        this.roleForm.setValue([]);

        x.data.forEach((y: any) => {
          if (y.name != "default") {
            this.optionsPapeis.push({
              value: y.id,
              label: y.name,
              disable: this.rolePolicies.findIndex((z: any) => z.name === y.name) != -1
            });
          }
        });
      }).catch(error => SwAlSetttings.printError(error))
    }
  }

  addRoletoUser() {
    const post: Permission = {
      "rolesId": this.roleForm.value
    } as Permission

    if (this.formCompany.company.value) {
      post.companyId = this.formCompany.company.value
    }

    if (this.formCompany.unity.value) {
      post.unityId = this.formCompany.unity.value
    }

    this._accessControlUserAuth.postToPath(post, this.editUserId + '/rolepolicy').then(async (x) => {
      await this.getUserEdit();
      await this.getPermissionsUser()
      await this.getModules();
      SwAlSetttings.Sucesso('Papel adicionado com sucesso!');

    }).catch((error) => {
      SwAlSetttings.printError(error);
    })
  }

  async getPermissionsUser() {
    this.permissionsUser = [];
    let stringQuery = '?'

    if (this.formCompany.company.value) {
      stringQuery += `companyId=${this.formCompany.company.value}`
    }

    if (this.formCompany.unity.value) {
      stringQuery += `&unityId=${this.formCompany.unity.value}`
    }

    var returnVar = await this._accessControlUserAuth.getByPath(stringQuery, this.editUserId + '/policy');
    ///@ts-ignore
    this.rolePolicies = returnVar.data[0].rolePolicies;;
    // @ts-ignore
    returnVar.data[0].rolePolicies.forEach((y: any) => {
      y.resources.forEach((x: any) => {
        const obj: permission = {
          "id": x.id,
          "name": x.name,
          "isRole": true,
          "access": true,
        } as permission

        // @ts-ignore
        this.permissionsUser.push(obj);
      })
    });

    // @ts-ignore
    returnVar.data[0].userPolicies.forEach((y: any) => {
      const index = this.permissionsUser.findIndex((element: any, index: number) => element.name === y.name);

      let obj = {};

      if (index != -1) {
        this.permissionsUser.splice(index, 1);
        obj = {
          "id": y.id,
          "name": y.name,
          "isRole": false,
          "access": y.access === "Allowed" ? true : false,
          "access1": y.access
        };
      } else {
        obj = {
          "id": y.id,
          "name": y.name,
          "isRole": false,
          "access": y.access === "Allowed" ? true : false,
          "access1": y.access
        };
      }
      // @ts-ignore
      this.permissionsUser.push(obj);
    });
  }

  async getModules() {
    const request = await this._profileTenantAuth.getAll();
    // @ts-ignore
    this.modulesByToken = request.data[0].modules;
    this.roleForm.setValue([]);
    this.moduleForm.setValue(null);
    this.optionsModule = [];
    //@ts-ignore
    request.data[0].modules.forEach((module: any) => {
      if (module.name != 'admin' && module.name != 'default') {
        const obj = {
          value: module.id,
          label: this._translateService.instant(`modules.${module.name}`),
        };
        this.optionsModule.push(obj)
      }
    });
  }

  CheckIsItemModule(moduleName: string) {
    return this.permissionsUser.findIndex((x: any) => x.name.split(':')[0] == moduleName) != -1 ? true : false;
  }

  CheckInSd(moduleName: string, array: Array<any>) {
    //@ts-ignore
    const index = array.findIndex((element: any, index: number) => {
      if (element.moduleName === moduleName) {
        return true
      }
    });

    if (index != -1)
      return true;
    else
      return false;
  }

  CheckIsUserRole(resourceName: string) {
    //@ts-ignore
    const index = this.permissionsUser.findIndex((element: any, index: number) => {
      if (element.name === resourceName && element.isRole === false) {
        return true
      }
    });

    if (index != -1) {
      // @ts-ignore
      return this.permissionsUser[index].access === true ? true : false;
    }
    else return true;
  }

  getRole(role: string, module: string): string {
    if (module === 'occurrences') {
      return `roles.${role.replace('occurrencies:', '')}`;
    }

    if (module === 'documents' && role.startsWith('document:')) {
      return `roles.${role.replace('document:', '')}`;
    }

    return `roles.${role.replace(`${module}:`, '')}`;
  }

  async deleteRole(policy: string, policyName: string) {
    let ls = await SwAlSetttings.Confirmar('Tem certeza que deseja excluir o papel ' + policyName + ' do usuário?')
    if (ls.isConfirmed) {
      let rolesId = {
        "rolesId": [
          policy
        ]
      };
      //@ts-ignore
      this._accessControlUserAuth.deleteB(this.editUserId + '/rolepolicy', rolesId).then(async (x) => {
        SwAlSetttings.Sucesso('Papel excluído com sucesso!');
        await this.getPermissionsUser()
        await this.getModules();
      });
    }
  }

  NegatePolicy(ResourceName: string, ResourceId: string) {
    //@ts-ignore
    const index = this.permissionsUser.findIndex((element: any, index: number) => {
      if (element.name === ResourceName) {
        return true
      }
    });
    if (index != -1) {
      //@ts-ignore
      if (this.permissionsUser[index].isRole) {
        //@ts-ignore
        this.AddDeniedResourceRole(this.permissionsUser[index].id);
      } else {
        //@ts-ignore
        this.removeResource(this.permissionsUser[index].id);
      }
    } else {
      this.AddDeniedResourceRole(ResourceId);
    }
  }

  AddDeniedResourceRole(ResourceId: string) {
    const obj = {
      "resourceId": ResourceId,
      "accessPolicy": 0,
    }
    this._accessControlUserAuth.postToPath(obj, this.editUserId + '/policy').then(async (x) => {
      await this.getPermissionsUser();
    }).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  removeResource(ResourceId: string) {
    const id = ResourceId;
    let ind = this.permissionsUser.findIndex((x: any) => x.id == id);
    //@ts-ignore
    let ind2 = this.permissionsUser.findIndex((x: any) => this.permissionsUser[ind].name == x.name && x.isRole === false);
    //@ts-ignore
    this._accessControlUserAuth.delete(this.editUserId + '/policy/' + this.permissionsUser[ind2].id).then(async (x) => {
      await this.getPermissionsUser();
    }).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  GarantedPolicy(ResourceName: string, ResourceId: string) {
    //@ts-ignore
    const index = this.permissionsUser.findIndex((element: any, index: number) => {
      if (element.name === ResourceName) {
        return true
      }
    });

    if (index != -1) {
      //@ts-ignore
      this.removeResource(this.permissionsUser[index].id);
    } else {
      //@ts-ignore
      this.addResource(ResourceId);
    }
  }

  async myTabSelectedTabChange(changeEvent: MatTabChangeEvent) {
    if (changeEvent.index == 1) {
      await this.getPermissionsUser();
      await this.getModules();
      await this.getCompany();
      this.unities = [];
      this.unities.push({ value: null, label: 'Todas' });
      this.formCompany.unity.setValue(null);
    }
  }

  async getCompany() {
    this.companies = [];
    this.companies.push({ value: null, label: 'Todas' });
    this._companyService.getResume().then(
      (response: any) => {
        if (response.data.length != 0) {
          response.data.forEach((company: any) => {
            this.companies.push({
              value: company.id,
              label: company.companyName,
            })
          });
        } else {
          SwAlSetttings.alertaMsg('Não foi encontrada nenhuma empresa!');
        }
      }
    ).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async getUnities() {
    this.unities = [];
    this.unities.push({ value: null, label: 'Todas' });
    this.formCompany.unity.setValue(null);
    if (this.formCompany.company.value){
      this._unityService.getPagination({ resume: true, companyId: this.formCompany.company.value }).then(
        (response: any) => {
          if (response.data.length > 0) {
            response.data.forEach((company: any) => {
              this.unities.push({
                value: company.id,
                label: company.name,
              })
            });
          } else {
            SwAlSetttings.alertaMsg('Não foi encontrada nenhuma filial!');
          }
        }
      ).catch(
        (err: any) => {
          SwAlSetttings.printError(err);
        }
      );
    }
    this.getPermissionsUser();
  }

  async getUnityByCompany() {
    this.unityOptions = [];
    if (this.form.defaultCompany.value) {
      await this._unityService.getPagination({ resume: true, companyId: this.form.defaultCompany.value }).then((res: any) => {
        if (res.data.length > 0) {
          res.data.map((item: any) => {
            this.unityOptions.push({
              value: item.id,
              label: item.name
            })
          })
        }
      });
    }
  }
}
