import { CommonModule, NgIf } from "@angular/common";
import {
   Component,
   EventEmitter,
   inject,
   Input,
   OnInit,
   Output
} from "@angular/core";
import { InputSelectFormComponent } from "../input-select/base/input-select.component";
import { AgreementSuppportFacade } from "@app/modules_new/registers/data/facades/agreement-support-facade";
import { generateFieldsOptions } from "./change";
import { InputTextFormComponent } from "../input-text/input-text.component";

@Component({
   selector: "change-field",
   templateUrl: "./change.component.html",
   styleUrls: ["./change.component.scss"],
   standalone: true,
   imports: [
      NgIf,
      CommonModule,
      InputSelectFormComponent,
      InputTextFormComponent
   ]
})
export class ChangeComponent implements OnInit {
   constructor() {}

   private _agreementSuppportFacade = inject(AgreementSuppportFacade);

   @Output() select1 = new EventEmitter<any>();
   @Output() select2 = new EventEmitter<any>();

   @Input() generateFields!: Array<generateFieldsOptions>;

   ngOnInit() {}

   async change(index: number) {
      switch (index) {
         case 0:
            if (
               this.generateFields[0].form.value ||
               this.generateFields[0].form.value === null
            ) {
               this.changeSelect1();
            }
            break;
         default:
            if (
               this.generateFields[index].form.value ||
               this.generateFields[index].form.value === null
            )
               this.changeSelect2();
            break;
      }
   }

   selectedCollectionPlace(index: number, event: any, placeholder: string) {
      if (
         index == 2 &&
         placeholder == "movimentation.requisition.filter.colectionPlace"
      ) {
         const selectedCollectionPlace: any = this.generateFields[
            index
         ].options.filter(
            (collectionPlace: any) => collectionPlace.value === event?.value
         );
         if (event && selectedCollectionPlace.length > 0) {
            localStorage.setItem(
               "CollectionPlace",
               selectedCollectionPlace[0]?.value
            );
         }
      }
   }

   changeSelect1() {
      this.select1.emit();
   }

   changeSelect2() {
      this.select2.emit();
   }
}
