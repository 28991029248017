import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AgreementsService } from '@app/services/agreements/agreements.service';
import { CustomersService } from '@app/services/customers/customers.service';
import { DoctorsService } from '@app/services/doctors/doctors.service';
import { UserAuthenticateService } from '@app/services/user-authenticate/user-authenticate.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Actions, ResolveAccess } from './access.model';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent implements OnInit {
  total: number = 0;
  page: number = 1;
  index: number = 50;
  type: number = 0;
  searchPlaceHolder: string = '';
  getParameters: string = (`?page=${this.page}&index=${this.index}`);
  Search = new UntypedFormControl();
  accessId: Guid = new Guid(Guid.getEmpty());
  users: Array<Object> = [];
  alterCustomer: boolean = true;

  typeOptions = [
    {
      value: 1,
      label: 'Cliente'
    },
    {
      value: 2,
      label: 'Unidade ou Filial'
    },
    {
      value: 3,
      label: 'Médico Solicitante'
    },
    {
      value: 4,
      label: 'Convênio'
    },
    {
      value: 5,
      label: 'Plano do Convênio'
    },
    {
      value: 6,
      label: 'Destino de Entrega'
    },
    {
      value: 7,
      label: 'Local de Coleta'
    }
  ];

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  access: Array<ResolveAccess> = [];
  resolveaccess = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Nome',
      retrive: 'name',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Tipo de Acesso',
      retrive: '',
      method: 'getType',
      after: '',
      before: ''
    },
    {
      label: 'Código de Acesso',
      retrive: 'accessCode',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Celular',
      retrive: 'cellPhone',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Email verificado',
      retrive: '',
      method: 'getEmailVerified',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ];

  formSearch = {
    name: new UntypedFormControl(null, [Validators.required]),
    birthDate: new UntypedFormControl(null, [Validators.required]),
    register: new UntypedFormControl(null, [Validators.required]),
    type: new UntypedFormControl(1, [Validators.required])
  }

  form = {
    type1: new UntypedFormControl(false, [Validators.required]),
    type3: new UntypedFormControl(false, [Validators.required]),
    type4: new UntypedFormControl(false, [Validators.required]),
    active: new UntypedFormControl(true, [Validators.required]),
    name: new UntypedFormControl(null, [Validators.required]),
    accessCode: new UntypedFormControl(null, [Validators.required]),
    cellPhone: new UntypedFormControl(null, [Validators.required]),
    externalId: new UntypedFormControl(null, [Validators.required]),
    password: new UntypedFormControl(null, [Validators.required]),
    confirmPassword: new UntypedFormControl(null, [Validators.required]),
    searchCustomer: new UntypedFormControl(null, [Validators.required])
  }

  constructor(private _dialog: MatDialog, private _userAuthenticateService: UserAuthenticateService, private _customerService: CustomersService,
    private _doctorsService: DoctorsService, private _agreementService: AgreementsService) { }

  async ngOnInit() {
    await this.get();
  }

  async newAccess() {
    this.form.active.setValue("");
    this.form.type1.setValue(false);
    this.form.type3.setValue(false);
    this.form.type4.setValue(false);
    this.form.name.setValue("");
    this.form.active.setValue("");
    this.form.cellPhone.setValue("");
    this.form.accessCode.setValue("");
    this.form.externalId.setValue(null);
    this.form.searchCustomer.setValue(null);
    this.form.password.setValue(null);
    this.form.confirmPassword.setValue(null);
    this.alterCustomer = true;
    this.users = [];
    let externalId = 0;
    let externalLabel = '';
    this.type = 0;
    if (this.accessId != new Guid(Guid.getEmpty())) {
      await this._userAuthenticateService.getById(this.accessId).then(
        async (res: any) => {
          this.form.name.setValue(res.data[0].name)
          this.form.active.setValue(res.data[0].isActive)
          this.form.cellPhone.setValue(res.data[0].cellPhone)
          this.form.accessCode.setValue(res.data[0].accessCode)
          this.type = res.data[0].type;

          switch (this.type) {
            case 1://Cliente
              this.searchPlaceHolder = "Pesquisa Cliente";
              this.form.type1.setValue(true);
              externalId = res.data[0].customer.id;
              externalLabel = res.data[0].customer.name;
              break;
            case 2://Unidade ou Filial
              break;
            case 3://Médico Solicitante
              this.searchPlaceHolder = "Pesquisa Médico";
              this.form.type3.setValue(true);
              externalId = res.data[0].doctor.id;
              externalLabel = res.data[0].doctor.name;
              break;
            case 4://Convênio
              this.searchPlaceHolder = "Pesquisa Convênio";
              this.form.type4.setValue(true);
              externalId = res.data[0].agreement.id;
              externalLabel = res.data[0].agreement.description;
              break;
            case 5://Plano do Convênio
              break;
            case 6://Destino de Entrega
              break;
            case 7://Local de Coleta
              break;
            default:
              break;
          }

          this.users.push({
            label: externalLabel,
            value: externalId
          })

          this.form.externalId.setValue(externalId);
          this.alterCustomer = false;
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );
    }

    const myTempDialog = this._dialog.open(this.dialogModal, {
      width: '700px'
    });
  }

  async getExternalId() {
    let getCustomerParameters: string = (`?Name=${this.form.searchCustomer.value}&page=1&numberRegistry=9999999`);
    this.users = [];
    switch (this.type) {
      case 1://Cliente
        await this._customerService.get(getCustomerParameters).then(
          (res: any) => {
            if (res.data.length != 0) {
              res.data.forEach((client: any) => {
                this.users.push({
                  label: client.name,
                  value: client.id
                })
              });
            }
          }
        ).catch(
          (err) => {
            SwAlSetttings.printError(err);
          }
        );
        break;
      case 2://Unidade ou Filial
        break;
      case 3://Médico Solicitante
        await this._doctorsService.get(getCustomerParameters).then(
          (res) => {
            if (res.data.length != 0) {
              res.data.forEach((client: any) => {
                this.users.push({
                  label: client.name,
                  value: client.id
                })
              });
            }
          }
        ).catch(
          (err) => {
            SwAlSetttings.printError(err);
          }
        );
        break;
      case 4://Convênio
        await this._agreementService.get(getCustomerParameters).then(
          (res: any) => {
            if (res.data.length != 0) {
              res.data.forEach((client: any) => {
                this.users.push({
                  label: client.name,
                  value: client.id
                })
              });
            }
          }
        ).catch(
          (err) => {
            SwAlSetttings.printError(err);
          }
        );
        break;
      case 5://Plano do Convênio
        break;
      case 6://Destino de Entrega
        break;
      case 7://Local de Coleta
        break;
      default:
        break;
    }

    this.alterCustomer = false;
  }

  async saveAccess() {
    let type = null;

    let access = {};

    if (this.form.type1 && !type) {
      type = 1;
    }

    if (this.form.type3 && !type) {
      type = 3;
    }

    if (this.form.type4 && !type) {
      type = 4;
    }

    if (type) {
      if (this.accessId != new Guid(Guid.getEmpty())) {
        access = {
          externalAccessId: this.form.externalId.value,
          accessCode: this.form.accessCode.value,
          cellphone: this.form.cellPhone.value,
          name: this.form.name.value,
          accessType: type,
          active: this.form.active.value
        }

        await this._userAuthenticateService.put(access, this.accessId).then(
          (res) => {
            this._dialog.closeAll()
            this.get()
            this.accessId = new Guid(Guid.getEmpty());
          }
        ).catch(
          (err) => {
            SwAlSetttings.printError(err);
          }
        );
      } else {
        access = {
          externalAccessId: this.form.externalId.value,
          accessCode: this.form.accessCode.value,
          cellphone: this.form.cellPhone.value,
          name: this.form.name.value,
          accessType: type,
          active: true,
          password: this.form.password.value,
          confirmPassword: this.form.confirmPassword.value
        }

        await this._userAuthenticateService.post(access).then(
          (res) => {
            this._dialog.closeAll()
            this.get()
            this.accessId = new Guid(Guid.getEmpty());
          }
        ).catch(
          (err) => {
            SwAlSetttings.printError(err);
          }
        );
      }
    }
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    this.getParameters = (`?AccessType=${this.formSearch.type.value}`);

    if (this.formSearch.birthDate.value) {
      let splittedParam: string[] = this.formSearch.birthDate.value.split('/');
      let day: string = splittedParam[0];
      let month: string = splittedParam[1];
      let fullYear: string = splittedParam[2];
      this.getParameters += (`&BirthDate=${fullYear + '-' + month + '-' + day}`);
    }

    if (this.formSearch.register.value) {
      this.getParameters += (`&RegistrationNumber=${this.formSearch.register.value}`);
    }

    if (this.formSearch.name.value) {
      this.getParameters += (`&Name=${this.formSearch.name.value}`);
    }

    this.getParameters += (`&page=${this.page}&numberRegistry=${this.index}`);

    this.access = [];
    await this._userAuthenticateService.get(this.getParameters).then(
      (res: any) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolve(res.data, this.resolveaccess);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.access = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.access.push(obj);
    });
  }

  getEmailVerified(item: any) {
    return !item.isVerifiedEmail ? "<svg style=\"max-width: calc(34px * var(--proportional));\" width=\"34\" height=\"21\" viewBox=\"0 0 34 21\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <rect x=\"0.475647\" y=\"0.647827\" width=\"33.3333\" height=\"20\" rx=\"10\" fill=\"#808080\"/> <path d=\"M10.4756 18.6478C14.8939 18.6478 18.4756 15.0661 18.4756 10.6478C18.4756 6.22955 14.8939 2.64783 10.4756 2.64783C6.05737 2.64783 2.47565 6.22955 2.47565 10.6478C2.47565 15.0661 6.05737 18.6478 10.4756 18.6478Z\" fill=\"white\"/> </svg>" : "<svg style='max-width: calc(34px * var(--proportional));height: auto' width=\"34\" height=\"20\" viewBox=\"0 0 34 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <rect x=\"0.384644\" width=\"33.3333\" height=\"20\" rx=\"10\" fill=\"#FE7434\"/> <path d=\"M23.3846 18C27.8029 18 31.3846 14.4183 31.3846 10C31.3846 5.58172 27.8029 2 23.3846 2C18.9664 2 15.3846 5.58172 15.3846 10C15.3846 14.4183 18.9664 18 23.3846 18Z\" fill=\"white\"/> </svg>";
  }

  getActive(item: any) {
    return !item.isActive ? "<svg style=\"max-width: calc(34px * var(--proportional));\" width=\"34\" height=\"21\" viewBox=\"0 0 34 21\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <rect x=\"0.475647\" y=\"0.647827\" width=\"33.3333\" height=\"20\" rx=\"10\" fill=\"#808080\"/> <path d=\"M10.4756 18.6478C14.8939 18.6478 18.4756 15.0661 18.4756 10.6478C18.4756 6.22955 14.8939 2.64783 10.4756 2.64783C6.05737 2.64783 2.47565 6.22955 2.47565 10.6478C2.47565 15.0661 6.05737 18.6478 10.4756 18.6478Z\" fill=\"white\"/> </svg>" : "<svg style='max-width: calc(34px * var(--proportional));height: auto' width=\"34\" height=\"20\" viewBox=\"0 0 34 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <rect x=\"0.384644\" width=\"33.3333\" height=\"20\" rx=\"10\" fill=\"#FE7434\"/> <path d=\"M23.3846 18C27.8029 18 31.3846 14.4183 31.3846 10C31.3846 5.58172 27.8029 2 23.3846 2C18.9664 2 15.3846 5.58172 15.3846 10C15.3846 14.4183 18.9664 18 23.3846 18Z\" fill=\"white\"/> </svg>";
  }

  close() {
    this.accessId = new Guid(Guid.getEmpty());
    this._dialog.closeAll()
  }

  clickType1() {
    this.searchPlaceHolder = "Pesquisa Cliente";
    this.type = 1;
    this.form.type3.setValue(false);
    this.form.type4.setValue(false);
  }

  clickType3() {
    this.type = 3;
    this.searchPlaceHolder = "Pesquisa Médico";
    this.form.type1.setValue(false);
    this.form.type4.setValue(false);
  }

  clickType4() {
    this.type = 4;
    this.searchPlaceHolder = "Pesquisa Convênio";
    this.form.type3.setValue(false);
    this.form.type1.setValue(false);
  }

  Actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.accessId = new Guid(emit.object.id);
        this.newAccess();
        break;
    }
  }

  getType(item: any) {
    switch (item.type) {
      case 1:
        return 'Cliente';
      case 2:
        return 'Unidade ou Filial';
      case 3:
        return 'Médico Solicitante';
      case 4:
        return 'Convênio';
      case 5:
        return 'Plano do Convênio';
      case 6:
        return 'Destino de Entrega';
      case 7:
        return 'Local de Coleta';
      default:
        return '';
    }
  }
}
