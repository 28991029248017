import { AsyncPipe, CommonModule } from "@angular/common";
import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { PageableComponent } from "@app/modules_new/common/components/pageable";
import { FormSelectPropComponent } from "@app/modules_new/shared/components/form/select-prop/select-prop.component";
import { PaginationTableComponent } from "@app/modules_new/shared/components/form/table/pagination/pagination.component";
import { TableFormComponent } from "@app/modules_new/shared/components/form/table/table.component";
import { ContentFilterLayoutComponent } from "@app/modules_new/shared/layouts/content-filter/content-filter.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, FormGroup } from "@angular/forms";
import { PaginationEvent } from "@app/modules_new/shared/components/form/table/pagination/pagination.model";
import { RequestOptions } from "@app/modules_new/data/services/types/http.types";
import { GroupResponse } from "../../data/services/groups/groups.types";
import { tableActions, tableColumns } from "./groups";
import { GroupsFacade } from "../../data/facade/groups.facade";
import { GroupsModal } from "./groups-modal/groups-modal.component";
import { PermissionGroupsComponent } from "../../ui/permission-groups-ui/permission-groups-ui.component";
import { MatTabChangeEvent, MatTabsModule } from "@angular/material/tabs";
import { PermissionUsersGroupsComponent } from "../../ui/permission-users-ui/permission-users-ui.component";
import { PermissionGroupsUsersFacade } from "../../data/facade/permission-groups-users.facade";

@Component({
   selector: "groups",
   templateUrl: "./groups.component.html",
   standalone: true,
   imports: [
      ContentFilterLayoutComponent,
      TranslateModule,
      TableFormComponent,
      PaginationTableComponent,
      AsyncPipe,
      FormSelectPropComponent,
      PermissionGroupsComponent,
      MatTabsModule,
      PermissionUsersGroupsComponent,
      CommonModule
   ]
})
export class GroupsComponent
   extends PageableComponent<GroupResponse[]>
   implements OnInit
{
   constructor() {
      super(tableColumns, tableActions);
   }

   @ViewChild("groups") groupsTable!: TableFormComponent;

   private _dialog = inject(MatDialog);
   private _groupsFacade = inject(GroupsFacade);
   private _permissionGroupsUsersFacade = inject(PermissionGroupsUsersFacade);

   formFilterGroup = new FormGroup({
      description: new FormControl(null)
   });

   formFilter: Array<object> = [
      {
         placeholder: "Descrição",
         type: "text",
         form: this.formFilterGroup.controls["description"],
         click: true
      }
   ];

   selectedIndex = 0;

   ngOnInit() {
      this.get();
   }

   get(search?: boolean) {
      this.page = search === true ? 1 : this.page;
      var request: RequestOptions = {
         query: {
            page: this.page,
            numberRegistry: this.numberRegistry,
            search: this.formFilterGroup.controls.description.value ?? null
         }
      };

      const { data$, total$ } = this._groupsFacade.get(request);
      this.data = data$;
      this.total = total$;
   }

   ngAfterViewInit() {
      this.groupsTable.userInteractions$.subscribe((response) => {
         switch (response?.eventType) {
            case "DROPDOWN_PRESSED": {
               if (response.payload.event) {
                  this._groupsFacade.createTabsGroup(response.payload.data.id);
                  this._permissionGroupsUsersFacade.createTabsGroup(
                     response.payload.data.id
                  );
               }
               break;
            }
            case "IS_ACTIVE_CHANGE":
               this._groupsFacade
                  .patchActive(
                     response.payload.data.id,
                     response.payload.data.isActive
                  )
                  .then(() => this.get());
               break;
            case "ACTIONS_PRESSED":
               switch (response.payload.event) {
                  case "EDITAR":
                     this.openModal(response.payload.data);
                     break;
                  case "DELETE": {
                     this._groupsFacade.delete(response.payload.data.id);
                  }
               }
               break;
         }
      });
   }

   openModal(data?: any) {
      const dialog = this._dialog.open(GroupsModal, {
         width: "calc(900px * var(--proportional))",
         data: data
      });
      dialog.afterClosed().subscribe(() => {
         this.get();
      });
   }

   changePage(event: PaginationEvent) {
      this.page = event.page;
      this.numberRegistry = event.numberRegistry;
      this.get();
   }

   resetFilter() {
      this.formFilterGroup.controls.description.reset(null);
   }

   async matTabChange(changeEvent: MatTabChangeEvent) {
      this.selectedIndex = changeEvent.index;
   }
}
