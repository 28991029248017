import {
   AfterViewInit,
   Component,
   Input,
   ViewChild,
   ElementRef,
   OnDestroy
} from "@angular/core";
import { SVGOptions, type SvgKeys } from "./svg-selector.model";
import { CommonModule, NgStyle } from "@angular/common";
import { HtmlSanitizerPipe } from "../../../pipe/sanitizer-html.pipe";

@Component({
   selector: "ui-svg-selector",
   templateUrl: "./svg-selector.component.html",
   styleUrls: ["./svg-selector.component.scss"],
   standalone: true,
   imports: [CommonModule, HtmlSanitizerPipe, NgStyle]
})
export class SvgSelectorUIComponent implements AfterViewInit, OnDestroy {
   /**
    * Nome do SVG que será usado
    * @see {SVGOptions}
    */
   @Input() svg: SvgKeys = "empty";
   /**
    * Cor para preenchimento de SVG
    */
   @Input() fill = "";
   /**
    * Cor para hover de SVG
    */
   @Input() hover = "";

   @ViewChild("svgHtml", { static: false }) svgHtml!: ElementRef;
   private _svgNaviteElement!: HTMLElement;

   ngAfterViewInit(): void {
      if (this.fill || this.hover) {
         const divNative = this.svgHtml.nativeElement as HTMLDivElement;
         this._svgNaviteElement = divNative.childNodes[0] as HTMLElement;

         if (this.hover) {
            let defualtFill = "";
            this._svgNaviteElement.addEventListener("mouseover", () => {
               this._svgNaviteElement.childNodes.forEach((path) => {
                  const pathElement = path as HTMLElement;
                  defualtFill = pathElement.style.fill;
                  pathElement.style.fill = this.hover;
               });
            });
            this._svgNaviteElement.addEventListener("mouseout", () => {
               this._svgNaviteElement.childNodes.forEach((path) => {
                  const pathElement = path as HTMLElement;
                  pathElement.style.fill = defualtFill;
               });
            });
         }

         if (this.fill) {
            this._svgNaviteElement?.childNodes.forEach((path) => {
               const pathElement = path as HTMLElement;
               if (path.nodeName === "path" || path.nodeName === "rect") {
                  if (getComputedStyle(pathElement).stroke === "none") {
                     pathElement.style.fill = this.fill;
                  } else {
                     pathElement.style.stroke = this.fill;
                  }
               } else if (path.nodeName === "g") {
                  path.childNodes.forEach((item) => {
                     const internalPathElement = item as HTMLElement;
                     if (internalPathElement.nodeName === "path") {
                        internalPathElement.style.fill = this.fill;
                     }
                  });
               }
            });
         }
      }
   }

   ngOnDestroy(): void {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this._svgNaviteElement?.removeEventListener("mouseover", () => {});
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this._svgNaviteElement?.removeEventListener("mouseout", () => {});
   }

   get selectedSvg() {
      return SVGOptions[this.svg] || SVGOptions.empty;
   }
}
