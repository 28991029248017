import { Component, OnDestroy } from '@angular/core';
import { CollectService } from '@app/services/collect/collect.service';
import { colectionOrigin } from './collect.model';
import { Util } from '@app/util/util';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-collect',
  templateUrl: './collect.component.html',
  styleUrls: ['./collect.component.scss']
})
export class CollectComponent implements OnDestroy {
  registerRequisition: Array<Object> = [];
  subscription!: Subscription;

  constructor(
    private _collectService: CollectService,
    private _util: Util
  ) { }

  ngOnInit() {
    this.getRequisitionRecipients();
    this.updateTable();
  }

  updateTable(){
    const inteval = 5000;
    const timerObservable = interval(inteval);
    this.subscription = timerObservable.pipe().subscribe(() => {
      this.getRequisitionRecipients();
    });
  }

  async getRequisitionRecipients() {
    await this._collectService.getPagination({ resume: true }).then(
      async (res) => {
        this.resolve(res.data, colectionOrigin);
      }
    )
  }

  resolve(data: any, columns: any) {
    this.registerRequisition = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerRequisition.push(obj);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
