import { Injectable } from '@angular/core';
import { BaseAuthService } from "./base-auth.service";
import { HttpAuthService } from './http-auth.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileUserAuth extends BaseAuthService {
    constructor(HttpAuth: HttpAuthService) {
        super(HttpAuth, 'v1/profile/user');
    }

    async getCurrentUser(): Promise<any> {
        const user: any = localStorage.getItem('user');
        let result: any = undefined;

        if (!user || user === null) {
            const response: any = await this.getAll();

            result = response.data[0];
            localStorage.setItem('user', JSON.stringify(result));
        } else {
            result = JSON.parse(user);
        }

        return new Promise((resolve) => {
            resolve(result);
        });
    }
}
