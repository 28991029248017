import { AsyncPipe } from "@angular/common";
import { Component, inject, ViewChild } from "@angular/core";
import { PageableComponent } from "@app/modules_new/common/components/pageable";
import { PaginationTableComponent } from "@app/modules_new/shared/components/form/table/pagination/pagination.component";
import { TableFormComponent } from "@app/modules_new/shared/components/form/table/table.component";
import { ContentFilterLayoutComponent } from "@app/modules_new/shared/layouts/content-filter/content-filter.component";
import { TranslateModule } from "@ngx-translate/core";
import { tableActions, tableColumns } from "./departments";
import { MatDialog } from "@angular/material/dialog";
import { PaginationEvent } from "@app/modules_new/shared/components/form/table/pagination/pagination.model";
import { FormControl, FormGroup } from "@angular/forms";
import { DepartmentResponse } from "@app/modules_new/registers/data/services/departments/departments.types";
import { DepartmentsModal } from "./modal/departments.modal.component";
import { DepartmentsFacade } from "../../data/facade/departments.facade";
import { RequestOptions } from "@app/modules_new/data/services/types/http.types";

@Component({
   selector: "app-departments",
   templateUrl: "./departments.component.html",
   standalone: true,
   providers: [ContentFilterLayoutComponent],
   imports: [
      ContentFilterLayoutComponent,
      TranslateModule,
      TableFormComponent,
      PaginationTableComponent,
      AsyncPipe
   ]
})
export class DepartmentsComponent extends PageableComponent<
   DepartmentResponse[]
> {
   private _departmentsFacade = inject(DepartmentsFacade);
   private _dialog = inject(MatDialog);

   constructor() {
      super(tableColumns, tableActions);
   }

   formFilterGroup = new FormGroup({
      search: new FormControl(),
      isActive: new FormControl(null)
   });

   formFilter: Array<object> = [
      {
         placeholder: "Departamento",
         type: "text",
         form: this.formFilterGroup.controls["search"],
         click: true
      },
      {
         placeholder: "agreements.filter.isActive",
         type: "toggle",
         form: this.formFilterGroup.controls["isActive"]
      }
   ];

   @ViewChild("module") moduleTable!: TableFormComponent;

   ngOnInit() {
      this.get();
   }

   get() {
      const obj: RequestOptions = {
         query: {
            search: this.formFilterGroup.controls.search.value,
            isActive: this.formFilterGroup.controls.isActive.value
               ? this.formFilterGroup.controls.isActive.value
               : null
         }
      };

      const { data$, total$ } = this._departmentsFacade.getDepartments(
         1,
         30,
         obj
      );
      this.data = data$;
      this.total = total$;
   }

   ngAfterViewInit(): void {
      this.moduleTable.userInteractions$.subscribe((response) => {
         switch (response?.eventType) {
            case "IS_ACTIVE_CHANGE": {
               this._departmentsFacade.patchActive(
                  response.payload.data.id,
                  response.payload.data.isActive
               );
               break;
            }
            case "ACTIONS_PRESSED": {
               switch (response.payload.event) {
                  case "EDITAR": {
                     this.openModal(response.payload.data);
                     break;
                  }
               }
            }
         }
      });
   }

   resetFilter() {
      this.formFilterGroup.reset();
   }

   changePage(event: PaginationEvent) {
      this.page = event.page;
      this.numberRegistry = event.numberRegistry;
      this._departmentsFacade.getDepartments(event.page, event.numberRegistry);
   }

   openModal(data?: any) {
      const dialog = this._dialog.open(DepartmentsModal, {
         width: "660px",
         data
      });
   }
}
