import { Pipe, PipeTransform, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

/**
 * Pipe responsável por retornar erros já traduzidos
 */
@Pipe({
   name: "errorTranslate",
   standalone: true
})
export class ErrorTranslatePipe implements PipeTransform {
   private _translate = inject(TranslateService);

   transform(error: any, value: any): string {
      let message = "";
      const typeOfValue = typeof value;
      if (error) {
         if (error["required"]) {
            this._translate.get("error.obrigatory").subscribe((res: string) => {
               message = res;
            });
         } else if (error["minlength"]) {
            this._translate.get("error.minlength").subscribe((res: string) => {
               message = this._replateAll(
                  res,
                  error["minlength"].requiredLength,
                  typeOfValue === "string" ? "caractéres" : "números",
                  error["minlength"].actualLength
               );
            });
         }
      }

      return message;
   }

   private _replateAll(message: string, ...values: string[]) {
      let finalMessage = message;
      for (let index = 0; index < values.length; index++) {
         finalMessage = finalMessage.replace(`{${index}}`, values[index]);
      }
      return finalMessage;
   }
}
