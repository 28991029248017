import { Component, ElementRef, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Location } from '@angular/common'
import { SwAlSetttings } from "@app/util/swal.settings";
import html2canvas from 'html2canvas';
import jsPDF, { AcroFormField } from 'jspdf';
import decodedToken from '@app/util/Token';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { content } from 'html2canvas/dist/types/css/property-descriptors/content';
import { Guid } from '@app/util/guid';

@Component({
  selector: 'app-requisition-protocol-report',
  templateUrl: './requisition_protocol.component.html',
  styleUrls: ['./requisition_protocol.component.scss']
})
export class RequisitionProtocolReportComponent implements OnInit {
  @ViewChildren('inputBinding') inputBinding: QueryList<any> | undefined;
  @ViewChild('generatePDF') childrenGeneratePDF!: ElementRef;

  Request = '';
  getView = false;
  pages = [[]];
  pdf!: jsPDF;
  i: number = 0;

  generate: boolean = false;

  unityDescription!: string | null;
  unityAddress!: string | null;
  requisitionNumber: number = 0;
  agreementDescription: string = '';
  age: number = 0;
  genderType: number = 0;
  formattedGenderType!: string | null;
  createdAt: Date = new Date();
  dateNow: Date = new Date(Date.now());
  customerName: string = '';
  userName: string = '';
  doctorName: string = '';
  collectionPlaceDescription: string = '';
  agreementPlanDescription: string = '';
  analyteList: Array<Object> = [];
  financial: Array<Object> = [];

  constructor(
    private _location: Location,
  ) { }

  ngOnInit(): void {
    this.getRequest();
    this.getView = true;
    this.pdf = new jsPDF('p', 'mm', 'a4');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.getView) {
        var maxHeight = 1400;
        var height = 0;
        // @ts-ignore
        var items = this.inputBinding.toArray();
        var pages = [[]];
        var page = 0;
        items.forEach((x: any, int: number) => {

          //@ts-ignore
          if (int + 1 <= this.Request.analytes.length) {
            if (height + x.nativeElement.offsetHeight <= maxHeight) {
              height += x.nativeElement.offsetHeight;
              // @ts-ignore
              pages[page].push(this.Request.analytes[int]);
            } else {
              pages.push([]);
              page++;
              // @ts-ignore
              pages[page].push(this.Request.analytes[int]);
              height = 0;
              height += x.nativeElement.offsetHeight;
            }
          }
        });
        this.pages = pages;
        this.generate = true;
        this.getView = false;
        SwAlSetttings.closeLoader()
        console.timeEnd("ngAfterView");
      }
    });
  }

  getRequest() {
    //@ts-ignore
    this.Request = JSON.parse(localStorage.getItem('request'));
    //@ts-ignore
    this.requisitionNumber = this.Request.requisition;
    //@ts-ignore
    this.age = this.Request.age;
    //@ts-ignore
    this.agreementDescription = this.Request.agreement;
    //@ts-ignore
    this.Request.analytes.forEach((analyte) => {
      this.analyteList.push(analyte);
    });
    //@ts-ignore
    this.collectionPlaceDescription = this.Request.collectionPlace;
    //@ts-ignore
    this.createdAt = this.Request.createdAt;
    //@ts-ignore
    this.customerName = this.Request.customer;
    //@ts-ignore
    this.doctorName = this.Request.doctor;
    //@ts-ignore
    this.genderType = this.Request.genderType;
    if (this.genderType) {
      switch (this.genderType) {
        case 1:
          this.formattedGenderType = 'Feminino';
          break;
        case 2:
          this.formattedGenderType = 'Masculino';
          break;
        default:
          this.formattedGenderType = 'Não Informado';
      }
    }

    //@ts-ignore
    this.agreementPlanDescription = this.Request.plan;

    this.userName = decodedToken.name;
    if (this.userName) {
      this.userName = this.userName.toUpperCase();
    }

    //@ts-ignore
    this.unityDescription = this.Request.unity;
    //@ts-ignore
    this.unityAddress = this.Request.unityAddress.split(',');
  }

  date() {
    var date = new Date();
    return date.toLocaleString()
  }

  exportPDF() {
    return this.pdf.output();
  }

  generatePDF() {
    console.time("generatePDF");
    console.timeLog("generatePDF");

    SwAlSetttings.loader();
    this.i = 0;
    this.pdf = new jsPDF('p', 'mm', 'a4');

    console.timeEnd("generatePDF");
  }

  back() {
    this._location.back()
  }
}