import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonComponentModule } from '@app/components/common-component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import {MatStepperModule} from '@angular/material/stepper';
import { QueriesRoutingModule } from './queries-routing.module';
import { EnvioSmsEmailComponent } from './components/sms-email-logging/sms-email.component';
import { SmsEmailParameters } from './components/sms-email-parameters/sms-email-parameters';

@NgModule({
  imports: [
    CommonModule,
    CommonComponentModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatTabsModule,
    MatCheckboxModule,
    MatStepperModule
  ],
  declarations: [
    EnvioSmsEmailComponent,
    SmsEmailParameters
  ],
  exports: [
    QueriesRoutingModule,
  ]
})
export class QueriesModule { }
