import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { GetAllDoctors, GetByIdDoctors, PostDoctors } from './doctors.types';
import { Guid } from 'src/app/util/guid';

@Injectable({
  providedIn: 'root'
})

export class DoctorsService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/doctor');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllDoctors>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetAllDoctors>>
  }

  override getById(id: Guid) {
    return super.getById(id) as Promise<HttpResponsePageable<GetByIdDoctors>>
  }

  override get(queryString: string) {
    return super.get(queryString) as Promise<HttpResponsePageable<GetAllDoctors>>
  }

  override post(body: Object) {
    return super.post(body) as Promise<PostDoctors>
  }

  override delete(id: Guid) {
    return super.delete(id) as Promise<HttpResponsePageable<any>>
  }
}
