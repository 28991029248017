<modal-layout [title]="'Menu' | translate">
    <form [formGroup]="formGroup" class="flex flex-col gap-[32px]">
        <div class="flex gap-form w-full">
            <forms-input-text
                class="w-full"
                type="text"
                [placeholder]="'Descrição'"
                [form]="formGroup.controls.description"
            ></forms-input-text>
            <forms-input-text
                class="w-full"
                type="text"
                [placeholder]="'URL'"
                [form]="formGroup.controls.url"
            ></forms-input-text>
        </div>
        <forms-button
            #button
            class="self-center"
            (click)="
                button.isDisabled
                    ? null
                    : _dialogData?.obj?.id
                    ? putMenu()
                    : postMenu();
                $event.preventDefault()
            "
            [validate]="formGroup"
            classExtra="btn btn-primary"
            [text]="'customer.register.save' | translate"
        ></forms-button>
    </form>
</modal-layout>
