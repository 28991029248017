import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { EquipmentsInterfacingService } from '@app/services/equipments/equipments.service';
import { SwAlSetttings } from '@app/util/swal.settings';
import { ObjectActions, Actions, ResolveEquipments } from './equipments.model'
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';

@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.scss']
})
export class EquipmentsComponent implements OnInit {

  total: number = 0;
  page: number = 1;
  index: number = 50;

  equipments: Array<object> = [];

  Search = new UntypedFormControl();
  active = new UntypedFormControl();

  resolveEquipments = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Equipamento',
      retrive: 'name',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Código',
      retrive: 'code',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Protocolo',
      retrive: '',
      method: 'getTypeProtocol',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ];

  typeProtocol: Array<object> = [
    {
      label: 'UriSed',
      value: 1
    },
    {
      label: 'AlinIQ',
      value: 2
    },
    {
      label: 'Alinity',
      value: 3
    },
    {
      label: 'Architect',
      value: 4
    },
    {
      label: 'AXSYM',
      value: 5
    },
    {
      label: 'CellDyn_1700',
      value: 6
    },
    {
      label: 'CellDyn_3000',
      value: 7
    },
    {
      label: 'CellDyn_3000_TXT',
      value: 8
    },
    {
      label: 'CellDyn_3200',
      value: 9
    },
    {
      label: 'CellDyn_3200_TXT',
      value: 10
    },
    {
      label: 'CellDyn_3500',
      value: 11
    },
    {
      label: 'CellDyn_3500_TXT',
      value: 12
    },
    {
      label: 'CellDyn_Ruby',
      value: 13
    },
    {
      label: 'NexGen',
      value: 14
    }
    ,
    {
      label: 'HBL',
      value: 15
    },
    {
      label: 'AlifaxTeste1',
      value: 16
    },
    {
      label: 'AUTOSoft',
      value: 17
    },
    {
      label: 'AutoMate2500',
      value: 18
    },
    {
      label: 'DXH',
      value: 19
    }
  ]

  constructor(
    private _equipmentsInterfacingService: EquipmentsInterfacingService,
    private _router: Router,
    private _localaDatePipe: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe
  ) { }

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  ngOnInit() {
    this.get();
  }

  redirect(equipmentId?: string) {
    this._router.navigateByUrl(`Cadastros/Interfaceamento/Equipamentos/${!equipmentId ? 'Novo' : equipmentId}`);
  }

  getTypeProtocol(item: any) {
    return (this.typeProtocol.find((protocol: any) => protocol.value == item.typeProtocol) as any).label;
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let search: string = this.Search.value ? this.Search.value : null;

    let isActive: string = this.active.value ? this.active.value : null;

    await this._equipmentsInterfacingService.getPagination({ page: this.page, numberRegistry: this.index, isActive, search }).then(
      (res: any) => {
        this.equipments = [];
        this.total = res.sumRecords;
        if (res.data.length != 0) {
          this.resolve(res.data, this.resolveEquipments);
        }
      }
    ).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.equipments.push(obj);
    });
  }

  getActive(item: ObjectActions) {
    return this._activePipe.transform(item.isActive)
  }

  getCreated(item: ObjectActions) {
    return this._localaDatePipe.transform(item.createdAt);
  }

  getUpdated(item: ObjectActions) {
    return this._localaDatePipe.transform(item.updatedAt);
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.redirect(emit.object.id);
        break;
      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  putActive(id: string, value: string) {
    this._equipmentsInterfacingService.patchActive(id, value).then((x: any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.get();
    }).catch(err => SwAlSetttings.printError(err))
  }

  resetFilter() {
    this.Search.reset();
    this.active.reset();
  }

}
