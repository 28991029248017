import {
   AfterViewInit,
   Component,
   ElementRef,
   Inject,
   OnInit,
   QueryList,
   TemplateRef,
   ViewChild,
   ViewChildren
} from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CustomersService } from "@app/services/customers/customers.service";
import {
   GetAllCustomers,
   GetByIdCustomers
} from "@app/services/customers/customers.types";
import { RequisitionService } from "@app/services/requisitions/requisition.service";
import { Guid } from "@app/util/guid";
import { SwAlSetttings } from "@app/util/swal.settings";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { BudgetService } from "@app/services/budget/budget.service";
import { InputPropComponent } from "@app/components/form/input-prop/input-prop.component";
import { AdvancedMaintenanceCopyService } from "@app/services/advanced-maintenance-copy/advanced-maintenance-copy.service";

enum FilterType {
   CPF = 1,
   BirthDate = 2,
   CustomerName = 3
}

@Component({
   selector: "app-modal-requisitions",
   templateUrl: "./modal-requisitions.component.html",
   styleUrls: ["./modal-requisitions.component.scss"]
})
export class ModalRequisitionsComponent implements OnInit, AfterViewInit {
   FilterType = FilterType;
   @ViewChild("mainInput") mainInput!: InputPropComponent;

   validateSearch: boolean = false;
   //#region Global Variables / Variáveis Globais
   total: number = 0;
   page: number = 1;
   index: number = 5;

   Extras: Array<Object> = [
      {
         type: "svg",
         action: "Select",
         content: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2_97)">
        <rect x="0.501465" y="0.0512695" width="21" height="21" rx="10.5" fill="#808080"/>
        <rect x="3.50146" y="3.05127" width="15" height="15" rx="7.5" fill="#F5F5F5"/>
        <rect x="5.50146" y="9.05127" width="11" height="3" rx="1.5" fill="#808080"/>
        <rect x="9.50146" y="5.05127" width="3" height="11" rx="1.5" fill="#808080"/>
        </g>
        <defs>
        <clipPath id="clip0_2_97">
        <rect width="21" height="21" fill="white" transform="translate(0.501465 0.0512695)"/>
        </clipPath>
        </defs>
      </svg>`
      }
   ];
   //#endregion

   //#region Customer / Patient Variables / Variáveis do Paciente / Cliente
   searchCustomer: boolean = false;
   customerId: string = "";
   patientExists: boolean = true;
   customerData: any;

   filterOptions = [
      {
         label: "Filtrar por CPF",
         value: FilterType.CPF
      },
      {
         label: "Filtrar por Nome",
         value: FilterType.CustomerName
      },
      {
         label: "Filtrar por Data de Nascimento",
         value: FilterType.BirthDate
      }
   ];

   gender = {
      0: "Não Informado",
      1: "Feminino",
      2: "Masculino"
   };
   //#endregion

   //#region Requisition / Requisição
   requisitionId: string = "";
   //#endregion
   budgetId: string = "";

   //#region Register and Resolve
   registerCustomers: Array<Object> = [];
   resolveCustomers: Array<Object> = [
      {
         label: "id",
         retrive: "id",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Nome",
         retrive: "name",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "CNPJ/CPF",
         retrive: "",
         method: "formatCPF",
         after: "",
         before: ""
      },
      {
         label: "Nascimento",
         retrive: "",
         method: "getBirthDate",
         after: "",
         before: ""
      },
      {
         label: "Sexo",
         retrive: "",
         method: "convertGenderType",
         after: "",
         before: ""
      },
      {
         label: "extra",
         retrive: "getExtra",
         method: "",
         after: "",
         before: ""
      }
   ];
   //#endregion

   //#region Forms / Formulários
   form = {
      filter: new UntypedFormControl(null, [Validators.required]),
      search: new UntypedFormControl(null, [Validators.required])
   };
   //#endregion

   @ViewChildren(InputPropComponent) comp!: QueryList<InputPropComponent>;

   constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _dialog: MatDialog,
      private _customerService: CustomersService,
      private _requisitionService: RequisitionService,
      private _router: Router,
      private _localeData: LocalaDatePipe,
      private _budgetService: BudgetService,
      private _advancedMaintenanceCopy: AdvancedMaintenanceCopyService
   ) {}

   ngOnInit() {
      this.form.filter.setValue(FilterType.CustomerName);
   }

   ngAfterViewInit(): void {
      this.inputFocus();
   }

   inputFocus() {
      const queryListArray: Array<InputPropComponent> = this.comp.toArray();
      const codeInputProp = queryListArray.find(
         (x) => x.placeholder == "Digite aqui o Nome"
      );
      if (codeInputProp) {
         codeInputProp.input.nativeElement.focus();
      }
   }

   formatCPF(item: any) {
      return item.cpf ? item.cpf : "";
   }

   async actions(emit: any) {
      switch (emit.action) {
         case "Select":
            this.customerId = emit.object.id;
            if (!this.data.budget) {
               //REQUISICAO
               await this.createRequisition(this.customerId);
               //this.closeModal();
               //this.router.navigateByUrl(`Cadastros/Requisicoes/${this.customerId}$${this.requisitionId}$Novo`)
            } else {
               await this.createBudget(this.customerId); //CRIAR ORCAMENTO
               this.closeModal();
               this._router.navigateByUrl(
                  `MovimentacaoOld/Recepcao/Orcamentos/View$${this.budgetId}`
               );
            }
            break;
      }
   }

   async getCustomerById(customerId: any) {
      if (customerId != "") {
         let customerIdGuid: Guid = new Guid(this.customerId);
         await this._customerService.getById(customerIdGuid).then((res) => {
            this.customerData = res.data[0];
         });
      }
   }

   async createBudget(customerId: string) {
      await this.getCustomerById(customerId);

      let budget = {
         customerId: this.customerData.id,
         unityId: this.data.dialogUnityId,
         collectionPlaceId: this.data.dialogCollectionPlaceId
      };

      await this._budgetService.post(budget).then((res) => {
         this.budgetId = res.id;
         SwAlSetttings.Sucesso("Orçamento criado com sucesso!");
      });
   }

   async createRequisition(customerId?: string) {
      let requisition: object;
      if (customerId) {
         await this.getCustomerById(customerId);

         requisition = {
            isActive: true,
            name: this.customerData.name,
            customerId: this.customerData.id,
            unityId: this.data.dialogUnityId,
            collectionPlaceId: this.data.dialogCollectionPlaceId,
            companyId: this.data.companyId
         };
      } else {
         requisition = {
            isActive: true,
            name: this.form.search.value,
            customerId: null,
            unityId: this.data.dialogUnityId,
            collectionPlaceId: this.data.dialogCollectionPlaceId,
            companyId: this.data.companyId
         };
      }
      if (this.data.copy) {
         await this._advancedMaintenanceCopy
            .postByPath(
               { customerId: this.customerData.id },
               this.data.requisitionId
            )
            .then(() => {
               SwAlSetttings.Sucesso("Requisição copiada com sucesso!");
               this.closeModal();
            })
            .catch((err) => {
               SwAlSetttings.printError(err);
            });
      } else {
         await this._requisitionService.post(requisition).then((res) => {
            this.requisitionId = res.id;
            this.closeModal();
            //customerId ? this.router.navigateByUrl(`Cadastros/Requisicoes/${this.customerId}$${this.requisitionId}$Novo`) : this.router.navigateByUrl(`Cadastros/Requisicoes/SemCliente$${this.requisitionId}$Novo`)
            localStorage.setItem(`autosave://lastURL`, this._router.url);
            this._router.navigateByUrl(
               `MovimentacaoOld/Recepcao/Requisicoes/${this.requisitionId}`
            );
            setTimeout(() => {
               SwAlSetttings.Sucesso("Requisição criada com sucesso!");
            }, 2500);
         });
      }
   }

   getExtra() {
      return this.Extras;
   }

   getBirthDate(item: GetAllCustomers) {
      return this._localeData.transform(
         item.birthDate,
         undefined,
         "dd/MM/yyyy"
      );
   }

   convertGenderType(item: any) {
      //@ts-ignore
      return item.genderType ? this.gender[item.genderType] : this.gender[0];
   }

   async getCustomers(paramPage?: number[]) {
      this.page = 1;
      this.index = 5;

      if (paramPage) {
         this.page = paramPage[0];
         this.index = paramPage[1];
      }

      let getParameters: string;
      let param: string = this.form.search.value;

      if (this.form.filter.value == FilterType.CPF) {
         this.validateSearch = true;
         getParameters = `?IsActive=true&Cpf=${param}&page=${this.page}&OrderBy=Name,asc&numberRegistry=${this.index}`;
      } else if (this.form.filter.value == FilterType.CustomerName) {
         this.validName(this.form.search.value.length);
         getParameters = `?IsActive=true&Name=${param}&OrderBy=Name,asc&page=${this.page}&numberRegistry=${this.index}`;
      } else if (this.form.filter.value == FilterType.BirthDate) {
         let splittedParam: string[] = param.split("/");
         let day: number = parseFloat(splittedParam[0]);
         let month: number = parseFloat(splittedParam[1]);
         let fullYear: number = parseFloat(splittedParam[2]);
         this.validDate(day, month, fullYear);
         getParameters = `?IsActive=true&BirthDate=${
            fullYear + "-" + month + "-" + day
         }&page=${this.page}&numberRegistry=${this.index}&OrderBy=Name,asc`;
      } else {
         getParameters = `?IsActive=true&page=${this.page}&numberRegistry=${this.index}&OrderBy=Name,asc`;
      }

      if (this.validateSearch) {
         await this._customerService
            .getByPath("", getParameters)
            .then((res) => {
               if (res.data.length != 0) {
                  this.total = res.sumRecords;
                  this.resolve(res.data, this.resolveCustomers);
                  this.patientExists = true;
               } else {
                  this.registerCustomers = [];
                  switch (this.form.filter.value) {
                     case FilterType.CPF:
                        SwAlSetttings.Error(
                           `Cliente com CPF "${this.form.search.value}" não encontrado!`
                        );
                        break;
                     case FilterType.BirthDate:
                        SwAlSetttings.Error(
                           `Cliente com data de nascimento "${this.form.search.value}" não encontrado!`
                        );
                        break;
                     default:
                        SwAlSetttings.Error(
                           `Cliente "${this.form.search.value}" não encontrado!`
                        );
                        break;
                  }
                  this.patientExists = false;
               }
            });
      }
   }

   validName(name: number) {
      if (name > 2) {
         this.validateSearch = true;
      } else {
         this.validateSearch = false;
         SwAlSetttings.warningMessage(
            "A pesquisa deve conter pelo menos 3 caracteres"
         );
      }
   }

   validDate(dia: number, mes: number, ano: number) {
      //@ts-ignore
      if (
         dia < 32 &&
         mes < 13 &&
         ano > 1900 &&
         ano <= new Date().getFullYear()
      ) {
         this.validateSearch = true;
      } else {
         this.validateSearch = false;
         setTimeout(() => {
            SwAlSetttings.warningMessage("Data de nascimento inválida!");
         });
      }
   }

   resolve(data: any, columns: any) {
      this.registerCustomers = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registerCustomers.push(obj);
      });
   }

   generateObject = (options: any) => {
      return {
         value: options,
         label: options
      };
   };

   async navigate() {
      await this.getCustomers();
   }

   createCustomer() {
      localStorage.setItem("unityId", this.data.dialogUnityId);
      localStorage.setItem(
         "collectionPlaceId",
         this.data.dialogCollectionPlaceId
      );
      this._router.navigateByUrl(`Cadastros/Atendimento/Clientes/Novo`);
      this._dialog.closeAll();
   }

   closeModal() {
      this._dialog.closeAll();
   }

   customerSearch() {
      this.searchCustomer = false;
      this.patientExists = true;
   }

   reset() {
      this.registerCustomers = [];
      this.form.search.reset();
   }
}
