import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})

export class VaccineService extends BaseService {
  constructor(HttpService: HttpService) {
    super(HttpService, 'v1/lab/vaccines/vaccine');
  }

}
