import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '@app/services/company/company.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Location } from '@angular/common';


@Component({
  selector: 'app-save-company',
  templateUrl: './save-company.component.html',
  styleUrls: ['./save-company.component.scss']
})
export class SaveCompanyComponent implements OnInit {
  private _toastService: any;
  constructor(
    private _companyService: CompanyService,
    private _route: ActivatedRoute,
    private _location: Location
  ) {
    if (_route.snapshot.params['id']) {
      this.companyId = _route.snapshot.params['id'];
    }
  }

  companyId : string = '';
  tenant : string = '';

  form = {
    cep : new UntypedFormControl(null),
    empresa : new UntypedFormControl(null),
    externalId: new UntypedFormControl(null),
    cnpj : new UntypedFormControl(null),
    Estado : new UntypedFormControl(null),
    Cidade : new UntypedFormControl(null),
    Bairro : new UntypedFormControl(null),
    Logradouro : new UntypedFormControl(null),
    Numero : new UntypedFormControl(null),
    Complemento : new UntypedFormControl(null),
    Imagem : new UntypedFormControl(null),
    missao : new UntypedFormControl(null),
    visao : new UntypedFormControl(null),
    valores : new UntypedFormControl(null),
    socialReason: new UntypedFormControl(null),
    country: new UntypedFormControl(null),
  }

  ngOnInit(): void {
    if(this.companyId) {
      this.getCompany();
    }
  }

  getCompany() {
    this._companyService.getById(new Guid(this.companyId)).then((x:any) => {
      const response = x.data[0]
      const address = response.address
      
      this.form.externalId.setValue(response.externalId);
      this.form.empresa.setValue(response.companyName);
      this.form.socialReason.setValue(response.socialReason);
      this.form.cnpj.setValue(response.cnpj);
      this.form.Imagem.setValue(response.fileImageId);
      this.form.visao.setValue(response.vision);
      this.form.missao.setValue(response.mission);
      this.form.valores.setValue(response.values);
      this.form.cep.setValue(address.zipCode);
      this.form.Logradouro.setValue(address.street);
      this.form.Estado.setValue(address.state);
      this.form.Cidade.setValue(address.city);
      this.form.Numero.setValue(address.number);
      this.form.Bairro.setValue(address.neighborhood);
      this.form.Complemento.setValue(address.complement);
      this.form.country.setValue(address.country)
    });
  }

  postCompany() {
    var object = {
      externalId: this.form.externalId.value,
      socialReason: this.form.empresa.value,
      companyName: this.form.empresa.value,
      cnpj: {
        value: this.form.cnpj.value,
      },
      isActive: true,
      mission: this.form.missao.value,
      vision: this.form.visao.value,
      values: this.form.valores.value,
      address: {
        zipCode: this.form.cep.value,
        street: this.form.Logradouro.value,
        neighborhood: this.form.Bairro.value,
        number: this.form.Numero.value,
        complement: this.form.Complemento.value,
        city: this.form.Cidade.value,
        state: this.form.Estado.value,
        country: this.form.country.value,
      }
    }
    this._companyService.post(object).then(
      (res : any) => {
        SwAlSetttings.Sucesso('Empresa cadastrada com sucesso!');
        this.back();
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  editCompany() {
    var object = {
      externalId: this.form.externalId.value,
      socialReason: this.form.socialReason.value,
      companyName: this.form.empresa.value,
      cnpj: {
        value: this.form.cnpj.value,
      },
      isActive: true,
      mission: this.form.missao.value,
      vision: this.form.visao.value,
      values: this.form.valores.value,
      address: {
        zipCode: this.form.cep.value,
        street: this.form.Logradouro.value,
        neighborhood: this.form.Bairro.value,
        number: this.form.Numero.value,
        complement: this.form.Complemento.value,
        city: this.form.Cidade.value,
        state: this.form.Estado.value,
        country: this.form.country.value,
      }
    }
    this._companyService.put(object, new Guid(this.companyId)).then(
      (res : any) => {
        SwAlSetttings.Sucesso('Dados atualizados com sucesso!');
        this.back();
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  back() {
    this._location.back();
  }

  async getCep(){    
    this.form.Logradouro.reset();
    this.form.Bairro.reset();
    this.form.Numero.reset();
    this.form.Complemento.reset();
    this.form.Cidade.reset();
    this.form.Estado.reset();
    await fetch(`https://viacep.com.br/ws/${this.form.cep.value}/json/`)
      .then(response => {
        return response.json();
      }).then(json => {
        if (!("erro" in json)) {
          this.form.cep.setValue(json.cep);
          this.form.Logradouro.setValue(json.logradouro)
          this.form.Bairro.setValue(json.bairro);
          this.form.Numero.value;
          this.form.Complemento.value;
          this.form.Cidade.setValue(json.localidade);
          this.form.Estado.setValue(json.uf);    
          this.form.country.setValue('Brasil');
        } else {          
          this.form.cep.reset();
          this._toastService.warningMessage("company.msgNotCEP");
        }
      });
  }
}
