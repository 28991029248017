import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UnityService } from '@app/services/auth/unity/unity-service.service';
import { TemplateService } from '@app/services/template/template.service';
import { ETypeTemplate, PostTemplate } from '@app/services/template/template.types';
import { SwAlSetttings } from '@app/util/swal.settings';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { IDynamicTable, IProtocolBody, ITableReader } from './dynamic-table.model';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';

@Component({
  selector: 'app-template-generator',
  templateUrl: './template-generator.component.html',
  styleUrls: ['./template-generator.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TemplateGeneratorComponent implements OnInit {

  @ViewChild('printPage', { static: true }) printPage!: ElementRef;

  currentScale = 1

  zoomIn() {
    this.currentScale += 0.1;
  }

  zoomOut() {
    this.currentScale -= 0.1;
  }

  resetZoom() {
    this.currentScale = 1;
  }


  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _unityService: UnityService,
    private _templateService: TemplateService
  ) { }

  // Variáveis gerais:
  htmlStructure: HTMLElement | null = null;
  title: string = '';
  bodyView: boolean = false;
  protocolHeight: number = 1240;

  // Variáveis que irão receber a lista de Elementos HTML (ElementRef):
  FirstHeaderHtmlElements: Array<string> = [];
  SecondHeaderHtmlElements: Array<string> = [];
  FooterHtmlElements: Array<string> = [];

  // Lista de unidades
  unityOptions: Array<Object> = [];

  // Lista de opções de variáveis.
  SelectOptions: Array<Object> = [
    {
      label: '*** Nenhum ***',
      value: 'none'
    },
    {
      label: 'Nome da Empresa',
      value: 'nome_empresa'
    },
    {
      label: 'Endereço da Empresa',
      value: 'endereco_empresa'
    },
    {
      label: 'Nome da Filial',
      value: 'nome_filial'
    },
    {
      label: 'Endereço da Filial',
      value: 'endereco_filial'
    },
    {
      label: 'Código de Barras da Requisição',
      value: 'codigo_barras_requisicao'
    },
    {
      label: 'Número da Requisição',
      value: 'numero_requisicao'
    },
    {
      label: 'Data de Entrada da Requisição',
      value: 'data_entrada_requisicao'
    },
    {
      label: 'Data de Previsão da Requisição',
      value: 'data_previsao_requisicao'
    },
    {
      label: 'Idade do Cliente',
      value: 'idade_cliente'
    },
    {
      label: 'Sexo do Cliente',
      value: 'sexo_cliente'
    },
    {
      label: 'Nome do Cliente',
      value: 'nome_cliente'
    },
    {
      label: 'Nome do Solicitante',
      value: 'nome_solicitante'
    },
    {
      label: 'Descrição do Convênio',
      value: 'descricao_convenio'
    },
    {
      label: 'Descrição do Local de Coleta',
      value: 'descricao_local_coleta'
    }
  ];

  // Lista de tipos de formatação de texto (h1, h2, span).
  SelectTypeOptions: Array<Object> = [
    {
      label: 'Título',
      value: 'h1'
    },
    {
      label: 'Subtítulo',
      value: 'h2'
    },
    {
      label: 'Texto',
      value: 'span'
    }
  ];

  // Lista do formato de papel (a4, a5).
  /* NOTE Removido temporariamente pois é necessário ajustar o tamanho do A5 em pixels.
  SelectFormatOfPaper: Array<Object> = [
    {
      label: 'A4',
      value: 'a4'
    },
    {
      label: 'A5',
      value: 'a5'
    }
  ];
  */

  // Lista de controle da edição do protocolo.
  EditOptions: Array<Object> = [
    {
      label: 'Primeiro Cabeçalho',
      value: 'first_header'
    },
    {
      label: 'Segundo Cabeçalho',
      value: 'second_header'
    },
    {
      label: 'Corpo',
      value: 'body'
    },
    {
      label: 'Rodapé',
      value: 'footer'
    }
  ];

  EditOptionsTab: Array<Object> = [
    {
      index: 1,
      value: 'first_header'
    },
    {
      index: 2,
      value: 'second_header'
    },
    {
      index: 3,
      value: 'body'
    },
    {
      index: 4,
      value: 'footer'
    }
  ];

  selectedIndex: number = 0

  // Form dos inputs utilizados para captar os dados do usuário.
  form = {
    isCentralized: new UntypedFormControl(false),
    selectVariableForm: new UntypedFormControl(null),
    selectTextTypeForm: new UntypedFormControl(null),
    inputForm: new UntypedFormControl(null),
    editForm: new UntypedFormControl(null),
    numberOfColumnsForm: new UntypedFormControl(1),
    widthOfColumnsForm: new UntypedFormControl(150),
    gapOfColumnsForm: new UntypedFormControl(10),
    paddingOfColumnsForm: new UntypedFormControl(10),
    templateDescription: new UntypedFormControl(null),

    /* NOTE comentado por pendência da implementação do A5 
      selectFormatOfPaper: new FormControl('a4'),
    */

    bodyThFontSize: new UntypedFormControl(13),
    bodyThFontWeight: new UntypedFormControl(600),
    bodyThColor: new UntypedFormControl('black'),
    bodyThPadding: new UntypedFormControl(0),

    bodyTdFontSize: new UntypedFormControl(10),
    bodyTdFontWeight: new UntypedFormControl(500),
    bodyTdColor: new UntypedFormControl('#404040'),
    bodyTdPadding: new UntypedFormControl(5),

    unity: new UntypedFormControl(null)
  }

  // Variáveis tipadas para controle da tabela (corpo do protocolo).
  tableData!: IDynamicTable;
  allHeaders!: ITableReader[];
  dragTrace!: { src: number, dest: number };

  // Dados de teste para visualização do usuário.
  data = `[
    {
       "analyteCode":"HEM",
       "analyteDescription":"HEMOGRAMA",
       "priceTableNumber":"28.040.481",
       "deadLine":"01/03/2023 às 18h",
       "observation":"Previsão do exame alterada de 28/02/2023 às 18h para 01/03/2023 às 18h em virtude de dificuldades técnicas."
    },
    {
       "analyteCode":"GLI",
       "analyteDescription":"GLICOSE",
       "priceTableNumber":"40.302.040",
       "deadLine":"01/03/2023 às 18h",
       "observation":""
    },
    {
       "analyteCode":"TSH",
       "analyteDescription":"HORMÔNIO TIREOESTIMULANTE",
       "priceTableNumber":"40.316.521",
       "deadLine":"01/03/2023 às 18h",
       "observation":""
    }
  ]`;

  async getUnity() {
    let getParameters: string = (`?page=1&index=99999`);

    await this._unityService.getByPath('', getParameters).then(
      (res) => {
        if (res.data.length != 0) {
          res.data.forEach(unity => {
            this.unityOptions.push({
              value: unity.id,
              label: unity.name
            });

            //@ts-ignore
            this.form.unity.setValue(this.unityOptions[0].value);
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  downloadProtocolPreview() {
    let pdf: jsPDF = new jsPDF('p', 'mm', 'a4');

    /* NOTE removido temporariamente para ajuste do tamanho do papel A5 em pixels
      let pdf: jsPDF | null = null;
      this.form.selectFormatOfPaper.value === 'a5' ? pdf = new jsPDF('p', 'mm', 'a5') : new jsPDF('p', 'mm', 'a4');
    */

    let element = document.getElementById("protocolHtml");

    setTimeout(() => {
      if (element) {
        html2canvas(element).then((canvas) => {
          let imgWidth = 198;
          let imgHeight = canvas.height * imgWidth / canvas.width;
          let contentDataURL = canvas.toDataURL("image/jpeg", 1);
          let position = 6;

          if (pdf) {
            pdf.addImage(contentDataURL, 'JPEG', 0, 0, imgWidth, imgHeight, undefined, 'NONE', 0);
            pdf.save('Documentos' + '.pdf');
          }
        });
      }
    });
  }

  ngOnInit() {
    // Consulta as unidades disponíveis
    this.getUnity();

    // Definição de valores iniciais para os inputs.
    this.form.editForm.setValue('first_header');
    this.form.selectVariableForm.setValue('none');
    this.form.selectTextTypeForm.setValue('span');

    // Limpando dados da tabela do corpo do protocolo.
    this.tableData = { headers: [], data: [] };
  }

  ngAfterViewInit(): void {
    /* 
    Definição do cabeçalho da tabela do corpo do protocolo.
    Inclusão de chave e índice para controle dos dados e inclusão de boolean para verificar se aquela chave está selecionado ou não pelo usuário:
    O intuito deste controle é permitir que o usuário manipule a tabela dinamicamente selecionando em tempo real o que deseja incluir no protocolo.
    Ao final é utilizado o "as" para tipar os dados como um ITableReader definido no arquivo "dynamic-table.model.ts"
    */
    let headers = [
      'analyteCode', 'analyteDescription', 'priceTableNumber',
      'deadLine', 'observation'
    ].map((x, i) => (
      {
        key: x,
        index: i,
        isSelected: true
      } as ITableReader
    ));

    // Após a adição dos atributos no objeto header pelo map acima, é incluso uma label onde de acordo com a chave onde é incluso uma descrição "amigável".
    headers.forEach((header) => {
      switch (header.key) {
        case 'analyteCode':
          header.label = 'Código do Exame';
          break;
        case 'analyteDescription':
          header.label = 'Descrição do Exame';
          break;
        case 'priceTableNumber':
          header.label = 'Código da Tabela'
          break;
        case 'deadLine':
          header.label = 'Previsão'
          break;
        case 'observation':
          header.label = 'Observação'
          break;
        case 'finantial':
          header.label = 'Financeiro'
          break;
        default:
          header.label = '';
          break;
      }
    });

    // Aqui é alimentado os dados de teste definidos de forma fixa anteriormente e tipado como um "IProtocolBody".
    let data = JSON.parse(this.data) as IProtocolBody[];

    // Aqui "renderizamos" os dados passados de acordo com o que o usuário selecionou (headers.isSelected == true).
    this.render(headers, data);

  }

  render(headers: ITableReader[], data: IProtocolBody[]) {

    //É realizado um filtro do que está selecionado pelo usuário e também os dados referentes àquele filtro e alimentado na variável tableData.
    this.tableData = {
      headers: headers.filter(x => x.isSelected),
      data: data
    };

    /* 
    allHeaders recebe uma lista de header
    essa variável é utilizada no html como um "fieldset" que irá listar todos os headers para que o usuário
    possa selecionar o que deseja incluir e utilizar o Drag and Drop para ordenar os dados dinamicamente.
    */
    this.allHeaders = headers;

    // Aqui é chamado um método que reseta o DragAndDrop definindo o destino (dest) e a origem (src) como -1
    this.resetDragTracer();

    // Aqui é chamado o changeDetector para verificar o child e atualizar os registros.
    this._changeDetector.detectChanges();
  }

  private resetDragTracer() {
    this.dragTrace = { src: -1, dest: -1 };
  }

  // Aqui recebemos o índice do header a partir do click do usuário no input definido no "fieldset".
  // Este método contém a regra para manipular o atributo isSelected e filtrar os headers baseados neste atributo.
  toggleHeader(index: number) {
    let isSelected = this.allHeaders[index].isSelected;
    this.allHeaders[index].isSelected = !isSelected;
    this.tableData.headers = this.allHeaders.filter(x => x.isSelected);
    this.tableData.headers.forEach((x, i) => x.index = i);
  }

  // Controle do início do Drag and Drop, definindo a posição de ORIGEM (src) como o ÍNDICE do header.
  onDragstart(i: number) {
    this.dragTrace.src = i;
  }

  // Método de controle DURANTE o Drag and Drop, definindo a posição de DESTINO (dest) como o ÍNDICE do header.
  onDragover(i: number) {
    this.dragTrace.dest = i;
  }

  /* 
  Controle do final do Drag and DragAndDrop:
    Aqui é criado uma constante para verificar se o usuário abortou a operação:
      Caso o "destino" OU "origem" do Drag and Drop seja "-1", é redefinido ambos os valores e o método é retornado.
      Caso contrário, realiza-se a lógica para efetuar a troca entre os índices de origem e destino.
      Isso é realizado tanto para o cabeçalho do fieldset (que o usuário manipula), quanto o cabeçalho da tabela do corpo do protocolo!  
  */
  onDragend() {

    //#region Controle para verificar se o usuário abortou a operação
    const ABORT =
      this.dragTrace.src === -1 ||
      this.dragTrace.dest === -1;

    if (ABORT) {
      this.resetDragTracer();
      return;
    }
    //#endregion Controle para verificar se o usuário abortou a operação

    //#region Inversão dos índices entre a origem e destino
    this.tableData.headers[this.dragTrace.src].index = this.dragTrace.dest;
    this.tableData.headers[this.dragTrace.dest].index = this.dragTrace.src;

    this.allHeaders[this.dragTrace.src].index = this.dragTrace.dest;
    this.allHeaders[this.dragTrace.dest].index = this.dragTrace.src;
    //#endregion Inversão dos índices entre a origem e destino

    //#region Controle da ordenação ascendente
    const ascending = (a: any, b: any) => a.index > b.index ? 1 : -1;

    this.tableData.headers.sort(ascending);
    this.allHeaders.sort(ascending);
    this.tableData.headers.forEach((x, i) => x.index = i);
    this.allHeaders.forEach((x, i) => x.index = i);
    //#endregion Controle da ordenação ascendente

    // Por conseguinte, é redefinido os valores de destino e origem.
    this.resetDragTracer();
  }

  // Método para zerar os vetores do cabeçalho primário e secundário do protocolo.
  clearHtmlStructure() {
    this.FirstHeaderHtmlElements = [];
    this.SecondHeaderHtmlElements = [];
    this.bodyView = false;
    this.FooterHtmlElements = [];
  }

  // Método responsável pela criação e manipulação do cabeçalho da porção "Corpo" do protocolo
  // TODO implementar forma de manipulação individual a partir do index
  generateHeader(data: ITableReader, index: number) {
    let element: string = '';

    element = `<span style='
                  color: ${this.form.bodyTdColor.value};
                  font-size: ${this.form.bodyTdFontSize.value}px;
                  font-weight: ${this.form.bodyTdFontWeight.value};
                  font-family: Inter;
                  word-wrap: break-word;
                  margin: ${this.form.bodyTdPadding.value}px;
                  line-height: ${this.form.bodyTdFontSize.value}px;
                '>
                ${data.label}
              </span>`

    return element;
  }

  // Método responsável pela criação e manipulação do corpo da porção "Corpo" do protocolo
  // TODO implementar forma de manipulação individual a partir do index
  generateBody(data: string, index: number) {
    let element: string = '';

    element = `<span style='
                  color: ${this.form.bodyTdColor.value};
                  font-size: ${this.form.bodyTdFontSize.value}px;
                  font-weight: ${this.form.bodyTdFontWeight.value};
                  font-family: Inter;
                  word-wrap: break-word;
                  margin: ${this.form.bodyTdPadding.value}px;
                  line-height: ${this.form.bodyTdFontSize.value}px;
                '>
                ${data}
              </span>`

    return element;
  }

  // Método que controla a criação de um novo elemento HTML e a sua respectiva inserção em cada lista FirstHeader e SecondHeader
  createNewElement() {

    let newElement: string | null = null;
    let inputFormString: string = this.form.inputForm.value;
    let formattedInputForm: string | null = null;

    // Validação para habilitar o corpo do cabeçalho na visualização do protocolo
    if (this.selectedIndex === 3) this.bodyView = true;

    // Validação para conversão de todos os "espaços" para o &nbsp para permitir o usuário incluir espaços no valor do elemento.
    if (this.form.inputForm.value) formattedInputForm = inputFormString.replaceAll(" ", "&nbsp;");

    // Validação para averiguar se o elemento estará centralizado ou não, definido por um "display:flex" e "justify-content:center", como style.
    if (this.form.isCentralized.value == true) {

      newElement = "<div style='display: flex; justify-content: center;'>";

      if (this.selectedIndex === 2) {
        this.form.selectTextTypeForm.value ? newElement += `<${this.form.selectTextTypeForm.value} style='font-family: Courier New'>` : '';
      } else {
        if (this.form.selectTextTypeForm.value === 'h1') {
          this.form.selectTextTypeForm.value ? newElement += `<${this.form.selectTextTypeForm.value} style='font-family: Inter; font-size: 20px; line-height: 24px; font-style: normal; font-weight: 600;'>` : '';
        } else if (this.form.selectTextTypeForm.value === 'h2') {
          this.form.selectTextTypeForm.value ? newElement += `<${this.form.selectTextTypeForm.value} style='font-family: Inter; font-size: 16px; line-height: 20px; font-style: normal; font-weight: 600;'>` : '';
        } else {
          this.form.selectTextTypeForm.value ? newElement += `<${this.form.selectTextTypeForm.value}>` : '';
        }
      }

      formattedInputForm ? newElement += `${formattedInputForm}` : '';
      this.form.selectVariableForm.value && this.form.selectVariableForm.value != 'none' ? newElement += `{{${this.form.selectVariableForm.value}}}` : '';
      this.form.selectTextTypeForm.value ? newElement += `</${this.form.selectTextTypeForm.value}>` : '';
      newElement += '</div>';

    } else {

      if (this.selectedIndex === 2) {
        this.form.selectTextTypeForm.value ? newElement = `<${this.form.selectTextTypeForm.value} style='font-family: Courier New'>` : '';
      } else {
        this.form.selectTextTypeForm.value ? newElement = `<${this.form.selectTextTypeForm.value}>` : '';
      }

      formattedInputForm ? newElement += `${formattedInputForm}` : '';
      this.form.selectVariableForm.value && this.form.selectVariableForm.value != 'none' ? newElement += `{{${this.form.selectVariableForm.value}}}` : '';
      this.form.selectTextTypeForm.value ? newElement += `</${this.form.selectTextTypeForm.value}>` : '';

    }

    // Uma vez validado o elemento e suas formatações iniciais, o mesmo é inserido na sua lista de elementos.
    newElement ? this.insertElement(newElement) : '';
  }

  /*
  Este método manipula o grid gerado no cabeçalho secundário do protocolo:
  O usuário pode definir os seguintes atributos do grid:
    Quantidade de colunas (quantityOfColumns);
    Tamanho das colunas (width);
    Espaçamento entre as colunas (gap);
    Espaçamento Interno entre as colunas (padding).
  */
  grid(quantityOfColumns: any, width: any, gap: any, padding: any) {

    let gridElement: HTMLElement | null = document.getElementById('grid');

    if (gridElement) {
      gridElement.style.gridTemplateColumns = `repeat( ${quantityOfColumns.value}, ${width.value}px)`;
      gridElement.style.display = `grid`;
      gridElement.style.rowGap = `${gap.value}px`;
      gridElement.style.padding = `${padding.value}px`;
    }
  }

  // Método que controla a inserção do elemento de acordo com sua porção (Cabeçalho primário ou secundário).
  insertElement(htmlElement: string) {
    if (htmlElement) {
      if (this.selectedIndex === 1) this.FirstHeaderHtmlElements.push(htmlElement);
      if (this.selectedIndex === 2) this.SecondHeaderHtmlElements.push(htmlElement);
      if (this.selectedIndex === 4) this.FooterHtmlElements.push(htmlElement);
    }
  }

  // Método que controla a remoção do elemento de acordo com sua porção (Cabeçalho primário ou secundário).
  removeElement() {
    if (this.selectedIndex === 1) this.FirstHeaderHtmlElements.pop();
    if (this.selectedIndex === 2) this.SecondHeaderHtmlElements.pop();
    if (this.selectedIndex === 3) this.bodyView = false;
    if (this.selectedIndex === 4) this.FooterHtmlElements.pop();
  }

  // Método que permite a inclusão de uma quebra de linha baseado na porção definida (Cabeçalho primário ou secundário).
  breakLine() {
    if (this.selectedIndex === 1) this.FirstHeaderHtmlElements.push("<br>");
    if (this.selectedIndex === 2) this.SecondHeaderHtmlElements.push("<br>");
    if (this.selectedIndex === 4) this.FooterHtmlElements.push("<br>");
  }

  // Método que permite a geração do HTML final, contendo todas as porções do protocolo criado.
  // TODO finalizar a estrutura para geração do HTML final.
  async getHtmlStructure() {
    this.htmlStructure = document.getElementById("protocolHtml");

    if (this.htmlStructure) {
      let bodyObject: PostTemplate = {
        description: this.form.templateDescription.value,
        templateHtml: this.htmlStructure.innerHTML,
        typeTemplate: ETypeTemplate.Protocol,
        unityId: this.form.unity.value
      }

      await this._templateService.post(bodyObject).then(
        (res) => {
          if (res.httpStatusCode == 201) {
            SwAlSetttings.Sucesso("Modelo de Protocolo criado com sucesso!");
          }
        }).catch(
          (err) => {
            SwAlSetttings.printError(err);
          }
        );
    }
  }
}
