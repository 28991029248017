import { Component, Input, inject } from "@angular/core";
import { CommonModule, Location, NgIf } from "@angular/common";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonFormComponent } from "../../components/form/button/button.component";

@Component({
   selector: "content-return-layout",
   templateUrl: "./content-return.component.html",
   styleUrls: ["./content-return.component.scss"],
   standalone: true,
   imports: [NgIf, CommonModule, TranslateModule, ButtonFormComponent]
})
export class ContentReturnLayoutComponent {
   private _location = inject(Location);
   private _router = inject(Router);

   @Input() title = "";
   @Input() additionalTitle!: string;
   @Input() analyteName!: string;
   @Input() hideTitle = false;
   @Input() hideButton = false;
   @Input() requisitioLayout: any;
   @Input() backPath = "";
   @Input() backParameters: {
      path: string;
      parameters: Object;
   } | null = null;
   @Input() marginBottom = null;
   @Input() padding = null;

   returnPage() {
      if (this.backParameters) {
         this._router.navigate([this.backParameters.path], {
            queryParams: this.backParameters.parameters
         });
         return;
      }
      if (this.backPath == "") {
         this._location.back();
      } else {
         this._router.navigateByUrl(this.backPath);
      }
   }
}
