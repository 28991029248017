<div class="contentPage mb-32">
  <div class="flex flex-between align-center" [ngStyle]="{'flex-direction': limit > width ? 'column' : 'row', 'gap' : limit > width ? '24px' : '32px'}">
    <div class="flex align-center gap-2" [ngStyle]="{'flex-direction': limit > width ? 'column' : 'row'}">
      <div class="flex align-center gap-2">
        <h1 id="title">{{title | translate}}</h1>
      </div>
      <app-button *ngIf="urlButton" (click)="navigate()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
      <ng-content select=".headerButton"></ng-content>
    </div>
    <ng-content select=".headerFilter"></ng-content>
  </div>
  <div class="mt-32">
    <ng-content select=".list"></ng-content>
  </div>
</div>