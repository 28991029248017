import { Guid } from "@app/util/guid";

export interface PostTemplate {
    description: string,
    templateHtml: string,
    typeTemplate: ETypeTemplate.Protocol,
    unityId: Guid
}

export interface GetTemplate {
    id: string,
    description: string,
    templateHtml: string,
    typeTemplate: ETypeTemplate.Protocol,
    personId: string,
    unityId: string,
    isActive: boolean,
    createdAt: Date,
    updatedAt: Date
}

export enum ETypeTemplate {
    Protocol = 0
}