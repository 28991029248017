<div class="contentPage">
  <div class="flex flex-between headerResponsive">
    <div class="flex gap-2 align-center headerResponsive">
      <app-button (click)="back()" [placeholder]="'global.back'" [class]="'btn-reverse'"></app-button>
      <h1 *ngIf="!edit">{{'customer.register.new' | translate}}</h1>
      <h1 *ngIf="edit">{{'customer.register.edit' | translate}}</h1>
    </div>
    <div class="flex gap-2 align-center headerResponsive" *ngIf="customerId">
      <div class="flex flex-center gap-1">
        <h1>{{'customer.register.number-requisition' | translate}}</h1><h1 class="orangeFont">{{requisitionNumber}}</h1>
      </div>
      <div class="flex flex-center gap-1">
        <h1>{{'customer.register.last-requisition' | translate}}</h1><h1 class="orangeFont">{{lastRequisition}}</h1>
      </div>
    </div>
  </div>
  <div class="flex flex-center mt-32">
    <div class="contextPage">
      <div class="box">
        <h2 class="w-100 flex flex-between align-center">{{'customer.register.general-data' | translate}}</h2>
        <div class="flex gp-12 mt-32">
          <div class="w-100">
            <div class="flex gap-form">
              <div class="w-100">
                <input-prop placeholder="global.form.mother-name" [form]="form.customerMotherName"></input-prop>
              </div>
              <div class="w-100">
                <input-prop placeholder="global.form.name-responsible" [form]="form.customerResponsibleName"></input-prop>
              </div>
              <div class="w-100">
                <input-prop placeholder="global.form.social-name" [form]="form.customerSocialName"></input-prop>
              </div>
            </div>
            <div class="flex gap-form mt-32">
              <div class="w-100">
                <input-prop *ngIf="!userChronos" placeholder="global.form.name" [form]="form.customerName"></input-prop>
              </div>
              <div class="w-100">
                <select-prop placeholder="global.form.biological-sex" [form]="form.customerGenderType" [options]="genderType"></select-prop>
              </div>
              <div class="w-100">
                <input-unset-prop *ngIf="!userChronos" placeholder="global.form.birthDate" type="date" [form]="form.customerBirthDate" [disableFutureDate]="true" required></input-unset-prop>
              </div>
            </div>
    
            <div class="flex gap-form mt-32 align-center">
              <div class="w-100">
                <select-prop placeholder="customer.register.ethnicity" [form]="form.customerEthnicity" [options]="ethnicityOptions"></select-prop>
              </div>
              <div class="w-100 flex align-center">
                <checkbox-prop [gap]="'1'" [label]="'customer.register.deficiency'" [form]="form.customerHasDeficiency"></checkbox-prop>
              </div>
              <div class="w-100 flex align-center">
                <checkbox-prop [gap]="'1'" [label]="'customer.register.foreigner'" [form]="form.customerIsForeigner"></checkbox-prop>
              </div>
            </div>
          </div>
          <div>
            <file-prop-webcam [form]="form.avatarId"></file-prop-webcam> 
          </div>
        </div>
      </div>
      <div class="box mt-32">
        <h2 class="flex gap-form">{{'customer.register.address' | translate}}</h2>
        <div class="flex gap-form mt-32 ">
          <div class="w-100">
            <input-prop class="w-100" (focusout)="getCep()" placeholder="customer.register.zip-code" mask="00.000-000" [form]="form.address.zipCode"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="customer.register.street" [form]="form.address.street"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="customer.register.number" [form]="form.address.number" type="number"></input-prop>
          </div>
        </div>
        <div class="flex gap-form mt-32 ">
          <div class="w-100">
            <input-prop placeholder="customer.register.complement" [form]="form.address.complement"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="customer.register.neighborhood" [form]="form.address.neighborhood"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="customer.register.city" [form]="form.address.city"></input-prop>
          </div>
        </div>
        <div class="flex gap-form mt-32 ">
          <div class="w-100">
            <input-prop placeholder="customer.register.state" [form]="form.address.state"></input-prop>
          </div>
          <div class="w-100 ">
            <input-prop placeholder="customer.register.country" [form]="form.address.country"></input-prop>
          </div>
          <div class="w-100">
          </div>
        </div>
      </div>
      <div class="box mt-32">
        <h2 class="flex gap-form">{{'customer.register.identification-documents' | translate}}</h2>
        <div class="flex gap-form mt-32 align-center">
          <div class="w-100">
            <input-prop placeholder="customer.register.rg" [form]="form.customerRg"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="customer.register.cpf"  [form]="form.customerCnpjCpf" mask="000.000.000-00"></input-prop>
            <div *ngIf="form.customerCnpjCpf ? form.customerCnpjCpf.invalid && (form.customerCnpjCpf.dirty || form.customerCnpjCpf.touched) : ''" style="color: rgb(248, 106, 4); margin-top: 14px;">
              Considere informar o CPF!
            </div>
          </div>
          <div class="w-100 flex align-center">
            <div class="flex align-center">
              <checkbox-prop [gap]="'1'" [label]="'customer.register.document-responsible'" [form]="form.customerCpfResponsible"></checkbox-prop>
            </div>
          </div>
        </div>
        <div class="flex gap-form mt-32 ">
          <div class="w-100">
            <input-prop placeholder="customer.register.cnh" [form]="form.customerCnh"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="customer.register.cns" [form]="form.customerCns"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="customer.register.passport" [form]="form.customerPassport"></input-prop>
          </div>
        </div>
      </div>
      <div class="box mt-32">
        <h2 class="flex gap-form">{{'customer.register.contact-details' | translate}}</h2>
        <div class="flex gap-form mt-32 ">
          <div class="w-100">
            <input-prop placeholder="customer.register.cellphone" [form]="form.customerCellphone" mask="(00) 0 0000-0000" [warningCustom]="'Insira o número de telefone em um padrão válido'"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="customer.register.residential-phone" [form]="form.customerResidentialPhone" mask="(00) 0000-0000" [warningCustom]="'Padrão de telefone residencial inválido!'"></input-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="customer.register.email" [type]="'email'" [form]="form.customerEmail"></input-prop>
          </div>
        </div>
        <div class="flex flex-between checkboxResponsive mt-32  align-center">
          <checkbox-prop  [gap]="'1'" label="customer.register.send-email-result" [form]="form.sendEmailResult"></checkbox-prop>
          <checkbox-prop  [gap]="'1'" [label]="'customer.register.send-sms-email'" [form]="form.sendSmsEmail"></checkbox-prop>
        </div>
      </div>
      <div class="box mt-32">
        <h2 class="flex gap-form">{{'customer.register.service-details' | translate}}</h2>
        <div class="flex gap-form mt-32 ">
          <div class="w-100">
            <input-search placeholder="customer.register.agreement" [form]="form.agreementId" (search)="getAgreements($event.value)" (changeForm)="getAgreementPlan()" [searchApi]="true" [options]="agreementOptions"></input-search>
          </div>
          <div class="w-100">
            <input-search placeholder="customer.register.agreement-plan" [form]="form.agreementPlanId" [options]="agreementPlanOptions"></input-search>
          </div>
          <div class="w-100">
            <input-prop placeholder="customer.register.health-card" [form]="form.customerCard"></input-prop>
          </div>
        </div>
        <div class="flex gap-form mt-32 ">
          <div class="w-100">
            <input-unset-prop  placeholder="customer.register.expiration-date" type="date" [form]="form.expirationDate" required></input-unset-prop>
          </div>
          <div class="w-100">
            <input-prop placeholder="customer.register.report" [form]="form.report"></input-prop>
          </div>
          <div class="w-100"></div>
        </div>
      </div>
      <div class="box mt-32">
        <h2 class="flex">{{'customer.register.medicines-used' | translate}}</h2>
        <div class="flex flex-between mt-32">
          <checkbox-prop [gap]="'1'" [label]="'customer.register.continuous-medication'" [form]="form.continuousMedication"></checkbox-prop>
        <div *ngIf="form.continuousMedication.value" class="flex align-center flex-between">
          <app-button (click)="openModalMedication($event)" [placeholder]="'customer.register.add-medicines'" *ngIf="!disable"></app-button>
        </div> 
      </div>
        <app-table 
          *ngIf="form.continuousMedication.value" 
          (parentFun)="actions($event)"
          [ClickInItem]="ActionsExtra"
          [noShadow]="true"
          [Actions]="['Editar', 'Excluir']"
          [Itens]="resolvedMedications"
          ></app-table>      
        </div>
      <div class="flex flex-center mt-32 gap-form btn-moblie align-center">
        <app-button (click)="customerId? updateCustomer() : createCustomer()" [placeholder]="'customer.register.save'"></app-button>
        <app-button (click)="createCustomerAndGenerateRequisition()" [placeholder]="'customer.register.save-and-create'" *ngIf="!disable && !customerId"></app-button>
      </div>
    </div>
  </div>
</div>

<ng-template #dialogModal let-Modal>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{'customer.register.medicines-used' | translate}}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="mt-32 flex flex-between gap-form">
        <select-prop class="w-100" [form]="form.unityId" placeholder="customer.register.select-unit" [options]="unityOptions" (change)="getUnities()"></select-prop>
        <select-prop class="w-100" [form]="form.collectionPlaceId" placeholder="customer.register.select-collection-location" [options]="collectionPlaceOptions"></select-prop>
      </div>
      <div class="mt-32 flex flex-center">
        <app-button [class]="'btn'" (click)="createReq()" [placeholder]="'customer.register.create-requisition'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalManualMedication let-modal>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{'customer.register.medicine' | translate}}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="mt-32 w-100 flex flex-between gap-form">
        <input-prop class="w-100" placeholder="customer.register.medicine" [form]="form.manualMedication" (keydown.enter)="modal?.id ? updateMedication(modal) : createMedication()"></input-prop>
      </div>
      <div class="mt-32 flex flex-center">
        <app-button (click)="modal ? updateMedication(modal) : createMedication()" [placeholder]="'customer.register.save'" [class]="'btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>