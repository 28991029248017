import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DepartmentsFacade } from "@app/modules_new/administrativeData/data/facade/departments.facade";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { InputSelectFormComponent } from "@app/modules_new/shared/components/form/input-select/base/input-select.component";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ModalLayoutComponent } from "@app/modules_new/shared/components/form/modal/modal.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "app-departments-modal",
   templateUrl: "./departments.modal.component.html",
   standalone: true,
   imports: [
      ModalLayoutComponent,
      InputTextFormComponent,
      ReactiveFormsModule,
      ButtonFormComponent,
      CommonModule,
      TranslateModule,
      InputSelectFormComponent
   ]
})
export class DepartmentsModal implements OnInit {
   constructor() {}

   public _dialogData = inject(MAT_DIALOG_DATA);
   private _departmentsFacade = inject(DepartmentsFacade);

   title: string = "departments.modal.new";

   formGroup = new FormGroup({
      externalId: new FormControl<string | null>(null, [Validators.required]),
      name: new FormControl<string | null>(null, [Validators.required])
   });

   async ngOnInit() {
      if (this._dialogData) {
         this.title = "departments.modal.edit";
         this.formGroup.setValue({
            externalId: this._dialogData.externalId ?? "",
            name: this._dialogData.name
         });
      }
   }

   createDepartment() {
      this._departmentsFacade.createDepartment(this.formGroup.value);
   }

   updateDepartment() {
      this._departmentsFacade.updadeDepartment(
         this.formGroup.value,
         this._dialogData.id
      );
   }
}
