import {
   ActionDefition,
   ColumnDefinition
} from "@app/modules_new/shared/components/form/table/table.model";

export const tableActions: ActionDefition[] = [
   {
      label: "Editar",
      svg: "action-write",
      event: "EDITAR"
   }
];

export const tableColumns: ColumnDefinition[] = [
   {
      label: "Nome",
      key: "name"
   },
   {
      label: "Tipo de Acesso",
      formatter: (item: any) => {
         switch (item.type) {
            case 1:
               return "Cliente";
            case 2:
               return "Unidade ou Filial";
            case 3:
               return "Médico Solicitante";
            case 4:
               return "Convênio";
            case 5:
               return "Plano do Convênio";
            case 6:
               return "Destino de Entrega";
            case 7:
               return "Local de Coleta";
            default:
               return "";
         }
      }
   },
   {
      label: "Código de Acesso",
      key: "accessCode"
   },
   {
      label: "Celular",
      key: "cellPhone"
   },
   {
      label: "Email verificado",
      key: "isVerifiedEmail",
      component: "svg",
      componentExtra: {
         event: "isVerifiedEmail",
         svgfill: { true: "#07BF56", false: "#EB5757" },
         svgkey: { true: "green-check", false: "red-x" }
      }
   },

   {
      label: "default.active",
      key: "isActive",
      component: "checkbox"
   }
];
