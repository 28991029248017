<div class="contentPage">
  <div class="flex flex-between align-center headerResponsive">
    <div class="flex align-center gp-12 titleResponsive">
      <h1>{{title}}</h1>      
      <input-prop (keydown.enter)="get()" placeholder="Requisição" [form]="form.requisition"></input-prop>
    </div>        
  </div>  
  <div *ngIf="name" class="box mt-32">
    <h2>Requisição: {{number}} - Convenio: {{agreement}}</h2>
    <h2>Cliente: {{name}}</h2>
    <div class="flex gap-form mt-32">    
      <div class="w-100">
        <table style="cursor: pointer;" class="w-100">
          <tr *ngFor="let row of examsModal" [class.highlighted]="highlightedRow === row.id" (click)="onRowClick(row.id)">
            <td style="height: 10px;">{{ row.name }} - {{ row.material }}</td>
          </tr>
        </table>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="w-100">
        <table style="cursor: pointer;" class="w-100">
          <tr *ngFor="let row of atributesModal" [class.highlighted]="highlightedRowAtribute === row.id" (click)="onRowClickAtribute(row.id)">
            <td style="height: 10px;">{{ row.name }}</td>
          </tr>
        </table>
      </div>      
    </div>
    <div class="mt-32">
      <mat-divider></mat-divider>
    </div>
    <div *ngIf="highlightedRowAtribute" class="flex gap-form">
      <div class="w-100 mt-32">
        <div>
          <span class="analyte">{{analyteName}}</span>
        </div>
        <div>
          <span class="atribute">{{atributeName}}</span>        
        </div>
        <div class="w-100 flex flex-center">
          <input-prop [noLabelUp]="true" class="w-100" placeholder="Resultado" [form]="examsFormGroup[highlightedRowAtribute]"></input-prop>    
        </div>
      </div>
      <div class="mt-32 w-100 flex flex-center align-center">
        <span class="multiline-text">{{referenceValue}}</span>
      </div>
    </div>
  </div>  
</div>