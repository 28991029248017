<div class="contentPage">
  <div class="flex align-center flex-between headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
        <h1>Usuários</h1>
        <app-button [matIcon]="'add'" [placeholder]="'Novo'" [class]="'flex gap-2 align-center'" [routerLink]="'/Usuarios/Novo'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="get()">
        <div class="query">
            <input-prop #autoFocus [form]="form.search" placeholder="Digite o Nome/Nome Social"></input-prop>
        </div>
        <div class="queryBtn">
            <button class="flex gap-2 align-center" (click)="get()">
                Pesquisar
                <mat-icon>search</mat-icon>
            </button>
        </div>
    </app-filter>
  </div>
  <app-table [Dropdown]="true" [Pagination]="true" [backPagination]="true" [ClickInItem]="ActionsExtra" (changePage)="get($event)" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="itens" (parentFun)="Actions($event)" [Actions]="['Editar']">
      <ng-template let-rowDetail #header>
          <div class="flex gap-3 responsiveData">
              <div class="col infosPerfil">
                  <h2>Dados de acesso</h2>
                  <div class="imgPerfil">
                      <!--<div *ngIf="imagePath">
                          <img [src]="imagePath">
                      </div>-->
                  </div>
                  <p><strong>E-mail/Código acesso:</strong> {{rowDetail['E-mail/Código de acesso']}}</p>
                  <p class="flex" *ngIf="rowDetail.hasOwnProperty('extra')"><strong>Usuário:</strong> <span [class]="rowDetail.extra.isActive ? 'active' : 'desactive'">
                      {{rowDetail.extra.isActive ? 'Ativo' : 'Desativado'}}</span></p>

                  <p class="flex" style="margin-left: 0;" *ngIf="rowDetail.hasOwnProperty('extra') && !rowDetail?.extra.userAccessActive">
                      <span class="pill-warning">Necessário ativação do primeiro acesso</span>
                  </p>
                  <!-- <a class="btn-reverse btn" style="display: inline-block;margin-top: calc(36px * var(--proportional))" [routerLink]="'/Conta/Usuarios/Permissoes/' + rowDetail.id">Configurar permissões</a> -->
              </div>
              <div class="col">
                  <h2>Dados de cadastro</h2>
                  <div class="infos">
                      <div class="flex gap-4 responsiveDataAccess830">
                          <div class="flex1" style="flex:0.5;min-width: auto" *ngIf="rowDetail.hasOwnProperty('extra')">
                              <div class="imgPerfil">
                                  <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!rowDetail.extra.hasOwnProperty('imageBase64')">
                                      <path d="M60.5 70.2316C72.4293 70.2316 82.1 60.5609 82.1 48.6316C82.1 36.7022 72.4293 27.0316 60.5 27.0316C48.5706 27.0316 38.9 36.7022 38.9 48.6316C38.9 60.5609 48.5706 70.2316 60.5 70.2316Z" fill="#DDDDDD"/>
                                      <path d="M97.5105 0H23.4895C10.8579 0 0.5 10.3579 0.5 22.9895V97.0105C0.5 109.642 10.8579 120 23.4895 120H97.5105C110.142 120 120.5 109.642 120.5 97.0105V22.9895C120.5 10.3579 110.142 0 97.5105 0ZM109.258 97.0105C109.258 103.453 104.079 108.632 97.6368 108.632H96.6263C96.121 92.8421 90.5632 79.2 79.4474 72.1263C78.9421 71.7474 78.1842 71.8737 77.6789 72.2526C72.8789 75.7895 66.9421 77.9368 60.5 77.9368C54.0579 77.9368 48.1211 75.7895 43.3211 72.2526C42.8158 71.8737 42.0579 71.8737 41.5526 72.1263C30.5632 79.2 24.8789 92.8421 24.5 108.758H23.4895C17.0474 108.758 11.8684 103.579 11.8684 97.1368V22.9895C11.8684 16.5474 17.0474 11.3684 23.4895 11.3684H97.5105C103.953 11.3684 109.132 16.5474 109.132 22.9895V97.0105H109.258Z" fill="#DDDDDD"/>
                                  </svg>
                                  <div *ngIf="rowDetail.extra.hasOwnProperty('imageBase64')">
                                      <img [src]="rowDetail.extra.imageBase64">
                                  </div>
                              </div>
                          </div>
                          <div class="flex flex-between w-100 responsiveDataAccess500">
                              <div *ngIf="rowDetail.hasOwnProperty('extra')">
                                  <p><strong>CEP:</strong> {{rowDetail.extra.address.zipCode ? rowDetail.extra.address.zipCode : '--'}}</p>
                                  <p><strong>Endereço:</strong> {{rowDetail.extra.address.street ? rowDetail.extra.address.street : '--'}}{{rowDetail.extra.address.number ? ', ' + rowDetail.extra.address.number : ''}}</p>
                                  <p><strong>Bairro:</strong> {{rowDetail.extra.address.neighborhood ? rowDetail.extra.address.neighborhood : '--'}}</p>
                                  <p><strong>Cidade:</strong> {{rowDetail.extra.address.city ? rowDetail.extra.address.city : '--'}}</p>
                                  <p><strong>Estado:</strong> {{rowDetail.extra.address.state ? rowDetail.extra.address.state : '--'}}</p>
                                  <p><strong>Complemento:</strong> {{rowDetail.extra.address?.complement ? rowDetail.extra.address.complement : '--'}}</p>
                              </div>
                              <div *ngIf="rowDetail.hasOwnProperty('extra')">
                                  <p><strong>Data de admissão:</strong> {{rowDetail.extra.admissionData && rowDetail.extra.admissionData.split('T')[0] != '0001-01-01' ? (rowDetail.extra.admissionData | date:'dd/MM/YYYY') : '--'}}</p>
                                  <p><strong>Data de demissão:</strong> {{rowDetail.extra.resignationData && rowDetail.extra.resignationData.split('T')[0] != '0001-01-01' ? (rowDetail.extra.resignationData |  date:'dd/MM/YYYY') : '--'}} </p>
                              </div>
                              <div *ngIf="rowDetail.hasOwnProperty('extra')">
                                  <p><strong>Celular: </strong> {{rowDetail.extra.cellPhone ? rowDetail.extra.cellPhone : '--'}}</p>
                                  <p><strong>Telefone fixo:</strong> {{rowDetail.extra.telephone ? rowDetail.extra.telephone : '--'}}</p>
                                  <p><strong>E-mail pessoal:</strong> {{rowDetail.extra.alternativeEmail ? rowDetail.extra.alternativeEmail : '--'}}</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </ng-template>
  </app-table>
</div>
