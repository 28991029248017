import { Injectable } from "@angular/core";
import { HttpResponsePageable } from "@app/services/services.types";
import { BaseService } from "../../base.service";
import { HttpService } from "../../http.service";
import { GetAllUnities, GetByIdUnity } from "./unity.types";
import { Guid } from "@app/util/guid";

@Injectable({
   providedIn: "root"
})
export class UnityService extends BaseService {
   constructor(HttpService: HttpService) {
      super(HttpService, "v1/lab/parameters/parametersunity");
   }

   override getAll() {
      return super.getAll() as Promise<HttpResponsePageable<GetAllUnities>>;
   }

   override getByPath(queryString: string, path: string, headers?: String[]) {
      return super.getByPath(queryString, path, headers) as Promise<
         HttpResponsePageable<GetAllUnities>
      >;
   }

   override getById(id: Guid) {
      return super.getById(id) as Promise<HttpResponsePageable<GetByIdUnity>>;
   }
}
