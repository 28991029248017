import { Component, OnInit } from '@angular/core';
import { SwAlSetttings } from '@app/util/swal.settings';
import { UntypedFormControl } from '@angular/forms';
import { DefaultFormats, LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { Util } from '@app/util/util';
import { RegistrationReportComponent } from '@app/components/form/registration-report/registration-report.component';
import { HttpClient } from '@angular/common/http';
import { TenantAuthService } from '@app/services/auth/tenantAuth/tenant-auth.service';
import { AbstractReport, HeaderMapping, ReportFilter } from '@app/modules/reports/reports-model';
import { TitlesService } from '@app/services/titles/titles.service';

@Component({
  selector: 'app-title-report',
  templateUrl: './title-report.component.html',
  styleUrls: ['./title-report.component.scss']
})
export class TitleReportComponent extends AbstractReport implements OnInit {
  override sumRecords!: number;
  filter = ({
    document : new UntypedFormControl(),
    actives : new UntypedFormControl('todos'),
    issuer: new UntypedFormControl(),
    emission: new UntypedFormControl(),
    value: new UntypedFormControl(),
    module: new UntypedFormControl(),
    dateStart: new UntypedFormControl(),
    dateFinish: new UntypedFormControl(),
    situation: new UntypedFormControl()
  });
  isActiveOptions: Array<{ label: string, value: string | null | boolean }> = [{ label: 'Todos', value: 'todos' }, { label: 'Somente Ativos', value: true }, { label: 'Somente Inativos', value: false }]
  moduleTabs = ["Contas a Pagar", "Contas a Receber", "Caixas e Bancos"]
  titles: Array<Array<object>> = [[],[],[]];

  titleSituationOptions: Array<{ label: string, value: number }> = [
    {
      label: "Aberto",
      value: 0
    },
    {
      label: "Vencido",
      value: 1
    },
    {
      label: "Baixado Parcialmente",
      value: 2
    },
    {
      label: "Baixado Integralmente",
      value: 3
    },
  ]

  tabIndex: number = 1;

  reportObject: Array<Object> = [];
  resolvedAnalytes: Array<Object> = [];
  index: number = 50;
  page: number = 1;
  total: number = 0;

  departmentOptions: Array<{ label: string, value: string | null }> = [{ label: 'Todos', value: 'todos' }];  
  isOutsourcedOptions: Array<{ label: string, value: string | null | boolean }> = [{ label: 'Todos', value: 'todos' }, { label: 'Somente Terceirizados', value: true }, { label: 'Somente Internos', value: false }]

  override reportDescription: string = 'Relatório de Títulos';
  override reportFilters: ReportFilter[][] = [];

  override headerMapping: HeaderMapping = {
    documentNumber: { title: 'Nr. Documento.', width: 'auto' },
    name: { title: 'Emitente', width: 'auto' },
    createdAtEmission: { title: 'Emissão', width: 'auto' },
    value: { title: 'Valor', width: 'auto' },
    titleSituation: { title: 'Situação', width: 'auto' },
    isOK: { title: 'Estado', width: 'auto' },
    createdAt: { title: 'Criado em', width: 'auto' },
    updatedAt: { title: 'Alterado em', width: 'auto' },
    isActive: { title: 'Ativo', width: 'auto' }    
  };

  resolvePaymentTypes = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Nr. Documento',
      retrive: 'documentNumber',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Emitente',
      retrive: 'name',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Emissão',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'createdAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Valor',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'value',
      type: 'money'
    },
    {
      label: 'Situação',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'titleSituation',
      type: 'titleSituation'
    },
    {
      label: 'Estado',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'isOK',
      type: 'titleEstado'
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'createdAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'updatedAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'isActive',
      type: 'boolean'
    },
  ];

  override receivedData!: any[];
  receivedDataForPrint!: object[];

  constructor(
    private _titlesService: TitlesService,    
    private _util: Util,
    private _localeDate: LocalaDatePipe,
    private _http: HttpClient,
    private _tenantAuth: TenantAuthService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    await this.getData();    
  }

  resolveGenericLocal(data: any, columns: any) {
    this.resolvedAnalytes = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.resolvedAnalytes.push(obj);
    });
  }

  override async getData(paramPage?: number[]): Promise<void> {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    const start = new Date(new Date(this.filter.dateStart.value).setUTCHours(0,0,0,0)).toISOString()
    const finish = new Date(new Date(this.filter.dateFinish.value).setUTCHours(23,59,59,999)).toISOString()
   
    let documentNumber: string = this.filter.document.value ? this.filter.document.value : null;

    let isActive: string = this.filter.actives.value === 'todos' ? null : JSON.parse(this.filter.actives.value);

    let issuer: string = this.filter.issuer.value ?  this.filter.issuer.value : null;

    let emission: string = this.filter.emission.value ?  this.filter.emission.value : null;

    let typeModule: string = (this.tabIndex + 1).toString();

    let dateStart: string = this.filter.dateStart.value ? start : "";

    let dateFinish: string = this.filter.dateFinish.value ? finish : "";

    let titleSituation: string = this.filter.situation.value >= 0 ?  this.filter.situation.value : null;

    await this._titlesService.getPagination({page: this.page, numberRegistry: this.index, isActive, documentNumber, issuer, emission, typeModule, dateStart, dateFinish, titleSituation}).then(
      (res) => {
        this.total = res.sumRecords;
        this.receivedData = res.data;
        this.resolveGenericLocal(res.data, this.resolvePaymentTypes);
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  async getDataForPrint() {
    this.reportObject = []
    const start = new Date(new Date(this.filter.dateStart.value).setUTCHours(0,0,0,0)).toISOString()
    const finish = new Date(new Date(this.filter.dateFinish.value).setUTCHours(23,59,59,999)).toISOString()
   
    let documentNumber: string = this.filter.document.value ? this.filter.document.value : null;

    let isActive: string = this.filter.actives.value === 'todos' ? null : JSON.parse(this.filter.actives.value);

    let issuer: string = this.filter.issuer.value ?  this.filter.issuer.value : null;

    let emission: string = this.filter.emission.value ?  this.filter.emission.value : null;

    let typeModule: string = (this.tabIndex + 1).toString();

    let dateStart: string = this.filter.dateStart.value ? start : "";

    let dateFinish: string = this.filter.dateFinish.value ? finish : "";

    let titleSituation: string = this.filter.situation.value >= 0 ?  this.filter.situation.value : null;

    await this._titlesService.getPagination({page: this.page, numberRegistry: this.index, isActive, documentNumber, issuer, emission, typeModule, dateStart, dateFinish, titleSituation}).then(
      (res) => {
        res.data.forEach((x : any) => {
          const obj : any = {
            documentNumber: x.documentNumber,
            name: x.name,
            createdAtEmission: x.createdAt,
            value: this.getValueString(x.value),
            titleSituation: this.getSituationString(x.titleSituation),
            isOK: this.getStateString(x.isOK),
            createdAt: x.createdAt,
            updatedAt: x.updatedAt,
            isActive: x.isActive ? 'Ativo': 'Inativo',
          }
          this.reportObject.push(obj)
        })
        this.sumRecords = res.sumRecords;
        this.receivedDataForPrint = this.reportObject;
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  async generatePdf() {
    await this.getDataForPrint();

    this.reportFilters = []
    var filters = [];
    var filterActive = 'Todos';
    if (this.filter.actives.value != 'todos'){
      if (this.filter.actives.value == 'true'){
        filterActive = 'Somente Ativos';
      } else {
        filterActive = 'Somente Inativos';
      }
    }   

    var obj = {
      title: 'Ativos: ', content : filterActive
    }
    
    filters.push(obj);    

    if (this.filter.document.value){
      var obj2 = {
        title: 'Documento: ', content : this.filter.document.value
      }
      
      filters.push(obj2);
    }  
    
    if (this.filter.issuer.value){
      var obj2 = {
        title: 'Emitente: ', content : this.filter.issuer.value
      }
      
      filters.push(obj2);
    }  

    if (filters.length == 3){
      this.reportFilters.push(filters);
      filters = [];
    }
    
    if (this.filter.situation.value >= 0){
      var obj = {
        title: 'Situação: ', content : this.titleSituationOptions[Number(this.filter.emission.value)].label
      }
      
      filters.push(obj)
    }

    if (filters.length == 3){
      this.reportFilters.push(filters);
      filters = [];
    }

    if (this.filter.dateStart.value){      
      var day = '';
      var month = 0;
      var year = 0;
      if (this.filter.dateStart.value._i.year == undefined){
        day = this.filter.dateStart.value._i.substring(0,2);
        month = this.filter.dateStart.value._i.substring(2,4);
        year = this.filter.dateStart.value._i.substring(4,8);     
      } else {
        day = this.filter.dateStart.value._i.day;
        month = this.filter.dateStart.value._i.month;
        year = this.filter.dateStart.value._i.year;
      } 

      var obj3 = {
        title: 'Data Inicial: ', content : day+'/'+month+'/'+year
      }
      
      filters.push(obj3)
    }

    if (filters.length == 3){
      this.reportFilters.push(filters);
      filters = [];
    }

    if (this.filter.dateFinish.value){
      var day = '';
      var month = 0;
      var year = 0;
      if (this.filter.dateFinish.value._i.year == undefined){
        day = this.filter.dateFinish.value._i.substring(0,2);
        month = this.filter.dateFinish.value._i.substring(2,4);
        year = this.filter.dateFinish.value._i.substring(4,8);     
      } else {
        day = this.filter.dateFinish.value._i.day;
        month = this.filter.dateFinish.value._i.month;
        year = this.filter.dateFinish.value._i.year;
      } 

      var obj3 = {
        title: 'Data Final: ', content : day+'/'+month+'/'+year
      }
      
      filters.push(obj3)
    }    

    if (filters.length > 0){
      this.reportFilters.push(filters);
      filters = [];
    }

    const reportComponent = new RegistrationReportComponent(this._http, this._localeDate, this._tenantAuth);
    reportComponent.headerMapping = this.headerMapping;
    reportComponent.reportFilters = this.reportFilters;
    reportComponent.receivedData = this.receivedDataForPrint;
    reportComponent.reportDescription = this.reportDescription;
    reportComponent.sumRecords = this.sumRecords;

    await reportComponent.ngOnInit();
  } 

  clearForms(){
    this.filter.document.reset()
    this.filter.issuer.reset()
    this.filter.situation.reset()
    this.filter.dateStart.reset()
    this.filter.dateFinish.reset()
  }
  
  tabChange(){
    if(this.titles[this.tabIndex].length > 0)
      return  
    this.getData()
  }

  getValue(item:any) {
    return 'R$ '+item.value
  }

  getValueString(item:string) {    
    return 'R$ '+item;
  }

  getSituation(item:any) {
    let situation = []
    situation[0] =`<span class="span-styles" style="background:#006E9D; color:#FFF;">Aberto</span>`;
    situation[1] =`<span class="span-styles" style="background:#EB5757; color:#FFF;">Vencido</span>`;
    situation[2] =`<span class="span-styles" style="background:#FFC000; color:#FFF;">Baixado Parcialmente</span>`;
    situation[3] =`<span class="span-styles" style="background:#07BF56; color:#FFF;">Baixado Integralmente</span>`;

    return situation[item.titleSituation]
  }

  getSituationString(item:number) {
    let situation = []
    situation[0] =`Aberto`;
    situation[1] =`Vencido`;
    situation[2] =`Baixado Parcialmente`;
    situation[3] =`Baixado Integralmente`;

    return situation[item]
  }

  getState(item:any) {
      return item.isOK ? `<span class="span-styles" style="background:#07BF56; color:#FFF;">Ok</span>` : `<span class="span-styles" style="background:#EB5757; color:#FFF;">Pendente</span>`
  }

  getStateString(item:any) {
    return item ? 'Ok' : 'Pendente'   
  }
}