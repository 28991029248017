import { Component, HostListener, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InputPropComponent } from '@app/components/form/input-prop/input-prop.component';
import { Exam, ExamResolve, MovimentationProcess } from '@app/services/movimentation-process/movimentation-process';
import { MovimentationProcessService } from '@app/services/movimentation-process/movimentation-process.service';
import { HttpResponsePageable } from '@app/services/services.types';
import { SpanExamSituation } from '@app/util/ESpanExamSituation';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';

@Component({
  selector: 'app-typing',
  templateUrl: './typing.component.html',
  styleUrls: ['./typing.component.scss']
})
export class TypingComponent implements OnInit {
  number: Number = 0;
  atributeName:String = '';
  analyteName:String = '';
  name:String = '';
  referenceValue: String = '';
  agreement: String = '';
  atributesModal: Array<Object> = [];
  examsModal: Array<Object> = [];
  examsFormGroup: Array<UntypedFormGroup> = [];
  checkboxFormGroup: Array<UntypedFormGroup> = [];
  allExams: Array<MovimentationProcess> = [];
  exams: Array<Exam> = [];
  registers: Array<ExamResolve> = [];
  actionsList: Array<String> = ['Digitar'];
  title: String = 'Digitação';
  highlightedRow: Guid | null = null;
  highlightedRowAtribute: any;
  @ViewChildren(InputPropComponent) comp!: QueryList<InputPropComponent>;

  @HostListener('keydown.tab', ['$event'])
  onTabKey(event: any) {    
    //@ts-ignore            
    if ((this.examsModal.filter((x) => x.id === this.highlightedRow)[0].atributeAnalyte.length) - 1 > this.examsModal.filter((x) => x.id === this.highlightedRow)[0].atributeAnalyte.findIndex((x:any) => x.id == this.highlightedRowAtribute)){      
      //@ts-ignore
      this.onRowClickAtribute(this.examsModal.filter((x) => x.id === this.highlightedRow)[0].atributeAnalyte[this.examsModal.filter((x) => x.id === this.highlightedRow)[0].atributeAnalyte.findIndex((x:any) => x.id == this.highlightedRowAtribute)+1].id);
    } else {      
      //@ts-ignore
      this.onRowClick(this.examsModal[this.examsModal.findIndex((x) => x.id === this.highlightedRow)+1].id);
    }
    setTimeout(() => {
      const inputs: Array<InputPropComponent> = this.comp.toArray();
      const codeInputProp = inputs.find((inputProp) => inputProp.placeholder === 'Resultado');
      if (codeInputProp) codeInputProp.input.nativeElement.focus();        
    });
  }

  form = {
    requisition: new UntypedFormControl(null),
    type: new UntypedFormControl(1),
    value: new UntypedFormControl(null),
  }

  genderType = [
    {
      value: 0,
      label: 'Não Informado'
    },
    {
      value: 1,
      label: 'Feminino'
    },
    {
      value: 2,
      label: 'Masculino'
    }
  ];

  typeAtributeReference = [
    {
      value: 0,
      label: 'Anos'
    },
    {
      value: 1,
      label: 'Meses'
    },
    {
      value: 2,
      label: 'Dias'
    }
  ];

  type = [
    {
      value: 1,
      label: 'Digitar'
    },
    {
      value: 2,
      label: 'Conferir'
    },
    {
      value: 3,
      label: 'Liberar'
    },
    {
      value: 4,
      label: 'Imprimir'
    },
    {
      value: 5,
      label: 'Entregar'
    }
  ];

  constructor(private _movimentationProcessService:MovimentationProcessService, private _dialog: MatDialog) { }

  ngOnInit() {
    
  }

  onRowClick(rowId: Guid) {
    this.highlightedRow = rowId;
    //@ts-ignore
    this.atributesModal = this.examsModal.filter((x) => x.id === rowId)[0].atributeAnalyte;  
    //@ts-ignore
    this.agreement = this.examsModal.filter((x) => x.id === rowId)[0].agreement;    
    //@ts-ignore
    this.analyteName = this.examsModal.filter((x) => x.id === rowId)[0].name;    
    this.atributeName = '';
    this.referenceValue = '';
    this.highlightedRowAtribute = null;
    if (this.atributesModal.length > 0){      
      //@ts-ignore
      this.highlightedRowAtribute = this.atributesModal[0].id;
      //@ts-ignore
      this.referenceValue = this.atributesModal[0].valueReference;
      //@ts-ignore
      this.atributeName = this.atributesModal[0].name;
    }
  }

  onRowClickAtribute(rowId: String) {
    this.highlightedRowAtribute = rowId;
    //@ts-ignore
    this.atributeName = this.atributesModal.filter((x) => x.id === rowId)[0].name;
    //@ts-ignore
    this.referenceValue = this.atributesModal.filter((x) => x.id === rowId)[0].valueReference;
  }

  async get(){
    this.actionsList = [this.type[this.form.type.value-1].label];

    switch (this.actionsList[0]) {
      case 'Digitar':
        this.title = 'Digitação';
        break;

      case 'Conferir':
        this.title = 'Conferência';
        break;

      case 'Liberar':
        this.title = 'Liberação';
        break;

      case 'Imprimir':
        this.title = 'Impressão';
        break;

      case 'Entregar':
        this.title = 'Entrega';
        break;
    }

    //this.registers = [];
    await this._movimentationProcessService.get('?ProcessAction='+this.form.type.value+'&Search='+this.form.requisition.value).then(
      (res : any) => {                
        if (res.data.length != 0) {
          this.allExams = res.data;   
          this.getValueReference();                 
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );        
  }

  getValueReference(){
    var i = 0;
    this.allExams.forEach((y: any) => {         
      this.name = y.customerName;
      this.number = y.number;
      //@ts-ignore
      y.agreementExamsRequest.forEach((x: any) => {                                  
        var atributes = x.atributeAnalyte;    
        var valuesReference : any = [];
        atributes.forEach((atribute: any) => {                          
          var notInformmedReference = this.getStringReference(atribute.referenceAtributeAnalyte.filter((x: any) => x.genderType == 0));          
          var femaleReference = this.getStringReference(atribute.referenceAtributeAnalyte.filter((x: any) => x.genderType == 1));          
          var maleReference = this.getStringReference(atribute.referenceAtributeAnalyte.filter((x: any) => x.genderType == 2));          
          var nonBinaryReference = this.getStringReference(atribute.referenceAtributeAnalyte.filter((x: any) => x.genderType == 3));
          var reference = notInformmedReference + femaleReference + maleReference + nonBinaryReference;
          //@ts-ignore
          var id = new Guid().guid;//TO DO
          //@ts-ignore
          this.examsFormGroup[id] = new UntypedFormControl();
          //@ts-ignore
          var obj = {
            //@ts-ignore
            id: id,
            name: atribute.name,
            valueReference:reference
          }
          valuesReference.push(obj);
        });                         
        if (i == 0){
          this.highlightedRow = x.id;
          this.atributesModal = valuesReference;          
          this.agreement = x.agreementDescription;
          this.highlightedRowAtribute = valuesReference[0].id;
          this.referenceValue = valuesReference[0].valueReference;
          this.atributeName = valuesReference[0].name;
          this.analyteName = x.name;
        }
        this.examsModal[i] = {
          id:x.id,
          name: x.name,
          material:x.description,
          agreement: x.agreementDescription,
          atributeAnalyte:valuesReference
        }
        i++;
      });
    });
  }

  getStringReference(data: any) {
    var stringReference = '';
    if (data.length > 0){
      var genderType = data[0].genderType;
      
      switch (genderType) {
        case 0://Não Informado
          stringReference = 'Não Informado:\n';
          break;
        case 1://Feminino
          stringReference = 'Feminino:\n';
          break;
        case 2://Masculino
          stringReference = 'Masculino:\n';
          break;
      }

      data.forEach((element: any) => {
        var line = 'De ' + element.ageMin + ' ' + this.typeAtributeReference[element.typeAgeMin].label + ' até ' + element.ageMax + ' ' + this.typeAtributeReference[element.typeAgeMax].label + ': Superior a ' + element.valueMin + ' e Inferior a ' + element.valueMax + '\n';
        stringReference += line;
      });
    }

    return stringReference;
  }
}