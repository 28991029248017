import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { SipniService } from '@app/services/vaccines/sipni.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { ResolveLaboratory, ResolveSipni, Sipni, Actions } from './sipni.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { DialogComponent } from '@app/components/form/dialog/dialog.component';
@Component({
  selector: 'app-sipni',
  templateUrl: './sipni.component.html',
  styleUrls: ['./sipni.component.scss']
})
export class SipniComponent implements OnInit {

 @ViewChild(DialogComponent) dialog!: DialogComponent


  editId: string = '';

  total: number = 0;
  page: number = 1;
  index: number = 50;

  itens: Array<ResolveLaboratory> = []

  filter = {
    Search: new UntypedFormControl(),
    isActive: new UntypedFormControl(false)
  }

  form = {
    acronym: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    active: new UntypedFormControl(false)
  }

  resolveLaboratory: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Sigla",
      method: '',
      retrive: "acronym",
      after: '',
      before: '',
    },
    {
      label: "Descrição",
      method: '',
      retrive: "description",
      after: '',
      before: '',
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ];

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  constructor(
    private _sipniService: SipniService,
    private _dialog: MatDialog,
    private _dataPipe: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe
  ) { }


  ngOnInit() {
    this.get()
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let search: string = this.filter.Search.value ? this.filter.Search.value : null;

    let isActive = this.filter.isActive.value ? this.filter.isActive.value: null;

    await this._sipniService.getPagination({page: this.page, index: this.index, search, isActive}).then(
      (res:any) => {
        this.itens = [];
        this.total = res.sumRecords;
        if(res.data.length > 0) {
          this.resolve(res.data, this.resolveLaboratory);
        }
      }
    ).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.itens = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.itens.push(obj);
    });
  }

  getActive(item: Sipni) {
    return this._activePipe.transform(item.isActive)
  }

  getDate(item: Sipni) {
    return this._dataPipe.transform(item.updatedAt);
  }

  post() {
    let obj = {
      acronym: this.form.acronym.value,
      description: this.form.description.value
    }

    this._sipniService.post(obj).then((res:any) => {
      SwAlSetttings.Sucesso('Sigla Cadastrada!')
      this.get()
      this.dialog.closeDialog()
    }).catch(err => {
      SwAlSetttings.Error(err.error.errors)
    })
  }

  put() {
    let obj = {
      acronym: this.form.acronym.value,
      description: this.form.description.value,
    }

    this._sipniService.put(obj, new Guid(this.editId)).then((res:any) => {
      SwAlSetttings.Sucesso('Sigla Editada!')
      this.get()
      this.dialog.closeDialog()
    }).catch(err => SwAlSetttings.Error(err.error))
  }

  delete(idToDelete: string) {
    SwAlSetttings.ALERT_CONFIRM.fire('Deseja excluir a Sigla?').then(
      async (r) => {
        if(r.isConfirmed) {
          this._sipniService.delete(new Guid(idToDelete)).then((res:any) => {
            SwAlSetttings.Sucesso('Sigla Excluída!')
            this.get();
            this.dialog.closeDialog()
        })};
    }).catch(err => SwAlSetttings.printError(err))
  }

  setValueOnForms(itens: ResolveSipni) {
    this.form.acronym.setValue(itens.Sigla)
    this.form.description.setValue(itens.Descrição)
    this.form.active.setValue(itens.Ativo.includes('active') ? true: false)
  }

  resetForms() {
    this.form.acronym.setValue('')
    this.form.description.setValue('')
    this.form.active.setValue(false)
  }

  putActive(id: string, value: string) {
    this._sipniService.patchActive(id, value).then((x:any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.get();
    }).catch(err => SwAlSetttings.printError(err))
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.editId = emit.object.id ?? ''
        this.dialog.openDialogModal(true, emit);
        this.setValueOnForms(emit.object)
        break;
      case 'Excluir':
        this.delete(emit.object.id)
        break;
      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  resetFilter(){
    this.filter.Search.reset();
    this.filter.isActive.reset();
  }

  afterClose() {
    this.resetForms()
    this.editId = ''
  }
}
