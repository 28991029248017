import { Injectable } from '@angular/core';
import {BaseAuthService} from "./base-auth.service";
import { HttpAuthService } from './http-auth.service';
import axios from "axios";
@Injectable({
    providedIn: 'root'
})
export class ProfilePathAuth extends BaseAuthService {

    constructor(HttpAuth: HttpAuthService) {
        super(HttpAuth, 'v1/profile');
    }
}
