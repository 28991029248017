<div class="contentPage">
  <div class="flex align-center w-100 gap-2 flex-between headerResponsive">
    <div class="flex gap-2 align-center w-100 titleResponsive">
      <h1>{{'company.title' | translate}}</h1>
      <app-button (click)="newCompany()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus type="text" [placeholder]="'company.name'" [form]="filterForm.name" ></input-prop>        
        <input-prop type="text" [placeholder]="'company.socialReason'" [form]="filterForm.socialReason" ></input-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" [form]="filterForm.active" [label]="'company.isActive'"></checkbox-prop>
      </div>
      <app-button (click)="get()" class="queryBtn" [placeholder]="'company.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [backPagination]="true" [Pagination]="true" [ClickInItem]="ActionsExtra" [Dropdown]="true" [sumRecords]="paginationInfo.sumRecords" [pageNumber]="paginationInfo.page" [indexNumber]="paginationInfo.index" [Itens]="companys" [Actions]="['Editar']" (parentFun)="Actions($event)" (changePage)="get($event)">
      <ng-template let-rowDetail #header>
        <div class="flex flex-between align-center">
          <h2>{{'company.titleUnit' | translate}}</h2>
          <app-button (click)="openDialogModalUnity()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
        </div>
        <app-table [sumRecords]="paginationUnity.sumRecords" [pageNumber]="paginationUnity.page" [indexNumber]="paginationUnity.index" [backPagination]="false" [Pagination]="true" [ClickInItem]="ActionsExtra" [noShadow]="true" [Itens]="units[rowDetail.id]" [Actions]="['Editar']" (changePage)="getUnity($event)" (parentFun)="ActionsUnity($event, rowDetail.id)"></app-table>
      </ng-template>      
    </app-table>
  </div>
</div>

<ng-template #dialogModal let-analyte>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
      <h1>{{editId ? ('company.editCompany' | translate) : ('company.newCompany' | translate)}}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="flex flex-column mt-32 gap-3">
        <input-prop (keyup.enter)="editId? put() : post()" class="w-100" [placeholder]="'company.name'" [form]="form.name"></input-prop>
        <input-prop (keyup.enter)="editId? put() : post()" class="w-100" [placeholder]="'company.socialReason'" [form]="form.socialReason"></input-prop>        
      </div>      
    </div>
    <div class="boxAddress mt-32">
      <h2>{{'company.address' | translate}}</h2>
      <div class="flex gap-form mt-32">
        <input-prop class="w-100" class="w-100" (focusout)="getCep(false)" [placeholder]="'company.zipCode'" [form]="form.CEP" mask="00000-000"></input-prop>
        <input-prop class="w-100" [placeholder]="'company.address'" [form]="form.street"></input-prop>
        <input-prop class="w-100" [placeholder]="'company.number'" [form]="form.number" type="number"></input-prop>
      </div>
      <div class="flex gap-form mt-32">
        <input-prop class="w-100" [placeholder]="'company.complement'" [form]="form.complement"></input-prop>
        <input-prop class="w-100" [placeholder]="'company.neighborhood'" [form]="form.neighborhood"></input-prop>
        <input-prop class="w-100" [placeholder]="'company.city'" [form]="form.city"></input-prop>
      </div>
      <div class="flex gap-form mt-32">
        <input-prop class="w-100" [placeholder]="'company.state'" [form]="form.state"></input-prop>
        <input-prop class="w-100" [placeholder]="'company.country'" [form]="form.country"></input-prop>
        <div class="w-100"></div>
      </div>
    </div>    
    <div class="mt-32 flex flex-center">
      <app-button (click)="editId? put() : post()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalUnity>
  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1>{{unityIdToEdit ? ('company.editUnit' | translate) : ('company.newUnit' | translate)}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="flex flex-between gap-2 mt-32">
      <input-prop [form]="formUnity.externalId" [placeholder]="'company.code'"></input-prop>
      <input-prop class="w-100" [form]="formUnity.description" [placeholder]="'company.description'"></input-prop>  
    </div>
    <div class="mt-32">
      <input-prop class="w-100" [form]="formUnity.cnpj" [mask]="'00.000.000/0000-00'" [placeholder]="'CNPJ'"></input-prop>  
    </div>
    <div class="boxAddress mt-32">
      <h2>{{'company.address' | translate}}</h2>
      <div class="flex gap-form mt-32">
        <input-prop class="w-100" class="w-100" (focusout)="getCep(true)" [placeholder]="'company.zipCode'" [form]="formUnity.CEP" mask="00000-000"></input-prop>
        <input-prop class="w-100" [placeholder]="'company.address'" [form]="formUnity.street"></input-prop>
        <input-prop class="w-100" [placeholder]="'company.number'" [form]="formUnity.number" type="number"></input-prop>
      </div>
      <div class="flex gap-form mt-32">
        <input-prop class="w-100" [placeholder]="'company.complement'" [form]="formUnity.complement"></input-prop>
        <input-prop class="w-100" [placeholder]="'company.neighborhood'" [form]="formUnity.neighborhood"></input-prop>
        <input-prop class="w-100" [placeholder]="'company.city'" [form]="formUnity.city"></input-prop>
      </div>
      <div class="flex gap-form mt-32">
        <input-prop class="w-100" [placeholder]="'company.state'" [form]="formUnity.state"></input-prop>
        <input-prop class="w-100" [placeholder]="'company.country'" [form]="formUnity.country"></input-prop>
        <div class="w-100"></div>
      </div>
    </div>    
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="unityIdToEdit ? putUnity() : postUnity()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>