<div (click)="openModalEmit()">
    <app-button
        (click)="openDialogModal(false)"
        [placeholder]="placeholderButton"
        [matIcon]="matIconName"
        [class]="'flex gap-2 align-center btn'"
    ></app-button>
</div>

<ng-template #dialogModal let-data>
    <div class="flex flex-between w-100 align-center">
        <h1 *ngIf="!data">{{ title | translate }}</h1>
        <h1 *ngIf="data">
            {{
                data.edit
                    ? ("appDialog.edit" | translate)
                    : maleText
                    ? ("appDialog.newMale" | translate)
                    : ("appDialog.new" | translate)
            }}
            {{ title | translate }}
        </h1>
        <svg
            width="21"
            (click)="closeDialog()"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
            />
        </svg>
    </div>
    <ng-content select=".bodyFilter"></ng-content>
</ng-template>
