import { DefaultFormats } from "@app/pipe/localeDate.pipe"

export interface Actions {
  action: string,
  id: string,
  context: string | null,
  object: any
}
export interface ResolveGeneric {
  id: string
  isActive: boolean
  createdAt: string
  updatedAt: string
}
export interface ResolvedItem extends ResolveGeneric {
  default: false
  description: string
  externalId: string
  index: number
  priceTableId: string
  agreementId: string
  mask: string,
  code: string
}

export interface ResolvedParameter extends ResolveGeneric {
  isValid: boolean
  typeParameter: number
  agreementId: string
}

export interface ResolvedDoctor extends ResolveGeneric {
 council: string
 doctorId: string
 doctorIsActive: boolean
 email: string
 name: string
 officePhone: string
 registrationNumber: string
 registrationState: string
}

export interface ResolvedAnalyte extends ResolveGeneric{
  analyteId: string
  analyteMaterialId: string
  description: string
  materialDescription: string
  materialId: string
  name: string
  value: number
} 

export interface ResolvedAgreementSpecialValue {
  Ativo: string 
  "Criado em": string
  "Descrição do Exame": string
  "Descrição do Material": string
  "Editado em": string
  "Valor Especial": string
  _hide_analyteId: string
  _hide_analyteMaterialId: string
  _hide_value: number
  id: string
}

export interface ResolvedAgreementPlanObj {
  "Descrição": string
  "Máscara da Tabela": string
  "Plano Padrão": string
  "Tabela de Honorários": string
  _hide_priceTableId: string
  id: string
  "Índice da Tabela": string
  "Índice do Plano": string
}

export const  resolveAgreementMessageParameters: Array<Object> = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEditParameters.resolveAgreementMessageParameters.parameterType',
    retrive: '',
    method: 'getParameterType',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEditParameters.resolveAgreementMessageParameters.createdAt',
    retrive: '',
    method: 'getCreatedDate',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEditParameters.resolveAgreementMessageParameters.updatedAt',
    retrive: '',
    method: 'getUpdatedDate',
    after: '',
    before: ''
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'getActive',
    after: '',
    before: ''
  }
];


export const  resolveAgreementSpecialValue: Array<Object> = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEditValueExam.resolveAgreementSpecialValue.code',
    retrive: '',
    method: 'getCode',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEditValueExam.resolveAgreementSpecialValue.name',
    retrive: 'name',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEditValueExam.resolveAgreementSpecialValue.description',
    retrive: 'materialDescription',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEditValueExam.resolveAgreementSpecialValue.value',
    retrive: '',
    method: 'getValue',
    after: '',
    before: 'R$ '
  },
  {
    label: 'agreementsEditValueExam.resolveAgreementSpecialValue.createdAt',
    retrive: '',
    method: 'getCreatedDate',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEditValueExam.resolveAgreementSpecialValue.updatedAt',
    retrive: '',
    method: 'getUpdatedDate',
    after: '',
    before: ''
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'getActive',
    after: '',
    before: ''
  },
  {
    label: '_hide_analyteId',
    retrive: 'analyteId',
    method: '',
    after: '',
    before: ''
  },
  {
    label: '_hide_value',
    retrive: 'value',
    method: '',
    after: '',
    before: ''
  },
  {
    label: '_hide_analyteMaterialId',
    retrive: 'analyteMaterialId',
    method: '',
    after: '',
    before: ''
  }
];

export const resolvePreAgreementPlan: Array<Object> = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEdit.resolvePreAgreementPlan.description',
    retrive: 'description',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEdit.resolvePreAgreementPlan.plan',
    retrive: '',
    method: 'getDefaultPlan',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEdit.resolvePreAgreementPlan.indexPlan',
    retrive: '',
    method: 'getPlanIndex',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEdit.resolvePreAgreementPlan.tablePrice',
    retrive: '',
    method: 'getPlanPriceTable',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEdit.resolvePreAgreementPlan.indexTable',
    retrive: '',
    method: 'getPlanPriceTableIndex',
    after: '',
    before: ''
  },
  {
    label: '_hide_priceTableId',
    retrive: 'priceTableId',
    method: '',
    after: '',
    before: ''
  }
];

export const resolveAgreementPlan: Array<Object> = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEdit.resolveAgreementPlan.description',
    retrive: 'description',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEdit.resolveAgreementPlan.controlPlan',
    retrive: '',
    method: 'getDefaultPlan',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEdit.resolveAgreementPlan.indexPlan',
    retrive: '',
    method: 'getPlanIndex',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEdit.resolveAgreementPlan.priceTable',
    retrive: '',
    method: 'getPlanPriceTable',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEdit.resolveAgreementPlan.indexTable',
    retrive: '',
    method: 'getPlanPriceTableIndex',
    after: '',
    before: ''
  },
  {
    label: '_hide_priceTableId',
    retrive: 'priceTableId',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEdit.resolveAgreementPlan.createdAt',
    retrive: '',
    method: 'getCreatedDate',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEdit.resolveAgreementPlan.updatedAt',
    retrive: '',
    method: 'getUpdatedDate',
    after: '',
    before: ''
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'getActive',
    after: '',
    before: ''
  }
];

export const resolveUnitys: Array<Object> = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEditParameters.resolveUnitys.companyName',
    retrive: 'companyName',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEditParameters.resolveUnitys.name',
    retrive: 'name',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreementsEditParameters.resolveUnitys.createdAt',
    retrive: '',
    method: 'formatField',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full
  },
  {
    label: 'agreementsEditParameters.resolveUnitys.updatedAt',
    retrive: '',
    method: 'formatField',
    fieldName: 'updatedAt',
    type: 'dateUpdate',
    dateFormat: DefaultFormats.full
  },
  {
    label: "Ativo",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'checkbox'
  }
];

