import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RegistrationReportComponent } from '@app/components/form/registration-report/registration-report.component';
import { Get } from '@app/modules/registers/components/agreement/agreements/agreements.model';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { AgreementsService } from '@app/services/agreements/agreements.service';
import { TenantAuthService } from '@app/services/auth/tenantAuth/tenant-auth.service';
import { SwAlSetttings } from '@app/util/swal.settings';
import { HeaderMapping, ReportFilter } from '@app/modules/reports/reports-model';


@Component({
  selector: 'app-agreements-report',
  templateUrl: './agreements-report.component.html',
  styleUrls: ['./agreements-report.component.scss']
})
export class AgreementsReportComponent implements OnInit {


  headerMapping: HeaderMapping = {
    externalId: { title: 'Cód.', width: 'auto' },
    description: { title: 'Descrição', width: '*' },
    typeInvoice: { title: 'Tipo', width: 'auto' },
    controlPlan: { title: 'Controla Plano', width: 'auto' },
    index: { title: 'Índice', width: 'auto' },
    createdAt: { title: 'Criado em', width: 'auto' },
    updatedAt: { title: 'Atualizado em', width: 'auto' },
    isActive: { title: 'Ativo', width: 'auto' }
  };

  reportDescription: string = 'Relatório de Cadastro de Convênios';
  reportFilters: ReportFilter[][] = [];

  sumRecords!: number;
  receivedDataForPrint!: object[];

  reportObject: Array<Object> = [];

  total: number = 0;
  page: number = 1;
  index: number = 50;

  isActiveOptions: Array<{ label: string, value: string | null | boolean }> = [{ label: 'Todos', value: 'todos' }, { label: 'Somente Ativos', value: true }, { label: 'Somente Inativos', value: false }]

  formGroup = new UntypedFormGroup({
    isActive: new UntypedFormControl('todos')
  });

  searchForm = {
    description: new UntypedFormControl(null),
    typeInvoice: new UntypedFormControl(null),
    controlPlan: new UntypedFormControl(null),
    index: new UntypedFormControl(null),
    externalCode: new UntypedFormControl(null)
  }

  registerAgreement: Array<Object> = [];

  resolveAgreements: Array<Object> = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Código Externo',
      retrive: '',
      method: 'getExternalCode',
      after: '',
      before: ''
    },
    {
      label: 'Descrição',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Tipo',
      retrive: '',
      method: 'getInvoice',
      after: '',
      before: ''
    },
    {
      label: 'Controla Plano',
      retrive: '',
      method: 'getControlPlan',
      after: '',
      before: ''
    },
    {
      label: 'Índice',
      retrive: '',
      method: 'getIndex',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'createdAt',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'updatedAt',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    },
  ];

  typeInvoiceOptions: Array<{ label: string, value: number }> = [
    {
      label: "Particular",
      value: 1
    },
    {
      label: "Faturado",
      value: 2
    }
  ]


  constructor(
    private _agreementService: AgreementsService,
    private _createdAt: LocalaDatePipe,
    private _updatedAt: LocalaDatePipe,
    private _localeDate: LocalaDatePipe,
    private _http: HttpClient,
    private _tenantAuth: TenantAuthService
  ) { }

  ngOnInit() {
    this.getAgreements();
  }

  getExternalCode(item: Get) {
    return item.externalId.replace('.', '');
  }

  createdAt(item: Get) {
    return this._createdAt.transform(item.createdAt);
  }

  updatedAt(item: Get) {
    return this._updatedAt.transform(item.updatedAt);
  }

  getActive(item: Get) {
    return item.isActive ? "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Sim</span>" : "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Não</span>";
  }

  getInvoice(item: Get) {
    return item.typeInvoice === 1 ? '<span class="span-styles" style="background: #07BF56; color: #FFFFFF;">Particular</span>' : '<span class="span-styles" style="background: #006E9D; color: #FFFFFF;">Faturado</span>';
  }

  getControlPlan(item: Get) {
    return item.controlPlan ? '<span class="span-styles" style="background: #07BF56; color: #FFFFFF;">Sim</span>' : '<span class="span-styles" style="background: #FF0000; color: #FFFFFF;">Não</span>';
  }

  getIndex(item: Get) {
    return item.index.toFixed(4);
  }

  async getAgreements(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    const filterObject = {
      description: this.searchForm.description.value ?? null,
      controlPlan: this.searchForm.controlPlan.value ?? null,
      index: this.searchForm.index.value ?? null,
      externalCode: this.searchForm.externalCode.value ?? null,
      typeInvoice: this.searchForm.typeInvoice.value ?? null,
      page: this.page,
      numberRegistry: this.index,
      isActive: this.formGroup.controls['isActive'].value === 'todos' ? null : JSON.parse(this.formGroup.controls['isActive'].value)
    }

    await this._agreementService.getPagination(filterObject).then(
      (res) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolve(res.data, this.resolveAgreements);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.registerAgreement = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerAgreement.push(obj);
    });
  }

  clearForms() {
    this.searchForm.externalCode.reset();
    this.searchForm.description.reset();
    this.searchForm.controlPlan.reset();
    this.searchForm.typeInvoice.reset();
    this.searchForm.index.reset();
  }

  async getDataForPrint() {
    this.reportObject = []

    let filterObject = {
      description: this.searchForm.description.value ?? null,
      controlPlan: this.searchForm.controlPlan.value ?? null,
      index: this.searchForm.index.value ?? null,
      externalCode: this.searchForm.externalCode.value ?? null,
      typeInvoice: this.searchForm.typeInvoice.value ?? null,
    }

    await this._agreementService.getPagination<Get>(filterObject).then(
      (res) => {
        res.data.forEach((x) => {
          const obj = {
            externalId: x.externalId.replace('.', ''),
            description: x.description,
            typeInvoice: x.typeInvoice === 1 ? 'Particular' : 'Faturado',
            controlPlan: x.controlPlan,
            index: x.index.toFixed(4),
            createdAt: x.createdAt,
            updatedAt: x.updatedAt,
            isActive: x.isActive ? 'Ativo' : 'Inativo',
          }
          this.reportObject.push(obj)
        })
        this.sumRecords = res.sumRecords;
        this.receivedDataForPrint = this.reportObject;
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  async generatePdf() {
    await this.getDataForPrint();
    this.reportFilters = [];
    var filters = [];

    if (this.searchForm.externalCode.value) {
      const obj = {
        title: 'Código: ', content: this.searchForm.externalCode.value
      }

      filters.push(obj);
    }

    if (this.searchForm.description.value) {
      const obj = {
        title: 'Descrição: ', content: this.searchForm.description.value
      }

      filters.push(obj);
    }

    if (this.searchForm.controlPlan.value) {
      const obj2 = {
        title: 'Controla Plano: ', content: 'Sim'
      }

      filters.push(obj2);
    }

    if (filters.length == 3) {
      this.reportFilters.push(filters);
      filters = [];
    }

    if (this.searchForm.typeInvoice.value) {
      const obj = {
        title: 'Tipo: ', content: this.typeInvoiceOptions[this.searchForm.typeInvoice.value - 1].label
      }

      filters.push(obj);
    }

    if (filters.length == 3) {
      this.reportFilters.push(filters);
      filters = [];
    }

    if (this.searchForm.index.value) {
      const obj = {
        title: 'Índice: ', content: this.searchForm.index.value
      }

      filters.push(obj);
    }

    if (filters.length > 0) {
      this.reportFilters.push(filters);
      filters = [];
    }

    const reportComponent = new RegistrationReportComponent(this._http, this._localeDate, this._tenantAuth);
    reportComponent.headerMapping = this.headerMapping;
    reportComponent.reportFilters = this.reportFilters;
    reportComponent.receivedData = this.receivedDataForPrint;
    reportComponent.reportDescription = this.reportDescription;
    reportComponent.sumRecords = this.sumRecords;

    await reportComponent.ngOnInit();
  }
}