import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DoctorsService } from "@app/services/doctors/doctors.service";
import { EnumObject } from "@app/services/enum/enum.service.model";
import { ToastService } from "@app/services/translate/toast.service";
import { Guid } from "@app/util/guid";
import { Util } from "@app/util/util";

@Component({
   selector: "app-save-doctor",
   templateUrl: "./save-doctor.component.html",
   styleUrls: ["./save-doctor.component.scss"]
})
export class SaveDoctorComponentOld implements OnInit {
   requisitionId: string | null = "";
   //#region Global Variables / Variáveis Globais
   title: string = "";
   edit: boolean = false;
   disable: boolean = false;
   urlAction: string = "";
   registerUF: Array<Object> = [
      {
         value: "AC",
         label: "AC - Acre"
      },
      {
         value: "AL",
         label: "AL - Alagoas"
      },
      {
         value: "AP",
         label: "AP - Amapá"
      },
      {
         value: "AM",
         label: "AM - Amazonas"
      },
      {
         value: "BA",
         label: "BA - Bahia"
      },
      {
         value: "CE",
         label: "CE - Ceará"
      },
      {
         value: "DF",
         label: "DF - Distrito Federal"
      },
      {
         value: "ES",
         label: "ES - Espirito Santo"
      },
      {
         value: "GO",
         label: "GO - Goiás"
      },
      {
         value: "MA",
         label: "MA - Maranhão"
      },
      {
         value: "MT",
         label: "MT - Mato Grosso"
      },
      {
         value: "MS",
         label: "MS - Mato Grosso do Sul"
      },
      {
         value: "MG",
         label: "MG - Minas Gerais"
      },
      {
         value: "PA",
         label: "PA - Pará"
      },
      {
         value: "PB",
         label: "PB - Paraíba"
      },
      {
         value: "PR",
         label: "PR - Paraná"
      },
      {
         value: "PE",
         label: "PE - Pernambuco"
      },
      {
         value: "PI",
         label: "PI - Piauí"
      },
      {
         value: "RJ",
         label: "RJ - Rio de Janeiro"
      },
      {
         value: "RN",
         label: "RN - Rio Grande do Norte"
      },
      {
         value: "RS",
         label: "RS - Rio Grande do Sul"
      },
      {
         value: "RO",
         label: "RO - Rondônia"
      },
      {
         value: "SC",
         label: "SC - Santa Catarina"
      },
      {
         value: "SP",
         label: "SP - São Paulo"
      },
      {
         value: "SE",
         label: "SE - Sergipe"
      },
      {
         value: "TO",
         label: "TO - Tocantins"
      }
   ];

   gender: Array<EnumObject> = [];

   council: Array<Object> = [
      {
         value: "CRAS",
         label: "CRAS - Conselho Regional de Assistência Social"
      },
      {
         value: "COREN",
         label: "COREN - Conselho Federal de Enfermagem"
      },
      {
         value: "CRF",
         label: "CRF - Conselho Regional de Farmácia"
      },
      {
         value: "CREFITO",
         label: "CREFITO -  Conselho Regional de Fisioterapia e Terapia Ocupacional"
      },
      {
         value: "CRM",
         label: "CRM - Conselho Regional de Medicina"
      },
      {
         value: "CRN",
         label: "CRN - Conselho Regional de Nutrição"
      },
      {
         value: "CRO",
         label: "CRO - Conselho Regional de Odontologia"
      },
      {
         value: "CRP",
         label: "CRP - Conselho Regional de Psicologia"
      },
      {
         value: "CREFONO",
         label: "CREFONO - Conselho Regional de Fonoaudiologia"
      },
      {
         value: "RMS",
         label: "RMS - Registro do Ministério da Saúde"
      },
      {
         value: "CRMV",
         label: "CRMV - Conselho Regional de Medicina Veterinária"
      },
      {
         value: "CRBM",
         label: "CRBM -  Conselho Regional de Biomedicina"
      },
      {
         value: "OUT",
         label: "OUT -  Outros Conselhos"
      }
   ];
   //#endregion

   doctorId: string = "";

   //#region Forms / Formulários
   form = {
      doctorIsActive: new UntypedFormControl(true),
      doctorName: new UntypedFormControl(null, [Validators.required]),
      doctorEmail: new UntypedFormControl(null),
      doctorOfficePhone: new UntypedFormControl(null, [
         Validators.pattern(
            /^(?!.*(\d)\1{3,}.*(\d)\2{3,})\(([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])\) 3[0-9]{3}-[0-9]{4}$/
         )
      ]),
      doctorCouncil: new UntypedFormControl(null, [Validators.required]),
      doctorRegistrationNumber: new UntypedFormControl(null, [
         Validators.required
      ]),
      doctorRegistrationState: new UntypedFormControl(null, [
         Validators.required
      ]),
      doctorCellphone: new UntypedFormControl(null),
      doctorCPF: new UntypedFormControl(null),
      doctorGender: new UntypedFormControl(null),
      addressCEP: new UntypedFormControl(""),
      address: new UntypedFormControl(""),
      addressNumber: new UntypedFormControl(""),
      addressComplement: new UntypedFormControl(""),
      addressDistrict: new UntypedFormControl(""),
      addressCity: new UntypedFormControl(""),
      addressState: new UntypedFormControl(""),
      addressCountry: new UntypedFormControl(""),
      allowsDelivery: new UntypedFormControl(false)
   };
   //#endregion

   constructor(
      private _doctorsService: DoctorsService,
      private _route: ActivatedRoute,
      private _router: Router,
      private _location: Location,
      private _util: Util,
      private _toastService: ToastService
   ) {
      if (_route.snapshot.params["id"] != "Novo") {
         let urlParams: string[] = _route.snapshot.params["id"].split("$");
         let doctorId: string = urlParams[0];
         let action: string = urlParams[1];

         this.doctorId = doctorId;
         this.urlAction = action;

         if (this.urlAction == "View") {
            this.title = "Visualizar Solicitante";
            this.disable = true;
         } else {
            this.edit = true;
            this.title = "Editar Solicitante";
         }
      } else {
         this.title = "Novo Solicitante";
      }

      //#region AutoSave
      if (this.title === "Novo Solicitante") {
         let json: object = {};

         Object.entries(this.form).forEach(([key, control]) => {
            control.valueChanges.subscribe((value) => {
               let tmp = {
                  [key]: value
               };

               json = Object.assign({}, json, tmp);
               localStorage.setItem(
                  `autosave://${this.constructor.name}`,
                  JSON.stringify(json)
               );
            });
         });

         if (localStorage.getItem(`autosave://${this.constructor.name}`)) {
            this._toastService
               .fireConfirmation("doctor.msgNotRegistration")
               .then((x) => {
                  if (x.isConfirmed) {
                     let obj = localStorage.getItem(
                        `autosave://${this.constructor.name}`
                     );

                     Object.entries(this.form).forEach(([key, control]) => {
                        let abc = obj?.includes(key);
                        if (abc) {
                           const convertedObj = JSON.parse(obj ?? "");
                           control.setValue(convertedObj[key]);
                        }
                     });
                  } else {
                     localStorage.removeItem(
                        `autosave://${this.constructor.name}`
                     );
                  }
               });
         }
         //#endregion
      }
   }

   async ngOnInit() {
      if (this.doctorId) {
         await this.getDoctorById();
      }
      this.gender = await this._util.getEnum("EGenderType");
   }

   navigate() {
      this._router.navigateByUrl("Cadastros/Atendimento/Solicitantes");
   }

   back() {
      this._location.back();
   }

   async getDoctorById() {
      if (this.doctorId != "") {
         await this._doctorsService
            .getById(new Guid(this.doctorId))
            .then((response) => {
               if (response.data.length != 0) {
                  const doctor = response.data[0];
                  let address = doctor.deliverAddress;

                  this.form.allowsDelivery.setValue(doctor.allowsDelivery),
                     this.form.doctorIsActive.setValue(doctor.isActive),
                     this.form.doctorName.setValue(doctor.name),
                     this.form.doctorOfficePhone.setValue(doctor.officePhone),
                     this.form.doctorCouncil.setValue(doctor.council),
                     this.form.doctorRegistrationNumber.setValue(
                        doctor.registrationNumber
                     ),
                     this.form.doctorRegistrationState.setValue(
                        doctor.registrationState
                     ),
                     this.form.doctorEmail.setValue(doctor.email),
                     this.form.doctorCPF.setValue(doctor.cpf),
                     this.form.doctorCellphone.setValue(doctor.cellPhone),
                     this.form.doctorGender.setValue(doctor.gender);

                  this.form.address.setValue(address.street);
                  this.form.addressNumber.setValue(address.number);
                  this.form.addressDistrict.setValue(address.neighborhood);
                  this.form.addressCity.setValue(address.city);
                  this.form.addressState.setValue(address.state);
                  this.form.addressCEP.setValue(address.zipCode);
                  this.form.addressComplement.setValue(address.complement);
                  this.form.addressCountry.setValue(address.country);
               }
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });
      }
   }

   async createDoctor() {
      this.requisitionId =
         this._route.snapshot.queryParamMap.get("Requisition");

      let doctor = {
         name: this.form.doctorName.value,
         email: this.form.doctorEmail.value,
         officePhone: this.form.doctorOfficePhone.value,
         gender: this.form.doctorGender.value
            ? parseFloat(this.form.doctorGender.value)
            : 0,
         cpf: {
            value: this.form.doctorCPF.value ?? null
         },
         deliverAddress: {
            zipCode: this.form.addressCEP.value,
            street: this.form.address.value,
            number: this.form.addressNumber.value,
            complement: this.form.addressComplement.value,
            neighborhood: this.form.addressDistrict.value,
            city: this.form.addressCity.value,
            state: this.form.addressState.value,
            country: this.form.addressCountry.value
         },
         cellPhone: this.form.doctorCellphone.value,
         registrationNumber: this.form.doctorRegistrationNumber.value,
         council: this.form.doctorCouncil.value,
         registrationState: this.form.doctorRegistrationState.value,
         allowsDelivery: this.form.allowsDelivery.value
      };

      await this._doctorsService.post(doctor).then((res: any) => {
         this._toastService.fireSuccess("doctor.msgPost");
         if (this.requisitionId) {
            let object = JSON.parse(
               //@ts-ignore
               localStorage.getItem("FormStorageAgreement")
            );
            object.doctorId = res.id;
            localStorage.setItem(
               "FormStorageAgreement",
               JSON.stringify(object)
            );
            this._router.navigateByUrl(
               "MovimentacaoOld/Recepcao/Requisicoes/" +
                  this.requisitionId +
                  "$P$NP"
            );
         } else {
            setTimeout(() => {
               this._router.navigateByUrl("Cadastros/Atendimento/Solicitantes");
            }, 500);
         }
         localStorage.removeItem(`autosave://${this.constructor.name}`);
      });
   }

   async updateDoctor() {
      let doctor = {
         isActive: this.form.doctorIsActive.value,
         name: this.form.doctorName.value,
         email: this.form.doctorEmail.value,
         officePhone: this.form.doctorOfficePhone.value,
         gender: this.form.doctorGender.value
            ? parseFloat(this.form.doctorGender.value)
            : 0,
         cpf: {
            value: this.form.doctorCPF.value ?? null
         },
         deliverAddress: {
            zipCode: this.form.addressCEP.value,
            street: this.form.address.value,
            number: this.form.addressNumber.value,
            complement: this.form.addressComplement.value,
            neighborhood: this.form.addressDistrict.value,
            city: this.form.addressCity.value,
            state: this.form.addressState.value,
            country: this.form.addressCountry.value
         },
         cellPhone: this.form.doctorCellphone.value,
         registrationNumber: this.form.doctorRegistrationNumber.value,
         council: this.form.doctorCouncil.value,
         registrationState: this.form.doctorRegistrationState.value,
         allowsDelivery: this.form.allowsDelivery.value
      };

      await this._doctorsService
         .put(doctor, new Guid(this.doctorId))
         .then((res: any) => {
            this._toastService.fireSuccess("doctor.msgPut");
            setTimeout(() => {
               this._router.navigateByUrl("Cadastros/Atendimento/Solicitantes");
            }, 500);
            localStorage.removeItem(`autosave://${this.constructor.name}`);
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   getCep() {
      let cep: string = "";

      if (this.form.addressCEP.value) {
         let tmpCep = this.form.addressCEP.value;
         cep = tmpCep.replace(/\D/g, "");
      }

      if (cep != "") {
         let validacep: RegExp = /^[0-9]{8}$/;

         if (validacep.test(cep)) {
            this.form.address.setValue("...");
            this.form.addressDistrict.setValue("...");
            this.form.addressCity.setValue("...");
            this.form.addressState.setValue("...");

            fetch(`https://viacep.com.br/ws/${cep}/json/`)
               .then((response) => {
                  return response.json();
               })
               .then((json) => {
                  if (!("erro" in json)) {
                     this.form.addressCEP.setValue(json.cep);
                     this.form.address.setValue(json.logradouro);
                     this.form.addressDistrict.setValue(json.bairro);
                     this.form.addressCity.setValue(json.localidade);
                     this.form.addressState.setValue(json.uf);
                     this.form.addressCountry.setValue("Brasil");
                  } else {
                     this.clearCepForm();
                     setTimeout(() => {
                        this._toastService.fireError("doctor.msgNotCEP");
                     }, 100);
                  }
               });
         }
      }
   }

   clearCepForm() {
      this.form.addressCEP.setValue("");
      this.form.address.setValue("");
      this.form.addressDistrict.setValue("");
      this.form.addressCity.setValue("");
      this.form.addressState.setValue("");
   }
}
