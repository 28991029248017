import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { borderBottomColor } from "html2canvas/dist/types/css/property-descriptors/border-color";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { NGX_MAT_DATE_FORMATS, NgxMatDateFormats } from "@angular-material-components/datetime-picker";
import { scan, startWith } from "rxjs";
import { createMask } from "@ngneat/input-mask";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'input-unset-prop',
  templateUrl: './input-unset-prop.component.html',
  styleUrls: ['./input-unset-prop.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }]
})

export class InputUnsetPropComponent implements OnInit {
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  @Input() type: string = 'text';
  @Input() readonly: boolean = false;
  //@ts-ignore
  @Input() form: UntypedFormControl = new UntypedFormControl(null);
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() mask: any = '';
  @Input() className: string = '';
  @Input() requiredInput?: string;
  @Input() pattern: string = '';
  @Input() prefix = '';
  @Input() noLabelUp = false;

  @Input() tabindexcomponent: string = '';

  @Input() max?: string;
  @Input() min?: string;
  @Input() maxlength?: string;
  @Input() minlength?: string;
  @Input() value?: string;

  PropValue: String = '';
  adapter = false;
  classe: string = '';

  dateInputMask = createMask<String>({
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy',
  });

  dateInputTimeMask = createMask<String>({
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy HH:mm',
  });

  constructor(private _dateAdapter: DateAdapter<Date>) {
    this._dateAdapter.setLocale('pt');
  }

  validate(s: String) {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    return s.match(rgx);
  }

  onChange(value: String) {
    this.form.setValue(value);
  }

  ngAfterViewInit() {
    this.form.valueChanges
      .subscribe(values => {
        if (this.type == "datetime-local" && values.includes('.') && !values.includes('Z')) {
          this.form.setValue(this.form.value + 'Z');
          this.adapter = true;
        }
      });
    if (this.type == "date" || this.type == "datetime-local") {
      try {
        if (this.form.value) {
          let date = new Date(this.form.value);
          setTimeout(() => {
            this.form.setValue(date.toISOString());
          })
        }
      }
      catch (e) {

      }
    }
  }

  ngOnInit(): void {

    if (this.type == 'date') {
      this.mask = 'd0/M0/0000';
    }

    if (this.type == 'datetime-local') {
      this.mask = 'd0/M0/0000 h0:m0';
    }

    this.classe = this.className + ' input-row';

    if (this.prefix) {
      this.form.setValue('111');
    }

    if (!this.form) {
      this.form = new UntypedFormControl();
      if (this.value) {
        this.form.setValue(this.value + 'Z');
      }
    }
  }


  showError(errorName: string): boolean {
    if (this.form.touched && this.form.errors?.[errorName] && this.requiredInput) {
      return true
    }
    return false
  }


  OnDown() {
    try {
      if (this.form.value) {
        let date = new Date(this.form.value + 'Z');
        setTimeout(() => {
          this.form.setValue(date.toISOString());
        })
      }
    }
    catch (e) {

    }
  }
  /**
   * Invoked when the model has been changed
   */

}
