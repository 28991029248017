<content-filter-layout
    [title]="'Acessos' | translate"
    [createButton]="{
        title: 'default.new' | translate,
        action: true
    }"
    (clickButton)="openModal()"
    [formFilter]="formFilter"
    (clearFilters)="resetFilter()"
    (searchFilters)="get()"
    [formFilterHeader]="formCompanyUnit"
    (select1)="get()"
>
    <forms-table
        #requisition
        [actions]="actions"
        [rows]="data"
        [columns]="columns"
    >
        <ng-container ngProjectAs="[pagination]">
            <forms-pagination
                (changePage)="changePage($event)"
                [totalPerPage]="30"
                [amount]="total | async"
                [page]="page"
            ></forms-pagination>
        </ng-container>
    </forms-table>
</content-filter-layout>
