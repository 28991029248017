import { SwAlSetttings } from '@app/util/swal.settings';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { MatDialog } from '@angular/material/dialog';
import { SchemmaService } from '@app/services/vaccines/schemma.service';
import { UntypedFormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Guid } from '@app/util/guid';
import { Schemma, Actions } from './schemma.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { DialogComponent } from '@app/components/form/dialog/dialog.component';

@Component({
  selector: 'app-schemma',
  templateUrl: './schemma.component.html',
  styleUrls: ['./schemma.component.scss']
})
export class SchemmaComponent implements OnInit {

  @ViewChild(DialogComponent) dialog!: DialogComponent


  editId: string = '';

  total: number = 0;
  page: number = 1;
  index: number = 50;

  itens: Array<object> = []

  typeValue: Array<object> = [
    {
      value: 0,
      label: 'Dia'
    },
    {
      value: 1,
      label: 'Mês'
    },
    {
      value: 2,
      label: 'Ano'
    }
  ]

  month: Array<object> = [
    {
      value: 1,
      label: 'Janeiro'
    },
    {
      value: 2,
      label: 'Fevereiro'
    },
    {
      value: 3,
      label: 'Março'
    },
    {
      value: 4,
      label: 'Abril'
    },
    {
      value: 5,
      label: 'Maio'
    },
    {
      value: 6,
      label: 'Junho'
    },
    {
      value: 7,
      label: 'Julho'
    },
    {
      value: 8,
      label: 'Agosto'
    },
    {
      value: 9,
      label: 'Setembro'
    },
    {
      value: 10,
      label: 'Outubro'
    },
    {
      value: 11,
      label: 'Novembro'
    },
    {
      value: 12,
      label: 'Dezembro'
    }
  ]

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  filter = {
    Search: new UntypedFormControl(),
    isActive: new UntypedFormControl(false)
  }

  form = {
    dose: new UntypedFormControl(''),
    initialMonth: new UntypedFormControl(null),
    typeInit: new UntypedFormControl(null),
    valueInit: new UntypedFormControl(''),
    typeFinal: new UntypedFormControl(null),
    valueFinal: new UntypedFormControl(''),
    active: new UntypedFormControl(false)
  }

  resolveLaboratory: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Dose",
      method: '',
      retrive: "dose",
      after: '',
      before: '',
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }

  ]

  constructor(
    private _schemmaService: SchemmaService,
    private _dialog: MatDialog,
    private _dataPipe: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe
  ) { }


  ngOnInit(): void {
    this.get()
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let search: string = this.filter.Search.value ? this.filter.Search.value : null;

    let isActive = this.filter.isActive.value ? this.filter.isActive.value : null;

    await this._schemmaService.getPagination({ page: this.page, index: this.index, search, isActive }).then(
      (res: any) => {
        this.itens = [];
        this.total = res.sumRecords;
        if (res.data.length > 0) {
          this.resolve(res.data, this.resolveLaboratory);
        }
      }
    ).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.itens.push(obj);
    });
  }

  getActive(item: Schemma) {
    return this._activePipe.transform(item.isActive)
  }

  getDate(item: Schemma) {
    return this._dataPipe.transform(item.updatedAt);
  }

  post() {
    let obj = {
      dose: this.form.dose.value,
      monthInitial: this.form.initialMonth.value,
      ageInitial: this.form.valueInit.value,
      typeAgeInitial: this.form.typeInit.value,
      ageFinish: this.form.valueFinal.value,
      typeAgeFinish: this.form.typeFinal.value
    }

    this._schemmaService.post(obj).then((res: any) => {
      SwAlSetttings.Sucesso('Esquema Cadastrado!')
      this.get()
      this.dialog.closeDialog()
    }).catch(err => SwAlSetttings.printError(err))
  }

  put() {
    let obj = {
      dose: this.form.dose.value,
      monthInitial: this.form.initialMonth.value,
      ageInitial: this.form.valueInit.value,
      typeAgeInitial: this.form.typeInit.value,
      ageFinish: this.form.valueFinal.value,
      typeAgeFinish: this.form.typeFinal.value,
      isActive: this.form.active.value
    }

    this._schemmaService.put(obj, new Guid(this.editId)).then((res: any) => {
      SwAlSetttings.Sucesso('Esquema Editado!')
      this.get()
      this.dialog.closeDialog()
    }).catch(err => SwAlSetttings.printError(err))
  }

  delete(idToDelete: string) {
    this._schemmaService.delete(new Guid(idToDelete)).then((res: any) => {
      SwAlSetttings.Sucesso('Esquema Excluído!')
      this.get()
      this.dialog.closeDialog()
    }).catch(err => SwAlSetttings.printError(err))
  }

  setValueOnForms(id: string) {
    this._schemmaService.getById(new Guid(id)).then((res: any) => {
      let response = res.data[0]

      this.form.dose.setValue(response.dose),
      this.form.initialMonth.setValue(response.monthInitial),
      this.form.valueInit.setValue(response.ageInitial),
      this.form.typeInit.setValue(response.typeAgeInitial),
      this.form.valueFinal.setValue(response.ageFinish),
      this.form.typeFinal.setValue(response.typeAgeFinish),
      this.form.active.setValue(response.isActive)
    })
  }

  resetForms() {
    this.form.dose.setValue(null),
    this.form.initialMonth.setValue(null),
    this.form.valueInit.setValue(null),
    this.form.typeInit.setValue(null),
    this.form.valueFinal.setValue(null),
    this.form.typeFinal.setValue(null),
    this.form.active.setValue(false)
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.editId = emit.object.id ?? ''
        this.dialog.openDialogModal(true, emit);
        this.setValueOnForms(emit.object.id)
        break;
      case 'Excluir':
        this.delete(emit.object.id)
        break;
      case 'PutActive':
          this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  putActive(id: string, value: string) {
    this._schemmaService.patchActive(id, value).then((x:any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.get();
    }).catch(err => SwAlSetttings.printError(err))
  }

  resetFilter(){
    this.filter.Search.reset();
    this.filter.isActive.reset();
  }

  afterClose() {
    this.resetForms()
    this.editId = ''
  }
}
