<div class="contentPage">
  <div class="flex flex-between w-100 align-center headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
      <h1>Mensagens</h1>
      <app-button (click)="openDialogModalMessage()" [placeholder]="'Novo'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus [form]="filterMessage" [placeholder]="'Pesquisar'"></input-prop>
        <checkbox-prop [gap]="1" [form]="active" class="flex flex-center" label="Somente Ativos"></checkbox-prop>
      </div>
      <app-button class="queryBtn" (click)="get()" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Dropdown]="true" [Actions]="['Responder' , 'Editar']" [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [indexNumber]="index" [pageNumber]="page" (changePage)="get($event)" [Itens]="registerChat" (parentFun)="actionsMessage($event)">
      <ng-template let-rowDetail #header>
        <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [indexNumber]="index" [pageNumber]="page" [noShadow]="true" [Actions]="['Visualizado', 'Editar']" [ClickInItem]="ActionsExtraMessages" *ngIf="resolveResponseTable[rowDetail.id]" [Itens]="resolveResponseTable[rowDetail.id]" (parentFun)="actionsResponse($event, rowDetail.id)"></app-table>
      </ng-template>
    </app-table>
  </div>
</div>

<ng-template #dialogModalMessage let-Message>
  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1>{{messageId != '' ? 'Editar' : 'Nova'}} Mensagem</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div *ngIf="messageId == ''" class="mt-32">
      <input-search [placeholder]="'Selecione um convenio'" [options]="formOptions" [form]="messageSend" ></input-search>
    </div>
    <div class="mt-32">
      <textarea-b-prop [placeholder]="messageId != '' ? 'Editar Mensagem' : 'Nova Mensagem'" [form]="formSend"></textarea-b-prop>
    </div>
    <div class="mt-32 flex flex-center">
      <app-button (click)="messageId != '' ? putMessage(Message) : postMessage(messageSend)" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalResponse let-response>
  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1>{{responseId != '' ? 'Editar' : 'Responder'}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="mt-32">
      <textarea-b-prop [placeholder]="messageId != '' ? 'Editar Resposta' : 'Responder' " [form]="formSend"></textarea-b-prop>
    </div>
    <div class="mt-32 flex flex-center">
      <app-button (click)="responseId != '' ? putMessage(response) : postMessage(response)" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
  </ng-container>
</ng-template>