import { createReducer, on } from '@ngrx/store';
import { addNotification, clearNotification, removeNotification, addAllNotification} from "./notification.actions"
import { Notifications } from './notification.model';

export const notifications = new Notifications()

export const notificationReducer = createReducer(
    notifications,
    on(clearNotification, _ => new Notifications()),
    on(addNotification, (state, payload) => {
        const stateClone = cloneObject(state)
        stateClone.amount = state.amount + 1
        stateClone.notificationsAvailable.push(payload)
        return stateClone
    }),
    on(removeNotification, (state, { id }) => {
        const stateClone = cloneObject(state)
        stateClone.amount = state.amount - 1
        stateClone.notificationsAvailable = state.notificationsAvailable.filter(item => item.id !== id)
        return stateClone
    }),
    on(addAllNotification, (state, payload) => {
        state = payload
        return state
    })
)

const cloneObject = (obj: Object) => {
    return JSON.parse(JSON.stringify(obj))
}