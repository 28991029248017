import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpService } from '../http.service';
import { HttpResponsePageable } from '../services.types';
import { GetAllCollectsPlace } from './collection-place.service.model';
import { QueryParams } from '../interfaces/services.types';

@Injectable({
  providedIn: 'root'
})

export class CollectionPlaceService extends BaseService {
  constructor(HttpService: HttpService) {
    super(HttpService, 'v1/lab/parameters/collectionplace');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllCollectsPlace>>
  }

  override getPagination<CollectionPlaceService>(queryObject?: QueryParams | undefined){
    return super.getPagination(queryObject) as Promise<HttpResponsePageable<CollectionPlaceService>> 
  }

  override getResume(active?: boolean){
    return super.getResume(active) as Promise<HttpResponsePageable<GetAllCollectsPlace>>
  }
}
