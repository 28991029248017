<div class="contentPage">
  <div class="flex align-center flex-between flex-direction-column-mobile">
    <div class="titulo">
      <div class="flex align-center gp-12">
        <button [routerLink]="['/Usuarios']" routerLinkActive="router-link-active" class="btn btn-reverse">Voltar</button>
        <h1>{{ !editUserId ? 'Novo usuário' : 'Editar usuário "' + editUserName + '"' }}</h1>
      </div>
    </div>
  </div>
  <div class="contextPage">
    <mat-tab-group dynamicHeight (selectedTabChange)="myTabSelectedTabChange($event)">
      <mat-tab label="Usuário">
        <div class="user flex flex-direction-column-mobile gap-form">
          <div class="col border">
            <h2>Dados de acesso</h2>
            <div class="flex align-center mt-32 flex-between flex-direction-column-mobile">
              <label style="text-align: left !important">Tipo de acesso</label>
              <div class="buttons cod">
                <div class="flex">
                  <button [ngStyle]="{'background-color': codThis === 1 ? '#FE6344' : '#FFFFFF', 'color': codThis === 1 ? '#FFFFFF' : '#FE6344'}" (click)="ToggleCod(0)">
                    E-mail
                  </button>
                  <button [ngStyle]="{'background-color': codThis === 1 ? '#FFFFFF' : '#FE6344', 'color': codThis === 1 ? '#FE6344' : '#FFFFFF'}" (click)="ToggleCod(1)">
                    Cód. Acesso
                  </button>
                </div>
              </div>
            </div>
            <div class="form mt-32">
              <input-prop [onFocus]="true" placeholder="Nome completo" [form]="form.personName"></input-prop>
            </div>

            <div class="form mt-32">
              <input-prop
                [form]="form.email"
                [placeholder]="codThis == 0 ? 'E-mail' : 'Código de acesso'"
                [type]="codThis == 0 ? 'email' : 'text'"
                (change)="CheckIsEmail()"
              ></input-prop>
            </div>

            <div class="form mt-32">
              <input-prop placeholder="CPF" [form]="form.cpf" mask="000.000.000-00"></input-prop>
            </div>

            <div class="form mt-32">
              <input-prop placeholder="Telefone Celular" [form]="form.cellPhone" type="phone" mask="(00) 00000-0000"></input-prop>
            </div>

            <div class="form mt-32">
              <div class="form">
                <input-multiple placeholder="Departamento" [options]="options" [form]="form.department"></input-multiple>
              </div>
            </div>

            <div class="form mt-32" *ngIf="!editUserId">
              <input-prop placeholder="Senha" [form]="form.password" type="password"></input-prop>
            </div>

            <div class="form mt-32" *ngIf="!editUserId">
              <input-prop placeholder="Repita a senha" [form]="form.confirmPassword" type="password"></input-prop>
            </div>

            <div class="flex align-center gp-12 form mt-32">
              <checkbox-prop [form]="form.active"></checkbox-prop>
              <span>Usuário ativo</span>
            </div>
            <div class="form mt-32" *ngIf="!this.userAccessActive">
              <span class="pill-warning">Necessário ativação do primeiro acesso</span>
            </div>
          </div>
          <div class="col border maxWidthUnset">
            <h2>Dados de cadastro</h2>
            <div class="input-row flex mt-32 flex-direction-column-mobile">
              <div class="input-col">
                <div class="form">
                  <input-prop placeholder="Data de admissão" [form]="form.admissionData" type="date"></input-prop>
                </div>
              </div>
              <div class="input-col">
                <div class="form">
                  <input-prop placeholder="Data de demissão" [form]="form.resignationData" type="date"></input-prop>
                </div>
              </div>
            </div>
            <div class="input-row mt-32 flex flex-wrap-mobile">
              <div class="input-col">
                <div class="form">
                  <input-prop placeholder="E-mail pessoal" [form]="form.alternativeEmail" type="email"></input-prop>
                </div>
              </div>
            </div>

            <div class="input-row mt-32 flex flex-direction-column-mobile mtmobile">
              <div class="input-col">
                <div class="form">
                  <input-prop placeholder="CEP" (change)="Cep()" [form]="form.CEP" mask="00000-000" type="text"></input-prop>
                </div>
              </div>
              <div class="input-col mtmobile">
                <div class="form">
                  <input-prop placeholder="Endereço" [form]="form.address" type="text"></input-prop>
                </div>
              </div>
            </div>

            <div class="input-row mt-32 flex flex-direction-column-mobile">
              <div class="input-col">
                <div class="form">
                  <div class="flex flex-direction-column-mobile input-row">
                    <div class="input-col">
                      <input-prop placeholder="Número" [form]="form.number" type="text"></input-prop>
                    </div>
                    <div class="input-col mtmobile">
                      <input-prop placeholder="Complemento" [form]="form.complement" type="text"></input-prop>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-col mtmobile">
                <div class="form">
                  <input-prop placeholder="Bairro" [form]="form.neighborhood" type="text"></input-prop>
                </div>
              </div>
            </div>

            <div class="mt-32 flex gap-form">
              <input-prop class="w-100 gap-form" placeholder="Cidade" [form]="form.city" type="text"></input-prop>
              <select-prop class="w-100 gap-form" placeholder="Estado" [states]="true" [form]="form.state"></select-prop>
            </div>

            <div class="mt-32 flex gap-form">
              <input-prop class="w-100 gap-form" placeholder="Telefone Fixo" [form]="form.phone" mask="(00) 0000-0000" type="text"></input-prop>
              <input-prop class="w-100 gap-form" placeholder="Percentual desconto máximo" [form]="form.discountPercentage" type="text"></input-prop>
            </div>

            <div class="mt-32 flex gap-form">
              <div class="w-100">
                  <input-search placeholder="Empresa Padrão" (changeForm)="getUnityByCompany(); form.defaultUnity.setValue(null)" [form]="form.defaultCompany" [options]="tenantsOfUser"></input-search>
              </div>
              <div class="w-100">
                  <input-search placeholder="Unidade padrão" [form]="form.defaultUnity" [options]="form.defaultCompany.value? unityOptions : []"></input-search>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-center pd-10 mt-32">
          <button [ngClass]="disable ? 'btn disable' : 'btn'" (click)="!editUserId ? postUser() : compare()">
            {{ !editUserId ? 'Salvar' : 'Salvar' }}
          </button>
        </div>
      </mat-tab>
      <mat-tab *ngIf="editUserId" label="Permissões">
        <div class="contentPagePermission">
          <div class="flex align-center flex-between">
            <div class="titulo">
              <div class="flex align-center">
                <h2>Configurar permissões de “{{ editUserName }}”</h2>
              </div>
            </div>
          </div>
          <div class="flex flex-center">
            <div class="w-100">
              <div class="flex gap-form mt-32 align-center flex-direction-column-mobile">
                <select-prop
                  class="w-100"
                  placeholder="Empresa"
                  (change)="getUnities()"
                  [options]="companies"
                  [form]="formCompany.company"
                ></select-prop>
                <select-prop class="w-100" placeholder="Filial" [options]="unities" (change)="getPermissionsUser()" [form]="formCompany.unity"></select-prop>
              </div>
              <div class="flex align-center mt-32">
                <h2>Adicionar papeis aos módulos</h2>
              </div>
              <div class="flex w-100 mt-32 gap-form align-center flex-direction-column-mobile">
                  <div class="w-100">
                    <select-prop
                      placeholder="Módulos"
                      [options]="optionsModule"
                      [form]="moduleForm"
                      (change)="getRoleByModule()"
                    ></select-prop>
                  </div>
                  <div class="w-100" [class]="!moduleForm.value ? 'select disabled' : 'select'">
                    <input-multiple [options]="optionsPapeis" placeholder="Papéis" [form]="roleForm"></input-multiple>
                  </div>
              </div>
              <div class="flex flex-center mt-32">
                <button class="btn-reverse btn" (click)="addRoletoUser()" [disabled]="!moduleForm.value || !roleForm.value">
                  {{ roleForm.value.length > 1 ? 'Adicionar papeis' : 'Adicionar papel' }}
                </button>
              </div>
              <div class="modulos mt-32">
                <h2>Módulos</h2>
                <div class="flex itens flex-column gap-4">
                  <ng-template ngFor let-item [ngForOf]="modulesByToken">
                    <div class="col border" *ngIf="CheckIsItemModule(item.name)">
                      <h4 style="text-transform: capitalize">{{ 'modules.' + item.name | translate }}</h4>
                      <div class="infos mt-32" *ngFor="let policy of rolePolicies">
                        <ng-template [ngIf]="CheckInSd(item.name, policy.resources)">
                          <div class="flex flex-between">
                            <span style="text-transform: capitalize">{{ policy.name }}</span>
                            <a
                              href="#"
                              onclick="return false"
                              class="deletar"
                              [matTooltip]="'Deletar papel ' + policy.name"
                              (click)="deleteRole(policy.id, policy.name)"
                              [matTooltipPosition]="position.value"
                              aria-label="Button that displays a tooltip in various positions"
                            >
                              <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M1.51111 18.5333C1.51111 19.3556 2.17778 20 3 20H13.0222C13.8444 20 14.5111 19.3556 14.5111 18.5333L15.0889 6.44444H0.955555L1.51111 18.5333ZM10.6667 8.86667C10.6667 8.44444 10.9111 8.11111 11.3333 8.11111C11.7556 8.11111 12 8.44444 12 8.86667V17.2222C12 17.6444 11.7556 17.9778 11.3333 17.9778C10.9111 17.9778 10.6667 17.6444 10.6667 17.2222V8.86667ZM7.33333 8.86667C7.33333 8.44444 7.57778 8.11111 8 8.11111C8.42222 8.11111 8.66667 8.44444 8.66667 8.86667V17.2222C8.66667 17.6444 8.42222 17.9778 8 17.9778C7.57778 17.9778 7.33333 17.6444 7.33333 17.2222V8.86667ZM4 8.86667C4 8.44444 4.24444 8.11111 4.66667 8.11111C5.08889 8.11111 5.33333 8.44444 5.33333 8.86667V17.2222C5.33333 17.6444 5.08889 17.9778 4.66667 17.9778C4.24444 17.9778 4 17.6444 4 17.2222V8.86667Z"
                                  fill="white"
                                />
                                <path
                                  d="M15.3556 2.66667H11.3333V0.688889C11.3333 0.288889 11.1333 0 10.7111 0H5.33333C4.91111 0 4.66667 0.288889 4.66667 0.688889V2.66667H0.688889C0.266667 2.66667 0 2.97778 0 3.4V4.24444C0 4.66667 0.266667 5.11111 0.688889 5.11111H15.3778C15.8 5.11111 16.0222 4.64444 16.0222 4.24444V3.4C16 2.97778 15.7778 2.66667 15.3556 2.66667ZM10 2.66667H6V1.33333H10V2.66667Z"
                                  fill="white"
                                />
                              </svg>
                            </a>
                          </div>
                          <div class="optionscase flex-wrap">
                            <ng-template ngFor let-resource [ngForOf]="policy.resources">
                              <a
                                href="#"
                                matTooltip="Info about the action"
                                [matTooltipPosition]="position.value"
                                aria-label="Button that displays a tooltip in various positions"
                                (click)="
                                  CheckIsUserRole(resource.name)
                                    ? NegatePolicy(resource.name, resource.id)
                                    : GarantedPolicy(resource.name, resource.id)
                                "
                                [ngClass]="CheckIsUserRole(resource.name) ? 'act' : ''"
                                onclick="return false"
                                *ngIf="resource.moduleName === item.name"
                              >
                                <span>
                                  {{ getRole(resource.name, item.name) | translate }}
                                </span>

                                <svg
                                  *ngIf="CheckIsUserRole(resource.name)"
                                  width="21"
                                  height="21"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M18.6818 10.6478C18.6818 6.12913 15.0187 2.46601 10.5 2.46601C5.98131 2.46601 2.31818 6.12913 2.31818 10.6478C2.31818 15.1665 5.98131 18.8296 10.5 18.8296C15.0187 18.8296 18.6818 15.1665 18.6818 10.6478ZM10.5 0.647827C16.0228 0.647827 20.5 5.12498 20.5 10.6478C20.5 16.1707 16.0228 20.6478 10.5 20.6478C4.97715 20.6478 0.5 16.1707 0.5 10.6478C0.5 5.12498 4.97715 0.647827 10.5 0.647827Z"
                                    fill="#EB5757"
                                  />
                                  <path
                                    d="M15.0455 10.6477C15.0455 11.1498 14.6384 11.5568 14.1364 11.5568L6.86364 11.5568C6.36156 11.5568 5.95454 11.1498 5.95455 10.6477C5.95455 10.1456 6.36156 9.73862 6.86364 9.73862H14.1364C14.6384 9.73862 15.0455 10.1456 15.0455 10.6477Z"
                                    fill="#EB5757"
                                  />
                                </svg>
                                <svg
                                  *ngIf="!CheckIsUserRole(resource.name)"
                                  width="21"
                                  height="21"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M18.6818 10.6478C18.6818 6.12913 15.0187 2.46601 10.5 2.46601C5.98131 2.46601 2.31818 6.12913 2.31818 10.6478C2.31818 15.1665 5.98131 18.8296 10.5 18.8296C15.0187 18.8296 18.6818 15.1665 18.6818 10.6478ZM10.5 0.647827C16.0228 0.647827 20.5 5.12498 20.5 10.6478C20.5 16.1707 16.0228 20.6478 10.5 20.6478C4.97715 20.6478 0.5 16.1707 0.5 10.6478C0.5 5.12498 4.97715 0.647827 10.5 0.647827ZM15.0455 10.6478C15.0455 11.1499 14.6384 11.5569 14.1364 11.5569L11.4091 11.5569V14.2842C11.4091 14.7863 11.0021 15.1933 10.5 15.1933C9.99792 15.1933 9.59091 14.7863 9.59091 14.2842L9.59091 11.5569H6.86364C6.36156 11.5569 5.95455 11.1499 5.95455 10.6478C5.95455 10.1458 6.36156 9.73874 6.86364 9.73874H9.59091V7.01146C9.59091 6.50939 9.99792 6.10237 10.5 6.10237C11.0021 6.10237 11.4091 6.50939 11.4091 7.01146V9.73874H14.1364C14.6384 9.73874 15.0455 10.1458 15.0455 10.6478Z"
                                    fill="#07BF56"
                                  />
                                </svg>
                              </a>
                            </ng-template>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <ng-template #dialogModal let-mydata>
      <ng-container>
        <div class="flex" style="justify-content: space-between; align-items: center">
          <h2 mat-dialog-title>Confirme Sua Senha</h2>
          <span class="close" mat-dialog-close (click)="dialog.closeAll()">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.384626 3.0527L18.0769 20.745L20.3846 18.4373L2.69232 0.745004L0.384626 3.0527Z"
                fill="#BFBFBF"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.6923 0.744995L0 18.4373L2.30769 20.745L20 3.05269L17.6923 0.744995Z"
                fill="#BFBFBF"
              />
            </svg>
          </span>
        </div>
        <div action="#">
          <div class="mt-42">
            <input-prop
              placeholder="Confirmar senha"
              type="password"
              [form]="form.passwordConfirm"
              requiredInput="required"
            ></input-prop>
          </div>
          <div class="flex mt-32 flex-center buttons">
            <button class="btn" (click)="EditUser()">Salvar</button>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
