<div class="contentPage">
  <div class="flex align-center flex-between w-100 headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
      <h1>Títulos</h1>
      <app-button (click)="redirect()" [placeholder]="'Novo'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="get()" [cleanButton]="true" (clearFilters)="clearFilters()"> 
      <div class="query">
        <input-prop #autoFocus type="text" placeholder="Número de Documento" [form]="filter.document"></input-prop>
        <input-prop type="text" placeholder="Emitente" [form]="filter.issuer"></input-prop>
        <input-search [placeholder]="'Situação'" [form]="filter.situation" [options]="titleSituationOptions"></input-search>
        <div class="flex align-center gap-2">
          <input-prop type="date" placeholder="Período de" [form]="filter.dateStart"></input-prop>
          <input-prop type="date" placeholder="até" [form]="filter.dateFinish"></input-prop>
        </div>
        <checkbox-prop class="flex flex-center" [gap]="1" label="Somente Ativos" [form]="filter.actives"></checkbox-prop>
      </div>
      <app-button (click)="get()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <mat-tab-group [(selectedIndex)]="tabIndex" (selectedIndexChange)="tabChange()">
      <mat-tab *ngFor="let title of titles; let i = index">
        <ng-template mat-tab-label>
          <div class="flex align-center gap-1">
            <h2>{{moduleTabs[i]}}</h2>
          </div>
        </ng-template>  
        <app-table [Itens]="title" [Dropdown]="true" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [ClickInItem]="ActionsExtra" [Actions]="['Editar']" (parentFun)="actions($event)">
          <ng-template let-rowDetail #header>
            <div class="contentPage border">
              <div class="flex align-center w-100">
                <h1>Parcelas</h1>
              </div>
              <app-table *ngIf="portion[rowDetail.id]" [noShadow]="true" [Itens]="portion[rowDetail.id]"></app-table>
            </div>
          </ng-template>
        </app-table>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
