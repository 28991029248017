import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { DownloadCollectComponent } from "./components/download-collect/download-collect.component";
import { ScreeningComponent } from "./components/screening/screening.component";
import { ProcessesComponent } from "./components/processes/processes.component";
import { TypingComponent } from "./components/typing/typing.component";
import { ConferenceComponent } from "./components/conference/conference.component";
import { LiberationComponent } from "./components/liberation/liberation.component";
import { PrintingComponent } from "./components/printing/printing.component";
import { DeliveryComponent } from "./components/delivery/delivery.component";
import { SaveBudgetComponent } from "@app/modules/registers/components/budget/save-budget/save-budget.component";
import { BudgetsComponent } from "@app/modules/registers/components/budget/budgets/budgets.component";
import { RequisitionsComponent } from "@app/modules/registers/components/requisition/requisitions/requisitions.component";
import { SaveRequisitionComponent } from "@app/modules/registers/components/requisition/save-requisition/save-requisition.component";
import { AdvancedMaintenceComponent } from "@app/modules/registers/components/requisition/advanced-maintence/advanced-maintence.component";

const routes: Routes = [
   {
      path: "MovimentacaoOld",
      children: [
         { path: "", redirectTo: "Movimentacao", pathMatch: "full" },
         { path: "Coletar/Baixa", component: DownloadCollectComponent },
         { path: "Recepcao/Requisicoes", component: RequisitionsComponent },
         {
            path: "Recepcao/Requisicoes/:id",
            component: SaveRequisitionComponent
         },
         {
            path: "Recepcao/Requisicoes/ManutencaoAvancada/:id",
            component: AdvancedMaintenceComponent
         },
         { path: "Recepcao/Orcamentos", component: BudgetsComponent },
         { path: "Recepcao/Orcamentos/:id", component: SaveBudgetComponent },
         { path: "Recepcao/Orcamentos/Novo", component: SaveBudgetComponent },
         { path: "Setor-Tecnico/Triagem", component: ScreeningComponent },
         { path: "Processos", component: ProcessesComponent },
         { path: "Digitacao", component: TypingComponent },
         { path: "Conferencia", component: ConferenceComponent },
         { path: "Liberacao", component: LiberationComponent },
         { path: "Impressao", component: PrintingComponent },
         { path: "Entrega", component: DeliveryComponent }
      ]
   }
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule]
})
export class MovimentationRoutingModule {}
