<div class="contentPage">
  <div class="flex flex-between align-center headerResponsive">
    <div class="flex flex-between align-center gap-2 titleResponsive">
      <h1>{{'agreements.title' | translate}}</h1>
      <app-button (click)="navigate()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="getAgreements()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus [placeholder]="'agreements.filter.code'" [form]="searchForm.externalCode"></input-prop>
        <input-prop [placeholder]="'agreements.filter.description'" [form]="searchForm.description"></input-prop>
        <select-prop [placeholder]="'agreements.filter.typeInvoice'" [form]="searchForm.typeInvoice" [options]="typeInvoiceOptions"></select-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" [label]="'agreements.filter.controlPlan'" [form]="searchForm.controlPlan"></checkbox-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" [label]="'agreements.filter.isActive'" [form]="searchForm.isActive"></checkbox-prop>
      </div>
      <app-button class="queryBtn" [placeholder]="'agreements.filter.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'" (click)="getAgreements()"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [ClickInItem]="ActionsExtra" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="registerAgreement" [Actions]="['Editar', 'Excluir']" (parentFun)="actions($event)" (changePage)="getAgreements($event)"></app-table>
  </div>
</div>
