import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { LoadingNewService } from "@app/loadingNew.service";
import { ApiAuthService } from "../../../../data/services/api-auth.service";
import { RequestOptions } from "../../../../data/services/types/http.types";
import { BaseNewService } from "@app/services/baseNew.service";

@Injectable({
   providedIn: "root"
})
export class AdminAccesscontrolUserAuthNew extends BaseNewService {
   public usersBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
      []
   );

   constructor(HttpAuth: ApiAuthService) {
      super(HttpAuth, "v1/accesscontrol/user");
      LoadingNewService.SilenceLoading.push(this.path);
   }

   loadUsers(): void {
      if (this.usersBehaviorSubject.value.length === 0) {
         const obj: RequestOptions = {
            query: {},
            path: "",
            headers: { TenantId: localStorage.getItem("TenantId") || "" }
         };
         this.get(obj).subscribe((res: any) => {
            this.usersBehaviorSubject.next(res.data);
         });
      }
   }

   getUserByPersonId(personId: string): any {
      return this.usersBehaviorSubject.value.find(
         (user: any) => user.personId === personId
      );
   }

   getImageByPersonId(personId: string): string | undefined {
      const user = this.getUserByPersonId(personId);
      let base64 = "";

      if (user && user.imageBase64) {
         base64 = `data:image/png;base64,${user.imageBase64[0]}`;
      }

      return base64 !== "data:image/png;base64," ? base64 : undefined;
   }

   getPaginationUsersWithTenant(
      page: number,
      numberRegistry: number,
      tenantId: string,
      search?: string,
      type?: number
   ): any {
      const obj: RequestOptions = {
         query: {
            page: page,
            numberRegistry: numberRegistry,
            search: search || null,
            type: type ?? null
         },
         path: "",
         headers: { TenantId: tenantId }
      };
      this.get(obj).subscribe((res: any) => {
         return res;
      });
   }
}
