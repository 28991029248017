import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageControlService } from '@app/services/message-control/message-control.service';
import { GetAllControlMessages } from '@app/services/message-control/message-control.types';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';

@Component({
  selector: 'app-sms-email',
  templateUrl: './sms-email.component.html',
  styleUrls: ['./sms-email.component.scss']
})

export class EnvioSmsEmailComponent implements OnInit {
  constructor(
    private _router: Router,
    private _controlMessages: MessageControlService
  ) { }

  //#region Global Variables / Variáveis Globais
  total: number = 0;
  page: number = 1;
  index: number = 50;
  filterOptions = [
    {
      label: 'Filtrar por Tipo',
      value: 'Filtrar por Tipo'
    },
    {
      label: 'Filtrar por Destino',
      value: 'Filtrar por Destino'
    },
    {
      label: 'Filtrar por Título',
      value: 'Filtrar por Título'
    },
    {
      label: 'Filtrar por Situação',
      value: 'Filtrar por Situação'
    }
  ];
  //#endregion Variáveis globais

  //#region Register and Resolve
  registerControlMessages: Array<Object> = [];
  resolveControlMessages: Array<Object> = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Título',
      retrive: 'title',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_message',
      retrive: 'message',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_title',
      retrive: 'title',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_observation',
      retrive: 'observation',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Data de Envio',
      retrive: '',
      method: 'getCreateAtDate',
      after: '',
      before: ''
    },
    {
      label: 'Tipo de Envio',
      retrive: '',
      method: 'convertType',
      after: '',
      before: ''
    },
    {
      label: 'Destino',
      retrive: 'destination',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Situação',
      retrive: '',
      method: 'getSituation',
      after: '',
      before: ''
    }
  ];
  //#endregion

  //#region Forms / Formulários
  form = {
    filter: new UntypedFormControl(null, [Validators.required]),
    search: new UntypedFormControl(null, [Validators.required]),
  };
  //#endregion

  //#region Message Variables / Variáveis referentes à Mensagem
  type = {
    0: 'Nenhum',
    1: 'Ziad - SMS',
    2: 'E-mail',
    3: 'Zoe - WhatsApp'
  };
  //#endregion

  async ngOnInit() {
    await this.getControlMessages();
  }

  async getControlMessages(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let getParameters: string;
    let param: string = this.form.search.value;

    if (this.form.filter.value == "Filtrar por Tipo") {
      getParameters = (`?Type=${param}&page=${this.page}&index=${this.index}`);
    } else if (this.form.filter.value == "Filtrar por Destino") {
      getParameters = (`?Destination=${param}&page=${this.page}&index=${this.index}`);
    } else if (this.form.filter.value == "Filtrar por Título") {
      getParameters = (`?Title=${param}&page=${this.page}&index=${this.index}`);
    } else if (this.form.filter.value == "Filtrar por Situação") {
      getParameters = (`?IsProcessed=${param}&page=${this.page}&index=${this.index}`);
    } else {
      getParameters = (`?page=${this.page}&index=${this.index}`);
    }

    await this._controlMessages.getByPath('', getParameters).then(
      (res) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolve(res.data, this.resolveControlMessages);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.registerControlMessages = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerControlMessages.push(obj);
    });
  }

  convertType(item: any) {
    //@ts-ignore
    return item.type ? this.type[item.type] : this.type[0];
  }

  getSituation(item: GetAllControlMessages) {
    let stylizedSpanForActiveSituation: string = "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Enviado</span>";
    let stylizedSpanForInactiveSituation: string = "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Não Enviado</span>";
    return item.processed ? stylizedSpanForActiveSituation : stylizedSpanForInactiveSituation;
  }

  getCreateAtDate(item: any) {
    var data = new Date(item.sent);
    const MINIMUM_YEAR = 1970;

    if (data.getFullYear() < MINIMUM_YEAR) return "";

    var formattedDay = data.getDate() < 10 ? "0" + data.getDate() : data.getDate().toString();
    var formattedMonth = data.getMonth() < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1).toString();
    var formattedHour = data.getHours() < 10 ? "0" + data.getHours() : data.getHours().toString();
    var formattedMinute = data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes().toString();
    return formattedDay + "/" + formattedMonth + "/" + data.getFullYear() + " às " + formattedHour + ":" + formattedMinute + " h";
  }

  /*
  getCellphone(item: any) {
    var cellphone = item.cellPhone;
    var countryCode = item.countryCode;
    var maskToCellphone : string = "^\([1-9]{2}\) 9 [1-9][0-9]{3} [0-9]{4}$"; // (XX) X XXXX XXXX
    var regexCellphone : RegExp = new RegExp(maskToCellphone);
    var isValid : boolean = regexCellphone.test(cellphone);

    if (!isValid) {
      var receivedCellphone : string = cellphone;
      var formattedCellphone = receivedCellphone.replace('-', '').replace(' ', '').replace(' ', '').trim();
      return formattedCellphone.length == 12 ? formattedCellphone.substring(0,4) + ' ' + formattedCellphone.substring(4,8) + ' ' + formattedCellphone.substring(8) : formattedCellphone = formattedCellphone.substring(0,2) + ' ' + formattedCellphone.substring(3,2) + ' ' + formattedCellphone.substring(2,6) + ' ' + formattedCellphone.substring(6,10);
    } else {
      return cellphone;
    }
  }
  */

  actions(emit: Object) {
    //@ts-ignore
    switch (emit.action) {
      case "View":
      //@ts-ignore
      //this.router.navigateByUrl('Cadastros/Clientes/' + emit.object.id + '$View');
      //break;
      case 'Editar':
      //@ts-ignore
      //this.router.navigateByUrl('Cadastros/Clientes/' + emit.object.id + '$Edit');
      //break;
      case 'Excluir':
      //@ts-ignore
      //this.customerId = emit.object.id;
      //this.excluir();
      //break;
    };
  }

  /*
  excluir() {
    SwAlSetttings.ALERT_CONFIRM.fire().then(
      async (r) => {
        if(r.isConfirmed) {
          SwAlSetttings.loader();
          let guidCustomerId : Guid = new Guid(this.customerId);
          await this.customerService.delete(guidCustomerId);
          this.ngOnInit();
        }
        SwAlSetttings.Sucesso('Cliente excluído com sucesso!');
        SwAlSetttings.closeLoader();
      }
    ).catch((error) => {
        SwAlSetttings.printError(error);
        SwAlSetttings.closeLoader();
      }
    );
  }
  */
}
