export const resolveMaterials: Array<Object> = [
  {
    label: 'Selecionar',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'false',
    type: 'checkbox',
  },
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Requisição',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'requisitionNumber',
    type: 'description',
  },
  {
    label: 'Nome',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'customerName',
    type: 'description',
  },
  {
    label: 'Código Recipiente',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'recipientExternalId',
    type: 'description',
  },
  {
    label: 'Recipiente',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'recipientDescription',
    type: 'description',
  },
  {
    label: 'Código de barras recipiente',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'codeBar',
    type: 'description',
  },
  {
    label: 'Caráter Atendimento',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'routine',
    type: 'routine',
  },
  {
    label: 'Conservação',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'conservation',
    type: 'description',
  },
]

export const resolveRecipients: Array<Object> = [
  {
    label: 'Selecionar',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'false',
    type: 'checkbox',
  },
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Requisição',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'number',
    type: 'description',
  },
  {
    label: 'Nome',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'name',
    type: 'description',
  },
  {
    label: 'Código Recipiente',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'externalId',
    type: 'description',
  },
  {
    label: 'Recipiente',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'description',
    type: 'description',
  },
  {
    label: 'Código de barras recipiente',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'codeBar',
    type: 'description',
  },
  {
    label: 'Caráter Atendimento',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'routine',
    type: 'routine',
  },
  {
    label: 'Conservação',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'typeConservation',
    type: 'TypeConservation',
  },
]