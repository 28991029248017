<div [ngClass]="timeout ? 'disabled' : ''" class="flex flex-between align-center {{gap ? 'gap-' + gap : 'padding'}} {{disabledSd ? 'disabled' : ''}} {{radio ? 'radio' : ''}}">
    <label>
        {{label | translate}}
    </label>
    
    <label class="switch" *ngIf="!radio" [ngClass]="dark ? 'dark' : ''">
        <input [tabindex]="tabindexcomponent" *ngIf="form; else withoutForm" type="checkbox" [formControl]="form" (change)="onChange($event)" [id]="name">
        <ng-template #withoutForm>
            <input type="checkbox" [id]="name">
        </ng-template>
        <span class="slider round"></span>
    </label>
    <label class="original" *ngIf="radio">
        <div class="input-abs">
            <input [tabindex]="tabindexcomponent" *ngIf="form; else withoutForm" type="checkbox" [formControl]="form" (change)="onChange(form.value)" [id]="name">
            <span class="checked"></span>
        </div>
        <ng-template #withoutForm>
            <input type="checkbox" [id]="name">
        </ng-template>
    </label>
</div>
