import { Location } from '@angular/common';
import { formatCurrency } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild, LOCALE_ID, Inject  } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TitlesService } from '@app/services/titles/titles.service';
import { Guid } from '@app/util/guid';
import { DateTime } from 'luxon';
import { SwAlSetttings } from '@app/util/swal.settings';

import { registerLocaleData } from '@angular/common';
import localePtBr from '@angular/common/locales/pt';

registerLocaleData(localePtBr);

import decodedToken from '@app/util/Token';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CustomersService } from '@app/services/customers/customers.service';
import { TitlePortionService } from '@app/services/titles/title-portion.service';
import { InputSearchComponent } from '@app/components/form/input-search/input-search.component';
import { PersonService } from '@app/services/person/person.service';
import { Actions, ResolvePartion } from './save-title.model'

const helper = new JwtHelperService();

@Component({
  selector: 'app-save-title',
  templateUrl: './save-title.component.html',
  styleUrls: ['./save-title.component.scss']
})
export class SaveTitleComponent implements OnInit {

  @ViewChild("dialogModal") dialogModal!: TemplateRef<any>;
  @ViewChild("dialogModalIncludePartion") dialogModalInclude!: TemplateRef<any>;

  titleForm = {
    emitent: new UntypedFormControl(),
    docNumber: new UntypedFormControl(),
    situation: new UntypedFormControl(),
    titleValue: new UntypedFormControl(),
    typeModule: 0
  }

  checkBoxForm = {
    search: new UntypedFormControl(),
    customer: new UntypedFormControl(true),
    person: new UntypedFormControl(false)
  }

  formTrue = true

  modalForm = {
    interval: new UntypedFormControl(),
    firstExpiring: new UntypedFormControl(),
    partionsNumber: new UntypedFormControl(),
  }

  modalIncludeForm = {
    partionNumber: new UntypedFormControl(),
    firstExpiring: new UntypedFormControl(),
    finalValue: new UntypedFormControl(),
  }

  alterCustomer = true
  searched = false

  typeEmitent = 1

  pagination = 0

  autoGenerated = false
  editing = false
  titleId: string = '';
  customers: Array<Object> = []

  resolvePaymentTypes = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Parcela',
      retrive: 'number',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Vencimento',
      retrive: '',
      method: 'getDeadline',
      after: '',
      before: ''
    },
    {
      label: 'Valor Parcela',
      retrive: '',
      method: 'getPartionAmount',
      after: '',
      before: 'R$ '
    },
  ]

  titleSituation = [
    {
      label: 'Aberto',
      value: 0
    },
    {
      label: 'Vencido',
      value: 1
    },
    {
      label: 'Baixado Parcialmente',
      value: 2
    },
    {
      label: 'Baixado Integralmente',
      value: 3
    }
  ]

  typeModule = [
    {
      label: 'Contas a Pagar',
      value: 1
    },
    {
      label: 'Contas a Receber',
      value: 2
    },
    {
      label: 'Caixas e Bancos',
      value: 3
    }
  ]

  rawPortions: Array<any> = []

  resolvedModalPortions: Array<Object> =[]

  resolvedPortions: Array<Object> =[]

  constructor(
    private _location: Location,
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _titlesService: TitlesService,
    private _titlePortionService: TitlePortionService,
    private _customerService: CustomersService,
    private _personService: PersonService) {
  }

  async ngOnInit() {
    if (this._route.snapshot.params['id'] != "Novo") {
      this.titleId = this._route.snapshot.params['id'];
      this.editing = true
      this.getTitle();
    } else {
      this._route.queryParams.subscribe(params => { this.titleForm.typeModule = params['module'] });
    }
  }

  async getTitle() {

    this.alterCustomer = false

    await this._titlesService.getById(new Guid(this.titleId)).then((x:any) => {
      let response = x.data[0]

      this.titleForm.typeModule = response.typeModule
      this.titleForm.docNumber.setValue(response.documentNumber)
      this.titleForm.situation.setValue(response.titleSituation)
      this.titleForm.titleValue.setValue(formatCurrency(response.value, 'pt-BR', ''))
      this.rawPortions = response.titlePortions
      this.resolve(this.rawPortions, this.resolvePaymentTypes, this.resolvedPortions)

      if(response.customerId) {
        this.emitent = 1
        this._customerService.getById(response.customerId).then((res: any) => {
          const data = res.data[0]
          this.customers.push({label: data.name, value: data.id})
          this.titleForm.emitent.setValue(data.id)
        })
      } else {
        this.emitent = 2
        this._personService.getById(response.personId).then((res: any) => {
          const data = res.data[0]
          this.customers.push({label: data.name, value: data.id})
          this.titleForm.emitent.setValue(data.id)
        })
      }

    })
  }

  get module() {
    return this.typeModule.find((obj) => obj.value.toString() === this.titleForm.typeModule.toString())?.label
  }

  set emitent(type: 1 | 2){
    this.titleForm.emitent.setValue(null)
    this.customers = []
    if(type == 2) {
      this.checkBoxForm.customer.setValue(false)
      this.checkBoxForm.person.setValue(true)
    } else {
      this.checkBoxForm.customer.setValue(true)
      this.checkBoxForm.person.setValue(false)
    }
    this.typeEmitent = type
  }

  async getCustomers() {
    this.alterCustomer = false
    this.customers = []
    const name = this.checkBoxForm.search.value

    if(this.checkBoxForm.person.value == true) {
      await this._personService.getPagination({page: 1, index: 100, name: name ? name.toString() : null}).then((res: any) => {
        if(res.data)
        res.data.forEach((x: any) =>{
      this.customers.push({label: x.name, value: x.id })})
      })
    } else {
      await this._customerService.getPagination({page: 1, numberRegistry: 100, name: name ? name.toString() : null}).then((res: any) => {
        if(res.data)
        res.data.forEach((x: any) =>{
      this.customers.push({label: x.name, value: x.id })})
      })
    }
  }

  back() {
    this._location.back()
  }

  openDialogModal(){
    this._dialog.open(this.dialogModal, {
      width: "600px",
    })

    this._dialog.afterAllClosed.subscribe(result => {
      this.resolvedModalPortions = []
      Object.keys(this.modalForm).forEach(key => (this.modalForm as any)[key].setValue(''))
    });
  }

  openDialogModalInclude(){
    this._dialog.open(this.dialogModalInclude, {
      width: "600px",
    })

    this._dialog.afterAllClosed.subscribe(result => {
      this.resolvedModalPortions = []
      Object.keys(this.modalIncludeForm).forEach(key => (this.modalIncludeForm as any)[key].setValue(''))
    });
  }

  closeModal() {
    this._dialog.closeAll();
  }

  /**
  * Substitui os campos e valores dos objetos passados e adiciona os objetos 'resolvidos' na Array especificada
  * @param data Um Array contendo os objetos com os valores brutos
  * @param column Um Array especificando os valores a serem substituidos
  * @param list A Array para a qual serão adicionados os objetos depois de serem resolvidos
  */
  resolve(data: any, column: any, list: Array<Object>) {

    data.forEach((x: any) => {
      let obj: any = {};

      column.forEach((y: any) => {

        if(!y.retrive){
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      list.push(obj);
    })
  }

  getDeadline(data: ResolvePartion){
    const date = new Date(data.deadline)
    return (date.toLocaleDateString())
  }

  getPartionAmount(data: ResolvePartion){
    return formatCurrency(data.value, 'pt-BR', '')
  }

  /**
  * Gera as parcelas automaticamente.
  * - Caso a divisão não seja exata, a sobra será distribuída entre as últimas parcelas
  */
  generatePartions(){
    let partions = []
    this.resolvedModalPortions = []
    const firstExpiring = this.modalForm.firstExpiring.value._d

    let expiring: DateTime = DateTime.local(firstExpiring.getFullYear(), firstExpiring.getMonth() + 1, firstExpiring.getDate())

    for(let i = 1; i <= this.modalForm.partionsNumber.value; i++){
      partions.push({
        number: i,
        deadline: new Date(expiring.year, expiring.month - 1, expiring.day),
        value:
          Math.floor((this.titleForm.titleValue.value / this.modalForm.partionsNumber.value) * 100) / 100
      })

      expiring = expiring.plus({days: this.modalForm.interval.value})
    }

    let remaining =((this.titleForm.titleValue.value * 100 - (partions[0].value * this.modalForm.partionsNumber.value) * 100))

    for(let i = partions.length - 1; (i > 0 && remaining >= 1); i--) {
      partions[i].value += 0.01
      remaining --
    }

    this.rawPortions = partions
    this.resolve(partions, this.resolvePaymentTypes, this.resolvedModalPortions)
  }

  /**
  * Adiciona uma parcela por vez
  */
  includePartion() {

    if(this.autoGenerated) {
      this.resolvedPortions = []
      this.rawPortions = []
      this.autoGenerated = false
    }

    const firstExpiring = this.modalIncludeForm.firstExpiring.value._d

    let partion = {
      number: this.modalIncludeForm.partionNumber.value,
      deadline: firstExpiring,
      value: this.modalIncludeForm.finalValue.value,
    }
    this.rawPortions.push(partion)
    this.closeModal()
    this.resolve([partion], this.resolvePaymentTypes, this.resolvedPortions)
  }

  retriveKeys() {
    if(this.resolvedModalPortions.length > 0)
      return Object.keys(this.resolvedModalPortions[0]).filter(x => x != "id")
    return []
  } // Poderiam ser fixadas direto no html

  retriveValues(obj: Object) {
    if(obj)
      return Object.values(obj).filter(x => x != "undefined")
    return []
  }

  /**
  * Adiciona as parcelas geradas na Modal para a tabela principal e fecha a modal
  */
  addTitles() {
    this.closeModal()
    this.generatePartions()
    this.autoGenerated = true
    this.resolvedPortions = this.resolvedModalPortions
  }

  actions(emit: Actions) {
    const portionId = emit.object.id
    const titleId = new Guid(this.titleId)
    switch (emit.action) {
      case 'Excluir':
        this.deletePortion(titleId, portionId, emit.object)
        break;
    }
  }

  deletePortion(titleId: Guid, portionId: string, portion: Object){

    const index = this.resolvedPortions.indexOf(portion)

    if(this.editing && portionId != 'undefined')
      this._titlePortionService.deletePortion(titleId, new Guid(portionId)).then((res: any) => {
        if(res.success) {
          this.resolvedPortions.splice(index, 1)
          this.rawPortions.splice(index, 1)
          SwAlSetttings.Sucesso('Parcela Deletada com Sucesso')
          return
        } else {
          SwAlSetttings.Error(res.errors)
          return
        }
      })
      else {
        this.resolvedPortions.splice(index, 1)
        this.rawPortions.splice(index, 1)
      }
  }

  deleteAll() {
    const titleId = new Guid(this.titleId)

    if (this.rawPortions.some(obj => obj.id))
    this._titlePortionService.deleteAll(titleId).then((res: any) => {
      if(res.success) {
        SwAlSetttings.Sucesso('Todas as parcelas do título foram apagadas!')
        this.rawPortions = []
        this.resolvedPortions = []
      }
    })
    else {
      this.rawPortions = []
      this.resolvedPortions = []
    }
  }

  submit(){

    const titleId = this._route.snapshot.params['id']

    const titleBodyBase = {
      typeModule: this.titleForm.typeModule,
      documentNumber: this.titleForm.docNumber.value,
      value: this.titleForm.titleValue.value,
      externalId: "",
      titlePortions: [] as any
    }

    const titleBody = this.checkBoxForm.person.value == true
    ? { ...titleBodyBase, personId: this.titleForm.emitent.value }
    : { ...titleBodyBase, customerId: this.titleForm.emitent.value };


    this.rawPortions.forEach(x => {
      if (x.id) return
      const portion = {
        number: x.number,
        value: x.value,
        deadline: typeof(x.deadline)=='string' ? new Date(x.deadline+"Z").toISOString() : x.deadline.toISOString(),
        historic: ""
      }
      titleBody.titlePortions.push(portion)
    })

    if(this.editing){
      this._titlesService.put(titleBody, titleId).then((res: any) => {
        if(res.success){
          SwAlSetttings.Sucesso('Título Editado')
          if(titleBody.titlePortions.length > 0)
            this._titlePortionService.post({titleId: titleId, parcels: [...titleBody.titlePortions]}).then()
          this.back()
        }
      })
      return
    }

    this._titlesService.post(titleBody).then((res: any) => {
      if(res.success) {
        SwAlSetttings.Sucesso('Título Cadastrado')
        this.back()
      }
    })
    return
  }
}
