import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { EquipmentsComponent } from "./components/equipments/equipments.component";
import { SaveEquipmentComponent } from "./components/equipments/save-equipment/save-equipment.component";

const routes: Routes = [
    {
      path: '',
      children: [
          { path: '', redirectTo: 'Interfaceamento', pathMatch: 'full' },
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InterfacingRoutingModule {

}
