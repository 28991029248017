import { HttpClient } from '@angular/common/http';
import { HttpResponsePageable } from '../services.types';
import { BaseService } from '../base.service';

import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class AtributeRelationshipService extends BaseService {

  constructor(private http: HttpClient, httpService: HttpService) { 
    super(httpService, 'v1/lab/parameters/atributerelationship')
   }

  override post(body: any) {
    return super.post(body) as Promise< HttpResponsePageable<any>>
  }

}
