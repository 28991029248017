import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { InputSelectFormComponent } from "../input-select/base/input-select.component";

@Component({
   selector: "form-select-prop",
   templateUrl: "./select-prop.component.html",
   styleUrls: ["./select-prop.component.scss"],
   standalone: true,
   imports: [
      CommonModule,
      TranslateModule,
      InputSelectFormComponent,
      ReactiveFormsModule
   ]
})
export class FormSelectPropComponent implements OnInit {
   @Input() form!: FormControl;
   @Input() placeholder: string = "";
   @Input() options: Array<{ value: string; label: string }> = [];

   constructor() {}

   ngOnInit(): void {}
}
