import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Exam, ExamResolve, MovimentationProcess } from '@app/services/movimentation-process/movimentation-process';
import { MovimentationProcessService } from '@app/services/movimentation-process/movimentation-process.service';
import { HttpResponsePageable } from '@app/services/services.types';
import { SpanExamSituation } from '@app/util/ESpanExamSituation';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';

@Component({
  selector: 'app-liberation',
  templateUrl: './liberation.component.html',
  styleUrls: ['./liberation.component.scss']
})
export class LiberationComponent implements OnInit {
  examsModal: Array<Object> = [];
  examsFormGroup: Array<UntypedFormGroup> = [];
  checkboxFormGroup: Array<UntypedFormGroup> = [];
  allExams: Array<MovimentationProcess> = [];
  exams: Array<Exam> = [];
  registers: Array<ExamResolve> = [];
  actionsList: Array<String> = ['Liberar'];
  title: String = 'Liberação';

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  form = {
    requisition: new UntypedFormControl(null),
    type: new UntypedFormControl(3),
    value: new UntypedFormControl(null),
  }

  genderType = [
    {
      value: 0,
      label: 'Não Informado'
    },
    {
      value: 1,
      label: 'Feminino'
    },
    {
      value: 2,
      label: 'Masculino'
    }
  ];

  typeAtributeReference = [
    {
      value: 0,
      label: 'Anos'
    },
    {
      value: 1,
      label: 'Meses'
    },
    {
      value: 2,
      label: 'Dias'
    }
  ];

  type = [
    {
      value: 1,
      label: 'Digitar'
    },
    {
      value: 2,
      label: 'Conferir'
    },
    {
      value: 3,
      label: 'Liberar'
    },
    {
      value: 4,
      label: 'Imprimir'
    },
    {
      value: 5,
      label: 'Entregar'
    }
  ];

  resolveRegisters = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Requisição',
      retrive: 'number',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Nome',
      retrive: 'customerName',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Exames',
      retrive: '',
      method: 'getExams',
      after: '',
      before: ''
    }
  ];

  resolveExam = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Exame',
      retrive: 'name',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Material',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Convênio',
      retrive: 'agreementDescription',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Solicitante',
      retrive: 'doctorName',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Situação',
      retrive: '',
      method: 'getSituation',
      after: '',
      before: ''
    },
    {
      label: 'Prazo',
      retrive: '',
      method: 'getDeadline',
      after: '',
      before: ''
    }
  ];

  constructor(private _movimentationProcessService: MovimentationProcessService, private _dialog: MatDialog) { }

  ngOnInit() {
    this.get();
  }

  async get() {
    this.actionsList = [this.type[this.form.type.value - 1].label];

    switch (this.actionsList[0]) {
      case 'Digitar':
        this.title = 'Digitação';
        break;

      case 'Conferir':
        this.title = 'Conferência';
        break;

      case 'Liberar':
        this.title = 'Liberação';
        break;

      case 'Imprimir':
        this.title = 'Impressão';
        break;

      case 'Entregar':
        this.title = 'Entrega';
        break;
    }

    //this.registers = [];
    await this._movimentationProcessService.get('?ProcessAction=' + this.form.type.value).then(
      (res: any) => {
        if (res.data.length != 0) {
          this.allExams = res.data;
          this.resolve(res.data, this.resolveRegisters)
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  actions(emit: any) {
    this.examsFormGroup = [];
    this.checkboxFormGroup = [];
    this.examsModal = [];
    if (emit.action == 'OpenDropdown') {
      this.resolveExams(this.allExams.filter(x => x.id === emit.id)[0].agreementExamsRequest, this.resolveExam, emit.id);
    } else {
      var i = 0;
      //@ts-ignore
      this.allExams.filter(x => x.id == emit.object.id)[0].agreementExamsRequest.forEach((x: any) => {
        //@ts-ignore
        this.examsFormGroup[x.id] = new UntypedFormControl();
        //@ts-ignore
        this.checkboxFormGroup[x.id] = new UntypedFormControl(false);
        var atributes = x.atributeAnalyte;
        var valuesReference: any = [];
        atributes.forEach((atribute: any) => {
          var notInformmedReference = this.getStringReference(atribute.referenceAtributeAnalyte.filter((x: any) => x.genderType == 0));
          var femaleReference = this.getStringReference(atribute.referenceAtributeAnalyte.filter((x: any) => x.genderType == 1));
          var maleReference = this.getStringReference(atribute.referenceAtributeAnalyte.filter((x: any) => x.genderType == 2));
          var nonBinaryReference = this.getStringReference(atribute.referenceAtributeAnalyte.filter((x: any) => x.genderType == 3));
          var reference = notInformmedReference + femaleReference + maleReference + nonBinaryReference;
          var obj = {
            name: atribute.name,
            valueReference: reference
          }
          valuesReference.push(obj);
        });
        this.examsModal[i] = {
          id: x.id,
          name: x.name,
          material: x.description,
          atributeAnalyte: valuesReference
        }
        i++;
      });

      const myTempDialog = this._dialog.open(this.dialogModal, {
        width: '660px'
      });
    }
  }

  actionsExam(emit: any, idReq: string) {
    this.examsFormGroup = [];
    this.checkboxFormGroup = [];
    this.examsModal = [];
    //@ts-ignore
    this.examsFormGroup[emit.object.id] = new UntypedFormControl();
    //@ts-ignore
    this.checkboxFormGroup[emit.object.id] = new UntypedFormControl(false);
    //@ts-ignore
    var atributes = this.allExams.filter(x => x.id === idReq)[0].agreementExamsRequest.filter(y => y.id == emit.object.id)[0].atributeAnalyte;
    var valuesReference: any = [];
    atributes.forEach((atribute: any) => {
      var notInformmedReference = this.getStringReference(atribute.referenceAtributeAnalyte.filter((x: any) => x.genderType == 0));
      var femaleReference = this.getStringReference(atribute.referenceAtributeAnalyte.filter((x: any) => x.genderType == 1));
      var maleReference = this.getStringReference(atribute.referenceAtributeAnalyte.filter((x: any) => x.genderType == 2));
      var nonBinaryReference = this.getStringReference(atribute.referenceAtributeAnalyte.filter((x: any) => x.genderType == 3));
      var reference = notInformmedReference + femaleReference + maleReference + nonBinaryReference;
      var obj = {
        name: atribute.name,
        valueReference: reference
      }
      valuesReference.push(obj);
    });

    this.examsModal[0] = {
      id: emit.object.id,
      name: emit.object.Exame,
      material: emit.object.Material,
      atributeAnalyte: valuesReference
    }
    const myTempDialog = this._dialog.open(this.dialogModal, {
      width: '660px'
    });
  }

  resolve(data: any, columns: any) {
    this.registers = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registers.push(obj);
    });
  }

  resolveExams(data: any, columns: any, id: Guid) {
    //@ts-ignore
    this.exams[id] = [];
    let exams: Array<Exam> = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      exams.push(obj);
    });
    //@ts-ignore
    this.exams[id] = exams;
  }

  getExams(item: object) {
    var exams = '';
    //@ts-ignore
    item.agreementExamsRequest.forEach((exam: any) => {
      if (exams == '') {
        exams = exam.code;
      } else {
        exams += ', ' + exam.code;
      }
    });
    return exams;
  }

  getSituation(item: object) {
    //@ts-ignore
    return SpanExamSituation[item.situation];
  }

  close() {
    this._dialog.closeAll()
  }

  getDeadline(item: any) {
    var data = new Date(item.analyteDeadline + 'Z');
    return data.toLocaleString().replace(",", " -");
  }

  getStringReference(data: any) {
    var stringReference = '';
    if (data.length > 0) {
      var genderType = data[0].genderType;

      switch (genderType) {
        case 0://Não Informado
          stringReference = 'Não Informado:\n';
          break;
        case 1://Feminino
          stringReference = 'Feminino:\n';
          break;
        case 2://Masculino
          stringReference = 'Masculino:\n';
          break;
      }

      data.forEach((element: any) => {
        var line = 'De ' + element.ageMin + ' ' + this.typeAtributeReference[element.typeAgeMin].label + ' até ' + element.ageMax + ' ' + this.typeAtributeReference[element.typeAgeMax].label + ': Superior a ' + element.valueMin + ' e Inferior a ' + element.valueMax + '\n';
        stringReference += line;
      });
    }

    return stringReference;
  }
}