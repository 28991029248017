import { Component, OnInit, Output } from "@angular/core";

@Component({
   selector: "app-dashboard",
   templateUrl: "./dashboard.component.html",
   styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
   @Output() menuopen: boolean = false;
   hideMenu: boolean = false;
   constructor() {}

   ngOnInit() {
      if (localStorage.getItem("isApp")) {
         this.hideMenu = true;
      }
   }

   changeMenuOpen(event: any) {
      this.menuopen = event.changeMenuOpen;
   }

   getYear() {
      return new Date().getFullYear();
   }
}
