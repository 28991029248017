<div class="contentPage">
  <div class="header flex flex-column gap-2">
    <div class="flex align-center headerResponsive">
      <div class="title gap-2 flex align-center flex-center titleResponsive">
        <app-button (click)="back()" [placeholder]="'global.back'" [class]="'btn-reverse'"></app-button>
        <h1>{{title | translate}} <b class="orangeFont">{{titleName}}</b></h1>
      </div>
    </div>
  </div>
  <mat-tab-group disableRipple (selectedTabChange)="myTabSelectedTabChange($event)" class="mt-32">
    <mat-tab>
      <ng-template mat-tab-label>
        <h2>{{'analyteEdit.title' | translate}}</h2>
      </ng-template>
      <ng-container>
        <div class="contextPage h-100">
          <div class="w-100 box">
            <div class="w-100 flex flex-between">
              <h2>{{'analyteEdit.titleData' | translate}}</h2>
              <checkbox-prop *ngIf="hasAnalyte" [label]="'analyteEdit.active'" [gap]="1"
                [form]="form.active"></checkbox-prop>
            </div>
            <div class="flex gap-form mt-32">
              <div class="max-width-responsive">
                <input-prop [placeholder]="'analyteEdit.code'" [form]="form.code"></input-prop>
              </div>
              <div class="w-100">
                <input-prop [placeholder]="'analyteEdit.name'" [form]="form.name"></input-prop>
              </div>
            </div>
            <div class="flex gap-form mt-32">
              <div class="w-100">
                <select-prop [placeholder]="'analyteEdit.department'" [form]="form.sectorId"
                  [options]="allSectors"></select-prop>
              </div>
              <div class="w-100">
                <input-prop [placeholder]="'analyteEdit.nameInvoice'" [form]="form.nameInvoice"></input-prop>
              </div>
            </div>
            <div class="mt-32">
              <textarea-prop [placeholder]="'analyteEdit.information'" [form]="form.information"></textarea-prop>
            </div>
          </div>
          <div class="w-100 box mt-32">
            <div class="w-100 flex flex-between">
              <h2>{{'analyteEdit.titleIntegration' | translate}}</h2>
              <checkbox-prop [label]="'analyteEdit.outsourced'" [gap]="1" [form]="form.outsourced"></checkbox-prop>
            </div>
            <div class="flex mt-32 gap-form">
              <div class="w-100">
                <input-search [placeholder]="'analyteEdit.defaultSupportLaboratory'"
                  [form]="form.defaultSupportLaboratory" [options]="supportLaboratoryOptions"></input-search>
              </div>
              <div class="max-width-responsive">
                <input-prop placeholder="Valor Terceirizado" [form]="form.outsourcedvalue"></input-prop>
              </div>
            </div>
          </div>
          <div class="w-100 box mt-32">
            <h2>{{"analyteEdit.titleConfig" | translate}}</h2>
            <div class="flex mt-32 gap-form">
              <div class="w-100">
                <select-prop [placeholder]="'analyteEdit.typeResultWeb'" [form]="form.typeResultWeb"
                  [options]="typeResultWeb"></select-prop>
              </div>
              <div class="w-100">
                <select-prop [placeholder]="'analyteEdit.restrictGender'" [form]="form.restrictGender"
                  [options]="restrictGender"></select-prop>
              </div>
            </div>
          </div>
          <div class="mt-32 box">
            <h2>{{'analyteEdit.titleConfigReport' | translate}}</h2>
            <div class="w-100 mt-32 flex gap-form">
              <div class="w-100">
                <input-prop [placeholder]="'analyteEdit.sequenceReport'" type="number"
                  [form]="form.sequenceReport"></input-prop>
              </div>
              <div class="w-100">
                <input-prop [placeholder]="'analyteEdit.orderReport'" type="number"
                  [form]="form.orderReport"></input-prop>
              </div>
            </div>
          </div>
          <div class="flex flex-center gap-2 mt-32">
            <app-button
              (click)="analyteId.toString() == '00000000-0000-0000-0000-000000000000' ? createAnalyte() : updateAnalyte()"
              [placeholder]="'global.save'" [class]="'flex gap-2 align-center'"></app-button>
          </div>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab *ngIf="hasAnalyte">
      <ng-template mat-tab-label>
        <div class="flex align-center">
          <h2>{{'analyteQuestion.title' | translate}}</h2>
        </div>
      </ng-template>
      <ng-container>
        <div class="contextPage h-100">
          <div class="flex flex-between gap-2 align-center headerResponsive">
            <h2 class="flex titleResponsive">{{'analyteQuestion.title' | translate}}</h2>
            <app-button (click)="addQuestion()" [placeholder]="'global.new'" [matIcon]="'add'"
              [class]="'flex gap-2 align-center'"></app-button>
          </div>
          <app-table [Pagination]="true" [backPagination]="false" [sumRecords]="total" [pageNumber]="page"
            [indexNumber]="index" [noShadow]="true" [ClickInItem]="ActionsExtraQuestions" [Itens]="questions"
            [Actions]="['Excluir']" (parentFun)="ActionsQuestion($event)"></app-table>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab *ngIf="hasAnalyte">
      <ng-template mat-tab-label>
        <div class="flex align-center">
          <h2>{{'analyteMaterial.title' | translate}}</h2>
        </div>
      </ng-template>
      <ng-container>
        <div class="contextPage h-100">
          <div *ngIf="(type === eType.VISUALIZAR)">
            <div class="flex flex-between align-center headerResponsive">
              <h2 class="flex titleResponsive">{{'analyteMaterial.titleMaterial' | translate}}</h2>
              <app-button (click)="addMaterial()" [placeholder]="'global.new'" [matIcon]="'add'"
                [class]="'flex gap-2 align-center'"></app-button>
            </div>
            <div>
              <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="materialsParameters.total"
                [pageNumber]="materialsParameters.page" [indexNumber]="materialsParameters.index" [noShadow]="true"
                [subMenu2]="true" [ClickInItem]="ActionsMatRecipient" [Actions]="['Editar', 'Excluir']"
                [Dropdown]="true" [Itens]="materials" (parentFun)="ActionsMaterial($event)"
                (changePage)="getAnalyteMaterials(analyteId, $event)">
                <ng-template let-rowDetail #header>
                  <div class="w-100 flex flex-wrap flex-between align-center">
                    <h2>{{'analyteMaterial.titleRecipients' | translate}}</h2>
                    <app-button (click)="openModalRecipient(rowDetail.id)" [placeholder]="'global.new'"
                      [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
                  </div>
                  <div class="mt-32">
                    <app-table [noShadow]="true" [Pagination]="true" [backPagination]="true"
                      [sumRecords]="recipientsParams[rowDetail.id]?.total ?? 0"
                      [pageNumber]="recipientsParams[rowDetail.id]?.page ?? 1"
                      [indexNumber]="recipientsParams[rowDetail.id]?.index ?? 1"
                      [Itens]="recipients[rowDetail.id] ?? []" [Actions]="['Excluir']"
                      (changePage)="getAnalyteMaterialRecipients(rowDetail.id, $event)"
                      (parentFun)="ActionsRecip($event)"></app-table>
                  </div>
                </ng-template>
              </app-table>
            </div>
          </div>
          <div class="boxform">
            <div *ngIf="type === eType.NOVO">
              <div class=" flex align-center gap-2">
                <app-button (click)="backToVisualization()" [placeholder]="'Voltar para Lista'"
                  [class]="'flex gap-2 align-center btn-reverse'"></app-button>
                <h2 class="flex titleResponsive">{{'analyteMaterial.titleNew' | translate}}</h2>
              </div>
              <div class="w-100 box mt-32">
                <div class="w-100 flex flex-between">
                  <h2>{{'analyteMaterial.materialName' | translate}}</h2>
                </div>
                <div class="flex align-center w-100 gap-form mt-32">
                  <div class="w-code">
                    <input-prop class="w-code" [placeholder]="'analyteMaterial.code'" [form]="form.materialCode"
                      [readonly]="editMaterial" (change)="getMaterialByCode()"></input-prop>
                  </div>
                  <div [class]="'flex gap-1 align-center w-100'">
                    <input-prop *ngIf="editMaterial" class="w-100" [placeholder]="'analyteMaterial.materialName'"
                      [form]="form.materialName" [readonly]="true"></input-prop>
                    <input-search *ngIf="!editMaterial" class="w-100" [placeholder]="'analyteMaterial.materialName'"
                      [form]="form.materialId" (changeForm)="setMaterialCode()" [options]="AllMaterials"
                      [searchApi]="true" (search)="getMaterials($event)"></input-search>
                    <checkbox-prop class="checkbox" [label]="'analyteMaterial.material'" [gap]="1"
                      [form]="form.isDefaultMaterial"></checkbox-prop>
                  </div>
                </div>
                <div class="mt-32 flex flex-between gap-form">
                  <input-prop class="w-100" [placeholder]="'analyteMaterial.deadline'" [form]="form.deadline"
                    type="number"></input-prop>
                  <input-prop class="w-100" [placeholder]="'analyteMaterial.urgentDeadline'"
                    [form]="form.urgentDeadline" type="number"></input-prop>
                  <input-prop class="w-100" [placeholder]="'analyteMaterial.emergencyDeadline'"
                    [form]="form.emergencyDeadline" type="time"></input-prop>
                </div>
              </div>
              <div class="w-100 box mt-32">
                <div class="w-100 flex flex-between">
                  <h2>{{'analyteMaterial.newRecipient' | translate}}</h2>
                </div>
                <div class="mt-32 mb-32 flex flex-between gap-form">
                  <div class="w-code">
                    <input-prop class="w-code" [placeholder]="'analyteMaterial.code'" [form]="form.recipientCode"
                      (change)="getRecipientByCode()"></input-prop>
                  </div>
                  <div [class]="'flex gap-1 align-center w-100'">
                    <input-search class="flex-1" [placeholder]="'analyteMaterial.newRecipient'"
                      [form]="form.newRecipient" [options]="recipientsOptions" (changeForm)="setRecipientCode()"
                      (search)="searchRecipients($event.value)" [searchApi]="true"></input-search>
                    <app-button (click)="addRecipient()" [placeholder]="'analyteMaterial.btn'"
                      [class]="'flex gap-2 align-center btn'"></app-button>
                  </div>
                </div>
                <div class="mt-32" *ngIf="this.recipientsList.length > 0">
                  <h2>{{'analyteMaterial.newRecipient' | translate}}</h2>
                  <app-table [Itens]="recipientsList" [Actions]="['Excluir']"
                    (parentFun)="ActionsRecipientModalList($event)"></app-table>
                </div>
              </div>
              <div class="mt-32 flex flex-center pd-5">
                <app-button (click)="saveMaterial()" [placeholder]="'global.save'"
                  [class]="'flex gap-2 align-center btn'"></app-button>
              </div>
            </div>
          </div>
          <div class="boxform">
            <div *ngIf="type === eType.EDITAR">
              <div class=" flex align-center gap-2">
                <app-button (click)="backToVisualization()" [placeholder]="'analyteMaterial.btnBack'"
                  [class]="'flex gap-2 align-center btn-reverse'"></app-button>
                <h2 class="flex titleResponsive">{{'analyteMaterial.titleEdit' | translate}}</h2>
              </div>
              <div class="w-100 box mt-32">
                <div class="w-100 flex flex-between">
                  <h2>{{'analyteMaterial.materialName' | translate}}</h2>
                </div>
                <div class="flex align-center w-100 gap-form mt-32">
                  <div class="w-code">
                    <input-prop class="w-code" [placeholder]="'analyteMaterial.code'" [form]="form.materialCode"
                      [readonly]="editMaterial" (change)="getMaterialByCode()"></input-prop>
                  </div>
                  <div [class]="'flex gap-1 align-center w-100'">
                    <input-prop *ngIf="editMaterial" class="w-100" [placeholder]="'analyteMaterial.materialName'"
                      [form]="form.materialName" [readonly]="true"></input-prop>
                    <input-search *ngIf="!editMaterial" class="w-100" [placeholder]="'analyteMaterial.materialName'"
                      [form]="form.materialId" (changeForm)="setMaterialCode()" [options]="AllMaterials"
                      [searchApi]="true" (search)="getMaterials($event)"></input-search>
                    <checkbox-prop [label]="'analyteMaterial.material'" [gap]="1"
                      [form]="form.isDefaultMaterial"></checkbox-prop>
                  </div>
                </div>
                <div class="mt-32 flex flex-between gap-form">
                  <input-prop class="w-100" [placeholder]="'analyteMaterial.deadline'" [form]="form.deadline"
                    type="number"></input-prop>
                  <input-prop class="w-100" [placeholder]="'analyteMaterial.urgentDeadline'"
                    [form]="form.urgentDeadline" type="number"></input-prop>
                  <input-prop class="w-100" [placeholder]="'analyteMaterial.emergencyDeadline'"
                    [form]="form.emergencyDeadline" type="time"></input-prop>
                </div>
              </div>
              <div class="w-100 box mt-32">
                <div class="w-100 flex flex-between">
                  <h2>{{'analyteMaterial.newRecipient' | translate}}</h2>
                </div>
                <div class="mt-32 mb-32 flex flex-between gap-form">
                  <div class="w-code">
                    <input-prop class="w-code" [placeholder]="'analyteMaterial.code'" [form]="form.recipientCode"
                      (change)="getRecipientByCode()"></input-prop>
                  </div>
                  <div [class]="'flex gap-1 align-center w-100'">
                    <input-search class="flex-1" [placeholder]="'analyteMaterial.newRecipient'"
                      [form]="form.newRecipient" [options]="recipientsOptions" (changeForm)="setRecipientCode()"
                      (search)="searchRecipients($event.value)" [searchApi]="true"></input-search>
                    <app-button (click)="addRecipient()" [placeholder]="'analyteMaterial.btn'"
                      [class]="'flex gap-2 align-center btn'"></app-button>
                  </div>
                </div>
                <div class="mt-32" *ngIf="this.recipientsList.length > 0">
                  <h2>{{'analyteMaterial.newRecipient' | translate}}</h2>
                  <app-table [Itens]="recipientsList" [Actions]="['Excluir']"
                    (parentFun)="ActionsRecipientModalList($event)"></app-table>
                </div>
              </div>
              <div style="padding: 5px;" class="mt-32 flex flex-center">
                <app-button (click)="saveMaterial()" [placeholder]="'global.save'"
                  [class]="'flex gap-2 align-center btn'"></app-button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab *ngIf="hasAnalyte">
      <ng-template mat-tab-label>
        <div class="flex align-center">
          <h2>{{'analyteAtributo.title' | translate}}</h2>
        </div>
      </ng-template>
      <ng-container>
        <div class="contextPage h-100">
          <div class="flex gap-2 flex-between align-center headerResponsive">
            <h2 class="flex titleResponsive">{{'analyteAtributo.title' | translate}}</h2>
            <app-button (click)="addAttribute()" [placeholder]="'global.new'" [matIcon]="'add'"
              [class]="'flex gap-2 align-center'"></app-button>
          </div>
          <app-table [noShadow]="true" [Actions]="['Editar', 'Excluir']" [ClickInItem]="ActionsExtraAtribute"
            [Pagination]="true" [backPagination]="false" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index"
            (parentFun)="ActionsAttributes($event)" [Itens]="attributes" [Dropdown]="true">
            <ng-template let-rowDetail #header>
              <div class="w-100 flex flex-between gap-2 align-center mt-32">
                <div>
                  <h2>{{'analyteAtributo.titleValue' | translate}}</h2>
                </div>
                <app-button (click)="includeAtributeReference(rowDetail.id)" [placeholder]="'global.new'"
                  [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
              </div>
              <div>
                <app-table [noShadow]="true" [Pagination]="true" [backPagination]="false" [sumRecords]="total"
                  [indexNumber]="index" [pageNumber]="page" [Itens]="referenceAtributes[rowDetail.id]"
                  [Actions]="['Editar', 'Excluir']" [ClickInItem]="ActionsExtraReferenceValue"
                  (parentFun)="ActionsReferenceAtribute($event, rowDetail.id)"></app-table>
              </div>
            </ng-template>
          </app-table>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab *ngIf="hasAnalyte">
      <ng-template mat-tab-label>
        <div class="flex align-center">
          <h2>{{'reports.title' | translate}}</h2>
        </div>
      </ng-template>
      <ng-container>
        <div class="contextPage h-100">
          <div class="flex gap-2 flex-between align-center headerResponsive">
            <h2>{{'reports.title' | translate}}</h2>
            <app-button (click)="addReport(false)" [placeholder]="'global.new'" [matIcon]="'add'"
              [class]="'flex gap-2 align-center'"></app-button>
          </div>
          <app-table [noShadow]="true" [Actions]="['Visualizar', 'Editar', 'Excluir']" [Pagination]="true"
            [backPagination]="false" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index"
            (parentFun)="ActionsReport($event)" [ClickInItem]="ActionsExtraReport" [Itens]="reports" [Dropdown]="true">
            <ng-template let-rowDetail #header>
              <div class="w-100 flex flex-wrap gap-2 flex-between align-center mt-32">
                <h2>{{'reports.titleAtributo' | translate}}</h2>
                <app-button (click)="openDialogModalAtributeReport(rowDetail.id)" [placeholder]="'global.new'"
                  [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
              </div>
              <div class="mt-32">
                <app-table [Pagination]="true" [backPagination]="false" [sumRecords]="total" [pageNumber]="page"
                  [indexNumber]="index" [Itens]="atributesReport[rowDetail.id]" [Actions]="['Excluir']"
                  [ClickInItem]="ActionsExtraAtributeReport"
                  (parentFun)="ActionsAtributeReport($event, rowDetail.id)"></app-table>
              </div>
            </ng-template>
          </app-table>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab *ngIf="hasAnalyte">
      <ng-template mat-tab-label>
        <div class="flex align-center">
          <h2>{{'manual.title' | translate}}</h2>
        </div>
      </ng-template>
      <ng-container>
        <div class="contextPage h-100">
          <div class="w-100 flex flex-wrap flex-between align-center">
            <div class="flex flex-column gap-1 w-100">
              <h2>{{'manual.description' | translate}}</h2>
              <div class="flex align-center flex-between w-100">
                <textarea-prop style="width: 100%" [form]="formManual.description"></textarea-prop>
              </div>
            </div>
            <div class="w-100">
              <div class="flex flex-between w-100 mt-24 gap-form">
                <div style="min-width: 50%;" class="flex flex-column gap-1">
                  <h2>{{'manual.preparation' | translate}}</h2>
                  <div class="flex flex-wrap">
                    <textarea-prop style="width: 99%" [form]="formManual.preparation"></textarea-prop>
                  </div>
                </div>
                <div style="min-width: 50%;" class="flex flex-column gap-1">
                  <h2>{{'manual.collect' | translate}}</h2>
                  <div class="flex flex-wrap">
                    <textarea-prop style="width: 99%" [form]="formManual.collect"></textarea-prop>
                  </div>
                </div>
              </div>
              <div class="flex flex-between w-100 mt-32 gap-form">
                <div style="min-width: 50%;" class="flex flex-column gap-1">
                  <h2>{{'manual.distribution' | translate}}</h2>
                  <div style="border-radius: 0px;flex-wrap: wrap;" class="flex">
                    <textarea-prop style="width: 99%" [form]="formManual.distribution"></textarea-prop>
                  </div>
                </div>
                <div style="min-width: 50%;">
                  <h2>{{'manual.estabilish' | translate}}</h2>
                  <div style="border-radius: 0px;flex-wrap: wrap;" class="flex">
                    <textarea-prop style="width: 99%" [form]="formManual.estabilish"></textarea-prop>
                  </div>
                </div>
              </div>
              <div class="flex flex-between w-100 mt-24 gap-form">
                <div style="min-width: 50%;" class="flex flex-column gap-1">
                  <h2>{{'manual.reject' | translate}}</h2>
                  <div style="border-radius: 0px;flex-wrap: wrap;" class="flex">
                    <textarea-prop style="width: 99%" [form]="formManual.reject"></textarea-prop>
                  </div>
                </div>
                <div style="min-width: 50%;" class="flex flex-column gap-1">
                  <h2>{{'manual.additional' | translate}}</h2>
                  <div style="border-radius: 0px;flex-wrap: wrap;" class="flex">
                    <textarea-prop style="width: 99%" [form]="formManual.additional"></textarea-prop>
                  </div>
                </div>
              </div>
              <div class="flex flex-between w-100 mt-24 gap-form">
                <div style="min-width: 50%;">
                  <h2>{{'manual.interpretation' | translate}}</h2>
                  <div style="border-radius: 0px;flex-wrap: wrap;" class="flex">
                    <textarea-prop style="width: 99%" [form]="formManual.interpretation"></textarea-prop>
                  </div>
                </div>
                <div style="min-width: 50%;" class="flex flex-column gap-1">
                  <h2>{{'manual.pathologies' | translate}}</h2>
                  <div style="border-radius: 0px;flex-wrap: wrap;" class="flex">
                    <textarea-prop style="width: 99%" [form]="formManual.pathologies"></textarea-prop>
                  </div>
                </div>
              </div>
              <div class="flex flex-between w-100 mt-32">
                <div style="min-width: 100%;" class="flex flex-column gap-1">
                  <h2>{{'manual.links' | translate}}</h2>
                  <div style="border-radius: 0px;flex-wrap: wrap;">
                    <textarea-prop style="width: 100%" [form]="formManual.links"></textarea-prop>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-center mt-32">
            <app-button (click)="submitAnalyteInstructions()" [placeholder]="'global.save'"></app-button>
          </div>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab *ngIf="hasAnalyte">
      <ng-template mat-tab-label>
        <div class="flex align-center">
          <h2>{{'analyteParameters.title' | translate}}</h2>
        </div>
      </ng-template>
      <ng-container>
        <div class="contextPage h-100">
          <div *ngIf="!disable" class=" flex-center">
            <div [ngClass]="disable? 'readonly':''">
              <div class="flex gap-2 flex-between align-center headerResponsive">
                <h2 class="flex titleResponsive">{{'analyteParameters.title' | translate}}</h2>
                <app-button (click)="modalParameter()" [placeholder]="'global.new'" [matIcon]="'add'"
                  [class]="'flex gap-2 align-center'"></app-button>
              </div>
              <app-table [Pagination]="true" [backPagination]="false" [sumRecords]="total" [pageNumber]="page"
                [indexNumber]="index" [noShadow]="true" [ClickInItem]="ActionsExtra"
                (parentFun)="ActionsParameters($event)" [Itens]="parameters"></app-table>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab *ngIf="hasAnalyte">
      <ng-template mat-tab-label>
        <div class="flex align-center">
          <h2>{{'analyteCondition.title' | translate}}</h2>
        </div>
      </ng-template>
      <ng-container>
        <div class="contextPage h-100">
          <div class="flex gap-2 flex-between align-center headerResponsive">
            <h2 class="flex titleResponsive">{{'analyteCondition.title' | translate}}</h2>
            <app-button (click)="addAnalyteCondition()" [placeholder]="'global.new'" [matIcon]="'add'"
              [class]="'flex gap-2 align-center'"></app-button>
          </div>
          <app-table [ClickInItem]="ActionsExtraConditional" [noShadow]="true" [Pagination]="true"
            [backPagination]="true" [Itens]="conditions" [sumRecords]="totalCondition" [pageNumber]="pageCondition"
            [indexNumber]="indexCondition" [Actions]="['Editar','Excluir']" [Dropdown]="true"
            (parentFun)="ActionsConditional($event)" (changePage)="getConditions($event)">
            <ng-template let-rowDetail #header>
              <div class="flex flex-between gap-2 align-center mt-32">
                <h2>{{'analyteCondition.titleParameters' | translate}}</h2>
                <app-button (click)="addConditionalParameter(rowDetail)" [placeholder]="'global.new'" [matIcon]="'add'"
                  [class]="'flex gap-2 align-center'"></app-button>
              </div>
              <app-table [ClickInItem]="ActionsExtraParameterConditional" [noShadow]="true"
                [Itens]="conditionsText[rowDetail.id]" [Actions]="['Editar', 'Excluir']"
                (changePage)="getConditionalParameters(rowDetail.id ,$event)"
                (parentFun)="ActionsConditionsText($event, rowDetail)"></app-table>
            </ng-template>
          </app-table>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab *ngIf="hasAnalyte">
      <ng-template mat-tab-label>
        <div class="flex align-center">
          <h2>{{'analyteLaboratories.title' | translate}}</h2>
        </div>
      </ng-template>
      <ng-container>
        <div class="contextPage ">
          <div class="flex align-center flex-between">
            <h2>{{'analyteLaboratories.title' | translate}}</h2>
            <app-filter (beforeClose)="supportLaboratories(true)" [cleanButton]="true" (clearFilters)="clearFilter()">
              <div class="query aniOpen">
                <input-prop class="w-100" [placeholder]="'analyteLaboratories.name'"
                  [form]="SupportLaboratoriesFilter"></input-prop>
              </div>
              <div class="queryBtn">
                <app-button class="queryBtn" (click)="supportLaboratories(true)"
                  [placeholder]="'analyteLaboratories.search'" [matIcon]="'search'"
                  [class]="'flex gap-2 align-center'"></app-button>
              </div>
            </app-filter>
          </div>
          <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="totalSupportLaboratories"
            [pageNumber]="pageSupportLaboratories" [indexNumber]="indexSupportLaboratories" [noShadow]="true"
            [Itens]="allSupportLaboratories" (changePage)="supportLaboratories($event)"></app-table>

        </div>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #dialogModalConditional let-conditional>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{titleModal ? ('analyteCondition.titleEdit' | translate) : ('analyteCondition.titleNew' | translate) }}
        </h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
        </svg>
      </div>
      <div class="flex w-100 gap-form mt-32 align-center">
        <div class="w-100">
          <input-search [placeholder]="'analyteCondition.atributeAnalyteName'"
            (changeForm)="formConditional.atributeAnalyteId.value ? checkType() : ''"
            [form]="formConditional.atributeAnalyteId" [options]="AllAttributes"></input-search>
        </div>
        <div class="w-100">
          <input-search [placeholder]="'analyteCondition.type'" [form]="formConditional.typeAnalisys"
            [options]="typeAnalisis"></input-search>
        </div>
      </div>
      <div *ngIf="conditionalNumeric == 2" class="flex w-100 gap-form mt-32">
        <div class="w-100">
          <input-search [placeholder]="'analyteCondition.condition'" [form]="formConditional.typeBlockedText"
            [options]="typeBlockedText"></input-search>
        </div>
        <div class="w-100">
          <input-prop [placeholder]="'analyteCondition.textAnalisys'"
            [form]="formConditional.textAnalisys"></input-prop>
        </div>
      </div>
      <div *ngIf="conditionalNumeric == 1" class="flex w-100 gap-form mt-32">
        <div class="w-100">
          <input-search [placeholder]="'analyteCondition.condition'" [form]="formConditional.typeBlockedNumericFrom"
            [options]="typeBlockedNumeric"></input-search>
        </div>
        <div class="w-100">
          <input-prop [placeholder]="'analyteCondition.result'" [form]="formConditional.startNumber"></input-prop>
        </div>
        <div class="w-100">
          <input-search [placeholder]="'analyteCondition.condition'" [form]="formConditional.typeBlockedNumericTo"
            [options]="typeBlockedNumeric"></input-search>
        </div>
        <div class="w-100">
          <input-prop [placeholder]="'analyteCondition.result'" [form]="formConditional.finishNumber"></input-prop>
        </div>
      </div>
      <div class="mt-32 flex flex-center">
        <app-button (click)="saveConditional()" [placeholder]="'global.save'" [class]="'btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalQuestion let-question>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{'analyteQuestion.titleNew' | translate}}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
        </svg>
      </div>
      <div class="mt-32 flex gap-form align-center">
        <input-prop class="w-code890" [placeholder]="'analyteQuestion.code'" (change)="getQuestionByCode()"
          [form]="form.questionCode"></input-prop>
        <input-search class="w-100" (changeForm)="setQuestionCode()" [placeholder]="'analyteQuestion.question'"
          [form]="form.question" [options]="AllQuestions" [searchApi]="true"
          (search)="getQuestions($event.value)"></input-search>
        <checkbox-prop class="w-checkbox" [label]="'analyteQuestion.isRequired'" [gap]="1"
          [form]="form.isRequired"></checkbox-prop>
      </div>
      <div class="mt-32 flex flex-center">
        <app-button (click)="saveQuestion()" [placeholder]="'global.save'" [class]="'btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalMaterial let-material>
  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1>{{ 'analyteMaterial.titleNewRecipiente' | translate}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
      </svg>
    </div>
    <div style="max-height: 660px;">
      <div class="mt-32 mb-32 flex flex-between gap-form">
        <input-prop [placeholder]="'analyteMaterial.code'" class="w-code" [form]="form.recipientCode"
          (change)="getRecipientByCode()"></input-prop>
        <input-search class="flex-1" [placeholder]="'analyteMaterial.newRecipient'" [form]="form.newRecipient"
          [options]="recipientsOptions" (changeForm)="setRecipientCode()" (search)="searchRecipients($event.value)"
          [searchApi]="true"></input-search>
      </div>
      <div style="padding: 5px;" class="mt-32 flex flex-center">
        <app-button (click)="newRecipient()" [placeholder]="'global.save'"
          [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalReport let-report>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{titleModal ? ('reports.titleEdit' | translate) : ('reports.titleNew' | translate)}}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
        </svg>
      </div>
      <div class="flex w-100 gap-form mt-32 align-center">
        <div class="w-100">
          <input-prop [placeholder]="'reports.name'" [form]="form.reportName"></input-prop>
        </div>
      </div>
      <div class="mt-32">
        <input-search [placeholder]="'reports.material'" [form]="form.reportMaterial"
          [options]="AllMaterialsReport"></input-search>
      </div>
      <div class="mt-32">
        <input-prop [placeholder]="'reports.reportMaterialName'" [form]="form.reportMaterialName"></input-prop>
      </div>
      <div class="mt-32">
        <input-search (keyup.enter)="saveReport()" [placeholder]="'reports.methodologyName'"
          [form]="form.reportMethodology" [options]="AllMethodologys"></input-search>
      </div>
      <div class="mt-32 flex flex-center">
        <app-button (click)="saveReport()" [placeholder]="'global.save'"
          [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalAttribute let-attribute>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{titleModal ? ('analyteAtributo.titleEdit' | translate) : ('analyteAtributo.titleNew' | translate) }}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
        </svg>
      </div>
      <div class="flex w-100 gap-form mt-32 align-center">
        <div class="w-100 flex gap-form">
          <input-prop (keyup.enter)="includeAttribute()" [placeholder]="'analyteAtributo.code'"
            [form]="formAttribute.code"></input-prop>
          <input-prop class="w-100" [placeholder]="'analyteAtributo.name'" [form]="formAttribute.name"></input-prop>
        </div>
      </div>
      <div style="justify-content: center;" class="align-center w-100 mt-32 flex">
        <fieldset class="flex w-100">
          <legend>{{'analyteAtributo.titleType' | translate}}</legend>
          <div class="w-100">
            <div class="flex gap-form mt-32">
              <select-prop class="w-100" [placeholder]="'analyteAtributo.type'" [form]="formAttribute.typeAttribute"
                [options]="typeAtribute"></select-prop>
              <input-search *ngIf="formAttribute.typeAttribute.value < 5 || formAttribute.typeAttribute.value == 12"
                class="w-100" [placeholder]="'analyteAtributo.unitMeasureId'" [form]="formAttribute.unitMeasureId"
                [options]="unitMeasure"></input-search>
              <input-prop *ngIf="formAttribute.typeAttribute.value == 13" class="w-100"
                [placeholder]="'analyteAtributo.optionsList'" [form]="formAttribute.optionsList"></input-prop>
              <app-button (click)="addOptionList()" *ngIf="formAttribute.typeAttribute.value == 13"
                [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
            </div>
            <div *ngIf="formAttribute.typeAttribute.value == 13" class="tableAttribute">
              <app-table [noShadow]="true" [forceCloseMenu]="true" [Pagination]="false" [backPagination]="false"
                [Itens]="optionsList" [Actions]="['Excluir']" (parentFun)="ActionsOptionsList($event)"
                [ClickInItem]="ActionsExtraDataType" (parentFun)="ActionsDataType($event)"></app-table>
            </div>
            <div *ngIf="formAttribute.typeAttribute.value > 4 && formAttribute.typeAttribute.value < 11"
              class="w-100 mt-32">
              <file-prop dashed="true" [form]="formAttribute.image" [formName]="formAttribute.imageName"></file-prop>
            </div>
            <div *ngIf="formAttribute.typeAttribute.value <= 4 || formAttribute.typeAttribute.value >= 11"
              class="w-100 mt-32">
              <div *ngIf="formAttribute.typeAttribute.value == 0" class="w-100">
                <input-prop [placeholder]="'analyteAtributo.decimalPlaces'" type="number"
                  [form]="formAttribute.decimalPlaces"></input-prop>
              </div>
              <div *ngIf="formAttribute.typeAttribute.value == 12" class="w-100 flex gap-form">
                <input-prop [placeholder]="'analyteAtributo.if'" [form]="formFormula.if"></input-prop>
                <select-prop [placeholder]="'analyteAtributo.typeComparation'" [form]="formFormula.typeComparation"
                  [options]="typeComparation"></select-prop>
                <select-prop *ngIf="formFormula.typeComparation.value != 0 && formFormula.if.value != ''"
                  [placeholder]="'analyteAtributo.condicional'" [form]="formFormula.conditional"
                  [options]="typeConditional"></select-prop>
                <select-prop *ngIf="formFormula.typeComparation.value == 0 && formFormula.if.value != ''"
                  [placeholder]="'analyteAtributo.condicional'" [form]="formFormula.conditional"
                  [options]="typeConditionalText"></select-prop>
                <select-prop *ngIf="formFormula.typeComparation.value != 0 && formFormula.if.value == ''"
                  [placeholder]="'analyteAtributo.condicional'" [readonlySelect]="true" [form]="formFormula.conditional"
                  [options]="typeConditional"></select-prop>
                <select-prop *ngIf="formFormula.typeComparation.value == 0 && formFormula.if.value == ''"
                  [placeholder]="'analyteAtributo.condicional'" [readonlySelect]="true" [form]="formFormula.conditional"
                  [options]="typeConditionalText"></select-prop>
                <input-prop [placeholder]="'analyteAtributo.value'" [form]="formFormula.value"></input-prop>
              </div>
              <div *ngIf="formAttribute.typeAttribute.value == 12" class="w-100 flex gap-form mt-32">
                <input-prop class="w-100" [placeholder]="'analyteAtributo.and'" [form]="formFormula.and"></input-prop>
                <select-prop class="w-100" [placeholder]="'analyteAtributo.typeResult'" [form]="formFormula.typeResult"
                  [options]="typeResult"></select-prop>
                <input-prop class="w-100" *ngIf="formFormula.if.value == ''" [placeholder]="'analyteAtributo.else'"
                  [form]="formFormula.else"></input-prop>
                <input-prop class="w-100" *ngIf="formFormula.if.value != ''" [placeholder]="'analyteAtributo.then'"
                  [form]="formFormula.else"></input-prop>
              </div>
              <div *ngIf="formAttribute.typeAttribute.value == 12" class="mt-32 flex flex-center space-evenly gap-2">
                <app-button (click)="saveFormula()" [placeholder]="'global.save'"
                  [class]="'flex gap-2 align-center btn'"></app-button>
                <app-button (click)="clearFormula()" [placeholder]="'analyteAtributo.btn'"
                  [class]="'flex gap-2 align-center btn'"></app-button>
              </div>
              <div *ngIf="formAttribute.typeAttribute.value == 12" class="mt-32 w-100">
                <textareaauto-prop [placeholder]="'analyteAtributo.formula'" [readonly]="true"
                  [form]="formFormula.formula"></textareaauto-prop>
              </div>
              <div
                *ngIf="formAttribute.typeAttribute.value == 1 || formAttribute.typeAttribute.value == 2 || formAttribute.typeAttribute.value == 3 || formAttribute.typeAttribute.value == 4"
                class="w-100 flex gap-form">
                <input-prop class="w-100" [placeholder]="'analyteAtributo.valueDefault'"
                  [form]="formAttribute.valueDefault"></input-prop>
                <checkbox-prop class="flex align-center w-100 flex-center" [label]="'analyteAtributo.isUpperCase'"
                  [gap]="1" [form]="formAttribute.isUpperCase"></checkbox-prop>
              </div>
              <div *ngIf="formAttribute.typeAttribute.value == 11" class="w-100">
                <input-search [placeholder]="'analyteAtributo.question'" [form]="formAttribute.questionId"
                  [options]="AllQuestions"></input-search>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div *ngIf="formAttribute.typeAttribute.value == 0 || formAttribute.typeAttribute.value == 12">
        <div style="justify-content: center;" class="align-center w-100 mt-32 flex gap-form">
          <fieldset class="flex w-100">
            <legend>{{'analyteAtributo.titleResult' | translate}}</legend>
            <div class="w-100">
              <div class="flex gap-form">
                <input-prop [placeholder]="'analyteAtributo.minValue'" [form]="formAttribute.attributeMinAbsurdValue"
                  type="number"></input-prop>
                <input-prop [placeholder]="'analyteAtributo.maxValue'" [form]="formAttribute.attributeMaxAbsurdValue"
                  type="number"></input-prop>
              </div>
            </div>
          </fieldset>
          <fieldset class="flex w-100">
            <legend>{{'analyteAtributo.titleText' | translate}}</legend>
            <div class="w-100">
              <div class="flex gap-form">
                <input-prop [placeholder]="'analyteAtributo.minValue'" [form]="formAttribute.attributeUpValue"
                  type="number"></input-prop>
                <input-prop [placeholder]="'analyteAtributo.maxValue'" [form]="formAttribute.attributeDownValue"
                  type="number"></input-prop>
              </div>
            </div>
          </fieldset>
        </div>
        <div style="justify-content: center;" class="align-center w-100 mt-32 flex gap-form">
          <fieldset class="flex w-100">
            <legend>{{'analyteAtributo.titleChanged' | translate}}</legend>
            <div class="w-100">
              <div class="flex gap-form">
                <input-prop [placeholder]="'analyteAtributo.minValue'" [form]="formAttribute.attributeMinControlValue"
                  type="number"></input-prop>
                <input-prop [placeholder]="'analyteAtributo.maxValue'" [form]="formAttribute.attributeMaxControlValue"
                  type="number"></input-prop>
              </div>
            </div>
          </fieldset>
          <fieldset class="flex w-100">
            <legend>{{'analyteAtributo.titleCritical' | translate}}</legend>
            <div class="w-100">
              <div class="flex gap-form">
                <input-prop [placeholder]="'analyteAtributo.minValue'" [form]="formAttribute.attributeMinCriticalValue"
                  type="number"></input-prop>
                <input-prop [placeholder]="'analyteAtributo.maxValue'" [form]="formAttribute.attributeMaxCriticalValue"
                  type="number"></input-prop>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="flex w-100 gap-form mt-32">
        <div class="w-100">
          <input-prop [placeholder]="'analyteAtributo.lineAfter'" [form]="formAttribute.lineAfter"
            type="number"></input-prop>
        </div>
      </div>
      <div class="flex flex-between align-center w-100 gap-form mt-10">
        <div class="gap-form display-grid">
          <checkbox-prop [label]="'analyteAtributo.isResult'" [gap]="1" [form]="formAttribute.isResult"></checkbox-prop>
          <checkbox-prop [label]="'analyteAtributo.isRequired'" [gap]="1"
            [form]="formAttribute.isRequired"></checkbox-prop>
        </div>
        <div class="gap-form display-grid">
          <checkbox-prop [label]="'analyteAtributo.isHidden'" [gap]="1" [form]="formAttribute.isHidden"></checkbox-prop>
          <checkbox-prop [label]="'analyteAtributo.isHiddenWhenNull'" [gap]="1"
            [form]="formAttribute.isHiddenWhenNull"></checkbox-prop>
        </div>
        <div class="gap-form display-grid">
          <checkbox-prop [label]="'analyteAtributo.isAuxiliar'" [gap]="1"
            [form]="formAttribute.isAuxiliar"></checkbox-prop>
          <checkbox-prop [label]="'analyteAtributo.applyOldResult'" [gap]="1"
            [form]="formAttribute.applyOldResult"></checkbox-prop>
        </div>
      </div>
      <div class="mt-32 flex flex-center">
        <app-button (click)="includeAttribute()" [placeholder]="'global.save'"
          [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalAttributeReference let-attribute>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{titleSubModal ? ('analyteAtributo.titleEditValue' | translate) : ('analyteAtributo.titleNewValue' |
          translate) }}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
        </svg>
      </div>
      <div class="flex w-100 gap-form mt-32 align-center">
        <div class="w-100">
          <input-prop [placeholder]="'analyteAtributo.resolveAtributeReference.name'"
            [form]="formReference.name"></input-prop>
        </div>
      </div>
      <div class="flex w-100 gap-form mt-32">
        <div class="w-100">
          <input-prop [placeholder]="'analyteAtributo.resolveAtributeReference.typeMin'" type="number"
            [form]="formReference.ageMin"></input-prop>
        </div>
        <div class="w-100">
          <select-prop [placeholder]="Tipo" [form]="formReference.typeAgeMin"
            [options]="typeAtributeReference"></select-prop>
        </div>
        <div class="w-100">
          <input-prop [placeholder]="'analyteAtributo.resolveAtributeReference.typeMax'" type="number"
            [form]="formReference.ageMax"></input-prop>
        </div>
        <div class="w-100">
          <select-prop [placeholder]="'analyteAtributo.resolveAtributeReference.type'" [form]="formReference.typeAgeMax"
            [options]="typeAtributeReference"></select-prop>
        </div>
      </div>
      <div class="flex w-100 gap-form mt-32">
        <div class="w-100">
          <select-prop [placeholder]="'analyteAtributo.resolveAtributeReference.gender'"
            [form]="formReference.genderType" [options]="genderType"></select-prop>
        </div>
        <div class="w-100">
          <input-prop [placeholder]="'analyteAtributo.resolveAtributeReference.valueMin'" type="number"
            [form]="formReference.valueMin"></input-prop>
        </div>
        <div class="w-100">
          <input-prop (keyup.enter)="saveAttributeReference()"
            [placeholder]="'analyteAtributo.resolveAtributeReference.valueMax'" type="number"
            [form]="formReference.valueMax"></input-prop>
        </div>
      </div>
      <div class="mt-32 flex flex-center">
        <app-button (click)="saveAttributeReference()" [placeholder]="'global.save'"
          [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalConditionalParameters let-conditionalParameter>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{conditionalParameter._hide_edit == '' ? ('analyteCondition.titleParametersNew' | translate) :
          ('analyteCondition.titleParametersEdit' | translate)}}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
        </svg>
      </div>
      <div class="flex w-100 gap-form mt-32 align-center">
        <div class="w-100">
          <input-prop [placeholder]="'analyteCondition.parameter'"
            [form]="formConditionalParameters.parameter"></input-prop>
        </div>
      </div>
      <div class="mt-32 flex flex-center">
        <app-button (click)="saveConditionalParameter(conditionalParameter)" [placeholder]="'global.save'"
          [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalParameters let-Parameter>
  <ng-container>
    <div class="flex flex-between">
      <h1>{{'analyteParameters.titleNew' | translate}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
      </svg>
    </div>
    <div class="w-100 mt-32">
      <select-prop [placeholder]="'analyteParameters.parameter'" [form]="formParameters.parameter"
        [options]="typeAnalyteParametersFilter"></select-prop>
    </div>
    <div class="mt-32 flex flex-center">
      <app-button (click)="newParameter()" [placeholder]="'global.save'"
        [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalAdditionalParameter>
  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1>{{'reports.titleNewReport' | translate}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
      </svg>
    </div>
    <div class="flex mt-32 gap-2">
      <input-search class="w-100" [placeholder]="'reports.newAtribute'"
        [form]="formAdditionalParameter.controls['newAtribute']" [options]="newAtributes"></input-search>
      <select-prop [placeholder]="'reports.column'" class='w-options'
        [form]="formAdditionalParameter.controls['column']" [options]="columnOoption"></select-prop>
      <select-prop [placeholder]="'reports.lineAfter'" class='w-options'
        [form]="formAdditionalParameter.controls['lineAfter']" [options]="lineAfterOption"></select-prop>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="includeAtributeList()" [placeholder]="'reports.btn'" [class]="'btn'"></app-button>
    </div>
    <h2>{{'reports.titleList' | translate}}</h2>
    <app-table [Itens]="atributeList" [noShadow]="true" [Actions]="['Excluir']"
      (parentFun)="actionsExamsAdditionalParameter($event, rowDetail)"></app-table>
    <div>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="creatAtributeReport()" [placeholder]="'global.save'" [class]="'btn'"></app-button>
    </div>
  </ng-container>
</ng-template>