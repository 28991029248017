import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { GetAllCustomers, GetByIdCustomers } from './customers.types';
import { Guid } from 'src/app/util/guid';

@Injectable({ 
    providedIn: 'root' 
})
export class CustomersService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/customer');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllCustomers>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetAllCustomers>>
  }

  override getById(id: Guid){
    return super.getById(id) as Promise<HttpResponsePageable<GetByIdCustomers>>
  }
  
}