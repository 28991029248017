<div class="contentPage">
  <div class="flex align-center flex-between headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
      <h1>{{'analyte.title' | translate}}</h1>
      <app-button (click)="newAnalyte()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="searchAnalytes()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus [placeholder]="'analyte.filter.code'" [form]="filterForm.code"></input-prop>
        <input-prop [placeholder]="'analyte.filter.name'" [form]="filterForm.name"></input-prop>
        <!-- <select-prop [options]="departmentOptions" [form]="filterForm.department" [placeholder]="'Pesquisar por Setor'"></select-prop> -->
        <checkbox-prop class="flex flex-center" [gap]="1" [label]="'analyte.filter.isOutsourced'" [form]="filterForm.isOutsourced"></checkbox-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" [label]="'analyte.filter.isActive'" [form]="filterForm.isActive"></checkbox-prop>
      </div>
      <app-button class="queryBtn" (click)="searchAnalytes()" [placeholder]="'analyte.filter.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [ClickInItem]="ActionsExtra" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="analytes" [Actions]="['Editar']" (parentFun)="actions($event)" (changePage)="get($event)"></app-table>
  </div>
</div>