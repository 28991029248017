import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { HttpService } from "../http.service";
import { HttpResponsePageable } from "../services.types";
import { ExternalAgreementResults } from "./results.types";

@Injectable({
   providedIn: "root"
})
export class ResultsService extends BaseService {
   constructor(httpService: HttpService) {
      super(httpService, "v1/lab/public/result");
   }

   override get(queryString: string) {
      return super.get(queryString) as Promise<
         HttpResponsePageable<ExternalAgreementResults>
      >;
   }
}
