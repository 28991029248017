<div class="contentPage">
  <div class="flex flex-between align-center gap-2 headerResponsive">
    <div class="flex gap-2 align-center titleResponsive">
      <h1>Relatório de Clientes</h1>
      <app-button (click)="generatePdf()" [placeholder]="'Gerar Relatório'" [matIcon]="'insert_drive_file'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="getCustomers()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <select-prop [form]="formGroup.controls['filter']" [placeholder]="'Selecione um filtro'" [options] = "filterOptions"></select-prop>
        <input-prop  #autoFocus *ngIf="formGroup.controls['filter'].value === 'Filtrar por Nome/Nome Social' && formGroup.controls['filter'].value != 'Filtrar por CPF'" [placeholder]="'Filtrar por Nome/Nome Social'" [form]="formGroup.controls['search']"></input-prop>
        <input-prop *ngIf="formGroup.controls['filter'].value === 'Filtrar por Data de Nascimento'" [placeholder]="'Digite aqui a Data de Nascimento'" [form]="formGroup.controls['search']" mask="00/00/0000"></input-prop>
        <input-prop *ngIf="formGroup.controls['filter'].value === 'Filtrar por CPF'" [placeholder]="'Digite aqui o CPF'" [form]="formGroup.controls['search']" mask="000.000.000-00"></input-prop>
        <input-prop *ngIf="formGroup.controls['filter'].value === 'Filtrar por Nome da Mãe'" [placeholder]="'Filtrar por Nome da Mãe'" [form]="formGroup.controls['search']"></input-prop>
        <select-prop [options]="isActiveOptions" [form]="formGroup.controls['isActive']" [placeholder]="'Situação'" class="w-100"></select-prop>  
      </div>
      <app-button class="queryBtn" (click)="getCustomers()" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="registerCustomers" (changePage)="getCustomers($event)"></app-table>
  </div>
</div>