import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { QueryParams } from "@app/modules_new/data/services/types/http.types";
import { UserAuthenticateFacade } from "@app/modules_new/external/data/userAuthenticate/userAuthenticate.facade";
import { AgreementSuppportFacade } from "@app/modules_new/registers/data/facades/agreement-support-facade";
import { CustomersFacade } from "@app/modules_new/registers/data/facades/customers-facade";
import { DoctorFacade } from "@app/modules_new/registers/data/facades/doctor.facade";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { InputSelectFormComponent } from "@app/modules_new/shared/components/form/input-select/base/input-select.component";
import { InputSelectMultplesFormComponent } from "@app/modules_new/shared/components/form/input-select/multiples/multiples.component";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ModalLayoutComponent } from "@app/modules_new/shared/components/form/modal/modal.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "userAuthenticate-modal",
   templateUrl: "./userAuthenticate-modal.component.html",
   standalone: true,
   imports: [
      ModalLayoutComponent,
      InputTextFormComponent,
      ReactiveFormsModule,
      ButtonFormComponent,
      InputSelectMultplesFormComponent,
      CommonModule,
      TranslateModule,
      InputSelectFormComponent
   ]
})
export class UserAuthenticateModal implements OnInit {
   constructor(private dialogRef: MatDialogRef<UserAuthenticateModal>) {}

   public _data = inject(MAT_DIALOG_DATA);

   private _userAuthenticateFacade = inject(UserAuthenticateFacade);
   private _customersFacade = inject(CustomersFacade);
   private _doctorFacade = inject(DoctorFacade);
   private _agreementSupportFacade = inject(AgreementSuppportFacade);

   type = [
      {
         value: 1,
         label: "Cliente"
      },
      // {
      //    value: 2,
      //    label: "Unidade ou Filial"
      // },
      {
         value: 3,
         label: "Médico Solicitante"
      },
      {
         value: 4,
         label: "Convênio"
      }
      // {
      //    value: 5,
      //    label: "Plano do Convênio"
      // },
      // {
      //    value: 6,
      //    label: "Destino de Entrega"
      // },
      // {
      //    value: 7,
      //    label: "Local de Coleta"
      // }
   ];

   formGroup = new FormGroup({
      type: new FormControl<number | null>(null),
      code: new FormControl<string | null>(null),
      name: new FormControl<string | null>(null),
      cellphone: new FormControl<string | null>(null),
      password: new FormControl<string | null>(null),
      confirmPassword: new FormControl<string | null>(null),
      externalAccessId: new FormControl<string | null>(null)
   });

   options: any = [];
   searchPlaceHolder = "";
   placeholder = "Novo Acesso";
   id = "";

   async ngOnInit() {
      if (this._data) {
         this.id = this._data.id;
         setTimeout(() => {
            this.placeholder = "Editar Acesso";
            this.formGroup.controls.type.setValue(this._data.type);
            this.options = [
               {
                  label: this._data.customerName,
                  value: this._data.customerId
               }
            ];
            this.formGroup.controls.code.setValue(this._data.accessCode);
            this.formGroup.controls.cellphone.setValue(this._data.cellPhone);
            this.formGroup.controls.name.setValue(this._data.name);
            setTimeout(() => {
               this.formGroup.controls.externalAccessId.setValue(
                  this._data.customerId
               );
            });
         });
      }
   }

   async save() {
      let access = {};

      access = {
         externalAccessId: this.formGroup.controls.externalAccessId.value,
         accessCode: this.formGroup.controls.code.value,
         cellphone: this.formGroup.controls.cellphone.value?.replace(/\s/g, ""),
         name: this.formGroup.controls.name.value,
         accessType: this.formGroup.controls.type.value,
         password: this.formGroup.controls.password.value,
         confirmPassword: this.formGroup.controls.confirmPassword.value
      };

      let success = false;

      if (this.id != "") {
         success = await this._userAuthenticateFacade.updateAccess(
            access,
            this.id
         );
      } else {
         success = await this._userAuthenticateFacade.createAccess(access);
      }

      if (success) {
         this.dialogRef.close({ success: true });
      }
   }

   async search(search: string) {
      const queryObject: QueryParams = {
         resume: true,
         name: search
      };
      switch (this.formGroup.controls.type.value) {
         case 1: //Cliente
            this.options = await this._customersFacade.getCustomersOptions(
               queryObject
            );
            break;
         case 2: //Unidade ou Filial
            this.searchPlaceHolder = "Pesquisa Unidade ou Filial";
            break;
         case 3: //Médico Solicitante
            this.options = await this._doctorFacade.getDoctorOptions(search);
            break;
         case 4: //Convênio
            this.options =
               await this._agreementSupportFacade.getFilterAgreement(
                  search,
                  false,
                  true
               );
            break;
         case 5: //Plano do Convênio
            this.searchPlaceHolder = "Pesquisa Plano do Convênio";
            break;
         case 6: //Destino de Entrega
            this.searchPlaceHolder = "Pesquisa Destino de Entrega";
            break;
         case 7: //Local de Coleta
            this.searchPlaceHolder = "Pesquisa Local de Coleta";
            break;
         default:
            break;
      }
   }

   changeType(search: any) {
      this.options = [];
      this.formGroup.controls.externalAccessId.reset();
      if (search) {
         switch (search.value) {
            case 1: //Cliente
               this.searchPlaceHolder = "Pesquisa Cliente";
               break;
            case 2: //Unidade ou Filial
               this.searchPlaceHolder = "Unidade ou Filial";
               break;
            case 3: //Médico Solicitante
               this.searchPlaceHolder = "Pesquisa Médico";
               break;
            case 4: //Convênio
               this.searchPlaceHolder = "Pesquisa Convênio";
               break;
            case 5: //Plano do Convênio
               this.searchPlaceHolder = "Pesquisa Plano do Convênio";
               break;
            case 6: //Destino de Entrega
               this.searchPlaceHolder = "Pesquisa Destino de Entrega";
               break;
            case 7: //Local de Coleta
               this.searchPlaceHolder = "Pesquisa Local de Coleta";
               break;
            default:
               break;
         }
      } else {
         this.searchPlaceHolder = "";
      }
   }
}
