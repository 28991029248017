<section class="dashboard">
    <app-header
        (changeMenuOpen)="changeMenuOpen($event)"
        *ngIf="!hideMenu"
    ></app-header>
    <div
        [ngClass]="menuopen ? 'act contents' : 'contents'"
        [ngStyle]="{
            height: hideMenu
                ? 'calc(100vh - calc(45px * var(--proportional)))'
                : 'calc(100vh - calc(105px * var(--proportional)))'
        }"
    >
        <ng-content select="router-outlet"></ng-content>
    </div>
    <a href="">
        © {{ getYear() }} - Concent Sistemas - Todos os direitos reservados
    </a>
</section>
