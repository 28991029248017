import {
   Component,
   EventEmitter,
   Injectable,
   Input,
   Output,
   TemplateRef,
   ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
   providedIn: "root"
})
@Component({
   selector: "app-dialog",
   templateUrl: "./dialog.component.html",
   styleUrls: ["./dialog.component.scss"]
})
export class DialogComponent {
   @Input() maleText: boolean = false;
   @Input() title: string = "";
   @Input() placeholderButton: string = "";
   @Input() matIconName: string | null = null;

   @Output() openModal: EventEmitter<any> = new EventEmitter<any>();
   @Output() afterClose: EventEmitter<any> = new EventEmitter<any>();

   @ViewChild("dialogModal") dialogModal!: TemplateRef<any>;

   constructor(private _dialog: MatDialog) {}

   openModalEmit() {
      this.openModal.emit(true);
   }

   openDialogModal(edit: boolean, emit?: unknown) {
      const myTempDialog = this._dialog.open(this.dialogModal, {
         width: "750px",
         data: { edit, emit }
      });

      myTempDialog.afterClosed().subscribe(() => {
         this.afterClose.emit(true);
      });
   }

   closeDialog() {
      this._dialog.closeAll();
   }
}
