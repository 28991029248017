import { BehaviorSubject, Observable } from 'rxjs';
import { DefaultFacade } from './default';

class ReactiveControl<T> {
  subject!: BehaviorSubject<T> | BehaviorSubject<T | null>;
  observable$!: Observable<T> | Observable<T | null>;
}

class ObservableControl<T> {
  subject!: BehaviorSubject<T>;
  observable$!: Observable<T>;
  constructor(initialValue: T) {
    this.subject = new BehaviorSubject<T>(initialValue);
    this.observable$ = this.subject.asObservable();
  }
}

class NullableObservableControl<T> {
  subject!: BehaviorSubject<T | null>;
  observable$!: Observable<T | null>;
  constructor() {
    this.subject = new BehaviorSubject<T | null>(null);
    this.observable$ = this.subject.asObservable();
  }
}

type ObservableControls<T> = { [K in keyof T]: ReactiveControl<any> };

/**
 * Classe para auxiliar em Facades que possuam valores "aninhados"
 *
 * @class DefaultFacade
 */
export abstract class MultiObservableFacade extends DefaultFacade {
  /**
   * Agrupador de Observables
   */
  private groupControls = new Map<string, ObservableControls<any>>();

  /**
   * Método responsável por inicializar agrupador de { subject, observable } para uso em Facades.
   * Objeto criado garantirá uso com type suggestions.
   *
   * @param definition @type {Object} para inicializar type suggestions
   * @returns @type {Object} que controla subjects e observables$
   */
  protected controlGroup<T>(definition: ObservableControls<T>) {
    return {
      get: (id: string) => {
        return this._getGroup<T>(id);
      },
      add: (id: string, newValues: ObservableControls<T>) => {
        return this._addGroup<T>(id, newValues);
      },
    };
  }

  private _getGroup<T>(id: string) {
    return this.groupControls.get(id) as ObservableControls<T>;
  }

  private _addGroup<T>(id: string, values: ObservableControls<T>) {
    this.groupControls.set(id, values);
  }

  protected emptyObservable() {
    return new ReactiveControl();
  }

  protected createNullableObservableControl<T>() {
    return new NullableObservableControl<T | null>();
  }

  protected createObservableControl<T>(initial: T) {
    return new ObservableControl<T>(initial);
  }
}
