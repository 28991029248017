import {
   ActionDefition,
   ColumnDefinition
} from "@app/modules_new/shared/components/form/table/table.model";

export const tableActions: ActionDefition[] = [
   {
      label: "default.edit",
      svg: "action-write",
      event: "EDITAR"
   },
   {
      label: "default.delete",
      svg: "action-trash",
      event: "DELETE"
   }
];

export const tableColumns: ColumnDefinition[] = [
   {
      label: "default.ip_released",
      key: "ipAddress"
   },
   {
      label: "default.description",
      key: "description"
   },
   {
      label: "default.createdAt",
      key: "createdAt",
      pipe: {
         name: "localeDate",
         args: ["short"]
      }
   },
   {
      label: "default.updatedAt",
      key: "updatedAt",
      pipe: {
         name: "localeDate",
         args: ["short"]
      }
   },
   {
      label: "departments.table-columns.isActive",
      key: "isActive",
      component: "checkbox"
   }
];
