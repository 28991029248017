<div class="contentPage">
    <div class="flex flex-between align-center">
      <div class="flex gap-2 align-center">
        <h1>Tipos de Receita Despesa</h1>
        <app-button (click)="openDialogModal()" [matIcon]="'add'" [placeholder]="'Novo'" [class]="'flex gap-2 align-center'"></app-button>
      </div>
      <app-filter class="filterResponsive" (beforeClose)="getTypesIncomeExpenses([1, paginationInfo.index])" [cleanButton]="true" (clearFilters)="resetFilter()">
        <div class="query">
          <input-prop #autoFocus [placeholder]="'Pesquisar por Descrição'" [form]="formGroupSearchFilter.controls['description']" (keydown.enter)="getTypesIncomeExpenses([1, paginationInfo.index])"></input-prop>
          <checkbox-prop class="flex flex-center" [gap]="1" label="Somente Ativos" [form]="formGroupSearchFilter.controls['IsActive']"></checkbox-prop>      
        </div>
        <app-button class="queryBtn mt-10" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'" (click)="getTypesIncomeExpenses([1, paginationInfo.index])"></app-button>
      </app-filter>
    </div>
    <div class="mt-32">
      <app-table 
        [Itens]="resolvedTypesIncomeExpense" 
        [Actions]="['Editar', 'Excluir']" 
        [ClickInItem]="ActionsExtra" 
        [backPagination]="true"
        [Pagination]="true"
        [sumRecords]="paginationInfo.sumRecords" 
        [indexNumber]="paginationInfo.index" 
        [pageNumber]="paginationInfo.page" 
        (changePage)="getTypesIncomeExpenses($event)" 
        (parentFun)="typeIncomeExpenseActions($event)">
      </app-table>
    </div>
  </div>
  
  <ng-template #dialogModal>
    <div class="flex flex-between w-100 align-center">
      <h1>{{formGroupIncomeExpense.controls['typeIncomeExpenseId'].value ? 'Editar' : 'Novo'}} Tipo de Receita/Despesa</h1>
      <svg class="close-svg" (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="flex gap-form mt-32">
      <div class="w-100">
        <input-prop [placeholder]="'Código Externo'" [form]="formGroupIncomeExpense.controls['externalId']"></input-prop>
      </div>
      <div class="w-100">
        <input-prop [placeholder]="'Descrição'" [form]="formGroupIncomeExpense.controls['description']"></input-prop>
      </div>
      <div class="w-100">
        <select-prop [placeholder]="'Tipo de Documento'" [options]="incomeExpenseTypesOptions" [form]="formGroupIncomeExpense.controls['type']"></select-prop>
      </div>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="formGroupIncomeExpense.controls['typeIncomeExpenseId'].value ? updateTypeIncomeExpense() : createTypeIncomeExpense()" [placeholder]="'Salvar'" [class]="'btn flex gap-2 align-center'"></app-button>
    </div>
  </ng-template>