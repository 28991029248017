import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { AgreementMessageService } from '@app/services/agreement-message/agreement-message.service';
import { AgreementMessagePostObject, AgreementMessagePutObject, MessageChildren } from '@app/services/agreement-message/agreement-message.types';
import { AgreementsService } from '@app/services/agreements/agreements.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Message, PutMessage, Actions } from './messages.model';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  messageId: string = '';
  responseId: string = '';

  idPatchMessages: string = ''

  total: number = 0;
  index: number = 50;
  page: number = 1;

  filterMessage = new UntypedFormControl(null);
  active = new UntypedFormControl(null);

  registerChat: Array<Object> = [];

  resolveMessageSand: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Mensagem",
      method: '',
      retrive: 'message',
      after: '',
      before: '',
    },
    {
      label: "Lido em",
      method: 'readDate',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "_hide_agreementId",
      method: '',
      retrive: 'agreementId',
      after: '',
      before: '',
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    },
  ];

  registerResponse: Array<Object> = [];

  resolveResponseSand: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Mensagem",
      method: '',
      retrive: 'message',
      after: '',
      before: '',
    },
    {
      label: "Lido em",
      method: (item: any) => this.readDate(item),
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "_hide_agreementId",
      method: '',
      retrive: 'agreementId',
      after: '',
      before: '',
    },
    {
      label: 'Criado em',
      retrive: '',
      method: (item: any) => this.getCreated(item),
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: (item: any) => this.getUpdated(item),
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: (item: any) => this.getActive(item),
      after: '',
      before: ''
    },
  ];

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  ActionsExtraMessages = {
    "Ativo": "PutActiveMessages"
  }

  transportResponse: Array<{ id: string, messageChildren: Array<MessageChildren> }> = [];

  messageSend = new UntypedFormControl(null);

  formOptions: Array<Object> = [];

  formSend = new UntypedFormControl(null);

  resolveResponseTable: Array<Array<object>> = [];

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _agreementMessageService: AgreementMessageService,
    private _localeDatePipe: LocalaDatePipe,
    private _agreement: AgreementsService,
    private _createdAt: LocalaDatePipe,
    private _updatedAt: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe
  ) { }

  @ViewChild('dialogModalMessage')
  dialogModalMessage!: TemplateRef<any>

  @ViewChild('dialogModalResponse')
  dialogModalResponse!: TemplateRef<any>

  async ngOnInit() {
    await this.getMessages();
    this.getOption();
    await this.get();
  }

  openDialogModalMessage(object?: object) {
    const myTempDialog = this._dialog.open(this.dialogModalMessage, {
      width: '660px',
      data: object
    });
    myTempDialog.afterClosed().subscribe(() => {
      this.messageId = '';
      this.resetForm();
    });
  }

  async getOption() {
    await this._agreement.getAll().then(
      (res) => {
        res.data.forEach(
          (obj) => {
            this.formOptions.push({
              label: obj.description,
              value: obj.id,
            });
          }
        )
      }).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );
  }

  async postMessage(object: any) {
    let postObject: AgreementMessagePostObject = {
      agreementId: object.value,
      message: this.formSend.value,
      typeMessage: 1,
      parentId: object ? object.id : null
    }
    await this._agreementMessageService.post(postObject).then(
      async (res) => {
        if (res.success) {
          setTimeout(() => {
            SwAlSetttings.Sucesso("Mensagem enviada com sucesso!");
          });
          this.formSend.setValue(null);
          this.close();
          this.getResponse(this.messageId);
          await this.getMessages();
          await this.get();
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  readDate(item: Message) {
    if (item.readDate) {
      return this._localeDatePipe.transform(item.readDate)
    } else {
      return '';
    }
  }

  getCreated(item: Message) {
    return this._createdAt.transform(item.createdAt);
  }

  getUpdated(item: Message) {
    return this._updatedAt.transform(item.updatedAt);
  }

  getActive(item: Message) {
    return this._activePipe.transform(item.isActive)
  }

  async putMessage(object: PutMessage) {
    let editMassage: AgreementMessagePutObject = {
      isActive: true,
      agreementId: object._hide_agreementId,
      message: this.formSend.value,
    }
    await this._agreementMessageService.put(editMassage, object.id).then(
      async (res) => {
        if (res.success) {
          setTimeout(() => {
            SwAlSetttings.Sucesso("Mensagem editada com sucesso!");
          });
          this.formSend.setValue(null);
          this.close();
          this.getResponse(this.messageId);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  putActive(id: string, value: string) {
    this._agreementMessageService.patchActive(id, value).then((x: any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.get();
    }).catch(err => SwAlSetttings.printError(err))
  }

  async actionsMessage(emit: Actions) {
    switch (emit.action) {
      case 'Responder':
        this.messageId = emit.object.id
        this.openDialogModalResponse(emit.object);
        break;
      case 'Editar':
        this.messageId = emit.object.id
        this.openDialogModalMessage(emit.object);
        this.formSend.setValue(emit.object.Mensagem)
        break;
      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
      case 'OpenDropdown':
        this.getResponse(emit.id)
        this.messageId = emit.id
        break;
    };
  };

  getResponse(responseId: string) {
    this._agreementMessageService.getById(new Guid(responseId)).then((res: any) => {

      this.resolveResponseTable[(responseId as any)] = []
      if (res.data[0].messagesChildren.length > 0) {
        this.resolveResponseTable[(responseId as any)] = this.resolve(res.data[0].messagesChildren, this.resolveResponseSand)
      }
    })
  }

  resolve(data: any, columns: any) {
    let list: Array<Object> = []
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.method(x);
        } else {
          obj[y.label] = x[y.retrive.toString()];
        }
      });
      list.push(obj);
    });
    return list
  }

  async getParentTable(messageId: string) {
    this.transportResponse = [];

    let messageChildren = await this.getAgreementMessageById(messageId);

    let messageObject = {
      id: messageId,
      messageChildren: messageChildren
    };

    this.transportResponse.push(messageObject);
    this.getMessageResponse(messageId);
  };

  getMessageResponse(id: string) {
    let messageContent = this.transportResponse.filter((x) => x.id === id);
    return this.resolveResponse(messageContent[0]?.messageChildren, this.resolveResponseSand);
  }

  async actionsResponse(emit: Actions, messageId?: string) {
    switch (emit.action) {
      case 'Visualizado':
        this.getAgreementMessageById(emit.object.id);
        break;
      case 'Editar':
        this.messageId = messageId ?? '';
        this.responseId = emit.object.id;
        this.openDialogModalResponse(emit.object);
        this.formSend.setValue(emit.object.Mensagem)
        break;
      case 'PutActiveMessages':
        this.putActiveMessages(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  putActiveMessages(id: string, value: string) {
    this._agreementMessageService.patchActive(id, value).then((x: any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.getResponse(this.messageId)
    }).catch(err => SwAlSetttings.printError(err))
  }

  async getAgreementMessageById(id: string) {
    let messagesChildren: Array<MessageChildren> = [];
    await this._agreementMessageService.getById(new Guid(id)).then(
      (res) => {
        res.data.forEach((x) => {
          messagesChildren = x.messagesChildren;
        });
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
    return messagesChildren;
  }

  async getAgreementVisualized(id: string, agreementId: string) {
    let obj = {
      agreementId: agreementId
    }
    await this._agreementMessageService.put(obj, undefined, `toread/${id}`).then(
      async (res) => {
        SwAlSetttings.Sucesso("Mensagem marcada como visualizada");
        await this.getMessages();
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  async getMessages() {
    await this._agreementMessageService.getAll().then(
      (res) => {
        this.resolveMessage(res.data, this.resolveMessageSand);
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  openDialogModalResponse(object: object) {
    const myTempDialog = this._dialog.open(this.dialogModalResponse, {
      width: '660px',
      data: object
    });
    myTempDialog.afterClosed().subscribe(() => {
      this.responseId = '';
      this.messageId = '';
      this.resetForm();
    });
  }

  close() {
    this._dialog.closeAll()
  }

  back() {
    this._router.navigateByUrl("/");
  }

  resolveMessage(data: any, columns: any) {
    this.registerChat = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerChat.push(obj);
    });
  }

  resolveResponse(data: any, columns: any) {
    let registerResponse: Array<Object> = [];
    if (data) {
      data.forEach((x: any) => {
        let obj: any = {};
        columns.forEach((y: any) => {
          if (!y.retrive) {
            //@ts-ignore
            obj[y.label] = y.before + this[y.method](x) + y.after;
          } else {
            obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
          }
        });
        registerResponse.push(obj);
      });
    }
    return registerResponse;
  }

  async get(paramPage?: number[]) {
    this.registerChat = [];
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }
    let filterObject = {
      page: this.page,
      index: this.index,
      isActive: this.active.value ? this.active.value : null,
      search: this.filterMessage.value ?? null,
    }
    await this._agreementMessageService.getPagination(filterObject).then(
      async (res: any) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolveMessage(res.data, this.resolveMessageSand);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resetForm() {
    this.messageSend.setValue(null)
    this.formSend.setValue(null)
  }

  resetFilter() {
    this.filterMessage.reset();
    this.active.reset();
  }
}