import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RegistrationReportComponent } from '@app/components/form/registration-report/registration-report.component';
import { ResolvedObject } from '@app/modules/registers/components/price-table/price-table.model';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { TenantAuthService } from '@app/services/auth/tenantAuth/tenant-auth.service';
import { PriceTableService } from '@app/services/pricetable/pricetable.service';
import { SwAlSetttings } from '@app/util/swal.settings';
import { HeaderMapping, ReportFilter } from '@app/modules/reports/reports-model';


@Component({
  selector: 'app-price-tables-report',
  templateUrl: './price-tables-report.component.html',
  styleUrls: ['./price-tables-report.component.scss']
})
export class PriceTablesReportComponent implements OnInit {

  headerMapping: HeaderMapping = {
    description: { title: 'Descrição', width: 'auto' },
    index: { title: 'Índice da tabela', width: '*' },
    mask: { title: 'Máscara da Tabela', width: 'auto' },
    createdAt: { title: 'Criado em', width: 'auto' },
    updatedAt: { title: 'Atualizado em', width: 'auto' },
    isActive: { title: 'Ativo', width: 'auto' }
  };

  isActiveOptions: Array<{ label: string, value: string | null | boolean }> = [{ label: 'Todos', value: 'todos' }, { label: 'Somente Ativos', value: true }, { label: 'Somente Inativos', value: false }]

  reportDescription: string = 'Relatório de Cadastro de Tabela de Preços';
  reportFilters: ReportFilter[][] = [];

  sumRecords!: number;

  total: number = 0;
  index: number = 50;
  page: number = 1;

  filter = {
    search: new UntypedFormControl(null),
    isActive: new UntypedFormControl('todos')
  }

  formPriceTable = {
    isActive: new UntypedFormControl(null),
    description: new UntypedFormControl(null),
    mask: new UntypedFormControl(null),
    index: new UntypedFormControl(1),
  }

  receivedData!: ResolvedObject[];
  receivedDataForPrint!: object[];

  registerTable: Array<Object> = [];

  reportObject: Array<Object> = [];

  resolveTable: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Descrição",
      method: '',
      retrive: 'description',
      after: '',
      before: '',
    },
    {
      label: "Índice da tabela",
      method: 'formatIndex',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "Máscara da Tabela",
      method: 'mask',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "_hide_mask",
      method: '',
      retrive: 'mask',
      after: '',
      before: '',
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: "Ativo",
      method: 'getActive',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "_hide_isActive",
      method: '',
      retrive: 'isActive',
      after: '',
      before: '',
    }
  ]; 

  constructor(
    private _priceTableService: PriceTableService,
    private _createdAt: LocalaDatePipe,
    private _updatedAt: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _localeDate: LocalaDatePipe,
    private _http: HttpClient,
    private _tenantAuth: TenantAuthService
  ) { }

  ngOnInit(): void {
    this.getPriceTables()
  }

  formatIndex(item: ResolvedObject) {
    return item.index.toFixed(4);
  }

  mask(item: ResolvedObject) {
    return item.mask ?  item.mask : '<span class="span-styles" style="background: #FFC000; color: #FFFFFF;">Sem Máscara</span>'
  }

  getCreated(item: ResolvedObject) {
    return this._createdAt.transform(item.createdAt);
  }

  getUpdated(item: ResolvedObject) {
    return this._updatedAt.transform(item.updatedAt);
  }

  getActive(item: ResolvedObject) {
    return item.isActive ? "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Sim</span>" : "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Não</span>";
  }

  async getPriceTables(paramPage?: number[]){
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let search = this.filter.search.value ?? null

    let isActive = this.filter.isActive.value === 'todos' ? null : JSON.parse(this.filter.isActive.value);

    await this._priceTableService.getPagination({page: this.page, index: this.index, search, isActive}).then(
      (res) => {
        this.total = res.sumRecords;
        this.resolvePriceTable(res.data, this.resolveTable);
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolvePriceTable(data: any, columns: any) {
    this.registerTable = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerTable.push(obj);
    });
  }

  async getDataForPrint() {
    this.reportObject = []
    const queryObject = {     
     search: this.filter.search.value ?? null,
     isActive: this.filter.isActive.value === 'todos' ? null : JSON.parse(this.filter.isActive.value)
    };

    await this._priceTableService.getPagination<ResolvedObject>(queryObject).then(
      (res) => {
        res.data.forEach((x) => {
          const obj = {
            description: x.description,
            index: x.index.toFixed(4),
            mask: x.mask ? x.mask : 'Sem Máscara',
            createdAt: x.createdAt,
            updatedAt: x.updatedAt,
            isActive: x.isActive ? 'Ativo': 'Inativo',
          }
          this.reportObject.push(obj)
        })
        this.sumRecords = res.sumRecords;
        this.receivedDataForPrint = this.reportObject;
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  async generatePdf() {
    await this.getDataForPrint();
    this.reportFilters = [];
    var filters = [];
    var filterActive = 'Todos';
    if (this.filter.isActive.value != 'todos'){
      if (this.filter.isActive.value == 'true'){
        filterActive = 'Somente Ativos';
      } else {
        filterActive = 'Somente Inativos';
      }
    }   

    var obj = {
      title: 'Ativos: ', content : filterActive
    }
    
    filters.push(obj);

    if (this.filter.search.value){
      var obj2 = {
        title: 'Pesquisa: ', content : this.filter.search.value
      }
      
      filters.push(obj2);
    }  

    this.reportFilters = [filters];

    const reportComponent = new RegistrationReportComponent(this._http, this._localeDate, this._tenantAuth);
    reportComponent.headerMapping = this.headerMapping;
    reportComponent.reportFilters = this.reportFilters;
    reportComponent.receivedData = this.receivedDataForPrint;
    reportComponent.reportDescription = this.reportDescription;
    reportComponent.sumRecords = this.sumRecords;

    await reportComponent.ngOnInit();
  }

  resetFilter(){
    this.filter.search.reset();
    this.filter.isActive.reset('todos')
  }

}
