<div class="contentPage">
  <div class="flex flex-between align-center headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
      <h1>Relatório de Metodologias</h1>
      <button (click)="generatePdf()" class="flex gap-2 align-center">
        <mat-icon> insert_drive_file</mat-icon>
        Gerar Relatório
      </button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="getMethodology()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus [placeholder]="'Pesquisar por Descrição'" [form]="filter.controls['name']"></input-prop>
        <select-prop [placeholder]="'Situação'" [form]="filter.controls['isActive']" [options]="isActiveOptions"></select-prop>
      </div>
      <button class="flex gap-2 align-center queryBtn" (click)="getMethodology()"><mat-icon>search</mat-icon>Pesquisar</button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [indexNumber]="index" [pageNumber]="page" [Itens]="methodology" (changePage)="getMethodology($event)"></app-table>
  </div>
</div>