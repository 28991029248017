import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.css']
})
export class ViewPdfComponent implements OnInit {

  //@ts-ignore
  docNome: string;
  //@ts-ignore
  tipoDoDocumento: string;
  linkDoPDF : any;
  @Input() printDocument = false;

  @Input() base64 : any;

  constructor(
    private _sanitizer: DomSanitizer,
    //@ts-ignore
    //@Inject(MAT_DIALOG_DATA) public data
  ) {

  }
//@ts-ignore
  convertToURL(base64, print = false) {
    fetch('data:application/pdf;base64,' + base64).then(
      async (res) => {
        const blob = await res.blob();
        const URLUnsafe = URL.createObjectURL(blob);
        let extra = '';
        if(!print) {
          extra = '#toolbar=0&navpanes=0&scrollbar=0';
        }
        this.linkDoPDF = this._sanitizer.bypassSecurityTrustResourceUrl(URLUnsafe + extra);
      }
    );
  }

  ngOnInit() {

    document.addEventListener('contextmenu', function (evt) {
      evt.preventDefault();
    }, false);
    document.addEventListener('keyup', function (e) {
      if (e.ctrlKey && e.keyCode === 80) {
        e.preventDefault();
      }
    });
    document.addEventListener('keydown', function (e) {
      if (e.ctrlKey && e.keyCode === 80) {
        e.preventDefault();
      }
    });

    this.convertToURL(this.base64, this.printDocument);
  }

  fechar() {
  }

  injectJS() {
     // @ts-ignore
    var sd = window.frames["pdfframe"].contentWindow.eval('document.addEventListener("contextmenu", function (e) {e.preventDefault();}, false)');
  }
}
