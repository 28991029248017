import {
  Component,
  Input,
  IterableDiffers,
  Output,
  EventEmitter,
  OnChanges, ViewChild, ElementRef, SimpleChanges, AfterViewInit, ChangeDetectionStrategy, NgZone
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, Subscription, bufferCount, bufferWhen, fromEvent, map, throttleTime } from "rxjs";
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';

@Component({
  selector: 'input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class InputSearchComponent implements OnChanges, AfterViewInit {

  @Input() type: string = 'text';
  @Input() readonly: boolean = false;
  @Input() form: UntypedFormControl = new UntypedFormControl({ value: null, disabled: this.readonly });
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() mask: any = '';
  @Input() className: string = '';
  @Input() requiredInput: string = '';
  @Input() tabindexcomponent: string = '';
  @Input() pattern: string = '';
  @Input() callChangeFormEmit: boolean = false;
  @Input() options: Array<any> = [];
  @Input() color: string = 'unset';
  @Input() hideSvg: boolean = false;
  @Input() onFocus: boolean = false;
  @Input() valueOption: String = '';
  @Input() searchApi: boolean = false;
  @Input() noLabelUp: boolean = false;
  @Output() changeForm = new EventEmitter<any>();
  @Output() valueChangeEmitter = new EventEmitter<any>();
  @Output() search = new EventEmitter<any>();
  quantityCharacters: number = 0;
  OptionSelect: number = -1;
  result: boolean = false;
  PropValue: String = '';
  classe: string = '';
  notSelect: boolean = true;
  border: boolean = false;
  differ: any;
  myControl = new UntypedFormControl(null);  
  searchString!: string | null;
  @ViewChild('search') searchElement!: ElementRef;
  @ViewChild('input') input!: ElementRef;
  @ViewChild('input', { read: MatAutocompleteTrigger })
  autoComplete!: MatAutocompleteTrigger;
  selectedOption = '';

  backspacePressSubscription!: Subscription;
  backspaceSubject = new Subject();

  @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete!: MatAutocompleteTrigger;

  constructor(
    private _differs: IterableDiffers,
    private _zone: NgZone,
  ) {
    this.differ = _differs.find([]).create(undefined);
  }

  ngOnInit(): void {
    this._zone.runOutsideAngular(() => window.addEventListener('scroll', this.scrollEvent, true));    
    this.classe = this.className + ' input-row';
  }

  ngOnChanges(changes: SimpleChanges): void {    
    if (changes.hasOwnProperty("options")) {
      if (this.valueOption == null || this.valueOption == '' || !this.valueOption) {
        if (this.options) {
          const ind = this.options.findIndex((x: any) => x.value == this.form.value);          
          if (this.options[ind] != undefined) {
            this.valueOption = this.options[ind].label;
          }
        }
      }
    }    
  }

  ngAfterViewInit() {

    this.subscribeToBackspaceSubject()

    fromEvent(this.input.nativeElement, 'focus').subscribe(() => {
      this.autocomplete.openPanel();
    });
    
    setTimeout(() => {   
      const ind = this.options ? this.options.findIndex((x: any) => x.value == this.form.value) : -1;      
      if (ind >= 0) {                        
        this.valueOption = this.options[ind];          
        //@ts-ignore
        this.myControl.setValue(this.valueOption.label);                
        setTimeout(() => {
          this.autoComplete.closePanel();
        }, 8);
      }      
      if (this.onFocus) {
        this.input.nativeElement.focus();
      }
    });

    this.form.valueChanges.subscribe((value: any) => {
      
      if (value == null) this.myControl.reset() 
      
      if (this.OptionSelect >= 0 || value || value === 0) {
        setTimeout(() => {
          const index = this.options ? this.options.findIndex((option) => option.value === this.form.value) : -1;
          if (index >= 0) {
            this.valueOption = this.options[index];            

            //@ts-ignore
            this.myControl.setValue(this.valueOption.label);
            if (this.options[0].value == value) {
              setTimeout(() => {
                this.autoComplete.closePanel();
              }, 8);
            } else {
              const selectedOption = this.options.filter((option) => option.value === value)[0];
              if (selectedOption != undefined && selectedOption.isDefaultMaterial) {
                setTimeout(() => {
                  this.autoComplete.closePanel();
                }, 8);
              }
            }            
          }
        });
      }     
    })

    if (this.options && this.options.length == 1) {
      this.form.setValue(this.options[0].value);
      this.changeForm.emit({ change: true });
    }    
  }

  ChangeVal() {
    this.valueOption = '';
  }

  focusInput() {
    if (this.input) this.input.nativeElement.focus()
  }

  validate(s: String) {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    return s.match(rgx);
  }

  onChange(value: String) {
    this.form.setValue(value ? value : '')
    this.changeForm.emit({ change: true })
  }

  SelectOption(label: String, model: { label: string, value: number }, index: number) {
    if (this.placeholder == 'Pesquise aqui o Material') {
      setTimeout(() => {
        this.border = false;
        this.OptionSelect = index;
        this.form.setValue(model.value);
        this.notSelect = false;
        if (index != 0 || !this.callChangeFormEmit) {
          this.changeForm.emit({ change: true });
        }
        this.getSelectedLabel();
      });
    } else {
      this.border = false;
      this.OptionSelect = index;
      this.form.setValue(model.value);
      this.notSelect = false;
      if (index != 0 || !this.callChangeFormEmit) {
        this.changeForm.emit({ change: true });
      }
      this.getSelectedLabel();
    }
  }

  getPosts(event: any) {
    const ind = this.options.findIndex((x: any) => x.value == event.option.value.value);    
    this.SelectOption(this.options[ind].label, this.options.filter((x: any) => x.value == event.option.value.value)[0], ind);
    this.getSelectedLabel();
  }

  displayFn(option?: any): string | undefined {
    return option ? option.label : '';
  }

  SearchIn(event: any) {
    this.border = false;
    this.notSelect = true;
    if (event.code != "Tab") this.form.setValue('');
    this.changeForm.emit({ change: true });
  }

  searchApiEmit(event: any) {
    if (this.searchString == undefined) {
      this.searchString = '';
    }
    if ((this.searchString != null || event.target.value) && this.selectedOption == '') {            
      this.search.emit({ value: event.target.value ? event.target.value : this.searchString })
      this.options = [];
    }
  }

  reset() {
    this.notSelect = true;
    this.valueOption = '';
    this.form.setValue('');
  }

  CheckValue() {
    if (this.form.value === '') {
      this.valueOption = '';
      this.border = true;
      this.changeForm.emit({ change: true });
    } else {
      this.OptionSelect = -1;
    }
  }

  getSelectedLabel() {
    return this.form.value !== undefined || this.form.value !== null ? this.options?.find(x => x.value === this.OptionSelect)?.label : ""
  }

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const valorDigitado = inputElement.value;

    if (this.options) {
      let ind = this.options.findIndex((x: any) => x.value.toUpperCase() == valorDigitado.toUpperCase());

      if (ind != -1) {
        this.form.setValue(this.options[ind].value);
        this.changeForm.emit({ change: true });
      }
    }
  }

  valueChange(event: Event) {
    this.selectedOption = '';
    //@ts-ignore
    if (!event.data) {
      this.form.setValue('')
    }

    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value == '') {
      this.valueChangeEmitter.emit({ data: inputElement.value });
    }
    this.searchString = inputElement.value;        
  }

  scrollEvent = (event: any): void => {
    if (this.autoComplete.panelOpen)
      this.autoComplete.updatePosition();
  }

  /**
  * Método com Observable para limpar o conteúdo do label caso o backspace seja pressionado 8 vezes em 1 segundo
  */
  subscribeToBackspaceSubject() {
    this.backspacePressSubscription = this.backspaceSubject
      .pipe(
        throttleTime(1000),
        bufferWhen(() => this.backspaceSubject.pipe(bufferCount(8))),
        map(events => events.length) 
      )
      .subscribe(time => { 
        if(time == 0) this.clearLabel() 
      });
  }

  clearLabel() {
    this.form.setValue('')
    this.valueOption = ''
  }

  backspacePressed() {
    this.backspaceSubject.next(true)
  }

  changeOption() {
    this.selectedOption = this.input.nativeElement.getAttribute('aria-activedescendant');    
  }

  clickOption(event:any){
    setTimeout(() => {
      if (this.myControl.value == '' && this.form.value == ''){
        this.form.setValue(this.options.filter((x: any) => x.label == event.srcElement.innerText)[0].value);
        const ind = this.options.findIndex((x: any) => x.label == event.srcElement.innerText);    
        this.SelectOption(this.options[ind].label, this.options.filter((x: any) => x.label == event.srcElement.innerText)[0], ind);
        this.getSelectedLabel();
        this.autoComplete.closePanel();
      }    
    }, 10);    
  }

  ngOnDestroy() {
    this.backspacePressSubscription.unsubscribe()
  }
}