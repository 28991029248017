import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CollectionPlaceInstructionService } from '@app/services/collection-place-instruction/collection-place-instruction.service';
import { Util } from '@app/util/util';
import { Actions, resolveCollectionInstruction } from './collection-place-instruction-list.model';
import { Guid } from '@app/util/guid';
import { ToastService } from '@app/services/translate/toast.service';

@Component({
  selector: 'app-collection-place-instruction-list',
  templateUrl: './collection-place-instruction-list.component.html',
  styleUrls: ['./collection-place-instruction-list.component.scss']
})
export class CollectionPlaceInstructionListComponent implements OnInit {

  total: number = 0;
  page: number = 1;
  index: number = 50;

  colectionInstruction : Array<object> = [];

  ActionsExtra = {
    'Ativo': 'PutActive',
  };

  collectionInstructionForm = new UntypedFormGroup({
    description: new UntypedFormControl(null),
  });

  formFilter = {
    Search: new UntypedFormControl(null),
    description: new UntypedFormControl(null),
    IsActive: new UntypedFormControl(null),
  }

  @ViewChild('dialogCollectionInstruction')
  dialogCollectionInstruction!: TemplateRef<any>

  constructor(
    private _dialog: MatDialog,
    private _collectionPlaceInstructionService: CollectionPlaceInstructionService,
    private _util: Util,
    private _toastService: ToastService
  ) { }

  ngOnInit() {
    this.getCollectionInstruction();
  }

  getCollectionInstruction(paramPage?: number[]){
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    const filterObjt = {
      page : this.page,
      index : this.index,
      Search: this.formFilter.description.value,
      IsActive : this.formFilter.IsActive.value ? this.formFilter.IsActive.value : null
    }

    this._collectionPlaceInstructionService.getPagination(filterObjt).then(
      (response) => {
        this.total = response.sumRecords
        this.resolve(response.data, resolveCollectionInstruction);
      }
    )
  }

  resolve(data: any, columns: any) {
    this.colectionInstruction = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.colectionInstruction.push(obj);
    });
  }

  openDialogCollectionInstruction(emit?: any){
    const myTempDialog = this._dialog.open(this.dialogCollectionInstruction, {
      width: '700px',
      data: emit
    });
    myTempDialog.afterClosed().subscribe(() => {
      this.collectionInstructionForm.reset();
    });
  }

  createCollectionInstruction(){
    const creatObj = {description: this.collectionInstructionForm.controls['description'].value}
    this._collectionPlaceInstructionService.post(creatObj).then(() => {
      this.closeModal();
      this.getCollectionInstruction();
      this._toastService.fireSuccess('collectionGuidance.msgPost');
    })
  }

  updateCollectionInstruction(id : string){
    const updateObj = {description: this.collectionInstructionForm.controls['description'].value}
    this._collectionPlaceInstructionService.put(updateObj, new Guid(id)).then((res) => {
      this.closeModal();
      this.getCollectionInstruction();
      this._toastService.fireSuccess('collectionGuidance.msgPut');
    })
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.collectionInstructionForm.controls['description'].setValue(emit.object['collectionGuidance.description']);
        this.openDialogCollectionInstruction(emit.object);
        break;
      case 'Excluir':
        this.deleteCollectionInstruction(emit.object.id);
        break;
      case 'PutActive':
        this.putActive(emit.object.id, emit.object.Ativo);
        break
    }
  }
  
  deleteCollectionInstruction(id : string){
    this._toastService.fireConfirmation('collectionGuidance.msgDelete').then(
      async (r) => {
        if (r.isConfirmed) {
          setTimeout(async () => {
            await this._collectionPlaceInstructionService.delete(new Guid(id));
            this._toastService.fireSuccess("collectionGuidance.msgDeleteSuccess");
            this.getCollectionInstruction();
          });
        }
      }
    )
  }

  putActive(id: string, value: string) {
    this._collectionPlaceInstructionService.patchActive(id, value).then(() => {
      this._toastService.fireSuccess('global.msgSituationSuccess');
    })
  }

  closeModal() {
    this._dialog.closeAll();
  }

  resetFilter(){
    this.formFilter.IsActive.reset();
    this.formFilter.Search.reset();
    this.formFilter.description.reset();
  }

}
