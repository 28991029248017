<modal-layout [title]="title">
    <form [formGroup]="formGroup" class="flex flex-col">
        <div class="flex gp-12">
            <forms-input-text
                class="mt-[24px]"
                [placeholder]="'departments.table-columns.externalId'"
                [form]="formGroup.controls.externalId"
            ></forms-input-text>
            <forms-input-text
                class="w-full mt-[24px]"
                [placeholder]="'departments.table-columns.department'"
                [form]="formGroup.controls.name"
            ></forms-input-text>
        </div>
        <forms-button
            #button
            class="self-center mt-[32px]"
            (click)="
                _dialogData.id ? updateDepartment() : createDepartment();
                $event.preventDefault()
            "
            classExtra="btn btn-primary"
            [text]="'customer.register.save' | translate"
        ></forms-button>
    </form>
</modal-layout>
