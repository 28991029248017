<div class="bg-white rounded-md">
    <div class="userDiv gap-4" *ngIf="userData">
        <div class="box typeUserDiv flex flex-col gap-4 items-baseline">
            <span class="text-lg font-bold">Dados de acesso</span>
            <span class="text-base font-bold"
                >E-mail/Código acesso: {{ userData.accessCode }}</span
            >
            <div class="flex flex-col">
                <p class="flex">
                    <span class="text-base font-bold">Usuário:</span>
                    <span [class]="userData.isActive ? 'active' : 'desactive'">
                        {{ userData.isActive ? "Ativo" : "Desativado" }}</span
                    >
                </p>

                <p
                    class="flex mt-2"
                    style="margin-left: 0"
                    *ngIf="!userData.userAccessActive"
                >
                    <span class="pill-warning"
                        >Necessário ativação do primeiro acesso</span
                    >
                </p>
            </div>
        </div>
        <div class="box w-full items-baseline flex flex-col">
            <span class="text-lg font-bold">Dados de cadastro</span>
            <div class="flex justify-between w-full">
                <div class="flex">
                    <div class="imgPerfil">
                        <svg
                            width="121"
                            height="120"
                            viewBox="0 0 121 120"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            *ngIf="!userData.fileImageId"
                        >
                            <path
                                d="M60.5 70.2316C72.4293 70.2316 82.1 60.5609 82.1 48.6316C82.1 36.7022 72.4293 27.0316 60.5 27.0316C48.5706 27.0316 38.9 36.7022 38.9 48.6316C38.9 60.5609 48.5706 70.2316 60.5 70.2316Z"
                                fill="#DDDDDD"
                            />
                            <path
                                d="M97.5105 0H23.4895C10.8579 0 0.5 10.3579 0.5 22.9895V97.0105C0.5 109.642 10.8579 120 23.4895 120H97.5105C110.142 120 120.5 109.642 120.5 97.0105V22.9895C120.5 10.3579 110.142 0 97.5105 0ZM109.258 97.0105C109.258 103.453 104.079 108.632 97.6368 108.632H96.6263C96.121 92.8421 90.5632 79.2 79.4474 72.1263C78.9421 71.7474 78.1842 71.8737 77.6789 72.2526C72.8789 75.7895 66.9421 77.9368 60.5 77.9368C54.0579 77.9368 48.1211 75.7895 43.3211 72.2526C42.8158 71.8737 42.0579 71.8737 41.5526 72.1263C30.5632 79.2 24.8789 92.8421 24.5 108.758H23.4895C17.0474 108.758 11.8684 103.579 11.8684 97.1368V22.9895C11.8684 16.5474 17.0474 11.3684 23.4895 11.3684H97.5105C103.953 11.3684 109.132 16.5474 109.132 22.9895V97.0105H109.258Z"
                                fill="#DDDDDD"
                            />
                        </svg>
                        <!-- <div
                            *ngIf="
                                rowDetail.extra.hasOwnProperty('imageBase64')
                            "
                        >
                            <img [src]="rowDetail.extra.imageBase64" />
                        </div> -->
                    </div>
                </div>
                <div class="flex justify-between w-full gap-4">
                    <div class="flex flex-col gap-4 mt-4 w-full">
                        <span class="text-base font-bold"
                            >CEP: {{ userData.address.zipCode }}</span
                        >
                        <span class="text-base font-bold"
                            >Endereço: {{ userData.address.street }}</span
                        >
                        <span class="text-base font-bold"
                            >Bairro: {{ userData.address.neighborhood }}</span
                        >
                        <span class="text-base font-bold"
                            >Cidade: {{ userData.address.city }}</span
                        >
                        <span class="text-base font-bold"
                            >Estado: {{ userData.address.state }}</span
                        >
                        <span class="text-base font-bold"
                            >Complemento:
                            {{ userData.address.complement }}</span
                        >
                    </div>
                    <div class="flex flex-col gap-4 mt-4 w-full">
                        <span class="text-base font-bold"
                            >Data de admissão:
                            {{
                                userData.admissionData
                                    | dynamicPipe
                                        : {
                                              name: "localeDate",
                                              args: ["full"]
                                          }
                            }}</span
                        >
                        <span class="text-base font-bold"
                            >Data de demissão:
                            {{
                                userData.resignationData
                                    | dynamicPipe
                                        : {
                                              name: "localeDate",
                                              args: ["full"]
                                          }
                            }}</span
                        >
                    </div>
                    <div class="flex flex-col gap-4 mt-4 w-full">
                        <span class="text-base font-bold"
                            >Celular: {{ userData.cellPhone }}</span
                        >
                        <span class="text-base font-bold"
                            >Telefone fixo: {{ userData.telephone }}</span
                        >
                        <span class="text-base font-bold"
                            >E-mail pessoal:
                            {{ userData.alternativeEmail }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
