import {
   Component,
   Input,
} from "@angular/core";
import { getSvg } from "./svg-selector-new.model";
import { CommonModule, NgStyle } from "@angular/common";
import { HtmlSanitizerPipe } from "../../../pipe/sanitizer-html.pipe";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
   selector: "ui-svg-selector-new",
   templateUrl: "./svg-selector-new.component.html",
   styleUrls: ["./svg-selector-new.component.scss"],
   standalone: true,
   imports: [CommonModule, HtmlSanitizerPipe, NgStyle, MatTooltipModule]
})
export class SvgSelectorUINewComponent {
   /**
    * Nome do SVG que será usado
    */
   @Input() svg: string = "empty";
   /**
    * Cor para preenchimento de SVG
    */
   @Input() fill = "#b4b4b4";
   /**
    * Cor para hover de SVG
    */
   @Input() hover = "";
   /**
    * Tooltip para o SVG
    */
   @Input() tooltip: string | null = null

   /**
    * Tamanho do SVG em px
    */
   @Input() sizePx: number = 32

   // @ViewChild("svgHtml", { static: false }) svgHtml!: ElementRef;
   // private _svgNativeElement!: HTMLElement;

   get selectedSvg() {
      return getSvg(this.svg, this.sizePx, this.fill)
   }
}
