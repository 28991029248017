<app-list [title]="'material.title'">
  <div class="headerButton">
    <app-dialog [title]="'material.description'" [placeholderButton]="'global.new'" [matIconName]="'add'" (afterClose)="afterClose()">
        <div class=" bodyFilter flex flex-column mt-32 gap-3 ">
          <div class="flex flex-between gap-2">
        <input-prop (keyup.enter)="editId? put() : post()" [placeholder]="'material.code'" [form]="form.externalId" type="number"></input-prop>
        <input-prop (keyup.enter)="editId? put() : post()" class="w-100" [placeholder]="'material.description'" [form]="form.materialName"></input-prop>
        </div>
      <div class="flex flex-column align-center">
        <app-button (click)="editId? put() : post()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
    </app-dialog>
  </div>
  <div class="headerFilter">
    <app-filter (beforeClose)="searchMaterials()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query flex flex-column gap-2 align-center">
        <input-prop #autoFocus type="text" [placeholder]="'material.description'" [form]="filterForm.description" ></input-prop>
        <input-prop type="number" [placeholder]="'material.code'" [form]="filterForm.externalId" ></input-prop>
        <checkbox-prop class="flex" [gap]="1" [label]="'material.isActive'" [form]="filterForm.active"></checkbox-prop>
        <app-button (click)="searchMaterials()" class="queryBtn" [placeholder]="'material.search'" [matIcon]="'search'" [class]="'btn flex gap-2 align-center'"></app-button>
      </div>
    </app-filter>
  </div>
  <div class="list">
    <app-table [Actions]="['Editar', 'Excluir']" [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true"  [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="materials" (changePage)="getMaterials($event)" (parentFun)="Actions($event)"></app-table>
  </div>
</app-list>

