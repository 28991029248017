<div class="contentPage">
  <div class="flex flex-between align-center gap-2 headerResponsive">
    <div class="flex gap-2 align-center titleResponsive">
      <h1>Relatório de Exames Laboratoriais</h1>
      <app-button (click)="generatePdf()" [placeholder]="'Gerar Relatório'" [matIcon]="'insert_drive_file'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="getData()" [cleanButton]="true" (clearFilters)="clearForms()">
      <div class="query">
        <input-prop #autoFocus [form]="filterForm.controls['code']" [placeholder]="'Código'" class="w-100"></input-prop>
        <input-prop  [form]="filterForm.controls['name']" [placeholder]="'Descrição'" class="w-100"></input-prop>
        <select-prop  [options]="isOutsourcedOptions" [form]="filterForm.controls['isOutsourced']" [placeholder]="'Exame Terceirizado'" class="w-100"></select-prop>
        <select-prop  [options]="sectorOptions" [form]="filterForm.controls['sectorId']" [placeholder]="'Setor'" class="w-100"></select-prop>
        <select-prop  [options]="isActiveOptions" [form]="filterForm.controls['isActive']" [placeholder]="'Situação'" class="w-100"></select-prop>  
      </div>
      <app-button (click)="getData()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [indexNumber]="index" [pageNumber]="page" [Itens]="resolvedAnalytes" (changePage)="getData($event)"></app-table>
  </div>
</div>