import { Injectable, inject } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import {
   HttpResponse,
   QueryParams,
   RequestOptions
} from "../../../data/types/http.types";
import { Guid } from "@app/util/guid";
import { MatDialog } from "@angular/material/dialog";
import { DepartmentAuthService } from "../services/department-auth/department-auth.service";
import {
   DepartmentResponse,
   DepartmentsResponse
} from "../services/department-auth/department-auth.types";
import { lastValueFrom } from "rxjs";

@Injectable({
   providedIn: "root"
})
export class DepartmentsFacade extends MultiObservableFacade {
   private _departmentService = inject(DepartmentAuthService);
   private _departmentResponse =
      this.createNullableObservableControl<DepartmentResponse[]>();
   private _departmentsAmount = this.createObservableControl<number>(0);
   private _departmentsPage = 1;
   private _departmentsNumberRegistry = 10;
   private _dialog = inject(MatDialog);

   async getDepartmentOptions() {
      var request: RequestOptions = {
         query: { resume: true, isActive: true }
      };

      try {
         const res: any = await lastValueFrom(
            this._departmentService.get(request)
         );
         if (res.data.length > 0) {
            const obj = res.data.map((res: any) => ({
               value: res.id,
               label: res.name
            }));
            return obj;
         } else {
            return [];
         }
      } catch (err) {
         console.error(err);
         return [];
      }
   }

   getDepartments(
      page: number = this._departmentsPage,
      numberRegistry: number = this._departmentsNumberRegistry,
      request?: RequestOptions
   ) {
      this._departmentsPage = page;
      this._departmentsNumberRegistry = numberRegistry;
      this._departmentResponse.subject.next(null);

      this._departmentService
         .getPagination<DepartmentsResponse>(page, numberRegistry, request)
         .subscribe({
            next: (response: DepartmentsResponse) => {
               this._departmentResponse.subject.next(response.data);
               this._departmentsAmount.subject.next(response.sumRecords);
            },
            error: (error) => {
               this.ToastErrorGeneric(error);
            }
         });

      return {
         data$: this._departmentResponse.observable$,
         total$: this._departmentsAmount.observable$
      };
   }

   createDepartment(createObj: any) {
      this._departmentService.post(createObj).subscribe({
         next: () => {
            this.getDepartments(1, 30);
            this.ToastSuccess("toast-msg.creation.success");
            this._dialog.closeAll();
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }

   updadeDepartment(updateObj: any, id: string) {
      this._departmentService.put(updateObj, new Guid(id)).subscribe({
         next: () => {
            this.getDepartments(1, 30);
            this.ToastSuccess("toast-msg.change.success");
            this._dialog.closeAll();
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }

   closeModal() {
      this._dialog.closeAll();
   }

   async patchActive(id: string, value: string) {
      let success = false;
      try {
         const response: any = await lastValueFrom(
            this._departmentService.patchToggleIsActive(id, !value)
         );
         if (response.success) {
            this.ToastSuccess(
               !value === true
                  ? "toast-msg.change-to-active.success"
                  : "toast-msg.change-to-inactive.success"
            );
            success = response.success;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      //@ts-ignore
      let data = this._departmentResponse.subject._value;
      let index = data.findIndex((x: any) => x.id == id);

      if (success) {
         data[index].isActive = !value;
      } else {
         data[index].isActive = value;
      }
      this._departmentResponse.subject.next(data);
      return success;
   }
}
