import {
   Component,
   Input,
   Renderer2,
   ViewChild,
   Output,
   EventEmitter,
   ContentChild,
   TemplateRef,
   HostListener,
   QueryList,
   NgZone
} from "@angular/core";

import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import SVGActions from "./SVGActions.json";
import { SlideInOutAnimation } from "src/app/animations";
import { LoadingService } from "@app/loading.service";
import { ActivatedRoute, Route, Router } from "@angular/router";

@Component({
   selector: "app-table",
   templateUrl: "./table.component.html",
   styleUrls: ["./table.component.scss"],
   animations: [SlideInOutAnimation]
})
export class AppTableComponent {
   @HostListener("document:click", ["$event"]) clickout(event: MouseEvent) {
      if (this.actClick != null) {
         this._zone.runOutsideAngular(() => (this.actClick = null));
      }
   }

   @ViewChild("toggleButton", { static: false }) toggleButton!: QueryList<any>;
   SVGActions: Object = SVGActions;

   isLoading: boolean = true;

   SVG: Array<SafeHtml> = [];
   constructor(
      private _renderer: Renderer2,
      private _sanitizer: DomSanitizer,
      private _loading: LoadingService,
      private _zone: NgZone,
      private _router: Router
   ) {
      this._loading.loadingSub.subscribe((res) => {
         if (res === false) {
            this.isLoading = res;
         }
      });
      this._router.url.includes("/Admin")
         ? (this.isAdminUrl = true)
         : (this.isAdminUrl = false);
      this.isAdminUrl ? "" : localStorage.removeItem("admin");
   }

   @Input() Itens: Array<any> = [];
   private Keys: Array<String> = [];
   private AscOrder: Object = {};
   public page: number = 1;
   @Input() NResultados: number = 30;
   public ArraySD: Array<string> = [];
   public isAdminUrl: boolean = false;
   @Input() Actions: Array<String> = [];
   @Input() Extras: Array<Object> = [];
   @Input() API: String = "";
   @Input() subMenu2: boolean = false;
   @Input() noShadow: boolean = false;
   @Input() Inativate: boolean = false;
   @Input() Search: String = "";
   @Input() ActiveOpacity: String = "";
   @Input() Dropdown: boolean = false;
   @Input() Single: boolean = false;
   @Input() checkBoxTrue: boolean = false;

   @Input() ContextId = null;

   @Input() ClickInItem = {};
   @Input() tooltipColumn: String = "";
   @Input() Route: String = "";
   @Input() Order: boolean = true;
   @Input() Padding: boolean = true;
   @Input() Svg: "search" | "error_outline" = "search";
   @Input() forceCloseMenu: boolean = false;
   @Input() RemoveThreeDots: boolean = false;
   @Input() Pagination = true;

   //#region Input / Output - BackEnd Pagination
   @Input() backPagination: boolean = false;
   @Input() sumRecords: number = 0;
   @Input() pageNumber: number = 0;
   @Input() indexNumber: number = 0;
   @Output() changePage: EventEmitter<any> = new EventEmitter();
   //#endregion

   public sortSelected: number = -1; // controla o item que foi ordenado
   @Input() SortPagination: boolean = false; // Caso queira deixar o filtro opcional
   @Output() sortPage: EventEmitter<any> = new EventEmitter();
   AscSortOrder: boolean = false;
   actClick: Number | null = null;

   @Output() parentFun: EventEmitter<any> = new EventEmitter();

   @ContentChild("header", { static: false }) headerTemplateRef:
      | TemplateRef<any>
      | undefined;
   @ContentChild("body", { static: false }) bodyTemplateRef:
      | TemplateRef<any>
      | undefined;

   Test() {
      this.actClick = null;
   }

   Expand(event: any, i: string, object: any) {
      var selection = window.getSelection();
      if (event.view.getSelection().toString().length == 0) {
         if (this.ArraySD.includes(i)) {
            const index = this.ArraySD.indexOf(i);
            if (index > -1) {
               this.ArraySD.splice(index, 1);
            }
         } else {
            this.ArraySD.push(i);
            this.parentFun.emit({
               action: "OpenDropdown",
               id: i,
               object: object
            });
         }
      }
   }

   trackByFn(item: any): any {
      return item.id;
   }

   RetriveKeys() {
      if (this.Itens.length > 0) {
         this.Keys = [];
         Object.keys(this.Itens[0]).forEach((x) => this.Keys.push(x));
         return this.Keys;
      }
      return [];
   }

   toggleClick(i: Number) {
      this.actClick = this.actClick == i ? null : i;
   }

   ReturnId(i: any) {
      return this.Itens[i].id;
   }

   Sort(item: any, index: number) {
      this.SortPagination
         ? this.SortRequest(item, index)
         : this.SortValues(item, index);
   }

   SortValues(item: any, index: number) {
      this.sortSelected = index;
      // Código que ficava em Sort()

      const ne = (str: any) =>
         str.replace(/\d+/g, (n: any) => n.padStart(8, "0"));
      this.page = 1;
      //@ts-ignore
      if (!this.AscOrder.hasOwnProperty(item)) {
         //@ts-ignore
         this.AscOrder[item] = true;
      }
      //@ts-ignore
      if (this.AscOrder[item]) {
         //@ts-ignore
         this.AscOrder[item] = false;
         this.Itens.sort((a, b) => {
            return a[item].localeCompare(b[item], undefined, {
               numeric: true,
               sensitivity: "base"
            });
         });
      } else {
         //@ts-ignore
         this.AscOrder[item] = true;
         this.Itens.sort((a, b) => {
            return b[item].localeCompare(a[item], undefined, {
               numeric: true,
               sensitivity: "base"
            });
         });
      }
   }

   SortRequest(item: any, index: number) {
      if (this.sortSelected != index) {
         this.sortSelected = index;
         this.AscSortOrder = true;
      }
      this.sortPage.emit({
         label: item,
         index,
         order: this.AscSortOrder ? "asc" : "desc"
      });
      this.AscSortOrder = !this.AscSortOrder;
   }

   //#region BackEnd Pagination Controls
   BackPages() {
      return Math.ceil(this.sumRecords / this.indexNumber);
   }

   Paginas(pageNumber?: number) {
      let current: number = this.pageNumber;
      if (pageNumber) {
         current = pageNumber;
      } else {
         current = this.pageNumber;
      }
      const range = 5;
      const offset = Math.ceil(range / 2);
      const total = this.BackPages();

      const pagesArray = [];
      for (let i = 1; i <= total; i++) {
         pagesArray.push(i);
      }
      pagesArray.splice(0, current - offset);
      pagesArray.splice(range, total);

      return pagesArray;
   }

   BackFirstPage() {
      this.pageNumber = 1;
      let firstPage: number[] = [this.pageNumber, this.indexNumber];
      this.changePage.emit(firstPage);
   }

   BackLastPage() {
      if (this.pageNumber > 1) {
         this.pageNumber--;

         let previousPage: number[] = [this.pageNumber, this.indexNumber];
         this.changePage.emit(previousPage);
      }
   }

   BackNextPage() {
      if (this.pageNumber < [].constructor(this.BackPages()).length) {
         this.pageNumber++;

         let nextPage: number[] = [this.pageNumber, this.indexNumber];
         this.changePage.emit(nextPage);
      }
   }

   BackEndPage() {
      this.pageNumber = [].constructor(this.BackPages()).length;
      let lastPage: number[] = [this.pageNumber, this.indexNumber];
      this.changePage.emit(lastPage);
   }

   BackGotoPage(pageNumber: number) {
      let gotoPage: number[] = [pageNumber, this.indexNumber];
      this.changePage.emit(gotoPage);
   }

   //#endregion

   //#region FrontEnd Pagination Controls

   // @ts-ignore
   Pages() {
      return Math.ceil(this.Itens.length / this.NResultados);
   }

   FirstPage() {
      this.page = 1;
   }

   LastPage() {
      if (this.page > 1) {
         this.page--;
      }
   }
   NextPage() {
      if (this.page < [].constructor(this.Pages()).length) {
         this.page++;
      }
   }

   EndPage() {
      this.page = [].constructor(this.Pages()).length;
   }
   //#endregion

   // @ts-ignore
   Determine(action: String, item: Object): boolean {
      if (this.Inativate) {
         // @ts-ignore
         if (action.toString() == "Ativar" && item.extra.active == true) {
            return false;
         }
         // @ts-ignore
         if (action.toString() == "Desativar" && !item.extra.active) {
            return false;
         }
      }

      if (
         action.toString() != "Revisar" &&
         action.toString() != "Aprovar" &&
         action.toString() != "Retornar" &&
         action.toString() != "Editar"
      ) {
         return true;
      } else {
         // @ts-ignore

         if (action.toString() == "Retornar") {
            if (item.hasOwnProperty("Situação")) {
               //@ts-ignore
               if (item.extra.hasOwnProperty("personResponsibleName")) {
                  // @ts-ignore
                  if (item.extra.personResponsibleName == null) {
                     return false;
                  }
               }
               //@ts-ignore
               if (!item["Situação"].includes("elaboracao")) return true;
            }
         }

         if (action.toString() == "Revisar") {
            if (item.hasOwnProperty("Situação")) {
               //@ts-ignore
               if (item.extra.hasOwnProperty("personResponsibleName")) {
                  // @ts-ignore
                  if (item.extra.personResponsibleName == null) {
                     return false;
                  }
               }
               if (item.hasOwnProperty("extra")) {
                  // @ts-ignore
                  if (item.extra.hasOwnProperty("personResponsibleName")) {
                     // @ts-ignore
                     if (item.extra.personResponsibleName != null) {
                        //@ts-ignore
                        if (item["Situação"].includes("revisao")) return true;
                     }
                  } else {
                     return true;
                  }
               } else {
                  return true;
               }
            }
         }

         if (action.toString() == "Aprovar") {
            if (item.hasOwnProperty("Situação")) {
               //@ts-ignore
               if (item.extra.hasOwnProperty("personResponsibleName")) {
                  // @ts-ignore
                  if (item.extra.personResponsibleName == null) {
                     return false;
                  }
               }
               //@ts-ignore
               if (item["Situação"].includes("aprovacao")) return true;
            }
         }

         if (action.toString() == "Editar") {
            if (item.hasOwnProperty("extra")) {
               // @ts-ignore
               if (item.extra.hasOwnProperty("personResponsibleName")) {
                  // @ts-ignore
                  if (item.extra.personResponsibleName != null) {
                     // @ts-ignore
                     return true;
                  } else {
                     return false;
                  }
               } else {
                  return true;
               }
               //@ts-ignore
            } else {
               return true;
            }
         }

         return false;
      }
   }

   Emitter(action: any, item: any) {
      if (this.forceCloseMenu) {
         this.actClick = null;
      }
      this.parentFun.emit({
         action: action,
         object: item,
         context: this.ContextId ? this.ContextId : null
      });
   }
}
