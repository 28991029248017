import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { HttpService } from '@app/services/http.service';
import { HttpClient } from '@angular/common/http';
import { HttpResponsePageable } from '@app/services/services.types';
import { AnalytePackageExams, AnalytePackageExamsPost } from './analyte-package-exams';
import { Guid } from '@app/util/guid';

@Injectable({
  providedIn: 'root'
})
export class AnalytePackageExamsService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/analytepackageexams');
  }
  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<AnalytePackageExams>>
  }
  override post(body: Object) {
    return super.post(body) as Promise<HttpResponsePageable<AnalytePackageExamsPost>>
  }

  override delete(id?: Guid | undefined, path?: string | undefined){
    return super.delete(id) as Promise<any>
  }
}
