<app-list [title]="'recipients.title'">
  <div class="headerButton">
    <app-dialog [title]="'recipients.titleModal'" [placeholderButton]="'global.new'" [matIconName]="'add'" (afterClose)="afterClose()">
      <div class="bodyFilter flex flex-column mt-32 gap-3">
        <div class="flex flex-between gap-2">
          <input-prop (keyup.enter)="editId? put() : post()" [placeholder]="'recipients.externalId'" [form]="form.externalId" type="number" ></input-prop>
          <input-prop (keyup.enter)="editId? put() : post()" [class]="'w-100'" [placeholder]="'recipients.description'" [form]="form.recipientName"></input-prop>
        </div>
        <div class="flex flex-column gap-3">
          <select-prop [placeholder]= "'recipients.getType'" [form]="form.typeCollect" [options]="getType"></select-prop>
        </div>
        <div class="flex flex-center">
          <app-button (click)="editId? put() : post()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
        </div>
      </div>
    </app-dialog>
  </div>
  <div class="headerFilter">
    <app-filter (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus [placeholder]="'recipients.description'" [form]="filterForm.description"></input-prop>
        <input-prop [placeholder]="'recipients.externalId'" [form]="filterForm.externalId"></input-prop>
        <select-prop [placeholder]="'recipients.getType'" [form]="filterForm.typeRecipient" [options]="getType"></select-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" [label]="'recipients.isActive'" [form]="form.isActive"></checkbox-prop>
      </div>
        <app-button (click)="get()" class="queryBtn" [placeholder]="'recipients.search'" [matIcon]="'search'" [class]="'btn flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="list">
    <app-table [Actions]="['Editar', 'Excluir']" [ClickInItem]="ActionsExtra" (sortPage)="getSortedPage($event)" [Pagination]="true" [backPagination]="true"  [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="recipients" (parentFun)="Actions($event)" ></app-table>
  </div>
</app-list>
