import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { EnvioSmsEmailComponent } from "./components/sms-email-logging/sms-email.component";
import { SmsEmailParameters } from "./components/sms-email-parameters/sms-email-parameters";

const routes: Routes = [
    {
      path: 'Consultas',
      children: [
          { path: '', redirectTo: 'Consultas', pathMatch: 'full' },
          { path: 'EmailSMS', component: EnvioSmsEmailComponent },
          { path: 'EmailSMS/Parameters', component: SmsEmailParameters}
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class QueriesRoutingModule {

}