import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})

export class BudgetAnalyteService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/laboratory/attendance/budgetrequestanalyte');
  }
}

  
