<mat-select [formControl]="form" (change)="onChange(form.value)" [ngClass]="readonlySelect ? 'readonly' : ''" [tabindex]="tabindexcomponent">
  <mat-select-trigger>
    <div class="select-wrapper">
      <div class="img-wrapper">
        <img *ngIf="personImages[form.value] != ''; else elseTemplate" [src]="'data:image/png;base64,' + personImages[form.value] ">
        
        <svg width="400" height="110">
          <rect width="300" height="100" style="fill:rgb(0,0,255);stroke-width:3;stroke:rgb(0,0,0)" />
        </svg>
        
      </div>
      <div>
        {{getLabel(form.value)}}
      </div>
    </div>

  </mat-select-trigger>

  <mat-option [value]="null" [disabled]="!nullable" *ngIf="placeholder">
    {{placeholder}}
  </mat-option>
  <ng-container *ngFor="let option of personOptions">
    <mat-option [value]="option.value">
      <div class="option-wrapper">
        <div class="img-wrapper">
          <img *ngIf="personImages[option.value]; else elseTemplate" [src]="'data:image/png;base64,' + personImages[option.value] ">
          <div style="background-color: bisque;justify-content: center;width: 30px;height: 30px;" *ngIf="!personImages[option.value];" ><div class="org" style="padding: 6px;">{{option.label.charAt(0)}}</div></div>
        </div>
        {{option.label}}
      </div>
    </mat-option>
  </ng-container>
</mat-select>

<span class="error">
  <span *ngIf="showError('required')">Esse campo é obrigatório</span>
</span>

<ng-template #elseTemplate>
  <svg width="30" height="30">
    <rect width="30" height="30" style="fill:rgb(231, 231, 231);stroke-width:3;" />
  </svg>  
</ng-template>