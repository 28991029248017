import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { HttpResponsePageable } from '../services.types';
import { Guid } from '@app/util/guid';
import { UnityParametersGetAll, UnityParametersGetById, UnityParametersPost, UnityParametersPostObject, UnityParametersPut } from './unity-parameters.types';

@Injectable({
  providedIn: 'root'
})
export class UnityParametersService extends BaseService {

  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/parametersunity');
  }
  
  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<UnityParametersGetAll>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<UnityParametersGetById>>
  }

  override getById(id: Guid) {
    return super.getById(id) as Promise<HttpResponsePageable<UnityParametersGetById>>
  }

  override post(body: UnityParametersPostObject) {
    return super.post(body) as Promise<HttpResponsePageable<UnityParametersPost>>
  }

  override put(body: object, id?: Guid | undefined, path?: string | undefined) {
    return super.put(body, id, path) as Promise<HttpResponsePageable<UnityParametersPut>>    
  }
}
