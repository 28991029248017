<div [ngClass]="menuopen ? 'menu act' : 'menu'">
    <ng-content select=".padding"></ng-content>
    <div class="padd">
        <div class="search">
            <input-prop
                [placeholderMenu]="true"
                [placeholder]="'menu.search'"
                [form]="searchMenu"
                (keyup.enter)="routeNavigate()"
            ></input-prop>
            <div class="searchSVG">
                <svg
                    (click)="routeNavigate()"
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.6732 9.51212C12.5196 8.25295 12.8669 6.72374 12.6474 5.22249C12.4279 3.72124 11.6574 2.35548 10.4859 1.39134C9.31439 0.427204 7.82591 -0.0662417 6.31047 0.00715382C4.79503 0.0805493 3.3612 0.715528 2.28836 1.78836C1.21553 2.8612 0.580549 4.29503 0.507154 5.81047C0.433758 7.32591 0.927204 8.81439 1.89134 9.98588C2.85548 11.1574 4.22124 11.9279 5.72249 12.1474C7.22374 12.3669 8.75295 12.0196 10.0121 11.1732L13.5253 14.6849C13.7479 14.8923 14.0423 15.0052 14.3465 14.9998C14.6507 14.9945 14.941 14.8712 15.1561 14.6561C15.3712 14.441 15.4945 14.1507 15.4998 13.8465C15.5052 13.5423 15.3923 13.2479 15.1849 13.0253L11.6732 9.51212ZM6.60538 9.86282C5.86223 9.86282 5.13577 9.64245 4.51786 9.22957C3.89996 8.8167 3.41836 8.22987 3.13397 7.54329C2.84957 6.85671 2.77516 6.10121 2.92015 5.37234C3.06513 4.64347 3.42299 3.97396 3.94847 3.44847C4.47396 2.92299 5.14347 2.56513 5.87234 2.42015C6.60121 2.27516 7.35671 2.34957 8.04329 2.63397C8.72987 2.91836 9.3167 3.39996 9.72957 4.01786C10.1424 4.63577 10.3628 5.36223 10.3628 6.10538C10.3628 7.10192 9.96694 8.05763 9.26229 8.76229C8.55763 9.46694 7.60192 9.86282 6.60538 9.86282Z"
                        fill="#808080"
                    />
                </svg>
            </div>
        </div>
    </div>
    <ul>
        <div *ngFor="let item of filtredMenu; let i = index">
            <li>
                <a
                    class="flex align-center flex-between"
                    [routerLink]="item.href"
                    (click)="
                        item.href != undefined
                            ? closeMenu()
                            : toggleShowDiv(i, item.name)
                    "
                    [ngClass]="MenuActive.indexOf(i) != -1 ? 'act' : ''"
                >
                    <div class="flex align-center">
                        <div [innerHTML]="newSteps[i]" class="mr"></div>
                        {{ item.name | translate }}
                    </div>
                    <svg
                        *ngIf="item.href == undefined"
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="drop"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.658 0.810874C18.2105 0.388476 17.4945 0.397731 17.0588 0.831545L9.5 8.35695L1.94125 0.831546C1.50551 0.397732 0.789495 0.388477 0.341981 0.810875C-0.105532 1.23327 -0.115078 1.92737 0.320657 2.36118L9.5 11.5L18.6793 2.36118C19.1151 1.92737 19.1055 1.23327 18.658 0.810874Z"
                            fill="#FFB999"
                        />
                    </svg>
                </a>
                <ul [@slideInOut]="MenuActive.indexOf(i) != -1 ? 'in' : 'out'">
                    <li *ngFor="let subMenu of item.subMenu; let j = index">
                        <a
                            *ngIf="subMenu.href"
                            [routerLink]="subMenu.href"
                            routerLinkActive="router-link-active"
                            (click)="closeMenu()"
                            style="display: flex"
                        >
                            <svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.74408 1.07739C8.06951 0.751954 8.59715 0.751954 8.92259 1.07739L14.7559 6.91072C15.0814 7.23616 15.0814 7.7638 14.7559 8.08923L8.92259 13.9226C8.59715 14.248 8.06951 14.248 7.74408 13.9226C7.41864 13.5971 7.41864 13.0695 7.74408 12.7441L12.1548 8.33331H0.833333C0.373096 8.33331 0 7.96022 0 7.49998C0 7.03974 0.373096 6.66665 0.833333 6.66665H12.1548L7.74408 2.2559C7.41864 1.93047 7.41864 1.40283 7.74408 1.07739Z"
                                    fill="#FFB999"
                                />
                            </svg>
                            {{ subMenu.name | translate }}
                        </a>
                        <a
                            *ngIf="subMenu.subMenu"
                            style="
                                display: flex !important;
                                align-items: center;
                                justify-content: space-between;
                            "
                            (click)="toggleShowSubDiv(j)"
                            [ngClass]="
                                SubMenuActive.indexOf(j) != -1 ? 'act' : ''
                            "
                        >
                            <div
                                style="
                                    display: flex !important;
                                    align-items: center;
                                    justify-content: space-between;
                                "
                            >
                                {{ subMenu.name | translate }}
                            </div>
                            <svg
                                width="19"
                                height="12"
                                viewBox="0 0 19 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="drop"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M18.658 0.810874C18.2105 0.388476 17.4945 0.397731 17.0588 0.831545L9.5 8.35695L1.94125 0.831546C1.50551 0.397732 0.789495 0.388477 0.341981 0.810875C-0.105532 1.23327 -0.115078 1.92737 0.320657 2.36118L9.5 11.5L18.6793 2.36118C19.1151 1.92737 19.1055 1.23327 18.658 0.810874Z"
                                    fill="#FFB999"
                                />
                            </svg>
                        </a>
                        <ul
                            *ngIf="subMenu.subMenu"
                            [@slideInOut]="
                                SubMenuActive.indexOf(j) != -1 ? 'in' : 'out'
                            "
                        >
                            <li *ngFor="let secondLevel of subMenu.subMenu">
                                <a
                                    routerLinkActive="router-link-active"
                                    [routerLink]="secondLevel.href"
                                    (click)="closeMenu()"
                                    style="display: flex"
                                >
                                    <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.74408 1.07739C8.06951 0.751954 8.59715 0.751954 8.92259 1.07739L14.7559 6.91072C15.0814 7.23616 15.0814 7.7638 14.7559 8.08923L8.92259 13.9226C8.59715 14.248 8.06951 14.248 7.74408 13.9226C7.41864 13.5971 7.41864 13.0695 7.74408 12.7441L12.1548 8.33331H0.833333C0.373096 8.33331 0 7.96022 0 7.49998C0 7.03974 0.373096 6.66665 0.833333 6.66665H12.1548L7.74408 2.2559C7.41864 1.93047 7.41864 1.40283 7.74408 1.07739Z"
                                            fill="#FFB999"
                                        />
                                    </svg>
                                    {{ secondLevel.name | translate }}
                                </a>
                                <!-- <a *ngIf="secondLevel.subMenu" style="display:flex !important; align-items: center; justify-content: space-between;" (click)="toggleShowSubDiv(j)" [ngClass]="SubMenuActive.indexOf(j) != -1 ? 'act' : ''">
                  <div style="display: flex !important; align-items: center; justify-content: space-between;">
                    {{ secondLevel.name }}
                  </div>
                  <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="drop"> <path fill-rule="evenodd" clip-rule="evenodd" d="M18.658 0.810874C18.2105 0.388476 17.4945 0.397731 17.0588 0.831545L9.5 8.35695L1.94125 0.831546C1.50551 0.397732 0.789495 0.388477 0.341981 0.810875C-0.105532 1.23327 -0.115078 1.92737 0.320657 2.36118L9.5 11.5L18.6793 2.36118C19.1151 1.92737 19.1055 1.23327 18.658 0.810874Z" fill="#FFB999"/></svg>
                </a> -->
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </div>
    </ul>
</div>
