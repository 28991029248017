import { FileDocumentService } from "../services/files/fileDocument.service";
import { DocumentService } from "../services/documents/document.service";
import { saveAs } from 'file-saver';
import { Injectable } from "@angular/core";
import { FileDocumentAuthService } from "../services/auth/fileDocument-auth.service";
import { ProfileUserAuth } from "../services/auth/profile-user-auth.service";
import { RefreshService } from "../services/user/refresh.service";

@Injectable({
    providedIn: 'root'
})
export class GActions {

    constructor(private _fileDocumentService: FileDocumentService, private _documentService: DocumentService, public _RefreshAuth: RefreshService, private _fileDocumentServiceAuth: FileDocumentAuthService) {
    }

    public async downloadFile(docId: any) {
        let doc = await this._documentService.getById(docId);
        //@ts-ignore
        let id = doc.data[0].fileDocumentId;
        let downFile = await this._fileDocumentService.getById(id);
        // @ts-ignore
        await this.saveFile(downFile.data[0])
        return downFile;
    }

    public async downloadRecorFile(fileDocumentId: string, name: string) {
        //@ts-ignore
        let id = fileDocumentId;
        // @ts-ignore
        let downFile = await this._fileDocumentService.getById(id);
        // @ts-ignore
        await this.saveFile(downFile.data[0])
        return downFile;
    }

    public async ViewFileDocument(docId: any) {
        let doc = await this._documentService.getByPath('action=1', docId);
        return doc;
    }

    public async downloadFileDocument(docId: any, name: string) {
        let doc = await this._documentService.getByPath('action=2', docId);
        //@ts-ignore
        //let downFile = await this.FileDocumentService.getById(id);
        // @ts-ignore
        await this.saveFileDocument(doc, name)
        return doc;
    }

    public async downloadOriginalFileDocument(docId: any, name: string) {
        let doc = await this._documentService.getByPath('action=2', docId);
        //@ts-ignore
        //let downFile = await this.FileDocumentService.getById(id);
        // @ts-ignore
        this.saveFileDocument(doc, name)
        return doc;
    }

    public async downloadTypeFile(fileDocId: any) {
        //@ts-ignore
        let id = fileDocId;
        let downFile = await this._fileDocumentService.getById(id);
        // @ts-ignore
        this.saveFileExtension(downFile.data[0])
        //@ts-ignore
        return downFile;
    }

    public async downloadTypeFileAuth(fileDocId: any) {
        //@ts-ignore
        let id = fileDocId;
        let downFile = await this._fileDocumentServiceAuth.getById(id);
        // @ts-ignore
        this.saveFile(downFile.data[0])
        return downFile;
    }

    public async deleteFileId(fileId: any) {
        //@ts-ignore
        let id = fileId;
        let downFile = await this._fileDocumentService.deleteB(id, { id });
        // @ts-ignore
        return downFile;
    }

    public async deleteFileAuthId(fileId: any) {
        //@ts-ignore
        let id = fileId;
        //@ts-ignore
        let downFile = await this._fileDocumentServiceAuth.deleteB(fileId, { fileDocumentId: id });
        // @ts-ignore
        return downFile;
    }

    private saveFile(file: any) {
        const split = file.name.split('.');
        let base64;
        if (split[split.length - 1] == 'pdf') {
            base64 = 'data:application/pdf;base64,' + file.fileBase64;
        } else {
            base64 = 'data:application/octet-stream;base64,' + file.fileBase64;
        }
        const link = document.createElement('a');
        link.href = base64;
        link.download = file.name;
        link.click();
    }

    private saveFileExtension(file: any) {
        let base64;
        if (file.extension == 'pdf') {
            base64 = 'data:application/pdf;base64,' + file.fileBase64;
        } else {
            base64 = 'data:application/octet-stream;base64,' + file.fileBase64;
        }
        const link = document.createElement('a');
        link.href = base64;
        link.download = file.name + "." + file.extension;
        link.click();
    }

    private saveFileDocument(data: any, name: string) {
        let base64;
        if (data.tipo == 'pdf') {
            base64 = 'data:application/pdf;base64,' + data.base64;
        } else {
            base64 = 'data:application/octet-stream;base64,' + data.base64;
        }
        const link = document.createElement('a');
        link.href = base64;
        link.download = name + '.' + data.tipo;
        link.click();
    }

    private async visualizarDocumento(docId: any) {
        let doc = await this._documentService.getById(docId);
        //@ts-ignore
        let id = doc.data[0].fileDocumentId;
        let downFile = await this._fileDocumentService.getById(id);
        // @ts-ignore
        return downFile.data[0].fileBase64;
    }

    public async viewRecorFile(docId: any) {
        let doc = await this._fileDocumentService.getByPath('action=1', docId);
        return doc;
    }

}
