import { UnityService } from "@app/services/shared/unity.service";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { GetActiveFilterPipe } from "@app/pipe/active.pipe";
import { CheckboxSvgPipe } from "@app/pipe/checkbox-Svg.pipe";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { Guid } from "@app/util/guid";
import {
   Actions,
   GetCompany,
   ResolveCompany,
   ResolveUnits,
   resolveCompanys,
   resolveUnitiesObj
} from "./company.model";
import { CompanyService } from "@app/services/company/company.service";
import { CompanyPutObject } from "@app/services/company/company.types";
import { QueryParams } from "@app/services/interfaces/services.types";
import { GetAllUnities } from "@app/services/auth/unity/unity.types";
import { ToastService } from "@app/services/translate/toast.service";
import { Router } from "@angular/router";
@Component({
   selector: "app-company",
   templateUrl: "./company.component.html",
   styleUrls: ["./company.component.scss"]
})
export class CompanyComponent implements OnInit {
   //TODO : PaginationInfo
   paginationInfo: any = {
      page: 1,
      sumRecords: 0,
      index: 50
   };
   paginationUnity: any = {
      page: 1,
      sumRecords: 0,
      index: 50
   };
   unityIdToEdit: string = "";
   companyId: Guid = new Guid(Guid.getEmpty());
   getParameters: string = `?page=${this.paginationInfo.page}&index=${this.paginationInfo.index}`;
   editId: string = "";

   filterForm = {
      socialReason: new UntypedFormControl(null),
      name: new UntypedFormControl(null),
      active: new UntypedFormControl(null)
   };

   form = {
      socialReason: new UntypedFormControl(false),
      name: new UntypedFormControl(null),
      active: new UntypedFormControl(null),
      CEP: new UntypedFormControl(null),
      street: new UntypedFormControl(null),
      number: new UntypedFormControl(null),
      complement: new UntypedFormControl(null),
      neighborhood: new UntypedFormControl(null),
      city: new UntypedFormControl(null),
      state: new UntypedFormControl(null),
      country: new UntypedFormControl(null)
   };

   formUnity = {
      description: new UntypedFormControl(null),
      externalId: new UntypedFormControl(null),
      companyId: new UntypedFormControl(null),
      cnpj: new UntypedFormControl(null),
      CEP: new UntypedFormControl(null),
      street: new UntypedFormControl(null),
      number: new UntypedFormControl(null),
      complement: new UntypedFormControl(null),
      neighborhood: new UntypedFormControl(null),
      city: new UntypedFormControl(null),
      state: new UntypedFormControl(null),
      country: new UntypedFormControl(null)
   };

   @ViewChild("dialogModal")
   dialogModal!: TemplateRef<any>;

   @ViewChild("dialogModalUnity")
   dialogModalUnity!: TemplateRef<any>;

   companys: Array<ResolveCompany> = [];
   units: Array<ResolveUnits> = [];

   resolveUnitiesObj = resolveUnitiesObj;
   resolveCompanys = resolveCompanys;

   ActionsExtra = {
      Ativo: "PutActive"
   };

   constructor(
      private _companyService: CompanyService,
      private _dialog: MatDialog,
      private _localaDatePipe: LocalaDatePipe,
      private _activePipe: GetActiveFilterPipe,
      private _checkboxSvgPipe: CheckboxSvgPipe,
      private _unityService: UnityService,
      private _toastService: ToastService,
      public _router: Router
   ) {}

   async ngOnInit() {
      await this.get();
   }

   async get(params?: number[]) {
      if (params) {
         this.paginationInfo.index = params[1];
         this.paginationInfo.page = params[0];
      }

      const queryObject: QueryParams = {
         isActive: this.filterForm.active.value
            ? this.filterForm.active.value
            : null,
         Search: this.filterForm.name.value
            ? this.filterForm.name.value
            : this.filterForm.socialReason.value ?? null,
         numberRegistry: this.paginationInfo.index,
         page: this.paginationInfo.page
      };

      await this._companyService
         .getPagination(queryObject)
         .then((response: any) => {
            if (response.data.length != 0) {
               this.companys = response.data.map((company: GetCompany) => ({
                  _hide_socialReason: company.socialReason,
                  id: company.id,
                  "company.code": company.externalId ?? "",
                  "company.name": company.companyName,
                  "company.createdAt":
                     this._localaDatePipe.transform(company.createdAt) ?? "",
                  "company.updatedAt":
                     this._localaDatePipe.transform(company.updatedAt) ?? "",
                  Ativo: company.isActive ? "checkbox|true" : "checkbox|"
               }));
               this.paginationInfo.sumRecords = response.sumRecords;
            } else {
               this._toastService.warningMessage("company.msgNotCompany");
            }
         })
         .catch((err) => {
            this._toastService.fireGenericError(err);
         });
   }

   async post() {
      const obj = {
         companyName: this.form.name.value,
         socialReason: this.form.socialReason.value,
         address: {
            zipCode: this.form.CEP.value,
            street: this.form.street.value,
            number: this.form.number.value,
            complement: this.form.complement.value,
            neighborhood: this.form.neighborhood.value,
            city: this.form.city.value,
            state: this.form.state.value,
            country: this.form.country.value
         }
      };

      await this._companyService
         .post(obj)
         .then((res: any) => {
            if (res.success) {
               this.get();
               this._toastService.fireSuccess("company.msgPostCompany");
               this.close(true);
            }
         })
         .catch((err) => {
            this._toastService.fireGenericError(err);
         });
   }

   async put() {
      const obj: CompanyPutObject = {
         companyName: this.form.name.value,
         socialReason: this.form.socialReason.value,
         isActive: true,
         address: {
            zipCode: this.formUnity.CEP.value,
            street: this.formUnity.street.value,
            number: this.formUnity.number.value,
            complement: this.formUnity.complement.value,
            neighborhood: this.formUnity.neighborhood.value,
            city: this.formUnity.city.value,
            state: this.formUnity.state.value,
            country: this.formUnity.country.value
         }
      };

      await this._companyService
         .put(obj, new Guid(this.companyId.toString()))
         .then(async (res) => {
            await this.get();
            this.close(true);
            this._toastService.fireSuccess("company.msgPutCompany");
         })
         .catch((err) => {
            this._toastService.fireGenericError(err);
         });
   }

   openDialogModal(id?: string) {
      this.editId = id ?? "";
      const myTempDialog = this._dialog.open(this.dialogModal, {
         width: "660px"
      });
      myTempDialog.afterClosed().subscribe(() => {
         this.editId = "";
         this.form.socialReason.reset();
         this.form.name.reset();
      });
   }

   openDialogModalUnity(id?: string) {
      if (id) {
         this.unityIdToEdit = id;
      } else {
         this.unityIdToEdit = "";
         this.resetFormUnity();
      }

      const myTempDialog = this._dialog.open(this.dialogModalUnity, {
         width: "660px"
      });
      myTempDialog.afterClosed().subscribe(() => {
         this.resetFormUnity();
      });
   }

   getCreated(item: any) {
      return this._localaDatePipe.transform(item.createdAt);
   }

   getUpdated(item: any) {
      return this._localaDatePipe.transform(item.updatedAt);
   }

   getActive(item: any) {
      return this._activePipe.transform(item.isActive);
   }

   close(clearCompany: boolean = false) {
      if (clearCompany) {
         this.companyId = new Guid(Guid.getEmpty());
      }
      this._dialog.closeAll();
   }

   putActive(id: Guid, value: string) {
      this._companyService
         .patchActive(id.toString(), value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.get();
         })
         .catch((err) => this._toastService.fireGenericError(err));
   }

   putActiveUnity(id: string, value: string) {
      this._unityService
         .patchActive(id.toString(), value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getUnity();
         })
         .catch((err) => this._toastService.fireGenericError(err));
   }

   Actions(emit: Actions) {
      switch (emit.action) {
         case "Editar":
            // this.companyId = new Guid(emit.object.id);
            // //@ts-ignore
            // this.form.name.setValue(emit.object['company.name']);
            // //@ts-ignore
            // this.form.socialReason.setValue(emit.object._hide_socialReason);
            // this.openDialogModal(emit.object.id.toString());
            this._router.navigateByUrl("EmpresasOld/Editar/" + emit.object.id);
            break;
         case "PutActive":
            //@ts-ignore
            this.putActive(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
         case "OpenDropdown":
            this.companyId = emit.object.id;
            this.getUnity();
            break;
      }
   }

   async getUnity(params?: number[]) {
      if (params) {
         this.paginationInfo.index = params[1];
         this.paginationInfo.page = params[0];
      }

      const queryObject: QueryParams = {
         companyId: this.companyId.toString(),
         numberRegistry: this.paginationInfo.index,
         page: this.paginationInfo.page
      };
      //@ts-ignore
      this.units[this.companyId.toString()] = [];
      await this._unityService
         .getPagination<GetAllUnities>(queryObject)
         .then(async (response) => {
            if (response.data.length != 0) {
               //@ts-ignore
               this.units[this.companyId.toString()] = response.data.map(
                  (unity: any) => ({
                     id: unity.id,
                     "company.code": unity.externalId ?? "",
                     "company.description": unity.name,
                     "company.createdAt":
                        this._localaDatePipe.transform(unity.createdAt) ?? "",
                     "company.updatedAt":
                        this._localaDatePipe.transform(unity.updatedAt) ?? "",
                     Ativo: unity.isActive ? "checkbox|true" : "checkbox|"
                  })
               );
               this.paginationUnity.sumRecords = response.sumRecords;
            }
         })
         .catch((err) => {
            this._toastService.fireGenericError(err);
         });
   }

   ActionsUnity(emit: Actions) {
      switch (emit.action) {
         case "Editar":
            this.unityIdToEdit = emit.object.id.toString();
            this._unityService
               .getById(emit.object.id)
               .then((x: any) => {
                  this.formUnity.description.setValue(x.data[0].name);
                  this.formUnity.externalId.setValue(x.data[0].externalId);
                  this.formUnity.cnpj.setValue(x.data[0].cnpj);
                  this.formUnity.CEP.setValue(x.data[0].address.zipCode);
                  this.formUnity.street.setValue(x.data[0].address.street);
                  this.formUnity.number.setValue(x.data[0].address.number);
                  this.formUnity.city.setValue(x.data[0].address.city);
                  this.formUnity.complement.setValue(
                     x.data[0].address.complement
                  );
                  this.formUnity.neighborhood.setValue(
                     x.data[0].address.neighborhood
                  );
                  this.formUnity.state.setValue(x.data[0].address.state);
                  this.formUnity.country.setValue(x.data[0].address.country);
               })
               .catch((err: any) => {
                  this._toastService.fireGenericError(err);
               });

            this.openDialogModalUnity(emit.object.id.toString());
            break;
         case "PutActive":
            this.putActiveUnity(
               emit.object.id.toString(),
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
      }
   }

   resetFilter() {
      this.filterForm.socialReason.reset();
      this.filterForm.name.reset();
      this.filterForm.active.reset();
   }

   postUnity() {
      const obj = {
         companyId: this.companyId.toString(),
         name: this.formUnity.description.value,
         externalId: this.formUnity.externalId.value,
         address: {
            zipCode: this.formUnity.CEP.value,
            street: this.formUnity.street.value,
            number: this.formUnity.number.value,
            complement: this.formUnity.complement.value,
            neighborhood: this.formUnity.neighborhood.value,
            city: this.formUnity.city.value,
            state: this.formUnity.state.value,
            country: this.formUnity.country.value
         },
         cnpj: { value: this.formUnity.cnpj.value }
      };

      this._unityService
         .post(obj)
         .then((x: any) => {
            this._toastService.fireSuccess("company.msgPostUnit");
            this.getUnity();
            this.close();
         })
         .catch((err: any) => {
            this._toastService.fireGenericError(err);
         });
   }

   putUnity() {
      const obj = {
         name: this.formUnity.description.value,
         externalId: this.formUnity.externalId.value,
         address: {
            zipCode: this.formUnity.CEP.value,
            street: this.formUnity.street.value,
            number: this.formUnity.number.value,
            complement: this.formUnity.complement.value,
            neighborhood: this.formUnity.neighborhood.value,
            city: this.formUnity.city.value,
            state: this.formUnity.state.value,
            country: this.formUnity.country.value
         },
         cnpj: { value: this.formUnity.cnpj.value }
      };

      this._unityService
         .put(obj, new Guid(this.unityIdToEdit))
         .then((x: any) => {
            this._toastService.fireSuccess("company.msgPutUnit");
            this.getUnity();
            this.close();
         })
         .catch((err: any) => {
            this._toastService.fireGenericError(err);
         });
   }

   resetFormUnity() {
      this.unityIdToEdit = "";
      this.formUnity.description.reset();
      this.formUnity.companyId.reset();
      this.formUnity.externalId.reset();
      this.formUnity.CEP.reset();
      this.formUnity.city.reset();
      this.formUnity.cnpj.reset();
      this.formUnity.complement.reset();
      this.formUnity.country.reset();
      this.formUnity.neighborhood.reset();
      this.formUnity.number.reset();
      this.formUnity.state.reset();
      this.formUnity.street.reset();
   }

   async getCep(unity: boolean) {
      let cep: string = "";

      if (unity && this.formUnity.CEP.value) {
         let tmpCep = this.formUnity.CEP.value;
         cep = tmpCep.replace(/\D/g, "");
      }

      if (!unity && this.form.CEP.value) {
         let tmpCep = this.form.CEP.value;
         cep = tmpCep.replace(/\D/g, "");
      }

      if (cep != "") {
         let validacep: RegExp = /^[0-9]{8}$/;

         if (validacep.test(cep)) {
            this.formUnity.street.setValue("...");
            this.formUnity.neighborhood.setValue("...");
            this.formUnity.city.setValue("...");
            this.formUnity.state.setValue("...");

            await fetch(`https://viacep.com.br/ws/${cep}/json/`)
               .then((response) => {
                  return response.json();
               })
               .then((json) => {
                  if (!("erro" in json)) {
                     if (unity) {
                        this.formUnity.CEP.setValue(json.cep);
                        this.formUnity.street.setValue(json.logradouro);
                        this.formUnity.neighborhood.setValue(json.bairro);
                        this.formUnity.city.setValue(json.localidade);
                        this.formUnity.state.setValue(json.uf);
                        this.formUnity.country.setValue("Brasil");
                     } else {
                        this.form.CEP.setValue(json.cep);
                        this.form.street.setValue(json.logradouro);
                        this.form.neighborhood.setValue(json.bairro);
                        this.form.city.setValue(json.localidade);
                        this.form.state.setValue(json.uf);
                        this.form.country.setValue("Brasil");
                     }
                  } else {
                     this.clearCepForm();
                     this._toastService.warningMessage("company.msgNotCEP");
                  }
               });
         } else {
            this.clearCepForm();
            this._toastService.warningMessage("company.msgNotCEP");
         }
      } else {
         this.clearCepForm();
      }
   }

   clearCepForm() {
      this.formUnity.CEP.setValue("");
      this.formUnity.street.setValue("");
      this.formUnity.number.setValue("");
      this.formUnity.complement.setValue("");
      this.formUnity.neighborhood.setValue("");
      this.formUnity.city.setValue("");
      this.formUnity.state.setValue("");
      this.formUnity.country.setValue("");
   }

   newCompany() {
      this._router.navigateByUrl("EmpresasOld/Novo");
   }
}
