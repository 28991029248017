import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "../http.service";
import { HttpPostResponse, HttpResponsePageable } from "../services.types";
import { Guid } from "@app/util/guid";

@Injectable({
   providedIn: "root"
})
export class DiscountAuthorizationService extends BaseService {
   constructor(private http: HttpClient, httpService: HttpService) {
      super(httpService, "v1/lab/parameters/discountauthorization");
   }

   override getAll() {
      return super.getAll() as Promise<HttpResponsePageable<any>>;
   }

   override getByPath(queryString: string, path: string, headers?: any[]) {
      return super.getByPath(queryString, path, headers) as Promise<
         HttpResponsePageable<any>
      >;
   }

   override getById(id: Guid) {
      return super.getById(id) as Promise<HttpResponsePageable<any>>;
   }

   override get(queryString: string) {
      return super.get(queryString) as Promise<HttpResponsePageable<any>>;
   }

   override post(body: Object) {
      return super.post(body) as Promise<HttpPostResponse<any>>;
   }

   override put(body: Object, id: Guid, path?: string) {
      return super.put(body, id, path) as Promise<HttpPostResponse<any>>;
   }

   override putWithPath(body: Object, id: Guid, path?: string) {
      return super.putWithPath(body, id, path) as Promise<
         HttpPostResponse<any>
      >;
   }
}
