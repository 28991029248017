import { Injectable } from '@angular/core';
import { BaseAuthService } from "../base-auth.service";
import { HttpAuthService } from '../http-auth.service';
import { HttpResponsePageable } from '../../services.types';
import { Guid } from '@app/util/guid';
import { GetByIdTenantAuth } from './tenant-auth.types';

@Injectable({
    providedIn: 'root'
})
export class TenantAuthService extends BaseAuthService {
    constructor(HttpAuth: HttpAuthService) {
        super(HttpAuth, 'v1/admin/tenant');
    }

    override getById(id: Guid) {
        return super.getById(id) as Promise<HttpResponsePageable<GetByIdTenantAuth>>
    }
}