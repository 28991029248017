import Swal from "sweetalert2";
import { ProfileUserAuth } from "../services/auth/profile-user-auth.service";
import { GActions } from "./GActions";
import { RefreshService } from "../services/user/refresh.service";

export class SwAlSetttings {
   constructor(private _GActions: GActions) {}

   public static ALERT = Swal.mixin({
      confirmButtonColor: "#ed753c",
      cancelButtonColor: "#f9b64e",
      allowEscapeKey: false,
      allowOutsideClick: false,
      customClass: {
         popup: "loader"
      }
   });

   public static ALERT_TOAST = Swal.mixin({
      toast: true,
      backdrop: true,
      position: "top",
      confirmButtonColor: "#ed753c",
      cancelButtonColor: "#f9b64e",
      allowEscapeKey: true,
      allowOutsideClick: true,
      customClass: {},
      didOpen: (toast) => {
         toast.addEventListener("mouseenter", Swal.stopTimer);
         toast.addEventListener("mouseleave", Swal.resumeTimer);
      }
   });

   public static ALERT_SUCCESS = Swal.mixin({
      toast: true,
      position: "top-right",
      title: "Ação bem sucedida",
      icon: "success",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      confirmButtonColor: "#ed753c",
      cancelButtonColor: "#f9b64e",
      allowEscapeKey: false,
      didOpen: (toast) => {
         toast.addEventListener("mouseenter", Swal.stopTimer);
         toast.addEventListener("mouseleave", Swal.resumeTimer);
      }
   });

   public static ALERT_INFO = Swal.mixin({
      toast: true,
      position: "top-end",
      icon: "info",
      showConfirmButton: false,
      allowEscapeKey: true,
      timer: 3000,
      timerProgressBar: true,
      confirmButtonColor: "rgba(237, 117, 060, 1)",
      cancelButtonColor: "rgba(249, 182, 078, 1)",
      reverseButtons: true,
      didOpen: (toast) => {
         toast.addEventListener("mouseenter", Swal.stopTimer);
         toast.addEventListener("mouseleave", Swal.resumeTimer);
      }
   });

   public static WARNING_INFO = Swal.mixin({
      toast: true,
      position: "top-right",
      title: "Ação bem sucedida",
      icon: "warning",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      confirmButtonColor: "#ed753c",
      cancelButtonColor: "#f9b64e",
      allowEscapeKey: false,
      didOpen: (toast) => {
         toast.addEventListener("mouseenter", Swal.stopTimer);
         toast.addEventListener("mouseleave", Swal.resumeTimer);
      }
   });

   public static ALERT_ERROR = Swal.mixin({
      toast: true,
      position: "top-right",
      title: "Ocorreu um erro! :(",
      icon: "error",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      confirmButtonColor: "#ed753c",
      cancelButtonColor: "#f9b64e",
      allowEscapeKey: false,
      reverseButtons: true,
      didOpen: (toast) => {
         toast.addEventListener("mouseenter", Swal.stopTimer);
         toast.addEventListener("mouseleave", Swal.resumeTimer);
      }
   });

   public static ALERT_ERROR_NORMAL = Swal.mixin({
      title: "Erro!",
      icon: "error",
      confirmButtonColor: "#ed753c",
      cancelButtonColor: "#f9b64e",
      allowEscapeKey: false,
      reverseButtons: true,
      didOpen: (toast) => {
         toast.addEventListener("mouseenter", Swal.stopTimer);
         toast.addEventListener("mouseleave", Swal.resumeTimer);
      }
   });

   public static ALERT_DELETE = Swal.mixin({
      title: "Tem certeza?",
      text: "Não há como reverter essa operação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      confirmButtonColor: "rgba(230, 051, 042, 1)",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#f9b64e",
      allowEscapeKey: false,
      allowOutsideClick: false,
      reverseButtons: true
   });

   public static ALERT_CONFIRM = Swal.mixin({
      title: "Tem certeza?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Sim",
      confirmButtonColor: "#ed753c",
      cancelButtonText: "Não",
      cancelButtonColor: "#f9b64e",
      allowEscapeKey: false,
      allowOutsideClick: true,
      reverseButtons: true,
      focusCancel: true
   });

   public static async Confirmar(mensagem: string | null = null) {
      return Swal.mixin({
         title: !mensagem ? "Tem certeza?" : mensagem,
         icon: "info",
         showConfirmButton: true,
         showCancelButton: true,
         confirmButtonText: "Sim",
         confirmButtonColor: "#ed753c",
         cancelButtonText: "Não",
         cancelButtonColor: "#f9b64e",
         allowEscapeKey: false,
         allowOutsideClick: false,
         reverseButtons: true,
         focusCancel: true
      }).fire();
   }

   public static async GerarCredenciais(mensagem: string) {
      return Swal.mixin({
         title: "Leia com Atenção!",
         html: `<h2>${mensagem}<h2>`,
         icon: "warning",
         width: "600px",
         showConfirmButton: true,
         confirmButtonText: "Gerar Credencial",
         confirmButtonColor: "#404040",
         allowEscapeKey: true,
         allowOutsideClick: true,
         focusConfirm: true
      }).fire();
   }

   public static ALERT_LOADER = Swal.mixin({
      title: "Carregando, aguarde...",
      showConfirmButton: false,
      didOpen: () => Swal.showLoading()
   });

   public static loader(): void {
      this.closeLoader();
      SwAlSetttings.ALERT.fire({
         showConfirmButton: false,
         didOpen: () => Swal.showLoading()
      });
   }

   public static closeLoader(): void {
      if (Swal.isVisible()) if (Swal.isLoading()) Swal.close();
   }

   public static dispararErro(err: Record<string, any>): void {
      //@ts-ignore
      if (err.error) {
         //@ts-ignore
         if (err.error["errors"]) {
            //@ts-ignore
            const key = Object.keys(err.error["errors"])[0];
            SwAlSetttings.ALERT_ERROR.fire({
               title: "Erro!",
               //@ts-ignore
               text: err.error["errors"][key][0]
            });
            //@ts-ignore
         } else if (err.error["mensagem"]) {
            SwAlSetttings.ALERT_ERROR.fire({
               title: "Erro!",
               //@ts-ignore
               text: err.error["mensagem"]
            });
         }
      } else if (err["_body"]) {
         SwAlSetttings.ALERT_ERROR.fire({
            title: "Erro!",
            text: err["_body"] + ""
         });
      } else {
         SwAlSetttings.ALERT_ERROR.fire({
            title: "Erro!",
            text: err["message"] + ""
         });
      }
   }

   public static alertaMsg(msg: any, admin?: boolean) {
      SwAlSetttings.ALERT_TOAST.fire({
         title: "Alerta importante!",
         text: msg,
         confirmButtonColor: admin ? "#404040" : "#FF6344"
      });
   }

   public static Sucesso(msg: any) {
      SwAlSetttings.ALERT_SUCCESS.fire({
         title: "Sucesso!",
         text: msg
      });
   }

   public static Error(msg: any) {
      SwAlSetttings.ALERT_ERROR.fire({
         title: "Ocorreu um erro!",
         text: msg
      });
   }

   public static printMessageError(text: string, title?: string) {
      SwAlSetttings.ALERT_ERROR.fire({
         title: title || "Ocorreu um erro!",
         text
      });
   }

   public static warningMessage(text: string, title?: string) {
      SwAlSetttings.WARNING_INFO.fire({
         title: title || "Importante!",
         text
      });
   }

   public static printError(err: ErrorEvent) {
      if (err.error) {
         if (
            err.error.status == 400 &&
            err.error.title != "One or more validation errors occurred."
         ) {
            SwAlSetttings.ALERT_ERROR.fire({
               title: "Seu acesso expirou!",
               text: "Para sua segurança você será redirecionado para o Login!"
            });

            setTimeout(() => {
               localStorage.setItem("token", "");
               window.location.href = "/";
            }, 3000);
         } else {
            if (
               err.error.status == 401 &&
               err.error.title != "One or more validation errors occurred."
            ) {
               SwAlSetttings.ALERT_ERROR.fire({
                  title: "Seu usuário não possui permissão para acessar este recurso!",
                  text: "Por favor, faça o Login com credenciais válidas ou solicite permissão ao Administrador!"
               });
            } else {
               if (err.error.errors) {
                  let message: string = "";
                  if (Array.isArray(err.error.errors))
                     //@ts-ignore
                     err.error.errors.forEach((error, index) => {
                        message +=
                           "• " +
                           error.replaceAll("|", "<br>  - ") +
                           (index < err.error.errors.length - 1 ? "<br>" : "") +
                           "<br>";
                     });
                  else {
                     Object.keys(err.error.errors).forEach((error, index) => {
                        message +=
                           err.error.errors[error] +
                           (index < err.error.errors.length - 1 ? "<br>" : "");
                     });
                  }

                  SwAlSetttings.ALERT_ERROR.fire({
                     title: "Ocorreu um erro!",
                     html: message
                  });
               } else {
                  let text: string = "";

                  if (err.error.status && err.error.title) {
                     text =
                        "Status:" + err.error.status + " | " + err.error.title;
                  } else {
                     text = "Não foi possível consultar os dados!";
                  }

                  if (
                     //@ts-ignore
                     err.url.search(
                        "https://users-avatar-saas.s3.amazonaws.com"
                     ) < 0
                  ) {
                     //@ts-ignore
                     SwAlSetttings.ALERT_ERROR.fire({
                        title: "Ocorreu um erro!",
                        text: text
                     });
                  }
               }
            }
         }
      } else {
         let message = "";
         if (err.hasOwnProperty("errors"))
            //@ts-ignore
            err.errors.forEach((x: any) => {
               message += x + " ";
            });

         SwAlSetttings.ALERT_ERROR.fire({
            title: "Ocorreu um erro!",
            text: message
         });
      }
   }

   public static convertToString(elemento: boolean): string {
      return elemento ? "S" : "N";
   }

   public static convertToBoolean(elemento: string): boolean {
      return elemento === "S";
   }

   public static convertToStringS(elemento: boolean): string {
      return elemento ? "A" : "I";
   }

   public static convertToBooleanS(elemento: string): boolean {
      return elemento === "A";
   }

   // createButton(text: string, cb: Event): JQuery<HTMLElement> {
   //   return $('<button>' + text + '</button>').trigger(cb);
   // }
}
