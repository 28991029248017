<div [class]="classe" *ngIf="type != 'date' && type != 'datetime-local'">
    <div class="flex align-center">
        <label *ngIf="label">
            {{ label | translate }}
        </label>
        <div class="sd">
            <input
                [class]="noLabelUp ? 'noLabelUp' : ''"
                [pattern]="pattern"
                [type]="type"
                [tabindex]="tabindexcomponent"
                [min]="min"
                [max]="max"
                [dropSpecialCharacters]="false"
                [placeholder]="placeholder | translate"
                [readOnly]="readonly"
                [required]="requiredInput"
                [formControl]="form"
                (keydown)="onChange(form.value)"
                (change)="onChange(form.value)"
                [mask]="mask"
                [maxlength]="maxlength"
                [minlength]="minlength"
                [prefix]="prefix"
            />
            <label for="">{{ placeholder | translate }}</label>
            <span></span>
            <span
                class="material-icons pass"
                *ngIf="type == 'password' || type == 'show_password'"
                (click)="
                    type == 'password'
                        ? (type = 'show_password')
                        : (type = 'password')
                "
            >
                {{ type == "password" ? "visibility_off" : "visibility_on" }}
            </span>

            <!-- <span class="error" *ngIf="form && requiredInput">
                <span *ngIf="showError('required')">Esse campo é obrigatório</span>
            </span> -->
        </div>
    </div>
</div>
<div class="date" *ngIf="type == 'date' || type == 'datetime-local'">
    <div [class]="classe" *ngIf="type == 'date'">
        <div class="sd">
            <label *ngIf="placeholder && !noLabelUp" class="label-date">
                {{ placeholder | translate }}
            </label>
            <mat-form-field>
                <input
                    matInput
                    (ngModelChange)="change.emit(true)"
                    [tabindex]="tabindexcomponent"
                    [min]="min"
                    [max]="max"
                    [dropSpecialCharacters]="false"
                    [placeholder]="placeholder | translate"
                    [readOnly]="readonly"
                    [required]="requiredInput"
                    [maxlength]="maxlength"
                    [minlength]="minlength"
                    [formControl]="form"
                    [matDatepicker]="picker"
                    [placeholder]="placeholder"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div [class]="classe" *ngIf="type == 'datetime-local'">
        <div class="sd">
            <label *ngIf="placeholder && !noLabelUp" class="label-date">
                {{ placeholder }}
            </label>
            <mat-form-field>
                <input
                    matInput
                    [tabindex]="tabindexcomponent"
                    [min]="min"
                    [max]="max"
                    [dropSpecialCharacters]="false"
                    [placeholder]="placeholder"
                    (keydown)="(OnDown)"
                    [readOnly]="readonly"
                    [required]="requiredInput"
                    [maxlength]="maxlength"
                    [minlength]="minlength"
                    [formControl]="form"
                    [ngxMatDatetimePicker]="picker"
                    [placeholder]="placeholder"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>
</div>
