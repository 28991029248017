import { CustomersComponentOld } from "./components/customer/customers/customers.component";
import { DoctorsComponent } from "./components/doctors/doctors.component";
import { RegistersRoutingModule } from "./registers-routing.module";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonComponentModule } from "@app/components/common-component.module";
import { SaveCustomerComponent } from "./components/customer/save-customer/save-customer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SaveDoctorComponentOld } from "./components/doctors/save-doctor/save-doctor.component";
import { ModalRequisitionsComponent } from "./components/requisition/modal-requisitions/modal-requisitions.component";
import { LoginComponent } from "@app/login/login.component";
import { RequisitionsComponent } from "./components/requisition/requisitions/requisitions.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { AddRequisitionComponent } from "./components/requisition/add-requisition/add-requisition.component";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatStepperModule } from "@angular/material/stepper";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MatDividerModule } from "@angular/material/divider";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { SaveBudgetComponent } from "./components/budget/save-budget/save-budget.component";
import { BudgetsComponent } from "./components/budget/budgets/budgets.component";
import { AgreementsComponent } from "./components/agreement/agreements/agreements.component";
import { SaveAgreementComponent } from "./components/agreement/save-agreement/save-agreement.component";
import { AnalytesComponent } from "./components/analyte/analytes/analytes.component";
import { SaveAnalyteComponent } from "./components/analyte/save-analyte/save-analyte.component";
import { MaterialsComponent } from "./components/materials/materials/materials.component";
import { RecipientsComponent } from "./components/recipients/recipients.component";
import { QuestionsComponent } from "./components/questions/questions.component";
import { MethodologiesComponent } from "./components/methodologies/methodologies.component";
import { MessagesComponent } from "./components/messages/messages.component";
import { SupportLaboratoryComponent } from "./components/support-laboratory/support-laboratory.component";
import { SaveLaboratoryComponent } from "./components/support-laboratory/save-laboratory/save-laboratory.component";
import { TypesPaymentComponent } from "./components/financial/types-payment/types-payment.component";
import { TitlesComponent } from "./components/financial/titles/titles.component";
import { SaveTitleComponent } from "./components/financial/titles/save-title/save-title.component";
import { PriceTableComponent } from "./components/price-table/price-table.component";
import { AnalytePackagesComponent } from "./components/analyte-packages/analyte-packages.component";
import { AgreementSupportComponent } from "./components/agreement-support/agreement-support/agreement-support.component";
import { SaveAgreementSupportComponent } from "./components/agreement-support/save-agreement-support/save-agreement-support.component";
import { SaveRequisitionComponent } from "./components/requisition/save-requisition/save-requisition.component";
import { SipniComponent } from "./components/vaccines/sipni/sipni.component";
import { GetActiveFilterPipe } from "@app/pipe/active.pipe";
import { UnitMeasureComponent } from "./components/stock/unit-measure/unit-measure.component";
import { GroupProductComponent } from "./components/stock/group-product/group-product.component";
import { SchemmaComponent } from "./components/vaccines/schemma/schemma.component";
import { ApplicationOfVaccinesComponent } from "./components/vaccines/application-vaccines/application-of-vaccines.component";
import { VaccinesAdministrationComponent } from "./components/vaccines/vaccines-administration/vaccines-administration.component";
import { ProductComponent } from "./components/stock/product/product.component";
import { VaccineComponent } from "./components/vaccines/vaccine/vaccine.component";
import { AdvancedMaintenceComponent } from "./components/requisition/advanced-maintence/advanced-maintence.component";
import { CheckboxSvgPipe } from "@app/pipe/checkbox-Svg.pipe";
import { SectorListComponent } from "./components/sector/sector-list/sector-list.component";
import { TypeDocumentListComponent } from "./components/financial/type-document/type-document-list/type-document-list.component";
import { TypeIncomeExpenseListComponent } from "./components/financial/types-income-expense/type-income-expense-list/type-income-expense-list.component";
import { BearerListComponent } from "./components/financial/bearer/bearer-list/bearer-list.component";
import { CollectionPlaceOriginListComponent } from "./components/collection-place-origin/collection-place-origin-list/collection-place-origin-list.component";
import { CollectionPlaceListComponent } from "./components/collection-place/collection-place-list/collection-place-list.component";
import { CollectionPlaceInstructionListComponent } from "./components/collection-place-instruction/collection-place-instruction-list/collection-place-instruction-list.component";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { OccurencyTypesComponent } from "./components/occurencyTypes/occurencyTypes.component";
@NgModule({
   imports: [
      CommonModule,
      CommonComponentModule,
      FormsModule,
      ReactiveFormsModule,
      MatExpansionModule,
      MatFormFieldModule,
      MatTabsModule,
      MatCheckboxModule,
      MatStepperModule,
      MatIconModule,
      MatTooltipModule,
      MatDividerModule,
      MatProgressSpinnerModule
   ],
   declarations: [
      DashboardComponent,
      DoctorsComponent,
      CustomersComponentOld,
      SaveCustomerComponent,
      SaveDoctorComponentOld,
      AddRequisitionComponent,
      ModalRequisitionsComponent,
      RequisitionsComponent,
      SaveBudgetComponent,
      BudgetsComponent,
      AgreementsComponent,
      SaveAgreementComponent,
      AnalytesComponent,
      SaveAnalyteComponent,
      MaterialsComponent,
      RecipientsComponent,
      QuestionsComponent,
      MethodologiesComponent,
      MessagesComponent,
      SupportLaboratoryComponent,
      SaveLaboratoryComponent,
      TypesPaymentComponent,
      TitlesComponent,
      PriceTableComponent,
      AnalytePackagesComponent,
      SaveTitleComponent,
      PriceTableComponent,
      AgreementSupportComponent,
      SaveAgreementSupportComponent,
      SaveRequisitionComponent,
      SipniComponent,
      UnitMeasureComponent,
      VaccineComponent,
      GroupProductComponent,
      VaccinesAdministrationComponent,
      SchemmaComponent,
      ApplicationOfVaccinesComponent,
      ProductComponent,
      AdvancedMaintenceComponent,
      SectorListComponent,
      CollectionPlaceInstructionListComponent,
      CollectionPlaceListComponent,
      TypeDocumentListComponent,
      TypeIncomeExpenseListComponent,
      BearerListComponent,
      CollectionPlaceOriginListComponent,
      OccurencyTypesComponent
   ],
   exports: [RegistersRoutingModule],
   providers: [LocalaDatePipe, GetActiveFilterPipe, CheckboxSvgPipe]
})
export class RegistersModule {}
