import {
   UntypedFormBuilder,
   UntypedFormControl,
   UntypedFormGroup,
   Validators
} from "@angular/forms";
import { Guid } from "@app/util/guid";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { RequisitionService } from "@app/services/requisitions/requisition.service";
import {
   AgreementRequest,
   Customer,
   InvoiceProgress,
   Question
} from "@app/services/requisitions/requisition.types";
import {
   ResolvedAgreementRequestObject,
   SelectOptions,
   EType,
   UnityOptions,
   resolveAgreementRequest,
   resolveExams,
   ExamOptions,
   resolveQuestions,
   resolveFinancial,
   resolveCollectResumeExams,
   ResumeCollectExams,
   resolveAgreementExamsRequest,
   resolveExamsWithValue,
   GetAllTypePayments,
   resolveAgreementExams
} from "./save-requisition.model";
import { Util } from "@app/util/util";
import { AgreementsService } from "@app/services/agreements/agreements.service";
import { DoctorsService } from "@app/services/doctors/doctors.service";
import { AnalyteService } from "@app/services/analyte/analyte.service";
import { AnalyteMaterialService } from "@app/services/analyte-material/analyte-material.service";
import { AgreementPlanService } from "@app/services/agreement-plan/agreement-plan.service";
import {
   AgreementPlan,
   GetPaginationAgreement
} from "@app/services/agreements/agreements.types";
import { AgreementRequestService } from "@app/services/requisitions/agreement-request/agreement-request.service";
import { ForecastExamsService } from "@app/services/forecast/forecast.service";
import { AnswerQuestionRequisitionService } from "@app/services/requisitions/answer-question-requisition/answer-question-requisition.service";
import { MatDialog } from "@angular/material/dialog";
import { TypePaymentsService } from "@app/services/payments/payments.service";
import { ETypePayments } from "@app/util/ETypePayments";
import { InvoiceService } from "@app/services/invoice/invoice.service";
import { InvoiceStatus } from "@app/util/EInvoiceStatus";
import { EGenderType } from "@app/util/EGenderType";
import { Router } from "@angular/router";
import { AgreementExamsRequestService } from "@app/services/agreement-exams-request/agreement-exams-request.service";
import { formatCurrency } from "@angular/common";
import { GuideTissService } from "@app/services/guide-tiss/guide-tiss.service";
import { EAgreementExamsRoutine } from "@app/util/EAgreementExamsRoutine";
import { DeadLineService } from "@app/services/deadline/deadline.service";
import decodedToken from "@app/util/Token";
import { CollectionPlaceService } from "@app/services/collection-places/collection-place.service";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import {
   SpanExamCollectSituation,
   SpanExamSituation
} from "@app/util/ESpanExamSituation";
import { GetAllCollectsPlace } from "@app/services/collection-places/collection-place.service.model";
import { DefaultValidators } from "@app/util/Validators";
import { UnityService } from "@app/services/shared/unity.service";
import { QueryParams } from "@app/services/interfaces/services.types";
import { UnityGet } from "@app/services/shared/unity.types";
import { CompanyService } from "@app/services/company/company.service";
import { FinancialService } from "@app/services/financial/financial.service";
import { AgreementExamsSupportLaboratoryService } from "@app/services/agreement-exams-support-laboratory/agreement-exams-support-laboratory.service";
import { SupportLaboratoryRequestService } from "@app/services/support-laboratory-request/support-laboratory-request.service";
import { DiscountAuthorizationService } from "@app/services/discountAuthorization/discountAuthorization.service";
import { ToastService } from "@app/services/translate/toast.service";

@Injectable({
   providedIn: "root"
})
export class SaveRequisitionFacade {
   private _finished: boolean = false;
   private _cleanAgreement: boolean = true;
   private _cleanDoctor: boolean = true;
   private _focusInputCode: boolean = true;
   private _focusInputAdvice: boolean = true;
   private _type: EType = EType.VISUALIZAR;
   private _initialValue: number = 0;
   private _registerAgreementRequest: Array<ResolvedAgreementRequestObject> =
      [];
   private _registerQuestions: Array<any> = [];
   private _questionsList: Array<Question> = [];
   private _formGroupQuestionAnswer: Array<UntypedFormGroup> = [];
   private _medications: Array<any> = [];
   private _privateCreateAgreementRequestExamsList: Array<AgreementRequest> =
      [];
   private _typePaymentsList: Array<{
      label: string;
      value: string;
      type: ETypePayments;
   }> = [];
   private _formTypePayment: { [key: string]: UntypedFormGroup } = {};
   private _invoiceProgressHistory: Array<InvoiceProgress> = [];
   private _agreementRequestList: Array<AgreementRequest> = [];
   private _agreementRequestId: Guid = new Guid(Guid.getEmpty());
   private _allAgreementRequestExams: Array<Object> = [];
   private _resumeCollectExams: Array<ResumeCollectExams> = [];
   private _requisitionNumber: number = 0;
   private _requisitionId: Guid = new Guid(Guid.getEmpty());
   private _customerId: string = "";
   private _unityOptions: Array<UnityOptions> = [];
   private _companyOptions: Array<Object> = [];
   private _collectionPlaceOptions: Array<SelectOptions> = [];
   private _agreementOptions: Array<{
      value: string;
      label: string;
      controlPlan: boolean;
      externalId: string;
      typeInvoice: number;
   }> = [];
   private _agreementPlanOptions: Array<SelectOptions> = [];
   private _doctorOptions: Array<any> = [];
   private _examOptions: Array<{
      code: string;
      label: string;
      information: string;
      value: string;
   }> = [];
   private _agreementExamsSupportOptions: Array<any> = [];
   private _requestExamsOptions: Array<ExamOptions> = [];
   private _materialExamsOptions: Array<SelectOptions> = [];
   private _mnemonicOptions: Array<SelectOptions> = [];
   private _analyteMaterialOptions: Array<any> = [];
   private _analyteIncludeSubscription!: Subscription;
   private _idsFormsMoney: Array<string> = [];
   private _collectRequestExams: Array<Object> = [];
   private _packageExams: Array<Object> = [];
   private _urgentRequestExams: Array<Object> = [];
   private _registerAgreementExamsRequest: Array<any> = [];
   private _biggestPromise: string = "";
   private _promise: string = "";
   private _registerChecked: Array<Guid> = [];
   private _allExamsSupportLaboratory: Array<any> = [];
   private _checkBoxTrue: boolean = false;
   private _usesProcessCollect: boolean = false;
   private _creatingExam: boolean = false;

   private _collectExamsPagination: Object = {
      sumRecords: 0,
      page: 1,
      index: 50
   };

   //#region Forms
   private _formCustomer = this._formBuilder.group({
      arrivalTime: [null],
      name: [null],
      genderType: [EGenderType["Não Informado"]],
      birthDate: [null, [DefaultValidators.birthDateValidator()]],
      motherName: [null],
      nameResponsible: [null],
      socialName: [null],
      cpf: [null],
      cpfIsResponsible: [false],
      rg: [null],
      cns: [null],
      passportNumber: [null],
      cellPhone: [null],
      residencePhone: [null],
      email: [null, [Validators.email]],
      CEP: [null],
      address: [null],
      number: [null],
      complement: [null],
      district: [null],
      city: [null],
      state: [null],
      country: [null],
      continuousMedication: [false],
      sendSmsEmail: [false],
      sendEmailResult: [false],
      agreementRequest: [null]
   });

   private _formQuestions = new UntypedFormGroup({
      questionId: new UntypedFormControl(null),
      answer: new UntypedFormControl(null)
   });

   private _formUnity = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      description: new UntypedFormControl(null)
   });

   private _formCompany = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      description: new UntypedFormControl(null)
   });

   private _formCollectionPlace = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      description: new UntypedFormControl(null)
   });

   private _formAgreementRequest = new UntypedFormGroup({
      codeAgreement: new UntypedFormControl(null),
      codeDoctor: new UntypedFormControl(null),
      agreement: new UntypedFormControl(null),
      plan: new UntypedFormControl(null),
      doctorId: new UntypedFormControl(null),
      deliveryRelease: new UntypedFormControl(false),
      code: new UntypedFormControl(null),
      authorization: new UntypedFormControl(null),
      cardNumber: new UntypedFormControl(null),
      cid: new UntypedFormControl(null),
      cardValidity: new UntypedFormControl(false),
      guideValidity: new UntypedFormControl(false),
      requestPending: new UntypedFormControl(false),
      healthCareCode: new UntypedFormControl(null),
      mainCode: new UntypedFormControl(null),
      typeCharacterService: new UntypedFormControl(0),
      typeAttendance: new UntypedFormControl(5),
      typeOut: new UntypedFormControl(2),
      accidentIndication: new UntypedFormControl(null),
      clinicalIndication: new UntypedFormControl(null),
      tissObservation: new UntypedFormControl(null),
      emissionDate: new UntypedFormControl(null)
   });

   private _formIncludeAnalyte = new UntypedFormGroup({
      code: new UntypedFormControl(null),
      analyteId: new UntypedFormControl(null),
      materialId: new UntypedFormControl(null),
      materialCollected: new UntypedFormControl(false),
      routine: new UntypedFormControl(EAgreementExamsRoutine.Normal),
      deadline: new UntypedFormControl(null),
      requestPending: new UntypedFormControl(false),
      collectionPlaceOriginId: new UntypedFormControl(null),
      collectionPlaceInstructionId: new UntypedFormControl(null),
      information: new UntypedFormControl(null)
   });

   private _formCreateAgreementRequest = new UntypedFormGroup({
      codeAgreement: new UntypedFormControl(null),
      codeDoctor: new UntypedFormControl(null),
      deliveryRelease: new UntypedFormControl(false),
      agreement: new UntypedFormControl(null),
      plan: new UntypedFormControl(null),
      doctorId: new UntypedFormControl(null),
      code: new UntypedFormControl(null),
      authorization: new UntypedFormControl(null),
      cardNumber: new UntypedFormControl(null),
      cid: new UntypedFormControl(null),
      cardValidity: new UntypedFormControl(new Date(Date.now())),
      guideValidity: new UntypedFormControl(new Date(Date.now())),
      requestPending: new UntypedFormControl(false),
      information: new UntypedFormControl(null),
      healthCareCode: new UntypedFormControl(null),
      mainCode: new UntypedFormControl(null),
      typeCharacterService: new UntypedFormControl(0),
      typeAttendance: new UntypedFormControl(5),
      typeOut: new UntypedFormControl(2),
      accidentIndication: new UntypedFormControl(2),
      clinicalIndication: new UntypedFormControl(null),
      tissObservation: new UntypedFormControl(null),
      emissionDate: new UntypedFormControl(new Date(Date.now()))
   });

   private _formChangeData = new UntypedFormGroup({
      agreementPlanId: new UntypedFormControl(null),
      agreement: new UntypedFormControl(null),
      doctorId: new UntypedFormControl(null),
      plan: new UntypedFormControl(null),
      code: new UntypedFormControl(null)
   });

   private _formClinicalInformation = new UntypedFormControl(null);
   private _formAttendanceExternal = new UntypedFormControl(null);
   private _formRequestExternal = new UntypedFormControl(null);
   private _formHospitalInformation = new UntypedFormGroup({
      recordHospital: new UntypedFormControl(null),
      bedHospital: new UntypedFormControl(null),
      roomHospital: new UntypedFormControl(null),
      sectorId: new UntypedFormControl(null),
      sectorExternalId: new UntypedFormControl(null)
   });

   private _formPaymentResume: UntypedFormGroup = this._formBuilder.group({
      bruteValue: [{ value: this._initialValue.toFixed(2), disabled: true }],
      addition: [{ value: this._initialValue.toFixed(2), disabled: true }],
      discount: [{ value: this._initialValue.toFixed(2), disabled: true }],
      balance: [{ value: this._initialValue.toFixed(2), disabled: true }],
      totalPay: [{ value: this._initialValue.toFixed(2), disabled: true }]
   });

   private _formGroupCollectResume: UntypedFormGroup = this._formBuilder.group({
      hasPriority: [false]
      //includeCustomerToCollect: [false]
   });

   private _formUpdateAgreementExamsRequest = new UntypedFormGroup({
      analyteName: new UntypedFormControl({ value: null, disabled: true }),
      materialId: new UntypedFormControl(null),
      routine: new UntypedFormControl(EAgreementExamsRoutine.Normal),
      collectionPlaceOriginId: new UntypedFormControl(null),
      collectionPlaceInstructionId: new UntypedFormControl(null),
      requestPending: new UntypedFormControl(false)
   });
   //#endregion

   private _subjectCollectionPlace = new BehaviorSubject(
      this._collectionPlaceOptions
   );
   private _subjectRegisterAgreementRequest = new BehaviorSubject(
      this._registerAgreementRequest
   );
   private _subjectType = new BehaviorSubject(this._type);
   private _subjectAgreement = new BehaviorSubject(this._agreementOptions);
   private _subjectAgreementPlan = new BehaviorSubject(
      this._agreementPlanOptions
   );
   private _subjectDoctors = new BehaviorSubject(this._doctorOptions);
   private _subjectIncludeAnalyte = new BehaviorSubject(
      this._formIncludeAnalyte
   );
   private _subjectCreateAgreementRequest = new BehaviorSubject(
      this._formCreateAgreementRequest
   );
   private _subjectCreateAgreementRequestExamsList = new BehaviorSubject(
      this._privateCreateAgreementRequestExamsList
   );
   private _subjectTypePaymentsList = new BehaviorSubject(
      this._typePaymentsList
   );
   private _subjectFormTypePayment = new BehaviorSubject(this._formTypePayment);
   private _subjectFormPaymentResume = new BehaviorSubject(
      this._formPaymentResume
   );
   private _subjectInvoiceHistoryProgress = new BehaviorSubject(
      this._invoiceProgressHistory
   );
   private _subjectFocusInputCode = new BehaviorSubject(this._focusInputCode);
   private _subjectFocusInputAdvice = new BehaviorSubject(
      this._focusInputAdvice
   );
   private _subjectAllAgreementRequestExams = new BehaviorSubject(
      this._allAgreementRequestExams
   );
   private _subjectResumeCollectExams = new BehaviorSubject(
      this._resumeCollectExams
   );
   private _subjectCleanAgreement = new BehaviorSubject(this._cleanAgreement);
   private _subjectCleanDoctor = new BehaviorSubject(this._cleanDoctor);
   private _subjectQuestions = new BehaviorSubject(this._registerQuestions);
   private _subjectAllQuestions = new BehaviorSubject(this._questionsList);
   private _subjectFormGroupAllQuestionAnswers = new BehaviorSubject(
      this._formGroupQuestionAnswer
   );
   private _subjectAgreementExamsRequest = new BehaviorSubject(
      this._registerAgreementExamsRequest
   );
   private _subjectUpdateAgreementExamsRequestForm = new BehaviorSubject(
      this._formUpdateAgreementExamsRequest
   );

   private _subjectBiggestPromise = new BehaviorSubject(this._biggestPromise);
   private _subjectPromise = new BehaviorSubject(this._promise);
   private _subjectCompanyOptions = new BehaviorSubject(this._companyOptions);
   private _subjectUnityOptions = new BehaviorSubject(this._unityOptions);
   private _subjectExamsOptions = new BehaviorSubject(this._examOptions);
   private _subjectagreementRequestExamsOptions = new BehaviorSubject(
      this._requestExamsOptions
   );
   private _subjectAgreementExamsSupportOptions = new BehaviorSubject(
      this._agreementExamsSupportOptions
   );
   private _subjectUsesProcessCollect = new BehaviorSubject(
      this._usesProcessCollect
   );

   public readonly collectionPlace$: Observable<Array<SelectOptions>> =
      this._subjectCollectionPlace.asObservable();
   public readonly registerAgreementRequest$: Observable<Array<Object>> =
      this._subjectRegisterAgreementRequest.asObservable();
   public readonly type$: Observable<EType> = this._subjectType.asObservable();
   public readonly agreement$: Observable<
      Array<{ value: string; label: string; controlPlan: boolean }>
   > = this._subjectAgreement.asObservable();
   public readonly agreementPlan$: Observable<Array<SelectOptions>> =
      this._subjectAgreementPlan.asObservable();
   public readonly doctor$: Observable<Array<SelectOptions>> =
      this._subjectDoctors.asObservable();
   public readonly includeAnalyte$: Observable<UntypedFormGroup> =
      this._subjectIncludeAnalyte.asObservable();
   public readonly questions$: Observable<Array<Object>> =
      this._subjectQuestions.asObservable();
   public readonly allQuestions$: Observable<Array<Question>> =
      this._subjectAllQuestions.asObservable();
   public readonly formGroupAllQuestionAnswers$: Observable<
      Array<UntypedFormGroup>
   > = this._subjectFormGroupAllQuestionAnswers.asObservable();
   public readonly focusInputCode$: Observable<boolean> =
      this._subjectFocusInputCode.asObservable();
   public readonly focusInputAdvice$: Observable<boolean> =
      this._subjectFocusInputAdvice.asObservable();
   public readonly formPaymentResume$: Observable<UntypedFormGroup> =
      this._subjectFormPaymentResume.asObservable();
   public readonly formGroupCreateAgreementRequest$: Observable<UntypedFormGroup> =
      this._subjectCreateAgreementRequest.asObservable();
   public readonly agreementRequestExamsList$: Observable<Array<Object>> =
      this._subjectCreateAgreementRequestExamsList.asObservable();
   public readonly typePaymentsList$: Observable<Array<Object>> =
      this._subjectTypePaymentsList.asObservable();
   public readonly formTypePayment$: Observable<{
      [key: number]: UntypedFormGroup;
   }> = this._subjectFormTypePayment.asObservable();
   public readonly allAgreementRequestExams$: Observable<Array<Object>> =
      this._subjectAllAgreementRequestExams.asObservable();
   public readonly resumeCollectExams$: Observable<Array<Object>> =
      this._subjectResumeCollectExams.asObservable();
   public readonly invoiceHistoryProgress$: Observable<Array<InvoiceProgress>> =
      this._subjectInvoiceHistoryProgress.asObservable();
   public readonly cleanAgreement$: Observable<boolean> =
      this._subjectCleanAgreement.asObservable();
   public readonly clearDoctor$: Observable<boolean> =
      this._subjectCleanDoctor.asObservable();
   public readonly agreementExamsRequest$: Observable<Array<Object>> =
      this._subjectAgreementExamsRequest.asObservable();
   public readonly updateAgreementExamsRequestForm$: Observable<UntypedFormGroup> =
      this._subjectUpdateAgreementExamsRequestForm.asObservable();
   public readonly biggestPromise$: Observable<string> =
      this._subjectBiggestPromise.asObservable();
   public readonly promise$: Observable<string> =
      this._subjectPromise.asObservable();
   public readonly companyOptions$: Observable<Array<Object>> =
      this._subjectCompanyOptions.asObservable();
   public readonly unityOptions$: Observable<Array<Object>> =
      this._subjectUnityOptions.asObservable();
   public readonly agreementRequestExamsOptions$: Observable<Array<Object>> =
      this._subjectagreementRequestExamsOptions.asObservable();
   public readonly examsOptions$: Observable<Array<Object>> =
      this._subjectExamsOptions.asObservable();
   public readonly agreementExamsSupportRequestOptions$: Observable<
      Array<Object>
   > = this._subjectAgreementExamsSupportOptions.asObservable();
   public readonly usesProcessCollect$: Observable<boolean> =
      this._subjectUsesProcessCollect.asObservable();

   constructor(
      private _formBuilder: UntypedFormBuilder,
      private _requisitionService: RequisitionService,
      private _agreementService: AgreementsService,
      private _agreementPlanService: AgreementPlanService,
      private _agreementRequestService: AgreementRequestService,
      private _analyteService: AnalyteService,
      private _analyteMaterialService: AnalyteMaterialService,
      private _doctorsService: DoctorsService,
      private _unityService: UnityService,
      private _util: Util,
      private _dialog: MatDialog,
      private _forecastService: ForecastExamsService,
      private _answerQuestionRequisitionService: AnswerQuestionRequisitionService,
      private _typePaymentsService: TypePaymentsService,
      private _invoiceService: InvoiceService,
      private _agreementsExamsRequest: AgreementExamsRequestService,
      private _route: Router,
      private _collectionPlaceService: CollectionPlaceService,
      private _guideTISSService: GuideTissService,
      private _deadLineService: DeadLineService,
      private _localDatePipe: LocalaDatePipe,
      private _companyService: CompanyService,
      private _financialService: FinancialService,
      private _agreementExamsSupportLaboratoryService: AgreementExamsSupportLaboratoryService,
      private _supportLaboratoryRequestService: SupportLaboratoryRequestService,
      private _discountAuthorizationService: DiscountAuthorizationService,
      private _toastService: ToastService
   ) {}

   async getCollectionPlace() {
      this._collectionPlaceOptions = [];
      const queryParams = { isActive: true };
      await this._collectionPlaceService
         .getPagination<GetAllCollectsPlace>(queryParams)
         .then((response) => {
            response.data.forEach((collectionPlace: any) => {
               const collectionPlaceObject = this.generateSelectOption(
                  collectionPlace.id,
                  collectionPlace.description
               );
               this._collectionPlaceOptions.push(collectionPlaceObject);
            });
            this._subjectCollectionPlace.next(this._collectionPlaceOptions);
         });
   }

   async getTypePayments() {
      this._typePaymentsList = [];
      await this._typePaymentsService
         .getPagination<GetAllTypePayments>({ isActive: true })
         .then((res) => {
            res.data.forEach((paymentType) => {
               const paymentTypeObject = {
                  label: paymentType.description,
                  value: paymentType.id,
                  type: paymentType.type
               };

               this._typePaymentsList.push(paymentTypeObject);
            });
            this.getTypePaymentsFormGroup();
            this._subjectTypePaymentsList.next(this._typePaymentsList);
         });
   }

   getTypePaymentsFormGroup() {
      this._idsFormsMoney = [];
      for (let typePayment of this._typePaymentsList) {
         if (typePayment.label == "DINHEIRO") {
            this._idsFormsMoney.push(typePayment.value);
         }
         this._formTypePayment[typePayment.value] = this.createFormGroup(
            typePayment.type
         );
      }
      this._subjectFormTypePayment.next(this._formTypePayment);
   }

   createFormGroup(type: ETypePayments): UntypedFormGroup {
      switch (type) {
         case ETypePayments.NAO_IDENTIFICADO:
         case ETypePayments.DINHEIRO:
         case ETypePayments.CHEQUE:
         case ETypePayments.PIX:
         case ETypePayments.ANTECIPACAO:
            return this._formBuilder.group({
               amountReceived: [this._initialValue.toFixed(2)]
            });
         case ETypePayments.CARTAO_CREDITO:
            return this._formBuilder.group({
               amountReceived: [this._initialValue.toFixed(2)],
               transactionId: [""],
               installmentsNumber: [0]
            });
         case ETypePayments.CARTAO_DEBITO:
            return this._formBuilder.group({
               amountReceived: [this._initialValue.toFixed(2)],
               transactionId: [""]
            });
         default:
            return this._formBuilder.group({
               amountReceived: [this._initialValue.toFixed(2)]
            });
      }
   }

   //#region Getters and Setters
   /**
    * @description Informa o tipo de operação que será realizada ao Pedido (Visualização, Edição ou Criação).
    * @returns Retorna um EType com a operação.
    */

   public get getSendAgreementSupport() {
      return this._registerChecked;
   }

   public get getMedications() {
      return this._medications;
   }

   public get getType() {
      return this._type;
   }

   public get getCollectForm() {
      return this._formGroupCollectResume;
   }

   /**
    * @returns Retorna um FormGroup com os FormControls a respeito dos dados do Local de Coleta (CollectionPlace).
    */
   public get getCollectionPlaceForm() {
      return this._formCollectionPlace;
   }

   /**
    * @returns Retorna um vetor de objetos contendo os pedidos médicos formatados.
    */
   public get getRegisterAgreementRequest() {
      return this._registerAgreementRequest;
   }

   /**
    * @returns Retorna o Número da Requisição.
    */
   public get getRequisitionNumber() {
      return this._requisitionNumber;
   }

   public get getCustumerId() {
      return this._customerId;
   }

   /**
    * @returns Retorna um FormGroup com os FormControls a respeito dos dados dos Pedidos Médicos (AgreementRequest).
    */
   public get getAgreementRequestForm() {
      return this._formAgreementRequest;
   }

   public get getChangeDataForm() {
      return this._formChangeData;
   }

   /**
    * @returns Retorna um FormGroup com os FormControls a respeito da inclusão de um novo Exame no Pedido.
    */
   public get getIncludeAnalyteForm() {
      return this._formIncludeAnalyte;
   }

   /**
    * @returns Retorna um FormGroup com os FormControls a respeito da inclusão de um novo Exame no Pedido.
    */
   public get getQuestionsForm() {
      return this._formQuestions;
   }

   /**
    * @returns Retorna um FormGroup com os FormControls a respeito dos dados de Cliente (Customer).
    */
   public get getCustomerForm() {
      return this._formCustomer;
   }

   /**
    * @returns Retorna um FormGroup com os FormControls a respeito dos dados da Unidade (Unity).
    */
   public get getUnityForm() {
      return this._formUnity;
   }

   /**
    * @description Permite atribuir o ID da Unidade.
    * @param id Recebe o ID da Unidade e atribui o ID ao FormControl.
    */
   public set setUnityId(id: string) {
      this._formUnity.controls["id"].setValue(id);
   }

   /**
    * @description Permite atribuir o ID do Local de Coleta.
    * @param id Recebe o ID do Local de Coleta e atribui o ID ao FormControl.
    */
   public set setCollectionPlaceId(id: string) {
      this._formCollectionPlace.controls["id"].setValue(id);
   }

   /**
    * @returns Retorna uma Lista de Exames/Analitos contendo o ID (value) e a Descrição (label).
    */
   public get getMnemonicOptions() {
      return this._mnemonicOptions;
   }
   /**
    * @returns Retorna uma Lista de Materiais do Exame contendo o ID (value) e a Descrição (label).
    */
   public get getMaterialExamsOptions() {
      return this._materialExamsOptions;
   }
   /**
    * @returns Retorna um FormControl referente à Informação Clínica do Paciente.
    */
   public get getClinicalInformation() {
      return this._formClinicalInformation;
   }

   public get getAttendanceExternal() {
      return this._formAttendanceExternal;
   }

   public get getRequestExternal() {
      return this._formRequestExternal;
   }

   public get getHospitalForm() {
      return this._formHospitalInformation;
   }

   public get getCompanyForm() {
      return this._formCompany;
   }

   /**
    * @returns Retorna uma Lista de Unidades contendo o ID (value) e a Descrição (label).
    */
   public get getUnityOptions() {
      return this._unityOptions;
   }

   public get getCompanyOptions() {
      return this._companyOptions;
   }

   public get getCollectRequestExams() {
      return this._collectRequestExams;
   }

   public get getSelectedPackageExams() {
      return this._packageExams;
   }

   //#endregion

   /**
    * @description Consulta todos os Convênios
    * @async Método assíncrono, há a necessidade de uso do await!
    */
   async getAgreements(id: string = "") {
      this._agreementOptions = [];
      if (id == "") {
         await this._agreementService.getResume().then((res) => {
            res.data.forEach((agreement: any) => {
               const agreementObject = {
                  value: agreement.id,
                  label: agreement.description,
                  controlPlan: agreement.controlPlan,
                  externalId: agreement.externalId,
                  typeInvoice: agreement.typeInvoice
               };
               this._agreementOptions.push(agreementObject);
            });
            this._subjectAgreement.next(this._agreementOptions);
         });
      } else {
         await this._agreementService.getById(new Guid(id)).then((res) => {
            res.data.forEach((agreement: any) => {
               const agreementObject = {
                  value: agreement.id,
                  label: agreement.description,
                  controlPlan: agreement.controlPlan,
                  externalId: agreement.externalId,
                  typeInvoice: agreement.typeInvoice
               };
               this._agreementOptions.push(agreementObject);
            });
         });
      }
   }

   async searchAgreement(search?: string, searchByCode?: boolean) {
      this._formAgreementRequest.controls["agreement"].setValue(undefined);
      this._agreementOptions = [];
      let obj: any = {
         search: search
      };
      if (searchByCode) {
         obj = {
            ExternalCode: search
         };
      }
      await this._agreementService
         .getPagination<GetPaginationAgreement>(obj)
         .then((res) => {
            res.data.length != 0
               ? (this._cleanAgreement = false)
               : (this._cleanAgreement = true);
            this._subjectCleanAgreement.next(this._cleanAgreement);
            res.data.forEach((agreement: any) => {
               const agreementObject = {
                  value: agreement.id,
                  label: agreement.description,
                  controlPlan: agreement.controlPlan,
                  externalId: agreement.externalId,
                  information: agreement.information,
                  typeInvoice: agreement.typeInvoice
               };
               this._agreementOptions.push(agreementObject);
            });
            this._subjectAgreement.next(this._agreementOptions);
            if (res.data.length == 0) {
               this._toastService.warningMessage(
                  "requisitionMedicalRequest.msgNotPlan"
               );
               this._formAgreementRequest.controls["codeAgreement"].reset();
            }
         });

      if (searchByCode) {
         if (this._agreementOptions.length == 1) {
            this._formAgreementRequest.controls["agreement"].setValue(
               this._agreementOptions[0].value
            );
            this._formCreateAgreementRequest.controls["agreement"].setValue(
               this._agreementOptions[0].value
            );
         }
      }
   }

   clearAgreement() {
      this._cleanAgreement = true;
      this._subjectCleanAgreement.next(this._cleanAgreement);
   }

   /**
    * @description Alimenta os Forms com os dados do Pedido
    * @param agreementRequestId Recebe o ID do Pedido
    */
   async updateAgreementRequest(agreementRequestId: string) {
      this._agreementRequestId = new Guid(agreementRequestId);
      this._formAgreementRequest.reset();
      this._formAgreementRequest.controls["typeCharacterService"].setValue(0);
      this._formAgreementRequest.controls["typeAttendance"].setValue(5);
      this._formAgreementRequest.controls["typeOut"].setValue(2);
      this._type = EType.VISUALIZAR;

      const selectedAgreementRequest = this._agreementRequestList.filter(
         (agreementRequest) => agreementRequest.id == agreementRequestId
      );
      await this.getAgreements(selectedAgreementRequest[0].agreementId);
      await this.getDoctor(selectedAgreementRequest[0].doctorId);
      this._formAgreementRequest.controls["agreement"].setValue(
         selectedAgreementRequest[0].agreementId
      );
      this._formAgreementRequest.controls["plan"].setValue(
         selectedAgreementRequest[0].agreementPlanId
      );
      this._formAgreementRequest.controls["codeAgreement"].setValue(
         selectedAgreementRequest[0].externalId
      );
      this._subjectAgreement.next(this._agreementOptions);
      this._formAgreementRequest.controls["cardNumber"].setValue(
         selectedAgreementRequest[0].cardNumber
      );
      this._formAgreementRequest.controls["cardValidity"].setValue(
         selectedAgreementRequest[0].cardValidity
      );
      this._formAgreementRequest.controls["guideValidity"].setValue(
         selectedAgreementRequest[0].guideValidity
      );
      this._formAgreementRequest.controls["authorization"].setValue(
         selectedAgreementRequest[0].authorization
      );
      this._formAgreementRequest.controls["cid"].setValue(
         selectedAgreementRequest[0].cid
      );
      this._formAgreementRequest.controls["code"].setValue(
         selectedAgreementRequest[0].code
      );
      this._formAgreementRequest.controls["doctorId"].setValue(
         selectedAgreementRequest[0].doctorId
      );
      this._formAgreementRequest.controls["codeDoctor"].setValue(
         selectedAgreementRequest[0].registrationNumber
      );
      this._subjectDoctors.next(this._doctorOptions);
      this._formAgreementRequest.controls["deliveryRelease"].setValue(
         selectedAgreementRequest[0].deliveryRelease
      );
      this._formAgreementRequest.controls["requestPending"].setValue(
         selectedAgreementRequest[0].requestPending
      );
      this._formAgreementRequest.controls["mainCode"].setValue(
         selectedAgreementRequest[0].mainCode
      );
      this._formAgreementRequest.controls["emissionDate"].setValue(
         selectedAgreementRequest[0].emissionDate
      );
      this._formAgreementRequest.controls["healthCareCode"].setValue(
         selectedAgreementRequest[0].healthCareCode
      );
      this._formAgreementRequest.controls["clinicalIndication"].setValue(
         selectedAgreementRequest[0].clinicalIndication
      );
      this._formAgreementRequest.controls["typeAttendance"].setValue(
         selectedAgreementRequest[0].typeAttendance
      );
      this._formAgreementRequest.controls["typeOut"].setValue(
         selectedAgreementRequest[0].typeOut
      );
      this._formAgreementRequest.controls["accidentIndication"].setValue(
         selectedAgreementRequest[0].accidentIndication
      );
      this._formAgreementRequest.controls["typeCharacterService"].setValue(
         selectedAgreementRequest[0].typeCharacterService
      );
      this._formAgreementRequest.controls["tissObservation"].setValue(
         selectedAgreementRequest[0].tissObservation
      );
      const exams = await this.getExams(agreementRequestId);
      this.resolveAgreementExamsRequest(exams, resolveAgreementExamsRequest);
      this._subjectAgreementExamsRequest.next(
         this._registerAgreementExamsRequest
      );
   }

   private resolveAgreementExamsRequest(data: any, columns: any) {
      this._registerAgreementExamsRequest = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this._registerAgreementExamsRequest.push(obj);
      });
   }

   async getAgreementExamsRequest(id?: string) {
      await this._agreementsExamsRequest
         .getByPath(
            "",
            `agreementrequest/${this._agreementRequestId.toString()}`
         )
         .then((res) => {
            this.resolveAgreementExamsRequest(
               res.data,
               resolveAgreementExamsRequest
            );
            this._subjectAgreementExamsRequest.next(
               this._registerAgreementExamsRequest
            );
         });
   }

   async createAgreementExamsRequest() {
      if (!this._creatingExam) {
         this._creatingExam = true;
         const agreementRequestObject = {
            requisitionId: this._requisitionId.toString(),
            agreementId:
               this._formCreateAgreementRequest.controls["agreement"].value,
            doctorId:
               this._formCreateAgreementRequest.controls["doctorId"].value,
            deliveryRelease:
               this._formCreateAgreementRequest.controls["deliveryRelease"]
                  .value,
            agreementPlanId:
               this._formCreateAgreementRequest.controls["plan"].value,
            code: this._formCreateAgreementRequest.controls["code"].value,
            authorization:
               this._formCreateAgreementRequest.controls["authorization"].value,
            cid: this._formCreateAgreementRequest.controls["cid"].value,
            cardNumber:
               this._formCreateAgreementRequest.controls["cardNumber"].value,
            guideValidity: new Date(
               this._formCreateAgreementRequest.controls["guideValidity"].value
            ),
            cardValidity: new Date(
               this._formCreateAgreementRequest.controls["cardValidity"].value
            ),
            mainCode:
               this._formCreateAgreementRequest.controls["mainCode"].value,
            emissionDate: new Date(
               this._formCreateAgreementRequest.controls["emissionDate"].value
            ),
            healthCareCode:
               this._formCreateAgreementRequest.controls["healthCareCode"]
                  .value,
            clinicalIndication:
               this._formCreateAgreementRequest.controls["clinicalIndication"]
                  .value,
            typeAttendance:
               this._formCreateAgreementRequest.controls["typeAttendance"]
                  .value,
            typeOut: this._formCreateAgreementRequest.controls["typeOut"].value,
            accidentIndication:
               this._formCreateAgreementRequest.controls["accidentIndication"]
                  .value,
            typeCharacterService:
               this._formCreateAgreementRequest.controls["typeCharacterService"]
                  .value,
            tissObservation:
               this._formCreateAgreementRequest.controls["tissObservation"]
                  .value
         };

         if (this._agreementRequestId.toString() === Guid.getEmpty()) {
            await this._agreementRequestService
               .post(agreementRequestObject)
               .then(async (res: any) => {
                  this._agreementRequestId = res.id;
               })
               .catch((err) => {
                  this._creatingExam = false;
                  this._toastService.fireGenericError(err);
               });
         }

         if (this._agreementRequestId.toString() != Guid.getEmpty()) {
            const agreementExamsRequest = {
               id: null,
               analyteId: this._formIncludeAnalyte.controls["analyteId"].value,
               materialId: Array.isArray(
                  this._formIncludeAnalyte.controls["materialId"].value
               )
                  ? null
                  : this._formIncludeAnalyte.controls["materialId"].value,
               routine: this._formIncludeAnalyte.controls["routine"].value,
               requestPending:
                  this._formIncludeAnalyte.controls["requestPending"].value,
               isCollected: false,
               collectionPlaceOriginId:
                  this._formIncludeAnalyte.controls["collectionPlaceOriginId"]
                     .value,
               collectionPlaceInstructionId:
                  this._formIncludeAnalyte.controls[
                     "collectionPlaceInstructionId"
                  ].value
            };

            await this._agreementsExamsRequest
               .postByPath(
                  agreementExamsRequest,
                  this._agreementRequestId.toString()
               )
               .then(async () => {
                  await this.getAgreementExamsRequest();
                  this._toastService.fireSuccess(
                     "requisitionMedicalRequest.msgExamSuccess"
                  );
                  this._creatingExam = false;
                  this._formIncludeAnalyte.controls["code"].reset();
                  this._formIncludeAnalyte.controls["analyteId"].reset();
                  this._formIncludeAnalyte.controls["materialId"].reset();
               })
               .catch((err) => {
                  this._creatingExam = false;
                  this._toastService.fireGenericError(err);
               });
         }
      }
   }

   async newAgreementRequest() {
      this._type = EType.NOVO;
      this._subjectType.next(this._type);
      this._formCreateAgreementRequest.reset();
      this._formCreateAgreementRequest.controls["cardValidity"].setValue(
         new Date(Date.now())
      );
      this._formCreateAgreementRequest.controls["guideValidity"].setValue(
         new Date(Date.now())
      );
      this._formCreateAgreementRequest.controls["typeAttendance"].setValue(5);
      this._formCreateAgreementRequest.controls["typeOut"].setValue(2);
      this._formCreateAgreementRequest.controls[
         "typeCharacterService"
      ].setValue(0);
      this._formCreateAgreementRequest.controls["emissionDate"].setValue(
         new Date(Date.now())
      );
      this._formCreateAgreementRequest.controls["accidentIndication"].setValue(
         2
      );
      this._subjectCreateAgreementRequest.next(
         this._formCreateAgreementRequest
      );
      this.clearOptions();
   }

   async createAgreementRequest() {
      const agreementRequestObject = {
         requisitionId: this._requisitionId.toString(),
         agreementId:
            this._formCreateAgreementRequest.controls["agreement"].value,
         doctorId: this._formCreateAgreementRequest.controls["doctorId"].value,
         deliveryRelease:
            this._formCreateAgreementRequest.controls["deliveryRelease"].value,
         agreementPlanId:
            this._formCreateAgreementRequest.controls["plan"].value,
         code: this._formCreateAgreementRequest.controls["code"].value,
         authorization:
            this._formCreateAgreementRequest.controls["authorization"].value,
         cid: this._formCreateAgreementRequest.controls["cid"].value,
         cardNumber:
            this._formCreateAgreementRequest.controls["cardNumber"].value,
         guideValidity: new Date(
            this._formCreateAgreementRequest.controls["guideValidity"].value
         ).toISOString(),
         cardValidity: new Date(
            this._formCreateAgreementRequest.controls["cardValidity"].value
         ).toISOString(),
         mainCode: this._formCreateAgreementRequest.controls["mainCode"].value,
         emissionDate:
            this._formCreateAgreementRequest.controls["emissionDate"].value,
         healthCareCode:
            this._formCreateAgreementRequest.controls["healthCareCode"].value,
         clinicalIndication:
            this._formCreateAgreementRequest.controls["clinicalIndication"]
               .value,
         typeAttendance:
            this._formCreateAgreementRequest.controls["typeAttendance"].value,
         typeOut: this._formCreateAgreementRequest.controls["typeOut"].value,
         accidentIndication:
            this._formCreateAgreementRequest.controls["accidentIndication"]
               .value,
         typeCharacterService:
            this._formCreateAgreementRequest.controls["typeCharacterService"]
               .value,
         tissObservation:
            this._formCreateAgreementRequest.controls["tissObservation"].value
      };

      // if (this._agreementRequestId.toString() === new Guid(Guid.getEmpty()).toString()) {
      //   await this._agreementRequestService.post(agreementRequestObject).then(
      //     async (res: any) => {
      //       this._agreementRequestId = res.id;
      //     }
      //   )
      // } else {
      //   await this._agreementRequestService.put(agreementRequestObject, this._agreementRequestId);
      // }

      // await this.backToVisualization();
      // this._registerAgreementExamsRequest = [];
      // this._subjectAgreementExamsRequest.next(this._registerAgreementExamsRequest);
      // this._toastService.fireSuccess("requisitionMedicalRequest.msgRequestSuccess");
      // this.clearOptions();
   }

   async deleteAnalyteAgreementExamsRequest(agreementExamsRequestId: string) {
      await this._agreementsExamsRequest
         .delete(new Guid(agreementExamsRequestId))
         .then(async () => {
            await this.getAgreementExamsRequest();
            this._toastService.fireSuccess(
               "requisitionMedicalRequest.msgDeleteExam"
            );
         });
   }

   async updateAnalyteAgreementExamsRequest(
      agreementExamsRequestId: string,
      agreementExamsRequestObject: object
   ) {
      const finalAgreementExamsRequestObject = {
         ...agreementExamsRequestObject,
         agreementRequestId: this._agreementRequestId.toString()
      };

      await this._agreementsExamsRequest
         .put(
            finalAgreementExamsRequestObject,
            new Guid(agreementExamsRequestId)
         )
         .then(async () => {
            await this.getAgreementExamsRequest();
            this._dialog.closeAll();
            this._toastService.fireSuccess(
               "requisitionMedicalRequest.msgPutExam"
            );
         });
   }

   async getAnalyteByCode(code: string) {
      if (!code) return;

      const analyteId = this._formIncludeAnalyte.controls["analyteId"].value;
      const selectedAnalyte = this._examOptions.find(
         (exam) => exam.code === code.toUpperCase()
      );

      if (selectedAnalyte && selectedAnalyte.value == analyteId) {
         return;
      }

      if (!selectedAnalyte) {
         await this.searchAnalyte({ code });

         if (this._examOptions.length > 0) {
            this._formIncludeAnalyte.controls["analyteId"].setValue(
               this._examOptions[0].value
            );
            await this.getAnalyteMaterial(this._examOptions[0].value);
            const materialList = this.findAnalyteMaterialOptions(
               this._examOptions[0].value
            );
            if (materialList && materialList.length === 1)
               this._formIncludeAnalyte.controls["materialId"].setValue(
                  materialList[0].value
               );
            this._subjectIncludeAnalyte.next(this._formIncludeAnalyte);
            return;
         }
         this._formIncludeAnalyte.controls["information"].reset();
         this._formIncludeAnalyte.controls["code"].setValue(null);
         this._formIncludeAnalyte.controls["analyteId"].setValue(null);
         this._formIncludeAnalyte.controls["materialId"].setValue(null);
         return;
      }

      if (selectedAnalyte && selectedAnalyte.value != analyteId) {
         this._formIncludeAnalyte.controls["information"].setValue(
            selectedAnalyte.information
         );
         this._formIncludeAnalyte.controls["analyteId"].setValue(
            selectedAnalyte.value
         );
         await this.getAnalyteMaterial(selectedAnalyte.value);
         const materialList = this.findAnalyteMaterialOptions(
            selectedAnalyte.value
         );
         materialList && materialList.length === 1
            ? this._formIncludeAnalyte.controls["materialId"].setValue(
                 materialList[0].value
              )
            : this._formIncludeAnalyte.controls["materialId"].setValue(
                 materialList
              );
         this._subjectIncludeAnalyte.next(this._formIncludeAnalyte);
      }
   }

   async getCodeByAnalyte(
      analyteId: string,
      index: number,
      newAnalyte?: boolean
   ) {
      const selectedAnalyte = this._examOptions.find(
         (analyte) => analyte.value === analyteId
      );

      if (newAnalyte) {
         if (selectedAnalyte) {
            this._formIncludeAnalyte.controls["information"].setValue(
               selectedAnalyte.information
            );
            this._formIncludeAnalyte.controls["code"].setValue(
               selectedAnalyte.code
            );
            this._formIncludeAnalyte.controls["analyteId"].setValue(
               selectedAnalyte.value
            );

            await this.getAnalyteMaterial(selectedAnalyte.value);

            const materialList = this.findAnalyteMaterialOptions(
               selectedAnalyte.value
            );

            materialList && materialList.length === 1
               ? this._formIncludeAnalyte.controls["materialId"].setValue(
                    materialList[0].value
                 )
               : this._formIncludeAnalyte.controls["materialId"].setValue(
                    materialList
                 );

            this._subjectIncludeAnalyte.next(this._formIncludeAnalyte);
         } else {
            this._toastService.warningMessage(
               "requisitionMedicalRequest.msgNotExam"
            );

            this._formIncludeAnalyte.controls["code"].setValue(null);
            this._formIncludeAnalyte.controls["analyteId"].setValue(null);
            this._formIncludeAnalyte.controls["materialId"].setValue(null);
         }
      } else {
         if (selectedAnalyte) {
            await this.getAnalyteMaterial(selectedAnalyte.value);
         } else {
            this._toastService.warningMessage(
               "requisitionMedicalRequest.msgNotExam"
            );
         }
      }
   }

   findAnalyteMaterialOptions(analyteId: string): any[] | null {
      let analyteMaterialOptions: any[] = [];

      if (this._analyteMaterialOptions.length > 0) {
         analyteMaterialOptions = this._analyteMaterialOptions.filter(
            (analyteMaterial) => analyteMaterial.analyteId === analyteId
         );
      }

      if (analyteMaterialOptions.length > 0) {
         return analyteMaterialOptions[0].analyteMaterials;
      }

      return null;
   }

   async getAnalyteMaterial(analyteId: string) {
      this._formIncludeAnalyte.controls["materialId"].reset();

      const materialExam: Array<SelectOptions> = [];

      const queryParams = {
         isActive: true,
         isDeleted: false,
         analyteId: analyteId
      };

      if (analyteId) {
         await this._analyteMaterialService
            .getPagination(queryParams)
            .then((res) => {
               res.data.forEach((material: any) => {
                  const selectObject = {
                     value: material.materialId,
                     label: material.description,
                     isDefaultMaterial: material.isDefaultMaterial
                  };
                  materialExam.push(selectObject);
               });

               const analyteMaterialObject = {
                  analyteId: analyteId,
                  analyteMaterials: materialExam
               };

               this._analyteMaterialOptions.push(analyteMaterialObject);
            });
      }
   }

   async getAgreementPlan(agreementId: string) {
      this._agreementPlanOptions = [];
      await this._agreementPlanService
         .getPagination<AgreementPlan>({ AgreementId: agreementId })
         .then((res) => {
            res.data.forEach((agreementPlan) => {
               const agreementPlanObject = {
                  value: agreementPlan.id,
                  label: agreementPlan.description,
                  default: agreementPlan.default
               };
               this._agreementPlanOptions.push(agreementPlanObject);
               if (res.data.length === 1 || agreementPlanObject.default) {
                  this._formCreateAgreementRequest.controls["plan"].setValue(
                     agreementPlan.id
                  );
                  this._subjectCreateAgreementRequest.next(
                     this._formCreateAgreementRequest
                  );
               }
            });
            this._subjectAgreementPlan.next(this._agreementPlanOptions);
         });
   }

   async getDoctor(id: string = "") {
      this._doctorOptions = [];
      if (id == "") {
         await this._doctorsService.getResume().then((res) => {
            res.data.forEach((doctor: any) => {
               const doctorObject = {
                  value: doctor.id,
                  label: doctor.name,
                  registrationNumber: doctor.registrationNumber
               };
               this._doctorOptions.push(doctorObject);
            });

            this._subjectDoctors.next(this._doctorOptions);
         });
      } else {
         await this._doctorsService.getById(new Guid(id)).then((res) => {
            res.data.forEach((doctor: any) => {
               const doctorObject = {
                  value: doctor.id,
                  label: doctor.name,
                  registrationNumber: doctor.registrationNumber
               };
               this._doctorOptions.push(doctorObject);
            });

            this._subjectDoctors.next(this._doctorOptions);
         });
      }
   }

   async searchDoctor(search: string, searchByCode?: boolean) {
      this._formAgreementRequest.controls["doctorId"].reset();
      this._formCreateAgreementRequest.controls["doctorId"].reset();
      this._doctorOptions = [];

      const searchByCodeQuery = { RegistrationNumber: search };
      const searchByNameQuery = { name: search };

      await this._doctorsService
         .getPagination(searchByCode ? searchByCodeQuery : searchByNameQuery)
         .then((res) => {
            res.data.length != 0
               ? (this._cleanDoctor = false)
               : (this._cleanDoctor = true);
            this._subjectCleanDoctor.next(this._cleanDoctor);
            res.data.forEach((doctor: any) => {
               const nameConselho = `${doctor.name} - ${doctor.council}/${doctor.registrationState}: ${doctor.registrationNumber}`;
               const doctorObject = {
                  value: doctor.id,
                  label: nameConselho,
                  registrationNumber: doctor.registrationNumber
               };
               this._doctorOptions.push(doctorObject);
            });
            this._subjectDoctors.next(this._doctorOptions);
            if (res.data.length == 0)
               this._toastService.warningMessage(
                  "requisitionMedicalRequest.msgNotDoctor"
               );
         });

      if (searchByCode) {
         if (this._doctorOptions.length == 1) {
            this._formAgreementRequest.controls["doctorId"].setValue(
               this._doctorOptions[0].value
            );
            this._formCreateAgreementRequest.controls["doctorId"].setValue(
               this._doctorOptions[0].value
            );
         }
      }
   }

   async searchAnalyte(query: QueryParams) {
      if (this._formCreateAgreementRequest.controls["agreement"].value) {
         query["agreementId"] =
            this._formCreateAgreementRequest.controls["agreement"].value;
      }

      if (this._formAgreementRequest.controls["agreement"].value) {
         query["agreementId"] =
            this._formAgreementRequest.controls["agreement"].value;
      }

      this._examOptions = [];
      await this._analyteService.getPagination(query).then((res) => {
         res.data.forEach((analyte: any) => {
            let label = analyte.name;
            if (
               analyte.invoiceCode != undefined &&
               (this._formAgreementRequest.controls["agreement"].value ||
                  this._formCreateAgreementRequest.controls["agreement"].value)
            ) {
               label += " - " + analyte.invoiceCode;
            }
            const selectObject = {
               value: analyte.id,
               label: label,
               code: analyte.code,
               information: analyte.information
            };
            this._examOptions.push(selectObject);
         });
      });
      this._subjectExamsOptions.next(this._examOptions);
   }

   clearDoctor() {
      this._cleanDoctor = true;
      this._subjectCleanDoctor.next(this._cleanDoctor);
   }

   async getAgreementRequestsByRequisitonId() {
      this._registerAgreementRequest = [];
      this._agreementRequestList = [];
      this._allAgreementRequestExams = [];
      await this._requisitionService
         .getById(this._requisitionId)
         .then((res) => {
            res.data[0].agreementRequest.forEach((agreementRequest) => {
               this._agreementRequestList.push(agreementRequest);
            });

            this.resolve(this._agreementRequestList, resolveAgreementRequest);
         });
      this._subjectRegisterAgreementRequest.next(
         this._registerAgreementRequest
      );
   }

   async getRequisitionById(requisitionId: Guid) {
      this._registerAgreementRequest = [];
      this._agreementRequestList = [];
      this._allAgreementRequestExams = [];
      await this._requisitionService.getById(requisitionId).then((res) => {
         this._customerId = res.data[0].customerId;
         this._finished = res.data[0].finished;
         res.data.forEach((requisiton: any) => {
            const agreementRequest: Array<Object> = [];
            requisiton.agreementRequest.forEach((agreement: any) => {
               agreementRequest.push({
                  id: agreement.agreementId,
                  TypeInvoice: agreement.typeInvoice
               });
            });
            this._formCustomer.controls["agreementRequest"].setValue(
               agreementRequest
            );
            if (requisiton.customer) {
               this._formCustomer.controls["CEP"].setValue(
                  requisiton.customer.address.zipCode
               );
               this._formCustomer.controls["address"].setValue(
                  requisiton.customer.address.street
               );
               this._formCustomer.controls["number"].setValue(
                  requisiton.customer.address.number
               );
               this._formCustomer.controls["complement"].setValue(
                  requisiton.customer.address.complement
               );
               this._formCustomer.controls["district"].setValue(
                  requisiton.customer.address.neighborhood
               );
               this._formCustomer.controls["city"].setValue(
                  requisiton.customer.address.city
               );
               this._formCustomer.controls["state"].setValue(
                  requisiton.customer.address.state
               );
               this._formCustomer.controls["country"].setValue(
                  requisiton.customer.address.country
               );
               if (
                  requisiton.customer.address.zipCode &&
                  requisiton.customer.address.country === undefined
               ) {
                  this._formCustomer.controls["country"].setValue("Brasil");
               }
            }
         });
         //#region Alimenta os dados do Cliente (Customer)
         this.feedCustomerForm(res.data[0].customer, res.data[0].name);
         //#endregion

         this._formCompany.controls["id"].setValue(res.data[0].companyId);

         //#region Alimenta os dados da Requisição (Requisition)
         this._requisitionNumber = res.data[0].number;

         this._requisitionId = new Guid(res.data[0].id);
         //#endregion

         //#region Alimenta os dados da Unidade (Unity)
         this._formUnity.controls["id"].setValue(res.data[0].unityId);
         //#endregion

         this._formHospitalInformation.controls["sectorId"].setValue(
            //@ts-ignore
            res.data[0].sectorId
         );
         this._formHospitalInformation.controls["recordHospital"].setValue(
            //@ts-ignore
            res.data[0].recordHospital
         );
         this._formHospitalInformation.controls["bedHospital"].setValue(
            //@ts-ignore
            res.data[0].bedHospital
         );
         this._formHospitalInformation.controls["roomHospital"].setValue(
            //@ts-ignore
            res.data[0].roomHospital
         );
         this._formHospitalInformation.controls["sectorExternalId"].setValue(
            //@ts-ignore
            res.data[0].sectorExternalId
         );
         this._formCustomer.controls["arrivalTime"].setValue(
            //@ts-ignore
            new Date(res.data[0].arrivalTime).toLocaleTimeString([], {
               hour: "2-digit",
               minute: "2-digit"
            })
         );

         //#region Alimenta os dados do Local de Coleta (CollectionPlace)
         this._formCollectionPlace.controls["id"].setValue(
            res.data[0].collectionPlaceId
         );
         //#endregion

         //#region Alimenta os dados da Informação Clínica (ClinicalInformation)
         this._formClinicalInformation.setValue(
            res.data[0].clinicalInformation
         );
         //#endregion

         //#region Alimenta os dados do pedido externo e número do atendimento
         this._formRequestExternal.setValue(res.data[0].requestExternal),
            this._formAttendanceExternal.setValue(
               res.data[0].attendanceExternal
            );
         //#endregion

         if (res.data[0].agreementRequest.length === 0) {
            this._type = EType.NOVO;
            // this._formCreateAgreementRequest.reset();
         }
         this._subjectType.next(this._type);

         //#region Alimenta os dados do Pedido (AgreementRequest)
         this._agreementRequestList = [];

         res.data[0].agreementRequest.forEach((agreementRequest: any) => {
            this._agreementRequestList.push(agreementRequest);
         });

         this.resolve(this._agreementRequestList, resolveAgreementRequest); // Alimenta o Itens do componente <app-table> dos Pedidos Médicos da Requisição.

         this._subjectRegisterAgreementRequest.next(
            this._registerAgreementRequest
         ); // Propaga a variável this._registerAgreementRequest atualizada para todos os "inscritos" no Observable registerAgreementRequest$.
         //#endregion
         this._medications = res.data[0].medicines;
      });
   }

   async confirmPayment() {
      const paymentArray: Array<{
         id?: string;
         value: number;
         typePaymentId?: string;
      }> = [];
      await this._toastService
         .fireConfirmation("financial.msgConfirmPay")
         .then(async (answer) => {
            if (answer.isConfirmed) {
               this._typePaymentsList.forEach((typePayment) => {
                  let value =
                     this._formTypePayment[typePayment.value].controls[
                        "amountReceived"
                     ].value;
                  value = value.replace(".", "").replace(",", ".");
                  value = parseFloat(value);

                  if (value > 0) {
                     let paydObject: any = {};
                     switch (typePayment.type) {
                        case 4: //Credito
                           paydObject = {
                              value,
                              typePaymentId: typePayment.value,
                              transactionId:
                                 //@ts-ignore
                                 this._formTypePayment[typePayment.value].get(
                                    "transactionId"
                                 ).value,
                              numberPortion:
                                 //@ts-ignore
                                 this._formTypePayment[typePayment.value].get(
                                    "installmentsNumber"
                                 ).value
                           };
                           break;
                        case 5: //Debito
                           paydObject = {
                              value,
                              typePaymentId: typePayment.value,
                              transactionId:
                                 //@ts-ignore
                                 this._formTypePayment[typePayment.value].get(
                                    "transactionId"
                                 ).value
                           };
                           break;
                        default:
                           paydObject = {
                              value,
                              typePaymentId: typePayment.value
                           };
                           break;
                     }
                     paymentArray.push(paydObject);
                  }
               });

               const paymentObject = {
                  requisitionId: this._requisitionId.toString(),
                  action: InvoiceStatus.Received,
                  movimentation: paymentArray
               };

               await this._invoiceService.post(paymentObject).then(async () => {
                  await this.getRequisitionById(this._requisitionId);
                  this._toastService.fireSuccess("financial.msgPaySuccess");
               });
            }
         });
      await this.getFinancial();
   }

   async paymentOperation(
      typeOperation: InvoiceStatus,
      value?: string | null,
      hashProcess?: string,
      control?: any
   ) {
      const message =
         typeOperation === InvoiceStatus.Addition
            ? "financial.msgAddition"
            : "financial.msgDiscount";
      const paymentObject = {
         requisitionId: this._requisitionId.toString(),
         action: typeOperation,
         movimentation: [
            {
               value: value
                  ? parseFloat(value.replace(".", "").replace(",", "."))
                  : 0,
               typePaymentId: null,
               hashProcess: hashProcess ?? null
            }
         ]
      };

      await this._invoiceService
         .post(paymentObject)
         .then(async (res) => {
            await this.getFinancial();
            this._toastService.fireSuccess(message);
         })
         .catch((err) => {
            if (
               err.error.errors[0] ==
                  "O desconto solicitado é maior que o permitido para este usuário!" &&
               control.success
            ) {
               control.success = false;
            }
            this._toastService.fireGenericError(err);
         });
   }

   generateQuestionAnswerForm() {
      this._formGroupQuestionAnswer = [];

      this._questionsList.forEach((question, index) => {
         const formAllQuestionAnswers: UntypedFormGroup = new UntypedFormGroup({
            questionId: new UntypedFormControl(question.id),
            answer: new UntypedFormControl(question.answer)
         });

         this._formGroupQuestionAnswer.push(formAllQuestionAnswers);
      });

      this._subjectFormGroupAllQuestionAnswers.next(
         this._formGroupQuestionAnswer
      );
   }

   /**
    * @description Consulta um CEP e atribui os dados aos formulários de endereço.
    */
   getCep() {
      let cep: string = "";

      if (this._formCustomer.controls["CEP"].value) {
         let tmpCep = this._formCustomer.controls["CEP"].value;
         cep = tmpCep.replace(/\D/g, "");
      }

      if (cep != "") {
         let validacep: RegExp = /^[0-9]{8}$/;

         if (validacep.test(cep)) {
            this._formCustomer.controls["CEP"].setValue("...");
            this._formCustomer.controls["address"].setValue("...");
            this._formCustomer.controls["district"].setValue("...");
            this._formCustomer.controls["city"].setValue("...");
            this._formCustomer.controls["state"].setValue("...");
            this._formCustomer.controls["country"].setValue("...");

            fetch(`https://viacep.com.br/ws/${cep}/json/`)
               .then((response) => {
                  return response.json();
               })
               .then((json) => {
                  if (!("erro" in json)) {
                     this._formCustomer.controls["CEP"].setValue(json.cep);
                     this._formCustomer.controls["address"].setValue(
                        json.logradouro
                     );
                     this._formCustomer.controls["district"].setValue(
                        json.bairro
                     );
                     this._formCustomer.controls["city"].setValue(
                        json.localidade
                     );
                     this._formCustomer.controls["state"].setValue(json.uf);
                     this._formCustomer.controls["country"].setValue("Brasil");
                  } else {
                     this.clearAddressForm();
                     this._formCustomer.controls["number"].setValue(null);
                     setTimeout(() => {
                        this._toastService.warningMessage(
                           "requisitionEdit.msgNotCEP"
                        );
                     }, 100);
                  }
               });
         }
      }
   }

   private clearAddressForm() {
      this._formCustomer.controls["CEP"].setValue(null);
      this._formCustomer.controls["address"].setValue(null);
      this._formCustomer.controls["district"].setValue(null);
      this._formCustomer.controls["city"].setValue(null);
      this._formCustomer.controls["state"].setValue(null);
      this._formCustomer.controls["country"].setValue(null);
   }

   /**
    * @description Consulta todas as unidades disponíveis para a empresa logada do usuário.
    * @async Método assíncrono, há a necessidade de uso do await!
    */
   async getUnities() {
      const query: QueryParams = {
         resume: true,
         isActive: true,
         companyId: this._formCompany.controls["id"].value
      };
      await this._unityService.getPagination<UnityGet>(query).then((res) => {
         this._unityOptions = res.data.map((unity) => ({
            label: unity.name,
            value: unity.id
         }));
         this._subjectUnityOptions.next(this._unityOptions);
      });
   }

   /**
    * @description Consulta todas as empresas disponíveis.
    * @async Método assíncrono, há a necessidade de uso do await!
    */
   async getCompanies() {
      const query: QueryParams = {
         resume: true,
         isActive: true
      };
      await this._companyService
         .getPagination<{ id: string; companyName: string }>(query)
         .then((res) => {
            this._companyOptions = res.data.map((company) => ({
               value: company.id,
               label: company.companyName
            }));
         });
      this._subjectCompanyOptions.next(this._companyOptions);
      this.getUnities();
   }

   /**
    * @description Consulta todos os Locais de Coleta disponíveis para a unidade selecionada e os propaga para os inscritos no Observable
    * @param unityId ID da Unidade.
    */

   private generateSelectOption(value: string, label: string) {
      const selectObject = {
         value: value,
         label: label
      };
      return selectObject;
   }

   private feedCustomerForm(customer: Customer, name: string) {
      if (customer) {
         this._formCustomer.controls["name"].setValue(customer.name);
         this._formCustomer.controls["birthDate"].setValue(customer.birthDate);
         this._formCustomer.controls["cpf"].setValue(customer.cpf);
         this._formCustomer.controls["genderType"].setValue(
            customer.genderType
         );
         this._formCustomer.controls["motherName"].setValue(
            customer.motherName
         );
         this._formCustomer.controls["nameResponsible"].setValue(
            customer.nameResponsible
         );
         this._formCustomer.controls["socialName"].setValue(
            customer.socialName
         );
         this._formCustomer.controls["rg"].setValue(customer.rg);
         this._formCustomer.controls["cns"].setValue(customer.cns);
         this._formCustomer.controls["passportNumber"].setValue(
            customer.passportNumber
         );
         this._formCustomer.controls["cellPhone"].setValue(customer.cellPhone);
         this._formCustomer.controls["residencePhone"].setValue(
            customer.residencePhone
         );
         this._formCustomer.controls["email"].setValue(customer.email);
         this._formCustomer.controls["continuousMedication"].value;
         this._formCustomer.controls["sendSmsEmail"].setValue(
            customer.sendEmailSms
         );
         this._formCustomer.controls["sendEmailResult"].setValue(
            customer.sendEmailResult
         );
         this._formCustomer.controls["continuousMedication"].setValue(
            customer.continuousMedication
         );
         this._formCustomer.controls["cpfIsResponsible"].setValue(
            customer.cpfIsResponsible
         );
      } else {
         this._formCustomer.controls["name"].setValue(name);
      }
   }

   private resolve(data: any, columns: any) {
      this._registerAgreementRequest = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this._registerAgreementRequest.push(obj);
      });
   }

   private resolveQuestions(data: any, columns: any) {
      this._registerQuestions = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this._registerQuestions.push(obj);
      });
   }

   resolveGenericLocal(data: any, columns: any) {
      const genericArray: Array<any> = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (y.method && y.method != "formatField") {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               if (!y.retrive) {
                  obj[y.label] =
                     y.before +
                     this._util.formatField(
                        x,
                        y.fieldName,
                        y.type,
                        y.interpolationFields,
                        y.dateFormat
                     ) +
                     y.after;
               } else {
                  obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
               }
            }
         });
         genericArray.push(obj);
      });

      return genericArray;
   }

   async saveAllQuestionAnswer() {
      const answerQuestions: Array<{ questionId: string; answer: string }> = [];

      this._formGroupQuestionAnswer.forEach((questionAnswerForm, index) => {
         const answerObject = {
            questionId: questionAnswerForm.controls["questionId"].value,
            answer: questionAnswerForm.controls["answer"].value
         };

         answerQuestions.push(answerObject);
      });

      const answerQuestionsList = {
         answerQuestionsList: answerQuestions
      };

      await this._answerQuestionRequisitionService
         .put(answerQuestionsList, this._requisitionId)
         .then(async () => {
            this.getCollectResume();
            this._dialog.closeAll();
            this._toastService.fireSuccess(
               "requestResume.msgPutAllAnswerSuccess"
            );
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async saveQuestionAnswer(questionId: string) {
      const answerQuestions: Array<{ questionId: string; answer: string }> = [];

      this._formGroupQuestionAnswer.forEach(() => {
         const answerObject = {
            questionId: questionId,
            answer: this._formQuestions.controls["answer"].value
         };

         answerQuestions.push(answerObject);
      });

      const answerQuestionsList = {
         answerQuestionsList: answerQuestions
      };

      await this._answerQuestionRequisitionService
         .put(answerQuestionsList, this._requisitionId)
         .then(async (res: any) => {
            this._dialog.closeAll();
            this._formQuestions.reset();
            this.getCollectResume();
            this._toastService.fireSuccess("requestResume.msgPutAnswerSuccess");
         })
         .catch((err) => {
            this._toastService.fireGenericError(err);
         });
   }

   resolveExams(data: any, columns: any, id: string) {
      let exams: Array<Object> = [];
      if (data) {
         data.forEach((x: any) => {
            let obj: any = {};
            columns.forEach((y: any) => {
               if (!y.retrive) {
                  obj[y.label] =
                     y.before +
                     this._util.formatField(
                        x,
                        y.fieldName,
                        y.type,
                        y.interpolationFields,
                        y.dateFormat
                     ) +
                     y.after;
               } else {
                  obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
               }
            });
            exams.push(obj);
         });
      }

      //@ts-ignore
      this._requestExamsOptions[id] = exams;
   }

   async deleteRequisition(requisitionId: Guid, agreementRequestId: Guid) {
      await this._agreementRequestService
         .deleteByPath(
            agreementRequestId,
            `requisition/${requisitionId}/agreementrequest`
         )
         .then(async () => {
            this.getAgreementRequestsByRequisitonId();
            this._toastService.fireSuccess(
               "requisitionMedicalRequest.msgDeleteRequest"
            );
         });
   }

   async updateRequisition() {
      const customerObject = {
         name: this._formCustomer.controls["name"].value,
         birthDate: new Date(
            this._formCustomer.controls["birthDate"].value
         ).toISOString(),
         genderType: parseInt(this._formCustomer.controls["genderType"].value),
         motherName: this._formCustomer.controls["motherName"].value,
         nameResponsible: this._formCustomer.controls["nameResponsible"].value,
         socialName: this._formCustomer.controls["socialName"].value,
         cpf: {
            value: this._formCustomer.controls["cpf"].value
         },
         rg: this._formCustomer.controls["rg"].value,
         passportNumber: this._formCustomer.controls["passportNumber"].value,
         cellPhone: this._formCustomer.controls["cellPhone"].value,
         residencePhone: this._formCustomer.controls["residencePhone"].value,
         email: this._formCustomer.controls["email"].value,
         address: {
            zipCode: this._formCustomer.controls["CEP"].value,
            street: this._formCustomer.controls["address"].value,
            number: this._formCustomer.controls["number"].value,
            complement: this._formCustomer.controls["complement"].value,
            neighborhood: this._formCustomer.controls["district"].value,
            city: this._formCustomer.controls["city"].value,
            state: this._formCustomer.controls["state"].value,
            country: this._formCustomer.controls["country"].value
         },
         clinicalInformation: this._formClinicalInformation.value,
         continuousMedication:
            this._formCustomer.controls["continuousMedication"].value,
         sendEmailSms: this._formCustomer.controls["sendSmsEmail"].value,
         cpfIsResponsible:
            this._formCustomer.controls["cpfIsResponsible"].value,
         cns: this._formCustomer.controls["cns"].value,
         sendEmailResult: this._formCustomer.controls["sendEmailResult"].value
      };

      const requisitionObject = {
         arrivalTime:
            this._formCustomer.controls["arrivalTime"].value != "Invalid Date"
               ? this._formCustomer.controls["arrivalTime"].value
               : null,
         name: this._formCustomer.controls["name"].value,
         birthDate: new Date(
            this._formCustomer.controls["birthDate"].value
         ).toISOString(),
         genderType: parseInt(this._formCustomer.controls["genderType"].value),
         customer: customerObject,
         unityId: this._formUnity.controls["id"].value,
         companyId: this._formCompany.controls["id"].value,
         collectionPlaceId: this._formCollectionPlace.controls["id"].value,
         clinicalInformation: this._formClinicalInformation.value,
         requestExternal: this._formRequestExternal.value,
         attendanceExternal: this._formAttendanceExternal.value,
         recordHospital:
            this._formHospitalInformation.controls["recordHospital"].value,
         bedHospital:
            this._formHospitalInformation.controls["bedHospital"].value,
         roomHospital:
            this._formHospitalInformation.controls["roomHospital"].value,
         sectorId: this._formHospitalInformation.controls["sectorId"].value
      };
      if (
         this._formCustomer.controls["birthDate"].value != "" &&
         this._formCustomer.controls["birthDate"].value != null
      ) {
         await this._requisitionService
            .putWithPath(requisitionObject, this._requisitionId, "update")
            .then(() => {
               this._toastService.fireSuccess(
                  "requisitionEdit.msgPutRequisition"
               );
               this.getRequisitionById(this._requisitionId);
            });
      } else {
         this._toastService.warningMessage("requisitionEdit.msgInfoDate");
      }
   }

   async updateRequisitionAgreementRequest() {
      const updateAgreementRequestObject = {
         agreementId: this._formAgreementRequest.controls["agreement"].value,
         doctorId: this._formAgreementRequest.controls["doctorId"].value,
         agreementPlanId: this._formAgreementRequest.controls["plan"].value,
         code: this._formAgreementRequest.controls["code"].value,
         guideValidity:
            this._formAgreementRequest.controls["guideValidity"].value,
         authorization:
            this._formAgreementRequest.controls["authorization"].value,
         cid: this._formAgreementRequest.controls["cid"].value,
         cardNumber: this._formAgreementRequest.controls["cardNumber"].value,
         cardValidity:
            this._formAgreementRequest.controls["cardValidity"].value,
         deliveryRelease:
            this._formAgreementRequest.controls["deliveryRelease"].value,
         requestPending:
            this._formAgreementRequest.controls["requestPending"].value,
         mainCode: this._formAgreementRequest.controls["mainCode"].value,
         emissionDate:
            this._formAgreementRequest.controls["emissionDate"].value,
         healthCareCode:
            this._formAgreementRequest.controls["healthCareCode"].value,
         clinicalIndication:
            this._formAgreementRequest.controls["clinicalIndication"].value,
         typeAttendance:
            this._formAgreementRequest.controls["typeAttendance"].value,
         typeOut: this._formAgreementRequest.controls["typeOut"].value,
         accidentIndication:
            this._formAgreementRequest.controls["accidentIndication"].value,
         typeCharacterService:
            this._formAgreementRequest.controls["typeCharacterService"].value,
         tissObservation:
            this._formAgreementRequest.controls["tissObservation"].value
      };

      await this._agreementRequestService.put(
         updateAgreementRequestObject,
         this._agreementRequestId
      );

      this._toastService.fireSuccess("requisitionMedicalRequest.msgPutRequest");
   }

   /**
    * @param agreementRequestId Recebe o ID do Pedido.
    * @returns Retorna um vetor de objeto contendo os Exames do Pedido.
    */

   /**
    * @description Executa uma ação de acordo com o parâmetro recebido.
    * @param emit Recebe o EventEmitter advindo do componente <app-table> para executar uma ação (emit.action).
    */
   async agreementRequestActions(emit: any) {
      switch (emit.action) {
         case "Guia TISS":
            let base64 = "";

            await this._guideTISSService
               .getByPath(`agreementRequestId=${emit.object.id}`, "")
               .then((res: any) => {
                  const cleanedData = res.data
                     .replace(/\\r/g, "")
                     .replace(/\\n/g, "");
                  const jsonData = JSON.parse(cleanedData);
                  base64 = jsonData;
                  this.download(base64, "guiaTISS");
               })
               .catch((err) => {
                  this._toastService.fireError(err);
               });

            break;
         case "Editar":
            if (emit.object._hide_agreementPlanId)
               await this.getAgreementPlan(emit.object._hide_agreementId);
            this._type = EType.EDITAR;
            this._subjectType.next(EType.EDITAR);
            await this.updateAgreementRequest(emit.object.id);
            break;
         case "Excluir":
            if (emit.object._hide_agreementPlanId)
               await this.deleteRequisition(
                  this._requisitionId,
                  emit.object.id
               );
            break;
         case "OpenDropdown":
            //@ts-ignore
            this._requestExamsOptions[emit.object.id] = [];
            const exams = await this.getExams(emit.object.id);
            this.resolveExams(exams, resolveExamsWithValue, emit.object.id);
            break;
      }
   }

   async agreementRequestExamsActions(emit: any, agreementRequestId: string) {
      switch (emit.action) {
         case "Excluir":
            await this._toastService
               .fireConfirmation("requisitionMedicalRequest.msgDeleteExams")
               .then((answer) => {
                  if (answer.isConfirmed) {
                     this._agreementsExamsRequest
                        .delete(emit.object._hide_id)
                        .then(async () => {
                           const exams = await this.getExams(
                              agreementRequestId
                           );
                           this.resolveExams(
                              exams,
                              resolveExamsWithValue,
                              agreementRequestId
                           );
                           this._toastService.fireSuccess(
                              "requisitionMedicalRequest.msgDeleteExam"
                           );
                        });
                  }
               });
            break;
      }
   }

   async updateAgreementExamsRequest(id: string, analyteDeadline: Date) {
      const object = {
         agreementExamsRequestIds: [id],
         deadline: new Date(analyteDeadline).toISOString(),
         personId: decodedToken.pid
      };

      await this._deadLineService.put(object, this._requisitionId).then(
         //
         () => {
            this._dialog.closeAll();
            this.getCollectResume();
         }
      );
   }

   async updateAgreementExamsMultiple(id: string, body: Object) {
      const agreementRequestId = new Guid(id);
      await this._agreementsExamsRequest
         .putWithPath(body, agreementRequestId, "multiple")
         .then(() => {
            this._dialog.closeAll();
            this.getAgreementRequestsByRequisitonId();
         });
   }

   ngOnDestroy(): void {
      this._analyteIncludeSubscription.unsubscribe();
      this._formCustomer.reset();
      this._formQuestions.reset();
      this._formUnity.reset();
      this._formCollectionPlace.reset();
      this._formIncludeAnalyte.reset();
      this._formChangeData.reset();
      this._formClinicalInformation.reset();
      this._formPaymentResume.reset();
      this._formGroupCollectResume.reset();
      this.clearOptions();
   }

   clearOptions() {
      this._agreementRequestId = new Guid(Guid.getEmpty());
      this._registerAgreementExamsRequest = [];
      this._subjectAgreementExamsRequest.next(
         this._registerAgreementExamsRequest
      );
      this._agreementOptions = [];
      this._subjectAgreement.next(this._agreementOptions);
      this._agreementPlanOptions = [];
      this._subjectAgreementPlan.next(this._agreementPlanOptions);
      this._doctorOptions = [];
      this._subjectDoctors.next(this._doctorOptions);
      this._formIncludeAnalyte.reset();
      this._subjectIncludeAnalyte.next(this._formIncludeAnalyte);
      this._formAgreementRequest.reset();
      this._formCreateAgreementRequest.reset();
      this._examOptions = [];
      this._subjectExamsOptions.next(this._examOptions);
   }

   public async includeCustomerToCollect(check: boolean) {
      if (check) {
         this._collectRequestExams.forEach((exam) => {
            var ind = this._urgentRequestExams.findIndex(
               //@ts-ignore
               (x: any) => x == exam.id
            );
            if (ind != -1) {
               //@ts-ignore
               exam.Urgente = "|checkbox|true";
            }
            //@ts-ignore
            exam.Coletar = "|checkbox|true";
         });
      } else {
         this._collectRequestExams.forEach((exam) => {
            var ind = this._urgentRequestExams.findIndex(
               //@ts-ignore
               (x: any) => x == exam.id
            );
            if (ind != -1) {
               //@ts-ignore
               exam.Urgente = "|checkbox|true";
            }
            //@ts-ignore
            exam.Coletar = "|checkbox|false";
         });
         this._collectRequestExams = [];
      }
   }

   public async getAllAnalyteInstructions(analyteId?: string) {
      let analyteInstructionsList: Array<Object> = [];
      let query = "";
      if (analyteId) {
         query = "?AnalyteId=" + analyteId;
      }
      await this._requisitionService
         .getByPath(query, "preparation/" + this._requisitionId)
         .then((res: any) => {
            const data = res.data;
            for (let analyte of data) {
               if (analyteId && analyte.analyteId != analyteId) return;
               let instructions = "Não há preparos definidos para esse exame.";
               if (analyte.analyteInstructions.length != 0)
                  instructions =
                     "● " +
                     analyte.analyteInstructions
                        .map((instruction: any) => instruction.description)
                        .join("\n\n● ");

               const obj = {
                  analyteId: analyte.id,
                  code: analyte.analyteName,
                  instructions: instructions,
                  form: new UntypedFormControl(instructions)
               };
               analyteInstructionsList.push(obj);
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
      return analyteInstructionsList;
   }

   public async getCollectResume() {
      await this._requisitionService
         .getByPath("", `finish/${this._requisitionId}`)
         .then((response: any) => {
            let analytes = response.data.analytes;
            this._usesProcessCollect = response.data.usesProcessCollect;
            this._subjectUsesProcessCollect.next(this._usesProcessCollect);
            analytes.forEach((analyte: any) => {
               analyte.usesProcessCollect = response.data.usesProcessCollect;
               if (
                  this._usesProcessCollect &&
                  analyte.situation == 0 &&
                  !this._finished
               ) {
                  analyte.situation = 1;
               }
            });
            this._resumeCollectExams = this.resolveGenericLocal(
               analytes,
               resolveCollectResumeExams
            );
            this._subjectResumeCollectExams.next(this._resumeCollectExams);
            //#region Alimenta os dados das Perguntas da Requisição (RequisitionQuestions)
            this._questionsList = response.data.questions;
            this._subjectAllQuestions.next(this._questionsList); // Propaga a variável this._questionsList atualizada para todos os "inscritos" no Observable allQuestions$.
            this.generateQuestionAnswerForm(); // Gera o Vetor de FormGroup usado para salvar todas respostas das perguntas.
            this.resolveQuestions(this._questionsList, resolveQuestions); // Alimenta o Itens da <app-table> das Perguntas da Requisição
            this._subjectQuestions.next(this._registerQuestions); // Propaga a variável this._registerQuestions atualizada para todos os "inscritos" no Observable questions$.
            //#endregion
            const biggestDeadline = this.getBiggestDeadline(
               response.data.deadlines
            );
            this._subjectBiggestPromise.next(biggestDeadline);
            let multipleDeadlines = "Previsão Parcial:<br>";
            response.data.deadlines.forEach((deadlineObject: any) => {
               let examsString = " exame";
               if (deadlineObject.count > 1) {
                  examsString = " exames";
               }
               multipleDeadlines +=
                  this._localDatePipe.transform(
                     new Date(deadlineObject.deadline)
                  ) +
                  " - " +
                  deadlineObject.count +
                  examsString +
                  "<br>";
            });
            this._subjectPromise.next(multipleDeadlines);
         });

      this.getAgreementExamsSupportOptions();
   }

   getBiggestDeadline(
      deadlines: Array<{ deadline: string; count: number }>
   ): string {
      let biggestDeadline: Date = new Date();
      deadlines.forEach((deadlineObject) => {
         const dateDeadline = new Date(deadlineObject.deadline);
         if (biggestDeadline < dateDeadline) biggestDeadline = dateDeadline;
      });
      return this._localDatePipe.transform(biggestDeadline) ?? "";
   }

   public changeCollectAction(emit: any) {
      const exam: ResumeCollectExams | undefined =
         this._resumeCollectExams.find((x) => x.id === emit.object.id);

      if (exam) {
         exam._hide_actionCollect < 3
            ? exam._hide_actionCollect++
            : (exam._hide_actionCollect = 0);
         //exam['Ação'] = EActionCollect[exam._hide_actionCollect];
      }
   }

   public toggleSituationAction(emit: any) {
      const exam: any = this._resumeCollectExams.find(
         (x) => x.id === emit.object.id
      );
      let situations = [];
      let quantitySituations = 0;
      if (this._usesProcessCollect) {
         situations = [0, 1, 3, 6];
         quantitySituations = 3;
      } else {
         situations = [0, 3, 6];
         quantitySituations = 2;
      }

      if (exam) {
         const index = situations.findIndex((x) => x == exam["_hide_status"]);
         exam["_hide_status"] =
            situations[index >= quantitySituations ? 0 : index + 1];
         if (this._usesProcessCollect) {
            exam["requestResume.resolveCollectResumeExams.actionCollect"] =
               SpanExamCollectSituation[exam["_hide_status"]];
         } else {
            exam["requestResume.resolveCollectResumeExams.actionCollect"] =
               SpanExamSituation[exam["_hide_status"]];
         }

         if (exam["_hide_status"] == 3) {
            const date = new Date();
            date.setHours(date.getHours() + 3);
            exam["requestResume.resolveCollectResumeExams.collectDate"] =
               this._localDatePipe.transform(date) ?? "";
            exam["_hide_collectDate"] = date;
            return;
         }
         exam["requestResume.resolveCollectResumeExams.collectDate"] = "";
         exam["_hide_collectDate"] = null;
      }
   }

   public toggleRoutineAction(emit: any) {
      const exam: any = this._resumeCollectExams.find(
         (x) => x.id === emit.object.id
      );

      if (exam) {
         exam["_hide_routine"] = parseInt(exam["_hide_routine"].toString());
         exam["_hide_routine"] =
            exam["_hide_routine"] >= 2 ? 0 : exam["_hide_routine"] + 1;
         exam["requestResume.resolveCollectResumeExams.routine"] =
            EAgreementExamsRoutine[exam["_hide_routine"]];
      }
   }

   public toggleRoutineGlobally(value: number) {
      this._resumeCollectExams.forEach((exam: any) => {
         exam["_hide_routine"] = value;
         exam["requestResume.resolveCollectResumeExams.routine"] =
            EAgreementExamsRoutine[value];
      });
   }

   public setAnalyteDeadlineGlobally(value: Date) {
      let ids: any = [];
      this._resumeCollectExams.forEach((x: any) => {
         ids.push(x.id);
      });
      const object = {
         agreementExamsRequestIds: ids,
         deadline: new Date(value).toISOString(),
         personId: decodedToken.pid
      };

      this._deadLineService.put(object, this._requisitionId).then(() => {
         this._dialog.closeAll();
         this.getCollectResume();
      });
   }

   public toggleStatusGlobally(value: number) {
      this._resumeCollectExams.forEach((exam: any) => {
         exam["_hide_status"] = value;
         if (this._usesProcessCollect) {
            //@ts-ignore
            exam["requestResume.resolveCollectResumeExams.actionCollect"] =
               SpanExamCollectSituation[exam["_hide_status"]];
         } else {
            //@ts-ignore
            exam["requestResume.resolveCollectResumeExams.actionCollect"] =
               SpanExamSituation[exam["_hide_status"]];
         }

         if (exam["_hide_status"] == 3 && exam["_hide_collectDate"]) return;

         if (exam["_hide_status"] == 3 && exam["_hide_collectDate"] == null) {
            const date = new Date();
            date.setHours(date.getHours() + 3);
            exam["requestResume.resolveCollectResumeExams.collectDate"] =
               this._localDatePipe.transform(date) ?? "";
            exam["_hide_collectDate"] = date;
            return;
         }
         exam["requestResume.resolveCollectResumeExams.collectDate"] = "";
         exam["_hide_collectDate"] = null;
      });
   }

   public setCollectDate(emit: any, date: Date, applyToAll: boolean = false) {
      const value = this._localDatePipe.transform(new Date(date).toISOString());

      if (applyToAll)
         return this._resumeCollectExams.forEach((exam: any) => {
            if (exam["_hide_status"] != 3) return;
            exam["requestResume.resolveCollectResumeExams.collectDate"] =
               value ?? "";
            exam["_hide_collectDate"] = date;
         });

      const exam: any = this._resumeCollectExams.find(
         (x) => x.id === emit.object.id
      );

      if (exam && value) {
         exam["requestResume.resolveCollectResumeExams.collectDate"] = value;
         exam["_hide_collectDate"] = date;
      }
   }

   public async finishRequisition() {
      const analytesCollects: Array<Object> = [];
      this._resumeCollectExams.forEach((exam: any) => {
         const obj = {
            id: exam.id,
            actionCollect: exam["_hide_status"],
            routine: exam["_hide_routine"],
            deadline:
               exam._hide_analyteDeadline &&
               exam._hide_analyteDeadline != "undefined"
                  ? exam._hide_analyteDeadline
                  : null
         };
         analytesCollects.push(obj);
      });
      const analytesCollectObject = {
         analytesCollects: analytesCollects
      };
      await this._requisitionService
         .putWithPath(analytesCollectObject, this._requisitionId, "finish")
         .then(async () => {
            await this._route.navigateByUrl(
               "Movimentacao/Recepcao/Requisicoes"
            );
            this.clearOptions();
            this._toastService.fireSuccess("requestResume.msgFinalizeSuccess");
         });
   }

   async backToVisualization() {
      this._type = EType.VISUALIZAR;
      this._subjectType.next(this._type);
      this.getAgreementRequestsByRequisitonId();
   }

   resetCustomerForm() {
      this._formCustomer.reset();
   }

   resetNewExamForm() {
      this._formIncludeAnalyte.controls["code"].setValue(null);
      this._formIncludeAnalyte.controls["analyteId"].setValue(null);
      this._formIncludeAnalyte.controls["materialId"].setValue(null);
   }

   resetMaterialForm() {
      this._formIncludeAnalyte.controls["materialId"].setValue(null);
   }

   setDoctorCode(doctorId: string) {
      if (this._doctorOptions.length > 0) {
         this._formCreateAgreementRequest.controls["codeDoctor"].setValue(
            this._doctorOptions.filter(
               (doctor: any) => doctor.value == doctorId
            )[0].registrationNumber ?? ""
         );
         this._formAgreementRequest.controls["codeDoctor"].setValue(
            this._doctorOptions.filter(
               (doctor: any) => doctor.value == doctorId
            )[0].registrationNumber
         );
      }
   }

   setAgreementCode(agreementId: string) {
      if (
         this._agreementOptions.length > 0 &&
         (this._formCreateAgreementRequest.controls["agreement"].value ||
            this._formAgreementRequest.controls["agreement"].value)
      ) {
         const externalId = this._agreementOptions
            .filter((agreement: any) => agreement.value == agreementId)[0]
            .externalId.replace(".", "");
         this._agreementOptions.forEach((x: any) => {
            if (agreementId === x.value)
               this._formCreateAgreementRequest.controls[
                  "information"
               ].setValue(x.information);
         });
         this._formCreateAgreementRequest.controls["codeAgreement"].setValue(
            externalId
         );
         this._formAgreementRequest.controls["codeAgreement"].setValue(
            externalId
         );
      }
   }

   download(base64String: string, fileName: string, type: string = "pdf") {
      //@ts-ignore
      const source = `data:application/${type};base64,${base64String.base64}`;
      const link = document.createElement("a");
      link.href = source;
      link.download = `${fileName}.${type}`;
      link.click();
   }

   setDefaultValues() {
      this._formCreateAgreementRequest.controls["cardValidity"].setValue(
         new Date(Date.now())
      );
      this._formCreateAgreementRequest.controls["guideValidity"].setValue(
         new Date(Date.now())
      );
      this._formCreateAgreementRequest.controls["typeAttendance"].setValue(5);
      this._formCreateAgreementRequest.controls["typeOut"].setValue(2);
      this._formCreateAgreementRequest.controls[
         "typeCharacterService"
      ].setValue(0);
      this._formCreateAgreementRequest.controls["emissionDate"].setValue(
         new Date(Date.now())
      );
      this._formCreateAgreementRequest.controls["accidentIndication"].setValue(
         2
      );
   }

   async getExams(agreementRequestId: string) {
      let exams: any = [];
      await this._agreementsExamsRequest
         .getByPath("", `agreementrequest/${agreementRequestId}`)
         .then((res) => {
            if (res.data) {
               exams = res.data;
            }
         });
      return exams;
   }

   async getAgreementExamsSupportOptions() {
      let obj: any = [];
      await this._agreementExamsSupportLaboratoryService
         .getByPath("", "requisition/" + this._requisitionId.toString())
         .then((res: any) => {
            obj = res;
         });

      if (obj.data.length > 0) {
         this._allExamsSupportLaboratory = obj;
         this._agreementExamsSupportOptions = [];
         obj.data.forEach((agreementExams: any) => {
            const exams = this.resolveGenericLocal(
               agreementExams.examsSupportLaboratory,
               resolveAgreementExams
            );
            this._agreementExamsSupportOptions.push({
               id: agreementExams.supportLaboratoryId,
               description: agreementExams.supportLaboratoryName,
               exams: exams
            });
         });

         this._subjectAgreementExamsSupportOptions.next(
            this._agreementExamsSupportOptions
         );
      }
   }

   async getFinancial() {
      await this._financialService
         .getById(this._requisitionId)
         .then((response: any) => {
            if (this._idsFormsMoney.length > 0) {
               this._formTypePayment[this._idsFormsMoney[0]].controls[
                  "amountReceived"
               ].setValue(
                  formatCurrency(
                     parseFloat(response.data.invoice.balance.toFixed(2)),
                     "PT-br",
                     ""
                  )
               );
            }
            this._formPaymentResume.controls["bruteValue"].setValue(
               response.data.invoice.value.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL"
               })
            );
            this._formPaymentResume.controls["addition"].setValue(
               response.data.invoice.addition.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL"
               })
            );
            this._formPaymentResume.controls["discount"].setValue(
               response.data.invoice.discont.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL"
               })
            );
            this._formPaymentResume.controls["balance"].setValue(
               response.data.invoice.balance.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL"
               })
            );
            this._formPaymentResume.controls["totalPay"].setValue(
               response.data.invoice.payd.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL"
               })
            );
            this._invoiceProgressHistory = response.data.invoice.progress;
            this._subjectInvoiceHistoryProgress.next(
               this._invoiceProgressHistory
            );
         });
   }

   getExam(item: any) {
      return item.analyteCode + " - " + item.analyteDescription;
   }

   getMaterial(item: any) {
      return item.materialCode + " - " + item.materialDescription;
   }

   returnCheckBox() {
      if (this._checkBoxTrue) {
         return "|checkbox|true";
      } else {
         return "|checkbox";
      }
   }

   async ActionsAgreementExamsSupportLaboratory(action: any) {
      switch (action.action) {
         case "check":
            if (action.$event.srcElement.checked != undefined) {
               var ind = this._registerChecked.findIndex(
                  (x: any) =>
                     x == action.object._hide_agreementExamsSupportLaboratoryId
               );
               if (action.$event.srcElement.checked) {
                  if (ind == -1) {
                     this._registerChecked.push(
                        action.object._hide_agreementExamsSupportLaboratoryId
                     );
                  }
               } else {
                  if (ind > -1) {
                     this._registerChecked.splice(ind, 1);
                  }
               }
            }
            break;
      }
   }

   checkboxAll(event: any, item: any) {
      if (event.srcElement.checked != undefined) {
         this._checkBoxTrue = event.srcElement.checked;
         const exams = this.resolveGenericLocal(
            //@ts-ignore
            this._allExamsSupportLaboratory.data.filter(
               //@ts-ignore
               (agreementSupport) =>
                  agreementSupport.supportLaboratoryId == item
            )[0].examsSupportLaboratory,
            resolveAgreementExams
         );
         exams.forEach((exam: any) => {
            const ind = this._registerChecked.findIndex(
               (registerChecked: any) =>
                  registerChecked ==
                  exam._hide_agreementExamsSupportLaboratoryId
            );
            if (event.srcElement.checked) {
               if (ind == -1) {
                  this._registerChecked.push(
                     exam._hide_agreementExamsSupportLaboratoryId
                  );
               }
            } else {
               if (ind > -1) {
                  this._registerChecked.splice(ind, 1);
               }
            }
         });
         this._agreementExamsSupportOptions[
            this._agreementExamsSupportOptions.findIndex(
               (agreementSupport) => agreementSupport.id == item
            )
         ].exams = exams;
         this._subjectAgreementExamsSupportOptions.next(
            this._agreementExamsSupportOptions
         );
      }
   }

   async sendAgreementSupport() {
      let supportlaboratoryId = "";
      this._agreementExamsSupportOptions.forEach((agreementSupport: any) => {
         if (
            agreementSupport.exams.filter(
               (exams: any) =>
                  exams._hide_agreementExamsSupportLaboratoryId ==
                  this._registerChecked[0]
            ).length > 0
         ) {
            supportlaboratoryId = agreementSupport.id;
         }
      });

      if (this._registerChecked.length > 0) {
         const obj = {
            supportlaboratoryId: supportlaboratoryId,
            resend: false,
            sendPartial: false,
            agreementExamsSupportLaboratoryIds: this._registerChecked
         };

         await this._supportLaboratoryRequestService
            .post(obj)
            .then((res: any) => {
               if (res.success) {
                  this._toastService.fireSuccess("requestResume.msgExamSent");
               }
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });
      }
   }

   createDiscountSolicitation(reason: string, value?: string) {
      const obj = {
         typeDiscount: 1,
         requisitionId: this._requisitionId.toString(),
         value: value
            ? parseFloat(value.replace(".", "").replace(",", "."))
            : 0,
         discountReason: reason
      };
      this._discountAuthorizationService
         .post(obj)
         .then(async (res: any) => {
            this._toastService.fireSuccess(
               "Solicitação de Desconto enviada com sucesso!"
            );
            this._dialog.closeAll();
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }
}
