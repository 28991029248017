import { Subscription } from 'rxjs';

/**
 * Representação de componentes que possuem `Observables` internamente,
 * então existem funções para agrupar e fazer `unsubscribe` em todos `Observables`.
 *
 * @class DataComponent
 */
export abstract class DataComponent {
  /**
   * Uma lista de @type {Subscription} para serem `unsubscribe`.
   */
  public subscriptions: Subscription[] = [];

  /**
   * Adiciona a lista de subscriptions
   * @param sub Uma subscription gerada ao fazer `.subscribe`
   * @return {void}
   */
  public addSubscription(sub: Subscription) {
    this.subscriptions.push(sub);
  }

  /**
   * Adiciona todos subscriptions na lista de subscriptions
   * @param subs Lista de subscriptions gerada ao fazer `.subscribe`
   * @return {void}
   */
  public addSubscriptions(subs: Subscription[]) {
    subs.forEach(value => {
      this.subscriptions.push(value);
    });
  }

  /**
   * Faz unsubscribe em toda a lista de subscriptions adicionada
   * @return {void}
   */
  public unsubscribeAll() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
