import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { HttpResponsePageable } from '../services.types';
import { GetByPathForecastExams } from './forecast.types';

@Injectable({
  providedIn: 'root'
})

export class ForecastExamsService extends BaseService {

  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/forecastexams')
  }

  override getByPath(queryString: string, path: string, headers?: String[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetByPathForecastExams>>
  }

}
