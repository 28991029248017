<modal-layout [title]="title">
    <form [formGroup]="form" class="flex flex-col gap-[32px]">
        <div class="flex">
            <forms-input-select
                class="w-full"
                placeholder="Selecione a empresa"
                [form]="form.controls.companyId"
                [options]="companyOptions"
                (valueChanged)="changeCompany()"
            ></forms-input-select>
        </div>
        <div class="flex">
            <forms-input-select
                class="w-full"
                placeholder="Selecione a unidade"
                [form]="form.controls.unityId"
                [options]="unityOptions"
            ></forms-input-select>
        </div>
        <div>
            <forms-input-select-multiples
                style="
                    background-color: #f4f4f4;
                    display: flex;
                    align-items: center;
                    box-shadow: inset 0 0 2px #404040;
                    width: 100%;
                    border-radius: calc(4px * var(--proportional))
                        calc(4px * var(--proportional)) 0px 0px;
                "
                class="w-full"
                [placeholder]="'Usuários'"
                [form]="form.controls.users"
                [optionStyle]="
                    'border border-[var(--primary-color)] text-[var(--primary-color)] px-3 py-1 rounded-3xl'
                "
                [options]="usersOptions"
            ></forms-input-select-multiples>
        </div>
        <forms-button
            #button
            class="self-center"
            validateBeforeTouch="true"
            (click)="
                button.isDisabled ? null : submit(); $event.preventDefault()
            "
            [validate]="form"
            classExtra="btn btn-primary"
            [text]="'customer.register.save' | translate"
        ></forms-button>
    </form>
</modal-layout>
