import { Inject, Injectable } from '@angular/core';
import { Guid } from '../util/guid';
import { HttpService } from './http.service';
import { IHttpService } from './interfaces/IHttpService';
import { QueryParams } from './interfaces/services.types';
import { HttpResponsePageable } from './services.types';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService implements IHttpService {

  private path: string;

  //@ts-nocheck
  constructor(protected httpService: HttpService, @Inject(String) path: string) {
    this.path = path;
  }

  get(queryString: string): Promise<Object> {
    return this.httpService.get(queryString, `api/${this.path}`);
  }

  getWithTenant(queryString: string, tenantId?: string): Promise<Object> {    
    return this.httpService.get(queryString, `api/${this.path}`, tenantId);
  }

  getWithId(queryString: string, id?: Guid): Promise<Object> {
    return this.httpService.get(queryString, `api/${this.path}/${id}`);
  }

  getAll(): Promise<Object> {
    return this.httpService.get('', `api/${this.path}`);
  }

  getById(id: Guid): Promise<Object> {
    return this.httpService.get('', `api/${this.path}/${id}`);
  }

  getByIdWithTenant(id: Guid, tenantId: string): Promise<Object> {
    return this.httpService.get('', `api/${this.path}/${id}`, tenantId);
  }

  getByPath(queryString: string, path: string, headers: Array<String> = []): Promise<Object> {
    //this.httpService.headers.Authorization = headers.toString;
    headers.forEach((headers) => {

      this.httpService.headers.Authorization = headers.toString();
    });
    return this.httpService.get(queryString, `api/${this.path}/${path}`);
  }

  getResume(active?: boolean) {
    let query: QueryParams = { resume: true };

    if (active !== undefined) {
      query['isActive'] = active; // Usando a notação de colchetes
    }
    return this.getPagination(query)
  }

  /**
   * Método para fazer GET de Endpoints que podem fazer páginação
   * @param page Página que deseja acessar
   * @param index Número de dados que devem ser retornados por Requisição
   * @param queryObject Objeto que representa o QueryParam
   * @returns Retorna requisição páginada
   */
  getPagination<T>(queryObject?: QueryParams): Promise<HttpResponsePageable<T>> {
    const queryParams = this.createQueryParam(queryObject)
    return this.httpService.get(queryParams, `api/${this.path}`) as Promise<HttpResponsePageable<T>>;
  }

  /**
   * Método para transformar dados em QueryParam para ser usado em URL
   * @param params Objeto que será transformado em QueryParam usando {chave: valor}
   * @param initialValue Valor inicial para ser usado na Query
   * @returns retorna valor em formato de "?chave=valor"
   */
  private createQueryParam(params?: QueryParams, initialValue: string = "?"): string {
    let queryParams: string[] = [initialValue]

    if (params) {
      Object.keys(params).forEach((param) => {
        let paramValue = params[param]
        if (typeof paramValue === "string" && paramValue.length == 0 || paramValue === null) return;
        queryParams.push(`${param}=${paramValue}`);
      })
    }

    if ((queryParams.length === 1) && (queryParams[0] === "?")) return ""

    return queryParams.join('&').replace("?&", "?");
  }

  deleteByPath(id?: Guid, path?: string): Promise<Object> {
    return this.httpService.delete(id, `api/${this.path}/${path}`);
  }

  post(body: Object): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.httpService.post(body, `api/${this.path}`);
  }

  postWithTenant(body: Object, tenantId?: string): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.httpService.post(body, `api/${this.path}`, '', tenantId);
  }

  putWithTenant(body: Object, id: string, tenantId?: string): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.httpService.putWithTenant(body, id, `api/${this.path}`, tenantId);
  }

  postByPath(body: Object, path: string): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.httpService.post(body, `api/${this.path}/${path}`);
  }

  alternativePost(body: Object, url:string): Promise<Object> {
    //@ts-ignore
    body.number = parseInt(body.number, 10);
    if (!body) throw new Error('Objeto inválido');
    return this.httpService.post(body, `api/${this.path}`, url);
  }

  postWithId(body: Object, id?: Guid): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.httpService.post(body, `api/${this.path}/${id}`);
  }

  put(body: Object, id?: Guid, path?: string): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    if (id) {
      return this.httpService.put(body, id, `api/${this.path}`);
    } else {
      return this.httpService.put(body, undefined, `api/${this.path}/${path}`);
    }
  }

  patchActive(id: string, value: string) {
    let valueActive : boolean;
    if (value.includes('checkbox')){
      valueActive = value.includes('true') ?? false
    } else {
      valueActive = value.includes('active') ?? false
    }
    let infosToPut = [{
      Opt: "replace",
      Patch: "/IsActive",
      Value: !valueActive
    }]

    return this.httpService.patch(infosToPut, new Guid(id), `api/${this.path}`)
  }

  putWithPath(body: Object, id?: Guid, path?: string): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.httpService.put(body, id, `api/${this.path}/${path}`);
  }

  delete(id?: Guid): Promise<Object> {
    //@ts-ignore
    return this.httpService.delete(id, `api/${this.path}`);
  }

  deleteB(id?: Guid, body?: object, path?: string): Promise<Object> {
    if (path){
      //@ts-ignore
      return this.httpService.deleteWithBody(id, body, `api/${this.path}/${path}`);
    } else {
      //@ts-ignore
      return this.httpService.deleteWithBody(id, body, `api/${this.path}`);
    }
  }

  patch(body: Object, id: Guid): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.httpService.put(body, id, `api/${this.path}`);
  }

  postLogout(id: Guid): Promise<Object> {
    return this.httpService.post({}, `api/${this.path}?userId=${id}`);
  }
}
