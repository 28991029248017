import { HttpClient } from '@angular/common/http';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { Guid } from 'src/app/util/guid';
import { AnalitePut, AnalytePost, GetAllAnalytes, GetByIdAnalytes, GetResumeAnalytes } from './analyte.types';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AnalyteService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/analyte');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllAnalytes>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetAllAnalytes>>
  }

  override getById(id: Guid){
    return super.getById(id) as Promise<HttpResponsePageable<GetByIdAnalytes>>
  }

  override getResume(){
    return super.getResume() as Promise<HttpResponsePageable<GetResumeAnalytes>>
  }

  override post(body: AnalytePost) {
    return super.post(body) as Promise<HttpResponsePageable<AnalytePost>>
  }

  override put(body: object, id?: Guid | undefined, path?: string | undefined) {
    return super.put(body, id, path) as Promise<HttpResponsePageable<AnalitePut>>    
  }
}
