import { Component, OnInit, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ForgotPasswordService } from '@app/services/auth/forgot-password.service';
import { UserLoginService } from '@app/services/user-login/user-login.service';
import { AccessType, AccessTypePTBR } from '@app/util/EAccessType';
import decodedToken from '@app/util/Token';
import { SwAlSetttings } from '@app/util/swal.settings';
import { JwtHelperService } from '@auth0/angular-jwt';
import Swal from 'sweetalert2';
const helper = new JwtHelperService();

interface ListAccess {
  description: string
}

@Component({
  selector: 'app-external-access',
  templateUrl: './external-access.component.html',
  styleUrls: ['./external-access.component.scss']
})

export class ExternalAccessComponent implements OnInit {

  // #region AccessType
  stringAccessType = new UntypedFormControl(0);
  customerAccess: boolean = true;
  accessType = 1;
  selectedOptionIndex = 0;
  needTokenValidation: boolean = false;

  listAccess: Array<ListAccess> = [
    {
      description: "Acesso Cliente",
    },
    {
      description: "Médico / Solicitante",
    },
    {
      description: "Convênio",
    }
    /*,
    {
      description: "Posto de Coleta",
    },
    {
      description: "Plano",
    },
    {
      description: "Destino",
    },
    {
      description: "Local de Coleta",
    }
    */
  ];
  // #endregion AccessType

  // #region User Inputs
  form = {
    accessCode: new UntypedFormControl(null),
    password: new UntypedFormControl(null),
    birth: new UntypedFormControl(null),
    accessToken: new UntypedFormControl(null),
  }
  // #endregion

  // #region Recover
  recoverPass: boolean = false;
  hasRecoveryCode: boolean = false;
  recoverAccess = new UntypedFormControl(null);

  formRecover = {
    accessCode: new UntypedFormControl(null, [Validators.required]),
    token: new UntypedFormControl(null, [Validators.required]),
    newPassword: new UntypedFormControl(null, [Validators.required]),
    confirmPassword: new UntypedFormControl(null, [Validators.required])
  }
  // #endregion

  registerRequisitions: Array<Object> = [];

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'Enter' && !this.recoverPass && !this.hasRecoveryCode) {
      this.login();
    } else if (event.code === 'Enter' && this.recoverPass && !this.hasRecoveryCode) {
      this.recover();
    } else if (event.code === 'Enter' && this.recoverPass && this.hasRecoveryCode) {
      this.recover(true);
    }
  }

  constructor(
    private _userLoginService: UserLoginService,
    private _router: Router,
    private _forgotPasswordService: ForgotPasswordService
  ) { }

  ngOnInit() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessType');
    localStorage.removeItem('userImage');
    localStorage.removeItem('TenantId');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessType');
    sessionStorage.removeItem('userImage');
    sessionStorage.removeItem('TenantId');
  }

  chooseAccessType(accessType: ListAccess, index: number) {
    this.selectedOptionIndex = index;

    this.stringAccessType.setValue(accessType);

    switch (accessType.description) {
      case "Acesso Cliente":
        this.accessType = AccessType.Customer
        break;
      case "Médico / Solicitante":
        this.accessType = AccessType.Doctor
        break;
      case "Convênio":
        this.accessType = AccessType.Agreement
        break;
      case "Posto de Coleta":
        this.accessType = AccessType.Unity
        break;
      case "Plano":
        this.accessType = AccessType.Plan
        break;
      case "Destino":
        this.accessType = AccessType.Destiny
        break;
      case "Local de Coleta":
        this.accessType = AccessType.CollectionPlace
        break;
      default:
        break;
    }

    if (this.accessType === AccessType.Customer) {
      this.customerAccess = true;
    } else {
      this.customerAccess = false;
    }
  }

  async login() {
    if (this.form.accessCode.value && this.form.password.value) {
      if (this.accessType == AccessType.Customer && !this.form.birth.value) {
        setTimeout(() => {
          SwAlSetttings.printMessageError("Data de Nascimento é obrigatório para o acesso de Cliente!")
        }, 100);
      } else {

        let customerObject = {
          accessType: this.accessType,
          accessCode: this.form.accessCode.value,
          password: this.form.password.value,
          birthDate: this.accessType === AccessType.Customer ? new Date(this.form.birth.value).toISOString() : null,
          accessToken: this.form.accessToken.value
        };

        let object = {
          accessType: this.accessType,
          accessCode: this.form.accessCode.value,
          password: this.form.password.value,
          accessToken: this.form.accessToken.value
        };

        await this._userLoginService.post(this.accessType === AccessType.Customer ? customerObject : object).then(
          async (res) => {
            if (res.success) {
              sessionStorage.setItem('token', res.accessToken ? res.accessToken : '');
              sessionStorage.setItem('refreshToken', res.refreshToken ? res.refreshToken : '');
              sessionStorage.setItem('TenantId', res.tenantId ? res.tenantId : '');
              sessionStorage.setItem('accessType', res.accessType.toString() ? res.accessType.toString() : '');
              let accessType = res.accessType;
              if (accessType) {
                window.location.href = `Externo/${AccessTypePTBR[res.accessType]}`;
              } else {
                SwAlSetttings.printMessageError("Não foi possível acessar os resultados!");
              }
            }
          }
        ).catch(
          (err) => {
            if (err.error.accessToken == 'validation required') {
              this.needTokenValidation = true;
              SwAlSetttings.Sucesso("Por favor, valide o token recebido!");
            } else {
              SwAlSetttings.printError(err);
            }
          }
        );
      }
    } else {
      setTimeout(() => {
        SwAlSetttings.printMessageError("Usuário e Senha são obrigatórios!")
      }, 100);
    }
  }

  back(recoveryCode?: boolean) {
    if (!recoveryCode) {
      this.form.accessCode.setValue(null)
      this.form.password.setValue(null)
      this.form.birth.setValue(null)
      this.recoverPass = !this.recoverPass
    } else {
      this.form.accessCode.setValue(null)
      this.form.password.setValue(null)
      this.form.birth.setValue(null)
      this.hasRecoveryCode = !this.hasRecoveryCode
    }
  }

  async recover(changePassword?: boolean) {
    let recoverPasswordObject = {
      "typeForgotPassword": 0,
      "token": "",
      "newPassword": "",
      "confirmPassword": "",
      "identification": this.recoverAccess.value,
      "resendToken": true
    }

    let changePasswordObject = {
      "typeForgotPassword": 1,
      "token": this.formRecover.token.value,
      "newPassword": this.formRecover.newPassword.value,
      "confirmPassword": this.formRecover.confirmPassword.value,
      "identification": this.formRecover.accessCode.value,
      "resendToken": true
    }

    await this._forgotPasswordService.post(changePassword ? changePasswordObject : recoverPasswordObject).then(
      (res) => {
        this.hasRecoveryCode = true;
        this.recoverPass = true;

        //@ts-ignore
        if (res.httpStatusCode === 400 && res.errors.length > 0) {
          //@ts-ignore
          SwAlSetttings.Sucesso(res.errors);
        } else {
          changePassword ? SwAlSetttings.Sucesso("Senha alterada com sucesso!") : SwAlSetttings.Sucesso("Olá, se suas credênciais estiverem corretas você receberá um sms contendo um token para validação e alteração da sua senha!");

          if (changePassword) {
            this.hasRecoveryCode = false;
            this.recoverPass = false;
          }
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  recoveryCode() {
    if (!this.hasRecoveryCode) {
      this.hasRecoveryCode = true;
    } else {
      this.hasRecoveryCode = false;
    }
  }
}