import { JwtHelperService } from '@auth0/angular-jwt';
import { ForgotPasswordService } from 'src/app/services/auth/forgot-password.service';
import { Component, NgModule, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from "@angular/forms";
import { AuthService } from "src/app/services/auth/auth-service.service";
import { SwAlSetttings } from "src/app/util/swal.settings";
import { UserTenantsService } from '@app/services/auth/auth-user-tenants.service';
import { UserService } from '@app/services/user/user.service';
import { infosResume, login, unities } from './login.model';
const helper = new JwtHelperService();



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  public form = {
    username: new UntypedFormControl(null, [Validators.required]),
    password: new UntypedFormControl(null, [Validators.required]),
    validation: new UntypedFormControl(null, [Validators.required]),
    idEmailCpf: new UntypedFormControl(null, [Validators.required]),
    chooseTenant: new UntypedFormControl(null, [Validators.required]),
    chooseUnity: new UntypedFormControl(null, [Validators.required]),
    chooseCompany: new UntypedFormControl(null, [Validators.required]),
  }

  public validation: boolean = false;
  public forgotPassword: boolean = false;
  public multipleTenant: boolean = false;
  public multipleUnitys: boolean = false;
  public tenantsOfUser: Array<object> = [];
  public companyOptions: Array<Object> = [];
  public unityOptions: Array<Object> = [];

  constructor(
    private _authService: AuthService,
    private _forgotPasswordService: ForgotPasswordService,
    private _userTenantsService: UserTenantsService,
    private _userService: UserService,
  ) { }

  ngOnInit(): void {
    localStorage.removeItem('TenantId');
    localStorage.removeItem('user');
    localStorage.removeItem('userImage');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('admin');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessType');
    sessionStorage.removeItem('userImage');
    sessionStorage.removeItem('TenantId');
  }

  ResendToken() {
    this.form.username.value ? this.PasswordForgot(this.form.username.value) : setTimeout(() => { SwAlSetttings.Error('Preencha o Código de Acesso Para Reenviar o Token!'); }, 1500);
  }

  async PasswordForgot(accessCode: string) {
    const form = {
      typeForgotPassword: 0,
      identification: this.form.idEmailCpf.value,
    };

    if (accessCode && !this.forgotPassword) form.identification = accessCode

    await this._forgotPasswordService.post(form).then((x: any) => {
      SwAlSetttings.Sucesso('Código enviado por SMS!')
      window.location.href = '/EsqueciMinhaSenha';
    }).catch((x: ErrorEvent) => {
      SwAlSetttings.printError(x)
    });
  }

  async Login(tenantSelected?: string) {
    localStorage.removeItem('company')
    localStorage.removeItem('unity')

    const form: login = {
      accessCode: this.form.username.value,
      password: this.form.password.value,
    } as login;

    if(tenantSelected) form.tenantId = tenantSelected;

    if(this.form.chooseUnity.value && this.unityOptions.length > 0) {
      form.unityId = this.form.chooseUnity.value
    }

    if(this.multipleUnitys) {
      form.companyId = this.form.chooseCompany.value
    }

    if (this.form.validation.value) form.codeToken = this.form.validation.value

    if(this.form.username.value && this.form.password.value) {
      await this._authService.post(form).then(async (x: any) => {
        if(x.success) {
          await this.getTenantsUser();
          localStorage.setItem('token', x.accessToken ? x.accessToken : '');
          localStorage.setItem('refreshToken', x.refreshToken ? x.refreshToken : '');
          const decodedToken = helper.decodeToken(x.accessToken);
          localStorage.setItem('TenantId', decodedToken.tid.replace(';', ''));
          window.location.href = '/';

          if(this.multipleUnitys) {
            if(this.form.chooseUnity.value) {
              let unityInfos = this.unityOptions.filter((x: any) => x.value === this.form.chooseUnity.value)[0]

              localStorage.setItem('unity', JSON.stringify(unityInfos))
            }
            let companyInfos: infosResume = this.companyOptions.filter((x: any) => x.value === this.form.chooseCompany.value)[0] as infosResume

            companyInfos = {
              name: companyInfos.label,
              id: companyInfos.value
            } as infosResume

            localStorage.setItem('company', JSON.stringify(companyInfos))

          }
        }
      }).catch((x) => {
        if(x.error.multipleTenant) {
          this.multipleTenant = true;
          this.getTenantsUser()
        } else {
          if(x.error.accessToken == "validation required") {
            this.validation = true;
            SwAlSetttings.Sucesso(x.error.errors)
          } else if(x.error.multipleCompanyUnities && x.error.httpStatusCode === 302) {
            this.multipleUnitys = true;
            this.getCompany(x.error.id);
          } else {
            SwAlSetttings.printError(x);
          }
        }

      });
    }

  }

  async getTenantsUser() {
    this.tenantsOfUser = []
    localStorage.removeItem('tenantsOfUser')

    await this._userTenantsService.getPagination({accessCode:this.form.username.value}).then((res:any) => {
      res.data.forEach((element:any) => {
          this.tenantsOfUser.push({
            label: element.companyName,
            value: element.id
          })
      });

      localStorage.setItem('tenantsOfUser', JSON.stringify(this.tenantsOfUser))
    })
  }

  password() {
    this.forgotPassword = !this.forgotPassword
  }

  navigateToForgotPassword() {
    window.location.href = '/EsqueciMinhaSenha';
  }

  async getCompany(tenantId?: string) {
    this.companyOptions = [];

    let tenant = this.form.chooseTenant.value ?? tenantId;

    if(this.multipleUnitys && typeof(tenant) === 'string') {
      this._userService.getCompanies(this.form.username.value, tenant).then((res: any) => {
        if(res.data.length > 0) {
          res.data.map((item: any) => {
            this.companyOptions.push({
              value: item.id,
              label: item.companyName,
              unities: item.unities
            })
          })
        }
      });
    }
  }

  filterUnitys() {
    this.unityOptions = [];

    if(this.form.chooseCompany.value) {
      let companys: unities = this.companyOptions.filter((x:any) => x.value === this.form.chooseCompany.value)[0] as unities
      if(companys.unities.length > 0) {
        companys.unities.map((res:any) => {
          this.unityOptions.push({value: res.id, label: res.name})
        })
      }
    }

  }

  clearForms() {
    this.form.chooseCompany.setValue('')
    this.form.chooseTenant.setValue('')
    this.form.chooseUnity.setValue('')
  }
}
