import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { delay } from "rxjs";
import { LoadingService } from "./loading.service";
import { AdminAccesscontrolUserAuth } from "./services/auth/admin/accesscontrol-user-auth.service";

@Component({
   selector: "app-root",
   templateUrl: "./app.component.html",
   styleUrls: ["./app.component.scss"]
})
export class AppComponent {
   title = "Concent";
   admin: boolean = false;
   logged: boolean = false;
   loggedSession: boolean = false;
   loading = false;
   pathName: string = window.location.pathname;
   ignoreLoading: boolean = false;

   constructor(
      public _router: Router,
      private _loading: LoadingService,
      private _accessControlService: AdminAccesscontrolUserAuth
   ) {
      // this.listenToLoading();
      if (localStorage.getItem("token")) {
         // this._accessControlService.loadUsers();
      }
      if (localStorage.getItem("admin")) {
         this.admin = true;
         document.body.className = "Admin";
      } else {
         document.body.className = "";
      }
   }

   listenToLoading(): void {
      this._loading.loadingSub
         .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
         .subscribe((loading) => {
            if (!this.ignoreLoading) {
               this.loading = loading;
            }
         });

      this.ignoreLoading = false;
   }

   ngOnInit(): void {
      this.listenToLoading();
      this.getLogin();
   }

   getLogin() {
      if (localStorage.getItem("token")) {
         this.logged = true;
      } else {
         this.logged = false;
      }

      if (sessionStorage.getItem("token")) {
         this.loggedSession = true;
      } else {
         this.loggedSession = false;
      }
   }

   get isAdminAccess() {
      return this._router.url.includes("Admin");
   }

   get isRedirecAccess() {
      return this._router.url.includes("redirect");
   }

   get wasLogged() {
      return this.logged || this.loggedSession;
   }

   get isExternalOrForgotPassword() {
      return (
         window.location.pathname === "/EsqueciMinhaSenha" ||
         window.location.pathname == "/resultados"
      );
   }
}
