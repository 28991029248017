import { Injectable } from "@angular/core";
import { BaseNewService } from "@app/services/baseNew.service";
import { LoadingNewService } from "@app/loadingNew.service";
import { ApiAuthService } from "@app/modules_new/data/services/api-auth.service";

@Injectable({
   providedIn: "root"
})
export class AccessControlUser extends BaseNewService {
   constructor(HttpBase: ApiAuthService) {
      super(HttpBase, "v1/accesscontrol/user");
      LoadingNewService.SilenceLoading.push(this.path);
   }
}
