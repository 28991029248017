<div class="contentPage">
    <div class="flex flex-between align-center headerResponsive">
        <div class="flex gap-2 align-center titleResponsive">
            <h1>{{ "requisition.title" | translate }}</h1>
            <app-button
                (click)="
                    openDialogModal(
                        formUnity.requisitionUnity.value,
                        formUnity.requisitionUnityName.toString(),
                        form.requisitionCollectionPlace.value,
                        form.companyId.value
                    )
                "
                [placeholder]="'global.new'"
                [matIcon]="'add'"
                [class]="'flex gap-2 align-center'"
            >
            </app-button>
        </div>
        <div class="flex gap-2">
            <div class="flex flex-center gap-2 titleResponsive">
                <select-prop
                    style="max-width: calc(200px * var(--proportional))"
                    (change)="getUnityRequisitions()"
                    [placeholder]="'requisition.companyOptions'"
                    [form]="form.companyId"
                    [options]="companyOptions"
                ></select-prop>
                <select-prop
                    style="max-width: calc(200px * var(--proportional))"
                    [form]="formUnity.requisitionUnity"
                    [placeholder]="'requisition.unityOptions'"
                    [options]="unityOptions"
                    (change)="getRequisitions()"
                ></select-prop>
                <select-prop
                    style="max-width: calc(200px * var(--proportional))"
                    [form]="form.requisitionCollectionPlace"
                    [placeholder]="'requisition.colectionPlaceOption'"
                    [options]="colectionPlaceOption"
                    (change)="getRequisitions()"
                ></select-prop>
            </div>
            <div class="flex">
                <app-filter
                    class="filterResponsive"
                    [cleanButton]="true"
                    (beforeClose)="searchRequisitions()"
                    (clearFilters)="clearForms()"
                >
                    <div class="query">
                        <input-prop
                            #autoFocus
                            class="w-filter"
                            [form]="form.searchByRequisitionNumber"
                            [placeholder]="
                                'requisition.filter.requisitionNumber'
                            "
                        ></input-prop>
                        <input-prop
                            class="w-filter"
                            [form]="form.searchByCustomerName"
                            [placeholder]="'requisition.filter.customerName'"
                        ></input-prop>
                        <input-prop
                            class="w-filter"
                            [placeholder]="'requisition.filter.customerCPF'"
                            [form]="form.searchByCustomerCPF"
                            mask="000.000.000-00"
                        ></input-prop>
                        <input-prop
                            class="w-filter"
                            [placeholder]="
                                'requisition.filter.customerBirthDate'
                            "
                            type="date"
                            [form]="form.searchByCustomerBirthDate"
                        ></input-prop>
                        <input-prop
                            class="w-filter"
                            [placeholder]="'requisition.filter.initialDate'"
                            type="datetime-local"
                            [form]="formDate.initialDate"
                        ></input-prop>
                        <input-prop
                            class="w-filter"
                            [placeholder]="'requisition.filter.finalDate'"
                            type="datetime-local"
                            [form]="formDate.finalDate"
                        ></input-prop>
                    </div>
                    <app-button
                        (click)="searchRequisitions()"
                        class="queryBtn"
                        [placeholder]="'requisition.filter.search'"
                        [matIcon]="'search'"
                        [class]="'flex gap-2 align-center'"
                    ></app-button>
                </app-filter>
            </div>
        </div>
    </div>
    <div class="mt-32">
        <mat-tab-group
            #tabs
            [(selectedIndex)]="matTabIndex"
            (selectedTabChange)="onTabChanged($event)"
        >
            <mat-tab label="Todas as Requisições">
                <app-table
                    [tooltipColumn]="'Divergências'"
                    [ClickInItem]="ActionsExtra"
                    class="w-100"
                    [Dropdown]="true"
                    [Pagination]="true"
                    [backPagination]="true"
                    [sumRecords]="total"
                    [pageNumber]="page"
                    [indexNumber]="index"
                    [Itens]="registerRequisitions"
                    [Actions]="[
                        'Editar',
                        'Excluir',
                        'Relatórios',
                        'Manutenção Avançada',
                        'Efetuar Cópia',
                        'Documentos',
                        'Gerar Ocorrências'
                    ]"
                    (parentFun)="actions($event)"
                    (changePage)="getRequisitions($event)"
                >
                    <ng-template let-rowDetail #header>
                        <app-table
                            *ngIf="registerExams[rowDetail.id]"
                            [noShadow]="true"
                            [Itens]="registerExams[rowDetail.id]"
                        ></app-table>
                    </ng-template>
                </app-table>
            </mat-tab>

            <mat-tab label="Minhas Requisições">
                <app-table
                    [ClickInItem]="ActionsExtra"
                    class="w-100"
                    [Dropdown]="true"
                    [Pagination]="true"
                    [backPagination]="true"
                    [sumRecords]="personRequisitionsTotal"
                    [pageNumber]="personRequisitionsPage"
                    [indexNumber]="personRequisitionsIndex"
                    [Itens]="personRequisitions"
                    [Actions]="[
                        'Editar',
                        'Excluir',
                        'Relatórios',
                        'Manutenção Avançada',
                        'Efetuar Cópia',
                        'Documentos',
                        'Gerar Ocorrências'
                    ]"
                    (parentFun)="actions($event)"
                    (changePage)="getPersonRequisitions($event)"
                >
                    <ng-template let-rowDetail #header>
                        <app-table
                            *ngIf="registerExams[rowDetail.id]"
                            [noShadow]="true"
                            [Itens]="registerExams[rowDetail.id]"
                        ></app-table>
                    </ng-template>
                </app-table>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<ng-template #dialogCopy let-information>
    <div class="flex flex-between w-100 align-center">
        <h1>{{ "requisitionCopy.title" | translate }}</h1>
        <svg
            class="close-svg"
            (click)="close()"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
            />
        </svg>
    </div>
    <div class="flex flex-center gap-2 align-center mt-16">
        <app-button
            (click)="close(); copyRequisiton(true, information.requisitionId)"
            [class]="'btn'"
            [placeholder]="'requisitionCopy.btnTrue'"
        ></app-button>
        <app-button
            (click)="copyRequisiton(false, information.requisitionId)"
            [class]="'btn'"
            [placeholder]="'requisitionCopy.btnFalse'"
        ></app-button>
    </div>
</ng-template>

<ng-template #dialogDocuments let-requisition>
    <div class="flex flex-between w-100 align-center">
        <h1>{{ "requisitionDocuments.title" | translate }}</h1>
        <svg
            class="close-svg"
            (click)="close()"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
            />
        </svg>
    </div>
    <div class="w-100 mt-22">
        <file-prop-multiple
            [formFiles]="requisitionFiles"
            (onDeleteFile)="removeDocumentFromRequisition(requisition, $event)"
        ></file-prop-multiple>
    </div>
    <div class="flex flex-center gap-2 align-center mt-16">
        <app-button
            [class]="'btn'"
            [placeholder]="'global.save'"
            (click)="addDocumentsInRequisition(requisition.id)"
        ></app-button>
        <app-button
            [class]="'btn btn-reverse'"
            [placeholder]="'global.cancel'"
            (click)="close()"
        ></app-button>
    </div>
</ng-template>

<ng-template #dialogModalAllReports let-allReports>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>{{ "requisitionPrintingReports.title" | translate }}</h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <div class="w-100 box">
                <div class="flex align-center gap-2">
                    <checkbox-prop
                        [form]="formRequisitionReports.deliveryProtocolCheckBox"
                    ></checkbox-prop>
                    <span
                        [ngStyle]="{
                            color: formRequisitionReports
                                .deliveryProtocolCheckBox.value
                                ? '#FE7434'
                                : '#404040',
                            'font-weight': formRequisitionReports
                                .deliveryProtocolCheckBox.value
                                ? 700
                                : 500
                        }"
                    >
                        {{
                            "requisitionPrintingReports.protocolCheckBox"
                                | translate
                        }}
                    </span>
                </div>
                <div class="flex align-center gap-2">
                    <checkbox-prop
                        [form]="formRequisitionReports.reportCheckBox"
                    ></checkbox-prop>
                    <span
                        [ngStyle]="{
                            color: formRequisitionReports.reportCheckBox.value
                                ? '#FE7434'
                                : '#404040',
                            'font-weight': formRequisitionReports.reportCheckBox
                                .value
                                ? 700
                                : 500
                        }"
                    >
                        {{
                            "requisitionPrintingReports.reportCheckBox"
                                | translate
                        }}
                    </span>
                </div>
                <div class="flex flex-between gap-2">
                    <div class="flex align-center gap-2">
                        <checkbox-prop
                            [form]="formRequisitionReports.collectTagCheckBox"
                        ></checkbox-prop>
                        <span
                            [ngStyle]="{
                                color: formRequisitionReports.collectTagCheckBox
                                    .value
                                    ? '#FE7434'
                                    : '#404040',
                                'font-weight': formRequisitionReports
                                    .collectTagCheckBox.value
                                    ? 700
                                    : 500
                            }"
                        >
                            {{
                                "requisitionPrintingReports.collectTagCheckBox"
                                    | translate
                            }}
                        </span>
                    </div>
                    <div
                        *ngIf="formRequisitionReports.collectTagCheckBox.value"
                        style="width: 35%; min-width: 160px"
                    >
                        <select-prop
                            [placeholder]="
                                'requisitionPrintingReports.collectTagCheckBoxTrue'
                            "
                            [form]="formTagReport.tagType"
                            [options]="tagTypeOptions"
                        ></select-prop>
                    </div>
                </div>
                <div class="flex align-center gap-2">
                    <checkbox-prop
                        [form]="formRequisitionReports.preparation"
                    ></checkbox-prop>
                    <span
                        [ngStyle]="{
                            color: formRequisitionReports.preparation.value
                                ? '#FE7434'
                                : '#404040',
                            'font-weight': formRequisitionReports.preparation
                                .value
                                ? 700
                                : 500
                        }"
                    >
                        {{
                            "requisitionPrintingReports.preparation" | translate
                        }}
                    </span>
                </div>
            </div>
            <div class="flex flex-center gap-2 mt-32">
                <app-button
                    [class]="'btn'"
                    (click)="
                        printRequisitionReports(
                            allReports.requisitionId,
                            allReports.requisitionNumber
                        )
                    "
                    [placeholder]="'requisitionPrintingReports.btnPrint'"
                >
                </app-button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalAllInstructions let-analytes>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>Preparo</h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <ng-container *ngFor="let analyteInstruction of analytes">
                <div class="mt-32">
                    <h2 class="exameFont">
                        Exame:
                        <strong class="orangeFont">{{
                            analyteInstruction.code
                        }}</strong>
                    </h2>
                    <div class="mt-32">
                        <textarea-prop
                            [readonly]="true"
                            [placeholder]="'Informação de Preparo'"
                            [form]="analyteInstruction.form"
                        ></textarea-prop>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalOccurrency let-analytes>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>Divergências</h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <div class="w-100 mt-22">
                <mat-tab-group #tabs>
                    <mat-tab class="w-100">
                        <ng-template mat-tab-label>
                            <div class="flex align-center gap-1">
                                <h3>Ocorrências</h3>
                            </div>
                        </ng-template>
                        <ng-container>
                            <div>
                                <app-table [Itens]="occurrencies"></app-table>
                            </div>
                        </ng-container>
                    </mat-tab>
                    <mat-tab class="w-100">
                        <ng-template mat-tab-label>
                            <div class="flex align-center gap-1">
                                <h3>Devedor</h3>
                            </div>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalGenerateOccurrency let-occurrency>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>Gerar Ocorrência</h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <div class="mt-32 flex w-100 flex-between gap-2">
                <input-prop
                    class="w-100"
                    [form]="formOcurrency.defaultHour"
                    [placeholder]="'Horário padrão de entrega'"
                    type="time"
                ></input-prop>
                <input-search
                    class="w-100"
                    placeholder="Tipo"
                    [form]="formOcurrency.type"
                    [options]="typeOccurrency"
                ></input-search>
            </div>
            <div class="mt-32 flex w-100">
                <input-prop
                    class="w-100"
                    [form]="formOcurrency.observation"
                    [placeholder]="'Observação'"
                ></input-prop>
            </div>
            <div class="mt-32 flex flex-center">
                <app-button
                    (click)="createOccurrency(occurrency)"
                    [placeholder]="'global.save'"
                    [class]="'flex gap-2 align-center btn'"
                ></app-button>
            </div>
        </div>
    </ng-container>
</ng-template>
