import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { VaccineApplicationService } from '@app/services/vaccines/vaccine-application.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { ResolveApplicationVaccines } from './application-of-vaccines.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { DialogComponent } from '@app/components/form/dialog/dialog.component';

@Component({
  selector: 'app-application-of-vaccines',
  templateUrl: './application-of-vaccines.component.html',
  styleUrls: ['./application-of-vaccines.component.scss']
})
export class ApplicationOfVaccinesComponent implements OnInit {

  @ViewChild(DialogComponent) dialog!: DialogComponent

  editId: string = '';

  total: number = 0;
  page: number = 1;
  index: number = 50;

  itens: Array<ResolveApplicationVaccines> = []

  filter = {
    Search: new UntypedFormControl(),
    isActive: new UntypedFormControl(false)
  }

  form = {
    description: new UntypedFormControl(''),
    active: new UntypedFormControl(false)
  }

  resolveVaccineApplication: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Descrição",
      method: '',
      retrive: "description",
      after: '',
      before: '',
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ]

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  constructor(
    private _dialog: MatDialog,
    private _vaccineApplicationService: VaccineApplicationService,
    private _dataPipe: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe
  ) { }

  ngOnInit() {
    this.get()
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let search: string = this.filter.Search.value ? this.filter.Search.value : null;

    let isActive = this.filter.isActive.value ? this.filter.isActive.value: null;

    await this._vaccineApplicationService.getPagination({page: this.page, index: this.index, search, isActive}).then(
      (res:any) => {
        this.itens = [];
        this.total = res.sumRecords;
        if(res.data.length > 0) {
          this.resolve(res.data, this.resolveVaccineApplication);
        }
      }
    ).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  getActive(item: any) {
    return this._activePipe.transform(item.isActive)
  }

  getDate(item: any) {
    return this._dataPipe.transform(item.updatedAt);
  }

  resolve(data: any, columns: any) {
    this.itens = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.itens.push(obj);
    });
  }


  setValueOnForms(itens: any) {
    this.form.description.setValue(itens.Descrição)
    this.form.active.setValue(itens.Ativo.includes('active') ? true: false)
  }

  resetForms() {
    this.form.description.setValue('')
    this.form.active.setValue(false)
  }

  post(){
    let obj ={
      description: this.form.description.value
    }

    this._vaccineApplicationService.post(obj).then((res) =>{
      SwAlSetttings.Sucesso('Aplicação Cadastrada!')
      this.get()
      this.dialog.closeDialog()
    }).catch(err => SwAlSetttings.printError(err.error))
  }

  put() {
    let obj = {
      description: this.form.description.value,
      isActive: true
    }

    this._vaccineApplicationService.put(obj, new Guid(this.editId)).then((res:any) => {
      SwAlSetttings.Sucesso('Aplicação Editada!')
      this.get()
      this.dialog.closeDialog()
    }).catch(err => SwAlSetttings.printError(err))
  }

  delete(idToDelete: string) {
    SwAlSetttings.ALERT_CONFIRM.fire('Deseja excluir a Aplicação?').then(
      async (r) => {
        if(r.isConfirmed) {
          this._vaccineApplicationService.delete(new Guid(idToDelete)).then((res:any) => {
            SwAlSetttings.Sucesso('Aplicação Excluída!')
            this.get()
            this.dialog.closeDialog()
        }
      )
    }
    }).catch(err => SwAlSetttings.printError(err))
  }

  putActive(id: string, value: string) {
    this._vaccineApplicationService.patchActive(id, value).then((x:any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.get()
    }).catch(err => SwAlSetttings.printError(err))
  }

  actions(emit: any) {
    switch (emit.action) {
      case 'Editar':
        this.editId = emit.object.id ?? ''
        this.dialog.openDialogModal(true, emit);
        this.setValueOnForms(emit.object)
        break;
      case 'Excluir':
        this.delete(emit.object.id)
        break;
      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  resetFilter(){
    this.filter.Search.reset();
    this.filter.isActive.reset();
  }

  afterClose() {
    this.resetForms()
    this.editId = ''
  }

}
