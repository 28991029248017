import { AsyncPipe } from "@angular/common";
import { Component, inject, Input, OnInit, ViewChild } from "@angular/core";
import { PageableComponent } from "@app/modules_new/common/components/pageable";
import { PaginationTableComponent } from "@app/modules_new/shared/components/form/table/pagination/pagination.component";
import { TableFormComponent } from "@app/modules_new/shared/components/form/table/table.component";
import { ContentFilterLayoutComponent } from "@app/modules_new/shared/layouts/content-filter/content-filter.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, FormGroup } from "@angular/forms";
import { PaginationEvent } from "@app/modules_new/shared/components/form/table/pagination/pagination.model";
import { tableActions, tableColumns } from "./permission-groups-ui";
import { GroupsFacade } from "../../data/facade/groups.facade";
import { PermissionGroupsModal } from "./permission-groups-ui-modal/permission-groups-ui-modal.component";

@Component({
   selector: "permission-groups-ui",
   templateUrl: "./permission-groups-ui.component.html",
   standalone: true,
   imports: [
      ContentFilterLayoutComponent,
      TranslateModule,
      TableFormComponent,
      PaginationTableComponent,
      AsyncPipe,
      PermissionGroupsModal
   ]
})
export class PermissionGroupsComponent
   extends PageableComponent<any[]>
   implements OnInit
{
   constructor() {
      super(tableColumns, tableActions);
   }

   @Input() group!: any;

   @ViewChild("permissions") permissionsTable!: TableFormComponent;

   private _dialog = inject(MatDialog);
   private _groupsFacade = inject(GroupsFacade);

   formFilterGroup = new FormGroup({
      description: new FormControl(null)
   });

   formFilter: Array<object> = [
      {
         placeholder: "Descrição",
         type: "text",
         form: this.formFilterGroup.controls["description"],
         click: true
      }
   ];

   ngOnInit() {
      this.get();
   }

   get(search?: boolean) {
      this.page = search === true ? 1 : this.page;
      const { data$, total$ } = this._groupsFacade.getById(this.group.id);
      this.data = data$;
      this.total = total$;
   }

   ngAfterViewInit() {
      this.permissionsTable.userInteractions$.subscribe((response) => {
         switch (response?.eventType) {
            case "IS_ACTIVE_CHANGE":
               this._groupsFacade
                  .patchActive(
                     response.payload.data.id,
                     response.payload.data.isActive
                  )
                  .then(() => this.get());
               break;
            case "ACTIONS_PRESSED":
               switch (response.payload.event) {
                  case "DELETE": {
                     this._groupsFacade.delete(response.payload.data.id);
                  }
               }
               break;
         }
      });
   }

   openModal(data?: any) {
      const dialog = this._dialog.open(PermissionGroupsModal, {
         width: "calc(900px * var(--proportional))",
         data: this.group.id
      });
      dialog.afterClosed().subscribe(() => {
         this.get();
      });
   }

   changePage(event: PaginationEvent) {
      this.page = event.page;
      this.numberRegistry = event.numberRegistry;
      this.get();
   }

   resetFilter() {
      this.formFilterGroup.controls.description.reset(null);
   }
}
