<ng-template #cameraModal>
    <div class="flex flex-between w-100 align-center">
        <h1>{{'file-prop-webcam.modal-title' | translate}}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
    <div class="w-100 flex flex-center mt-32 video-container" [ngClass]="{'border': isCaptured}">
        <video #video id="video" [width]="WIDTH" [height]="HEIGHT" autoplay></video>
    </div>
    <div class="w-100 flex flex-center gp-12 mt-32" *ngIf="!error">
        <button class="btn" *ngIf="!isCaptured" (click)="capture()">{{'file-prop-webcam.take-photo' | translate}}</button>
        <button class="btn" *ngIf="isCaptured" (click)="sendImage()">{{'file-prop-webcam.send' | translate}}</button>
        <button class="btn" *ngIf="isCaptured" (click)="removeCurrent()">{{'file-prop-webcam.take-another' | translate}}</button>
    </div>
    <div *ngIf="error">
        {{error}}
    </div>
</ng-template>

<div class="container w-100" style="max-width: 200px;">
    <div class="image-container w-100" *ngIf="imageSrc">
        <img #image [src]="imageSrc" class="w-100" alt="Decoded Image">
    </div>
    <div class="svg-container" *ngIf="!imageSrc">
        <svg class="w-100"
            viewBox="0 0 206 206" preserveAspectRatio="xMidYMid meet" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M103 120.564C123.479 120.564 140.08 103.963 140.08 83.4842C140.08 63.0055 123.479 46.4042 103 46.4042C82.5213 46.4042 65.92 63.0055 65.92 83.4842C65.92 103.963 82.5213 120.564 103 120.564Z" fill="#DDDDDD"/>
            <path d="M166.535 0H39.4653C17.7811 0 0 17.7811 0 39.4653V166.535C0 188.219 17.7811 206 39.4653 206H166.535C188.219 206 206 188.219 206 166.535V39.4653C206 17.7811 188.219 0 166.535 0ZM186.701 166.535C186.701 177.594 177.811 186.484 166.752 186.484H165.017C164.149 159.379 154.608 135.96 135.526 123.817C134.659 123.166 133.358 123.383 132.491 124.034C124.251 130.105 114.059 133.792 103 133.792C91.9411 133.792 81.7495 130.105 73.5095 124.034C72.6421 123.383 71.341 123.383 70.4737 123.817C51.6084 135.96 41.8505 159.379 41.2 186.701H39.4653C28.4063 186.701 19.5158 177.811 19.5158 166.752V39.4653C19.5158 28.4063 28.4063 19.5158 39.4653 19.5158H166.535C177.594 19.5158 186.484 28.4063 186.484 39.4653V166.535H186.701Z" fill="#DDDDDD"/>
        </svg>
    </div>
    <button class="camera-button" (click)="openCamera()" matTooltip="Adicionar Foto">
        <mat-icon>add_a_photo</mat-icon>
    </button>
</div>
