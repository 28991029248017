import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'select-state',
  templateUrl: './select-state.component.html',
  styleUrls: ['./select-state.component.scss']
})
export class SelectStateComponent implements OnInit {


  @Input() type : string = 'text';
  //@ts-ignore
  @Input() form: FormControl;
  @Input() placeholder : string = '';
  @Input() name : string = '';
  @Input() label : string = '';
  @Input() mask : any = '';
  @Input() className : string = '';
  @Input() requiredInput : string = '';
  @Input() tabindexcomponent : string = '';
  @Input() pattern : string = '';
  options : Array<any> = [
    {
      label:'',
      value:'',
    }
  ];

  @Input() readonly : boolean = false;

  constructor() { }

  onChange(value : any) {
    if(this.type == 'number') {
      this.form.setValue(parseInt(value));
    } else {
      this.form.setValue(value);
    }
  }

  ngOnInit(): void {
  }
}
