import { Component, OnInit } from '@angular/core';
import { Materials } from './materials-report.module';
import { HeaderMapping, ReportFilter } from '@app/modules/reports/reports-model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DefaultFormats, LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { Util } from '@app/util/util';
import { HttpClient } from '@angular/common/http';
import { TenantAuthService } from '@app/services/auth/tenantAuth/tenant-auth.service';
import { SwAlSetttings } from '@app/util/swal.settings';
import { RegistrationReportComponent } from '@app/components/form/registration-report/registration-report.component';
import { ReportMaterialService } from '@app/services/report-material/report-Material.service';

@Component({
  selector: 'app-materials-report',
  templateUrl: './materials-report.component.html',
  styleUrls: ['./materials-report.component.scss']
})
export class MaterialsReportComponent implements OnInit {

  index: number = 50;
  page: number = 1;
  total: number = 0;

  receivedData!: Materials[];

  materials: Array<Materials> = [];

  isActiveOptions: Array<{ label: string, value: string | null | boolean }> = [{ label: 'Todos', value: 'todos' }, { label: 'Somente Ativos', value: true }, { label: 'Somente Inativos', value: false }]
  receivedDataForPrint!: object[];
  reportDescription: string = 'Relatório de Cadastro de Materiais';
  sumRecords!: number;
  reportObject: Array<Object> = [];

  headerMapping: HeaderMapping = {
    description: { title: 'Material', width: '*' },
    createdAt: { title: 'Criado em', width: 'auto' },
    updatedAt: { title: 'Atualizado em', width: 'auto' },
    isActive: { title: 'Ativo', width: 'auto' }
  };

  reportFilters: ReportFilter[][] = [];

  filter = new UntypedFormGroup ({
    search: new UntypedFormControl(null),
    isActive: new UntypedFormControl('todos')
  })

  resolveMateriais = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Material',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'createdAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'updatedAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'isActive',
      type: 'boolean'
    }
  ]

  constructor(
    private _reportMaterialService: ReportMaterialService,
    private _util: Util,
    private _localeDate: LocalaDatePipe,
    private _http: HttpClient,
    private _tenantAuth: TenantAuthService,
  ) { }

  async ngOnInit() {
    await this.getMaterials();
  }

  async getMaterials(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    const filterObject = {
      description: this.filter.controls['search'].value ?? null,
      isActive: this.filter.controls['isActive'].value === 'todos' ? null : JSON.parse(this.filter.controls['isActive'].value)
    };

    await this._reportMaterialService.getPagination<Materials>(filterObject).then(
      (res) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.receivedData = res.data;
          this.resolve(res.data, this.resolveMateriais);
        } else {
          SwAlSetttings.alertaMsg('Nenhum cadastro de material encontrado!');
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.materials = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.materials.push(obj);
    });
  }

  async generatePdf() {
    await this.getDataForPrint();

    this.reportFilters = []
    var filters = [];
    var filterActive = 'Todos';
    if (this.filter.controls['isActive'].value != 'todos'){
      if (this.filter.controls['isActive'].value == 'true'){
        filterActive = 'Somente Ativos';
      } else {
        filterActive = 'Somente Inativos';
      }
    }   

    var obj = {
      title: 'Ativos: ', content : filterActive
    }
    
    filters.push(obj);

    if (this.filter.controls['search'].value){
      var obj2 = {
        title: 'Descrição: ', content : this.filter.controls['search'].value
      }
      
      filters.push(obj2);
    }  

    this.reportFilters = [filters];

    const reportComponent = new RegistrationReportComponent(this._http, this._localeDate, this._tenantAuth);

    reportComponent.headerMapping = this.headerMapping;
    reportComponent.reportFilters = this.reportFilters;
    reportComponent.receivedData = this.receivedDataForPrint;
    reportComponent.reportDescription = this.reportDescription;
    reportComponent.sumRecords = this.sumRecords;

    await reportComponent.ngOnInit();
  }

  async getDataForPrint() {
    this.reportObject = [];
    const queryObject = {
      search: this.filter.controls['search'].value,
      isActive: this.filter.controls['isActive'].value === 'todos' ? null : JSON.parse(this.filter.controls['isActive'].value)
    };
    await this._reportMaterialService.getPagination<Materials>(queryObject).then(
      (res) => {
        res.data.forEach((x) =>{
          const obj = {
            description: x.description,
            isActive: x.isActive ? 'Ativo': 'Inativo',
            createdAt: x.createdAt,
            updatedAt: x.updatedAt
          }
          this.reportObject.push(obj)
        })
        this.sumRecords = res.sumRecords;
        this.receivedDataForPrint = this.reportObject;
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  resetFilter(){
    this.filter.controls['search'].reset();
    this.filter.controls['isActive'].reset('todos');
  }

}
