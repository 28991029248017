import { DefaultFormats } from "@app/pipe/localeDate.pipe";

export interface SectorActions{
  action: string
  object: SectorObject
}

interface SectorObject{
  "id": string
  "hospitalSectors.code": string
  "hospitalSectors.description": string
  "Criado em": string
  "Alterado em": string
  "Ativo": string
}

export const resolveSector: Array<Object> = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'hospitalSectors.code',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'externalId',
      type: 'description',
      sort: 'name'
    },
    {
      label: "hospitalSectors.description",
      method: 'formatField',
      retrive: '',
      after: '',
      before: '',
      fieldName: 'description',
      type: 'description',
    },
    {
      label: 'hospitalSectors.createdAt',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'createdAt',
      type: 'date',
      dateFormat: DefaultFormats.full,
      sort: 'createdAt'
    },
    {
      label: 'hospitalSectors.updatedAt',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'updatedAt',
      type: 'date',
      dateFormat: DefaultFormats.full,
      sort: 'updatedAt'
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'isActive',
      type: 'checkbox',
      sort: 'isActive'
    },
  ];