import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "@app/services/base.service";
import { HttpService } from "@app/services/http.service";


@Injectable({ 
    providedIn: 'root' 
})
export class CustomersDocumentsService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/customer/customerdocuments');
  }
}