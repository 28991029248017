export interface Actions {
    action: string,
    context: string | null,
    object: ResolveDoctors
}

export interface ResolveDoctors {
    "Conselho": string,
    "E-mail": string,
    "Nome": string,
    "Situação": string,
    "Telefone": string,
    "id": string
}

export const resolveDoctors = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: '',
      sort: '',
    },
    {
      label: 'global.title-list.code',
      retrive: '',
      method: 'getExternalId',
      after: '',
      before: '',
      sort: 'externalId',
    },    
    {
      label: 'global.title-list.name',
      retrive: 'name',
      method: '',
      after: '',
      before: '',
      sort: 'name',
    },
    {
      label: 'global.title-list.council',
      retrive: '',
      method: 'getCrm',
      after: '',
      before: '',
      sort: 'council',
    },
    {
      label: 'global.title-list.cellphone-number',
      retrive: '',
      method: 'cellPhone',
      after: '',
      before: '',
      sort: 'cellPhone',
    },
    {
      label: 'global.title-list.office-phone',
      retrive: '',
      method: 'officePhone',
      after: '',
      before: '',
      sort: 'officePhone',
    },
    {
      label: 'global.title-list.email',
      retrive: '',
      method: 'getEmail',
      after: '',
      before: '',
      sort: 'email',
    },
    {
      label: 'global.title-list.created-at',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: '',
      sort: 'createdAt',
    },
    {
      label: 'global.title-list.updated-at',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: '',
      sort: 'updatedAt',
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: '',
      sort: 'isActive',
    }
  ];