<div class="contentPage">
  <div class="flex flex-between align-center mb-32 headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
      <h1>{{'priceTable.title' | translate}}</h1>
      <app-button (click)="openDialogPriceTable(idPriceTable)" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="getPriceTables()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus (keyup.enter)="getPriceTables()" [form]="filter.controls['search']" [placeholder]="'priceTable.search'"></input-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" [label]="'priceTable.isActive'" [form]="filter.controls['isActive']"></checkbox-prop>
      </div>
      <app-button (click)="getPriceTables()" class="queryBtn" [placeholder]="'priceTable.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <app-table [Dropdown]="true" [ClickInItem]="ActionsExtra" [Actions]="['Editar' , 'Excluir']" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [indexNumber]="index" [pageNumber]="page" (changePage)="getPriceTables($event)" [Itens]="registerTable" (parentFun)="actions($event)">
    <ng-template let-rowDetail #header>
      <div class="flex flex-between align-center mb-32">
        <div class="flex align-center gap-2 titleResponsive">
        <h2>{{'priceTable.titleExams' | translate}}</h2>
        <app-button (click)="openDialogExams()" [placeholder]="'priceTable.btnNew'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
        </div>
        <app-filter class="filterResponsive" (beforeClose)="getByIdExams(rowDetail.id)" [cleanButton]="true" (clearFilters)="resetFilter()">
          <div class="query">
            <input-prop #autoFocus (keyup.enter)="getByIdExams(rowDetail.id)" [form]="filterPriceExames.controls['AnalyteName']" [placeholder]="'priceTable.analyteName'"></input-prop>
            <input-prop (keyup.enter)="getByIdExams(rowDetail.id)" [form]="filterPriceExames.controls['AnalyteCode']" [placeholder]="'priceTable.analyteCode'"></input-prop>
            <checkbox-prop class="flex flex-center" [gap]="1" [label]="'priceTable.isActive'" [form]="filterPriceExames.controls['isActive']"></checkbox-prop>
          </div>
          <app-button (click)="getByIdExams(rowDetail.id)" class="queryBtn" [placeholder]="'priceTable.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
        </app-filter>
      </div>
      <div class="box mb-32">
        <app-table [ClickInItem]="ActionsExtraPrice" [Actions]="['Editar' , 'Excluir']" [backPagination]="true" [Pagination]="true" [sumRecords]="generalListExams1[rowDetail.id]?.total" [indexNumber]="generalListExams1[rowDetail.id]?.index ?? 10" [pageNumber]="generalListExams1[rowDetail.id]?.page" [Itens]="generalListExams1[rowDetail.id]?.priceTableExams ?? []" (parentFun)="examsActions($event)" (changePage)="getByIdExams(rowDetail.id, $event)" [noShadow]="true"></app-table>
      </div>
    </ng-template>
  </app-table>
</div>

<ng-template #dialogCreatePrice let-editPrice>
  <ng-container>
    <div class="flex flex-between">
      <h1 *ngIf="!editPrice">{{'priceTable.titleNewTable' | translate}}</h1>
      <h1 *ngIf="editPrice">{{'priceTable.titleEditTable' | translate}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="flex flex-column mt-32 gap-3">
      <input-prop [placeholder]="'priceTable.description'" [form]="formPriceTable.description"></input-prop>
      <input-prop [placeholder]="'priceTable.mask'" [form]="formPriceTable.mask"></input-prop>
      <input-prop (keyup.enter)="!editPrice ? createPriceTable() : updatePriceTable(editPrice)" [placeholder]="'priceTable.index'" [form]="formPriceTable.index"></input-prop>
    </div>
    <div class="mt-32 flex flex-center">
      <app-button (click)="!editPrice ? createPriceTable() : updatePriceTable(editPrice)" [placeholder]="'global.save'" [class]="'btn'"></app-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogCreateExams let-editExam>
  <ng-container> 
    <div class="flex flex-between">
      <h1 *ngIf="!editExam">{{'priceTable.titleNewExam' | translate}}</h1>
      <h1 *ngIf="editExam">{{'priceTable.titleEditExam' | translate}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="flex flex-column mt-32 gap-3">
      <div class="flex flex-between mt-24 gap-2">
        <input-prop (keyup.enter)="setFocus()" [readonly]="editExam" [placeholder]="'priceTable.analyteCode'" [form]="formExams.controls['code']" (change)="analyteCodeChanged()"></input-prop>
        <input-search *ngIf="!editExam" #analyteIdForm class="w-100" [searchApi]="true" (search)="getExamsOption($event.value)" (changeForm)="analyteIdChanged()" [placeholder]="'priceTable.analyteId'" [options]="optionsExams" [form]="formExams.controls['analyteId']"></input-search>
        <input-prop *ngIf="editExam" class="w-100" [placeholder]="'priceTable.analyteId'" [readonly]="true" [form]="formExams.controls['analyteDescription']"></input-prop>
      </div>
      <input-prop (keyup.enter)="!editExam ? createPriceTableExam() : updatePriceTableExam(editExam)" [placeholder]="'priceTable.billingCode'" [form]="formExams.controls['billingCode']"></input-prop>
      <input-prop-currency [placeholder]="'priceTable.price'" [form]="formExams.controls['price']"></input-prop-currency>
    </div>
    <div class="flex flex-between mt-32 ">
      <checkbox-prop [gap]="1" [label]="'priceTable.isBloqued'" [form]="formExams.controls['isBloqued']"></checkbox-prop>
      <checkbox-prop [gap]="1" [label]="'priceTable.isSuspended'" [form]="formExams.controls['isSuspended']"></checkbox-prop>
    </div>
    <div class="mt-32 flex flex-center">
      <app-button (click)="!editExam ? createPriceTableExam() : updatePriceTableExam(editExam)" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>
