import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getActive"
})
export class GetActiveFilterPipe implements PipeTransform {
  transform(value: boolean): string {
    return value ? 'checkbox|true' : 'checkbox|';
  }
}
