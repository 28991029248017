import { inject, Injectable } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import { ForgotPasswordService } from "../services/forgotPassword/forgotPassword.service";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
   providedIn: "root"
})
export class ForgotPasswordFacade extends MultiObservableFacade {
   private _forgotPasswordService = inject(ForgotPasswordService);
   private _dialog = inject(MatDialog);

   changePassword(obj: any) {
      this._forgotPasswordService.post(obj).subscribe({
         next: (res) => {
            this.ToastSuccess("Senha alterada com sucesso!");
            this._dialog.closeAll();
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }
}
