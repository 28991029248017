import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { AgreementsService } from '@app/services/agreements/agreements.service';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { Guid } from '@app/util/guid';
import { Actions, Get, ResolveAgreements } from './agreements.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { Util } from '@app/util/util';
import { EnumObject } from '@app/services/enum/enum.service.model';
import { ToastService } from '@app/services/translate/toast.service';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})

export class AgreementsComponent implements OnInit {
  constructor(
    private _router: Router,
    private _agreementService: AgreementsService,
    private _createdAt: LocalaDatePipe,
    private _updatedAt: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _util: Util,
    private _toastService: ToastService
  ) { }

  //#region Global Variable / Variáveis Globais
  total: number = 0;
  page: number = 1;
  index: number = 50;
  //#endregion Variáveis globais

  //#region Register and Resolve
  registerAgreement: Array<Object> = [];

  ActionsExtra = {
    "Ativo": "PutActive"
  }
  //#endregion

  searchForm = {
    description: new UntypedFormControl(null),
    typeInvoice: new UntypedFormControl(null),
    controlPlan: new UntypedFormControl(null),
    externalCode: new UntypedFormControl(null),
    isActive: new UntypedFormControl(null)
  }

  typeInvoiceOptions: Array<EnumObject> = []

  async ngOnInit() {
    await this.getAgreements();
    this.typeInvoiceOptions = await this._util.getEnum('ETypeAgreements')
  }

  getInvoice(item: Get) {
    let returnType: string = '';

    switch (item.typeInvoice) {
      case 1:
        returnType = '<span class="span-styles" style="background: #07BF56; color: #FFFFFF;">Particular</span>'
        break;
      case 2:
        returnType = '<span class="span-styles" style="background: #006E9D; color: #FFFFFF;">Faturado</span>'
        break;
    }

    return returnType;
  }

  getIndex(item: Get) {
    return item.index.toFixed(4);
  }

  getControlPlan(item: Get) {
    if (item.controlPlan) {
      return '<span class="span-styles" style="background: #07BF56; color: #FFFFFF;">Sim</span>'
    } else {
      return '<span class="span-styles" style="background: #FF0000; color: #FFFFFF;">Não</span>'
    }
  }

  getExternalCode(item: Get) {
    return item.externalId ?? '';
  }

  createdAt(item: Get) {
    return this._createdAt.transform(item.createdAt);
  }

  updatedAt(item: Get) {
    return this._updatedAt.transform(item.updatedAt);
  }

  async getAgreements(paramPage?: number[]) {
    this.registerAgreement = [];
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let filterObject = {
      Search: this.searchForm.description.value ?? null,
      controlPlan: this.searchForm.controlPlan.value ?? null,
      externalCode: this.searchForm.externalCode.value ?? null,
      typeInvoice: this.searchForm.typeInvoice.value ?? null,
      isActive: this.searchForm.isActive.value ? this.searchForm.isActive.value : null,
      page: this.page,
      numberRegistry: this.index
    }

    await this._agreementService.getPagination(filterObject).then(
      (res) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolve(res.data, ResolveAgreements);
        }
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.registerAgreement = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerAgreement.push(obj);
    });
  }

  navigate() {
    this._router.navigateByUrl('Cadastros/Convênios/Convênio/Novo');
  }

  getActive(item: Get) {
    return this._activePipe.transform(item.isActive)
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this._router.navigateByUrl('Cadastros/Convênios/Convênio/' + emit.object.id + '$Edit');
        break;
      case 'Excluir':
        this.removeAgreement(emit.object.id);
        break;
      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    };
  }

  putActive(id: Guid, value: string) {
    this._agreementService.patchActive(id.toString(), value).then((x: any) => {
      this._toastService.fireSuccess('global.msgSituationSuccess')
      this.getAgreements();
    }).catch(err => this._toastService.fireError(err))
  }

  removeAgreement(agreementId: Guid) {
    this._toastService.fireConfirmation('agreements.msgDelete').then(
      async (r) => {
        if (r.isConfirmed) {
          setTimeout(async () => {
            await this._agreementService.delete(agreementId);
            this._toastService.fireSuccess("agreements.msgDeleteSuccess");
            this.getAgreements();
          });
        }
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    );
  }

  resetFilter() {
    this.searchForm.externalCode.reset();
    this.searchForm.description.reset();
    this.searchForm.controlPlan.reset();
    this.searchForm.typeInvoice.reset();
    this.searchForm.isActive.reset();
  }
}
