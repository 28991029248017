import { Injectable, inject } from "@angular/core";
import { MultiObservableFacade } from "../../../common/data/facades/multi-observable";
import { RequestOptions } from "../../../data/services/types/http.types";
import { MenuItemService } from "../services/menuItem/menuItem.service";
import {
   MenuItemResponse,
   MenuItemsResponse
} from "../services/menuItem/menuItem.types";
import { Guid } from "@app/util/guid";
import { MatDialog } from "@angular/material/dialog";
import { ToastService } from "@app/services/translate/toast.service";
import { lastValueFrom } from "rxjs";

@Injectable({
   providedIn: "root"
})
export class MenuItemFacade extends MultiObservableFacade {
   private _menuItemService = inject(MenuItemService);
   private _dialog = inject(MatDialog);
   private toastService = inject(ToastService);

   private _menuItens =
      this.createNullableObservableControl<MenuItemResponse[]>();
   private _menuItensAmount = this.createObservableControl<number>(0);

   private controls = this.controlGroup({
      menuItens: this.emptyObservable(),
      menuItensAmount: this.emptyObservable()
   });

   createTabsGroup(id: string) {
      this.controls.add(id, {
         menuItens: this.createNullableObservableControl<MenuItemResponse[]>(),
         menuItensAmount: this.createObservableControl<number>(0)
      });
   }

   getSubMenuItens(
      page: number,
      numberRegistry: number,
      id: string,
      search?: RequestOptions
   ) {
      this._menuItemService
         .getPagination<MenuItemsResponse>(page, numberRegistry, search)
         .subscribe({
            next: (response) => {
               this.controls.get(id).menuItens.subject.next(response.data);
               this.controls
                  .get(id)
                  .menuItensAmount.subject.next(response.data.length);
            },
            error: (err) => {
               this.controls.get(id).menuItens.subject.next([]);
            }
         });
      return {
         data$: this.controls.get(id).menuItens.observable$,
         total$: this.controls.get(id).menuItensAmount.observable$
      };
   }

   async getMenuOptions(): Promise<any> {
      try {
         const response: any = await lastValueFrom(
            this._menuItemService.getPagination<MenuItemsResponse>(1, 999999)
         );

         return response;
      } catch (error) {
         this.ToastErrorGeneric(error);
         return null;
      }
   }

   getMenuItens(page: number, numberRegistry: number, search?: RequestOptions) {
      this._menuItemService
         .getPagination<MenuItemsResponse>(page, numberRegistry, search)
         .subscribe({
            next: (response: any) => {
               this._menuItens.subject.next(response.data);
               this._menuItensAmount.subject.next(response.sumRecords);
            },
            error: (error) => {
               this._menuItens.subject.next([]);
               this._menuItensAmount.subject.next(0);
               this.ToastErrorGeneric(error);
            }
         });

      return {
         data$: this._menuItens.observable$,
         total$: this._menuItensAmount.observable$
      };
   }

   patchActive(id: string, value: string) {
      this._menuItemService.patchToggleIsActive(id, !value).subscribe({
         next: () => {
            this.ToastSuccess("toast-msg.change.success");
            this.getMenuItens(1, 30);
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }

   delete(id: string, parentId: string = "") {
      this.toastService
         .fireConfirmation("Deseja realmente excluir esse menu?")
         .then(async (r) => {
            if (r.isConfirmed) {
               this._menuItemService.delete(new Guid(id)).subscribe({
                  next: () => {
                     this.ToastSuccess("Menu excluido com sucesso!");
                     if (parentId == "") {
                        this.getMenuItens(1, 30);
                     } else {
                        const obj: RequestOptions = {
                           query: {
                              parentId: parentId
                           }
                        };

                        this.getSubMenuItens(1, 30, parentId, obj);
                     }
                  },
                  error: (error) => {
                     this.ToastErrorGeneric(error, "toast-msg.creation.error");
                  }
               });
            }
         });
   }

   post(createObj: any) {
      this._menuItemService.post(createObj).subscribe({
         next: () => {
            this.ToastSuccess("Menu criado com sucesso!");
            this._dialog.closeAll();
            if (createObj.parentId) {
               const obj: RequestOptions = {
                  query: {
                     parentId: createObj.parentId
                  }
               };
               this.getSubMenuItens(1, 30, createObj.parentId, obj);
            } else {
               this.getMenuItens(1, 30);
            }
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }

   put(id: string, createObj: any) {
      this._menuItemService.put(createObj, new Guid(id)).subscribe({
         next: () => {
            this.ToastSuccess("Menu atualizado com sucesso!");
            this._dialog.closeAll();
            if (createObj.parentId) {
               const obj: RequestOptions = {
                  query: {
                     parentId: createObj.parentId
                  }
               };
               this.getSubMenuItens(1, 30, createObj.parentId, obj);
            } else {
               this.getMenuItens(1, 30);
            }
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }
}
