import { HttpClient } from "@angular/common/http";
import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { IHttpService } from "../interfaces/IHttpService";
import { Guid } from "../../util/guid";
import token from "../../util/Token";

@Injectable({
   providedIn: "root"
})
export abstract class HttpAuthService implements IHttpService {
   public baseURL: string;
   public headers = {
      "Content-Type": "",
      Authorization: "",
      tenantId: ""
   };

   constructor(protected http: HttpClient) {
      this.baseURL = environment.baseAuthURL;
      this.setHeaders();
   }

   // TODO: Ajustar Normalize
   get(queryString: string, path?: string): Promise<Object> {
      this.setHeaders();
      let parans = Location.normalizeQueryParams(queryString);
      let normalize;
      normalize = `${path}${parans}`;
      const url = `${this.baseURL}${normalize}`;
      const options = { headers: this.headers };
      //@ts-ignore
      return this.http.get(url, options).toPromise();
   }

   post(body: Object, path?: string): Promise<Object> {
      this.setHeaders();
      const url = `${this.baseURL}${path}`;

      const options = { headers: this.headers };
      //@ts-ignore
      return this.http.post(url, body, options).toPromise();
   }

   put(body: Object, id: Guid, path?: string): Promise<Object> {
      this.setHeaders();
      const url = `${this.baseURL}${path}/${id}`;

      const options = { headers: this.headers };
      //@ts-ignore
      return this.http.put(url, body, options).toPromise();
   }

   delete(id: Guid, path?: string): Promise<Object> {
      this.setHeaders();
      let normalize;
      if (id != null) {
         normalize = `${path}/${id}`;
      } else {
         normalize = `${path}`;
      }
      const url = `${this.baseURL}${normalize}`;

      const options = { headers: this.headers };
      //@ts-ignore
      return this.http.delete(url, options).toPromise();
   }

   deleteWithBody(id: Guid, body: Object, path?: string): Promise<Object> {
      this.setHeaders();
      let normalize;
      if (id != null) {
         normalize = `${path}/${id}`;
      } else {
         normalize = `${path}`;
      }
      const url = `${this.baseURL}${normalize}`;

      const options = { headers: this.headers, body: body };
      //@ts-ignore
      return this.http.delete(url, options).toPromise();
   }

   patch(body: Object, id: Guid, path?: string): Promise<Object> {
      this.setHeaders();
      const url = `${this.baseURL}${path}/${id}`;
      const options = { headers: this.headers };

      return this.http.patch(url, body, options).toPromise() as Promise<{}>;
   }

   patchWithPath(body: Object, path: string): Promise<Object> {
      this.setHeaders();
      const url = `${this.baseURL}${path}`;
      const options = { headers: this.headers };

      return this.http.patch(url, body, options).toPromise() as Promise<{}>;
   }

   refreshToken() {
      const url = `${this.baseURL}/profile/user/refresh`;

      return this.http.post(url, {
         accessToken: localStorage.getItem("token"),
         refreshToken: token.refreshToken,
         id: token.sub
      });
   }

   private setHeaders() {
      this.headers["Content-Type"] = "application/json";

      if (localStorage.getItem("token")) {
         //this.headers['Authorization'] = localStorage.getItem('token'); Teste autenticação Concent BackEnd
         // @ts-ignore
         //this.headers['Authorization'] = localStorage.getItem('token');
      }
      if (localStorage.getItem("TenantId")) {
         //@ts-ignore
         this.headers["tenantId"] = localStorage.getItem("TenantId");
      }
      if (sessionStorage.getItem("TenantId")) {
         //@ts-ignore
         this.headers["tenantId"] = sessionStorage.getItem("TenantId");
      }
   }
}
