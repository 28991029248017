import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-b2b-integration',
  templateUrl: './b2b-integration.component.html',
  styleUrls: ['./b2b-integration.component.scss']
})
export class B2bIntegrationComponent implements OnInit {

  resolve: Array<object> = [
    {
      label : 'id',
      retrive: 'id',
      after: '',
      before: ''
    }    
  ]

  register: Array<object> = [
    {
      "id" : "1",
      "Arquivo" : "Exemplo 1",
      "Data e Hora" : "03/05/2023 15:00",
      "Situação" : "Processandro",
      "Erro" : "NÂO"
    },
    {
      "id" : "2",
      "Arquivo" : "Exemplo 2",
      "Data e Hora" : "04/05/2023 8:50",
      "Situação" : "Processandro",
      "Erro" : "NÂO"
    }
  ]

  constructor(private _route: Router) { }

  ngOnInit() {
  }

  redirectIntegracaoB2B(){
    this._route.navigateByUrl("Externo/Convenio");
  }
}