import { Guid } from "@app/util/guid";

export interface Resolve {
  label: string,
  retrive: string,
  method: string,
  after: string,
  before: string
}

export interface ActionsDiscountAuthorization {
  action: string,
  context: string | null,
  object: ResolvedDiscountAuthorization
}

export interface ResolvedDiscountAuthorization {
  "id": Guid,
  "Nr.º": string,
  "Origem da Solicitação": string,
  "Usuário Solicitante": string,
  "Data de Solicitação": string,
  "Valor Total": string,
  "Desconto Solicitado": string,
  "Desconto Concedido": string,
  "Situação": string,
  "Análise": string,
  "Alterado em": string,
  "Criado em": string,
  "Ativo": string,
  "_hide_reason": string
}

export const resolveDiscountsAuthorization: Array<Object> = [ 
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Nr.º',
      retrive: '',
      method: 'getNumber',
      after: '',
      before: ''
    },
    {
      label: 'Origem da Solicitação',
      retrive: '',
      method: 'getType',
      after: '',
      before: ''
    },
    {
      label: 'Usuário Solicitante',
      retrive: 'personName',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Data de Solicitação',
      retrive: '',
      method: 'getSolicitationDate',
      after: '',
      before: ''
    },
    {
      label: 'Valor Total',
      retrive: '',
      method: 'getTotalValue',
      after: '',
      before: ''
    },
    {
      label: 'Desconto Solicitado',
      retrive: '',
      method: 'getValue',
      after: '',
      before: ''
    },
    {
      label: 'Desconto Concedido',
      retrive: '',
      method: 'getValueReturn',
      after: '',
      before: ''
    },     
    {
      label: 'Situação',
      retrive: '',
      method: 'getSituation',
      after: '',
      before: ''
    },   
    {
      label: 'Análise',
      retrive: '',
      method: 'getButton',
      after: '',
      before: ''
    },       
    {
      label: "_hide_reason",
      retrive: 'discountReason',
      method: '',
      after: '',
      before: ''
    }, 
    // {
    //   label: 'Alterado em',
    //   retrive: '',
    //   method: 'getUpdated',
    //   after: '',
    //   before: ''
    // },
    // {
    //   label: 'Criado em',
    //   retrive: '',
    //   method: 'getCreated',
    //   after: '',
    //   before: ''
    // },     
  ];

  export interface GetAllDiscountsAuthorization {
    id: string
    budgetId: string
    budgetNumber: number
    typeDiscount: number
    personId: string
    personName: string
    value: number
    reason: string
    situation: number
    valueReturn: number
    personAuthorizationId: string
    name: string
    isActive: boolean
    createdAt: Date
    updatedAt: Date    
}

export interface GetByIdDiscountsAuthorization {
    id: string
    budgetId: string
    budgetNumber: number
    typeDiscount: number
    personId: string
    personName: string
    value: number
    reason: string
    situation: number
    valueReturn: number
    personAuthorizationId: string
    name: string
    isActive: boolean
    createdAt: Date
    updatedAt: Date    
}

export interface PostDiscountAuthorization {
    typeDiscount: number
    requisitionId: string
    budgetId: string
    personId: string
    value: number
    reason: string
}

export interface PutDiscountAuthorization {
    typeDiscount: number
    requisitionId: string
    budgetId: string
    personId: string
    value: number
    reason: string
}