import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "searchFilter"
})
export class SearchFilterPipe implements PipeTransform {
    transform(value: any, args? : any): any {
        if(!args) return value;
        args = args.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");;
        if (value) {
            return value.filter(function (item : any) {
                return JSON.stringify(item)
                    .toLowerCase()
                    .normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                    .includes(args)
            })
        }
    }
}
