import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FileDocumentService } from '@app/services/files/fileDocument.service';
import { GActions } from '@app/util/GActions';
import { SwAlSetttings } from '@app/util/swal.settings';

@Component({
  selector: 'file-prop-webcam',
  templateUrl: './file-prop-webcam.component.html',
  styleUrls: ['./file-prop-webcam.component.scss']
})
export class FilePropWebcamComponent implements OnInit {

  WIDTH = 640;
  HEIGHT = 480;

  @ViewChild("cameraModal")
  public cameraModal!: TemplateRef<any>;

  @ViewChild("video")
  public video!: ElementRef;

  error: any;
  imageSrc: string | null = null
  isCaptured: boolean = false;
  isCameraOpen: boolean = false;
  private stream: MediaStream | null = null;

  @Output() sendFile = new EventEmitter<any>();
  @Input() form!: FormControl;

  constructor(
    private _dialog: MatDialog,
    private _fileDocumentService: FileDocumentService,
    private _GActions : GActions) { }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(value => {
      if(value) {
        this._fileDocumentService.getById(value).then(
          res => this.drawImage(res.data[0].fileBase64, res.data[0].extension)
        )
      }
    })
  }

  /**
  * Abre a modal com a transmissão da câmera
  */
  async openCamera() {
    this.isCaptured = false
    this._dialog.open(this.cameraModal, {}).afterClosed().subscribe(() => {
      this.stopDevices()
      this.isCameraOpen = false
    })
    await this.setupDevices();
    this.isCameraOpen = true
  }
 
  /**
   * Lista os dispositivos de captura e liga a câmera do usuário
   */
  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: true
        });
        if (this.stream) {
          this.video.nativeElement.srcObject = this.stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = "Nenhum dispositivo de captura de vídeo encontrado!";
        }
      } catch (e) {
        this.error = e;
      }
    }
  }

  close() {
    this._dialog.closeAll()
  }

  /**
   * Desliga os dispositivos de captura
   */
  async stopDevices() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.video.nativeElement.srcObject = null;
      this.stream = null;
    }
  }

  /**
  * Atribui a imagem no lugar do SVG
  */
  drawImage(base64: string, extension: string) {
    this.imageSrc = `data:image/${extension};base64,${base64}`;
  }

  /**
  * Pausa a transmissão/vídeo
  */
  capture() {
    this.isCaptured = true;
    this.video.nativeElement.pause();
  }

  /**
  * Retoma a transmissão/vídeo
  */
  removeCurrent() {
    this.isCaptured = false;
    this.video.nativeElement.play();
  }

  /**
  * Faz uma screenshot do frame e envia ao backend
  */
  sendImage() {
    const video: HTMLVideoElement = this.video.nativeElement;

    const canvas = document.createElement('canvas');
    canvas.width = this.WIDTH;
    canvas.height = this.HEIGHT;
    const context = canvas.getContext('2d');

    if (context) {
      context.drawImage(video, 0, 0);
      const base64Image = canvas.toDataURL('webcam-image/jpeg');
      this.imageSrc = base64Image

      const fileUpload = {
        base64: base64Image.substring(base64Image.lastIndexOf(',') + 1),
        name: 'webcam-image.jpeg'
      }
      this._fileDocumentService.post(fileUpload).then((res) =>{ 
        this.form.setValue(res.id)
        SwAlSetttings.Sucesso('Foto Enviada!')
        this._dialog.closeAll()
      })
    }
  }
}

