import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AccessControlUserCodeBar } from '@app/services/auth/acesscontrol-user-codebar.service';
import { ValidateProcessService } from '@app/services/validate-process/validate-process.service';
import { SwAlSetttings } from '@app/util/swal.settings';

@Component({
  selector: 'app-screening',
  templateUrl: './screening.component.html',
  styleUrls: ['./screening.component.scss']
})
export class ScreeningComponent implements OnInit {

  form = {
    codeBar: new UntypedFormControl(null, [Validators.required]),
    userCodeBar: new UntypedFormControl(null, [Validators.required])
  }

  lotCode: string = '';
  user: string = '';
  personId: string | null = null;
  userValidated: boolean = false;
  registerRecipients: Array<Object> = [];

  @ViewChild('dialogUserValidation')
  dialogUserValidation!: TemplateRef<any>

  resolveRecipients: Array<Object> = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Nome',
      retrive: 'name',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Exames',
      retrive: '',
      method: 'getExams',
      after: '',
      before: ''
    },
    {
      label: 'Código de Barras',
      retrive: 'codeBar',
      method: '',
      after: '',
      before: ''
    },
  ];

  constructor(
    private _dialog: MatDialog,
    private _accessControlUserCodeBar: AccessControlUserCodeBar,
    private _validateProcessService: ValidateProcessService
  ) { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.openDialogModalUserValidation()
    if (localStorage.getItem("lotCode") != null) {
      //@ts-ignore
      this.lotCode = localStorage.getItem("lotCode");
    }
  }

  openDialogModalUserValidation(): void {
    this.form.userCodeBar.reset();
    const myTempDialog = this._dialog.open(this.dialogUserValidation, {
      width: '660px'
    });
  };

  finish() {
    this.userValidated = false;
    this.user = '';
    this.personId = '';
  }

  async putCodeBar(codeBar: string) {
    this.registerRecipients = [];
    var obj = {
      lotCode: this.lotCode ? this.lotCode : null,
      codeBar: codeBar
    }

    await this._validateProcessService.post(obj).then(
      async (res) => {
        //@ts-ignore
        this.lotCode = res.lotCode;
        localStorage.setItem('lotCode', this.lotCode);
        this.getRecipients();
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  closeModal() {
    this._dialog.closeAll();
  }

  async validateUserCodebar() {
    let codebar: string = `codebar=${this.form.userCodeBar.value}`;
    if (this.form.userCodeBar.value) {
      await this._accessControlUserCodeBar.getByPath(codebar, '').then(
        (res) => {
          if (res.data.length > 0) {
            this.userValidated = true;
            res.data.forEach(data => {
              this.user = data.name;
              this.personId = data.personId;
            });
            setTimeout(() => {
              SwAlSetttings.Sucesso("Usuário autenticado com sucesso!")
              this.getRecipients();
            });
          } else {
            this.form.userCodeBar.setValue(null);
            SwAlSetttings.printMessageError("Código de Barras inválido!");
            setTimeout(() => {
              this.openDialogModalUserValidation();
            });
          }
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      )
      this.closeModal();
    }
  }

  async actions(emit: any) {
    switch (emit.action) {
      case 'Excluir':
        await this._validateProcessService.delete(emit.object.id).then(
          (res) => {
            SwAlSetttings.Sucesso("Recipiente removido com sucesso!")
            this.getRecipients();
          }
        ).catch(
          (err) => {
            SwAlSetttings.printError(err);
          }
        );
        break;
    }
  }

  async getRecipients() {
    this.registerRecipients = [];
    if (this.lotCode != '') {
      await this._validateProcessService.get('?LotCode=' + this.lotCode).then(
        (res) => {
          //@ts-ignore
          if (res.data.length != 0) {
            //@ts-ignore
            this.resolve(res.data, this.resolveRecipients);
          }
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );
    }
  }

  getExams(item: object) {
    var exams = '';
    //@ts-ignore
    item.exams.forEach(exam => {
      if (exams == '') {
        exams = exam;
      } else {
        exams += ' - ' + exam;
      }
    });

    return exams;
  }

  resolve(data: any, columns: any) {
    this.registerRecipients = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerRecipients.push(obj);
    });
  }

  async finishSorting() {
    await this._validateProcessService.deleteByPath(undefined, 'finish?LotCode=' + this.lotCode).then(
      (res) => {
        SwAlSetttings.Sucesso("Triagem finalizada com sucesso!")
        this.getRecipients();
        localStorage.removeItem('lotCode');
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }
}