<div class="container mt-32" appDnd (fileDropped)="onFileDropped($event)" [ngClass]="readonly ? 'readonly' : ''">
    <div class="flex flex-center" [ngClass]="!readonly ? 'mt-32' : ''">
        <svg width="112" *ngIf="!imagePath" height="111" viewBox="0 0 112 111" fill="none" xmlns="http://www.w3.org/2000/svg" class="imageEmpresa">
            <path d="M56 64.9642C67.0346 64.9642 75.98 56.0189 75.98 44.9842C75.98 33.9496 67.0346 25.0042 56 25.0042C44.9654 25.0042 36.02 33.9496 36.02 44.9842C36.02 56.0189 44.9654 64.9642 56 64.9642Z" fill="#DDDDDD"/>
            <path d="M90.2347 0H21.7653C10.0811 0 0.5 9.58105 0.5 21.2653V89.7347C0.5 101.419 10.0811 111 21.7653 111H90.2347C101.919 111 111.5 101.419 111.5 89.7347V21.2653C111.5 9.58105 101.919 0 90.2347 0ZM101.101 89.7347C101.101 95.6937 96.3105 100.484 90.3516 100.484H89.4168C88.9495 85.8789 83.8084 73.26 73.5263 66.7168C73.0589 66.3663 72.3579 66.4832 71.8905 66.8337C67.4505 70.1053 61.9589 72.0916 56 72.0916C50.041 72.0916 44.5495 70.1053 40.1095 66.8337C39.6421 66.4832 38.9411 66.4832 38.4737 66.7168C28.3084 73.26 23.0505 85.8789 22.7 100.601H21.7653C15.8063 100.601 11.0158 95.8105 11.0158 89.8516V21.2653C11.0158 15.3063 15.8063 10.5158 21.7653 10.5158H90.2347C96.1937 10.5158 100.984 15.3063 100.984 21.2653V89.7347H101.101Z" fill="#DDDDDD"/>
        </svg>
        <img [src]="imagePath" *ngIf="imagePath" class="imageEmpresa">

    </div>
    <div class="flex flex-center mt-32" *ngIf="!readonly">
        <a for="fileDropRef" class="btn btn-reverse">Carregar imagem</a>
    </div>

    <input type="hidden" [formControl]="form" name="fileUpload" (change)="onChange(form.value)">
    <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event)" />
    <div class="flex align-center">
    </div>
  </div>