<app-list [title]="'Esquema de Vacinas'">
  <div class="headerButton">
    <app-dialog [title]="'Esquema de Vacinas'" [placeholderButton]="'Novo'" [matIconName]="'add'" (afterClose)="afterClose()">
      <div class="bodyFilter flex flex-column mt-32 gap-3 align-center">
        <div class="flex w-100 align-center gap-form mt-32">
        <div class="w-100">
          <input-prop [form]="form.dose" placeholder="Dose"></input-prop>
        </div>
        <div class="w-100">
          <input-search-clean [form]="form.initialMonth" placeholder="Mês Inicial" [options]="month"></input-search-clean>
        </div>
      </div>
      <div class="mt-32 w-100 border box">
        <h3>Faixa Inicial</h3>
        <div class="flex w-100 align-center gap-form mt-32">
          <div class="w-100">
            <input-search-clean [form]="form.typeInit" placeholder="Tipo" [options]="typeValue"></input-search-clean>
          </div>
          <div class="w-100">
            <input-prop [form]="form.valueInit" [type]="'number'" placeholder="Valor"></input-prop>
          </div>
        </div>
      </div>
      <div class="mt-32 w-100 border box">
        <h3>Faixa Final</h3>
        <div class="flex w-100 align-center gap-form mt-32">
          <div class="w-100">
            <input-search-clean [form]="form.typeFinal" placeholder="Tipo" [options]="typeValue"></input-search-clean>
          </div>
          <div class="w-100">
            <input-prop (keyup.enter)="editId? put() : post()" [form]="form.valueFinal" [type]="'number'" placeholder="Valor"></input-prop>
          </div>
        </div>
      </div>
      <div class="flex flex-center mt-32 w-100">
        <app-button (click)="editId? put() : post()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
    </app-dialog>
  </div>
  <div class="headerFilter">
    <app-filter (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query flex flex-column gap-2 align-center">
        <input-prop #autoFocus [form]="filter.Search" (keyup.enter)="get()" [placeholder]="'Pesquisar'"></input-prop>
        <checkbox-prop class="flex" [gap]="1" label="Somente Ativos" [form]="filter.isActive"></checkbox-prop>
        <app-button (click)="get()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'btn flex gap-2 align-center'"></app-button>
      </div>
    </app-filter>
  </div>
  <div class="list">
    <app-table [Actions]="['Editar', 'Excluir']" [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true"  [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="itens" (parentFun)="actions($event)" ></app-table>
  </div>
</app-list>
