import { HttpHeaders } from '@angular/common/http';
import type { HeaderParams, QueryParams } from '../data/types/http.types';

/**
 * Método auxiliar para ajudar na criação de Queries ou Header
 *
 * @param params Objeto que será usado para alimentar dados
 * @returns Um `Map<string, any>` chave/valor de objeto, { key: value }
 */
function getKeyValues(params?: QueryParams | HeaderParams) {
  const values = new Map<string, any>();
  if (params) {
    Object.keys(params).forEach(param => {
      const paramValue = params[param];
      if ((typeof paramValue === 'string' && paramValue.length == 0) || paramValue === null) return;
      values.set(param, paramValue);
    });
  }
  return values;
}

/**
 * Método para retornar Path e Query que será usado em requisição
 *
 * @param params Objeto que será transformado em Query
 * @param path Path que poderá ser adicionado antes de query
 * @returns retorna `String` com valor formatado `path?queries`
 */
function getPathWithQuery(params?: QueryParams, path = '') {
  if (path && !params) return path;
  if (!params) return '';

  const query: string[] = [];

  getKeyValues(params).forEach((paramValue, param) => {
    query.push(`${param}=${paramValue}`);
  });

  return path + ['?', ...query.join('&')].join('');
}

/**
 * Método para gerar HttpHeader
 *
 * @param params Objeto que será transformado em HttpHeader
 * @returns retorna `HttpHeaders` com valores informados em objeto
 */
function getHeaders(params?: HeaderParams) {
  let headers = new HttpHeaders();
  getKeyValues(params).forEach((paramValue, param) => {
    headers = headers.set(param, paramValue);
  });
  return headers;
}

export { getPathWithQuery, getHeaders };
