import { inject, Injectable } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import { lastValueFrom } from "rxjs";
import { LoginAuthService } from "./loginAuth.service";
@Injectable({
   providedIn: "root"
})
export class LoginAuthFacade extends MultiObservableFacade {
   private _loginAuthService = inject(LoginAuthService);

   async post(request: any) {
      try {
         const response: any = await lastValueFrom(
            this._loginAuthService.post(request)
         );

         if (response.success == true) {
            return response;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      return null;
   }
}
