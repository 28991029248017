<div class="contentPage">
  <div class="flex flex-column">
    <div class="flex flex-between align-center headerResponsive">
      <div class="flex align-center gap-2 titleResponsive">
        <h1>{{'budgets.title' | translate}}</h1>
        <app-button (click)="newBudget()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
      </div>      
      <app-filter class="filterResponsive" [cleanButton]="true" (beforeClose)="getBudgets()" (clearFilters)="clearForms()">
        <div class="query">
          <div class="w-100">
            <input-prop #autoFocus [form]="form.RequisitionNumber" [placeholder]="'budgets.numberBudget'" type="number"></input-prop>
          </div>
          <div class="w-100">
            <input-prop [form]="form.CustomerName" [placeholder]="'budgets.nameCustomer'"></input-prop>
          </div>
          <input-prop [placeholder]="'budgets.initialDate'" type="datetime-local" [form]="form.InitialDate"></input-prop>
          <input-prop [placeholder]="'budgets.finalDate'" type="datetime-local" [form]="form.FinalDate"></input-prop>
        </div>
        <div class="queryBtn flex flex-column gap-2">
          <app-button (click)="getBudgets()" [placeholder]="'budgets.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
        </div>
      </app-filter>
    </div>
    <div class="mt-32">
      <app-table [ClickInItem]="ActionsExtra" [Pagination]="true" Dropdown="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="registersBudgets" [Actions]="['Editar', 'Excluir', 'Imprimir']" (parentFun)="actions($event)" (changePage)="getBudgets($event)">
        <ng-template  let-rowDetail #header>          
          <h2>{{'budgets.titleExam' | translate}}</h2>
          <div class="mt-32">
            <app-table [Pagination]="false" [backPagination]="false" [Itens]="registersExams[rowDetail.id]"></app-table>          
          </div>
        </ng-template>
      </app-table>
    </div>
  </div>
</div>
