<div style="padding: 0px;margin-top: 13px;" class="contentPage">
  <div class="titulo flex">
      <h1>Dashboard</h1> 
  </div>    
  <div class="flex itensCard">
      <div class="col" *ngFor="let column of columns">
          <div style="max-height: 400px;" class="flex-column ">
              <div class="flex flex-between">
                <div class="titulo">
                    <p>{{column.titulo}}</p>
                    <strong>{{column.hasOwnProperty('cards') ? column.cards.length : '0'}}</strong>
                </div>
                <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg"  *ngIf="column.type == 0">
                  <rect x="0.5" width="59.0769" height="61" rx="4" fill="#000000"/>
                  <path d="M39.6538 19.9231H35.8077V18H33.8846V19.9231H26.1923V18H24.2692V19.9231H20.4231C19.3654 19.9231 18.5 20.7885 18.5 21.8462V41.0769C18.5 42.1346 19.3654 43 20.4231 43H39.6538C40.7115 43 41.5769 42.1346 41.5769 41.0769V21.8462C41.5769 20.7885 40.7115 19.9231 39.6538 19.9231ZM39.6538 41.0769H20.4231V27.6154H39.6538V41.0769ZM39.6538 25.6923H20.4231V21.8462H24.2692V23.7692H26.1923V21.8462H33.8846V23.7692H35.8077V21.8462H39.6538V25.6923Z" fill="white"/>
                </svg>
                <svg width="62" height="61" viewBox="0 0 62 61" fill="#000000" xmlns="http://www.w3.org/2000/svg" *ngIf="column.type == 1">
                  <rect x="0.5" width="59.0769" height="61" rx="4" fill="#bfbfbf"/>
                  <path d="M39.6538 19.9231H35.8077V18H33.8846V19.9231H26.1923V18H24.2692V19.9231H20.4231C19.3654 19.9231 18.5 20.7885 18.5 21.8462V41.0769C18.5 42.1346 19.3654 43 20.4231 43H39.6538C40.7115 43 41.5769 42.1346 41.5769 41.0769V21.8462C41.5769 20.7885 40.7115 19.9231 39.6538 19.9231ZM39.6538 41.0769H20.4231V27.6154H39.6538V41.0769ZM39.6538 25.6923H20.4231V21.8462H24.2692V23.7692H26.1923V21.8462H33.8846V23.7692H35.8077V21.8462H39.6538V25.6923Z" fill="black"/>
                </svg>
                <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="column.type == 2">
                  <rect x="0.5" width="59.0769" height="61" rx="4" fill="#CB1B18"/>
                  <path d="M39.6538 19.9231H35.8077V18H33.8846V19.9231H26.1923V18H24.2692V19.9231H20.4231C19.3654 19.9231 18.5 20.7885 18.5 21.8462V41.0769C18.5 42.1346 19.3654 43 20.4231 43H39.6538C40.7115 43 41.5769 42.1346 41.5769 41.0769V21.8462C41.5769 20.7885 40.7115 19.9231 39.6538 19.9231ZM39.6538 41.0769H20.4231V27.6154H39.6538V41.0769ZM39.6538 25.6923H20.4231V21.8462H24.2692V23.7692H26.1923V21.8462H33.8846V23.7692H35.8077V21.8462H39.6538V25.6923Z" fill="white"/>
                </svg>
                <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="column.type == 3">
                  <rect x="0.5" width="59.0769" height="61" rx="4" fill="#FFC000"/>
                  <path d="M39.6538 19.9231H35.8077V18H33.8846V19.9231H26.1923V18H24.2692V19.9231H20.4231C19.3654 19.9231 18.5 20.7885 18.5 21.8462V41.0769C18.5 42.1346 19.3654 43 20.4231 43H39.6538C40.7115 43 41.5769 42.1346 41.5769 41.0769V21.8462C41.5769 20.7885 40.7115 19.9231 39.6538 19.9231ZM39.6538 41.0769H20.4231V27.6154H39.6538V41.0769ZM39.6538 25.6923H20.4231V21.8462H24.2692V23.7692H26.1923V21.8462H33.8846V23.7692H35.8077V21.8462H39.6538V25.6923Z" fill="white"/>
                </svg>
                <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="column.type == 4">
                  <rect x="0.5" width="59.0769" height="61" rx="4" fill="#33FFF9"/>
                  <path d="M39.6538 19.9231H35.8077V18H33.8846V19.9231H26.1923V18H24.2692V19.9231H20.4231C19.3654 19.9231 18.5 20.7885 18.5 21.8462V41.0769C18.5 42.1346 19.3654 43 20.4231 43H39.6538C40.7115 43 41.5769 42.1346 41.5769 41.0769V21.8462C41.5769 20.7885 40.7115 19.9231 39.6538 19.9231ZM39.6538 41.0769H20.4231V27.6154H39.6538V41.0769ZM39.6538 25.6923H20.4231V21.8462H24.2692V23.7692H26.1923V21.8462H33.8846V23.7692H35.8077V21.8462H39.6538V25.6923Z" fill="white"/>
                </svg>
                <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg"  *ngIf="column.type == 5">
                  <rect x="0.5" width="59.0769" height="61" rx="4" fill="#9B8056"/>
                  <path d="M39.6538 19.9231H35.8077V18H33.8846V19.9231H26.1923V18H24.2692V19.9231H20.4231C19.3654 19.9231 18.5 20.7885 18.5 21.8462V41.0769C18.5 42.1346 19.3654 43 20.4231 43H39.6538C40.7115 43 41.5769 42.1346 41.5769 41.0769V21.8462C41.5769 20.7885 40.7115 19.9231 39.6538 19.9231ZM39.6538 41.0769H20.4231V27.6154H39.6538V41.0769ZM39.6538 25.6923H20.4231V21.8462H24.2692V23.7692H26.1923V21.8462H33.8846V23.7692H35.8077V21.8462H39.6538V25.6923Z" fill="white"/>
                </svg>
                <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="column.type == 6">
                  <rect x="0.5" width="59.0769" height="61" rx="4" fill="#07bf56"/>
                  <path d="M39.6538 19.9231H35.8077V18H33.8846V19.9231H26.1923V18H24.2692V19.9231H20.4231C19.3654 19.9231 18.5 20.7885 18.5 21.8462V41.0769C18.5 42.1346 19.3654 43 20.4231 43H39.6538C40.7115 43 41.5769 42.1346 41.5769 41.0769V21.8462C41.5769 20.7885 40.7115 19.9231 39.6538 19.9231ZM39.6538 41.0769H20.4231V27.6154H39.6538V41.0769ZM39.6538 25.6923H20.4231V21.8462H24.2692V23.7692H26.1923V21.8462H33.8846V23.7692H35.8077V21.8462H39.6538V25.6923Z" fill="white"/>
                </svg>
                <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="column.type == 7">
                  <rect x="0.5" width="59.0769" height="61" rx="4" fill="#FF6344"/>
                  <path d="M39.6538 19.9231H35.8077V18H33.8846V19.9231H26.1923V18H24.2692V19.9231H20.4231C19.3654 19.9231 18.5 20.7885 18.5 21.8462V41.0769C18.5 42.1346 19.3654 43 20.4231 43H39.6538C40.7115 43 41.5769 42.1346 41.5769 41.0769V21.8462C41.5769 20.7885 40.7115 19.9231 39.6538 19.9231ZM39.6538 41.0769H20.4231V27.6154H39.6538V41.0769ZM39.6538 25.6923H20.4231V21.8462H24.2692V23.7692H26.1923V21.8462H33.8846V23.7692H35.8077V21.8462H39.6538V25.6923Z" fill="white"/>
                </svg>
                <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="column.type == 8">
                  <rect x="0.5" width="59.0769" height="61" rx="4" fill="#7346A5"/>
                  <path d="M39.6538 19.9231H35.8077V18H33.8846V19.9231H26.1923V18H24.2692V19.9231H20.4231C19.3654 19.9231 18.5 20.7885 18.5 21.8462V41.0769C18.5 42.1346 19.3654 43 20.4231 43H39.6538C40.7115 43 41.5769 42.1346 41.5769 41.0769V21.8462C41.5769 20.7885 40.7115 19.9231 39.6538 19.9231ZM39.6538 41.0769H20.4231V27.6154H39.6538V41.0769ZM39.6538 25.6923H20.4231V21.8462H24.2692V23.7692H26.1923V21.8462H33.8846V23.7692H35.8077V21.8462H39.6538V25.6923Z" fill="white"/>
                </svg>
                <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="column.type == 9">
                  <rect x="0.5" width="59.0769" height="61" rx="4" fill="rgb(0, 110, 157)"/>
                  <path d="M39.6538 19.9231H35.8077V18H33.8846V19.9231H26.1923V18H24.2692V19.9231H20.4231C19.3654 19.9231 18.5 20.7885 18.5 21.8462V41.0769C18.5 42.1346 19.3654 43 20.4231 43H39.6538C40.7115 43 41.5769 42.1346 41.5769 41.0769V21.8462C41.5769 20.7885 40.7115 19.9231 39.6538 19.9231ZM39.6538 41.0769H20.4231V27.6154H39.6538V41.0769ZM39.6538 25.6923H20.4231V21.8462H24.2692V23.7692H26.1923V21.8462H33.8846V23.7692H35.8077V21.8462H39.6538V25.6923Z" fill="white"/>
                </svg>                   
              </div>
              <!-- <span>
                      Última atualização: {{atualizacao | date: 'dd/MM/yyyy'}} às {{atualizacao | date: 'HH:mm'}}
                      <select-prop></select-prop>
              </span> -->
              <div *ngIf="column.type==0" class="itens">
                  <div style="overflow: auto;padding: 2px;" *ngFor="let card of column.cards">
                      <app-cards                        
                        [hideLines]=true
                        [columnValue]="column.type"                              
                        [header]="card.header"                                                                                               
                        [linha5]="card.date"                                                                                                                  
                        [linha6]="card.name"
                        >                                                              
                      </app-cards>
                  </div>
              </div>
              <div *ngIf="column.type==1" class="itens">
                  <div style="overflow: auto;padding: 2px;" *ngFor="let card of column.cards">
                    <app-cards                      
                      [hideLines]=true
                      [columnValue]="column.type"                                  
                      [header]="card.header"                                                                                               
                      [linha5]="card.date"                                                                                                                  
                      [linha6]="card.name"
                      >                                                              
                    </app-cards>
                  </div>
              </div>   
              <div *ngIf="column.type==2" class="itens">
                  <div style="overflow: auto;padding: 2px;" *ngFor="let card of column.cards">
                    <app-cards                      
                      [hideLines]=true
                      [columnValue]="column.type"     
                      [header]="card.header"                                                                                               
                      [linha5]="card.date"                                                                                                                  
                      [linha6]="card.name"
                      >                                                              
                    </app-cards>
                  </div>
              </div>  
              <div *ngIf="column.type==3" class="itens">
                  <div style="overflow: auto;padding: 2px;" *ngFor="let card of column.cards">
                    <app-cards                      
                      [hideLines]=true
                      [columnValue]="column.type"     
                      [header]="card.header"                                                                                               
                      [linha5]="card.date"                                                                                                                  
                      [linha6]="card.name"
                      >                                                              
                    </app-cards>
                  </div>
              </div>      
              <div *ngIf="column.type==4" class="itens">
                  <div style="overflow: auto;padding: 2px;" *ngFor="let card of column.cards">
                    <app-cards                      
                      [hideLines]=true
                      [columnValue]="column.type"     
                      [header]="card.header"                                                                                               
                      [linha5]="card.date"                                                                                                                  
                      [linha6]="card.name"
                      >                                                              
                    </app-cards>
                  </div>
              </div>
              <div *ngIf="column.type==5" class="itens">
                <div style="overflow: auto;padding: 2px;" *ngFor="let card of column.cards">
                  <app-cards                    
                    [hideLines]=true
                    [columnValue]="column.type"     
                    [header]="card.header"                                                                                               
                    [linha5]="card.date"                                                                                                                  
                    [linha6]="card.name"
                    >                                                              
                  </app-cards>
                </div>
              </div>    
              <div *ngIf="column.type==6" class="itens">
                <div style="overflow: auto;padding: 2px;" *ngFor="let card of column.cards">
                  <app-cards                    
                    [hideLines]=true
                    [columnValue]="column.type"     
                    [header]="card.header"                                                                                               
                    [linha5]="card.date"                                                                                                                  
                    [linha6]="card.name"
                    >                                                              
                  </app-cards>
                </div>
              </div>  
              <div *ngIf="column.type==7" class="itens">
                <div style="overflow: auto;padding: 2px;" *ngFor="let card of column.cards">
                  <app-cards                    
                    [hideLines]=true
                    [columnValue]="column.type"     
                    [header]="card.header"                                                                                               
                    [linha5]="card.date"                                                                                                                  
                    [linha6]="card.name"
                    >                                                              
                  </app-cards>
                </div>
              </div>  
              <div *ngIf="column.type==8" class="itens">
                <div style="overflow: auto;padding: 2px;" *ngFor="let card of column.cards">
                  <app-cards                    
                    [hideLines]=true
                    [columnValue]="column.type"     
                    [header]="card.header"                                                                                               
                    [linha5]="card.date"                                                                                                                  
                    [linha6]="card.name"
                    >                                                              
                  </app-cards>
                </div>
              </div>  
              <div *ngIf="column.type==9" class="itens">
                <div style="overflow: auto;padding: 2px;" *ngFor="let card of column.cards">
                  <app-cards                    
                    [hideLines]=true
                    [columnValue]="column.type"     
                    [header]="card.header"                                                                                               
                    [linha5]="card.date"                                                                                                                  
                    [linha6]="card.name"
                    >                                                              
                  </app-cards>
                </div>
              </div>                                     
          </div>
      </div>
  </div>
</div>