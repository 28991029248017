import { UntypedFormControl } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { TitlesService } from "@app/services/titles/titles.service";
import { SwAlSetttings } from "@app/util/swal.settings";
import { Guid } from "@app/util/guid";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { Router } from "@angular/router";
import {
   Actions,
   ResolveTitle,
   ObjectActionsPortion,
   ObjectActions
} from "./titles.model";
import { GetActiveFilterPipe } from "@app/pipe/active.pipe";
import { CheckboxSvgPipe } from "@app/pipe/checkbox-Svg.pipe";

@Component({
   selector: "app-titles",
   templateUrl: "./titles.component.html",
   styleUrls: ["./titles.component.scss"]
})
export class TitlesComponent implements OnInit {
   filter = {
      document: new UntypedFormControl(),
      actives: new UntypedFormControl(false),
      issuer: new UntypedFormControl(),
      dateStart: new UntypedFormControl(),
      dateFinish: new UntypedFormControl(),
      situation: new UntypedFormControl()
   };

   total: number = 0;
   page: number = 1;
   index: number = 50;

   tabIndex: number = 1;

   titles: Array<Array<object>> = [[], [], []];

   portion: Array<object> = [];

   moduleTabs = ["Contas a Pagar", "Contas a Receber", "Caixas e Bancos"];

   resolvePaymentTypes = [
      {
         label: "id",
         retrive: "id",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Nr. Documento",
         retrive: "documentNumber",
         method: "",
         after: "",
         before: ""
      },
      // {
      //   label: 'Módulo',
      //   retrive: '',
      //   method: 'getModule',
      //   after: '',
      //   before: ''
      // },
      {
         label: "Emitente",
         retrive: "name",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Emissão",
         retrive: "",
         method: "getEmission",
         after: "",
         before: ""
      },
      {
         label: "Valor",
         retrive: "",
         method: "getValue",
         after: "",
         before: ""
      },
      {
         label: "Situação",
         retrive: "",
         method: "getSituation",
         after: "",
         before: ""
      },
      {
         label: "Estado",
         retrive: "",
         method: "getState",
         after: "",
         before: ""
      },
      {
         label: "Criado em",
         retrive: "",
         method: "getCreated",
         after: "",
         before: ""
      },
      {
         label: "Alterado em",
         retrive: "",
         method: "getUpdated",
         after: "",
         before: ""
      },
      {
         label: "Ativo",
         retrive: "",
         method: "getActive",
         after: "",
         before: ""
      }
   ];

   resolvePortions = [
      {
         label: "id",
         retrive: "id",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Parcela",
         retrive: "number",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Vencimento",
         retrive: "",
         method: "getDeadLine",
         after: "",
         before: ""
      },
      {
         label: "Valor Bruto",
         retrive: "",
         method: "getValue",
         after: "",
         before: ""
      },
      {
         label: "Desconto",
         retrive: "discount",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Adicional",
         retrive: "addition",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Valor Parcela",
         retrive: "balance",
         method: "",
         after: "",
         before: ""
      }
   ];

   titleModuleOptions: Array<{ label: string; value: number }> = [
      {
         label: "Contas a pagar",
         value: 1
      },
      {
         label: "Contas a receber",
         value: 2
      },
      {
         label: "Caixas e bancos",
         value: 3
      }
   ];

   titleSituationOptions: Array<{ label: string; value: number }> = [
      {
         label: "Aberto",
         value: 0
      },
      {
         label: "Vencido",
         value: 1
      },
      {
         label: "Baixado Parcialmente",
         value: 2
      },
      {
         label: "Baixado Integralmente",
         value: 3
      }
   ];

   ActionsExtra = {
      Ativo: "PutActive"
   };

   constructor(
      private _router: Router,
      private _titlesService: TitlesService,
      private _createdAt: LocalaDatePipe,
      private _updatedAt: LocalaDatePipe,
      private _activePipe: GetActiveFilterPipe,
      private _checkboxSvgPipe: CheckboxSvgPipe
   ) {}

   ngOnInit() {
      this.get();
   }

   tabChange() {
      if (this.titles[this.tabIndex].length > 0) return;
      this.get();
   }

   async get(paramPage?: number[]) {
      this.titles[this.tabIndex] = [];
      if (paramPage) {
         this.page = paramPage[0];
         this.index = paramPage[1];
      }

      const start = new Date(
         new Date(this.filter.dateStart.value).setUTCHours(0, 0, 0, 0)
      ).toISOString();
      const finish = new Date(
         new Date(this.filter.dateFinish.value).setUTCHours(23, 59, 59, 999)
      ).toISOString();

      let documentNumber: string = this.filter.document.value
         ? this.filter.document.value
         : null;

      let isActive: string = this.filter.actives.value
         ? this.filter.actives.value
         : null;

      let issuer: string = this.filter.issuer.value
         ? this.filter.issuer.value
         : null;

      let typeModule: string = (this.tabIndex + 1).toString();

      let dateStart: string = this.filter.dateStart.value ? start : "";

      let dateFinish: string = this.filter.dateFinish.value ? finish : "";

      let titleSituation: string =
         this.filter.situation.value >= 0 ? this.filter.situation.value : null;

      await this._titlesService
         .getPagination({
            page: this.page,
            numberRegistry: this.index,
            isActive,
            documentNumber,
            issuer,
            typeModule,
            dateStart,
            dateFinish,
            titleSituation
         })
         .then((res: any) => {
            this.filter.situation.setValue(null);

            this.total = res.sumRecords;
            if (res.data.length != 0) {
               this.resolve(res.data, this.resolvePaymentTypes);
            }
         })
         .catch((err: any) => {
            SwAlSetttings.printError(err);
         });
   }

   clearFilters() {
      Object.keys(this.filter).forEach((key) => {
         this.filter[key as keyof typeof this.filter].reset();
      });
   }

   resolve(data: any, columns: any) {
      this.titles[this.tabIndex].length = 0;
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.titles[this.tabIndex].push(obj);
      });
   }

   getById(titleId: string) {
      this._titlesService.getById(new Guid(titleId)).then((x: any) => {
         this.resolvePortion(
            x.data[0].titlePortions,
            this.resolvePortions,
            titleId
         );
      });
   }

   resolvePortion(data: any, columns: any, titleId: string) {
      let arrayToFilter: Array<any> = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         arrayToFilter.push(obj);
      });

      //@ts-ignore
      this.portion[titleId] = arrayToFilter;
   }

   getValue(item: ObjectActions) {
      return "R$ " + item.value;
   }

   getEmission(item: ObjectActions) {
      return new Date(item.createdAt).toLocaleDateString();
   }

   getSituation(item: ObjectActions) {
      let situation = [];
      situation[0] = `<span class="span-styles" style="background:#006E9D; color:#FFF;">Aberto</span>`;
      situation[1] = `<span class="span-styles" style="background:#EB5757; color:#FFF;">Vencido</span>`;
      situation[2] = `<span class="span-styles" style="background:#FFC000; color:#FFF;">Baixado Parcialmente</span>`;
      situation[3] = `<span class="span-styles" style="background:#07BF56; color:#FFF;">Baixado Integralmente</span>`;

      return situation[item.titleSituation];
   }

   getState(item: ObjectActions) {
      if (item.isOK)
         return `<span class="span-styles" style="background:#07BF56; color:#FFF;">Ok</span>`;
      return `<span class="span-styles" style="background:#EB5757; color:#FFF;">Pendente</span>`;
   }

   getCreated(item: ObjectActions) {
      return this._createdAt.transform(item.createdAt);
   }

   getUpdated(item: ObjectActions) {
      return this._updatedAt.transform(item.updatedAt);
   }

   getActive(item: ObjectActions) {
      return this._activePipe.transform(item.isActive);
   }

   actions(emit: Actions) {
      switch (emit.action) {
         case "OpenDropdown":
            this.getById(emit.id);
            break;
         case "Editar":
            this.redirect(emit.object.id);
            break;
         case "PutActive":
            this._titlesService
               .patchActive(
                  emit.object.id,
                  this._checkboxSvgPipe.transform(emit.object.Ativo)
               )
               .then((res: any) => {
                  if (res.success) {
                     this.get();
                  }
               });
      }
   }

   getDeadLine(item: ObjectActionsPortion) {
      return new Date(item.deadline).toLocaleDateString();
   }

   redirect(titleId?: string) {
      this._router.navigateByUrl(
         `CadastrosOld/Titulos/${
            titleId ? titleId : "Novo?module=" + (this.tabIndex + 1)
         }`
      );
   }
}
