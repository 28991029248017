import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseAuthService } from '../auth/base-auth.service';
import { HttpAuthService } from '../auth/http-auth.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseAuthService {
  constructor(httpService: HttpAuthService) {
    super(httpService, 'v1/public/user');
  }

  getCompanies(accessCode: string, tenantId: string) {
    return super.getByPath(`?accessCode=${accessCode}`, `companies`, [], tenantId)
  }
}
