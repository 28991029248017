<div class="contentPage">
  <div class="flex align-center w-100 headerResponsive">
    <div class="flex align-center w-100 gap-2 titleResponsive">
      <h1>Lotes</h1>
      <app-button (click)="new()" [placeholder]="'Novo'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">        
        <select-prop [placeholder]="'Empresa'" (change)="getUnity()" [form]="filterForm.companyId" [options]="companys"></select-prop>
        <select-prop [placeholder]="'Unidade'" [form]="filterForm.unityId" [options]="unityOptions"></select-prop>
        <select-prop [placeholder]="'Local de Coleta'" [form]="filterForm.collectionPlaceId" [options]="collectionPlaceOptions"></select-prop>
        <input-prop [placeholder]="'Código Lote'" [form]="filterForm.lotCode"></input-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" [label]="'Somente Ativos'" [form]="filterForm.isActive"></checkbox-prop>
      </div>
      <app-button class="queryBtn" (click)="get()" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="data-table">
    <app-table [Pagination]="true" [SortPagination]="true" [Dropdown]="true" (sortPage)="getSortedPage($event)" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="lots" [Actions]="['Imprimir Etiqueta', 'Imprimir Relatório', 'Temperatura', 'Editar', 'Excluir']" (parentFun)="Actions($event)" (changePage)="get($event)">
      <ng-template let-rowDetail #header>
        <app-table [Pagination]="false" [backPagination]="false" [Itens]="materials[rowDetail.id]"></app-table>
      </ng-template>
    </app-table>
  </div>
</div>

<ng-template #dialogModal let-obj>
  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1>Temperatura Envio</h1>
      <svg (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="mt-32">
      <input-prop class="mt-32 w-100" [form]="form.temperature" placeholder="Temperatura"></input-prop>
    </div>    
    <div class="w-100 mt-32 flex flex-center">
      <app-button [placeholder]="'Salvar'" (click)="changeTemperature(obj)" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>
