import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { BearerService } from '@app/services/bearer/bearer.service';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Actions, Bearer, BearerPostObject, BearerPutObject, CompanyOption, PaginationInfo, ResolvedBearer } from './bearer.model';
import { Guid } from '@app/util/guid';
import { CompanyService } from '@app/services/company/company.service';
import { QueryParams } from '@app/services/interfaces/services.types';

@Component({
  selector: 'app-bearer-list',
  templateUrl: './bearer-list.component.html',
  styleUrls: ['./bearer-list.component.scss']
})
export class BearerListComponent implements OnInit {

  @ViewChild('dialogModal') dialogModal!: TemplateRef<unknown>

  resolvedBearerList: Array<ResolvedBearer> = []

  companyOptions: Array<CompanyOption> = []

  paginationInfo: PaginationInfo = {
    page: 1,
    sumRecords: 0,
    index: 50
  }

  searchFiltersFormGroup = new UntypedFormGroup({
    description: new UntypedFormControl(),
    isActive: new UntypedFormControl(),
  })

  bearerFormGroup = new UntypedFormGroup({
    bearerId: new UntypedFormControl(),
    companyId: new UntypedFormControl(),
    description: new UntypedFormControl(),
    nameBank: new UntypedFormControl(),
    agencyNumber: new UntypedFormControl(),
    accountNumber: new UntypedFormControl(),
    externalId: new UntypedFormControl(),
    considersBalanceCashFlow: new UntypedFormControl(false)
  })

  ActionsExtra = {
    "Ativo": 'toggleActive'
  }

  constructor(
    private _bearerService: BearerService,
    private _localaDatePipe: LocalaDatePipe,
    private _dialog: MatDialog,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _companyService: CompanyService
  ) { }

  ngOnInit(): void {
    this.getCompanies()
    this.getBearersList()
  }

  async getCompanies() {
    await this._companyService.getResume().then(response => {
      if (response.success) {
        this.companyOptions = response.data.map((company) => (
          {
            value: company.id,
            label: company.socialReason
          }
        ))
      }
    })
  }

  getCompanyName(companyId: string) {
    return this.companyOptions.find(company => company.value == companyId)?.label ?? ''
  }

  async getBearersList(params?: number[]) {
    if (params) {
      this.paginationInfo.index = params[1]
      this.paginationInfo.page = params[0]
    }

    const queryObject: QueryParams = {
      search: this.searchFiltersFormGroup.controls['description'].value,
      isActive: this.searchFiltersFormGroup.controls['isActive'].value,
      numberRegistry: this.paginationInfo.index,
      page: this.paginationInfo.page
    }

    await this._bearerService.getPagination<Bearer>(queryObject).then(response => {
      if (response.success) {
        this.resolvedBearerList = response.data.map((bearer) => ({
          id: bearer.id,
          "Empresa": bearer.companyName ?? '',
          "Código": bearer.externalId ?? '',
          "Descrição": bearer.description ?? '',
          "Nome do Banco": bearer.nameBank ?? '',
          "Agência": bearer.agencyNumber ?? '',
          "Conta": bearer.accountNumber ?? '',
          "Criado em": this._localaDatePipe.transform(bearer.createdAt) ?? '',
          "Alterado em": this._localaDatePipe.transform(bearer.updatedAt) ?? '',
          "Ativo": bearer.isActive ? 'checkbox|true' : 'checkbox|',
          "_hide_considersBalanceCashFlow": bearer.considersBalanceCashFlow,
          "_hide_companyId": bearer.companyId
        }
        ))

        this.paginationInfo.sumRecords = response.sumRecords
      }
    })
  }

  bearerActions(emit: Actions) {
    switch (emit.action) {
      case 'toggleActive':
        this.patchActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object["Ativo"]))
        break
      case 'Excluir':
        this.deleteBearer(emit.object.id)
        break
      case 'Editar':
        this.openDialogModalEdit(emit.object)
        break
    }
  }

  openDialogModalEdit(object: ResolvedBearer) {
    this.bearerFormGroup.controls['bearerId'].setValue(object.id)
    this.bearerFormGroup.controls['nameBank'].setValue(object['Nome do Banco'])
    this.bearerFormGroup.controls['companyId'].setValue(object["_hide_companyId"])
    this.bearerFormGroup.controls['externalId'].setValue(object["Código"])
    this.bearerFormGroup.controls['description'].setValue(object["Descrição"])
    this.bearerFormGroup.controls['agencyNumber'].setValue(object['Agência'])
    this.bearerFormGroup.controls['accountNumber'].setValue(object['Conta'])
    this.bearerFormGroup.controls['considersBalanceCashFlow'].setValue(object["_hide_considersBalanceCashFlow"])
    this.openDialogModal()
  }

  openDialogModal() {
    this._dialog.open(this.dialogModal, {
      width: '660px'
    }).afterClosed().subscribe(() => this.bearerFormGroup.reset())
  }

  async patchActive(id: string, value: string) {
    await this._bearerService.patchActive(id, value).then(async (response) => {
      if (response.success) {
        await this.getBearersList()
        SwAlSetttings.Sucesso(`Portador ${value === 'active' ? 'inativado' : 'ativado'} com sucesso!`)
      }
    })
  }

  async deleteBearer(id: string) {
    await this._bearerService.delete(new Guid(id)).then(async (response) => {
      if (response.success) {
        await this.getBearersList()
        SwAlSetttings.Sucesso('Portador excluído com sucesso!')
      }
    })
  }

  async createBearer() {
    const bearerObject: BearerPostObject = {
      companyId: this.bearerFormGroup.controls['companyId'].value,
      externalId: this.bearerFormGroup.controls['externalId'].value,
      description: this.bearerFormGroup.controls['description'].value,
      nameBank: this.bearerFormGroup.controls['nameBank'].value,
      agencyNumber: this.bearerFormGroup.controls['agencyNumber'].value,
      accountNumber: this.bearerFormGroup.controls['accountNumber'].value,
      considersBalanceCashFlow: this.bearerFormGroup.controls['considersBalanceCashFlow'].value,
    }
    this._bearerService.post(bearerObject).then(async (response) => {
      if (response.success) {
        await this.getBearersList()
        this._dialog.closeAll()
        SwAlSetttings.Sucesso('Portador criado com sucesso!')
      }
    })
  }

  async updateBearer() {
    const id: Guid = new Guid(this.bearerFormGroup.controls['bearerId'].value)
    const bearerObject: BearerPutObject = {
      companyId: this.bearerFormGroup.controls['companyId'].value,
      externalId: this.bearerFormGroup.controls['externalId'].value,
      description: this.bearerFormGroup.controls['description'].value,
      nameBank: this.bearerFormGroup.controls['nameBank'].value,
      agencyNumber: this.bearerFormGroup.controls['agencyNumber'].value,
      accountNumber: this.bearerFormGroup.controls['accountNumber'].value,
      considersBalanceCashFlow: this.bearerFormGroup.controls['considersBalanceCashFlow'].value,
    }
    await this._bearerService.put(bearerObject, id).then(async (response) => {
      if (response.success) {
        await this.getBearersList()
        this._dialog.closeAll()
        SwAlSetttings.Sucesso('Portador atualizado com sucesso!')
      }
    })
  }

  resetFilter(){
   this.searchFiltersFormGroup.controls['description'].reset();
   this.searchFiltersFormGroup.controls['isActive'].reset();
  }
}
