import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminAccesscontrolUserAuth } from 'src/app/services/auth/admin/accesscontrol-user-auth.service';
import { Person } from './select-person.types';
import { PersonService } from 'src/app/services/person.service';
import { SelectPersonService } from './select-person.service';

interface PersonImage {
  [properties: string]: string;
}

interface Options {
  value: any,
  label: string,
}

@Component({
  selector: 'select-person-prop',
  templateUrl: './select-person-prop.component.html',
  styleUrls: ['./select-person-prop.component.scss']
})
export class SelectPersonPropComponent implements OnInit {

  @Input() type : string = 'text';
  //@ts-ignore
  @Input() form: FormControl;
  @Input() placeholder : string = '';
  @Input() name : string = '';
  @Input() label : string = '';
  @Input() mask : any = '';
  @Input() nullable : boolean = false;
  @Input() readonlySelect : boolean = false;
  @Input() className : string = '';
  @Input() requiredInput : string = '';
  @Input() pattern : string = '';
  @Input() tabindexcomponent : string = '';
  @Output() changeValue: EventEmitter<any> = new EventEmitter<string>();

  persons: Person[] = [];
  personImages: PersonImage = {};
  personOptions: Options[] = [];
  
  constructor(
    private _selectPersonService: SelectPersonService
  ) { }

  
  onChange(value : any) { 
    if(this.type == 'number') {
      this.form.setValue(parseInt(value));
    } else {
      this.form.setValue(value);
    }

    if(value == "null") this.form.setValue(null);
    this.changeValue?.emit(value)
  }

  ngOnInit(): void {
    const tenantId = localStorage.getItem('TenantId') || '';
    this._selectPersonService.getPersonsAndUserData(tenantId);
    this.persons = this._selectPersonService.persons;
    this.personOptions = this._selectPersonService.personOptions;
    this.personImages = this._selectPersonService.personImages;

  }


  getLabel(value: string): string{
    return this.personOptions.find(item => item.value === value)?.label!; 
  }

  showError(errorName: string): boolean{
    if(this.form.touched && this.form.errors?.[errorName]){
      return true
    }
    return false
  }

}
