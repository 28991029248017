import { Component, OnInit, Input, Output, EventEmitter, ViewChild, NgZone} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

@Component({
  selector: 'textareaauto-prop',
  templateUrl: './textarea-prop.component.html',
  styleUrls: ['./textarea-prop.component.scss'],
})
export class TextAreaAutoPropComponent implements OnInit {
  @Input() type : string = 'text';
  @Input() readonly: boolean = false;
  //@ts-ignore
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  //@ts-ignore
  @Input() form: UntypedFormControl;
  @Input() placeholder : string = '';
  @Input() label : string = '';
  @Input() mask : any = '';
  @Input() className : string = '';
  @Input() requiredInput : string = '';
  @Input() pattern : string = '';
  @Input() tabindexcomponent : string = '';

  @Input() max : string = '';
  @Input() maxlenght : string = '';

  PropValue : String ='';

  classe:string  = '';

  constructor(
      private _ngZone: NgZone
  ) {
   }

  validate(s:String) {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    return s.match(rgx);
  }

  onChange(value : String) { 
    this.form.setValue(value);
  }

  ngOnInit(): void {
    this.classe = this.className + ' input-row';
  }


  /**
   * Invoked when the model has been changed
   */

}
