import { Injectable } from '@angular/core';
import { BaseAuthService } from '../auth/base-auth.service';
import { HttpAuthService } from '@services/auth/http-auth.service';
import { Guid } from '@app/util/guid';
import { QueryParams } from '../interfaces/services.types';
import { HttpResponsePageable, HttpResponse } from '../services.types';

@Injectable({
  providedIn: 'root'
})

export class ZoeMachineService extends BaseAuthService {
  constructor(httpService: HttpAuthService) {
    super(httpService, 'v1/message/zoemachine');
  }

  override getPagination(queryObject?: QueryParams) {
    return super.getPagination(queryObject) as Promise<HttpResponsePageable<any>>
  }

  override delete(id?: Guid | undefined): Promise<HttpResponse> {
    return super.delete(id) as Promise<HttpResponse>
  }

  override post(body: Object): Promise<HttpResponse> {
    return super.post(body) as Promise<HttpResponse>
  }

  override put(body: Object, id: Guid): Promise<HttpResponse> {
    return super.put(body, id) as Promise<HttpResponse>
  }
  override patchActive(id: string, value: string): Promise<HttpResponse> {
    return super.patchActive(id, value) as Promise<HttpResponse>
  }
}