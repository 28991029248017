import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss'] 
})
export class KeywordsComponent implements OnInit {


  @Input() type : string = 'text';
  //@ts-ignore
  @Input() form: UntypedFormControl;
  @Input() placeholder : string = '';
  @Input() label : string = '';
  @Input() tabindexcomponent : string = '';
  @Input() mask : any = '';
  @Input() className : string = '';
  @Input() requiredInput : string = '';
  @Input() pattern : string = '';

  StringArray = [];
  String = '';

  PropValue : String ='';

  classe:string  = '';

  constructor(
  ) {
      
   }

  validate(s:String) {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    return s.match(rgx);
  }

  onChange(value : String) { 
    this.form.setValue(value);
  }

  ngOnInit(): void {
    this.classe = this.className + ' input-row';
  }

  AddItem() {
    //@ts-ignore
    this.String = this.String.replaceAll(',', '');
    if(this.String) {
      if(this.form.value) {
        this.form.setValue(this.form.value + ',' + this.String);
      } else {
        this.form.setValue(this.String);
      }
      if(this.StringArray.length == 0) {
        this.StringArray = this.form.value.split(',');
        if(this.StringArray.filter(f => f === this.String).length === 0)
        this.form.setValue(this.StringArray.join(','));
      } else {
        
        if(this.StringArray.filter(f => f === this.String).length === 0) {
          //@ts-ignore
          this.StringArray.push(this.String);
        }
        this.form.setValue(this.StringArray.join(','));
      }

      this.String = '';
    }
  }
  //@ts-ignore
  EnterSubmit(event) {
    if(event.KeyCode === 13) this.AddItem();
  }

  RemItem(item: String) {
    if(this.StringArray.length == 0) {
      this.StringArray = this.form.value.split(',');
      this.StringArray =  this.StringArray.filter(f => f !== item);
      this.form.setValue(this.StringArray.join(','));
    } else {
      //@ts-ignore
      this.StringArray =  this.StringArray.filter(f => f !== item);
      this.form.setValue(this.StringArray.join(','));
    }
  }


  /**
   * Invoked when the model has been changed
   */
}
