import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { DataComponent } from "@app/modules_new/common/components/data";
import { LoginFacade } from "@app/modules_new/external/data/facade/login.facade";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ToggleFormComponent } from "@app/modules_new/shared/components/form/toggle/toggle.component";
import { ContentReturnLayoutComponent } from "@app/modules_new/shared/layouts/concent-return/content-return.component";
import { AccessType, AccessTypePTBR } from "@app/util/EAccessType";
import { SwAlSetttings } from "@app/util/swal.settings";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "externalLogin",
   templateUrl: "./login.component.html",
   styleUrls: ["./login.component.scss"],
   standalone: true,
   imports: [
      ContentReturnLayoutComponent,
      InputTextFormComponent,
      ToggleFormComponent,
      ButtonFormComponent,
      ReactiveFormsModule,
      TranslateModule,
      CommonModule
   ]
})
export class LoginComponent extends DataComponent implements OnInit {
   listAccess: Array<any> = [
      {
         description: "Acesso Cliente"
      },
      {
         description: "Médico / Solicitante"
      },
      {
         description: "Convênio"
      }
      /*,
      {
        description: "Posto de Coleta",
      },
      {
        description: "Plano",
      },
      {
        description: "Destino",
      },
      {
        description: "Local de Coleta",
      }
      */
   ];

   customerAccess: boolean = true;
   accessType = 1;
   selectedOptionIndex = 0;
   recoverPass: boolean = false;
   hasRecoveryCode: boolean = false;
   stringAccessType = new FormControl(0);

   recoverAccess = new FormControl(null);

   public form = new FormGroup({
      accessCode: new FormControl(null),
      password: new FormControl(null),
      birth: new FormControl(null),
      accessToken: new FormControl(null)
   });

   public formRecover = new FormGroup({
      accessCode: new FormControl(null),
      token: new FormControl(null),
      newPassword: new FormControl(null),
      confirmPassword: new FormControl(null)
   });

   private _loginFacade = inject(LoginFacade);

   ngOnInit(): void {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessType");
      localStorage.removeItem("userImage");
      localStorage.removeItem("TenantId");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("refreshToken");
      sessionStorage.removeItem("accessType");
      sessionStorage.removeItem("userImage");
      sessionStorage.removeItem("TenantId");
      if (localStorage.getItem("token")) {
         localStorage.removeItem("token");
         window.location.reload();
      }
   }

   async login() {
      if (
         this.form.controls.accessCode.value &&
         this.form.controls.password.value
      ) {
         if (
            this.accessType == AccessType.Customer &&
            !this.form.controls.birth.value
         ) {
            setTimeout(() => {
               SwAlSetttings.printMessageError(
                  "Data de Nascimento é obrigatório para o acesso de Cliente!"
               );
            }, 100);
         } else {
            let customerObject = {
               accessType: this.accessType,
               accessCode: this.form.controls.accessCode.value,
               password: this.form.controls.password.value,
               birthDate:
                  this.accessType === AccessType.Customer //@ts-ignore
                     ? new Date(this.form.controls.birth.value).toISOString()
                     : null,
               accessToken: this.form.controls.accessToken.value
            };

            let object = {
               accessType: this.accessType,
               accessCode: this.form.controls.accessCode.value,
               password: this.form.controls.password.value,
               accessToken: this.form.controls.accessToken.value
            };

            const res = await this._loginFacade.login(
               this.accessType === AccessType.Customer ? customerObject : object
            );

            if (res) {
               sessionStorage.setItem(
                  "token",
                  res.accessToken ? res.accessToken : ""
               );
               sessionStorage.setItem(
                  "refreshToken",
                  res.refreshToken ? res.refreshToken : ""
               );
               sessionStorage.setItem(
                  "TenantId",
                  res.tenantId ? res.tenantId : ""
               );
               sessionStorage.setItem(
                  "accessType",
                  res.accessType.toString() ? res.accessType.toString() : ""
               );
               let accessType = res.accessType;
               if (accessType) {
                  window.location.href = `Externo/${
                     AccessTypePTBR[res.accessType]
                  }`;
               } else {
                  SwAlSetttings.printMessageError(
                     "Não foi possível acessar os resultados!"
                  );
               }
            }
         }
      } else {
         setTimeout(() => {
            SwAlSetttings.printMessageError(
               "Usuário e Senha são obrigatórios!"
            );
         }, 100);
      }
   }

   chooseAccessType(accessType: any, index: number) {
      this.selectedOptionIndex = index;

      this.stringAccessType.setValue(accessType);

      switch (accessType.description) {
         case "Acesso Cliente":
            this.accessType = AccessType.Customer;
            break;
         case "Médico / Solicitante":
            this.accessType = AccessType.Doctor;
            break;
         case "Convênio":
            this.accessType = AccessType.Agreement;
            break;
         case "Posto de Coleta":
            this.accessType = AccessType.Unity;
            break;
         case "Plano":
            this.accessType = AccessType.Plan;
            break;
         case "Destino":
            this.accessType = AccessType.Destiny;
            break;
         case "Local de Coleta":
            this.accessType = AccessType.CollectionPlace;
            break;
         default:
            break;
      }

      if (this.accessType === AccessType.Customer) {
         this.customerAccess = true;
      } else {
         this.customerAccess = false;
      }
   }

   back(recoveryCode?: boolean) {
      if (!recoveryCode) {
         this.form.controls.accessCode.setValue(null);
         this.form.controls.password.setValue(null);
         this.form.controls.birth.setValue(null);
         this.recoverPass = !this.recoverPass;
      } else {
         this.form.controls.accessCode.setValue(null);
         this.form.controls.password.setValue(null);
         this.form.controls.birth.setValue(null);
         this.hasRecoveryCode = !this.hasRecoveryCode;
      }
   }

   async recover(changePassword?: boolean) {
      this.hasRecoveryCode = true;
      this.recoverPass = true;

      let recoverPasswordObject = {
         typeForgotPassword: 0,
         token: "",
         newPassword: "",
         confirmPassword: "",
         identification: this.recoverAccess.value,
         resendToken: true
      };

      let changePasswordObject = {
         typeForgotPassword: 1,
         token: this.formRecover.controls.token.value,
         newPassword: this.formRecover.controls.newPassword.value,
         confirmPassword: this.formRecover.controls.confirmPassword.value,
         identification: this.formRecover.controls.accessCode.value,
         resendToken: true
      };

      const success = await this._loginFacade.forgot(
         changePassword ? changePasswordObject : recoverPasswordObject
      );

      if (success) {
         changePassword
            ? SwAlSetttings.Sucesso("Senha alterada com sucesso!")
            : SwAlSetttings.Sucesso(
                 "Olá, se suas credênciais estiverem corretas você receberá um sms contendo um token para validação e alteração da sua senha!"
              );

         if (changePassword) {
            this.hasRecoveryCode = false;
            this.recoverPass = false;
         }
      }
   }
}
