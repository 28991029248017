<div class="contentPage">
  <div class="flex align-center w-100 flex-direction-column-mobile flex-between">
    <h1>Triagem</h1>
  </div>
  <div class="mt-32 flex flex-column">
    <div class="flex align-center w-100 flex-direction-column-mobile flex-between">
      <app-button (click)="openDialogModalUserValidation()" *ngIf="user === ''" [placeholder]="'Autenticar Usuário'" [class]="'flex gap-2 align-center'"></app-button>
      <div *ngIf="user != ''" class="flex flex-between align-center gap-2">
        <h1>Usuário Atual:</h1>
        <b>{{user}}</b>
        <app-button (click)="finish()" [placeholder]="'Finalizar sessão'" [class]="'flex gap-2 align-center'"></app-button>
      </div>
      <input-prop *ngIf="user != ''" (keydown.enter)="form.codeBar.value ? putCodeBar(form.codeBar.value) : ''" type="number" placeholder="Informe o Código de Barras" [form]="form.codeBar"></input-prop>
    </div>
    <div class="data-table mt-32">
      <app-table [Pagination]="false" [backPagination]="false" [Itens]="registerRecipients" [Actions]="['Excluir']" (parentFun)="actions($event)" ></app-table>      
    </div>
    <div class="flex flex-center mt-32">
      <app-button (click)="finishSorting()" [placeholder]="'Finalizar triagem'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
  </div>
</div>

<ng-template #dialogUserValidation let-userValidation>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h2>Autenticação por Código de Barras</h2>
        <svg class="close-svg" (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="flex w-100 mt-32 gap-2 align-center">
        <div class="w-100">
          <input-prop (keydown.enter)="validateUserCodebar()" type="number" [form]="form.userCodeBar" [placeholder]="'Código de Barras'"></input-prop>
        </div>
      </div>
      <div class="flex flex-center gap-2 mt-32">
        <app-button (click)="validateUserCodebar()" [placeholder]="'Validar'" [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>