import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { RequisitionProtocolReportComponent } from "./requisition/protocol/requisition_protocol.component";
import { AnalyteReportComponent } from "./analytes/analyte-report/analyte-report.component";
import { RecipientsReportComponent } from "./recipients-report/recipients-report/recipients-report.component";
import { ClientReportComponent } from "./client-report/client-report/client-report.component";
import { ProductReportComponent } from './products/product-report/product-report.component';
import { QuestionReportComponent } from "./questions/question-report/question-report.component";
import { MethodologyComponent } from "./methodology/methodology/methodology.component";
import { MaterialsReportComponent } from "./materials/materials-report/materials-report.component";

import { UnitMeasureReportComponent } from "./unit-measure/unit-measure-report/unit-measure-report.component";
import { PriceTablesReportComponent } from "./price-Tables/price-tables-report/price-tables-report.component";
import { TitleReportComponent } from "./title-report/title-report.component";
import { GroupProductReportComponent } from "./group-product-report/group-product-report.component";
import { TypesPaymentReportComponent } from "./types-payment-report/types-payment-report.component";
import { AgreementsReportComponent } from "./agreement/agreements-report/agreements-report.component";
import { DoctorReportComponent } from "./doctor/doctor-report/doctor-report.component";

const routes: Routes = [
    {
        path: 'Requisition', children: [
            { path: 'Protocol', component: RequisitionProtocolReportComponent },
        ]
    },
    {
        path: 'Relatorios', children: [
            { path: 'Cadastro/Exames-Laboratoriais', component: AnalyteReportComponent },
            { path: 'Cadastro/Recipientes', component: RecipientsReportComponent },
            { path: 'Cadastro/Clientes', component: ClientReportComponent },
            { path: 'Financeiro/Títulos', component: TitleReportComponent },
            { path: 'Cadastro/Perguntas', component: QuestionReportComponent },
            { path: 'Cadastro/Metodologias', component: MethodologyComponent },
            { path: 'Cadastro/Solicitante', component: DoctorReportComponent },
            { path: 'Cadastro/GrupoDeEstoque', component: GroupProductReportComponent },
            { path: 'Cadastro/Produtos', component: ProductReportComponent },
            { path: 'Cadastro/UnidadesDeMedida', component: UnitMeasureReportComponent },
            { path: 'Cadastro/TabelaDePrecos', component: PriceTablesReportComponent },
            { path: 'Cadastro/TipoDePagamento', component: TypesPaymentReportComponent },
            { path: 'Cadastro/Convenios', component: AgreementsReportComponent },
            { path: 'Cadastro/Materiais', component: MaterialsReportComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ReportsRoutingModule { }