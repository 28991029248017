import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
   UntypedFormControl,
   UntypedFormGroup,
   Validators
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AnalyteService } from "@app/services/analyte/analyte.service";
import { AnalyteMaterialService } from "@app/services/analyte-material/analyte-material.service";
import { ExamsAdditionalInformationLaboratoryServiceGetAll } from "@app/services/exams-additional-information-laboratory/exams-additional-information-laboratory";
import { ExamsAdditionalInformationLaboratoryService } from "@app/services/exams-additional-information-laboratory/exams-additional-information-laboratory.service";
import {
   DeParaAnalyte,
   ExamsSupportLaboratoryGetAll,
   ExamsSupportLaboratoryPostobject,
   ExamsSupportLaboratoryPutObject
} from "@app/services/exams-support-laboratory/exams-support-laboratory";
import { ExamsSupportLaboratoryService } from "@app/services/exams-support-laboratory/exams-support-laboratory.service";
import {
   SupportLaboratoryPostObject,
   SupportLaboratoryPutObject
} from "@app/services/support-laboratory/support-laboratory";
import { SupportLaboratoryService } from "@app/services/support-laboratory/support-laboratory.service";
import { Guid } from "@app/util/guid";
import { SwAlSetttings } from "@app/util/swal.settings";
import {
   OptionMaterial,
   Actions,
   resolveCommunicationCredentials,
   CredentialOperation,
   resolveToFromExams,
   resolveExamsAdditionalInformation,
   resolveCollectionTime,
   resolveAtributeRelationship
} from "./add-laboratory.model";
import { DefaultFormats, LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { GetActiveFilterPipe } from "@app/pipe/active.pipe";
import { PersonService } from "@app/services/person/person.service";
import { ReportService } from "@app/services/report/report.service";
import { CheckboxSvgPipe } from "@app/pipe/checkbox-Svg.pipe";
import { AtributeRelationshipService } from "@app/services/atribute-relationship/atribute-relationship.service";
import { SupportLaboratoryCollectionTimeService } from "@app/services/support-laboratory-collection-time/SupportLaboratoryCollectionTime.service";
import { AnalyteQuestionService } from "@app/services/analyte-question/analyte-question.service";
import { AtributeReportService } from "@app/services/atribute-report/atribute-report.service";
import { CredentialsSupportLaboratoryService } from "@app/services/credentials-support-laboratory/credentials-support-laboratory.service";
import {
   CreateCredentialSupportLaboratory,
   GetAllCredentialsSupportLaboratory,
   UpdateCredentialSupportLaboratory
} from "@app/services/credentials-support-laboratory/credentials-support-laboratory-model";
import { Util } from "@app/util/util";
import { GetByIdPerson } from "@app/services/person/person.types";
import { GetByPathReport } from "@app/services/report/report.service.model";
import { AnalyteMaterialRecipientService } from "@app/services/analyte-material-recipient/analyte-material-recipient.service";
import { AtributeAnalyteService } from "@app/services/atribute-analyte/atribute-analyte.service";
import { UnityService } from "@app/services/shared/unity.service";
import { CompanyService } from "@app/services/company/company.service";
import { CompanyOption } from "../../financial/bearer/bearer-list/bearer.model";

@Component({
   selector: "app-save-laboratory",
   templateUrl: "./save-laboratory.component.html",
   styleUrls: ["./save-laboratory.component.scss"]
})
export class SaveLaboratoryComponent {
   /* #region Laboratório */

   selectedAnalyteId: string = "";
   informationId: string = "";
   blocked: boolean = false;
   companyOptions: Array<CompanyOption> = [];

   onOptionLaboratory = [
      {
         label: "Alvaro",
         value: 0
      },
      {
         label: "Cap Brasil",
         value: 1
      },
      {
         label: "Concent",
         value: 2
      },
      {
         label: "DB Diagnósticos Protocolo 1",
         value: 3
      },
      {
         label: "DB Diagnósticos Protocolo 2",
         value: 4
      },
      {
         label: "DB Diagnósticos Protocolo 3",
         value: 5
      },
      {
         label: "Sao Marcos",
         value: 6
      },
      {
         label: "Maricondi",
         value: 7
      },
      {
         label: "Hermes Pardini",
         value: 8
      },
      {
         label: "Shift",
         value: 9
      },
      {
         label: "Unilab",
         value: 10
      },
      {
         label: "Zenite",
         value: 11
      },
      {
         label: "Lab Rede ",
         value: 12
      },
      {
         label: "Gimenes",
         value: 13
      },
      {
         label: "Atitude",
         value: 14
      },
      {
         label: "CDA Naoun",
         value: 15
      },
      {
         label: "Cerpa",
         value: 16
      },
      {
         label: "Fleury",
         value: 17
      },
      {
         label: "Solução",
         value: 18
      },
      {
         label: "Solução Matrix",
         value: 19
      },
      {
         label: "Madya",
         value: 20
      },
      {
         label: "Matrix Connect",
         value: 21
      },
      {
         label: "Multilab",
         value: 22
      },
      {
         label: "NeoVita Salus",
         value: 23
      },
      {
         label: "Patho",
         value: 24
      },
      {
         label: "Genoa",
         value: 25
      },
      {
         label: "SysLab WebService",
         value: 26
      },
      {
         label: "SysLab Arquivo",
         value: 27
      }
   ];

   genderOption = [
      {
         label: "Masculino",
         value: 0
      },
      {
         label: "Feminino",
         value: 1
      },
      {
         label: "Não binario",
         value: 2
      },
      {
         label: "Não informado",
         value: 3
      }
   ];

   supportLaboratoryFormGroup = new UntypedFormGroup({
      isActive: new UntypedFormControl(),
      typeCommunication: new UntypedFormControl(),
      name: new UntypedFormControl(),
      nameAgent: new UntypedFormControl(),
      phoneAgent: new UntypedFormControl(),
      personId: new UntypedFormControl(),
      personName: new UntypedFormControl(),
      cpf: new UntypedFormControl(),
      street: new UntypedFormControl(),
      number: new UntypedFormControl(),
      neighborhood: new UntypedFormControl(),
      city: new UntypedFormControl(),
      state: new UntypedFormControl(),
      zipCode: new UntypedFormControl(),
      complement: new UntypedFormControl(),
      country: new UntypedFormControl(),
      telephone: new UntypedFormControl(),
      cellPhone: new UntypedFormControl(),
      email: new UntypedFormControl()
   });

   supportLaboratoryCredentialsFormGroup = new UntypedFormGroup({
      companyId: new UntypedFormControl(),
      unityId: new UntypedFormControl(),
      codeLogin: new UntypedFormControl(),
      password: new UntypedFormControl(),
      apiKey: new UntypedFormControl(),
      urlService: new UntypedFormControl(),
      urlComplement: new UntypedFormControl(),
      isActive: new UntypedFormControl()
   });

   checkBoxForm = {
      newPerson: new UntypedFormControl(false),
      selectPerson: new UntypedFormControl(true)
   };

   /* #endregion */

   /* #region DePara exames */

   toFromExamsFormGroup = new UntypedFormGroup({
      analyteId: new UntypedFormControl(null),
      materialId: new UntypedFormControl(null),
      analyteReportId: new UntypedFormControl(null),
      codeInSupportLaboratory: new UntypedFormControl(null),
      material: new UntypedFormControl(null),
      value: new UntypedFormControl(null),
      reference: new UntypedFormControl(null),
      hasRelationship: new UntypedFormControl(null)
   });

   registerExam: Array<Object> = [];

   formOptionsAnalyte: Array<Object> = [];

   formOptionsMaterial: Array<OptionMaterial> = [];

   /* #endregion */

   /* #region DePara pergunta */

   examsAdditionalInformationIndex: number = 50;
   examsAdditionalInformationPage: number = 1;
   examsAdditionalInformationTotal: any = {};
   examsAdditionalInformationTable: any = {};

   atributeRelationshipIndex: number = 50;
   atributeRelationshipPage: number = 1;
   atributeRelationshipTotal: any = {};
   atributeRelationshipTable: any = {};

   collectionTimeIndex: number = 50;
   collectionTimePage: number = 1;
   collectionTimeTotal: any = {};
   collectionTimeTabel: any = {};

   registerExamsAdditionalInformation: any = {};
   registerColectionTime: any = {};
   registerAtributeRelationship: any = {};

   formInformation = {
      description: new UntypedFormControl(null),
      requerid: new UntypedFormControl(false),
      codeInSupportLaboratory: new UntypedFormControl(null),
      examsSupportLaboratoryId: new UntypedFormControl(null),
      questionId: new UntypedFormControl(null)
   };

   examsAdditionalInformationFormGroup = new UntypedFormGroup({
      description: new UntypedFormControl(null),
      requerid: new UntypedFormControl(null),
      codeInSupportLaboratory: new UntypedFormControl(null),
      examsSupportLaboratoryId: new UntypedFormControl(null),
      questionId: new UntypedFormControl(null)
   });

   formExamsAdditiona = new UntypedFormGroup({
      examsSupportLaboratoryId: new UntypedFormControl(null),
      atributeReportid: new UntypedFormControl(null),
      atributeSupportLaboratory: new UntypedFormControl(null)
   });

   formCollectionTime = new UntypedFormGroup({
      examsSupportLaboratoryId: new UntypedFormControl(null),
      analyteMaterialRecipientId: new UntypedFormControl(null),
      sampleTimeDescription: new UntypedFormControl(null)
   });

   filterAtributeRelationship: Array<any> = [];
   questionsExamsAdditional: Array<Object> = [];
   analyteQuestionOptions: Array<Object> = [];
   optionCollectionTime: Array<Object> = [];

   /* #endregion */

   title: string = "Editar Laboratório de Apoio";

   ECredentialOperation = CredentialOperation;
   supportLaboratoryId: string = "";
   persons: Array<Object> = [];
   analyteReportOptions: Array<{ value: string; label: string }> = [];

   unityOptions: Array<Object> = [
      {
         label: "Todos",
         value: null
      }
   ];

   credentialsIndex: number = 50;
   credentialsPage: number = 1;
   credentialsTotal: number = 0;

   communicationCredentialRegister: Array<GetAllCredentialsSupportLaboratory> =
      [];

   ActionsExtra = {
      Ativo: "patchActive"
   };

   constructor(
      private _dialog: MatDialog,
      private _router: Router,
      private _supportLaboratoryService: SupportLaboratoryService,
      private _route: ActivatedRoute,
      private _examsSupportLaboratoryService: ExamsSupportLaboratoryService,
      private _analyteService: AnalyteService,
      private _examsAdditionalInformationLaboratoryService: ExamsAdditionalInformationLaboratoryService,
      private _analyteMaterialService: AnalyteMaterialService,
      private _localaDatePipe: LocalaDatePipe,
      private _activePipe: GetActiveFilterPipe,
      private _personService: PersonService,
      private _reportService: ReportService,
      private _checkboxSvgPipe: CheckboxSvgPipe,
      private _atributeRelationshipService: AtributeRelationshipService,
      private _supportLaboratoryCollectionTimeService: SupportLaboratoryCollectionTimeService,
      private _analyteQuestionService: AnalyteQuestionService,
      private _atributeReportService: AtributeReportService,
      private _unityService: UnityService,
      private _credentialsSupportLaboratoryService: CredentialsSupportLaboratoryService,
      private _util: Util,
      private _analyteMaterialRecipient: AnalyteMaterialRecipientService,
      private _atributeAnalyteService: AtributeAnalyteService,
      private _companyService: CompanyService
   ) {
      const urlParams: string[] = _route.snapshot.params["id"].split("$");
      _route.snapshot.params["id"] != "Novo"
         ? (this.supportLaboratoryId = urlParams[0])
         : (this.title = "Novo Laboratório de Apoio");
   }

   @ViewChild("dialogExam")
   dialogExam!: TemplateRef<any>;

   @ViewChild("dialogInformationCreate")
   dialogInformationCreate!: TemplateRef<any>;

   @ViewChild("dialogAtributeRelationship")
   dialogAtributeRelationship!: TemplateRef<any>;

   @ViewChild("dialogExamsAdditionalInformationLaboratory")
   dialogExamsAdditionalInformationLaboratory!: TemplateRef<any>;

   @ViewChild("dialogSupportLaboratoryCollectionTime")
   dialogSupportLaboratoryCollectionTime!: TemplateRef<any>;

   @ViewChild("dialogCommunicationCredentials")
   dialogCommunicationCredentials!: TemplateRef<any>;

   ngAfterViewInit() {
      if (this.supportLaboratoryId) {
         this.getByIdSupportLaboratory();
         this.getExam();
         this.getCommunicationCredentials();
      }
   }

   async getPersons(personName: string) {
      this.persons = [];

      const queryObject = {
         name: personName
      };

      await this._personService.getPagination(queryObject).then((res) => {
         if (res.data.length == 0) {
            SwAlSetttings.warningMessage(
               `Nenhum cadastro encontrado para o nome "${personName}"`
            );
            return;
         }
         this.persons = res.data.map((person: any) => ({
            value: person.id,
            label: person.name
         }));
      });
   }

   async getPersonLabels() {
      await this._personService
         .getById(this.supportLaboratoryFormGroup.controls["personId"].value)
         .then((response) => {
            const person = response.data[0];
            this.supportLaboratoryFormGroup.controls["personName"].setValue(
               person.name
            );
            this.supportLaboratoryFormGroup.controls["cpf"].setValue(
               person.cpf
            );

            if (person.address) {
               const address: any = person.address;
               this.supportLaboratoryFormGroup.controls["street"].setValue(
                  address.street
               );
               this.supportLaboratoryFormGroup.controls["number"].setValue(
                  address.number
               );
               this.supportLaboratoryFormGroup.controls[
                  "neighborhood"
               ].setValue(address.neighborhood);
               this.supportLaboratoryFormGroup.controls["city"].setValue(
                  address.city
               );
               this.supportLaboratoryFormGroup.controls["state"].setValue(
                  address.state
               );
               this.supportLaboratoryFormGroup.controls["zipCode"].setValue(
                  address.zipCode
               );
               this.supportLaboratoryFormGroup.controls["complement"].setValue(
                  address.complement
               );
               this.supportLaboratoryFormGroup.controls["country"].setValue(
                  address.country
               );
            }
         });
   }

   back() {
      this._router.navigateByUrl(
         "/CadastrosOld/Laboratorios-de-Apoio/Laboratorio-Apoio"
      );
   }

   close() {
      this._dialog.closeAll();
   }

   async getCep() {
      let cep: string = "";

      if (this.supportLaboratoryFormGroup.controls["zipCode"].value) {
         let tmpCep = this.supportLaboratoryFormGroup.controls["zipCode"].value;
         cep = tmpCep.replace(/\D/g, "");
      }

      if (cep != "") {
         const validacep: RegExp = /^[0-9]{8}$/;

         if (validacep.test(cep)) {
            await fetch(`https://viacep.com.br/ws/${cep}/json/`)
               .then((response) => {
                  return response.json();
               })
               .then((json) => {
                  if (!("erro" in json)) {
                     this.supportLaboratoryFormGroup.controls[
                        "zipCode"
                     ].setValue(json.cep);
                     this.supportLaboratoryFormGroup.controls[
                        "street"
                     ].setValue(json.logradouro);
                     this.supportLaboratoryFormGroup.controls[
                        "neighborhood"
                     ].setValue(json.bairro);
                     this.supportLaboratoryFormGroup.controls["city"].setValue(
                        json.localidade
                     );
                     this.supportLaboratoryFormGroup.controls["state"].setValue(
                        json.uf
                     );
                     this.supportLaboratoryFormGroup.controls[
                        "country"
                     ].setValue("Brasil");
                  } else {
                     this.clearCepForm();
                     setTimeout(() => {
                        SwAlSetttings.alertaMsg("CEP não encontrado!");
                     }, 100);
                  }
               });
         }
      }
   }

   clearCepForm() {
      this.supportLaboratoryFormGroup.controls["zipCode"].setValue(null);
      this.supportLaboratoryFormGroup.controls["street"].setValue(null);
      this.supportLaboratoryFormGroup.controls["neighborhood"].setValue(null);
      this.supportLaboratoryFormGroup.controls["city"].setValue(null);
      this.supportLaboratoryFormGroup.controls["state"].setValue(null);
   }

   async createSupportLaboratory() {
      const createSupportLaboratoryObject: SupportLaboratoryPostObject = {
         typeCommunication:
            this.supportLaboratoryFormGroup.controls["typeCommunication"].value,
         address: {
            street: this.supportLaboratoryFormGroup.controls["street"].value,
            number: this.supportLaboratoryFormGroup.controls["number"].value,
            neighborhood:
               this.supportLaboratoryFormGroup.controls["neighborhood"].value,
            city: this.supportLaboratoryFormGroup.controls["city"].value,
            state: this.supportLaboratoryFormGroup.controls["state"].value,
            zipCode: this.supportLaboratoryFormGroup.controls["zipCode"].value,
            complement:
               this.supportLaboratoryFormGroup.controls["complement"].value,
            country: this.supportLaboratoryFormGroup.controls["country"].value
         },
         name: this.supportLaboratoryFormGroup.controls["name"].value,
         personId: this.supportLaboratoryFormGroup.controls["personId"].value,
         personName:
            this.supportLaboratoryFormGroup.controls["personName"].value,
         cpf: {
            value: this.supportLaboratoryFormGroup.controls["cpf"].value
         },
         nameAgent: this.supportLaboratoryFormGroup.controls["nameAgent"].value,
         phoneAgent:
            this.supportLaboratoryFormGroup.controls["phoneAgent"].value,
         telephone: this.supportLaboratoryFormGroup.controls["telephone"].value,
         cellphone: this.supportLaboratoryFormGroup.controls["cellPhone"].value,
         email: this.supportLaboratoryFormGroup.controls["email"].value
      };

      await this._supportLaboratoryService
         .post(createSupportLaboratoryObject)
         .then(() => {
            this.back();
            SwAlSetttings.Sucesso(
               "Laboratório de Apoio cadastrado com sucesso!"
            );
         });
   }

   cpfCnpj(e: any) {
      if (e.target.value.length) {
         this.supportLaboratoryFormGroup.controls["cpf"].setValue(
            this.supportLaboratoryFormGroup.controls["cpf"].value
               ?.replaceAll(".", "")
               .replaceAll("-", "")
               .replaceAll("/", "")
         );
      }
   }

   async updateSupportLaboratory() {
      const updateSupportLaboratoryObject = {
         cpf: {
            value: this.supportLaboratoryFormGroup.controls["cpf"].value
         },
         address: {
            street: this.supportLaboratoryFormGroup.controls["street"].value,
            number: this.supportLaboratoryFormGroup.controls["number"].value,
            neighborhood:
               this.supportLaboratoryFormGroup.controls["neighborhood"].value,
            city: this.supportLaboratoryFormGroup.controls["city"].value,
            state: this.supportLaboratoryFormGroup.controls["state"].value,
            zipCode: this.supportLaboratoryFormGroup.controls["zipCode"].value,
            complement:
               this.supportLaboratoryFormGroup.controls["complement"].value,
            country: this.supportLaboratoryFormGroup.controls["country"].value
         },
         typeComunication:
            this.supportLaboratoryFormGroup.controls["typeCommunication"].value,
         name: this.supportLaboratoryFormGroup.controls["name"].value,
         nameAgent: this.supportLaboratoryFormGroup.controls["nameAgent"].value,
         personId: this.supportLaboratoryFormGroup.controls["personId"].value,
         personName:
            this.supportLaboratoryFormGroup.controls["personName"].value,
         phoneAgent:
            this.supportLaboratoryFormGroup.controls["phoneAgent"].value,
         telephone: this.supportLaboratoryFormGroup.controls["telephone"].value,
         cellPhone: this.supportLaboratoryFormGroup.controls["cellPhone"].value,
         email: this.supportLaboratoryFormGroup.controls["email"].value
      };

      await this._supportLaboratoryService
         .put(updateSupportLaboratoryObject, new Guid(this.supportLaboratoryId))
         .then(async () => {
            SwAlSetttings.Sucesso("Laboratório de Apoio editado com sucesso!");
            this.back();
         });
   }

   async getByIdSupportLaboratory() {
      await this._supportLaboratoryService
         .getById(new Guid(this.supportLaboratoryId))
         .then((res) => {
            const supportLaboratory = res.data[0];

            this.persons = [
               {
                  label: supportLaboratory.personName,
                  value: supportLaboratory.personId
               }
            ];

            this.supportLaboratoryFormGroup.controls["name"].setValue(
               supportLaboratory.name
            );
            this.supportLaboratoryFormGroup.controls["nameAgent"].setValue(
               supportLaboratory.nameAgent
            );
            this.supportLaboratoryFormGroup.controls["cpf"].setValue(
               supportLaboratory.cpf
            );
            this.supportLaboratoryFormGroup.controls["phoneAgent"].setValue(
               supportLaboratory.phoneAgent
            );
            this.supportLaboratoryFormGroup.controls["personId"].setValue(
               supportLaboratory.personId
            );
            this.supportLaboratoryFormGroup.controls["personName"].setValue(
               supportLaboratory.personName
            );
            this.supportLaboratoryFormGroup.controls["telephone"].setValue(
               supportLaboratory.telephone
            );
            this.supportLaboratoryFormGroup.controls["cellPhone"].setValue(
               supportLaboratory.cellPhone
            );
            this.supportLaboratoryFormGroup.controls["email"].setValue(
               supportLaboratory.email
            );

            if (supportLaboratory.address) {
               this.supportLaboratoryFormGroup.controls["zipCode"].setValue(
                  supportLaboratory.address.zipCode
               );
               this.supportLaboratoryFormGroup.controls["street"].setValue(
                  supportLaboratory.address.street
               );
               this.supportLaboratoryFormGroup.controls["number"].setValue(
                  supportLaboratory.address.number
               );
               this.supportLaboratoryFormGroup.controls["complement"].setValue(
                  supportLaboratory.address.complement
               );
               this.supportLaboratoryFormGroup.controls["city"].setValue(
                  supportLaboratory.address.city
               );
               this.supportLaboratoryFormGroup.controls[
                  "neighborhood"
               ].setValue(supportLaboratory.address.neighborhood);
               this.supportLaboratoryFormGroup.controls["state"].setValue(
                  supportLaboratory.address.state
               );
               this.supportLaboratoryFormGroup.controls["country"].setValue(
                  supportLaboratory.address.country
               );
            }

            this.supportLaboratoryFormGroup.controls[
               "typeCommunication"
            ].setValue(supportLaboratory.typeComunication);
            this.supportLaboratoryFormGroup.controls["isActive"].setValue(
               supportLaboratory.isActive
            );
         });
   }

   /* #region DePara Exames */

   resolveToFromExams(data: any, columns: any) {
      this.registerExam = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registerExam.push(obj);
      });
   }

   isHasRelationship: any = {};

   async actionsExam(emit: Actions) {
      switch (emit.action) {
         case "Editar":
            this.setValuesToExamsFormGroup(emit.object);
            this.openDialogExam(true, emit.object.id);
            break;
         case "Excluir":
            this.deleteExam(emit.object.id);
            break;
         case "OpenDropdown":
            await this.getExamsAdditionalInformation(emit.object.id);
            emit.object._hide_hasRelationship.includes("true")
               ? (this.isHasRelationship[emit.id] = true)
               : (this.isHasRelationship[emit.id] = false);
            this.getAtributeRelationship(emit.object.id);
            this.getCollectionTime(emit.object.id);
            break;
         case "patchActive":
            this.patchActiveExams(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
      }
   }

   patchActiveExams(id: string, value: string) {
      this._examsSupportLaboratoryService.patchActive(id, value).then(() => {
         SwAlSetttings.Sucesso("Situação Alterada!");
      });
   }

   async setValuesToExamsFormGroup(emit: any) {
      this.toFromExamsFormGroup.controls["analyteId"].setValue(
         emit._hide_analyteId
      );
      this.toFromExamsFormGroup.controls["codeInSupportLaboratory"].setValue(
         emit["Exame no Apoio"]
      );
      await this.getMaterial();
      this.toFromExamsFormGroup.controls["materialId"].setValue(
         emit._hide_materialId
      );
      this.toFromExamsFormGroup.controls["material"].setValue(
         emit["Material no Apoio"]
      );
      this.toFromExamsFormGroup.controls["analyteReportId"].setValue(
         emit._hide_analyteReportId
      );
      await this.getAnalyteReports();
      this.toFromExamsFormGroup.controls["value"].setValue(
         emit._hide_value != "undefined" ? emit._hide_value : null
      );
      this.toFromExamsFormGroup.controls["hasRelationship"].setValue(
         JSON.parse(emit._hide_hasRelationship)
      );
      this.toFromExamsFormGroup.controls["reference"].setValue(
         JSON.parse(emit._hide_reference)
      );
   }

   async openDialogExam(edit: boolean, id?: string) {
      await this.getExamsOption();

      const myTempDialog = this._dialog.open(this.dialogExam, {
         width: "70%",
         data: { editar: edit, id: id }
      });

      myTempDialog.afterClosed().subscribe(() => {
         this.resetToFromExamsFormGroup();
      });
   }

   async getExamsOption() {
      this.formOptionsAnalyte = [];
      this.formOptionsMaterial = [];
      await this._analyteService.getResume().then((res) => {
         res.data.forEach((analyte) => {
            this.formOptionsAnalyte.push({
               label: `${analyte.code} - ${analyte.name}`,
               value: analyte.id
            });
         });
      });
   }

   getMaterialsAndReports() {
      this.getMaterial();
      this.getAnalyteReports();
   }

   async getMaterial() {
      if (this.toFromExamsFormGroup.controls["analyteId"].value) {
         this.formOptionsMaterial = [];

         await this._analyteMaterialService
            .getPagination({
               analyteId: this.toFromExamsFormGroup.controls["analyteId"].value
            })
            .then((x: any) => {
               x.data.forEach((res: any) => {
                  this.formOptionsMaterial.push({
                     label: res.description,
                     value: res.materialId,
                     analyteId:
                        this.toFromExamsFormGroup.controls["analyteId"].value,
                     analyteMaterialId: res.id
                  });
               });
               if (this.formOptionsMaterial.length === 1)
                  this.toFromExamsFormGroup.controls["materialId"].setValue(
                     (this.formOptionsMaterial[0] as any).value
                  );
            });
      }
   }

   async getAnalyteReports() {
      const queryObject = {
         analyteId: this.toFromExamsFormGroup.controls["analyteId"].value
      };
      this.toFromExamsFormGroup.controls["analyteReportId"].reset();
      await this._reportService
         .getPagination<GetByPathReport>(queryObject)
         .then((res) => {
            this.analyteReportOptions = res.data.map((analyteReport) => ({
               value: analyteReport.id,
               label: analyteReport.name
            }));
            if (this.analyteReportOptions.length === 1)
               this.toFromExamsFormGroup.controls["analyteReportId"].setValue(
                  this.analyteReportOptions[0].value
               );
            if (res.data.length == 0)
               SwAlSetttings.warningMessage(
                  "O Exame selecionado não possui Laudo configurado!"
               );
         });
   }

   async postExam() {
      const materialObject = this.formOptionsMaterial.find(
         (material) =>
            material.value ===
            this.toFromExamsFormGroup.controls["materialId"].value
      );

      const postExam: ExamsSupportLaboratoryPostobject = {
         supportLaboratoryId: this.supportLaboratoryId,
         analyteId: this.toFromExamsFormGroup.controls["analyteId"].value,
         analyteMaterialId: materialObject
            ? materialObject.analyteMaterialId
            : null,
         codeInSupportLaboratory:
            this.toFromExamsFormGroup.controls["codeInSupportLaboratory"].value,
         materialInSupportLaboratory:
            this.toFromExamsFormGroup.controls["material"].value,
         value: this.toFromExamsFormGroup.controls["value"].value,
         acceptValueReference:
            this.toFromExamsFormGroup.controls["reference"].value,
         reportId: this.toFromExamsFormGroup.controls["analyteReportId"].value,
         hasRelationship:
            this.toFromExamsFormGroup.controls["hasRelationship"].value
      };

      await this._examsSupportLaboratoryService
         .post(postExam)
         .then(async (res) => {
            if (res.success) {
               setTimeout(() => {
                  SwAlSetttings.Sucesso(
                     "De/Para de Exames criado com sucesso!"
                  );
               });
               this.close();
               this.ngAfterViewInit();
               this.resetToFromExamsFormGroup();
            }
         });
   }

   resetToFromExamsFormGroup() {
      this.toFromExamsFormGroup.reset();
      this.formOptionsMaterial = [];
      this.analyteReportOptions = [];
   }

   deleteExam(id: string) {
      SwAlSetttings.ALERT_CONFIRM.fire(
         "Deseja excluir o De/Para de Exames?"
      ).then(async (r) => {
         if (r.isConfirmed) {
            await this._examsSupportLaboratoryService.delete(new Guid(id));
            this.ngAfterViewInit();
            SwAlSetttings.Sucesso("De/Para de Exames excluído com sucesso!");
         }
      });
   }

   async updateToFromExams(id: string) {
      const materialObject = this.formOptionsMaterial.find(
         (material) =>
            material.value ===
            this.toFromExamsFormGroup.controls["materialId"].value
      );

      const updateToFromExamsObject: ExamsSupportLaboratoryPutObject = {
         analyteId: this.toFromExamsFormGroup.controls["analyteId"].value,
         analyteMaterialId: materialObject
            ? materialObject.analyteMaterialId
            : null,
         supportLaboratoryId: this.supportLaboratoryId,
         codeInSupportLaboratory:
            this.toFromExamsFormGroup.controls["codeInSupportLaboratory"].value,
         materialInSupportLaboratory:
            this.toFromExamsFormGroup.controls["material"].value,
         value: this.toFromExamsFormGroup.controls["value"].value,
         acceptValueReference:
            this.toFromExamsFormGroup.controls["reference"].value,
         hasRelationship:
            this.toFromExamsFormGroup.controls["hasRelationship"].value,
         reportId: this.toFromExamsFormGroup.controls["analyteReportId"].value
      };

      await this._examsSupportLaboratoryService
         .put(updateToFromExamsObject, new Guid(id))
         .then(async () => {
            await this.getExam();
            this.close();
            SwAlSetttings.Sucesso("De/Para de Exames atualizado com sucesso!");
         });
   }

   async getExam() {
      await this._examsSupportLaboratoryService
         .getPagination({ supportLaboratoryId: this.supportLaboratoryId })
         .then((res) => {
            this.resolveToFromExams(res.data, resolveToFromExams);
         });
   }

   /* #endregion */

   /* #region DePara Perguntas */

   async getExamsAdditionalInformation(id: any, paramPage?: number[]) {
      this.examsAdditionalInformationTable[id] = { paramPage };
      if (paramPage) {
         this.examsAdditionalInformationPage = paramPage[0];
         this.examsAdditionalInformationIndex = paramPage[1];
      }

      const queryObject = {
         examsSupportLaboratoryId: id,
         index: this.examsAdditionalInformationIndex,
         page: this.examsAdditionalInformationPage
      };

      await this._examsAdditionalInformationLaboratoryService
         .getPagination(queryObject)
         .then((res) => {
            this.examsAdditionalInformationTotal[id] = res.sumRecords;
            this.resolveExamsAdditionalInformations(
               res.data,
               resolveExamsAdditionalInformation,
               id
            );
         });
   }

   async openDialogExamsAdditionalInformationLaboratory(
      edit: boolean,
      rowDetail: any,
      emit?: any
   ) {
      await this.getQuestionsAnalyte(rowDetail._hide_analyteId);

      if (edit) this.setExamsAdditionalInformationLaboratory(emit);

      const myTempDialog = this._dialog.open(
         this.dialogExamsAdditionalInformationLaboratory,
         {
            width: "660px",
            data: { edit, rowDetail, emit }
         }
      );
      myTempDialog.afterClosed().subscribe(() => {
         this.examsAdditionalInformationFormGroup.reset();
      });
   }

   async getQuestionsAnalyte(analyteId: string) {
      this.analyteQuestionOptions = [];

      const queryObject = {
         analyteId
      };

      await this._analyteQuestionService
         .getPagination(queryObject)
         .then((res) => {
            if (res.data.length > 0) {
               res.data.forEach((x: any) => {
                  this.analyteQuestionOptions.push({
                     label: x.questionName,
                     value: x.questionId
                  });
               });
            } else {
               SwAlSetttings.warningMessage(
                  "O Exame selecionado não possui Pergunta cadastrada!"
               );
            }
         });
   }

   setExamsAdditionalInformationLaboratory(rowDetail: any) {
      this.examsAdditionalInformationFormGroup.controls[
         "examsSupportLaboratoryId"
      ].setValue(rowDetail.object._hide_questionId);
      this.examsAdditionalInformationFormGroup.controls[
         "codeInSupportLaboratory"
      ].setValue(rowDetail.object.Código);
      rowDetail.object["Obrigatório"].includes("Sim")
         ? this.examsAdditionalInformationFormGroup.controls[
              "requerid"
           ].setValue(true)
         : this.examsAdditionalInformationFormGroup.controls[
              "requerid"
           ].setValue(false);
   }

   async actionsExamsAdditionalInformationLaboratory(
      emit: Actions,
      rowDetail: Actions
   ) {
      switch (emit.action) {
         case "Editar":
            this.selectedAnalyteId = rowDetail.id;
            this.openDialogExamsAdditionalInformationLaboratory(
               true,
               rowDetail,
               emit
            );
            break;
         case "Excluir":
            this.deletExamsAdditionalInformation(emit.object.id, rowDetail.id);
            break;
         case "patchActive":
            this.putActiveExamsAdditional(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
      }
   }

   putActiveExamsAdditional(id: string, value: string) {
      this._examsAdditionalInformationLaboratoryService
         .patchActive(id, value)
         .then(() => {
            SwAlSetttings.Sucesso("Situação Alterada!");
         });
   }

   async createExamsAdditionalInformation(id: string) {
      const createExamsAdditionalInformationObject = {
         requerid: this.examsAdditionalInformationFormGroup.controls["requerid"]
            .value
            ? JSON.parse(
                 this.examsAdditionalInformationFormGroup.controls["requerid"]
                    .value
              )
            : false,
         codeInSupportLaboratory:
            this.examsAdditionalInformationFormGroup.controls[
               "codeInSupportLaboratory"
            ].value,
         examsSupportLaboratoryId: id,
         questionId:
            this.examsAdditionalInformationFormGroup.controls[
               "examsSupportLaboratoryId"
            ].value
      };
      await this._examsAdditionalInformationLaboratoryService
         .post(createExamsAdditionalInformationObject)
         .then(() => {
            this.getExamsAdditionalInformation(id);
            this.close();
            SwAlSetttings.Sucesso("De/Para de Pergunta criado com sucesso!");
         });
   }

   async updateExamsAdditionalInformation(id: string) {
      const updateExamsAdditionalInformationObject = {
         requerid: this.examsAdditionalInformationFormGroup.controls["requerid"]
            .value
            ? JSON.parse(
                 this.examsAdditionalInformationFormGroup.controls["requerid"]
                    .value
              )
            : false,
         codeInSupportLaboratory:
            this.examsAdditionalInformationFormGroup.controls[
               "codeInSupportLaboratory"
            ].value,
         examsSupportLaboratoryId: this.selectedAnalyteId,
         questionId:
            this.examsAdditionalInformationFormGroup.controls[
               "examsSupportLaboratoryId"
            ].value
      };
      await this._examsAdditionalInformationLaboratoryService
         .put(updateExamsAdditionalInformationObject, new Guid(id))
         .then(() => {
            this.getExamsAdditionalInformation(this.selectedAnalyteId);
            this.close();
            SwAlSetttings.Sucesso(
               "De/Para de Pergunta atualizado com sucesso!"
            );
         });
   }

   deletExamsAdditionalInformation(id: any, examId: string) {
      SwAlSetttings.ALERT_CONFIRM.fire(
         "Deseja excluir o De/Para de Pergunta?"
      ).then(async (r) => {
         if (r.isConfirmed) {
            await this._examsAdditionalInformationLaboratoryService.delete(
               new Guid(id)
            );
            SwAlSetttings.Sucesso("De/Para de Pergunta excluído com sucesso!");
            this.getExamsAdditionalInformation(examId);
         }
      });
   }

   resolveExamsAdditionalInformations(data: any, columns: any, id: string) {
      const arrayFilter: Array<object> = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         arrayFilter.push(obj);
      });
      this.registerExamsAdditionalInformation[id] = arrayFilter;
   }

   /* #endregion */

   /* #region DePara Atributos */

   getAtributeRelationship(id: any, paramPage?: number[]) {
      this.atributeRelationshipTable[id] = { paramPage };
      if (paramPage) {
         this.atributeRelationshipIndex = paramPage[0];
         this.atributeRelationshipPage = paramPage[1];
      }

      const queryObject = {
         examsSupportLaboratoryId: id,
         index: this.atributeRelationshipIndex,
         page: this.atributeRelationshipPage
      };
      this._atributeRelationshipService
         .getPagination(queryObject)
         .then((res) => {
            this.atributeRelationshipTotal[id] = res.sumRecords;
            this.resolveAtributeRelationship(
               res.data,
               resolveAtributeRelationship,
               id
            );
         });
   }

   resolveAtributeRelationship(data: any, columns: any, id: string) {
      const arrayFilter: Array<object> = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         arrayFilter.push(obj);
      });
      this.registerAtributeRelationship[id] = arrayFilter;
   }

   openDialogAtributeRelationship(edit: boolean, rowDetail: any, emit?: any) {
      this.getAtributeRelationshipOptions(rowDetail._hide_analyteReportId);
      if (edit) this.setAtributeRelationship(emit);
      const myTempDialog = this._dialog.open(this.dialogAtributeRelationship, {
         width: "660px",
         data: { edit, rowDetail, emit }
      });
      myTempDialog.afterClosed().subscribe(() => {
         this.formExamsAdditiona.reset();
      });
   }

   optionExamsAdditional: Array<Object> = [];

   async getAtributeRelationshipOptions(id: string) {
      this.optionExamsAdditional = [];
      await this._atributeReportService
         .get("?ReportId=" + id)
         .then((res: any) => {
            res.data.forEach((x: any) => {
               this.optionExamsAdditional.push({
                  label: x.atributeAnalyteName,
                  value: x.id
               });
            });
         });
   }

   actionsAtributeRelationship(emit: Actions, rowDetail: any) {
      switch (emit.action) {
         case "Editar":
            this.selectedAnalyteId = rowDetail.id;
            this.openDialogAtributeRelationship(true, rowDetail, emit);
            break;
         case "Excluir":
            this.deletAtributeRelationship(emit.object.id, rowDetail.id);
            break;
         case "patchActive":
            this.putActiveAtributeRelationship(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
      }
   }

   setAtributeRelationship(emit: any) {}

   putActiveAtributeRelationship(id: string, value: string) {
      this._atributeRelationshipService.patchActive(id, value).then(() => {
         SwAlSetttings.Sucesso("Situação Alterada!");
      });
   }

   deletAtributeRelationship(id: any, examId: string) {
      SwAlSetttings.ALERT_CONFIRM.fire(
         "Deseja excluir o De/Para de Pergunta?"
      ).then(async (r) => {
         if (r.isConfirmed) {
            await this._atributeRelationshipService.delete(new Guid(id));
            SwAlSetttings.Sucesso("De/Para de Pergunta excluído com sucesso!");
            this.getAtributeRelationship(examId);
         }
      });
   }

   async creatAtributeRelationship(rowDetail: any) {
      const creatAtributeRelationshipObject = {
         examsSupportLaboratoryId: rowDetail.id,
         atributeReportid:
            this.formExamsAdditiona.controls["examsSupportLaboratoryId"].value,
         atributeSupportLaboratory:
            this.formExamsAdditiona.controls["atributeSupportLaboratory"].value
      };
      await this._atributeRelationshipService
         .post(creatAtributeRelationshipObject)
         .then(async () => {
            setTimeout(async () => {
               SwAlSetttings.Sucesso("Informações enviadas");
               this.close();
               this.getAtributeRelationship(rowDetail.id);
            });
         });
   }

   async updateAtributeRelationship(id: string) {
      const updateAtributeRelationshipObject = {
         examsSupportLaboratoryId: this.selectedAnalyteId,
         atributeReportid:
            this.formExamsAdditiona.controls["examsSupportLaboratoryId"].value,
         atributeSupportLaboratory:
            this.formExamsAdditiona.controls["atributeSupportLaboratory"].value
      };
      await this._atributeRelationshipService
         .put(updateAtributeRelationshipObject, new Guid(id))
         .then(async () => {
            setTimeout(async () => {
               SwAlSetttings.Sucesso("Informações enviadas");
               this.close();
               this.getAtributeRelationship(this.selectedAnalyteId);
            });
         });
   }

   /* #endregion */

   /* #region Tempo de Curva */

   async openDialogSupportLaboratoryCollectionTime(
      edit: boolean,
      rowDetail: any,
      emit?: any
   ) {
      await this.getOptionCollectionTime(rowDetail["_hide_analyteMaterialId"]);
      if (edit) this.setColectionTime(emit);
      this.selectedAnalyteId = rowDetail.id;

      const myTempDialog = this._dialog.open(
         this.dialogSupportLaboratoryCollectionTime,
         {
            width: "660px",
            data: { edit, rowDetail, emit }
         }
      );
      myTempDialog.afterClosed().subscribe(() => {
         this.formCollectionTime.reset();
      });
   }

   setColectionTime(emit: any) {
      this.formCollectionTime.controls["analyteMaterialRecipientId"].setValue(
         emit._hide_analyteMaterialRecipientId
      );
      this.formCollectionTime.controls["sampleTimeDescription"].setValue(
         emit._hide_sampleTimeDescription
      );
   }

   async getOptionCollectionTime(analyteMaterialId: string) {
      this.optionCollectionTime = [];
      await this._analyteMaterialRecipient
         .getPagination({ analyteMaterialId })
         .then((response) => {
            response.data.forEach((recipient: any) => {
               this.optionCollectionTime.push({
                  label: recipient.description,
                  value: recipient.id
               });
            });
         });
   }

   async actionsCollectionTime(emit: Actions, rowDetail: Actions) {
      switch (emit.action) {
         case "Editar":
            this.openDialogSupportLaboratoryCollectionTime(
               true,
               rowDetail,
               emit.object
            );
            this.selectedAnalyteId = rowDetail.id;
            break;
         case "Excluir":
            this.deletColectionTime(emit.object.id, rowDetail.id);
            break;
         case "patchActive":
            this.putActiveColectionTime(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
      }
   }

   deletColectionTime(id: any, examId: string) {
      SwAlSetttings.ALERT_CONFIRM.fire("Deseja excluir o Tempo de curva?").then(
         async (r) => {
            if (r.isConfirmed) {
               await this._supportLaboratoryCollectionTimeService.delete(
                  new Guid(id)
               );
               SwAlSetttings.Sucesso("Tempo de curva excluído com sucesso!");
               this.getCollectionTime(examId);
            }
         }
      );
   }

   putActiveColectionTime(id: string, value: string) {
      this._supportLaboratoryCollectionTimeService
         .patchActive(id, value)
         .then(() => {
            SwAlSetttings.Sucesso("Situação Alterada!");
         });
   }

   getCollectionTime(id: string, paramPage?: number[]) {
      if (paramPage) {
         this.collectionTimeTabel[id] = { paramPage };
         this.collectionTimePage = paramPage[0];
         this.collectionTimeIndex = paramPage[1];
      }

      const queryObject = {
         examsSupportLaboratoryId: id,
         numberRegistry: this.collectionTimeIndex,
         page: this.collectionTimePage
      };

      this._supportLaboratoryCollectionTimeService
         .getPagination(queryObject)
         .then((res) => {
            this.collectionTimeTotal[id] = res.sumRecords;
            this.resolveCollectionTime(res.data, resolveCollectionTime, id);
         });
   }

   resolveCollectionTime(data: any, columns: any, id: any) {
      const arrayFilter: Array<object> = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         arrayFilter.push(obj);
      });
      this.registerColectionTime[id] = arrayFilter;
   }

   createCollectionTime(rowDetail: Actions) {
      const createCollectionTimeObject = {
         examsSupportLaboratoryId: rowDetail.id,
         analyteMaterialRecipientId:
            this.formCollectionTime.controls["analyteMaterialRecipientId"]
               .value,
         sampleTimeDescription:
            this.formCollectionTime.controls["sampleTimeDescription"].value
      };
      this._supportLaboratoryCollectionTimeService
         .post(createCollectionTimeObject)
         .then(() => {
            setTimeout(async () => {
               SwAlSetttings.Sucesso("Tempo de curva cadastrada");
               this.close();
               this.getCollectionTime(rowDetail.id);
            });
         });
   }

   updateCollectionTime(id: string) {
      const updateCollectionTimeObject = {
         examsSupportLaboratoryId: this.selectedAnalyteId,
         analyteMaterialRecipientId:
            this.formCollectionTime.controls["analyteMaterialRecipientId"]
               .value,
         sampleTimeDescription:
            this.formCollectionTime.controls["sampleTimeDescription"].value
      };
      this._supportLaboratoryCollectionTimeService
         .put(updateCollectionTimeObject, new Guid(id))
         .then(() => {
            setTimeout(async () => {
               SwAlSetttings.Sucesso("Tempon de curva editada");
               this.close();
               this.getCollectionTime(this.selectedAnalyteId);
            });
         });
   }

   async openDialogCommunicationCredentials(
      operation: CredentialOperation,
      id?: string
   ) {
      const myTempDialog = this._dialog.open(
         this.dialogCommunicationCredentials,
         {
            width: "70%",
            data: { operation, id }
         }
      );

      myTempDialog.afterClosed().subscribe(() => {
         this.supportLaboratoryCredentialsFormGroup.reset();
      });
   }

   resolveCommunicationCredential(data: any, columns: any) {
      this.communicationCredentialRegister = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.communicationCredentialRegister.push(obj);
      });
   }

   async getCommunicationCredentials(paramPage?: number[]) {
      if (paramPage) {
         this.credentialsPage = paramPage[0];
         this.credentialsIndex = paramPage[1];
      }

      const queryObject = {
         supportLaboratoryId: this.supportLaboratoryId,
         index: this.credentialsIndex,
         page: this.credentialsPage
      };

      await this._credentialsSupportLaboratoryService
         .getPagination<GetAllCredentialsSupportLaboratory>(queryObject)
         .then((res) => {
            this.credentialsTotal = res.sumRecords;
            this.resolveCommunicationCredential(
               res.data,
               resolveCommunicationCredentials
            );
         });
   }

   async getByIdCommunicationCredetials(id: Guid) {
      await this._credentialsSupportLaboratoryService
         .getById(id)
         .then(async (res) => {
            this.supportLaboratoryCredentialsFormGroup.controls[
               "apiKey"
            ].setValue(res.data[0].apiKey);
            this.supportLaboratoryCredentialsFormGroup.controls[
               "codeLogin"
            ].setValue(res.data[0].codeLogin);
            this.supportLaboratoryCredentialsFormGroup.controls[
               "password"
            ].setValue(res.data[0].password);
            this.supportLaboratoryCredentialsFormGroup.controls[
               "companyId"
            ].setValue(res.data[0].companyId);
            if (
               this.supportLaboratoryCredentialsFormGroup.controls["companyId"]
                  .value != null
            ) {
               await this.getUnities();
            }
            this.supportLaboratoryCredentialsFormGroup.controls[
               "unityId"
            ].setValue(res.data[0].unityId);
            this.supportLaboratoryCredentialsFormGroup.controls[
               "urlComplement"
            ].setValue(res.data[0].urlComplement);
            this.supportLaboratoryCredentialsFormGroup.controls[
               "urlService"
            ].setValue(res.data[0].urlService);
         });
   }

   async communicationCredentialsActions(emit: Actions) {
      switch (emit.action) {
         case "Editar":
            await this.getCompanies();
            await this.getByIdCommunicationCredetials(new Guid(emit.object.id));
            this.openDialogCommunicationCredentials(
               CredentialOperation.Update,
               emit.object.id
            );
            break;
         case "Excluir":
            await this.deleteCommunicationCredential(new Guid(emit.object.id));
            break;
      }
   }

   async createCommunicationCredential() {
      const createCredentialSupportLaboratoryObject: CreateCredentialSupportLaboratory =
         {
            supportLaboratoryId: this.supportLaboratoryId,
            companyId:
               this.supportLaboratoryCredentialsFormGroup.controls["companyId"]
                  .value,
            unityId:
               this.supportLaboratoryCredentialsFormGroup.controls["unityId"]
                  .value,
            codeLogin:
               this.supportLaboratoryCredentialsFormGroup.controls["codeLogin"]
                  .value,
            password:
               this.supportLaboratoryCredentialsFormGroup.controls["password"]
                  .value,
            apiKey:
               this.supportLaboratoryCredentialsFormGroup.controls["apiKey"]
                  .value,
            urlService:
               this.supportLaboratoryCredentialsFormGroup.controls["urlService"]
                  .value,
            urlComplement:
               this.supportLaboratoryCredentialsFormGroup.controls[
                  "urlComplement"
               ].value
         };

      await this._credentialsSupportLaboratoryService
         .post(createCredentialSupportLaboratoryObject)
         .then(async () => {
            this.close();
            await this.getCommunicationCredentials();
            SwAlSetttings.Sucesso(
               "Credencial de Comunicação criada com sucesso!"
            );
         });
   }

   async updateCommunicationCredential(id: Guid) {
      const updateCredentialSupportLaboratory: UpdateCredentialSupportLaboratory =
         {
            codeLogin:
               this.supportLaboratoryCredentialsFormGroup.controls["codeLogin"]
                  .value,
            password:
               this.supportLaboratoryCredentialsFormGroup.controls["password"]
                  .value,
            apiKey:
               this.supportLaboratoryCredentialsFormGroup.controls["apiKey"]
                  .value,
            urlService:
               this.supportLaboratoryCredentialsFormGroup.controls["urlService"]
                  .value,
            urlComplement:
               this.supportLaboratoryCredentialsFormGroup.controls[
                  "urlComplement"
               ].value,
            isActive: true
         };

      await this._credentialsSupportLaboratoryService
         .put(updateCredentialSupportLaboratory, id)
         .then(async () => {
            this.close();
            await this.getCommunicationCredentials();
            SwAlSetttings.Sucesso(
               "Credencial de Comunicação atualizada com sucesso!"
            );
         });
   }

   async deleteCommunicationCredential(id: Guid) {
      await this._credentialsSupportLaboratoryService
         .delete(id)
         .then(async () => {
            await this.getCommunicationCredentials();
            SwAlSetttings.Sucesso(
               "Credencial de Comunicação excluída com sucesso!"
            );
         });
   }

   async getCompanies() {
      this.companyOptions = [{ label: "Todos", value: null }];
      const queryObject = {
         isActive: true,
         resume: true
      };
      await this._companyService.getPagination(queryObject).then((res) => {
         res.data.forEach((company: any) => {
            const companyObject = {
               label: company.companyName,
               value: company.id
            };
            this.companyOptions.push(companyObject);
         });
      });
   }

   async getUnities() {
      this.unityOptions = [{ label: "Todos", value: null }];
      const queryObject = {
         isActive: true,
         resume: true,
         companyId:
            this.supportLaboratoryCredentialsFormGroup.controls["companyId"]
               .value
      };
      await this._unityService.getPagination(queryObject).then((res) => {
         res.data.forEach((unity: any) => {
            const unityObject = {
               label: unity.name,
               value: unity.id
            };
            this.unityOptions.push(unityObject);
         });
      });
   }

   async newConfiguration() {
      await this.getCompanies();
      this.openDialogCommunicationCredentials(this.ECredentialOperation.Create);
   }
}
