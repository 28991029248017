import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  
  @Input() class: string = '';
  @Input() placeholder: string = '';
  @Input() matIcon: string = '';
  @Input() reverse: boolean = false;
  @Input() disable: boolean = false;

  constructor() { }

  ngOnInit() {
    
  }
 
}
