<div
    *ngIf="!isLoading"
    class="table"
    [ngStyle]="{
        padding: !Padding
            ? '0px'
            : 'padding: calc(32px * var(--proportional)) calc(32px * var(--proportional)) calc(54px * var(--proportional));'
    }"
    [ngClass]="Single || noShadow ? 'Single' : ''"
>
    <table *ngIf="Itens?.length">
        <thead>
            <tr>
                <ng-template
                    ngFor
                    let-item
                    [ngForOf]="RetriveKeys()"
                    let-index="index"
                    [ngForTrackBy]="trackByFn"
                >
                    <td
                        scope="col"
                        *ngIf="
                            item != 'id' &&
                            item.substring(0, 6) != '_hide_' &&
                            item != 'tenantId' &&
                            item != 'extra'
                        "
                    >
                        <div
                            class="flex"
                            [ngClass]="{ sortActive: sortSelected === index }"
                            (click)="Sort(item.toString(), index)"
                        >
                            {{ item | translate }}
                            <svg
                                *ngIf="Order"
                                width="12"
                                height="20"
                                viewBox="0 0 12 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0.702596 12.5928C0.967712 12.3346 1.39189 12.3403 1.65003 12.6054L6.12798 17.2044L10.6059 12.6054C10.8641 12.3403 11.2882 12.3346 11.5534 12.5928C11.8185 12.8509 11.8241 13.2751 11.566 13.5402L6.12798 19.1252L0.689964 13.5402C0.431825 13.2751 0.437481 12.8509 0.702596 12.5928Z"
                                    fill="#FE7434"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.5534 7.50716C11.2883 7.7653 10.8641 7.75964 10.6059 7.49452L6.128 2.89555L1.65005 7.49452C1.39191 7.75964 0.967732 7.7653 0.702617 7.50716C0.437501 7.24902 0.431845 6.82484 0.689984 6.55972L6.128 0.974731L11.566 6.55972C11.8242 6.82484 11.8185 7.24902 11.5534 7.50716Z"
                                    fill="#FE7434"
                                />
                            </svg>
                        </div>
                    </td>
                </ng-template>
                <ng-template [ngIf]="Extras != undefined || Extras.length">
                    <td scope="col" *ngFor="let extra of Extras">&nbsp;</td>
                </ng-template>
                <td scope="col" *ngIf="!Single && Actions.length > 0"></td>
                <td scope="col" *ngIf="Dropdown" class="Dropdown"></td>
            </tr>
        </thead>
        <tbody>
            <ng-template
                ngFor
                let-item
                [ngForOf]="Itens | searchFilter : Search"
                let-i="index"
                [ngForTrackBy]="trackByFn"
            >
                <ng-container
                    *ngIf="
                        !backPagination &&
                        i <= NResultados * page - 1 &&
                        NResultados * page - 1 - NResultados < i
                    "
                >
                    <tr>
                        <ng-template
                            ngFor
                            let-key
                            [ngForOf]="RetriveKeys()"
                            let-index="index"
                        >
                            <td
                                *ngIf="
                                    key != 'id' &&
                                    key.substring(0, 6) != '_hide_' &&
                                    key != 'tenantId' &&
                                    key != 'extra' &&
                                    item[key.toString()] != undefined
                                "
                                [ngClass]="{
                                    inative:
                                        Inativate == true && !item.extra.active
                                }"
                                [scope]="key | translate"
                                [ngClass]="{
                                    inative:
                                        Inativate == true && !item.extra.active
                                }"
                            >
                                <div
                                    *ngIf="
                                        !ClickInItem.hasOwnProperty(
                                            key.toString()
                                        ) &&
                                        !item[key.toString()].includes(
                                            'checkbox'
                                        ) &&
                                        !item[key.toString()].includes(
                                            'boolean'
                                        ) &&
                                        !item[key.toString()].includes(
                                            'TypeConservation'
                                        )
                                    "
                                    [innerHTML]="
                                        item[key.toString()].includes('span')
                                            ? _sanitizer.bypassSecurityTrustHtml(
                                                  item[key.toString()]
                                              )
                                            : item[key.toString()]
                                    "
                                    [style]="
                                        key.toString() == 'Título'
                                            ? 'max-width:30vh;    overflow-wrap: break-word;white-space: pre-wrap;'
                                            : 'cursor: text;display: inline-block;position: relative;z-index: 2'
                                    "
                                ></div>
                                <div
                                    class="inline-block"
                                    *ngIf="
                                        ClickInItem.hasOwnProperty(
                                            key.toString()
                                        ) &&
                                        !item[key.toString()].includes(
                                            'button'
                                        ) &&
                                        !item[key.toString()].includes(
                                            'checkbox'
                                        ) &&
                                        !item[key.toString()].includes(
                                            'boolean'
                                        ) &&
                                        !item[key.toString()].includes(
                                            'TypeConservation'
                                        )
                                    "
                                >
                                    <div
                                        [innerHTML]="
                                            _sanitizer.bypassSecurityTrustHtml(
                                                item[key.toString()]
                                            )
                                        "
                                        class="cursorPointer"
                                        style="
                                            display: inline-block;
                                            position: relative;
                                            z-index: 1;
                                        "
                                    ></div>
                                    <div
                                        class="absolute-svg"
                                        matTooltip="{{
                                            'global.action.edit' | translate
                                        }}"
                                        [matTooltipPosition]="'above'"
                                        [matTooltipDisabled]="
                                            item[key.toString()].includes('svg')
                                        "
                                        (click)="
                                            parentFun.emit({
                                                action: ClickInItem[
                                                    key.toString()
                                                ],
                                                object: item
                                            })
                                        "
                                        [ngClass]="
                                            item[key.toString()].includes('svg')
                                                ? ''
                                                : 'teste'
                                        "
                                    ></div>
                                </div>
                                <div
                                    *ngIf="
                                        ClickInItem.hasOwnProperty(
                                            key.toString()
                                        ) &&
                                        item[key.toString()].includes('button')
                                    "
                                    class="cursorPointer"
                                    style="
                                        display: inline-block;
                                        position: relative;
                                        z-index: 2;
                                    "
                                >
                                    <button
                                        [ngClass]="
                                            item[key.toString()].includes(
                                                '|reverse'
                                            )
                                                ? 'btn-reverse'
                                                : ''
                                        "
                                        (click)="
                                            parentFun.emit({
                                                action: ClickInItem[
                                                    key.toString()
                                                ],
                                                object: item
                                            })
                                        "
                                    >
                                        {{
                                            item[key.toString()]
                                                .replace("|button", "")
                                                .replace("|reverse", "")
                                        }}
                                    </button>
                                </div>
                                <div
                                    *ngIf="
                                        item[key.toString()].includes(
                                            'checkbox'
                                        )
                                    "
                                    class="cursorPointer"
                                    style="
                                        display: inline-block;
                                        position: relative;
                                        z-index: 2;
                                    "
                                >
                                    <checkbox-prop
                                        *ngIf="
                                            item[key.toString()].split(
                                                'checkbox|'
                                            )[1] != 'true'
                                        "
                                        [dark]="isAdminUrl"
                                        [formTrue]="false"
                                        (change)="
                                            parentFun.emit({
                                                action: ClickInItem[
                                                    key.toString()
                                                ],
                                                object: item,
                                                $event
                                            })
                                        "
                                    ></checkbox-prop>
                                    <checkbox-prop
                                        *ngIf="
                                            item[key.toString()].split(
                                                'checkbox|'
                                            )[1] === 'true'
                                        "
                                        [dark]="isAdminUrl"
                                        [formTrue]="true"
                                        (change)="
                                            parentFun.emit({
                                                action: ClickInItem[
                                                    key.toString()
                                                ],
                                                object: item,
                                                $event
                                            })
                                        "
                                    ></checkbox-prop>
                                </div>
                                <div
                                    *ngIf="
                                        item[key.toString()].includes('boolean')
                                    "
                                    style="
                                        display: inline-block;
                                        position: relative;
                                        z-index: 2;
                                    "
                                >
                                    <div
                                        *ngIf="
                                            item[key.toString()].split(
                                                'boolean|'
                                            )[1] === 'true'
                                        "
                                    >
                                        <svg
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                        >
                                            <path
                                                d="M12.2268 16.8328L12.614 17.22L12.9645 16.7993L18.1024 10.6338C18.253 10.4531 18.5216 10.4287 18.7023 10.5793C18.883 10.7299 18.9074 10.9984 18.7568 11.1792L12.9698 18.1236C12.8932 18.2155 12.7814 18.271 12.6619 18.2764C12.5423 18.2818 12.426 18.2367 12.3414 18.1521L8.8692 14.6799C8.70286 14.5135 8.70286 14.2439 8.8692 14.0775C9.03553 13.9112 9.30521 13.9112 9.47155 14.0775L12.2268 16.8328ZM3.95185 13.8C3.95185 8.36102 8.36102 3.95185 13.8 3.95185C19.239 3.95185 23.6481 8.36102 23.6481 13.8C23.6481 19.239 19.239 23.6481 13.8 23.6481C8.36102 23.6481 3.95185 19.239 3.95185 13.8ZM13.8 0.5C6.45461 0.5 0.5 6.45461 0.5 13.8C0.5 21.1454 6.45461 27.1 13.8 27.1C21.1454 27.1 27.1 21.1454 27.1 13.8C27.1 6.45461 21.1454 0.5 13.8 0.5ZM19.7555 12.0114C20.3657 11.2791 20.2668 10.1908 19.5345 9.58059C18.8023 8.97036 17.714 9.0693 17.1037 9.80157L12.5266 15.2941L10.3908 13.1583C9.71677 12.4843 8.62397 12.4843 7.94996 13.1583C7.27594 13.8323 7.27594 14.9251 7.94996 15.5991L11.4222 19.0713C11.7651 19.4142 12.2364 19.597 12.7208 19.5751C13.2052 19.5531 13.658 19.3284 13.9685 18.9558L19.7555 12.0114ZM13.8 2.65185C7.64305 2.65185 2.65185 7.64305 2.65185 13.8C2.65185 19.957 7.64305 24.9481 13.8 24.9481C19.957 24.9481 24.9481 19.957 24.9481 13.8C24.9481 7.64305 19.957 2.65185 13.8 2.65185ZM1.8 13.8C1.8 7.17258 7.17258 1.8 13.8 1.8C20.4274 1.8 25.8 7.17258 25.8 13.8C25.8 20.4274 20.4274 25.8 13.8 25.8C7.17258 25.8 1.8 20.4274 1.8 13.8Z"
                                                fill="#07BF56"
                                                stroke="#07BF56"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        *ngIf="
                                            item[key.toString()].split(
                                                'boolean|'
                                            )[1] === 'false'
                                        "
                                    >
                                        <svg
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                        >
                                            <path
                                                d="M4.03586 13.8848C4.03586 8.44586 8.44502 4.0367 13.884 4.0367C19.323 4.0367 23.7321 8.44586 23.7321 13.8848C23.7321 19.3238 19.323 23.733 13.884 23.733C8.44502 23.733 4.03586 19.3238 4.03586 13.8848ZM13.884 0.584839C6.53862 0.584839 0.583984 6.53945 0.583984 13.8848C0.583984 21.2302 6.53862 27.1848 13.884 27.1848C21.2294 27.1848 27.184 21.2302 27.184 13.8848C27.184 6.53945 21.2294 0.584839 13.884 0.584839ZM13.884 2.73669C7.72705 2.73669 2.73584 7.72789 2.73584 13.8848C2.73584 20.0418 7.72705 25.033 13.884 25.033C20.041 25.033 25.0321 20.0418 25.0321 13.8848C25.0321 7.72789 20.041 2.73669 13.884 2.73669ZM1.884 13.8848C1.884 7.25742 7.25659 1.88484 13.884 1.88484C20.5114 1.88484 25.884 7.25742 25.884 13.8848C25.884 20.5123 20.5114 25.8848 13.884 25.8848C7.25659 25.8848 1.884 20.5123 1.884 13.8848Z"
                                                fill="#EB5757"
                                                stroke="#EB5757"
                                            />
                                            <mask
                                                id="path-2-inside-1_10646_350948"
                                                fill="white"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M17.5701 9.019C17.8249 9.35865 17.8249 9.82566 17.5701 10.1653L10.9583 18.9811C10.5762 19.4906 9.81197 19.4906 9.42987 18.9811C9.17513 18.6415 9.17513 18.1745 9.42987 17.8348L16.0417 9.019C16.4238 8.50953 17.188 8.50953 17.5701 9.019Z"
                                                />
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M17.5701 18.9811C17.188 19.4905 16.4238 19.4905 16.0417 18.9811L9.42987 10.1653C9.17513 9.82561 9.17513 9.35859 9.42987 9.01894C9.81197 8.50947 10.5762 8.50947 10.9583 9.01894L17.5701 17.8347C17.8249 18.1744 17.8249 18.6414 17.5701 18.9811Z"
                                                />
                                            </mask>
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M17.5701 9.019C17.8249 9.35865 17.8249 9.82566 17.5701 10.1653L10.9583 18.9811C10.5762 19.4906 9.81197 19.4906 9.42987 18.9811C9.17513 18.6415 9.17513 18.1745 9.42987 17.8348L16.0417 9.019C16.4238 8.50953 17.188 8.50953 17.5701 9.019Z"
                                                fill="#EB5757"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M17.5701 18.9811C17.188 19.4905 16.4238 19.4905 16.0417 18.9811L9.42987 10.1653C9.17513 9.82561 9.17513 9.35859 9.42987 9.01894C9.81197 8.50947 10.5762 8.50947 10.9583 9.01894L17.5701 17.8347C17.8249 18.1744 17.8249 18.6414 17.5701 18.9811Z"
                                                fill="#EB5757"
                                            />
                                            <path
                                                d="M17.5701 17.8347L13.8247 20.6438L17.5701 17.8347ZM10.9583 9.01894L7.21288 11.828L10.9583 9.01894ZM16.0417 9.019L12.2963 6.20995L16.0417 9.019ZM9.42987 17.8348L5.68447 15.0258L9.42987 17.8348ZM17.5701 10.1653L21.3155 12.9744L17.5701 10.1653ZM10.9583 18.9811L14.7037 21.7902L10.9583 18.9811ZM14.7037 21.7902L21.3155 12.9744L13.8247 7.35626L7.21288 16.1721L14.7037 21.7902ZM12.2963 6.20995L5.68447 15.0258L13.1753 20.6439L19.7871 11.828L12.2963 6.20995ZM5.68447 12.9743L12.2963 21.7901L19.7871 16.172L13.1753 7.35621L5.68447 12.9743ZM21.3155 15.0257L14.7037 6.20989L7.21288 11.828L13.8247 20.6438L21.3155 15.0257ZM13.8247 20.6438C12.831 19.3188 12.831 17.497 13.8247 16.172L21.3155 21.7901C22.8187 19.7858 22.8187 17.03 21.3155 15.0257L13.8247 20.6438ZM13.1753 11.828C11.6847 13.8154 8.70348 13.8155 7.21288 11.828L14.7037 6.20989C12.4489 3.20348 7.93927 3.2035 5.68447 6.2099L13.1753 11.828ZM12.2963 21.7901C14.5511 24.7965 19.0607 24.7965 21.3155 21.7901L13.8247 16.172C15.3153 14.1846 18.2965 14.1845 19.7871 16.172L12.2963 21.7901ZM13.1753 7.35621C14.169 8.68118 14.169 10.503 13.1753 11.828L5.68447 6.2099C4.18127 8.21417 4.18127 10.97 5.68447 12.9743L13.1753 7.35621ZM19.7871 11.828C18.2965 13.8155 15.3153 13.8155 13.8247 11.828L21.3155 6.20995C19.0607 3.20355 14.5511 3.20355 12.2963 6.20995L19.7871 11.828ZM13.1753 16.1721C14.169 17.497 14.169 19.3189 13.1753 20.6439L5.68447 15.0258C4.18127 17.03 4.18127 19.7859 5.68447 21.7902L13.1753 16.1721ZM21.3155 12.9744C22.8187 10.9701 22.8187 8.21422 21.3155 6.20995L13.8247 11.828C12.831 10.5031 12.831 8.68124 13.8247 7.35626L21.3155 12.9744ZM7.21288 16.1721C8.70348 14.1846 11.6847 14.1846 13.1753 16.1721L5.68447 21.7902C7.93927 24.7966 12.4489 24.7966 14.7037 21.7902L7.21288 16.1721Z"
                                                fill="#EB5757"
                                                mask="url(#path-2-inside-1_10646_350948)"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        item[key.toString()].includes(
                                            'TypeConservation'
                                        )
                                    "
                                    class="flex"
                                    style="
                                        display: inline-block;
                                        position: relative;
                                        z-index: 2;
                                    "
                                >
                                    <div
                                        *ngIf="
                                            item[key.toString()].includes('0')
                                        "
                                    >
                                        <svg
                                            matTooltip="Congelado"
                                            [matTooltipPosition]="'above'"
                                            class="formar-svg"
                                            width="30"
                                            height="30"
                                            viewBox="0 0 25 25"
                                            fill="none"
                                        >
                                            <path
                                                d="M15 2L12.5 4.5L10 2"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M21.7383 6.73959H18.2028V3.20406"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M23.5 15L21 12.5L23.5 10"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M18.5563 21.9424L18.5563 18.4069L22.0919 18.4069"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M10 23.5L12.5 21L15 23.5"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M2.99999 18.4068L6.53552 18.4068L6.53552 21.9424"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M2 10L4.5 12.5L2 15"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M6.88908 3.20406L6.88908 6.73959L3.35355 6.73959"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M12.5 1V24.5"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M20.6776 4.26471L4.06063 20.8817"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M24.5 12.5L1 12.5"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M21.0312 20.8817L4.41421 4.26472"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        *ngIf="
                                            item[key.toString()].includes('1')
                                        "
                                    >
                                        <svg
                                            matTooltip="Refrigerado"
                                            [matTooltipPosition]="'above'"
                                            class="formar-svg"
                                            width="32"
                                            height="32"
                                            viewBox="0 0 17 16"
                                            fill="none"
                                        >
                                            <path
                                                d="M2 13.5088L5.29993 11.523C6.37104 10.8785 7.6819 10.7718 8.84317 11.2345L11.8284 12.4242C12.4235 12.6614 12.814 13.2373 12.814 13.8779V13.9677C12.814 14.5074 12.5025 15.0032 12.019 15.2428V15.2428C11.6888 15.4064 11.3018 15.4339 10.9523 15.3174V15.3174C10.3857 15.1286 10 14.5972 10 14V14"
                                                stroke="#9B8BFF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M3 8.80702L5.08477 7.66322C6.18802 7.05793 7.5118 7.0071 8.65822 7.526L13.3024 9.6281C13.6359 9.77905 14.0067 9.82733 14.3677 9.76681V9.76681C15.2562 9.61787 15.907 8.84888 15.907 7.94806V7.56188C15.907 7.03453 15.6097 6.55224 15.1386 6.3153V6.3153C14.7441 6.11694 14.2791 6.11694 13.8847 6.3153L13.7432 6.38645C13.3588 6.57977 13.1163 6.97326 13.1163 7.40351V7.40351"
                                                stroke="#9B8BFF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M1 5.21053L4.59606 3.17592C5.8266 2.47969 7.33328 2.48479 8.55908 3.18934L10.918 4.54521C11.2718 4.74851 11.6851 4.82278 12.0874 4.75532V4.75532C12.9363 4.61302 13.5581 3.87826 13.5581 3.01754V2.56188C13.5581 2.03453 13.2608 1.55224 12.7897 1.3153V1.3153C12.3953 1.11694 11.9303 1.11694 11.5359 1.3153L11.3944 1.38645C11.01 1.57977 10.7674 1.97326 10.7674 2.40351V2.40351"
                                                stroke="#9B8BFF"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        *ngIf="
                                            item[key.toString()].includes('2')
                                        "
                                    >
                                        <svg
                                            matTooltip="Temperatura Ambiente"
                                            [matTooltipPosition]="'above'"
                                            class="formar-svg"
                                            width="35"
                                            height="35"
                                            viewBox="0 0 22 18"
                                            fill="none"
                                        >
                                            <path
                                                d="M6.20001 16.7368H16.8573C17.7286 16.7368 18.576 16.4524 19.2708 15.9267L19.4542 15.788C19.6836 15.6144 19.8871 15.4091 20.0588 15.1783L20.2817 14.8784C20.732 14.2728 20.8591 13.4858 20.6223 12.7692V12.7692C20.3612 11.979 19.6964 11.3896 18.8807 11.225L18.2 11.0877"
                                                stroke="black"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M6.2 16.7368L5.37493 16.3207C3.91857 15.5861 3 14.094 3 12.4629V11.988C3 11.1528 3.3299 10.3514 3.91788 9.75827V9.75827C4.81582 8.85245 6.1695 8.57365 7.3523 9.05092L7.4 9.07017"
                                                stroke="black"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M14.2 8.66666L15.1695 8.42217C15.5833 8.31779 16.0167 8.31779 16.4306 8.42217L16.6075 8.46679C17.1173 8.59537 17.5607 8.90987 17.8507 9.34856V9.34856C18.0785 9.69336 18.2 10.0975 18.2 10.5108V11.0877"
                                                stroke="black"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M14.2 8.66667V8.66667C13.9603 7.45775 12.9841 6.53155 11.7642 6.35575L11.5059 6.31853C11.1707 6.27022 10.83 6.27421 10.4959 6.33037L10.438 6.34011C9.28354 6.53421 8.29218 7.27017 7.77229 8.31906L7.39999 9.07018"
                                                stroke="black"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M7.3 5.4386C7.3 6.50369 6.44523 7.35965 5.4 7.35965C4.35477 7.35965 3.5 6.50369 3.5 5.4386C3.5 4.37351 4.35477 3.51755 5.4 3.51755C6.44523 3.51755 7.3 4.37351 7.3 5.4386Z"
                                                stroke="#FFC000"
                                            />
                                            <path
                                                d="M2.20001 5.4386L1.00001 5.4386"
                                                stroke="#FFC000"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M9.79999 5.4386L8.59999 5.4386"
                                                stroke="#FFC000"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M3.04852 7.85965L2.19999 8.71562"
                                                stroke="#FFC000"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M5.39999 1V2.21053"
                                                stroke="#FFC000"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M2.20001 2.21053L3.04854 3.0665"
                                                stroke="#FFC000"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M8.64853 2.21053L7.8 3.0665"
                                                stroke="#FFC000"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </td>
                        </ng-template>
                        <ng-template
                            [ngIf]="Extras != undefined || Extras.length"
                        >
                            <td
                                *ngFor="let extra of Extras; let ind = index"
                                class="actions p-relative"
                            >
                                <ng-template [ngIf]="!extra.action">
                                    <div
                                        [innerHTML]="
                                            extra.type === 'svg' ||
                                            extra.type === 'check-prop'
                                                ? _sanitizer.bypassSecurityTrustHtml(
                                                      extra.content
                                                  )
                                                : extra.content
                                        "
                                    ></div>
                                </ng-template>
                                <div
                                    *ngIf="extra.action"
                                    style="cursor: pointer; display: block"
                                    [innerHTML]="
                                        extra.type === 'svg' ||
                                        extra.type === 'check-prop'
                                            ? _sanitizer.bypassSecurityTrustHtml(
                                                  extra.content
                                              )
                                            : extra.content
                                    "
                                ></div>
                                <a
                                    *ngIf="extra.action"
                                    style="cursor: pointer"
                                    class="abs"
                                    (click)="
                                        parentFun.emit({
                                            action: extra.action,
                                            object: item
                                        })
                                    "
                                ></a>
                            </td>
                        </ng-template>
                        <td
                            class="cursor"
                            scope="Ações"
                            #toggleButton
                            [ngClass]="i == actClick ? 'act' : ''"
                            (click)="toggleClick(i); $event.stopPropagation()"
                            *ngIf="!Single && Actions.length > 0"
                            style="
                                max-width: 100%;
                                width: auto;
                                word-break: keep-all;
                            "
                        >
                            <svg
                                width="6"
                                height="20"
                                viewBox="0 0 6 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="svg"
                            >
                                <path
                                    class="svg"
                                    d="M0.500001 2.5C0.500001 2.00555 0.656399 1.5222 0.949416 1.11108C1.24243 0.699953 1.65891 0.379521 2.14618 0.190302C2.63345 0.0010832 3.16963 -0.0484251 3.68691 0.0480379C4.20419 0.144501 4.67935 0.382603 5.05229 0.732234C5.42523 1.08187 5.6792 1.52732 5.7821 2.01228C5.88499 2.49723 5.83218 2.99989 5.63035 3.45671C5.42851 3.91352 5.08672 4.30397 4.64819 4.57867C4.20966 4.85338 3.69408 5 3.16667 5C2.45967 4.99924 1.78186 4.73561 1.28194 4.26693C0.782021 3.79825 0.50081 3.16281 0.500001 2.5ZM3.16667 7.5C2.63925 7.5 2.12368 7.64662 1.68515 7.92133C1.24662 8.19603 0.904823 8.58648 0.702989 9.04329C0.501155 9.50011 0.448347 10.0028 0.55124 10.4877C0.654134 10.9727 0.90811 11.4181 1.28105 11.7678C1.65399 12.1174 2.12914 12.3555 2.64643 12.452C3.16371 12.5484 3.69989 12.4989 4.18716 12.3097C4.67443 12.1205 5.0909 11.8 5.38392 11.3889C5.67694 10.9778 5.83333 10.4945 5.83333 10C5.83253 9.33719 5.55131 8.70175 5.05139 8.23307C4.55147 7.76439 3.87366 7.50076 3.16667 7.5ZM3.16667 15C2.63925 15 2.12368 15.1466 1.68515 15.4213C1.24662 15.696 0.904823 16.0865 0.702989 16.5433C0.501155 17.0001 0.448347 17.5028 0.55124 17.9877C0.654134 18.4727 0.90811 18.9181 1.28105 19.2678C1.65399 19.6174 2.12914 19.8555 2.64643 19.952C3.16371 20.0484 3.69989 19.9989 4.18716 19.8097C4.67443 19.6205 5.0909 19.3 5.38392 18.8889C5.67694 18.4778 5.83333 17.9945 5.83333 17.5C5.83253 16.8372 5.55131 16.2017 5.05139 15.7331C4.55147 15.2644 3.87366 15.0008 3.16667 15Z"
                                />
                            </svg>
                            <ul
                                [ngClass]="subMenu2 ? 'subMenu2' : 'subMenu'"
                                *ngIf="i == actClick"
                                outsideClick
                                #menu
                                (outsideClick)="Test()"
                            >
                                <ng-container
                                    *ngFor="
                                        let action of Actions;
                                        let i = index
                                    "
                                >
                                    <a
                                        onclick="return false"
                                        href=""
                                        (click)="Emitter(action, item)"
                                        *ngIf="Determine(action, item)"
                                    >
                                        <div
                                            class="svg"
                                            [innerHTML]="
                                                _sanitizer.bypassSecurityTrustHtml(
                                                    SVGActions[
                                                        action.toString()
                                                    ].svg.toString()
                                                )
                                            "
                                        ></div>
                                        {{
                                            SVGActions[action.toString()].label
                                                | translate
                                        }}
                                    </a>
                                </ng-container>
                            </ul>
                        </td>
                        <td
                            *ngIf="Dropdown"
                            scope="Mais informações"
                            [class]="
                                ArraySD.includes(i)
                                    ? 'actExpand act in ExpandButton'
                                    : 'ExpandButton'
                            "
                            (click)="Expand($event, i, item)"
                        >
                            <div
                                class="flex flex-end"
                                style="padding-right: 20px"
                            >
                                <svg
                                    width="20"
                                    height="11"
                                    viewBox="0 0 20 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M0.841981 0.310843C1.28949 -0.111554 2.00551 -0.1023 2.44125 0.331514L10 7.85692L17.5588 0.331514C17.9945 -0.1023 18.7105 -0.111555 19.158 0.310843C19.6055 0.733241 19.6151 1.42734 19.1793 1.86115L10 11L0.820658 1.86115C0.384921 1.42734 0.394468 0.733242 0.841981 0.310843Z"
                                    />
                                </svg>
                            </div>
                        </td>
                    </tr>
                    <tr
                        *ngIf="Dropdown && ArraySD.includes(i)"
                        [ngClass]="ArraySD.includes(i) ? 'act in' : 'out'"
                        class="expand"
                    >
                        <td colspan="100%">
                            <strong>
                                <ng-container
                                    *ngIf="headerTemplateRef"
                                    [ngTemplateOutlet]="headerTemplateRef"
                                    [ngTemplateOutletContext]="{
                                        $implicit: item
                                    }"
                                >
                                </ng-container>
                            </strong>
                        </td>
                    </tr>
                </ng-container>
                <ng-container
                    *ngIf="
                        backPagination &&
                        i <= indexNumber * page - 1 &&
                        indexNumber * page - 1 - indexNumber < i
                    "
                >
                    <tr>
                        <ng-template
                            ngFor
                            let-key
                            [ngForOf]="RetriveKeys()"
                            let-index="index"
                        >
                            <td
                                *ngIf="
                                    key != 'id' &&
                                    key.substring(0, 6) != '_hide_' &&
                                    key != 'tenantId' &&
                                    key != 'extra'
                                "
                                [scope]="key | translate"
                                [ngClass]="{
                                    inative:
                                        Inativate == true && !item.extra.active
                                }"
                            >
                                <div>
                                    <div
                                        *ngIf="
                                            !ClickInItem.hasOwnProperty(
                                                key.toString()
                                            ) &&
                                            !item[key.toString()].includes(
                                                'checkbox'
                                            ) &&
                                            !item[key.toString()].includes(
                                                'boolean'
                                            ) &&
                                            !item[key.toString()].includes(
                                                'TypeConservation'
                                            ) &&
                                            !item[key.toString()].includes(
                                                'validPhoneToTable'
                                            )
                                        "
                                        [innerHTML]="
                                            item[key.toString()].includes(
                                                'span'
                                            )
                                                ? _sanitizer.bypassSecurityTrustHtml(
                                                      item[key.toString()]
                                                  )
                                                : item[key.toString()]
                                        "
                                        [style]="
                                            key.toString() == 'Título'
                                                ? 'max-width:30vh;    overflow-wrap: break-word;white-space: pre-wrap;'
                                                : 'cursor: text;display: inline-block;position: relative;z-index: 2'
                                        "
                                    ></div>
                                </div>
                                <div
                                    class="inline-block"
                                    *ngIf="
                                        ClickInItem.hasOwnProperty(
                                            key.toString()
                                        ) &&
                                        !item[key.toString()].includes(
                                            'button'
                                        ) &&
                                        !item[key.toString()].includes(
                                            'checkbox'
                                        ) &&
                                        !item[key.toString()].includes(
                                            'boolean'
                                        ) &&
                                        !item[key.toString()].includes(
                                            'TypeConservation'
                                        )
                                    "
                                >
                                    <div
                                        [innerHTML]="
                                            _sanitizer.bypassSecurityTrustHtml(
                                                item[key.toString()]
                                            )
                                        "
                                        class="cursorPointer"
                                        style="
                                            display: inline-block;
                                            position: relative;
                                            z-index: 1;
                                        "
                                    ></div>
                                    <div
                                        mat-raised-button
                                        matTooltip="{{
                                            Itens[i]._hide_tooltip
                                        }}"
                                        matTooltipClass="tooltipTable"
                                        [matTooltipDisabled]="
                                            tooltipColumn == '' ||
                                            key.toString() != tooltipColumn
                                        "
                                        class="absolute-svg"
                                        (click)="
                                            parentFun.emit({
                                                action: ClickInItem[
                                                    key.toString()
                                                ],
                                                object: item
                                            })
                                        "
                                    ></div>
                                </div>
                                <div
                                    *ngIf="
                                        ClickInItem.hasOwnProperty(
                                            key.toString()
                                        ) &&
                                        item[key.toString()].includes('button')
                                    "
                                    class="cursorPointer"
                                    style="
                                        display: inline-block;
                                        position: relative;
                                        z-index: 2;
                                    "
                                >
                                    <button
                                        [ngClass]="
                                            item[key.toString()].includes(
                                                '|reverse'
                                            )
                                                ? 'btn-reverse'
                                                : ''
                                        "
                                        (click)="
                                            parentFun.emit({
                                                action: ClickInItem[
                                                    key.toString()
                                                ],
                                                object: item
                                            })
                                        "
                                    >
                                        {{
                                            item[key.toString()]
                                                .replace("|button", "")
                                                .replace("|reverse", "")
                                        }}
                                    </button>
                                </div>
                                <div
                                    *ngIf="
                                        item[key.toString()].includes(
                                            'checkbox'
                                        )
                                    "
                                    class="cursorPointer"
                                    style="
                                        display: inline-block;
                                        position: relative;
                                        z-index: 2;
                                    "
                                >
                                    <checkbox-prop
                                        *ngIf="
                                            item[key.toString()].split(
                                                'checkbox|'
                                            )[1] != 'true'
                                        "
                                        [dark]="isAdminUrl"
                                        [formTrue]="false"
                                        (change)="
                                            parentFun.emit({
                                                action: ClickInItem[
                                                    key.toString()
                                                ],
                                                object: item,
                                                $event
                                            })
                                        "
                                    ></checkbox-prop>
                                    <checkbox-prop
                                        *ngIf="
                                            item[key.toString()].split(
                                                'checkbox|'
                                            )[1] === 'true'
                                        "
                                        [dark]="isAdminUrl"
                                        [formTrue]="true"
                                        (change)="
                                            parentFun.emit({
                                                action: ClickInItem[
                                                    key.toString()
                                                ],
                                                object: item,
                                                $event
                                            })
                                        "
                                    ></checkbox-prop>
                                </div>
                                <div
                                    *ngIf="
                                        item[key.toString()].includes('boolean')
                                    "
                                    style="
                                        display: inline-block;
                                        position: relative;
                                        z-index: 2;
                                    "
                                >
                                    <div
                                        *ngIf="
                                            item[key.toString()].split(
                                                'boolean|'
                                            )[1] === 'true'
                                        "
                                    >
                                        <svg
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                        >
                                            <path
                                                d="M12.2268 16.8328L12.614 17.22L12.9645 16.7993L18.1024 10.6338C18.253 10.4531 18.5216 10.4287 18.7023 10.5793C18.883 10.7299 18.9074 10.9984 18.7568 11.1792L12.9698 18.1236C12.8932 18.2155 12.7814 18.271 12.6619 18.2764C12.5423 18.2818 12.426 18.2367 12.3414 18.1521L8.8692 14.6799C8.70286 14.5135 8.70286 14.2439 8.8692 14.0775C9.03553 13.9112 9.30521 13.9112 9.47155 14.0775L12.2268 16.8328ZM3.95185 13.8C3.95185 8.36102 8.36102 3.95185 13.8 3.95185C19.239 3.95185 23.6481 8.36102 23.6481 13.8C23.6481 19.239 19.239 23.6481 13.8 23.6481C8.36102 23.6481 3.95185 19.239 3.95185 13.8ZM13.8 0.5C6.45461 0.5 0.5 6.45461 0.5 13.8C0.5 21.1454 6.45461 27.1 13.8 27.1C21.1454 27.1 27.1 21.1454 27.1 13.8C27.1 6.45461 21.1454 0.5 13.8 0.5ZM19.7555 12.0114C20.3657 11.2791 20.2668 10.1908 19.5345 9.58059C18.8023 8.97036 17.714 9.0693 17.1037 9.80157L12.5266 15.2941L10.3908 13.1583C9.71677 12.4843 8.62397 12.4843 7.94996 13.1583C7.27594 13.8323 7.27594 14.9251 7.94996 15.5991L11.4222 19.0713C11.7651 19.4142 12.2364 19.597 12.7208 19.5751C13.2052 19.5531 13.658 19.3284 13.9685 18.9558L19.7555 12.0114ZM13.8 2.65185C7.64305 2.65185 2.65185 7.64305 2.65185 13.8C2.65185 19.957 7.64305 24.9481 13.8 24.9481C19.957 24.9481 24.9481 19.957 24.9481 13.8C24.9481 7.64305 19.957 2.65185 13.8 2.65185ZM1.8 13.8C1.8 7.17258 7.17258 1.8 13.8 1.8C20.4274 1.8 25.8 7.17258 25.8 13.8C25.8 20.4274 20.4274 25.8 13.8 25.8C7.17258 25.8 1.8 20.4274 1.8 13.8Z"
                                                fill="#07BF56"
                                                stroke="#07BF56"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        *ngIf="
                                            item[key.toString()].split(
                                                'boolean|'
                                            )[1] === 'false'
                                        "
                                    >
                                        <svg
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                        >
                                            <path
                                                d="M4.03586 13.8848C4.03586 8.44586 8.44502 4.0367 13.884 4.0367C19.323 4.0367 23.7321 8.44586 23.7321 13.8848C23.7321 19.3238 19.323 23.733 13.884 23.733C8.44502 23.733 4.03586 19.3238 4.03586 13.8848ZM13.884 0.584839C6.53862 0.584839 0.583984 6.53945 0.583984 13.8848C0.583984 21.2302 6.53862 27.1848 13.884 27.1848C21.2294 27.1848 27.184 21.2302 27.184 13.8848C27.184 6.53945 21.2294 0.584839 13.884 0.584839ZM13.884 2.73669C7.72705 2.73669 2.73584 7.72789 2.73584 13.8848C2.73584 20.0418 7.72705 25.033 13.884 25.033C20.041 25.033 25.0321 20.0418 25.0321 13.8848C25.0321 7.72789 20.041 2.73669 13.884 2.73669ZM1.884 13.8848C1.884 7.25742 7.25659 1.88484 13.884 1.88484C20.5114 1.88484 25.884 7.25742 25.884 13.8848C25.884 20.5123 20.5114 25.8848 13.884 25.8848C7.25659 25.8848 1.884 20.5123 1.884 13.8848Z"
                                                fill="#EB5757"
                                                stroke="#EB5757"
                                            />
                                            <mask
                                                id="path-2-inside-1_10646_350948"
                                                fill="white"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M17.5701 9.019C17.8249 9.35865 17.8249 9.82566 17.5701 10.1653L10.9583 18.9811C10.5762 19.4906 9.81197 19.4906 9.42987 18.9811C9.17513 18.6415 9.17513 18.1745 9.42987 17.8348L16.0417 9.019C16.4238 8.50953 17.188 8.50953 17.5701 9.019Z"
                                                />
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M17.5701 18.9811C17.188 19.4905 16.4238 19.4905 16.0417 18.9811L9.42987 10.1653C9.17513 9.82561 9.17513 9.35859 9.42987 9.01894C9.81197 8.50947 10.5762 8.50947 10.9583 9.01894L17.5701 17.8347C17.8249 18.1744 17.8249 18.6414 17.5701 18.9811Z"
                                                />
                                            </mask>
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M17.5701 9.019C17.8249 9.35865 17.8249 9.82566 17.5701 10.1653L10.9583 18.9811C10.5762 19.4906 9.81197 19.4906 9.42987 18.9811C9.17513 18.6415 9.17513 18.1745 9.42987 17.8348L16.0417 9.019C16.4238 8.50953 17.188 8.50953 17.5701 9.019Z"
                                                fill="#EB5757"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M17.5701 18.9811C17.188 19.4905 16.4238 19.4905 16.0417 18.9811L9.42987 10.1653C9.17513 9.82561 9.17513 9.35859 9.42987 9.01894C9.81197 8.50947 10.5762 8.50947 10.9583 9.01894L17.5701 17.8347C17.8249 18.1744 17.8249 18.6414 17.5701 18.9811Z"
                                                fill="#EB5757"
                                            />
                                            <path
                                                d="M17.5701 17.8347L13.8247 20.6438L17.5701 17.8347ZM10.9583 9.01894L7.21288 11.828L10.9583 9.01894ZM16.0417 9.019L12.2963 6.20995L16.0417 9.019ZM9.42987 17.8348L5.68447 15.0258L9.42987 17.8348ZM17.5701 10.1653L21.3155 12.9744L17.5701 10.1653ZM10.9583 18.9811L14.7037 21.7902L10.9583 18.9811ZM14.7037 21.7902L21.3155 12.9744L13.8247 7.35626L7.21288 16.1721L14.7037 21.7902ZM12.2963 6.20995L5.68447 15.0258L13.1753 20.6439L19.7871 11.828L12.2963 6.20995ZM5.68447 12.9743L12.2963 21.7901L19.7871 16.172L13.1753 7.35621L5.68447 12.9743ZM21.3155 15.0257L14.7037 6.20989L7.21288 11.828L13.8247 20.6438L21.3155 15.0257ZM13.8247 20.6438C12.831 19.3188 12.831 17.497 13.8247 16.172L21.3155 21.7901C22.8187 19.7858 22.8187 17.03 21.3155 15.0257L13.8247 20.6438ZM13.1753 11.828C11.6847 13.8154 8.70348 13.8155 7.21288 11.828L14.7037 6.20989C12.4489 3.20348 7.93927 3.2035 5.68447 6.2099L13.1753 11.828ZM12.2963 21.7901C14.5511 24.7965 19.0607 24.7965 21.3155 21.7901L13.8247 16.172C15.3153 14.1846 18.2965 14.1845 19.7871 16.172L12.2963 21.7901ZM13.1753 7.35621C14.169 8.68118 14.169 10.503 13.1753 11.828L5.68447 6.2099C4.18127 8.21417 4.18127 10.97 5.68447 12.9743L13.1753 7.35621ZM19.7871 11.828C18.2965 13.8155 15.3153 13.8155 13.8247 11.828L21.3155 6.20995C19.0607 3.20355 14.5511 3.20355 12.2963 6.20995L19.7871 11.828ZM13.1753 16.1721C14.169 17.497 14.169 19.3189 13.1753 20.6439L5.68447 15.0258C4.18127 17.03 4.18127 19.7859 5.68447 21.7902L13.1753 16.1721ZM21.3155 12.9744C22.8187 10.9701 22.8187 8.21422 21.3155 6.20995L13.8247 11.828C12.831 10.5031 12.831 8.68124 13.8247 7.35626L21.3155 12.9744ZM7.21288 16.1721C8.70348 14.1846 11.6847 14.1846 13.1753 16.1721L5.68447 21.7902C7.93927 24.7966 12.4489 24.7966 14.7037 21.7902L7.21288 16.1721Z"
                                                fill="#EB5757"
                                                mask="url(#path-2-inside-1_10646_350948)"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        item[key.toString()].includes(
                                            'validPhoneToTable'
                                        )
                                    "
                                    class="cursorPointer"
                                    style="
                                        display: inline-block;
                                        position: relative;
                                        z-index: 2;
                                    "
                                >
                                    <div>
                                        {{
                                            item[key.toString()].replace(
                                                "|validPhoneToTable",
                                                ""
                                            ) | validPhoneToTable
                                        }}
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        item[key.toString()].includes(
                                            'TypeConservation'
                                        )
                                    "
                                    class="flex"
                                    style="
                                        display: inline-block;
                                        position: relative;
                                        z-index: 2;
                                    "
                                >
                                    <div
                                        *ngIf="
                                            item[key.toString()].includes('0')
                                        "
                                    >
                                        <svg
                                            matTooltip="Congelado"
                                            [matTooltipPosition]="'above'"
                                            class="formar-svg"
                                            width="30"
                                            height="30"
                                            viewBox="0 0 25 25"
                                            fill="none"
                                        >
                                            <path
                                                d="M15 2L12.5 4.5L10 2"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M21.7383 6.73959H18.2028V3.20406"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M23.5 15L21 12.5L23.5 10"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M18.5563 21.9424L18.5563 18.4069L22.0919 18.4069"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M10 23.5L12.5 21L15 23.5"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M2.99999 18.4068L6.53552 18.4068L6.53552 21.9424"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M2 10L4.5 12.5L2 15"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M6.88908 3.20406L6.88908 6.73959L3.35355 6.73959"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M12.5 1V24.5"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M20.6776 4.26471L4.06063 20.8817"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M24.5 12.5L1 12.5"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M21.0312 20.8817L4.41421 4.26472"
                                                stroke="#2704FF"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        *ngIf="
                                            item[key.toString()].includes('1')
                                        "
                                    >
                                        <svg
                                            matTooltip="Refrigerado"
                                            [matTooltipPosition]="'above'"
                                            class="formar-svg"
                                            width="32"
                                            height="32"
                                            viewBox="0 0 17 16"
                                            fill="none"
                                        >
                                            <path
                                                d="M2 13.5088L5.29993 11.523C6.37104 10.8785 7.6819 10.7718 8.84317 11.2345L11.8284 12.4242C12.4235 12.6614 12.814 13.2373 12.814 13.8779V13.9677C12.814 14.5074 12.5025 15.0032 12.019 15.2428V15.2428C11.6888 15.4064 11.3018 15.4339 10.9523 15.3174V15.3174C10.3857 15.1286 10 14.5972 10 14V14"
                                                stroke="#9B8BFF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M3 8.80702L5.08477 7.66322C6.18802 7.05793 7.5118 7.0071 8.65822 7.526L13.3024 9.6281C13.6359 9.77905 14.0067 9.82733 14.3677 9.76681V9.76681C15.2562 9.61787 15.907 8.84888 15.907 7.94806V7.56188C15.907 7.03453 15.6097 6.55224 15.1386 6.3153V6.3153C14.7441 6.11694 14.2791 6.11694 13.8847 6.3153L13.7432 6.38645C13.3588 6.57977 13.1163 6.97326 13.1163 7.40351V7.40351"
                                                stroke="#9B8BFF"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M1 5.21053L4.59606 3.17592C5.8266 2.47969 7.33328 2.48479 8.55908 3.18934L10.918 4.54521C11.2718 4.74851 11.6851 4.82278 12.0874 4.75532V4.75532C12.9363 4.61302 13.5581 3.87826 13.5581 3.01754V2.56188C13.5581 2.03453 13.2608 1.55224 12.7897 1.3153V1.3153C12.3953 1.11694 11.9303 1.11694 11.5359 1.3153L11.3944 1.38645C11.01 1.57977 10.7674 1.97326 10.7674 2.40351V2.40351"
                                                stroke="#9B8BFF"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        *ngIf="
                                            item[key.toString()].includes('2')
                                        "
                                    >
                                        <svg
                                            matTooltip="Temperatura Ambiente"
                                            [matTooltipPosition]="'above'"
                                            class="formar-svg"
                                            width="35"
                                            height="35"
                                            viewBox="0 0 22 18"
                                            fill="none"
                                        >
                                            <path
                                                d="M6.20001 16.7368H16.8573C17.7286 16.7368 18.576 16.4524 19.2708 15.9267L19.4542 15.788C19.6836 15.6144 19.8871 15.4091 20.0588 15.1783L20.2817 14.8784C20.732 14.2728 20.8591 13.4858 20.6223 12.7692V12.7692C20.3612 11.979 19.6964 11.3896 18.8807 11.225L18.2 11.0877"
                                                stroke="black"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M6.2 16.7368L5.37493 16.3207C3.91857 15.5861 3 14.094 3 12.4629V11.988C3 11.1528 3.3299 10.3514 3.91788 9.75827V9.75827C4.81582 8.85245 6.1695 8.57365 7.3523 9.05092L7.4 9.07017"
                                                stroke="black"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M14.2 8.66666L15.1695 8.42217C15.5833 8.31779 16.0167 8.31779 16.4306 8.42217L16.6075 8.46679C17.1173 8.59537 17.5607 8.90987 17.8507 9.34856V9.34856C18.0785 9.69336 18.2 10.0975 18.2 10.5108V11.0877"
                                                stroke="black"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M14.2 8.66667V8.66667C13.9603 7.45775 12.9841 6.53155 11.7642 6.35575L11.5059 6.31853C11.1707 6.27022 10.83 6.27421 10.4959 6.33037L10.438 6.34011C9.28354 6.53421 8.29218 7.27017 7.77229 8.31906L7.39999 9.07018"
                                                stroke="black"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M7.3 5.4386C7.3 6.50369 6.44523 7.35965 5.4 7.35965C4.35477 7.35965 3.5 6.50369 3.5 5.4386C3.5 4.37351 4.35477 3.51755 5.4 3.51755C6.44523 3.51755 7.3 4.37351 7.3 5.4386Z"
                                                stroke="#FFC000"
                                            />
                                            <path
                                                d="M2.20001 5.4386L1.00001 5.4386"
                                                stroke="#FFC000"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M9.79999 5.4386L8.59999 5.4386"
                                                stroke="#FFC000"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M3.04852 7.85965L2.19999 8.71562"
                                                stroke="#FFC000"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M5.39999 1V2.21053"
                                                stroke="#FFC000"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M2.20001 2.21053L3.04854 3.0665"
                                                stroke="#FFC000"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M8.64853 2.21053L7.8 3.0665"
                                                stroke="#FFC000"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </td>
                        </ng-template>
                        <ng-template
                            [ngIf]="Extras != undefined || Extras.length"
                        >
                            <td
                                *ngFor="let extra of Extras; let ind = index"
                                class="actions p-relative"
                            >
                                <ng-template [ngIf]="!extra.action">
                                    <div
                                        [innerHTML]="
                                            extra.type === 'svg' ||
                                            extra.type === 'check-prop'
                                                ? _sanitizer.bypassSecurityTrustHtml(
                                                      extra.content
                                                  )
                                                : extra.content
                                        "
                                    ></div>
                                </ng-template>
                                <div
                                    *ngIf="extra.action"
                                    style="cursor: pointer; display: block"
                                    [innerHTML]="
                                        extra.type === 'svg' ||
                                        extra.type === 'check-prop'
                                            ? _sanitizer.bypassSecurityTrustHtml(
                                                  extra.content
                                              )
                                            : extra.content
                                    "
                                ></div>
                                <a
                                    *ngIf="extra.action"
                                    style="cursor: pointer"
                                    class="abs"
                                    (click)="
                                        parentFun.emit({
                                            action: extra.action,
                                            object: item
                                        })
                                    "
                                ></a>
                            </td>
                        </ng-template>
                        <td
                            class="cursor"
                            scope="Ações"
                            #toggleButton
                            [ngClass]="i == actClick ? 'act' : ''"
                            (click)="toggleClick(i); $event.stopPropagation()"
                            *ngIf="!Single && Actions.length > 0"
                            style="
                                max-width: 100%;
                                width: auto;
                                word-break: keep-all;
                            "
                        >
                            <svg
                                width="6"
                                height="20"
                                viewBox="0 0 6 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="svg"
                            >
                                <path
                                    class="svg"
                                    d="M0.500001 2.5C0.500001 2.00555 0.656399 1.5222 0.949416 1.11108C1.24243 0.699953 1.65891 0.379521 2.14618 0.190302C2.63345 0.0010832 3.16963 -0.0484251 3.68691 0.0480379C4.20419 0.144501 4.67935 0.382603 5.05229 0.732234C5.42523 1.08187 5.6792 1.52732 5.7821 2.01228C5.88499 2.49723 5.83218 2.99989 5.63035 3.45671C5.42851 3.91352 5.08672 4.30397 4.64819 4.57867C4.20966 4.85338 3.69408 5 3.16667 5C2.45967 4.99924 1.78186 4.73561 1.28194 4.26693C0.782021 3.79825 0.50081 3.16281 0.500001 2.5ZM3.16667 7.5C2.63925 7.5 2.12368 7.64662 1.68515 7.92133C1.24662 8.19603 0.904823 8.58648 0.702989 9.04329C0.501155 9.50011 0.448347 10.0028 0.55124 10.4877C0.654134 10.9727 0.90811 11.4181 1.28105 11.7678C1.65399 12.1174 2.12914 12.3555 2.64643 12.452C3.16371 12.5484 3.69989 12.4989 4.18716 12.3097C4.67443 12.1205 5.0909 11.8 5.38392 11.3889C5.67694 10.9778 5.83333 10.4945 5.83333 10C5.83253 9.33719 5.55131 8.70175 5.05139 8.23307C4.55147 7.76439 3.87366 7.50076 3.16667 7.5ZM3.16667 15C2.63925 15 2.12368 15.1466 1.68515 15.4213C1.24662 15.696 0.904823 16.0865 0.702989 16.5433C0.501155 17.0001 0.448347 17.5028 0.55124 17.9877C0.654134 18.4727 0.90811 18.9181 1.28105 19.2678C1.65399 19.6174 2.12914 19.8555 2.64643 19.952C3.16371 20.0484 3.69989 19.9989 4.18716 19.8097C4.67443 19.6205 5.0909 19.3 5.38392 18.8889C5.67694 18.4778 5.83333 17.9945 5.83333 17.5C5.83253 16.8372 5.55131 16.2017 5.05139 15.7331C4.55147 15.2644 3.87366 15.0008 3.16667 15Z"
                                />
                            </svg>
                            <ul
                                [ngClass]="subMenu2 ? 'subMenu2' : 'subMenu'"
                                *ngIf="i == actClick"
                                outsideClick
                                #menu
                                (outsideClick)="Test()"
                            >
                                <ng-container
                                    *ngFor="
                                        let action of Actions;
                                        let i = index
                                    "
                                >
                                    <a
                                        onclick="return false"
                                        href=""
                                        (click)="Emitter(action, item)"
                                        *ngIf="Determine(action, item)"
                                    >
                                        <div
                                            class="svg"
                                            [innerHTML]="
                                                _sanitizer.bypassSecurityTrustHtml(
                                                    SVGActions[
                                                        action.toString()
                                                    ].svg.toString()
                                                )
                                            "
                                        ></div>
                                        {{
                                            SVGActions[action.toString()].label
                                                | translate
                                        }}
                                    </a>
                                </ng-container>
                            </ul>
                        </td>
                        <td
                            *ngIf="Dropdown"
                            scope="Mais informações"
                            [class]="
                                ArraySD.includes(Itens[i].id)
                                    ? 'actExpand act in ExpandButton'
                                    : 'ExpandButton'
                            "
                            (click)="Expand($event, Itens[i].id, item)"
                        >
                            <div
                                class="flex flex-end"
                                style="padding-right: 20px"
                            >
                                <svg
                                    width="20"
                                    height="11"
                                    viewBox="0 0 20 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M0.841981 0.310843C1.28949 -0.111554 2.00551 -0.1023 2.44125 0.331514L10 7.85692L17.5588 0.331514C17.9945 -0.1023 18.7105 -0.111555 19.158 0.310843C19.6055 0.733241 19.6151 1.42734 19.1793 1.86115L10 11L0.820658 1.86115C0.384921 1.42734 0.394468 0.733242 0.841981 0.310843Z"
                                    />
                                </svg>
                            </div>
                        </td>
                    </tr>
                    <tr
                        *ngIf="Dropdown && ArraySD.includes(Itens[i].id)"
                        [ngClass]="
                            ArraySD.includes(Itens[i].id) ? 'act in' : 'out'
                        "
                        class="expand"
                    >
                        <td colspan="100%">
                            <strong>
                                <ng-container
                                    *ngIf="headerTemplateRef"
                                    [ngTemplateOutlet]="headerTemplateRef"
                                    [ngTemplateOutletContext]="{
                                        $implicit: item
                                    }"
                                >
                                </ng-container>
                            </strong>
                        </td>
                    </tr>
                </ng-container>
            </ng-template>
        </tbody>
        <ng-content select=".padding"></ng-content>
    </table>

    <!-- #region Layout para quando não existir itens na tabela -->

    <div *ngIf="!Itens?.length" class="flex flex-center align-center">
        <div class="flex flex-column align-center gp-12 mt-48">
            <svg
                class="noHover"
                width="30"
                height="40"
                viewBox="0 0 46 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.5 48.78C0.5 50.82 1.5 52.7 3.5 54.42C5.5 56.14 8.24 57.5 11.72 58.5C15.2 59.5 18.96 60 23 60C27.04 60 30.8 59.5 34.28 58.5C37.76 57.5 40.5 56.14 42.5 54.42C44.5 52.7 45.5 50.82 45.5 48.78V42.42C45.5 44.46 44.48 46.26 42.44 47.82C40.4 49.38 37.7 50.54 34.34 51.3C30.98 52.06 27.2 52.46 23 52.5C18.8 52.54 15.02 52.14 11.66 51.3C8.3 50.46 5.6 49.3 3.56 47.82C1.52 46.34 0.5 44.54 0.5 42.42V48.78ZM0.5 37.5C0.5 39.54 1.5 41.42 3.5 43.14C5.5 44.86 8.24 46.24 11.72 47.28C15.2 48.32 18.96 48.82 23 48.78C27.04 48.74 30.8 48.24 34.28 47.28C37.76 46.32 40.5 44.94 42.5 43.14C44.5 41.34 45.5 39.46 45.5 37.5V31.2C45.5 33.2 44.48 34.98 42.44 36.54C40.4 38.1 37.7 39.28 34.34 40.08C30.98 40.88 27.2 41.28 23 41.28C18.8 41.28 15.02 40.88 11.66 40.08C8.3 39.28 5.6 38.1 3.56 36.54C1.52 34.98 0.5 33.2 0.5 31.2V37.5ZM0.5 26.28C0.5 28.32 1.5 30.2 3.5 31.92C5.5 33.64 8.24 35 11.72 36C15.2 37 18.96 37.5 23 37.5C27.04 37.5 30.8 37 34.28 36C37.76 35 40.5 33.64 42.5 31.92C44.5 30.2 45.5 28.32 45.5 26.28V19.92C45.5 21.96 44.48 23.76 42.44 25.32C40.4 26.88 37.7 28.04 34.34 28.8C30.98 29.56 27.2 29.96 23 30C18.8 30.04 15.02 29.64 11.66 28.8C8.3 27.96 5.6 26.8 3.56 25.32C1.52 23.84 0.5 22.04 0.5 19.92V26.28ZM0.5 15C0.5 17.04 1.5 18.92 3.5 20.64C5.5 22.36 8.24 23.74 11.72 24.78C15.2 25.82 18.96 26.32 23 26.28C27.04 26.24 30.8 25.74 34.28 24.78C37.76 23.82 40.5 22.44 42.5 20.64C44.5 18.84 45.5 16.96 45.5 15V11.28C45.5 9.24 44.5 7.36 42.5 5.64C40.5 3.92 37.76 2.56 34.28 1.56C30.8 0.56 27.04 0.04 23 -4.92873e-08C18.96 -0.04 15.2 0.48 11.72 1.56C8.24 2.64 5.5 4 3.5 5.64C1.5 7.28 0.5 9.16 0.5 11.28V15Z"
                    fill="#BFBFBF"
                />
            </svg>
            <span class="text-grey">{{ "table.notRecords" | translate }}</span>
        </div>
    </div>

    <!-- #endregion -->

    <!-- #region Paginação controlada pelo FrontEnd (backPagination = false) -->

    <div
        class="flex flex-between align-center gp-12 responsivePagination680"
        *ngIf="!Search && Pagination && !backPagination && Itens?.length"
    >
        <div class="flex align-center responsivePagination425">
            <p>
                <span class="span-black">{{
                    Itens.length > 5
                        ? page * NResultados - NResultados + 1
                        : Itens.length
                }}</span>
                -
                <span class="span-black">{{
                    Itens.length > 5
                        ? NResultados * page > Itens.length
                            ? Itens.length
                            : NResultados * page
                        : Itens.length
                }}</span>
                <!-- {{ "table.of" | translate }} -->
                <span class="span-black">{{ Itens.length }}</span>
                {{ "table.results" | translate }}
            </p>
            <select
                name=""
                id=""
                [(ngModel)]="NResultados"
                *ngIf="Itens.length > 5"
                (change)="page = 1"
            >
                <ng-container>
                    <option [value]="10">
                        10 {{ "table.itensPage" | translate }}
                    </option>
                    <option [value]="30">
                        30 {{ "table.itensPage" | translate }}
                    </option>
                    <option [value]="50">
                        50 {{ "table.itensPage" | translate }}
                    </option>
                </ng-container>
            </select>
        </div>
        <div
            class="pages"
            *ngIf="Itens.length > 5 && [].constructor(Pages()).length > 0"
        >
            <span (click)="FirstPage()" [ngClass]="page == 1 ? 'op0' : ''">
                <svg
                    width="17"
                    height="21"
                    viewBox="0 0 17 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.72168"
                        y="0.500122"
                        width="2"
                        height="20"
                        rx="1"
                        fill="#404040"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.3287 0.860103C16.7874 1.33117 16.7773 2.08487 16.3063 2.54354L8.13466 10.5001L16.3063 18.4567C16.7773 18.9154 16.7874 19.6691 16.3287 20.1401C15.8701 20.6112 15.1164 20.6213 14.6453 20.1626L4.72168 10.5001L14.6453 0.837657C15.1164 0.378987 15.8701 0.389036 16.3287 0.860103Z"
                        fill="#404040"
                    />
                </svg>
            </span>
            <span (click)="LastPage()" [ngClass]="page == 1 ? 'op0' : ''">
                <svg
                    width="13"
                    height="21"
                    viewBox="0 0 13 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.2733 0.860103C12.732 1.33117 12.7219 2.08487 12.2509 2.54354L4.07924 10.5001L12.2509 18.4567C12.7219 18.9154 12.732 19.6691 12.2733 20.1401C11.8146 20.6112 11.0609 20.6213 10.5899 20.1626L0.66626 10.5001L10.5899 0.837657C11.0609 0.378987 11.8146 0.389036 12.2733 0.860103Z"
                        fill="#404040"
                    />
                </svg>
            </span>
            <ng-container *ngIf="Pages() <= 5">
                <ng-container
                    *ngFor="
                        let option of [].constructor(Pages());
                        let index = index
                    "
                >
                    <span
                        class="number"
                        [ngClass]="page == index + 1 ? 'act' : ''"
                        href=""
                        *ngIf="index < 5"
                        (click)="page = index + 1"
                    >
                        {{ index + 1 }}
                    </span>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="Pages() > 5">
                <ng-container
                    *ngFor="
                        let option of [].constructor(Pages());
                        let index = index
                    "
                >
                    <span
                        class="number"
                        [ngClass]="page == index + 1 ? 'act' : ''"
                        href=""
                        *ngIf="index < 5"
                        (click)="page = index + 1"
                    >
                        {{ index + 1 }}
                    </span>
                </ng-container>
            </ng-container>
            <span
                (click)="NextPage()"
                [ngClass]="page == [].constructor(Pages()).length ? 'op0' : ''"
            >
                <svg
                    width="13"
                    height="21"
                    viewBox="0 0 13 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.948371 0.860103C0.489701 1.33117 0.49975 2.08487 0.970817 2.54354L9.14244 10.5001L0.970817 18.4567C0.49975 18.9154 0.489701 19.6691 0.948371 20.1401C1.40704 20.6112 2.16074 20.6213 2.63181 20.1626L12.5554 10.5001L2.63181 0.837657C2.16074 0.378987 1.40704 0.389036 0.948371 0.860103Z"
                        fill="#404040"
                    />
                </svg>
            </span>

            <span
                (click)="EndPage()"
                [ngClass]="page == [].constructor(Pages()).length ? 'op0' : ''"
            >
                <svg
                    width="17"
                    height="21"
                    viewBox="0 0 17 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        width="2"
                        height="20"
                        rx="1"
                        transform="matrix(-1 0 0 1 16.5 0.500122)"
                        fill="#404040"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.892951 0.860103C0.434281 1.33117 0.44433 2.08487 0.915397 2.54354L9.08702 10.5001L0.915397 18.4567C0.44433 18.9154 0.434281 19.6691 0.892951 20.1401C1.35162 20.6112 2.10532 20.6213 2.57639 20.1626L12.5 10.5001L2.57639 0.837657C2.10532 0.378987 1.35162 0.389036 0.892951 0.860103Z"
                        fill="#404040"
                    />
                </svg>
            </span>
        </div>
    </div>

    <!-- #endregion -->

    <!-- #region Paginação controlada pelo BackEnd (backPagination = true) -->

    <div
        class="flex gp-12 flex-between align-center responsivePagination680"
        *ngIf="!Search && Pagination && backPagination && Itens.length"
    >
        <div class="flex align-center responsivePagination425">
            <p>
                <span class="span-black">{{
                    sumRecords > 5
                        ? pageNumber * indexNumber - indexNumber + 1
                        : sumRecords
                }}</span>
                <!-- -{{sumRecords > 10 ? indexNumber * pageNumber : sumRecords}} de {{sumRecords}} resultados -->
                -
                <span class="span-black">{{
                    sumRecords > 5
                        ? indexNumber * pageNumber > sumRecords
                            ? sumRecords
                            : indexNumber * pageNumber
                        : sumRecords
                }}</span>
                {{ "de" | translate }}
                <span class="span-black">{{ sumRecords }}</span>
                {{ "table.results" | translate }}
            </p>
            <select
                name=""
                id=""
                [(ngModel)]="indexNumber"
                *ngIf="sumRecords > 5"
                (change)="BackFirstPage()"
            >
                <ng-container>
                    <option [value]="5">
                        5 {{ "table.itensPage" | translate }}
                    </option>
                    <option [value]="10">
                        10 {{ "table.itensPage" | translate }}
                    </option>
                    <option [value]="30">
                        30 {{ "table.itensPage" | translate }}
                    </option>
                    <option [value]="50">
                        50 {{ "table.itensPage" | translate }}
                    </option>
                </ng-container>
            </select>
        </div>
        <div
            class="pages"
            *ngIf="sumRecords > 5 && [].constructor(BackPages()).length > 0"
        >
            <span
                (click)="BackFirstPage()"
                [ngClass]="pageNumber == 1 ? 'op0' : ''"
            >
                <svg
                    class="back-and-last-page"
                    width="17"
                    height="21"
                    viewBox="0 0 17 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.72168"
                        y="0.500122"
                        width="2"
                        height="20"
                        rx="1"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.3287 0.860103C16.7874 1.33117 16.7773 2.08487 16.3063 2.54354L8.13466 10.5001L16.3063 18.4567C16.7773 18.9154 16.7874 19.6691 16.3287 20.1401C15.8701 20.6112 15.1164 20.6213 14.6453 20.1626L4.72168 10.5001L14.6453 0.837657C15.1164 0.378987 15.8701 0.389036 16.3287 0.860103Z"
                    />
                </svg>
            </span>
            <span
                (click)="BackLastPage()"
                [ngClass]="pageNumber == 1 ? 'op0' : ''"
            >
                <svg
                    class="back-and-last-page"
                    width="13"
                    height="21"
                    viewBox="0 0 13 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.2733 0.860103C12.732 1.33117 12.7219 2.08487 12.2509 2.54354L4.07924 10.5001L12.2509 18.4567C12.7219 18.9154 12.732 19.6691 12.2733 20.1401C11.8146 20.6112 11.0609 20.6213 10.5899 20.1626L0.66626 10.5001L10.5899 0.837657C11.0609 0.378987 11.8146 0.389036 12.2733 0.860103Z"
                    />
                </svg>
            </span>
            <ng-container *ngFor="let pagina of Paginas(); let index = index">
                <span
                    class="number"
                    [ngClass]="pageNumber == pagina ? 'act' : ''"
                    href=""
                    (click)="BackGotoPage(pagina)"
                >
                    {{ pagina }}
                </span>
            </ng-container>
            <span
                (click)="BackNextPage()"
                [ngClass]="
                    pageNumber == [].constructor(BackPages()).length
                        ? 'op0'
                        : ''
                "
            >
                <svg
                    class="back-and-last-page"
                    width="13"
                    height="21"
                    viewBox="0 0 13 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.948371 0.860103C0.489701 1.33117 0.49975 2.08487 0.970817 2.54354L9.14244 10.5001L0.970817 18.4567C0.49975 18.9154 0.489701 19.6691 0.948371 20.1401C1.40704 20.6112 2.16074 20.6213 2.63181 20.1626L12.5554 10.5001L2.63181 0.837657C2.16074 0.378987 1.40704 0.389036 0.948371 0.860103Z"
                    />
                </svg>
            </span>
            <span
                (click)="BackEndPage()"
                [ngClass]="
                    pageNumber == [].constructor(BackPages()).length
                        ? 'op0'
                        : ''
                "
            >
                <svg
                    class="back-and-last-page"
                    width="17"
                    height="21"
                    viewBox="0 0 17 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        width="2"
                        height="20"
                        rx="1"
                        transform="matrix(-1 0 0 1 16.5 0.500122)"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.892951 0.860103C0.434281 1.33117 0.44433 2.08487 0.915397 2.54354L9.08702 10.5001L0.915397 18.4567C0.44433 18.9154 0.434281 19.6691 0.892951 20.1401C1.35162 20.6112 2.10532 20.6213 2.57639 20.1626L12.5 10.5001L2.57639 0.837657C2.10532 0.378987 1.35162 0.389036 0.892951 0.860103Z"
                    />
                </svg>
            </span>
        </div>
    </div>

    <!-- #endregion -->
</div>
