import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { TranslateModule } from '@ngx-translate/core';
import { CommonComponentModule } from "@components/common-component.module";
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ListTraceabilityComponent } from './list-traceability/list-traceability.component';
import { ViewTraceabilityComponent } from './view-traceability/view-traceability.component';
import { TraceabilityRoutingModule } from './traceability-routing.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    CommonComponentModule,        
    ReactiveFormsModule,
    MatTooltipModule,
    TranslateModule,
    MatCheckboxModule,
    MatIconModule    
  ],
  declarations: [
    ListTraceabilityComponent,
    ViewTraceabilityComponent
  ],
  exports: [
    TraceabilityRoutingModule,
  ]      
})

export class TraceabilityModule { }

