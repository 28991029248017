import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export type LoadingConnection = {
    isRunning: boolean;
    method: string;
};

@Injectable({
    providedIn: "root"
})
export class LoadingNewService {
    static SilenceLoading: Array<string> = [];
    static isSilence(url: string): boolean {
        let contains = false;
        this.SilenceLoading.forEach((path) => {
            if (url.includes(path)) {
                contains = true;
            }
        });
        return contains;
    }

    /**
     * Contains in-progress loading requests
     */
    loadingInExecution: Map<string, LoadingConnection> = new Map<
        string,
        LoadingConnection
    >();
    loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * Sets the loadingSub property value based on the following:
     * - If loading is true, add the provided url to the loadingMap with a true value, set loadingSub value to true
     * - If loading is false, remove the loadingMap entry and only when the map is empty will we set loadingSub to false
     * This pattern ensures if there are multiple requests awaiting completion, we don't set loading to false before
     * other requests have completed. At the moment, this function is only called from the @link{HttpRequestInterceptor}
     * @param loading {boolean}
     * @param url {string}
     */
    setLoading(loading: boolean, request: HttpRequest<any>): void {
        const url = request.url;

        if (!url) {
            throw new Error(
                "The request URL must be provided to the LoadingService.setLoading function"
            );
        }

        if (LoadingNewService.isSilence(url) && request.method === "GET") {
            return;
        }

        if (loading === true) {
            this.loadingInExecution.set(url, {
                isRunning: loading,
                method: request.method
            });
            this.loadingSub.next(true);
        } else if (loading === false && this.loadingInExecution.has(url)) {
            this.loadingInExecution.delete(url);
        }

        if (this.loadingInExecution.size === 0) {
            this.loadingSub.next(false);
        }
    }
}
