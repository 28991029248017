import { Component, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardAdminComponent implements OnInit {

  constructor() { }

  @Output() menuopen : boolean = false;

  ngOnInit(): void {
  }

  changeMenuOpen(event : any) {
    this.menuopen = event.changeMenuOpen;
  }

  getYear() {
    return (new Date()).getFullYear();
  }

}
