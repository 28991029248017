import { ModalComponent } from "./form/modal/modal.component";
import { InputMaskModule } from "@ngneat/input-mask";
import { NGX_MAT_DATE_FORMATS } from "@angular-material-components/datetime-picker";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputPropComponent } from "./form/input-prop/input-prop.component";
import { AppTableComponent } from "./table/table.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { SelectPropComponent } from "./form/select-prop/select-prop.component";
import { CheckboxPropComponent } from "./form/checkbox-prop/checkbox-prop.component";
import { FilePropComponent } from "./form/file-prop/file-prop.component";
import { DndDirective } from "./dnd.directive";
import { RouterModule } from "@angular/router";
import { InputSearchComponent } from "./form/input-search/input-search.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { InputMultipleComponent } from "./form/input-multiple/input-multiple.component";
import { KeywordsComponent } from "./form/keywords/keywords.component";
import { ViewPdfComponent } from "./view-pdf/view-pdf.component";
import { InputSearchCreateComponent } from "./form/input-search-create/input-search.component";
import { InputUserComponent } from "./form/input-user/input-search.component";
import { SelectStateComponent } from "./form/select-state/select-state.component";
import { FilePropImageComponent } from "./form/file-prop-image/file-prop.component";
import { SearchFilterPipe } from "../pipe/search-filter.pipe";
import { PrazoPipe } from "../pipe/prazo.pipe";
import { FilePropMultipleComponent } from "./form/file-prop-multiple/file-prop.component";
import { TextareaPropComponent } from "./form/textarea-prop/textarea-prop.component";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { InputUserMultipleComponent } from "./form/input-user-multiple/input-search.component";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { TextAreaAutoPropComponent } from "./form/textarea-auto-prop/textarea-prop.component";
import { TextFieldModule } from "@angular/cdk/text-field";
import { TextareaPropSmallerComponent } from "./form/textarea-prop-smaller/textarea-prop-smaller.component";
import { PieChartComponent } from "./form/pie-chart/pie-chart.component";
import { InputOcMultipleComponent } from "./form/input-oc-multiple/input-search.component";
import { FilePropAuthComponent } from "./form/file-prop-auth/file-prop.component";
import { SelectPersonPropComponent } from "./form/select-person-prop/select-person-prop.component";
import { FilePropAuthStyleComponent } from "./form/file-prop-auth-style/file-prop.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { PrazoDatePipe } from "../pipe/prazoDate.pipe";
import {
   NgxMatDateFormats,
   NgxMatDatetimePickerModule,
   NgxMatNativeDateModule,
   NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import { MomentDateModule } from "@angular/material-moment-adapter";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { TextareaBPropComponent } from "./form/textarea-b-prop/textarea-prop.component";
import { PrazoDeadLinePipe } from "../pipe/prazodeadline.pipe";
import { FileAnswer } from "./form/file-response/file-prop.component";
import { InputUnsetPropComponent } from "./form/input-unset-prop/input-unset-prop.component";
import { FilterComponent } from "./form/filter/filter.component";
import { MatIconModule } from "@angular/material/icon";
import { CardsComponent } from "./cards/cards.component";
import { DashboardCardsComponent } from "./dashboard-cards/dashboard-cards.component";
import { ChartJsPieComponent } from "./form/chartjs-pie/chartjs-pie.component";
import { ChartJsBarComponent } from "./form/chartjs-bar/chartjs-bar.component";
import { InputSearchCleanComponent } from "./form/input-search-clean/input-search-clean.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { RegistrationReportComponent } from "./form/registration-report/registration-report.component";
import { SimpleReportComponent } from "./form/simple-report/simple-report/simple-report.component";
import { InputMultipleUserSearchComponent } from "./form/input-multiple-user-search/input-multiple-user-search.component";
import { StopPropagationDirective } from "@app/directives/stop-propagation/stop-propagation.directive";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import {
   CdkVirtualScrollViewport,
   ScrollingModule
} from "@angular/cdk/scrolling";
import { InputUserMultipleNewComponent } from "./form/input-user-multiple-new/input-user-multiple-new.component";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { ButtonComponent } from "./form/button/button.component";
import { InputPropCurrencyComponent } from "./form/input-prop-currency/input-prop-currency.component";
import { ListComponent } from "./form/list/list.component";
import { FormComponent } from "./form/form/form.component";
import { DialogComponent } from "./form/dialog/dialog.component";
import { InputSuboptionsComponent } from "./form/input-suboptions/input-suboptions.component";
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { SelectLanguageComponent } from "./form/select-language/select-language.component";
import { CloseMenuDirective } from "@app/directives/close-menu/close-menu.directive";
import { CloseUserMenuDirective } from "@app/directives/close-user-menu/close-user-menu.directive";
import { ValidPhoneToTableFilterPipe } from "@app/pipe/validPhoneToTable.pipe";
import { InputSelectComponent } from "./form/input-search-select/input-select.component";
import { FilePropWebcamComponent } from "./form/file-prop-webcam/file-prop-webcam.component";
import { NotifyCheckboxPropComponent } from "./form/notifyCheck-prop/notifyCheck-prop.component";

const maskConfig: Partial<IConfig> = {
   validation: false
};

const CUSTOM_DATE_FORMATS_NG: NgxMatDateFormats = {
   parse: {
      dateInput: "DD/MM/YYYY HH:mm"
   },
   display: {
      dateInput: "DD/MM/YYYY HH:mm",
      monthYearLabel: "MMM YYYY",
      dateA11yLabel: "LL",
      monthYearA11yLabel: "MMMM YYYY"
   }
};

@NgModule({
   declarations: [
      InputSuboptionsComponent,
      InputPropComponent,
      TextareaPropComponent,
      TextareaBPropComponent,
      TextareaPropSmallerComponent,
      AppTableComponent,
      SelectPropComponent,
      CheckboxPropComponent,
      FilePropComponent,
      InputSearchComponent,
      InputMultipleComponent,
      KeywordsComponent,
      KeywordsComponent,
      ViewPdfComponent,
      InputSearchCreateComponent,
      SelectStateComponent,
      FilePropImageComponent,
      SearchFilterPipe,
      FilePropMultipleComponent,
      PrazoPipe,
      PrazoDatePipe,
      PrazoDeadLinePipe,
      InputUserComponent,
      InputUserMultipleComponent,
      InputSearchCleanComponent,
      TextAreaAutoPropComponent,
      PieChartComponent,
      InputOcMultipleComponent,
      FilePropAuthComponent,
      SelectPersonPropComponent,
      FilePropAuthStyleComponent,
      FileAnswer,
      ModalComponent,
      InputUnsetPropComponent,
      FilterComponent,
      CardsComponent,
      DashboardCardsComponent,
      ChartJsPieComponent,
      ChartJsBarComponent,
      InputMultipleUserSearchComponent,
      RegistrationReportComponent,
      SimpleReportComponent,
      StopPropagationDirective,
      CloseMenuDirective,
      CloseUserMenuDirective,
      InputUserMultipleNewComponent,
      ButtonComponent,
      InputPropCurrencyComponent,
      ListComponent,
      DialogComponent,
      FormComponent,
      ValidPhoneToTableFilterPipe,
      SelectLanguageComponent,
      InputSelectComponent,
      FilePropWebcamComponent,
      NotifyCheckboxPropComponent
   ],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule.forChild([]),
      Ng2SearchPipeModule,
      NgxMaskModule.forRoot(maskConfig),
      MatFormFieldModule,
      MatAutocompleteModule,
      MatSelectModule,
      TextFieldModule,
      MatInputModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MomentDateModule,
      NgxMatDatetimePickerModule,
      NgxMatNativeDateModule,
      NgxMatTimepickerModule,
      NgxMatMomentModule,
      InputMaskModule,
      MatIconModule,
      PdfViewerModule,
      MatTooltipModule,
      ScrollingModule,
      MatChipsModule,
      TranslateModule
   ],
   exports: [
      InputSuboptionsComponent,
      InputPropComponent,
      TextareaPropComponent,
      TextareaBPropComponent,
      TextareaPropSmallerComponent,
      AppTableComponent,
      SelectPropComponent,
      CheckboxPropComponent,
      FilePropComponent,
      InputSearchComponent,
      InputSearchCleanComponent,
      Ng2SearchPipeModule,
      InputMultipleComponent,
      KeywordsComponent,
      ViewPdfComponent,
      InputSearchCreateComponent,
      SelectStateComponent,
      FilePropImageComponent,
      SearchFilterPipe,
      FilePropMultipleComponent,
      PrazoPipe,
      PrazoDatePipe,
      PrazoDeadLinePipe,
      ValidPhoneToTableFilterPipe,
      InputUserComponent,
      InputUserMultipleComponent,
      TextAreaAutoPropComponent,
      TextFieldModule,
      PieChartComponent,
      InputOcMultipleComponent,
      FilePropAuthComponent,
      SelectPersonPropComponent,
      FilePropAuthStyleComponent,
      FileAnswer,
      ModalComponent,
      InputUnsetPropComponent,
      FilterComponent,
      CardsComponent,
      DashboardCardsComponent,
      ChartJsPieComponent,
      ChartJsBarComponent,
      RegistrationReportComponent,
      SimpleReportComponent,
      InputMultipleUserSearchComponent,
      StopPropagationDirective,
      CloseMenuDirective,
      CloseUserMenuDirective,
      MatChipsModule,
      InputUserMultipleNewComponent,
      ButtonComponent,
      InputPropCurrencyComponent,
      ListComponent,
      DialogComponent,
      FormComponent,
      SelectLanguageComponent,
      InputSelectComponent,
      TranslatePipe,
      FilePropWebcamComponent,
      NotifyCheckboxPropComponent
   ],
   providers: [
      { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS_NG },
      RegistrationReportComponent,
      CdkVirtualScrollViewport
   ]
})
export class CommonComponentModule {}
