import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AnalyteService } from '@app/services/analyte/analyte.service';
import { Actions, ResolveAnalytes, resolveAnalytes, } from './analytes.model';
import { DepartmentService } from '@app/services/department/department.service';
import { Util } from '@app/util/util';
import { ToastService } from '@app/services/translate/toast.service';
import { DepartmentAuthService } from '@app/services/auth/department.service';

@Component({
  selector: 'app-analytes',
  templateUrl: './analytes.component.html',
  styleUrls: ['./analytes.component.scss']
})
export class AnalytesComponent implements OnInit {
  total: number = 0;
  page: number = 1;
  index: number = 50;
  getParameters: string = (`?page=${this.page}&index=${this.index}`);

  analytes: Array<any> = [];


  ActionsExtra = {
    "Ativo": "PutActive"
  }

  filterForm = {
    name: new UntypedFormControl(null),
    code: new UntypedFormControl(null),
    isOutsourced: new UntypedFormControl(null),
    isActive: new UntypedFormControl(null),
    department: new UntypedFormControl(null)
  }

  departmentOptions: Array<Object> = []

  constructor(
    private _departmentService: DepartmentAuthService,
    private _analyteService: AnalyteService,
    private _router: Router,
    private _util: Util,
    private _toastService: ToastService
  ) { }

  async ngOnInit() {
    await this.get();
    //await this.getDepartment();
  }

  nameInvoice(item: any) {
    return item.nameInvoice ? item.nameInvoice : "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Não informado</span>";
  }

  newAnalyte() {
    this._router.navigateByUrl('Cadastros/Exames/Exames-Laboratoriais/Novo');
  }

  searchAnalytes() {
    this.page = 1
    this.get()
  }

  async get(paramPage?: number[]) {
    this.analytes = [];
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    const filterObject = {
      page: this.page,
      numberRegistry: this.index,
      department: this.filterForm.department.value ?? null,
      isOutsourced: this.filterForm.isOutsourced.value == true ? this.filterForm.isOutsourced.value : null,
      code: this.filterForm.code.value,
      Search: this.filterForm.name.value,
      isActive: this.filterForm.isActive.value ? this.filterForm.isActive.value : null
    }

    await this._analyteService.getPagination(filterObject).then(
      (res) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolve(res.data, resolveAnalytes);
        }
      }
    )
  }

  resetFilter() {
    this.filterForm.code.reset();
    this.filterForm.name.reset();
    this.filterForm.isOutsourced.reset();
    this.filterForm.department.reset();
    this.filterForm.isActive.reset();
  }

  resolve(data: any, columns: any) {
    this.analytes = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.analytes.push(obj);
    });
  }

  async actions(emit: Actions) {
    switch (emit.action) {
      case "Editar":
        this._router.navigateByUrl('Cadastros/Exames/Exames-Laboratoriais/' + emit.object.id);
        break;
      case 'PutActive':
        this._analyteService.patchActive(emit.object.id, emit.object.Ativo).then(() => {
          this._toastService.fireSuccess('global.msgSituationSuccess');
        })
        break;
    }
  }

  putActive(id: string, value: string) {
    this._analyteService.patchActive(id, value).then((x: any) => {
      this.get();
      setTimeout(() => {
        this._toastService.fireSuccess('global.msgSituationSuccess');
      }, 500);
    }).catch(err => {
      this._toastService.fireError(err);
    });
  }

  async getDepartment() {
    await this._departmentService.getPagination().then((res) => {
      res.data.forEach((department: any) => {
        const departmentOption = {
          label: department.name,
          value: department.name
        }
        this.departmentOptions.push(departmentOption);
      });
    })
  }
}
