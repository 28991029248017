import { Pipe, PipeTransform } from "@angular/core";
import { formatDate } from "@angular/common";
import { locale } from "moment";

/**
 * Pipe to transforma dates to lacale date
 */
@Pipe({
   name: "localeDate"
})
export class LocalaDatePipe implements PipeTransform {
   private locale: string = "en-US";

   /**
    * @param value Date to be modified
    * @param formatType Standards values, based in uses
    * @param format Custom format, if the 'standards' do not meet the need
    * @returns
    */
   transform(
      value: Date | string | number,
      formatType: DefaultFormats = DefaultFormats.full,
      format?: string
   ): string | null {
      value = value.toString().replace(/\+00:00$/, "");

      if (window.navigator.userAgent.toLowerCase().search("firefox") != -1) {
         if (typeof value === "string") {
            value = new Date(Date.parse(value));
         }
      } else {
         if (typeof value === "string") {
            value = new Date(Date.parse(value));
         } else {
            value = value;
         }
      }

      if (value instanceof Date) {
         if (
            value
               .toISOString()
               .split("T")[1]
               .replace(/[^1-9]/g, "") == ""
         )
            value = new Date(
               value.getUTCFullYear(),
               value.getUTCMonth(),
               value.getUTCDate()
            );
      }

      return formatDate(
         value,
         this.getFormatByType(formatType, format),
         this.locale
      );
   }

   private getFormatByType(typed: DefaultFormats, format?: string) {
      if (format) {
         return format;
      }
      /**
       * Standards formats
       * @see Custom-formats: https://angular.io/api/common/DatePipe#custom-format-options
       */
      switch (typed) {
         case DefaultFormats.short: {
            return "dd/MM/yyyy";
         }
         case DefaultFormats.hours: {
            return "HH:mm:ss";
         }
         case DefaultFormats.full: {
            return "dd/MM/yyyy 'às' HH:mm";
         }
      }
   }
}

export enum DefaultFormats {
   short = "short",
   hours = "hours",
   full = "full"
}
