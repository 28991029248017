import { inject } from "@angular/core";
import { ToastService } from "@app/services/translate/toast.service";

/**
 * Classe base para todos os Facades. Garante que possam exibir notifações do tipo `Toast` para usuário
 *
 * @class DefaultFacade
 */
export abstract class DefaultFacade {
   protected _toastService = inject(ToastService);
   /**
    * Exibe Toast com icone de sucesso.
    *
    * @param message Mensagem ou Lista de mensagens para serem exibidos
    */
   protected ToastSuccess(message: string) {
      // ToastNotification.Sucesso(message);
      this._toastService.fireSuccess(message);
   }

   protected warningMessage(text: string) {
      this._toastService.warningMessage(text);
   }

   /**
    * Exibe Toast com icone de erro.
    *
    * @param message Mensagem para ser exibida
    * @param title Titulo que será exibido em cima da mensagem (default: 'Ah que pena!')
    */
   protected ToastError(message: string, title?: string) {
      // ToastNotification.printMessageError(message, title);
      this._toastService.fireError(message, title);
   }

   /**
    * Exibe Toast com icone de erro.
    * Não deve ser usado com frequencia, pois trata erro de maneira generica.
    *
    * @param error Erro generico
    */
   protected ToastErrorGeneric(error: any, title?: string) {
      this._toastService.fireGenericError(error, title);
      // ToastNotification.printError(error);
   }

   /**
    * Exibe Toast com icone de alerta.
    *
    * @param title Titulo que será exibido
    * @param html Html que será usado como body de alerta
    * @param confirmButton Texto do botão confirmar
    */
   protected async ToastAlertGeneric(
      title: string,
      html: string,
      confirmButton: string
   ) {
      // return ToastNotification.GenericAlert(title, html, confirmButton);
      return this._toastService.fireConfirmationHtml(
         title,
         html,
         confirmButton
      );
   }

   /**
    * Exibe Pop UP com pergunta.
    *
    * @param title Titulo que será exibido. Geralmente pergunta para "SIM" ou "NÃO"
    */
   protected async ToastPopUp(title: string) {
      return this._toastService.fireConfirmation(title);
   }
}
