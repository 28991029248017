import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { CollectService } from "@app/services/collect/collect.service";
import { SwAlSetttings } from "@app/util/swal.settings";
import {
   GetAllCollects,
   GetByIdCollect
} from "@app/services/collect/collect.types";
import { Guid } from "@app/util/guid";
import { MatDialog } from "@angular/material/dialog";
import { AccessControlUserCodeBar } from "@app/services/auth/acesscontrol-user-codebar.service";
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from "@angular/material/legacy-tabs";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { RequisitionStatus } from "@app/util/ERequisitionStatus";
import { PersonAuthService } from "@app/services/auth/person-auth.service";
import {
   AnalytesCollect,
   FinishCollectBody,
   FinishRecipientRequest,
   ListRequisition,
   Recipients,
   StartCollectBody,
   Actions,
   Analytes,
   GetPriority
} from "./download-collect.model";
import { Util } from "@app/util/util";
import { RequisitionService } from "@app/services/requisitions/requisition.service";
import decodedToken from "@app/util/Token";
import { UnityService } from "@app/services/shared/unity.service";
import { CollectionPlaceService } from "@app/services/collection-places/collection-place.service";
import { CompanyService } from "@app/services/company/company.service";
import { QueryParams } from "@app/services/interfaces/services.types";

@Component({
   selector: "app-download-collect",
   templateUrl: "./download-collect.component.html",
   styleUrls: ["./download-collect.component.scss"]
})
export class DownloadCollectComponent implements OnInit {
   registerExams = [];
   registerChecked: Array<Guid> = [];
   form = {
      codeBarFilter: new UntypedFormControl(null, [Validators.required]),
      userCodeBar: new UntypedFormControl(null, [Validators.required])
   };

   formFilter = {
      companyId: new UntypedFormControl(null),
      unityId: new UntypedFormControl(null),
      collectionPlaceId: new UntypedFormControl(null),
      requisitionNumber: new UntypedFormControl(null)
   };

   requisitionId = "";
   registerRecipients: Array<Object> = [];
   registerRequisition: Array<Object> = [];
   recipientsSelection: Array<Object> = [];

   ActionsExtraSelect = {
      Selecionar: "check"
   };

   total: number = 0;
   page: number = 1;
   index: number = 50;

   companyOptions: any = [];
   unityOptions: any = [];
   collectionPlaceOptions: any = [];

   resolveSelectionRecipients: Array<Object> = [
      {
         label: "id",
         retrive: "id",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "_hide_recipientId",
         retrive: "recipientId",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Selecionar",
         retrive: "",
         method: "returnCheckBox",
         after: "",
         before: ""
      },
      {
         label: "Código de Barras",
         retrive: "codeBar",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Descrição",
         retrive: "recipientDescription",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Analitos",
         retrive: "analytes",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Material",
         retrive: "",
         method: "getAnalyteMaterial",
         after: "",
         before: ""
      }
   ];

   resolveRecipients: Array<Object> = [
      {
         label: "id",
         retrive: "id",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Descrição",
         retrive: "recipientDescription",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Código de Barras - Web",
         retrive: "codeBar",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Analitos",
         retrive: "analytes",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Material",
         retrive: "materialDescription",
         method: "getAnalyteMaterial",
         after: "",
         before: ""
      }
   ];

   resolveRequisitions: Array<Object> = [
      {
         label: "id",
         retrive: "requisitionId",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "_hide_requisitionId",
         retrive: "requisitionId",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "_hide_customerId",
         retrive: "customerId",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Requisição",
         retrive: "requisitionNumber",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Cliente",
         retrive: "",
         method: "getCustomer",
         after: "",
         before: ""
      },
      {
         label: "Data de Entrada",
         retrive: "",
         method: "getDate",
         after: "",
         before: ""
      },
      {
         label: "Coletando por",
         retrive: "",
         method: "getCollectPersonName",
         after: "",
         before: ""
      },
      {
         label: "Início da Coleta",
         retrive: "",
         method: "getInitialCollect",
         after: "",
         before: ""
      },
      {
         label: "Prioritário",
         retrive: "",
         method: "getPriority",
         after: "",
         before: ""
      },
      {
         label: "Espera",
         retrive: "waitingTime",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Situação",
         retrive: "",
         method: "getButton",
         after: "",
         before: ""
      }
   ];

   ActionsExtra = {
      Situação: "collectSituation"
   };

   examSituation = {
      0: "<span class='span-styles' style='background: #F8F8FF; color: #000000;'>Não Coletado</span>",
      1: "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Aguardando Coleta</span>",
      2: "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Coletado Parcial</span>",
      3: "<span class='span-styles' style='background: #FF0000; color: #F8F8FF;'>Coletado</span>",
      4: "<span class='span-styles' style='background: #363636; color: #F8F8FF;'>Solic. Recoleta</span>",
      5: "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Recoletado</span>",
      6: "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Triado</span>",
      7: "<span class='span-styles' style='background: #F8F8FF; color: #00FFFF;'>Digitado Parcial</span>",
      8: "<span class='span-styles' style='background: #F8F8FF; color: #00FFFF;'>Digitado</span>",
      9: "<span class='span-styles' style='background: #00FFFF; color: #000000;'>Conferido</span>",
      10: "<span class='span-styles' style='background: #F8F8FF; color: #00FF00;'>Liberado</span>",
      11: "<span class='span-styles' style='background: #F8F8FF; color: #0000FF;'>Entregue</span>",
      12: "<span class='span-styles' style='background: #F8F8FF; color: #00008B;'>Impresso</span>",
      20: "<span class='span-styles' style='background: #0000FF; color: #C0C0C0;'>Suspenso de Fatura</span>",
      21: "<span class='span-styles' style='background: #00FF00; color: #F8F8FF;'>Exclusivo de Fatura</span>",
      30: "<span class='span-styles' style='background: #F8F8FF; color: #C0C0C0;'>Cancelado</span>",
      91: "<span class='span-styles' style='background: #F8F8FF; color: #C0C0C0;'>Em Coleta</span>"
   };

   @ViewChild("dialogUserValidation")
   dialogUserValidation!: TemplateRef<any>;

   @ViewChild("dialogCollectRecipients")
   dialogCollectRecipients!: TemplateRef<any>;

   @ViewChild("dialogModalAllInstructions")
   dialogModalAllInstructions!: TemplateRef<any>;

   // #region Baixa por recipientes
   listCodeBar: Array<Object> = [];
   filterCodeBar: Array<Object> = [];
   filteredCodeBar: Array<Object> = [];
   requisitionFilter: Array<Object> = [];

   codeBarRequisitionId: string = "";
   // #endregion

   // #region Coleta
   listRequisition: any = [];
   listRequisitionRecipients: Array<Object> = [];
   // #endregion

   userValidated: boolean = false;

   actualCollectByRecipientsUser: string = "";
   collectByRecipientsPersonId: string | null = null;

   actualCollectUser: string = "";
   collectPersonId: string | null = null;

   selectedTab = new UntypedFormControl(0);

   analytesCollect: Array<AnalytesCollect> = [];

   constructor(
      private _collectService: CollectService,
      private _dialog: MatDialog,
      private _accessControlUserCodeBar: AccessControlUserCodeBar,
      private _localeDate: LocalaDatePipe,
      private _personAuthService: PersonAuthService,
      private _collectionPlaceService: CollectionPlaceService,
      private _companyService: CompanyService,
      private _requisitionService: RequisitionService,
      private _unityService: UnityService
   ) {}

   async getCollectPerson(personCollectId: string) {
      let personInCollectName: string = "";
      if (personCollectId != Guid.getEmpty()) {
         await this._personAuthService
            .getById(new Guid(personCollectId))
            .then((res) => {
               personInCollectName = res.data[0].name;
            })
            .catch((err) => {
               SwAlSetttings.printError(err);
            });
         return personInCollectName;
      } else {
         return "";
      }
   }

   getCollectPersonName(item: GetAllCollects) {
      if (item.personInCollectName) {
         return item.personInCollectName;
      } else {
         return "";
      }
   }

   startCollect(object: any) {
      const obj = {
         requisitionId: object.requisitionId,
         personId: this.collectByRecipientsPersonId
      };

      this._collectService
         .post(obj)
         .then((res) => {
            SwAlSetttings.Sucesso("Processo de Coleta iniciado com sucesso!");
            setTimeout(() => {
               this.closeModal();
               this.getAllRequisitionRecipients();
            });
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   async finishCollect(obj: any, exams?: any) {
      let examsCollect = this.registerChecked;
      if (exams) {
         examsCollect = exams;
      }
      const object = {
         personId: this.collectByRecipientsPersonId,
         recipientIds: this.registerChecked
      };
      if (object) {
         this._collectService
            .put(object, undefined, `finish/${new Guid(obj.requisitionId)}`)
            .then((res) => {
               SwAlSetttings.Sucesso(
                  "Processo de Coleta finalizado com sucesso!"
               );
               setTimeout(() => {
                  this.closeModal();
                  this.getAllRequisitionRecipients();
               });
            })
            .catch((err) => {
               SwAlSetttings.printError(err);
            });
      }
   }

   getInitialCollect(item: GetAllCollects) {
      if (item.initialCollect) {
         return this._localeDate.transform(item.initialCollect);
      } else {
         return "";
      }
   }

   openDialogModalUserValidation(requisition?: string, finish?: boolean): void {
      const myTempDialog = this._dialog.open(this.dialogUserValidation, {
         width: "660px",
         data: { requisitionId: requisition, finish: finish }
      });

      myTempDialog.afterClosed().subscribe(() => {
         this.form.userCodeBar.setValue(null);
      });
   }

   async openDialogModalCollectRecipients(requisitionId: string) {
      let customer = "";
      let requisition = 0;
      let requisitionProgressId = "";
      await this._collectService
         .getById(new Guid(requisitionId))
         .then(async (res: any) => {
            if (res.data.length > 0) {
               customer = res.data[0].customerName;
               requisition = res.data[0].requisitionNumber;
               //@ts-ignore
               this.resolveSelected(
                  res.data[0].recipientsData,
                  this.resolveSelectionRecipients
               );
            }
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
      const myTempDialog = this._dialog.open(this.dialogCollectRecipients, {
         width: "660px",
         data: {
            customer: customer,
            requisition: requisition,
            requisitionId: requisitionId
         }
      });
      myTempDialog.afterClosed().subscribe((res) => {});
   }

   closeModal() {
      this._dialog.closeAll();
   }

   async ngOnInit() {
      await this.getCompanies();
      await this.getCollectionPlace();
      await this.getUnity();
      this.form.userCodeBar.setValue(null);
      this.getAllRequisitionRecipients();
   }

   async myTabSelectedTabChange(changeEvent: MatTabChangeEvent) {
      this.selectedTab.setValue(changeEvent);
      if (
         this.selectedTab.value === 1 &&
         this.actualCollectByRecipientsUser === ""
      ) {
         this.openDialogModalUserValidation();
      }

      if (this.selectedTab.value === 0) {
         this.getAllRequisitionRecipients();
      }
   }

   async validateUserCodebar(obj: any) {
      const queryParam: string = `codebar=${this.form.userCodeBar.value}`;
      if (this.form.userCodeBar.value != null) {
         await this._accessControlUserCodeBar
            .getByPath(queryParam, "")
            .then((res) => {
               if (res.data.length > 0) {
                  if (this.selectedTab.value === 1) {
                     this.userValidated = true;
                     this.actualCollectByRecipientsUser = res.data[0].name;
                     this.collectByRecipientsPersonId = res.data[0].personId;
                     setTimeout(() => {
                        SwAlSetttings.Sucesso(
                           "Usuário autenticado com sucesso!"
                        );
                     });
                  } else {
                     this.actualCollectByRecipientsUser = res.data[0].name;
                     this.collectByRecipientsPersonId = res.data[0].personId;
                     setTimeout(() => {
                        SwAlSetttings.Sucesso(
                           "Usuário autenticado com sucesso!"
                        );
                        if (obj != undefined) {
                           if (obj.finish) {
                              this.openDialogModalCollectRecipients(
                                 obj.requisitionId
                              );
                           } else {
                              this.startCollect(obj);
                           }
                        }
                     });
                  }
               } else {
                  this.form.userCodeBar.setValue(null);
                  SwAlSetttings.printMessageError("Código de Barras inválido!");
                  setTimeout(() => {
                     this.openDialogModalUserValidation();
                  });
               }
            });
         this.closeModal();
      }
   }

   returnCodeBar(codebar: String) {
      this.listCodeBar.forEach((requisition: any) => {
         let requisitionId = requisition.requisitionId;

         if (requisition.recipients.length > 0) {
            requisition.recipients.forEach((recipients: any) => {
               if (recipients.codeBar === codebar) {
                  if (!this.filteredCodeBar.includes(codebar)) {
                     let newobj = { ...recipients, requisitionId };
                     this.filteredCodeBar.push(newobj);
                  }
               }
            });
         }
      });

      this.resolve(this.filteredCodeBar, this.resolveRecipients);

      this.form.codeBarFilter.setValue(null);
   }

   async actions(emit: any) {
      switch (emit.action) {
         case "OpenDropdown":
            //@ts-ignore
            this.registerExams[emit.object._hide_requisitionId] = [];
            //@ts-ignore
            await this._collectService
               .getById(new Guid(emit.object._hide_requisitionId))
               .then(async (res: any) => {
                  if (res.data.length > 0) {
                     //@ts-ignore
                     this.ResolveExams(
                        res.data[0].recipientsData,
                        this.resolveRecipients,
                        emit.object._hide_requisitionId
                     );
                  }
               })
               .catch((err) => {
                  SwAlSetttings.printError(err);
               });
            break;
         case "situationButton":
            SwAlSetttings.ALERT_CONFIRM.fire(
               "Deseja imprimir as etiquetas de Coleta?"
            ).then((r) => {
               let recipients: Array<Object> = [];
               if (r.isConfirmed) {
                  this.listCodeBar.forEach((x: any) => {
                     if (emit.object.id === x.id) {
                        recipients = x.recipients;
                     }
                  });
               } else {
                  this.listCodeBar.forEach((x: any) => {
                     if (emit.object.id === x.id) {
                        recipients = x.recipients;
                     }
                  });
               }
            });
            break;
         case "collectSituation":
            if (emit.object.Situação.includes("Iniciar Coleta")) {
               //@ts-ignore
               this.openDialogModalUserValidation(
                  emit.object._hide_requisitionId,
                  false
               );
            } else {
               if (this.actualCollectUser === "") {
                  //@ts-ignore
                  this.openDialogModalUserValidation(
                     emit.object._hide_requisitionId,
                     true
                  );
                  break;
               } else {
                  //@ts-ignore
                  this.openDialogModalCollectRecipients(
                     emit.object._hide_requisitionId
                  );
                  break;
               }
            }
      }
   }

   ActionsSelect(action: any) {
      switch (action.action) {
         case "check":
            if (action.$event.srcElement.checked != undefined) {
               var ind = this.registerChecked.findIndex(
                  (x: any) => x == action.object.id
               );
               if (action.$event.srcElement.checked) {
                  if (ind == -1) {
                     this.registerChecked.push(action.object.id);
                  }
               } else {
                  if (ind > -1) {
                     this.registerChecked.splice(ind, 1);
                  }
               }
            }
            break;
      }
   }

   getButton(item: GetAllCollects) {
      let finish: string =
         "<span class='span-styles' style='background: #FF0000; color: white;'>Finalizar Coleta</span>";
      let start: string =
         "<span class='span-styles' style='background: #07BF56; color: white; '>Iniciar Coleta</span>";
      switch (item.requisitionStatus) {
         case RequisitionStatus.AwaitCollect:
            return start;
         case RequisitionStatus.InCollect:
            return finish;
         default:
            return "";
      }
   }

   getCustomer(item: GetAllCollects) {
      return item.customerName.toUpperCase();
   }

   getAnalyteMaterial(item: any) {
      let materialDescription: string = "";
      materialDescription = item.materialDescription;
      return materialDescription;
   }

   getAnalyteSituation(item: Recipients) {
      let situationDescription: string = item.situation.toString();

      return (this.examSituation as any)[situationDescription];
   }

   getRecipientDescription(item: Recipients) {
      if (item.externalId) {
         return item.externalId + " - " + item.description;
      } else {
         return item.description;
      }
   }

   getExternalCodeBar(item: Recipients) {
      if (item.externalCodeBar) {
         return item.externalCodeBar;
      } else {
         return "<span class='span-styles' style='background: red; color: #FFFFFF;'>Não Integrado</span>";
      }
   }

   async getAllRequisitionRecipients(paramPage?: number[]) {
      if (paramPage) {
         this.page = paramPage[0];
         this.index = paramPage[1];
      } else {
         this.page = 1;
         this.index = 50;
      }

      const queryObject: QueryParams = {
         companyId: this.formFilter.companyId.value
            ? this.formFilter.companyId.value
            : null,
         unityId: this.formFilter.unityId.value
            ? this.formFilter.unityId.value
            : null,
         collectionPlaceId: this.formFilter.collectionPlaceId.value
            ? this.formFilter.collectionPlaceId.value
            : null,
         requisitionNumber: this.formFilter.requisitionNumber.value
            ? this.formFilter.requisitionNumber.value
            : null,
         numberRegistry: this.index,
         page: this.page
      };

      this.listRequisition = [];
      let listRequisitionWithPerson: any = [];
      this.registerRequisition = [];
      await this._collectService
         .getPagination(queryObject)
         .then(async (res) => {
            if (res.data.length > 0) {
               this.total = res.sumRecords;
               res.data.forEach((x: any) => {
                  this.listRequisition.push(x);
               });
               for (
                  let index = 0;
                  index < this.listRequisition.length;
                  index++
               ) {
                  if (
                     this.listRequisition[index].personInCollect !=
                     Guid.getEmpty()
                  ) {
                     //@ts-ignore
                     let personName =
                        this.listRequisition[index].personInCollectName;
                     const newObj = {
                        ...this.listRequisition[index],
                        ...{
                           personInCollectName: personName
                              ? personName.toUpperCase()
                              : personName
                        }
                     };
                     listRequisitionWithPerson.push(newObj);
                  } else {
                     const newObj = {
                        ...this.listRequisition[index],
                        ...{ personInCollectName: "" }
                     };
                     listRequisitionWithPerson.push(newObj);
                  }
               }

               this.resolveRequisition(res.data, this.resolveRequisitions);
            }
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   async putCodeBarRequisition(codeBar: string) {
      const obj = {
         personId: this.collectByRecipientsPersonId,
         codeBar: codeBar
      };
      await this._collectService
         .postByPath(obj, `codebar`)
         .then(async (res: any) => {
            //@ts-ignore
            this.codeBarRequisitionId = res.requisitionId;
            res.data.forEach((x: any) => {
               this.listCodeBar.push(x);
            });
            this.resolve(this.listCodeBar, this.resolveRecipients);
            this.form.codeBarFilter.reset();
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });

      await this._collectService
         .getById(new Guid(this.codeBarRequisitionId))
         .then((res: any) => {
            if (res.data.length > 0) {
            }
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   getDate(item: ListRequisition) {
      return this._localeDate.transform(item.createdAt);
   }

   finish() {
      if (this.selectedTab.value === 1) {
         this.userValidated = false;
         this.actualCollectByRecipientsUser = "";
         this.collectByRecipientsPersonId = "";
         this.openDialogModalUserValidation();
      } else {
         this.userValidated = false;
         this.actualCollectUser = "";
         this.collectPersonId = "";
      }
   }

   resolveSelected(data: any, columns: any) {
      this.recipientsSelection = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.recipientsSelection.push(obj);
      });
   }

   resolve(data: any, columns: any) {
      this.registerRecipients = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registerRecipients.push(obj);
      });
   }

   resolveRequisition(data: any, columns: any) {
      this.registerRequisition = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registerRequisition.push(obj);
      });
   }

   ResolveExams(data: any, columns: any, id: string) {
      let exams: Array<Object> = [];
      if (data) {
         data.forEach((x: any) => {
            let obj: any = {};
            columns.forEach((y: any) => {
               if (!y.retrive) {
                  //@ts-ignore
                  obj[y.label] = y.before + this[y.method](x) + y.after;
               } else {
                  obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
               }
            });
            exams.push(obj);
         });
      }
      //@ts-ignore
      this.registerExams[id] = exams;
   }

   getPriority(item: GetPriority) {
      let stylizedSpanForPrioritary: string =
         "<span class='span-styles' style='background: #07BF56; color: #FFF'>Não</span>";
      let stylizedSpanForNotPrioritary: string =
         "<span class='span-styles' style='background: #FF0000; color: #FFF'>Sim</span>";
      return item.isPrioritary
         ? stylizedSpanForNotPrioritary
         : stylizedSpanForPrioritary;
   }

   returnCheckBox() {
      return "|checkbox";
   }

   async openDialogModalAllInstructions(
      requisitionId?: string,
      analyteId?: string
   ) {
      let analytesPreparation: Array<Object> = [];
      let query = "";
      if (analyteId) {
         query = "?AnalyteId=" + analyteId;
      }
      await this._requisitionService
         .getByPath(query, "preparation/" + requisitionId)
         .then((res: any) => {
            const data = res.data;
            for (let analyte of data) {
               if (analyteId && analyte.analyteId != analyteId) return;
               let instructions = "Não há preparos definidos para esse exame.";
               if (analyte.analyteInstructions.length != 0)
                  instructions =
                     "● " +
                     analyte.analyteInstructions
                        .map((instruction: any) => instruction.description)
                        .join("\n\n● ");

               const obj = {
                  analyteId: analyte.id,
                  code: analyte.analyteName,
                  instructions: instructions,
                  form: new UntypedFormControl(instructions)
               };
               analytesPreparation.push(obj);
            }
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });

      if (analytesPreparation.length != 0) {
         const myTempDialog = this._dialog.open(
            this.dialogModalAllInstructions,
            {
               width: "660px",
               maxHeight: "660px",
               data: analytesPreparation
            }
         );
      } else {
         SwAlSetttings.warningMessage(
            "O Exame não possui preparo para coleta!"
         );
      }
   }

   close() {
      this._dialog.closeAll();
   }

   clearForms() {
      this.formFilter.collectionPlaceId.reset();
      this.formFilter.companyId.reset();
      this.formFilter.requisitionNumber.reset();
      this.formFilter.unityId.reset();
   }

   async getUnity() {
      this.unityOptions = [];
      this.unityOptions = [{ label: "Todos", value: null }];
      const query = {
         companyId: this.formFilter.companyId.value,
         resume: true
      };
      if (!query.companyId) {
         return;
      }
      await this._unityService
         .getPagination(query)
         .then((res: any) => {
            if (res.data.length != 0) {
               res.data.forEach((element: any) => {
                  this.unityOptions.push({
                     label: element.name,
                     value: element.id
                  });
               });
            } else {
               SwAlSetttings.warningMessage("Nenhuma unidade encontrado!");
            }
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   async getCollectionPlace() {
      this.collectionPlaceOptions = [];
      this.collectionPlaceOptions = [{ label: "Todos", value: null }];

      const queryParams = { isActive: true, resume: true };
      await this._collectionPlaceService
         .getPagination(queryParams)
         .then((res: any) => {
            res.data.forEach((x: any) => {
               const optionObj = {
                  value: x.id,
                  label: x.description
               };
               this.collectionPlaceOptions.push(optionObj);
            });
         });
   }

   async getCompanies() {
      this.companyOptions = [];
      this.companyOptions = [{ label: "Todos", value: null }];
      await this._companyService.getResume().then((response) => {
         if (response.success) {
            this.companyOptions = this.companyOptions.concat(
               response.data.map((company) => ({
                  value: company.id,
                  label: company.socialReason
               }))
            );
         }
      });
   }

   async getUnities() {
      await this.getUnity();
      await this.getAllRequisitionRecipients();
   }
}
