import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { TranslateModule } from '@ngx-translate/core';
import { CommonComponentModule } from "@app/components/common-component.module";
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ReportsRoutingModule } from './reports-routing.module';
import { RequisitionProtocolReportComponent } from './requisition/protocol/requisition_protocol.component';
import { AnalyteReportComponent } from './analytes/analyte-report/analyte-report.component';
import { RecipientsReportComponent } from './recipients-report/recipients-report/recipients-report.component';
import { ClientReportComponent } from './client-report/client-report/client-report.component';
import { MatIconModule } from '@angular/material/icon';
import { TitleReportComponent } from './title-report/title-report.component';
import { GroupProductReportComponent } from './group-product-report/group-product-report.component';
import { ProductReportComponent } from './products/product-report/product-report.component';
import { QuestionReportComponent } from './questions/question-report/question-report.component';
import { MethodologyComponent } from './methodology/methodology/methodology.component';
import { MaterialsReportComponent } from './materials/materials-report/materials-report.component';

import { UnitMeasureReportComponent } from './unit-measure/unit-measure-report/unit-measure-report.component';
import { PriceTablesReportComponent } from './price-Tables/price-tables-report/price-tables-report.component';
import { TypesPaymentReportComponent } from './types-payment-report/types-payment-report.component';
import { AgreementsReportComponent } from './agreement/agreements-report/agreements-report.component';
import { DoctorReportComponent } from './doctor/doctor-report/doctor-report.component';

@NgModule({
  declarations: [
    RequisitionProtocolReportComponent,
    AnalyteReportComponent,
    RecipientsReportComponent,
    ClientReportComponent,
    TitleReportComponent,
    QuestionReportComponent,
    MethodologyComponent,
    MaterialsReportComponent,
    GroupProductReportComponent,
    ProductReportComponent,
    QuestionReportComponent,
    MethodologyComponent,
    MaterialsReportComponent,
    UnitMeasureReportComponent,
    PriceTablesReportComponent,
    TypesPaymentReportComponent,
    AgreementsReportComponent,
    DoctorReportComponent

  ],
  imports: [
    CommonModule,
    MatTabsModule,
    CommonComponentModule,
    ReportsRoutingModule,
    ReactiveFormsModule,
    MatTooltipModule,
    TranslateModule,
    MatCheckboxModule,
    MatIconModule,
    MatCheckboxModule,

  ]
})
export class ReportsModule { }
