import { Component, EventEmitter, inject, OnInit, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ToggleFormComponent } from "../../toggle/toggle.component";
import { InputSelectFormComponent } from "../../input-select/base/input-select.component";
import { FormSelectPropComponent } from "../../select-prop/select-prop.component";
import { InputTextFormComponent } from "../../input-text/input-text.component";
import { CommonModule } from "@angular/common";
import { InputSearchFormComponent } from "../../input-select/search/input-search.component";

@Component({
   selector: "app-filter-modal",
   templateUrl: "./filter-modal.component.html",
   styleUrls: ["./filter-modal.component.css"],
   standalone: true,
   imports: [
      TranslateModule,
      MatIconModule,
      MatTooltipModule,
      InputTextFormComponent,
      FormSelectPropComponent,
      InputSelectFormComponent,
      InputSearchFormComponent,
      ToggleFormComponent,
      CommonModule
   ]
})
export class FilterModalComponent implements OnInit {
   constructor() {}

   @Output() clearInputs = new EventEmitter<void>();

   @Output() clearFilters: EventEmitter<any> = new EventEmitter<any>();
   @Output() searchFilters: EventEmitter<any> = new EventEmitter<any>();
   @Output() clearFiltersPartial: EventEmitter<any> = new EventEmitter<any>();
   @Output() changeOption: EventEmitter<any> = new EventEmitter<any>();

   private _matDialog = inject(MatDialog);
   private _dataModal: any = inject(MAT_DIALOG_DATA);
   formFilter!: any;
   mask: string = "";
   type: string = "text";

   ngOnInit() {
      this.formFilter = this._dataModal.formFilter;
      if (this.formFilter[0].options)
         this.searchMask(
            this.formFilter[0].options,
            this.formFilter[0].form.value
         );
   }

   searchMask(input: any, form: any, placeholder?: string) {
      if (
         placeholder == "Empresa" &&
         this.formFilter[0].placeholder == "Empresa" &&
         this.formFilter[1].placeholder == "Filial"
      ) {
         let data = this._dataModal.formFilter;
         data[1].options = data[0].options
            .filter((x: any) => x.value == data[0].form.value)[0]
            .unities.map((res: any) => ({
               value: res.value,
               label: res.label
            }));
         this.formFilter = data;
      }
      input.forEach((input: any) => {
         if (input.value == form) {
            input.mask ? (this.mask = input.mask) : (this.mask = "");
            input.type ? (this.type = input.type) : (this.type = "text");
         }
      });
      this.resetFiltersPartial();
   }

   resetFiltersPartial() {
      this.clearFiltersPartial.emit();
   }

   clearFiltersEmit() {
      this.clearFilters.emit();
      this.mask = "";
      this.type = "text";
   }

   search() {
      this.searchFilters.emit();
      this.close();
   }

   close() {
      this._matDialog.closeAll();
   }
}
