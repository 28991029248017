import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpService } from './http.service';
import { HttpResponsePageable } from './services.types';
import { Guid } from '@app/util/guid';
import { PersonGetAll, PersonGetById, PersonPost, PersonPostObject, PersonPut } from './person.types';

@Injectable({
  providedIn: 'root'
})
export class PersonService extends BaseService {

  constructor(httpService: HttpService) {
    super(httpService, 'v1/accesscontrol/person');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<PersonGetAll>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<PersonGetById>>
  }

  override getById(id: Guid) {
    return super.getById(id) as Promise<HttpResponsePageable<PersonGetById>>
  }

  override post(body: PersonPostObject) {
    return super.post(body) as Promise<HttpResponsePageable<PersonPost>>
  }

  override put(body: object, id?: Guid | undefined, path?: string | undefined) {
    return super.put(body, id, path) as Promise<HttpResponsePageable<PersonPut>>    
  }
}
