import {
   ActionDefition,
   ColumnDefinition
} from "@app/modules_new/shared/components/form/table/table.model";

export const tableActions: ActionDefition[] = [
   {
      label: "default.edit",
      svg: "action-write",
      event: "EDITAR"
   }
];

export const tableColumns: ColumnDefinition[] = [
   {
      label: "departments.table-columns.externalId",
      key: "externalId"
   },
   {
      label: "departments.table-columns.department",
      key: "name"
   },
   {
      label: "default.active",
      key: "isActive",
      component: "checkbox"
   }
];
