import {
    Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, ChangeDetectionStrategy, NgZone, OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { fromEvent } from "rxjs";
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { OptionSelect } from './input-select.model';

/**
*  Versão Modificada sem métodos desnecessários
*/
@Component({
    selector: 'input-select',
    templateUrl: './input-select.component.html',
    styleUrls: ['./input-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSelectComponent implements OnInit, AfterViewInit {

    @Input() form!: UntypedFormControl;

    @Input() type: string = 'text';
    @Input() placeholder: string = '';
    @Input() label: string = '';
    @Input() className: string = '';
    @Input() requiredInput: string = '';
    @Input() tabindexcomponent: string = '';
    @Input() callChangeFormEmit: boolean = false;
    @Input() options: Array<OptionSelect> = [];
    @Input() color: string = 'unset';
    @Input() hideSvg: boolean = false;
    @Input() onFocus: boolean = false;
    @Input() disabled: boolean = false;
    @Input() noLabelUp: boolean = false;

    @Output() changeForm = new EventEmitter<OptionSelect | string>();

    @ViewChild('input') input!: ElementRef;
    @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete!: MatAutocompleteTrigger;

    classe: string = '';

    notSelect: boolean = true;
    border: boolean = false;

    formValue = new UntypedFormControl('');

    constructor(private _zone: NgZone) { }

    ngOnInit(): void {
        this._zone.runOutsideAngular(() => window.addEventListener('scroll', this.scrollEvent, true));
        this.classe = this.className + ' input-row';

        if (this.form) {
            const optionSelected = this.options.find((item: OptionSelect) => item.value == this.form.value)!;
            this.formValue.setValue(optionSelected?.label);
        }
    }

    ngAfterViewInit() {
        fromEvent(this.input.nativeElement, 'focus').subscribe(() => {
            this.autocomplete.openPanel();
        });

        if (this.onFocus) {
            this.input.nativeElement.focus();
        }
    }

    getSelectedOption(event: MatAutocompleteSelectedEvent) {
        const selected = event.option.value as OptionSelect;
    
        this._changeValue(selected);
    
        this.border = false;
        this.notSelect = false;
    }

    onFocusOut() {
        if (this.formValue.value && this.formValue.value.length > 0) {
          const labels = this.options.map(item => item.label?.toLocaleLowerCase());
          const hasValue = labels.includes(this.formValue.value?.toLocaleLowerCase());
          if (!hasValue) {
            this._changeValue('');
          }
        }
    }

    displayCorretLabelFn(option?: any): string | undefined {
        if (option) return option.label
        else return '';
    }

    scrollEvent = (event: any): void => {
        if (this.autocomplete.panelOpen) 
            this.autocomplete.updatePosition();
    }

    private _changeValue(value: OptionSelect | string) {
        if (typeof value === 'string') {
          this.form.setValue(value);
          this.changeForm.emit(value);
        } else {
          this.form.setValue(value.value);
          this.changeForm.emit(value.value);
        }
    }

}
