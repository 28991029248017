import { DefaultFormats } from "@app/pipe/localeDate.pipe";

export interface PlaceListEmit {
  action: string
  object: PlaceListObject
}

export interface PlaceListObject {
  id: string
  "collectionLocation.code": string
  "collectionLocation.description": string
  "Criado em": string
  "Alterado em": string
  "Ativo": string
  _hide_arrivalTimeRequired: string
  _hide_awaitCollectAutomatic: string
}

export const customerResolve: Array<Object> = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'collectionLocation.code',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'externalId',
    type: 'description',
    sort: 'name'
  },
  {
    label: 'collectionLocation.description',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'description',
    type: 'description',
    sort: 'name'
  },
  {
    label: 'collectionLocation.createdAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'createdAt'
  },
  {
    label: 'collectionLocation.updatedAt',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'updatedAt'
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'checkbox',
    sort: 'isActive'
  },
  {
    label: '_hide_arrivalTimeRequired',
    retrive: 'arrivalTimeRequired',
    method: '',
    after: '',
    before: ''
  },
  {
    label: '_hide_awaitCollectAutomatic',
    retrive: 'awaitCollectAutomatic',
    method: '',
    after: '',
    before: ''
  },
]