import { CustomersComponentOld } from "./components/customer/customers/customers.component";
import { DoctorsComponent } from "./components/doctors/doctors.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { SaveCustomerComponent } from "./components/customer/save-customer/save-customer.component";
import { SaveDoctorComponentOld } from "./components/doctors/save-doctor/save-doctor.component";
import { LoginComponent } from "@app/login/login.component";
import { AgreementsComponent } from "./components/agreement/agreements/agreements.component";
import { SaveAgreementComponent } from "./components/agreement/save-agreement/save-agreement.component";
import { AnalytesComponent } from "./components/analyte/analytes/analytes.component";
import { SaveAnalyteComponent } from "./components/analyte/save-analyte/save-analyte.component";
import { MaterialsComponent } from "./components/materials/materials/materials.component";
import { RecipientsComponent } from "./components/recipients/recipients.component";
import { QuestionsComponent } from "./components/questions/questions.component";
import { MethodologiesComponent } from "./components/methodologies/methodologies.component";
import { MessagesComponent } from "./components/messages/messages.component";
import { SupportLaboratoryComponent } from "./components/support-laboratory/support-laboratory.component";
import { SaveLaboratoryComponent } from "./components/support-laboratory/save-laboratory/save-laboratory.component";
import { TypesPaymentComponent } from "./components/financial/types-payment/types-payment.component";
import { PriceTableComponent } from "./components/price-table/price-table.component";
import { AnalytePackagesComponent } from "./components/analyte-packages/analyte-packages.component";
import { AgreementSupportComponent } from "./components/agreement-support/agreement-support/agreement-support.component";
import { SaveAgreementSupportComponent } from "./components/agreement-support/save-agreement-support/save-agreement-support.component";
import { SaveRequisitionComponent } from "./components/requisition/save-requisition/save-requisition.component";
import { SipniComponent } from "./components/vaccines/sipni/sipni.component";
import { UnitMeasureComponent } from "./components/stock/unit-measure/unit-measure.component";
import { GroupProductComponent } from "./components/stock/group-product/group-product.component";
import { SchemmaComponent } from "./components/vaccines/schemma/schemma.component";
import { ApplicationOfVaccinesComponent } from "./components/vaccines/application-vaccines/application-of-vaccines.component";
import { VaccinesAdministrationComponent } from "./components/vaccines/vaccines-administration/vaccines-administration.component";
import { ProductComponent } from "./components/stock/product/product.component";
import { VaccineComponent } from "./components/vaccines/vaccine/vaccine.component";
import { AdvancedMaintenceComponent } from "./components/requisition/advanced-maintence/advanced-maintence.component";
import { EquipmentsComponent } from "@app/modules/interfacing/components/equipments/equipments.component";
import { SaveEquipmentComponent } from "@app/modules/interfacing/components/equipments/save-equipment/save-equipment.component";
import { TypeDocumentListComponent } from "./components/financial/type-document/type-document-list/type-document-list.component";
import { TypeIncomeExpenseListComponent } from "./components/financial/types-income-expense/type-income-expense-list/type-income-expense-list.component";
import { BearerListComponent } from "./components/financial/bearer/bearer-list/bearer-list.component";
import { CollectionPlaceOriginListComponent } from "./components/collection-place-origin/collection-place-origin-list/collection-place-origin-list.component";
import { CollectionPlaceListComponent } from "./components/collection-place/collection-place-list/collection-place-list.component";
import { CollectionPlaceInstructionListComponent } from "./components/collection-place-instruction/collection-place-instruction-list/collection-place-instruction-list.component";
import { SectorListComponent } from "./components/sector/sector-list/sector-list.component";
import { OccurencyTypesComponent } from "./components/occurencyTypes/occurencyTypes.component";

const routes: Routes = [
   {
      path: "CadastrosOld",
      children: [
         { path: "", redirectTo: "Dashboard", pathMatch: "full" },
         { path: "Dashboard", component: DashboardComponent },
         { path: "Atendimento/Clientes", component: CustomersComponentOld },
         { path: "Atendimento/Clientes/:id", component: SaveCustomerComponent },
         { path: "Atendimento/Solicitantes", component: DoctorsComponent },
         {
            path: "Atendimento/Solicitantes/:id",
            component: SaveDoctorComponentOld
         },
         {
            path: "Atendimento/Origem-de-Coleta",
            component: CollectionPlaceOriginListComponent
         },
         {
            path: "Atendimento/Local-de-Coleta",
            component: CollectionPlaceListComponent
         },
         {
            path: "Atendimento/Orientacao-de-Coleta",
            component: CollectionPlaceInstructionListComponent
         },
         {
            path: "Atendimento/Setores",
            component: SectorListComponent
         },
         { path: "Convênios/Convênio", component: AgreementsComponent },
         { path: "Convênios/Convênio/:id", component: SaveAgreementComponent },
         { path: "Exames/Exames-Laboratoriais", component: AnalytesComponent },
         {
            path: "Exames/Exames-Laboratoriais/Novo",
            component: SaveAnalyteComponent
         },
         {
            path: "Exames/Exames-Laboratoriais/:id",
            component: SaveAnalyteComponent
         },
         {
            path: "Laboratorios-de-Apoio/Laboratorio-Apoio",
            component: SupportLaboratoryComponent
         },
         {
            path: "Laboratorios-de-Apoio/Laboratorio-Apoio/:id",
            component: SaveLaboratoryComponent
         },
         { path: "Exames/Materiais", component: MaterialsComponent },
         { path: "Exames/Metodologias", component: MethodologiesComponent },
         {
            path: "Exames/Pacote-de-Exames",
            component: AnalytePackagesComponent
         },
         { path: "Exames/Perguntas", component: QuestionsComponent },
         { path: "Exames/Recipientes", component: RecipientsComponent },
         {
            path: "Financeiro/Tipo-Documento",
            component: TypeDocumentListComponent
         },
         {
            path: "Concent-Apoio/Apoiados",
            component: AgreementSupportComponent
         },
         {
            path: "Concent-Apoio/Apoiados/Novo",
            component: SaveAgreementSupportComponent
         },
         {
            path: "Concent-Apoio/Apoiados/Novo/:id",
            component: SaveAgreementSupportComponent
         },
         {
            path: "Concent-Apoio/Gerenciador-Mensagens",
            component: MessagesComponent
         },
         { path: "Convênios/Tabela-de-Preco", component: PriceTableComponent },
         { path: "TiposPagamento", component: TypesPaymentComponent },
         { path: "Financeiro/Portadores", component: BearerListComponent },
         {
            path: "Financeiro/Tipos-Receita-Despesa",
            component: TypeIncomeExpenseListComponent
         },
         { path: "Vacinas/Sipni", component: SipniComponent },
         {
            path: "Vacinas/AdmVacinas",
            component: VaccinesAdministrationComponent
         },
         { path: "Vacinas/EsquemaVacinacao", component: SchemmaComponent },
         { path: "Exames/UnidadeDeMedida", component: UnitMeasureComponent },
         { path: "Estoque/UnidadeDeMedida", component: UnitMeasureComponent },
         { path: "Estoque/GrupoDeEstoque", component: GroupProductComponent },
         {
            path: "Vacinas/AplicacaoVacina",
            component: ApplicationOfVaccinesComponent
         },
         { path: "Vacinas/Vacina", component: VaccineComponent },
         { path: "Estoque/Produto", component: ProductComponent },
         {
            path: "Interfaceamento/Equipamentos",
            component: EquipmentsComponent
         },
         {
            path: "Interfaceamento/Equipamentos/:id",
            component: SaveEquipmentComponent
         },
         {
            path: "Atendimento/Tipos-Ocorrencias",
            component: OccurencyTypesComponent
         }
      ]
   },
   {
      path: "Login",
      children: [
         { path: "", redirectTo: "Login", pathMatch: "full" },
         { path: "Login", component: LoginComponent }
      ]
   }
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule]
})
export class RegistersRoutingModule {}
