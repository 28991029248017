<div class="access padd-top">
    <div class="flex flex-column">
        <div class="box">
            <div class="flex flex-center">
                <svg
                    width="54"
                    height="47"
                    viewBox="0 0 54 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M50.1018 2.36001C50.5332 2.41772 50.9358 2.56198 51.3097 2.73511C51.6836 2.90823 52.0288 3.13905 52.3164 3.42759C52.604 3.68727 52.8341 4.00466 53.0066 4.32205C53.1792 4.63944 53.2655 4.98568 53.2655 5.33192L53.3805 15.5461C53.3805 15.95 53.2943 16.2962 53.1217 16.6136C52.9491 16.931 52.719 17.2196 52.4027 17.4215C52.0863 17.6524 51.7412 17.7966 51.3385 17.8832C50.9358 17.9697 50.4757 17.9697 50.0155 17.912C49.5553 17.8543 49.0951 17.6812 48.6925 17.4792C48.2898 17.2773 47.9159 16.9887 47.6283 16.7002C47.3407 16.3828 47.0819 16.0366 46.9381 15.6615C46.7655 15.2864 46.6792 14.9113 46.708 14.5073L46.8805 6.89001L37.9358 5.50504C37.5044 5.44733 37.073 5.30306 36.6991 5.10109C36.3252 4.89912 36.0089 4.66829 35.7212 4.40861C35.4624 4.12007 35.2323 3.83154 35.1173 3.4853C34.9735 3.16791 34.9159 2.82167 34.9447 2.47542C34.9735 2.12918 35.0885 1.81179 35.2898 1.55211C35.4624 1.29243 35.7212 1.0616 36.0376 0.859629C36.3252 0.686508 36.6991 0.542241 37.073 0.484534C37.4469 0.426827 37.8783 0.426827 38.3097 0.484534L50.1018 2.36001Z"
                        fill="#FE7734"
                    />
                    <path
                        d="M20.9093 0.224845C17.9757 0.59994 15.1571 1.43669 12.5686 2.70624C9.89381 4.03351 7.56416 5.76471 5.63717 7.84216C3.53761 10.1216 1.98451 12.7184 1.0354 15.6326C0.345132 17.7389 0 19.9318 0 22.1246C0 23.1057 0.0575213 24.0578 0.201327 25.0388C0.661504 28.2704 1.81195 31.3866 3.65265 34.272C5.55088 37.2727 8.0531 39.8407 11.1018 41.9181C14.323 44.111 17.9181 45.6402 21.8296 46.4193C25.7412 47.1983 29.6527 47.1983 33.4204 46.3904C37.0155 45.6114 40.2655 44.1976 43.1128 42.1201C45.8739 40.1292 48.0597 37.6478 49.6128 34.7913C51.1659 31.9348 52 28.8475 52.1151 25.6159C52.1438 25.0965 52.0288 24.5772 51.8274 24.0867C51.6261 23.625 51.3673 23.1922 51.0221 22.7883C50.677 22.4132 50.2743 22.0669 49.8429 21.8073C49.3827 21.5476 48.8938 21.3456 48.3761 21.259C47.8584 21.1436 47.3407 21.1436 46.8518 21.2302C46.3628 21.3167 45.9027 21.4899 45.5 21.7495C45.0974 22.0092 44.7522 22.3843 44.4934 22.7883C44.2345 23.2211 44.062 23.7116 44.0332 24.2598C43.8894 26.395 43.2566 28.4147 42.1637 30.2325C41.0708 32.0791 39.5752 33.666 37.7058 34.9356C35.7788 36.2628 33.5929 37.1862 31.2058 37.6478C28.6748 38.1672 26.0575 38.1383 23.4403 37.619C20.823 37.0996 18.4071 36.0897 16.2212 34.6759C14.1792 33.3198 12.4535 31.6174 11.1305 29.6843C9.86504 27.7799 9.03097 25.7602 8.65708 23.625C8.28319 21.5187 8.39823 19.4124 8.97345 17.3927C9.54867 15.4306 10.5265 13.6417 11.8783 12.0836C13.1726 10.6121 14.7544 9.3714 16.5664 8.44809C18.3783 7.52478 20.3341 6.91885 22.4049 6.63032C24.5332 6.34178 26.719 6.39949 28.9049 6.80344C29.3938 6.89 29.854 6.91885 30.3429 6.83229C30.8031 6.77458 31.2345 6.63032 31.6372 6.42834C32.0398 6.19752 32.385 5.90898 32.6438 5.59159C32.9314 5.2165 33.104 4.7837 33.1903 4.32204C33.2766 3.86038 33.219 3.39873 33.0752 2.96593C32.9314 2.56198 32.7013 2.18688 32.4137 1.84064C32.1261 1.52325 31.781 1.23472 31.3783 1.00389C30.9757 0.773061 30.5442 0.628794 30.0553 0.542234C26.9779 -0.0636895 23.9004 -0.150251 20.9093 0.224845Z"
                        fill="url(#paint0_linear_7801_2831)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_7801_2831"
                            x1="9.04127"
                            y1="40.5618"
                            x2="40.12"
                            y2="9.5826"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#B3B3B3" />
                            <stop offset="0.0959349" stop-color="#C4C4C4" />
                            <stop offset="0.2515" stop-color="#D8D8D8" />
                            <stop offset="0.4285" stop-color="#E6E6E6" />
                            <stop offset="0.6431" stop-color="#EEEEEE" />
                            <stop offset="1" stop-color="#F1F1F1" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div
                *ngIf="!recoverPass && !hasRecoveryCode"
                class="flex flex-col gap-5 mt-8"
            >
                <h1 class="flex flex-center text-2xl font-bold">
                    Informe o Tipo de Acesso
                </h1>
                <p class="flex flex-center text-xl">
                    Faça seu login para ter acesso aos Resultados
                </p>
                <div class="padd-column">
                    <ng-container
                        *ngFor="let item of listAccess; let ind = index"
                    >
                        <div class="w-100 flex flex-column edge">
                            <span
                                tabindex="1"
                                class="padd-access"
                                (click)="chooseAccessType(item, ind)"
                                [style.backgroundColor]="
                                    selectedOptionIndex === ind
                                        ? '#FF6344'
                                        : 'white'
                                "
                                [style.color]="
                                    selectedOptionIndex === ind
                                        ? '#ffffff'
                                        : '#404040'
                                "
                            >
                                {{ item.description }}
                            </span>
                        </div>
                    </ng-container>
                </div>
                <div class="flex flex-column gap-8 mt-4">
                    <forms-input-text
                        [placeholder]="'Usuário'"
                        [form]="form.controls.accessCode"
                    ></forms-input-text>
                    <forms-input-text
                        [placeholder]="'Senha'"
                        type="password"
                        [form]="form.controls.password"
                        (keydown.enter)="login()"
                    ></forms-input-text>
                    <forms-input-text
                        *ngIf="customerAccess"
                        [placeholder]="'Data de Nascimento'"
                        type="date"
                        [form]="form.controls.birth"
                        (keydown.enter)="login()"
                    ></forms-input-text>
                    <forms-input-text
                        *ngIf="needTokenValidation"
                        [placeholder]="'Token de Acesso'"
                        [form]="form.controls.accessToken"
                    ></forms-input-text>
                </div>
                <div class="flex flex-center">
                    <button (click)="login()">Login</button>
                </div>
                <div>
                    <span
                        class="flex flex-center under orangeFont text-base"
                        (click)="back()"
                        >Esqueci minha senha</span
                    >
                </div>
            </div>
            <div
                class="flex flex-column flex-center"
                *ngIf="recoverPass && !hasRecoveryCode"
            >
                <h1 class="flex flex-center text-2xl font-bold mt-8">
                    Esqueci minha senha
                </h1>
                <p class="flex flex-center font text-xl text-center mt-8">
                    Confirme seu Código de Acesso<br />Após a confirmação, um
                    Token / Código de Validação será enviado ao celular
                    cadastrado.
                </p>
                <forms-input-text
                    class="mt-8"
                    [placeholder]="'Código de Acesso'"
                    [form]="recoverAccess"
                    (keydown.enter)="recover()"
                ></forms-input-text>
                <div class="flex flex-center mt-8">
                    <button (click)="recover()">Enviar</button>
                </div>
                <span
                    class="flex flex-center orangeFont under mt-8"
                    (click)="recoveryCode()"
                    >Já possuo um Token / Código de Validação</span
                >
                <span
                    class="flex flex-center orangeFont under mt-8"
                    (click)="back()"
                    >Voltar</span
                >
            </div>
            <div
                class="flex flex-column flex-center"
                *ngIf="recoverPass && hasRecoveryCode"
            >
                <h1 class="flex flex-center text-2xl font-bold">
                    Código de Validação
                </h1>
                <p class="flex flex-center text-xl mt-8">
                    Informe o Token recebido por SMS.<br />Preencha os dados
                    abaixo para trocar sua senha.
                </p>
                <div class="mt-8 flex flex-column gap-3">
                    <div class="gap-8 mt-4">
                        <forms-input-text
                            [placeholder]="'Token'"
                            [form]="formRecover.controls.token"
                        ></forms-input-text>
                        <div
                            style="margin-top: 5px"
                            *ngIf="(formRecover.controls.token.dirty || formRecover.controls.token.touched) && formRecover.controls.token.errors?.['required']"
                        >
                            <span class="error"
                                >O Token é obrigatório e deve ser
                                informado!</span
                            >
                        </div>
                    </div>
                    <div class="gap-8 mt-4">
                        <forms-input-text
                            [placeholder]="'Código de Acesso'"
                            [form]="formRecover.controls.accessCode"
                        ></forms-input-text>
                        <div
                            style="margin-top: 5px"
                            *ngIf="(formRecover.controls.accessCode.dirty || formRecover.controls.accessCode.touched) && formRecover.controls.accessCode.errors?.['required']"
                        >
                            <span class="error"
                                >O Código de Acesso é obrigatório e deve ser
                                informado!</span
                            >
                        </div>
                    </div>
                    <div class="gap-8 mt-4">
                        <forms-input-text
                            type="password"
                            [placeholder]="'Nova Senha'"
                            [form]="formRecover.controls.newPassword"
                            (keydown.enter)="recover(true)"
                        ></forms-input-text>
                        <div
                            style="margin-top: 5px"
                            *ngIf="(formRecover.controls.newPassword.dirty || formRecover.controls.newPassword.touched) && formRecover.controls.newPassword.errors?.['required']"
                        >
                            <span class="error"
                                >A Nova Senha é obrigatória e deve ser
                                informada!</span
                            >
                        </div>
                    </div>
                    <div class="gap-8 mt-4">
                        <forms-input-text
                            type="password"
                            [placeholder]="'Confirmar Senha'"
                            [form]="formRecover.controls.confirmPassword"
                            (keydown.enter)="recover(true)"
                        ></forms-input-text>
                        <div
                            style="margin-top: 5px"
                            *ngIf="(formRecover.controls.confirmPassword.dirty || formRecover.controls.confirmPassword.touched) && formRecover.controls.confirmPassword.errors?.['required']"
                        >
                            <span class="error"
                                >A Confirmação da Senha é obrigatória e deve ser
                                informada!</span
                            >
                        </div>
                    </div>
                    <div class="flex flex-center mt-8">
                        <button (click)="recover(true)">Alterar Senha</button>
                    </div>
                </div>
                <span
                    class="flex flex-center orangeFont under mt-8"
                    (click)="back(true)"
                    >Voltar</span
                >
            </div>
        </div>
    </div>
</div>
<div class="flex flex-end">
    <a href="https://concentsistemas.com.br/" target="_blank">
        © 2024 - Concent Sistemas - Todos os direitos reservados
    </a>
</div>
