<div class="relative main">
    <mat-select
        style="color: #808080"
        [formControl]="form"
        [placeholder]="placeholder | translate"
        (selectionChange)="onUserChange($event)"
    >
        <mat-option *ngIf="allowToEraseValue" class="opacity-60">{{
            "global.none" | translate
        }}</mat-option>
        <mat-option *ngFor="let option of options" [value]="option.value">
            {{ option.label | translate }}
        </mat-option>
    </mat-select>
    <h3 class="absolute" [ngClass]="{ 'label-up': form.value != null }">
        {{ placeholder | translate }}
    </h3>
    <span class="underline"></span>
    <!-- <div
        class="w-100 absolute flex justify-end"
        *ngIf="form.invalid && form.dirty"
    >
        <span class="text-[#eb5757]">{{
            form.errors | errorTranslate : form.value
        }}</span>
    </div> -->
</div>
