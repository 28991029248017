import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http.service';
import { BaseService } from '@app/services/base.service';
import { HttpResponsePageable } from '@app/services/services.types';
import { Guid } from '@app/util/guid';
import { GetAllPlans, GetByIdPlans } from './agreement-plan.types';

@Injectable({
  providedIn: 'root'
})
export class AgreementPlanService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/agreementplan');
  }

  override get(queryString: string) {
    return super.get(queryString) as Promise<HttpResponsePageable<GetAllPlans>>
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllPlans>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetAllPlans>>
  }

  override getById(id: Guid) {
    return super.getById(id) as Promise<HttpResponsePageable<GetByIdPlans>>
  }
}