import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { MethodologyService } from '@app/services/methodology/methodology.service';
import { Guid } from '@app/util/guid';
import { GetMethodologies, ResolveMethodologies, Actions } from './methodologies.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { DialogComponent } from '@app/components/form/dialog/dialog.component';
import { ToastService } from '@app/services/translate/toast.service';

@Component({
  selector: 'app-methodologies',
  templateUrl: './methodologies.component.html',
  styleUrls: ['./methodologies.component.scss']
})
export class MethodologiesComponent implements OnInit {
  total: number = 0;
  page: number = 1;
  index: number = 50;
  getParameters: string = (`?page=${this.page}&index=${this.index}`);
  methodologyId: Guid = new Guid(Guid.getEmpty());
  filter = new UntypedFormControl();

  editId: string = ''

  @ViewChild(DialogComponent) dialog!: DialogComponent

  methodology: Array<ResolveMethodologies> = [];
  resolveMethodology = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'methodologies.name',
      retrive: 'name',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'methodologies.createdAt',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'methodologies.updatedAt',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ];

  form = {
    methodologyName: new UntypedFormControl(null),
    isActive: new UntypedFormControl(false),
  }

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  constructor(
    private _methodologyService: MethodologyService,
    private _dialog: MatDialog,
    private _createdAt: LocalaDatePipe,
    private _updatedAt: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _toastService: ToastService
  ) { }

  async ngOnInit() {
    await this.get();
  }

  async post() {
    let obj = {
      name: this.form.methodologyName.value
    }
    await this._methodologyService.post(obj).then((res) => {
      if (res.success) {
        this.get();
        this._toastService.fireSuccess('methodologies.msgPost')
        this._dialog.closeAll()
      }
    }).catch(
      (err) => {
        this._toastService.fireError(err);
      });
  }


  async put() {
    let obj = {
      name: this.form.methodologyName.value
    }
    await this._methodologyService.put(obj, new Guid(this.editId)).then(
      (res) => {
        this.get();
        this._toastService.fireSuccess('methodologies.msgPut')
        this._dialog.closeAll()
      }).catch(
        (err) => {
          this._toastService.fireError(err);
        });
  }

  delete(id: Guid) {
    this._toastService.fireConfirmation("methodologies.msgDelete").then(
      async (resposta) => {
        if (resposta.isConfirmed) {
          await this._methodologyService.delete(id).then(
            async (res) => {
              if (res.success) {
                await this.get();
                this._toastService.fireSuccess("methodologies.msgDeleteSuccess");
              }
            });
        }
      }).catch(
        (err) => {
          this._toastService.fireError(err);
        });
  }


  resetForms() {
    this.form.methodologyName.setValue('');
  }

  async get(paramPage?: number[]) {
    this.methodology = [];
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let Search: string = this.filter.value ? this.filter.value : null;

    let isActive: string = this.form.isActive.value ? this.form.isActive.value : null;

    await this._methodologyService.getPagination({ page: this.page, index: this.index, isActive, Search }).then(
      (res: any) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolve(res.data, this.resolveMethodology);
        }
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.methodology = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.methodology.push(obj);
    });
  }

  getCreated(item: GetMethodologies) {
    return this._createdAt.transform(item.createdAt);
  }

  getUpdated(item: GetMethodologies) {
    return this._updatedAt.transform(item.updatedAt);
  }

  getActive(item: GetMethodologies) {
    return this._activePipe.transform(item.isActive)
  }

  close() {
    this.methodologyId = new Guid(Guid.getEmpty());
    this._dialog.closeAll();
  }

  Actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.editId = emit.object.id.toString() ?? ''
        this.dialog.openDialogModal(true, emit);
        this.form.methodologyName.setValue(emit.object['methodologies.name'])
        break;
      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
      case 'Excluir':
        this.delete(emit.object.id)
        this.get();
        break;
    };
  }

  putActive(id: Guid, value: string) {
    this._methodologyService.patchActive(id.toString(), value).then((x: any) => {
      this._toastService.fireSuccess('global.msgSituationSuccess')
      this.get();
    }).catch(err => this._toastService.fireError(err))
  }

  resetFilter() {
    this.filter.reset();
    this.form.isActive.reset();
  }

  afterClose() {
    this.resetForms()
    this.editId = ''
  }
}
