import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { GroupProductService } from '@app/services/stock/group-product.service';
import { ProductService } from '@app/services/stock/product';
import { UnitMeasureService } from '@app/services/stock/unit-measure.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Get, GetSubGroup, ObjectItens, Actions } from './product.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @ViewChild('openModal')
  openModal!: TemplateRef<any>

  editId: string = '';

  total: number = 0;
  page: number = 1;
  index: number = 50;

  itens: Array<object> = []

  filter = {
    Search: new UntypedFormControl(),
    isActive: new UntypedFormControl(false)
  }

  form = {
    group: new UntypedFormControl(null),
    subGroup: new UntypedFormControl(null),
    unitMeasurement: new UntypedFormControl(null),
    type: new UntypedFormControl(null),
    description: new UntypedFormControl(null),
    file: new UntypedFormControl(null),
    fileName: new UntypedFormControl(null),
    active: new UntypedFormControl(false)
  }

  resolveProduct: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: 'Descrição',
      method: '',
      retrive: 'description',
      after: '',
      before: ''
    },
    {
      label: 'Unidade de Medida',
      method: '',
      retrive: 'name',
      after: '',
      before: ''
    },
    {
      label: 'Tipo',
      method: 'getType',
      retrive: '',
      after: '',
      before: ''
    },
    {
      label: "Grupo",
      method: '',
      retrive: 'groupDescription',
      after: '',
      before: '',
    },
    {
      label: 'SubGrupo',
      method: '',
      retrive: 'subGroupDescription',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      method: 'getDate',
      retrive: '',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      method: 'getDate',
      retrive: '',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      method: 'getActive',
      retrive: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_unitMeasureId',
      method: '',
      retrive: 'unitMeasureId',
      after: '',
      before: ''
    },
    {
      label: '_hide_typeProduct',
      method: '',
      retrive: 'typeProduct',
      after: '',
      before: ''
    },
    {
      label: '_hide_description',
      method: '',
      retrive: 'description',
      after: '',
      before: ''
    }
    ,
    {
      label: '_hide_groupProductId',
      method: '',
      retrive: 'groupProductId',
      after: '',
      before: ''
    }
    ,
    {
      label: '_hide_subGroupProductId',
      method: '',
      retrive: 'subGroupProductId',
      after: '',
      before: ''
    }
  ]

  typeOptions: Array<{ label: string, value: number }> = [
    {
      label: 'Mercadoria para revenda',
      value: 1
    },
    {
      label: 'Materia Prima',
      value: 2
    },
    {
      label: 'Serviço',
      value: 3
    },
    {
      label: 'Material de Uso e Consumo',
      value: 4
    },
    {
      label: 'Vacina',
      value: 5
    },
  ];

  unitMeasureServiceOption: Array<object> = []

  groupOption: Array<object> = []

  subGroupMaterialOption: Array<object> = []

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  constructor(
    private _dialog: MatDialog,
    private _groupProductService: GroupProductService,
    private _unitMeasureService: UnitMeasureService,
    private _productService: ProductService,
    private _dataPipe: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe
  ) { }

  getGroup() {
    this._groupProductService.getResume().then((res) => {
      res.data.forEach((label: any) => {
        this.groupOption.push({
          label: label.description,
          value: label.id
        })
      })
    })
  }

  async getSubGroup(id: Guid) {
    this.subGroupMaterialOption = []
    const group = await this._groupProductService.getById(id) as { data: [] }
    group.data.forEach((sub: GetSubGroup & { subGroupProduct: GetSubGroup[] }) => {
      sub.subGroupProduct.forEach((info: GetSubGroup) => {
        this.subGroupMaterialOption.push({
          label: info.description,
          value: info.id
        })
      });
    })
  }

  getUnitMeasure() {
    this._unitMeasureService.getResume().then((res) => {
      res.data.forEach((info: any) => {
        this.unitMeasureServiceOption.push({
          label: info.name,
          value: info.id
        })
      })
    })
  }

  ngOnInit() {
    this.get()
    this.getGroup()
    this.getUnitMeasure()
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let search: string = this.filter.Search.value ? this.filter.Search.value : null;

    let isActive = this.filter.isActive.value ? this.filter.isActive.value : null;

    await this._productService.getPagination({ page: this.page, index: this.index, search, isActive }).then(
      (res: any) => {
        this.itens = [];
        this.total = res.sumRecords;
        if (res.data.length > 0) {
          this.resolve(res.data, this.resolveProduct);
        }
      }
    ).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  getActive(item: Get) {
    return this._activePipe.transform(item.isActive)
  }

  getDate(item: Get) {
    return this._dataPipe.transform(item.updatedAt);
  }

  getType(item: Get) {
    let type = []
    type[1] =`<span class="span-styles" style="background:#285e95; color:#FFF;">Mercadoria para revenda</span>`;
    type[2] =`<span class="span-styles" style="background:#62997a; color:#FFF;">Materia Prima</span>`;
    type[3] =`<span class="span-styles" style="background:#b25ffc; color:#FFF;">Serviço</span>`;
    type[4] =`<span class="span-styles" style="background:#44aad9; color:#FFF;">Material de Uso e Consumo</span>`;
    type[5] =`<span class="span-styles" style="background:#b14a46; color:#FFF;">Vacina</span>`;

    return type[item.typeProduct]
  }

  resolve(data: any, columns: any) {
    this.itens = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.itens.push(obj);
    });
  }

  post() {
    let obj = {
      groupProductId: this.form.group.value,
      subGroupProductId: this.form.subGroup.value ?? null,
      unitMeasureId: this.form.unitMeasurement.value,
      typeProduct: JSON.parse(this.form.type.value),
      description: this.form.description.value,
      fileImageId: this.form.file.value,
    }
    this._productService.post(obj).then((res) => {
      SwAlSetttings.Sucesso('Produto Cadastrado!')
      this.get()
      this.closeDialog()
    }).catch(err => SwAlSetttings.printError(err))
  }

  openDialogModal(id?: string) {
    this.editId = id ?? ''

    const myTempDialog = this._dialog.open(this.openModal, {
      width: '660px'
    });
    myTempDialog.afterClosed().subscribe((res) => {
      this.resetForms()
      this.editId = ''
    })
  }

  closeDialog() {
    this._dialog.closeAll();
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.openDialogModal(emit.object.id.toString())
        this.setValueOnForms(emit.object)
        break;
      case 'Excluir':
        this.delete(emit.object.id.toString())
        break;
      case 'PutActive':
        this.putActive(emit.object.id.toString(), this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  putActive(id: string, value: string) {
    this._productService.patchActive(id, value).then((x: any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.get();
    }).catch(err => SwAlSetttings.printError(err))
  }

  async setValueOnForms(itens: ObjectItens) {
    await this.getSubGroup(itens._hide_groupProductId)
    this.form.group.setValue(itens._hide_groupProductId)
    this.form.subGroup.setValue(itens._hide_subGroupProductId)
    this.form.unitMeasurement.setValue(itens._hide_unitMeasureId)
    this.form.type.setValue(itens._hide_typeProduct)
    this.form.description.setValue(itens._hide_description)
    this.form.active.setValue(itens.Ativo.includes('active') ? true : false)
    this.form.fileName.setValue(itens.fileName)
  }

  delete(idToDelete: string) {
    SwAlSetttings.ALERT_CONFIRM.fire('Deseja excluir o Produto?').then(
      async (r) => {
        if (r.isConfirmed) {
          this._productService.delete(new Guid(idToDelete)).then((res: any) => {
            SwAlSetttings.Sucesso('Produto Excluído!')
            this.get()
            this.closeDialog()
          })
        }
      }
    ).catch(err => SwAlSetttings.printError(err))
  }

  resetForms() {
    this.form.group.setValue(null)
    this.form.subGroup.setValue(null)
    this.form.unitMeasurement.setValue(null)
    this.form.type.setValue(null)
    this.form.description.setValue(null)
    this.form.active.setValue(false)
    this.form.file.setValue(null)
    this.form.fileName.setValue(null)
  }

  put() {
    let obj = {
      groupProductId: this.form.group.value,
      subGroupProductId: this.form.subGroup.value ?? null,
      unitMeasureId: this.form.unitMeasurement.value,
      typeProduct: JSON.parse(this.form.type.value),
      description: this.form.description.value,
      fileImageId: this.form.fileName.value,
      isActive: this.form.active.value
    }
    this._productService.put(obj, new Guid(this.editId)).then((res: any) => {
      SwAlSetttings.Sucesso('Produto Editado!')
      this.get()
      this.closeDialog()
    }).catch(err => SwAlSetttings.printError(err))
  }

  resetFilter(){
    this.filter.Search.reset();
    this.filter.isActive.reset();
  }

}