import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AdminBaseAuthService } from "./base-auth.service";
import { AdminHttpAuthService } from './http-auth.service';

@Injectable({
    providedIn: 'root'
})
export class AdminAccesscontrolUserAuth extends AdminBaseAuthService {

    public usersBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    constructor(HttpAuth: AdminHttpAuthService) {
        super(HttpAuth, 'v1/accesscontrol/user');
    }

    loadUsers(): void {
        if (this.usersBehaviorSubject.value.length === 0) {
            this.getAll(localStorage.getItem('TenantId') || undefined).then((res: any) => {
                this.usersBehaviorSubject.next(res.data);
            });
        }
    }

    getUserByPersonId(personId: string): any {
        return this.usersBehaviorSubject.value.find((user: any) => user.personId === personId);
    }

    getImageByPersonId(personId: string): string | undefined {
        const user = this.getUserByPersonId(personId);
        let base64 = '';

        if (user && user.imageBase64) {
            base64 = `data:image/png;base64,${user.imageBase64[0]}`;
        }

        return base64 !== 'data:image/png;base64,' ? base64 : undefined;
    }

    getPaginationUsersWithTenant(page: number, numberRegistry: number, tenantId: string, search?: string, type?: number): Promise<Object> {
        const queryParams = `?page=${page}&numberRegistry=${numberRegistry}&search=${search}&type=${type}`;
        return this.HttpAuthService.get(queryParams, `api/${this.path}`, tenantId);
    }
}
