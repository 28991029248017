import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RegistrationReportComponent } from '@app/components/form/registration-report/registration-report.component';
import { DefaultFormats, LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { ProductService } from '@app/services/stock/product';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Util } from '@app/util/util';
import { HeaderMapping } from '@app/modules/reports/reports-model';
import { HttpClient } from '@angular/common/http';
import { TenantAuthService } from '@app/services/auth/tenantAuth/tenant-auth.service';

@Component({
  selector: 'app-product-report',
  templateUrl: './product-report.component.html',
  styleUrls: ['./product-report.component.scss']
})
export class ProductReportComponent implements OnInit {

  filter = new UntypedFormGroup({
    description: new UntypedFormControl(null),
    type: new UntypedFormControl(null),
    isActive: new UntypedFormControl(null)
  })

  isActiveOptions: Array<{ label: string, value: string | null | boolean }> = [{ label: 'Todos', value: null }, { label: 'Somente Ativos', value: true }]

  index: number = 50
  page: number = 1
  sumRecords: number = 0

  rawData: Array<any> = []
  resolvedProducts: Array<any> = []

  filterTypeOptions: Array<{ label: string, value: number | null }> = [
    {
      label: 'Todos',
      value: null
    },
    {
      label: 'Mercadoria para revenda',
      value: 1
    },
    {
      label: 'Materia Prima',
      value: 2
    },
    {
      label: 'Serviço',
      value: 3
    },
    {
      label: 'Material de Uso e Consumo',
      value: 4
    },
    {
      label: 'Vacina',
      value: 5
    },
  ];

  typeOptions: Array<{ label: string, value: number }> = [
    {
      label: 'Mercadoria para revenda',
      value: 1
    },
    {
      label: 'Materia Prima',
      value: 2
    },
    {
      label: 'Serviço',
      value: 3
    },
    {
      label: 'Material de Uso e Consumo',
      value: 4
    },
    {
      label: 'Vacina',
      value: 5
    },
  ];

  resolveProducts: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: 'Descrição',
      method: '',
      retrive: 'description',
      after: '',
      before: ''
    },
    {
      label: 'Unidade de Medida',
      method: '',
      retrive: 'name',
      after: '',
      before: ''
    },
    {
      label: 'Tipo',
      retrive: '',
      method: 'formatField',
      fieldName: 'typeProduct',
      type: 'customType',
      options: this.typeOptions
    },
    {
      label: "Grupo",
      method: '',
      retrive: 'groupDescription',
      after: '',
      before: '',
    },
    {
      label: 'SubGrupo',
      method: '',
      retrive: 'subGroupDescription',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'formatField',
      fieldName: 'createdAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'formatField',
      fieldName: 'updatedAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'formatField',
      fieldName: 'isActive',
      type: 'boolean'
    }
  ]



  constructor(
    private _productService: ProductService,
    private _util: Util,
    private _http: HttpClient,
    private _localeDate: LocalaDatePipe,
    private _tenantAuth: TenantAuthService,) { }

  ngOnInit(): void {
    this.get();
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    const filterObject = {
      isActive: this.filter.controls['isActive'].value ? JSON.parse(this.filter.controls['isActive'].value) : null,
      numberRegistry: this.index,
      page: this.page,
      search: this.filter.controls['description'].value ?? null,
      typeProduct: this.filter.controls['type'].value ?? null
    };

    await this._productService.getPagination<any>(filterObject).then(
      (res) => {
        if (res.data.length != 0) {
          this.sumRecords = res.sumRecords;
          this.rawData = res.data;
          this.resolvedProducts = this.resolve(res.data, this.resolveProducts);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  private resolve(data: any, columns: any) {
    let dataList: Array<Object> = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat, y.options);
        } else {
          obj[y.label] = x[y.retrive.toString()];
        }
      });
      dataList.push(obj);
    });
    return dataList
  }

  getType(item: any) {
    let info: Array<typeof this.typeOptions[0]> = this.typeOptions.filter((x: typeof this.typeOptions[0]) => {
      if (x.value == item.typeProduct) {
        return x.label
      }
      return []
    })
    return info[0].label
  }

  async getDataForPrint() {
    const filterObject = {
      isActive: this.filter.controls['isActive'].value ? JSON.parse(this.filter.controls['isActive'].value) : null,
      search: this.filter.controls['description'].value ?? null,
      typeProduct: this.filter.controls['type'].value ?? null
    };

    return this._productService.getPagination<any>(filterObject).then((res: any) => {
      if (res.success) {
        return res.data
      }
    })
  }

  async generatePdf() {

    const data = await this.getDataForPrint()
    const resolvedData: Array<any> = this.resolve(data, this.resolveProducts)

    const reportComponent = new RegistrationReportComponent(this._http, this._localeDate, this._tenantAuth);

    const headerMapping: HeaderMapping = {
      description: { title: 'Descrição', width: '*' },
      name: { title: 'Unidade de Medida', width: 'auto' },
      typeProduct: { title: 'Tipo', width: 'auto' },
      isActive: { title: 'Ativo', width: 'auto' },
      createdAt: { title: 'Criado em', width: 'auto' },
      updatedAt: { title: 'Atualizado em', width: 'auto' }
    };

    const resolvedHeaderMapping: HeaderMapping = {
      ['Descrição']: { title: 'Descrição', width: '*' },
      ['Unidade de Medida']: { title: 'U.M', width: 'auto' },
      ['Tipo']: { title: 'Tipo', width: 'auto' },
      ['Grupo']: { title: 'Grupo', width: 'auto' },
      ['SubGrupo']: { title: 'SubGrupo', width: 'auto' },
      ['Criado em']: { title: 'Criado em', width: 'auto' },
      ['Alterado em']: { title: 'Atualizado em', width: 'auto' },
      ['Ativo']: { title: 'Ativo', width: 'auto' },
    };

    let resolvedProductsToPrint: Array<Object> = []
    resolvedData.forEach((x) => {
      const obj = { ...x }
      obj['Ativo'] = obj['Ativo'].includes('Sim') ? 'Ativo' : 'Inativo'
      resolvedProductsToPrint.push(obj)
    })

    var filters = [];
    var filterActive = 'Todos';
    if (this.filter.controls['isActive'].value != null) {
      if (this.filter.controls['isActive'].value == 'true') {
        filterActive = 'Somente Ativos';
      } else {
        filterActive = 'Somente Inativos';
      }
    }

    var obj = {
      title: 'Ativos: ', content: filterActive
    }

    filters.push(obj);

    if (this.filter.controls['type'].value != 'todos') {
      var filterActive = 'Todos';
      switch (this.filter.controls['type'].value) {
        case '1':
          filterActive = 'Mercadoria para revenda'
          break;

        case '2':
          filterActive = 'Materia Prima'
          break;

        case '3':
          filterActive = 'Serviço'
          break;

        case '4':
          filterActive = 'Material de Uso e Consumo'
          break;

        case '5':
          filterActive = 'Vacina'
          break;
      }
      var obj = {
        title: 'Tipo: ', content: filterActive
      }

      filters.push(obj);
    }

    if (this.filter.controls['description'].value) {
      var obj2 = {
        title: 'Descrição: ', content: this.filter.controls['description'].value
      }

      filters.push(obj2);
    }

    reportComponent.reportFilters = [filters];
    reportComponent.headerMapping = resolvedHeaderMapping;
    reportComponent.receivedData = resolvedProductsToPrint;
    reportComponent.reportDescription = 'Relatório de Cadastro de Produtos';
    reportComponent.sumRecords = this.sumRecords;

    await reportComponent.ngOnInit();
  }

  resetFilter() {
    this.filter.controls['description'].reset();
    this.filter.controls['type'].reset(null);
    this.filter.controls['isActive'].reset(null);
  }
}
