import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UnityService } from '@app/services/auth/unity/unity-service.service';
import { CollectionPlaceService } from '@app/services/collection-places/collection-place.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import './collections-place.model';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { Actions, ObjectActions, ResolveUnity } from './collections-place.model';

@Component({
  selector: 'app-collection-place',
  templateUrl: './collection-place.component.html',
  styleUrls: ['./collection-place.component.scss']
})
export class CollectionPlaceComponent implements OnInit {

  @ViewChild('dialogModalUnity')
  dialogModalUnity!: TemplateRef<any>

  total: number = 0;
  page: number = 1;
  index: number = 50;

  unityRegisters: Array<object> = []

  unityIdToEdit: string = '';
  localIdToEdit: string = '';

  formFilter = {
    Search: new UntypedFormControl(),
    actives: new UntypedFormControl()
  }

  unity = {
    active: new UntypedFormControl(false),
    description: new UntypedFormControl()
  }

  collectionPlaceForm = new UntypedFormGroup({
    description: new UntypedFormControl(null),
    externalId: new UntypedFormControl(null),
    address: new UntypedFormControl(null),
  });

  resolveUnitys = [//externalId
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_externalId',
      retrive: 'externalId',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Unidade',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ]

  constructor(
    private _unityService: UnityService,
    private _dialog: MatDialog,
    private _localaDatePipe: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe
  ) { }

  ngOnInit() {
    this.getUnity();
  }

  async getUnity(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let description: string = this.formFilter.Search.value ? this.formFilter.Search.value : null;

    let isActive = this.formFilter.actives.value ? this.formFilter.actives.value : null;

    await this._unityService.getPagination({ page: this.page, index: this.index, description, isActive }).then(
      (res) => {
        this.total = res.sumRecords;
        this.unityRegisters = this.resolve(res.data, this.resolveUnitys);
      }
    ).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    let arrayToFilter: Array<object> = []
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      arrayToFilter.push(obj);
    });

    return arrayToFilter
  }

  postUnity() {
    const obj = {
      description: this.collectionPlaceForm.controls['description'].value,
      externalId: this.collectionPlaceForm.controls['externalId'].value,
    }

    this._unityService.post(obj).then((x: any) => {
      this.getUnity();
      SwAlSetttings.Sucesso('Unidade cadastrada!');
      this.closeModal();
    }).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  putUnity() {
    const obj = {
      description: this.collectionPlaceForm.controls['description'].value,
      externalId: this.collectionPlaceForm.controls['externalId'].value,
    }

    this._unityService.put(obj, new Guid(this.unityIdToEdit)).then((x: any) => {
      SwAlSetttings.Sucesso('Unidade cadastrada!');
      this.getUnity();
      this.closeModal();
    }).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  putActive(id: string, value: string) {
    this._unityService.patchActive(id, value).then((res: any) => {
      if (res.success) {
        SwAlSetttings.Sucesso('Situação alterada!');
        this.getUnity();
      }
    })
  }

  closeModal() {
    this._dialog.closeAll();
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.openDialogModalUnity(emit.object, emit.action);
        break;
      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break
    }
  }

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  openDialogModalUnity(itensToEdit?: ResolveUnity, action?: string) {
    if (itensToEdit) {
      this.unityIdToEdit = itensToEdit.id;
      this.unity.description.setValue(itensToEdit.Unidade)
    }

    if (action === 'Editar') {
      this.collectionPlaceForm.controls['externalId'].setValue(itensToEdit?._hide_externalId)
      this.collectionPlaceForm.controls['description'].setValue(itensToEdit?.Unidade)
    }

    const myTempDialog = this._dialog.open(this.dialogModalUnity, {
      width: '700px'
    });

    myTempDialog.afterClosed().subscribe((res) => {
      this.unityIdToEdit = '';
      this.unity.description.setValue('');
      this.collectionPlaceForm.reset();
    });
  }

  getActive(item: ObjectActions) {
    return this._activePipe.transform(item.isActive)
  }

  getCreated(item: ObjectActions) {
    return this._localaDatePipe.transform(item.createdAt);
  }

  getUpdated(item: ObjectActions) {
    return this._localaDatePipe.transform(item.updatedAt);
  }

  resetFilter() {
    this.formFilter.Search.reset();
    this.formFilter.actives.reset();
  }

}