import { Pipe, PipeTransform, inject } from "@angular/core";
import { formatDate } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

/**
 * Pipe to transform 'date' in to 'locale date'
 */
@Pipe({
   name: "localeDate",
   standalone: true,
   pure: false
})
export class LocaleDatePipe implements PipeTransform {
   private _translate = inject(TranslateService);
   private locale = "en-US";

   /**
    * @param value Date to be modified
    * @param formatType Standards values, based in uses
    * @param format Custom format, if the 'standards' do not meet the need
    * @returns
    */
   transform(
      value: Date | string | number,
      formatType: DefaultFormats = DefaultFormats.full,
      format?: string
   ): string | null {
      if (value != undefined) {
         if (typeof value === "string") {
            value = new Date(value);
         }
         return formatDate(
            value,
            this.getFormatByType(formatType, format),
            this.locale
         );
      } else {
         return "";
      }
   }

   private getFormatByType(typed: DefaultFormats, format?: string) {
      if (format) {
         return format;
      }
      /**
       * Standards formats
       * @see Custom-formats: https://angular.io/api/common/DatePipe#custom-format-options
       */
      switch (typed) {
         case DefaultFormats.short: {
            return DateLocalize[this._translate.currentLang];
         }
         case DefaultFormats.hours: {
            return "HH:mm:ss";
         }
         case DefaultFormats.full: {
            return DateTimeLocalize[this._translate.currentLang];
         }
      }
   }
}

enum DefaultFormats {
   short = "short",
   hours = "hours",
   full = "full"
}

const DateLocalize: Record<string, string> = {
   "pt-BR": "dd/MM/yyyy",
   es: "yyyy-MM-dd",
   en: "YYYY/MM/dd"
};

const DateTimeLocalize: Record<string, string> = {
   "pt-BR": "dd/MM/yyyy 'às' HH:mm",
   es: "yyyy-MM-dd 'a las' HH:mm",
   en: "yyyy/MM/dd 'at' HH:mm"
};
