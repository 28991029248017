import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import decodedToken from '@app/util/Token';
import {SlideInOutAnimation} from "../../../../animations";
import {UserUtil} from "../../../../util/user";

@Component({
  selector: 'user-menu-admin',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  animations: [SlideInOutAnimation]
})
export class UserMenuAdminComponent implements OnInit {

  @Input() menuuseropen : boolean = false;
  MenuActive : Array<Number> = [];
  @Output() closeMenuEmit : EventEmitter<any> = new EventEmitter();
  timeLogged: any

  constructor(private _userUtil : UserUtil) { }

  ngOnInit(): void {
    this.timeLogged = decodedToken.iat * 1000
  }

  toggleShowDiv(index : Number) {
    const _indexOf = this.MenuActive.indexOf(index);
    if(_indexOf == -1)
      this.MenuActive.push(index);
    else
      this.MenuActive.splice(_indexOf, 1);
  }

  CloseMenu() {
    this.closeMenuEmit.emit({close:true});
  }

  ToggleAdmin() {
    window.location.href = "/";
    localStorage.removeItem('admin');
  }

  Sair() {
    this._userUtil.Sair()
  }

}
