import { CommonModule } from "@angular/common";
import {
   Component,
   ElementRef,
   EventEmitter,
   inject,
   Input,
   OnInit,
   Output
} from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { formFilter } from "@app/modules_new/shared/layouts/content-filter/content-filter.model";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { FilterModalComponent } from "./filter-modal/filter-modal.component";
import { Router } from "@angular/router";

@Component({
   selector: "filter-form",
   templateUrl: "./filter.component.html",
   styleUrls: ["./filter.component.css"],
   standalone: true,
   imports: [CommonModule, MatIconModule, MatTooltipModule, TranslateModule]
})
export class FilterFormComponent implements OnInit {
   constructor() {}

   private _matDialog = inject(MatDialog);
   private _elementRef = inject(ElementRef);
   private _router = inject(Router);
   isAdm: boolean = this._router.url.includes("/Admin") ? true : false;

   @Input() formFilter!: formFilter;

   @Output() clearFilters: EventEmitter<any> = new EventEmitter<any>();
   @Output() searchFilters: EventEmitter<any> = new EventEmitter<any>();
   @Output() clearFiltersPartial: EventEmitter<any> = new EventEmitter<any>();

   mask: string = "";

   ngOnInit() {}

   searchMask(input: any, form: any) {
      input.forEach((input: any) => {
         if (input.value == form)
            input.mask ? (this.mask = input.mask) : (this.mask = "");
      });
      this.resetFiltersPartial();
   }

   resetFiltersPartial() {
      this.clearFiltersPartial.emit();
   }

   clearFiltersEmit() {
      this.clearFilters.emit();
      this.mask = "";
   }

   search() {
      this.searchFilters.emit();
   }

   openModal() {
      const posicao = this.position(this._elementRef.nativeElement);
      const dialog = this._matDialog.open(FilterModalComponent, {
         restoreFocus: false,
         position: {
            top: posicao.botton.toString() + "px",
            right: "calc(50px * var(--proportional))"
         },
         backdropClass: "custom-backdrop",
         panelClass: "custom-dialog",
         data: {
            formFilter: this.formFilter
         }
      });
      dialog.componentInstance.clearFiltersPartial.subscribe(() => {
         this.resetFiltersPartial();
      });
      dialog.componentInstance.clearFilters.subscribe(() => {
         this.clearFiltersEmit();
      });
      dialog.componentInstance.searchFilters.subscribe(() => {
         this.search();
      });
   }

   position(elemento: HTMLElement): { botton: number } {
      const rect = elemento.getBoundingClientRect();
      return { botton: rect.bottom };
   }
}
