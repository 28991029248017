<app-dashboard *ngIf="(!isAdminAccess && wasLogged) && !isRedirecAccess">
  <router-outlet></router-outlet>
</app-dashboard>

<app-dashboard-admin *ngIf="(isAdminAccess && wasLogged) && !isRedirecAccess">
  <router-outlet></router-outlet>
</app-dashboard-admin>

<div *ngIf="loading" class="loading-container flex-content-center">
  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div>

<app-login *ngIf="!wasLogged && !isExternalOrForgotPassword && !isRedirecAccess">
</app-login>

<div *ngIf="(!loggedSession && isExternalOrForgotPassword) || isRedirecAccess">
    <router-outlet></router-outlet>
</div>
