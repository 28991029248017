import { Pipe, PipeTransform } from "@angular/core";
import { FilterRowsRealtime } from "../components/form/table/table.model";

/**
 * Pipe responsável por filtar valores de tabela
 */
@Pipe({
    name: "filterRows",
    standalone: true
})
export class TableRowsFilterPipe implements PipeTransform {
    transform(rows: any[], args: FilterRowsRealtime): any {
        if (!rows) return [];
        if (!args || args.keys.length === 0) return rows;

        const filtered = rows?.filter((item) =>
            this._nestedFilter(item, args.keys, args.value)
        );

        return filtered;
    }

    private _filterDefinitionFn =
        (itemObject: any) => (key: string, value: string) =>
            itemObject[key].toLowerCase().includes(value.toLowerCase());

    private _nestedFilter(item: any, keys: string[], value: string) {
        const filterFn = this._filterDefinitionFn(item);
        return keys.reduce(
            (acc, current) => acc || filterFn(current, value),
            null
        );
    }
}
