import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpService } from "@app/services/http.service";
import { BaseService } from "@app/services/base.service";
import { HttpResponsePageable } from "@app/services/services.types";
import { Guid } from "@app/util/guid";

@Injectable({
   providedIn: "root"
})
export class AnalyteInstructionService extends BaseService {
   constructor(private http: HttpClient, httpService: HttpService) {
      super(httpService, "v1/lab/parameters/analyteinstructions");
   }
}
