import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CustomersService } from "@app/services/customers/customers.service";
import { GetAllCustomers } from "@app/services/customers/customers.types";
import { Guid } from "@app/util/guid";
import { customerResolve, filterOptions } from "./customers.model";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Util } from "@app/util/util";
import { CheckboxSvgPipe } from "@app/pipe/checkbox-Svg.pipe";
import { ToastService } from "@app/services/translate/toast.service";

@Injectable({
  providedIn: 'root',
})
export class CustomerFacade {
  private _registerCustomers: Array<Object> = [];

  private _formGroup = new UntypedFormGroup({
    filter: new UntypedFormControl(null),
    search: new UntypedFormControl(null),
    isActive: new UntypedFormControl(null)
  });

  private _index: number = 50;
  private _page: number = 1;
  private _total: number = 0;

  private _orderBy: { sort: string, order: string } = { sort: '', order: 'asc' }

  private _behaviourSubject = new BehaviorSubject(this._registerCustomers);

  public readonly behaviourSubject: Observable<Array<Object>> = this._behaviourSubject.asObservable();

  constructor(
    private _customerService: CustomersService,
    private _router: Router,
    private _util: Util,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _toastService: ToastService
  ) { }

  //#region Getters and Setters
  public set setIndex(index: number) {
    this._index = index;
  }

  public get getIndex() {
    return this._index;
  }

  public set setPage(page: number) {
    this._page = page;
  }

  public get getPage() {
    return this._page;
  }

  public set setTotal(total: number) {
    this._total = total;
  }

  public get getTotal() {
    return this._total;
  }

  public get getFilterValue() {
    return this._formGroup.controls['filter'].value;
  }

  public set setFilterValue(value: string) {
    this._formGroup.controls['filter'].setValue(value);
  }

  public get getSearchValue() {
    return this._formGroup.controls['search'].value;
  }

  public set setSearchValue(value: string) {
    this._formGroup.controls['search'].setValue(value);
  }

  public get getForm() {
    return this._formGroup;
  }

  public get getOptionsList() {
    return filterOptions;
  }

  public set setDefaultFilter(value: string) {
    this._formGroup.controls['filter'].setValue(value);
  }

  public get getRegisterCustomers() {
    return this._registerCustomers;
  }
  //#endregion

  private resolve(data: any, columns: any) {
    this._registerCustomers = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this._registerCustomers.push(obj);
    });
  }

  navigateToCreateCustomer() {
    this._router.navigateByUrl('Cadastros/Atendimento/Clientes/Novo');
  }

  async getCustomers(paramPage?: number[]) {
    this._registerCustomers = [];
    if (paramPage) {
      this._page = paramPage[0];
      this._index = paramPage[1];
    }

    const filters: {
      page: number,
      numberRegistry: number,
      //isActive: boolean,
      motherName?: string,
      cpf?: string,
      name?: string,
      birthDate?: string,
      isActive?: string,
      orderBy: string
    } = { page: this._page, numberRegistry: this._index, motherName: '', cpf: '', name: '', birthDate: '', orderBy: this._orderBy.sort ? `${this._orderBy.sort},${this._orderBy.order}` : '' }

    switch (this._formGroup.controls['filter'].value) {
      case "CPF":
        filters.cpf = this._formGroup.controls['search'].value;
        break;
      case "Name":
        filters.name = this._formGroup.controls['search'].value;
        break;
      case "birthDate":
        const date = this._formGroup.controls['search'].value?.split('/')
        if (date && date.length == 3 && date[2]) {
          filters.birthDate = new Date(`${date[1]}/${date[0]}/${date[2]}`).toISOString();
        }
        break;
      case "motherName":
        filters.motherName = this._formGroup.controls['search'].value;
        break;
    }
    filters.numberRegistry = this._index;
    filters.page = this._page;
    filters.isActive = this._formGroup.controls['isActive'].value ? JSON.parse(this._formGroup.controls['isActive'].value) : null;

    await this._customerService.getPagination<GetAllCustomers>(filters).then(
      (res) => {
        if (res.data.length > 0) {
          this.setTotal = res.sumRecords;
          this.resolve(res.data, customerResolve);
          this._behaviourSubject.next(this._registerCustomers);
        } else {
          this._toastService.warningMessage("customer.msgNoCustomers");
        }
      }
    ).catch(
      (err) => {
        this._toastService.fireGenericError(err);
      }
    );
  }

  async getSortedPage(event: { label: string, index: number, order: string }) {
    let value: any = customerResolve.find((x: any) => x.label == event.label)

    if (value && value.sort) {
      this._orderBy.sort = value.sort
      this._orderBy.order = event.order
    }
    this.getCustomers()
  }

  customerActions(emit: any) {
    switch (emit.action) {
      // case "Attach":
      //   this._router.navigateByUrl('Cadastros/Atendimento/Clientes/' + emit.object.id + '$View');
      //   break;
      case 'Editar':
        this._router.navigateByUrl('Cadastros/Atendimento/Clientes/' + emit.object.id + '$Edit');
        break;
      case 'Excluir':
        this.deleteCustomer(emit.object.id);
        break;
      case 'PutActive':
        this.putActiveCustomer(emit.object.id, emit.object['Ativo']);
        break
    };
  }

  putActiveCustomer(id: string, value: string) {
    this._customerService.patchActive(id, value.includes('true') ? 'active' : '').then((res: any) => {
      if (res.success) {
        this.getCustomers();
        setTimeout(() => {
        this._toastService.fireSuccess('global.msgSituationSuccess');
      }, 2000);
      }
    })
  }

  async deleteCustomer(id: Guid) {
    this._toastService.fireConfirmation('customer.msgDeleteCustomer').then(
      async (answer) => {
        if (answer.isConfirmed) {
          await this._customerService.delete(id);
          await this.getCustomers();
          this._toastService.fireSuccess('customer.msgDeleteCustomerSuccess');
        }
      }
    ).catch((error) => {
      this._toastService.fireGenericError(error);
    }
    );
  }
}
