import { Injectable } from '@angular/core';
import { AdminHttpAuthService } from '../auth/admin/http-auth.service';
import { AdminBaseAuthService } from '../auth/admin/base-auth.service';
import { Observable, from } from 'rxjs';
import { Guid } from '@app/util/guid';

@Injectable({
    providedIn: 'root'
})
export class RedirectWinService extends AdminBaseAuthService {

    constructor(HttpAuth: AdminHttpAuthService) {
        super(HttpAuth, 'v1/accesscontrol/endpointredirect');
    }

    getMenuByHash(hash: string) {
        return from(super.getById(new Guid(hash))) as Observable<HttpMultipleReponse<{
            id: string,
            isActive: boolean,
            type: number,
            createdAt: string,
            updatedAt: string,
            urlRedirect: string
        }>>
    }
}

// TODO: #847 - Validar/mover para tipos usados
type HttpMultipleReponse<T> = {
    id: string,
    data: Array<T>
    errors: Array<string>
    success: boolean
    sumRecords: number
    totalPagins: number
    httpStatusCode: number
}