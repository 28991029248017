import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { BudgetService } from "@app/services/budget/budget.service";
import { Router } from "@angular/router";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { Guid } from "@app/util/guid";
import {
   ResolveListBudgets,
   Actions,
   resolveBudgets,
   resolvExams,
   Budgets,
   Requests
} from "./budgets.model";
import { RegistrationReportComponent } from "@app/components/form/registration-report/registration-report.component";
import { TenantAuthService } from "@app/services/auth/tenantAuth/tenant-auth.service";
import { HttpClient } from "@angular/common/http";
import { UnityParametersService } from "@app/services/unity-parameters/unity-parameters.service";
import { FileDocumentService } from "@app/services/files/fileDocument.service";
import { BudgetPrintService } from "@app/services/budget-print/budget-print.service";
import { CollectionPlaceService } from "@app/services/collection-places/collection-place.service";
import { ToastService } from "@app/services/translate/toast.service";

@Component({
   selector: "app-budgets",
   templateUrl: "./budgets.component.html",
   styleUrls: ["./budgets.component.scss"]
})
export class BudgetsComponent implements OnInit {
   form = {
      FinalDate: new UntypedFormControl(null),
      InitialDate: new UntypedFormControl(null),
      CustomerName: new UntypedFormControl(null),
      RequisitionNumber: new UntypedFormControl(null),
      searchByRequisitionNumber: new UntypedFormControl(null),
      searchByCustomerName: new UntypedFormControl(null),
      searchByCustomerCPF: new UntypedFormControl(null),
      searchByCustomerBirthDate: new UntypedFormControl(null),
      searchCustomer: new UntypedFormControl(null)
   };
   headerMapping: any = {
      analyteCode: { title: "Código", width: "auto" },
      analyteName: { title: "Descrição", width: "*" },
      billingCode: { title: "Código de Fatura", width: "auto" },
      value: { title: "Valor", width: "auto" },
      deadline: { title: "Previsão", width: "auto" }
   };
   unityOptions: Array<any> = [
      {
         value: null,
         label: "TODAS"
      }
   ];
   total: number = 0;
   page: number = 1;
   index: number = 50;
   getParameters: string = `?page=${this.page}&index=${this.index}`;
   registersBudgets: Array<Budgets> = [];
   registersRequests: Array<Requests> = [];
   registersExams: any = {};
   //#region Filial Variables / Variáveis referentes às Filiais
   collectionPlace: Array<any> = [];
   collectionPlaceIndex: number = 0;
   collectionPlaceId: string = "";
   //#endregion

   formDate = {
      initialDate: new UntypedFormControl(null),
      finalDate: new UntypedFormControl(null)
   };

   ActionsExtra = {
      "N.º": "editBudget",
      Nome: "editCustomer",
      "N.º da Requisição": "editRequisition"
   };

   resolveBudgets = resolveBudgets;
   resolvExams = resolvExams;

   constructor(
      private _budgetService: BudgetService,
      private _budgetPrintService: BudgetPrintService,
      private _router: Router,
      private _localaDatePipe: LocalaDatePipe,
      private _tenantAuth: TenantAuthService,
      private _localeDate: LocalaDatePipe,
      private _http: HttpClient,
      private _unityParametersService: UnityParametersService,
      private _fileDocumentService: FileDocumentService,
      private _collectionPlaceService: CollectionPlaceService,
      private _toastService: ToastService
   ) {}

   async ngOnInit() {
      this.form.InitialDate.setValue(this.incrementDays(new Date(), -30));
      this.form.FinalDate.setValue(new Date());
      await this.getBudgets();
   }

   incrementDays(date: Date, days: number) {
      let dateNow: Date = date;
      let dateMilisseconds = dateNow.setDate(dateNow.getDate() + days);
      return new Date(dateMilisseconds);
   }

   async getBudgets(paramPage?: number[]) {
      let formattedInitialDate = new Date(
         this.form.InitialDate.value
      ).toISOString();
      let formattedFinalDate = new Date(
         this.form.FinalDate.value
      ).toISOString();

      if (paramPage) {
         this.page = paramPage[0];
         this.index = paramPage[1];
      }

      let filters = {
         page: this.page,
         index: this.index,
         isActive: true,
         initialDate: formattedInitialDate,
         lastDate: formattedFinalDate,
         requisitionNumber: null,
         name: this.form.CustomerName.value,
         customerCPF: null,
         birthDate: null,
         number: this.form.RequisitionNumber.value
      };

      await this._budgetService
         .getPagination(filters)
         .then((res) => {
            if (res.data.length != 0) {
               this.total = res.sumRecords;
               this.resolve(res.data, this.resolveBudgets);
            } else {
               this.resolve([], this.resolveBudgets);
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   resolve(data: any, columns: any) {
      this.registersBudgets = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registersBudgets.push(obj);
      });
   }

   resolveRequests(data: any, columns: any) {
      this.registersRequests = [];
      if (data != undefined) {
         data.forEach((x: any) => {
            let obj: any = {};
            columns.forEach((y: any) => {
               if (!y.retrive) {
                  //@ts-ignore
                  obj[y.label] = y.before + this[y.method](x) + y.after;
               } else {
                  obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
               }
            });
            this.registersRequests.push(obj);
         });
      }
      return this.registersRequests;
   }

   resolveExams(data: any, columns: any, id: string) {
      let exams: Array<Object> = [];
      if (data) {
         data.forEach((x: any) => {
            let obj: any = {};
            columns.forEach((y: any) => {
               if (!y.retrive) {
                  //@ts-ignore
                  obj[y.label] = y.before + this[y.method](x) + y.after;
               } else {
                  obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
               }
            });
            exams.push(obj);
         });
      }
      this.registersExams[id] = exams;
   }

   getDate(item: ResolveListBudgets) {
      return this._localaDatePipe.transform(item.createdAt);
   }

   updatedAt(item: ResolveListBudgets) {
      return this._localaDatePipe.transform(item.updatedAt);
   }

   delete(id: string) {
      this._toastService
         .fireConfirmation("budgets.msgDelete")
         .then(async (res) => {
            if (res.isConfirmed) {
               await this._budgetService.delete(new Guid(id));
               this._toastService.fireSuccess("budgets.msgDeleteSuccess");
               await this.getBudgets();
            }
         })
         .catch((error) => {
            this._toastService.fireError(error);
         });
   }

   async actions(emit: any) {
      switch (emit.action) {
         case "editBudget":
            this._router.navigateByUrl(
               "MovimentacaoOld/Recepcao/Orcamentos/" + emit.object.id
            );
            break;
         case "editCustomer":
            //@ts-ignore
            this._router.navigateByUrl(
               "Cadastros/Atendimento/Clientes/" +
                  emit.object._hide_customerId +
                  "$Edit"
            );
            break;
         case "editRequisition":
            //@ts-ignore
            this._router.navigateByUrl(
               "MovimentacaoOld/Recepcao/Requisicoes/" +
                  emit.object._hide_requisitionId
            );
            break;
         case "Editar":
            this._router.navigateByUrl(
               "MovimentacaoOld/Recepcao/Orcamentos/" + emit.object.id
            );
            break;
         case "Excluir":
            this.delete(emit.object.id);
            break;
         case "Imprimir":
            var data: any = {};
            let unityId = "";
            var reportDescription = "";
            var customerName = "";
            var headerImage = "";
            var footerImage = "";
            var imageHeaderId = "";
            var imageFooterId = "";
            await this._budgetPrintService
               .getById(new Guid(emit.object.id))
               .then((res) => {
                  data = res.data[0];
                  unityId = res.data[0].unityId;
                  reportDescription = "Orçamento " + res.data[0].budgetNumber;
                  customerName = res.data[0].customerName;
                  data.budgetRequest.forEach((value1: any) => {
                     value1.budgetRequestAnalytes.forEach((value2: any) => {
                        value2.value =
                           "R$ " +
                           value2.value.toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                           });
                     });
                  });
               })
               .catch((err) => {
                  this._toastService.fireError(err);
               });

            await this._unityParametersService
               .getByPath("", "?UnityId=" + unityId)
               .then((res) => {
                  if (res.data.length > 0) {
                     if (res.data[0].fileHeaderReportId) {
                        imageHeaderId = res.data[0].fileHeaderReportId;
                     }

                     if (res.data[0].fileFooterReportId) {
                        imageFooterId = res.data[0].fileFooterReportId;
                     }
                  }
               })
               .catch((err) => {
                  this._toastService.fireError(err);
               });

            if (imageHeaderId != "") {
               await this._fileDocumentService
                  .getById(new Guid(imageHeaderId))
                  .then((res) => {
                     headerImage =
                        "data:image/" +
                        res.data[0].extension +
                        ";base64," +
                        res.data[0].fileBase64;
                  })
                  .catch((err) => {
                     this._toastService.fireError(err);
                  });
            }

            if (imageFooterId != "") {
               await this._fileDocumentService
                  .getById(new Guid(imageFooterId))
                  .then((res) => {
                     footerImage =
                        "data:image/" +
                        res.data[0].extension +
                        ";base64," +
                        res.data[0].fileBase64;
                  })
                  .catch((err) => {
                     this._toastService.fireError(err);
                  });
            }

            const reportComponent = new RegistrationReportComponent(
               this._http,
               this._localeDate,
               this._tenantAuth
            );
            reportComponent.reportDescription = reportDescription;
            reportComponent.customerName = customerName;
            reportComponent.footerImage = footerImage;
            reportComponent.headerImage = headerImage;
            reportComponent.receivedData = data;
            reportComponent.headerMapping = this.headerMapping;
            reportComponent.budget = true;
            reportComponent.sumRecords = 1;
            await reportComponent.ngOnInit();
            break;
         case "OpenDropdown":
            //@ts-ignore
            this.registersExams[emit.id] = [];
            this._budgetService
               .getById(emit.id)
               .then((res: any) => {
                  //@ts-ignore
                  this.resolveExams(
                     res.data[0].budgetAnalyte,
                     this.resolvExams,
                     emit.id
                  );
               })
               .catch((err) => {
                  this._toastService.fireError(err);
               });
            break;
      }
   }

   async actionsRequest(budgetId: any, emit: Actions) {
      switch (emit.action) {
         case "OpenDropdown":
            //@ts-ignore
            this.requestsExams[emit.id] = this.budgetRequests[budgetId].filter(
               (x: any) => x.id === emit.id
            )[0].budgetRequestAnalytes;
            break;
      }
   }

   clearForms() {
      Object.keys(this.form).forEach((key) => {
         this.form[key as keyof typeof this.form].reset();
      });
      this.form.InitialDate.setValue(this.incrementDays(new Date(), -30));
      this.form.FinalDate.setValue(new Date());
   }

   newBudget() {
      this._router.navigateByUrl("MovimentacaoOld/Recepcao/Orcamentos/Novo");
   }
}
