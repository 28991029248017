<div class="contentPage">
  <div class="flex flex-column">
    <div class="flex align-center w-100 flex-direction-column-mobile">
      <h1>Resultados</h1>      
    </div>    
    <div class="box">
      <div class="flex flex-betweem w-100 gap-3 align-center flex-direction-column-mobile">                
        <div class="w-100" style="padding-top: 1.25em;">
          <input-prop placeholder="Data Inicial" type="datetime-local" [form]="formDate.initialDate"></input-prop>
        </div>
        <div class="w-100" style="padding-top: 1.25em;">
          <input-prop placeholder="Data Final" type="datetime-local" [form]="formDate.finalDate"></input-prop>
        </div>        
        <div class="w-100">                   
          <input-prop type="text" placeholder="Digite aqui para pesquisar" [form]="SearchExam" (change)="getResults()"></input-prop>
        </div>
        <div class="w-100" style="padding-top: 1.25em;">         
          
        </div>
        <div class="w-100" style="padding-top: 1.25em;">         
          
        </div>
        <button class="btn" (click)="getResults()">Filtrar</button>
      </div>      
    </div>
  </div>
  <div class="data-table">
    <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [ClickInItem]="ActionsExtra" [Itens]="registerResults" (parentFun)="Actions($event)" (changePage)="getResults($event)"></app-table>
  </div>
</div>