import { HttpClient } from '@angular/common/http';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { Guid } from 'src/app/util/guid';
import { Injectable } from '@angular/core';
import { PriceTableExams } from './price-table-exams.types';
import { QueryParams } from '../interfaces/services.types';

@Injectable({ 
  providedIn: 'root' 
})

export class PriceTableExamsService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/pricetableexams');
  }

  override put(body: Object, id?: Guid | undefined, path?: string | undefined) {
    return super.put(body, id, path) as Promise<HttpResponsePageable<any>>
  }

  override post(body: Object) {
    return super.post(body) as Promise<HttpResponsePageable<any>>
  }

  override deleteByPath(id?: Guid | undefined, path?: string | undefined) {
    return super.deleteByPath(id, path) as Promise<HttpResponsePageable<any>>
  }

  override getById(id: Guid){
    return super.getById(id) as Promise<HttpResponsePageable<PriceTableExams>>
  }

  override getByPath(queryString: string, path: string, headers?: String[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<PriceTableExams>>
  }

  override getPagination<PriceTableExams>(queryObject?: QueryParams | undefined){
    return super.getPagination(queryObject) as Promise<HttpResponsePageable<PriceTableExams>> 
  }
}