export enum SpanExamSituation {
  "<span class='span-styles' style='background: #F8F8FF; color: #000000;'>Não Coletado</span>" = 0,
  "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Aguardando Coleta</span>" = 1,  
  "<span class='span-styles' style='background: #FF0000; color: #F8F8FF;'>Coletado</span>" = 3,
  "<span class='span-styles' style='background: #363636; color: #F8F8FF;'>Solic. Recoleta</span>" = 4,
  "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Recoletado</span>" = 5,
  "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Em Transporte</span>" = 6,
  "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Transporte Parcial</span>" = 7,
  "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Triado</span>" = 8,
  "<span class='span-styles' style='background: #F8F8FF; color: #00FFFF;'>Digitado Parcial</span>" = 9,
  "<span class='span-styles' style='background: #F8F8FF; color: #00FFFF;'>Digitado</span>" = 10,
  "<span class='span-styles' style='background: #00FFFF; color: #000000;'>Conferido</span>" = 11,
  "<span class='span-styles' style='background: #F8F8FF; color: #00FF00;'>Liberado</span>" = 12,
  "<span class='span-styles' style='background: #F8F8FF; color: #00008B;'>Impresso</span>" = 13,
  "<span class='span-styles' style='background: #F8F8FF; color: #0000FF;'>Entregue</span>" = 14,  
  "<span class='span-styles' style='background: #285e95; color: #C0C0C0;'>Suspenso de Fatura</span>" = 20,
  "<span class='span-styles' style='background: #07BF56; color: #F8F8FF;'>Exclusivo de Fatura</span>" = 21,
  "<span class='span-styles' style='background: #F8F8FF; color: #C0C0C0;'>Cancelado</span>" = 30  
}

export enum SpanExamCollectSituation {
  "<span class='span-styles' style='background: #F8F8FF; color: #000000;'>Não Coletar</span>" = 0,
  "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Incluir Aguardando Coleta</span>" = 1,  
  "<span class='span-styles' style='background: #FF0000; color: #F8F8FF;'>Coletar</span>" = 3,  
  "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Triar</span>" = 6,
}
