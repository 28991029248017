import { NgIf } from "@angular/common";
import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { DataComponent } from "@app/modules_new/common/components/data";

@Component({
   selector: "forms-button[text]",
   templateUrl: "./button.component.html",
   styleUrls: ["./button.component.scss"],
   standalone: true,
   imports: [NgIf, ReactiveFormsModule]
})
export class ButtonFormComponent
   extends DataComponent
   implements OnInit, OnDestroy
{
   @Input() variant = "";
   @Input() classExtra = "";
   @Input() text!: string;
   @Input() validate: FormGroup | FormControl | undefined;
   @Input() validateBeforeTouch = false;

   @Input() isDisabled = false;

   private _router = inject(Router);
   isAdm: boolean = this._router.url.includes("/Admin") ? true : false;

   ngOnInit(): void {
      if (this.isAdm) {
         this.classExtra += " btnAdm";
      }
      if (this.validate) {
         if (this.validateBeforeTouch && !this.validate?.touched) {
            this.isDisabled =
               !this.validate.valid ||
               (this.validate?.hasValidator(Validators.required) &&
                  this.validate?.value.length === 0);
         }
         this.addSubscriptions([
            this.validate.valueChanges.subscribe((value) => {
               this.isDisabled = !this.validate?.valid;
            })
         ]);
      }
   }

   ngOnDestroy() {
      this.unsubscribeAll();
   }
}
