import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { ListTraceabilityComponent } from "./list-traceability/list-traceability.component";
import { ViewTraceabilityComponent } from "./view-traceability/view-traceability.component";

const routes: Routes = [
    {
        path: 'Estatistica', children: [
            {path: 'Rastreabilidade', component: ListTraceabilityComponent},
            { path: 'Rastreabilidade/:id', component: ViewTraceabilityComponent }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TraceabilityRoutingModule {}