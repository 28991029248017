import { Injectable } from "@angular/core";
import { BaseNewService } from "@app/services/baseNew.service";
import { LoadingNewService } from "@app/loadingNew.service";
import { ApiBaseService } from "../../../../data/services/api-base.service";

@Injectable({
   providedIn: "root"
})
export class AgreementInvoicingService extends BaseNewService {
   constructor(HttpChronos: ApiBaseService) {
      super(HttpChronos, "v1/lab/parameters/agreementinvoicing");
      LoadingNewService.SilenceLoading.push(this.path);
   }
}
