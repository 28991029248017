import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { InputSearchComponent } from '@app/components/form/input-search/input-search.component';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { AgreementExamsSupportLaboratoryService } from '@app/services/agreement-exams-support-laboratory/agreement-exams-support-laboratory.service';
import { AccessControlUserCodeBar } from '@app/services/auth/acesscontrol-user-codebar.service';
import { UnityService } from "@app/services/auth/unity/unity-service.service";
import { SupportLaboratoryRequestService } from '@app/services/support-laboratory-request/support-laboratory-request.service';
import { SupportLaboratoryService } from '@app/services/support-laboratory/support-laboratory.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { BehaviorSubject, Observable } from 'rxjs';
import { RegisterExams, resolveExams, resolveRecipients, resolveSends, situation } from './support-laboratory-sending.model';
import { Util } from '@app/util/util';

@Component({
  selector: 'app-support-laboratory-sending',
  templateUrl: './support-laboratory-sending.component.html',
  styleUrls: ['./support-laboratory-sending.component.scss']
})
export class SupportLaboratorySendingComponent implements OnInit {
  @ViewChildren(InputSearchComponent) inputSearchComponents!: QueryList<InputSearchComponent>
  unityOptions: Array<Object> = [];
  supportLaboratoryOptions: Array<Object> = [];
  supportLaboratory = {
    description: new UntypedFormControl(null),
    situation: new UntypedFormControl(0),
    requisition: new UntypedFormControl(null),
    initialDate: new UntypedFormControl(null),
    finalDate: new UntypedFormControl(null),
    checkbox: new UntypedFormControl(null),
    unity: new UntypedFormControl(null),
  }
  form = {
    codeBarFilter: new UntypedFormControl(null),
    userCodeBar: new UntypedFormControl(null)
  };
  total: number = 0;
  page: number = 1;
  index: number = 30;
  getParameterssupportLaboratory: string = (`?page=1&registerIndex=999999`);
  registerExams: Array<RegisterExams> = [];
  registerSends: Array<Object> = [];
  registerRecipients: Array<Object> = [];
  checkBoxTrue: boolean = false;
  registerChecked: Array<Guid> = [];
  data: Array<Object> = [];
  loggedUser: string = '';
  loggedUserName: string = '';
  userValidated: boolean = false;
  selectedTab = new UntypedFormControl(0);

  private _registerExamsSubject = new BehaviorSubject(this.registerExams);
  registerExams$: Observable<Array<Object>> = this._registerExamsSubject.asObservable();

  @ViewChild('dialogUserValidation')
  dialogUserValidation!: TemplateRef<any>

  resolveSends = resolveSends;
  resolveExams = resolveExams;
  resolveRecipients = resolveRecipients;
  situation = situation;

  constructor(
    private _supportLaboratoryService: SupportLaboratoryService,
    private _dialog: MatDialog,
    private _accessControlUserCodeBar: AccessControlUserCodeBar,
    private _agreementExamsSupportLaboratoryService: AgreementExamsSupportLaboratoryService,
    private _supportLaboratoryRequestService: SupportLaboratoryRequestService,
    private _unityService: UnityService,
    private _localeDatePipe: LocalaDatePipe,
    private _util: Util
  ) { }

  async ngOnInit() {
    this.supportLaboratory.initialDate.setValue(this.incrementDays(new Date(), -30));
    this.supportLaboratory.finalDate.setValue(new Date());
    await this.getSupportLaboratory();
    this.registerRecipients = [];

    const data = [
      {
        id: '1f7b9836-8816-485c-947c-311795071a35',
        code: 321654987,
        date: '01/01/2022 08:31'
      },
      {
        id: '1f7b9836-8816-485c-947c-311795071a35',
        code: 987654321,
        date: '01/12/2022 08:31'
      }
    ];

    this.resolveSend(data, this.resolveSends);
  }

  async getSupportLaboratory() {
    this.supportLaboratoryOptions = [];
    await this._supportLaboratoryService.getResume().then(
      (res) => {
        if (res.data.length != 0) {
          res.data.forEach((supportLaboratory: any) => {
            this.supportLaboratoryOptions.push({
              label: supportLaboratory.name,
              value: supportLaboratory.id,
            });
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async get(paramPage?: number[]) {
    if (this.supportLaboratory.description.value) {
      if (paramPage) {
        this.page = paramPage[0];
        this.index = paramPage[1];
      }

      let filters = {
        page: this.page,
        index: this.index,
        supportLaboratoryId: this.supportLaboratory.description.value,
        situation: this.supportLaboratory.situation.value
      }

      this.data = [];

      await this._agreementExamsSupportLaboratoryService.getPagination(filters).then(
        (res) => {
          if (res.data.length != 0) {
            this.total = res.sumRecords;
            //@ts-ignore
            this.data = res.data;
          }
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );

      this.resolve(this.data, this.resolveExams);
      this._registerExamsSubject.next(this.registerExams);
    } else {
      SwAlSetttings.warningMessage("Selecione um apoio!");
      const queryListArray: Array<InputSearchComponent> = this.inputSearchComponents.toArray();
      const codeInputProp = queryListArray.find((inputProp) => inputProp.placeholder === 'Selecione o laboratório de apoio');
      if (codeInputProp) codeInputProp.input.nativeElement.focus();
    }
  }

  resolveSend(data: any, columns: any) {
    this.registerSends = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerSends.push(obj);
    });
  }

  resolve(data: any, columns: any) {
    this.registerExams = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (y.method == 'returnCheckBox') {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          if (!y.retrive) {
            obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
          } else {
            obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
          }
        }
      });
      this.registerExams.push(obj);
    });
  }

  resolveRecipient(data: any, columns: any) {
    //this.registerRecipients = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerRecipients.push(obj);
    });
  }

  returnCheckBox() {
    if (this.checkBoxTrue) {
      return '|checkbox|true';
    } else {
      return '|checkbox';
    }
  }

  ActionsExtra = {
    Selecionar: 'check',
    Remover: 'removeRecipient'
  };

  async Actions(action: any) {
    switch (action.action) {
      case 'check':
        if (action.$event.srcElement.checked != undefined) {
          var ind = this.registerChecked.findIndex((x: any) => x == action.object.id);
          if (action.$event.srcElement.checked) {
            if (ind == -1) {
              this.registerChecked.push(action.object.id);
            }
          } else {
            if (ind > -1) {
              this.registerChecked.splice(ind, 1);
            }
          }
        }
        break;
      case 'removeRecipient':
        var ind = this.registerRecipients.findIndex((x: any) => x.id == action.object.id);
        this.registerRecipients.splice(ind, 1);
        break;
    }
  }

  checkboxAll(event: any) {
    if (event.srcElement.checked != undefined) {
      this.checkBoxTrue = event.srcElement.checked;
      this.registerChecked = [];
      if (this.checkBoxTrue) {
        this.data.forEach((exam: any) => {
          this.registerChecked.push(exam.id);
        });
      }

      this.resolve(this.data, this.resolveExams);
      this._registerExamsSubject.next(this.registerExams);
    }
  }

  getSituation(item: any) {
    //@ts-ignore
    return this.situation[item.situation].label;
  }

  async send(situation: number) {
    if (this.registerChecked.length > 0) {
      const obj = {
        supportlaboratoryId: this.supportLaboratory.description.value,
        resend: false,//TODO
        sendPartial: false,//TODO
        // situation: situation,//TODO
        agreementExamsSupportLaboratoryIds: this.registerChecked
      }

      await this._supportLaboratoryRequestService.post(obj).then(
        (res) => {
          this.get();
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );
    }
  }

  async myTabSelectedTabChange(changeEvent: MatTabChangeEvent) {
    this.selectedTab.setValue(changeEvent);
    if (this.selectedTab.value === 1 && this.loggedUser === '') {
      this.openDialogModalUserValidation();
    }

    if (this.selectedTab.value === 0) {
      this.get();
    }

    if (this.selectedTab.value === 2) {
      this.getUnities();
    }
  }

  openDialogModalUserValidation(): void {
    const myTempDialog = this._dialog.open(this.dialogUserValidation, {
      width: '660px'
    });
  };

  async validateUserCodebar() {
    let codebar: string = `codebar=${this.form.userCodeBar.value}`;
    if (this.form.userCodeBar.value) {
      await this._accessControlUserCodeBar.getByPath(codebar, '').then(
        (res) => {
          if (res.data.length > 0) {
            this.userValidated = true;

            this.loggedUser = res.data[0].personId;
            this.loggedUserName = res.data[0].name;
            setTimeout(() => {
              SwAlSetttings.Sucesso("Usuário autenticado com sucesso!")
            });
          } else {
            this.form.userCodeBar.setValue(null);
            SwAlSetttings.printMessageError("Código de Barras inválido!")
            setTimeout(() => {
              this.openDialogModalUserValidation();
            });
          }
        }
      )
      this._dialog.closeAll();
    }
  }

  finish() {
    this.userValidated = false;
    this.loggedUser = '';
    this.loggedUserName = '';
    this.openDialogModalUserValidation();
  }

  returnDelete() {
    return '<svg width=17 height=21 viewBox=0 0 17 21 fill=none xmlns=http://www.w3.org/2000/svg><path d=M2.01111 19.0333C2.01111 19.8556 2.67778 20.5 3.5 20.5H13.5222C14.3444 20.5 15.0111 19.8556 15.0111 19.0333L15.5889 6.94444H1.45556L2.01111 19.0333ZM11.1667 9.36667C11.1667 8.94444 11.4111 8.61111 11.8333 8.61111C12.2556 8.61111 12.5 8.94444 12.5 9.36667V17.7222C12.5 18.1444 12.2556 18.4778 11.8333 18.4778C11.4111 18.4778 11.1667 18.1444 11.1667 17.7222V9.36667ZM7.83333 9.36667C7.83333 8.94444 8.07778 8.61111 8.5 8.61111C8.92222 8.61111 9.16667 8.94444 9.16667 9.36667V17.7222C9.16667 18.1444 8.92222 18.4778 8.5 18.4778C8.07778 18.4778 7.83333 18.1444 7.83333 17.7222V9.36667ZM4.5 9.36667C4.5 8.94444 4.74444 8.61111 5.16667 8.61111C5.58889 8.61111 5.83333 8.94444 5.83333 9.36667V17.7222C5.83333 18.1444 5.58889 18.4778 5.16667 18.4778C4.74444 18.4778 4.5 18.1444 4.5 17.7222V9.36667Z fill=#FE7434/><path d=M15.8556 3.16667H11.8333V1.18889C11.8333 0.788889 11.6333 0.5 11.2111 0.5H5.83333C5.41111 0.5 5.16667 0.788889 5.16667 1.18889V3.16667H1.18889C0.766667 3.16667 0.5 3.47778 0.5 3.9V4.74444C0.5 5.16667 0.766667 5.61111 1.18889 5.61111H15.8778C16.3 5.61111 16.5222 5.14444 16.5222 4.74444V3.9C16.5 3.47778 16.2778 3.16667 15.8556 3.16667ZM10.5 3.16667H6.5V1.83333H10.5V3.16667Z fill=#FE7434/></svg>';
  }

  async putCodeBarRequisition(codeBar: string) {
    var obj = {
      id: this.newGuid(),
      number: 321654,
      name: 'Teste',
      exam: 'Exame',
      code: 654987,
      material: 'Material',
      tag: codeBar,
      date: '12/12/2020'
    }

    let ind = this.registerRecipients.findIndex((x: any) => x == obj.id);
    if (ind == -1) {
      let objArray = [];
      objArray.push(obj);
      this.resolveRecipient(objArray, this.resolveRecipients);
    }
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  resetFilter() {
    this.supportLaboratory.initialDate.reset();
    this.supportLaboratory.finalDate.reset();
  }

  incrementDays(date: Date, days: number) {
    let dateNow: Date = date;
    let dateMilisseconds = dateNow.setDate(dateNow.getDate() + days);
    return new Date(dateMilisseconds);
  }

  async getUnities() {
    this.unityOptions = []
    await this._unityService.getAll().then(
      (res) => {
        res.data.forEach((unity: any) => {
          this.unityOptions.push({
            label: unity.description,
            value: unity.id
          })
        });
      }
    )
  }

  getCreateAtDate(item: any) {
    return this._localeDatePipe.transform(item.createdAt);
  }
}
