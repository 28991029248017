import { Component, OnInit } from '@angular/core';
import { DefaultFormats, LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { SwAlSetttings } from '@app/util/swal.settings';
import { CustomersService } from '@app/services/customers/customers.service';
import { GetAllCustomers } from '@app/services/customers/customers.types';
import { Util } from '@app/util/util';
import { CustomerFilterOptions } from '@app/modules/registers/components/customer/customers/customers.model';
import { CustomerFacade } from '@app/modules/registers/components/customer/customers/customers.facade';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RegistrationReportComponent } from '@app/components/form/registration-report/registration-report.component';
import { HttpClient } from '@angular/common/http';
import { TenantAuthService } from '@app/services/auth/tenantAuth/tenant-auth.service';
import { HeaderMapping, ReportFilter } from '@app/modules/reports/reports-model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-client-report',
  templateUrl: './client-report.component.html',
  styleUrls: ['./client-report.component.scss']
})
export class ClientReportComponent implements OnInit {

  reportObject: Array<Object> = [];


  receivedData!: GetAllCustomers[];
  receivedDataForPrint!: object[];
  sumRecords!: number;


  index: number = 50;
  page: number = 1;
  total: number = 0;

  registerCustomers: Array<Object> = [];

  headerMapping: HeaderMapping = {
    name: { title: 'Nome', width: '*' },
    genderType: { title: 'Sexo Biológico', width: 'auto' },
    birthDate: { title: 'Nascimento', width: 'auto' },
    cpf: { title: 'CNPJ/CPF', width: 'auto' },
    cellPhone: { title: 'Celular', width: 'auto' },
    email: { title: 'E-mail', width: 'auto' },
    createdAt: { title: 'Criado em', width: 'auto' },
    updatedAt: { title: 'Atualizado em', width: 'auto' },
    isActive: { title: 'Ativo', width: 'auto' },
  };

  reportFilters: ReportFilter[][] = [];

  isActiveOptions: Array<{ label: string, value: string | null | boolean }> = [{ label: 'Todos', value: 'todos' }, { label: 'Somente Ativos', value: true }, { label: 'Somente Inativos', value: false }]

  reportDescription: string = 'Relatório de Cadastro de Clientes';

  filterOptions: Array<CustomerFilterOptions> = this._customerFacade.getOptionsList;

  customerResolve: Array<Object> = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Nome',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'name',
      type: 'name'
    },
    {
      label: 'Sexo Biológico',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'genderType',
      type: 'genderType'
    },
    {
      label: 'Nascimento',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'birthDate',
      type: 'date',
      dateFormat: DefaultFormats.short
    },
    {
      label: 'Nome da Mãe',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'motherName',
      type: 'name'
    },
    {
      label: 'CNPJ/CPF',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'cpf',
      type: 'description'
    },
    {
      label: 'Celular',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'cellPhone',
      type: 'description'
    },
    {
      label: 'E-mail',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'email',
      type: 'description'
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'createdAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'updatedAt',
      type: 'date',
      dateFormat: DefaultFormats.full
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'isActive',
      type: 'boolean'
    },
  ];

  formGroup = new UntypedFormGroup({
    filter: new UntypedFormControl(null),
    search: new UntypedFormControl(null),
    isActive: new UntypedFormControl('todos')
  });


  _behaviourSubject = new BehaviorSubject(this.registerCustomers);


  constructor(
    private _customerService: CustomersService,
    private _util: Util,
    private _customerFacade: CustomerFacade,
    private _localeDate: LocalaDatePipe,
    private _http: HttpClient,
    private _tenantAuth: TenantAuthService
  ) { }

  async ngOnInit() {
    this.formGroup.controls['filter'].setValue("Filtrar por Nome/Nome Social");
    this.formGroup.controls['search'].setValue("");
    await this.getCustomers();
  }


  public set setTotal(total: number) {
    this.total = total;
  }

  async getCustomers(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    const filters: {
      page: number,
      numberRegistry: number,
      cpf?: string,
      name?: string,
      birthDate?: string,
      isActive?: string,
      motherName?: string
    } = { page: this.page, numberRegistry: this.index, cpf: '', name: '', birthDate: '' }

    switch (this.formGroup.controls['filter'].value) {
      case "Filtrar por CPF":
        filters.cpf = this.formGroup.controls['search'].value;
        break;
      case "Filtrar por Nome/Nome Social":
        filters.name = this.formGroup.controls['search'].value;
        break;
      case "Filtrar por Data de Nascimento":
        let splittedParam: string[] = this.formGroup.controls['search'].value.split('/');
        filters.birthDate = new Date(`${splittedParam[2]}-${splittedParam[1]}-${splittedParam[0]}`).toISOString();
        break;
      case "Filtrar por Nome da Mãe":
        filters.motherName = this.formGroup.controls['search'].value;
        break;
    }
    filters.numberRegistry = this.index;
    filters.page = this.page;

    filters.isActive = this.formGroup.controls['isActive'].value === 'todos' ? null : JSON.parse(this.formGroup.controls['isActive'].value);

    await this._customerService.getPagination<GetAllCustomers>(filters).then(
      (res) => {
        if (res.data.length != 0) {
          this.setTotal = res.sumRecords;
          this.resolve(res.data, this.customerResolve);
          this._behaviourSubject.next(this.registerCustomers);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.registerCustomers = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerCustomers.push(obj);
    });
  }

  async getDataForPrint() {
    this.reportObject = []

    const filters: {
      cpf?: string,
      name?: string,
      birthDate?: string,
      isActive?: string,
      motherName?: string
    } = { cpf: '', name: '', birthDate: '' }

    switch (this.formGroup.controls['filter'].value) {
      case "Filtrar por CPF":
        filters.cpf = this.formGroup.controls['search'].value;
        break;
      case "Filtrar por Nome/Nome Social":
        filters.name = this.formGroup.controls['search'].value;
        break;
      case "Filtrar por Data de Nascimento":
        let splittedParam: string[] = this.formGroup.controls['search'].value.split('/');
        filters.birthDate = new Date(`${splittedParam[2]}-${splittedParam[1]}-${splittedParam[0]}`).toISOString();
        break;
      case "Filtrar por Nome da Mãe":
        filters.motherName = this.formGroup.controls['search'].value;
        break;
    }

    filters.isActive = this.formGroup.controls['isActive'].value === 'todos' ? null : JSON.parse(this.formGroup.controls['isActive'].value);

    await this._customerService.getPagination<GetAllCustomers>(filters).then(
      (res) => {
        res.data.forEach((x) => {
          const obj = {
            name: x.name,
            genderType: x.genderType === 1 ? 'Feminino' : 'Masculino',
            birthDate: this._localeDate.transform(x.birthDate),
            cpf: x.cpf,
            cellPhone: x.cellPhone,
            email: x.email,
            createdAt: x.createdAt,
            updatedAt: x.updatedAt,
            isActive: x.isActive ? 'Ativo' : 'Inativo',
          }
          this.reportObject.push(obj)
        })
        this.sumRecords = res.sumRecords;
        this.receivedDataForPrint = this.reportObject;
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  async generatePdf() {
    await this.getDataForPrint();
    this.reportFilters = [];
    var filters = [];
    var filterActive = 'Todos';
    if (this.formGroup.controls['isActive'].value != 'todos') {
      if (this.formGroup.controls['isActive'].value == 'true') {
        filterActive = 'Somente Ativos';
      } else {
        filterActive = 'Somente Inativos';
      }
    }

    var obj = {
      title: 'Ativos: ', content: filterActive
    }

    filters.push(obj);

    if (this.formGroup.controls['search'].value) {
      var typeSearch = '';
      switch (this.formGroup.controls['filter'].value) {
        case "Filtrar por CPF":
          typeSearch = 'CPF: ';
          break;
        case "Filtrar por Nome/Nome Social":
          typeSearch = 'Nome: ';
          break;
        case "Filtrar por Data de Nascimento":
          typeSearch = 'Data de Nascimento: ';
          break;
        case "Filtrar por Nome da Mãe":
          typeSearch = this.formGroup.controls['search'].value;
          break;
      }

      obj = {
        title: typeSearch, content: this.formGroup.controls['search'].value
      }
      filters.push(obj)
    }

    this.reportFilters = [filters];
    const reportComponent = new RegistrationReportComponent(this._http, this._localeDate, this._tenantAuth);
    reportComponent.headerMapping = this.headerMapping;
    reportComponent.reportFilters = this.reportFilters;
    reportComponent.receivedData = this.receivedDataForPrint;
    reportComponent.reportDescription = this.reportDescription;
    reportComponent.sumRecords = this.sumRecords;

    await reportComponent.ngOnInit();
  }

  resetFilter() {
    this.formGroup.controls['filter'].setValue("Filtrar por Nome/Nome Social");
    this.formGroup.controls['search'].setValue("");
    this.formGroup.controls['isActive'].setValue("todos");
  }

}
