<div [class]="classe">
  <div class="flex align-center">
      <label *ngIf="label">
          {{label}}
      </label>
      <div class="sd" [ngClass]="{border: border}">
          <svg class="abs" width="13" *ngIf="notSelect && !form.value && !hideSvg"  height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.715988 0.248954C0.998628 -0.0178237 1.45085 -0.0119791 1.72605 0.262009L6.5 5.0149L11.2739 0.262009C11.5492 -0.0119796 12.0014 -0.0178242 12.284 0.248953C12.5667 0.515731 12.5727 0.954109 12.2975 1.2281L6.5 7L0.702521 1.2281C0.427319 0.954109 0.433348 0.515732 0.715988 0.248954Z" fill="#808080"/>
          </svg>
          <!-- <svg class="abs ok" *ngIf="!notSelect || form.value" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve"> <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata> <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M4630.9,5002.4c-1544.5-122.9-2904.7-932-3750.7-2226.7c-385.1-590-649.4-1311-749.7-2044.4c-36.9-272.4-41-930-8.2-1188.1C241-1376.5,597.5-2238.9,1140.3-2931.2c176.2-221.2,669.8-704.7,889-868.6c714.9-534.6,1509.7-856.3,2406.9-970.9c237.6-30.7,889-30.7,1126.7,0c897.2,114.7,1690,434.2,2406.9,968.9c249.9,186.4,751.8,688.3,938.2,938.2c534.7,717,854.2,1509.7,968.9,2406.9c30.7,237.6,30.7,889,0,1126.7c-102.4,788.7-356.4,1493.3-774.3,2128.3c-184.4,280.6-325.7,454.8-600.2,737.4c-665.7,684.2-1456.4,1130.8-2396.7,1354C5677.6,4990.1,5067.2,5037.2,4630.9,5002.4z M7818.3,2480.8c147.5-69.6,258.1-184.4,329.8-340c45.1-100.4,51.2-133.1,43-288.8c-6.1-131.1-20.5-202.8-55.3-270.4C8103,1515.9,7554,952.6,6243-360.4C4161.8-2443.7,4290.8-2331.1,3985.6-2331.1c-288.8,0-284.7-2-1243.4,956.6c-731.3,733.3-839.9,850.1-889,958.7c-145.4,317.5-4.1,704.7,317.5,868.5c176.2,90.1,438.4,86,616.6-10.2c59.4-32.8,307.3-262.2,647.3-604.3l551-551L5562.9,862.5c1112.3,1110.3,1603.9,1589.6,1667.4,1620.3C7418.8,2577,7619.6,2575,7818.3,2480.8z"/></g></g> </svg> -->
          <input #input [disabled]=disabled [matAutocomplete]="auto"
              [class]="noLabelUp ? 'noLabelUp' : ''"  [matAutocomplete]="auto"
              [tabindex]="tabindexcomponent" [type]="type" [required]=requiredInput
              [placeholder]="placeholder"
              [(ngModel)]="valueOption" >
          <label for="">{{placeholder}}</label>
          <span></span>
          <span class="abs" *ngIf="color !== 'unset'" [ngStyle]="{'background': color}"  style="width: 33px; height: 16px; border-radius: 100px; top: 16px;"></span>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getSelectedOption($event)" [displayWith]="displayCorretLabelFn" >
              <mat-option *ngFor="let option of options | searchFilter: valueOption; let index;" [value]="option">
                  {{ option.label }}
              </mat-option>
          </mat-autocomplete>
      </div>
  </div>
</div>
