import { Injectable } from "@angular/core";
import decodedToken from "./Token";

@Injectable({
    providedIn: 'root'
})
export class PermissionCheck {

    modulesByToken = [];

    getModulesByToken() {
        return decodedToken.modules;
    }

    getCheckToken(name: string) {
        let modules = this.getModulesByToken();
        if (modules) {
            return modules.includes(name);
        } else {
            return ''
        }
    }

}