import { Observable } from "rxjs";
import { DataComponent } from "./data";
import {
   ActionDefition,
   ColumnDefinition
} from "@app/modules_new/shared/components/form/table/table.model";

/**
 * Representação de Componentes que buscarão dados paginados,
 * já possue todos os atributos necessários para usar `TableComponent`.
 *
 * @class PageableComponent<T>
 * @see ClientsUsersUIComponent
 */
export abstract class PageableComponent<T> extends DataComponent {
   /**
    * Dados que são retornados por consulta `GET`
    */
   protected data!: Observable<T | null>;
   /**
    * Total de valores retornados por `GET`
    */
   protected total!: Observable<number>;
   /**
    * Página atual de consulta
    */
   protected page = 1;
   /**
    * Total de registros por consulta
    */
   protected numberRegistry = 30;
   /**
    * Valor inicial para texto de pesquisa
    */
   protected textFilter = "";

   /**
    * @param columns @type {ColumnDefinition} Necessário para exibir colunas de tabela
    * @param actions @type {ActionDefition} Opcional para exibir ações em cada linha de tabela
    */
   constructor(
      protected columns: ColumnDefinition[],
      protected actions?: ActionDefition[]
   ) {
      super();
   }

   /**
    * Use em debug
    * @returns Opções disponíveis
    */
   getActions = (): any => {
      return this.actions!.map((item) => item.event);
   };
}
