<div style="overflow: auto" class="p-[24px]">
    <div class="flex flex-between mb-[24px]">
        <h2 class="text-2xl font-bold">{{ title | translate }}</h2>
        <span *ngIf="closeButton" class="close">
            <ui-svg-selector
                [mat-dialog-close]="[]"
                [hover]="'var(--primary-color)'"
                [fill]="'#BFBFBF'"
                [svg]="'icon-menu-close'"
            ></ui-svg-selector>
        </span>
    </div>
    <ng-content></ng-content>
</div>
