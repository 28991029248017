import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkbox-Svg'
})
export class CheckboxSvgPipe implements PipeTransform {
  transform(emit?:any){
    let svg = "" 
    if(emit == "checkbox|true"){
      svg = "active"
    }
    return svg
  }
}
