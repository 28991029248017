import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DepartmentAuthService } from '@app/services/auth/department.service';
import { ToastService } from '@app/services/translate/toast.service';
import { Guid } from '@app/util/guid';
import { Actions } from './departments.model';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  total = 0
  page = 1
  numberRegistry = 30
  departments: Array<Object> = []

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  searchForm = new FormControl()
  departmentForm = new FormGroup({
    name: new FormControl(),
    externalId: new FormControl()
  })

  constructor(private _departmentService: DepartmentAuthService, private _dialog: MatDialog, private _toastService: ToastService) { }

  ngOnInit(): void {
    this.getDepartments()
  }

  openDialog() {
    this.departmentForm.reset()
    this._dialog.open(this.dialogModal, {
      width: '660px', data: {}
    })
  }
  
  openUpdateDialog(department: any) {
    this._dialog.open(this.dialogModal, {
      width: '660px',
      data: department
    })
    this.departmentForm.setValue(
      {
        name: department['departments.table-columns.department'],
        externalId: department['departments.table-columns.externalId'],
      }
    )
  }

  getDepartments(paramsPage?: number[]) {
    if(paramsPage) {
      this.page = paramsPage[0] 
      this.numberRegistry = paramsPage[1]
    }

    const query = {
      search: this.searchForm.value,
      page: this.page,
	    numberRegistry: this.numberRegistry
    }

    this._departmentService.getPagination(query).then(res => {
      this.departments = res.data.map((department: any) => (
        {
          id: department.id,
          "departments.table-columns.externalId": department.externalId ?? '',
          "departments.table-columns.department": department.name,
          "departments.table-columns.isActive": department.isActive ? 'checkbox|true' : 'checkbox|',
        }
      )) 
      this.total = res.sumRecords
    })
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.openUpdateDialog(emit.object)
        break
    }
  }

  createDepartment() {
    this._departmentService.post(this.departmentForm.value).then(res => {
      this.getDepartments()
      this._toastService.fireSuccess('departments.msg.success-post')
      this.close()
    })
  }

  updateDepartment(departmentId: Guid) {
    this._departmentService.put(this.departmentForm.value, departmentId).then(res => {
      this.getDepartments()
      this._toastService.fireSuccess('departments.msg.success-put')
      this.close()
    })
  }

  close() {
    this._dialog.closeAll()
  }

  clearFilter() {
    this.searchForm.reset()
  }

}
