<div class="contentPage">
  <div class="w-100 flex flex-between align-center headerResponsive"> 
    <h1 class="flex align-center titleResponsive">Rastreabilidade</h1>
    <app-filter class="filterResponsive" [advanced]="true" [cleanButton]="true" (beforeClose)="getRequisitions()" (clearFilters)="clearForms()">
      <div class="query aniOpen">
        <select-prop [form]="formUnity.requisitionUnity" placeholder="Selecione a Unidade" [options] = "unityOptions" (change)="getByIdUnity()"></select-prop>
        <select-prop [optionAll]='true' [form]="form.requisitionCollectionPlace" placeholder="Selecione o Local de Coleta" [options]="collectionPlaceOptions"></select-prop>
        <input-prop [placeholder]="'Data Inicial'" type="datetime-local" [form]="formDate.initialDate"></input-prop>
        <input-prop [placeholder]="'Data Final'" type="datetime-local" [form]="formDate.finalDate"></input-prop>
      </div>
      <div class="advancedQuery">
        <input-prop [form]="form.searchByRequisitionNumber" placeholder="Número da Requisição"></input-prop>
        <input-prop [form]="form.searchByCustomerName" placeholder="Nome do Cliente"></input-prop>
        <input-prop [placeholder]="'CPF do Cliente'" [form]="form.searchByCustomerCPF" mask="000.000.000-00"></input-prop>
        <input-prop [placeholder]="'Data de Nascimento do Cliente'" type="date" [form]="form.searchByCustomerBirthDate"></input-prop>
      </div>
      <app-button (click)="getRequisitions()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="flex mt-32">
      <app-table class="w-100" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [ClickInItem]="ActionsExtra" [Itens]="registerRequisitions" (parentFun)="Actions($event)" (changePage)="getRequisitions($event)"></app-table>
  </div>
</div>
