import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { EnumObject } from "@app/services/enum/enum.service.model";
import { UnityService } from "@app/services/shared/unity.service";
import { Guid } from "@app/util/guid";
import { Util } from "@app/util/util";
import { UnityParametersService } from "@app/services/unity-parameters/unity-parameters.service";
import { AgreementsService } from "@app/services/agreements/agreements.service";
import { InputSearchComponent } from "@app/components/form/input-search/input-search.component";
import {
   UnityParametersPost,
   UnityParametersPut
} from "@app/services/unity-parameters/unity-parameters.types";
import { AgreementPlanService } from "@app/services/agreement-plan/agreement-plan.service";
import decodedToken from "@app/util/Token";
import { ToastService } from "@app/services/translate/toast.service";

@Component({
   selector: "app-save-unity-parameters",
   templateUrl: "./save-unity-parameters.component.html",
   styleUrls: ["./save-unity-parameters.component.scss"]
})
export class SaveUnityParametersComponentOld implements OnInit {
   id: string = "";

   unitys: Array<object> = [];

   promiseOptions: Array<EnumObject> = [];

   agreementPlanOptionsDefoult: Array<object> = [];
   agreementPlanOptionsOne: Array<object> = [];
   agreementPlanOptionsTwo: Array<object> = [];

   agreementOptions: Array<{
      value: string;
      label: string;
      externalId: string;
      controlPlan?: boolean;
   }> = [];
   agreementOptionsOne: Array<{
      value: string;
      label: string;
      externalId: string;
      controlPlan?: boolean;
   }> = [];
   agreementOptionsTwo: Array<{
      value: string;
      label: string;
      externalId: string;
      controlPlan?: boolean;
   }> = [];

   controlPlanDefault: boolean = false;
   controlPlanOne: boolean = false;
   controlPlanTwo: boolean = false;

   formParameter = new UntypedFormGroup({
      name: new UntypedFormControl(null),
      unity: new UntypedFormControl(null),
      defaultHour: new UntypedFormControl(""),
      header: new UntypedFormControl(""),
      headerName: new UntypedFormControl(""),
      footer: new UntypedFormControl(""),
      footerName: new UntypedFormControl(""),
      blockingDebtors: new UntypedFormControl(false),
      daysBlockingDebtors: new UntypedFormControl(0),
      promiseType: new UntypedFormControl(null),
      requiredNameResposible: new UntypedFormControl(false),
      ageNameResponsible: new UntypedFormControl(""),

      AgreementDefault: new UntypedFormControl(null),
      codeAgreementDefault: new UntypedFormControl(null),
      AgreementPlanDefault: new UntypedFormControl(null),
      AgreementOne: new UntypedFormControl(null),
      codeAgreementOne: new UntypedFormControl(null),
      AgreementPlanOne: new UntypedFormControl(null),
      AgreementTwo: new UntypedFormControl(null),
      codeAgreementTwo: new UntypedFormControl(null),
      AgreementPlanTwo: new UntypedFormControl(null)
   });

   @ViewChildren(InputSearchComponent)
   inputSearchComponents!: QueryList<InputSearchComponent>;

   constructor(
      private _router: Router,
      private _route: ActivatedRoute,
      private _unityService: UnityService,
      private _util: Util,
      private _unityParametersService: UnityParametersService,
      private _agreementService: AgreementsService,
      private _agreementPlanService: AgreementPlanService,
      private _toastService: ToastService
   ) {
      if (_route.snapshot.params["id"] != "Novo") {
         let urlParams: string = _route.snapshot.params["id"].split("$");
         this.id = urlParams[0];
      }
   }

   async ngOnInit() {
      await this.getUnitys();
      this.promiseOptions = await this._util.getEnum("ETypeForecast");
      this.setValueForEdit(this.id);
   }

   async setValueForEdit(id: string) {
      await this._unityParametersService
         .getById(new Guid(id))
         .then((response) => {
            response.data.forEach(async (unityParamete) => {
               this.formParameter.controls["name"].setValue(unityParamete.name);
               this.formParameter.controls["unity"].setValue(
                  unityParamete.unityId
               );
               this.formParameter.controls["defaultHour"].setValue(
                  new Date(
                     unityParamete.timeStandardDelivery
                  ).toLocaleTimeString([], {
                     hour: "2-digit",
                     minute: "2-digit"
                  })
               );
               this.formParameter.controls["promiseType"].setValue(
                  unityParamete.typeForecast
               );
               this.formParameter.controls["daysBlockingDebtors"].setValue(
                  unityParamete.daysBlockingDebtors
               );
               this.formParameter.controls["blockingDebtors"].setValue(
                  unityParamete.blockingDebtors
               );
               this.formParameter.controls["requiredNameResposible"].setValue(
                  unityParamete.requiredNameResposible
               );
               this.formParameter.controls["ageNameResponsible"].setValue(
                  unityParamete.ageNameResponsible
               );
               if (unityParamete.fileHeaderReport) {
                  this.formParameter.controls["header"].setValue(
                     unityParamete.fileHeaderReport.id
                  );
                  this.formParameter.controls["headerName"].setValue(
                     unityParamete.fileHeaderReport.name
                  );
               }
               if (unityParamete.fileFooterReport) {
                  this.formParameter.controls["footer"].setValue(
                     unityParamete.fileFooterReport.id
                  );
                  this.formParameter.controls["footerName"].setValue(
                     unityParamete.fileFooterReport.name
                  );
               }

               if (unityParamete.agreementDefaultId) {
                  this.agreementOptions.push({
                     value: unityParamete.agreementDefaultId,
                     label: unityParamete.agreementDefaultDescription,
                     externalId: unityParamete.externalIdAgreementDefault
                  });
                  this.formParameter.controls["codeAgreementDefault"].setValue(
                     unityParamete.externalIdAgreementDefault
                  );
                  this.formParameter.controls["AgreementDefault"].setValue(
                     unityParamete.agreementDefaultId
                  );
                  unityParamete.agreementPlanDefaultDescription
                     ? (this.controlPlanDefault = true)
                     : false;
                  setTimeout(() => {
                     this.agreementPlanOptionsDefoult.push({
                        value: unityParamete.agreementPlanDefaultId,
                        label: unityParamete.agreementPlanDefaultDescription
                     });
                  });
                  this.formParameter.controls["AgreementPlanDefault"].setValue(
                     unityParamete.agreementPlanDefaultId
                  );
               }

               if (unityParamete.agreementOneId) {
                  this.agreementOptionsOne.push({
                     value: unityParamete.agreementOneId,
                     label: unityParamete.agreementOneDescription,
                     externalId: unityParamete.externalIdAgreementOne
                  });
                  this.formParameter.controls["codeAgreementOne"].setValue(
                     unityParamete.externalIdAgreementOne
                  );
                  this.formParameter.controls["AgreementOne"].setValue(
                     unityParamete.agreementOneId
                  );
                  unityParamete.agreementPlanOneDescription
                     ? (this.controlPlanOne = true)
                     : false;
                  setTimeout(() => {
                     this.agreementPlanOptionsOne.push({
                        value: unityParamete.agreementPlanOneId,
                        label: unityParamete.agreementPlanOneDescription
                     });
                  });
                  this.formParameter.controls["AgreementPlanOne"].setValue(
                     unityParamete.agreementPlanOneId
                  );
               }
               if (unityParamete.agreementTwoId) {
                  this.agreementOptionsTwo.push({
                     value: unityParamete.agreementTwoId,
                     label: unityParamete.agreementTwoDescription,
                     externalId: unityParamete.externalIdAgreementTwo
                  });
                  this.formParameter.controls["codeAgreementTwo"].setValue(
                     unityParamete.externalIdAgreementTwo
                  );
                  this.formParameter.controls["AgreementTwo"].setValue(
                     unityParamete.agreementTwoId
                  );
                  unityParamete.agreementPlanTwoDescription
                     ? (this.controlPlanTwo = true)
                     : false;
                  setTimeout(() => {
                     this.agreementPlanOptionsTwo.push({
                        value: unityParamete.agreementPlanTwoId,
                        label: unityParamete.agreementPlanTwoDescription
                     });
                  });
                  this.formParameter.controls["AgreementPlanTwo"].setValue(
                     unityParamete.agreementPlanTwoId
                  );
               }
            });
         });
   }

   async getUnitys() {
      this.unitys = [];
      setTimeout(() => {
         this.unitys.push({
            value: null,
            label: "Global"
         });
      });

      const queryParams = {
         companyId: decodedToken.cid,
         resume: true,
         isActive: true
      };

      await this._unityService.getPagination(queryParams).then((res) => {
         res.data.forEach((unity: any) => {
            this.unitys.push({
               value: unity.id,
               label: unity.name
            });
         });
      });
   }

   back() {
      this._router.navigateByUrl("Parametros/Parametros-Unidade");
   }

   async searchAgreementDefault(
      search: string,
      searchByCode: boolean,
      comparative: number
   ) {
      let obj: any = [];
      searchByCode
         ? (obj = { ExternalCode: search, isActive: true })
         : (obj = { search: search, isActive: true });
      if (search != null) {
         await this._agreementService.getPagination(obj).then((response) => {
            if (comparative === 0) {
               this.resetSerch(searchByCode, comparative);
               response.data.forEach((agreement: any) => {
                  this.agreementOptions.push({
                     value: agreement.id,
                     label: agreement.description,
                     externalId: agreement.externalId
                        ? agreement.externalId.replace(".", "")
                        : "",
                     controlPlan: agreement.controlPlan
                  });
               });
               if (this.agreementOptions.length > 0) {
                  this.setFocusToAgreement(comparative);
               } else {
                  this._toastService.warningMessage(
                     "unituParametersEdit.msgNotPlan"
                  );
               }
            } else if (comparative === 1) {
               this.resetSerch(searchByCode, comparative);
               response.data.forEach((agreement: any) => {
                  this.agreementOptionsOne.push({
                     value: agreement.id,
                     label: agreement.description,
                     externalId: agreement.externalId
                        ? agreement.externalId.replace(".", "")
                        : "",
                     controlPlan: agreement.controlPlan
                  });
               });
               if (this.agreementOptionsOne.length > 0) {
                  this.setFocusToAgreement(comparative);
               } else {
                  this._toastService.warningMessage(
                     "unituParametersEdit.msgNotPlan"
                  );
               }
            } else {
               this.resetSerch(searchByCode, comparative);
               response.data.forEach((agreement: any) => {
                  this.agreementOptionsTwo.push({
                     value: agreement.id,
                     label: agreement.description,
                     externalId: agreement.externalId
                        ? agreement.externalId.replace(".", "")
                        : "",
                     controlPlan: agreement.controlPlan
                  });
               });
               if (this.agreementOptionsTwo.length > 0) {
                  this.setFocusToAgreement(comparative);
               } else {
                  this._toastService.warningMessage(
                     "unituParametersEdit.msgNotPlan"
                  );
               }
            }
         });
      }
   }

   resetSerch(searchByCode: boolean, comparative: number) {
      if (comparative === 0) {
         this.agreementOptions = [];
         this.controlPlanDefault = false;
         searchByCode
            ? this.formParameter.controls["AgreementDefault"].reset()
            : this.formParameter.controls["codeAgreementDefault"].reset();
      } else if (comparative === 1) {
         this.agreementOptionsOne = [];
         this.controlPlanOne = false;
         searchByCode
            ? this.formParameter.controls["AgreementOne"].reset()
            : this.formParameter.controls["codeAgreementOne"].reset();
      } else {
         this.agreementOptionsTwo = [];
         this.controlPlanTwo = false;
         searchByCode
            ? this.formParameter.controls["AgreementTwo"].reset()
            : this.formParameter.controls["codeAgreementTwo"].reset();
      }
   }

   checkControlPlan(id: string, comparative: number) {
      if (comparative === 0) {
         this.agreementOptions.forEach((Agreement) => {
            if (Agreement.value === id) {
               this.formParameter.controls["codeAgreementDefault"].setValue(
                  Agreement.externalId
               );
               this.controlPlanDefault = Agreement.controlPlan
                  ? Agreement.controlPlan
                  : false;
               this.getAgreementPlan(id, comparative);
            }
         });
      } else if (comparative === 1) {
         this.agreementOptionsOne.forEach((Agreement) => {
            if (Agreement.value === id) {
               this.formParameter.controls["codeAgreementOne"].setValue(
                  Agreement.externalId
               );
               this.controlPlanOne = Agreement.controlPlan
                  ? Agreement.controlPlan
                  : false;
               this.getAgreementPlan(id, comparative);
            }
         });
      } else {
         this.agreementOptionsTwo.forEach((Agreement) => {
            if (Agreement.value === id) {
               this.formParameter.controls["codeAgreementTwo"].setValue(
                  Agreement.externalId
               );
               this.controlPlanTwo = Agreement.controlPlan
                  ? Agreement.controlPlan
                  : false;
               this.getAgreementPlan(id, comparative);
            }
         });
      }
   }

   async getAgreementPlan(agreementId: string, comparative: number) {
      await this._agreementPlanService
         .getPagination({ AgreementId: agreementId })
         .then((res) => {
            if (comparative === 0) {
               this.agreementPlanOptionsDefoult = [];
               res.data.forEach((plan: any) => {
                  this.agreementPlanOptionsDefoult.push({
                     value: plan.id,
                     label: plan.description
                  });
               });
            } else if (comparative === 1) {
               this.agreementPlanOptionsOne = [];
               res.data.forEach((plan: any) => {
                  this.agreementPlanOptionsOne.push({
                     value: plan.id,
                     label: plan.description
                  });
               });
            } else {
               this.agreementPlanOptionsTwo = [];
               res.data.forEach((plan: any) => {
                  this.agreementPlanOptionsTwo.push({
                     value: plan.id,
                     label: plan.description
                  });
               });
            }
         });
   }

   setFocusToAgreement(comparative: number) {
      if (comparative === 0) {
         const queryListArray: Array<InputSearchComponent> =
            this.inputSearchComponents.toArray();
         const codeInputProp = queryListArray.find(
            (inputProp) => inputProp.placeholder === "Selecione o Convênio"
         );
         if (codeInputProp) {
            codeInputProp.input.nativeElement.focus();
         }
      } else if (comparative === 1) {
         const queryListArray: Array<InputSearchComponent> =
            this.inputSearchComponents.toArray();
         const codeInputProp = queryListArray.find(
            (inputProp) =>
               inputProp.placeholder === "Selecione o Convênio Comparativo 1"
         );
         if (codeInputProp) {
            codeInputProp.input.nativeElement.focus();
         }
      } else {
         const queryListArray: Array<InputSearchComponent> =
            this.inputSearchComponents.toArray();
         const codeInputProp = queryListArray.find(
            (inputProp) =>
               inputProp.placeholder === "Selecione o Convênio Comparativo 2"
         );
         if (codeInputProp) {
            codeInputProp.input.nativeElement.focus();
         }
      }
   }

   async createUnityParameter() {
      const createUnityParameter: UnityParametersPost = {
         agreementDefaultId:
            this.formParameter.controls["AgreementDefault"].value,
         agreementPlanDefaultId:
            this.controlPlanDefault === true
               ? this.formParameter.controls["AgreementPlanDefault"].value
               : null,
         agreementOneId: this.formParameter.controls["AgreementOne"].value,
         agreementPlanOneId:
            this.controlPlanOne === true
               ? this.formParameter.controls["AgreementPlanOne"].value
               : null,
         agreementTwoId: this.formParameter.controls["AgreementTwo"].value,
         agreementPlanTwoId:
            this.controlPlanTwo === true
               ? this.formParameter.controls["AgreementPlanTwo"].value
               : null,
         companyId: decodedToken.cid,
         unityId: this.formParameter.controls["unity"].value,
         timeStandardDelivery: this.formParameter.controls["defaultHour"].value,
         fileHeaderReportId: this.formParameter.controls["header"].value,
         fileFooterReportId: this.formParameter.controls["footer"].value,
         blockingDebtors: this.formParameter.controls["blockingDebtors"].value,
         daysBlockingDebtors:
            this.formParameter.controls["daysBlockingDebtors"].value,
         typeForecast: this.formParameter.controls["promiseType"].value,
         requiredNameResposible:
            this.formParameter.controls["requiredNameResposible"].value,
         ageNameResponsible:
            this.formParameter.controls["ageNameResponsible"].value
      };
      await this._unityParametersService.post(createUnityParameter).then(() => {
         this.back();
         this._toastService.fireSuccess("unituParametersEdit.msgPost");
      });
   }

   async updateUnityParameter() {
      const updateUnityParameter: UnityParametersPut = {
         timeStandardDelivery: this.formParameter.controls["defaultHour"].value,
         fileHeaderReportId: this.formParameter.controls["header"].value,
         fileFooterReportId: this.formParameter.controls["footer"].value,
         blockingDebtors: this.formParameter.controls["blockingDebtors"].value,
         daysBlockingDebtors:
            this.formParameter.controls["daysBlockingDebtors"].value,
         typeForecast: this.formParameter.controls["promiseType"].value,
         agreementDefaultId:
            this.formParameter.controls["AgreementDefault"].value,
         agreementPlanDefaultId:
            this.controlPlanDefault === true
               ? this.formParameter.controls["AgreementPlanDefault"].value
               : null,
         agreementOneId: this.formParameter.controls["AgreementOne"].value,
         agreementPlanOneId:
            this.controlPlanOne === true
               ? this.formParameter.controls["AgreementPlanOne"].value
               : null,
         agreementTwoId: this.formParameter.controls["AgreementTwo"].value,
         agreementPlanTwoId:
            this.controlPlanTwo === true
               ? this.formParameter.controls["AgreementPlanTwo"].value
               : null,
         requiredNameResposible:
            this.formParameter.controls["requiredNameResposible"].value,
         ageNameResponsible:
            this.formParameter.controls["ageNameResponsible"].value
      };

      await this._unityParametersService
         .put(updateUnityParameter, new Guid(this.id))
         .then(() => {
            this.back();
            this._toastService.fireSuccess("unituParametersEdit.msgPut");
         });
   }
}
