import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { TableRegistryService } from "./tableRegistry.service";

@Injectable({
   providedIn: "root"
})
export class LoadingService {
   loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   /**
    * Contains in-progress loading requests
    */
   loadingMap: Map<string, boolean> = new Map<string, boolean>();

   constructor(
      private router: Router,
      private tableRegistryService: TableRegistryService
   ) {}

   /**
    * Sets the loadingSub property value based on the following:
    * - If the URL ends with a GUID, loading is set to true
    * - If loading is false, remove the loadingMap entry and only when the map is empty will we set loadingSub to false
    * This pattern ensures that loading is only shown for requests targeting specific entities by GUID.
    * @param loading {boolean}
    * @param url {string}
    */
   setLoading(
      loading: boolean,
      url: string,
      method: string,
      error: boolean = false
   ): void {
      if (!url) {
         throw new Error(
            "The request URL must be provided to the LoadingService.setLoading function"
         );
      }

      let shouldControlLoading = false;

      if (method != "GET") {
         shouldControlLoading = true;
      }

      if (
         !shouldControlLoading &&
         url.search("agreementexamsrequest/agreementrequest") < 0
      ) {
         shouldControlLoading = this.urlEndsWithGuid(this.router.url, url);
         if (!shouldControlLoading) {
            shouldControlLoading = this.checkLoading(url);
         }
      }

      if (shouldControlLoading) {
         if (loading === true) {
            this.loadingMap.set(url, loading);
            this.loadingSub.next(true);
         } else if (loading === false && this.loadingMap.has(url)) {
            this.loadingMap.delete(url);
         }
         if (this.loadingMap.size === 0) {
            this.loadingSub.next(false);
         }
      }
   }

   /**
    * Determines whether the URL ends with a GUID.
    * @param url {string}
    * @returns {boolean}
    */
   private urlEndsWithGuid(url: string, endpointUrl: string): boolean {
      const guidPattern =
         /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

      return (
         endpointUrl.search("page") < 0 &&
         guidPattern.test(url) &&
         guidPattern.test(endpointUrl) &&
         url.search("Exames/Historico") < 0 &&
         url.search("Exames/Exames-Laboratoriais/") < 0
      );
   }

   private checkLoading(url: string): boolean {
      const enumPattern = /enum/i;
      const profilePattern = /profile/i;
      const resumePattern = /resume=true/i;
      const agreementrequestshippinginvoice =
         /agreementrequestshippinginvoice/i;

      return (
         enumPattern.test(url) ||
         resumePattern.test(url) ||
         profilePattern.test(url) ||
         agreementrequestshippinginvoice.test(url)
      );
   }
}
