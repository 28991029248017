import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UserUtil } from "../../../util/user";
import { SwAlSetttings } from "@app/util/swal.settings";

@Component({
   selector: "app-header-admin",
   templateUrl: "./header.component.html",
   styleUrls: ["./header.component.scss"]
})
export class HeaderAdminComponent implements OnInit {
   menuopen: boolean = false;

   @Output() changeMenuOpen: EventEmitter<any> = new EventEmitter();
   @Output() changeMenuUserOpen: EventEmitter<any> = new EventEmitter();

   menuuseropen: boolean = false;
   inTransition: boolean = false;
   triggerEvent: boolean = false;
   accessCode: string = "";

   constructor(public UserUtil: UserUtil, private _sanitizer: DomSanitizer) {}

   ngOnInit(): void {
      this.userImage();
   }

   closeMenu(event: any) {
      this.OpenMenu();
   }

   OpenMenu(): void {
      if (!this.inTransition)
         if (this.menuuseropen) {
            this.inTransition = true;
            this.menuuseropen = false;
            setTimeout(() => {
               this.menuopen = true;
               this.inTransition = false;
               this.triggerEvent = this.triggerEvent ? false : true;
            }, 300);
         } else {
            this.menuopen = this.menuopen ? false : true;
         }
      this.changeMenuOpen.emit({ changeMenuOpen: this.menuopen });
   }

   imagePath: any = "";

   async userImage() {
      await this.UserUtil.getImage()
         .then((x) => {
            if (x.image != null) {
               this.imagePath = x.image;
            }
            this.accessCode = x.accessCode;
         })
         .catch((err) => SwAlSetttings.printError(err));
   }

   OpenUserMenu(): void {
      if (!this.inTransition)
         if (this.menuopen) {
            this.menuopen = false;
            this.changeMenuOpen.emit({ changeMenuOpen: this.menuopen });
            this.inTransition = true;
            setTimeout(() => {
               this.menuuseropen = true;
               this.triggerEvent = this.triggerEvent ? false : true;
               this.inTransition = false;
            }, 300);
         } else {
            if (this.menuuseropen) {
               this.inTransition = true;
               setTimeout(() => {
                  this.triggerEvent = this.triggerEvent ? false : true;
                  this.inTransition = false;
               }, 300);
            } else {
               this.triggerEvent = this.triggerEvent ? false : true;
            }
            this.menuuseropen = this.menuuseropen ? false : true;
         }
   }

   parentFunc($event: any) {
      if ($event.close) {
         this.OpenUserMenu();
      }
   }
}
