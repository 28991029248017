import { Component, EventEmitter, Input, IterableDiffers, OnInit, Output } from '@angular/core';
import { PersonService } from 'src/app/services/person.service';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'input-multiple',
  templateUrl: './input-multiple.component.html',
  styleUrls: ['./input-multiple.component.scss']
})
export class InputMultipleComponent implements OnInit {

  @Input() type: string = 'text';
  //@ts-ignore
  @Input() form: FormControl;
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() mask: any = '';
  @Input() className: string = '';
  @Input() requiredInput: string = '';
  @Input() tabindexcomponent: string = '';
  @Input() pattern: string = '';
  @Input() options: Array<any> = [];
  @Input() readonly: boolean = false;

  @Output() changeForm = new EventEmitter<any>();

  OptionSelect: Number = -1;
  //@ts-ignore
  @Input() valueOption: String = '';
  PropValue: String = '';
  classe: string = '';
  notSelect: boolean = true;

  @Input() todos: boolean = false;

  border: boolean = false;
  @Input() noLabelUp: boolean = false;

  differ: any;

  constructor(private _personService: PersonService, _differs: IterableDiffers, private _sanitizer : DomSanitizer
  ) {
    //@ts-ignore
    this.differ = _differs.find([]).create(null);
  }

  validate(s: String) {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    return s.match(rgx);
  }


  ngDoCheck() {
    this.getName()
    // here you can do what you want on array change
    // you can check for forEachAddedItem or forEachRemovedItem on change object to see the added/removed items
    // Attention: ngDoCheck() is triggered at each binded variable on componenet; if you have more than one in your component, make sure you filter here the one you want.
  }

  onChange(value: String) {
    if (value)
      this.form.setValue(value);
    else
      this.form.setValue('');

    this.changeForm.emit({change:true})
  }

  ngOnInit(): void {
    this.classe = this.className + ' input-row';
    this.changeForm.emit({change:true})
    // this.get();

  }


  SelectOption(label: String, value: String, index: Number) {
    this.border = false;
    this.OptionSelect = index;
    this.form.setValue(value);
    this.notSelect = false;
    this.changeForm.emit({change:true})
    this.getName();
  }

  getPosts(event:any) {
    setTimeout(() => {
      let ind = this.options.findIndex((x:any) => x.value == event.option.value);
      this.SelectOption(this.options[ind].label, event.option.value, ind);
    }, 500)
  }

  SearchIn(event : any) {
    this.border = false;
    this.notSelect = true;
    if(event.code != "Tab")
      this.form.setValue('');
    this.changeForm.emit({change : true})
  }

  CheckValue() {
    setTimeout(() => {
      if (this.form.value === '') {
        this.valueOption = '';
        this.border = true;
        this.changeForm.emit({change:true})
      }
    }, 200)
    this.changeForm.emit({change:true})
  }

  getName() {
    if (this.form.value) {
      const object = this.options.find(x => x.value === this.form.value);
      if (object) {
        this.valueOption =  this.options.find(x => x.value === this.form.value).label;
      }
    }
  }

  getImg() {
    if (this.form.value) {
      const object = this.options.find(x => x.value === this.form.value);
      if (object) {
        return this.options.find(x => x.value === this.form.value).userImg
      }
    }
  }

  checkUserImg(userId : string) {
    let ind = this.options.findIndex((x:any) => x.value == userId)
    return this.options[ind].userImg;
  }


  checkUser(userId : string) {
    let ind = this.options.findIndex((x:any) => x.value == userId)
    return this.options[ind];
  }

  labelB() {
    if(this.todos) {
      if(Array.isArray(this.form.value))
      if(this.form.value.includes('todos')) return true
    }
    return false;
  }

  Click(event : any, index : number) {
    if(this.todos) {
        if(index == 0 && this.form.value.includes('todos')) {
          this.form.setValue(['todos']);
        }
    }
  }
}
