import { formatCurrency } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'input-prop-currency',
  templateUrl: './input-prop-currency.component.html',
  styleUrls: ['./input-prop-currency.component.scss']
})
export class InputPropCurrencyComponent implements OnInit {

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  @Input() readonly: boolean = false;
  @Input() form: UntypedFormControl = new UntypedFormControl(0);
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() className: string = '';
  @Input() requiredInput?: string;
  @Input() noLabelUp = false;
  @Input() tabindexcomponent: string = '';

  //@Input() valueGap: number = 0.01;
  @Input() minValue: number = 0;
  @Input() maxValue?: number;
  @Input() maxlength: string = '20';

  @Input() warningCustom!: string
  
  classe: string = '';
  defaultForm: UntypedFormControl = new UntypedFormControl('R$ 0,00');

  @ViewChild('input') input!: ElementRef;

  get rawValue() {
    return this.form.value
  }

  constructor() { }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
    this.classe = this.className + ' input-row';

    this.formatLabelValue()

    this.form.valueChanges.subscribe(value => {
      this.formatLabelValue()
    })

    this.defaultForm.valueChanges.subscribe(value => {
      value = value.replace(/[^0-9]/g, '') / 100
      
      const inputElement = this.input.nativeElement as HTMLInputElement;
      const startPos = inputElement.selectionStart;

      let diff: number = this.defaultForm.value.length
 
      this.defaultForm.setValue("R$ "+formatCurrency(value, 'pt-BR', ''), {emitEvent: false})
      this.form.setValue(value, {emitEvent: false})
      
      diff -= this.defaultForm.value.length

      let position: number = (startPos ?? 0) - (diff)

      setTimeout(() => {
        inputElement.setSelectionRange(position, position);
      });

    })
  }

  formatLabelValue() {
    this.defaultForm.setValue("R$ "+formatCurrency(this.form.value, 'pt-BR', ''), {emitEvent: false})
  }

  onChange(form: any) {
    this.validateForm()
    this.change.emit(true)
  }

  validateForm(){
    if(this.rawValue < this.minValue) {
      this.form.setErrors({'minValue': true})
    }

    if(this.maxValue && this.rawValue > this.maxValue) {
      this.form.setErrors({'maxValue': true})
    }
  
    // if(Math.round(Math.round(this.rawValue * 100) % Math.round(this.valueGap * 100)) > 0) {
    //   this.form.setErrors({'gap': true})
    // }
  }

  _keyDown(event: any) {
    const pattern = /[0-9,.]/;
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown','Delete', 'A', 'a', 'v', 'V', 'c', 'C'];
    const inputKey = event.key
    
    if (!pattern.test(inputKey) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  showError(errorName: string): boolean {
    if (this.form.touched && this.form.errors?.[errorName] && this.requiredInput) {
      return true
    }
    return false
  }

}
