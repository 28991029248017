<modal-layout [title]="title | translate">
    <form [formGroup]="form" class="my-12 flex flex-col gap-10">
        <forms-input-text
            [placeholder]="'Senha atual'"
            [form]="form.controls.actualPassword"
            [type]="'password'"
        >
        </forms-input-text>
        <forms-input-text
            [placeholder]="'Nova senha'"
            [form]="form.controls.password"
            [type]="'password'"
        >
        </forms-input-text>
        <forms-input-text
            [placeholder]="'Confirmar nova senha'"
            [form]="form.controls.confirmPassword"
            [type]="'password'"
        >
        </forms-input-text>
        <forms-button
            #button
            class="self-center"
            (click)="
                button.isDisabled ? null : submit(); $event.preventDefault()
            "
            [validate]="form"
            classExtra="btn btn-primary"
            [text]="'Atualizar senha' | translate"
        ></forms-button>
    </form>
</modal-layout>
