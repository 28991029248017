<content-filter-layout
    [title]="'Usuários'"
    [createButton]="{
        title: 'global.new' | translate,
        redirectPath: 'Novo'
    }"
    [formFilter]="formFilter"
    (clearFilters)="resetFilter()"
    (searchFilters)="get()"
>
    <forms-table
        #users
        [actions]="actions"
        [rows]="data"
        [columns]="columns"
        [hasDropdown]="true"
    >
        <ng-template #dropdown let-row>
            <users-ui [user]="row.id"></users-ui>
        </ng-template>
        <ng-container ngProjectAs="[pagination]">
            <forms-pagination
                (changePage)="changePage($event)"
                [totalPerPage]="30"
                [amount]="total | async"
                [page]="page"
            ></forms-pagination>
        </ng-container>
    </forms-table>
</content-filter-layout>
