<div [class]="classe">
    <mat-form-field>
      <label [ngClass]="form.value || form.value.length !== 0 ? 'labelUp' : 'noLabelUp'" *ngIf="placeholder">{{placeholder}}</label>
        <mat-select
                disableOptionCentering panelClass="myPanelClass"
                [formControl]="form" [disabled]="readonly"
                [placeholder]="placeholder ?? 'Selecione'" multiple>
            <mat-select-trigger>
                <div class="flex gap8">
                    <div class="user" *ngFor="let user of form.value">
                        <div class="flex align-center gap8" >
                            <span class="select">
                                {{checkUser(user)?.label}}
                            </span>
                        </div>
                    </div>
                </div>
            </mat-select-trigger>
            <mat-option *ngFor="let option of options; let ind = index" [value]="option.value" [ngClass]="(option.hasOwnProperty('disable') && option.disable) || (labelB()) ? !labelB() ? 'none' : 'none first' : ''" (click)="Click($event, ind)">
                <div class="flex align-center gap8">
                    <div class="user" *ngIf="option.hasOwnProperty('userImg')">
                        <div class="userImgDiv" *ngIf="option.userImg">
                            <img [src]="'data:image/jpg;base64,'+option.userImg" class="userIMG" />
                        </div>
                        <div class="userImgDivNoting" *ngIf="!option.userImg">
                            {{option.label.charAt(0)}}
                        </div>
                    </div>
                    {{option.label}}
                </div>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
