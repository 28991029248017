import { AsyncPipe } from "@angular/common";
import {
   AfterViewInit,
   Component,
   inject,
   Input,
   OnInit,
   ViewChild
} from "@angular/core";
import { PaginationTableComponent } from "@app/modules_new/shared/components/form/table/pagination/pagination.component";
import { PaginationEvent } from "@app/modules_new/shared/components/form/table/pagination/pagination.model";
import { TableFormComponent } from "@app/modules_new/shared/components/form/table/table.component";
import { ContentFilterLayoutComponent } from "@app/modules_new/shared/layouts/content-filter/content-filter.component";
import { TranslateModule } from "@ngx-translate/core";
import { tableActions, tableColumns } from "./subMenu";
import { RequestOptions } from "@app/modules_new/data/types/http.types";
import { PageableComponent } from "@app/modules_new/common/components/pageable";
import { MenuItemResponse } from "../../data/services/menuItem/menuItem.types";
import { MenuItemFacade } from "../../data/facades/menuItem.facade";
import { MatDialog } from "@angular/material/dialog";
import { MenuItemModal } from "../../pages/menuItem/modal/modal.component";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
   selector: "subMenu[menu]",
   templateUrl: "./subMenu.component.html",
   standalone: true,
   imports: [
      ContentFilterLayoutComponent,
      TableFormComponent,
      PaginationTableComponent,
      TranslateModule,
      AsyncPipe
   ]
})
export class SubMenuUIComponent
   extends PageableComponent<MenuItemResponse[]>
   implements OnInit, AfterViewInit
{
   private _dialog = inject(MatDialog);
   private _menuItemFacade = inject(MenuItemFacade);
   @ViewChild("subMenu") subMenuTable!: TableFormComponent;
   @Input() menu!: any;

   formFilterGroup = new FormGroup({
      description: new FormControl(null),
      isActive: new FormControl(null)
   });

   formFilter: Array<object> = [
      {
         placeholder: "default.description",
         type: "text",
         form: this.formFilterGroup.controls["description"],
         click: true
      },
      {
         placeholder: "agreements.filter.isActive",
         type: "toggle",
         form: this.formFilterGroup.controls["isActive"]
      }
   ];

   constructor() {
      super(tableColumns, tableActions);
   }

   ngOnInit() {
      this.get();
   }

   get() {
      const obj: RequestOptions = {
         query: {
            search: this.formFilterGroup.controls.description.value ?? null,
            isActive: this.formFilterGroup.controls.isActive.value,
            parentId: this.menu.id
         }
      };

      const { data$, total$ } = this._menuItemFacade.getSubMenuItens(
         1,
         30,
         this.menu.id,
         obj
      );
      this.data = data$;
      this.total = total$;
   }

   ngAfterViewInit() {
      this.subMenuTable.userInteractions$.subscribe((response) => {
         switch (response?.eventType) {
            case "DROPDOWN_PRESSED": {
               if (response.payload.event) {
                  this._menuItemFacade.createTabsGroup(
                     response.payload.data.id
                  );
               }
               break;
            }
            case "IS_ACTIVE_CHANGE":
               this._menuItemFacade.patchActive(
                  response.payload.data.id,
                  response.payload.data.isActive
               );
               break;
            case "ACTIONS_PRESSED":
               switch (response.payload.event) {
                  case "EDITAR":
                     const edit = {
                        description: response.payload.data.description,
                        url: response.payload.data.url,
                        id: response.payload.data.id
                     };
                     this.openModal(edit);
                     break;
                  case "DELETE":
                     this._menuItemFacade.delete(
                        response.payload.data.id,
                        this.menu.id
                     );
                     break;
               }
               break;
         }
      });
   }

   changePage(event: PaginationEvent) {
      const obj: RequestOptions = {
         query: {
            search: this.formFilterGroup.controls.description.value ?? null,
            isActive: this.formFilterGroup.controls.isActive.value
         }
      };
      this.page = event.page;
      this.numberRegistry = event.numberRegistry;
      this._menuItemFacade.getSubMenuItens(
         event.page,
         event.numberRegistry,
         this.menu.id,
         obj
      );
   }

   openModal(data: any = {}) {
      data.parentId = this.menu.id;
      const edit = {
         obj: data
      };

      const dialog = this._dialog.open(MenuItemModal, {
         width: "calc(900px * var(--proportional))",
         data: edit
      });
   }

   resetFilter() {
      this.formFilterGroup.controls.description.reset(null);
      this.formFilterGroup.controls.isActive.reset(null);
   }
}
