import { CommonModule, NgClass, NgForOf, NgIf } from "@angular/common";
import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { PaginationEvent } from "./pagination.model";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SvgSelectorUIComponent } from "@app/modules_new/shared/components/ui/svg-selector/svg-selector.component";
import { TableFormComponent } from "../table.component";
import { Router } from "@angular/router";

/**
 * Componente para Páginação
 */
@Component({
   selector: "forms-pagination[amount][page]",
   templateUrl: "./pagination.component.html",
   styleUrls: ["./pagination.component.scss"],
   standalone: true,
   imports: [
      NgIf,
      NgForOf,
      NgClass,
      CommonModule,
      FormsModule,
      SvgSelectorUIComponent,
      TranslateModule
   ]
})
export class PaginationTableComponent {
   private _table = inject(TableFormComponent);
   private _maxPages = 5;

   /**
    * Total de valores que endpoint possue
    */
   @Input() amount = 0;
   /**
    * Total de valores que devem ser exibidos em tela
    */
   @Input() totalPerPage = 30;
   /**
    * Página que está sendo exibida
    */
   @Input() page = 1;

   @Output() changePage = new EventEmitter<PaginationEvent>();

   private _router = inject(Router);
   isAdm: boolean = this._router.url.includes("/Admin") ? true : false;

   changeToPage(nextPage: number) {
      this.page = nextPage;
      this._emitChangeInPage();
   }

   toFirstPage() {
      this.page = 1;
      this._emitChangeInPage();
   }

   toBackPage() {
      this.page = this.page - 1;
      this._emitChangeInPage();
   }

   toNextPage() {
      this.page = this.page + 1;
      this._emitChangeInPage();
   }

   toLastPage() {
      this.page = this.totalPages;
      this._emitChangeInPage();
   }

   changeTotalPerPage() {
      this.page = 1;
      this._emitChangeInPage();
   }

   private _emitChangeInPage() {
      this._table.setLoader();
      this.changePage.emit({
         page: this.page,
         numberRegistry: this.totalPerPage
      });
   }

   get totalPages() {
      return Math.ceil(this.amount / this.totalPerPage);
   }

   get isFirstPage() {
      return this.page === 1;
   }

   get isLastPage() {
      return this.totalPages === this.page;
   }

   get availablePages() {
      let initalPage = this.page;

      if (this.page <= 2) {
         initalPage = 1;
      } else if (this.page + this._maxPages > this.totalPages) {
         initalPage =
            this.page - (this.page + this._maxPages - this.totalPages) + 1;
         if (initalPage < 1) initalPage = 1;
      }

      return new Array(
         this._maxPages > this.totalPages ? this.totalPages : this._maxPages
      )
         .fill(0)
         .map((value, index) => ({
            name: index + initalPage,
            isDisabled: this.page === index + initalPage
         }));
   }
}
