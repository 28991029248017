export interface register {
    'createdAt': string,
    'description': string,
    'externalId': string,
    'id': string,
    'isActive': boolean,
    'type': number,
    'updatedAt': string
}

export interface filter {
    'description': string,
    'type': number,
    'isActive': boolean,
    'createdAt': string,
    'updatedAt': string
}

export enum EType{
    Coleta = 0,
    Triagem = 1,
    Lamina = 2,
    Seringa = 3
}