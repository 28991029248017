import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Actions, ETypeEndPoint, ResolvedEndpoints, urlTypeOptions } from './url-redirect.model';
import { EndpointRedirectService } from '@app/services/endpoint-redirect/endpoint-redirect.service';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Guid } from '@app/util/guid';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';

@Component({
  selector: 'app-url-redirect',
  templateUrl: './url-redirect.component.html',
  styleUrls: ['./url-redirect.component.scss']
})
export class UrlRedirectComponent implements OnInit {

  resolvedEndpoints: Array<ResolvedEndpoints> = []
  paginationInfo = {
    page: 1,
    sumRecords: 0,
    numberRegistry: 50
  }

  urlTypeOptions = urlTypeOptions

  formGroupEndpoint = new UntypedFormGroup({
    endpointId: new UntypedFormControl(),
    type: new UntypedFormControl(),
    urlRedirect: new UntypedFormControl(),
  })

  formGroupSearchFilter = new UntypedFormGroup({
    search: new UntypedFormControl(),
  })

  @ViewChild('dialogModal') dialogModal!: TemplateRef<any>

  constructor(
    private _endpointRedirectService: EndpointRedirectService,
    private _dialog: MatDialog,
    private _localaDatePipe: LocalaDatePipe
  ) { }

  async ngOnInit() {
    await this.getEndpoints();
  }

  async getEndpoints(params?: number[]) {
    if (params) {
      this.paginationInfo.page = params[0]
      this.paginationInfo.numberRegistry = params[1]
    }

    await this._endpointRedirectService.getPagination().then(response => {
      if (response.success) {
        this.resolvedEndpoints = response.data.map((endpoint: any) => (
          {
            id: endpoint.id,
            "Tipo": ETypeEndPoint[endpoint.type],
            "URL de Redirecionamento": endpoint.urlRedirect ?? '',
            "Data de Criação": this._localaDatePipe.transform(endpoint.createdAt) ?? '',
            "Data de Alteração": this._localaDatePipe.transform(endpoint.updatedAt) ?? '',
            "Ativo": endpoint.isActive ? 'checkbox|true' : 'checkbox|',
            "_hide_type": endpoint.type
          }
        ))
      }
      this.paginationInfo.sumRecords = response.sumRecords
    })
  }

  ActionsExtra = {
    "Ativo": 'patchActive'
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.setFormsAndOpenModal(emit.object)
        break;
      case 'Excluir':
        this.deleteEndpoint(emit.object.id)
        break;
      case 'patchActive':
        this.patchActive(emit.object.id, emit.object["Ativo"])
        break;
    }
  }

  patchActive(id: string, value: string) {
    this._endpointRedirectService.patchActive(id, value).then(async response => {
      if(response.success) {
        await this.getEndpoints()
        SwAlSetttings.Sucesso(`URL de Redirecionamento ${value === 'active' ? 'inativada' : 'ativada'} com sucesso!`)
      }
    })
  }

  setFormsAndOpenModal(endpoint: ResolvedEndpoints) {
    this.formGroupEndpoint.controls['type'].setValue(endpoint['_hide_type'])
    this.formGroupEndpoint.controls['urlRedirect'].setValue(endpoint['URL de Redirecionamento'])
    this.formGroupEndpoint.controls['endpointId'].setValue(endpoint.id)
    this.openDialogModal()
  }

  openDialogModal() {
    this._dialog.open(this.dialogModal, {
      width: '660px'
    }).afterClosed()
      .subscribe(() => this.formGroupEndpoint.reset())
  }

  deleteEndpoint(id: string) {
    this._endpointRedirectService.delete(new Guid(id)).then(async (response: any) => {
      if (response.success) {
        await this.getEndpoints()
        SwAlSetttings.Sucesso('URL de Redirecionamento excluída com sucesso!')
      }
    })
  }

  updateEndpoint() {
    const endpointObject = this.formGroupEndpoint.value
    const id: Guid = this.formGroupEndpoint.controls['endpointId'].value

    this._endpointRedirectService.put(endpointObject, id).then(async response => {
      if (response.success) {
        await this.getEndpoints()
        SwAlSetttings.Sucesso('URL de Redirecionamento atualizada com sucesso!')
        this._dialog.closeAll()
      }
    })
  }

  createEndpoint() {
    const endpointObject = this.formGroupEndpoint.value
    this._endpointRedirectService.post(endpointObject).then(async response => {
      if (response.success) {
        await this.getEndpoints()
        SwAlSetttings.Sucesso('URL de Redirecionamento criada com sucesso!')
        this._dialog.closeAll()
      }
    })
  }

}
