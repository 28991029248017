//@ts-ignore
import { Guid } from '../util/guid';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './../http.service';
import { BaseService } from './../base.service';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DocumentService extends BaseService {
  constructor(protected http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/documents/document');
  }
}
