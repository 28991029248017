import { UntypedFormControl } from "@angular/forms"
import { DefaultFormats } from "@app/pipe/localeDate.pipe"

export interface Resolve {
  label: string,
  retrive: string,
  method: string,
  after: string,
  before: string
}

export interface Actions {
  action: string,
  context: string | null,
  object: ResolvedCustomerObject
}

interface ResolvedCustomerObject {
  "id": string,
  "Nome": string,
  "Sexo": string,
  "Nascimento": string,
  "Nome da Mãe": string,
  "CNPJ/CPF": string,
  "Celular": string,
  "E-mail": string,
  "Data de Cadastro": string
}

export interface ResolvedAgreementRequestObject {
  "id": string,
  "Convênio": string,
  "Solicitante": string,
  "Conselho": string,
  "Autorização": string,
  "Carteirinha": string,
  "CID": string,
  "Guia": string,
  "Criado em": string,
  "Última Alteração": string,
  "Ativo": string
}

export interface SelectOptions {
  label: string,
  value: string
}

export interface AnalyteOptions {
  code: string,
  value: string,
  label: string
}
export interface MnemonicOptions {  
  value: string,
  label: string
}
export interface UnityOptions {
  collectionPlaceOptions: Array<SelectOptions>,
  value: string,
  label: string
}
export interface formControlExams {
  "id": UntypedFormControl,
  "mne": UntypedFormControl,
  "inp": UntypedFormControl,
  "mat": UntypedFormControl,
  "val": UntypedFormControl,
  "urg": UntypedFormControl,
  "prz": UntypedFormControl,
  "col": UntypedFormControl,
}
export interface ResolveBudget {
  "id": string,
  "Convênio": string,
  "Solicitante": string,
  "Conselho": string,  
  "Valor Bruto": string,
  "Desconto": string,
  "Acréscimo": string,
  "Valor Liquído": string,
}

export interface ResolveExam {
  "id": string,
  "Código": string,
  "Exame": string,
  "Material": string,
  "Valor Bruto": string,
  "Desconto": string,
  "Acréscimo": string,
  "Valor Liquído": string
}

export interface DoctorOptions {
  label: string,
  value: string
}

export const resolveValue = [        
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },    
  {
    label: 'budgetEdit.resolveValue.description',
    retrive: 'description',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'budgetEdit.resolveValue.plan',
    retrive: '',
    method: 'getPlan',
    after: '',
    before: ''
  },  
  {
    label: "budgetEdit.resolveValue.value",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'value',
    type: 'money'
  },
  {
    label: "budgetEdit.resolveValue.addition",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'addition',
    type: 'money'
  },
  {
    label: "budgetEdit.resolveValue.discount",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'discount',
    type: 'money'
  },
  {
    label: "budgetEdit.resolveValue.balance",
    method: 'formatField',
    retrive: '',
    after: '',
    before: '',
    fieldName: 'balance',
    type: 'money'
  },
  {
    label: 'budgetEdit.resolveValue.action',
    retrive: '',
    method: 'getActionButton',
    after: '',
    before: ''
  }
];
