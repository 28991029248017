import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectComponent } from './components/collect/collect.component';
import { MovimentationRoutingModule } from './movimentation-routing.module';
import { CommonComponentModule } from '@app/components/common-component.module';
import { BrowserModule } from '@angular/platform-browser';
import { DownloadCollectComponent } from './components/download-collect/download-collect.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { SupportLaboratorySendingComponent } from './components/support-laboratory-sending/support-laboratory-sending.component';
import { ScreeningComponent } from './components/screening/screening.component';
import { ProcessesComponent } from './components/processes/processes.component';
import { TypingComponent } from './components/typing/typing.component';
import { ConferenceComponent } from './components/conference/conference.component';
import { LiberationComponent } from './components/liberation/liberation.component';
import { PrintingComponent } from './components/printing/printing.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { MatDividerModule } from '@angular/material/divider';
import { ShippingDistributionComponent } from './components/shipping-distribution/shipping-distribution.component';
import { CreateShippingDistributionComponent } from './components/create-shipping-distribution/create-shipping-distribution.component';
import { DiscountAuthorizationComponent } from './components/discount-authorization/discount-authorization.component';


@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    CommonComponentModule,
    MovimentationRoutingModule,
    MatIconModule,
    MatTooltipModule,
    MatTabsModule,
    MatDividerModule
  ],
  declarations: [
    CollectComponent,
    DownloadCollectComponent,
    SupportLaboratorySendingComponent,
    ScreeningComponent,
    ProcessesComponent,
    TypingComponent,
    ConferenceComponent,
    LiberationComponent,
    PrintingComponent,
    DeliveryComponent,
    DiscountAuthorizationComponent,
    ShippingDistributionComponent,
    CreateShippingDistributionComponent
  ],
  exports: [
    MovimentationRoutingModule,
  ]
})
export class MovimentationModule { }
