import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { PublicApiHashService } from "@app/services/auth/apihash/apihash.service";
import { RedirectWinService } from "@app/services/redirect/redirect.service";
import { UserService } from "@app/services/user/user.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Subscription } from "rxjs";

@Component({
   selector: "app-redirect",
   templateUrl: "./redirect.component.html",
   styleUrls: ["./redirect.component.scss"]
})
export class RedirectComponent implements OnInit {
   routeSubscription!: Subscription;
   helper = new JwtHelperService();
   constructor(
      private route: ActivatedRoute,
      private _redirectService: RedirectWinService,
      private _publicApiHashService: PublicApiHashService,
      private _userService: UserService
   ) {}

   ngOnInit(): void {
      this.routeSubscription = this.route.queryParams.subscribe(
         async (queryParams) => {
            const urlValidated: boolean = this._validateUrl(queryParams);
            let userIsAuthenticated: boolean = false;

            if (urlValidated) {
               const hashToken = queryParams["hash"];
               const hashMenu = queryParams["id"];
               const companyId = queryParams["companyId"];
               const unityId = queryParams["unityId"];

               const userObject = {
                  accessKey: hashToken
               };
               var tenantId = "";
               var accessCode = "";

               await this._publicApiHashService
                  .postToPath(userObject, "loginuser")
                  .then((response) => {
                     if (response.accessToken && response.refreshToken) {
                        tenantId = this.helper
                           .decodeToken(response.accessToken)
                           .tid.replace(";", "");
                        accessCode = this.helper.decodeToken(
                           response.accessToken
                        ).email;
                        localStorage.setItem("token", response.accessToken);
                        localStorage.setItem(
                           "refreshToken",
                           response.refreshToken
                        );
                        localStorage.setItem("TenantId", tenantId);
                        userIsAuthenticated = true;
                     }
                  });

               var companyOptions: any = [];

               if (userIsAuthenticated) {
                  await this._userService
                     .getCompanies(accessCode, tenantId)
                     .then((res: any) => {
                        if (res.data.length > 0) {
                           res.data.map((item: any) => {
                              companyOptions.push({
                                 value: item.id,
                                 label: item.companyName,
                                 unities: item.unities
                              });
                           });
                        }
                     });

                  var unityOptions: any = [];

                  let companys: any = companyOptions.filter(
                     (x: any) => x.value === companyId
                  )[0] as any;

                  if (companys.unities.length > 0) {
                     companys.unities.map((res: any) => {
                        unityOptions.push({ value: res.id, label: res.name });
                     });
                  }

                  let unityInfos = unityOptions.filter(
                     (x: any) => x.value === unityId
                  )[0];

                  localStorage.setItem("unity", JSON.stringify(unityInfos));

                  let companyInfos: any = companyOptions.filter(
                     (x: any) => x.value === companyId
                  )[0] as any;

                  companyInfos = {
                     name: companyInfos.label,
                     id: companyInfos.value
                  } as any;

                  localStorage.setItem("company", JSON.stringify(companyInfos));

                  this._redirectUser(hashMenu);
               }
            } else {
               console.error(
                  `ERROR: Não foi possível capturar os dados!\nURL recebida: ${JSON.stringify(
                     queryParams
                  )}`
               );
               window.location.href = "/";
            }
         }
      );
   }

   public _redirectUser(hashMenu: string) {
      this._redirectService.getMenuByHash(hashMenu).subscribe({
         next: (response) => {
            if (response.success && response.data.length > 0) {
               const url = new URL(response.data[0].urlRedirect);
               if (url.pathname === "/") {
                  window.location.href = "/";
               } else {
                  window.location.href = `${url.pathname}`;
               }
            }
         }
      });
   }

   private _validateUrl(queryParams: Params): boolean {
      return "hash" in queryParams && "id" in queryParams;
   }

   ngOnDestroy(): void {
      this.routeSubscription?.unsubscribe();
   }
}
