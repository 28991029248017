import { Injectable, inject } from "@angular/core";
import { MultiObservableFacade } from "../../../common/data/facades/multi-observable";
import { RequestOptions } from "../../../data/services/types/http.types";
import {
   UserResponse,
   UsersResponse
} from "../services/accesscontrol-user-authNew/accesscontrol-user-authNew.types";
import { AdminAccesscontrolUserAuthNew } from "../services/accesscontrol-user-authNew/accesscontrol-user-authNew.service";
import { MatDialog } from "@angular/material/dialog";
import { UserModalComponent } from "@app/modules_new/admin/components/form/user-modal/user-modal.component";
import { ApiHashIntegrationObject } from "@app/services/auth/admin/apihash/apihash.service";
import { SwAlSetttings } from "@app/util/swal.settings";
import { UserPasswordModalComponent } from "@app/modules_new/admin/components/form/user-password-modal/user-password-modal.component";
import { ApiHashService } from "../services/apiHash/apiHash.service";
import { QueryParams } from "../../../data/services/types/http.types";
import { lastValueFrom } from "rxjs";
import { AccessControlUser } from "@app/modules_new/administrativeData/data/services/accessControlUser/accessControlUser.service";
import { Guid } from "@app/util/guid";
import { TenantProfileService } from "../services/tenantProfile/tenantProfile.service";

@Injectable({
   providedIn: "root"
})
export class UsersAdminUsersFacade extends MultiObservableFacade {
   private _apiService = inject(ApiHashService);
   private _userService = inject(AdminAccesscontrolUserAuthNew);
   private _accessControlUser = inject(AccessControlUser);
   private _tenantProfileService = inject(TenantProfileService);
   private _users = this.createNullableObservableControl<UserResponse[]>();
   private _usersAmount = this.createObservableControl<number>(0);
   private _dialog = inject(MatDialog);
   private controls = this.controlGroup({
      users: this.emptyObservable(),
      usersAmount: this.emptyObservable()
   });

   createUsers(id: string) {
      this.controls.add(id, {
         users: this.createNullableObservableControl<UserResponse[]>(),
         usersAmount: this.createObservableControl<number>(0)
      });
   }

   async getUsersOptions(search?: RequestOptions) {
      try {
         const response: any = await lastValueFrom(
            this._userService.getPagination<UsersResponse>(1, 999999, search)
         );

         if (response.success == true) {
            return response.data.map((x: any) => ({
               value: x.id,
               label: x.name
            }));
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }

      return [];
   }

   getUsersWithoutDropDown(
      page: number,
      numberRegistry: number,
      search?: QueryParams
   ) {
      this._userService
         .getPagination<UsersResponse>(page, numberRegistry, {
            query: search
         })
         .subscribe({
            next: (response) => {
               this._users.subject.next(response.data);
               this._usersAmount.subject.next(response.sumRecords);
            },
            error: (err) => {
               this._users.subject.next([]);
               this._usersAmount.subject.next(0);
            }
         });
      return {
         data$: this._users.observable$,
         total$: this._usersAmount.observable$
      };
   }

   getUsers(
      page: number,
      numberRegistry: number,
      id: string,
      search?: RequestOptions
   ) {
      this.controls.get(id).users.subject.next(null);
      this._userService
         .getPagination<UsersResponse>(page, numberRegistry, search)
         .subscribe({
            next: (response) => {
               this.controls.get(id).users.subject.next(response.data);
               this.controls
                  .get(id)
                  .usersAmount.subject.next(response.data.length);
            },
            error: (err) => {
               this.controls.get(id).users.subject.next([]);
            }
         });
      return {
         data$: this.controls.get(id).users.observable$,
         total$: this.controls.get(id).usersAmount.observable$
      };
   }
   openUserModal(tenantId: string) {
      const dialog = this._dialog.open(UserModalComponent, {
         width: "calc(900px * var(--proportional))",
         data: { tenantId: tenantId }
      });
   }

   openUserModalPassword(accessCode: string, name: string, id: string) {
      const dialog = this._dialog.open(UserPasswordModalComponent, {
         width: "calc(900px * var(--proportional))",
         data: { accessCode: accessCode, name: name, id: id }
      });
   }

   async createApiHashUser(userId: string, tenantId: string) {
      if (userId) userId = userId.replaceAll(" ", "");

      const postObject: ApiHashIntegrationObject = {
         userId: userId
      };

      await SwAlSetttings.GerarCredenciais(
         "Ao criar uma API Hash será gerado um arquivo com suas credenciais, guarde-o com cuidado!"
      ).then(async (answer) => {
         if (answer.isConfirmed) {
            const request: RequestOptions = {
               query: {},
               path: "/user",
               headers: {
                  TenantId: tenantId
               }
            };
            await this._apiService.post(postObject, request).subscribe({
               next: (response: any) => {
                  this.saveApiHashCredencialDocument(
                     response.data,
                     response.name
                  );
                  this._dialog.closeAll();
                  SwAlSetttings.Sucesso("API Hash criada com sucesso!");
               },
               error: (err) => {}
            });
         }
      });
   }

   saveApiHashCredencialDocument(data: any, name: string) {
      let base64 = "data:application/csv;base64," + data;

      const link = document.createElement("a");
      link.href = base64;
      link.download = name;
      link.click();
   }

   async patch(id: string, value: string) {
      let success = false;
      try {
         const response: any = await lastValueFrom(
            this._accessControlUser.patchToggleIsActive(id, !value)
         );
         if (response.success) {
            this.ToastSuccess(
               !value === true
                  ? "toast-msg.change-to-active.success"
                  : "toast-msg.change-to-inactive.success"
            );
            success = response.success;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      //@ts-ignore
      let data = this._users.subject._value;
      let index = data.findIndex((x: any) => x.id == id);

      if (success) {
         data[index].isActive = !value;
      } else {
         data[index].isActive = value;
      }
      this._users.subject.next(data);
      return success;
   }

   async getAccessControl(request: RequestOptions) {
      const response: any = await lastValueFrom(
         this._accessControlUser.get(request)
      );
      return response;
   }

   async deleteAccessControl(id: Guid, request?: any) {
      const response: any = await lastValueFrom(
         this._accessControlUser.delete(id, request)
      );
      if (response.success) {
         this.ToastSuccess("Permissão adicionada com sucesso!");
      }
      return response;
   }

   async postAccessControl(obj: any, request: RequestOptions) {
      const response: any = await lastValueFrom(
         this._accessControlUser.post(obj, request)
      );

      return response;
   }

   async patchActive(id: string, value: string, tenantId: string) {
      let success = false;
      const request: RequestOptions = {
         headers: {
            TenantId: tenantId
         }
      };
      try {
         const response: any = await lastValueFrom(
            this._accessControlUser.patchToggleIsActive(id, !value, request)
         );
         if (response.success) {
            this.ToastSuccess(
               !value === true
                  ? "toast-msg.change-to-active.success"
                  : "toast-msg.change-to-inactive.success"
            );
            success = response.success;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      //@ts-ignore
      let data = this.controls.get(tenantId).users.subject._value;
      let index = data.findIndex((x: any) => x.id == id);

      if (success) {
         data[index].isActive = !value;
      } else {
         data[index].isActive = value;
      }
      this.controls.get(tenantId).users.subject.next(data);
      return success;
   }

   async getById(id: Guid) {
      try {
         const response: any = await lastValueFrom(
            this._userService.getById(id)
         );

         return response.data[0];
      } catch (error) {
         this.ToastErrorGeneric(error);
         return null;
      }
   }

   async postUser(obj: any) {
      try {
         const response: any = await lastValueFrom(this._userService.post(obj));
         this.ToastSuccess("Usuário criado com sucesso!");
         return response.success;
      } catch (error) {
         this.ToastErrorGeneric(error);
         return null;
      }
   }

   async putUser(obj: any, id: Guid) {
      try {
         const response: any = await lastValueFrom(
            this._userService.put(obj, id)
         );

         if (response.success) {
            this.ToastSuccess("Usuário atualizado com sucesso!");
         }

         return response.success;
      } catch (error) {
         this.ToastErrorGeneric(error);
         return null;
      }
   }

   async getProfile() {
      const response: any = await lastValueFrom(
         this._tenantProfileService.get()
      );

      return response;
   }
}
