<modal-layout [title]="'Novo usuário'">
    <form [formGroup]="form" class="my-12 flex flex-col gap-10">
        <div class="flex w-full items-center justify-between">
            <label>Tipo de acesso</label>
            <forms-button-group
                [form]="form.controls.type"
                [options]="[
                    { label: 'E-mail', value: 1 },
                    { label: 'Cód. Acesso', value: 2 }
                ]"
            >
            </forms-button-group>
        </div>
        <forms-input-text
            type="text"
            class="w-full"
            [placeholder]="'Nome'"
            requiredInput="true"
            [form]="form.controls.name"
        ></forms-input-text>
        <forms-input-text
            [placeholder]="
                form.controls.type.value == 1 ? 'E-mail' : 'Código de acesso'
            "
            (change)="CheckIsEmail()"
            type="text"
            class="w-full"
            requiredInput="true"
            [form]="form.controls.email"
        ></forms-input-text>
        <forms-input-text
            mask="(00) 00000-0000"
            type="cellphone"
            class="w-full"
            [placeholder]="'Celular'"
            requiredInput="true"
            [form]="form.controls.cellPhone"
        ></forms-input-text>
        <forms-input-text
            mask="000.000.000-00"
            type="text"
            class="w-full"
            [placeholder]="'CPF'"
            requiredInput="true"
            [form]="form.controls.cpf"
        ></forms-input-text>
        <forms-input-text
            type="password"
            class="w-full"
            [placeholder]="'Senha'"
            requiredInput="true"
            [form]="form.controls.password"
        ></forms-input-text>
        <forms-input-text
            type="password"
            class="w-full"
            [placeholder]="'Repetir senha'"
            requiredInput="true"
            [form]="form.controls.confirmPassword"
        ></forms-input-text>
        <forms-button
            #button
            class="self-center"
            (click)="createUser()"
            classExtra="btn btn-primary"
            [text]="'Cadastrar'"
        ></forms-button>
    </form>
</modal-layout>
