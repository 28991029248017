import Swal from 'sweetalert2';

export const closeSwalFn = () => {
  Swal.close();
};

export enum SwalTranslateDefaults {
  SUCCESS = 'toast.success',
  ERROR = 'toast.error',
  SUCCESS_GENERIC = 'toast.success-generic',
  ERROR_GENERIC = 'toast.error-generic',
  CONFIRMATION_QUESTION = 'toast.confirmation-question',
  IRREVERSIBLE_ALERT = 'toast.irreversible-alert',
  IMPORTANT = 'toast.important',
  BTN_CONFIRM = 'toast.btn-confirm',
  BTN_CANCEL = 'toast.btn-cancel',
  YES = 'toast.yes',
  NO = 'toast.no',
  LOADING = 'toast.loading',
  EXPIRED_ACCESS = 'toast-msg.expired-access',
  EXPIRED_ACCESS_TEXT = 'toast-msg.expired-access-text',
  INVALID_PERMISSION = 'toast-msg.invalid-access',
  INVALID_PERMISSION_TEXT = 'toast-msg.invalid-access-text',
}

export class SwalStatics {
  /**
   * Modal Genérico para solicitar confirmação de usuário
   */
  public static ALERT_GENERIC = Swal.mixin({
    icon: 'warning',
    width: '600px',
    showConfirmButton: true,
    confirmButtonColor: '#404040',
    allowEscapeKey: true,
    allowOutsideClick: true,
    focusConfirm: true,
  });

  /**
   * Modal para solicitar confirmação de usuário
   * @param title label para ser traduzido, ficará em cima de mensagem
   * @param confirnText label para ser traduzido, ficará em botão de confirmação
   * @param cancelText label para ser traduzido, ficará em botão de cancelamento
   */
  public static ALERT_CONFIRMATION = (title: string, confirmText: string, cancelText: string) =>
    Swal.mixin({
      title: title,
      icon: 'info',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: confirmText,
      confirmButtonColor: '#ed753c',
      cancelButtonText: cancelText,
      cancelButtonColor: '#f9b64e',
      allowEscapeKey: false,
      allowOutsideClick: false,
      reverseButtons: true,
      focusCancel: true,
    });

  /**
   * Alerta (parecido com o js.alert) para indicar informação topo central de página
   */
  public static ALERT_NAV = Swal.mixin({
    toast: true,
    backdrop: true,
    position: 'top',
    confirmButtonColor: '#ed753c',
    cancelButtonColor: '#f9b64e',
    allowEscapeKey: true,
    allowOutsideClick: true,
    customClass: {},
    didOpen: (toast: any) => {
      document.body.addEventListener('click', closeSwalFn);
    },
  });

  /**
   * Toast (Top/Direito) com icone de sucesso.
   *
   * @param title label para ser traduzido, ficará em cima de mensagem
   */
  public static ALERT_SUCCESS = (title: string) =>
    Swal.mixin({
      toast: true,
      position: 'top-right',
      title: title,
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      confirmButtonColor: '#ed753c',
      cancelButtonColor: '#f9b64e',
      allowEscapeKey: false,
      didOpen: (toast: any) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

  /**
   * Toast (Top/Direito) com icone de erro.
   *
   * @param title label para ser traduzido, ficará em cima de mensagem
   */
  public static ALERT_ERROR = (title: string) =>
    Swal.mixin({
      toast: true,
      position: 'top-right',
      title: title,
      icon: 'error',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      confirmButtonColor: '#ed753c',
      cancelButtonColor: '#f9b64e',
      allowEscapeKey: false,
      reverseButtons: true,
      didOpen: (toast: any) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

  /**
   * Modal para indicar carregamento e exibir loading
   * @param title label para ser traduzido, ficará em cima de icone
   */
  public static ALERT_LOADER = (title: string) =>
    Swal.mixin({
      title: title,
      showConfirmButton: false,
      didOpen: () => Swal.showLoading(),
    });

  /**
   * Modal para indicar carregamento e exibir loading
   * @param title label para ser traduzido, ficará em cima de icone
   */
  public static ALERT_DELETE = (title: string, text: string, confirmText: string, cancelText: string) =>
    Swal.mixin({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmText,
      confirmButtonColor: 'rgba(230, 051, 042, 1)',
      cancelButtonText: cancelText,
      cancelButtonColor: '#f9b64e',
      allowEscapeKey: false,
      allowOutsideClick: false,
      reverseButtons: true,
    });

  public static WARNING_INFO = (title: string) =>
  Swal.mixin({
      toast: true,
      position: 'top-right',
      title: title,
      icon: 'warning',
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      confirmButtonColor: '#ed753c',
      cancelButtonColor: '#f9b64e',
      allowEscapeKey: false,
      didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
  });
}
