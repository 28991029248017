import { Location } from "@angular/common";
import { Injectable, inject } from "@angular/core";
import { MultiObservableFacade } from "../../../common/data/facades/multi-observable";
import { CustomersService } from "../services/customers/customers.service";
import {
   CustomerResponse,
   CustomersResponse
} from "../services/customers/customers.types";
import { QueryParams } from "../../../data/services/types/http.types";
import { Guid } from "@app/util/guid";
import { Util } from "@app/util/util";
import { CreateCustomer } from "@app/services/customers/customers.types";
import { lastValueFrom } from "rxjs";

@Injectable({
   providedIn: "root"
})
export class CustomersFacade extends MultiObservableFacade {
   private _location = inject(Location);
   private _util = inject(Util);
   private _customersService = inject(CustomersService);
   private _customers =
      this.createNullableObservableControl<CustomerResponse[]>();
   private _customer = this.createNullableObservableControl<CustomerResponse>();
   private _customersAmount = this.createObservableControl<number>(0);

   private controls = this.controlGroup({
      customer: this.emptyObservable()
   });

   private _custumerPage = this.createObservableControl<number>(1);
   private _custumerRegistry = this.createObservableControl<number>(30);

   get(query: QueryParams = {}) {
      if (query["page"] && query["numberRegistry"]) {
         this._custumerPage.subject.next(query["page"] as number);
         this._custumerRegistry.subject.next(query["numberRegistry"] as number);
      }

      query["page"] = this._custumerPage.subject.value;
      query["numberRegistry"] = this._custumerRegistry.subject.value;

      this._customersService
         .get<CustomersResponse>({
            query
         })
         .subscribe({
            next: (response) => {
               this._customers.subject.next(response.data);
               this._customersAmount.subject.next(response.sumRecords);
            },
            error: (err) => {
               this._customers.subject.next([]);
               this._customersAmount.subject.next(0);
            }
         });
      return {
         data$: this._customers.observable$,
         total$: this._customersAmount.observable$
      };
   }

   getCustomers(
      page: number,
      numberRegistry: number,
      search?: QueryParams,
      showWarning?: boolean
   ) {
      this._customers.subject.next(null);
      this._customersAmount.subject.next(0);
      this._customersService
         .getPagination<CustomersResponse>(page, numberRegistry, {
            query: search
         })
         .subscribe({
            next: (response) => {
               this._customers.subject.next(response.data);
               this._customersAmount.subject.next(response.sumRecords);
               if (response.data.length === 0 && showWarning) {
                  if (search?.["cpf"] !== null) {
                     this.warningMessage(
                        `Cliente ${search?.["cpf"]} não encontrado!`
                     );
                  } else if (search?.["name"] !== null) {
                     this.warningMessage(
                        `Cliente ${search?.["name"]} não encontrado!`
                     );
                  } else if (search?.["BirthDate"] !== null) {
                     this.warningMessage(
                        `Cliente ${search?.["BirthDate"]} não encontrado!`
                     );
                  }
               }
            },
            error: (err) => {
               this._customers.subject.next([]);
               this._customersAmount.subject.next(0);
            }
         });
      return {
         data$: this._customers.observable$,
         total$: this._customersAmount.observable$
      };
   }

   async getCustomersOptions(search?: QueryParams) {
      try {
         const response: any = await lastValueFrom(
            this._customersService.get<CustomersResponse>({
               query: search
            })
         );

         if (response.success == true) {
            return response.data.map((x: any) => ({
               value: x.id,
               label: x.name,
               externalId: x.externalId
            }));
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }
      return [];
   }

   emptyTable() {
      this._customers.subject.next([]);
      this._customersAmount.subject.next(0);
      return {
         data$: this._customers.observable$,
         total$: this._customersAmount.observable$
      };
   }

   loadCustomerForRequest(id: string) {
      this.controls.add(id, {
         customer: this.createNullableObservableControl<any[]>()
      });
      this._customersService
         .getById<CustomersResponse>(new Guid(id))
         .subscribe({
            next: (response) => {
               this.controls.get(id).customer.subject.next(response.data[0]);
            },
            error: (error) => {
               this.controls.get(id).customer.subject.next([]);
            }
         });
      return {
         data$: this.controls.get(id).customer.observable$
      };
   }

   async getCustomerById(id: Guid): Promise<any> {
      const response = await lastValueFrom(
         this._customersService.getById<CustomersResponse>(id)
      );
      return response?.data[0];
   }

   async patchActive(customerId: string, value: string) {
      return new Promise((resolve, reject) => {
         this._customersService
            .patchToggleIsActive(customerId, !value)
            .subscribe({
               next: (res) => {
                  this.ToastSuccess("toast-msg.change.success");
                  resolve(res);
               },
               error: (error) => {
                  this.ToastErrorGeneric(error, "toast-msg.creation.error");
               }
            });
      });
   }

   async getSex() {
      return await this._util.getEnum("EGenderType");
   }

   async createCustomer(
      request: CreateCustomer,
      isCreateRequisition: boolean = false
   ) {
      try {
         const response: any = await lastValueFrom(
            this._customersService.post(request)
         );
         if (isCreateRequisition) {
            return response.id;
         } else {
            this.ToastSuccess("customer.msgPost");
            return response.id;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }
   }

   async updateCustomer(
      id: Guid,
      request: CreateCustomer,
      isCreateRequisition: boolean = false
   ) {
      try {
         const response: any = await lastValueFrom(
            this._customersService.put(request, id)
         );
         if (isCreateRequisition) {
            return response.id;
         } else {
            this.ToastSuccess("customer.msgPut");
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }
   }

   backPage() {
      this._location.back();
   }
}
