<div style="box-shadow: inset 0 0 2px #404040" [class]="classe">
    <div class="flex align-center">
        <label *ngIf="label">
            {{ label }}
        </label>
        <mat-form-field appearance="fill" class="textAreaAuto w-full">
            <textarea
                #inputElement
                matInput
                [formControl]="form"
                [readonly]="readonly"
                [placeholder]="placeholder | translate"
                [tabindex]="tabindexcomponent"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                [cdkAutosizeMaxRows]="maxRows"
            ></textarea>
            <label for="">{{ placeholder | translate }}</label>
        </mat-form-field>
        <div class="error flex justify-end" *ngIf="form && requiredInput">
            <span *ngIf="showError()">{{
                form.errors | errorTranslate : form.value
            }}</span>
        </div>
    </div>
</div>
