import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './../base.service';
import { HttpService } from './../http.service';
import { HttpResponseLogin } from './user-login.types';

@Injectable({ 
  providedIn: 'root'
})
export class UserLoginService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/public/user/login');
  }

  override post(body: Object) {
    return super.post(body) as Promise<HttpResponseLogin>
  }

}