<div class="flex">
    <div class="label">
        <label>{{ "select-language.label" | translate }}:</label>
    </div>
    <mat-form-field style="width: 55px" appearance="none">
        <mat-select class="selectLanguage" [value]="selectedlanguage">
            <mat-select-trigger class="language">
                <img
                    *ngIf="selectedlanguage == 'pt-BR'"
                    width="35"
                    height="25"
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/0/05/Flag_of_Brazil.svg/320px-Flag_of_Brazil.svg.png"
                />
                <img
                    *ngIf="selectedlanguage == 'en'"
                    width="35"
                    height="25"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/320px-Flag_of_the_United_States.svg.png"
                />
                <img
                    *ngIf="selectedlanguage == 'es'"
                    width="35"
                    height="25"
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/9/9a/Flag_of_Spain.svg/800px-Flag_of_Spain.svg.png"
                />
            </mat-select-trigger>
            <mat-option
                *ngFor="let language of options"
                [value]="language.lang"
                [selected]="language.lang === currentLang"
                (onSelectionChange)="changeLang($event)"
                class="class.custom-mat-option"
            >
                <img
                    *ngIf="language.lang == 'pt-BR'"
                    width="35"
                    height="25"
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/0/05/Flag_of_Brazil.svg/320px-Flag_of_Brazil.svg.png"
                />
                <img
                    *ngIf="language.lang == 'en'"
                    width="35"
                    height="25"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/320px-Flag_of_the_United_States.svg.png"
                />
                <img
                    *ngIf="language.lang == 'es'"
                    width="35"
                    height="25"
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/9/9a/Flag_of_Spain.svg/800px-Flag_of_Spain.svg.png"
                />
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
