import { createMask } from "@ngneat/input-mask";

export const dateMask = createMask<Date>({
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy',
    placeholder: 'dd/mm/yyyy',
    parser: (value: string) => {
      return formatStringToDate(value)
    },
});

export const datetimeMask = createMask<Date | undefined>({
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy HH:MM',
    placeholder: 'dd/mm/yyyy HH:MM',
    parser: (value: string) => {
      return formatStringToDatetime(value)
    },
});

export const timeMask = createMask<String>({
    alias: 'datetime',
    inputFormat: 'HH:MM',
    placeholder: 'HH:MM',
    positionCaretOnClick: 'select',
    positionCaretOnTab: false,
});

/**
 * Transforms String to Date
 * @param value 01/01/2024 11:00
 * @returns new Date()
 */
const formatStringToDatetime = (value: string): Date | undefined => {
  const datas = value.split(" ")
  const hours = datas[1].split(":")
  const values = datas[0].split('/');
  const year = +values[2];
  const month = +values[1] - 1;
  const date = +values[0];

  const invalidDate = year && month && date && +hours[0] && +hours[1]

  return isNaN(invalidDate) ? undefined : new Date(year, month, date,  +hours[0], +hours[1])
}

/**
 * Transforms String to Date
 * @param value 01/01/2024 11:00
 * @returns new Date()
 */
const formatStringToDate = (value: string): Date => {
  const values = value.split('/');
  const year = +values[2];
  const month = +values[1] - 1;
  const date = +values[0];
  return new Date(year, month, date)
} 

/**
 * Transforms Date to String with only date
 * @param value new Date()
 * @returns dd/mm/yyyy as {string} 
 */
export const formatDateToString = (value: Date): string => {
  return new Date(value).toLocaleDateString()
}

/**
 * Transforms Date to String with hours
 * @param value ew Date()
 * @returns dd/mm/yyyy HH:MM as {string} 
 */
export const formatDatetimeToString = (value: Date): string => {
  const dates = new Date(value).toLocaleString().replace(",", "").split(" ")
  const hours = dates[1].split(":")
  return `${dates[0]} ${hours[0]}:${hours[1]}`
}