import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { TemplateGeneratorComponent } from "./components/template-generator/template-generator.component";
import { UnityParametersComponentOld } from "./components/unity-parameters/unity-parameters.component";
import { SaveUnityParametersComponentOld } from "./components/unity-parameters/save-unity-parameters/save-unity-parameters.component";

const routes: Routes = [
   {
      path: "ParametrosOld",
      children: [
         { path: "", redirectTo: "ParametrosOld", pathMatch: "full" },
         {
            path: "Configurador-Protocolo",
            component: TemplateGeneratorComponent
         },
         { path: "Parametros-Unidade", component: UnityParametersComponentOld },
         {
            path: "Parametros-Unidade/:id",
            component: SaveUnityParametersComponentOld
         }
      ]
   }
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule]
})
export class ParametersRoutingModule {}
