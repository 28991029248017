import { NgClass, NgIf } from "@angular/common";
import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   ElementRef,
   ViewChild
} from "@angular/core";
import { ReactiveFormsModule, FormControl, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ErrorTranslatePipe } from "@app/modules_new/shared/pipe/error-translate.pipe";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { TranslateModule } from "@ngx-translate/core";
import { NgxMaskModule } from "ngx-mask";

@Component({
   selector: "forms-input-text",
   templateUrl: "input-text.component.html",
   styleUrls: ["input-text.component.scss"],
   standalone: true,
   imports: [
      MatFormFieldModule,
      MatInputModule,
      NgIf,
      NgClass,
      ReactiveFormsModule,
      NgxMaskModule,
      ErrorTranslatePipe,
      TranslateModule,
      MatIconModule,
      MatTooltipModule
   ]
})
export class InputTextFormComponent implements OnInit {
   @Input() type = "text";
   @Input() readonly = false;
   @Input() form: FormControl = new FormControl(null);
   @Input() placeholder = "";
   @Input() label = "";
   @Input() mask: any = "";
   @Input() className = "";
   @Input() requiredInput?: string;
   @Input() pattern = "";
   @Input() prefix = "";
   @Input() noLabelUp = false;
   @Input() autocomplete = false;
   @Input() greenBorder = false;
   @Input() redBorder = false;
   @Input() yellowBorder = false;
   @Input() tabindexcomponent = "";
   @Input() min?: string;
   @Input() max?: string;
   @Input() maxlenght?: string;
   @Input() searchBtn: boolean = false;
   @Input() onFocus = false;
   @Input() attributeData: any = null;

   @Output() changed: EventEmitter<string> = new EventEmitter();
   @Output() focusOut: EventEmitter<string> = new EventEmitter();
   @Output() searchEmit = new EventEmitter();

   @ViewChild("inputElement") inputElement!: ElementRef;

   isPasswordVisible = false;
   showMessagePhone = false;
   passwordType = "";
   defaultClass = "";

   ngOnInit(): void {
      this.defaultClass = this.className + " input-row";
      this.passwordType = this.type;

      // this.form.valueChanges.subscribe((data) => {
      //    this.showMessagePhone = false;
      //    if (
      //       data &&
      //       this.mask == "(00) 00000-0000" &&
      //       this.isValidCellPhone(data)
      //    ) {
      //       this.showMessagePhone = true;
      //    }
      //    if (
      //       data &&
      //       this.mask == "(00) 0000-0000" &&
      //       this.isValidTelephone(data)
      //    ) {
      //       this.showMessagePhone = true;
      //    }
      // });

      // if (this.form?.hasValidator(Validators.required)) {
      //    this.requiredInput = "true";
      //    this.form.markAsTouched({ onlySelf: true });
      // }

      // TODO: ?????
      // if (this.prefix) {
      //   this.form.setValue('111');
      // }
      this.setMaxValue();
   }

   ngAfterViewInit(): void {
      setTimeout(() => {
         if (this.onFocus) {
            this.inputElement.nativeElement.focus();
         }
         if (this.attributeData?.decimal && !this.readonly) {
            this.setColors();
         }
      });
   }

   /**
    * @description Esta função verifica se o atributo `max` não está definido e se o tipo do input
    * é `'date'` ou `'datetime-local'`. Se essas condições forem atendidas, a função
    * define um valor máximo/max padrão.
    */
   setMaxValue() {
      if (
         !this.max &&
         (this.type === "date" || this.type === "datetime-local")
      ) {
         this.max = this.type === "date" ? "2999-12-31" : "2999-12-31T23:59";
      }
   }

   /**
    * @description Manipula o evento de mudança do campo de input e atualiza o formulário ou emite o novo valor.
    * Esta função processa o novo valor do campo de input com base em seu tipo.
    * Se o tipo do input não for `'date'`, `'time'` ou `'datetime-local'`, ela atualiza
    * o controle do formulário associado com o novo valor. Independentemente do tipo,
    * a função emite o evento `changed` com o valor atualizado.
    *
    * @param {string} value - O novo valor do campo de input.
    */
   onChange(value: string) {
      if (
         this.type != "date" &&
         this.type != "time" &&
         this.type != "datetime-local"
      ) {
         this.form.setValue(value);
      }
      setTimeout(() => {
         this.changed.emit(value);
      });
   }

   changePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
      this.type = this.isPasswordVisible ? "text" : "password";
   }

   showError(): boolean {
      if (this.form.touched && this.form?.hasValidator(Validators.required)) {
         return true;
      }

      return false;
   }

   focusInput() {
      this.inputElement.nativeElement.focus();
      setTimeout(() => {
         this.form.markAsTouched({ onlySelf: true });
      });
   }

   focusOutInput() {
      if (
         (this.type == "date" || this.type == "datetime-local") &&
         this.form.value == ""
      ) {
         this.form.setValue(null);
      }
      this.focusOut.emit();
   }

   get HasErrors(): boolean {
      if (this.form?.touched) {
         return this.form.errors !== null;
      }
      return false;
   }

   emit() {
      this.searchEmit.emit();
   }

   onlyNumber(event: KeyboardEvent): void {
      if (this.attributeData?.decimal && !this.readonly) {
         const allowedCharacters = [
            "Home",
            "End",
            "Tab",
            "Backspace",
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
         ];

         if (!allowedCharacters.includes(event.key) && !event.ctrlKey) {
            event.preventDefault();
         } else {
            setTimeout(() => {
               this.setColors();
            });
         }
      }
   }

   setColors() {
      this.redBorder = false;
      this.greenBorder = false;
      this.yellowBorder = false;

      const rawValue = this.form.value?.replace(/\D/g, "");

      if (rawValue == undefined) {
         return;
      }

      if (rawValue === "") {
         this.form.setValue("");
         return;
      }

      const decimals = this.attributeData.decimal;
      let integerPart: string;
      let decimalPart: string;

      if (rawValue.length <= decimals) {
         integerPart = "";
         decimalPart = rawValue.padStart(decimals, "0");
      } else {
         integerPart = rawValue.slice(0, rawValue.length - decimals);
         decimalPart = rawValue.slice(-decimals);
      }

      integerPart = integerPart.replace(/^0+/, "");
      if (integerPart === "") {
         integerPart = "0";
      }

      this.form.setValue(`${integerPart},${decimalPart}`);

      const value = parseFloat(`${integerPart}.${decimalPart}`);

      if (
         value > this.attributeData.maxAbsurdValue ||
         value < this.attributeData.minAbsurdValue
      ) {
         this.redBorder = true;
      } else if (
         (this.attributeData.valueMax && value > this.attributeData.valueMax) ||
         (this.attributeData.valueMin && value < this.attributeData.valueMin)
      ) {
         this.yellowBorder = true;
      } else if (
         this.attributeData.valueMax &&
         value < this.attributeData.valueMax &&
         this.attributeData.valueMin &&
         value > this.attributeData.valueMin
      ) {
         this.greenBorder = true;
      }
   }
}
