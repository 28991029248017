import {
   Component,
   NgZone,
   OnInit,
   TemplateRef,
   ViewChild
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { RequisitionService } from "@app/services/requisitions/requisition.service";
import { Guid } from "@app/util/guid";
import { MatDialog } from "@angular/material/dialog";
import { ModalRequisitionsComponent } from "@app/modules/registers/components/requisition/modal-requisitions/modal-requisitions.component";
import { CustomersService } from "@app/services/customers/customers.service";
import { RequisitionReportTagService } from "@app/services/requisitions/requisition-tag-report/requisition-report-tag.service";
import { ETypeTagCollect } from "@app/util/ETypeTag";
import { RequisitionReportProtocol } from "@app/services/requisitions/requisition-report-protocol/requisition-report-protocol.service";
import { TemplateService } from "@app/services/template/template.service";
import { ResumeRequisitionService } from "@app/services/requisitions/resume/resume-requisition.service";
import { DeliveryProtocolService } from "@app/services/delivery-protocol/delivery-protocol.service";
import { RequisitionReportService } from "@app/services/requisition-report/requisition-report.service";
import { RequisitionTagsService } from "@app/services/requisition-tags/requisition-tags.service";
import decodedToken from "@app/util/Token";
import { BehaviorSubject, Observable, map } from "rxjs";
import { ProfileUserAuth } from "@app/services/auth/profile-user-auth.service";
import { ConcentIntegrationService } from "@app/services/concent-integration/concent-integration.service";
import {
   Actions,
   resolveRequisitions,
   resolveResumeExams,
   resolveOccurrencies
} from "./requisitions.model";
import { Util } from "@app/util/util";
import { AdvancedMaintenanceCopyService } from "@app/services/advanced-maintenance-copy/advanced-maintenance-copy.service";
import { CollectionPlaceService } from "@app/services/collection-places/collection-place.service";
import { CompanyService } from "@app/services/company/company.service";
import { CompanyOption } from "../../financial/bearer/bearer-list/bearer.model";
import { UnityService } from "@app/services/shared/unity.service";
import { RequisitionDocumentsService } from "@app/services/requisitions/documents/requisition-documents.service";
import { EnumObject } from "@app/services/enum/enum.service.model";
import { ToastService } from "@app/services/translate/toast.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { QueryParams } from "@app/services/interfaces/services.types";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { TypeOcurrencyService } from "@app/services/typeOcurrency/typeOcurrency.service";
import { OcurrencyService } from "@app/services/ocurrency/ocurrency.service";
import { SwAlSetttings } from "@app/util/swal.settings";

@Component({
   selector: "app-requisitions",
   templateUrl: "./requisitions.component.html",
   styleUrls: ["./requisitions.component.scss"]
})
export class RequisitionsComponent implements OnInit {
   //#region Global Variables / Variáveis Globais
   @ViewChild("dialogModalAllReports")
   dialogModalAllReports!: TemplateRef<any>;

   @ViewChild("dialogCopy")
   dialogCopy!: TemplateRef<any>;

   companyOptions: Array<CompanyOption> = [];
   @ViewChild("dialogDocuments")
   dialogDocuments!: TemplateRef<any>;

   @ViewChild("dialogModalAllInstructions")
   dialogModalAllInstructions!: TemplateRef<any>;

   @ViewChild("dialogModalOccurrency")
   dialogModalOccurrency!: TemplateRef<any>;

   @ViewChild("dialogModalGenerateOccurrency")
   dialogModalGenerateOccurrency!: TemplateRef<any>;

   fileDocuments: Array<any> = [];

   hasExams: { [key: string]: boolean } = {};
   tenantId: Guid = new Guid(localStorage.getItem("TenantId")?.toString());
   dateNow: string = `${new Date().toLocaleDateString()}`;
   total: number = 0;
   page: number = 1;
   index: number = 50;
   matTabIndex = 0;

   personRequisitionsTotal: number = 0;
   personRequisitionsPage: number = 1;
   personRequisitionsIndex: number = 50;

   requisitionSituation: { [key: number]: string } = {
      0: "Em Atendimento",
      1: "Falta Material / Não Coletado",
      2: "Parcial",
      3: "Em Andamento",
      4: "Liberado",
      20: "Aguardando Coleta",
      30: "Em Coleta"
   };

   getParameters: string = `?page=${this.page}&index=${this.index}`;
   occurrencies: any = [];
   typeOccurrency = [];
   initialDate: string = ``;
   finalDate: string = ``;
   //#endregion
   exams: Array<Object> = [];
   //#region Unity Variables / Unidades referentes às Unidades.
   unities: Array<any> = [];
   unity: Array<any> = [];
   unityOptions: Array<any> = [];
   unityIndex: number = 0;
   unityId: string = "";
   //#endregion

   //#region Customer Variables / Variáveis referentes ao Cliente.
   gender: Array<EnumObject> = [];

   customerOptions: Array<Object> = [];
   //#endregion

   //#region Filial Variables / Variáveis referentes às Filiais
   collectionPlace: Array<any> = [];
   collectionPlaceOptions: Array<any> = [];
   collectionPlaceIndex: number = 0;
   collectionPlaceId: string = "";
   //#endregion

   alterCustomer: boolean = false;
   firstCustomer: boolean = true;
   showFilters: boolean = false;

   //#region Registers and Resolves
   registerRequisitions: Array<any> = [];
   personRequisitions: Array<any> = [];

   //#endregion

   //#region Forms / Formulários
   form = {
      requisitionCollectionPlace: new UntypedFormControl(null),
      searchByRequisitionNumber: new UntypedFormControl(null),
      searchByCustomerName: new UntypedFormControl(null),
      searchByCustomerCPF: new UntypedFormControl(null),
      searchByCustomerBirthDate: new UntypedFormControl(null),
      requisitionCollectionPlaceDescription: new UntypedFormControl(null),
      requisitionCollectionPlaceId: new UntypedFormControl(null),
      searchCustomer: new UntypedFormControl(null),
      companyId: new UntypedFormControl("")
   };

   formUnity = {
      requisitionUnity: new UntypedFormControl(null),
      requisitionUnityName: new UntypedFormControl(null),
      requisitionUnityId: new UntypedFormControl(null)
   };

   formDate = {
      initialDate: new UntypedFormControl(null),
      finalDate: new UntypedFormControl(null)
   };

   requisitionForm = {
      customerId: new UntypedFormControl(null),
      customerName: new UntypedFormControl(null),
      tenantId: new UntypedFormControl(null),
      tenantCompanyName: new UntypedFormControl(null),
      unityId: new UntypedFormControl(null),
      unityName: new UntypedFormControl(null),
      birthDate: new UntypedFormControl(null),
      genderType: new UntypedFormControl(null),
      clinicalInformation: new UntypedFormControl(null)
   };

   formRequisitionReports = {
      collectTagCheckBox: new UntypedFormControl(false),
      deliveryProtocolCheckBox: new UntypedFormControl(false),
      reportCheckBox: new UntypedFormControl(false),
      looseTagCheckBox: new UntypedFormControl(false),
      preparation: new UntypedFormControl(false)
   };

   formTagReport = {
      tagType: new UntypedFormControl(null)
   };

   formOcurrency = {
      defaultHour: new UntypedFormControl(""),
      type: new UntypedFormControl(null),
      observation: new UntypedFormControl(null)
   };

   requisitionFiles = new UntypedFormControl([]);
   //#endregion

   tagTypeOptions: Array<EnumObject> = [];

   registerExams: any = {};

   colectionPlaceOption: Array<Object> = [];

   //@ViewChild(RequisitionProtocolReportComponent) deliveryProtocolComponent! : Component

   private _collectionPlacesSubject = new BehaviorSubject(
      this.collectionPlaceOptions
   );
   private _unitySubject = new BehaviorSubject(this.unityOptions);

   collectionPlaces$: Observable<Array<Object>> =
      this._collectionPlacesSubject.asObservable();
   unities$: Observable<Array<Object>> = this._unitySubject.asObservable();

   ActionsExtra = {
      Divergências: "occurrency"
   };

   constructor(
      private _typeOcurrencyService: TypeOcurrencyService,
      private _occurrencyService: OcurrencyService,
      private _dialog: MatDialog,
      private _requisitionService: RequisitionService,
      private _customerService: CustomersService,
      private _router: Router,
      private _requisitionProtocolReport: RequisitionReportProtocol,
      private _resumeRequisitionService: ResumeRequisitionService,
      private _requisitionTagReport: RequisitionReportTagService,
      private _templateService: TemplateService,
      private _requisitionDocumentService: RequisitionDocumentsService,
      private _deliveryProtocolService: DeliveryProtocolService,
      private _requisitionReportService: RequisitionReportService,
      private _requisitionTagsService: RequisitionTagsService,
      private _profileUserAuth: ProfileUserAuth,
      private _concentIntegrationService: ConcentIntegrationService,
      private _util: Util,
      private _advancedMaintenanceCopy: AdvancedMaintenanceCopyService,
      private _collectionPlaceService: CollectionPlaceService,
      private _companyService: CompanyService,
      private _unityService: UnityService,
      private _toastService: ToastService,
      private _localaDatePipe: LocalaDatePipe
   ) {}

   async ngOnInit() {
      this.formDate.initialDate.setValue(this.incrementDays(new Date(), -30));
      this.formDate.finalDate.setValue(new Date());
      await this.getCompanies();
      await this.getUnity();
      await this.getCollectionPlace();
      await this.getPerson();

      const lastRequisition = localStorage.getItem("lastRequisition");
      if (lastRequisition) {
         const lastRequisitionObj = JSON.parse(lastRequisition);
         this.form.companyId.setValue(lastRequisitionObj.companyId);
         this.formUnity.requisitionUnity.setValue(lastRequisitionObj.unityId);
         this.form.requisitionCollectionPlace.setValue(
            lastRequisitionObj.collectionPlaceId
         );
         this.form.searchByRequisitionNumber.setValue(
            lastRequisitionObj.requisitionNumber
         );
      }
      await this.getRequisitions();

      this.tagTypeOptions = await this._util.getEnum("ETypeTagCollect");
      this.gender = await this._util.getEnum("EGenderType");
   }

   openFilters() {
      if (this.showFilters) {
         this.showFilters = false;
      } else {
         this.showFilters = true;
      }
   }

   async getPerson() {
      const personId = decodedToken.pid;
      await this._profileUserAuth
         .getAll()
         .then((res: any) => {
            // this.formUnity.requisitionUnity.setValue(res.data[0].unityId);

            // this.formUnity.requisitionUnityName.setValue(res.data[0].unityd);

            this._unitySubject.next(this.unityOptions);

            const filteredCollectionPlaces = this.collectionPlaceOptions.filter(
               (collectionPlace) =>
                  collectionPlace.unityId ===
                     this.formUnity.requisitionUnity.value ||
                  collectionPlace.unityId == undefined
            );

            this._collectionPlacesSubject.next(filteredCollectionPlaces);

            if (filteredCollectionPlaces.length > 0) {
               if (filteredCollectionPlaces.length > 1) {
                  this.form.requisitionCollectionPlace.setValue(
                     filteredCollectionPlaces[1].value
                  );
               } else {
                  this.form.requisitionCollectionPlace.setValue(
                     filteredCollectionPlaces[0].value
                  );
               }
            }
            // else {
            //   SwAlSetttings.alertaMsg("Essa unidade não possui um Local de Coleta cadastrado!");
            // }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async printRequisitionReports(
      requisitionId: Guid,
      requisitionNumber: number
   ) {
      this.formRequisitionReports.preparation.value === false &&
      this.formRequisitionReports.deliveryProtocolCheckBox.value === false &&
      this.formRequisitionReports.reportCheckBox.value === false &&
      this.formRequisitionReports.collectTagCheckBox.value === false
         ? this._toastService.warningMessage(
              "requisitionPrintingReports.msgPrint"
           )
         : null;
      let tagType: string;
      let tagTypeAction: ETypeTagCollect = ETypeTagCollect.ZebraEpl;

      if (this.formRequisitionReports.collectTagCheckBox.value) {
         tagType = this.formTagReport.tagType.value;
         if (tagType === "Zebra EPL") {
            tagTypeAction = ETypeTagCollect.ZebraEpl;
            this.getRequisitionTags(
               requisitionId,
               tagTypeAction,
               requisitionNumber
            );
         } else if (tagType === "Zebra ZPL") {
            tagTypeAction = ETypeTagCollect.ZebraZpl;
            this.getRequisitionTags(
               requisitionId,
               tagTypeAction,
               requisitionNumber
            );
         } else {
            tagTypeAction = ETypeTagCollect.Argox;
            this.getRequisitionTags(
               requisitionId,
               tagTypeAction,
               requisitionNumber
            );
         }
      }

      if (this.formRequisitionReports.deliveryProtocolCheckBox.value) {
         await this.getRequisitionProtocol(requisitionId, requisitionNumber);

         //this.router.navigateByUrl('/Relatorios/Requisition/Protocol');
      }

      if (this.formRequisitionReports.reportCheckBox.value) {
         await this.getRequisitionReport(requisitionId, requisitionNumber);
         //this.router.navigateByUrl('/Relatorios/Requisition/Protocol');
      }

      if (this.formRequisitionReports.preparation.value) {
         this.openDialogModalAllInstructions(requisitionId);
      }
   }

   async getRequisitionReport(requisitionId: Guid, requisitionNumber: number) {
      let base64 = "";

      await this._requisitionReportService
         .getByPath(`requisitionId=${requisitionId}`, "")
         .then((res: any) => {
            const cleanedData = res.data
               .replace(/\\r/g, "")
               .replace(/\\n/g, "");
            const jsonData = JSON.parse(cleanedData);
            base64 = jsonData;
            this.download(base64, "laudo");
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async getRequisitionTags(
      requisitionId: Guid,
      typeTag: ETypeTagCollect,
      requisitionNumber: number
   ) {
      let base64 = "";
      await this._requisitionTagsService
         .getByPath(`requisitionId=${requisitionId}`, "")
         .then((res: any) => {
            const cleanedData = res.data
               .replace(/\\r/g, "")
               .replace(/\\n/g, "");
            const jsonData = JSON.parse(cleanedData);
            base64 = jsonData;
            this.download(base64, this.generateRandomString(10), "pgts");
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async getRequisitionProtocol(
      requisitionId: Guid,
      requisitionNumber: number
   ) {
      let base64 = "";
      await this._requisitionProtocolReport
         .getByPath(`requisitionId=${requisitionId}`, "")
         .then((res: any) => {
            const cleanedData = res.data
               .replace(/\\r/g, "")
               .replace(/\\n/g, "");
            const jsonData = JSON.parse(cleanedData);
            base64 = jsonData;
            this.download(base64, "protocolo");
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   replaceTemplateHtml(innerHTML: string, variables: any) {
      for (const [searchString, replaceString] of Object.entries(variables)) {
         //@ts-ignore
         innerHTML = innerHTML.replaceAll(searchString, replaceString);
      }

      return innerHTML;
   }

   incrementDays(date: Date, days: number) {
      return new Date(date.setDate(date.getDate() + days));
   }

   openDialogModalRequisition(
      requisitionId: string,
      requisitionNumber: number,
      finished: boolean
   ) {
      this.formRequisitionReports.reportCheckBox.setValue(false);
      this.formRequisitionReports.collectTagCheckBox.setValue(false);
      this.formRequisitionReports.deliveryProtocolCheckBox.setValue(false);
      this.formRequisitionReports.looseTagCheckBox.setValue(false);
      this.formTagReport.tagType.setValue("Zebra EPL");
      const dialogModalAllReports = this._dialog.open(
         this.dialogModalAllReports,
         {
            width: "calc(660px * var(--proportional))",
            data: {
               requisitionId: requisitionId,
               requisitionNumber: requisitionNumber,
               finished: finished
            }
         }
      );
      dialogModalAllReports.afterClosed().subscribe((res) => {});
   }

   async copyRequisiton(isInformCustomer: boolean, requisitionId: string) {
      if (isInformCustomer) {
         this.openDialogModal(
            this.formUnity.requisitionUnity.value,
            this.formUnity.requisitionUnityName.toString(),
            this.form.companyId.value,
            this.form.requisitionCollectionPlace.value,
            true,
            requisitionId
         );
      } else {
         await this._advancedMaintenanceCopy
            .postByPath({ customerId: "" }, requisitionId)
            .then(async () => {
               this._toastService.fireSuccess("requisitionCopy.msgCopySuccess");
               this.close();
               await this.getRequisitions();
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });
      }
   }

   openDialogCopy(requisitionId: string) {
      const dialogCopy = this._dialog.open(this.dialogCopy, {
         width: "calc(500px * var(--proportional))",
         data: {
            requisitionId: requisitionId
         }
      });
   }

   close() {
      this._dialog.closeAll();
   }

   openDialogModal(
      unityId: string,
      unityName: string,
      collectionPlaceId: string,
      companyId: string,
      copy?: boolean,
      requisitionId?: string
   ) {
      if (!unityId) {
         this._toastService.warningMessage(
            "requisition.msgInfoUnityeCollection"
         );
      } else if (!collectionPlaceId) {
         this._toastService.warningMessage("requisition.msgInfoCollection");
      }

      if (unityId && collectionPlaceId) {
         if (this.unityOptions.filter((x) => x.value === unityId)[0]) {
            const myTempDialog = this._dialog.open(ModalRequisitionsComponent, {
               maxHeight: "540px",
               data: {
                  dialogUnityId: unityId,
                  dialogUnityName: unityName,
                  dialogCollectionPlaceId: collectionPlaceId,
                  copy: copy,
                  requisitionId: requisitionId,
                  companyId: companyId
               },
               autoFocus: "input"
            });
            myTempDialog.afterClosed().subscribe(async () => {
               await this.getRequisitions();
            });
         } else {
            this._toastService.warningMessage("requisition.msgUnitInactive");
         }
      }
   }

   async getRequisitionByUnity() {
      this.total = 0;
      this.page = 1;
      this.index = 50;
      if (this.formUnity.requisitionUnity.value) {
         await this.getByIdUnity();
      } else {
         this.collectionPlaceOptions = [
            {
               label: "Todos",
               value: null
            }
         ];
         this._collectionPlacesSubject.next(this.collectionPlaceOptions);
         this.form.requisitionCollectionPlace.setValue(
            this.collectionPlaceOptions[0].value
         );
      }
      await this.getRequisitions();
   }

   async getRequisitionByCollectionPlace() {}

   async getCompanies() {
      this.companyOptions = [
         {
            label: "Todos",
            value: null
         }
      ];
      await this._companyService.getResume().then((response) => {
         if (response.success) {
            this.companyOptions = response.data.map((company) => ({
               value: company.id,
               label: company.companyName
            }));
         }
      });
      if (decodedToken.cid) {
         this.form.companyId.setValue(decodedToken.cid);
      } else {
         this.form.companyId.setValue(null);
      }
   }

   async getUnityRequisitions() {
      await this.getUnity();
      await this.getRequisitions();
   }

   async getUnity() {
      this.formUnity.requisitionUnity.reset();
      this.unityOptions = [];
      this.unityOptions = [
         {
            label: "Todos",
            value: null
         }
      ];
      const query = {
         companyId: this.form.companyId.value,
         resume: true
      };
      if (!query.companyId) {
         return;
      }
      await this._unityService
         .getPagination(query)
         .then((res: any) => {
            if (res.data.length != 0) {
               res.data.forEach((element: any) => {
                  this.unityOptions.push({
                     label: element.name,
                     value: element.id
                  });
               });
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
      this.formUnity.requisitionUnity.setValue(decodedToken.uid);
   }

   async getCollectionPlace() {
      this.colectionPlaceOption = [
         {
            label: "Todos",
            value: null
         }
      ];
      const queryParams = { isActive: true };
      await this._collectionPlaceService
         .getPagination(queryParams)
         .then((res: any) => {
            res.data.forEach((x: any) => {
               const optionObj = {
                  value: x.id,
                  label: x.description
               };
               this.colectionPlaceOption.push(optionObj);
            });
         });
   }

   async getByIdUnity() {
      this.collectionPlaceOptions = [];
      this.collectionPlaceOptions = [
         {
            label: "Todos",
            value: null
         }
      ];
      await this._unityService
         .getById(this.formUnity.requisitionUnity.value)
         .then((res) => {
            res.data.forEach((unity) => {
               if (unity.collectionPlace.length === 0) {
                  this._toastService.warningMessage(
                     "requisition.msgNotUnitCollection"
                  );
                  this.form.requisitionCollectionPlace.setValue(null);
                  this.collectionPlaceOptions = [];
                  this.resolve([], resolveRequisitions);
               } else {
                  unity.collectionPlace.forEach((collectionPlace) => {
                     this.collectionPlaceOptions.push({
                        value: collectionPlace.id,
                        label: collectionPlace.description
                     });
                  });
                  this.form.requisitionCollectionPlace.setValue(
                     this.collectionPlaceOptions[0].value
                  );
               }
            });
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
      this._collectionPlacesSubject.next(this.collectionPlaceOptions);
   }

   getRequisitionFinished(finished: boolean) {
      return finished
         ? "<span style='background: #07BF56; color: #FFFFFF;' class='span-styles'>Finalizada</span>"
         : "<span style=' background: #FF0000; color: #FFFFFF;' class='span-styles'>Não Finalizada</span>";
   }

   onTabChanged(emit: MatTabChangeEvent) {
      this.form.searchByRequisitionNumber.reset();
      this.form.searchByCustomerName.reset();
      this.form.searchByCustomerCPF.reset();
      this.form.searchByCustomerBirthDate.reset();
      this.formDate.initialDate.setValue(this.incrementDays(new Date(), -30));
      this.formDate.finalDate.setValue(new Date());

      if (emit.index == 1) {
         this.getPersonRequisitions();
      } else {
         this.getRequisitions();
      }
   }

   async getPersonRequisitions(
      paramPage: number[] = [
         this.personRequisitionsPage,
         this.personRequisitionsIndex
      ]
   ) {
      const collectionPlace =
         this.form.requisitionCollectionPlace.value === "Todos"
            ? ""
            : this.form.requisitionCollectionPlace.value;

      this.personRequisitionsPage = paramPage[0];
      this.personRequisitionsIndex = paramPage[1];

      let query = {
         page: this.personRequisitionsPage,
         numberRegistry: this.personRequisitionsIndex,
         isActive: true,
         collectionPlaceId: collectionPlace,
         unityId: this.formUnity.requisitionUnity.value,
         initialDate: new Date(this.formDate.initialDate.value).toISOString(),
         lastDate: new Date(this.formDate.finalDate.value).toISOString(),
         requisitionNumber: this.form.searchByRequisitionNumber.value,
         name: this.form.searchByCustomerName.value,
         customerCPF: this.form.searchByCustomerCPF.value,
         birthDate:
            this.form.searchByCustomerBirthDate.value?.toISOString() ?? null,
         companyId: this.form.companyId.value,
         personId: decodedToken.pid
      };

      await this._requisitionService.getPagination(query).then((res) => {
         this.personRequisitions = res.data.map((requisiton: any) => ({
            id: requisiton.id,
            _hide_customerId: requisiton.customerId ?? null,
            _hide_UnityId: requisiton.unityId,
            "requisition.number": requisiton.number.toString(),
            "requisition.name": requisiton.name.toUpperCase(),
            "requisition.companyName": requisiton.companyName,
            "requisition.unityDescription": requisiton.unityDescription,
            "requisition.collectionPlaceDescription":
               requisiton.collectionPlaceDescription.toUpperCase(),
            "requisition.createdAt": this._localaDatePipe.transform(
               requisiton.createdAt
            ),
            "requisition.finished": this.getRequisitionFinished(
               requisiton.finished
            ),
            "requisition.situation":
               this.requisitionSituation[requisiton.situation]
         }));
         this.personRequisitionsTotal = res.sumRecords;
      });

      localStorage.removeItem("lastRequisition");
   }

   searchRequisitions() {
      this.page = 1;
      this.personRequisitionsPage = 1;
      if (this.matTabIndex == 0) {
         this.getRequisitions();
      } else {
         this.getPersonRequisitions();
      }
   }

   async getRequisitions(paramPage: number[] = [this.page, this.index]) {
      const formattedInitialDate = new Date(
         this.formDate.initialDate.value
      ).toISOString();
      const formattedFinalDate = new Date(
         this.formDate.finalDate.value
      ).toISOString();
      const collectionPlace =
         this.form.requisitionCollectionPlace.value === "Todos"
            ? ""
            : this.form.requisitionCollectionPlace.value;
      const companyId = this.form.companyId.value;

      let filters: {
         page: number;
         numberRegistry: number;
         isActive: boolean;
         collectionPlaceId: string;
         unityId: string;
         initialDate: string;
         lastDate: string;
         requisitionNumber: string | null;
         name: string | null;
         customerCPF: string | null;
         birthDate: string | null;
         companyId: string | null;
         personId: string | null;
      };
      filters = {
         page: this.page,
         numberRegistry: this.index,
         isActive: true,
         collectionPlaceId: collectionPlace,
         unityId: this.formUnity.requisitionUnity.value,
         initialDate: formattedInitialDate,
         lastDate: formattedFinalDate,
         requisitionNumber: null,
         name: null,
         customerCPF: null,
         birthDate: null,
         companyId: companyId,
         personId: null
      };

      if (this.form.searchByRequisitionNumber.value)
         filters.requisitionNumber = this.form.searchByRequisitionNumber.value;
      if (this.form.searchByCustomerName.value)
         filters.name = this.form.searchByCustomerName.value;
      if (this.form.searchByCustomerCPF.value)
         filters.customerCPF = this.form.searchByCustomerCPF.value;
      if (this.form.searchByCustomerBirthDate.value)
         filters.birthDate =
            this.form.searchByCustomerBirthDate.value.toISOString();

      if (paramPage) {
         this.page = paramPage[0];
         this.index = paramPage[1];
      } else {
         this.page = 1;
         this.index = 50;
      }

      filters.page = this.page;
      filters.numberRegistry = this.index;

      await this._requisitionService
         .getPagination(filters)
         .then((res) => {
            if (res.data.length != 0) {
               this.total = res.sumRecords;
               this.resolve(res.data, resolveRequisitions);
            } else {
               this.resolve([], resolveRequisitions);
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
      localStorage.removeItem("lastRequisition");
   }

   async getByIdRequisition(requisitionId: string) {
      this.customerOptions = [];

      await this._requisitionService
         .getById(new Guid(requisitionId))
         .then((res) => {
            if (res.data.length != 0) {
               this.customerOptions.push({
                  value: res.data[0].customerId,
                  label: res.data[0].name
               });
               this.requisitionForm.customerId.setValue(res.data[0].customerId),
                  this.requisitionForm.customerName.setValue(
                     res.data[0].customerId
                  ),
                  this.requisitionForm.birthDate.setValue(
                     res.data[0].birthDate
                  ),
                  this.requisitionForm.genderType.setValue(
                     res.data[0].genderType
                  ),
                  this.requisitionForm.clinicalInformation.setValue(
                     res.data[0].clinicalInformation
                  );
            } else {
               this._toastService.warningMessage("requisition.msgNotDatas");
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async getCustomers() {
      if (this.form.searchCustomer.value) {
         this.requisitionForm.customerName.setValue("");
         this.firstCustomer = false;
         this.alterCustomer = this.alterCustomer ? false : true;
         this.customerOptions = [];

         await this._customerService
            .getPagination({
               page: this.page,
               numberRegistry: 999,
               name: this.form.searchCustomer.value
            })
            .then((res: any) => {
               if (res.data.length != 0) {
                  res.data.forEach((element: any) => {
                     this.customerOptions.push({
                        label: element.name,
                        value: element.id
                     });
                  });
               } else {
                  this._toastService.warningMessage("requisition.msgNotDatas");
               }
            });
      } else {
         this._toastService.warningMessage("requisition.msgInfoCustomer");
      }
   }

   async actions(emit: Actions) {
      switch (emit.action) {
         case "Gerar Ocorrências":
            this.formOcurrency.defaultHour.reset();
            this.formOcurrency.observation.reset();
            this.formOcurrency.type.reset();
            const queryObject: QueryParams = {
               isResume: true,
               isActive: true,
               numberRegistry: 999999,
               page: 1
            };
            this.typeOccurrency = [];
            await this._typeOcurrencyService
               .getPagination(queryObject)
               .then((res: any) => {
                  if (res.data.length != 0) {
                     this.typeOccurrency = res.data.map((element: any) => ({
                        label: element.description,
                        value: element.id
                     }));
                  }
               })
               .catch(
                  //@ts-ignore
                  (err) => {
                     this._toastService.fireError(err);
                  }
               );

            const occurrencyModal = this._dialog.open(
               this.dialogModalGenerateOccurrency,
               {
                  width: "660px",
                  maxHeight: "660px",
                  data: emit.object.id
               }
            );
            break;
         case "occurrency":
            this.occurrencies = [];
            const query: QueryParams = {
               requisitionId: emit.object.id,
               numberRegistry: 999999,
               page: 1
            };
            await this._occurrencyService
               .getPagination(query)
               .then((res: any) => {
                  if (res.data.length != 0) {
                     this.resolveOccurrencies(res.data, resolveOccurrencies);
                  }
               })
               .catch(
                  //@ts-ignore
                  (err) => {
                     this._toastService.fireError(err);
                  }
               );

            const myTempDialog = this._dialog.open(this.dialogModalOccurrency, {
               width: "660px",
               maxHeight: "660px"
            });
            break;
         case "Manutenção Avançada":
            this._router.navigateByUrl(
               "MovimentacaoOld/Recepcao/Requisicoes/ManutencaoAvancada/" +
                  emit.object.id
            );
            break;
         case "Editar":
            localStorage.setItem(`autosave://lastURL`, this._router.url);
            this._router.navigateByUrl(
               "MovimentacaoOld/Recepcao/Requisicoes/" + emit.object.id
            );
            break;
         case "Relatórios":
            let finished = false;

            if (emit.object["requisition.finished"].search("Não") == -1) {
               finished = true;
            }
            this.openDialogModalRequisition(
               emit.object.id,
               emit.object["Número"],
               finished
            );
            break;
         case "Excluir":
            this._toastService
               .fireConfirmation("requisition.msgDeleteRequisition")
               .then(async (r) => {
                  if (r.isConfirmed) {
                     await this._requisitionService.delete(emit.object.id);
                     this._toastService.fireSuccess(
                        "requisition.msgDeleteSuccess"
                     );
                     this.getRequisitions();
                  }
               });
            break;
         case "OpenDropdown":
            let exams = await this.getResumeById(emit.id);
            this.ResolveExams(exams, resolveResumeExams, emit.id);
            if (exams.length == 0) {
               this.hasExams[emit.id] = false;
            } else {
               this.hasExams[emit.id] = true;
            }
            let examsObject = {
               id: emit.id,
               exams: exams
            };
            this.exams.push(examsObject);
            break;
         case "Efetuar Cópia":
            this.openDialogCopy(emit.object.id);
            break;
         case "Documentos":
            const response = await this._requisitionService.getByPath(
               "",
               `documents/${new Guid(emit.object.id)}`
            );
            this.fileDocuments = (response as any).data[0]
               .requisitionDocuments as {
               fileDocumentId: string;
               name: string;
            }[];

            if (this.fileDocuments) {
               this.requisitionFiles.setValue([
                  ...this.fileDocuments.map((item) => ({
                     id: item.fileDocumentId,
                     name: item.name,
                     createdAt: item.createdAt
                  }))
               ]);
            }

            const documentsDialog = this._dialog.open(this.dialogDocuments, {
               width: "calc(500px * var(--proportional))",
               data: {
                  id: emit.object.id,
                  fileDocuments: this.fileDocuments
               }
            });

            documentsDialog.afterClosed().subscribe(async () => {
               this.requisitionFiles.setValue([]);
            });
            break;
      }
   }

   addDocumentsInRequisition(requisitionId: string) {
      let body: any = [];
      this.requisitionFiles.value.forEach((element: any) => {
         if (element.createdAt == undefined) {
            body.push(element.id);
         }
      });
      if (body.length === 0) {
         this._toastService.warningMessage("requisitionDocuments.msgNotDocs");
      } else {
         this._requisitionDocumentService.postWithId(
            { fileDocumentsIds: body },
            new Guid(requisitionId)
         );
         this.close();
         this._toastService.fireSuccess("requisitionDocuments.msgDocsSuccess");
      }
   }

   removeDocumentFromRequisition(requisition: any, fileDocumentId: Guid) {
      const requisitionDocumentToRemove = (
         requisition.fileDocuments as { id: string; fileDocumentId: string }[]
      ).find((item) => item.fileDocumentId === fileDocumentId.toString());

      if (requisitionDocumentToRemove) {
         this._requisitionDocumentService.delete(
            new Guid(requisitionDocumentToRemove.id)
         );

         this.requisitionFiles.setValue(
            this.requisitionFiles.value.filter(
               (file: { id: string }) => file.id !== fileDocumentId.toString()
            )
         );
      }
   }

   getExams(id: string) {
      let exams: Array<any> = this.exams.filter((exam: any) => exam.id === id);
      this.ResolveExams(exams[0].exams, resolveResumeExams, id);
   }

   async getResumeById(id: string) {
      let exams: Array<Object> = [];
      await this._resumeRequisitionService
         .getById(new Guid(id))
         .then((res: any) => {
            if (res.data.length != 0) {
               res.data[0].examsRequest.forEach((x: any) => {
                  x.agreementsExamsRequest.forEach((exam: any) => {
                     exams.push(exam);
                  });
               });
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
      return exams;
   }

   ResolveExams(data: any, columns: any, id: string) {
      let exams: Array<Object> = [];
      if (data) {
         data.forEach((x: any) => {
            let obj: any = {};
            columns.forEach((y: any) => {
               if (!y.retrive) {
                  obj[y.label] =
                     y.before +
                     this._util.formatField(
                        x,
                        y.fieldName,
                        y.type,
                        y.interpolationFields,
                        y.dateFormat
                     ) +
                     y.after;
               } else {
                  obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
               }
            });
            exams.push(obj);
         });
      }
      this.registerExams[id] = exams;
   }

   resolve(data: any, columns: any) {
      this.registerRequisitions = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               if (!y.method || y.method == "formatField") {
                  obj[y.label] =
                     y.before +
                     this._util.formatField(
                        x,
                        y.fieldName,
                        y.type,
                        y.interpolationFields,
                        y.dateFormat
                     ) +
                     y.after;
               } else {
                  //@ts-ignore
                  obj[y.label] = this[y.method](x);
               }
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registerRequisitions.push(obj);
      });
   }

   resolveOccurrencies(data: any, columns: any) {
      this.occurrencies = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.occurrencies.push(obj);
      });
   }

   navigate() {
      localStorage.setItem(`autosave://lastURL`, this._router.url);
      this._router.navigateByUrl("MovimentacaoOld/Recepcao/Requisicoes/Novo");
   }

   clearForms() {
      this.form.searchByCustomerCPF.reset();
      this.form.searchByCustomerBirthDate.reset();
      this.form.searchByCustomerName.reset();
      this.form.searchByRequisitionNumber.reset();
      this.formDate.initialDate.setValue(this.incrementDays(new Date(), -30));
      this.formDate.finalDate.setValue(new Date());
   }

   download(base64String: string, fileName: string, type: string = "pdf") {
      //@ts-ignore
      const source = `data:application/${type};base64,${base64String.base64}`;
      const link = document.createElement("a");
      link.href = source;
      link.download = `${fileName}.${type}`;
      link.click();
   }

   async getBaseUrl() {
      let url = "";
      await this._concentIntegrationService
         .getAll()
         .then((res: any) => {
            url = res.data[0].urlBase;
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });

      return url;
   }

   generateRandomString(length: number): string {
      const validChars =
         "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";

      for (let i = 0; i < length; i++) {
         const randomIndex = Math.floor(Math.random() * validChars.length);
         result += validChars.charAt(randomIndex);
      }

      return result;
   }

   async openDialogModalAllInstructions(requisitionId: Guid) {
      let analyteInstructionsList: Array<Object> = [];
      await this._requisitionService
         .getByPath("", "preparation/" + requisitionId)
         .then((res: any) => {
            const data = res.data;
            for (let analyte of data) {
               let instructions = "Não há preparos definidos para esse exame.";
               if (analyte.analyteInstructions.length != 0)
                  instructions =
                     "● " +
                     analyte.analyteInstructions
                        .map((instruction: any) => instruction.description)
                        .join("\n\n● ");

               const obj = {
                  analyteId: analyte.id,
                  code: analyte.analyteName,
                  instructions: instructions,
                  form: new UntypedFormControl(instructions)
               };
               analyteInstructionsList.push(obj);
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
      if (analyteInstructionsList.length != 0) {
         this._dialog.closeAll();
         const myTempDialog = this._dialog.open(
            this.dialogModalAllInstructions,
            {
               width: "660px",
               maxHeight: "660px",
               data: analyteInstructionsList
            }
         );
      }
   }

   createOccurrency(requisitionId: string) {
      let timeString = this.formOcurrency.defaultHour.value;
      let [hours, minutes] = timeString.split(":").map(Number);
      let date = new Date();
      date.setHours(hours, minutes, 0, 0);

      const obj = {
         requisitionId: requisitionId,
         typeOccurrencyId: this.formOcurrency.type.value,
         description: this.formOcurrency.observation.value,
         dateOccurrency: date
      };

      this._occurrencyService
         .post(obj)
         .then((res) => {
            this._dialog.closeAll();
            this._toastService.fireSuccess(
               "Ocorrência cadastrada com sucesso!"
            );
            this.getRequisitions();
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }
}
