<div class="relative main w-full">
    <mat-select
        class="w-full"
        [ngClass]="{ 'pd-left-60': selectedOption?.svg }"
        style="color: #808080"
        [formControl]="form"
        [placeholder]="placeholder | translate"
        (selectionChange)="onUserChange($event)"
    >
        <mat-option *ngIf="allowToEraseValue" class="opacity-60">{{
            "global.none" | translate
        }}</mat-option>
        <mat-option *ngFor="let option of options" [value]="option.value">
            <ui-svg-selector-new
                *ngIf="option.svg"
                [svg]="option.svg"
                [fill]="option.fill"
                [sizePx]="32"
                class="align-middle self-center"
            ></ui-svg-selector-new>
            {{ option.label | translate }}
        </mat-option>
    </mat-select>
    <h3 class="absolute" [ngClass]="{ 'label-up': form.value != null }">
        {{ placeholder | translate }}
    </h3>
    <span class="underline"></span>
    <ui-svg-selector-new
        *ngIf="selectedOption?.svg"
        [svg]="selectedOption?.svg"
        [fill]="selectedOption?.fill"
        class="align-middle self-center absolute left-4 bottom-1/2 translate-y-1/2 z-10"
    ></ui-svg-selector-new>
    <!-- <div
        class="w-100 absolute flex justify-end"
        *ngIf="form.invalid && form.dirty"
    >
        <span class="text-[#eb5757]">{{
            form.errors | errorTranslate : form.value
        }}</span>
    </div> -->
</div>
