import { Component, Input, OnInit } from '@angular/core';
import { Content, Margins, TDocumentDefinitions } from "pdfmake/interfaces";
import { HttpClient } from '@angular/common/http';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import decodedToken from '@app/util/Token';
import { TenantAuthService } from '@app/services/auth/tenantAuth/tenant-auth.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { ColumnHeader } from './registration-report.model';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from "pdfmake/build/pdfmake";
import { table } from 'console';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

/**
 * Componente usado para gerar PDF de relatórios.
 *
 * @usageNotes
 *
 * ### Gerando um Relatório em PDF
 *
 * Para gerar um relatório em PDF, você deve fornecer os seguintes dados como entrada (Input):
 *
 * #### Input: reportDescription
 * - Descrição: A descrição do relatório.
 * - Tipo: string.
 * - Exemplo: `'Relatório de Cadastro de Exames'`.
 *
 * #### Input: reportFilters (opcional)
 * - Descrição: Os filtros opcionais para o relatório.
 * - Tipo: Array de Arrays contendo objetos.
 * - Exemplo:
 * ```typescript
 * [
 *   [{ title: 'SOMENTE ATIVOS: ', content: 'SIM' }, { title: 'PERÍODO: ', content: '01/01/2023 a 31/12/2023' }],
 *   [{ title: 'EXAMES: ', content: 'AAAAA a ZZZZZ' }]
 * ]
 * ```
 *
 * #### Input: receivedData
 * - Descrição: Os dados recebidos para gerar o relatório.
 * - Tipo: Array de Objetos contendo os dados.
 * - Exemplo:
 * ```typescript
 *  [
      {
        description: 'HEMOGRAMA',
        code: 'HEM',
        material: 'Sangue EDTA',
        isActive: true,
        createdAt: '2000-10-31T01:30:00.000Z',
        updatedAt: '2000-10-31T01:30:00.000Z'
      },
    ]
 * ```
 *
 * #### Input: headerMapping
 * - Descrição: A estrutura de mapeamento do cabeçalho.
 * - Tipo: Objeto descrevendo o mapeamento.
 * - Exemplo:
 * ```typescript
 * headerMapping = {
    description: { title: 'Descrição', width: '*' },
    code: { title: 'Mnemônico', width: 'auto' },
    material: { title: 'Material', width: 'auto' },
    isActive: { title: 'Ativo', width: 'auto' },
    createdAt: { title: 'Criado em', width: 'auto' },
    updatedAt: { title: 'Atualizado em', width: 'auto' },
  };
 * ```
 * `width` pode ser `*`, `auto` ou `number`
 *
 * @example
 * ### Exemplo de uso do componente:
 * ```html
 * <app-report
 *   [reportDescription]="'Relatório de Cadastro de Exames'"
 *   [reportFilters]="reportFilters"
 *   [receivedData]="dataContent"
 *   [headerMapping]="headerMapping"
 * ></app-report>
 * ```
 *
 * @remarks
 * Certifique-se de fornecer os inputs necessários para gerar o relatório em PDF.
 */
@Component({
  selector: 'app-report[receivedData][reportDescription][headerMapping]',
  templateUrl: './registration-report.component.html',
  styleUrls: ['./registration-report.component.scss'],
})

export class RegistrationReportComponent implements OnInit {
  private readonly token = decodedToken;
  private readonly headerWidths: Array<string> = ['*', 'auto'];
  private readonly headerReportName: Array<string> = ['*'];

  private userName!: string;
  private tenantId!: Guid;
  private companyName!: string;
  private headerFilterWidths!: Array<string>;
  private headerColumns: ColumnHeader[] = [];

  @Input() headerImage!: string;
  @Input() footerImage!: string;
  @Input() reportDescription!: string;
  @Input() reportFilters!: Array<Array<any>>;
  @Input() receivedData!: any[];
  @Input() headerMapping!: { [key: string]: { title: string; width: string } };
  @Input() sumRecords: number = 0;
  @Input() customerName!: string;
  @Input() budget: boolean = false;

  constructor(
    private _http: HttpClient,
    private _localeDate: LocalaDatePipe,
    private _tenantAuth: TenantAuthService
  ) {
    if (this.token) {
      this.userName = this.token.name;
      this.tenantId = new Guid(this.token.tid.replace(';', ''));
    }
  }

  async ngOnInit() {
    await this.getCompanyName();
    this.convert();
    this.generateFilters();    
  }

  /**
   * Gera uma matriz de larguras de coluna para a tabela do relatório com base no mapeamento do cabeçalho.
   * Cada largura é obtida do mapeamento do cabeçalho correspondente, ou utiliza uma largura padrão se não definida.
   *
   * @returns Uma matriz de strings representando as larguras das colunas.
   */
  generateColumnWidths() {
    const defaultWidth = 'auto'; // Largura padrão para as colunas que não estão mapeadas
    const columnWidthsArray: string[] = Object.values(this.headerMapping).map(header => header.width || defaultWidth);
    return columnWidthsArray;
  }

  /**
  * Gera o corpo (filtros) e configura o cabeçalho (filterHeader) dinamicamente
  * para os dados fornecidos em 'reportFilters'.
  */
  generateFilters() {
    if (!this.reportFilters || this.reportFilters.length === 0) {
      return;
    }
    // Obter o tamanho máximo necessário para preencher os vetores internos.
    const maxLength = this.getMaxFilterLength(this.reportFilters);

    // Preencher os vetores internos para garantir que todos tenham o mesmo tamanho.
    this.fillFilterArrays(this.reportFilters, maxLength);

    // Gerar o vetor 'headerFilterWidths' com a quantidade correta de '*' para configurar as larguras das colunas do cabeçalho.
    this.headerFilterWidths = this.generateHeaderFilterWidths(maxLength);

    // Gerar o array de strings a partir dos filtros.
    this.reportFilters = this.generateTextArray(this.reportFilters);
  }

  /**
   * Calcula o tamanho máximo necessário para preencher os vetores internos.
   * @param filters - Matriz de filtros onde cada vetor interno representa um conjunto de filtros.
   * @returns O tamanho máximo entre os vetores internos.
   */
  getMaxFilterLength(filters: any[][]): number {
    return filters.reduce((maxLength, filterArray) => Math.max(maxLength, filterArray.length), 0);
  }

  /**
   * Preenche os vetores internos com objetos padrão para garantir que todos tenham o mesmo tamanho.
   * @param filters - Matriz de filtros onde cada vetor interno representa um conjunto de filtros.
   * @param maxLength - Tamanho máximo necessário para preencher os vetores internos.
   */
  fillFilterArrays(filters: any[][], maxLength: number) {
    const DEFAULT_FILTER = {
      title: '',
      content: '',
      _margin: {},      // Definir como um objeto vazio em vez de uma string vazia
      _inlines: {},     // Definir como um objeto vazio em vez de uma string vazia
      _minWidth: 'auto',
      _maxWidth: 'auto',
      positions: 0,
    };
    filters.forEach((filterArray) => {
      while (filterArray.length < maxLength) {
        filterArray.push({ ...DEFAULT_FILTER });
      }
    });
  }

  /**
   * Gera o vetor 'headerFilterWidths' com base no tamanho máximo para configurar as larguras das colunas do cabeçalho.
   * @param maxLength - Tamanho máximo necessário para o cabeçalho.
   * @returns Um vetor com a quantidade correta de '*' para configurar as larguras das colunas do cabeçalho.
   */
  generateHeaderFilterWidths(maxLength: number): string[] {
    return Array.from({ length: maxLength }, () => '*');
  }

  /**
   * Gera o array de strings a partir dos filtros.
   * @param filters - Matriz de filtros onde cada vetor interno representa um conjunto de filtros.
   * @returns Um array de strings representando os dados combinados de 'title' e 'content' para cada filtro.
   */
  generateTextArray(filters: any[][]): any[][] {
    return filters.map((filterArray) => {
      return filterArray.map((obj) => ({
        text: [
          { text: obj.title ? obj.title.toUpperCase() : '', style: { bold: true, fontSize: 12 } },
          { text: obj.content, style: { fontSize: 12 } },
        ],
        _margin: obj._margin ?? {},          // Verifica se obj._margin é undefined e, se for, usa um objeto vazio
        _inlines: obj._inlines ?? {},        // Verifica se obj._inlines é undefined e, se for, usa um objeto vazio
        _minWidth: obj._minWidth ?? 'auto',  // Verifica se obj._minWidth é undefined e, se for, usa 'auto'
        _maxWidth: obj._maxWidth ?? 'auto',  // Verifica se obj._maxWidth é undefined e, se for, usa 'auto'
        positions: obj.positions ?? 0,      // Verifica se obj.positions é undefined e, se for, usa 0
      }));
    });
  }

  /**
   * Obtém o nome da empresa a partir da API com base no TenantID.
   * O nome da empresa é armazenado na propriedade "companyName".
   * Se a busca falhar, um erro é registrado usando as configurações de exibição de erro do SweetAlert.
   */
  async getCompanyName() {
    await this._tenantAuth.getById(this.tenantId).then(
      (res) => {
        this.companyName = res.data[0].companyName;
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  createTableData(data: any[], fields: string[]): any[][] {
    return data.map((item) => {
      return fields.map((field) => item[field]);
    });
  }

  formatBodyData(item: any, header: any) {
    if (item[header.name] != undefined && item[header.name] != null) {
      const dateRegex: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{0,6}$/;
      const isValidDate: boolean = dateRegex.test(item[header.name]);
      if (isValidDate) {
        const date = new Date(item[header.name]);
        return this._localeDate.transform(date);
      } else {
        const nameType = typeof item[header.name];
        if (nameType === 'boolean') {
          return item[header.name] === 'true' ? 'Sim' : 'Não';
        } else {
          return item[header.name];
        }
      }
    }
  }

  generatePDF(logoUri: string) {
    if (!this.receivedData || this.receivedData.length === 0) {
      console.error("ERROR: Não há dados disponíveis ou nenhum item foi retornado da API.");
      return;
    }

    const commonLayout = {
      hLineWidth: (i: number) => (i === 0 || i === 1) ? 1 : 0,
      vLineWidth: () => 0
    };

    const companyWithLogo: Content = {
      table: {
        widths: ['auto', '*'],
        body: [
          [
            { image: logoUri, fit: [50, 50], alignment: 'left' },
            { text: `${this.companyName}`, alignment: 'left', margin: [0, 15, 0, 0], style: { fontSize: 14, color: '#FF6344', bold: true } }, // Coluna com o texto
          ]
        ],
      },
      layout: {
        defaultBorder: false,
      }
    };

    const localeDate = new Date().toLocaleString('pt-BR');
    const userWithDateNow: Content = {
      stack: [
        { text: `Emissão: ${localeDate}`, alignment: 'left', margin: [0, 10, 0, 0], style: { fontSize: 12, color: '#FF6344', bold: true }  },
        { text: `Usuário: ${this.userName.toUpperCase()}`, alignment: 'left', margin: [0, 5, 0, 0], style: { fontSize: 12, color: '#FF6344', bold: true } }
      ]
    }
 
    this.headerColumns = Object.entries(this.headerMapping).map(([key, value]) => ({ name: key, ...value }));

    var headerRow = this.headerColumns.map(header => ({ text: this.headerMapping[header.name].title, style: 'header' }));

    var tableBody : any = [];
    if (this.budget){
      var bodyBudget : any = [];      
      //@ts-ignore
      this.receivedData.budgetRequest.forEach(element => {
        headerRow = this.headerColumns.map(header => ({ text: this.headerMapping[header.name].title, style: 'header' }));
        const headerBudget =  {
          table: {
            widths:  ['auto', '*'],
            body: [
              [{ text: 'Convênio: ' + element.agreementDescription, style: { fontSize: 12, bold: true, color: '#000000' }, alignment: 'left' },
              { text: element.descriptionPlan ? 'Plano: ' + element.descriptionPlan : '', style: { fontSize: 12, bold: true, color: '#000000' }, alignment: 'right' }],
              [{ text: 'Solicitante: ' + element.doctorName, style: { fontSize: 12, bold: true, color: '#000000' }, alignment: 'left' }, {}],              
            ],
          },
          layout: {
            hLineWidth: (i: number) => (i === 0) ? 1 : 0,
            vLineWidth: (i: number, node: any) =>  0
          }
        }
        bodyBudget.push(headerBudget);

        const contentExams = element.budgetRequestAnalytes.map((item: any) => {
          return this.headerColumns.map((header) => ({
            text: this.formatBodyData(item, header),
            alignment: 'left',
          }));
        });
        var obj = {
          table: {
            widths: this.generateColumnWidths(),
            body: [              
              headerRow,
              ...contentExams.map((row : any, index : any) => {
                const fillColor = index % 2 === 0 ? '#F0F0F0' : null;
                //@ts-ignore
                const formattedRow = row.map(cell   => ({ ...cell, fillColor }));
                return formattedRow;
              })
            ],
          },
          layout: {
            ...commonLayout
          }
        }

        bodyBudget.push(obj);

        const line =  {
          table: {
            widths:  ['*'],
            body: [
              [{ text: ' ', style: { fontSize: 12, bold: true, color: '#000000' }, alignment: 'left' }]              
            ],
          },
          layout: {
            hLineWidth: (i: number) => 0,
            vLineWidth: (i: number, node: any) =>  0
          }
        }
        bodyBudget.push(line) 

        const footer =  {
          table: {
            widths:  ['auto', '*'],
            body: [
              [{ text: 'Total Exames: ' + element.totalAnalyte, style: { fontSize: 10, bold: true, color: '#000000' }, alignment: 'left' },
              { text: 'Valor Total: R$ ' +  element.totalValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: { fontSize: 10, bold: true, color: '#000000' }, alignment: 'right' }]              
            ],
          },
          layout: {
            hLineWidth: (i: number) => (i === 0) ? 1 : 0,
            vLineWidth: (i: number, node: any) =>  0
          }
        }
        bodyBudget.push(footer)    
      });

    } else {
      tableBody = this.receivedData.map((item) => {
        return this.headerColumns.map((header) => ({
          text: this.formatBodyData(item, header),
          alignment: 'left',
        }));
      });
    }
    
    const self = this;
    
    var heightHeader = 105;
    if (this.reportFilters){
      this.reportFilters.forEach((filter) => {
        heightHeader += 19;
      });
    }

    const documentDefinition: TDocumentDefinitions = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      pageMargins: [30, heightHeader, 30, 50],
      header: function (currentPage, pageCount) {
        let stackContent: any[] = [];
        if (currentPage === 1) {
          if (self.reportFilters && self.reportFilters.length > 0) {            
            stackContent = [
              {
                table: {
                  widths: self.headerWidths,
                  body: [
                    [companyWithLogo, userWithDateNow],
                  ],
                },
                layout: 'noBorders'
              },
              {
                table: {
                  widths: self.headerReportName,
                  body: [
                    [{ text: self.reportDescription, style: { fontSize: 12, bold: true, color: '#000000' }, alignment: 'center' }],
                  ],
                },
                layout: {
                  hLineWidth: (i: number) => (i === 0) ? 1 : 0,
                  vLineWidth: (i: number, node: any) => (i > 0 && i < node.table.body.length) ? 1 : 0
                }
              },
              {
                table: {
                  widths: self.headerFilterWidths,
                  body: self.reportFilters
                },
                layout: {
                  hLineWidth: (i: number) => (i === 0) ? 1 : 0,
                  vLineWidth: (i: number, node: any) => (i === 0 && i === node.table.body.length) ? 1 : 0
                }
              }
            ];
          } else {              
            if (self.budget){
              stackContent = [];

              var header = {};              
              if (self.headerImage){
                header = {
                  image: self.headerImage,
                  width: 594,
                  alignment: 'center',
                }        
                stackContent.push(header);
                var dateEmission = {
                  table: {
                    widths:  ['*', '*', '*'],
                    body: [
                      [{ text: `Usuário: ${self.userName.toUpperCase()}`, alignment: 'left', style: { fontSize: 12, color: '#FF6344', bold: true } },
                      { text: `Emissão: ${self._localeDate.transform(new Date())}`, alignment: 'center', style: { fontSize: 12, color: '#FF6344', bold: true } },                      
                      { text: `Página: ` + currentPage + ' de ' + pageCount, alignment: 'right', style: { fontSize: 12, color: '#FF6344', bold: true } }]
                    ],
                  },
                  layout: {
                    hLineWidth: (i: number) => 0,
                    vLineWidth: (i: number, node: any) => 0
                  }
                }
                stackContent.push(dateEmission);
              } else {
                header = {
                  table: {
                    widths: self.headerWidths,
                    body: [
                      [companyWithLogo, userWithDateNow],
                    ],
                  },
                  layout: 'noBorders'
                }                              
                stackContent.push(header);
              }              

              const subHeader = {
                table: {
                  widths:  ['*', '*'],
                  body: [
                    [{ text: 'Cliente: ' + self.customerName, style: { fontSize: 12, bold: true, color: '#000000' }, alignment: 'left' },
                    { text: self.reportDescription, style: { fontSize: 12, bold: true, color: '#000000' }, alignment: 'right' }],
                  ],
                },
                layout: {
                  hLineWidth: (i: number) => (i === 0) ? 1 : 0,
                  vLineWidth: (i: number, node: any) => (i > 0 && i < node.table.body.length) ? 1 : 0
                }
              };
              stackContent.push(subHeader);
              
            } else {
              stackContent = [
                {
                  table: {
                    widths: self.headerWidths,
                    body: [
                      [companyWithLogo, userWithDateNow],
                    ],
                  },
                  layout: 'noBorders'
                },
                {
                  table: {
                    widths: self.headerReportName,
                    body: [
                      [{ text: self.reportDescription, style: { fontSize: 12, bold: true, color: '#000000' }, alignment: 'center' }],
                    ],
                  },
                  layout: {
                    hLineWidth: (i: number) => (i === 0) ? 1 : 0,
                    vLineWidth: (i: number, node: any) => (i > 0 && i < node.table.body.length) ? 1 : 0
                  }
                }
              ];
            }        
          }
        } else {          
          stackContent = [
            {
              table: {
                widths: self.headerWidths,
                body: [
                  [companyWithLogo, userWithDateNow],
                ],
              },
              layout: 'noBorders'
            },
            {
              table: {
                widths: self.headerReportName,
                body: [
                  [{ text: self.reportDescription, style: { fontSize: 12, bold: true, color: '#000000' }, alignment: 'center' }],
                ],
              },
              layout: {
                hLineWidth: (i: number) => (i === 0) ? 1 : 0,
                vLineWidth: (i: number, node: any) => (i === 0 || i === node.table.body.length) ? 0 : 1
              }
            },
            {
              table: {
                widths: self.generateColumnWidths(),
                body: [
                  headerRow,
                  ...tableBody
                ],
              },
              layout: {
                hLineWidth: (i: number) => (i === 0 || i === 1) ? 1 : 0,
                vLineWidth: () => 0
              }
            }
          ];
        }

        var topMargin = 30;
        if (self.headerImage){
          topMargin = 0;
        }
        
        const margin: Margins = [30, topMargin, 30, 40];

        return [
          {
            stack: stackContent,
            layout: commonLayout,
            margin: margin
          },
        ];
      },
      content: [
        {
          table: {
            widths: this.generateColumnWidths(),
            body: [              
              headerRow,
              ...tableBody.map((row : any, index : any) => {
                const fillColor = index % 2 === 0 ? '#F0F0F0' : null;
                //@ts-ignore
                const formattedRow = row.map(cell   => ({ ...cell, fillColor }));
                return formattedRow;
              })
            ],
          },
          layout: {
            ...commonLayout
          }
        }
      ],
      footer: function (currentPage, pageCount) {
        if (self.footerImage){
          return [
            {      
              image: self.footerImage,
              width: 594,          
              alignment: 'center',                   
            }
          ]
        } else {
          if (currentPage === pageCount) {
            return [
              {
                columns: [
                  { text: `Total de Registros: ${self.sumRecords}`, alignment: 'left', margin: [30, 10, 0, 0] },
                  {
                    text: [
                      { text: 'Página: ', style: 'pageLabel', alignment: 'right' },
                      { text: currentPage.toString(), style: 'boldText', alignment: 'right' },
                      { text: ' de ', style: 'pageLabel', alignment: 'right' },
                      { text: pageCount.toString(), style: 'boldText', alignment: 'right' }
                    ],
                    margin: [0, 10, 30, 0]
                  }
                ]
              }
            ]
          } else {
            return [
              {
                columns: [
                  {
                    text: [
                      { text: 'Página: ', style: 'pageLabel', alignment: 'right' },
                      { text: currentPage.toString(), style: 'boldText', alignment: 'right' },
                      { text: ' de ', style: 'pageLabel', alignment: 'right' },
                      { text: pageCount.toString(), style: 'boldText', alignment: 'right' }
                    ],
                    margin: [0, 10, 30, 0]
                  }
                ]
              }
            ]
          }
        }
      },
      defaultStyle: {
        fontSize: 8,
      },
      styles: {
        header: {
          fontSize: 10,
          bold: true
        },
        boldText: {
          bold: true
        },
      }
    };

    if (this.budget){
      documentDefinition.content = bodyBudget;
    }
    pdfMake.createPdf(documentDefinition).open();
  }

  convert() {
    const logoPath = 'assets/images/favicon.png';

    this._http.get(logoPath, { responseType: 'blob' }).subscribe(
      (data) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          const logoUri = base64String;

          this.generatePDF(logoUri);
        };
        reader.readAsDataURL(data);
      },
      (error) => {
        console.error('Error loading image:', error);
      }
    );
  }
}