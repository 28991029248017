<div class="contentPage">
  <div class="flex flex-between align-center headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
      <h1>{{ 'customer.title' | translate }}</h1>
      <app-button (click)="navigate()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter (beforeClose)="resetPageAndSearch()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <select-prop class="w-filter" [form]="formGroup.controls['filter']" [placeholder]="'customer.filter.select-filter'" [options]="'customer.filter.options' | translate"></select-prop>
        <input-prop #autoFocus class="w-filter" *ngIf="formGroup.controls['filter'].value === 'Name'" [placeholder]="'customer.filter.type-here'" [form]="formGroup.controls['search']"></input-prop>
        <input-prop class="w-filter" *ngIf="formGroup.controls['filter'].value === 'birthDate'" [placeholder]="'customer.filter.type-here'" [form]="formGroup.controls['search']" mask="00/00/0000" (change)="validateBirthDate()"></input-prop>
        <input-prop class="w-filter" [warningCustom]="'CPF invalido'" *ngIf="formGroup.controls['filter'].value === 'CPF'" [placeholder]="'customer.filter.type-here'" [form]="formGroup.controls['search']" mask="000.000.000-00"></input-prop>
        <input-prop class="w-filter" *ngIf="formGroup.controls['filter'].value === 'motherName'" [form]="formGroup.controls['search']" [placeholder]="'customer.filter.type-here'"></input-prop>
        <checkbox-prop [label]="'customer.filter.isActive'" class="flex flex-center" [gap]="1" [form]="formGroup.controls['isActive']"></checkbox-prop>
      </div>
      <app-button class="queryBtn" (click)="resetPageAndSearch()" (beforeClose)="resetPageAndSearch()" [placeholder]="'customer.filter.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [backPagination]="true" [SortPagination]="true" (sortPage)="getSortedPage($event)" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="registerCustomers" [Actions]="['Editar', 'Attach', 'Excluir']" [ClickInItem]="ActionsExtra" (parentFun)="actions($event)" (changePage)="getCustomers($event)"></app-table>
  </div>
</div>

<ng-template #dialogModalDocument let-customer>
  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1>{{ 'customer.documents' | translate }}</h1>
      <svg (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <h2 class="mt-32">{{'customer.customerDocuments' | translate }}: {{document.registerName.value}}</h2>
      <div class="mt-32 w-100">
        <file-prop-multiple [formFiles]="customerDocuments" (onDeleteFile)="removeDocumentFromCustomer(customer, $event)"></file-prop-multiple>
      </div>
    <div class="flex flex-center mt-32">
      <app-button (click)="putCustomerDocument()" [placeholder]="'global.save'" [class]="'btn flex gap-2 align-center'" ></app-button>
    </div>
  </ng-container>
</ng-template>