import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonComponentModule } from "@app/components/common-component.module";
import { BrowserModule } from "@angular/platform-browser";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TemplateGeneratorComponent } from "./components/template-generator/template-generator.component";
import { ParametersRoutingModule } from "./parameters-rounting.module";
import { TranslateModule } from "@ngx-translate/core";
import { SanitizeHtmlPipe } from "@app/pipe/sanitizeHtml.pipe";
import { CollectionPlaceComponent } from "./components/collection-place/collection-place.component";
import { MatTabsModule } from "@angular/material/tabs";
import { UrlRedirectComponent } from "./components/url-redirect/url-redirect.component";
import { MessageParametersComponent } from "./components/message-parameters/message-parameters.component";
import { SaveUnityParametersComponentOld } from "./components/unity-parameters/save-unity-parameters/save-unity-parameters.component";
import { UnityParametersComponentOld } from "./components/unity-parameters/unity-parameters.component";

@NgModule({
   imports: [
      BrowserModule,
      CommonModule,
      CommonComponentModule,
      ParametersRoutingModule,
      MatIconModule,
      MatTooltipModule,
      TranslateModule,
      MatTabsModule
   ],
   declarations: [
      TemplateGeneratorComponent,
      SanitizeHtmlPipe,
      CollectionPlaceComponent,
      SaveUnityParametersComponentOld,
      UrlRedirectComponent,
      MessageParametersComponent,
      UnityParametersComponentOld
   ],
   exports: [ParametersRoutingModule]
})
export class ParametersModule {}
