import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from "@angular/forms";
import { ForgotPasswordService } from 'src/app/services/auth/forgot-password.service';
import { AuthService } from "src/app/services/auth/auth-service.service";
import { LoginService } from "src/app/services/login.service";
import { SwAlSetttings } from '../util/swal.settings';

const helper = new JwtHelperService();
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  form = {
    accessCode : new UntypedFormControl(null, [Validators.required]),
    token : new UntypedFormControl(null, [Validators.required]),
    password : new UntypedFormControl(null, [Validators.required]),
    confirmPassword : new UntypedFormControl(null, [Validators.required]),
  }

  validation = false;
  forgotPassword: boolean = false;

  constructor(
      private _forgotPasswordService: ForgotPasswordService,
      private _router: Router
  ) { }
 
  ngOnInit(): void {
    localStorage.removeItem('TenantId');
    localStorage.removeItem('user');
    localStorage.removeItem('userImage');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('admin');

  }

  validarToken(){
    let object = {
      typeForgotPassword: 1,
      token: this.form.token.value,
      identification: this.form.accessCode.value,
      newPassword: this.form.password.value,
      confirmPassword: this.form.confirmPassword.value
    }

    this._forgotPasswordService.post(object).then((x:any) => {

        SwAlSetttings.Sucesso('Senha Alterada com Sucesso!');
        window.location.href = '';
    })
    .catch((x) => {
      SwAlSetttings.printError(x)
    });
  }

}
