<app-list [title]="'Sipni'">
    <div class="headerButton">
      <app-dialog [title]="'Sipni'" [placeholderButton]="'Novo'" [matIconName]="'add'" (afterClose)="afterClose()">
        <div class="bodyFilter flex flex-column mt-32 gap-3 align-center">
          <div class="w-100">
          <input-prop class="w-100" placeholder="Sigla" [form]="form.acronym"></input-prop>
          </div>
          <div class="w-100">
          <textarea-prop [form]="form.description" placeholder="Descrição"></textarea-prop>
          </div>
          <app-button (click)="editId? put() : post()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
        </div>
      </app-dialog>
    </div>
    <div class="headerFilter">
      <app-filter (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
        <div class="query flex flex-column gap-2 align-center">
          <input-prop #autoFocus [form]="filter.Search" (keyup.enter)="get()" [placeholder]="'Pesquisar'"></input-prop>
          <checkbox-prop class="flex" [gap]="1" label="Somente Ativos" [form]="filter.isActive"></checkbox-prop>
          <app-button (click)="get()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'btn flex gap-2 align-center'"></app-button>
        </div>
      </app-filter>
    </div>
    <div class="list">
      <app-table [Actions]="['Editar', 'Excluir']" [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true"  [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="itens" (parentFun)="actions($event)" ></app-table>
    </div>
</app-list>
