import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { HttpService } from '@app/services/http.service';
import { HttpResponsePageable } from '@app/services/services.types';
import { GetAllPriceTable } from '@app/services/pricetable/pricetable.types';
import { Guid } from '@app/util/guid';
import { VaccineAdm } from '@app/modules/registers/components/vaccines/vaccines-administration/vaccines-administration.model';

@Injectable({
  providedIn: 'root'
})
export class VaccinesAdministrationService extends BaseService {
  constructor(HttpService: HttpService) {
    super(HttpService, 'v1/lab/vaccines/administration');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllPriceTable>>
  }
  
  override post(body: Object) {
    return super.post(body) as Promise<HttpResponsePageable<any>>
  }

  override put(body: Object, id?:Guid, path?: string) {
    return super.put(body, id, path) as Promise<HttpResponsePageable<VaccineAdm>>
  }

  override delete(id?: Guid | undefined, path?: string | undefined){
    return super.delete(id) as Promise<any>
  }

}
