<div class="contentPage">
  <div class="flex flex-between w-100 gap-2 mb-32 align-center">
    <div class="w-100 flex flex-center gap-2 align-center flex-between">
      <div class="flex headerResponsive align-center gap-2">
        <app-button (click)="back()" [class]="'btn-reverse'" [placeholder]="'global.back'"></app-button>
        <h1>{{'advancedMaintence.title' | translate}}</h1>
      </div>
      <div class="flex headerResponsive align-center gap-2">
        <h1>{{'advancedMaintence.requisition' | translate}} <b class="orangeFont">{{customerNumber}}</b></h1>
        <h1>{{'advancedMaintence.customer' | translate }} <b class="orangeFont">{{formCustomer.controls['name'].value != '' ?
            formCustomer.controls['name'].value : 'advancedMaintence.notInfo' | translate}}</b></h1>
      </div>
    </div>
  </div>
  <mat-tab-group class="mt-32" (selectedTabChange)="matTabChange($event)">

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex align-center gap-1">
          <h2>{{ 'requisitionEdit.titleData' | translate }}</h2>
        </div>
      </ng-template>
      <div class="flex flex-center">
        <div class="contextPage">
          <div class="box">
            <div class="flex flex-between mt-32">
              <h2>{{ 'requisitionEdit.titleGeneralData'| translate }}</h2>
              <input-prop [form]="formCustomer.controls['arrivalTime']" [placeholder]="'requisitionEdit.arrivalTime'" type="time"></input-prop>
            </div>
            <div class="flex gap-form mt-32">
              <div class="w-100">
                <input-prop [placeholder]="'customer.register.name'" [form]="formCustomer.controls['name']"></input-prop>
              </div>
              <div class="w-100">
                <select-prop [placeholder]="'customer.register.biological-sex'" [form]="formCustomer.controls['genderType']" [options]="genderType"></select-prop>
              </div>
              <div class="w-100">
                <input-prop [placeholder]="'customer.register.birth-date'" type="date" [disableFutureDate]="true" [form]="formCustomer.controls['birthDate']"></input-prop>
              </div>
            </div>
            <div class="flex gap-form mt-32">
              <input-prop class="w-100" [placeholder]="'customer.register.mother-name'" [form]="formCustomer.controls['motherName']"></input-prop>
              <input-prop class="w-100" [placeholder]="'customer.register.name-responsible'" [form]="formCustomer.controls['nameResponsible']"></input-prop>
              <input-prop class="w-100" [placeholder]="'customer.register.social-name'" [form]="formCustomer.controls['socialName']"></input-prop>
            </div>
            <div class="flex gap-form mt-32">
              <input-prop class="w-100" [placeholder]="'requisitionEdit.formAttendanceExternal'" [form]="formNumberRequest.attendanceExternal"></input-prop>
              <input-prop class="w-100" [placeholder]="'requisitionEdit.formRequestExternal'" [form]="formNumberRequest.requestExternal"></input-prop>
              <div class="w-100"></div>
            </div>
            <div class="gap-form mt-32">
              <h2>{{ 'requisitionEdit.titleSector' | translate }}</h2>
              <div class="flex gap-form mt-32">
                <input-prop class="w-50" [placeholder]="'requisitionEdit.sectorExternalId'" [form]="formHospitalInformation.controls['sectorExternalId']" (keydown.enter)="searchSector(formHospitalInformation.controls['sectorExternalId'].value, true)" (keydown.tab)="searchSector(formHospitalInformation.controls['sectorExternalId'].value, true, true)"></input-prop>
                <input-search class="w-100" [placeholder]="'requisitionEdit.sectorId'" [options]="sectorOption" [form]="formHospitalInformation.controls['sectorId']" (changeForm)="searchSector(formHospitalInformation.controls['sectorId'].value, false)"></input-search>
              </div>
              <div class="flex gap-form mt-32">
                <input-prop class="w-50" [placeholder]="'requisitionEdit.recordHospital'" [form]="formHospitalInformation.controls['recordHospital']"></input-prop>
                <div class="w-100 flex gap-form">
                  <input-prop class="w-100" [placeholder]="'requisitionEdit.roomHospital'" [form]="formHospitalInformation.controls['roomHospital']"></input-prop>
                  <input-prop class="w-100" [placeholder]="'requisitionEdit.bedHospital'"  [form]="formHospitalInformation.controls['bedHospital']"></input-prop>
                </div>
              </div>
            </div>
            <div>
              <h2 class="flex gap-form mt-32">{{ 'requisitionEdit.titleIdentificationDocuments' | translate }}</h2>
              <div class="flex gap-form mt-32 ">
                <div class="w-100">
                  <input-prop [placeholder]="'customer.register.cpf'" [form]="formCustomer.controls['cpf']"
                    mask="000.000.000-00"></input-prop>
                </div>
                <div class="w-100">
                  <input-prop [placeholder]="'customer.register.rg'" [form]="formCustomer.controls['rg']"></input-prop>
                </div>
                <div class="flex align-center w-100">
                  <checkbox-prop [gap]="'1'" [label]="'customer.register.document-responsible'" [form]="formCustomer.controls['cpfIsResponsible']"></checkbox-prop>
                </div>
              </div>
              <div class="flex gap-form mt-32 ">
                <div class="w-100">
                  <input-prop [placeholder]="'customer.register.cns'" [form]="formCustomer.controls['cns']"></input-prop>
                </div>
                <div class="w-100">
                  <input-prop [placeholder]="'customer.register.passport'" [form]="formCustomer.controls['passportNumber']"></input-prop>
                </div>
                <div class="w-100"></div>
              </div>
            </div>
            <h2 class="flex gap-form mt-32">{{ 'requisitionEdit.titleContactDetails' | translate }}</h2>
            <div class="flex gap-form mt-32 ">
              <div class="w-100">
                <input-prop [placeholder]="'customer.register.cellphone'" [form]="formCustomer.controls['cellPhone']" mask="(00) 0 0000-0000"></input-prop>
              </div>
              <div class="w-100">
                <input-prop [placeholder]="'customer.register.residential-phone'" [form]="formCustomer.controls['residencePhone']"  mask="(00) 0000-0000"></input-prop>
              </div>
              <div class="w-100">
                <input-prop [placeholder]="'customer.register.email'" [type]="'email'" [form]="formCustomer.controls['email']"></input-prop>
              </div>
            </div>
            <h2 class="mt-32">{{ 'requisitionEdit.titleAddress' | translate }}</h2>
            <div class="flex gap-form mt-32">
              <input-prop class="w-100" class="w-100" (focusout)="getCep()" [placeholder]="'customer.register.zip-code'" [form]="formCustomer.controls['CEP']" mask="00000-000"></input-prop>
              <input-prop class="w-100" [placeholder]="'customer.register.address'" [form]="formCustomer.controls['address']"></input-prop>
              <input-prop class="w-100" [placeholder]="'customer.register.number'" [form]="formCustomer.controls['number']"
                type="number"></input-prop>
            </div>
            <div class="flex gap-form mt-32">
              <input-prop class="w-100" [placeholder]="'customer.register.complement'" [form]="formCustomer.controls['complement']"></input-prop>
              <input-prop class="w-100" [placeholder]="'customer.register.neighborhood'" [form]="formCustomer.controls['district']"></input-prop>
              <input-prop class="w-100" [placeholder]="'customer.register.city'" [form]="formCustomer.controls['city']"></input-prop>
            </div>
            <div class="flex gap-form mt-32">
              <input-prop class="w-100" [placeholder]="'customer.register.state'" [form]="formCustomer.controls['state']"></input-prop>
              <input-prop class="w-100" [placeholder]="'customer.register.country'" [form]="formCustomer.controls['country']"></input-prop>
              <div class="w-100"></div>
            </div>
          </div>
          <div class="box mt-32">
            <h2>{{ 'requisitionEdit.titleCompany' | translate }}</h2>
            <div class="mt-32">
              <select-prop [placeholder]="'requisitionEdit.companyRequisition'" [form]="formCustomer.controls['companyId']" (changeValue)="getUnities()" [options]="companyOptions"></select-prop>
            </div>
          </div>
          <div class="box mt-32">
            <h2>{{ 'requisitionEdit.titleUnit' | translate }}</h2>
            <div class="mt-32">
              <select-prop [placeholder]="'requisitionEdit.unitRequisition'" [form]="formCustomer.controls['unityId']" [options]="unityOptions"></select-prop>
            </div>
          </div>
          <div class="box mt-32">
            <h2>{{ 'requisitionEdit.titleColectionPlace' | translate }}</h2>
            <div class="mt-32">
              <select-prop [placeholder]="'requisitionEdit.colectionPlaceRequisition'" [form]="formCustomer.controls['collectionPlaceId']" [options]="collectionPlaceOptions"></select-prop>
            </div>
          </div>
          <div class="box mt-32">
            <h2>{{ 'requisitionEdit.titleClinicalInformation' | translate }}</h2>
            <div class="mt-32">
              <input-prop [placeholder]="'requisitionEdit.formClinicalInformation'" [form]="formCustomer.controls['clinicalInformation']"></input-prop>
            </div>
          </div>
          <div class="mt-32 w-100 box">
            <h2>{{ 'customer.register.additional-information' | translate }}</h2>
            <div class="flex flex-between mt-32 w-100 gap-2 checkboxResponsive">
              <checkbox-prop [gap]="'1'" [label]="'customer.register.send-email-result'" [form]="formCustomer.controls['sendEmailResult']"></checkbox-prop>
              <checkbox-prop [form]="formCustomer.controls['sendEmailSms']" class="flex flex-center" [gap]="1" [label]="'customer.register.send-sms-email'"></checkbox-prop>
            </div>
            <h2 class="flex mt-32">{{ 'customer.register.medicines-used' | translate }}</h2>
            <div class="flex flex-between mt-32">
              <checkbox-prop [form]="formCustomer.controls['continuousMedication']" (change)="getMedications()" class="flex flex-center" [gap]="1" [label]="'customer.register.continuous-medication'"></checkbox-prop>
              <div *ngIf="formCustomer.controls['continuousMedication'].value" class="mt-32">
                <app-button (click)="openModalMedication()"[placeholder]="'customer.register.add-medicines'" [class]="'flex gap-2 align-center'"></app-button>
              </div>
            </div>
            <app-table *ngIf="formCustomer.controls['continuousMedication'].value" [noShadow]="true" [pagination]="true" [backPagination]="true" (changePage)="getMedications($event)" (parentFun)="actions($event)"  [ClickInItem]="ActionsExtraMedication" [Actions]="['Editar', 'Excluir']" [Itens]="medicationsCustomer" [sumRecords]="paginationMedicines.sumRecords" [indexNumber]="paginationMedicines.index" [pageNumber]="paginationMedicines.page"></app-table>
          </div>
          <div class="grid grid-center mt-32">
            <app-button (click)="updateRequisition()" [placeholder]="'requisitionEdit.btnUpdateData'"  [class]="'flex gap-2 align-center'"></app-button>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex align-center gap-1">
          <h2>{{ 'requisitionMedicalRequest.request' | translate }}</h2>
        </div>
      </ng-template>
      <div class="boxform">
        <div class="contextPage">
          <form [formGroup]="formAgreementRequestBuilder">
            <div formArrayName="agreementRequestArray"
              *ngFor="let creds of formAgreementRequestBuilder?.controls['agreementRequestArray']?.controls let i = index">
              <ng-container [formGroupName]="i">
                <div class="box mt-32">
                  <h2>{{ 'requisitionMedicalRequest.agreement' | translate }}</h2>
                  <div class="flex flex-direction-column-mobile mt-32 gap-2">
                    <div class="flex align-center w-100 gap-form">
                      <input-prop [form]="creds.controls['codeAgreement']" class="w-code" [placeholder]="'requisitionMedicalRequest.code'" (keydown.enter)="searchAgreement(i, $event.value, true)" (keydown.tab)="searchAgreement(i,creds.controls['codeAgreement'], true)"></input-prop>
                      <input-search (search)="searchAgreement(i,$event.value)" [searchApi]="true" (changeForm)="setAgreementCod(creds.controls['agreement'].value, creds.controls['codeAgreement'],i)" [options]="agreementOptions[i]" [form]="creds.controls['agreement']" [class]="'w-100'" [placeholder]="'requisitionMedicalRequest.selectAgreement'"></input-search>
                    </div>
                    <div *ngIf="agremeentPlanOptions[i]?.length > 0" class="flex align-center w-100 gap-2">
                      <input-search [class]="'w-100'" [options]="agremeentPlanOptions[i]" [placeholder]="'requisitionMedicalRequest.plan'" [form]="creds.controls['plan']"></input-search>
                      <mat-icon (click)="checkControlPlan(creds.controls['agreement'].value,i)">refresh</mat-icon>
                    </div>
                  </div>
                  <div class="flex mt-32 gap-2 form1250">
                    <div class="flex gap-form w-100">
                      <div class="w-card">
                        <input-prop [form]="creds.controls['cardNumber']" [placeholder]="'requisitionMedicalRequest.cardNumber'"></input-prop>
                      </div>
                      <div class="w-card-alidity">
                        <input-prop [form]="creds.controls['cardValidity']" [placeholder]="'requisitionMedicalRequest.cardValidity'" type="date"></input-prop>
                      </div>
                      <div class="w-guide">
                        <input-prop [form]="creds.controls['code']" [placeholder]="'requisitionMedicalRequest.guide'"></input-prop>
                      </div>
                      <div class="w-agreement-date">
                        <input-prop [form]="creds.controls['guideValidity']" type="date" [placeholder]="'requisitionMedicalRequest.guideValidity'"></input-prop>
                      </div>
                    </div>
                    <div class="flex flex-center align-center gap-form">
                      <div class="w-authorization">
                        <input-prop [form]="creds.controls['authorization']" [placeholder]="'requisitionMedicalRequest.authorization'"></input-prop>
                      </div>
                      <div class="w-cid">
                        <input-prop [form]="creds.controls['cid']" [placeholder]="'requisitionMedicalRequest.cid'"></input-prop>
                      </div>
                    </div>
                  </div>
                  <div class="mt-32">
                    <h2>{{'global.action.guide' | translate}}</h2>
                    <div class="w-100 mt-32 gap-2">
                      <div class="flex align-center w-100 gap-form mt-32">
                        <input-prop class="w-100" [form]="creds.controls['mainCode']" [placeholder]="'requisitionMedicalRequest.mainCode'"></input-prop>
                        <input-prop class="w-100" [form]="creds.controls['emissionDate']" [placeholder]="'requisitionMedicalRequest.emissionDate'" type="date"></input-prop>
                        <input-prop class="w-100" [form]="creds.controls['healthCareCode']" [placeholder]="'requisitionMedicalRequest.healthCareCode'"></input-prop>
                        <input-prop class="w-100" [form]="creds.controls['clinicalIndication']" [placeholder]="'requisitionMedicalRequest.clinicalIndication'"></input-prop>
                      </div>
                      <div class="flex align-center w-100 gap-form mt-32">
                        <select-prop class="w-100" [form]="creds.controls['typeAttendance']" [options]="typeAttendance" [placeholder]="'requisitionMedicalRequest.typeAttendance'"></select-prop>
                        <select-prop class="w-100" [form]="creds.controls['typeOut']" [options]="typeOut" [placeholder]="'requisitionMedicalRequest.typeOut'"></select-prop>
                        <select-prop class="w-100" [form]="creds.controls['accidentIndication']" [options]="accidentIndication" [placeholder]="'requisitionMedicalRequest.accidentIndication'"></select-prop>
                        <select-prop class="w-100" [form]="creds.controls['typeCharacterService']" [options]="typeCharacterService" [placeholder]="'requisitionMedicalRequest.typeCharacterService'"></select-prop>
                      </div>
                      <div class="flex align-center w-100 gap-form mt-32">
                        <textarea-prop class="w-100" [form]="creds.controls['tissObservation']" [placeholder]="'requisitionMedicalRequest.tissObservation'"></textarea-prop>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-32 box">
                  <h2>{{'requisitionMedicalRequest.requester.titleRequester' | translate}}</h2>
                  <div class="w-100 mt-32 flex align-center gap-2 form700">
                    <input-prop [form]="creds.controls['codeDoctor']" (keydown.enter)="searchDoctor(i,creds.controls['codeDoctor'].value, true)" (keydown.tab)="searchDoctor(i,creds.controls['codeDoctor'].value, true)" class="w-code" [placeholder]="'requisitionMedicalRequest.requester.codeDoctor'"></input-prop>
                    <input-search [form]="creds.controls['doctorId']" (changeForm)="setDoctorCode(creds.controls['doctorId'].value, creds.controls['codeDoctor'],i)" [class]="'w-100'" [searchApi]="true" [placeholder]="'requisitionMedicalRequest.requester.doctorId'" (search)="searchDoctor(i,$event.value )" [options]="doctorOptions[i]"></input-search>
                    <checkbox-prop [form]="creds.controls['deliveryRelease']" class="checkbox-delivery" [gap]="1" [label]="'requisitionMedicalRequest.requester.deliveryRelease'"></checkbox-prop>
                  </div>
                </div>
                <div class="flex flex-center mt-32">
                  <app-button (click)="updateRequisitionAgreementRequest(creds.controls['id'].value,i)" [placeholder]="'requisitionMedicalRequest.requester.btnUpdateRequest'" [class]="'flex gap-2 align-center'"></app-button>
                </div>
              </ng-container>
            </div>
          </form>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex align-center gap-1">
          <h2>{{'advancedMaintence.titleExam' | translate}}</h2>
        </div>
      </ng-template>
      <div class="contextPage">
        <div class="flex gap-3 flex-direction-column-mobile mt-32 w-100">
          <div style="max-width: 190px;" class="w-100 flex flex-center align-center">
            <checkbox-prop (click)="checkboxAll($event)" [label]="'advancedMaintence.checkboxAll'" [gap]="1" [form]=""></checkbox-prop>
          </div>
          <div class="flex gap-3 flex-direction-column-mobile w-100 flex-between">
            <div class="w-100">
              <app-input-suboptions #inputSuboptionsActions [placeholder]="'advancedMaintence.action'" [options]="actionOptions" [form]="formExamAction.acao" (changeForm)="changeActions()" (click)="setFocus()"></app-input-suboptions>
            </div>
          </div>
          <div *ngIf='formExamAction.acao.value === 16'
            class="flex gap-3 flex-direction-column-mobile w-100 flex-between">
            <div class="w-100 flex align-center">
              <input-search class="w-100" [placeholder]="'advancedMaintence.collectionSource'" [options]="actionValueOptions" [form]="formActionValue"></input-search>
            </div>
          </div>
          <div *ngIf='formExamAction.acao.value === 107'
            class="flex gap-3 flex-direction-column-mobile w-100 flex-between">
            <div class="w-100 flex align-center">
              <input-prop class="w-100" type="date" [form]="" [placeholder]="'advancedMaintence.billingDate'"></input-prop>
            </div>
          </div>
          <div *ngIf='formExamAction.acao.value === 201'
            class="flex gap-3 flex-direction-column-mobile w-100 flex-between">
            <div class="w-100 flex align-center">
              <input-prop class="w-100" type="datetime-local" [form]="formActions.deadline" [placeholder]="'advancedMaintence.forecastDate'"></input-prop>
            </div>
          </div>
          <div *ngIf='formExamAction.acao.value === 202'
            class="flex gap-3 flex-direction-column-mobile w-100 flex-between">
            <div class="w-100 flex align-center">
              <input-prop class="w-100" type="datetime-local" [form]="formActionValue" [placeholder]="'advancedMaintence.collectionDate'"></input-prop>
            </div>
          </div>
          <div *ngIf='formExamAction.acao.value === 108'
            class="flex gap-3 flex-direction-column-mobile w-100 flex-between">
            <div class="w-100 flex align-center">
              <select-prop class="w-100" [form]="" [placeholder]="'advancedMaintence.numberOfExams'"></select-prop>
            </div>
          </div>
          <div *ngIf='' class="flex gap-3 flex-direction-column-mobile w-100 flex-between">
            <div class="w-100">
              <input-prop [form]="" [placeholder]="'advancedMaintence.primaryLabelCode'"></input-prop>
            </div>
          </div>
          <div *ngIf='' class="flex gap-3 flex-direction-column-mobile w-100 flex-between">
            <div class="w-100">
              <input-prop [form]="" [placeholder]="'advancedMaintence.registrationNumber'"></input-prop>
            </div>
          </div>
          <div *ngIf='' class="flex gap-3 flex-direction-column-mobile w-100 flex-between">
            <div class="w-100">
              <select-prop [form]="" [placeholder]="'advancedMaintence.unitRecipient'"></select-prop>
            </div>
          </div>
        </div>
        <div class="flex flex-center gap-2">
          <app-button *ngIf='formExamAction.acao.value != null || undefined' (click)="confirm()" [class]="'flex gap-2 align-center'" [placeholder]="'global.confirm'"></app-button>
        </div>
        <app-table [Pagination]="true" [backPagination]="false" [Itens]="exams" [ClickInItem]="ActionsExtra" (parentFun)="Actions($event)"></app-table>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex align-center gap-1">
          <h2>{{'advancedMaintence.titleFinancial' | translate}}</h2>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex align-center gap-1">
          <h2>{{'advancedMaintence.titleInvoicing' | translate}}</h2>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex align-center gap-1">
          <h2>{{'advancedMaintence.titleOccurrences' | translate}}</h2>
        </div>
      </ng-template>
    </mat-tab>

  </mat-tab-group>
  <ng-template #dialogModalManualMedication let-Modal>
    <ng-container>
      <div>
        <div class="flex flex-between w-100 align-center">
          <h1>{{'advancedMaintence.titleMedication' | translate}}</h1>
          <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
          </svg>
        </div>
        <div class="mt-32 w-100 flex flex-between gap-form">
          <input-prop class="w-100" [placeholder]="'advancedMaintence.titleMedication'" [form]="formMedication.medication"></input-prop>
        </div>
        <div class="mt-32 flex flex-center">
          <app-button (click)="saveManualMedication(Modal.id)" [placeholder]="'global.save'" [class]="'btn'"></app-button>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #dialogModalMedication let-Medication>
    <ng-container>
      <div class="flex flex-between w-100 align-center">
        <h1>{{'advancedMaintence.titleAddMedication' | translate}}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
        </svg>
      </div>
      <div class="mt-32 w-100">
        <div class="search">
          <input-prop class="w-100" [placeholder]="'advancedMaintence.titleMedication'" [form]="formMedication.medication"
            (keydown.enter)="createMedicine()"></input-prop>
        </div>
      </div>
      <div class="w-100 flex flex-center mt-32">
        <app-button (click)="createMedicine()" [placeholder]="'global.save'" [class]="'btn'"></app-button>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #dialogModalMaterial let-examId>
    <ng-container>
      <div class="flex flex-between w-100 align-center">
        <h1>{{'advancedMaintence.titleMaterial' | translate}}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
        </svg>
      </div>
      <div class="mt-32 w-100">
        <div class="search">
          <input-search [options]="materialOptions" (keydown.enter)="changeMaterial(examId)" [placeholder]="'advancedMaintence.selectMaterial'" [form]="formModal"></input-search>
        </div>
      </div>
      <div class="w-100 flex flex-center mt-32">
        <app-button (click)="changeMaterial(examId)" [placeholder]="'global.save'" [class]="'btn'"></app-button>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #dialogModalRoutine let-examId>
    <ng-container>
      <div class="flex flex-between w-100 align-center">
        <h1>{{'advancedMaintence.titleRoutine' | translate}}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
        </svg>
      </div>
      <div class="mt-32 w-100">
        <div class="search">
          <input-search [options]="routineOptions" (keydown.enter)="changeRoutine(examId)" [placeholder]="'advancedMaintence.routine'" [form]="formModal"></input-search>
        </div>
      </div>
      <div class="w-100 flex flex-center mt-32">
        <app-button (click)="changeRoutine(examId)" [placeholder]="'global.save'" [class]="'btn'"></app-button>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #dialogModalDeadline let-examId>
    <ng-container>
      <div class="flex flex-between w-100 align-center">
        <h1>{{'advancedMaintence.titleForecast' | translate}}</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
        </svg>
      </div>
      <div class="mt-32 w-100">
        <div class="search">
          <input-prop class="w-100" type="datetime-local" (keydown.enter)="changeDeadline(examId)" [form]="formModal" [placeholder]="'advancedMaintence.forecastDate'"></input-prop>
        </div>
      </div>
      <div class="w-100 flex flex-center mt-32">
        <app-button (click)="changeDeadline(examId)" [placeholder]="'global.save'" [class]="'btn'"></app-button>
      </div>
    </ng-container>
  </ng-template>