import { Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InputPropComponent } from '../input-prop/input-prop.component';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  @Input() topUnset: boolean = false;
  @Input() advanced: boolean = false;
  @Input() cleanButton: boolean = false;

  @Output() beforeClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearFilters: EventEmitter<any> = new EventEmitter<any>();

  top: string = '';
  advancedFilter: boolean = false;
  showSearch: boolean = false;
  maxHeight: string = '';

  // @HostListener é para pesquisar e fechar o filtro quando apertar "enter" no componente de pesquisa "
  //TODO Verificar se há uma melhor abordagem
  @HostListener('keydown.enter', ['$event'])
  onEnterKey(event: any) {
    this.beforeClose.emit();
    this.alterSearch();
  }

  @HostListener('window:resize', ['$event'])
  onResizeWindow(): void {
    this.resizeFilter()
  }

  @ViewChild('filter') filter!: ElementRef;
  @ViewChild('btn') btn!: ElementRef;
  @ViewChild('fullContent', { static: false }) fullFilterContent!: ElementRef<HTMLDivElement>;
  @ContentChild('autoFocus', { static: false }) focusInput!: InputPropComponent;

  constructor(
    private _el: ElementRef
  ) { }

  ngAfterViewInit() {
    document.addEventListener('mousedown', (event) => {
      //@ts-ignore
      if (!this.filter.nativeElement.contains(event.target) && !this.btn.nativeElement.contains(event.target) && event.target.outerHTML.search('data-mat-icon-type="font">done</mat-icon>') == -1 && document.getElementsByClassName("cdk-overlay-pane mat-datepicker-popup").length == 0) {
        this.showSearch = false;
      }
    });
  }

  position(elemento: HTMLElement): { botton: number } {
    const rect = elemento.getBoundingClientRect();
    return { botton: rect.bottom };
  }

  getPosition() {
    if (!this.topUnset){
      const posicao = this.position(this._el.nativeElement);
      this.top = Math.round(posicao.botton + 20).toString() + 'px';
    }
  }

  openAdvancedFilters() {
    this.advancedFilter = !this.advancedFilter
    setTimeout(() => {
      this.resizeFilter();
    }, 0);
  }

  alterSearch() {
    this.showSearch = !this.showSearch
    setTimeout(() => {
      this.resizeFilter();
      if (this.focusInput) this.focusInput.focusInput()
    }, 0);
  }

  clearFiltersEmit() {
    this.clearFilters.emit(true)
  }

  resizeFilter() {
    const componentElement = this.fullFilterContent.nativeElement;
    const rect = componentElement.getBoundingClientRect();

    if (rect.y + rect.height > window.innerHeight)
      return this.maxHeight = window.innerHeight - this.fullFilterContent.nativeElement.getBoundingClientRect().top - 10 + "px"

    return this.maxHeight = ''
  }
}
