import { v4 as uuidv4 } from 'uuid';

export class Guid {
  private guid: string;

  constructor(guid?: string) {
    this.guid = guid || Guid.getNewGUIDString();
  }

  public toString() {
    return this.guid;
  }

  isEmpty(): boolean {
    return this.guid === '00000000-0000-0000-0000-000000000000' || this.guid === '';
  }

  private static getNewGUIDString(): string {
    return uuidv4();
  }

  public static getEmpty(): string {
    return '00000000-0000-0000-0000-000000000000';
  }

  public static getGuidEmpty(): Guid {
    return new Guid('00000000-0000-0000-0000-000000000000');
  }
}
