import { Injectable } from '@angular/core';
import { BaseAuthService } from "./base-auth.service";
import { HttpAuthService } from './http-auth.service';
import { HttpResponsePageable } from '../services.types';
import { GetUserByCodeBar } from './acesscontrol-user-codebar.types';

@Injectable({
    providedIn: 'root'
})
export class AccessControlUserCodeBar extends BaseAuthService {
    constructor(HttpAuth: HttpAuthService) {
        super(HttpAuth, 'v1/accesscontrol/user/codebar');
    }

    override getByPath(queryString: string, path: string, headers?: any[]) {
        return super.getByPath(queryString, path) as Promise<HttpResponsePageable<GetUserByCodeBar>>
    }
}
