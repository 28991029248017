import { CommonModule } from "@angular/common";
import {
   Component,
   inject,
   OnInit,
   QueryList,
   ViewChildren
} from "@angular/core";
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GroupsFacade } from "@app/modules_new/administrativeData/data/facade/groups.facade";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ModalLayoutComponent } from "@app/modules_new/shared/components/form/modal/modal.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "groups-modal",
   templateUrl: "./groups-modal.component.html",
   standalone: true,
   imports: [
      ModalLayoutComponent,
      InputTextFormComponent,
      ReactiveFormsModule,
      ButtonFormComponent,
      CommonModule,
      TranslateModule
   ]
})
export class GroupsModal implements OnInit {
   private _groupsFacade = inject(GroupsFacade);
   public _dialogData = inject(MAT_DIALOG_DATA);

   formGroup = new FormGroup({
      name: new FormControl<string | null>(null, [Validators.required])
   });

   title: string = "Novo grupo";

   @ViewChildren(InputTextFormComponent)
   InputTextFormComponent!: QueryList<InputTextFormComponent>;

   async ngOnInit() {
      if (this._dialogData) {
         this.formGroup.controls.name.setValue(this._dialogData.name);
         this.title = "Editar Grupo";
      }
   }

   async submit() {
      const obj = {
         name: this.formGroup.controls.name.value
      };

      if (this._dialogData) {
         await this._groupsFacade.put(obj, this._dialogData.id);
      } else {
         const response: any = await this._groupsFacade.post(obj);
         if (response.success) {
            this.InputTextFormComponent.first.focusInput();
            this.formGroup.controls.name.reset();
         }
      }
   }
}
