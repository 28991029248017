import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SupportLaboratoryRequestService extends BaseService {

constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/supportlaboratory/request');
  }
}
