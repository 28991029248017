import { DefaultFormats } from "@app/pipe/localeDate.pipe"

export interface Actions {
    action: string,
    id: string,
    context: string | null,
    object: ResolveListOccurency
}
  
export interface ResolveListOccurency{
    id: string
    Ativo: string
    ['description']:string
}
export const resolveOccurrency: Array<Object> = [
    {
        label: 'id',
        retrive: 'id',
        method: '',
        after: '',
        before: ''
    },
    {
        label: "Descrição",
        method: 'formatField',
        retrive: '',
        after: '',
        before: '',
        fieldName: 'description',
        type: 'description',
    },
    {
        label: 'Criado em',
        retrive: '',
        method: 'formatField',
        after: '',
        before: '',
        fieldName: 'createdAt',
        type: 'date',
        dateFormat: DefaultFormats.full,
        sort: 'createdAt'
      },
      {
        label: 'Alterado em',
        retrive: '',
        method: 'formatField',
        after: '',
        before: '',
        fieldName: 'updatedAt',
        type: 'date',
        dateFormat: DefaultFormats.full,
        sort: 'updatedAt'
      },
      {
        label: 'Ativo',
        retrive: '',
        method: 'formatField',
        after: '',
        before: '',
        fieldName: 'isActive',
        type: 'active',
        sort: 'isActive'
      }
];