import {
   ActionDefition,
   ColumnDefinition
} from "@app/modules_new/shared/components/form/table/table.model";

export const tableActions: ActionDefition[] = [
   {
      label: "Editar",
      svg: "action-write",
      event: "EDITAR"
   },
   {
      label: "default.delete",
      svg: "action-trash",
      event: "DELETE"
   }
];

export const tableColumns: ColumnDefinition[] = [
   {
      label: "Descrição",
      key: "description"
   },
   {
      label: "URL",
      key: "url"
   }
   // {
   //    label: "global.title-list.created-at",
   //    key: "createdAt",
   //    pipe: {
   //       name: "localeDate",
   //       args: ["short"]
   //    }
   // },
   // {
   //    label: "global.title-list.updated-at",
   //    key: "updatedAt",
   //    pipe: {
   //       name: "localeDate",
   //       args: ["short"]
   //    }
   // },
   // {
   //    label: "Ativo",
   //    key: "isActive",
   //    component: "checkbox"
   // }
];
