import { Pipe, PipeTransform, inject } from '@angular/core';
import { HtmlSanitizerPipe } from './sanitizer-html.pipe';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'span',
  standalone: true,
})
export class SpanPipe implements PipeTransform {
  private _htmlSanitizer = inject(HtmlSanitizerPipe);

  private DEFAULT_MODEL = '<span class="span-styles" style="background: #BG_COLOR; color: #TEXT_COLOR;">#TEXT</span>';

  transform(value: number | string, spans: Record<number | string, string[]>): SafeHtml {
    const spanHtml = this._replaveValues(spans[value]);
    return this._htmlSanitizer.transform(spanHtml);
  }

  private _replaveValues(values: string[]) {
    const { backgroundColor, textColor, text } = this._getValues(values);

    return this.DEFAULT_MODEL.replace('#BG_COLOR', backgroundColor).replace('#TEXT_COLOR', textColor).replace('#TEXT', text);
  }

  private _getValues(values: string[]) {
    if (values.length < 3) throw new Error('PipeSpan: Valores inválidos');

    return {
      backgroundColor: values[0],
      textColor: values[1],
      text: values[2],
    };
  }
}
