<div>
  <button #btn (click)="alterSearch(); getPosition()" class="flex gp-12 align-center">
    <mat-icon>filter_list</mat-icon>
    {{ 'filter.button' | translate }}
  </button>
</div>
<div [ngClass]="!showSearch ? 'hidden' : 'opened'">
  <div #filter [ngStyle]="{'top': top}" class="pop">
    <div class="contextPage overflow-auto" #fullContent [style.max-height]="maxHeight"> 
      <div class="flex flex-between align-center">
        <h1>{{ 'filter.title' | translate }}</h1>
        <div class="flex gp-12 hover">
          <mat-icon *ngIf="cleanButton" (click)="clearFiltersEmit()" matTooltip="{{ 'filter.refresh' | translate}}" [matTooltipPosition]="'above'">refresh</mat-icon>
          <mat-icon (click)="alterSearch()" matTooltip="{{ 'filter.close' | translate }}" [matTooltipPosition]="'above'">close</mat-icon>
        </div>
      </div>
      <div class="flex flex-column align-center">
        <div class="mt-32">
          <!--Use a class "query" nos que vai aparecer antes da pesquisa avançada-->
          <ng-content select=".query"></ng-content>
        </div>
        <div (click)="openAdvancedFilters()" class="flex align-center flex-center hte" [ngClass]="!advanced ? 'hidden' : 'opened'">
          <span class="spanNegrito">Pesquisa Avançada</span>
          <mat-icon class="hover"  [ngClass]="advancedFilter ? 'hidden' : 'opened'">keyboard_arrow_down</mat-icon>
          <mat-icon class="hover" [ngClass]="!advancedFilter ? 'hidden' : 'opened'">keyboard_arrow_up</mat-icon>
        </div>
        <div class="flex flex-column mt-16" [ngClass]="!advancedFilter ? 'hidden' : 'opened'">
          <!--Use a class "advancedQuery" no que se adequa a pesquisa avançada-->
          <ng-content select=".advancedQuery"></ng-content>
        </div>
        <div (click)="alterSearch()" class="mt-16">
          <!--Use a class "queryBtn" para incluir o botão dentro do modelo-->
          <ng-content select=".queryBtn"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
