<div [ngClass]="menuopen ? 'menu act' : 'menu'">
    <ng-content select=".padding"></ng-content> 
    <ul>
        <li *ngFor="let item of MenuObject; let i = index" [ngClass]="item.href ? '' : 'notItem'" (click)="closeMenu()">
            <a [routerLink]="item.href" class="flex align-center">
                <div [innerHTML]="newSteps[i]" class="mr"></div>
                {{ item.name }}
            </a>
        </li>
    </ul>
</div>    