<div class="contentPage">
  <div class="flex flex-between w-100 mb-32 align-center headerResponsive">
    <div class="flex gap-2 align-center titleResponsive">
      <h1>Laboratórios de Apoio</h1>
      <app-button (click)="createSupportLab()" [placeholder]="'Novo'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="getSupportLaboratory()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus (keyup.enter)="getSupportLaboratory()" [form]="search" [placeholder]="'Pesquisar'"></input-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" label="Somente Ativos" [form]="isActive"></checkbox-prop>
      </div>
      <app-button (click)="getSupportLaboratory()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <app-table [Dropdown]="true" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [ClickInItem]="ActionsExtra" [Itens]="registerLaboratory" [Actions]="['Editar' , 'Excluir']" (changePage)="getSupportLaboratory($event)" (parentFun)="actions($event)">
    <ng-template let-rowDetail #header>
      <div class="flex flex-between align-center">
        <h2>De/Para de Exames:</h2>
        <app-button (click)="openDialogExam(false, rowDetail)" [placeholder]="'Novo'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
      </div>
      <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="totalExam[rowDetail.id]" [pageNumber]="page" [indexNumber]="index" [ClickInItem]="ActionsExtra" [Itens]="registerExams[rowDetail.id]" [Actions]="['Editar' , 'Excluir']" (changePage)="getExam(rowDetail.id, $event)" (parentFun)="actionsExam($event)"></app-table>
    </ng-template>
  </app-table>
</div>

<ng-template #dialogExam let-extraInformation>
  <div class="flex flex-between">
    <h1 class="mb-32">{{extraInformation.edit ? 'Editar' : 'Novo'}} De/Para de Exame</h1>
    <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
    </svg>
  </div>
  <div class="flex gap-form mt-32">
    <input-search class="w-100" [form]="toFromExamsFormGroup.controls['analyteId']" [placeholder]="'Selecione o Exame'" [options]="formOptionsAnalyte" (changeForm)="toFromExamsFormGroup.controls['analyteId'].value ? getMaterialsAndReports() : ''"></input-search>
    <input-prop class="w-100" [form]="toFromExamsFormGroup.controls['codeInSupportLaboratory']" [placeholder]="'Informe o Código do Exame no Apoio'"></input-prop>
  </div>
  <div class="flex gap-form mt-32">
    <input-search class="w-100" [form]="toFromExamsFormGroup.controls['materialId']" [placeholder]="'Selecione o Material do Exame'" [options]="formOptionsMaterial"></input-search>
    <input-prop class="w-100" [form]="toFromExamsFormGroup.controls['material']" [placeholder]="'Informe o Código do Material no Apoio'"></input-prop>
  </div>
  <div class="flex gap-form mt-32">
    <input-search class="w-100" [form]="toFromExamsFormGroup.controls['analyteReportId']" [placeholder]="'Selecione o Laudo do Exame'" [options]="analyteReportOptions"></input-search>
    <input-prop-currency class="w-100" [form]="toFromExamsFormGroup.controls['value']" [placeholder]="'Valor'"></input-prop-currency>
  </div>
  <div class="flex flex-between mt-32 gap-form">
    <checkbox-prop class="flex flex-center" label="Receber Valores de Referência do Apoio" [gap]="1" [form]="toFromExamsFormGroup.controls['reference']"></checkbox-prop>
    <checkbox-prop class="flex flex-center" label="Possui Relacionamento (De/Para de Atributos)" [gap]="1" [form]="toFromExamsFormGroup.controls['hasRelationship']"></checkbox-prop>
  </div>
  <div class="flex flex-center mt-32">
    <app-button (click)="extraInformation.edit ? updateExam(extraInformation.rowDetail) : postExam()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
  </div>
</ng-template>