import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { GetUnity } from '@app/company/company.model';
import { CollectionPlaceService } from '@app/services/collection-places/collection-place.service';
import { CompanyService } from '@app/services/company/company.service';
import { QueryParams } from '@app/services/interfaces/services.types';
import { RecipientShippingDistributionService } from '@app/services/recipient-shipping-distribution/recipient-shipping-distribution.service';
import { UnityService } from '@app/services/shared/unity.service';
import { ShippingDistributionService } from '@app/services/shipping-distribution/shipping-distribution.service';
import decodedToken from '@app/util/Token';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Util } from '@app/util/util';
import { resolveMaterials, resolveRecipients } from './create-shipping-distribution.component.model';

@Component({
  selector: 'app-create-shipping-distribution',
  templateUrl: './create-shipping-distribution.component.html',
  styleUrls: ['./create-shipping-distribution.component.scss']
})
export class CreateShippingDistributionComponent implements OnInit {
  lotNumber = '';
  companys: Array<any> = [];
  units: Array<any> = [];  
  collectionPlaces: Array<any> = [];
  materials: Array<any> = [];
  lotRecipients: Array<any> = [];
  allLotRecipients: Array<any> = [];
  deleteRegisters: Array<Guid> = [];
  checkBoxTrue : boolean = false;
  checkBoxTrueLot : boolean = false;
  registerChecked: Array<Guid> = [];
  data: Array<Object> = [];  
  total: number = 0;
  page: number = 1;
  index: number = 30;
  selectedIndex = 0;
  id:string = '';
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new UntypedFormControl(this.positionOptions[3]);
  
  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>
  form = {
    company: new UntypedFormControl(null),
    unity: new UntypedFormControl(null),
    collectionPlace: new UntypedFormControl(null),        
    date: new UntypedFormControl(new Date(Date.now())),
    checkboxAll: new UntypedFormControl(false),
    code: new UntypedFormControl(null),
    temperature: new UntypedFormControl(null)
  }

  formEdit = {
    company: new UntypedFormControl(''),
    companyId: new UntypedFormControl(null),
    unity: new UntypedFormControl(''),
    unityId: new UntypedFormControl(null),    
    collectionPlace: new UntypedFormControl(''),    
    collectionPlaceId: new UntypedFormControl(null),
    date: new UntypedFormControl(null)
  }

  ActionsExtra = {
    Selecionar: 'check'    
  };

  recipientSituation = {
    0: "<span class='span-styles' style='background: #006E9D;color: #FFFFFF;'>Congelado</span>",
    1: "<span class='span-styles' style='background: #07BF56;color: #FFFFFF;'>Ambiente</span>",
    2: "<span class='span-styles' style='background: #FFC000;color: #FFFFFF;'>Resfriado</span>"    
  }

  attendanceSituation = {
    0: "<span class='span-styles' style='background: #07BF56;color: #FFFFFF;'>Normal</span>",
    1: "<span class='span-styles' style='background: #EB5757;color: #FFFFFF;'>Urgente</span>",
    2: "<span class='span-styles' style='background: #FFC000;color: #FFFFFF;'>Emergência</span>"    
  }

  examSituation = {
    0: "<span class='span-styles' style='background: #F8F8FF; color: #000000;'>Não Coletado</span>",
    1: "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Aguardando Coleta</span>",
    2: "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Coletado Parcial</span>",
    3: "<span class='span-styles' style='background: #FF0000; color: #F8F8FF;'>Coletado</span>",
    4: "<span class='span-styles' style='background: #363636; color: #F8F8FF;'>Solic. Recoleta</span>",
    5: "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Recoletado</span>",
    6: "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Triado</span>",
    7: "<span class='span-styles' style='background: #F8F8FF; color: #00FFFF;'>Digitado Parcial</span>",
    8: "<span class='span-styles' style='background: #F8F8FF; color: #00FFFF;'>Digitado</span>",
    9: "<span class='span-styles' style='background: #00FFFF; color: #000000;'>Conferido</span>",
    10: "<span class='span-styles' style='background: #F8F8FF; color: #00FF00;'>Liberado</span>",
    11: "<span class='span-styles' style='background: #F8F8FF; color: #0000FF;'>Entregue</span>",
    12: "<span class='span-styles' style='background: #F8F8FF; color: #00008B;'>Impresso</span>",
    20: "<span class='span-styles' style='background: #0000FF; color: #C0C0C0;'>Suspenso de Fatura</span>",
    21: "<span class='span-styles' style='background: #00FF00; color: #F8F8FF;'>Exclusivo de Fatura</span>",
    30: "<span class='span-styles' style='background: #F8F8FF; color: #C0C0C0;'>Cancelado</span>",
    91: "<span class='span-styles' style='background: #F8F8FF; color: #C0C0C0;'>Em Coleta</span>",
  }

  resolveMaterials = [
    {
      label: 'Selecionar',
      retrive: '',
      method: 'returnCheckBox',
      after: '',
      before: ''
    },
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Requisição',
      retrive: 'requisitionNumber',
      method: '',
      after: '',
      before: '',
      sort: 'requisitionNumber'
    },
    {
      label: 'Nome',
      retrive: 'customerName',
      method: '',
      after: '',
      before: '',
      sort: 'customerName'
    },
    {
      label: 'Código Recipiente',
      retrive: 'recipientExternalId',
      method: '',
      after: '',
      before: '',
      sort: 'recipientExternalId '
    },
    {
      label: 'Recipiente',
      retrive: 'recipientDescription',
      method: '',
      after: '',
      before: '',
      sort: 'recipientDescription '
    },
    {
      label: 'Código de barras recipiente',
      retrive: 'codeBar',
      method: '',
      after: '',
      before: '',
      sort: 'codeBar'
    },
    // {
    //   label: 'Situação',
    //   retrive: '',
    //   method: 'getExamSituation',
    //   after: '',
    //   before: '',
    //   sort: 'situation '
    // },
    {
      label: 'Caráter Atendimento',
      retrive: '',
      method: 'getAttendanceSituation',
      after: '',
      before: '',
      sort: 'attendanceSituation'
    },
    {
      label: 'Conservação',
      retrive: 'conservation',
      method: 'getRecipientSituation',
      after: '',
      before: '',
      sort: 'recipientSituation'
    }
  ];

  resolveRecipients = [
    {
      label: 'Selecionar',
      retrive: '',
      method: 'returnCheckBox',
      after: '',
      before: ''
    },
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Requisição',
      retrive: 'number',
      method: '',
      after: '',
      before: '',
      sort: 'number'
    },
    {
      label: 'Nome',
      retrive: 'name',
      method: '',
      after: '',
      before: '',
      sort: 'name'
    },
    {
      label: 'Código Recipiente',
      retrive: 'externalId',
      method: '',
      after: '',
      before: '',
      sort: 'externalId '
    },
    {
      label: 'Recipiente',
      retrive: 'description',
      method: '',
      after: '',
      before: '',
      sort: 'description '
    },
    {
      label: 'Código de barras recipiente',
      retrive: 'codeBar',
      method: '',
      after: '',
      before: '',
      sort: 'codeBar'
    },
    // {
    //   label: 'Situação',
    //   retrive: '',
    //   method: 'getExamSituation',
    //   after: '',
    //   before: '',
    //   sort: 'situation '
    // },
    {
      label: 'Caráter Atendimento',
      retrive: '',
      method: 'getAttendanceSituation',
      after: '',
      before: '',
      sort: 'attendanceSituation'
    },
    {
      label: 'Conservação',
      retrive: 'conservation',
      method: 'getRecipientSituation',
      after: '',
      before: '',
      sort: 'recipientSituation'
    }
  ];

  constructor(private _companyService: CompanyService, private _unityService: UnityService, private _dialog: MatDialog,
    private _collectionPlaceService: CollectionPlaceService, private _shippingDistributionService:ShippingDistributionService, private _route: ActivatedRoute,
    private _recipientShippingDistributionService:RecipientShippingDistributionService, private _router: Router, private _util: Util) { }

  async ngOnInit() {    
    this.form.date.setValue(this.incrementDays(new Date(), -7));
    if (this._route.snapshot.params['id'] && this._route.snapshot.params['id'].split('$')[0] != "Novo" || this.id != '') {
      if (this.id == ''){
        this.id = this._route.snapshot.params['id'].split('$')[0];
      }      
      await this.getShippingDistribution();
    } else {   
      await this.getCompanys();
      this.form.company.setValue(decodedToken.cid);      
      await this.getUnities(false);
      this.form.unity.setValue(decodedToken.uid);
      await this.getCollectionPlace();
      await this.getRecipients();
    }    
  }

  async getUnities(get:boolean=true) {        
    const queryObject: QueryParams = {
      resume:true,
      companyId: this.form.company.value
    }

    await this._unityService.getPagination<GetUnity>(queryObject).then(response => {
      if (response.success) {
        this.units = response.data.map((company) => (
          {
            value: company.id,
            label: company.name
          }
        ))
      }
    })
    if (get){
      await this.getRecipients();
    }
  }
  
  async getShippingDistribution(){    
    this.lotRecipients = [];
    await this._shippingDistributionService.getById(new Guid(this.id)).then((response : any) => {        
      if (response.data.length > 0) {
        this.lotNumber = response.data[0].lotId;
        this.formEdit.collectionPlace.setValue(response.data[0].collectionPlaceDescription);
        this.formEdit.collectionPlaceId.setValue(response.data[0].collectionPlaceId);
        this.formEdit.company.setValue(response.data[0].companyName);
        this.formEdit.companyId.setValue(response.data[0].companyId);
        this.formEdit.unity.setValue(response.data[0].name);
        this.formEdit.unityId.setValue(response.data[0].unityId);
        this.formEdit.date.setValue(response.data[0].dateSend);
        this.allLotRecipients = response.data[0].recipientShippingDistributions;
        this.resolveRecipientsLots(response.data[0].recipientShippingDistributions, resolveRecipients);
      }
    })   
  }

  getRecipients(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }
    let getParameters: string = (`?page=1&index=9999999`);
    if (this.id == ''){
      if (this.form.company.value){
        getParameters += '&companyId='+this.form.company.value;
      }
      if (this.form.unity.value){
        getParameters += '&unityId='+this.form.unity.value;
      }
      if (this.form.collectionPlace.value){
        getParameters += '&collectionPlaceId='+this.form.collectionPlace.value;
      }      
      if (this.form.date.value){
        getParameters += '&InitialDate='+ new Date(this.form.date.value).toISOString(); 
      }      
    } else {
      if (this.formEdit.companyId.value){
        getParameters += '&companyId='+this.formEdit.companyId.value;
      }
      if (this.formEdit.unityId.value){
        getParameters += '&unityId='+this.formEdit.unityId.value;
      }
      if (this.formEdit.collectionPlaceId.value){
        getParameters += '&collectionPlaceId='+this.formEdit.collectionPlaceId.value;
      }
    }
    if ((this.form.company.value && this.form.unity.value && this.form.collectionPlace.value)){
      this.materials = [];
      this._shippingDistributionService.getByPath(getParameters, 'recipientswithoutlot').then((response : any) => {
        //@ts-ignore
        if (response.data.length > 0) {
          this.data = response.data;
          this.total = response.sumRecords;
          //@ts-ignore
          this.resolve(response.data, resolveMaterials);
        }
      })    
    }
  }

  async getCompanys(){
    this.companys = [];
    await this._companyService.getResume().then(
      (res) => {
        if (res.data.length != 0) {
          res.data.forEach((x) => {
            const optionObj = {
              value : x.id,
              label: x.socialReason
            }
            this.companys.push(optionObj)
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );    
  }

  async getCollectionPlace(){    
    this.collectionPlaces = [];    
    await this._collectionPlaceService.getResume().then(
      (res) => {
        if (res.data.length != 0) {
          res.data.forEach((x) => {
            const optionObj = {
              value : x.id,
              label: x.description
            }
            this.collectionPlaces.push(optionObj)
          });
        }        
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );    
  }

  getAttendanceSituation(item:any){
    //@ts-ignore
    return this.attendanceSituation[item.routine];
  }

  getRecipientSituation(item:any){
    //@ts-ignore
    return this.recipientSituation[item.recipientSituation];
  }

  getExamSituation(item:any){
    //@ts-ignore
    return this.examSituation[item.situation];
  }

  back() {
    this._router.navigateByUrl('/Movimentacao/Recepcao/Envio-Remessa-Materiais-Distribuicao')
  }

  resolveRecipientsLots(data: any, columns: any) {
    this.lotRecipients = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.lotRecipients.push(obj);
    }); 
  }

  resolve(data: any, columns: any) {
    this.materials = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.materials.push(obj);
    });    
  }

  checkboxAll(event:any){    
    if (event.srcElement.checked != undefined){
      if (this.lotNumber == ''){
        this.checkBoxTrue = event.srcElement.checked;
        this.registerChecked = [];
        if (this.checkBoxTrue){
          this.data.forEach((exam: any) => {
            this.registerChecked.push(exam.id);
          });
        }          
        this.resolve(this.data, resolveMaterials);
      } else {
        this.checkBoxTrueLot = event.srcElement.checked;
        this.deleteRegisters = [];
        if (this.checkBoxTrueLot){
          this.allLotRecipients.forEach((exam: any) => {
            this.deleteRegisters.push(exam.id);
          });
        }                   
        this.resolveRecipientsLots(this.allLotRecipients, resolveRecipients);
      }      
    }
  }

  returnCheckBox(item:any){    
    if (this.lotNumber == ''){
      var ind = this.registerChecked.findIndex((x: any) => x == item.id);        
      if (ind >= 0) {
        return '|checkbox|true';
      } else {
        return '|checkbox';      
      }    
    } else {
      var ind = this.deleteRegisters.findIndex((x: any) => x == item.id);        
      if (ind >= 0) {
        return '|checkbox|true';
      } else {
        return '|checkbox';      
      }    
    }    
  }

  async Actions(action: any) {
    switch (action.action) {
      case 'check':
        if (action.$event.srcElement.checked != undefined){
          var ind = this.registerChecked.findIndex((x:any) => x == action.object.id);
          if (action.$event.srcElement.checked) {
            if (ind == -1){
              this.registerChecked.push(action.object.id);
            }
          } else {
            if (ind > -1){
              this.registerChecked.splice(ind, 1);
            }
          }
        }
        break;      
    }
  }

  async ActionsLot(action: any) {
    switch (action.action) {
      case 'check':
        if (action.$event.srcElement.checked != undefined){
          var ind = this.deleteRegisters.findIndex((x:any) => x == action.object.id);
          if (action.$event.srcElement.checked) {
            if (ind == -1){
              this.deleteRegisters.push(action.object.id);
            }
          } else {
            if (ind > -1){
              this.deleteRegisters.splice(ind, 1);
            }
          }
        }
        break;      
    }
  }

  sendLot(){
    const obj = {
      "examsRecipientRequestId": this.registerChecked,
      "companyId": this.form.company.value,
      "unityId": this.form.unity.value,
      "collectionPlaceId": this.form.collectionPlace.value,
      "sendTemperature": this.form.temperature.value,
      "dateSend": this.form.date.value      
    }

    this._shippingDistributionService.post(obj).then(
      (res) => {
        SwAlSetttings.Sucesso('Lote gerado e enviado com sucesso!');  
        this._dialog.closeAll();
        //@ts-ignore
        this.id = res.id;
        this._router.navigateByUrl('Movimentacao/Recepcao/Envio-Remessa-Materiais-Distribuicao/'+this.id); 
        this.registerChecked = [];        
        this.ngOnInit();         
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );  
  }

  modalTemp(){
    const myTempDialog = this._dialog.open(this.dialogModal, {
      width: '660px'
    });    
  }

  close() {    
    this._dialog.closeAll()
  }

  deleteRecipients(){    
    const obj = {
      "examsRecipientRequestId": this.deleteRegisters
    }    
    this._recipientShippingDistributionService.deleteB(undefined, obj).then(
      (res) => {
        SwAlSetttings.Sucesso('Recipientes removidos com sucesso!');
        this.checkBoxTrueLot = false;
        this.deleteRegisters = [];        
        this.getShippingDistribution();
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );  
  }

  async refresh(){
    if (this._route.snapshot.params['id'] && this._route.snapshot.params['id'].split('$')[0] != "Novo" || this.id != '') {      
      await this.getShippingDistribution();
    } else {      
      await this.getRecipients();
    }    
  }

  getRecipientByBarCode() {    
    const item : any = this.data.filter((x: any) => x.codeBar === this.form.code.value)[0];
    this.registerChecked.push(item.id);
    this.form.code.reset();
    this.resolve(this.data, resolveMaterials);   
  }

  incrementDays(date: Date, days: number): Date {
    let dateNow: Date = new Date(date);
    dateNow.setDate(dateNow.getDate() + days);    
    dateNow.setHours(0);
    dateNow.setMinutes(0);
    dateNow.setSeconds(0);
    return dateNow;
  }
}