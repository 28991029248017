import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SwAlSetttings } from '@app/util/swal.settings';
import { ResultsService } from '@app/services/results/results.service';
import { Route, Router } from '@angular/router';
import { decodedSessionToken } from '@app/util/Token';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent implements OnInit {

  form = {
    initialDate: new UntypedFormControl(new Date(new Date().setDate(new Date().getDate() - 30))), // data atual - 30 dias
    finalDate: new UntypedFormControl(new Date()),
    requisitionNumber: new UntypedFormControl(null),
    customerName: new UntypedFormControl(null),
    customerBirthDate: new UntypedFormControl(null),
    customerCpf: new UntypedFormControl(null)
  }

  reqSituation = {
    0: "<span border-radius: 100px; padding: 4px 12px'>Em Atendimento</span>",
    1: "<span border-radius: 100px; padding: 4px 12px'>Final Atendimento</span>",
    3: "<span border-radius: 100px; padding: 4px 12px'>Aguardando Coleta</span>",
    4: "<span border-radius: 100px; padding: 4px 12px'>Em Coleta</span>",
    5: "<span border-radius: 100px; padding: 4px 12px'>Finalizacao Coleta</span>",
    10: "<span border-radius: 100px; padding: 4px 12px'>Triado Parcial</span>",
    11: "<span border-radius: 100px; padding: 4px 12px'>Triado</span>",
    20: "<span border-radius: 100px; padding: 4px 12px'>Digitado Parcial</span>",
    21: "<span border-radius: 100px; padding: 4px 12px'>Digitado</span>",
    30: "<span border-radius: 100px; padding: 4px 12px'>Conferido Parcial</span>",
    31: "<span border-radius: 100px; padding: 4px 12px'>Conferido</span>",
    40: "<span border-radius: 100px; padding: 4px 12px'>Liberado Parcial</span>",
    41: "<span border-radius: 100px; padding: 4px 12px'>Liberado</span>"
  }

  total: number = 0;
  page: number = 1;
  index: number = 50;
  getParameters: string = (`?page=${this.page}&index=${this.index}`);
  registerResults: Array<any> = [];
  resolveResults = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Requisição',
      retrive: 'number',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Entrada',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'Nome',
      retrive: 'name',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Código',
      retrive: 'analytes',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Situação',
      retrive: '',
      method: 'getReqSituation',
      after: '',
      before: ''
    },
    {
      label: 'Prazo',
      retrive: '',
      method: 'getDeadline',
      after: '',
      before: ''
    },
    {
      label: 'Imprimir',
      retrive: '',
      method: 'getPrint',
      after: '',
      before: ''
    }
  ];

  resolveResultsNoName = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Requisição',
      retrive: 'number',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Entrada',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'Código',
      retrive: 'analytes',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Situação',
      retrive: '',
      method: 'getReqSituation',
      after: '',
      before: ''
    },
    {
      label: 'Prazo',
      retrive: '',
      method: 'getDeadline',
      after: '',
      before: ''
    },
    {
      label: 'Imprimir',
      retrive: '',
      method: 'getPrint',
      after: '',
      before: ''
    }
  ];

  constructor(private _resultsService: ResultsService, private _route: Router) { }

  async ngOnInit() {
    await this.getResults();
  }

  async getResults(paramPage?: number[]) {
    let formattedInitialDate = new Date(this.form.initialDate.value).toISOString();
    let formattedFinalDate = new Date(this.form.finalDate.value).toISOString();
    this.getParameters = (`?IsActive=true&InitialDate=${formattedInitialDate}&FinishDate=${formattedFinalDate}&page=${this.page}&numberRegistry=${this.index}`);

    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
      this.getParameters = (`?IsActive=true?InitialDate=${formattedInitialDate}&FinishDate=${formattedFinalDate}&page=${this.page}&numberRegistry=${this.index}`);
    }

    if (this.form.requisitionNumber.value) this.getParameters = `${this.getParameters}&RequisitionNumber=${this.form.requisitionNumber.value}`;
    if (this.form.customerName.value) this.getParameters = `${this.getParameters}&CustomerName=${this.form.customerName.value}`;
    if (this.form.customerCpf.value) this.getParameters = `${this.getParameters}&CustomerCPF=${this.form.customerCpf.value}`;
    if (this.form.customerBirthDate.value) this.getParameters = `${this.getParameters}&CustomerBirthDate=${this.form.customerBirthDate.value.toISOString()}`;

    //TODO Validar retorno de resultado dos convenios
    var results: Array<object> = [];
    this.registerResults = [];
    await this._resultsService.get(this.getParameters).then(
      (res) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          results = res.data;
          let accessType = decodedSessionToken
          if (accessType.act === 1) {
            this.resolve(results, this.resolveResultsNoName)
          } else {
            this.resolve(results, this.resolveResults)
          }
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.registerResults = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerResults.push(obj);
    });
  }

  getDate(item: any) {
    var data = new Date(item.createdAt + 'Z');
    return data.toLocaleString().replace(",", " -");
  }

  getDeadline(item: any) {
    var data = new Date(item.maxDeadline + 'Z');
    return data.toLocaleString().replace(",", " -");
  }//Situacao //PrevisaoDEADlINE // Numero Req // Agrupar por Req

  getReqSituation(item: any) {
    let situationDescription: string = item.requisitionStatus;
    //@ts-ignore
    return this.reqSituation[situationDescription];
  }

  getPrint() {
    return 'Imprimir|button'
  }

  ActionsExtra = {
    "Imprimir": 'printResult'
  };

  async Actions(action: any) {
    switch (action.action) {
      case 'printResult':
        const url = "http://zoe.concent.com.br/labvidato?hlab8001?1,5,109348,0,469E1,PE"//TODO AJUSTAR LINK      
        window.open(url, '_blank');
        break
    }
  }
}
