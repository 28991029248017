import { Location } from "@angular/common";
import { PriceTableService } from "@app/services/pricetable/pricetable.service";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { SwAlSetttings } from "@app/util/swal.settings";
import { Guid } from "@app/util/guid";
import { AnalyteInstructionService } from "@app/services/analyte-instruction/analyte-instruction.service";
import { Router } from "@angular/router";
import { AnalyteService } from "@app/services/analyte/analyte.service";

@Component({
   selector: "app-manual-exams",
   templateUrl: "./manual-exams.component.html",
   styleUrls: ["./manual-exams.component.scss"]
})
export class ManualExamsComponent implements OnInit {
   exams: any = [];
   linksHtml: Array<Object> = [];
   examName: String = "";
   customerPreparation: String = "";
   collect: String = "";
   distribuition: String = "";
   estabilish: String = "";
   reject: String = "";
   additional: String = "";
   interpretation: String = "";
   pathologies: String = "";
   materials: String = "";
   description: String = "";

   form = {
      exam: new UntypedFormControl(null, [Validators.required])
   };

   constructor(
      private _priceTableService: AnalyteService,
      private _location: Location,
      private _manualExamsService: AnalyteInstructionService,
      private _router: Router
   ) {}

   ngOnInit() {
      this.get();
   }

   async get() {
      this.exams = [];
      await this._priceTableService
         .getAll()
         .then((res) => {
            if (res.data.length != 0) {
               res.data.forEach((priceTableExams: any) => {
                  this.exams.push({
                     label:
                        priceTableExams.code +
                        " - " +
                        priceTableExams.name +
                        " - " +
                        priceTableExams.billingCode,
                     value: priceTableExams.id,
                     price: priceTableExams.price
                  });
               });
            } else {
               this.exams = [];
               SwAlSetttings.alertaMsg(
                  `Não foi possível encontrar a Tabela de Preço com Id(${"priceTableId"})!`
               );
            }
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   async getExamName() {
      this.customerPreparation = "";
      this.collect = "";
      this.distribuition = "";
      this.estabilish = "";
      this.reject = "";
      this.additional = "";
      this.interpretation = "";
      this.pathologies = "";
      this.linksHtml = [];
      this.description = "";

      //@ts-ignore
      this.examName = this.exams.filter(
         (x: any) => x.value === this.form.exam.value
      )[0].label;
      await this._manualExamsService
         .getByPath("", "?analyteId=" + this.form.exam.value)
         .then((res) => {
            //@ts-ignore
            if (res.data.length != 0) {
               //@ts-ignore
               var response = res.data[0].analyteInstructions;
               //@ts-ignore
               if (
                  response.filter((x: any) => x.typeAnalyteInstructions === 1)
                     .length > 0
               ) {
                  //@ts-ignore
                  this.customerPreparation = response.filter(
                     (x: any) => x.typeAnalyteInstructions === 1
                  )[0].description;
               }
               //@ts-ignore
               if (
                  response.filter((x: any) => x.typeAnalyteInstructions === 2)
                     .length > 0
               ) {
                  //@ts-ignore
                  this.collect = response.filter(
                     (x: any) => x.typeAnalyteInstructions === 2
                  )[0].description;
               }
               //@ts-ignore
               if (
                  response.filter((x: any) => x.typeAnalyteInstructions === 3)
                     .length > 0
               ) {
                  //@ts-ignore
                  this.distribuition = response.filter(
                     (x: any) => x.typeAnalyteInstructions === 3
                  )[0].description;
               }
               //@ts-ignore
               if (
                  response.filter((x: any) => x.typeAnalyteInstructions === 4)
                     .length > 0
               ) {
                  //@ts-ignore
                  this.estabilish = response.filter(
                     (x: any) => x.typeAnalyteInstructions === 4
                  )[0].description;
               }
               //@ts-ignore
               if (
                  response.filter((x: any) => x.typeAnalyteInstructions === 5)
                     .length > 0
               ) {
                  //@ts-ignore
                  this.reject = response.filter(
                     (x: any) => x.typeAnalyteInstructions === 5
                  )[0].description;
               }
               //@ts-ignore
               if (
                  response.filter((x: any) => x.typeAnalyteInstructions === 6)
                     .length > 0
               ) {
                  //@ts-ignore
                  this.additional = response.filter(
                     (x: any) => x.typeAnalyteInstructions === 6
                  )[0].description;
               }
               //@ts-ignore
               if (
                  response.filter((x: any) => x.typeAnalyteInstructions === 7)
                     .length > 0
               ) {
                  //@ts-ignore
                  this.interpretation = response.filter(
                     (x: any) => x.typeAnalyteInstructions === 7
                  )[0].description;
               }
               //@ts-ignore
               if (
                  response.filter((x: any) => x.typeAnalyteInstructions === 8)
                     .length > 0
               ) {
                  //@ts-ignore
                  this.pathologies = response.filter(
                     (x: any) => x.typeAnalyteInstructions === 8
                  )[0].description;
               }
               //@ts-ignore
               if (
                  response.filter((x: any) => x.typeAnalyteInstructions === 10)
                     .length > 0
               ) {
                  //@ts-ignore
                  this.description = response.filter(
                     (x: any) => x.typeAnalyteInstructions === 10
                  )[0].description;
               }
               //@ts-ignore
               if (
                  response.filter((x: any) => x.typeAnalyteInstructions === 9)
                     .length > 0
               ) {
                  //@ts-ignore
                  var links = response.filter(
                     (x: any) => x.typeAnalyteInstructions === 9
                  )[0].description;
                  this.linksHtml = links.split(" ");
               }
               this.materials = "";
               //@ts-ignore
               if (res.data[0].materials.length > 0) {
                  //@ts-ignore
                  res.data[0].materials.forEach((element) => {
                     if ((this.materials = "")) {
                        this.materials = element.description;
                     } else {
                        //@ts-ignore
                        this.materials += " " + element.description;
                     }
                  });
               }
            } else {
               SwAlSetttings.alertaMsg(`Não foi possível encontrar o exame!`);
            }
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   back() {
      this._router.navigateByUrl("Externo/Convenio");
      //this.location.back()
   }
}
