import { Injectable } from "@angular/core";
import { BaseNewService } from "@app/services/baseNew.service";
import { LoadingNewService } from "@app/loadingNew.service";
import { ApiAuthService } from "../../../../data/services/api-auth.service";

@Injectable({
   providedIn: "root"
})
export class ForgotService extends BaseNewService {
   constructor(HttpAuth: ApiAuthService) {
      super(HttpAuth, "v1/public/user/forgot");
      LoadingNewService.SilenceLoading.push(this.path);
   }
}
