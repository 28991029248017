<app-list [title]="'questions.title'">
  <div class="headerButton">
    <app-dialog [title]="'questions.name'" [placeholderButton]="'global.new'" [matIconName]="'add'" (afterClose)="afterClose()">
      <div class="bodyFilter">
        <div class="flex gap-2 mt-32">
          <input-prop (keyup.enter)="questionId? put() : post()" [placeholder]="'questions.externalId'" [form]="form.externalId" type="number"></input-prop>
          <input-prop class="w-100" (keyup.enter)="questionId? put() : post()" [placeholder]="'questions.name'" [form]="form.questionName"></input-prop>
          <select-prop class="w-gender" [placeholder]="'questions.genderTypeDescription'" [form]="form.genderType" [options]="genderType"></select-prop>
        </div>
        <div class="flex flex-center mt-32">
          <app-button (click)="questionId? put() : post()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
        </div>
      </div>
    </app-dialog>
  </div>
  <div class="headerFilter">
    <app-filter (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query flex flex-column gap-2 align-center">
        <input-prop #autoFocus [placeholder]="'questions.name'" [form]="formFilter.name"></input-prop>
        <input-prop type="number" [placeholder]="'questions.externalId'" [form]="formFilter.externalId"></input-prop>
        <checkbox-prop class="flex" [gap]="1" [label]="'questions.isActive'" [form]="formFilter.isActive"></checkbox-prop>
        <app-button (click)="get()" class="queryBtn" [placeholder]="'questions.search'" [matIcon]="'search'" [class]="'btn flex gap-2 align-center'"></app-button>
      </div>
    </app-filter>
  </div>
  <div class="list">
    <app-table [Actions]="['Editar', 'Excluir']" [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true"  [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="questions" (parentFun)="Actions($event)" (changePage)="get($event)"></app-table>
  </div>
</app-list>
