<div class="contentPage">
  <div class="flex w-100 align-center gap-2 headerResponsive">
    <app-button class="titleResponsive" (click)="back()" [placeholder]="'Novo'" [class]="'flex gap-2 align-center btn-reverse'"></app-button>
    <h1 class="titleResponsive">{{equipmentId ? 'Editar' : 'Novo'}} Equipamento Interfaceado</h1>
  </div>
  <mat-tab-group (selectedTabChange)="myTabSelectedTabChange($event)" class="mt-32" dynamicHeight>
    <mat-tab>
      <ng-template mat-tab-label>
        <h2>Dados Gerais</h2>
      </ng-template>
      <div class="contextPage">
        <div class="flex flex-column gap-form">
          <div class="flex mt-32 w-100 gap-form">
            <div class="w-100">
              <div class="w-100 flex gap-form">
                <div class="w-100">
                  <input-prop [form]="equipment.name" placeholder="Nome do Equipamento"></input-prop>
                </div>
                <div>
                  <input-prop [form]="equipment.code" placeholder="Código"></input-prop>
                </div>
              </div>
              <div class="flex align-center flex-between w-100 gap-form mt-32">
                <div class="w-100">
                  <input-search-clean [form]="comunication.protocol" class="w-100" placeholder="Protocolo" [options]="typeProtocol"></input-search-clean>
                </div>
                <div class="w-100">
                  <select-prop placeholder="Tipo de Comunicação" class="w-100" [form]="comunication.typeComunication" [options]="typeComunication"></select-prop>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-32">
            <mat-divider> </mat-divider>
          </div>
          <div class="w-100 flex gap-form mt-32">
            <div class="w-100">
              <div class="w-100 border contentPage">
                <div *ngIf="comunication.typeComunication.value == '1'" #serial>
                  <h2>Comunicação Serial RS-232</h2>
                  <div class="flex gap-form w-100 mt-32">
                    <div class="w-100">
                      <input-prop placeholder="Porta Serial" [form]="comunication.serialNumber"></input-prop>
                    </div>
                    <div class="w-100">
                      <select-prop placeholder="Bit por Segundo" [form]="comunication.bitSecond" [options]="bitSeconds"></select-prop>
                    </div>
                  </div>
                  <div class="flex align-center gap-form mt-32 ht-46">
                    <div class="w-100">
                      <select-prop placeholder="Paridade" [form]="comunication.parity" [options]="parity"></select-prop>
                    </div>
                    <div class="w-100">
                      <select-prop placeholder="Tamanho do Bit" [form]="comunication.sizeBit" [options]="bitSize"></select-prop>
                    </div>
                    <div class="w-100">
                      <select-prop placeholder="Bit de Parada" [form]="comunication.bitStop" [options]="bitStop"></select-prop>
                    </div>
                  </div>
                </div>
                <div *ngIf="comunication.typeComunication.value == '2' || comunication.typeComunication.value == '4' || comunication.typeComunication.value == '3' " #network>
                  <h2>Comunicação TCP/IP (Rede)</h2>
                  <div class="w-100 mt-32 flex align-center gap-form">
                    <div class="w-100">
                      <input-prop placeholder="Endereço IP" [form]="comunication.ipAddress"></input-prop>
                    </div>
                    <div class="w-100">
                      <input-prop placeholder="Porta" [form]="comunication.port" [mask]="'00000'" (change)="formatPort()"></input-prop>
                    </div>
                  </div>
                </div>
                <div *ngIf="comunication.typeComunication.value == null || comunication.typeComunication.value == '5' " class="icon">
                  <svg width="100px" height="100px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                    <g id="SVGRepo_iconCarrier"> 
                      <path d="M9 0a9 9 0 1 0 9 9 9 9 0 0 0-9-9zM2 9a6.992 6.992 0 0 1 11.19-5.6L3.4 13.19A6.945 6.945 0 0 1 2 9zm7 7a6.944 6.944 0 0 1-4.19-1.4l9.79-9.79A6.992 6.992 0 0 1 9 16z"/>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div class="w-100 flex flex-column gap-form">
              <div class="w-100 border contentPage">
                <h2>Informações</h2>
                <div class="w-100 mt-32 flex gap-form">
                  <div class="w-100">
                    <input-prop placeholder="Tamanho do Frame" [form]="comunication.sizeFrame" [mask]="'0000'"></input-prop>
                  </div>
                  <div class="w-100">
                    <input-prop placeholder="Tempo de Buffer" [form]="comunication.timeBuffer" [mask]="'0000'"></input-prop>
                  </div>
                </div>
              </div>
              <div class="w-100 border contentPage">
                <h2>Opções</h2>
                <div class="w-100 mt-32 flex flex-column gap-form">
                  <checkbox-prop class="flex" [gap]="1" [label]="'Envio Automático de Lista de Trabalho'" [form]="comunication.sendWorkList"></checkbox-prop>
                  <checkbox-prop class="flex" [gap]="1" [label]="'Valida Triagem de Recipientes'" [form]="comunication.validateResultAutomatic"></checkbox-prop>
                  <checkbox-prop class="flex" [gap]="1" [label]="'Processa Imagens'" [form]="comunication.processImage"></checkbox-prop>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-32 w-100 flex flex-center">
          <app-button (click)="equipmentId ? putEquipment() : postEquipment()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center'"></app-button>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="equipmentId">
      <ng-template mat-tab-label>
        <h2>Exames</h2>
      </ng-template>
      <div class="contextPage">
        <div class="flex align-center flex-between">
          <h2>Exames</h2>
          <app-button (click)="openDialogModalExam()" [placeholder]="'Incluir Exame'" [class]="'flex gap-2 align-center'"></app-button>
        </div>
        <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="examsEquipments" [noShadow]="true" [Actions]="['Editar', 'Excluir']" [Dropdown]="true" (parentFun)="actionsExams($event)" [ClickInItem]="ActionsExtra">
          <ng-template let-rowDetail #header>
            <div class="">
              <div class="flex align-center flex-between">
                <h2>Recipientes</h2>
                <app-button (click)="openDialogModalRecipients(rowDetail.id)" [placeholder]="'Incluir Recipientes'" [class]="'flex gap-2 align-center'"></app-button>
              </div>
              <app-table *ngIf="recipients[rowDetail.id]" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [noShadow]="true" [Itens]="recipients[rowDetail.id]" [Actions]="['Excluir']" [ClickInItem]="ActionsExtra" (parentFun)="actionsRecipients($event, rowDetail.id)"></app-table>
            </div>
          </ng-template>
        </app-table>
      </div>
    </mat-tab>
    <mat-tab *ngIf="equipmentId">
      <ng-template mat-tab-label>
        <h2>Material De/Para</h2>
      </ng-template>
      <div class="contextPage">
        <div class="flex align-center flex-between">
          <h2>Material De/Para</h2>
          <app-button (click)="openDialogModal()" [placeholder]="'Incluir Material'" [class]="'flex gap-2 align-center'"></app-button>
        </div>
        <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="materialsEquipments" [noShadow]="true" [Actions]="['Editar', 'Excluir']" [ClickInItem]="ActionsExtra" (parentFun)="actions($event)"></app-table>
      </div>
    </mat-tab>
    <mat-tab *ngIf="equipmentId">
      <ng-template mat-tab-label>
        <h2>Texto</h2>
      </ng-template>
      <div class="contextPage">
        <div class="flex align-center flex-between">
          <h2>Texto</h2>
          <app-button (click)="openDialogModalText()" [placeholder]="'Incluir Texto'" [class]="'flex gap-2 align-center'"></app-button>
        </div>
        <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [ClickInItem]="ActionsExtra" [Itens]="textsEquipments" [noShadow]="true" [Actions]="['Editar', 'Excluir']" (parentFun)="actionsText($event)"></app-table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #dialogModalText let-mydata>
  <ng-container>
    <div class="flex flex-between align-center">
      <h1>{{!textId ? 'Novo Texto' : 'Editar Texto'}}</h1>
      <svg (click)="closeDialog()" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.384626 3.0527L18.0769 20.745L20.3846 18.4373L2.69232 0.745004L0.384626 3.0527Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6923 0.744995L0 18.4373L2.30769 20.745L20 3.05269L17.6923 0.744995Z"/>
      </svg>
    </div>
    <div class="flex align-center flex-between gap-2 mt-32">
      <div class="w-100">
        <input-prop [form]="text.code" placeholder="Código"></input-prop>
      </div>
    </div>
    <div class="w-100 mt-32">
        <input-prop [form]="text.text" placeholder="Texto"></input-prop>
    </div>
    <div class="w-100 mt-32">
      <input-prop (keyup.enter)="!textId ? postText() : putText()" [form]="text.translate" placeholder="Tradução"></input-prop>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="!textId ? postText() : putText()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModal let-mydata>
  <ng-container>
    <div class="flex flex-between align-center">
      <h1>{{!materialId ? 'Novo Material' : 'Editar Material'}}</h1>
      <svg (click)="closeDialog()" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.384626 3.0527L18.0769 20.745L20.3846 18.4373L2.69232 0.745004L0.384626 3.0527Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6923 0.744995L0 18.4373L2.30769 20.745L20 3.05269L17.6923 0.744995Z"/>
      </svg>
    </div>
    <div class="w-100 mt-32">
        <input-search-clean [form]="material.materialId" placeholder="Material" [options]="materials"></input-search-clean>
    </div>
    <div class="w-100 mt-32">
      <input-prop (keyup.enter)="!materialId ? postMaterial() : putMaterial()" [form]="material.nameMaterialEquipment" placeholder="Nome do Material no Equipamento"></input-prop>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="!materialId ? postMaterial() : putMaterial()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalExam let-mydata>
  <ng-container>
    <div class="flex flex-between align-center">
      <h1>{{!examId ? 'Novo Exame' : 'Editar Exame'}}</h1>
      <svg (click)="closeDialog()" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.384626 3.0527L18.0769 20.745L20.3846 18.4373L2.69232 0.745004L0.384626 3.0527Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6923 0.744995L0 18.4373L2.30769 20.745L20 3.05269L17.6923 0.744995Z"/>
      </svg>
    </div>
    <div class="w-100 mt-32" [ngClass]="examId ? 'readonly' : ''">
      <input-search-clean [form]="exam.analyte" placeholder="Exame" [tabindexcomponent]="examId ? '-1' :  ''" [options]="examOptions"></input-search-clean>
    </div>
    <div class="w-100 mt-32">
      <checkbox-prop class="flex flex-center" label="Bloqueado para liberação automática" [gap]="1" [form]="exam.analyteBlockedValidate"></checkbox-prop>
    </div>
    <div class="w-100 mt-32">
      <checkbox-prop class="flex flex-center" label="Habiliado" [gap]="1" [form]="exam.analyteEnabled"></checkbox-prop>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="!examId ? postExam() : putExam()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalRecipients let-mydata>
  <ng-container>
    <div class="flex flex-between align-center">
      <h1>{{!recipientPutId ? 'Novo Recipiente' : 'Editar Recipiente'}}</h1>
      <svg (click)="closeDialog()" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.384626 3.0527L18.0769 20.745L20.3846 18.4373L2.69232 0.745004L0.384626 3.0527Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6923 0.744995L0 18.4373L2.30769 20.745L20 3.05269L17.6923 0.744995Z"/>
      </svg>
    </div>
    <div class="w-100 mt-32" [ngClass]="recipientPutId ? 'readonly' : ''">
      <input-search-clean [form]="recipient.name" placeholder="Recipiente" [tabindexcomponent]="recipientPutId ? '-1' :  ''" [options]="allRecipients"></input-search-clean>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="!recipientPutId ? postRecipient() : putRecipient()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>
