<div class="contentPage">
  <div class="flex flex-between w-100 gap-2 mb-32 align-center headerResponsive">
    <div class="flex flex-center gap-2 align-center titleResponsive">
      <h1>Produtos</h1>
      <app-button (click)="openDialogModal()" [placeholder]="'Novo'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus [placeholder]="'Pesquisar'" (keyup.enter)="get()" [form]="filter.Search"></input-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" label="Somente Ativos" [form]="filter.isActive"></checkbox-prop>
      </div>
      <app-button (click)="get()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <app-table [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="itens" [Actions]="['Editar', 'Excluir']" (parentFun)="actions($event)"></app-table>
</div>

<ng-template #openModal>
  <div class="flex flex-between w-100 align-center">
    <h1>{{editId ? 'Editar' : 'Novo'}} Produto</h1>
    <svg class="close-svg" (click)="closeDialog()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
    </svg>
  </div>
  <div class="mt-32 flex gap-form">
    <input-search class="w-100" [placeholder]="'Grupo'" [options]="groupOption" [form]="form.group" (changeForm)="getSubGroup(form.group.value)"></input-search>
    <input-search class="w-100" [readonly]="!form.group.value" [placeholder]="'SubGrupo'" [options]="subGroupMaterialOption" [form]="form.subGroup"></input-search>
  </div>
  <div class="mt-32 flex gap-form">
    <input-search class="w-100" [placeholder]="'Unidade de Medida'" [options]="unitMeasureServiceOption" [form]="form.unitMeasurement"></input-search>
    <input-search-clean class="w-100" [placeholder]="'Tipo'" [options]="typeOptions" [form]="form.type"></input-search-clean>
  </div>
  <div class="mt-32">
    <textarea-b-prop [placeholder]="'Descrição'" [form]="form.description"></textarea-b-prop>
  </div>
  <div class="mt-32">
    <file-prop [form]="form.file" [formName]="form.fileName"></file-prop>
  </div>
  <div class="flex flex-center mt-32">
    <app-button (click)="editId? put() : post()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
  </div>
</ng-template>
