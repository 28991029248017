import { Guid } from "@app/modules_new/shared/data/types/guid";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, skip } from "rxjs";
import { EventChild, EventPayload } from "./event-propagation.types";

type EventHandler<T> = {
    [key: string]: {
        emitter: EventChildEmitter<T>;
        observer: EventChildObserver<T>;
    };
};

@Injectable({
    providedIn: "root"
})
export class EventChildPropagationService<T> {
    private _handlers: EventHandler<T> = {};

    handleEvents$(id: Guid) {
        this._checkHandler(id);

        return this._handlers[id.toString()].observer.observable$.pipe(skip(1));
    }

    dispatcher(id: Guid) {
        this._checkHandler(id);
        return this._handlers[id.toString()].emitter;
    }

    private _checkHandler(id: Guid) {
        if (!this._handlers[id.toString()]) {
            const emitter = new EventChildEmitter<T>();
            const observer = new EventChildObserver<T>(emitter.eventObserver);
            this._handlers[id.toString()] = {
                emitter,
                observer
            };
        }
    }
}

class EventChildEmitter<T> {
    private _event = new BehaviorSubject<EventChild<T> | null>(null);

    emmit(eventType: T, payload: EventPayload) {
        this._event.next({
            eventType,
            payload
        });
    }

    get eventObserver() {
        return this._event.asObservable();
    }
}

export class EventChildObserver<T> {
    constructor(
        public readonly observable$: Observable<EventChild<T> | null>
    ) {}
}
