import {
   Component,
   Input,
   Output,
   EventEmitter,
   ViewChild,
   ElementRef,
   AfterViewInit,
   OnInit,
   OnDestroy,
   inject
} from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { fromEvent } from "rxjs";
import {
   MatAutocompleteModule,
   MatAutocompleteSelectedEvent,
   MatAutocompleteTrigger
} from "@angular/material/autocomplete";
import { CommonModule } from "@angular/common";
import { OptionSelect } from "../input-select.types";
import { DataComponent } from "@app/modules_new/common/components/data";
import { SearchFilterPipe } from "@app/modules_new/shared/pipe/search-filter.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { ErrorTranslatePipe } from "@app/modules_new/shared/pipe/error-translate.pipe";
import { Router } from "@angular/router";

@Component({
   selector: "forms-input-search",
   templateUrl: "./input-search.component.html",
   styleUrls: ["./input-search.component.scss"],
   standalone: true,
   imports: [
      ReactiveFormsModule,
      CommonModule,
      MatAutocompleteModule,
      SearchFilterPipe,
      TranslateModule
   ]
})
export class InputSearchFormComponent
   extends DataComponent
   implements AfterViewInit, OnInit, OnDestroy
{
   private _router = inject(Router);
   isBilling: boolean =
      this._router.url.includes("/Faturamento/Faturamento-Convenio") ||
      this._router.url.includes("/Faturamento/Remessa-Lote-Guias")
         ? true
         : false;
   @Input() form!: FormControl<string>;

   @Input() type = "text";
   @Input() placeholder = "";
   @Input() label = "";
   @Input() className = "";
   @Input() requiredInput = "";
   @Input() tabindexcomponent = "";
   @Input() callChangeFormEmit = false;
   @Input() options: Array<OptionSelect> = [];
   @Input() color = "unset";
   @Input() hideSvg = false;
   @Input() onFocus = false;
   @Input() disabled = false;
   @Input() noLabelUp = false;

   @Output() valueChanged = new EventEmitter<OptionSelect>();
   @Output() changeForm = new EventEmitter<string>();
   @Output() search = new EventEmitter<any>();
   @Output() isOpen = new EventEmitter<boolean>();

   @ViewChild("input") input!: ElementRef;

   @ViewChild(MatAutocompleteTrigger, { static: true })
   autocomplete!: MatAutocompleteTrigger;

   @ViewChild(MatAutocompleteTrigger) autoTrigger!: MatAutocompleteTrigger;

   classe = "";
   notSelect = true;
   border = false;

   formValue = new FormControl("");

   ngOnInit(): void {
      if (this.form.disabled) this.formValue.disable();

      this.classe = this.className + " input-row";
      if (this.form?.hasValidator(Validators.required)) {
         this.requiredInput = "true";
      }

      this.addSubscriptions([
         this.form.valueChanges.subscribe(() => {
            const optionSelected = this.options?.find(
               (item: OptionSelect) => item.value == this.form.value
            )!;
            this.formValue.setValue(optionSelected?.label);
            if (optionSelected?.label) {
               this.autocomplete.closePanel();
            }
            this.valueChanged.emit(optionSelected);
         })
      ]);

      this.addSubscriptions([
         this.formValue.valueChanges.subscribe((response) => {
            if (response === "") {
               this._changeValue("");
            }
         })
      ]);
   }

   ngOnDestroy(): void {
      this.unsubscribeAll();
   }

   ngAfterViewInit() {
      fromEvent(this.input.nativeElement, "focus").subscribe(() => {
         this.autocomplete?.openPanel();
      });

      if (this.onFocus) {
         this.input.nativeElement.focus();
      }
   }

   onFocusOut() {
      setTimeout(() => {
         if (this.formValue.value && this.formValue.value.length > 0) {
            const labels = this.options.map((item) =>
               item.label?.toLocaleLowerCase()
            );
            const hasValue = labels.includes(
               this.formValue.value?.toLocaleLowerCase()
            );
            if (!hasValue) {
               this._changeValue("");
            }
         }
      }, 100);
   }

   getSelectedOption(event: MatAutocompleteSelectedEvent) {
      const selected = event.option.value as OptionSelect;

      this._changeValue(selected);

      this.border = false;
      this.notSelect = false;
   }

   displayCorretLabelFn(option?: string): string | undefined {
      if (typeof option === "string") return option;
      if (typeof option === "object") return (option as OptionSelect)?.label;
      return "";
   }

   private _changeValue(value: OptionSelect | string) {
      if (typeof value === "string") {
         this.form.setValue(value);
         this.changeForm.emit(value);
      } else {
         this.form.setValue(value.value);
         this.changeForm.emit(value.value);
      }
   }

   searchEnter() {
      if (this.form.value == null || this.form.value == "") {
         this.search.emit(this.formValue.value);
         this.formValue.setValue(null);
         this.focusInput();
      }
      const isOpen = this.autoTrigger.panelOpen;
      this.isOpen.emit(isOpen);
   }

   showError(): boolean {
      if (this.form?.hasValidator(Validators.required)) {
         return true;
      }
      return false;
   }

   focusInput() {
      this.input.nativeElement.focus();
      setTimeout(() => {
         this.form.markAsTouched({ onlySelf: true });
      });
   }
}
