import { Location } from "@angular/common";
import { UntypedFormControl, Validators } from "@angular/forms";
import {
   Component,
   ElementRef,
   OnInit,
   QueryList,
   TemplateRef,
   ViewChild,
   ViewChildren
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomersService } from "@app/services/customers/customers.service";
import { Guid } from "@app/util/guid";
import { RequisitionService } from "@app/services/requisitions/requisition.service";
import { UnityService } from "@app/services/auth/unity/unity-service.service";
import decodedToken from "@app/util/Token";
import { PersonService } from "@app/services/person/person.service";
import { MedicinesService } from "@app/services/medicines/medicines.service";
import { Util } from "@app/util/util";
import {
   ethnicityOptions,
   resolveMedicationCustomer,
   resolveMedicationCustomerLocally
} from "./save-customer.model";
import { DefaultValidators } from "@app/util/Validators";
import { ToastService } from "@app/services/translate/toast.service";
import { InputPropComponent } from "@app/components/form/input-prop/input-prop.component";
import { AgreementsService } from "@app/services/agreements/agreements.service";

export interface GetCep {
   cep: string;
   logradouro: string;
   complemento: string;
   bairro: string;
   localidade: string;
   uf: string;
   ibge: string;
   gia: string;
   ddd: string;
   siafi: string;
}

@Component({
   selector: "app-save-customer",
   templateUrl: "./save-customer.component.html",
   styleUrls: ["./save-customer.component.scss"]
})
export class SaveCustomerComponent implements OnInit {
   @ViewChildren(InputPropComponent) inputsProp!: QueryList<InputPropComponent>;

   ActionsExtra = {
      Ativo: "PutActive"
   };

   isSerchMedicine: boolean = false;
   userChronos!: boolean;
   hasSaveData: boolean = false;
   //#region Global Variables / Variáveis Globais
   title: string = "Editar Cliente";
   disable: boolean = false;
   edit: boolean = false;
   //#endregion
   @ViewChild("dialogModal")
   dialogModal!: TemplateRef<any>;
   @ViewChild("dialogModalManualMedication")
   dialogModalManualMedication!: TemplateRef<any>;
   unityOptions: Array<any> = [];
   collectionPlaceOptions: Array<any> = [];
   AllcollectionPlaceOptions: Array<any> = [];
   medications: Array<any> = [];
   rawMedications: Array<any> = [];
   resolvedMedications: Array<any> = [];

   agreementOptions: Array<any> = [];
   agreementPlanOptions: Array<any> = [];

   requisitionNumber: number = 0;
   lastRequisition: number = 0;

   //#region Customer Variables / Variáveis referentes ao Cliente
   customerId: string = "";
   genderType: Array<any> = [];

   ethnicityOptions = ethnicityOptions;

   //#endregion

   //#region Forms / Formulários
   form = {
      customerName: new UntypedFormControl(null, [Validators.required]),
      customerIsActive: new UntypedFormControl(true),
      customerGenderType: new UntypedFormControl(0),
      customerBirthDate: new UntypedFormControl(null),
      customerMotherName: new UntypedFormControl(null),
      customerEthnicity: new UntypedFormControl(0),
      customerHasDeficiency: new UntypedFormControl(false),
      customerIsForeigner: new UntypedFormControl(false),
      avatarId: new UntypedFormControl(null),
      customerResponsibleName: new UntypedFormControl(null),
      customerSocialName: new UntypedFormControl(null),
      customerCnpjCpf: new UntypedFormControl(null),
      customerCpfResponsible: new UntypedFormControl(null),
      customerRg: new UntypedFormControl(null),
      customerCnh: new UntypedFormControl(null),
      customerCns: new UntypedFormControl(null),
      customerCard: new UntypedFormControl(null),
      customerPassport: new UntypedFormControl(null),
      customerCellphone: new UntypedFormControl(null, [
         Validators.required,
         Validators.pattern(
            /^(?!.*(\d)\1{3,}.*(\d)\2{3,})\(([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])\) 9 [0-9]{4}-[0-9]{4}$/
         )
      ]),
      customerResidentialPhone: new UntypedFormControl(null, [
         Validators.pattern(
            /^(?!.*(\d)\1{3,}.*(\d)\2{3,})\(([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])\) 3[0-9]{3}-[0-9]{4}$/
         )
      ]),
      customerEmail: new UntypedFormControl(null, [Validators.email]),
      expirationDate: new UntypedFormControl(),
      report: new UntypedFormControl(),
      address: {
         zipCode: new UntypedFormControl(""),
         street: new UntypedFormControl(""),
         number: new UntypedFormControl(""),
         complement: new UntypedFormControl(""),
         neighborhood: new UntypedFormControl(""),
         city: new UntypedFormControl(""),
         state: new UntypedFormControl(""),
         country: new UntypedFormControl("")
      },
      continuousMedication: new UntypedFormControl(false),
      medication: new UntypedFormControl(null),
      sendSmsEmail: new UntypedFormControl(false),
      sendEmailResult: new UntypedFormControl(false),
      unityId: new UntypedFormControl(null),
      agreementId: new UntypedFormControl(null),
      agreementPlanId: new UntypedFormControl(null),
      collectionPlaceId: new UntypedFormControl(null),
      manualMedication: new UntypedFormControl(null)
   };
   //#endregion

   constructor(
      private _customerService: CustomersService,
      private _requisitionService: RequisitionService,
      private _route: ActivatedRoute,
      private _router: Router,
      private _location: Location,
      private _dialog: MatDialog,
      private _unityService: UnityService,
      private _personService: PersonService,
      private _medicinesService: MedicinesService,
      private _agreementsService: AgreementsService,
      private _util: Util,
      private _toastService: ToastService //@Inject(MAT_DIALOG_DATA) public data: string = "",
   ) {
      if (_route.snapshot.params["id"] != "Novo") {
         // && !this.data
         let urlParams: string[] = _route.snapshot.params["id"].split("$");
         let customerId = urlParams[0];
         this.customerId = customerId;
         this.edit = true;

         if (urlParams[1] == "View") {
            this.title = "Visualizar Cliente";
            this.disable = true;
         }
      } else {
         this.title = "Novo Cliente";
         let urlParams: string[] = _route.snapshot.params["id"].split("$");
      }

      //#region AutoSave
      if (this.title === "Inserir Cliente") {
         let json: object = {};

         Object.entries(this.form).forEach(([key, control]) => {
            //@ts-ignore
            control.valueChanges.subscribe((value) => {
               let tmp = {
                  [key]: value
               };

               json = Object.assign({}, json, tmp);
               localStorage.setItem(
                  `autosave://${this.constructor.name}`,
                  JSON.stringify(json)
               );
            });
         });

         if (localStorage.getItem(`autosave://${this.constructor.name}`)) {
            this._toastService
               .fireConfirmation("customer.msgNotRegistration")
               .then((x) => {
                  if (x.isConfirmed) {
                     let obj = localStorage.getItem(
                        `autosave://${this.constructor.name}`
                     );

                     Object.entries(this.form).forEach(([key, control]) => {
                        let abc = obj?.includes(key);
                        if (abc) {
                           const convertedObj = JSON.parse(obj ?? "");
                           // control.setValue(convertedObj[key]);
                        }
                     });
                  } else {
                     localStorage.removeItem(
                        `autosave://${this.constructor.name}`
                     );
                  }
               });
         }
      }
      //#endregion
   }

   async ngOnInit() {
      await this.getCustomerById();
      if (localStorage.getItem("token")) {
         this.userChronos = false;
      } else {
         this.userChronos = true;
      }
      this.genderType = await this._util.getEnum("EGenderType");
   }

   clearCepForm() {
      this.form.address.zipCode.setValue("");
      this.form.address.street.setValue("");
      this.form.address.neighborhood.setValue("");
      this.form.address.city.setValue("");
      this.form.address.state.setValue("");
   }

   getCep() {
      let cep: string = "";

      if (this.form.address.zipCode.value) {
         let tmpCep = this.form.address.zipCode.value;
         cep = tmpCep.replace(/\D/g, "");
      }

      if (cep != "") {
         let validacep: RegExp = /^[0-9]{8}$/;

         if (validacep.test(cep)) {
            this.form.address.street.setValue("...");
            this.form.address.neighborhood.setValue("...");
            this.form.address.city.setValue("...");
            this.form.address.state.setValue("...");

            fetch(`https://viacep.com.br/ws/${cep}/json/`)
               .then((response) => {
                  return response.json();
               })
               .then((json) => {
                  if (!("erro" in json)) {
                     this.form.address.street.setValue(json.logradouro);
                     this.form.address.zipCode.setValue(json.cep);
                     this.form.address.city.setValue(json.localidade);
                     this.form.address.state.setValue(json.uf);
                     this.form.address.country.setValue("Brasil");
                     this.form.address.neighborhood.setValue(json.bairro);
                  } else {
                     this.clearCepForm();
                     setTimeout(() => {
                        this._toastService.fireError("customer.msgNotCEP");
                     });
                  }
               });
         }
      }
   }

   back() {
      this._location.back();
   }

   async getCustomerById() {
      if (this.customerId != "") {
         await this._customerService
            .getById(new Guid(this.customerId))
            .then((response) => {
               response.data.forEach((customer) => {
                  this.form.customerName.setValue(customer.name);
                  this.form.customerGenderType.setValue(customer.genderType);
                  this.form.customerBirthDate.setValue(customer.birthDate);
                  this.form.customerMotherName.setValue(customer.motherName);
                  this.form.customerResponsibleName.setValue(
                     customer.nameResponsible
                  );
                  this.form.customerSocialName.setValue(customer.socialName);
                  this.form.customerRg.setValue(customer.rg);
                  this.form.customerCnpjCpf.setValue(customer.cpf);
                  this.form.customerCpfResponsible.setValue(
                     customer.cpfIsResponsible
                  );
                  this.form.customerCns.setValue(customer.cns);
                  this.form.customerPassport.setValue(customer.passportNumber);
                  this.form.customerCellphone.setValue(customer.cellPhone);
                  this.form.customerResidentialPhone.setValue(
                     customer.residencePhone
                  );
                  this.form.customerEmail.setValue(customer.email);
                  this.form.address.zipCode.setValue(customer.address.zipCode);
                  this.form.address.street.setValue(customer.address.street);
                  this.form.address.number.setValue(customer.address.number);
                  this.form.address.complement.setValue(
                     customer.address.complement
                  );
                  this.form.address.neighborhood.setValue(
                     customer.address.neighborhood
                  );
                  this.form.address.city.setValue(customer.address.city);
                  this.form.address.state.setValue(customer.address.state);
                  this.form.address.country.setValue(customer.address.country);
                  this.form.sendEmailResult.setValue(customer.sendEmailResult);
                  this.form.sendSmsEmail.setValue(customer.sendEmailSms);
                  this.form.continuousMedication.setValue(
                     customer.continuousMedication
                  );
                  this.resolveCustomerMedication(
                     customer.medicines,
                     resolveMedicationCustomer
                  );
                  this.requisitionNumber = customer.requisitionQtd;
                  this.lastRequisition = customer.requisitionNumber;

                  this.form.customerEthnicity.setValue(customer.race);
                  this.form.customerIsForeigner.setValue(customer.foreigner);
                  this.form.report.setValue(customer.medicalRecord);
                  this.form.customerCnh.setValue(customer.cnh);
                  this.form.customerHasDeficiency.setValue(customer.deficient);
                  this.form.sendEmailResult.setValue(customer.sendSmsResult);
                  this.form.customerCard.setValue(customer.cardNumber);
                  this.form.expirationDate.setValue(customer.validateCard);
                  if (customer.avatarId)
                     this.form.avatarId.setValue(customer.avatarId);

                  if (customer.agreementId)
                     this.getAgreementById(
                        customer.agreementId,
                        customer.agreementPlanId
                     );
               });
            });
      }
   }

   getAgreementById(agreementId: string, agreementPlanId: string) {
      this._agreementsService.getById(new Guid(agreementId)).then((res) => {
         this.agreementOptions = res.data.map((agreement: any) => ({
            label: agreement.description,
            value: agreement.id
         }));

         this.agreementPlanOptions = res.data[0].agreementPlan.map(
            (plan: any) => ({
               label: plan.description,
               value: plan.id
            })
         );
         setTimeout(() => {
            this.form.agreementPlanId.setValue(agreementPlanId);
            this.form.agreementId.setValue(agreementId);
         }, 0);
      });
   }

   async createCustomer(isCreatedFromRequisition?: boolean) {
      let isSucceed: boolean = false;
      let createdCustomerId: string = "";

      const customer = {
         name: this.form.customerName.value,
         birthDate: this.form.customerBirthDate.value,
         genderType: parseFloat(this.form.customerGenderType.value),
         motherName: this.form.customerMotherName.value,
         nameResponsible: this.form.customerResponsibleName.value,
         socialName: this.form.customerSocialName.value,
         cpf: {
            value: this.form.customerCnpjCpf.value
         },
         cpfIsResponsible: this.form.customerCpfResponsible.value,
         rg: this.form.customerRg.value,
         passportNumber: this.form.customerPassport.value,
         cellPhone: this.form.customerCellphone.value,
         residencePhone: this.form.customerResidentialPhone.value,
         email: this.form.customerEmail.value,
         address: {
            zipCode: this.form.address.zipCode.value,
            street: this.form.address.street.value,
            number: this.form.address.number.value,
            complement: this.form.address.complement.value,
            neighborhood: this.form.address.neighborhood.value,
            city: this.form.address.city.value,
            state: this.form.address.state.value,
            country: this.form.address.country.value
         },
         continuousMedication: this.form.continuousMedication.value,
         sendEmailSms: this.form.sendSmsEmail.value,
         cns: this.form.customerCns.value,
         medicinesList: this.rawMedications,

         race: this.form.customerEthnicity.value,
         foreigner: this.form.customerIsForeigner.value,
         medicalRecord: this.form.report.value,
         cnh: this.form.customerCnh.value,
         deficient: this.form.customerHasDeficiency.value,
         sendSmsResult: this.form.sendEmailResult.value,
         agreementId: this.form.agreementId.value,
         agreementPlanId: this.form.agreementPlanId.value,
         cardNumber: this.form.customerCard.value,
         validateCard: this.form.expirationDate.value,
         avatarId: this.form.avatarId.value
      };

      if (true /*this.form.customerBirthDate.value != null*/) {
         await this._customerService
            .post(customer)
            .then((res: any) => {
               createdCustomerId = res.id;
               isSucceed = true;
               setTimeout(() => {
                  this._toastService.fireSuccess("customer.msgPost");
               }, 2000);
               if (!isCreatedFromRequisition) {
                  this._router.navigateByUrl("Cadastros/Atendimento/Clientes");
               }
            })
            .catch((err) => {
               this._toastService.fireGenericError(err);
            });
      } else {
         setTimeout(() => {
            this._toastService.warningMessage("customer.msgInfoDate");
         });
      }

      let returnObj = {
         isSucceed: isSucceed,
         createdCustomerId: createdCustomerId
      };

      return returnObj;
   }

   async updateCustomer() {
      if (this.form.customerBirthDate.value != null) {
         const customer = {
            name: this.form.customerName.value,
            birthDate: this.form.customerBirthDate.value,
            genderType: parseFloat(this.form.customerGenderType.value),
            motherName: this.form.customerMotherName.value,
            nameResponsible: this.form.customerResponsibleName.value,
            socialName: this.form.customerSocialName.value,
            cpf: {
               value: this.form.customerCnpjCpf.value
            },
            cpfIsResponsible: this.form.customerCpfResponsible.value,
            rg: this.form.customerRg.value,
            passportNumber: this.form.customerPassport.value,
            cellPhone:
               this.form.customerCellphone.value === undefined ||
               this.form.customerCellphone.value === ""
                  ? null
                  : this.form.customerCellphone.value,
            residencePhone:
               this.form.customerResidentialPhone.value === undefined ||
               this.form.customerResidentialPhone.value === ""
                  ? null
                  : this.form.customerResidentialPhone.value,
            email:
               this.form.customerEmail.value === undefined ||
               this.form.customerEmail.value === ""
                  ? null
                  : this.form.customerEmail.value,
            address: {
               zipCode: this.form.address.zipCode.value,
               street: this.form.address.street.value,
               number: this.form.address.number.value,
               complement: this.form.address.complement.value,
               neighborhood: this.form.address.neighborhood.value,
               city: this.form.address.city.value,
               state: this.form.address.state.value,
               country: this.form.address.country.value
            },
            continuousMedication: this.form.continuousMedication.value,
            medication: this.form.medication.value,
            sendEmailSms: this.form.sendSmsEmail.value,
            sendEmailResult: this.form.sendEmailResult.value,
            cns: this.form.customerCns.value,

            race: this.form.customerEthnicity.value,
            foreigner: this.form.customerIsForeigner.value,
            medicalRecord: this.form.report.value,
            cnh: this.form.customerCnh.value,
            deficient: this.form.customerHasDeficiency.value,
            sendSmsResult: this.form.sendEmailResult.value,
            agreementId: this.form.agreementId.value,
            agreementPlanId: this.form.agreementPlanId.value,
            cardNumber: this.form.customerCard.value,
            validateCard: this.form.expirationDate.value,
            avatarId: this.form.avatarId.value
         };

         await this._customerService
            .put(customer, new Guid(this.customerId))
            .then(() => {
               this._router.navigateByUrl("Cadastros/Atendimento/Clientes");
               setTimeout(() => {
                  this._toastService.fireSuccess("customer.msgPut");
               }, 2000);
            });
      } else {
         setTimeout(() => {
            this._toastService.warningMessage("customer.msgInfoDate");
         });
      }
   }

   async createCustomerAndGenerateRequisition() {
      let returnCustomer = await this.createCustomer(true);

      if (returnCustomer.isSucceed) {
         let returnRequisitionId = await this.createRequisition(
            returnCustomer.createdCustomerId
         );
         if (returnRequisitionId) {
            localStorage.setItem(`autosave://lastURL`, this._router.url);
            this._router.navigateByUrl(
               `MovimentacaoOld/Recepcao/Requisicoes/${returnRequisitionId}`
            );
         }
      }
   }

   async createRequisition(customerId: string) {
      let unityId = localStorage.getItem("unityId");
      let collectionPlaceId = localStorage.getItem("collectionPlaceId");
      let requisitionId = null;

      if (
         unityId != null &&
         collectionPlaceId != null &&
         collectionPlaceId != "Todos"
      ) {
         let requisition = {
            isActive: true,
            name: this.form.customerName.value,
            birthDate: new Date(
               this.form.customerBirthDate.value
            ).toISOString(),
            genderType: parseInt(this.form.customerGenderType.value),
            customerId: customerId,
            unityId: unityId,
            collectionPlaceId: collectionPlaceId,
            clinicalInformation: "",
            companyId: decodedToken.cid
         };

         await this._requisitionService
            .post(requisition)
            .then((res) => {
               requisitionId = res.id;
               this._toastService.fireSuccess("customer.msgPostRequisition");
            })
            .catch(
               //@ts-ignore
               (err) => {
                  this._toastService.fireError(err);
               }
            );
      } else {
         this.customerId = customerId;
         await this.getUnity();
         await this.getPerson();
         const myTempDialog = this._dialog.open(this.dialogModal, {
            width: "660px"
         });
      }

      return requisitionId;
   }

   async getUnity() {
      await this._unityService
         .get("?page=1&registerIndex=99999&resume=true")
         .then((res: any) => {
            res.data.forEach((unity: any) => {
               this.unityOptions.push({
                  value: unity.id,
                  label: unity.description
               });

               unity.collectionPlace.forEach((collectionPlace: any) => {
                  this.AllcollectionPlaceOptions.push({
                     value: collectionPlace.id,
                     unityId: unity.id,
                     label: collectionPlace.description
                  });
               });
            });
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async getPerson() {
      const personId = decodedToken.pid;
      await this._personService
         .getById(personId)
         .then((res: any) => {
            this.form.unityId.setValue(res.data[0].unityId);
            this.getUnities();
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   getAgreements(search: string) {
      this._agreementsService.getPagination({ search }).then(
         (res) =>
            (this.agreementOptions = res.data.map((agreement: any) => ({
               label: agreement.description,
               value: agreement.id
            })))
      );
   }

   getAgreementPlan() {
      const agreementId = this.form.agreementId.value;
      if (!agreementId) {
         this.agreementPlanOptions = [];
         this.form.agreementPlanId.reset();
         return;
      }
      this._agreementsService.getById(agreementId).then(
         (res: any) =>
            (this.agreementPlanOptions = res.data[0].agreementPlan.map(
               (plan: any) => ({
                  label: plan.description,
                  value: plan.id
               })
            ))
      );
   }

   close() {
      this._dialog.closeAll();
   }

   getUnities() {
      this.collectionPlaceOptions = this.AllcollectionPlaceOptions.filter(
         (x) => x.unityId === this.form.unityId.value
      );
   }

   async createReq() {
      let requisition = {
         isActive: true,
         name: this.form.customerName.value,
         birthDate: new Date(this.form.customerBirthDate.value).toISOString(),
         genderType: parseInt(this.form.customerGenderType.value),
         customerId: this.customerId,
         unityId: this.form.unityId.value,
         collectionPlaceId: this.form.collectionPlaceId.value,
         clinicalInformation: ""
      };

      await this._requisitionService
         .post(requisition)
         .then((res) => {
            this.close();
            this._toastService.fireSuccess("customer.msgPostRequisition");
            localStorage.setItem(`autosave://lastURL`, this._router.url);
            this._router.navigateByUrl(
               `MovimentacaoOld/Recepcao/Requisicoes/${res.id}`
            );
         })
         .catch(
            //@ts-ignore
            (err) => {
               this._toastService.fireError(err);
            }
         );
   }

   isValidDate(date: string): boolean {
      const inputDate = new Date(date);
      const currentDate = new Date();

      if (
         isNaN(inputDate.getDate()) ||
         inputDate > currentDate ||
         inputDate.getFullYear() < 1900 ||
         inputDate.getMonth() < 0 ||
         inputDate.getMonth() > 11
      ) {
         setTimeout(() => {
            this._toastService.fireAlertNavegator("customer.msgDateError");
         });
         return false;
      }
      return true;
   }

   openModalMedication(event: any) {
      this.medications = [];
      this.form.medication.reset();
      const myTempDialog = this._dialog.open(this.dialogModalManualMedication, {
         width: "660px",
         maxHeight: event.view.innerHeight > 764 ? "750px" : "500px"
      });
      myTempDialog
         .afterClosed()
         .subscribe(() => this.form.manualMedication.reset());
   }

   resolve(data: any, columns: any) {
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.medications.push(obj);
      });
   }

   resolveCustomerMedication(data: any, columns: any) {
      this.resolvedMedications = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.resolvedMedications.push(obj);
      });
   }

   async actions(emit: any) {
      switch (emit.action) {
         case "Excluir":
            if (emit.object.id != "undefined") {
               this.deleteMedicine(emit.object);
            } else {
               const index = this.rawMedications.findIndex(
                  (med) => med.description == emit.object.Nome
               );
               this.rawMedications.splice(index, 1);
               this.resolvedMedications.splice(index, 1);
            }
            break;
         case "Editar":
            this.form.manualMedication.setValue(
               emit.object["customer.register.list.name"]
            );
            const myTempDialog = this._dialog.open(
               this.dialogModalManualMedication,
               {
                  width: "660px",
                  data: emit.object
               }
            );
            myTempDialog
               .afterClosed()
               .subscribe(() => this.form.manualMedication.reset());
            break;
         case "PutActive":
            if (!this.customerId) break;
            this._medicinesService
               .patchActive(emit.object.id, emit.object.Ativo)
               .then((x: any) => {
                  this._toastService.fireSuccess("global.msgSituationSuccess");
               })
               .catch((err) => this._toastService.fireError(err));
            break;
      }
   }

   async deleteMedicine(medicine: { id: string; Nome: string }) {
      this._toastService
         .fireConfirmation("customer.register.delete-medicine")
         .then(async (resposta) => {
            if (resposta.isConfirmed) {
               await this._medicinesService
                  .delete(new Guid(medicine.id))
                  .then(async () => {
                     await this.getMedications();
                     this._toastService.fireSuccess(
                        "customer.msgRemoveMedication"
                     );
                  });
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   //@ts-ignore
   createMedication() {
      const medication = {
         customerId: this.customerId,
         description: this.form.manualMedication.value,
         isActive: true
      };

      if (!this.customerId) {
         if (
            this.rawMedications.some(
               (med) => med.description == medication.description
            )
         ) {
            return this._toastService.fireAlertNavegator(
               "customer.msgAddMedication"
            );
         }
         this.rawMedications.push(medication);
         this.resolveCustomerMedication(
            this.rawMedications,
            resolveMedicationCustomerLocally
         );
         this.form.manualMedication.setValue("");
         return this._toastService.fireSuccess("customer.msgPostMedication");
      }

      this._medicinesService.post(medication).then(async (res: any) => {
         if (res.id) {
            await this.getMedications();
            this._toastService.fireSuccess("customer.msgPostMedication");
            const queryListArray: Array<InputPropComponent> =
               this.inputsProp.toArray();
            const codeInputProp = queryListArray.find(
               (inputProp) =>
                  inputProp.placeholder === "customer.register.medicine"
            );
            if (codeInputProp) {
               setTimeout(() => {
                  codeInputProp.input.nativeElement.focus();
                  codeInputProp.input.nativeElement.click();
               });
            }
         }
      });
   }

   isActive(item: any) {
      if (item.isActive) {
         return "checkbox|true";
      } else {
         return "checkbox|";
      }
   }

   getTypeMedication(item: any) {
      if (item.inclusionType == 2) {
         return "<span class='span-styles' style='background: #006E9D; color: #F8F8FF;'>Integração</span>";
      } else {
         return "<span class='span-styles' style='background: #07BF56; color: #F8F8FF;'>Manual</span>";
      }
   }

   //@ts-ignore
   updateMedication(medication: any) {
      if (!this.customerId) {
         medication = this.rawMedications.find(
            (med) => med.description == medication["Nome"]
         );

         if (
            this.rawMedications.some(
               (med) => med.description == this.form.manualMedication.value
            )
         ) {
            return this._toastService.fireAlertNavegator(
               "customer.msgPutExist"
            );
         }

         medication.description = this.form.manualMedication.value;
         this.resolveCustomerMedication(
            this.rawMedications,
            resolveMedicationCustomerLocally
         );
         this._dialog.closeAll();
         return this._toastService.fireSuccess("customer.msgPutMedication");
      }

      medication = {
         ...medication,
         customerId: this.customerId,
         description: this.form.manualMedication.value
      };

      this._medicinesService
         .put(medication, new Guid(medication.id))
         .then(async (res: any) => {
            if (res.id) {
               await this.getMedications();
               this._toastService.fireSuccess("customer.msgPutMedication");
               this.close();
            }
         });
   }

   async getMedications() {
      await this._medicinesService
         .getPagination({ customerId: this.customerId })
         .then((res: any) => {
            this.resolveCustomerMedication(res.data, resolveMedicationCustomer);
         });
   }
}
