import { CommonModule } from "@angular/common";
import {
   Component,
   inject,
   OnInit,
   QueryList,
   ViewChildren
} from "@angular/core";
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UsersAdminUsersFacade } from "@app/modules_new/admin/data/facades/users-admin-users.facade";
import { GroupsFacade } from "@app/modules_new/administrativeData/data/facade/groups.facade";
import { RequestOptions } from "@app/modules_new/data/services/types/http.types";
import { AgreementSuppportFacade } from "@app/modules_new/registers/data/facades/agreement-support-facade";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { InputSelectFormComponent } from "@app/modules_new/shared/components/form/input-select/base/input-select.component";
import { InputSelectMultplesFormComponent } from "@app/modules_new/shared/components/form/input-select/multiples/multiples.component";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ModalLayoutComponent } from "@app/modules_new/shared/components/form/modal/modal.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "permission-users-modal",
   templateUrl: "./permission-users-ui-modal.component.html",
   standalone: true,
   imports: [
      ModalLayoutComponent,
      ReactiveFormsModule,
      ButtonFormComponent,
      CommonModule,
      TranslateModule,
      InputSelectFormComponent,
      InputSelectMultplesFormComponent
   ]
})
export class PermissionUsersGroupsModal implements OnInit {
   private _usersFacade = inject(UsersAdminUsersFacade);
   private _agreementSuppportFacade = inject(AgreementSuppportFacade);
   private _groupsFacade = inject(GroupsFacade);
   public _dialogData = inject(MAT_DIALOG_DATA);

   form = new FormGroup({
      companyId: new FormControl<string | null>(null, [Validators.required]),
      unityId: new FormControl<string | null>(null, [Validators.required]),
      users: new FormControl<string | null>(null, [Validators.required])
   });

   title: string = "Adicionar permissões";
   companyOptions: any = [];
   unityOptions: any = [];
   usersOptions: any = [];

   @ViewChildren(InputTextFormComponent)
   InputTextFormComponent!: QueryList<InputTextFormComponent>;

   async ngOnInit() {
      this.companyOptions =
         await this._agreementSuppportFacade.getCompanyOptions();

      this.usersOptions = await this._usersFacade.getUsersOptions();
   }

   async submit() {
      let ids: any = []; //@ts-ignore
      this.form.controls.users.value.forEach((element: any) => {
         ids.push(element.value);
      });

      let obj = {
         usersId: ids,
         companyId: this.form.controls.companyId.value,
         unityId: this.form.controls.unityId.value
      };

      const request: RequestOptions = {
         path: `/${this._dialogData}/user`
      };

      const response: any = await this._groupsFacade.postUser(obj, request);

      if (response.success) {
         this.form.reset();
      }
   }

   changeCompany() {
      this.form.controls.unityId.setValue(null);
      this.unityOptions = this.companyOptions
         .filter((x: any) => x.value == this.form.controls.companyId.value)[0]
         .unities.map((res: any) => ({
            value: res.value,
            label: res.label
         }));
   }
}
