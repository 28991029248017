import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  //TODO Verificar componentização dos Forms (Group, Control, Arrays);
  @HostListener('window:resize')
  onResize(): void {
    this.width.setValue(window.innerWidth)
    this.limit.setValue(this._elementRef.nativeElement.querySelector('#tittle').offsetWidth + 200)
  }

  @Input() title: string = '';

  limit = new UntypedFormControl(null)
  width = new UntypedFormControl(null)

  constructor(
    private _location: Location,
    private _elementRef: ElementRef,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.width.setValue(window.innerWidth)
    this.limit.setValue(this._elementRef.nativeElement.querySelector('#tittle').offsetWidth + 200)
  }

  goToBack() {
    this._location.back()
  }

}
