<div
    class="table-wp"
    [ngClass]="{
        paddingDefault: !ignorePadding,
        shadowTable: shadow,
        roundBorder: roundBorder
    }"
>
    <div *ngIf="filterRows?.value" class="text-end">
        {{ ("forms-table.filter" | translate) + '"' + filterRows.value + '"' }}
    </div>

    <ng-template #tableHeader>
        <thead>
            <tr>
                <th
                    *ngIf="canSelectRows"
                    class="max-w-14"
                    [ngStyle]="{
                        'border-bottom': isAdm
                            ? '1px solid #404040'
                            : '1px solid #fe6344'
                    }"
                >
                    <forms-checkbox
                        *ngIf="!selectOnlyOne && !doubleTable"
                        (changed)="selectRows($event)"
                        [form]="formSelectAll"
                        [radio]="true"
                    ></forms-checkbox>
                </th>
                <th
                    *ngFor="let column of columns"
                    (click)="sortColumn(column)"
                    [ngClass]="{ sorted: wasSorted(column) }"
                    [ngStyle]="{
                        'border-bottom': isAdm
                            ? '1px solid #404040'
                            : '1px solid #fe6344'
                    }"
                >
                    <span class="flex justify-between items-center">
                        {{ column?.label | translate }}
                        <ui-svg-selector
                            *ngIf="column?.label"
                            class="ml-2 sort-icon"
                            [fill]="isAdm ? '#404040' : 'var(--primary-color)'"
                            [svg]="'table-sort-icon'"
                        ></ui-svg-selector>
                    </span>
                </th>
                <th
                    *ngIf="hasDropdownOrActions"
                    class="w-10"
                    [ngStyle]="{
                        'border-bottom': isAdm
                            ? '1px solid #404040'
                            : '1px solid #fe6344'
                    }"
                ></th>
            </tr>
        </thead>
    </ng-template>

    <ng-container *ngIf="!doubleTable">
        <table class="w-full">
            <ng-container *ngTemplateOutlet="tableHeader"></ng-container>

            <ng-container *ngIf="loadingTable; else singleTableBody">
                <tbody>
                    <tr class="loading">
                        <td
                            [attr.colspan]="
                                canSelectRows
                                    ? columns.length + 1
                                    : columns.length
                            "
                            class="text-center py-8"
                        >
                            <span
                                class="flex flex-col justify-center items-center w-full"
                            >
                                <ui-loading></ui-loading>
                                <p class="animate-pulse text-xl mt-2">
                                    {{ "forms-table.loading" | translate }}
                                </p>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </ng-container>

            <ng-template #singleTableBody>
                <tbody>
                    <tr *ngIf="(rows | async)?.length === 0">
                        <td
                            [attr.colspan]="
                                canSelectRows
                                    ? columns.length + 1
                                    : columns.length
                            "
                            class="text-center py-8"
                        >
                            <div class="flex flex-col items-center gap-1 p-4">
                                <span
                                    class="text-4xl font-medium text-[#F24C3D] border-b border-[#F24C3D]"
                                >
                                    Nenhum resultado encontrado.
                                </span>
                                <span class="text-2xl text-black">
                                    Tente ajustar os filtros ou entre em contato
                                    com a nossa central.
                                </span>
                            </div>
                        </td>
                    </tr>

                    <ng-container
                        *ngFor="
                            let row of rows | async | filterRows : filterRows;
                            trackBy: rowIdentity
                        "
                    >
                        <tr
                            [ngClass]="
                                (greenRow && row.situation == 6) ||
                                (greenRow && row.typeAnalyte === 1)
                                    ? 'greenRow'
                                    : greenRow && row.typeAnalyte === 2
                                    ? 'lightGreenLine'
                                    : ''
                            "
                        >
                            <td *ngIf="canSelectRows">
                                <forms-checkbox
                                    (changed)="selectRows($event, row)"
                                    [radio]="true"
                                    [row]="row"
                                ></forms-checkbox>
                            </td>
                            <td
                                *ngFor="let column of columns"
                                [attr.scope]="column?.label | translate"
                            >
                                <table-column
                                    (userInteraction)="columnClick($event)"
                                    [column]="column"
                                    [row]="row"
                                    [disabled]="column.componentExtra?.disabled"
                                ></table-column>
                            </td>
                            <td
                                *ngIf="hasDropdownOrActions || extra"
                                class="flex items-center"
                            >
                                <div class="flex items-center justify-center">
                                    <div
                                        *ngIf="extra"
                                        (click)="extraAction($event, row)"
                                    >
                                        <svg
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            (click)="emit(row.id)"
                                        >
                                            <g clip-path="url(#clip0_2_97)">
                                                <rect
                                                    x="0.5"
                                                    y="0.05"
                                                    width="21"
                                                    height="21"
                                                    rx="10.5"
                                                    fill="#808080"
                                                />
                                                <rect
                                                    x="3.5"
                                                    y="3.05"
                                                    width="15"
                                                    height="15"
                                                    rx="7.5"
                                                    fill="#F5F5F5"
                                                />
                                                <rect
                                                    x="5.5"
                                                    y="9.05"
                                                    width="11"
                                                    height="3"
                                                    rx="1.5"
                                                    fill="#808080"
                                                />
                                                <rect
                                                    x="9.5"
                                                    y="5.05"
                                                    width="3"
                                                    height="11"
                                                    rx="1.5"
                                                    fill="#808080"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_2_97">
                                                    <rect
                                                        width="21"
                                                        height="21"
                                                        fill="white"
                                                        transform="translate(0.5 0.05)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div
                                        class="flex items-center justify-between"
                                        style="min-width: 40px"
                                    >
                                        <table-actions
                                            [actions]="actions"
                                            *ngIf="actions?.length > 0"
                                            (clicked)="actionClick($event, row)"
                                        ></table-actions>
                                        <ui-svg-selector
                                            *ngIf="hasDropdown"
                                            [ngClass]="{
                                                isOpen: isDropdownOpen(row)
                                            }"
                                            class="cursor-pointer"
                                            (click)="toggleDropdown(row)"
                                            [fill]="'#404040'"
                                            [svg]="'icon-arrow-dropdown'"
                                        ></ui-svg-selector>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr *ngIf="isDropdownOpen(row)">
                            <td [attr.colspan]="columns.length + 2">
                                <ng-container
                                    *ngIf="dropdownTemplateRef"
                                    [ngTemplateOutlet]="dropdownTemplateRef"
                                    [ngTemplateOutletContext]="{
                                        $implicit: row
                                    }"
                                ></ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </ng-template>
        </table>
    </ng-container>

    <ng-container *ngIf="doubleTable">
        <div class="flex gap-4 mt-4">
            <table class="w-1/2">
                <ng-container *ngTemplateOutlet="tableHeader"></ng-container>
                <tbody>
                    <tr *ngFor="let row of rowsLeft; trackBy: rowIdentity">
                        <td *ngIf="canSelectRows">
                            <forms-checkbox
                                (changed)="selectRows($event, row)"
                                [radio]="true"
                                [row]="row"
                            ></forms-checkbox>
                        </td>
                        <td
                            *ngFor="let column of columns"
                            [attr.scope]="column?.label | translate"
                        >
                            <table-column
                                (userInteraction)="columnClick($event)"
                                [column]="column"
                                [row]="row"
                                [disabled]="column.componentExtra?.disabled"
                            ></table-column>
                        </td>
                        <td
                            *ngIf="hasDropdownOrActions || extra"
                            class="flex items-center"
                        >
                            <div class="flex items-center justify-center">
                                <div
                                    *ngIf="extra"
                                    (click)="extraAction($event, row)"
                                >
                                    <svg
                                        width="22"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        (click)="emit(row.id)"
                                    >
                                        <g clip-path="url(#clip0_2_97)">
                                            <rect
                                                x="0.5"
                                                y="0.05"
                                                width="21"
                                                height="21"
                                                rx="10.5"
                                                fill="#808080"
                                            />
                                            <rect
                                                x="3.5"
                                                y="3.05"
                                                width="15"
                                                height="15"
                                                rx="7.5"
                                                fill="#F5F5F5"
                                            />
                                            <rect
                                                x="5.5"
                                                y="9.05"
                                                width="11"
                                                height="3"
                                                rx="1.5"
                                                fill="#808080"
                                            />
                                            <rect
                                                x="9.5"
                                                y="5.05"
                                                width="3"
                                                height="11"
                                                rx="1.5"
                                                fill="#808080"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2_97">
                                                <rect
                                                    width="21"
                                                    height="21"
                                                    fill="white"
                                                    transform="translate(0.5 0.05)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div
                                    class="flex items-center justify-between"
                                    style="min-width: 40px"
                                >
                                    <table-actions
                                        [actions]="actions"
                                        *ngIf="actions?.length > 0"
                                        (clicked)="actionClick($event, row)"
                                    ></table-actions>
                                    <ui-svg-selector
                                        *ngIf="hasDropdown"
                                        [ngClass]="{
                                            isOpen: isDropdownOpen(row)
                                        }"
                                        class="cursor-pointer"
                                        (click)="toggleDropdown(row)"
                                        [fill]="'#404040'"
                                        [svg]="'icon-arrow-dropdown'"
                                    ></ui-svg-selector>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table class="w-1/2">
                <ng-container *ngTemplateOutlet="tableHeader"></ng-container>
                <tbody>
                    <tr *ngFor="let row of rowsRight; trackBy: rowIdentity">
                        <td *ngIf="canSelectRows">
                            <forms-checkbox
                                (changed)="selectRows($event, row)"
                                [radio]="true"
                                [row]="row"
                            ></forms-checkbox>
                        </td>
                        <td
                            *ngFor="let column of columns"
                            [attr.scope]="column?.label | translate"
                        >
                            <table-column
                                (userInteraction)="columnClick($event)"
                                [column]="column"
                                [row]="row"
                                [disabled]="column.componentExtra?.disabled"
                            ></table-column>
                        </td>
                        <td
                            *ngIf="hasDropdownOrActions || extra"
                            class="flex items-center"
                        >
                            <div class="flex items-center justify-center">
                                <div
                                    *ngIf="extra"
                                    (click)="extraAction($event, row)"
                                >
                                    <svg
                                        width="22"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        (click)="emit(row.id)"
                                    >
                                        <g clip-path="url(#clip0_2_97)">
                                            <rect
                                                x="0.5"
                                                y="0.05"
                                                width="21"
                                                height="21"
                                                rx="10.5"
                                                fill="#808080"
                                            />
                                            <rect
                                                x="3.5"
                                                y="3.05"
                                                width="15"
                                                height="15"
                                                rx="7.5"
                                                fill="#F5F5F5"
                                            />
                                            <rect
                                                x="5.5"
                                                y="9.05"
                                                width="11"
                                                height="3"
                                                rx="1.5"
                                                fill="#808080"
                                            />
                                            <rect
                                                x="9.5"
                                                y="5.05"
                                                width="3"
                                                height="11"
                                                rx="1.5"
                                                fill="#808080"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2_97">
                                                <rect
                                                    width="21"
                                                    height="21"
                                                    fill="white"
                                                    transform="translate(0.5 0.05)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div
                                    class="flex items-center justify-between"
                                    style="min-width: 40px"
                                >
                                    <table-actions
                                        [actions]="actions"
                                        *ngIf="actions?.length > 0"
                                        (clicked)="actionClick($event, row)"
                                    ></table-actions>
                                    <ui-svg-selector
                                        *ngIf="hasDropdown"
                                        [ngClass]="{
                                            isOpen: isDropdownOpen(row)
                                        }"
                                        class="cursor-pointer"
                                        (click)="toggleDropdown(row)"
                                        [fill]="'#404040'"
                                        [svg]="'icon-arrow-dropdown'"
                                    ></ui-svg-selector>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-content
        class="mt-4"
        *ngIf="(rows | async) !== null"
        select="[pagination]"
    ></ng-content>
</div>
