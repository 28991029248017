import decodedToken from "@app/util/Token";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SlideInOutAnimation } from "../../../../animations";
import { UserUtil } from "../../../../util/user";

@Component({
   selector: "user-menu",
   templateUrl: "./user-menu.component.html",
   styleUrls: ["./user-menu.component.scss"],
   animations: [SlideInOutAnimation]
})
export class UserMenuComponent implements OnInit {
   tomCat: boolean = false;
   adminAccess: boolean = false;
   @Input() menuuseropen: boolean = false;
   MenuActive: Array<Number> = [];
   @Output() closeMenuEmit: EventEmitter<any> = new EventEmitter();

   constructor(private _userUtil: UserUtil) {}

   imagePath: any = "";

   timeLogged: any;

   ngOnInit(): void {
      if (decodedToken) {
         decodedToken.iss == "https://*.concentsistemas.com.br"
            ? (this.tomCat = true)
            : (this.tomCat = false);
         this.timeLogged = decodedToken.iat * 1000;
      }
   }

   toggleShowDiv(index: Number) {
      const _indexOf = this.MenuActive.indexOf(index);
      if (_indexOf == -1) this.MenuActive.push(index);
      else this.MenuActive.splice(_indexOf, 1);
   }

   CloseMenu() {
      this.closeMenuEmit.emit({ close: true });
   }

   Sair() {
      this._userUtil.Sair();
   }

   ToggleAdmin() {
      window.location.href = "/Admin";
   }

   checkAdmin() {
      if (
         !this.tomCat &&
         decodedToken &&
         (decodedToken.tid === "08d9db76-225b-4a3d-8ee9-769e6ae577c5" ||
            "af3a45a4-e305-4b43-8472-9bbb1c34c229")
      ) {
         return true;
      } else {
         return false;
      }
   }

   maxDate() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();
      var hh = today.getHours();
      var mn = today.getMinutes();
      if (dd < 10) {
         //@ts-ignore
         dd = "0" + dd;
      }
      if (mm < 10) {
         //@ts-ignore
         mm = "0" + mm;
      }
      if (hh < 10) {
         //@ts-ignore
         hh = "0" + hh;
      }
      if (mn < 10) {
         //@ts-ignore
         mn = "0" + mn;
      }
      //@ts-ignore
      today = +hh + ":" + mn + " - " + dd + "/" + mm + "/" + yyyy;
      return today;
   }
}
