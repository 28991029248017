import { Inject, Injectable } from '@angular/core';
import { Guid } from '../../util/guid';
import { IHttpService } from '../interfaces/IHttpService';
import { QueryParams } from '../interfaces/services.types';
import { HttpAuthService } from "./http-auth.service";
import { HttpResponsePageable } from '../services.types';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseAuthService implements IHttpService {

  protected path: string;

  //@ts-nocheck
  constructor(protected HttpAuthService: HttpAuthService, @Inject(String) path: string) {
    this.path = path;
  }

  get(queryString: string): Promise<Object> {
    return this.HttpAuthService.get(queryString, `api/${this.path}`);
  }

  getAll(): Promise<Object> {
    return this.HttpAuthService.get('', `api/${this.path}`);
  }

  getById(id: Guid): Promise<Object> {
    return this.HttpAuthService.get('', `api/${this.path}/${id}`);
  }

  getByPath(queryString: string, path: string, headers: Array<String> = [], tenantId?: string): Promise<Object> {
    if(tenantId) {
      this.HttpAuthService.headers.tenantId = tenantId;
    }
    headers.forEach((headers) => {
      // this.HttpAuthService.headers.Authorization = headers.toString();
    });
    return this.HttpAuthService.get(queryString, `api/${this.path}/${path}`);
  }

  post(body: Object): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.HttpAuthService.post(body, `api/${this.path}`);
  }

  postToPath(body: Object, path : string): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.HttpAuthService.post(body, `api/${this.path}/${path}`);
  }

  postLogout(id: Guid): Promise<Object> {
    return this.HttpAuthService.post({},`api/${this.path}?userId=${id}`);
  }

  // @ts-ignore
  deleteToPath(id?: Guid, path : string): Promise<Object> {
    // @ts-ignore
    return this.HttpAuthService.delete(id, `api/${this.path}/${path}`);
  }

  put(body: Object, id: Guid): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.HttpAuthService.put(body, id, `api/${this.path}`);
  }

  delete(id?: Guid): Promise<Object> {
    //@ts-ignore
    return this.HttpAuthService.delete(id, `api/${this.path}`);
  }

  deleteB(id?: Guid, body?: object, path?: string): Promise<Object> {
    if (path){
      //@ts-ignore
      return this.HttpAuthService.deleteWithBody(id, body, `api/${this.path}/${path}`);
    } else {
      //@ts-ignore
      return this.HttpAuthService.deleteWithBody(id, body, `api/${this.path}`);
    }
  }

  patch(body: Object, id: Guid): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.HttpAuthService.put(body, id, `api/${this.path}`);
  }

  patchWithPath(body: Object, path: string): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.HttpAuthService.patchWithPath(body, `api/${this.path}/${path}`);
  }

  patchActive(id: string, value: string) {
    let valueActive : boolean;
    if (value.includes('checkbox')){
      valueActive = value.includes('true') ?? false
    } else {
      valueActive = value.includes('active') ?? false
    }
    let infosToPut = [{
      Opt: "replace",
      Patch: "/IsActive",
      Value: !valueActive
    }]

    return this.HttpAuthService.patch(infosToPut, new Guid(id), `api/${this.path}`)
  }
  /**
   * Método para fazer consulta de dados resumidos
   * @param active Valor para filtrar por dados ativos
   * @returns retorna o GET padrão com QueryParam "?Resume=true"
   */
  getResume(active?: boolean) {
    let query: QueryParams = { resume: true };

    if (active !== undefined) {
      query['active'] = active; // Usando a notação de colchetes
    }
    return this.getPagination(query)
  }

  /**
   * Método para fazer GET de Endpoints que podem fazer páginação
   * @param page Página que deseja acessar
   * @param index Número de dados que devem ser retornados por Requisição
   * @param queryObject Objeto que representa o QueryParam
   * @returns Retorna requisição páginada
   */
  getPagination<T>(queryObject?: QueryParams): Promise<HttpResponsePageable<T>> {
    const queryParams = this.createQueryParam(queryObject)
    return this.HttpAuthService.get(queryParams, `api/${this.path}`) as Promise<HttpResponsePageable<T>>;
  }

  /**
   * Método para transformar dados em QueryParam para ser usado em URL
   * @param params Objeto que será transformado em QueryParam usando {chave: valor}
   * @param initialValue Valor inicial para ser usado na Query
   * @returns retorna valor em formato de "?chave=valor"
   */
  private createQueryParam(params?: QueryParams, initialValue: string = "?"): string {
    let queryParams: string[] = [initialValue]

    if(params) {
      Object.keys(params).forEach((param) => {
          let paramValue = params[param]
          if(typeof paramValue === "string" && paramValue.length == 0 || paramValue === null) return;
          queryParams.push(`${param}=${paramValue}`);
      })
    }

    if((queryParams.length === 1) && (queryParams[0] === "?")) return ""

    return queryParams.join('&').replace("?&", "?");
  }
}
