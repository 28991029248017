import {
    Component, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges, AfterViewInit, ChangeDetectionStrategy, NgZone,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription, fromEvent } from "rxjs";
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';

/**
*  Versão Modificada sem métodos desnecessários
*/
@Component({
    selector: 'input-search-clean',
    templateUrl: './input-search-clean.component.html',
    styleUrls: ['./input-search-clean.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSearchCleanComponent implements AfterViewInit {

    @Input() form!: UntypedFormControl;

    @Input() type: string = 'text';
    @Input() placeholder: string = '';
    @Input() label: string = '';
    @Input() mask: any = '';
    @Input() className: string = '';
    @Input() requiredInput: string = '';
    @Input() tabindexcomponent: string = '';
    @Input() pattern: string = '';
    @Input() callChangeFormEmit: boolean = false;
    @Input() options: Array<any> = [];
    @Input() color: string = 'unset';
    @Input() hideSvg: boolean = false;
    @Input() onFocus: boolean = false;
    @Input() disabled: boolean = false;
    @Input() valueOption: String = '';
    @Input() noLabelUp: boolean = false;

    @Output() changeForm = new EventEmitter<any>();

    @ViewChild('input') input!: ElementRef;
    @ViewChild('search') searchElement!: ElementRef;
    @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete!: MatAutocompleteTrigger;

    @ViewChild('input', { read: MatAutocompleteTrigger })
    autoComplete!: MatAutocompleteTrigger;

    OptionSelect: Number = -1;

    autoCompleteOpen = false;
    result: boolean = false;
    PropValue: String = '';
    classe: string = '';
    notSelect: boolean = true;
    border: boolean = false;

    private _formChangeHandler!: Subscription

    constructor(private _zone: NgZone) { }

    ngOnInit(): void {
        this._zone.runOutsideAngular(() => window.addEventListener('scroll', this.scrollEvent, true));
        this.classe = this.className + ' input-row';
    }

    ngOnDestroy(): void {
        this._formChangeHandler.unsubscribe()
    }

    ngAfterViewInit() {
        fromEvent(this.input.nativeElement, 'focus').subscribe(() => {
            this.autocomplete.openPanel();
        });

        if (this.onFocus) {
            this.input.nativeElement.focus();
        }

        this._formChangeHandler = this.form.valueChanges.subscribe((val: any) => {
            if (val === null) {
                this.valueOption = '';
            } else {
                let indexSelected = this.options.findIndex((x: any) => x.value == this.form.value);
                this.valueOption = this.options[indexSelected];
            }
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty("options") && this.options) {
            if (this.valueOption == null || this.valueOption == '' || !this.valueOption) {
                let indexSelected = this.options.findIndex((x: any) => x.value == this.form.value);
                this.valueOption = this.options[indexSelected];
            }
        }
        if (this.options && this.options.length > 0) {
            this.autoCompleteOpen = true;
        }
    }

    validate(s: String) {
        var rgx = /^[0-9]*\.?[0-9]*$/;
        return s.match(rgx);
    }

    getSelectedOption(event: MatAutocompleteSelectedEvent) {
        let indexSelected = this.options.findIndex((x: any) => x.value == event.option.value.value);
        let labelSelected = this.options[indexSelected].label
        this.SelectOption(labelSelected, event.option.value, indexSelected);
    }

    private SelectOption(label: String, model: { label: string, value: any }, index: Number) {
        this.border = false;
        this.OptionSelect = index;
        this.notSelect = false;
        this.form.setValue(model.value);

        if (index != 0 || !this.callChangeFormEmit) {
            this.changeForm.emit({ change: true, data: model, indexSelected: index })
        }
    }

    displayCorretLabelFn(option?: any): string | undefined {
        if (option) return option.label
        else return '';
    }

    scrollEvent = (event: any): void => {
        if (this.autoComplete.panelOpen)
            this.autoComplete.updatePosition();
    }

}
