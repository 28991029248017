import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { Guid } from 'src/app/util/guid';
import { GetAllInvoices } from './invoice.types';

@Injectable({ 
    providedIn: 'root' 
})
export class InvoiceService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/movimentation/invoice');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllInvoices>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetAllInvoices>>
  }
}