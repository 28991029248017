export enum AccessType {
   Customer = 1,
   Unity = 2,
   Doctor = 3,
   Agreement = 4,
   Plan = 5,
   Destiny = 6,
   CollectionPlace = 7
}

export enum AccessTypePTBR {
   Cliente = 1,
   Unidade = 2,
   Medico = 3,
   Convenio = 4,
   Plano = 5,
   Destino = 6,
   LocalColeta = 7
}