<div class="contentPage">
  <div class="flex gap-2 align-center">
    <app-button (click)="back()" [placeholder]="'global.back'" [class]="'btn-reverse'"></app-button>
    <h1>{{id ? ('unituParametersEdit.titleModalEdit' | translate) : ('unituParametersEdit.titleModalNew' | translate)}}</h1>
  </div>
  <mat-tab-group class="mt-32">
    <mat-tab>
      <ng-template mat-tab-label>
        <h2>{{'unituParametersEdit.titleData' | translate}}</h2>
      </ng-template>
      <div class="contextPage">
        <div class="mt-32 flex flex-column gap-form box">
          <h2>{{'unituParametersEdit.titleDataUnit' | translate}}</h2>
          <div class="flex w-100 gap-form">
            <input-prop *ngIf="id" [class]="'w-inherit'" [readonly]="true" [placeholder]="'unituParametersEdit.name'" [form]="formParameter.controls['name']" [tabindexcomponent]="id? '-1' : ''"></input-prop>
            <input-search *ngIf="!id" [class]="'w-inherit'" [placeholder]="'unituParametersEdit.name'" [form]="formParameter.controls['unity']" [options]="unitys"></input-search>
            <input-prop style='max-width: calc(100px * var(--proportional));' [form]="formParameter.controls['defaultHour']" [placeholder]="'unituParametersEdit.defaultHour'" type="time"></input-prop>
            <select-prop style='min-width: calc(180px * var(--proportional));' [placeholder]="'unituParametersEdit.promiseType'" [form]="formParameter.controls['promiseType']" [options]="promiseOptions"></select-prop>
          </div>
        </div>
        <div class="mt-32 flex flex-column gap-form box">
          <h2>{{'unituParametersEdit.titleConfig' | translate}}</h2>
          <div class="flex flex-column gap-2">
            <div class="flex align-center gap-1">
              <checkbox-prop [gap]="'1'" [label]="'unituParametersEdit.blockingDebtors'" [form]="formParameter.controls['blockingDebtors']"></checkbox-prop>
              <input-prop style="max-width: calc(150px * var(--proportional));" [type]="'number'" [form]="formParameter.controls['daysBlockingDebtors']" [labelAfter]="'unituParametersEdit.daysBlockingDebtors'"></input-prop>
            </div>
            <div class="flex align-center gap-1">
              <checkbox-prop [gap]="'1'" [label]="'unituParametersEdit.requiredNameResposible'" [form]="formParameter.controls['requiredNameResposible']"></checkbox-prop>
              <input-prop style="max-width: calc(150px * var(--proportional));" [type]="'number'" [form]="formParameter.controls['ageNameResponsible']" [labelAfter]="'unituParametersEdit.ageNameResponsible'"></input-prop>
            </div>
          </div>
        </div>
        <div class="mt-32 flex flex-center">
          <app-button (click)="id ? updateUnityParameter() : createUnityParameter()" [placeholder]="'global.save'"></app-button>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <h2>{{'unituParametersEdit.titleBudget' | translate}}</h2>
      </ng-template>
      <div class="contextPage">
        <div class="mt-32 flex flex-column gap-form box">
          <h2>{{'unituParametersEdit.titleAgreement' | translate}}</h2>
          <div class="flex gap-form">
            <input-prop [placeholder]="'unituParametersEdit.code'" [form]="formParameter.controls['codeAgreementDefault']" (keydown.enter)="searchAgreementDefault(formParameter.controls['codeAgreementDefault'].value, true, 0)" (keydown.tab)="searchAgreementDefault(formParameter.controls['codeAgreementDefault'].value, true, 0)"></input-prop>
            <div class="flex w-100 gap-form">
              <input-search class="w-100" [options]="agreementOptions" [placeholder]="'unituParametersEdit.agreementDefault'" [form]="formParameter.controls['AgreementDefault']" (search)="searchAgreementDefault($event.value, false, 0)" (changeForm)="checkControlPlan(formParameter.controls['AgreementDefault'].value, 0)" [searchApi]="true"></input-search>
              <input-search class="w-100" [options]="agreementPlanOptionsDefoult" *ngIf="controlPlanDefault" [placeholder]="'unituParametersEdit.plan'" [form]="formParameter.controls['AgreementPlanDefault']"></input-search>
            </div>
          </div>
          <h2>{{'unituParametersEdit.titleAgreementOne' | translate}}</h2>
          <div class="flex gap-form">
            <input-prop [placeholder]="'unituParametersEdit.code'" [form]="formParameter.controls['codeAgreementOne']" (keydown.enter)="searchAgreementDefault(formParameter.controls['codeAgreementOne'].value, true, 1)" (keydown.tab)="searchAgreementDefault(formParameter.controls['codeAgreementOne'].value, true, 1)"></input-prop>
            <div class="flex w-100 gap-form">
              <input-search class="w-100" [options]="agreementOptionsOne" [placeholder]="'unituParametersEdit.agreementOne'" [form]="formParameter.controls['AgreementOne']" (search)="searchAgreementDefault($event.value, false, 1)" (changeForm)="checkControlPlan(formParameter.controls['AgreementOne'].value, 1)" [searchApi]="true"></input-search>
              <input-search class="w-100" [options]="agreementPlanOptionsOne" *ngIf="controlPlanOne" [placeholder]="'unituParametersEdit.plan'" [form]="formParameter.controls['AgreementPlanOne']"></input-search>
            </div>
          </div>
          <h2>{{'unituParametersEdit.titleAgreementTwo' | translate}}</h2>
          <div class="flex gap-form">
            <input-prop [placeholder]="'unituParametersEdit.code'" [form]="formParameter.controls['codeAgreementTwo']" (keydown.enter)="searchAgreementDefault(formParameter.controls['codeAgreementTwo'].value, true, 2)" (keydown.tab)="searchAgreementDefault(formParameter.controls['codeAgreementTwo'].value, true, 2)"></input-prop>
            <div  class="flex w-100 gap-form">
              <input-search class="w-100" [options]="agreementOptionsTwo" [placeholder]="'unituParametersEdit.agreementTwo'" [form]="formParameter.controls['AgreementTwo']" (search)="searchAgreementDefault($event.value, false, 2)" (changeForm)="checkControlPlan(formParameter.controls['AgreementTwo'].value, 2)" [searchApi]="true"></input-search>
              <input-search class="w-100" [options]="agreementPlanOptionsTwo" *ngIf="controlPlanTwo" [placeholder]="'unituParametersEdit.plan'" [form]="formParameter.controls['AgreementPlanTwo']"></input-search>
            </div>
          </div>
        </div>
        <div class="mt-32 flex flex-center">
          <app-button (click)="id ? updateUnityParameter() : createUnityParameter()" [placeholder]="'global.save'"></app-button>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <h2>{{'unituParametersEdit.titleReport' | translate}}</h2>
      </ng-template>
      <div class="contextPage">
        <div class="mt-32 flex flex-column gap-form box">
          <div>
            <h2>{{'unituParametersEdit.imageHeader' | translate}}</h2>
            <file-prop [form]="formParameter.controls['header']" [formName]="formParameter.controls['header']"></file-prop>
          </div>
          <div>
            <h2>{{'unituParametersEdit.titleImagem' | translate}} </h2>
            <file-prop [form]="formParameter.controls['footer']" [formName]="formParameter.controls['footer']"></file-prop>
          </div>
        </div>
        <div class="mt-32 flex flex-center">
          <app-button (click)="id ? updateUnityParameter() : createUnityParameter()" [placeholder]="'global.save'"></app-button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>