import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AgreementComponent } from "./agreement/agreement.component";
import { SendMessageComponent } from "./agreement/send-message/send-message.component";
import { ExternalAccessComponent } from "./external-access.component";
import { CreateExternalRequisitionComponent } from "./agreement/create-requisition/create-requisition.component";
import { ExternalCustomerComponent } from "./customer/customer.component";
import { ManualExamsComponent } from "./agreement/manual-exams/manual-exams.component";
import { B2bIntegrationComponent } from "./agreement/b2b-integration/b2b-integration.component";
import { ViewExternalRequisitionComponent } from "./agreement/view-requisition/view-requisition.component";

const routes: Routes = [
   {
      path: "ExternoOld",
      children: [
         { path: "", redirectTo: "Login", pathMatch: "full" },
         {
            path: "Login",
            pathMatch: "full",
            component: ExternalAccessComponent
         },
         {
            path: "Cliente",
            pathMatch: "full",
            component: ExternalCustomerComponent
         },
         { path: "Convenio", pathMatch: "full", component: AgreementComponent },
         {
            path: "Convenio/Mensagens",
            pathMatch: "full",
            component: SendMessageComponent
         },
         {
            path: "Convenio/CriarRequisicao/:id",
            pathMatch: "full",
            component: CreateExternalRequisitionComponent
         },
         {
            path: "Convenio/CriarRequisicao",
            pathMatch: "full",
            component: CreateExternalRequisitionComponent
         },
         {
            path: "Convenio/ManualExames",
            pathMatch: "full",
            component: ManualExamsComponent
         },
         {
            path: "Convenio/IntegracaoB2B",
            pathMatch: "full",
            component: B2bIntegrationComponent
         },
         {
            path: "Convenio/VisualizarRequisicao/:id",
            pathMatch: "full",
            component: ViewExternalRequisitionComponent
         }
      ]
   }
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule]
})
export class ExternalAccessRoutingModule {}
