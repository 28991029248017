import { Location, formatCurrency } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
   UntypedFormControl,
   UntypedFormGroup,
   Validators
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Guid } from "@app/util/guid";
import { AgreementsService } from "@app/services/agreements/agreements.service";
import { PriceTableService } from "@app/services/pricetable/pricetable.service";
import { AgreementsParametersService } from "@app/services/agreement-parameters/agreement-parameters.service";
import { AgreementPlanService } from "@app/services/agreement-plan/agreement-plan.service";
import { AnalyteService } from "@app/services/analyte/analyte.service";
import { AgreementSpecialValueService } from "@app/services/agreement-special-value/agreement-special-value.service";
import { DoctorsService } from "@app/services/doctors/doctors.service";
import { AgreementsBlockedDoctors } from "@app/services/agreement-blocked-doctors/agreement-blocked-doctors.service";
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from "@angular/material/legacy-tabs";
import { GetActiveFilterPipe } from "@app/pipe/active.pipe";
import { AnalyteMaterialService } from "@app/services/analyte-material/analyte-material.service";
import { DefaultFormats, LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import {
   Actions,
   ResolvedAnalyte,
   ResolvedItem,
   ResolvedParameter,
   ResolvedDoctor,
   ResolvedAgreementSpecialValue,
   ResolvedAgreementPlanObj,
   resolveUnitys,
   resolveAgreementSpecialValue,
   resolveAgreementMessageParameters,
   resolveAgreementPlan,
   resolvePreAgreementPlan
} from "./save-agreement.model";
import { CheckboxSvgPipe } from "@app/pipe/checkbox-Svg.pipe";
import { UnityService } from "@app/services/shared/unity.service";
import { UnityNotAuthorizedService } from "@app/services/unity-not-authorized/unity-not-authorized.service";
import { Util } from "@app/util/util";
import { PriceTableExamsService } from "@app/services/price-table-exams/price-table-exams.service";
import { InputSearchComponent } from "@app/components/form/input-search/input-search.component";
import { CompanyService } from "@app/services/company/company.service";
import { CompanyOption } from "../../financial/bearer/bearer-list/bearer.model";
import {
   AgreementPostObject,
   addAgreementPlan
} from "@app/services/agreements/agreements.types";
import { EnumObject } from "@app/services/enum/enum.service.model";
import { ToastService } from "@app/services/translate/toast.service";

@Component({
   selector: "app-save-agreement",
   templateUrl: "./save-agreement.component.html",
   styleUrls: ["./save-agreement.component.scss"]
})
export class SaveAgreementComponent implements OnInit {
   @ViewChild("analyteIdForm") analyteIdForm!: InputSearchComponent;
   allParameters: Array<object> = [];
   totalPlan: number = 0;
   editId: string = "";
   requisitionId: string | null = "";
   actualPage: number = 0;
   agreementPlansPost: Array<addAgreementPlan> = [];
   resolvePreRegister: Array<object> = [];
   blockedUnities: Array<object> = [];
   //#region Global Variables / Variáveis Globais
   userChronos!: boolean;
   hasSaveData: boolean = false;
   title: string = "Editar Convênio";
   agreementName: string = "";
   priceTableExams: Array<Object> = [];
   priceTableExamsParams: { index: number; page: number; sumRecords: number } =
      { index: 5, page: 1, sumRecords: 0 };
   disable: boolean = false;
   agreementId: Guid | null = null;
   pageBlockedUnity: number = 1;
   indexBlockedUnity: number = 50;
   totalBlockedUnity: number = 0;
   page: number = 1;
   index: number = 50;
   total: number = 0;
   priceTableOptions: Array<{
      mask: any;
      index: number;
      label: string;
      value: string;
   }> = [];
   planOptions: Array<Object> = [];
   doctorOptions: Array<Object> = [];
   unityOptions: Array<Object> = [];
   analyteOptions: Array<{
      value: string;
      label: string;
      materials?: Array<any>;
      code?: string;
   }> = [];
   materialOptions: Array<{
      value: string;
      label: string;
      materials?: Array<any>;
   }> = [];
   blockedDoctors: Array<Object> = [];
   registerAgreementParameters: Array<Object> = [];
   parameterPage: number = 1;
   parameterIndex: number = 50;
   parameterTotal: number = 0;
   registerAgreementPlan: Array<Object> = [];
   registerAgreementSpecialValue: Array<Object> = [];
   companyOptions: Array<CompanyOption> = [];

   typeAgreementParameters = [
      {
         value: 1,
         label: "Permite assinalar exame para realização de emergencia"
      },
      {
         value: 2,
         label: "Permite assinalar exame para realização de urgencia"
      },
      {
         value: 3,
         label: "Permite visualizar resultados via web"
      },
      {
         value: 4,
         label: "Não Imprime laudo internamente, somente web"
      },
      {
         value: 5,
         label: "Bloqueia a impressão de laudo interno e web se estiver devedor de alguma fatura ou deve guia."
      },
      {
         value: 6,
         label: "Bloqueia a visualização de laudos para clientes deste convenio."
      },
      {
         value: 7,
         label: "Obrigatório Cpf para atendimento de clientes."
      },
      {
         value: 8,
         label: "Obrigatório Solicitante Válido."
      },
      {
         value: 9,
         label: "Obrigatório Responsável para menores de 18 anos."
      },
      {
         value: 10,
         label: "Bloqueia notificação de clientes do convenio quando do resultado pronto"
      },
      {
         value: 11,
         label: "Obrigatório número de guia de atendimento."
      },
      {
         value: 12,
         label: "Obrigatório número de carteirinha do paciente para atendimento."
      },
      {
         value: 13,
         label: "Fatura apenas depois do exame estar liberado."
      },
      {
         value: 14,
         label: "Emite mensagem com o código de validação no laudo."
      },
      {
         value: 15,
         label: "Utiliza laudo com certificado digital."
      },
      {
         value: 16,
         label: "Permite atender e ficar devendo guia."
      },
      {
         value: 17,
         label: "Permite efetuar desconto."
      },
      {
         value: 18,
         label: "Não calcular custo operacional."
      },
      {
         value: 19,
         label: "Detalha valores bruto, desconto e líquido no protocolo."
      },
      {
         value: 20,
         label: "Utiliza processo de conferência de guias para faturamento."
      },
      {
         value: 21,
         label: "Exames entram coletados e enviados ao laboratório."
      },
      {
         value: 22,
         label: "Exames entram coletados, triados e enviados ao laboratório."
      }
   ];

   typeInvoice: Array<EnumObject> = [];

   defaultIndex: number = 1;
   //#endregion

   //#region Forms / Formulários
   form = {
      externalId: new UntypedFormControl(null),
      description: new UntypedFormControl(null),
      index: new UntypedFormControl(this.defaultIndex.toFixed(4)),
      typeInvoice: new UntypedFormControl(1),
      priceTableId: new UntypedFormControl(null),
      priceTableIndex: new UntypedFormControl(null),
      priceTableMask: new UntypedFormControl(null),
      controlPlan: new UntypedFormControl(false),
      controlPlanId: new UntypedFormControl(null),
      controlPlanIndex: new UntypedFormControl(null),
      agreementParameter: new UntypedFormControl(null),
      information: new UntypedFormControl(null)
   };

   formExternalPerson = {
      personExternalId: new UntypedFormControl(null),
      personName: new UntypedFormControl(null),
      personCPF: new UntypedFormControl(null),
      personTelephone: new UntypedFormControl(null, [
         Validators.pattern(
            /^(?!.*(\d)\1{3,}.*(\d)\2{3,})\(([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])\) 3[0-9]{3}-[0-9]{4}$/
         )
      ]),
      personCellPhone: new UntypedFormControl(null, [
         Validators.required,
         Validators.pattern(
            /^(?!.*(\d)\1{3,}.*(\d)\2{3,})\(([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])\) 9 [0-9]{4}-[0-9]{4}$/
         )
      ]),
      personEmail: new UntypedFormControl(null, [Validators.email]),

      addressStreet: new UntypedFormControl(null),
      addressNumber: new UntypedFormControl(null),
      addressComplement: new UntypedFormControl(null),
      addressNeighborhood: new UntypedFormControl(null),
      addressCity: new UntypedFormControl(null),
      addressState: new UntypedFormControl(null),
      addressCountry: new UntypedFormControl(null),
      addressCEP: new UntypedFormControl(null),

      observation: new UntypedFormControl(null),
      fileDocumentId: new UntypedFormControl(null)
   };

   formAgreementPlan = {
      externalId: new UntypedFormControl(null),
      description: new UntypedFormControl(null),
      index: new UntypedFormControl(null),
      default: new UntypedFormControl(false),
      priceTableId: new UntypedFormControl(null),
      priceTableIndex: new UntypedFormControl(null)
   };

   formAgreementEditPlan = {
      externalId: new UntypedFormControl(null),
      description: new UntypedFormControl(null),
      index: new UntypedFormControl(null),
      default: new UntypedFormControl(false),
      priceTableId: new UntypedFormControl(null),
      priceTableIndex: new UntypedFormControl(null)
   };

   formAgreementSpecialValue = {
      isActive: new UntypedFormControl(false),
      analyteId: new UntypedFormControl(null),
      materialId: new UntypedFormControl(null),
      priceValue: new UntypedFormControl(null),
      code: new UntypedFormControl(null)
   };
   //#endregion

   formAgreementDoctors = {
      id: new UntypedFormControl(null)
   };

   formUnity = {
      id: new UntypedFormControl(null),
      companyId: new UntypedFormControl(null)
   };

   formPriceTableAnalyte = new UntypedFormGroup({
      isBlocked: new UntypedFormControl(""),
      isSuspended: new UntypedFormControl(""),
      analyteId: new UntypedFormControl(""),
      priceTableId: new UntypedFormControl(""),
      billingCode: new UntypedFormControl(""),
      price: new UntypedFormControl(""),
      code: new UntypedFormControl("")
   });

   filter = {
      AnalyteName: new UntypedFormControl(null),
      AnalyteCode: new UntypedFormControl(null),
      isActive: new UntypedFormControl()
   };

   ActionsExtra = {
      Situação: "Editar",
      Remover: "remove",
      Ativo: "PutActive"
   };

   ActionsExtraPriceTableExams = {
      Ativo: "PutActive"
   };

   maskCpfCnpj: string = "00000000000000";

   @ViewChild("dialogUpdateAgreementPlan")
   dialogUpdateAgreementPlan!: TemplateRef<any>;
   @ViewChild("dialogBlockDoctors") dialogBlockDoctors!: TemplateRef<any>;
   @ViewChild("dialogBlockUnity") dialogBlockUnity!: TemplateRef<any>;
   @ViewChild("dialogAgreementSpecialValue")
   dialogAgreementSpecialValue!: TemplateRef<any>;
   @ViewChild("dialogModalPriceTableExam")
   dialogAddExamToPriceTable!: TemplateRef<any>;

   @ViewChild("dialogModalParameter")
   dialogModalParameter!: TemplateRef<any>;

   parameterId!: string;

   hideParameters: Array<number> = [];

   additionalParameters: Array<{
      itens: any;
      sumRecords: number;
      index: number;
      page: number;
   }> = [
      {
         itens: [],
         sumRecords: 0,
         index: 50,
         page: 1
      }
   ];

   constructor(
      private _agreementService: AgreementsService,
      private _priceTableService: PriceTableService,
      private _priceTableExamsService: PriceTableExamsService,
      private _agreementParameterService: AgreementsParametersService,
      private _agreementPlanService: AgreementPlanService,
      private _dialog: MatDialog,
      private _route: ActivatedRoute,
      private _router: Router,
      private _location: Location,
      private _analyteService: AnalyteService,
      private _analyteMaterialService: AnalyteMaterialService,
      private _agreementSpecialValueService: AgreementSpecialValueService,
      private _doctorsService: DoctorsService,
      private _agreementblockeddoctors: AgreementsBlockedDoctors,
      private _activePipe: GetActiveFilterPipe,
      private _checkboxSvgPipe: CheckboxSvgPipe,
      private _localaDatePipe: LocalaDatePipe,
      private _unityService: UnityService,
      private _unityNotAuthorizedService: UnityNotAuthorizedService,
      private _util: Util,
      private _companyService: CompanyService,
      private _toastService: ToastService
   ) {
      if (_route.snapshot.params["id"] != "Novo") {
         let urlParams: string[] = _route.snapshot.params["id"].split("$");
         this.agreementId = new Guid(urlParams[0]);

         if (urlParams[1] == "View") {
            this.title = "Visualizar Convênio";
            this.disable = true;
         }
      } else {
         this.title = "Novo Convênio";
      }
   }

   async ngOnInit() {
      await this.getPriceTable();
      await this.getAgreementById();
      if (this.agreementId) {
         await this.getAgreementPlan();
      }
      this.cpfCnpj();
      this.typeInvoice = await this._util.getEnum("ETypeAgreements");
   }

   cpfCnpj() {
      this.formExternalPerson.personCPF.setValue(
         this.formExternalPerson.personCPF.value
            ?.replaceAll(".", "")
            .replaceAll("-", "")
            .replaceAll("/", "")
      );
      this.formExternalPerson.personCPF.value?.length < 12
         ? (this.maskCpfCnpj = "000.000.000-0000")
         : (this.maskCpfCnpj = "00.000.000/0000-00");
   }

   getPlanPriceTableIndex(item: ResolvedItem) {
      let priceTablePlan: any = this.priceTableOptions.find(
         (x: any) => x.value === item.priceTableId
      );
      return priceTablePlan!.index.toFixed(4);
   }

   getPlanPriceTable(item: ResolvedItem) {
      let priceTablePlan: any = this.priceTableOptions.find(
         (x: any) => x.value === item.priceTableId
      );
      return priceTablePlan!.label;
   }

   getDefaultPlan(item: ResolvedItem) {
      return item.default
         ? '<span class="span-styles" style="background: #07BF56; color: #FFFFFF;">Sim</span>'
         : '<span class="span-styles" style="background: #FF0000; color: #FFFFFF;">Não</span>';
   }

   getPlanIndex(item: ResolvedItem) {
      return isNaN(item.index) ? "" : item.index.toFixed(4);
   }

   getCode(item: ResolvedItem) {
      return item.code ?? "";
   }

   getCreatedDate(item: ResolvedItem) {
      if (item.createdAt) return this._localaDatePipe.transform(item.createdAt);
      return "";
   }

   getUpdatedDate(item: ResolvedItem) {
      if (item.updatedAt) return this._localaDatePipe.transform(item.updatedAt);
      return "";
   }

   getActive(item: ResolvedItem) {
      return this._activePipe.transform(item.isActive);
   }

   async getCep() {
      let cep: string = "";

      if (this.formExternalPerson.addressCEP.value) {
         let tmpCep = this.formExternalPerson.addressCEP.value;
         cep = tmpCep.replace(/\D/g, "");
      }

      if (cep != "") {
         let validacep: RegExp = /^[0-9]{8}$/;

         if (validacep.test(cep)) {
            this.formExternalPerson.addressStreet.setValue("...");
            this.formExternalPerson.addressNeighborhood.setValue("...");
            this.formExternalPerson.addressCity.setValue("...");
            this.formExternalPerson.addressState.setValue("...");

            await fetch(`https://viacep.com.br/ws/${cep}/json/`)
               .then((response) => {
                  return response.json();
               })
               .then((json) => {
                  if (!("erro" in json)) {
                     this.formExternalPerson.addressCEP.setValue(json.cep);
                     this.formExternalPerson.addressStreet.setValue(
                        json.logradouro
                     );
                     this.formExternalPerson.addressNeighborhood.setValue(
                        json.bairro
                     );
                     this.formExternalPerson.addressCity.setValue(
                        json.localidade
                     );
                     this.formExternalPerson.addressState.setValue(json.uf);
                     this.formExternalPerson.addressCountry.setValue("Brasil");
                  } else {
                     this.clearCepForm();
                     this._toastService.warningMessage(
                        "agreementsEditContact.msgNotCEP"
                     );
                  }
               });
         } else {
            this.clearCepForm();
            this._toastService.warningMessage(
               "agreementsEditContact.msgNotCEP"
            );
         }
      } else {
         this.clearCepForm();
      }
   }

   clearCepForm() {
      this.formExternalPerson.addressCEP.setValue("");
      this.formExternalPerson.addressStreet.setValue("");
      this.formExternalPerson.addressNeighborhood.setValue("");
      this.formExternalPerson.addressCity.setValue("");
      this.formExternalPerson.addressState.setValue("");
   }

   formatIndex(index: string) {
      let convertContent = parseFloat(index.replace(/,/, "."));
      this.form.index.setValue(convertContent ? convertContent.toFixed(4) : 0);
   }

   async updateAgreementParameter(
      isValid: boolean,
      agreementParameterId: Guid
   ) {
      let obj = {
         isActive: true,
         agreementId: this.agreementId?.toString(),
         isValid: isValid
      };

      await this._agreementParameterService
         .put(obj, agreementParameterId)
         .then((res) => {
            this.getAgreementParameters();
            this._toastService.fireSuccess(
               "agreementsEditParameters.msgPutParameters"
            );
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async openDialogBlockDoctors() {
      this.formAgreementDoctors.id.reset();
      const myTempDialog = this._dialog.open(this.dialogBlockDoctors, {
         width: "660px"
      });
   }

   async openDialogBlockUnity() {
      this.formUnity.id.reset();
      this.formUnity.companyId.reset();
      await this.getUnity();
      await this.getCompanies();
      const myTempDialog = this._dialog.open(this.dialogBlockUnity, {
         width: "660px"
      });
   }

   async openDialogCreateAgreementSpecialValue() {
      this.close();
      const myTempDialog = this._dialog.open(this.dialogAgreementSpecialValue, {
         width: "660px",
         data: {}
      });
      myTempDialog.afterClosed().subscribe((x) => {
         this.resetSpecialValueForms();
         this.analyteOptions = [];
      });
   }

   async openDialogUpdateAgreementSpecialValue(
      agreementSpecialValue: ResolvedAgreementSpecialValue
   ) {
      await this.getAnalyteMaterial(
         this.formAgreementSpecialValue.analyteId.value
      ).then((x) => {
         this.formAgreementSpecialValue.materialId.setValue(
            agreementSpecialValue._hide_analyteMaterialId
         );
      });
      const myTempDialog = this._dialog.open(this.dialogAgreementSpecialValue, {
         width: "660px",
         data: agreementSpecialValue
      });
      myTempDialog.afterClosed().subscribe((x) => {
         this.resetSpecialValueForms();
      });
   }

   openDialogUpdateAgreementPlan(agreementPlanObj: ResolvedAgreementPlanObj) {
      const myTempDialog = this._dialog.open(this.dialogUpdateAgreementPlan, {
         width: "660px",
         data: agreementPlanObj
      });
   }

   close() {
      this._dialog.closeAll();
   }

   analyteIdChanged() {
      const analyte = this.analyteOptions.find(
         (x: any) =>
            x.value == this.formPriceTableAnalyte.controls["analyteId"].value
      );
      if (analyte)
         this.formPriceTableAnalyte.controls["code"].setValue(analyte.code);
   }

   async getAnalytes() {
      this.analyteOptions = [];
      this._analyteService
         .getResume()
         .then((x) => {
            x.data.forEach((analyte: any) => {
               let analyteObject = {
                  value: analyte.id,
                  label: analyte.name,
                  materials: analyte.materiais
               };
               this.analyteOptions.push(analyteObject);
            });
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async getAgreementSpecialValue(paramPage?: number[]) {
      if (paramPage) {
         this.page = paramPage[0];
         this.index = paramPage[1];
      }

      const query = {
         agreementId: this.agreementId?.toString() ?? null,
         page: this.page,
         index: this.index
      };

      await this._agreementSpecialValueService
         .getPagination(query)
         .then((res) => {
            this.resolveSpecialValue(res.data, resolveAgreementSpecialValue);
            this.total = res.sumRecords;
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async deleteSpecialValue(agreementSpecialValueId: string) {
      await this._agreementSpecialValueService
         .delete(new Guid(agreementSpecialValueId))
         .then(async (res) => {
            this._toastService.fireConfirmation(
               "agreementsEditValueExam.msgDeleteSuccess"
            );
            await this.getAgreementSpecialValue();
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async updateSpecialValue(agreementSpecialValueId: string) {
      let obj = {
         agreementId: this.agreementId?.toString(),
         analyteId: this.formAgreementSpecialValue.analyteId.value,
         analyteMaterialId: this.formAgreementSpecialValue.materialId.value,
         value: parseFloat(this.formAgreementSpecialValue.priceValue.value)
      };

      await this._agreementSpecialValueService
         .put(obj, new Guid(agreementSpecialValueId))
         .then(async (res) => {
            this._toastService.fireSuccess(
               "agreementsEditValueExam.msgPutExam"
            );
            await this.getAgreementSpecialValue();
            this.close();
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   resetSpecialValueForms() {
      this.formAgreementSpecialValue.analyteId.reset();
      this.formAgreementSpecialValue.materialId.reset();
      this.formAgreementSpecialValue.priceValue.reset();
      this.materialOptions = [];
      this.formPriceTableAnalyte.reset();
   }

   async createSpecialValue() {
      let obj = {
         agreementId: this.agreementId?.toString(),
         analyteId: this.formAgreementSpecialValue.analyteId.value,
         analyteMaterialId: this.formAgreementSpecialValue.materialId.value,
         value: this.formAgreementSpecialValue.priceValue.value
      };

      await this._agreementSpecialValueService
         .post(obj)
         .then((res) => {
            this._toastService.fireSuccess(
               "agreementsEditValueExam.msgPostExam"
            );
            this._dialog.closeAll();
            this.getAgreementSpecialValue();
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async getAnalyteMaterial(analyteId: string) {
      if (analyteId) {
         this.materialOptions = [];
         await this._analyteMaterialService
            .getByPath(`AnalyteId=${analyteId}`, "")
            .then((res) => {
               res.data.forEach((material) => {
                  const materialObj = {
                     label: material.description,
                     value: material.id
                  };
                  this.materialOptions.push(materialObj);
               });
               this.materialOptions.length === 1
                  ? this.formAgreementSpecialValue.materialId.setValue(
                       this.materialOptions[0].value
                    )
                  : "";
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });
      }
   }

   async updateAgreementPlan(agreementPlanId: Guid, edit: Boolean = false) {
      let convertedIndex = 0;
      if (edit) {
         convertedIndex = parseFloat(
            this.formAgreementEditPlan.index.value.replace(/,/, ".")
         );
      } else {
         convertedIndex = parseFloat(
            this.formAgreementPlan.index.value.replace(/,/, ".")
         );
      }

      let obj = {
         isActive: true,
         agreementId: this.agreementId?.toString(),
         externalId: null,
         description: this.formAgreementPlan.description.value,
         index: convertedIndex,
         default: this.formAgreementPlan.default.value,
         priceTableId: this.formAgreementPlan.priceTableId.value,
         active: true
      };

      if (edit) {
         obj = {
            isActive: true,
            agreementId: this.agreementId?.toString(),
            externalId: null,
            description: this.formAgreementEditPlan.description.value,
            index: convertedIndex,
            default: this.formAgreementEditPlan.default.value,
            priceTableId: this.formAgreementEditPlan.priceTableId.value,
            active: true
         };
      }

      await this._agreementPlanService
         .put(obj, agreementPlanId)
         .then((res) => {
            setTimeout(async () => {
               await this.getAgreementPlan();
               this._toastService.fireSuccess("agreementsEdit.msgPutPlan");
               this.close();
            });
         })
         .catch((err) => {
            setTimeout(() => {
               this._toastService.fireError(err);
            });
         });
   }

   async priceTableExamActions(emit: Actions) {
      switch (emit.action) {
         case "Editar":
            this.openAddExamToPriceTableModal(emit.object.id);
            this.formPriceTableAnalyte.controls["code"].setValue(
               emit.object["agreementsEditValueExam.priceTableExamsParams.code"]
            );
            this.formAgreementSpecialValue.code.setValue(
               emit.object[
                  "agreementsEditValueExam.priceTableExamsParams.description"
               ]
            );
            this.formPriceTableAnalyte.controls["analyteId"].setValue(
               emit.object._hide_analyteId
            );
            this.formPriceTableAnalyte.controls["price"].setValue(
               emit.object._hide_value
            );
            this.formPriceTableAnalyte.controls["billingCode"].setValue(
               emit.object[
                  "agreementsEditValueExam.priceTableExamsParams.invoiceCode"
               ]
            );
            this.formPriceTableAnalyte.controls["isSuspended"].setValue(
               emit.object._hide_isSuspended
            );
            this.formPriceTableAnalyte.controls["isBlocked"].setValue(
               emit.object._hide_isBloqued
            );
            break;
         case "Excluir":
            setTimeout(() => {
               this._toastService
                  .fireConfirmation("agreementsEditValueExam.msgDelete")
                  .then(async (res) => {
                     if (res.isConfirmed) {
                        await this._priceTableExamsService.deleteByPath(
                           undefined,
                           `${emit.object["_hide_priceTableId"]}/exams/${emit.object.id}`
                        );
                        setTimeout(async () => {
                           this.getPriceTableExams();
                           this._toastService.fireSuccess(
                              "agreementsEditValueExam.msgDeleteSuccess"
                           );
                        });
                     }
                  });
            });
            break;
         case "PutActive":
            this.putActivePriceTableExam(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
      }
   }

   putActivePriceTableExam(priceTableExamId: string, value: string) {
      this._priceTableExamsService
         .patchActive(priceTableExamId, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getPriceTableExams();
         })
         .catch((err) => this._toastService.fireError(err));
   }

   openAddExamToPriceTableModal(id?: string) {
      this.editId = id ?? "";
      const priceTableName: any = this.priceTableOptions.find(
         (x: any) => x.value === this.form.priceTableId.value
      );
      this.close();
      if (!priceTableName) {
         this._toastService.warningMessage(
            "agreementsEditValueExam.msgInfoTable"
         );
         return;
      }
      const myTempDialog = this._dialog.open(this.dialogAddExamToPriceTable, {
         width: "660px",
         data: {
            priceTableName: priceTableName.label,
            analyteId: this.editId
         }
      });
      myTempDialog.afterClosed().subscribe((x) => {
         this.formPriceTableAnalyte.reset();
         this.analyteOptions = [];
         this.editId = "";
      });
   }

   addExamToPriceTable() {
      const payload = {
         priceTableId: this.form.priceTableId.value,
         analyteId: this.formPriceTableAnalyte.controls["analyteId"].value,
         billingCode: this.formPriceTableAnalyte.controls["billingCode"].value,
         price: this.formPriceTableAnalyte.controls["price"].value,
         isBloqued: this.formPriceTableAnalyte.controls["isBlocked"].value,
         isSuspended: this.formPriceTableAnalyte.controls["isSuspended"].value
      };
      if (!payload.analyteId)
         return this._toastService.warningMessage(
            "agreementsEditValueExam.msgInfoExam"
         ) as any;

      this._priceTableExamsService.post(payload).then((res) => {
         if (res.success) {
            this._toastService.fireSuccess(
               "agreementsEditValueExam.msgPostExam"
            );
            this.getPriceTableExams();
            this.close();
            this.formPriceTableAnalyte.reset();
         }
      });
   }

   putExamToPriceTable() {
      const payload = {
         priceTableId: this.form.priceTableId.value,
         analyteId: this.formPriceTableAnalyte.controls["analyteId"].value,
         billingCode: this.formPriceTableAnalyte.controls["billingCode"].value,
         price: this.formPriceTableAnalyte.controls["price"].value,
         isBloqued: this.formPriceTableAnalyte.controls["isBlocked"].value,
         isSuspended: this.formPriceTableAnalyte.controls["isSuspended"].value
      };

      this._priceTableExamsService
         .put(payload, new Guid(this.editId))
         .then((res) => {
            if (res.success) {
               this._toastService.fireSuccess(
                  "agreementsEditValueExam.msgPutExam"
               );
               this.getPriceTableExams();
               this.close();
            }
         });
   }

   async planActions(emit: Actions) {
      switch (emit.action) {
         case "Editar":
            emit.object["Plano Padrão"].includes("Sim")
               ? this.formAgreementEditPlan.default.setValue(true)
               : this.formAgreementEditPlan.default.setValue(false);
            this.formAgreementEditPlan.description.setValue(
               emit.object["Descrição"]
            );
            this.formAgreementEditPlan.index.setValue(
               emit.object["Índice do Plano"]
            );
            this.formAgreementEditPlan.priceTableId.setValue(
               emit.object["_hide_priceTableId"]
            );
            this.formAgreementEditPlan.priceTableIndex.setValue(
               emit.object["Índice da Tabela"]
            );
            this.openDialogUpdateAgreementPlan(emit.object);
            break;
         case "Excluir":
            setTimeout(() => {
               this._toastService
                  .fireConfirmation("agreementsEdit.msgDelete")
                  .then(async (res) => {
                     if (res.isConfirmed) {
                        await this._agreementPlanService.delete(emit.object.id);
                        setTimeout(async () => {
                           await this.getAgreementPlan();
                           this._toastService.fireSuccess(
                              "agreementsEdit.msgDeleteSuccess"
                           );
                        });
                     }
                  });
            });
            break;
         case "PutActive":
            this.putActiveplan(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
      }
   }

   putActiveplan(id: string, value: string) {
      this._agreementPlanService
         .patchActive(id, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getAgreementPlan();
         })
         .catch((err) => this._toastService.fireError(err));
   }

   async specialValueActions(emit: Actions) {
      switch (emit.action) {
         case "PutActive":
            this.putActiveSpecialValues(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
         case "Editar":
            this.formAgreementSpecialValue.analyteId.setValue(
               emit.object._hide_analyteId
            );
            this.formAgreementSpecialValue.code.setValue(
               emit.object[
                  "agreementsEditValueExam.resolveAgreementSpecialValue.name"
               ]
            );
            this.formAgreementSpecialValue.materialId.setValue(
               emit.object["Descrição do Material"]
            );
            this.formAgreementSpecialValue.priceValue.setValue(
               emit.object._hide_value
            );
            this.openDialogUpdateAgreementSpecialValue(emit.object);
            break;
         case "Excluir":
            setTimeout(() => {
               this._toastService
                  .fireConfirmation("agreementsEditValueExam.msgDelete")
                  .then(async (res) => {
                     if (res.isConfirmed) {
                        await this._agreementSpecialValueService.delete(
                           emit.object.id
                        );
                        setTimeout(async () => {
                           await this.getAgreementSpecialValue();
                           this._toastService.fireSuccess(
                              "agreementsEditValueExam.msgDeleteSuccess"
                           );
                        });
                     }
                  });
            });
            break;
      }
   }

   putActiveSpecialValues(id: string, value: string) {
      this._agreementSpecialValueService
         .patchActive(id, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getAgreementSpecialValue();
         })
         .catch((err) => this._toastService.fireError(err));
   }

   putActive(id: string, value: string) {
      this._agreementParameterService
         .patchActive(id, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getAgreementParameters();
         })
         .catch((err) => this._toastService.fireError(err));
   }

   async actions(emit: Actions) {
      switch (emit.action) {
         case "PutActive":
            this.putActive(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
         case "Editar":
            let parameterSituation: string = emit.object["Situação"];
            if (parameterSituation.includes("Ativo")) {
               setTimeout(async () => {
                  await this._toastService
                     .fireConfirmation("agreementsEditParameters.msgInactivate")
                     .then((res) => {
                        if (res.isConfirmed) {
                           this.updateAgreementParameter(false, emit.object.id);
                        }
                     });
               });
            } else {
               setTimeout(async () => {
                  await this._toastService
                     .fireConfirmation("agreementsEditParameters.msgActive")
                     .then((res) => {
                        if (res.isConfirmed) {
                           this.updateAgreementParameter(true, emit.object.id);
                        }
                     });
               });
            }
            break;
      }
   }

   async removeAgreementParameter(agreementParameterId: string) {
      await this._agreementParameterService
         .deleteByPath(undefined, `${this.agreementId}/${agreementParameterId}`)
         .then(async (res) => {
            await this.getAgreementParameters();
            this._toastService.fireSuccess(
               "agreementsEditParameters.msgDeleteParametersSuccess"
            );
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   selectPriceTable(priceTableId: Guid, control: UntypedFormControl) {
      const priceTable = this.priceTableOptions.find(
         (x: any) => x.value === priceTableId
      );
      if (!priceTableId || !priceTable) return;

      control.setValue(priceTable.index.toFixed(4));

      this.priceTableExamsParams.page = 1;
      this.priceTableExamsParams.index = 5;

      this.getPriceTableExams();
   }

   back() {
      this._location.back();
   }

   resolveAgreementParameters(data: any, columns: any) {
      this.registerAgreementParameters = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registerAgreementParameters.push(obj);
      });
   }

   resolveAgreementPlans(data: any, columns: any) {
      this.registerAgreementPlan = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registerAgreementPlan.push(obj);
      });
   }

   resolveUnity(data: any, columns: any) {
      this.blockedUnities = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] = this._util.formatField(
                  x,
                  y.fieldName,
                  y.type,
                  y.interpolationFields,
                  y.dateFormat,
                  y.options
               );
            } else {
               obj[y.label] = x[y.retrive.toString()];
            }
         });
         this.blockedUnities.push(obj);
      });
   }

   resolveSpecialValue(data: any, columns: any) {
      this.registerAgreementSpecialValue = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registerAgreementSpecialValue.push(obj);
      });
   }

   getParameterType(item: ResolvedParameter) {
      return this.typeAgreementParameters[item.typeParameter - 1].label;
   }

   getStatus(item: ResolvedParameter) {
      return !item.isValid
         ? "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Inativo</span>"
         : "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Ativo</span>";
   }

   async getAgreementParameters(paramPage?: number[]) {
      if (paramPage) {
         this.parameterPage = paramPage[0];
         this.parameterIndex = paramPage[1];
      }

      const queryObject = {
         isActive: true,
         page: this.parameterPage,
         numberRegistry: this.parameterIndex,
         agreementId: this.agreementId ? this.agreementId.toString() : null
      };

      await this._agreementParameterService
         .getPagination<AgreementsParametersService>(queryObject)
         .then((res) => {
            this.parameterTotal = res.sumRecords;
            this.allParameters = res.data;
            this.resolveAgreementParameters(
               res.data,
               resolveAgreementMessageParameters
            );
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async matTabChange(event: MatTabChangeEvent) {
      if (event.index) {
         switch (event.index) {
            case 2:
               await this.getAgreementParameters();
               this.getAdditionalParameter(this.parameterId);
               break;
            case 3:
               this.getPriceTableExams();
               this.getAgreementSpecialValue();
               break;
         }
      }
   }

   async getExamsOption(event: any) {
      const query = {
         resume: true,
         search: event
      };
      this.formPriceTableAnalyte.controls["code"].reset();
      await this._analyteService.getPagination(query).then((res) => {
         this.analyteOptions = res.data.map((data: any) => ({
            label: data.name,
            value: data.id,
            code: data.code
         }));
         if (this.analyteOptions.length == 1)
            this.formPriceTableAnalyte.controls["analyteId"].setValue(
               this.analyteOptions[0].value
            );
      });
   }

   async analyteCodeChanged() {
      const codeControl = this.formPriceTableAnalyte.controls["code"];
      let codeValue = codeControl.value || "";
      codeControl.setValue(codeValue.toUpperCase());

      const query = {
         resume: true,
         search: codeValue.toUpperCase()
      };

      if (query.search.length <= 1) return;
      await this._analyteService.getPagination(query).then((res) => {
         this.analyteOptions = res.data.map((exam: any) => ({
            label: exam.name,
            value: exam.id,
            code: exam.code
         }));
         const analyte = this.analyteOptions.find(
            (analyte) => analyte.code === codeValue.toUpperCase()
         );

         if (analyte) {
            return this.formPriceTableAnalyte.controls["analyteId"].setValue(
               analyte.value
            );
         }

         if (this.analyteOptions.length === 1) {
            return this.formPriceTableAnalyte.controls["analyteId"].setValue(
               this.analyteOptions[0].value
            );
         }
      });
   }

   setFocus() {
      this.analyteIdForm.focusInput();
   }

   getPriceTableExams(paramPage?: number[]) {
      if (paramPage) {
         this.priceTableExamsParams.page = paramPage[0];
         this.priceTableExamsParams.index = paramPage[1];
      }

      const priceTableId = this.form.priceTableId.value;
      if (!priceTableId) return;

      const queryParams = {
         priceTableId,
         page: this.priceTableExamsParams.page,
         index: this.priceTableExamsParams.index,
         AnalyteName: this.filter.AnalyteName.value ?? null,
         AnalyteCode: this.filter.AnalyteCode.value ?? null,
         isActive: this.filter.isActive.value ?? null
      };

      this._priceTableExamsService
         .getPagination(queryParams)
         .then((res: any) => {
            const data = res.data;
            this.priceTableExamsParams.sumRecords = res.sumRecords;
            this.priceTableExams = data.map((exam: any) => ({
               id: exam.id,
               ["agreementsEditValueExam.priceTableExamsParams.code"]:
                  exam.code,
               ["agreementsEditValueExam.priceTableExamsParams.description"]:
                  exam.name,
               ["agreementsEditValueExam.priceTableExamsParams.price"]:
                  formatCurrency(exam.price ?? 0, "pt-BR", "R$"),
               ["agreementsEditValueExam.priceTableExamsParams.invoiceCode"]:
                  exam.invoiceCode ?? "",
               ["agreementsEditValueExam.priceTableExamsParams.isBloqued"]:
                  exam.isBloqued
                     ? "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Sim</span>"
                     : "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Não</span>",
               ["agreementsEditValueExam.priceTableExamsParams.isSuspended"]:
                  exam.isSuspended
                     ? "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Sim</span>"
                     : "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Não</span>",
               ["Ativo"]: exam.isActive ? "checkbox|true" : "checkbox|",
               ["_hide_priceTableId"]: priceTableId,
               ["_hide_value"]: exam.price,
               ["_hide_isBloqued"]: exam.isBloqued,
               ["_hide_isSuspended"]: exam.isSuspended,
               ["_hide_analyteId"]: exam.analyteId
            }));
         });
   }

   resetFilter() {
      this.filter.AnalyteName.reset();
      this.filter.AnalyteCode.reset();
      this.filter.isActive.reset();
   }

   async getPriceTable() {
      await this._priceTableService.getPagination().then((res: any) => {
         res.data.forEach((priceTable: any) => {
            let obj = {
               value: priceTable.id,
               label: priceTable.description,
               index: priceTable.index,
               mask: priceTable.mask,
               isActive: priceTable.isActive
            };

            this.priceTableOptions.push(obj);
         });
      });
   }

   async getAgreementById() {
      if (
         this.agreementId != null &&
         this.agreementId != new Guid(Guid.getEmpty())
      ) {
         await this._agreementService
            .getById(this.agreementId)
            .then(async (res) => {
               this.form.information.setValue(res.data[0].information);
               this.form.externalId.setValue(res.data[0].externalId);
               this.form.description.setValue(res.data[0].description);
               this.agreementName = `${res.data[0].description}`;
               this.form.index.setValue(res.data[0].index.toFixed(4));
               this.form.priceTableId.setValue(res.data[0].priceTableId);
               this.form.typeInvoice.setValue(res.data[0].typeInvoice);
               this.form.controlPlan.setValue(res.data[0].controlPlan);

               this.form.priceTableIndex.setValue(
                  res.data[0].priceTableIndex.toFixed(4)
               );
               this.form.priceTableMask.setValue(res.data[0].mask);

               this.resolveBlockedDoctors(res.data[0].agreementBlockedDoctors);

               this.formExternalPerson.personName.setValue(res.data[0].name);
               this.formExternalPerson.personCPF.setValue(res.data[0].cpf);
               this.formExternalPerson.personEmail.setValue(res.data[0].email);
               this.formExternalPerson.personTelephone.setValue(
                  res.data[0].telephone
               );
               this.formExternalPerson.personCellPhone.setValue(
                  res.data[0].cellPhone
               );

               const address = res.data[0].address;

               if (address) {
                  this.formExternalPerson.addressCEP.setValue(address.zipCode);
                  this.formExternalPerson.addressStreet.setValue(
                     address.street
                  );
                  this.formExternalPerson.addressNumber.setValue(
                     address.number
                  );
                  this.formExternalPerson.addressComplement.setValue(
                     address.complement
                  );
                  this.formExternalPerson.addressNeighborhood.setValue(
                     address.neighborhood
                  );
                  this.formExternalPerson.addressCity.setValue(address.city);
                  this.formExternalPerson.addressCountry.setValue("Brasil");
                  this.formExternalPerson.addressState.setValue(address.state);
               }

               this.resolveAgreementPlans(
                  res.data[0].agreementPlan,
                  resolveAgreementPlan
               );
            });
      }
   }

   async getBlockedUnity(paramPage?: number[]) {
      this.blockedUnities = [];
      if (
         this.agreementId != null &&
         this.agreementId != new Guid(Guid.getEmpty())
      ) {
         this.pageBlockedUnity = 1;

         if (paramPage) {
            this.pageBlockedUnity = paramPage[0];
            this.indexBlockedUnity = paramPage[1];
         }

         await this._unityNotAuthorizedService
            .get(
               `?AgreementId=${this.agreementId}&page=${this.pageBlockedUnity}&index=${this.indexBlockedUnity}`
            )
            .then((res: any) => {
               if (res.data.length != 0) {
                  this.totalBlockedUnity = res.sumRecords;
                  this.resolveUnity(res.data, resolveUnitys);
               }
            });
      }
   }

   async editAgreementPlan() {
      let obj = {
         controlPlan: true,
         isActive: true,
         agreementId: this.agreementId?.toString(),
         externalId: null,
         description: this.formAgreementPlan.description.value,
         index: parseFloat(this.formAgreementPlan.index.value),
         default: this.formAgreementPlan.default.value,
         priceTableId: this.formAgreementPlan.priceTableId.value
      };

      await this._agreementPlanService
         .post(obj)
         .then((res) => {
            setTimeout(async () => {
               await this.getAgreementPlan();
               this._toastService.fireSuccess("agreementsEdit.msgPostPlan");
               this.close();
               this.resetPlanFields();
            });
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   resetPlanFields() {
      this.formAgreementPlan.default.setValue(false);
      this.formAgreementPlan.description.setValue("");
      this.formAgreementPlan.index.setValue("");
      this.formAgreementPlan.priceTableId.setValue("");
      this.formAgreementPlan.priceTableIndex.setValue("");

      this.formAgreementEditPlan.default.setValue(false);
      this.formAgreementEditPlan.description.setValue("");
      this.formAgreementEditPlan.index.setValue("");
      this.formAgreementEditPlan.priceTableId.setValue("");
      this.formAgreementEditPlan.priceTableIndex.setValue("");
   }

   getValue(item: ResolvedAnalyte) {
      return formatCurrency(item.value > 0 ? item.value : 0, "pt-BR", "");
   }

   async getAgreementPlan() {
      let getParams = `AgreementId=${this.agreementId}&page=${this.page}&numberRegistry=${this.index}`;

      await this._agreementPlanService
         .get(getParams)
         .then((res) => {
            this.resolveAgreementPlans(res.data, resolveAgreementPlan);
            this.totalPlan = res.sumRecords;
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async createAgreement() {
      this.requisitionId =
         this._route.snapshot.queryParamMap.get("Requisition");
      this.formExternalPerson.addressCEP.value != null
         ? this.formExternalPerson.addressCEP.setValue(
              this.formExternalPerson.addressCEP.value
           )
         : null;

      const address: any = {
         zipCode: this.formExternalPerson.addressCEP.value,
         street: this.formExternalPerson.addressStreet.value,
         number: this.formExternalPerson.addressNumber.value,
         complement: this.formExternalPerson.addressComplement.value,
         neighborhood: this.formExternalPerson.addressNeighborhood.value,
         city: this.formExternalPerson.addressCity.value,
         state: this.formExternalPerson.addressState.value,
         country: this.formExternalPerson.addressCountry.value
      };

      const createAgreementObject: AgreementPostObject = {
         externalId: this.form.externalId.value,
         description: this.form.description.value
            ? this.form.description.value
            : "",
         index: parseFloat(this.form.index.value),
         priceTableId: this.form.priceTableId.value,
         typeInvoice: parseFloat(this.form.typeInvoice.value),
         controlPlan: this.form.controlPlan.value
            ? this.form.controlPlan.value
            : false,
         information: this.form.information.value,
         addAgreementPlan: this.agreementPlansPost,
         personName: this.formExternalPerson.personName.value
            ? this.formExternalPerson.personName.value
            : "",
         personCPF: {
            value: this.formExternalPerson.personCPF.value
               ? this.formExternalPerson.personCPF.value
               : ""
         },
         personEmail: this.formExternalPerson.personEmail.value
            ? this.formExternalPerson.personEmail.value
            : "",
         personTelephone: this.formExternalPerson.personTelephone.value
            ? this.formExternalPerson.personTelephone.value
            : "",
         personCellPhone: this.formExternalPerson.personCellPhone.value
            ? this.formExternalPerson.personCellPhone.value
            : "",
         personAddress: address,
         personExternalId: this.formExternalPerson.personExternalId.value
            ? this.formExternalPerson.personExternalId.value
            : "",
         informationInvoice: ""
      };

      if (this.form.priceTableId.value != null) {
         await this._agreementService
            .post(createAgreementObject)
            .then((response) => {
               setTimeout(() => {
                  this._toastService.fireSuccess(
                     "agreementsEdit.msgPostAgreement"
                  );
                  if (this.requisitionId) {
                     let object = JSON.parse(
                        //@ts-ignore
                        localStorage.getItem("FormStorageAgreement")
                     );
                     object.agreement = response.id;
                     localStorage.setItem(
                        "FormStorageAgreement",
                        JSON.stringify(object)
                     );
                     this._router.navigateByUrl(
                        "MovimentacaoOld/Recepcao/Requisicoes/" +
                           this.requisitionId +
                           "$P$NP"
                     );
                  } else {
                     this.back();
                  }
               });
            });
      } else {
         this._toastService.warningMessage(
            "agreementsEditValueExam.msgInfoTable"
         );
      }
   }

   async updateAgreement() {
      this.formExternalPerson.addressCEP.value != null
         ? this.formExternalPerson.addressCEP.setValue(
              this.formExternalPerson.addressCEP
                 .value /*.replaceAll('-' , '') */
           )
         : null;

      const address: any = {
         zipCode: this.formExternalPerson.addressCEP.value,
         street: this.formExternalPerson.addressStreet.value,
         number: this.formExternalPerson.addressNumber.value,
         complement: this.formExternalPerson.addressComplement.value,
         neighborhood: this.formExternalPerson.addressNeighborhood.value,
         city: this.formExternalPerson.addressCity.value,
         state: this.formExternalPerson.addressState.value,
         country: this.formExternalPerson.addressCountry.value
      };

      let agreement = {
         externalId: this.form.externalId.value,
         description: this.form.description.value,
         index: parseFloat(this.form.index.value),
         priceTableId: this.form.priceTableId.value,
         typeInvoice: parseInt(this.form.typeInvoice.value),
         controlPlan: this.form.controlPlan.value,
         information: this.form.information.value,
         fileDocumentId: this.formExternalPerson.fileDocumentId.value,
         personExternalId: this.formExternalPerson.personExternalId.value
            ? this.formExternalPerson.personExternalId.value
            : "",
         personName: this.formExternalPerson.personName.value,
         personCPF: {
            value: this.formExternalPerson.personCPF.value
               ? this.formExternalPerson.personCPF.value
               : ""
         },
         personEmail: this.formExternalPerson.personEmail.value,
         personTelephone: this.formExternalPerson.personTelephone.value,
         personCellPhone: this.formExternalPerson.personCellPhone.value,
         address: address
      };
      if (this.agreementId) {
         await this._agreementService
            .put(agreement, this.agreementId)
            .then((res: any) => {
               setTimeout(() => {
                  this._toastService.fireSuccess(
                     "agreementsEdit.msgPutAgreement"
                  );
                  this._router.navigateByUrl("Cadastros/Convênios/Convênio");
               });
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });
      }
   }

   resolvePreRegisterFunc(data: any) {
      const columns = resolvePreAgreementPlan;
      this.resolvePreRegister = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.resolvePreRegister.push(obj);
      });
   }

   plansPreRegister() {
      if (!this.formAgreementPlan.description.value) {
         this._toastService.warningMessage("agreementsEdit.msgInfoPlan");
         return;
      }

      if (!this.formAgreementPlan.priceTableId.value) {
         this._toastService.warningMessage("agreementsEdit.msgInfoTable");
         return;
      }

      let agreementPlan: addAgreementPlan = {
         externalId: "",
         description: this.formAgreementPlan.description.value,
         index: parseFloat(this.formAgreementPlan.index.value),
         default: this.formAgreementPlan.default.value,
         priceTableId: this.formAgreementPlan.priceTableId.value
      };

      this.agreementPlansPost.push(agreementPlan);
      this.resolvePreRegisterFunc(this.agreementPlansPost);
      this.resetPlanFields();
   }

   async actionsPreRegister(emit: Actions) {
      switch (emit.action) {
         case "Excluir":
            this.agreementPlansPost.splice(emit.object.description, 1);
            this.resolvePreRegisterFunc(this.agreementPlansPost);
            break;
      }
   }

   resolveBlockedDoctors(data: any) {
      this.blockedDoctors = data.map((doctor: any) => ({
         id: doctor.id,
         "agreementsEditParameters.resolveBlockedDoctors.name":
            doctor.name.toUpperCase(),
         "agreementsEditParameters.resolveBlockedDoctors.council": `${doctor.council}/${doctor.registrationState}:${doctor.registrationNumber}`,
         "agreementsEditParameters.resolveBlockedDoctors.officePhone":
            doctor.officePhone ?? "",
         "agreementsEditParameters.resolveBlockedDoctors.email":
            doctor.email ?? "",
         "agreementsEditParameters.resolveBlockedDoctors.createdAt":
            this._localaDatePipe.transform(doctor.createdAt),
         "agreementsEditParameters.resolveBlockedDoctors.updatedAt":
            this._localaDatePipe.transform(doctor.updatedAt),
         Ativo: doctor.isActive ? "checkbox|true" : "checkbox|"
      }));
   }

   async getDoctors(search: string) {
      await this._doctorsService
         .getPagination({ name: search })
         .then((res: any) => {
            this.doctorOptions = res.data.map((doctor: any) => ({
               value: doctor.id,
               label: `${doctor.name} - ${doctor.council}/${doctor.registrationState}: ${doctor.registrationNumber}`,
               registrationNumber: doctor.registrationNumber
            }));
         });
   }

   async getUnity() {
      this.unityOptions = [];
      const value = this.formUnity.companyId.value;
      if (!value) {
         return;
      }
      await this._unityService
         .getByPath("", `?page=1&index=999999&companyId=${value}`)
         .then((res: any) => {
            if (res.data.length != 0) {
               res.data.forEach((element: any) => {
                  this.unityOptions.push({
                     label: element.name,
                     value: element.id
                  });
               });
            } else {
               this._toastService.warningMessage(
                  "agreementsEditParameters.msgInfoUnit"
               );
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async createBlockedDoctor() {
      var blockDoctor = {
         doctorId: this.formAgreementDoctors.id.value,
         agreementId: (this.agreementId as any).guid
      };
      await this._agreementblockeddoctors
         .post(blockDoctor)
         .then((res) => {
            setTimeout(() => {
               this._toastService.fireSuccess(
                  "agreementsEditParameters.msgBloeckedSuccess"
               );
               this._dialog.closeAll();
               this.getAgreementById();
            });
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async getCompanies() {
      await this._companyService.getResume().then((response) => {
         if (response.success) {
            this.companyOptions = response.data.map((company) => ({
               value: company.id,
               label: company.socialReason
            }));
         }
      });
   }

   async createBlockedUnity() {
      var blockUnity = {
         unityId: this.formUnity.id.value,
         agreementId: (this.agreementId as any).guid,
         companyId: this.formUnity.companyId.value
      };
      await this._unityNotAuthorizedService
         .post(blockUnity)
         .then(() => {
            setTimeout(() => {
               this._toastService.fireSuccess(
                  "agreementsEditParameters.msgBloeckedUnitSuccess"
               );
               this._dialog.closeAll();
               this.getBlockedUnity();
            });
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   officePhone(item: ResolvedDoctor) {
      return item.officePhone ? item.officePhone : "()";
   }

   getCrm(item: ResolvedDoctor) {
      return (
         item.council +
         " - " +
         item.registrationNumber +
         "/" +
         item.registrationState
      );
   }

   returnDelete() {
      return '<svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.01111 19.0333C2.01111 19.8556 2.67778 20.5 3.5 20.5H13.5222C14.3444 20.5 15.0111 19.8556 15.0111 19.0333L15.5889 6.94444H1.45556L2.01111 19.0333ZM11.1667 9.36667C11.1667 8.94444 11.4111 8.61111 11.8333 8.61111C12.2556 8.61111 12.5 8.94444 12.5 9.36667V17.7222C12.5 18.1444 12.2556 18.4778 11.8333 18.4778C11.4111 18.4778 11.1667 18.1444 11.1667 17.7222V9.36667ZM7.83333 9.36667C7.83333 8.94444 8.07778 8.61111 8.5 8.61111C8.92222 8.61111 9.16667 8.94444 9.16667 9.36667V17.7222C9.16667 18.1444 8.92222 18.4778 8.5 18.4778C8.07778 18.4778 7.83333 18.1444 7.83333 17.7222V9.36667ZM4.5 9.36667C4.5 8.94444 4.74444 8.61111 5.16667 8.61111C5.58889 8.61111 5.83333 8.94444 5.83333 9.36667V17.7222C5.83333 18.1444 5.58889 18.4778 5.16667 18.4778C4.74444 18.4778 4.5 18.1444 4.5 17.7222V9.36667Z" fill="#FE7434"/><path d="M15.8556 3.16667H11.8333V1.18889C11.8333 0.788889 11.6333 0.5 11.2111 0.5H5.83333C5.41111 0.5 5.16667 0.788889 5.16667 1.18889V3.16667H1.18889C0.766667 3.16667 0.5 3.47778 0.5 3.9V4.74444C0.5 5.16667 0.766667 5.61111 1.18889 5.61111H15.8778C16.3 5.61111 16.5222 5.14444 16.5222 4.74444V3.9C16.5 3.47778 16.2778 3.16667 15.8556 3.16667ZM10.5 3.16667H6.5V1.83333H10.5V3.16667Z" fill="#FE7434"/></svg>';
   }

   async Actions(action: Actions) {
      switch (action.action) {
         case "Excluir":
            this._toastService
               .fireConfirmation("agreementsEditParameters.msgDeleteBloecked")
               .then(async (res) => {
                  if (res.isConfirmed) {
                     await this._agreementblockeddoctors.delete(
                        action.object.id
                     );
                     this._toastService.fireSuccess(
                        "agreementsEditParameters.msgDeleteBloeckedSuccess"
                     );
                     await this.getAgreementById();
                  }
               })
               .catch((error) => {
                  this._toastService.fireError(error);
               });
            break;
         case "PutActive":
            this.putActiveAgreementBloked(
               action.object.id,
               action.object.Ativo
            );
            break;
      }
   }

   async ActionsParameters(action: Actions) {
      switch (action.action) {
         case "Excluir":
            this._toastService
               .fireConfirmation("agreementsEditParameters.msgDeleteParameters")
               .then(async (res) => {
                  if (res.isConfirmed) {
                     await this._agreementParameterService.deleteByPath(
                        undefined,
                        this.agreementId + "/" + action.object.id
                     );
                     this._toastService.fireSuccess(
                        "agreementsEditParameters.msgDeleteParametersSuccess"
                     );
                     await this.getAgreementParameters();
                     await this.getAdditionalParameter(this.parameterId);
                  }
               })
               .catch((error) => {
                  this._toastService.fireError(error);
               });
            break;
         case "PutActive":
            this.putActive(
               action.object.id,
               this._checkboxSvgPipe.transform(action.object.Ativo)
            );
            break;
      }
   }

   async ActionsUnity(action: Actions) {
      switch (action.action) {
         case "Excluir":
            this._toastService
               .fireConfirmation("agreementsEditParameters.msgDelete")
               .then(async (res) => {
                  if (res.isConfirmed) {
                     await this._unityNotAuthorizedService.delete(
                        action.object.id
                     );
                     this._toastService.fireSuccess(
                        "agreementsEditParameters.msgDeleteSuccess"
                     );
                     await this.getBlockedUnity();
                  }
               })
               .catch((error) => {
                  this._toastService.fireError(error);
               });
            break;
         case "PutActive":
            this.putActiveUnityBlocked(action.object.id, action.object.Ativo);
            break;
      }
   }

   putActiveAgreementBloked(id: string, value: string) {
      this._agreementblockeddoctors
         .patchActive(id, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getAgreementById();
         })
         .catch((err) => this._toastService.fireError(err));
   }

   putActiveUnityBlocked(id: string, value: string) {
      this._unityNotAuthorizedService
         .patchActive(id, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getBlockedUnity();
         })
         .catch((err) => this._toastService.fireError(err));
   }

   async changePage(changeEvent: MatTabChangeEvent) {
      if (changeEvent.index) {
         this.actualPage = changeEvent.index;
         if (changeEvent.index == 1) {
            this.getBlockedUnity();
         }
      }
   }

   openDialogModalParameter(parameterId: string) {
      if (this.hideParameters.length == this.typeAgreementParameters.length) {
         this._toastService.warningMessage(
            "agreementsEditParameters.msgAllParametersAdded"
         );
         return;
      }
      this.parameterId = parameterId;
      const myTempDialog = this._dialog.open(this.dialogModalParameter, {
         width: "800px"
      });
      myTempDialog.afterClosed().subscribe((res) => {
         this.parameterId = "";
         this.form.agreementParameter.reset();
      });
   }

   includeAgreementParameter() {
      const obj = {
         agreementId: this.agreementId?.toString(),
         agreementParameters: []
      };
      this.form.agreementParameter.value.forEach((element: number) => {
         (obj.agreementParameters as any).push({ typeParameter: element });
      });
      this._agreementParameterService
         .post(obj)
         .then(async (res) => {
            this._toastService.fireSuccess(
               "agreementsEditParameters.msgPostParameters"
            );
            this.close();
            await this.getAgreementParameters();
            this.getAdditionalParameter(this.parameterId);
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   closeModal() {
      this._dialog.closeAll();
   }

   getAdditionalParameter(parameterId: string) {
      this.hideParameters = this.allParameters.map((x: any) => x.typeParameter);

      this.additionalParameters[parameterId as any] = {
         itens: [],
         sumRecords: 0,
         index: 50,
         page: 1
      };
      if (this.allParameters.length > 0) {
         this.additionalParameters[parameterId as any] = {
            itens: this.resolveAgreementParameters(
               this.allParameters,
               resolveAgreementMessageParameters
            ),
            sumRecords: this.parameterTotal,
            index: 50,
            page: 1
         };
      }
   }
}
