<div class="contentPage">
  <div class="flex gap-2 w-100 align-center headerResponsive">
    <app-button (click)="back()" [placeholder]="'global.back'" [class]="'btn-reverse'"></app-button>
    <h1>{{'agreementsEdit.title' | translate }}{{agreementName ? ':' : ''}} <b *ngIf="agreementName" class="orangeFont">{{agreementName}}</b></h1>
  </div>
  <mat-tab-group class="mt-32" (selectedTabChange)="matTabChange($event)">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex align-center gap-1">
          <h2>{{'agreementsEdit.titleData' | translate}}</h2>
        </div>
      </ng-template>
      <div class="flex flex-center w-100">
        <div class="data-content" [ngClass]="disable? 'readonly':''">
          <div class="column">
            <div class="box">
              <div class="w-100 flex flex-between align-center">
                <h2>{{'agreementsEdit.titleAgreement' | translate}}</h2>
                <div class="flex gap-2">
                  <checkbox-prop [gap]="1" [label]="'agreementsEdit.controlPlan'" [form]="form.controlPlan"></checkbox-prop>
                </div>
              </div>
              <div class="flex mt-32 gap-form">
                <div class="input-code">
                  <input-prop [placeholder]="'agreementsEdit.code'" [form]="form.externalId"></input-prop>
                </div>
                <div class="w-100">
                  <input-prop [placeholder]="'agreementsEdit.description'" [form]="form.description"></input-prop>
                </div>
                <div class="w-100 mt-moblie-register">
                  <select-prop [placeholder]="'agreementsEdit.type'" [form]="form.typeInvoice" [options]="typeInvoice"></select-prop>
                </div>
                <div class="w-100 mt-moblie-register">
                  <input-prop [placeholder]="'agreementsEdit.index'" (change)="formatIndex(form.index.value)" [form]="form.index"></input-prop>
                </div>
              </div>
              <div class="mt-32">
                <textarea-prop [placeholder]="'agreementsEdit.information'" [form]="form.information"></textarea-prop>
              </div>
            </div>

            <div *ngIf="form.controlPlan.value" class="mt-32 box">
              <div class="flex flex-between w-100 align-center">
                <h2>{{'agreementsEdit.titlePlan' | translate}}</h2>
              </div>
              <div class="column">
                <div class="mt-32 w-100 flex flex-between align-center">
                  <h2>{{'agreementsEdit.titleDataPlan' | translate}}</h2>
                  <checkbox-prop [gap]="1"  [label]="'agreementsEdit.default'" [form]="formAgreementPlan.default"></checkbox-prop>
                </div>
                <div class="flex mt-24 gap-form">
                  <div class="w-100">
                    <input-prop [placeholder]="'agreementsEdit.descriptionPlan'" [form]="formAgreementPlan.description"></input-prop>
                  </div>
                  <div class="w-100">
                    <input-prop [placeholder]="'agreementsEdit.indexPlan'" [form]="formAgreementPlan.index"></input-prop>
                  </div>
                </div>
                <h2 class="mt-32">{{'agreementsEdit.titleTable' | translate}}</h2>
                <div class="flex gap-form">
                  <div class="w-100 mt-32">
                    <select-prop [placeholder]="'agreementsEdit.priceTable'" [form]="formAgreementPlan.priceTableId" [options]="priceTableOptions" (changeValue)="selectPriceTable(formAgreementPlan.priceTableId.value, formAgreementPlan.priceTableIndex)"></select-prop>
                  </div>
                  <div class="w-100 mt-32">
                    <input-prop class="readonly" [readonly]="true" [placeholder]="'agreementsEdit.priceTableIndex'" [form]="formAgreementPlan.priceTableIndex"></input-prop>
                  </div>
                </div>
              </div>
              <div class="flex flex-center mt-32">
                <app-button (click)="agreementName ? editAgreementPlan() : plansPreRegister()" [placeholder]="'agreementsEdit.btnPlan'" [class]="'flex gap-2 align-center'"></app-button>
              </div>
              <div class="mt-32">
                <mat-divider></mat-divider>
              </div>
              <div class="mt-32" *ngIf="form.controlPlan.value">
                <h2>{{ 'agreementsEdit.titlePlansList' | translate}}</h2>
                <app-table *ngIf="registerAgreementPlan.length > 0 && agreementName" [Actions]="['Editar', 'Excluir']" (parentFun)="planActions($event)" [Itens]="registerAgreementPlan" [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true" [sumRecords]="totalPlan" [pageNumber]="page" [indexNumber]="index" [noShadow]="true"></app-table>
                <app-table *ngIf="!agreementName && resolvePreRegister.length > 0" [Actions]="['Excluir']" (parentFun)="actionsPreRegister($event)" [Itens]="resolvePreRegister" [ClickInItem]="ActionsExtra" [noShadow]="true"></app-table>
              </div>
            </div>
          </div>
          <div *ngIf="agreementId" class="flex flex-center mt-32 gap-2 mb-32">
            <app-button (click)="agreementId? updateAgreement() : createAgreement()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center'"></app-button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex align-center gap-1">
          <h2>{{'agreementsEditContact.title' | translate}}</h2>
        </div>
      </ng-template>
      <div class="data-content" [ngClass]="disable? 'readonly':''">
        <div class="box">
          <div class="column">
            <div class="w-100 flex">
              <h2>{{'agreementsEditContact.titleData' | translate}}</h2>
            </div>
            <div class="flex mt-32 gap-form">
              <div class="w-100">
                <input-prop [placeholder]="'agreementsEditContact.personName'" [form]="formExternalPerson.personName"></input-prop>
              </div>
              <div class="w-100">
                <input-prop (change)="cpfCnpj()" [placeholder]=" 'CNPJ / CPF'" [form]="formExternalPerson.personCPF" mask="{{maskCpfCnpj}}"></input-prop>
              </div>
              <div class="w-100">
                <input-prop [placeholder]="'agreementsEditContact.personEmail'" [form]="formExternalPerson.personEmail" [type]="'email'"></input-prop>
              </div>
            </div>
            <div class="flex mt-32 gap-form">
              <div class="w-100">
                <input-prop [placeholder]="'agreementsEditContact.personTelephone'" [form]="formExternalPerson.personTelephone" mask="(00) 0000-0000"></input-prop>
              </div>
              <div class="w-100">
                <input-prop [placeholder]="'agreementsEditContact.personCellPhone'" [form]="formExternalPerson.personCellPhone" mask="(00) 0 0000-0000"></input-prop>
              </div>
              <div class="w-100">
              </div>
            </div>
          </div>
          <div class="flex gap-2 mt-32">
            <h2>{{'agreementsEditContact.address' | translate}}</h2>
          </div>
          <div class="flex gap-form mt-32">
            <div class="w-100">
              <input-prop class="w-100 " (focusout)="getCep()" [placeholder]="'agreementsEditContact.zipCode'" mask="00.000-000" [form]="formExternalPerson.addressCEP"></input-prop>
            </div>
            <div class="w-100">
              <input-prop [placeholder]="'agreementsEditContact.address'" [form]="formExternalPerson.addressStreet"></input-prop>
            </div>
            <div class="w-100">
              <input-prop [placeholder]="'agreementsEditContact.number'" [form]="formExternalPerson.addressNumber" type="number"></input-prop>
            </div>
          </div>
          <div class="flex mt-32 gap-form">
            <div class="w-100">
              <input-prop [placeholder]="'agreementsEditContact.complement'" [form]="formExternalPerson.addressComplement"></input-prop>
            </div>
            <div class="w-100">
              <input-prop [placeholder]="'agreementsEditContact.neighborhood'" [form]="formExternalPerson.addressNeighborhood"></input-prop>
            </div>
            <div class="w-100">
              <input-prop [placeholder]="'agreementsEditContact.city'" [form]="formExternalPerson.addressCity"></input-prop>
            </div>
          </div>
          <div class="flex gap-form mt-32">
            <div class="w-100">
              <input-prop [placeholder]="'agreementsEditContact.state'" [form]="formExternalPerson.addressState"></input-prop>
            </div>
            <div class="w-100">
              <input-prop [placeholder]="'agreementsEditContact.country'" [form]="formExternalPerson.addressCountry"></input-prop>
            </div>
            <div class="w-100">
            </div>
          </div>
        </div>
        <div *ngIf="agreementId" class="flex flex-center mt-32 gap-2 mb-32">
          <app-button (click)="agreementId? updateAgreement() : createAgreement()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center'"></app-button>
        </div>
      </div>

    </mat-tab>
    <mat-tab *ngIf="title == 'Editar Convênio'">
      <ng-template mat-tab-label>
        <div class="flex align-center gap-1">
          <h2>{{'agreementsEditParameters.title' | translate}}</h2>
        </div>
      </ng-template>
      <div class="data-content">
        <div class="box">
          <div class="w-100 flex flex-between align-center">
            <h2>{{'agreementsEditParameters.titlePermissions' | translate}}s</h2>
            <app-button *ngIf="actualPage == 0" (click)="openDialogBlockDoctors()" [placeholder]="'agreementsEditParameters.btnRequesterBlocked'" [class]="'flex gap-2 align-center'"></app-button>
            <app-button *ngIf="actualPage == 1" (click)="openDialogBlockUnity()" [placeholder]="'agreementsEditParameters.btnUnitBloecked'" [class]="'flex gap-2 align-center'"></app-button>
          </div>
          <mat-tab-group (selectedTabChange)="changePage($event)">
            <mat-tab>
              <ng-template mat-tab-label>
                <div class="flex align-center gap-1">
                  <h2>{{'agreementsEditParameters.titleBlockedRequesters' | translate}}</h2>
                </div>
              </ng-template>
              <div class="flex mt-32 gap-form">
                <div class="w-100">
                  <app-table [noShadow]="true" [ClickInItem]="ActionsExtra" [Actions]="[ 'Excluir' ]" [Pagination]="true" [Itens]="blockedDoctors" (parentFun)="Actions($event)"></app-table>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div class="flex align-center gap-1">
                  <h2>{{'agreementsEditParameters.titleUnitBloecked' | translate}}</h2>
                </div>
              </ng-template>
              <div class="flex mt-32 gap-form">
                <div class="w-100">
                  <app-table [noShadow]="true" [ClickInItem]="ActionsExtra"  [Actions]="[ 'Excluir' ]"  [Pagination]="true" [backPagination]="true" [sumRecords]="totalBlockedUnity" [pageNumber]="pageBlockedUnity" [indexNumber]="indexBlockedUnity" [Itens]="blockedUnities" (parentFun)="ActionsUnity($event)" (changePage)="getBlockedUnity($event)"></app-table>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="box mt-32">
          <div class="w-100 flex flex-between align-center">
            <h2>{{'agreementsEditParameters.titlePlan' | translate}}</h2>
            <app-button (click)="openDialogModalParameter()" [placeholder]="'agreementsEditParameters.btnParaters'" [class]="'flex gap-2 align-center'"></app-button>
          </div>
          <div [ngClass]="disable? 'readonly':''" class="mt-24">
            <app-table [Actions]="['Excluir']" [Pagination]="true" [backPagination]="false" [sumRecords]="parameterTotal" [pageNumber]="parameterPage" [indexNumber]="parameterIndex" [noShadow]="true" [ClickInItem]="ActionsExtra" (parentFun)="ActionsParameters($event)" (changePage)="getAgreementParameters($event)" [Itens]="registerAgreementParameters"></app-table>
          </div>
        </div>
        <div class="flex flex-center mt-32 gap-2 mb-32">
          <app-button (click)="agreementId? updateAgreement() : createAgreement()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center'"></app-button>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex align-center gap-1">
          <h2>{{'agreementsEditValueExam.title' | translate}}</h2>
        </div>
      </ng-template>
      <div class="data-content">
        <div class="box">
          <div class="w-100">
            <h2>{{'agreementsEditValueExam.titleData' | translate}}</h2>  
          </div>
          <div class="flex gap-form mt-32">
            <div class="w-100">
              <select-prop [placeholder]="'agreementsEditValueExam.priceTable'" [form]="form.priceTableId" [options]="priceTableOptions" (changeValue)="selectPriceTable(form.priceTableId.value, form.priceTableIndex)"></select-prop>
            </div>
            <div class="w-100">
              <input-prop class="readonly" [readonly]="true" [placeholder]="'agreementsEditValueExam.index'" [form]="form.priceTableIndex"></input-prop>
            </div>
          </div>
          <div *ngIf="form.priceTableId.value">
            <div class="flex flex-between align-center mt-24 gap-2">
              <div class="flex align-center gap-2 titleResponsive">
              <h2>{{'agreementsEditValueExam.titleExam' | translate}}</h2>
              <app-button [placeholder]="'agreementsEditValueExam.btnAddExam'" (click)="openAddExamToPriceTableModal()" [class]="'flex gap-2 align-center'"></app-button>
              </div>
              <app-filter class="filterResponsive" (beforeClose)="getPriceTableExams()" [cleanButton]="true" [topUnset]="true" (clearFilters)="resetFilter()">
                <div class="query">
                  <input-prop #autoFocus (keyup.enter)="getPriceTableExams()" [placeholder]="'agreementsEditValueExam.filter.code'" [form]="filter.AnalyteCode"></input-prop>
                  <input-prop (keyup.enter)="getPriceTableExams()" [placeholder]="'agreementsEditValueExam.filter.description'" [form]="filter.AnalyteName"></input-prop>
                  <checkbox-prop class="flex flex-center" [gap]="1" [label]="'agreementsEditValueExam.filter.isActive'" [form]="filter.isActive"></checkbox-prop>
                </div>
                <app-button class="queryBtn" (click)="getPriceTableExams()" [placeholder]="'agreementsEditValueExam.filter.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
              </app-filter>
            </div>
            <div class="mt-32">
              <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="priceTableExamsParams.sumRecords" [pageNumber]="priceTableExamsParams.page" [indexNumber]="priceTableExamsParams.index" [Actions]="['Editar', 'Excluir']" (parentFun)="priceTableExamActions($event)" (changePage)="getPriceTableExams($event)" [ClickInItem]="ActionsExtraPriceTableExams" [Padding]="false" [noShadow]="true" [Itens]="priceTableExams"></app-table>
            </div>
          </div>
        </div>
        <div *ngIf="title === 'Editar Convênio'" class="mt-32 box">
          <div class="w-100 flex flex-between align-center mb-32 gap-form">
            <h2>{{'agreementsEditValueExam.titleExamValue' | translate}}</h2>
            <app-button *ngIf="!disable" (click)="openDialogCreateAgreementSpecialValue()" [placeholder]="'agreementsEditValueExam.btnValue'" [class]="'flex gap-2 align-center'"></app-button>
          </div>
          <app-table *ngIf="registerAgreementSpecialValue.length > 0" [ClickInItem]="ActionsExtra" [Pagination]="true" [sumRecords]="total" [indexNumber]="index" [Actions]="['Editar', 'Excluir']" (changePage)="getAgreementSpecialValue()" (parentFun)="specialValueActions($event)" [Itens]="registerAgreementSpecialValue" [noShadow]="true"></app-table>
        </div>
        <div class="flex flex-center mt-32 gap-2 mb-32">
          <app-button (click)="agreementId? updateAgreement() : createAgreement()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center'"></app-button> 
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #dialogUpdateAgreementPlan let-agreementPlan>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{'agreementsEdit.editPlan' | translate}}</h1>
        <svg (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="column">
        <div class="flex mt-32 gap-form">
          <div style="flex: 3" class="flex gap-form">
            <div class="w-100">
              <input-prop [placeholder]="'agreementsEdit.descriptionPlan'" [form]="formAgreementEditPlan.description"></input-prop>
            </div>
            <div class="w-100">
              <input-prop [placeholder]="'agreementsEdit.indexPlan'" [form]="formAgreementEditPlan.index"></input-prop>
            </div>
          </div>
          <div style="flex: 1" class="flex align-center">
            <checkbox-prop [gap]="1" [label]="'agreementsEdit.default'" [form]="formAgreementEditPlan.default"></checkbox-prop>
          </div>
        </div>
        <div class="flex mt-32 gap-form">
          <div class="w-100">
            <select-prop [placeholder]="'agreementsEdit.priceTable'" [form]="formAgreementEditPlan.priceTableId" [options]="priceTableOptions" (changeValue)="selectPriceTable(formAgreementEditPlan.priceTableId.value, formAgreementEditPlan.priceTableIndex)"></select-prop>
          </div>
          <div class="w-33">
            <input-prop class="readonly" [readonly]="true" [placeholder]="'agreementsEdit.priceTableIndex'" [form]="formAgreementEditPlan.priceTableIndex"></input-prop>
          </div>
        </div>
      </div>
      <div class="flex flex-center mt-32">
        <app-button (click)="updateAgreementPlan(agreementPlan.id, true)" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogBlockDoctors let-specialValue>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{'agreementsEditParameters.titleBlockedRequestersModal' | translate}}</h1>
        <svg (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="column mt-32">
        <div class="w-100">
          <input-search [placeholder]="'agreementsEditParameters.doctorOptions'" [form]="formAgreementDoctors.id" [options]="doctorOptions" [searchApi]="true" (search)="getDoctors($event.value)"></input-search>
        </div>
      </div>
      <div class="flex flex-center mt-32">
        <app-button (click)="createBlockedDoctor()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogBlockUnity let-specialValue>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{'agreementsEditParameters.titleUnitBloeckedModal' | translate}}</h1>
        <svg (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="column mt-32">
        <div class="flex flex-column mt-32 gap-3">
          <select-prop (changeValue)="getUnity()" class="w-100" [placeholder]="'agreementsEditParameters.companyOptions'" [form]="formUnity.companyId" [options]="companyOptions"></select-prop>
          <input-search (keyup.enter)="createBlockedUnity()" [placeholder]="'agreementsEditParameters.unityOptions'" [form]="formUnity.id" [options]="unityOptions"></input-search>
        </div>
      </div>
      <div class="flex flex-center mt-32">
        <app-button (click)="createBlockedUnity()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogAgreementSpecialValue let-agreementSpecialValue>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{ agreementSpecialValue.id ? ('agreementsEditValueExam.titleValueEdit' | translate) : ('agreementsEditValueExam.titleValueNew' | translate)}}</h1>
        <svg (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="column mt-32">
        <div class="flex flex-between mt-32 gap-1">
          <input-prop  *ngIf="!agreementSpecialValue.id" (keyup.enter)="setFocus()" placeholder="agreementsEditValueExam.code" [form]="formPriceTableAnalyte.controls['code']"  (change)="analyteCodeChanged()"></input-prop>
          <input-search *ngIf="!agreementSpecialValue.id" #analyteIdForm class="w-100" [placeholder]="'agreementsEditValueExam.analyteOptions'" [searchApi]="true" (search)="getExamsOption($event.value)" (changeForm)="getAnalyteMaterial(formAgreementSpecialValue.analyteId.value)" [form]="formAgreementSpecialValue.analyteId" [options]="analyteOptions"></input-search>
          <input-prop *ngIf="agreementSpecialValue.id" class="readonly w-100" [readonly]="true" [placeholder]="'agreementsEditValueExam.analyteOptions'" [form]="formAgreementSpecialValue.code"></input-prop>
        </div>
        <div class="w-100 mt-32">
          <input-search [placeholder]="'agreementsEditValueExam.material'" [form]="formAgreementSpecialValue.materialId" [options]="materialOptions"></input-search>
        </div>
        <div class="w-100 mt-32">
          <input-prop-currency (keyup.enter)="agreementSpecialValue.id ? updateSpecialValue(agreementSpecialValue.id) : createSpecialValue()" [placeholder]="'agreementsEditValueExam.infoValue'" [form]="formAgreementSpecialValue.priceValue"></input-prop-currency>
        </div>
      </div>
      <div class="flex flex-center mt-32">
        <app-button (click)="agreementSpecialValue.id ? updateSpecialValue(agreementSpecialValue.id) : createSpecialValue()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalParameter>
  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1>{{'agreementsEditValueExam.titleParametersNew' | translate}}</h1>
      <svg (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="mt-32">
      <input-user-multiple-new [form]="form.agreementParameter" [hideOptions]="hideParameters" [options]="typeAgreementParameters"></input-user-multiple-new>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="includeAgreementParameter()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalPriceTableExam let-priceTableData>

  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1>{{ editId ? ('agreementsEditValueExam.titleExamEdit' | translate) : ('agreementsEditValueExam.titleExamNew' | translate)}} <b class="orangeFont">{{priceTableData.priceTableName}}</b></h1>
      <svg (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="flex flex-between mt-32 gap-1">
      <input-prop *ngIf="!priceTableData.analyteId"  (keyup.enter)="setFocus()" placeholder="agreementsEditValueExam.code" [form]="formPriceTableAnalyte.controls['code']" (change)="analyteCodeChanged()"></input-prop>
      <input-search *ngIf="!priceTableData.analyteId" #analyteIdForm class="w-100" [placeholder]="'agreementsEditValueExam.analyteOptions'" [searchApi]="true" (search)="getExamsOption($event.value)" (changeForm)="analyteIdChanged()" [form]="formPriceTableAnalyte.controls['analyteId']" [options]="analyteOptions"></input-search>
      <input-prop *ngIf="priceTableData.analyteId" [readonly]="true" class="readonly" placeholder="agreementsEditValueExam.code" [form]="formPriceTableAnalyte.controls['code']"></input-prop>
      <input-prop  *ngIf="priceTableData.analyteId"  [readonly]="true" class="readonly w-100" [placeholder]="'agreementsEditValueExam.analyteOptions'" [form]="formAgreementSpecialValue.code"></input-prop>

    </div>
    <div class="flex flex-between mt-32 gap-1">
      <input-prop-currency class="w-100" [placeholder]="'agreementsEditValueExam.price'" [form]="formPriceTableAnalyte.controls['price']"></input-prop-currency>
      <input-prop class="w-100" [placeholder]="'agreementsEditValueExam.billingCode'" [form]="formPriceTableAnalyte.controls['billingCode']"></input-prop>
    </div>
    <div class="flex flex-between mt-32">
      <checkbox-prop [gap]="1" [label]="'agreementsEditValueExam.isSuspended'" [form]="formPriceTableAnalyte.controls['isSuspended']"></checkbox-prop>
      <checkbox-prop [gap]="1" [label]="'agreementsEditValueExam.isBlocked'" [form]="formPriceTableAnalyte.controls['isBlocked']"></checkbox-prop>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="editId? putExamToPriceTable() : addExamToPriceTable()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>
