<div (focusout)="onFocusOut()" [class]="classe" *ngIf="type != 'date' && type != 'datetime-local' && type != 'time'">
    <div class="flex align-center">
        <label *ngIf="label">
            {{label}}
        </label>
        <div class="sd">

            <input class="fontsize14" *ngIf="type!='time'" #input [class]="noLabelUp ? 'noLabelUp' : ''" [pattern]="pattern" [type]="type" [tabindex]="tabindexcomponent" [min]="min" [max]="max" [dropSpecialCharacters]="false" [placeholder]="placeholder | translate"
                   [readOnly]="readonly" [required]=requiredInput [formControl]="form" (keydown)="onChange(form.value)" (change)="onChange(form.value)"  (input)="changeValueEmit()"
                   [mask]="mask" [maxlength]="maxlength" [minlength]="minlength" [prefix]="prefix">            
            <label [ngStyle]="placeholderMenu ? { 'color': 'white', 'padding-left' : 'calc(16px * var(--proportional))' ,'font-size': 'calc(14px * var(--proportional))' } : {}">{{placeholder | translate}}</label>
            <span></span>
            <span class="material-icons pass" *ngIf="type=='password' || type=='show_password'" (click)="type=='password' ? type = 'show_password' : type = 'password'">
            {{type=='password' ? 'visibility_off' : 'visibility_on'}}
            </span>
            <span class="error" *ngIf="form && requiredInput">
                <span *ngIf="showError('required')">Esse campo é obrigatório</span>
            </span>
        </div>
        <label style="margin-left: 8px;" *ngIf="labelAfter">
            {{labelAfter | translate}}
        </label>
    </div>
</div>
<div (focusout)="onFocusOut()" [class]="classe" *ngIf="type=='time'"> 
    <label *ngIf="label">
        {{label}}
    </label>
    <div class="sd">
        <input class="time" [inputMask]="masks.timeMask" [placeholder]="placeholder | translate" [formControl]="form">   
        <label>{{placeholder | translate}}</label>
    </div>
</div>
<div (focusout)="onFocusOut()" class="date" *ngIf="type=='date' || type=='datetime-local'">
    <div [class]="classe" *ngIf="type=='date'">
        <div class="sd">
            <label *ngIf="placeholder && !noLabelUp" class="label-date">
                {{placeholder | translate}}
            </label>
            <mat-form-field (keydown.enter)="$event.preventDefault()">
                <input class="fontsize14" #dateInput matInput (ngModelChange)="change.emit(true)" [tabindex]="tabindexcomponent" 
                    [min]="min" [max]="max" [dropSpecialCharacters]="false" [placeholder]="placeholder"
                    [inputMask]="masks.dateMask"
                    (dateChange)="onDateChange($event)"
                    [required]=requiredInput [maxlength]="maxlength" [minlength]="minlength" 
                    [formControl]="dateForm" [matDatepicker]="picker"
                    [placeholder]="placeholder" [matDatepickerFilter]="disableFutureDate ? dateFilter : '' ">
                <mat-datepicker-toggle matSuffix [for]="picker" tabindex="-1"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div [class]="classe" *ngIf="type=='datetime-local'">
        <div class="sd">
            <label *ngIf="placeholder && !noLabelUp"  class="label-date">
                {{placeholder | translate}}
            </label>
            <mat-form-field (keydown.enter)="$event.preventDefault()">
                <input class="fontsize14" matInput [tabindex]="tabindexcomponent" [min]="min" [max]="max" 
                    [dropSpecialCharacters]="false" [placeholder]="placeholder"
                    [inputMask]="masks.datetimeMask"
                    (dateChange)="onDateChange($event)"
                    [required]=requiredInput [maxlength]="maxlength" [minlength]="minlength" 
                    [formControl]="dateForm" [ngxMatDatetimePicker]="picker"
                    [placeholder]="placeholder">
                <mat-datepicker-toggle matSuffix [for]="picker" tabindex="-1"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker tabindex="-1"></ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="form.invalid && (form.dirty || form.touched)">
        <div class="warning" *ngIf="form.errors?.['required']  && warningCustom" style="color: red;">
            {{warningCustom}}
        </div>
        <div *ngIf="form.errors?.['required'] && !warningCustom" style="color: red;">
            <p class="warning" *ngIf="!warningCustom">É obrigatorio informar a {{placeholder | translate}}</p>
        </div>
        <div class="warning" *ngIf="form.errors?.['invalidDate']">
            Informe uma data válida.
        </div>
    </div>
</div>