export type TypeIncomeExpense = {
    id: string,
    externalId: string,
    description: string,
    type: number,
    isActive: boolean,
    updatedAt: string,
    createdAt: string,
}

export type ResolvedTypeIncomeExpense = {
    id: string
    "Código": string
    "Descrição": string
    "Criado em": string
    "Alterado em": string
    "Ativo": string
    "_hide_type": number
}

export type PaginationInfo = {
    page: number,
    sumRecords: number,
    index: number,
}

export const spanOptions: {[key: number]: string} = {
    1: '<span class="span-styles" style="background: #1cad51; color: #FFFFFF;">Receita</span>',
    2: '<span class="span-styles" style="background: #EB5757; color: #FFFFFF;">Despesa</span>'
}

export type Actions = {
    object: ResolvedTypeIncomeExpense
    action: string,
    id: string,
    context: string | null,
}

export const incomeExpenseTypesOptions = [
    {
      value: 1,
      label: 'Receita'
    },
    {
      value: 2,
      label: 'Despesa'
    }
  ]