import { inject, Injectable } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import { lastValueFrom } from "rxjs";
import { ProfileService } from "../services/profile/profile.service";

@Injectable({
   providedIn: "root"
})
export class ProfileFacade extends MultiObservableFacade {
   private _profileService = inject(ProfileService);

   async getProfile() {
      try {
         const response: any = await lastValueFrom(
            this._profileService.get<any>()
         );

         if (response.success == true) {
            return response.data[0];
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }

      return [];
   }
}
