<div [class]="classe">
    <div class="flex align-center">
        <label *ngIf="label">
            {{label}}
        </label>
        <div class="sd flex">
            <!-- <div class="flex align-center prefix">
                <span class="fontsize14">R$</span>
            </div> -->
            <!-- <mat-divider [vertical]="true"></mat-divider> -->
            <input class="fontsize14" #input [class]="noLabelUp ? 'noLabelUp' : ''" [tabindex]="tabindexcomponent" [placeholder]="placeholder | translate"
                    [readOnly]="readonly" [required]=requiredInput [formControl]="defaultForm" (keydown)="_keyDown($event)" (change)="onChange(form.value)"
                    [maxlength]="maxlength">
            <label>{{placeholder | translate}}</label>
            <span></span>
            <span class="error" *ngIf="form && requiredInput">
                <span *ngIf="showError('required')">Esse campo é obrigatório</span>
            </span>
        </div>
    </div>
    <div *ngIf="form.invalid && (defaultForm.dirty || defaultForm.touched)">
        <div *ngIf="form.errors?.['required']">
            <p class="warning" *ngIf="!warningCustom">É obrigatorio informar o {{placeholder}}</p>
            <p class="warning" *ngIf="warningCustom">{{warningCustom}}</p>
        </div>
        <!-- <div class="warning" *ngIf="form.errors?.['gap']">
            Insira um valor divisível por {{valueGap.toFixed(2)}}
        </div> -->
        <div class="warning" *ngIf="form.errors?.['minValue']">
            Insira um Valor maior que {{minValue.toFixed(2)}}
        </div>
        <div class="warning" *ngIf="form.errors?.['maxValue']">
            Insira um Valor menor que {{maxValue.toFixed(2)}}
        </div>
    </div>
</div>
