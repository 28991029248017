import { Component, Input } from "@angular/core";
import { SvgSelectorUIComponent } from "../../ui/svg-selector/svg-selector.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialogModule } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";

@Component({
    selector: "modal-layout[title]",
    templateUrl: "modal.component.html",
    standalone: true,
    imports: [SvgSelectorUIComponent, TranslateModule, MatDialogModule, CommonModule]
})
export class ModalLayoutComponent {
    @Input() title = "";
    @Input() closeButton = true;
}
