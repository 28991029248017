import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { Guid } from 'src/app/util/guid';
import { QueryParams } from '../interfaces/services.types';
import { HttpResponse, TypeIncomeExpensePost } from './type-income-expense.types';

@Injectable({
  providedIn: 'root'
})

export class TypeIncomeExpenseService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/financial/typeincome');
  }

  override getPagination<T>(queryObject?: QueryParams | undefined): Promise<HttpResponsePageable<T>> {
    return super.getPagination(queryObject) as Promise<HttpResponsePageable<T>>
  }

  override delete(id?: Guid | undefined): Promise<HttpResponse> {
    return super.delete(id) as Promise<HttpResponse>
  }

  override put(body: TypeIncomeExpensePost, id?: Guid | undefined, path?: string | undefined): Promise<HttpResponse> {
    return super.put(body, id, path) as Promise<HttpResponse>
  }

  override post(body: Object): Promise<HttpResponse> {
    return super.post(body) as Promise<HttpResponse>
  }

  override patchActive(id: string, value: string): Promise<HttpResponse> {
    return super.patchActive(id, value) as Promise<HttpResponse>
  }

}