import { HttpClient } from '@angular/common/http';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { GetAllCollects, GetByIdCollect } from './collect.types';
import { Injectable } from '@angular/core';
import { Guid } from '@app/util/guid';

@Injectable({
  providedIn: 'root'
})

export class CollectService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/movimentation/collect');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllCollects>>
  }

  override getByPath(queryString: string, path: string, headers?: String[] | undefined) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetAllCollects>>
  }

  override getById(id: Guid) {
    return super.getById(id) as Promise<HttpResponsePageable<GetByIdCollect>>
  }

  override put(body: Object, id?: Guid | undefined, path?: string | undefined): Promise<Object> {
    return super.put(body, id, path)
  }
}