<div class="flex justify-between items-center flex-wrap-mobile">
    <div class="flex items-center">
        <p>
            {{ amount > 5 ? page * totalPerPage - totalPerPage + 1 : amount }}
            -
            {{
                amount > 5
                    ? totalPerPage * page > amount
                        ? amount
                        : totalPerPage * page
                    : amount
            }}
            {{ "table.of" | translate }} {{ amount }}
            {{ "table.results" | translate }}
        </p>
        <select
            name=""
            id=""
            [(ngModel)]="totalPerPage"
            *ngIf="amount > 5"
            (change)="changeTotalPerPage()"
        >
            <ng-container>
                <option [value]="5">
                    5 {{ "table.itensPage" | translate }}
                </option>
                <option [value]="10">
                    10 {{ "table.itensPage" | translate }}
                </option>
                <option [value]="30">
                    30 {{ "table.itensPage" | translate }}
                </option>
                <option [value]="50">
                    50 {{ "table.itensPage" | translate }}
                </option>
            </ng-container>
        </select>
    </div>
    <div class="pages" *ngIf="totalPages > 0">
        <span (click)="toFirstPage()" [ngClass]="{ op0: isFirstPage }">
            <ui-svg-selector [svg]="'page-first'"></ui-svg-selector>
        </span>
        <span (click)="toBackPage()" [ngClass]="{ op0: isFirstPage }">
            <ui-svg-selector [svg]="'page-back'"></ui-svg-selector>
        </span>
        <ng-container *ngFor="let option of availablePages">
            <span
                class="number"
                [ngStyle]="{
                    'background-color':
                        isAdm && page === option.name ? '#404040' : ''
                }"
                [ngClass]="{ act: page === option.name }"
                (click)="!option.isDisabled && changeToPage(option.name)"
            >
                {{ option.name }}
            </span>
        </ng-container>
        <span (click)="toNextPage()" [ngClass]="{ op0: isLastPage }">
            <ui-svg-selector [svg]="'page-next'"></ui-svg-selector>
        </span>
        <span (click)="toLastPage()" [ngClass]="{ op0: isLastPage }">
            <ui-svg-selector [svg]="'page-last'"></ui-svg-selector>
        </span>
    </div>
</div>
