import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { AnalytePackageExams } from '@app/services/analyte-package-exams/analyte-package-exams';
import { AnalytePackageExamsService } from '@app/services/analyte-package-exams/analyte-package-exams.service';
import { AnalyteService } from '@app/services/analyte/analyte.service';
import { AnalyteMaterialService } from '@app/services/analyte-material/analyte-material.service';
import { ExamPackService } from '@app/services/examPack/examPack.service';
import { Guid } from '@app/util/guid';
import { ExamPackesGetAll, Actions } from './analyte-packages.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { ToastService } from '@app/services/translate/toast.service';

@Component({
  selector: 'app-analyte-packages',
  templateUrl: './analyte-packages.component.html',
  styleUrls: ['./analyte-packages.component.scss']
})
export class AnalytePackagesComponent implements OnInit {

  idAnalytePackage : string = ''

  page = 1;
  index = 50;
  total = 0;

  examPage = 1;
  examIndex = 9999;
  examTotal = 0;

  titleExamPackages: string = "";

  examPack: Array<Object> = []

  analyteId: Guid = new Guid(Guid.getEmpty());

  examPackOptions: Array<Object> = []

  dropDownExamPack: Array<{ id: string, examPackageTable: Array<AnalytePackageExams> }> = [];

  orderBy: {sort: string, order: string} = {sort: '', order: 'asc'}


  isEditMode: boolean = false;

  formExamPack = {
    isActive: new UntypedFormControl(true),
    pack: new UntypedFormControl(null),
    matPack: new UntypedFormControl(null),
    report: new UntypedFormControl(null),
    cobra: new UntypedFormControl(null),
    allowSelection: new UntypedFormControl(false)
  }

  formExamPaclInclude = {
    analytePackageId: new UntypedFormControl(null),
    analyteId: new UntypedFormControl(null),
    analyteMaterialId: new UntypedFormControl(null),
    pack: new UntypedFormControl(null),
    matPack: new UntypedFormControl(null)
  }

  formOptionsMaterial: Array<Object> = [];

  filter = {
    name: new UntypedFormControl(),
    isActive: new UntypedFormControl(false)
  }

  registerExamPackage: Array<Object> = [];

  resolveExamPackage: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "_hide_packId",
      method: '',
      retrive: "analyteId",
      after: '',
      before: '',
    },
    {
      label: "_hide_allowSelection",
      method: '',
      retrive: "allowSelection",
      after: '',
      before: '',
    },
    {
      label: "analytePackages.resolveExamPackage.name",
      method: '',
      retrive: 'name',
      after: '',
      before: '',
      sort:  'name'
    },
    {
      label: "analytePackages.resolveExamPackage.getPackageReport",
      method: 'getPackageReport',
      retrive: '',
      after: '',
      before: '',
      sort: 'typeInvoiceAnalytePackage',
    },
    {
      label: "_hide_material",
      method: '',
      retrive: "description",
      after: '',
      before: '',
    },
    {
      label: "analytePackages.resolveExamPackage.typeColorExamPack",
      method: 'typeColorExamPack',
      retrive: '',
      after: '',
      before: '',
      sort: 'typePackageReport',
    },
    {
      label: "analytePackages.resolveExamPackage.createdAt",
      method: 'getCreated',
      retrive: '',
      after: '',
      before: '',
      sort:  'createdAt'
    },
    {
      label: 'analytePackages.resolveExamPackage.updatedAt',
      method: 'getUpdated',
      retrive: '',
      after: '',
      before: '',
      sort:  'updatedAt'
    },
    {
      label: "Ativo",
      method: 'getActive',
      retrive: '',
      after: '',
      before: '',
      sort:  'isActive'
    }
  ];

  analytePackageExamsOptions: Array<Object> = [];

  registerAnalytePackageExams: Array<Object> = [];

  resolveAnalytePackageExams: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "analytePackages.resolveAnalytePackageExams.name",
      method: '',
      retrive: 'name',
      after: '',
      before: '',
    },
    {
      label: "analytePackages.resolveAnalytePackageExams.description",
      method: '',
      retrive: 'description',
      after: '',
      before: '',
    },
    {
      label: "analytePackages.resolveAnalytePackageExams.createdAt",
      method: 'getCreated',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: 'analytePackages.resolveAnalytePackageExams.updatedAt',
      method: 'getUpdated',
      retrive: '',
      after: '',
      before: ''
    }
  ];

  typeExamPackage = [
    {
      value: 0,
      label: 'Exame'
    },
    {
      value: 1,
      label: 'Pacote'
    }
  ];

  constructor(
    private _dialog: MatDialog,
    private _examPackService: ExamPackService,
    private _analyteService: AnalyteService,
    private _localaDatePipe: LocalaDatePipe,
    private _analytePackageExamsService: AnalytePackageExamsService,
    private _analyteMaterialService: AnalyteMaterialService,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _toastService: ToastService
  ) { }

  ActionsExtraExams = {
    "Ativo": "PutActiveExams"
  }

  ActionsExtraPackageExams = {
    "Ativo": "PutActivePackageExams"
  }

  @ViewChild('dialogModal')
  dialogCreatePackage!: TemplateRef<any>

  @ViewChild('dialogAddExamPackage')
  dialogAddExamPackage!: TemplateRef<any>


  getPackageReport(item: ExamPackesGetAll) {
    return item.typePackageReport === 0 ? "<span style='border-radius: 100px; padding: 4px 12px; background-color: #006E9D; color: white'>Exame</span>" : "<span style='border-radius: 100px; padding: 4px 12px; background-color: #07BF56; color: white'>Pacote</span>";
  }

  typeColorExamPack(item: ExamPackesGetAll) {
    return item.typeInvoiceAnalytePackage === 0 ? "<span style='border-radius: 100px; padding: 4px 12px; background-color: #006E9D; color: white'>Exame</span>" : "<span style='border-radius: 100px; padding: 4px 12px; background-color: #07BF56; color: white'>Pacote</span>";
  }

  getActive(item: ExamPackesGetAll) {    
    return this._activePipe.transform(item.isActive)
  }

  getCreated(item: ExamPackesGetAll) {
    return this._localaDatePipe.transform(item.createdAt)
  }

  getUpdated(item: ExamPackesGetAll) {
    return this._localaDatePipe.transform(item.updatedAt);
  }

  async ngOnInit() {
    await this.getExamPackage();
    await this.getAllAnalytes();
  }

  async getExamPackage(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let name: string = this.filter.name.value ? this.filter.name.value : null;
    let isActive = this.filter.isActive.value ? this.filter.isActive.value : null;

      await this._examPackService.getPagination({ page: this.page, index: this.index, name, isActive, orderBy: this.orderBy.sort ? `${this.orderBy.sort},${this.orderBy.order}` : null}).then(
      (res) => {
        this.total = res.sumRecords;
        this.registerExamPackage = this.resolve(res.data, this.resolveExamPackage);
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    )
  }


  async getSortedPage(event: { label: string, index: number, order: string }) {
    let value: any = this.resolveExamPackage.find((x: any) => x.label == event.label)
    
    if(value && value.sort) {
      this.orderBy.sort = value.sort
      this.orderBy.order = event.order
    }
    this.getExamPackage()
  }


  async createExamPackage() {
    let obj = {
      analyteId: this.formExamPack.pack.value,
      analyteMaterialId: this.formExamPack.matPack.value,
      typePackageReport: parseInt(this.formExamPack.report.value),
      typeInvoiceAnalytePackage: parseInt(this.formExamPack.cobra.value),
      allowSelection: this.formExamPack.allowSelection.value
    }

    if(!obj.analyteMaterialId) return this._toastService.warningMessage('analytePackages.msgInfoMaterial') 

    await this._examPackService.post(obj).then(
      (res) => {
        if (res.success) {
          this.getExamPackage();
          this._toastService.fireSuccess("analytePackages.msgPostPack");
          this.close();
        }
    }).catch(
      (err) => {
        this._toastService.fireError(err);
    });
  }

  async addExamPackage(analytePackageId: string) {
    let obj = {
      analytePackageId: analytePackageId,
      analyteId: this.formExamPaclInclude.analyteId.value,
      analyteMaterialId: this.formExamPaclInclude.analyteMaterialId.value,
    }
    await this._analytePackageExamsService.post(obj).then(
      (res) => {
        if (res.success) {
          this.getALLAnalytePackageExams(analytePackageId);
          this._toastService.fireSuccess("analytePackages.msgPostExam");
          this.close();
        }
      }).catch(
        (err) => {
          this._toastService.fireError(err);
        });
  }

  updateExamPackage() {
    const updateObj = {
      isActive: this.formExamPack.isActive.value,
      typePackageReport: parseInt(this.formExamPack.report.value),
      typeInvoiceAnalytePackage: parseInt(this.formExamPack.cobra.value),
      allowSelection: this.formExamPack.allowSelection.value
    }
    this._examPackService.put(updateObj, this.analyteId).then((res) => {
      if (res.success) {
        this.getExamPackage();
        this._toastService.fireSuccess('analytePackages.msgPutPack');
        this.close();
      }
    }).catch(
      (err) => {
        this._toastService.fireError(err);
      });
  }

  async getAllAnalytes() {
    await this._analyteService.getAll().then(
      (res) => {
        const filter = res.data.filter((packExam) => packExam.isActive === true);
        this.examPackOptions = filter.map((packExam) => ({
          value: packExam.id,
          label: `${packExam.code} - ${packExam.name}`
        }));
      }
    );
  }

  async getALLAnalytePackageExams(analytePackageId: string) {
    this.registerAnalytePackageExams = [];
    await this._analytePackageExamsService.getPagination({ analytePackageId: analytePackageId, page: this.examPage, index: this.examIndex }).then(
      (res) => {
        const dropDownExamPackObject: { id: string, examPackageTable: Array<AnalytePackageExams> } = {
          id: analytePackageId,
          examPackageTable: res.data as Array<AnalytePackageExams>
        }
        const index = this.dropDownExamPack.findIndex((x) => x.id === analytePackageId);
        index === -1 ? this.dropDownExamPack.push(dropDownExamPackObject) : this.dropDownExamPack[index] = dropDownExamPackObject;
      });
  }

  returnListExams(analytePackageId?: string) {
    const object = this.dropDownExamPack.filter((res) => res.id === analytePackageId);
    return object.length > 0 ? this.resolve(object[0].examPackageTable, this.resolveAnalytePackageExams) : [];
  }

  resolve(data: any, columns: any) {
    let returnList: Array<Object> = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      returnList.push(obj);
    });
    return returnList;
  }

  close() {
    this._dialog.closeAll();
  }

  async openDialogExamPackages(id?: any) {
    this.titleExamPackages = id ?? ''
    const myTempDialog = this._dialog.open(this.dialogCreatePackage, {
      width: '660px',
      data: id
    });
    myTempDialog.afterClosed().subscribe((res) => {
        this.isEditMode = false;
        this.reset();
    });
  }

  openDialogExamPackagesInclude(analytePackageId: string) {
    this.formExamPaclInclude.analyteId.setValue(null);
    this.formExamPaclInclude.analyteMaterialId.setValue(null);
    this.formExamPaclInclude.analytePackageId.setValue(null);
    const myTempDialog = this._dialog.open(this.dialogAddExamPackage, {
      width: '660px',
      data: analytePackageId
    });
  }

  reset(){
    this.formExamPack.pack.setValue(null);
    this.formExamPack.matPack.setValue(null);
    this.formExamPack.report.setValue(null);
    this.formExamPack.cobra.setValue(null);
    this.formExamPack.isActive.setValue(true);
    this.formExamPack.allowSelection.reset()
  }

  deleteExamPack(examPackid: Guid) {
    this._toastService.fireConfirmation('analytePackages.msgDeletePack').then(
      async (res) => {
        if (res.isConfirmed) {
          await this._examPackService.delete(examPackid);
          this._toastService.fireSuccess('analytePackages.msgDeleteSuccess');
          await this.getExamPackage();
        }
      }
    ).catch(
      (error) => {
        this._toastService.fireError(error);
      }
    );
  }

  deleteExams(examsid: Guid, analytePackageId?: string) {
    this._toastService.fireConfirmation('analytePackages.msgDeleteExam').then(
      async (res) => {
        if (res.isConfirmed) {
          await this._analytePackageExamsService.delete(examsid);
          this._toastService.fireSuccess('analytePackages.msgDeleteExamSuccess');
          if (analytePackageId) this.getALLAnalytePackageExams(analytePackageId);
        }
      }
    ).catch(
      (error) => {
        this._toastService.fireError(error);
      }
    );
  }

  async packageActions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.analyteId = emit.object.id;
        this.isEditMode = true;
        this.selectAnalyteMaterial(new Guid(emit.object._hide_packId))
        this.openDialogExamPackages(emit.object);
        this.formExamPack.pack.setValue(emit.object._hide_packId);
        this.formExamPack.matPack.setValue(emit.object._hide_material);
        this.formExamPack.allowSelection.setValue(emit.object._hide_allowSelection.toLocaleLowerCase() == 'true' ? true : false)
        emit.object['analytePackages.resolveExamPackage.getPackageReport'].includes('Exame') ? this.formExamPack.report.setValue(0) : this.formExamPack.report.setValue(1);
        emit.object['analytePackages.resolveExamPackage.typeColorExamPack'].includes('Exame') ? this.formExamPack.cobra.setValue(0) : this.formExamPack.cobra.setValue(1);
        break;
      case 'Excluir':
        this.deleteExamPack(emit.object.id);
        this.getExamPackage();
        break;
      case 'OpenDropdown':
        await this.getALLAnalytePackageExams(emit.id);
        break;
      case 'PutActiveExams':
        this.putExamsActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  putExamsActive(id: Guid, value: string) {
    this._examPackService.patchActive(id.toString(), value).then((x:any) => {
      this._toastService.fireSuccess('global.msgSituationSuccess')
      this.getExamPackage();
    }).catch(err => this._toastService.fireError(err))
  }

  async examsActions(emit: Actions, analytePackageId?: string) {
    switch (emit.action) {
      case 'Excluir':
        if (analytePackageId) this.deleteExams(emit.object.id, analytePackageId);
        break;
      case 'PutActivePackageExams':
        this.putActiveExams(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  putActiveExams(id: Guid, value: string) {
    this._analyteService.patchActive(id.toString(), value).then((x:any) => {
      this._toastService.fireSuccess('global.msgSituationSuccess')
      this.getALLAnalytePackageExams(this.idAnalytePackage)
      this.idAnalytePackage= ''
    }).catch(err => this._toastService.fireError(err))
  }

  async selectAnalyteMaterial(id: Guid) {
    if (id) {
      this.formOptionsMaterial = [];
      await this._analyteMaterialService.get(`AnalyteId=${id}`).then(
        (res) => {
          //@ts-ignore
          res.data.forEach((material) => {
            const materialObj = {
              label: material.description,
              value: material.id
            }
            this.formOptionsMaterial.push(materialObj);
          });
          if (this.formOptionsMaterial.length === 1) {
            this.formExamPack.matPack.setValue((this.formOptionsMaterial[0] as any).value)
          } else {
            this.formExamPack.matPack.setValue("")
          }
        }).catch(
          (err) => {
            this._toastService.fireError(err);
          }
        );
    }
  }

  resetFilter(){
    this.filter.name.reset();
    this.filter.isActive.reset();
  }
}
