import { AsyncPipe } from "@angular/common";
import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { PageableComponent } from "@app/modules_new/common/components/pageable";
import { PaginationTableComponent } from "@app/modules_new/shared/components/form/table/pagination/pagination.component";
import { TableFormComponent } from "@app/modules_new/shared/components/form/table/table.component";
import { ContentFilterLayoutComponent } from "@app/modules_new/shared/layouts/content-filter/content-filter.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, FormGroup } from "@angular/forms";
import { MenuItemResponse } from "../../data/services/menuItem/menuItem.types";
import { tableActions, tableColumns } from "./menuItem";
import { MenuItemFacade } from "../../data/facades/menuItem.facade";
import { PaginationEvent } from "@app/modules_new/shared/components/form/table/pagination/pagination.model";
import { MenuItemModal } from "./modal/modal.component";
import { RequestOptions } from "@app/modules_new/data/services/types/http.types";
import { ToastService } from "@app/services/translate/toast.service";
import { SubMenuUIComponent } from "../../ui/subMenu/subMenu.component";

@Component({
   selector: "menuItem",
   templateUrl: "./menuItem.component.html",
   standalone: true,
   imports: [
      ContentFilterLayoutComponent,
      TranslateModule,
      TableFormComponent,
      PaginationTableComponent,
      AsyncPipe,
      MenuItemModal,
      SubMenuUIComponent
   ]
})
export class MenuItemComponent
   extends PageableComponent<MenuItemResponse[]>
   implements OnInit
{
   constructor() {
      super(tableColumns, tableActions);
   }

   @ViewChild("menuItem") menuItemTable!: TableFormComponent;

   private _toastService = inject(ToastService);
   private _dialog = inject(MatDialog);
   private _menuItemFacade = inject(MenuItemFacade);

   formFilterGroup = new FormGroup({
      description: new FormControl(null),
      isActive: new FormControl(null)
   });

   formFilter: Array<object> = [
      {
         placeholder: "default.description",
         type: "text",
         form: this.formFilterGroup.controls["description"],
         click: true
      },
      {
         placeholder: "agreements.filter.isActive",
         type: "toggle",
         form: this.formFilterGroup.controls["isActive"]
      }
   ];

   ngOnInit() {
      this.get();
   }

   get() {
      const request: RequestOptions = {
         query: {
            search: this.formFilterGroup.controls.description.value ?? null,
            isActive: this.formFilterGroup.controls.isActive.value
         }
      };

      const { data$, total$ } = this._menuItemFacade.getMenuItens(
         this.page,
         this.numberRegistry,
         request
      );
      this.data = data$;
      this.total = total$;
   }

   ngAfterViewInit() {
      this.menuItemTable.userInteractions$.subscribe((response) => {
         switch (response?.eventType) {
            case "DROPDOWN_PRESSED": {
               if (response.payload.event) {
                  this._menuItemFacade.createTabsGroup(
                     response.payload.data.id
                  );
               }
               break;
            }
            case "IS_ACTIVE_CHANGE":
               this._menuItemFacade.patchActive(
                  response.payload.data.id,
                  response.payload.data.isActive
               );
               break;
            case "ACTIONS_PRESSED":
               switch (response.payload.event) {
                  case "EDITAR":
                     const edit = {
                        obj: {
                           description: response.payload.data.description,
                           url: response.payload.data.url,
                           id: response.payload.data.id
                        }
                     };
                     this.openModal(edit);
                     break;
                  case "DELETE":
                     this._toastService
                        .fireConfirmation("Deseja realmente excluir esse menu?")
                        .then((res: any) => {
                           if (res.isConfirmed) {
                              this._menuItemFacade.delete(
                                 response.payload.data.id
                              );
                           }
                        });
                     break;
               }
               break;
         }
      });
   }

   openModal(data: any = null) {
      const dialog = this._dialog.open(MenuItemModal, {
         width: "calc(900px * var(--proportional))",
         data: data
      });
   }

   changePage(event: PaginationEvent) {
      const obj: RequestOptions = {
         query: {
            search: this.formFilterGroup.controls.description.value ?? null,
            isActive: this.formFilterGroup.controls.isActive.value
         }
      };
      this.page = event.page;
      this.numberRegistry = event.numberRegistry;
      this._menuItemFacade.getMenuItens(event.page, event.numberRegistry, obj);
   }

   resetFilter() {
      this.formFilterGroup.controls.description.reset(null);
      this.formFilterGroup.controls.isActive.reset(null);
   }
}
