import { DefaultFormats } from "@app/pipe/localeDate.pipe";

export interface ListRequisition {
    createdAt: Date,
    customerId: string,
    customerName: string,
    id: string,
    initialCollect: Date,
    isPriority: boolean,
    personInCollect: string,
    qrCode: string,
    recipients: Array<Recipients>,
    requisitionNumber: number,
    requisitionStatus: number,
    waitingTime: string
}
  
export interface Recipients {
    analytes: Array<Analytes>,
    codeBar: string,
    description: string,
    externalCodeBar: string,
    externalId: string,
    id: string,
    situation: number
}
  
export interface Analytes {
    analiteId: string,
    externalId: string,
    materialDescription: string,
    status: number
}

export const colectionOrigin: Array<Object> = [
	{
		label: 'id',
		retrive: 'id',
		method: '',
		after: '',
		before: ''
	},
	{
		label: "Requisição",
		method: 'formatField',
		retrive: '',
		after: '',
		before: '',
		fieldName: 'requisitionNumber',
		type: 'description',
	},
	{
		label: "Cliente",
		method: 'formatField',
		retrive: '',
		after: '',
		before: '',
		fieldName: 'customerName',
		type: 'description',
	},
	{
		label: 'Entrada',
		retrive: '',
		method: 'formatField',
		after: '',
		before: '',
		fieldName: 'createdAt',
		type: 'date',
		dateFormat: DefaultFormats.full,
		sort: 'createdAt'
	},
	{
		label: "Prioritário",
		method: 'formatField',
		retrive: '',
		after: '',
		before: '',
		fieldName: 'getPriority',
		type: 'boolean',
	},
	{
		label: "Coletado Por",
		method: 'formatField',
		retrive: '',
		after: '',
		before: '',
		fieldName: 'personInCollectName',
		type: 'description',
	},
	{
		label: "Espera",
		method: 'formatField',
		retrive: '',
		after: '',
		before: '',
		fieldName: 'waitingTime',
		type: 'description',
	},
	{
		label: "Exames",
		method: 'formatField',
		retrive: '',
		after: '',
		before: '',
		fieldName: 'analytes',
		type: 'description',
	},
];