import {
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnDestroy,
   OnInit,
   Output,
   inject
} from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { InputFilterEvent } from "./input-filter.model";
import { Location } from "@angular/common";
import { DataComponent } from "@app/modules_new/common/components/data";
import { SvgSelectorUIComponent } from "../../ui/svg-selector/svg-selector.component";

@Component({
   selector: "forms-input-filter",
   templateUrl: "./input-filter.component.html",
   styleUrls: ["./input-filter.component.scss"],
   standalone: true,
   imports: [SvgSelectorUIComponent, ReactiveFormsModule]
})
export class InputFilterFormComponent
   extends DataComponent
   implements OnInit, OnDestroy
{
   widthImput: string = "";
   private _elementRef = inject(ElementRef);
   private _location = inject(Location);
   @Input() syncWithURLQuery = false;
   @Input() initialValue = "";
   @Input() placeholder = "";
   @Input() form = new FormControl<string>("");

   @Output() filter = new EventEmitter<InputFilterEvent>();

   ngOnInit(): void {
      if (this.initialValue) {
         this.form.setValue(this.initialValue);
      }
      if (this.syncWithURLQuery) {
         this.addSubscriptions([
            this.form.valueChanges.subscribe((value) => {
               const query = value ? `q=${value}` : "";
               this._location.replaceState(window.location.pathname, query);
            })
         ]);
      }
   }

   ngAfterViewInit() {
      setTimeout(() => {
         if (
            this._elementRef.nativeElement.querySelector("#placeholder")
               .placeholder.length < 20
         ) {
            this.widthImput =
               this._elementRef.nativeElement.querySelector("#placeholder")
                  .placeholder.length +
               20 +
               "ch";
         } else {
            this.widthImput =
               this._elementRef.nativeElement.querySelector("#placeholder")
                  .placeholder.length +
               4 +
               "ch";
         }
      });
   }

   ngOnDestroy(): void {
      this.unsubscribeAll();
   }

   onEnterPressed() {
      this.filter.emit({
         type: "ENTER",
         value: this.form.value || ""
      });
   }

   onClick() {
      this.filter.emit({
         type: "CLICK",
         value: this.form.value || ""
      });
   }

   onChange(event: any) {
      if (event.key === "Enter") return;
      this.filter.emit({
         type: "KEY_PRESSED",
         value: this.form.value || ""
      });
   }
}
