import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { VaccinesAdministrationService } from '@app/services/vaccines/vaccines-administration.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { GetValue, Actions, VaccineAdm } from './vaccines-administration.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { DialogComponent } from '@app/components/form/dialog/dialog.component';

@Component({
  selector: 'app-vaccines-administration',
  templateUrl: './vaccines-administration.component.html',
  styleUrls: ['./vaccines-administration.component.scss'],
})
export class VaccinesAdministrationComponent implements OnInit {

  @ViewChild(DialogComponent) dialog!: DialogComponent

  total: number = 0;
  page: number = 1;
  index: number = 50;

  titleModal: string = "";

  editId: string = '';

  filter = {
    Search: new UntypedFormControl(),
    isActive: new UntypedFormControl(false)
  }

  formTable = {
    description: new UntypedFormControl(null)
  }

  registerVaccines: Array<object> = [];

  resolveVaccines: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Descrição",
      method: '',
      retrive: "description",
      after: '',
      before: '',
    },
    {
      label: "Criado em",
      method: 'getFormDate',
      retrive: "",
      after: '',
      before: '',
    },
    {
      label: 'Atualizado em',
      method: "getUpdated",
      retrive: '',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      method: "getActive",
      retrive: '',
      after: '',
      before: ''
    }
  ];

  constructor(
    private _vaccinesAdmService: VaccinesAdministrationService,
    private _localDatePipe: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe,
  ) { }

  getActive(item: VaccineAdm) {
    return this._activePipe.transform(item.isActive)
  }
  getFormDate(item: VaccineAdm) {
    return this._localDatePipe.transform(item.createdAt)
  }
  getUpdated(item: VaccineAdm) {
    return this._localDatePipe.transform(item.updatedAt);
  }

  ngOnInit() {
    this.get();
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let search: string = this.filter.Search.value ? this.filter.Search.value : null;
    let isActive = this.filter.isActive.value ? this.filter.isActive.value : null;

    await this._vaccinesAdmService.getPagination({ page: this.page, index: this.index, search, isActive }).then(
      (res) => {
        this.total = res.sumRecords;
        this.resolve(res.data, this.resolveVaccines);
      }).catch(
        (err) => {
          SwAlSetttings.printError(err);
        });
  }

  resolve(data: any, columns: any) {
    this.registerVaccines = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerVaccines.push(obj);
    });
  }

  async createAdmVaccine() {
    let obj = {
      description: this.formTable.description.value
    }
    await this._vaccinesAdmService.post(obj).then(
      (res) => {
        if (res.success) {
          this.get();
          SwAlSetttings.Sucesso('Administração de Vacinas Cadastrada!')
          this.dialog.closeDialog();
        }
      }).catch(
        (err) => {
          SwAlSetttings.printError(err);
        });
  }

  updateAdmVaccine() {
    let obj = {
      description: this.formTable.description.value,
    }
    this._vaccinesAdmService.put(obj, new Guid(this.editId)).then(
      (res) => {
        this.get();
        SwAlSetttings.Sucesso('Administração Editada!')
        this.dialog.closeDialog();
      }).catch(
        (err) => {
          SwAlSetttings.printError(err);
        });
  }

  delete(id: string) {
    SwAlSetttings.ALERT_CONFIRM.fire("Deseja realmente excluir a Administração de Vacinas?").then(
      async (resposta) => {
        if (resposta.isConfirmed) {
          await this._vaccinesAdmService.delete(new Guid(id)).then(
            async (res) => {
              if (res.success) {
                await this.get();
                SwAlSetttings.Sucesso("Administração excluída com sucesso!");
              }
            });
        }
      }).catch(
        (err) => {
          SwAlSetttings.printError(err);
        });
  }

  getValue(itens: GetValue) {
    this.formTable.description.setValue(itens.Descrição)
  }

  resetForms() {
    this.formTable.description.setValue('')
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.editId = emit.object.id ?? ''
        this.dialog.openModal
        this.dialog.openDialogModal(true, emit);
        this.getValue(emit.object)
        break;
      case 'Excluir':
        this.delete(emit.object.id)
        this.get();
        break;
      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    };
  }

  putActive(id: string, value: string) {
    this._vaccinesAdmService.patchActive(id, value).then((res: any) => {
      this.get()
    })
  }

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  afterClose() {
    this.resetForms()
    this.editId = ''
  }

  resetFilter() {
    this.filter.Search.reset();
    this.filter.isActive.reset();
  }
}
