import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { ViewChild, TemplateRef } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { UnityParametersService } from "./../../../../services/unity-parameters/unity-parameters.service";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AdditionalParametersService } from "@app/services/additional-parameters/additional-parameters.service";
import { GetActiveFilterPipe } from "@app/pipe/active.pipe";
import { Actions, ObjectActions } from "./unity-parameters.modal";
import { CheckboxSvgPipe } from "@app/pipe/checkbox-Svg.pipe";
import decodedToken from "@app/util/Token";
import { EnumObject } from "@app/services/enum/enum.service.model";
import { Util } from "@app/util/util";
import { Router } from "@angular/router";
import { ToastService } from "@app/services/translate/toast.service";

@Component({
   selector: "app-unity-parameters",
   templateUrl: "./unity-parameters.component.html",
   styleUrls: ["./unity-parameters.component.scss"]
})
export class UnityParametersComponentOld implements OnInit {
   @ViewChild("dialogModalAdditionalParameter")
   dialogModalAdditionalParameter!: TemplateRef<any>;

   parameterId: string = "";
   additionalParameterId: string = "";

   index: number = 50;
   page: number = 1;
   total: number = 0;

   unityParameters: Array<object> = [];
   unitys: Array<object> = [];

   hideParameters: Array<number> = [];

   additionalParameters: Array<{
      itens: any;
      sumRecords: number;
      index: number;
      page: number;
   }> = [
      {
         itens: [],
         sumRecords: 0,
         index: 50,
         page: 1
      }
   ];

   resolveAdditional = [
      {
         label: "id",
         retrive: "id",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "_hide_parameterCode",
         retrive: "parameterCode",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "unituParameters.descriptionParameters",
         retrive: "",
         method: "getDescription",
         after: "",
         before: ""
      },
      {
         label: "unituParameters.createdAt",
         retrive: "",
         method: "getCreated",
         after: "",
         before: ""
      },
      {
         label: "unituParameters.updatedAt",
         retrive: "",
         method: "getUpdated",
         after: "",
         before: ""
      },
      {
         label: "Ativo",
         retrive: "",
         method: "getActive",
         after: "",
         before: ""
      }
   ];

   resolveUnitys = [
      {
         label: "id",
         retrive: "id",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "unituParameters.company",
         retrive: "companyName",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "unituParameters.description",
         retrive: "description",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "unituParameters.createdAt",
         retrive: "",
         method: "getCreated",
         after: "",
         before: ""
      },
      {
         label: "unituParameters.updatedAt",
         retrive: "",
         method: "getUpdated",
         after: "",
         before: ""
      },
      {
         label: "Ativo",
         retrive: "",
         method: "getActive",
         after: "",
         before: ""
      }
   ];

   filter = {
      search: new UntypedFormControl(""),
      active: new UntypedFormControl(false)
   };

   promiseOptions: Array<EnumObject> = [];

   additionalDatas: Array<EnumObject> = [];

   additionalParameter = {
      name: new UntypedFormControl("")
   };

   ActionsExtra = {
      Ativo: "PutActive"
   };

   constructor(
      private _unityParametersService: UnityParametersService,
      private _additionalParametersService: AdditionalParametersService,
      private _dialog: MatDialog,
      private _localaDatePipe: LocalaDatePipe,
      private _activePipe: GetActiveFilterPipe,
      private _checkboxSvgPipe: CheckboxSvgPipe,
      private _util: Util,
      private _router: Router,
      private _toastService: ToastService
   ) {}

   async ngOnInit() {
      this.getParameters();
      this.promiseOptions = await this._util.getEnum("ETypeForecast");
      this.additionalDatas = await this._util.getEnum("EUnityParameters");
   }

   //#region Funções Globais

   closeModal() {
      this._dialog.closeAll();
   }

   getCreated(item: ObjectActions) {
      return this._localaDatePipe.transform(item.createdAt);
   }

   getUpdated(item: ObjectActions) {
      return this._localaDatePipe.transform(item.updatedAt);
   }

   //#endregion Fechando Funções Globais

   //#region Métodos de Parâmetros

   searchParameters() {
      this.page = 1;
      this.getParameters();
   }

   async getParameters(paramPage?: number[]) {
      if (paramPage) {
         this.page = paramPage[0];
         this.index = paramPage[1];
      }
      let search: string = this.filter.search.value ?? null;
      let isActive: string = this.filter.active.value
         ? this.filter.active.value
         : null;
      await this._unityParametersService
         .getPagination({
            page: this.page,
            numberRegistry: this.index,
            search,
            isActive,
            companyId: decodedToken.cid
         })
         .then((res: any) => {
            this.unityParameters = [];
            this.total = res.sumRecords;
            if (res.data.length != 0) {
               this.unityParameters = this.resolve(
                  res.data,
                  this.resolveUnitys
               );
            }
         })
         .catch((err: any) => {
            this._toastService.fireError(err);
         });
   }

   resolve(data: any, columns: any) {
      let arrayToFilter: Array<object> = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         arrayToFilter.push(obj);
      });
      return arrayToFilter;
   }

   getDescription(obj: any) {
      if (obj.parameterCode) {
         let translations: Array<string> = [];
         this.additionalDatas.map((additionalDatas) => {
            translations.push(additionalDatas.label);
         });
         return translations[obj.parameterCode];
      }
      return;
   }

   getActive(item: ObjectActions) {
      return this._activePipe.transform(item.isActive);
   }

   navigateToSaveUnityParameters() {
      this._router.navigateByUrl("Parametros/Parametros-Unidade/Novo");
   }

   actions(emit: Actions) {
      switch (emit.action) {
         case "Editar":
            this._router.navigateByUrl(
               "Parametros/Parametros-Unidade/" + emit.object.id + "$Edit"
            );
            break;
         case "OpenDropdown":
            this.getAdditionalParameter(emit.id);
            break;
         case "PutActive":
            this.putActive(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
      }
   }

   putActive(id: string, value: string) {
      this._unityParametersService
         .patchActive(id, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getParameters();
         })
         .catch((err) => this._toastService.fireError(err));
   }

   //#endregion Fechando Métodos de Parâmetros

   async getOptionsAdditionalParameter(parameterId: string) {
      await this._additionalParametersService
         .getPagination({ parameterUnityId: parameterId })
         .then((res) => {
            this.hideParameters = [];
            res.data.forEach((x: any) => {
               this.hideParameters.push(x.parameterCode);
            });
         });
   }

   async getAdditionalParameter(parameterId: string) {
      await this._additionalParametersService
         .getPagination({ parameterUnityId: parameterId })
         .then((res) => {
            this.additionalParameters[parameterId as any] = {
               itens: [],
               sumRecords: 0,
               index: 50,
               page: 1
            };
            if (res.data.length > 0) {
               this.additionalParameters[parameterId as any] = {
                  itens: this.resolve(res.data, this.resolveAdditional),
                  sumRecords: res.sumRecords,
                  index: 50,
                  page: 1
               };
            } else {
               this._toastService.warningMessage(
                  "unituParameters.msgNotParameters"
               );
            }
         });
   }

   async openDialogModalAdditionalParameter(parameterId: string) {
      this.parameterId = parameterId;
      await this.getOptionsAdditionalParameter(parameterId);
      if (this.hideParameters.length === this.additionalDatas.length) {
         this._toastService.warningMessage("unituParameters.msgAllParameters");
      } else {
         const myTempDialog = this._dialog.open(
            this.dialogModalAdditionalParameter,
            {
               width: "1200px"
            }
         );
         myTempDialog.afterClosed().subscribe((res) => {
            this.parameterId = "";
            this.additionalParameterId = "";
            this.additionalParameter.name.reset();
         });
      }
   }

   postAdditionalParameter() {
      let obj = {
         parameterCodes: this.additionalParameter.name.value,
         parameterId: this.parameterId
      };
      this._additionalParametersService.post(obj).then((res: any) => {
         this._toastService.fireSuccess("unituParameters.msgPost");
         this.closeModal();
         this.getAdditionalParameter(this.parameterId);
      });
   }

   actionsCollection(emit: Actions, parameterId: string) {
      switch (emit.action) {
         case "PutActive":
            this.parameterId = parameterId;
            this.putActiveAdditionParameters(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
      }
   }

   putActiveAdditionParameters(id: string, value: string) {
      this._additionalParametersService
         .patchActive(id, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getAdditionalParameter(this.parameterId);
            this.parameterId = "";
         })
         .catch((err) => this._toastService.fireError(err));
   }

   resetFilter() {
      this.filter.active.reset();
      this.filter.search.reset();
   }
}
