import { Injectable, inject } from "@angular/core";
import { AgreementNewService } from "@app/modules_new/admin/data/services/agreement/agreement.service.spec";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import {
   AgreementOption,
   AgreementResponse,
   AgreementsResponse
} from "@app/modules_new/admin/data/services/agreement/agreement.service";
import decodedToken, { decodedSessionToken } from "@app/util/Token";
import { AgreementSupportNewService } from "@app/modules_new/admin/data/services/agreement-support/agreement-support-new.service.spec";
import {
   AgreementSupportResponse,
   AgreementsSupportResponse
} from "@app/modules_new/admin/data/services/agreement-support/agreement-support-new.service";
import { Guid } from "@app/util/guid";
import { Router } from "@angular/router";
import { QueryParams } from "../../../data/services/types/http.types";
import { UsersPublicService } from "@app/modules_new/admin/data/services/auth-users.service";
import { lastValueFrom } from "rxjs";
import { RequestOptions } from "@app/modules_new/data/types/http.types";

@Injectable({
   providedIn: "root"
})
export class AgreementSuppportFacade extends MultiObservableFacade {
   private _agreementNewService = inject(AgreementNewService);
   private _agreementSupportNewService = inject(AgreementSupportNewService);
   private _userService = inject(UsersPublicService);
   private _router = inject(Router);

   private _agreement =
      this.createNullableObservableControl<AgreementResponse[]>();
   private _agreementsSupport =
      this.createNullableObservableControl<AgreementsSupportResponse[]>();
   private _agreementsSupportAmount = this.createObservableControl<number>(0);

   getAgreementsOptions() {
      const agreementOpt: Array<AgreementOption> = [];
      const query = {
         isActive: true
      };
      this._agreementNewService
         .getPagination<AgreementsResponse>(1, 999999, { query })
         .subscribe({
            next: (response) => {
               this._agreement.subject.next(response.data);
               if (response.data.length > 0) {
                  response.data.forEach((agreement) => {
                     agreementOpt.push({
                        value: agreement.id,
                        label:
                           agreement.description + " - " + agreement.externalId
                     });
                  });
               }
            },
            error: (error) => {
               this.ToastErrorGeneric(error);
            }
         });
      return agreementOpt;
   }

   getAgreementOptions(queryParams: QueryParams) {
      return new Promise((resolve) =>
         this._agreementNewService
            .get<AgreementsResponse>({
               query: { ...queryParams, resume: true }
            })
            .subscribe({
               next: (response) =>
                  resolve(
                     response.data.map((agreement) => ({
                        value: agreement.id,
                        label:
                           agreement.description +
                           (agreement.externalId
                              ? " - " + agreement.externalId
                              : ""),
                        externalId: agreement.externalId ?? "",
                        controlPlan: agreement.controlPlan
                     }))
                  ),
               error: (error) => {
                  this.ToastErrorGeneric(error);
               }
            })
      );
   }

   /**
    * @param search Informe o que deseja pesquisar
    * @param isUsingCode Controla se a pesquisa sera feita por ExternalCode/true/Código e search/false/Descrição
    * @returns Retorna os Convênios contendo de label, value, information, externalId e controlPlan
    */
   async getFilterAgreement(
      search: string,
      isUsingCode: boolean,
      resume: boolean | null = null
   ) {
      let filter;
      if (isUsingCode)
         filter = {
            query: {
               ExternalCode: search,
               isActive: true,
               resume: resume ? true : null
            }
         };
      else
         filter = {
            query: {
               search: search,
               isActive: true,
               resume: resume ? true : null
            }
         };
      console.log(filter);
      try {
         const res: any = await lastValueFrom(
            this._agreementNewService.get(filter)
         );
         const agreementObj: Array<any> = [];

         if (res.data.length > 0) {
            agreementObj.push(
               ...res.data.map((agreement: any) => ({
                  value: agreement.id,
                  label: agreement.description,
                  information: agreement.information,
                  externalId: agreement.externalId,
                  controlPlan: agreement.controlPlan,
                  typeInvoiceDescription: agreement.typeInvoiceDescription
               }))
            );
            return agreementObj;
         } else {
            this.warningMessage("requisitionMedicalRequest.msgInfo");
            return [];
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }
   }

   async getCompanyOptions(
      isAddAll: boolean = false,
      tenantId: String | null = null
   ) {
      let tenant = null;
      if (tenantId) {
         tenant = tenantId;
      } else {
         tenant = decodedToken
            ? decodedToken.tid
            : sessionStorage.getItem("TenantId")!;
      }
      var request: RequestOptions = {
         path: "/companies",
         query: {
            accessCode: decodedToken
               ? decodedToken.email
               : decodedSessionToken.email,
            isActive: true
         },
         headers: {
            TenantId: tenant
         }
      };
      const companies: any = [];
      if (isAddAll)
         companies.push({
            value: null,
            label: "Todos",
            unities: [
               {
                  value: null,
                  label: "Todos"
               }
            ]
         });
      try {
         const res: any = await lastValueFrom(this._userService.get(request));
         if (res.data.length > 0) {
            res.data.forEach((res: any) => {
               const company = {
                  value: res.id,
                  label: res.companyName,
                  unities: res.unities.map((units: any) => ({
                     value: units.id,
                     label: units.name
                  }))
               };

               companies.push(company);
            });

            return companies;
         } else {
            return [];
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }
   }

   getAgreementSupport(
      page: number,
      numberRegistry: number,
      search?: QueryParams
   ) {
      this._agreementSupportNewService
         .getPagination<AgreementSupportResponse>(page, numberRegistry, {
            query: search
         })
         .subscribe({
            next: (response: any) => {
               this._agreementsSupport.subject.next(response.data);
               this._agreementsSupportAmount.subject.next(response.sumRecords);
            },
            error: (error) => {
               this.ToastErrorGeneric(error);
            }
         });

      return {
         data$: this._agreementsSupport.observable$,
         total$: this._agreementsSupportAmount.observable$
      };
   }

   getAgreementSupportById(id: string) {
      return new Promise((resolve) => {
         this._agreementSupportNewService
            .getById<AgreementsSupportResponse>(new Guid(id))
            .subscribe({
               next: (response) => {
                  resolve(response.data[0]);
               },
               error: (error) => {
                  this.ToastErrorGeneric(error);
               }
            });
      });
   }

   createAgreementSupport(createObj: any) {
      this._agreementSupportNewService.post(createObj).subscribe({
         next: () => {
            this.ToastSuccess("register.agreemente-support.create");
            this.navigateToCreateDoctor();
            this.getAgreementSupport(1, 30);
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }

   updateAgreementSupport(createObj: any, id: string) {
      this._agreementSupportNewService.put(createObj, new Guid(id)).subscribe({
         next: () => {
            this.ToastSuccess("register.agreemente-support.update");
            this.navigateToCreateDoctor();
            this.getAgreementSupport(1, 30);
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.change.error");
         }
      });
   }

   navigateToCreateDoctor() {
      this._router.navigateByUrl("Cadastros/Concent-Apoio/Apoiados");
   }

   async patchActive(id: string, value: string) {
      let success = false;
      try {
         const response: any = await lastValueFrom(
            this._agreementSupportNewService.patchToggleIsActive(id, !value)
         );
         if (response.success) {
            this.ToastSuccess(
               !value === true
                  ? "toast-msg.change-to-active.success"
                  : "toast-msg.change-to-inactive.success"
            );
            success = response.success;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      //@ts-ignore
      let data = this._agreementsSupport.subject._value;
      let index = data.findIndex((x: any) => x.id == id);

      if (success) {
         data[index].isActive = !value;
      } else {
         data[index].isActive = value;
      }
      this._agreementsSupport.subject.next(data);
      return success;
   }
}
