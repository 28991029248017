<ng-container>
  <div *ngIf="showContent">
    <div class="modal" ngClass="{{this.size}}" @modal>
      <div class="flex" style="justify-content: space-between">
        <h2 class="title">{{title}}</h2>
        <span class="close" (click)="close();">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.384626 3.0527L18.0769 20.745L20.3846 18.4373L2.69232 0.745004L0.384626 3.0527Z" fill="#BFBFBF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6923 0.744995L0 18.4373L2.30769 20.745L20 3.05269L17.6923 0.744995Z" fill="#BFBFBF"/>
            </svg>
        </span>
      </div>
      <ng-content></ng-content>
    </div>
    <div class="overlay" (click)="toggle()" @overlay></div>
  </div>
</ng-container>