import { Location } from "@angular/common";
import { Injectable, inject } from "@angular/core";
import { MultiObservableFacade } from "../../../common/data/facades/multi-observable";
import { AdminUserAuth } from "@app/services/auth/admin/user-auth.service";
import { MatDialog } from "@angular/material/dialog";
import { TenantAuthNewService } from "../services/tenantAuthNew/tenant-auth-new.service";
import {
   ClientNewRequest,
   TenantResponse,
   TenantsResponse
} from "../services/tenantAuthNew/tenant-auth-new.types";
import {
   QueryParams,
   RequestOptions
} from "../../../data/services/types/http.types";
import { UserResponse } from "../services/accesscontrol-user-authNew/accesscontrol-user-authNew.types";
import { SwAlSetttings } from "@app/util/swal.settings";
import { Guid } from "@app/util/guid";
import { UsersAdminUsersFacade } from "./users-admin-users.facade";
import { lastValueFrom } from "rxjs";

@Injectable({
   providedIn: "root"
})
export class UsersFacade extends MultiObservableFacade {
   private controls = this.controlGroup({
      users: this.emptyObservable(),
      usersAmount: this.emptyObservable()
   });

   private _systemClient =
      this.createNullableObservableControl<TenantResponse>();
   private _usersFacade = inject(UsersAdminUsersFacade);
   private _tenantService = inject(TenantAuthNewService);
   private _adminUserAuth = inject(AdminUserAuth);
   private _dialog = inject(MatDialog);
   private _location = inject(Location);
   private _tenants = this.createNullableObservableControl<TenantResponse[]>();
   private _tenantsAmount = this.createObservableControl<number>(0);

   getTenants(page: number, numberRegistry: number, search?: QueryParams) {
      this._tenants.subject.next(null);
      this._tenantsAmount.subject.next(0);
      this._tenantService
         .getPagination<TenantsResponse>(page, numberRegistry, {
            query: search
         })
         .subscribe({
            next: (response) => {
               this._tenants.subject.next(response.data);
               this._tenantsAmount.subject.next(response.sumRecords);
            },
            error: (err) => {
               this._tenants.subject.next([]);
               this._tenantsAmount.subject.next(0);
            }
         });
      return {
         data$: this._tenants.observable$,
         total$: this._tenantsAmount.observable$
      };
   }

   postUser(obj: any, tenantId: string) {
      this._adminUserAuth
         .postWithTenant(obj, tenantId)
         .then((x: any) => {
            SwAlSetttings.Sucesso("Usuário cadastrado com sucesso!");
            this._dialog.closeAll();
            const obj: RequestOptions = {
               query: { page: 1, numberRegistry: 10 },
               path: "",
               headers: { TenantId: tenantId }
            };
            this._usersFacade.getUsers(1, 10, tenantId, obj);
         })
         .catch((err: any) => {
            SwAlSetttings.printError(err);
         });
   }

   resetPassword(obj: any, id: string) {
      this._adminUserAuth.resetPassword(obj, id).then((x: any) => {
         SwAlSetttings.Sucesso("Senha Resetada com Sucesso!");
         this._dialog.closeAll();
      });
   }

   createNewTenant(request: ClientNewRequest) {
      this._tenantService.post(request).subscribe({
         next: () => {
            this.ToastSuccess("toast-msg.change.success");
            this.backPage();
         },
         error: (error) => {
            this.ToastErrorGeneric(error);
         }
      });
   }

   updateTenant(id: Guid, request: ClientNewRequest) {
      this._tenantService.put(request, id).subscribe({
         next: () => {
            this.ToastSuccess("toast-msg.change.success");
            this.backPage();
         },
         error: (error) => {
            this.ToastErrorGeneric(error);
         }
      });
   }

   backPage() {
      this._location.back();
   }

   getClientById(id: Guid) {
      this._tenantService.getById<TenantsResponse>(id).subscribe({
         next: (response) => {
            this._systemClient.subject.next(response.data[0]);
         }
      });

      return {
         data$: this._systemClient.observable$
      };
   }

   async patchActive(id: string, value: string) {
      let success = false;
      try {
         const response: any = await lastValueFrom(
            this._tenantService.patchToggleIsActive(id, !value)
         );
         if (response.success) {
            this.ToastSuccess(
               !value === true
                  ? "toast-msg.change-to-active.success"
                  : "toast-msg.change-to-inactive.success"
            );
            success = response.success;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      //@ts-ignore
      let data = this._tenants.subject._value;
      let index = data.findIndex((x: any) => x.id == id);

      if (success) {
         data[index].isActive = !value;
      } else {
         data[index].isActive = value;
      }
      this._tenants.subject.next(data);
      return success;
   }
}
