import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpService } from '../http.service';
import { QueryParams } from '../interfaces/services.types';
import { HttpResponsePageable } from '../services.types';

@Injectable({
  providedIn: 'root'
})
export class EnumService extends BaseService {

constructor(HttpService: HttpService) {
  super(HttpService, 'v1/enum');
 }

 override getPagination<T>(queryObject?: QueryParams | undefined){
  return super.getPagination(queryObject) as Promise<HttpResponsePageable<T>> 
}

}
