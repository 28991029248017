import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { GetAllRequisitions, GetByIdRequisition, PostRequisition, PutRequisition } from './requisition.types';
import { HttpPostResponse, HttpResponsePageable } from '../services.types';
import { Guid } from 'src/app/util/guid';

@Injectable({
  providedIn: 'root'
})

export class RequisitionService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/requisition');
  }
  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllRequisitions>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetAllRequisitions>>
  }

  override getById(id: Guid) {
    return super.getById(id) as Promise<HttpResponsePageable<GetByIdRequisition>>
  }

  override get(queryString: string) {
    return super.get(queryString) as Promise<HttpResponsePageable<GetAllRequisitions>>
  }

  override post(body: Object) {
    return super.post(body) as Promise<HttpPostResponse<PostRequisition>>
  }

  override put(body: Object, id:Guid, path?:string) {
    return super.put(body, id, path) as Promise<HttpPostResponse<PutRequisition>>
  }

  override putWithPath(body: Object, id:Guid, path?:string) {
    return super.putWithPath(body, id, path) as Promise<HttpPostResponse<PutRequisition>>
  }
}


