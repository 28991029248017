import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { QueryParams } from '../interfaces/services.types';
import {  CollectionPlaceInstructionPost, CollectionPlaceInstructionPut } from './collection-place-instruction.service.module';
import { Guid } from '@app/util/guid';

@Injectable({
  providedIn: 'root'
})
export class CollectionPlaceInstructionService extends BaseService {

  constructor(
    private http: HttpClient,
    httpService: HttpService
  ) {
    super(httpService, 'v1/lab/attendance/collectionplaceinstruction');
  }

  override post(body: CollectionPlaceInstructionPost) {
    return super.post(body);
  }

  override put(body: Object, id?: Guid | undefined, path?: string | undefined) {
    return super.put(body, id, path) as Promise<HttpResponsePageable<CollectionPlaceInstructionPut>>
  }

  override getPagination<CollectionPlaceInstructionPagination>(queryObject?: QueryParams | undefined){
    return super.getPagination(queryObject) as Promise<HttpResponsePageable<CollectionPlaceInstructionPagination>> 
  }

}
