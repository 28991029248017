import { Injectable, inject } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import { QueryParams, RequestOptions } from "../../../data/types/http.types";
import { Guid } from "@app/util/guid";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { DoctorNewService } from "../services/doctor/doctor.service";
import {
   DoctorByIdResponse,
   DoctorObject,
   DoctorResponse,
   DoctorsResponse
} from "../services/doctor/doctor.types";
import { lastValueFrom, Observable } from "rxjs";

@Injectable({
   providedIn: "root"
})
export class DoctorFacade extends MultiObservableFacade {
   private _doctorNewService = inject(DoctorNewService);
   private _doctors = this.createNullableObservableControl<DoctorResponse[]>();
   private _doctor = this.createNullableObservableControl<DoctorByIdResponse>();
   private _doctorsAmount = this.createObservableControl<number>(0);
   private _dialog = inject(MatDialog);
   private _router = inject(Router);

   getDoctor(page: number, numberRegistry: number, search?: QueryParams) {
      this._doctorNewService
         .getPagination<DoctorsResponse>(page, numberRegistry, {
            query: search
         })
         .subscribe({
            next: (response) => {
               this._doctors.subject.next(response.data);
               this._doctorsAmount.subject.next(response.sumRecords);
            },
            error: (error) => {
               this._doctors.subject.next([]);
               this._doctorsAmount.subject.next(0);
               this.ToastErrorGeneric(error);
            }
         });

      return {
         data$: this._doctors.observable$,
         total$: this._doctorsAmount.observable$
      };
   }

   getDoctorById(doctorId: string) {
      this._doctorNewService
         .getById<DoctorByIdResponse>(new Guid(doctorId))
         .subscribe({
            next: (response: any) => {
               this._doctor.subject.next(response.data[0]);
            },
            error: (error) => {
               this.ToastErrorGeneric(error);
            }
         });
      return {
         data$: this._doctor.observable$
      };
   }

   async getOptionById(id: string) {
      try {
         const response: any = await lastValueFrom(
            this._doctorNewService.getById(new Guid(id))
         );
         const obj: any = [];
         if (response.data) {
            obj.push({
               value: response.data[0].id,
               label: response.data[0].name,
               registrationNumber: response.data[0].registrationNumber
            });
            return obj;
         }
         return [];
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }
   }

   createDoctor(createObj: DoctorObject) {
      return new Promise((resolve) => {
         this._doctorNewService.post(createObj).subscribe({
            next: (response: any) => {
               this.ToastSuccess("register.service.doctor.post");
               resolve(response.id);
            },
            error: (error) => {
               this.ToastErrorGeneric(error, "toast-msg.creation.error");
            }
         });
      });
   }

   updadeDoctor(updateObj: DoctorObject, id: string) {
      this._doctorNewService.put(updateObj, new Guid(id)).subscribe({
         next: () => {
            this.ToastSuccess("register.service.doctor.put");
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.change.error");
         }
      });
   }

   closeModal() {
      this._dialog.closeAll();
   }

   patchActive(customerId: string, value: string) {
      return new Promise((resolve) =>
         this._doctorNewService
            .patchToggleIsActive(customerId, !value)
            .subscribe({
               next: (res) => {
                  this.ToastSuccess("toast-msg.change.success");
                  resolve(res);
               },
               error: (error) => {
                  this.ToastErrorGeneric(error, "toast-msg.change.error");
               }
            })
      );
   }

   navigateToCreateDoctor() {
      this._router.navigateByUrl("Cadastros/Atendimento/Solicitantes");
   }

   deleteDoctor(id: string) {
      this.ToastPopUp("Deseja realmente excluir esse solicitante?").then(
         (r) => {
            if (r.isConfirmed) {
               this._doctorNewService.delete(new Guid(id)).subscribe({
                  next: () => {
                     this.ToastSuccess("register.service.doctor.delete");
                     this.getDoctor(1, 30, { QueryParams: "" });
                  },
                  error: (error) => {
                     this.ToastErrorGeneric(error, "toast-msg.remove.error");
                  }
               });
            }
         }
      );
   }

   getDoctors(query: QueryParams = {}): Observable<DoctorsResponse> {
      return this._doctorNewService.get({ query });
   }

   async getDoctorOptionsByCode(
      search: string,
      isUsingCode: boolean
   ): Promise<any[]> {
      let filter;
      if (isUsingCode)
         filter = {
            query: { RegistrationNumber: search, isActive: true }
         };
      else
         filter = {
            query: { name: search, isActive: true }
         };
      try {
         const response = await lastValueFrom(
            this._doctorNewService.get<DoctorsResponse>(filter)
         );
         const doctors: Array<any> = [];
         if (response.data.length > 0) {
            doctors.push(
               ...response.data.map((doc: any) => ({
                  value: doc.id,
                  label: `${doc.name} - ${doc.council}/${doc.registrationState}: ${doc.registrationNumber}`,
                  registrationNumber: doc.registrationNumber
               }))
            );
            return doctors;
         } else {
            return [];
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }
   }

   async getDoctorOptions(search: string | null = null): Promise<any[]> {
      const request: RequestOptions = {
         query: { Search: search, resume: true }
      };
      try {
         const response = await lastValueFrom(
            this._doctorNewService.get<DoctorsResponse>(request)
         );

         if (response.data.length > 0) {
            const doctors = response.data.map((doctor: any) => ({
               value: doctor.id,
               label: `${doctor.name} - ${doctor.council}/${doctor.registrationState}: ${doctor.registrationNumber}`,
               registrationNumber: doctor.registrationNumber
            }));
            return doctors;
         } else {
            return [];
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }
   }
}
