import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { TemplateGeneratorComponent } from "./components/template-generator/template-generator.component";

const routes: Routes = [
   {
      path: "ParametrosOld",
      children: [
         { path: "", redirectTo: "ParametrosOld", pathMatch: "full" },
         {
            path: "Configurador-Protocolo",
            component: TemplateGeneratorComponent
         }
      ]
   }
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule]
})
export class ParametersRoutingModule {}
