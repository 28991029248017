import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../http.service';
import { HttpResponsePageable } from '../services.types';
import { Guid } from '@app/util/guid';


@Injectable({
  providedIn: 'root'
})
export class DeadLineService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/movimentation/advancedmaintenance/deadline');
  }
}