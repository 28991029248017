import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { AnalyteService } from '@app/services/analyte/analyte.service';
import { PriceTableExamsService } from '@app/services/price-table-exams/price-table-exams.service';
import { PriceTableService } from '@app/services/pricetable/pricetable.service';
import { PriceTableExams } from '@app/services/pricetable/pricetable.types';
import { Guid } from '@app/util/guid';
import { Actions, ResolvedObject, ResolvedExam } from './price-table.model'
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { formatCurrency } from '@angular/common';
import { InputSearchComponent } from '@app/components/form/input-search/input-search.component';
import { ToastService } from '@app/services/translate/toast.service';

@Component({
  selector: 'app-price-table',
  templateUrl: './price-table.component.html',
  styleUrls: ['./price-table.component.scss']
})
export class PriceTableComponent implements OnInit {

  @ViewChild('analyteIdForm') analyteIdForm!: InputSearchComponent

  optionsExams: Array<{value: string, label: string, code: string}> = [];

  optionsCode: Array<Object> = [];

  idPriceTable = "";

  idExam = "";

  generalListExams: Array<{ id: string, priceTableExams: Array<PriceTableExams>, totalExams: number, indexPage: number }> = [];
  
  generalListExams1: Array<{
    index: number,
    total: number,
    priceTableExams: Array<Object>
  }> = [];

  total: number = 0;
  index: number = 50;
  page: number = 1;

  totalExams: number = 0;
  indexExams: number = 10;
  pageExams: number = 1;

  filter = new UntypedFormGroup({
    search: new UntypedFormControl(null),
    isActive: new UntypedFormControl(false)
  })

  filterPriceExames =  new UntypedFormGroup({
    AnalyteCode: new UntypedFormControl(null),
    AnalyteName: new UntypedFormControl(null),
    isActive: new UntypedFormControl(false)
  })

  formPriceTable = {
    isActive: new UntypedFormControl(null),
    description: new UntypedFormControl(null),
    mask: new UntypedFormControl(null),
    index: new UntypedFormControl(1),
  }

  formExams: UntypedFormGroup = new UntypedFormGroup({
    analyteDescription: new UntypedFormControl(null),
    code: new UntypedFormControl(null),
    isActive: new UntypedFormControl(null),
    priceTableId: new UntypedFormControl(null),
    billingCode: new UntypedFormControl(null),
    analyteId: new UntypedFormControl(null),
    price: new UntypedFormControl(null),
    isBloqued: new UntypedFormControl(false),
    isSuspended: new UntypedFormControl(false),
  })

  registerTable: Array<Object> = [];

  resolveTable: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "priceTable.table.description",
      method: '',
      retrive: 'description',
      after: '',
      before: '',
    },
    {
      label: "priceTable.table.index",
      method: 'formatIndex',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "priceTable.table.mask",
      method: 'mask',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "_hide_mask",
      method: '',
      retrive: 'mask',
      after: '',
      before: '',
    },
    {
      label: 'priceTable.table.createdAt',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'priceTable.table.updatedAt',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: "Ativo",
      method: 'getActive',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "_hide_isActive",
      method: '',
      retrive: 'isActive',
      after: '',
      before: '',
    }
  ];
  
  ActionsExtra = {
    "Ativo": "PutActive"
  }

  ActionsExtraPrice = {
    "Ativo": "PutActivePrice"
  }

  registerExams: Array<Object> = [];

  constructor(
    private _dialog: MatDialog,
    private _priceTableService: PriceTableService,
    private _priceTableExamsService: PriceTableExamsService,
    private _analyteService: AnalyteService,
    private _createdAt: LocalaDatePipe,
    private _updatedAt: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _toastService: ToastService
    
  ) {}

  @ViewChild('dialogCreatePrice')
  dialogCreatePrice!: TemplateRef<any>
  @ViewChild('dialogCreateExams')
  dialogCreateExams!: TemplateRef<any>

  async ngOnInit() {
    await this.getPriceTables();
  }

  formatIndex(item: ResolvedObject) {
    return item.index.toFixed(4);
  }

  close() {
    this._dialog.closeAll();
  }



  updatePriceTable(id: string){
    let bodyObject = {
      isActive: JSON.parse(this.formPriceTable.isActive.value),
      description: this.formPriceTable.description.value,
      mask: this.formPriceTable.mask.value,
      index: parseFloat(this.formPriceTable.index.value),
    }

    this._priceTableService.put(bodyObject, new Guid(id)).then(
      (res) => {
        if (res.success) {
          setTimeout(async () => {
            await this.getPriceTables();
            this._toastService.fireSuccess("priceTable.msgPutPlan");
            this.close();
          });
        }
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    )
  }

  async createPriceTable(){
    let obj = {
      description: this.formPriceTable.description.value,
      mask: this.formPriceTable.mask.value,
      index: parseFloat(this.formPriceTable.index.value),
    }
    await this._priceTableService.post(obj).then(
      (res) => {
        setTimeout(async () => {
          await this.getPriceTables();
          this._toastService.fireSuccess("priceTable.msgPostPlan");
          this.close();
        });
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    )
  }

  resolvePriceTable(data: any, columns: any) {
    this.registerTable = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerTable.push(obj);
    });
  }

  async getPriceTables(paramPage?: number[]){
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let search = this.filter.controls["search"].value ?? null

    let isActive = this.filter.controls["isActive"].value ? this.filter.controls["isActive"].value : null;

    await this._priceTableService.getPagination({page: this.page, index: this.index, search, isActive}).then(
      (res) => {
        this.total = res.sumRecords;
        this.resolvePriceTable(res.data, this.resolveTable);
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    );
  }

  async deletePriceTable(id : Guid) {
    this._toastService.fireConfirmation('priceTable.msgDeleteTable').then(
      async (answer) => {
        if (answer.isConfirmed) {
          await this._priceTableService.delete(id);
          await this.getPriceTables();
          this._toastService.fireSuccess('priceTable.msgDeleteTableSuccess');
        }
      }
    )
  }

  toDate(property: string) {
    return this._createdAt.transform(property);
  }

  getCreated(item: ResolvedExam) {
    return this._createdAt.transform(item.createdAt);
  }

  getUpdated(item: ResolvedObject) {
    return this._updatedAt.transform(item.updatedAt);
  }

  getActive(item: ResolvedObject) {
    return this._activePipe.transform(item.isActive)
  }

  mask(item: ResolvedObject) {
    return item.mask ?  item.mask : '<span class="span-styles" style="background: #FFC000; color: #FFFFFF;">Sem Máscara</span>'
  }

  async actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.openDialogPriceTable(emit.object.id);
        this.formPriceTable.isActive.setValue(emit.object['_hide_isActive']);
        this.formPriceTable.description.setValue(emit.object['priceTable.table.description']);
        this.formPriceTable.mask.setValue(emit.object['_hide_mask'] != "null" ? emit.object['_hide_mask'] : "");
        this.formPriceTable.index.setValue(emit.object['priceTable.table.index']);
        break;
      case 'Excluir':
        await this.deletePriceTable(new Guid(emit.object.id));
        await this.getPriceTables();
        break;
      case 'OpenDropdown':
        await this.getByIdExams(emit.id);
        this.idPriceTable = emit.id
        break;
      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  putActive(id: string, value: string) {
    this._priceTableService.patchActive(id, value).then((x:any) => {
      this._toastService.fireSuccess('global.msgSituationSuccess')
      this.getPriceTables();
    }).catch(err => this._toastService.fireError(err))
  }

  resolvePriceTableExams(priceTableExams: Array<any>): Array<Object> {

    const registerPriceTableExams = priceTableExams.map((exam: ResolvedExam) => ({
      "id": exam.id,
      "priceTable.tableExams.code": exam.code,
      "priceTable.tableExams.description": exam.name,
      "priceTable.tableExams.price": formatCurrency(exam.price ?? 0, 'pt-BR', 'R$'),
      "priceTable.tableExams.invoiceCode": exam.invoiceCode ?? '',
      "priceTable.tableExams.isSuspended": exam.isSuspended ? "<span class='span-styles' style='background: #FF0000; color:#FFF'>Sim</span>" : "<span class='span-styles' style='background: #07BF56; color:#FFF'>Não</span>",
      "priceTable.tableExams.isBloqued": exam.isBloqued ? "<span class='span-styles' style='background: #FF0000; color:#FFF'>Sim</span>" : "<span class='span-styles' style='background: #07BF56; color:#FFF'>Não</span>",
      "priceTable.tableExams.createdAt": this.toDate(exam.createdAt),
      "priceTable.tableExams.updatedAt": this.toDate(exam.updatedAt),
      "Ativo": exam.isActive ? 'checkbox|true' : 'checkbox|',
      "_hide_isSuspended": exam.isSuspended,
      "_hide_isBloqued": exam.isBloqued,
      "_hide_isActive": exam.isActive,
      "_hide_peice": exam.price,
      "_hide_priceTableId": exam.priceTableId,
      "_hide_analyteId": exam.analyteId
    }))

    return registerPriceTableExams;
  }

  setFocus() {
    this.analyteIdForm.focusInput()
  }

  async getByIdExams(id : string, paramPage?: number[]) {
    if (paramPage) {
      this.pageExams = paramPage[0];
      this.indexExams = paramPage[1];
    }

    const query = {
      priceTableId: id,
      index: this.indexExams,
      page: this.pageExams,
      AnalyteCode: this.filterPriceExames.controls["AnalyteCode"].value ?? null, 
      AnalyteName: this.filterPriceExames.controls["AnalyteName"].value ?? null,
      isActive: this.filterPriceExames.controls["isActive"].value ?? null
    }

    await this._priceTableExamsService.getPagination<PriceTableExams>(query).then(
      (res) => {
        if(res.data.length > 0){
          const resolved = {
           index: this.indexExams,
           total: res.sumRecords,
           page: this.pageExams,
           priceTableExams: this.resolvePriceTableExams(res.data)
          };
          this.generalListExams1[id as any] = resolved
        }
      }
    )
  }

  async examsActions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.openDialogExams(emit.object.id);
        this.formExams.controls["isActive"].setValue(JSON.parse(emit.object['_hide_isActive']));
        this.formExams.controls["priceTableId"].setValue(emit.object['_hide_priceTableId']);
        this.formExams.controls["billingCode"].setValue(emit.object['priceTable.tableExams.invoiceCode']);
        this.formExams.controls["analyteDescription"].setValue(emit.object['priceTable.tableExams.description']);
        this.formExams.controls["code"].setValue(emit.object['priceTable.tableExams.code']);
        this.formExams.controls["price"].setValue(emit.object['_hide_peice']);
        this.formExams.controls["isBloqued"].setValue(JSON.parse(emit.object['_hide_isBloqued']));
        this.formExams.controls["isSuspended"].setValue(JSON.parse(emit.object['_hide_isSuspended']));
        break;
      case 'Excluir':
        this.deleteExams(emit.object.id);
        break;
      case 'PutActivePrice':
        this.putActivePrice(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
      break;
    }
  }

  putActivePrice(id: string, value: string) {
    this._priceTableExamsService.patchActive(id, value).then((x:any) => {
      this._toastService.fireSuccess('global.msgSituationSuccess')
      this.getByIdExams(this.idPriceTable);
    }).catch(err => this._toastService.fireError(err))
  }

  analyteCodeChanged() {
    const codeControl = this.formExams.controls['code'];
    let codeValue = codeControl.value || '';
    codeControl.setValue(codeValue.toUpperCase());
  
    const query = {
      resume: true,
      code: codeValue.toUpperCase()
    };  
    if (query.code.length <= 1) return;  
    this._analyteService.getPagination(query).then(
      (res) => {
        this.optionsExams = res.data.map((exam: any) => ({
          label: exam.name,
          value: exam.id,
          code: exam.code
        }));  
        if (this.optionsExams.length === 1) {
          this.formExams.controls['analyteId'].setValue(this.optionsExams[0].value);
        }
      }
    );
  }

  analyteIdChanged(){
    const analyte = this.optionsExams.find((x: any) => x.value == this.formExams.controls['analyteId'].value)
    if(analyte) this.formExams.controls['code'].setValue(analyte.code)
  }

  async getExamsOption(analyteName: string){
    const query = {
      resume: true,
      name: analyteName
    }
    this.formExams.controls['code'].reset()
    await this._analyteService.getPagination(query).then(
      (res) => {
        this.optionsExams = res.data.map((data: any) => (
          {
            label: data.name,
            value: data.id,
            code: data.code
          }
        )
      )
      if(this.optionsExams.length == 1) 
          this.formExams.controls['analyteId'].setValue(this.optionsExams[0].value)
    })
  }

  createPriceTableExam(){
    let objPostExams = {
      priceTableId: this.idPriceTable,
      billingCode: this.formExams.controls["billingCode"].value,
      analyteId: this.formExams.controls["analyteId"].value,
      price: parseFloat(this.formExams.controls["price"].value),
      isBloqued: this.formExams.controls["isBloqued"].value,
      isSuspended: this.formExams.controls["isSuspended"].value,
    }

    if(!objPostExams.analyteId) return this._toastService.warningMessage("priceTable.msgSelectExam") as any;

    this._priceTableExamsService.post(objPostExams).then(
      (res) => {
        setTimeout(async () => {
          await this.getByIdExams(this.idPriceTable);
          this._toastService.fireSuccess("priceTable.msgPostExam");
          this.close();
        });
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    )
  }

  updatePriceTableExam(id: string){
    let objEditExams = {
      isActive: this.formExams.controls["isActive"].value,
      billingCode: this.formExams.controls["billingCode"].value,
      price: parseFloat(this.formExams.controls["price"].value),
      isBloqued: this.formExams.controls["isBloqued"].value,
      isSuspended: this.formExams.controls["isSuspended"].value,
      analyteId: this.formExams.controls["analyteId"].value,
    }
    this._priceTableExamsService.put(objEditExams, new Guid(id)).then(
      (res) => {
        setTimeout(async () => {
          this._toastService.fireSuccess("priceTable.msgPutExam");
          this.close();
          await this.getByIdExams(res.id);
        });
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    )
  }

  deleteExams(id : Guid) {
    this._toastService.fireConfirmation('priceTable.msgDeleteExam').then(
      async (answer) => {
        if (answer.isConfirmed) {
          await this._priceTableExamsService.deleteByPath(undefined, `${this.idPriceTable}/exams/${id}`);
          this._toastService.fireSuccess('priceTable.msgDeleteExamSuccess');
          await this.getByIdExams(this.idPriceTable);
        }
      }
    )
  }

  openDialogPriceTable(id: string) {
    const myTempDialog = this._dialog.open(this.dialogCreatePrice, {
      width: '660px',
      data: id
    });
    myTempDialog.afterClosed().subscribe(x => {
      this.formPriceTable.description.setValue(null);
      this.formPriceTable.index.setValue(1);
      this.formPriceTable.isActive.setValue(false);
      this.formPriceTable.mask.setValue(null);
    });
  }

  openDialogExams(id: string = ''){
    const myTempDialog = this._dialog.open(this.dialogCreateExams, {
      width: '660px',
      data: id
    });
    myTempDialog.afterClosed().subscribe(x => {
      this.optionsExams = []
      this.formExams.reset()
    });
  }

  resetFilter(){
    this.filter.reset();
    this.filterPriceExames.reset();
  }
}
