<div class="contentPage">
  <div class="flex flex-between align-center">
    <div class="flex gp-12 align-center">
      <h1>Portadores</h1>
      <app-button [placeholder]="'Novo'" [matIcon]="'add'" (click)="openDialogModal()" [class]="'btn flex gp-12 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="getBearersList([1, paginationInfo.index])" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus [placeholder]="'Descrição'" [form]="searchFiltersFormGroup.controls['description']" (keydown.enter)="getBearersList([1, paginationInfo.index])"></input-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" label="Somente Ativos" [form]="searchFiltersFormGroup.controls['isActive']"></checkbox-prop>      
      </div>
        <app-button class="queryBtn" [placeholder]="'Pesquisar'" (click)="getBearersList([1, paginationInfo.index])" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table 
      [Itens]="resolvedBearerList" 
      [sumRecords]="paginationInfo.sumRecords" 
      [pageNumber]="paginationInfo.page" 
      [indexNumber]="paginationInfo.index"
      [Actions]="['Editar', 'Excluir']"
      [ClickInItem]="ActionsExtra"
      [Pagination]="true"
      [backPagination]="true"
      (changePage)="getBearersList($event)"
      (parentFun)="bearerActions($event)"></app-table>
  </div>
</div>

<ng-template #dialogModal>
  <div class="flex flex-between w-100 align-center">
    <h1>{{bearerFormGroup.controls['bearerId'].value ? 'Editar' : 'Novo'}} Portador</h1>
    <svg (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
    </svg>
  </div>
  <div class="w-100 mt-32 flex gap-form">
    <select-prop class="w-100" [placeholder]="'Empresa'" [form]="bearerFormGroup.controls['companyId']" [options]="companyOptions"></select-prop>
  </div>
  <div class="w-100 mt-32 flex gap-form">
    <input-prop class="w-100" style="flex:1" [placeholder]="'Código'" [form]="bearerFormGroup.controls['externalId']"></input-prop>
    <input-prop class="w-100" style="flex:2" [placeholder]="'Descrição'" [form]="bearerFormGroup.controls['description']"></input-prop>
  </div>
  <div class="w-100 mt-32 flex gap-form">
    <div style="flex: 1">
      <input-prop class="w-100" [placeholder]="'Banco'" [form]="bearerFormGroup.controls['nameBank']"></input-prop>
    </div>
    <div class="w-100 flex gap-form" style="flex: 2">
      <input-prop class="w-100" [placeholder]="'Agência'" [form]="bearerFormGroup.controls['agencyNumber']"></input-prop>
      <input-prop class="w-100" [placeholder]="'Conta'" [form]="bearerFormGroup.controls['accountNumber']"></input-prop>
    </div>
  </div>
  <div class="w-100 mt-32 flex">
    <checkbox-prop label="Considera Saldo no Fluxo de Caixa" [gap]="1" [form]="bearerFormGroup.controls['considersBalanceCashFlow']"></checkbox-prop>
  </div>
   <div class="flex flex-center justify-center mt-32">
    <app-button [placeholder]="'Salvar'" (click)="bearerFormGroup.controls['bearerId'].value ? updateBearer() : createBearer()" [class]="'btn flex gp-12 align-center'"></app-button>
  </div>
</ng-template>