export const DEFAULT_LANG = 'pt-BR';

export type Language = {
  name: string;
  lang: string;
};

export const LanguageOptions: Language[] = [
  { name: 'select-language.options.pt-BR', lang: 'pt-BR' },
  { name: 'select-language.options.en', lang: 'en' },
  { name: 'select-language.options.es', lang: 'es' },
];
