<app-list [title]="'Tipos De Ocorrências'">
  <div class="headerButton">
    <app-dialog [maleText]="true" [title]="'Tipo de Ocorrência'" [placeholderButton]="'global.new'" [matIconName]="'add'" (afterClose)="afterClose()">
      <div class="bodyFilter">
        <div class="mt-32 w100">
          <input-prop [placeholder]="'Tipo de Ocorrência'" [form]="form.description" type="text"></input-prop>
        </div>
        <div class="flex flex-center mt-32">
          <app-button (click)="occurrencyTypeId ? put() : post()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
        </div>
      </div>
    </app-dialog>
  </div>
  <!-- <div class="headerFilter">
    <app-filter (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query flex flex-column gap-2 align-center">
        <input-prop #autoFocus [placeholder]="'questions.name'" [form]="formFilter.name"></input-prop>
        <input-prop type="number" [placeholder]="'questions.externalId'" [form]="formFilter.externalId"></input-prop>
        <checkbox-prop class="flex" [gap]="1" [label]="'questions.isActive'" [form]="formFilter.isActive"></checkbox-prop>
        <app-button (click)="get()" class="queryBtn" [placeholder]="'questions.search'" [matIcon]="'search'" [class]="'btn flex gap-2 align-center'"></app-button>
      </div>
    </app-filter>
  </div> -->
  <div class="list">
    <app-table [Actions]="['Editar', 'Excluir']" [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true"  [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="occurrencyTypes" (parentFun)="Actions($event)" (changePage)="get($event)"></app-table>
  </div>
</app-list>
