import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { AccessControlUserAuth } from '@app/services/auth/acesscontrol-user-auth.service';
import { DepartmentAuthService } from '@app/services/auth/department.service';
import { FileDocumentAuthService } from '@app/services/auth/fileDocument-auth.service';
import { ToastService } from '@app/services/translate/toast.service';
import { GActions } from '@app/util/GActions';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor(private _activePipe: GetActiveFilterPipe, private UsersService: AccessControlUserAuth, private FileDocument: FileDocumentAuthService,
    private DepartmentService : DepartmentAuthService,private router: Router, private PersonService: AccessControlUserAuth, private dialog: MatDialog, 
    private _sanitizer: DomSanitizer, private _checkboxSvgPipe: CheckboxSvgPipe, private _toastService: ToastService) {
  }

  itens: Array<Object> = [];

  situation = {
      "0": "<span class=\"situation elaboracao\">Elaboração</span>",
      "1": "<span class=\"situation revisao\">Revisão</span>",
      "2": "<span class=\"situation aprovacao\">Aprovação</span>",
      "3": "<span class=\"situation aprovacao\">Aprovado</span>",
      "4": "<span class=\"situation obsoleto\">Obsoleto</span>",
  };

  DepartmentId: string = '';
  total : number = 0;
  page : number = 1;
  index : number = 30;

  formRec = {
      name: new UntypedFormControl(null),
  }

  form = {
      search: new UntypedFormControl(null),
  }

  resolveItens =
      [
        {
            label: 'id',
            retrive: "id",
            after: '',
            before: '',
        },
        {
            label: "Nome",
            retrive: "name",
            after: '',
            before: '',

        },
        {
            label: "E-mail/Código de acesso",
            retrive: "accessCode",
            after: '',
            before: '',
        },
        {
            label: "Telefone",
            retrive: "",
            method: 'validPhone',
            after: '',
            before: '',
        },
        {
            label: 'Ativo',
            retrive: '',
            method: 'getActive',
            after: '',
            before: ''
        }
      ];

  Search = new UntypedFormControl();

  ActionsExtra = {
    "Ativo": "PatchActive"
  }

  
  ngOnInit(): void {
      this.get();
  }

  getFake() {
      return '--'
  }

  get(paramPage? : number[]): void {
      this.page = 1;

      if (paramPage) {
          this.page = paramPage[0];
          this.index = paramPage[1];
      }

      let search: string = this.form.search.value? this.form.search.value : null;

      this.PersonService.getPagination(({ page: this.page, numberRegistry: this.index, search })).then(
          async (res: any) => {
              this.itens = [];
              this.total = res.sumRecords;
              if (res.data.length != 0) {
                  await this.Resolve(res.data, this.resolveItens);
              } else {
                  SwAlSetttings.alertaMsg('Não há usuários cadastrados!');
              }
          }
      ).catch(
          (err: ErrorEvent) => {
              SwAlSetttings.printError(err);
          }
      );
  }

  getUserById(id : string) {
      this.PersonService.getById(new Guid(id)).then(async (x:any) => {
          const ind = this.itens.findIndex((y: any) => y.id === id);
          // @ts-ignore
          this.itens[ind].extra = x.data[0];
          if(x.data[0].fileImageId) {
              var image = await this.FileDocument.getById(x.data[0].fileImageId);
              // @ts-ignore
              this.itens[ind].extra.imageBase64 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
                  //@ts-ignore
                  + image.data[0].fileBase64);
          }
      }).catch((error: ErrorEvent) => SwAlSetttings.printError(error));
  }

  getName(object: any) {
      return object.firstName + ' ' + object.middleName + ' ' + object.lastName;
  }

  validPhone(object: any) {
    return object.cellPhone ? `${object.cellPhone}|validPhoneToTable` : '';
  }

  async Resolve(data: any, columns: any) {
      const arr = [];
      for (const x of data) {
          let obj: any = {};
          for (const y of columns) {
              if (!y.retrive) {
                  //@ts-ignore
                  obj[y.label] = this[y.method](x);
              } else {
                  obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
              }

          }
          arr.push(obj);
      }
      this.itens = arr;
  }

  getPersonName(item: any) {
      return item.documentProgresses[0].firstName + ' ' + item.documentProgresses[0].lastName;
  }

  getSituationName(item: any) {
      //@ts-ignore
      return this.situation[item.situation]
  }

  /*getAdmissionData(item : any) {

      return item.admissionData && item.admissionData.split("T")[0] != '0001-01-01' ? new Date(item.admissionData).toLocaleDateString() : '--'
  }*/

  Editar() {
      let formUpdate = {
          name: this.formRec.name.value
      };
      //@ts-ignore
      this.UsersService.put(formUpdate, this.DepartmentId).then((x) => {
          SwAlSetttings.Sucesso('Mudamos a situação do seu documento!');
          this.dialog.closeAll();
          //@ts-ignore
          this.itens = [];
          this.get();
      }).catch(
          (err: ErrorEvent) => {
              SwAlSetttings.printError(err);
              this.dialog.closeAll();
          }
      );
  }

  Novo() {
      const UpdateObj = {
          name: this.formRec.name.value,
      }
      this.UsersService.post(UpdateObj).then(
          (res: any) => {
              this.itens = [];
              this.get();
              this.dialog.closeAll();
          }
      ).catch(
          (err: ErrorEvent) => {
              SwAlSetttings.printError(err);
              this.dialog.closeAll();
          }
      );
  }

  async Actions(emit:any) {      
        switch (emit.action) {
            case 'PatchActive':
                //@ts-ignore                
                this.PersonService.patchActive(emit.object.id.toString(), this._checkboxSvgPipe.transform(emit.object.Ativo)).then((x: any) => {
                    this._toastService.fireSuccess('global.msgSituationSuccess')
                    this.get();
                }).catch(err => this._toastService.fireGenericError(err))
                break;
            case 'Editar' :
                //@ts-ignore
                this.router.navigateByUrl('/Usuarios/Editar/' + emit.object.id)
                break;
            case 'OpenDropdown':
                // @ts-ignore
                this.getUserById(emit.id);
                break;
      }
  }

  getActive(item: any) {
    return this._activePipe.transform(item.isActive);
  }
}
