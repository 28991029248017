import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { ErrorTranslatePipe } from "@app/modules_new/shared/pipe/error-translate.pipe";
import { SvgSelectorUINewComponent } from "../../ui/svg-selector-new/svg-selector-new.component";

type Option = {
   label: string;
   value: string;
   svg?: string;
   svgFill?: string;
};

@Component({
   selector: "form-select-prop",
   templateUrl: "./select-prop.component.html",
   styleUrls: ["./select-prop.component.scss"],
   standalone: true,
   imports: [
      CommonModule,
      TranslateModule,
      ReactiveFormsModule,
      MatSelectModule,
      ErrorTranslatePipe,
      SvgSelectorUINewComponent
   ]
})
export class FormSelectPropComponent implements OnInit {
   @Input() readonly = false;
   @Input() form!: FormControl;
   @Input() placeholder: string = "";
   @Input() allowToEraseValue = false;
   @Input() options: Array<Option> = [];
   @Output() valueChanged = new EventEmitter<Object>();
   selectedOption?: Option;

   constructor() {}

   ngOnInit(): void {
      if (this.readonly) {
         this.form.disable();
      } else {
         this.form.enable();
      }

      this.form.valueChanges.subscribe((value) => {
         this.selectedOption = this.options.find(
            (option) => option.value == value
         );
         this.valueChanged.emit(this.selectedOption);
      });
   }

   onUserChange(event: MatSelectChange) {}
}
