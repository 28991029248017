<div class="contentPage">
  <div class="flex flex-between align-center">
    <div class="flex align-center gap-2">
      <h1>{{ 'collectionGuidance.title' | translate }}</h1>
      <app-button (click)="openDialogCollectionInstruction()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter (beforeClose)="getCollectionInstruction()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus [form]="formFilter.description" [placeholder]="'collectionGuidance.filter.description'"></input-prop>
        <checkbox-prop [label]="'collectionGuidance.filter.isActive'" class="flex flex-center" [gap]="1" [form]="formFilter.IsActive"></checkbox-prop>
      </div>
      <app-button class="queryBtn" (click)="getCollectionInstruction()" [placeholder]="'collectionGuidance.filter.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="colectionInstruction" [Actions]="['Editar', 'Excluir']" [ClickInItem]="ActionsExtra" (parentFun)="actions($event)" (changePage)="getCollectionInstruction($event)"></app-table>
  </div>
</div>

<ng-template #dialogCollectionInstruction let-edit>
  <div class="flex flex-between w-100 align-center">
    <h1 *ngIf="!edit">{{'collectionGuidance.new' | translate}}</h1>
    <h1 *ngIf="edit">{{'collectionGuidance.edit' | translate}}</h1>
    <svg (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
    </svg>
  </div>
  <div class="flex gap-2">
    <input-prop class="mt-32 w-100" [form]="collectionInstructionForm.controls['description']" [placeholder]="'collectionGuidance.description'" (keyup.enter)="edit ? updateCollectionInstruction(edit.id) : createCollectionInstruction()"></input-prop>
  </div>
  <div class="w-100 mt-32 flex flex-center">
    <app-button (click)="edit ? updateCollectionInstruction(edit.id) : createCollectionInstruction()" [placeholder]="'global.save'" [class]="'btn flex gap-2 align-center'"></app-button>
  </div>
</ng-template>