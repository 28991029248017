import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GetActiveFilterPipe } from "@app/pipe/active.pipe";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { DoctorsService } from "@app/services/doctors/doctors.service";
import { GetAllDoctors } from "@app/services/doctors/doctors.types";
import { Guid } from "@app/util/guid";
import { ResolveDoctors, resolveDoctors } from "./doctors.model";
import { CheckboxSvgPipe } from "@app/pipe/checkbox-Svg.pipe";
import { ToastService } from "@app/services/translate/toast.service";
import { UntypedFormControl, Validators } from "@angular/forms";

@Component({
   selector: "app-doctors",
   templateUrl: "./doctors.component.html",
   styleUrls: ["./doctors.component.scss"]
})
export class DoctorsComponent implements OnInit {
   constructor(
      private _router: Router,
      private _doctorsService: DoctorsService,
      private _createdAt: LocalaDatePipe,
      private _updatedAt: LocalaDatePipe,
      private _activePipe: GetActiveFilterPipe,
      private _checkboxSvgPipe: CheckboxSvgPipe,
      private _toastService: ToastService
   ) {}

   //#region Global Variables / Variáveis Globais
   page: number = 1;
   index: number = 50;
   total: number = 0;
   orderBy: { sort: string; order: string } = { sort: "", order: "asc" };
   //#endregion Variáveis globais

   //#region Registers and Resolves
   registerDoctors: Array<ResolveDoctors> = [];

   ActionsExtra = {
      Ativo: "PutActive"
   };

   //#endregion

   //#region Forms / Formulários
   form = {
      filter: new UntypedFormControl(null, [Validators.required]),
      search: new UntypedFormControl(null),
      isActive: new UntypedFormControl(null)
   };
   //#endregion

   async ngOnInit() {
      await this.getDoctors();
      this.form.filter.setValue("Name");
   }
   getActive(item: any) {
      return this._activePipe.transform(item.isActive);
   }

   officePhone(item: GetAllDoctors) {
      return item.officePhone ? item.officePhone : "";
   }

   cellPhone(item: GetAllDoctors) {
      return item.cellPhone ? item.cellPhone : "";
   }

   getCrm(item: GetAllDoctors) {
      return (
         item.council +
         " - " +
         item.registrationNumber +
         "/" +
         item.registrationState
      );
   }

   getExternalId(item: GetAllDoctors) {
      return item.externalId ? item.externalId : "";
   }

   async getDoctors(paramPage?: number[]) {
      this.registerDoctors = [];
      if (paramPage) {
         this.page = paramPage[0];
         this.index = paramPage[1];
      }

      let filters: {
         page: number;
         index: number;
         isActive: boolean;
         RegistrationNumber: string;
         Name: string;
         cpf: string;
         officePhone: string;
         Email: string;
         orderBy: string;
      } = {
         page: this.page,
         index: this.index,
         isActive: this.form.isActive.value ? this.form.isActive.value : null,
         RegistrationNumber: "",
         Name: "",
         cpf: "",
         officePhone: "",
         Email: "",
         orderBy: this.orderBy.sort
            ? `${this.orderBy.sort},${this.orderBy.order}`
            : ""
      };
      switch (this.form.filter.value) {
         case "Registration Number":
            filters.RegistrationNumber = this.form.search.value.replaceAll(
               "-",
               ""
            );
            break;
         case "Name":
            filters.Name = this.form.search.value;
            break;
         case "CPF":
            filters.cpf = this.form.search.value;
            break;
         case "Phone":
            filters.officePhone = this.form.search.value;
            break;
         case "Email":
            filters.Email = this.form.search.value;
            break;
      }

      filters.index = this.index;
      filters.page = this.page;

      await this._doctorsService
         .getPagination(filters)
         .then((res: any) => {
            if (res.data.length != 0) {
               this.total = res.sumRecords;
               this.resolve(res.data, resolveDoctors);
            } else {
               this._toastService.warningMessage("doctor.msgNoDoctors");
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async getSortedPage(event: { label: string; index: number; order: string }) {
      let value = resolveDoctors.find((x: any) => x.label == event.label);

      if (value && value.sort) {
         this.orderBy.sort = value.sort;
         this.orderBy.order = event.order;
      }
      this.getDoctors();
   }

   resolve(data: any, columns: any) {
      this.registerDoctors = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registerDoctors.push(obj);
      });
   }

   navigate() {
      this._router.navigateByUrl("Cadastros/Atendimento/Solicitantes/Novo");
   }

   getCreated(item: GetAllDoctors) {
      return this._createdAt.transform(item.createdAt);
   }

   getUpdated(item: GetAllDoctors) {
      return this._updatedAt.transform(item.updatedAt);
   }

   isActive(item: any) {
      return this._activePipe.transform(item.isActive);
   }

   async actions(emit: any) {
      switch (emit.action) {
         case "View":
            this._router.navigateByUrl(
               "Cadastros/Atendimento/Solicitantes/" + emit.object.id + "$View"
            );
            break;
         case "Editar":
            this._router.navigateByUrl(
               "Cadastros/Atendimento/Solicitantes/" + emit.object.id + "$Edit"
            );
            break;
         case "Excluir":
            this.deleteDoctor(new Guid(emit.object.id));
            break;
         case "PutActive":
            this.putActive(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
      }
   }

   putActive(id: string, value: string) {
      this._doctorsService
         .patchActive(id, value)
         .then((x: any) => {
            this._toastService.fireSuccess("global.msgSituationSuccess");
            this.getDoctors();
         })
         .catch((err) => this._toastService.fireError(err));
   }

   deleteDoctor(id: Guid) {
      this._toastService
         .fireConfirmation("doctor.msgDeleteDoctor")
         .then(async (answer) => {
            if (answer.isConfirmed) {
               await this._doctorsService.delete(id).then(async (res) => {
                  if (res.success) {
                     this.registerDoctors = this.registerDoctors.filter(
                        (x) => x.id.toString() !== id.toString()
                     );
                     this._toastService.fireSuccess("doctor.msgDeleteSuccess");
                     await this.getDoctors();
                  }
               });
            }
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   resetSelect() {
      this.form.search.reset();
      this.form.isActive.reset();
   }

   resetFilter() {
      this.form.filter.setValue("Name");
      this.form.search.reset();
      this.form.isActive.reset();
   }

   getEmail(item: any) {
      return item.email == undefined || null ? "" : item.email;
   }
}
