import { Component, OnInit, inject } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonComponentModule } from "@app/components/common-component.module";
import { ForgotPasswordFacade } from "@app/modules_new/administrativeData/data/facade/forgotPassword.facade";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { ModalLayoutComponent } from "@app/modules_new/shared/components/form/modal/modal.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "photoModal",
   templateUrl: "./photoModal.modal.html",
   standalone: true,
   imports: [
      TranslateModule,
      ModalLayoutComponent,
      CommonComponentModule,
      ButtonFormComponent,
      ReactiveFormsModule
   ]
})
export class PhotoModalComponent implements OnInit {
   public _modalData: any = inject(MAT_DIALOG_DATA);
   private _forgotPasswordFacade = inject(ForgotPasswordFacade);
   title: string = "Atualizar Senha";

   public photo = new FormControl<any>(null, [Validators.required]);

   ngOnInit(): void {}

   async changePhoto() {}
}
