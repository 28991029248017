import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { HttpService } from '@app/services/http.service';

@Injectable({
  providedIn: 'root'
})

export class AgreementSupportParametersService extends BaseService {
  constructor(httpService: HttpService) {
    super(httpService, 'v1/laboratory/billing/agreementsupportparameters');
  }

 
}