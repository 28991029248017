import { Injectable } from "@angular/core";
import { DefaultFormats, LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import {
   SpanExamCollectSituation,
   SpanExamSituation
} from "./ESpanExamSituation";
import { EGenderType } from "./EGenderType";
import { InvoiceStatusPTBR } from "./EInvoiceStatus";
import { EAgreementExamsRoutine } from "./EAgreementExamsRoutine";
import { EActionCollect } from "./EActionCollect";
import { EReqSituation } from "./EReqSituation";
import { EnumObject } from "@app/services/enum/enum.service.model";
import { EnumService } from "@app/services/enum/enum.service";
import { EnumAuthService } from "@app/services/enumAuth/enumAuth.service";

@Injectable({
   providedIn: "root"
})
export class Util {
   constructor(
      private _localeDate: LocalaDatePipe,
      private _enumService: EnumService,
      private _enumAuthService: EnumAuthService
   ) {}

   leftPad(value: number, totalWidth: number, paddingChar = "0") {
      var length = totalWidth - value.toString().length + 1;
      return Array(length).join(paddingChar || "0") + value;
   }

   formatField(
      resolveObject: any,
      fieldName: string,
      type: string,
      interpolationFields: string,
      dateFormat?: DefaultFormats,
      customTypes?: Array<any>
   ) {
      let formattedField: string = "";

      if ((resolveObject && fieldName) || type == "paper") {
         switch (type) {
            case "occurrency":
               if (resolveObject.occurrences > 0) {
                  formattedField =
                     '<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.6144 19.6062L15.9344 2.10846C15.2942 0.818456 13.9609 0 12.5 0C11.0391 0 9.70577 0.818456 9.06562 2.10846L0.385638 19.6062C-0.191165 20.7687 -0.116809 22.141 0.581076 23.2376C1.27904 24.3341 2.50325 25 3.81988 25H21.1801C22.4968 25 23.721 24.3341 24.4189 23.2376C25.1168 22.141 25.1912 20.7687 24.6144 19.6062ZM12.5 20.8946C11.7215 20.8946 11.0909 20.2752 11.0909 19.511C11.0909 18.7468 11.7216 18.1274 12.5 18.1274C13.2784 18.1274 13.9091 18.7468 13.9091 19.511C13.9091 20.2752 13.2784 20.8946 12.5 20.8946ZM13.7316 16.4233C13.6931 17.0555 13.1392 17.5462 12.4999 17.55C11.861 17.546 11.3061 17.0557 11.2684 16.4233C11.1445 13.8487 11.021 11.2738 10.8974 8.6989C10.8839 7.70331 11.8046 7.14022 12.4998 7.14463C13.1955 7.14007 14.1158 7.70331 14.1026 8.6989C13.9788 11.2739 13.8547 13.8485 13.7316 16.4233Z" fill="#FFC000"/></svg>';
               } else {
                  formattedField =
                     '<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 1.85185C6.61919 1.85185 1.85185 6.61919 1.85185 12.5C1.85185 18.3808 6.61919 23.1481 12.5 23.1481C18.3808 23.1481 23.1481 18.3808 23.1481 12.5C23.1481 6.61919 18.3808 1.85185 12.5 1.85185ZM0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5ZM17.7224 8.89517C18.1152 9.22254 18.1683 9.8064 17.8409 10.1992L12.0539 17.1437C11.8874 17.3435 11.6444 17.4641 11.3846 17.4759C11.1247 17.4877 10.8718 17.3896 10.6879 17.2057L7.21564 13.7334C6.85405 13.3718 6.85405 12.7856 7.21564 12.424C7.57724 12.0624 8.1635 12.0624 8.5251 12.424L11.2804 15.1792L16.4183 9.01372C16.7457 8.62087 17.3295 8.56779 17.7224 8.89517Z" fill="#07BF56"/></svg>';
               }
               break;
            case "description":
               formattedField = resolveObject[fieldName]
                  ? resolveObject[fieldName]
                  : "";
               break;
            case "agreementRequestCode":
               resolveObject.requestPending
                  ? (formattedField =
                       "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Deve Guia</span>")
                  : (formattedField = resolveObject[fieldName]
                       ? resolveObject[fieldName]
                       : "");
               break;
            case "name":
               formattedField = resolveObject[fieldName]
                  ? resolveObject[fieldName].toUpperCase()
                  : "";
               break;
            case "date":
               if (resolveObject[fieldName]) {
                  if (resolveObject[fieldName].deadline) {
                     const formattedData = this._localeDate.transform(
                        resolveObject[fieldName].deadline,
                        dateFormat
                     );
                     formattedField = formattedData ? formattedData : "";
                  } else {
                     if (dateFormat && resolveObject[fieldName]) {
                        if (
                           resolveObject[fieldName] === "0001-01-01T00:00:00"
                        ) {
                           formattedField =
                              "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Pendente</span>";
                        } else {
                           // const formattedData = this._localeDate.transform(
                           //    resolveObject[fieldName],
                           //    dateFormat
                           // );
                           // formattedField = formattedData ? formattedData : "";
                        }
                     }
                  }
               }
               break;
            case "dateUpdate":
               if (
                  resolveObject.createdAt.slice(0, 19) ===
                  resolveObject.updatedAt.slice(0, 19)
               ) {
                  formattedField = "";
               } else if (dateFormat && resolveObject[fieldName]) {
                  const formattedData = this._localeDate.transform(
                     resolveObject[fieldName],
                     dateFormat
                  );
                  formattedField = formattedData ? formattedData : "";
               }
               break;
            case "checkbox":
               formattedField = `|checkbox|${resolveObject[fieldName]}`; //<svg style=\"max-width: calc(34px * var(--proportional));\" width=\"34\" height=\"21\" viewBox=\"0 0 34 21\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <rect x=\"0.475647\" y=\"0.647827\" width=\"33.3333\" height=\"20\" rx=\"10\" fill=\"#808080\"/> <path d=\"M10.4756 18.6478C14.8939 18.6478 18.4756 15.0661 18.4756 10.6478C18.4756 6.22955 14.8939 2.64783 10.4756 2.64783C6.05737 2.64783 2.47565 6.22955 2.47565 10.6478C2.47565 15.0661 6.05737 18.6478 10.4756 18.6478Z\" fill=\"white\"/> </svg>" : "<svg style='max-width: calc(34px * var(--proportional));height: auto' width=\"34\" height=\"20\" viewBox=\"0 0 34 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <rect x=\"0.384644\" width=\"33.3333\" height=\"20\" rx=\"10\" fill=\"#FE6344\"/> <path d=\"M23.3846 18C27.8029 18 31.3846 14.4183 31.3846 10C31.3846 5.58172 27.8029 2 23.3846 2C18.9664 2 15.3846 5.58172 15.3846 10C15.3846 14.4183 18.9664 18 23.3846 18Z\" fill=\"white\"/> </svg>";
               break;
            case "situation":
               formattedField = resolveObject[fieldName]
                  ? "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Sim</span>"
                  : "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Não</span>";
               break;
            case "examSituation":
               formattedField = SpanExamSituation[resolveObject[fieldName]];
               break;
            case "examCollectSituation":
               if (
                  resolveObject.usesProcessCollect &&
                  resolveObject["actionCollect"] != undefined
               ) {
                  formattedField =
                     SpanExamCollectSituation[resolveObject["actionCollect"]];
               } else {
                  formattedField =
                     SpanExamSituation[resolveObject["situation"]];
               }
               break;
            case "doctorCouncil":
               const fields = interpolationFields
                  .replaceAll(" ", "")
                  .split(",");
               formattedField = `${resolveObject[fields[0]]}/${
                  resolveObject[fields[1]]
               }: ${resolveObject[fields[2]]}`;
               break;
            case "money":
               const fieldValue = resolveObject[fieldName];
               const options = {
                  style: "currency",
                  currency: "BRL",
                  minimumFractionDigits: 2
               };
               formattedField = fieldValue
                  ? fieldValue.toLocaleString("pt-BR", options)
                  : "";
               break;
            case "invoiceStatus":
               formattedField = InvoiceStatusPTBR[resolveObject[fieldName]];
               break;
            case "routine":
               formattedField =
                  EAgreementExamsRoutine[resolveObject[fieldName]];
               break;
            case "actionCollect":
               formattedField = EActionCollect[resolveObject[fieldName]];
               break;
            case "paper":
               formattedField =
                  '<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16.2143 20H5.5C5.12123 19.9996 4.75809 19.849 4.49027 19.5812C4.22244 19.3133 4.07181 18.9502 4.07143 18.5714V14.2857H5.5V18.5714H16.2143V2.85714H9.78571V1.42857H16.2143C16.5931 1.42895 16.9562 1.57958 17.224 1.84741C17.4918 2.11524 17.6425 2.47838 17.6429 2.85714V18.5714C17.6425 18.9502 17.4918 19.3133 17.224 19.5812C16.9562 19.849 16.5931 19.9996 16.2143 20Z" fill="#404040"/> <path d="M9.78571 5.71429H14.7857V7.14286H9.78571V5.71429Z" fill="#404040"/> <path d="M9.07143 9.28571H14.7857V10.7143H9.07143V9.28571Z" fill="#404040"/> <path d="M8.35714 12.8571H14.7857V14.2857H8.35714V12.8571Z" fill="#404040"/> <path d="M4.07143 12.1429C3.12452 12.1419 2.21666 11.7653 1.54709 11.0958C0.877524 10.4262 0.500945 9.51834 0.5 8.57143V0.714286H1.92857V8.57143C1.92857 9.13975 2.15434 9.68479 2.5562 10.0867C2.95806 10.4885 3.50311 10.7143 4.07143 10.7143C4.63975 10.7143 5.18479 10.4885 5.58666 10.0867C5.98852 9.68479 6.21429 9.13975 6.21429 8.57143V2.14286C6.21429 1.95342 6.13903 1.77174 6.00508 1.63778C5.87112 1.50383 5.68944 1.42857 5.5 1.42857C5.31056 1.42857 5.12888 1.50383 4.99492 1.63778C4.86097 1.77174 4.78571 1.95342 4.78571 2.14286V9.28571H3.35714V2.14286C3.35714 1.57454 3.58291 1.02949 3.98477 0.627628C4.38663 0.225765 4.93168 0 5.5 0C6.06832 0 6.61337 0.225765 7.01523 0.627628C7.41709 1.02949 7.64286 1.57454 7.64286 2.14286V8.57143C7.64191 9.51834 7.26533 10.4262 6.59577 11.0958C5.9262 11.7653 5.01834 12.1419 4.07143 12.1429Z" fill="#404040"/> </svg>';
               break;
            case "genderType":
               formattedField = EGenderType[resolveObject[fieldName]] ?? "";
               break;
            case "routineType":
               formattedField =
                  EAgreementExamsRoutine[resolveObject[fieldName]];
               break;
            case "recipienteType":
               const RecipienteType = [
                  '<span class="span-styles" style="background:#EB5757; color:#FFF;">Coleta</span>',
                  '<span class="span-styles" style="background:#FFC000; color:#FFF;">Triagem</span>',
                  '<span class="span-styles" style="background:#006E9D; color:#FFF;">Lamina</span>',
                  '<span class="span-styles" style="background:#07BF56; color:#FFF;">Seringa</span>'
               ];
               formattedField = RecipienteType[resolveObject[fieldName]];
               break;
            case "titleEstado":
               formattedField = resolveObject[fieldName]
                  ? `<span class="span-styles" style="background:#07BF56; color:#FFF;">Ok</span>`
                  : `<span class="span-styles" style="background:#EB5757; color:#FFF;">Pendente</span>`;
               break;
            case "titleSituation":
               let situation = [
                  `<span class="span-styles" style="background:#006E9D; color:#FFF;">Aberto</span>`,
                  `<span class="span-styles" style="background:#EB5757; color:#FFF;">Vencido</span>`,
                  `<span class="span-styles" style="background:#FFC000; color:#FFF;">Baixado Parcialmente</span>`,
                  `<span class="span-styles" style="background:#07BF56; color:#FFF;">Baixado Integralmente</span>`
               ];
               formattedField = situation[resolveObject[fieldName]];
               break;
            case "boolean":
               formattedField = `|boolean|${resolveObject[fieldName]}`;
               break;
            case "customType":
               if (customTypes) {
                  let info: { label: string; value: string } = customTypes.find(
                     (x: { label: string; value: string }) => {
                        return x.value == resolveObject[fieldName];
                     }
                  );
                  formattedField = info.label;
               }
               break;
            case "active":
               // formattedField = this._activePipe.transform(
               //    resolveObject[fieldName]
               // );
               break;
            case "globalUnity":
               formattedField =
                  resolveObject[fieldName] ??
                  "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Global</span>";
               break;
            case "finished":
               formattedField = resolveObject[fieldName]
                  ? "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Finalizada</span>"
                  : "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Não Finalizada</span>";
               break;
            case "situation_enum":
               formattedField = EReqSituation[resolveObject[fieldName]];
               break;
            case "day":
               if (resolveObject[fieldName] === 1) {
                  formattedField = `${resolveObject[fieldName]} dia`;
               } else if (resolveObject[fieldName] > 1) {
                  formattedField = `${resolveObject[fieldName]} dias`;
               } else {
                  formattedField = "Não informado";
               }
               break;
            case "concatenationAge":
               const typeAge = interpolationFields
                  .replaceAll(" ", "")
                  .split(",");
               if (resolveObject[typeAge[1]] === 0) {
                  if (resolveObject[typeAge[0]] === 0) {
                     formattedField = "";
                  } else {
                     if (resolveObject[typeAge[0]] === 1) {
                        formattedField = `${resolveObject[typeAge[0]]} Ano`;
                     } else {
                        formattedField = `${resolveObject[typeAge[0]]} Anos`;
                     }
                  }
               } else if (resolveObject[typeAge[1]] === 1) {
                  if (resolveObject[typeAge[0]] === 0) {
                     formattedField = "";
                  } else {
                     if (resolveObject[typeAge[0]] === 1) {
                        formattedField = `${resolveObject[typeAge[0]]} Mês`;
                     } else {
                        formattedField = `${resolveObject[typeAge[0]]} Meses`;
                     }
                  }
               } else {
                  if (resolveObject[typeAge[0]] === 0) {
                     formattedField = "";
                  } else {
                     if (resolveObject[typeAge[0]] === 1) {
                        formattedField = `${resolveObject[typeAge[0]]} Dia`;
                     } else {
                        formattedField = `${resolveObject[typeAge[0]]} Dias`;
                     }
                  }
               }
               break;
            case "TypeConservation":
               formattedField = `|TypeConservation|${resolveObject[fieldName]}`;
               break;
            default:
               formattedField = "";
               break;
         }
      }

      return formattedField;
   }

   static sortDate = (a: any, b: any) => {
      if (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) {
         return -1;
      }
      if (new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) {
         return 1;
      }
      return 0;
   };

   static sortDateType = (type: "ASC" | "DESC") => {
      const helper = type === "ASC" ? -1 : 1;
      return (a: any, b: any) => {
         if (
            new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
         ) {
            return -1 * helper;
         }
         if (
            new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()
         ) {
            return 1 * helper;
         }
         return 0;
      };
   };

   async getEnum(EnumName: string): Promise<Array<EnumObject>> {
      const queryParams = { EnumName: EnumName, IsActive: true };
      let options: Array<EnumObject> = [];
      const response = await this._enumService.getPagination<EnumObject>(
         queryParams
      );
      response.data.forEach((enumObject) => {
         options.push({
            label: enumObject.label,
            value: enumObject.value
         });
      });
      return options;
   }

   async getEnumAuth(EnumName: string): Promise<Array<EnumObject>> {
      const queryParams = { EnumName: EnumName, IsActive: true };
      let options: Array<EnumObject> = [];
      const response = await this._enumAuthService.getPagination<EnumObject>(
         queryParams
      );
      response.data.forEach((enumObject) => {
         options.push({
            label: enumObject.label,
            value: enumObject.value
         });
      });
      return options;
   }
}
