import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { IHttpService } from './interfaces/IHttpService';
import { Guid } from '../util/guid';

@Injectable({ providedIn: 'root' })
export abstract class HttpService implements IHttpService {

  public baseURL: string;

  public headers = {
    'Content-Type': '',
    'tenantId': '',
    'Authorization': ''
  };

  constructor(protected http: HttpClient) {
    this.baseURL = environment.baseURL;
    this.setHeaders();
  }

  // TODO: Ajustar Normalize
  get(queryString: string, path?: string, tenantId?: string): Promise<Object> {
    this.setHeaders(tenantId);
    let parans = Location.normalizeQueryParams(queryString);
    let normalize;
    normalize = `${path}${parans}`;
    const url = `${this.baseURL}${normalize}`;
    const options = { headers: this.headers };

    //@ts-ignore
    return this.http.get(url, options).toPromise();
  }

  post(body: Object, path?: string, urlClient: string = '', tenantId?: string): Promise<Object> {
    this.setHeaders(tenantId);
    var url = '';

    if (urlClient == '') {
      url = `${this.baseURL}${path}`;
    } else {
      url = `${urlClient}${path}`;
    }

    const options = { headers: this.headers };
    //@ts-ignore
    return this.http.post(url, body, options).toPromise();
  }

  putWithTenant(body: Object, id: string, path?: string, tenantId?: string): Promise<Object> {
    this.setHeaders(tenantId);
    const url = `${this.baseURL}${path}/${id}`;
    const options = { headers: this.headers };
    //@ts-ignore
    return this.http.put(url, body, options).toPromise();
  }

  put(body: Object, id?: Guid, path?: string): Promise<Object> {
    this.setHeaders();
    if (id) {
      const url = `${this.baseURL}${path}/${id}`;
      const options = { headers: this.headers };
      //@ts-ignore
      return this.http.put(url, body, options).toPromise();
    } else {
      const url = `${this.baseURL}${path}`;
      const options = { headers: this.headers };
      //@ts-ignore
      return this.http.put(url, body, options).toPromise();
    }
  }

  delete(id?: Guid, path?: string): Promise<Object> {
    this.setHeaders();
    let normalize
    if (id != null) {
      normalize = `${path}/${id}`;
    } else {
      normalize = `${path}`;
    }
    const url = `${this.baseURL}${normalize}`;
    const options = { headers: this.headers };

    //@ts-ignore
    return this.http.delete(url, options).toPromise();
  }

  deleteWithBody(id: Guid, body: Object, path?: string): Promise<Object> {
    this.setHeaders();
    let normalize
    if (id != null) {
      normalize = `${path}/${id}`;
    } else {
      normalize = `${path}`;
    }
    const url = `${this.baseURL}${normalize}`;

    const options = { headers: this.headers, body: body };
    //@ts-ignore
    return this.http.delete(url, options).toPromise();
  }

  patch(body: Object, id: Guid, path?: string): Promise<Object> {
    this.setHeaders();
    const url = `${this.baseURL}${path}/${id}`;
    const options = { headers: this.headers };

    return this.http.patch(url, body, options).toPromise() as Promise<{}>
  }

  private setHeaders(tenantId?: string) {
    this.headers['Content-Type'] = 'application/json';
    if (localStorage.getItem('token')) {
      //@ts-ignore
      //this.headers['Authorization'] = localStorage.getItem('token');
      //@ts-ignore
      //this.headers['TenantId'] = localStorage.getItem('tenantId');
    }

    if (tenantId) {
      this.headers['tenantId'] = tenantId;
    } else {
      if (localStorage.getItem('TenantId')) {
        //@ts-ignore
        this.headers['tenantId'] = localStorage.getItem('TenantId')
      }

      if (sessionStorage.getItem('TenantId')) {
        //@ts-ignore
        this.headers['tenantId'] = sessionStorage.getItem('TenantId')
      }
    }
  }

}

