import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { Guid } from 'src/app/util/guid';
import { GetAllAgreementParameters, GetByIdAgreementParameters } from './agreement-parameters.types';

@Injectable({
  providedIn: 'root'
})

export class AgreementsParametersService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/agreementparameters');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllAgreementParameters>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetAllAgreementParameters>>
  }

  override getById(id: Guid){
    return super.getById(id) as Promise<HttpResponsePageable<GetByIdAgreementParameters>>
  }
}