import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register the plugin to all charts:
Chart.register(ChartDataLabels);
Chart.register(...registerables);

@Component({
  selector: 'app-chartjs-pie',
  templateUrl: './chartjs-pie.component.html',
  styleUrls: ['./chartjs-pie.component.scss']
})

export class ChartJsPieComponent implements OnInit {
  @Input() requisition : boolean = false;  
  @Input() labels : Array<string> = [];
  @Input() values : Array<number> = [];  
  @Input() backgroundColor : Array<string> = [];
  @Input() name : string = 'chart';

  constructor(private _ngZone: NgZone) {}

  ngOnInit(): void {                 
  }

  ngAfterViewInit(): void {
    this._ngZone.runOutsideAngular(() => {
      this.createChart();
    });    
  }

  createChart(): void {            
    let requisition = this.requisition;    
    var myChart = new Chart(this.name, {
      type: 'pie',
      options: {
        responsive:true,
        plugins: {
          tooltip: {
            callbacks: {                      
              label: function(context) {                      
                let label = `${context.label}: ${context.formattedValue}${requisition ? '%' : ''}`;
                return label;
              }
            }
          },
          datalabels: {
            color: 'white',
            formatter:(value, context)=> {                            
              let display = [`${value}`];
              if (this.requisition){
                const datapoints = context.chart.data.datasets[0].data;
                //@ts-ignore
                function totalSum(total, datapoint){
                  return total + datapoint;
                }
                const totalValue = datapoints.reduce(totalSum, 0);
                const requisitionValue = (value / totalValue * 100).toFixed(2);
                display = [`${requisitionValue}%`];
              } 
              return display;
            }
          }
        }
      },
      data: {
        labels: this.labels,
        datasets: [{          
          data: this.values,
          backgroundColor: this.backgroundColor,
          hoverOffset: 6,
          hoverBackgroundColor:this.backgroundColor,
          hoverBorderColor:this.backgroundColor
        }]
      }      
    });                
  };  
}