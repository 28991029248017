<modal-layout [title]="title | translate">
    <form [formGroup]="form" class="flex flex-column gap-form gap-[32px]">
        <div class="sd">
            <forms-input-text
                class="w-full"
                [placeholder]="'ip_address.enter_ip'"
                [form]="form.controls.ipAddress"
            ></forms-input-text>
            <div class="error flex justify-end">
                <span *ngIf="form.get('ipAddress')?.errors?.pattern"
                    >Endereço IP inválido.
                </span>
            </div>
        </div>
        <forms-textarea
            [placeholder]="'default.description' | translate"
            [form]="form.controls.description"
        ></forms-textarea>
        <forms-button
            class="flex flex-center mt-8"
            #button
            validateBeforeTouch="true"
            (click)="
                button.isDisabled ? null : submit(); $event.preventDefault()
            "
            [validate]="form"
            classExtra="btn btn-primary"
            [text]="'customer.register.save' | translate"
        ></forms-button>
    </form>
</modal-layout>
