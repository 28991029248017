import { UnityService } from "@app/services/shared/unity.service";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { Guid } from "@app/util/guid";
import { SwAlSetttings } from "@app/util/swal.settings";
import { Router } from "@angular/router";
import { ShippingDistributionService } from "@app/services/shipping-distribution/shipping-distribution.service";
import { CollectionPlaceService } from "@app/services/collection-places/collection-place.service";
import { CompanyService } from "@app/services/company/company.service";
import { QueryParams } from "@app/services/interfaces/services.types";
import { GetUnity } from "@app/company/company.model";
import { Actions } from "./shipping-distribuition.model";

@Component({
   selector: "app-shipping-distribution",
   templateUrl: "./shipping-distribution.component.html",
   styleUrls: ["./shipping-distribution.component.scss"]
})
export class ShippingDistributionComponent implements OnInit {
   total: number = 0;
   page: number = 1;
   index: number = 50;
   getParameters: string = `?page=${this.page}&index=${this.index}`;
   lotId: Guid = new Guid(Guid.getEmpty());
   collectionPlaceOptions: Array<any> = [];
   unityOptions: Array<any> = [];
   companys: Array<any> = [];
   orderBy: { sort: string; order: string } = { sort: "", order: "asc" };

   @ViewChild("dialogModal")
   dialogModal!: TemplateRef<any>;

   lots: Array<any> = [];
   materials: Array<any> = [];

   resolveLots = [
      {
         label: "id",
         retrive: "id",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "Lote",
         retrive: "lotId",
         method: "",
         after: "",
         before: "",
         sort: "lotId"
      },
      {
         label: "Unidade",
         retrive: "unityName",
         method: "",
         after: "",
         before: "",
         sort: "unityName"
      },
      {
         label: "Local de Coleta",
         retrive: "collectionPlaceDescription",
         method: "",
         after: "",
         before: "",
         sort: "collectionPlaceDescription "
      },
      {
         label: "Temperatura de Envio",
         retrive: "sendTemperature",
         method: "",
         after: "",
         before: "",
         sort: "sendTemperature "
      },
      {
         label: "Temperatura de Recebimento",
         retrive: "",
         method: "GetReceiveTemperature",
         after: "",
         before: "",
         sort: ""
      },
      {
         label: "Situação",
         retrive: "",
         method: "getSituation",
         after: "",
         before: "",
         sort: "situation "
      },
      {
         label: "Data de Envio",
         retrive: "",
         method: "getSendDate",
         after: "",
         before: "",
         sort: "sendDate"
      },
      {
         label: "Data de Recebimento",
         retrive: "",
         method: "getReceiveDate",
         after: "",
         before: "",
         sort: "receiveDate"
      }
   ];

   resolveMaterials = [
      {
         label: "id",
         retrive: "id",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "N.º Requisição",
         retrive: "number",
         method: "",
         after: "",
         before: "",
         sort: "number"
      },
      {
         label: "Cliente",
         retrive: "name",
         method: "",
         after: "",
         before: "",
         sort: "name"
      },
      {
         label: "Código de Barras",
         retrive: "codeBar",
         method: "",
         after: "",
         before: "",
         sort: "codeBar"
      },
      {
         label: "Recipiente",
         retrive: "description",
         method: "",
         after: "",
         before: "",
         sort: "description"
      },
      {
         label: "Situação",
         retrive: "",
         method: "getExamSituation",
         after: "",
         before: "",
         sort: "situation "
      },
      {
         label: "Caráter Atendimento",
         retrive: "",
         method: "getRecipientSituation",
         after: "",
         before: "",
         sort: "attendance"
      }
   ];

   form = {
      temperature: new UntypedFormControl(null)
   };

   filterForm = {
      companyId: new UntypedFormControl(null),
      unityId: new UntypedFormControl(null),
      collectionPlaceId: new UntypedFormControl(null),
      lotCode: new UntypedFormControl(null),
      isActive: new UntypedFormControl(true)
   };

   lotSituation = {
      0: "<span class='span-styles' style='background: #006E9D;color: #FFFFFF;'>Criado</span>",
      1: "<span class='span-styles' style='background: #07BF56;color: #FFFFFF;'>Enviado</span>",
      2: "<span class='span-styles' style='background: #FFC000;color: #FFFFFF;'>Recebido</span>",
      3: "<span class='span-styles' style='background: #B0DD50;color: #FFFFFF;'>Checado</span>",
      4: "<span class='span-styles' style='background: #EB5757;color: #FFFFFF;'>Recusado</span>"
   };

   recipientSituation = {
      0: "<span class='span-styles' style='background: #07BF56;color: #FFFFFF;'>Normal</span>",
      1: "<span class='span-styles' style='background: #EB5757;color: #FFFFFF;'>Urgente</span>",
      2: "<span class='span-styles' style='background: #FFC000;color: #FFFFFF;'>Emergência</span>"
   };

   examSituation = {
      0: "<span class='span-styles' style='background: #F8F8FF; color: #000000;'>Não Coletado</span>",
      1: "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Aguardando Coleta</span>",
      2: "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Coletado Parcial</span>",
      3: "<span class='span-styles' style='background: #FF0000; color: #F8F8FF;'>Coletado</span>",
      4: "<span class='span-styles' style='background: #363636; color: #F8F8FF;'>Solic. Recoleta</span>",
      5: "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Recoletado</span>",
      6: "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Triado</span>",
      7: "<span class='span-styles' style='background: #F8F8FF; color: #00FFFF;'>Digitado Parcial</span>",
      8: "<span class='span-styles' style='background: #F8F8FF; color: #00FFFF;'>Digitado</span>",
      9: "<span class='span-styles' style='background: #00FFFF; color: #000000;'>Conferido</span>",
      10: "<span class='span-styles' style='background: #F8F8FF; color: #00FF00;'>Liberado</span>",
      11: "<span class='span-styles' style='background: #F8F8FF; color: #0000FF;'>Entregue</span>",
      12: "<span class='span-styles' style='background: #F8F8FF; color: #00008B;'>Impresso</span>",
      20: "<span class='span-styles' style='background: #0000FF; color: #C0C0C0;'>Suspenso de Fatura</span>",
      21: "<span class='span-styles' style='background: #00FF00; color: #F8F8FF;'>Exclusivo de Fatura</span>",
      30: "<span class='span-styles' style='background: #F8F8FF; color: #C0C0C0;'>Cancelado</span>",
      91: "<span class='span-styles' style='background: #F8F8FF; color: #C0C0C0;'>Em Coleta</span>"
   };

   constructor(
      private _dialog: MatDialog,
      private _localaDatePipe: LocalaDatePipe,
      private _unityService: UnityService,
      private _router: Router,
      private _shippingDistributionService: ShippingDistributionService,
      private _collectionPlaceService: CollectionPlaceService,
      private _companyService: CompanyService
   ) {}

   async ngOnInit() {
      await this.get();
      await this.getCompanys();
      await this.getCollectionPlace();
   }

   openDialogModal(obj: any) {
      const myTempDialog = this._dialog.open(this.dialogModal, {
         width: "660px",
         data: obj
      });
      myTempDialog.afterClosed().subscribe(() => {
         this.form.temperature.reset();
      });
   }

   closeDialog() {
      this._dialog.closeAll();
   }

   async get(paramPage?: number[]) {
      if (paramPage) {
         this.page = paramPage[0];
         this.index = paramPage[1];
      }

      let filterObject = {
         numberRegistry: this.index,
         page: this.page,
         orderBy: this.orderBy.sort
            ? `${this.orderBy.sort},${this.orderBy.order}`
            : null,
         lotId: this.filterForm.lotCode.value
            ? this.filterForm.lotCode.value
            : null
      };

      await this._shippingDistributionService
         .getPagination(filterObject)
         .then((res: any) => {
            if (res.data.length != 0) {
               this.total = res.sumRecords;
               this.resolve(res.data, this.resolveLots);
            }
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   async getSortedPage(event: { label: string; index: number; order: string }) {
      const value = this.resolveLots.find((x) => x.label == event.label);
      if (value && value.sort) {
         this.orderBy.sort = value.sort;
         this.orderBy.order = event.order;
      }
      await this.get();
   }

   resolve(data: any, columns: any) {
      this.lots = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.lots.push(obj);
      });
   }

   getSendDate(item: any) {
      if (item.dateSend) {
         return this._localaDatePipe.transform(item.dateSend);
      } else {
         return "";
      }
   }

   getReceiveDate(item: any) {
      if (item.dateReceive) {
         return this._localaDatePipe.transform(item.dateReceive);
      } else {
         return "";
      }
   }

   close() {
      this._dialog.closeAll();
   }

   Actions(emit: Actions) {
      switch (emit.action) {
         case "Temperatura":
            this._shippingDistributionService
               .getById(new Guid(emit.object.id))
               .then((res: any) => {
                  this.form.temperature.setValue(res.data[0].sendTemperature);
                  this.openDialogModal(res.data[0]);
               })
               .catch((err) => {
                  SwAlSetttings.printError(err);
               });
            break;
         case "OpenDropdown":
            this._shippingDistributionService
               .getById(new Guid(emit.object.id))
               .then((res: any) => {
                  if (res.data.length != 0) {
                     this.ResolveMaterials(
                        res.data[0].recipientShippingDistributions,
                        this.resolveMaterials,
                        emit.object.id
                     );
                  }
               })
               .catch((err) => {
                  SwAlSetttings.printError(err);
               });
            break;
         case "Editar":
            this._router.navigateByUrl(
               "Movimentacao/Recepcao/Envio-Remessa-Materiais-Distribuicao/" +
                  emit.object.id
            );
            break;
         case "Excluir":
            this._shippingDistributionService
               .delete(new Guid(emit.object.id))
               .then((x: any) => {
                  SwAlSetttings.Sucesso("Lote Excluido!");
                  this.closeDialog();
                  this.get();
               })
               .catch((err) => SwAlSetttings.Error(err));
            break;
      }
   }

   resetFilter() {
      this.filterForm.unityId.reset();
      this.filterForm.collectionPlaceId.reset();
      this.filterForm.lotCode.reset();
      this.filterForm.isActive.reset();
   }

   async getUnity() {
      this.unityOptions = [];
      this.unityOptions = [
         {
            label: "Todos",
            value: null
         }
      ];
      const queryObject: QueryParams = {
         resume: true,
         companyId: this.filterForm.companyId.value
      };
      await this._unityService
         .getPagination<GetUnity>(queryObject)
         .then(async (res) => {
            res.data.forEach((unity) => {
               this.unityOptions.push({
                  value: unity.id,
                  label: unity.name
               });
            });
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
      this.resetFilter();
   }

   async getCollectionPlace() {
      this.collectionPlaceOptions = [];
      this.collectionPlaceOptions = [
         {
            label: "Todos",
            value: null
         }
      ];
      await this._collectionPlaceService
         .getResume(true)
         .then((res: any) => {
            res.data.forEach((x: any) => {
               const optionObj = {
                  value: x.td,
                  label: x.externalId
               };
               this.collectionPlaceOptions.push(optionObj);
            });
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   generateSelectOption(value: string, label: string) {
      const selectObject = {
         value: value,
         label: label
      };
      return selectObject;
   }

   getSituation(item: any) {
      //@ts-ignore
      return this.lotSituation[item.situation];
   }

   getRecipientSituation(item: any) {
      //@ts-ignore
      return this.recipientSituation[item.attendance];
   }

   getExamSituation(item: any) {
      //@ts-ignore
      return this.examSituation[item.situation];
   }

   ResolveMaterials(data: any, columns: any, id: string) {
      let exams: Array<Object> = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         exams.push(obj);
      });
      //@ts-ignore
      this.materials[id] = exams;
   }

   new() {
      this._router.navigateByUrl(
         "MovimentacaoOld/Recepcao/Envio-Remessa-Materiais-Distribuicao/Novo"
      );
   }

   GetReceiveTemperature(item: any) {
      if (item.receiveTemperature) {
         return item.receiveTemperature;
      } else {
         return "";
      }
   }

   async getCompanys() {
      this.companys = [];
      this.companys = [
         {
            label: "Todos",
            value: null
         }
      ];
      await this._companyService
         .getResume()
         .then((res) => {
            if (res.data.length != 0) {
               res.data.forEach((x) => {
                  const optionObj = {
                     value: x.id,
                     label: x.socialReason
                  };
                  this.companys.push(optionObj);
               });
            }
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   changeTemperature(obj: any) {
      const sendObject = {
         situation: obj.situation,
         unityId: obj.unityId,
         companyId: obj.companyId,
         sendTemperature: this.form.temperature.value
      };

      this._shippingDistributionService
         .put(sendObject, obj.id)
         .then((res) => {
            SwAlSetttings.Sucesso("Temperatura alterada com sucesso!");
            this._dialog.closeAll();
            this.get();
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }
}
