import {
   ActionDefition,
   ColumnDefinition
} from "@app/modules_new/shared/components/form/table/table.model";

export const tableActions: ActionDefition[] = [
   {
      label: "Editar",
      svg: "action-write",
      event: "EDITAR"
   },
   {
      label: "default.delete",
      svg: "action-trash",
      event: "DELETE"
   }
];

export const tableColumns: ColumnDefinition[] = [
   {
      label: "Nome",
      key: "name"
   }
];
