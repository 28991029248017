import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'textarea-b-prop',
  templateUrl: './textarea-prop.component.html',
  styleUrls: ['./textarea-prop.component.scss'],
})
export class TextareaBPropComponent implements OnInit {
  @Input() type : string = 'text';
  @Input() readonly: boolean = false;
  //@ts-ignore
  @Input() form: UntypedFormControl;
  @Input() placeholder : string = '';
  @Input() label : string = '';
  @Input() mask : any = '';
  @Input() className : string = '';
  @Input() requiredInput : string = '';
  @Input() pattern : string = '';
  @Input() tabindexcomponent : string = '';

  @Input() max : string = '';
  @Input() maxlenght : string = '';

  PropValue : String ='';

  classe:string  = '';

  constructor(
  ) {
   }

  validate(s:String) {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    return s.match(rgx);
  }

  onChange(value : String) { 
    this.form.setValue(value);
  }

  ngOnInit(): void {
    this.classe = this.className + ' input-row';
  }


  /**
   * Invoked when the model has been changed
   */

}
