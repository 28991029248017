<div class="contentPage">
  <div class="w-100 flex align-center flex-between headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
      <h1>Unidades</h1>
      <app-button (click)="openDialogModalUnity()" [placeholder]="'Novo'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="getUnity()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus (keyup.enter)="getUnity()" [form]="formFilter.Search" [placeholder]="'Pesquisar'"></input-prop>
        <checkbox-prop class="flex" [gap]="1" [form]="formFilter.actives" label="Somente Ativos"></checkbox-prop>
      </div>
      <app-button (click)="getUnity()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="unityRegisters" [Actions]="['Editar']" [ClickInItem]="ActionsExtra" (parentFun)="actions($event)" (changePage)="getUnity($event)"></app-table>
  </div>
</div>

<ng-template #dialogModalUnity>
  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1>{{unityIdToEdit ? 'Editar' : 'Nova'}} Unidade</h1>
      <svg (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="mt-32 flex gap-2">
      <input-prop [type]="'number'" (keyup.enter)="unityIdToEdit ? putUnity() : postUnity()" [form]="collectionPlaceForm.controls['externalId']" placeholder="Código Externo"></input-prop>
      <input-prop class="w-100" (keyup.enter)="unityIdToEdit ? putUnity() : postUnity()" [form]="collectionPlaceForm.controls['description']" placeholder="Descrição"></input-prop>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="unityIdToEdit ? putUnity() : postUnity()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>