import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CollectionPlaceOriginService } from '@app/services/collection-place-origin/collection-place-origin.service';
import { Guid } from '@app/util/guid';
import { Util } from '@app/util/util';
import { Actions, resolveCollectionOrigin } from './collection-place-origin-list.model';
import { ToastService } from '@app/services/translate/toast.service';

@Component({
  selector: 'app-collection-place-origin-list',
  templateUrl: './collection-place-origin-list.component.html',
  styleUrls: ['./collection-place-origin-list.component.scss']
})
export class CollectionPlaceOriginListComponent implements OnInit {

  constructor(
    private _dialog: MatDialog,
    private _util: Util,
    private _collectionPlaceOriginService: CollectionPlaceOriginService,
    private _toastService: ToastService
  ) { }

  total: number = 0;
  page: number = 1;
  index: number = 50;

  collectionPlaceForm = new UntypedFormGroup({
    description: new UntypedFormControl(null),
  });

  formFilter = {
    Search: new UntypedFormControl(null),
    description: new UntypedFormControl(null),
    IsActive: new UntypedFormControl(null),
  }

  ActionsExtra = {
    'Ativo': 'PutActive',
  };

  colectionOrigin: Array<object> = []

  @ViewChild('dialogRegisterOrigin')
  dialogRegisterOrigin!: TemplateRef<any>

  ngOnInit() {
    this.getCollectionOrigin();
  }

  getCollectionOrigin(paramPage?: Array<number>) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    const filterObj = {
      page: this.page,
      index: this.index,
      Search: this.formFilter.description.value ? this.formFilter.description.value : null,
      IsActive: this.formFilter.IsActive.value ? this.formFilter.IsActive.value : null
    }

    this._collectionPlaceOriginService.getPagination(filterObj).then(
      (res: any) => {
        this.resolve(res.data, resolveCollectionOrigin)
        this.total = res.sumRecords
      }
    ).catch(
      (err: any) => {
        this._toastService.fireGenericError(err)
      }
    );
  }

  resolve(data: any, columns: any) {
    this.colectionOrigin = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.colectionOrigin.push(obj);
    });
  }

  openDialogRegisterOrigin(emit?: any) {
    const myTempDialog = this._dialog.open(this.dialogRegisterOrigin, {
      width: '700px',
      data: emit
    });
    myTempDialog.afterClosed().subscribe(() => {
      this.collectionPlaceForm.reset();
    });
  }

  closeModal() {
    this._dialog.closeAll();
  }

  createCollectionOrigin() {
    const createObt = {
      description: this.collectionPlaceForm.controls['description'].value
    }
    this._collectionPlaceOriginService.post(createObt).then((res: any) => {
      if (res.success) {
        this._toastService.fireSuccess('collectionplaceorigin.msgPost');
        this.getCollectionOrigin();
        this.closeModal();
      }
    }).catch(
      (err: any) => {
        this._toastService.fireGenericError(err)
      }
    );
  }

  updateCollectionOrigin(id: string) {
    const updateObt = {
      description: this.collectionPlaceForm.controls['description'].value
    }
    this._collectionPlaceOriginService.put(updateObt, new Guid(id)).then((res: any) => {
      if (res.success) {
        this._toastService.fireSuccess('collectionplaceorigin.msgPut');
        this.getCollectionOrigin();
        this.closeModal();
      }
    }).catch(
      (err: any) => {
        this._toastService.fireGenericError(err)
      }
    );
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.collectionPlaceForm.controls['description'].setValue(emit.object['collectionplaceorigin.description']);
        this.openDialogRegisterOrigin(emit.object);
        break;
      case 'Excluir':
        this.deleteCollectionOrigin(emit.object.id);
        break;
      case 'PutActive':
        this.putActive(emit.object.id, emit.object.Ativo);
        break
    }
  }

  setValue(object: any) {
    this.collectionPlaceForm.controls['description'].setValue(object.Descrição);
  }

  deleteCollectionOrigin(id: string) {
    this._toastService.fireConfirmation('collectionplaceorigin.msgDeleteCollection').then(
      async (r) => {
        if (r.isConfirmed) {
          setTimeout(async () => {
            await this._collectionPlaceOriginService.delete(new Guid(id));
            this._toastService.fireSuccess('collectionplaceorigin.msgDeleteSuccess');
            this.getCollectionOrigin();
          });
        }
      }
    ).catch(
      (err) => {
        this._toastService.fireGenericError(err)
      }
    );
  }

  putActive(id: string, value: string) {
    this._collectionPlaceOriginService.patchActive(id, value).then((res: any) => {
      if (res.success) {
        this._toastService.fireSuccess('global.msgSituationSuccess');
        this.getCollectionOrigin();
      }
    }).catch(
      (err: any) => {
        this._toastService.fireGenericError(err)
      }
    );
  }

  resetFilter() {
    this.formFilter.description.reset(null)
    this.formFilter.IsActive.reset(null)
  }

}
