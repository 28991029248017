import { AsyncPipe, CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { DynamicPipe } from "@app/modules_new/shared/pipe/dynamic-pipe.pipe";
import { LocaleDatePipe } from "@app/modules_new/shared/pipe/localeDate.pipe";
import { HtmlSanitizerPipe } from "@app/modules_new/shared/pipe/sanitizer-html.pipe";
import { SpanPipe } from "@app/modules_new/shared/pipe/span.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { ProfileFacade } from "../../data/facade/profile.facade";
import { ContentFilterLayoutComponent } from "@app/modules_new/shared/layouts/content-filter/content-filter.component";
import { UserUtil } from "@app/util/user";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { MatDialog } from "@angular/material/dialog";
import { PasswordModalComponent } from "./passwordModal/passwordModal.modal";
import { PhotoModalComponent } from "./photoModal/photoModal.modal";

@Component({
   selector: "myProfile",
   styleUrls: ["./myProfile.component.scss"],
   templateUrl: "./myProfile.component.html",
   standalone: true,
   providers: [LocaleDatePipe, SpanPipe, HtmlSanitizerPipe, TranslateModule],
   imports: [
      TranslateModule,
      AsyncPipe,
      CommonModule,
      DynamicPipe,
      ContentFilterLayoutComponent,
      ButtonFormComponent
   ]
})
export class MyProfileComponent implements OnInit {
   private _dialog = inject(MatDialog);
   private _userUtil = inject(UserUtil);
   private _profileFacade = inject(ProfileFacade);
   userData!: any;
   imagePath = "";
   departments: any = [];

   async ngOnInit() {
      this.userData = await this._profileFacade.getProfile();
      this.userImageGet();
      this.userData.departments.map((element: any) => {
         this.departments.push({
            name: element.department.name,
            id: element.department.id
         });
      });
   }

   userImageGet() {
      this._userUtil.getImage().then((x) => {
         if (x.image != null) {
            this.imagePath = x.image;
         }
      });
   }

   changePassword() {
      const dialog = this._dialog.open(PasswordModalComponent, {
         width: "calc(900px * var(--proportional))",
         data: this.userData.accessCode
      });
   }

   changePhoto() {
      const dialog = this._dialog.open(PhotoModalComponent, {
         width: "calc(900px * var(--proportional))",
         data: this.userData.accessCode
      });
      dialog.afterClosed().subscribe(() => {
         window.location.reload();
      });
   }
}
