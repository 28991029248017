import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { GroupProductService } from '@app/services/stock/group-product.service';
import { SubGroupProductService } from '@app/services/stock/sub-group-product.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { ResolveStock, Actions, GetStock } from './group-product.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';

@Component({
  selector: 'app-group-product',
  templateUrl: './group-product.component.html',
  styleUrls: ['./group-product.component.scss']
})
export class GroupProductComponent implements OnInit {

  @ViewChild('dialogModal') dialogModal!: TemplateRef<any>

  groupId = ''
  subGroupId = ''

  options = {
    EditGroup: {
      label: 'Editar Grupo',
      method: (body: Object) => this.editGroup(body),
    },
    CreateGroup: {
      label: 'Novo Grupo',
      method: (body: Object) => this.createGroup(body),
    },
    EditSubGroup: {
      label: 'Editar Sub-Grupo',
      method: (body: Object) => this.editSubGroup(body),
    },
    CreateSubGroup: {
      label: 'Novo Sub-Grupo',
      method: (body: Object) => this.createSubGroup(body),
    },
  };

  index: number = 50
  total: number = 0
  page: number = 1

  description = new UntypedFormControl()
  descriptionSearch = new UntypedFormControl()
  isActive = new UntypedFormControl(false)

  filter = {
    descriptionSearch: new UntypedFormControl(),
    isActive: new UntypedFormControl(false)
  }

  resolvedStockGroup: Array<object> = [];

  resolvedStockSubGroup: Array<Array<object>>  = [];

  resolveStock = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
    },
    {
      label: 'Nome',
      retrive: 'description',
      method: '',
    },
    {
      label: 'Criado em',
      retrive: '',
      method: (item: any) => this.getCreated(item),
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: (item: any) => this.getUpdated(item),
    },
    {
      label: 'Ativo',
      retrive: '',
      method: (item: any) => this.getActive(item),
    }
  ]

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  ActionsExtra2 = {
    "Ativo": "PutActive2"
  }

  constructor(
    private _createdAt: LocalaDatePipe,
    private _updatedAt: LocalaDatePipe,
    private _dialog: MatDialog,
    private _groupService: GroupProductService,
    private _subGroupService: SubGroupProductService,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe
  ) { }

  ngOnInit(): void {
    this.get()
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let search: string = this.filter.descriptionSearch.value ? this.filter.descriptionSearch.value : null;

    let isActive = this.filter.isActive.value ? this.filter.isActive.value : null;

    await this._groupService.getPagination({ page: this.page, numberRegistry: this.index, search, isActive }).then(
      (res: any) => {
        this.total = 0;
          this.total = res.sumRecords;
          this.resolvedStockGroup = this.resolve(res.data, this.resolveStock);
       }
    ).catch(
      (err: any) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  clearForm(){
    this.description.setValue('')
    this.isActive.setValue(false)
  }

  resolve(data: any, columns: any) {
    let list: Array<Object> = []
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.method(x);
        } else {
          obj[y.label] = x[y.retrive.toString()];
        }
      });
      list.push(obj);
    });
    return list
  }

  openModal(option: {label: string, method: Function}, groupId?: string) {
    this.groupId = groupId ?? this.groupId
    const modal = this._dialog.open(this.dialogModal, {
      width: "600px",
      data:{option}
    })
    modal.afterClosed().subscribe(x => {
      this.clearForm()
      this.groupId = ''
      this.subGroupId = ''
    })
  }

  setValue(value: ResolveStock) {
    this.description.setValue(value["Nome"])
  }

  putActive(id: string, value: string) {
    this._groupService.patchActive(id, value).then((x:any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.get()
    }).catch(err => SwAlSetttings.printError(err))
  }

  putActive2(id: string, value: string) {
    this._subGroupService.patchActive(id, value).then((x:any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.getSubGroup(this.groupId);
    }).catch(err => SwAlSetttings.printError(err))
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'OpenDropdown':    //chama da mesma forma -> [Itens]="getSubGroup(rowDetail.id)"
        this.getSubGroup(emit.id)
        break;

      case 'Editar':
        this.groupId = emit.object.id
        this.openModal(this.options.EditGroup)
        this.setValue(emit.object)
        break;

      case 'Excluir':
        this.deleteGroup(emit.object.id)
        break;

      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  actionsSubGroup(emit: Actions, parentId: string){
    switch (emit.action) {
      case 'Editar':
        this.groupId = parentId
        this.subGroupId = emit.object.id
        this.openModal(this.options.EditSubGroup)
        this.setValue(emit.object)
        break;

      case 'Excluir':
        this.groupId = parentId
        this.deleteSubGroup(emit.object.id)
        break;

       case 'PutActive2':
        this.groupId = parentId
        this.subGroupId = emit.object.id
        this.putActive2(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }


  getSubGroup(groupId: string){
    this._groupService.getById(new Guid(groupId)).then((res: any) => {

      this.resolvedStockSubGroup[(groupId as any)] = []
      if(res.data[0].subGroupProduct.length > 0) {
        this.resolvedStockSubGroup[(groupId as any)] = this.resolve(res.data[0].subGroupProduct, this.resolveStock)
      }
    })
  }
  //#region getters
  getCreated(item: GetStock) {
    return this._createdAt.transform(item.createdAt);
  }

  getUpdated(item: GetStock) {
    return this._updatedAt.transform(item.updatedAt);
  }

  getActive(item: GetStock) {
    return this._activePipe.transform(item.isActive)
  }
  //#endregion

  async submit(data: any) {

    const body = {
      groupProductId: this.groupId ?? null,
      description: this.description.value,
    }
    const result = await data.option.method(body, data.id)
    if (result.success) {
      this.clearForm();
      this._dialog.closeAll();
      result.method()
      SwAlSetttings.Sucesso(result.msg);
      this.groupId = ''
      this.subGroupId = ''
    }
  }

  async createGroup(body: Object) {
    const res: any = await this._groupService.post(body);
    return {success: res.success, msg: 'Grupo Criado',  method: () => this.get()};
  }

  async editGroup(body: Object) {
    const res: any = await this._groupService.put(body, undefined, this.groupId)
    return {success: res.success, msg: 'Grupo Editado',  method: () => this.get()};
  }

  async createSubGroup(body: Object) {
    const res: any = await this._subGroupService.post(body)
    return {success: res.success, msg: 'SubGrupo Criado',  method: () => this.getSubGroup(this.groupId)};
  }
  async editSubGroup(body: Object) {
    const res: any = await this._subGroupService.put(body, undefined, this.subGroupId)
    return {success: res.success, msg: 'SubGrupo Editado',  method: () => this.getSubGroup(this.groupId)};
  }

  deleteGroup(id: string) {
    this._groupService.delete(new Guid(id)).then((res: any) => {
      if(res.success){
        SwAlSetttings.Sucesso('Grupo Excluído');
        this.get()
      }
    })
  }

  deleteSubGroup(id: string) {
    this._subGroupService.delete(new Guid(id)).then((res: any) => {
      if(res.success){
        SwAlSetttings.Sucesso('SubGrupo Excluído');
        this.getSubGroup(this.groupId)
      }
    })
  }

  resetFilter(){
    this.filter.descriptionSearch.reset();
    this.filter.isActive.reset();
  }
}
