import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-chartjs-bar',
  templateUrl: './chartjs-bar.component.html',
  styleUrls: ['./chartjs-bar.component.scss']
})
export class ChartJsBarComponent implements OnInit {  
  @Input() labels : Array<string> = [];
  @Input() values : Array<number> = [];  
  @Input() backgroundColor : Array<string> = [];
  @Input() name : string = 'chart';
  @Input() hour : boolean = false;
  
  constructor(private _ngZone: NgZone) {}

  ngOnInit(): void {                 
  }

  ngAfterViewInit(): void {
    this._ngZone.runOutsideAngular(() => {
      this.createChart();
    });    
  }

  createChart(): void {     
    let hour = this.hour;           
    var myChart = new Chart(this.name, {
      type: 'bar',
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        responsive:true,
        plugins: {
          tooltip: {
            callbacks: {                      
              label: function(context) {                      
                let label = context.formattedValue;

                if (hour){                  
                  //@ts-ignore
                  const hours = Math.floor(context.formattedValue / 60);
                  //@ts-ignore
                  const minutes = context.formattedValue % 60;
                  //@ts-ignore
                  label = [`${hours}H ${minutes}M`];                  
                }

                return label;
              }
            }
          },       
          datalabels: {
            color: 'white',
            formatter:(value, context)=> {                            
              let display = [`${value}`];
              if (this.hour){
                const hours = Math.floor(value / 60);
                const minutes = value % 60;
                display = [`${hours}H ${minutes}M`];
              } 
              return display;
            }
          },
          legend: {
            display: false            
          }
        }
      },
      data: {
        labels: this.labels,
        datasets: [{                  
          data: this.values,
          backgroundColor: this.backgroundColor,          
          hoverBackgroundColor:this.backgroundColor,
          hoverBorderColor:this.backgroundColor
        }]
      }      
    });                
  };  
}