<div class="contentPage">
  <div class="w-100 flex flex-between align-center headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
      <h1>{{'typesPayment.title' | translate}}</h1>
      <app-button (click)="openDialogModal()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop  #autoFocus type="text" (keyup.enter)="get()" [placeholder]="'typesPayment.filter.description'" [form]="Search"></input-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" [label]="'typesPayment.filter.isActive'" [form]="actives"></checkbox-prop>
      </div>
      <app-button (click)="get()" class="queryBtn" [placeholder]="'typesPayment.filter.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="paymentTypes" [Actions]="['Editar']" (parentFun)="actions($event)" (changePage)="get($event)"></app-table>
  </div>
</div>

<ng-template #dialogModal let-edit>
  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1 *ngIf="!edit">{{'typesPayment.new' | translate}}</h1>
      <h1 *ngIf="edit">{{'typesPayment.edit' | translate}}</h1>
      <svg (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="mt-32">
      <input-prop class="mt-32 w-100" [form]="form.description" [placeholder]="'typesPayment.description'"></input-prop>
    </div>
    <div class="flex gap-form mt-32">
      <select-prop class="w-100" [placeholder]="'typesPayment.typeDescription'" [form]="form.type" [options]="tiposPagamento"></select-prop>
      <input-prop (keyup.enter)="typeId? putPaymentType() : postPaymentType()" class="w-100" [form]="form.external" [placeholder]="'typesPayment.externalId'"></input-prop>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="typeId? putPaymentType() : postPaymentType()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>
