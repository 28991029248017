<div class="contentPage">
  <div class="flex align-center w-100 gap-2 flex-between headerResponsive">
    <div class="flex gap-2 align-center w-100 titleResponsive">
      <h1>Aprovação de Desconto</h1>      
    </div>
    <app-filter class="filterResponsive" (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus type="text" placeholder="Situação" [form]="filterForm.situation" ></input-prop>        
      </div>
      <app-button (click)="get()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [ClickInItem]="ActionsExtra" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="discountsAuthorization" (parentFun)="Actions($event)" (changePage)="get($event)"></app-table>
  </div>
</div>

<ng-template #dialogModal let-data>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1 *ngIf="data.discount">Valor do Desconto</h1>
        <h1 *ngIf="!data.discount">Motivo</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="flex flex-column mt-32 gap-3">
        <input-prop *ngIf="data.discount" (keyup.enter)="saveDiscount(data)" placeholder="Valor" [form]="form.value"></input-prop>
        <textarea-prop *ngIf="!data.discount" class="w-100" [form]="form.reason" [placeholder]="'Motivo'"></textarea-prop>
      </div>
      <div class="mt-32 flex flex-center">
        <app-button (click)="saveDiscount(data)" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalAnalysis let-data>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">        
        <h1>O Desconto será aprovado?</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="flex mt-32">        
        <textarea-prop [readonly]="true" class="w-100" [form]="reason" [placeholder]="'Motivo da Solicitação'"></textarea-prop>
      </div>
      <div class="mt-32 flex flex-center flex-evenly">
        <app-button (click)="analysisDiscount(false, data.id)" [placeholder]="'Não'" [class]="'flex gap-2 align-center btn'"></app-button>
        <app-button (click)="analysisDiscount(true, data.id, data.value)" [placeholder]="'Sim'" [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalDiscount let-data>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">        
        <h1>Detalhamento do Desconto</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="value">
        <label class="budget">Valor Solicitado : {{ data.value }}</label>
        <label class="budget">Valor Solicitado Percentual : {{ data.valuePercentual }}%</label>
      </div>
      <div *ngIf="data.situation == 2" class="value">
        <label class="budget">Valor Concedido : {{ data.valueReturn }}</label>
        <label class="budget">Valor Concedido Percentual : {{ data.valueReturnPercentual }}%</label>
      </div>
      <div *ngIf="data.situation == 4" class="flex mt-32">        
        <textarea-prop [readonly]="true" class="w-100" [form]="reason" [placeholder]="'Motivo da Recusa'"></textarea-prop> 
      </div>      
    </div>
  </ng-container>
</ng-template>