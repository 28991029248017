import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { AnalyteService } from 'src/app/services/analyte/analyte.service';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { PriceTableService } from 'src/app/services/pricetable/pricetable.service';
import { RequisitionService } from 'src/app/services/requisitions/requisition.service';
import { Guid } from 'src/app/util/guid';
import { SwAlSetttings } from 'src/app/util/swal.settings';
import { AgreementRequestService } from '@app/services/requisitions/agreement-request/agreement-request.service';
import { AnswerQuestionRequisitionService } from '@app/services/requisitions/answer-question-requisition/answer-question-requisition.service';
import { TypePaymentsService } from '@app/services/payments/payments.service';
import { InvoiceService } from '@app/services/invoice/invoice.service';
import { GetAllInvoices } from '@app/services/invoice/invoice.types';
import { InvoiceStatus, ParticularStatus } from '@app/util/EInvoiceStatus';
import { MatStepper } from '@angular/material/stepper';
import { ETypePayments } from '@app/util/ETypePayments';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { Instructions } from '@app/services/analyte/analyte.types';

interface AllAgreementRequestExams {
  addition: number
  analyteDeadline: Date
  analyteId: string
  balance: number
  code: string
  createdAt: Date
  description: string
  discont: number
  id: string
  isActive: boolean
  isUrgent: boolean
  materialId: string
  name: string
  status: number
  updatedAt: Date
  value: number
}

interface AnalyteInstructionsList {
  analyteDescription: string
  analyteId: string
  code: string
  instructions: Array<Instructions>
}

@Component({
  selector: 'app-view-requisition',
  templateUrl: './view-requisition.component.html',
  styleUrls: ['./view-requisition.component.scss']
})
export class ViewExternalRequisitionComponent implements OnInit {
  //#region Global Variables / Variáveis Globais
  newQuestionAnswer = [];
  title: string = "";
  dateNow: Date = new Date();
  exam: string = "";
  disable: boolean = false;
  total: number = 0;
  page: number = 1;
  index: number = 9999999;
  change: number = 0;
  urlAction: string = "";

  Extras: Array<Object> = [
    {
      type: 'svg',
      action: 'Select',
      content: '<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16.2143 20H5.5C5.12123 19.9996 4.75809 19.849 4.49027 19.5812C4.22244 19.3133 4.07181 18.9502 4.07143 18.5714V14.2857H5.5V18.5714H16.2143V2.85714H9.78571V1.42857H16.2143C16.5931 1.42895 16.9562 1.57958 17.224 1.84741C17.4918 2.11524 17.6425 2.47838 17.6429 2.85714V18.5714C17.6425 18.9502 17.4918 19.3133 17.224 19.5812C16.9562 19.849 16.5931 19.9996 16.2143 20Z" fill="#404040"/> <path d="M9.78571 5.71429H14.7857V7.14286H9.78571V5.71429Z" fill="#404040"/> <path d="M9.07143 9.28571H14.7857V10.7143H9.07143V9.28571Z" fill="#404040"/> <path d="M8.35714 12.8571H14.7857V14.2857H8.35714V12.8571Z" fill="#404040"/> <path d="M4.07143 12.1429C3.12452 12.1419 2.21666 11.7653 1.54709 11.0958C0.877524 10.4262 0.500945 9.51834 0.5 8.57143V0.714286H1.92857V8.57143C1.92857 9.13975 2.15434 9.68479 2.5562 10.0867C2.95806 10.4885 3.50311 10.7143 4.07143 10.7143C4.63975 10.7143 5.18479 10.4885 5.58666 10.0867C5.98852 9.68479 6.21429 9.13975 6.21429 8.57143V2.14286C6.21429 1.95342 6.13903 1.77174 6.00508 1.63778C5.87112 1.50383 5.68944 1.42857 5.5 1.42857C5.31056 1.42857 5.12888 1.50383 4.99492 1.63778C4.86097 1.77174 4.78571 1.95342 4.78571 2.14286V9.28571H3.35714V2.14286C3.35714 1.57454 3.58291 1.02949 3.98477 0.627628C4.38663 0.225765 4.93168 0 5.5 0C6.06832 0 6.61337 0.225765 7.01523 0.627628C7.41709 1.02949 7.64286 1.57454 7.64286 2.14286V8.57143C7.64191 9.51834 7.26533 10.4262 6.59577 11.0958C5.9262 11.7653 5.01834 12.1419 4.07143 12.1429Z" fill="#404040"/> </svg>',
    }
  ];

  genderType = [
    {
      value: 0,
      label: 'Não Informado'
    },
    {
      value: 1,
      label: 'Feminino'
    },
    {
      value: 2,
      label: 'Masculino'
    }
  ]

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  @ViewChild('dialogModalExam')
  dialogModalExam!: TemplateRef<any>

  @ViewChild('dialogModalInstruction')
  dialogModalInstruction!: TemplateRef<any>

  @ViewChild('dialogModalAllInstructions')
  dialogModalAllInstructions!: TemplateRef<any>

  @ViewChild('dialogModalQuestion')
  dialogModalQuestion!: TemplateRef<any>

  @ViewChild('dialogModalAllQuestions')
  dialogModalAllQuestions!: TemplateRef<any>

  @ViewChild('dialogPixModal')
  dialogPixModal!: TemplateRef<any>

  @ViewChild('stepper')
  private myStepper: MatStepper | undefined;
  //#endregion

  //#region Analytes and Exams Variables / Variáveis dos Exames ou Analitos
  totalPriceList: Array<Object> = [];
  isUrgentChecked: boolean = true;
  detailedExams = new UntypedFormControl(false, [Validators.required]);
  newExam = [];
  examOptions: Array<Object> = [];
  materialExamOptions: Array<Object> = [];
  examPrice: Array<Object> = [];
  examPriceList: Array<Object> = [];
  examSituation = {
    0: "<span class='span-styles' style='background: #F8F8FF; color: #000000;'>Não Coletado</span>",
    1: "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Aguardando Coleta</span>",
    2: "<span class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Coletado Parcial</span>",
    3: "<span class='span-styles' style='background: #FF0000; color: #F8F8FF;'>Coletado</span>",
    4: "<span class='span-styles' style='background: #363636; color: #F8F8FF;'>Solic. Recoleta</span>",
    5: "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Recoletado</span>",
    6: "<span class='span-styles' style='background: #FFFF00; color: #000000;'>Triado</span>",
    7: "<span class='span-styles' style='background: #F8F8FF; color: #00FFFF;'>Digitado Parcial</span>",
    8: "<span class='span-styles' style='background: #F8F8FF; color: #00FFFF;'>Digitado</span>",
    9: "<span class='span-styles' style='background: #00FFFF; color: #000000;'>Conferido</span>",
    10: "<span class='span-styles' style='background: #F8F8FF; color: #00FF00;'>Liberado</span>",
    11: "<span class='span-styles' style='background: #F8F8FF; color: #0000FF;'>Entregue</span>",
    12: "<span class='span-styles' style='background: #F8F8FF; color: #00008B;'>Impresso</span>",
    20: "<span class='span-styles' style='background: #0000FF; color: #C0C0C0;'>Suspenso de Fatura</span>",
    21: "<span class='span-styles' style='background: #00FF00; color: #F8F8FF;'>Exclusivo de Fatura</span>",
    30: "<span class='span-styles' style='background: #F8F8FF; color: #C0C0C0;'>Cancelado</span>",
    91: "<span class='span-styles' style='background: #F8F8FF; color: #C0C0C0;'>Em Coleta</span>",
  }
  allAgreementRequestExams: Array<Object> = [];
  //#endregion

  //#region Agreements and Plans Variables / Variáveis dos Convênios e Planos
  agreementPlan: boolean = true;
  typeInvoiceIsPrivate: boolean = false;
  newAgreementPlan = [];
  agreementOptions: Array<Object> = [];
  agreementPriceTable: string = "";
  agreementPlanOptions: Array<Object> = [];
  agreementControlPlan: boolean = false;
  //#endregion

  //#region Requisition Variables / Variáveis das Requisições
  isNewRequisition: boolean = false;
  isEditRequisition: boolean = false;
  newRequisitionId: string = "";
  requisitionRequestArray: Array<Object> = [];
  requisitionId: string = "";
  requisitionNumber: number = 0;
  //#endregion

  //#region AgreementRequest Variables / Variáveis referentes aos Pedidos Médicos
  agreementRequestExists: boolean = false;
  agreementsRequestArray: Array<any> = [];
  agreementsRequestArrayExams: Array<any> = [];
  analytesAgreementRequestArray: Array<any> = [];
  //#endregion


  //#region Customer Variables / Variáveis referentes aos Clientes.
  customerName: string = "";
  customerGender: number = 0;
  customerId: string = "";
  customerBirthDate: string = "";
  customerCpf: string = "";
  //#endregion

  //#region QueryParameter Variables / Variáveis de parâmetro para as queries (consultas)
  getParameters: string = (`?page=${this.page}&index=${this.index}`);
  getParametersAgreement: string = (`?page=${this.page}&registerIndex=${this.index}`);
  //#endregion


  //#region Forms
  form = {
    material: new UntypedFormControl(null, [Validators.required]),
    isUrgent: new UntypedFormControl(false, [Validators.required]),
  }

  formCustomer = {
    customerName: new UntypedFormControl(null, [Validators.required]),
    customerGenderType: new UntypedFormControl(null, [Validators.required]),
    customerBirthDate: new UntypedFormControl(null, [Validators.required]),
    customerMotherName: new UntypedFormControl(null, [Validators.required]),
    customerResponsibleName: new UntypedFormControl(null, [Validators.required]),
    customerSocialName: new UntypedFormControl(null, [Validators.required]),
    customerCnpjCpf: new UntypedFormControl(null, [Validators.required]),
    customerRg: new UntypedFormControl(null, [Validators.required]),
    customerPassport: new UntypedFormControl(null, [Validators.required]),
    customerCellphone: new UntypedFormControl(null, [Validators.required]),
    customerResidentialPhone: new UntypedFormControl(null, [Validators.required]),
    customerEmail: new UntypedFormControl(null, [Validators.required]),
  };

  formCollect = {
    hasPriority: new UntypedFormControl(false, [Validators.required]),
    includeCustomerToCollect: new UntypedFormControl(false, [Validators.required])
  }


  questionForm = {
    id: new UntypedFormControl(null, [Validators.required]),
    question: new UntypedFormControl(null, [Validators.required]),
    answer: new UntypedFormControl(null, [Validators.required])
  }

  requisitionForm = {
    customerId: new UntypedFormControl(null, [Validators.required]),
    customerName: new UntypedFormControl(null),
    tenantId: new UntypedFormControl(null),
    tenantCompanyName: new UntypedFormControl(null),
    unityId: new UntypedFormControl(null, [Validators.required]),
    unityName: new UntypedFormControl(null, [Validators.required]),
    collectionPlaceId: new UntypedFormControl(null, [Validators.required]),
    collectionPlaceDescription: new UntypedFormControl(null, [Validators.required]),
    birthDate: new UntypedFormControl(null),
    genderType: new UntypedFormControl(null),
    clinicalInformation: new UntypedFormControl(null),
    creationDate: new UntypedFormControl(null),
    lastUpdateDate: new UntypedFormControl(null)
  }

  instructionForm = {
    content: new UntypedFormControl(null)
  }

  //#endregion

  //#region Unity Variables / Unidades referentes às Unidades.
  unities: Array<any> = [];
  unity: Array<any> = [];
  unityOptions: Array<any> = [];
  unityIndex: number = 0;
  unityId: string = "";
  //#endregion

  //#region Register / Resolve
  registerExams: Array<Object> = [];
  registerTeste: Array<Object> = [];
  registerQuestions: Array<Object> = [];
  registerTesteFilter: Array<{ id: string, data: Array<Object> }> = [];

  resolveQuestions: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Descrição",
      method: '',
      retrive: "name",
      after: '',
      before: '',
    },
    {
      label: "_hide_name",
      method: '',
      retrive: "name",
      after: '',
      before: '',
    },
    {
      label: "Data de Resposta",
      method: 'getQuestionDate',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "Resposta",
      method: 'getQuestionAnswer',
      retrive: '',
      after: '',
      before: '',
    },
  ];

  resolveExamsPrice: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "analyteId",
      after: '',
      before: '',
    },
    {
      label: "_hide_materialId",
      method: '',
      retrive: "materialId",
      after: '',
      before: '',
    },
    {
      label: "Mnemônico",
      method: '',
      retrive: "code",
      after: '',
      before: '',
    },
    {
      label: "Descrição do Exame",
      method: '',
      retrive: "name",
      after: '',
      before: '',
    },
    {
      label: "Valor Bruto",
      method: 'getValue',
      retrive: "",
      after: '',
      before: '',
    },
    {
      label: "Acréscimo",
      method: 'getAddition',
      retrive: "",
      after: '',
      before: '',
    },
    {
      label: "Desconto",
      method: 'getDiscont',
      retrive: "",
      after: '',
      before: '',
    },
    {
      label: "Valor Líquido",
      method: 'getBalance',
      retrive: "",
      after: '',
      before: '',
    }
  ];

  resolveExams: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "analyteId",
      after: '',
      before: '',
    },
    {
      label: "_hide_materialId",
      method: '',
      retrive: "materialId",
      after: '',
      before: '',
    },
    {
      label: "Mnemônico",
      method: '',
      retrive: "code",
      after: '',
      before: '',
    },
    {
      label: "Descrição do Exame",
      method: '',
      retrive: "name",
      after: '',
      before: '',
    },
    {
      label: "Material",
      method: '',
      retrive: "description",
      after: '',
      before: '',
    },
    {
      label: "Urgente",
      method: "getUrgentSituation",
      retrive: "",
      after: '',
      before: '',
    },
    {
      label: "Previsão de Entrega",
      method: "generateDeadLine",
      retrive: "",
      after: '',
      before: '',
    },
    {
      label: "Situação",
      method: "convertExamSituation",
      retrive: '',
      after: '',
      before: '',
    }
  ];

  resolveCollectResumeExams: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "analyteId",
      after: '',
      before: '',
    },
    {
      label: "_hide_materialId",
      method: '',
      retrive: "materialId",
      after: '',
      before: '',
    },
    {
      label: "Mnemônico",
      method: '',
      retrive: "code",
      after: '',
      before: '',
    },
    {
      label: "Descrição do Exame",
      method: '',
      retrive: "name",
      after: '',
      before: '',
    },
    {
      label: "Material",
      method: '',
      retrive: "description",
      after: '',
      before: '',
    },
    {
      label: "Urgente",
      method: "getUrgentSituation",
      retrive: "",
      after: '',
      before: '',
    },
    {
      label: "Previsão de Entrega",
      method: "generateDeadLine",
      retrive: "",
      after: '',
      before: '',
    },
    {
      label: "Situação",
      method: "getCollectSituation",
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "extra",
      retrive: "getExtra",
      after: '',
      before: '',
    },
  ];

  resolveAgreementRequest: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Convênio",
      method: '',
      retrive: 'description',
      after: '',
      before: '',
    },
    {
      label: "Solicitante",
      method: '',
      retrive: "name",
      after: '',
      before: '',
    },
    {
      label: "Conselho",
      method: 'getRegister',
      retrive: "",
      after: '',
      before: '',
    },
    {
      label: "Autorização",
      method: 'getAuthorization',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "Carteirinha",
      method: 'getCardNumber',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "CID",
      method: 'getCID',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "Guia",
      method: 'getCode',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "_hide_agreementId",
      method: '',
      retrive: 'agreementId',
      after: '',
      before: '',
    }
  ];

  resolveParticularAgreement: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "Convênio",
      method: '',
      retrive: 'description',
      after: '',
      before: '',
    },
    {
      label: "Solicitante",
      method: '',
      retrive: "name",
      after: '',
      before: '',
    },
    {
      label: "Registro",
      method: 'getRegister',
      retrive: '',
      after: '',
      before: '',
    },
    {
      label: "Valor Bruto",
      method: '',
      retrive: "totalPrice",
      after: '',
      before: '',
    },
    {
      label: "_hide_agreementId",
      method: '',
      retrive: 'agreementId',
      after: '',
      before: '',
    }
  ];
  //#endregion

  formPayments = {
    agreementInformation: new UntypedFormControl(null, [Validators.required]),
    doctorInformation: new UntypedFormControl(null, [Validators.required]),
    payments: new UntypedFormControl(null, [Validators.required]),
    valorBruto: new UntypedFormControl(0, [Validators.required]),
    acrescimo: new UntypedFormControl(0, [Validators.required]),
    desconto: new UntypedFormControl(0, [Validators.required]),
    saldoAberto: new UntypedFormControl(0, [Validators.required]),
    valorLiquido: new UntypedFormControl(0, [Validators.required]),
    searchCustomer: new UntypedFormControl(null, [Validators.required])
  }

  money: Array<Object> = [];

  newPayment: Array<any> = [];

  getInvoiceArray: Array<any> = [];

  paymentsFormVector: Array<Object> = [];

  ActionsExtra = {
    Ativo: 'toggleActive',
  };

  analytesCollects: Array<Object> = [];

  constructor(
    private _dialog: MatDialog,
    private _requisitionService: RequisitionService,
    private _route: ActivatedRoute,
    private _customerService: CustomersService,
    private _router: Router,
    private _analyteService: AnalyteService,
    private _location: Location,
    private _priceTableService: PriceTableService,
    private _agreementRequestService: AgreementRequestService,
    private _answerQuestionRequisitionService: AnswerQuestionRequisitionService,
    private _formBuilder: UntypedFormBuilder,
    private _typePaymentsService: TypePaymentsService,
    private _invoiceService: InvoiceService,
    private _clipboard: Clipboard,
    private _localeDate: LocalaDatePipe
  ) {

  }

  async ngOnInit() {
    await this.getRequisitionById(this._route.snapshot.params['id']);
    await this.getPaymentsType();
  }

  includeCustomerToCollect() {
    this.analytesCollects.forEach((x) => {
      //@ts-ignore
      x.checkbox.setValue(!this.formCollect.includeCustomerToCollect.value);
    })
  }

  async finishRequisition() {
    let splittedDate = this.customerBirthDate.split('/');
    let formattedBirthDate = new Date(`${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`);

    let analytesCollects = this.analytesCollects.map((x) => (
      {
        //@ts-ignore
        id: x.id,
        //@ts-ignore
        situation: x.checkbox.value ? 1 : 0
      }
    ));

    let finishBody = {
      name: this.customerName,
      birthDate: formattedBirthDate.toISOString(),
      genderType: this.customerGender,
      customerId: this.customerId,
      unityId: this.requisitionForm.unityId.value,
      clinicalInformation: this.requisitionForm.clinicalInformation.value,
      isPriority: this.formCollect.hasPriority.value,
      analytesCollects
    }

    await this._requisitionService.putWithPath(finishBody, new Guid(this.requisitionId), 'finish').then(
      (res) => {
        SwAlSetttings.Sucesso("Requisição finalizada com sucesso!");
        setTimeout(() => {
          this._router.navigateByUrl('Cadastros/Requisicoes');
        }, 1000);
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  pixCopyAndPaste() {
    let text: string | null = null;
    if (document.getElementById("pix_copy_and_paste")) {
      //@ts-ignore
      text = document.getElementById("pix_copy_and_paste").value;
    }

    if (text) {
      this._clipboard.copy(text);
      SwAlSetttings.Sucesso("Copiado com sucesso!");
    }
  }

  getRegister(item: any) {
    return `${item.council}/${item.registrationState}: ${item.registrationNumber}`;
  }

  async additionOrDiscountTitle(getInvoice: Array<GetAllInvoices>, agreementRequest: Guid, value: number, action: ParticularStatus) {
    let alreadyIntegrated: boolean = false;
    let additionMessage: string = "Deseja realmente incluir um acréscimo para este título financeiro?";
    let discountMessage: string = "Deseja realmente incluir um desconto para este título financeiro?";
    let successAditionMessage: string = "Acréscimo realizado com sucesso!";
    let successDiscountMessage: string = "Desconto realizado com sucesso!";
    let invoiceAdditionProgress = {
      action: 6,
      id: agreementRequest,
      typePaymentId: null,
      value: value
    };
    let invoiceDiscountProgress = {
      action: 7,
      id: agreementRequest,
      typePaymentId: null,
      value: value
    };

    setTimeout(() => {
      SwAlSetttings.Confirmar(action == ParticularStatus.Addition ? additionMessage : discountMessage).then(
        async (x) => {
          if (x.isConfirmed) {
            getInvoice[0].progress.forEach(progress => {
              if (progress.situation === 1) {
                alreadyIntegrated = true;
              };
            });

            if (!alreadyIntegrated) {
              await this.integrateTitle(getInvoice[0], agreementRequest, parseFloat(getInvoice[0].value));
            }

            let bodyObject = {
              agreementId: getInvoice[0].agreementId,
              agreementPlanId: getInvoice[0].agremeentPlanId,
              analytes: [],
              authorization: getInvoice[0].authorization,
              cardNumber: getInvoice[0].cardNumber,
              cardValidity: getInvoice[0].cardValidity,
              //TODO verificar retorno do cid no getInvoice(back) para encaminhar corretamente no update
              cid: null,
              code: getInvoice[0].code,
              doctorId: getInvoice[0].doctorId,
              invoiceProgress: action == ParticularStatus.Addition ? invoiceAdditionProgress : invoiceDiscountProgress,
              isActive: true,
            }

            await this._invoiceService.put(bodyObject, agreementRequest).then(
              async (res) => {
                SwAlSetttings.Sucesso(action == ParticularStatus.Addition ? successAditionMessage : successDiscountMessage);
                await this.actions({ action: 'OpenDropdown', id: agreementRequest }); // atualiza os dados de pagamento
              }
            ).catch(
              (err) => {
                SwAlSetttings.printError(err);
              }
            );
          }
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      )
    }, 100);
  }

  async integrateTitle(getInvoice: GetAllInvoices, agreementRequest: Guid, bruteValue: number) {
    let invoiceProgress = {
      action: 1,
      id: agreementRequest,
      typePaymentId: null,
      value: bruteValue
    };

    let bodyObject = {
      agreementId: getInvoice.agreementId,
      agreementPlanId: getInvoice.agremeentPlanId,
      analytes: [],
      authorization: getInvoice.authorization,
      cardNumber: getInvoice.cardNumber,
      cardValidity: getInvoice.cardValidity,
      //TODO verificar retorno do cid no getInvoice(back) para encaminhar corretamente no update
      cid: null,
      code: getInvoice.code,
      doctorId: getInvoice.doctorId,
      invoiceProgress: invoiceProgress,
      isActive: true,
    };

    await this._invoiceService.put(bodyObject, agreementRequest).then(
      (res) => {
        setTimeout(async () => {
          SwAlSetttings.Sucesso('Título integrado com sucesso!');
          await this.actions({ action: 'OpenDropdown', id: agreementRequest }); // atualiza os dados de pagamento
        }, 100);
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  //TODO refatorar para vetor de pagamentos
  async putInvoice(getInvoice: Array<GetAllInvoices>, requisitionId: Guid, paymentId: Guid | null, agreementRequest: Guid, isAddition?: boolean, isDiscount?: boolean) {
    let action: InvoiceStatus = InvoiceStatus.NotImplanted;
    let value: number = 0;
    let analytes: Array<Object> = [];
    let alreadyIntegrated = false;

    getInvoice[0].progress.forEach(progress => {
      if (progress.situation === 1) {
        alreadyIntegrated = true;
      };
    });

    setTimeout(() => {
      SwAlSetttings.Confirmar('Confirma a integração e baixa dos valores informado nas formas de pagamento?').then(
        async (x) => {
          if (x.isConfirmed) {

            if (!alreadyIntegrated) {
              await this.integrateTitle(getInvoice[0], agreementRequest, parseFloat(getInvoice[0].value));
            }

            action = InvoiceStatus.Received;
            value = parseFloat(getInvoice[0].value);

            for await (const payments of this.newPayment) {
              if (payments.value.value <= 0) {
                setTimeout(() => {
                  SwAlSetttings.alertaMsg('É necessário informar o valor do pagamento!');
                }, 100);
              } else {
                let invoiceProgressReceive: Object = {
                  "id": agreementRequest,
                  "action": action,
                  "value": typeof payments.value.value === "number" ? payments.value.value.toString().replace(',', '.') : payments.value.value.replace(',', '.'),
                  "typePaymentId": payments.label.value
                };

                let bodyReceive = {
                  "isActive": true,
                  "agreementPlanId": getInvoice[0].agremeentPlanId ? getInvoice[0].agremeentPlanId : null,
                  "agreementId": getInvoice[0].agreementId,
                  "doctorId": getInvoice[0].doctorId,
                  "code": getInvoice[0].code,
                  "authorization": getInvoice[0].authorization,
                  "cid": null,
                  "cardNumber": getInvoice[0].cardNumber,
                  "cardValidity": getInvoice[0].cardValidity,
                  "analytes": analytes,
                  "invoiceProgress": invoiceProgressReceive
                };

                await this._invoiceService.put(bodyReceive, agreementRequest).then(
                  async (res) => {
                    SwAlSetttings.Sucesso('Pagamento realizado com sucesso!');
                    await this.actions({ action: 'OpenDropdown', id: agreementRequest }); // atualiza os dados de pagamento
                    this.goForward(this.myStepper);
                  }
                ).catch(
                  (err) => {
                    SwAlSetttings.printError(err);
                  }
                );
              }
            }
          }
        }
      )
    }, 100);
  }

  goForward(stepper: MatStepper | undefined) {
    stepper!.next();
  }

  retornaPaymentForm(getInvoice: GetAllInvoices) {
    if (getInvoice) {
      return this._formBuilder.group({
        agreementInformation: new UntypedFormControl(null, [Validators.required]),
        doctorInformation: new UntypedFormControl(null, [Validators.required]),
        payments: new UntypedFormControl(null, [Validators.required]),
        paymentType: new UntypedFormControl(null, [Validators.required]),
        valorBruto: new UntypedFormControl(getInvoice.value, [Validators.required]),
        acrescimo: new UntypedFormControl(getInvoice.addition, [Validators.required]),
        desconto: new UntypedFormControl(getInvoice.discont, [Validators.required]),
        totalPago: new UntypedFormControl(getInvoice.received, [Validators.required]),
        valorLiquido: new UntypedFormControl(getInvoice.balance, [Validators.required]),
        searchCustomer: new UntypedFormControl(null, [Validators.required])
      }).controls;
    } else {
      return this._formBuilder.group({
        agreementInformation: new UntypedFormControl(null, [Validators.required]),
        doctorInformation: new UntypedFormControl(null, [Validators.required]),
        payments: new UntypedFormControl(null, [Validators.required]),
        paymentType: new UntypedFormControl(null, [Validators.required]),
        valorBruto: new UntypedFormControl(0, [Validators.required]),
        acrescimo: new UntypedFormControl(0, [Validators.required]),
        desconto: new UntypedFormControl(0, [Validators.required]),
        saldoAberto: new UntypedFormControl(0, [Validators.required]),
        totalPago: new UntypedFormControl(0, [Validators.required]),
        valorLiquido: new UntypedFormControl(0, [Validators.required]),
        searchCustomer: new UntypedFormControl(null, [Validators.required])
      }).controls;
    }
  }

  payment(paymentsType: UntypedFormControl) {
    let initial: number = 0;
    this.newPayment = [];

    //@ts-ignore
    if (paymentsType.value.length > 0) {
      //@ts-ignore
      paymentsType.value.forEach((element: any) => {
        const formPayment = {
          label: new UntypedFormControl(element, [Validators.required]),
          value: new UntypedFormControl(initial.toFixed(2), [Validators.required]),
          dueDate: new UntypedFormControl(null, [Validators.required]),
          transaction: new UntypedFormControl(null, [Validators.required]),
          parcel: new UntypedFormControl(null, [Validators.required]),
          percentage: new UntypedFormControl(null, [Validators.required]),
          type: new UntypedFormControl(null, [Validators.required]),
        };
        //@ts-ignore
        this.newPayment.push(formPayment);
      });
    }
  }

  totalValue(id: any, index?: number) {
    let valor: number = 0;

    this.newPayment.forEach((x: any) => {
      valor = parseFloat(x.value.value.replace(",", ".")) + valor
    });

    //@ts-ignore
    if (this.paymentsFormVector[id].valorBruto.value >= valor) {
      //@ts-ignore
      this.paymentsFormVector[id].saldoAberto.setValue(this.paymentsFormVector[id].valorLiquido.value ? (this.paymentsFormVector[id].valorLiquido.value - valor).toFixed(2) : (this.paymentsFormVector[id].valorBruto.value - valor).toFixed(2));
    } else if (this.newPayment.length == 1) {
      //@ts-ignore
      this.newPayment[0].value.setValue(this.paymentsFormVector[id].valorBruto.value);
      //@ts-ignore
      this.paymentsFormVector[id].saldoAberto.setValue(0);
    } else if (this.newPayment.length > 1 && index) {
      //@ts-ignore
      this.newPayment[index].value.setValue(this.paymentsFormVector[id].saldoAberto.value);
      //@ts-ignore
      this.paymentsFormVector[id].saldoAberto.setValue(0);
    }
  }

  checkReceivedValue(pay: UntypedFormControl, id: number) {
    //@ts-ignore
    pay.value.value > this.paymentsFormVector[id].valorLiquido.value ? pay.value.setValue(this.paymentsFormVector[id].valorLiquido.value) : pay.value.setValue(pay.value.value);
  }

  async getInvoice(agreementId: Guid, requisitionId: Guid, typeInvoice: number) {
    let queryParams = `Type=${typeInvoice}&RequisitionId=${requisitionId}&AgreementId=${agreementId}&page=1&numberRegistry=9999`;
    let listInvoice: Array<GetAllInvoices> = [];

    await this._invoiceService.getByPath(queryParams, '').then(
      (res) => {
        if (res.data.length != 0) {
          res.data.forEach((x) => {
            listInvoice.push(x);
          })
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )

    return listInvoice;
  }

  getPayment(label: string) {
    //@ts-ignore
    let name = this.money.filter(x => x.value === label);
    //@ts-ignore
    return name[0].label;
  }

  getTypeOfPayment(label: string) {
    //@ts-ignore
    let name = this.money.filter(x => x.value === label);
    //@ts-ignore
    return name[0].type;
  }

  public get GetETypePayments(): typeof ETypePayments {
    return ETypePayments;
  }

  public get GetEParticularStatus(): typeof ParticularStatus {
    return ParticularStatus;
  }

  getAuthorization(item: any) {
    return item.authorization ? item.authorization : "";
  }

  getCardNumber(item: any) {
    return item.cardNumber ? item.cardNumber : "";
  }

  getCID(item: any) {
    return item.cid ? item.cid : "";
  }

  getCode(item: any) {
    return item.code ? item.code : "";
  }

  close() {
    this._dialog.closeAll()
  }

  async myTabSelectedTabChange(changeEvent: MatTabChangeEvent) {
    //this.change = changeEvent.index;
    //this.agreementsRequestArray = [];
    //this.getRequisitionById(this.route.snapshot.params['id']);
  }

  openDialogModalExam(e: any, id: Guid, materialId: Guid): void {
    this.exam = '';
    const myTempDialog = this._dialog.open(this.dialogModalExam, {
      width: '660px'
    });
    this.form.material.setValue(materialId);
    this.exam = e;
    this.getAnalytesById(id);
  }

  openDialogModalInstruction(analyte: AllAgreementRequestExams) {
    const myTempDialog = this._dialog.open(this.dialogModalInstruction, {
      width: '660px',
      data: analyte
    });
  }

  openDialogModalAllInstructions(analyteList: Array<AnalyteInstructionsList>) {
    const myTempDialog = this._dialog.open(this.dialogModalAllInstructions, {
      width: '660px',
      maxHeight: '660px',
      data: analyteList
    });
  }

  openDialogModalQuestion(description: string, id: Guid): void {
    const myTempDialog = this._dialog.open(this.dialogModalQuestion, {
      width: '660px',
    });
    this.questionForm.question.setValue(description);
    this.questionForm.id.setValue(id);

    myTempDialog.afterClosed().subscribe((res) => {
      this.ngOnInit();
    });
  }

  openDialogModalAllQuestions(): void {
    const myTempDialog = this._dialog.open(this.dialogModalAllQuestions, {
      width: '660px'
    });

    myTempDialog.afterClosed().subscribe((res) => {
      this.ngOnInit();
    });
  }

  openDialogModalPIX(): void {
    const myTempDialog = this._dialog.open(this.dialogPixModal, {
      width: '660px'
    });

    myTempDialog.afterClosed().subscribe((res) => {
      this.ngOnInit();
    });
  }

  async getPaymentsType() {
    this.money = [];
    await this._typePaymentsService.getAll().then(
      (res) => {
        if (res.data.length != 0) {
          res.data.forEach((paymentType) => {
            this.money.push(
              {
                "label": paymentType.description,
                "value": paymentType.id,
                "type": paymentType.type
              }
            );
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  async getCustomerById(id: string) {
    await this._customerService.getById(new Guid(id)).then(
      (res) => {
        if (res.data.length != 0) {
          const customer = res.data[0];
          this.customerName = customer.name ? customer.name.toUpperCase() : customer.name;
          this.customerCpf = customer.cpf;
          this.customerBirthDate = new Date(customer.birthDate).toLocaleDateString();

          this.formCustomer.customerBirthDate.setValue(customer.birthDate);
          this.formCustomer.customerCellphone.setValue(customer.cellPhone);
          this.formCustomer.customerCnpjCpf.setValue(customer.cpf);
          this.formCustomer.customerEmail.setValue(customer.email);
          this.formCustomer.customerGenderType.setValue(customer.genderType);
          this.formCustomer.customerMotherName.setValue(customer.motherName ? customer.motherName.toUpperCase() : '');
          this.formCustomer.customerName.setValue(customer.name ? customer.name.toUpperCase() : '');
          this.formCustomer.customerPassport.setValue(customer.passportNumber);
          this.formCustomer.customerResidentialPhone.setValue(customer.residencePhone);
          this.formCustomer.customerRg.setValue(customer.rg);
          this.formCustomer.customerSocialName.setValue(customer.socialName ? customer.socialName.toUpperCase() : '');
          this.formCustomer.customerResponsibleName.setValue(customer.nameResponsible ? customer.nameResponsible.toUpperCase() : '');
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  async getAnalytesById(analyteId: Guid) {
    await this._analyteService.getById(analyteId).then(
      (res) => {
        if (res.data.length != 0) {
          this.form.isUrgent.setValue(res.data[0].isUrgent)
          res.data.forEach((analyte) => {
            analyte.materiais.forEach((analyteMaterials) => {
              this.materialExamOptions.push({
                label: analyteMaterials.externalId + ' - ' + analyteMaterials.description,
                value: analyteMaterials.materialId
              });

            });
          });
        } else {
          SwAlSetttings.alertaMsg(`Não foi possível realizar a consulta!`);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async saveQuestionAnswer() {
    let answer = {
      questionId: this.questionForm.id.value,
      answer: this.questionForm.answer.value
    }

    await this._answerQuestionRequisitionService.put(answer, new Guid(this.requisitionId)).then(
      (res: any) => {
        SwAlSetttings.Sucesso('Resposta salva com sucesso!');
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )

    setTimeout(() => {
      this.close();
    }, 1000);
  }

  async saveAllQuestionAnswer() {
    let answerArray: Array<Object> = [];

    this.newQuestionAnswer.forEach((x) => {
      let answer = {
        //@ts-ignore
        questionId: x.id.value,
        //@ts-ignore
        answer: x.answer.value
      }
      answerArray.push(answer);
    });

    for await (const answer of answerArray) {
      this._answerQuestionRequisitionService.put(answer, new Guid(this.requisitionId)).then(
        (res: any) => {
          SwAlSetttings.Sucesso('Resposta salva com sucesso!');
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );

      setTimeout(() => {
        this.close();
      }, 1000);
    }
  }

  getExtra(item: any) {
    //@ts-ignore
    return this.Extras;
  }

  getDiscont(item: any) {
    let valor = item.discont.toFixed(2).toString().split('.')
    let real = valor[0]
    let centavo = valor[1]

    return 'R$ ' + real + ',' + centavo;
  }

  getAddition(item: any) {
    let valor = item.addition.toFixed(2).toString().split('.')
    let real = valor[0]
    let centavo = valor[1]

    return 'R$ ' + real + ',' + centavo;
  }

  getValue(item: any) {
    let valor = item.value.toFixed(2).toString().split('.')
    let real = valor[0]
    let centavo = valor[1]

    return 'R$ ' + real + ',' + centavo;
  }

  getBalance(item: any) {
    let valor = item.balance.toFixed(2).toString().split('.')
    let real = valor[0]
    let centavo = valor[1]

    return 'R$ ' + real + ',' + centavo;
  }

  async getRequisitionById(id: string) {
    this.newQuestionAnswer = [];
    this.totalPriceList = [];
    await this._requisitionService.getById(new Guid(id)).then(
      async (res) => {
        const requisition = res.data[0];

        this.customerName = requisition.name ? requisition.name.toUpperCase() : '';
        this.formCustomer.customerName.setValue(requisition.name ? requisition.name.toUpperCase() : '');
        this.customerGender = requisition.genderType;
        this.customerCpf = requisition.cpf;
        this.customerBirthDate = new Date(requisition.birthDate).toLocaleDateString();

        this.requisitionRequestArray = res.data;
        this.requisitionId = requisition.id;
        this.customerId = requisition.customerId;
        this.requisitionForm.unityName.setValue(requisition.unityDescription ? requisition.unityDescription.toUpperCase() : '');
        this.requisitionForm.unityId.setValue(requisition.unityId);
        this.requisitionForm.clinicalInformation.setValue(requisition.clinicalInformation);
        this.requisitionForm.collectionPlaceDescription.setValue(requisition.collectionPlaceDescription);
        this.requisitionForm.collectionPlaceId.setValue(requisition.collectionPlaceId);
        this.requisitionNumber = requisition.number;
        this.requisitionForm.creationDate.setValue(requisition.createdAt);
        this.requisitionForm.lastUpdateDate.setValue(requisition.updatedAt);
        this.agreementsRequestArray = requisition.agreementRequest

        requisition.questions.forEach((x) => {
          const formQuestion = {
            id: new UntypedFormControl(x.id, [Validators.required]),
            answer: new UntypedFormControl(x.answer),
            description: new UntypedFormControl(x.name, [Validators.required])
          };

          //@ts-ignore
          this.newQuestionAnswer.push(formQuestion);
        });

        this.resolveQuestion(requisition.questions, this.resolveQuestions);

        const agreementRequest: Array<object> = [];

        this.agreementsRequestArray.forEach((x: any) => {

          // Alimenta vetor com todos os exames de todos os pedidos para o resumo da coleta da requisição
          x.agreementsExamsRequest.forEach((exams: any) => {
            //@ts-ignore
            if (!this.allAgreementRequestExams.find((x) => x.id === exams.id)) {
              this.allAgreementRequestExams.push(exams);
            }
          });

          //@ts-ignore
          let obj = { "price": x.totalPrice, "id": x.id, "agreementId": x.agreementId };

          this.totalPriceList.push(obj);

          if (x.typeInvoice === 1) {
            agreementRequest.push(x);
          }
        });

        await this.getCustomerById(requisition.customerId);

        this.change === 2 ? this.resolve(agreementRequest, this.resolveParticularAgreement) : this.resolve(this.agreementsRequestArray, this.resolveAgreementRequest);

      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  newSolicitation() {
    this._router.navigateByUrl('Externo/Convenio/CriarRequisicao/' + this.requisitionId);
  }

  getExamsAgreement(id: any) {
    let teste: any = [];
    this.agreementsRequestArray.forEach((x: any) => {
      if (id === x.id) {
        teste.push(x.agreementsExamsRequest)
      }
    });
    return this.resolveExamsColumns(teste[0], this.change === 2 ? this.resolveExamsPrice : this.resolveExams);
  }

  resolveExamsColumns(data: any, columns: any) {
    let registerExams: any = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      registerExams.push(obj);
    });

    return registerExams;
  }

  resolveQuestion(data: any, columns: any) {
    this.registerQuestions = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerQuestions.push(obj);
    });
  }

  back() {
    this._location.back()
  }

  closeModal() {
    this._dialog.closeAll();
  }

  getUrgentSituation(item: any) {
    let situation: boolean = item.isUrgent;
    return situation ? "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Sim</span>" : "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Não</span>";
  }

  convertExamSituation(item: any) {
    //@ts-ignore
    return item.status ? this.examSituation[item.status] : this.examSituation[0];
  }


  generateDeadLine(item: any) {
    if (item.isUrgent) {
      return new Date(item.analyteDeadline).toLocaleString();
    } else {
      return new Date(item.analyteDeadline).toLocaleString();
    }
  }

  resolve(data: any, columns: any) {
    this.registerExams = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerExams.push(obj);
    });
  }

  getCollectSituation(item: any, index: number) {

    //@ts-ignore
    if (!this.analytesCollects.find((x) => x.id === item.id)) {
      let obj = {
        id: item.id,
        index: index,
        checkbox: new UntypedFormControl(false)
      }

      this.analytesCollects.push(obj);
    }

    //@ts-ignore
    return this.analytesCollects.find((x) => x.id === item.id).checkbox;
  }

  async getAnalyteInstruction(item: AllAgreementRequestExams) {
    this.instructionForm.content.setValue(null);
    let instructions: string = '';
    await this._analyteService.getById(new Guid(item.analyteId)).then((res) => {
      res.data[0].instructions.forEach((instruction, index) => {
        if (index != res.data[0].instructions.length - 1) {
          instructions += `${instruction.description}\n\n`;
        } else {
          instructions += `${instruction.description}`;
        }
      });
      this.instructionForm.content.setValue(instructions);
      this.openDialogModalInstruction(item);
    }).catch((err) => {
      SwAlSetttings.printError(err);
    });
  }

  //TODO pendente listar todos os processos de resumo de coleta
  async getAllAnalyteInstructions(item: Array<AllAgreementRequestExams>) {
    let analyteInstructionsList: Array<AnalyteInstructionsList> = [];

    for await (const analyte of item) {
      this._analyteService.getById(new Guid(analyte.analyteId)).then((res) => {
        let obj: AnalyteInstructionsList = {
          "analyteId": analyte.analyteId,
          "code": analyte.code,
          "analyteDescription": analyte.name,
          "instructions": res.data[0].instructions
        }

        analyteInstructionsList.push(obj);
      }).catch((err) => {
        SwAlSetttings.printError(err);
      });
    }

    this.openDialogModalAllInstructions(analyteInstructionsList);
  }

  resolveResumeCollectExams(data: any, columns: any) {
    let collectExams: any = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      collectExams.push(obj);
    });

    return collectExams;
  }

  async actions(emit: any) {
    //@ts-ignore
    switch (emit.action) {
      case 'Select':
        break;
      case 'OpenDropdown':
        this.getInvoiceArray = [];
        //@ts-ignore
        let totalPriceList = this.totalPriceList.filter(item => item.id === emit.id);
        //@ts-ignore
        let getInvoice = await this.getInvoice(totalPriceList[0].agreementId, this._route.snapshot.params['id'], 1);
        this.getInvoiceArray.push(getInvoice);
        this.paymentsFormVector[emit.id] = this.retornaPaymentForm(getInvoice[0]);
        break;
      case 'Editar':
        //@ts-ignore
        this._router.navigateByUrl('Externo/Convenio/CriarRequisicao/' + this._route.snapshot.params['id'] + '$' + emit.object.id);
        break;
      case 'Excluir':
        //@ts-ignore
        this.excluir(this._route.snapshot.params['id'], emit.object.id);
        break;
    }
  }

  getAnalytePrice(analyteId: Guid, index: number) {
    this.examPrice.forEach((examPrice) => {
      //@ts-ignore
      if (examPrice.id === analyteId.toString()) {
        this.examPriceList.push({
          //@ts-ignore
          "id": examPrice.id,
          //@ts-ignore
          "code": examPrice.code,
          //@ts-ignore
          "value": examPrice.value
        });
        //@ts-ignore
        this.newExam[index].val.setValue(examPrice.value);
      }
    });
  }

  async getByIdPriceTable(priceTableId: Guid) {
    this.examOptions = [];
    await this._priceTableService.getById(priceTableId).then(
      (res) => {
        if (res.data.length != 0) {
          res.data.forEach((priceTable) => {
            priceTable.priceTableExams.forEach((priceTableExams) => {
              this.examPrice.push({
                id: priceTableExams.analyteId,
                code: priceTableExams.code + ' - ' + priceTableExams.name,
                value: priceTableExams.price
              });
              this.examOptions.push({
                label: priceTableExams.code + ' - ' + priceTableExams.name + ' - ' + priceTableExams.billingCode,
                value: priceTableExams.analyteId,
                price: priceTableExams.price
              });
            });
          });
        } else {
          SwAlSetttings.alertaMsg(`Não foi possível encontrar a Tabela de Preço com Id(${priceTableId})!`);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  excluir(requisitionId: Guid, agreementRequestId: Guid) {
    SwAlSetttings.ALERT_CONFIRM.fire().then(
      async (r) => {
        if (r.isConfirmed) {
          await this._agreementRequestService.deleteByPath(undefined, `${requisitionId}/agreementrequest/${agreementRequestId}`);
          SwAlSetttings.Sucesso('Pedido médico excluído com sucesso!');
          this.ngOnInit();
        }
      }
    ).catch((error) => {
      SwAlSetttings.printError(error);
    }
    );
  }

  getQuestionDate(item: any) {
    if (item.updatedAt) {
      return this._localeDate.transform(new Date(item.updatedAt));
    } else {
      return '';
    }
  }

  getQuestionAnswer(item: any) {
    if (!item.answer) {
      let spanAnswer = "<span class='span-styles' style='color: red;'>Não Respondida</span>";
      return spanAnswer;
    } else {
      return item.answer;
    }
  }

  examsActions(emit: any) {
    //@ts-ignore
    switch (emit.action) {
      case 'Select':
        break;
      case 'Editar':
        //@ts-ignore
        //this.openDialogModalExam(emit.object['Descrição do Exame'], emit.object['id'], emit.object['_hide_materialId']);
        break;
      case 'Excluir':
        break;
    }
  }

  questionActions(emit: any) {
    //@ts-ignore
    switch (emit.action) {
      case 'Responder':
        this.openDialogModalQuestion(emit.object['Descrição'], emit.object['id']);
        break;
      case 'Editar':
        //@ts-ignore
        //this.openDialogModalExam(emit.object['Descrição do Exame'], emit.object['id'], emit.object['_hide_materialId']);
        break;
      case 'Excluir':
        break;
    }
  }

}
