import { AsyncPipe } from "@angular/common";
import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { FormSelectPropComponent } from "@app/modules_new/shared/components/form/select-prop/select-prop.component";
import { PaginationTableComponent } from "@app/modules_new/shared/components/form/table/pagination/pagination.component";
import { TableFormComponent } from "@app/modules_new/shared/components/form/table/table.component";
import { ContentFilterLayoutComponent } from "@app/modules_new/shared/layouts/content-filter/content-filter.component";
import { TranslateModule } from "@ngx-translate/core";
import { tableActions, tableColumns } from "./userAuthenticate";
import { PageableComponent } from "@app/modules_new/common/components/pageable";
import { FormGroup, FormControl } from "@angular/forms";
import { PaginationEvent } from "@app/modules_new/shared/components/form/table/pagination/pagination.model";
import { UserAuthenticateModal } from "./modal/userAuthenticate-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { UserAuthenticateResponse } from "@app/modules_new/external/data/services/userAuthenticate/userAuthenticate.types";
import { UserAuthenticateFacade } from "@app/modules_new/external/data/facade/userAuthenticate.facade";

@Component({
   selector: "userAuthenticate",
   templateUrl: "./userAuthenticate.component.html",
   standalone: true,
   imports: [
      ContentFilterLayoutComponent,
      TranslateModule,
      TableFormComponent,
      PaginationTableComponent,
      AsyncPipe,
      FormSelectPropComponent
   ]
})
export class UserAuthenticateComponent
   extends PageableComponent<UserAuthenticateResponse[]>
   implements OnInit
{
   constructor() {
      super(tableColumns, tableActions);
   }
   private _dialog = inject(MatDialog);
   private _userAuthenticateFacade = inject(UserAuthenticateFacade);
   formCompanyUnit: Array<object> = [];
   formCompanyUnitGroup: FormGroup = new FormGroup({
      type: new FormControl(1)
   });

   formFilterGroup = new FormGroup({
      name: new FormControl(null),
      birthDate: new FormControl(null)
   });

   formFilter: Array<object> = [
      {
         placeholder: "Nome",
         type: "text",
         form: this.formFilterGroup.controls["name"],
         click: true
      },
      {
         placeholder: "Data de Nascimento",
         type: "date",
         form: this.formFilterGroup.controls["birthDate"],
         click: true
      }
   ];

   @ViewChild("requisition") requisitionTable!: TableFormComponent;

   async ngOnInit() {
      const filter = {
         AccessType: this.formCompanyUnitGroup.controls["type"].value,
         Name: this.formFilterGroup.controls["name"].value,
         BirthDate: this.formFilterGroup.controls["birthDate"].value
      };
      const { data$, total$ } = this._userAuthenticateFacade.get(1, 30, filter);
      this.data = data$;
      this.total = total$;

      this.formCompanyUnit = [
         {
            placeholder: "Selecione o tipo do acesso",
            form: this.formCompanyUnitGroup.controls["type"],
            options: [
               {
                  value: 1,
                  label: "Cliente"
               },
               // {
               //    value: 2,
               //    label: "Unidade ou Filial"
               // },
               {
                  value: 3,
                  label: "Médico Solicitante"
               },
               {
                  value: 4,
                  label: "Convênio"
               }
               // {
               //    value: 5,
               //    label: "Plano do Convênio"
               // },
               // {
               //    value: 6,
               //    label: "Destino de Entrega"
               // },
               // {
               //    value: 7,
               //    label: "Local de Coleta"
               // }
            ],
            type: "option"
         }
      ];
      setTimeout(() => {
         this.formCompanyUnitGroup.controls["type"].setValue(1);
      });
   }

   ngAfterViewInit() {
      this.requisitionTable.userInteractions$.subscribe(async (response) => {
         switch (response?.eventType) {
            case "IS_ACTIVE_CHANGE":
               const success = await this._userAuthenticateFacade.patchActive(
                  response.payload.data.id,
                  response.payload.data.isActive
               );

               if (success) {
                  this.get();
               }

               break;
            case "ACTIONS_PRESSED":
               switch (response.payload.event) {
                  case "EDITAR":
                     const dialog = this._dialog.open(UserAuthenticateModal, {
                        width: "calc(900px * var(--proportional))",
                        data: response.payload.data
                     });

                     dialog.afterClosed().subscribe((result) => {
                        if (result && result.success == true) {
                           this.get();
                        }
                     });
                     break;
               }
               break;
         }
      });
   }

   changePage(event: PaginationEvent) {
      const filter = {
         AccessType: this.formCompanyUnitGroup.controls["type"].value,
         Name: this.formFilterGroup.controls["name"].value,
         BirthDate: this.formFilterGroup.controls["birthDate"].value
      };
      this.page = event.page;
      this.numberRegistry = event.numberRegistry;
      this._userAuthenticateFacade.get(
         event.page,
         event.numberRegistry,
         filter
      );
   }

   ngOnDestroy() {
      this.unsubscribeAll();
   }

   resetFilter() {
      this.formFilterGroup.reset();
   }

   get() {
      const filter = {
         AccessType: this.formCompanyUnitGroup.controls["type"].value,
         Name: this.formFilterGroup.controls["name"].value,
         BirthDate: this.formFilterGroup.controls["birthDate"].value
      };
      this._userAuthenticateFacade.get(1, 30, filter);
   }

   openModal() {
      const dialog = this._dialog.open(UserAuthenticateModal, {
         width: "calc(900px * var(--proportional))"
      });

      dialog.afterClosed().subscribe((result) => {
         if (result && result.success == true) {
            this.get();
         }
      });
   }
}
