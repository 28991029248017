import { Component, OnInit } from "@angular/core";
import { ProfileTenantAuth } from "@app/services/auth/profile-user-tenant.service";
import decodedToken from "@app/util/Token";

@Component({
   selector: "main-menu",
   templateUrl: "./main-menu.component.html",
   styleUrls: ["./main-menu.component.scss"]
})
export class MainMenuComponent implements OnInit {
   mission: string = "";
   vision: string = "";
   values: string = "";

   UserName: string = decodedToken.name;

   constructor(private _tenantService: ProfileTenantAuth) {}

   ngOnInit(): void {
      //TODO TenantID
      // this.getTenant();
   }

   getTenant() {
      this._tenantService.getAll().then((x: any) => {
         this.vision = x.data[0].vision;
         this.mission = x.data[0].mission;
         this.values = x.data[0].values;
      });
   }
}
