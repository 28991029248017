import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { CustomerFacade } from "./customers.facade";
import { CustomerActions, CustomerFilterOptions } from "./customers.model";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { CustomersService } from "@app/services/customers/customers.service";
import { Guid } from "@app/util/guid";
import { CustomersDocumentsService } from "@app/services/customers/customers-documents/customers-documents.service";
import { ToastService } from "@app/services/translate/toast.service";

@Component({
   selector: "app-customers",
   templateUrl: "./customers.component.html",
   styleUrls: ["./customers.component.scss"]
})
export class CustomersComponentOld implements OnInit {
   constructor(
      private _customerFacade: CustomerFacade,
      private _dialog: MatDialog,
      private _customerService: CustomersService,
      private _customersDocumentsService: CustomersDocumentsService,
      private _toastService: ToastService
   ) {
      this._customerFacade.behaviourSubject.subscribe((registerCustomers) => {
         this.registerCustomers = registerCustomers;
      });
   }

   isActiveOptions: Array<{ label: string; value: string | null | boolean }> = [
      { label: "Todos", value: "todos" },
      { label: "Somente Ativos", value: true },
      { label: "Somente Inativos", value: false }
   ];

   page: number = this._customerFacade.getPage;
   total: number = this._customerFacade.getTotal;
   index: number = this._customerFacade.getIndex;

   filterOptions: Array<CustomerFilterOptions> =
      this._customerFacade.getOptionsList;

   formGroup: UntypedFormGroup = this._customerFacade.getForm;

   registerCustomers = this._customerFacade.getRegisterCustomers;

   document = {
      registerName: new UntypedFormControl()
   };

   customerDocuments = new UntypedFormControl([]);

   clienteId: string = "";

   @ViewChild("dialogModalDocument")
   dialogModalDocument!: TemplateRef<any>;

   async ngOnInit() {
      this.formGroup.controls["filter"].setValue("Name");
      this.formGroup.controls["search"].reset();
      await this.getCustomers();
   }

   resetPageAndSearch() {
      this._customerFacade.setPage = 1;
      this.getCustomers();
   }

   async getCustomers(paramPage?: Array<number>) {
      await this._customerFacade.getCustomers(paramPage);
      this.total = this._customerFacade.getTotal;
      this.page = this._customerFacade.getPage;
      this.index = this._customerFacade.getIndex;
      this.registerCustomers = this._customerFacade.getRegisterCustomers;
   }

   async getSortedPage(event: { label: string; index: number; order: string }) {
      await this._customerFacade.getSortedPage(event);
   }

   actions(emit: CustomerActions) {
      switch (emit.action) {
         case "Attach":
            this.openDialogDocument(emit.object);
            break;
      }
      this._customerFacade.customerActions(emit);
   }

   closeModal() {
      this._dialog.closeAll();
   }

   async openDialogDocument(register?: any) {
      await this.getClientDocuments(register.id);

      let fileDocuments: any = [];

      this.customerDocuments.value.forEach((item: any) => {
         fileDocuments.push({
            id: item.customerDocumentId,
            fileDocumentId: item.id
         });
      });

      this.clienteId = register.id;
      this.document.registerName.setValue(register["global.title-list.name"]);
      const myTempDialog = this._dialog.open(this.dialogModalDocument, {
         width: "700px",
         data: {
            id: register.id,
            fileDocuments: fileDocuments
         }
      });
      myTempDialog
         .afterClosed()
         .subscribe(() => this.customerDocuments.setValue([]));
   }

   async getClientDocuments(clienteId?: string) {
      const documents: Array<Object> = [];

      await this._customerService
         .getById(new Guid(clienteId))
         .then(async (res: any) => {
            await res.data[0].customerDocuments.forEach((data: any) => {
               documents.push({
                  id: data.fileDocumentId,
                  customerDocumentId: data.id,
                  name: data.name,
                  createdAt: data.createdAt
               });
            });
            this.customerDocuments.setValue(documents);
         });
   }

   async putCustomerDocument() {
      let body: any = [];
      this.customerDocuments.value.forEach((element: any) => {
         if (element.createdAt == undefined) {
            body.push(element.id);
         }
      });
      if (body.length === 0) {
         this._toastService.fireError("customer.msgNotDocumentsCustomer");
      } else {
         await this._customerService
            .getById(new Guid(this.clienteId))
            .then((res: any) => {
               res.data.forEach(async (x: any) => {
                  await this._customersDocumentsService
                     .postWithId(
                        { fileDocumentsIds: body },
                        new Guid(this.clienteId)
                     )
                     .then(() => {
                        this._toastService.fireSuccess(
                           "customer.msgDocumentsSave"
                        );
                        this.closeModal();
                     })
                     .catch((err) => {
                        this._toastService.fireError(err);
                     });
               });
            });
      }
   }

   removeDocumentFromCustomer(customer: any, fileDocumentId: Guid) {
      if (customer && customer.fileDocuments) {
         const customerDocumentToRemove = (
            customer.fileDocuments as { id: string; fileDocumentId: string }[]
         ).find((item) => item.fileDocumentId === fileDocumentId.toString());
         if (customerDocumentToRemove) {
            this._customersDocumentsService.delete(
               new Guid(customerDocumentToRemove.id)
            );
            this.customerDocuments.setValue(
               this.customerDocuments.value.filter(
                  (file: { id: string }) =>
                     file.id !== fileDocumentId.toString()
               )
            );
         }
      }
   }

   validateBirthDate() {
      let date = this.formGroup.controls["search"].value.split("/");

      if (date.length == 3) {
         date[0] = date[0] > "31" || date[0] < 1 ? "31" : date[0];
         date[1] = date[1] > "12" || date[1] < 1 ? "12" : date[1];
         date = `${date[0].padStart(2, "0")}/${date[1].padStart(2, "0")}/${
            date[2]
         }`;
         this.formGroup.controls["search"].setValue(date);
      }
   }

   ActionsExtra = {
      Ativo: "PutActive"
   };

   resetFilter() {
      this.formGroup.controls["filter"].setValue("Name");
      this.formGroup.controls["search"].reset();
      this.formGroup.controls["isActive"].reset();
   }

   navigate() {
      this._customerFacade.navigateToCreateCustomer();
   }
}
