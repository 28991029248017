<button
    #btn
    [ngStyle]="{
        background: isAdm ? '#404040' : ''
    }"
    class="flex gp-12 align-center btn custom-padding"
    (click)="openModal()"
>
    <mat-icon>filter_list</mat-icon>
    {{ "filter.title" | translate }}
</button>
