import { CommonModule } from "@angular/common";
import {
   Component,
   inject,
   OnInit,
   QueryList,
   ViewChildren
} from "@angular/core";
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GroupsFacade } from "@app/modules_new/administrativeData/data/facade/groups.facade";
import { RequestOptions } from "@app/modules_new/data/services/types/http.types";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { InputSelectFormComponent } from "@app/modules_new/shared/components/form/input-select/base/input-select.component";
import { InputSelectMultplesFormComponent } from "@app/modules_new/shared/components/form/input-select/multiples/multiples.component";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ModalLayoutComponent } from "@app/modules_new/shared/components/form/modal/modal.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
   selector: "permission-groups-modal",
   templateUrl: "./permission-groups-ui-modal.component.html",
   standalone: true,
   imports: [
      ModalLayoutComponent,
      ReactiveFormsModule,
      ButtonFormComponent,
      CommonModule,
      TranslateModule,
      InputSelectFormComponent,
      InputSelectMultplesFormComponent
   ]
})
export class PermissionGroupsModal implements OnInit {
   private _groupsFacade = inject(GroupsFacade);
   public _dialogData = inject(MAT_DIALOG_DATA);

   form = new FormGroup({
      module: new FormControl<string | null>(null, [Validators.required]),
      resource: new FormControl<string | null>(null, [Validators.required])
   });

   title: string = "Novo permissão";
   moduleOptions: any = [];
   resourceOptions: any = [];

   @ViewChildren(InputTextFormComponent)
   InputTextFormComponent!: QueryList<InputTextFormComponent>;

   async ngOnInit() {
      const request: RequestOptions = {
         query: {
            resume: true
         }
      };

      this.moduleOptions = await this._groupsFacade.getModulesOptions(request);
   }

   async changeModule() {
      const request: RequestOptions = {
         path: "/" + this.form.controls.module.value + "/resource"
      };

      this.resourceOptions = await this._groupsFacade.getResourceOptions(
         request
      );
   }

   async submit() {
      let ids: any = []; //@ts-ignore
      this.form.controls.resource.value.forEach((element: any) => {
         ids.push(element.value);
      });

      let obj = {
         resourceId: ids
      };

      const request: RequestOptions = {
         path: `/${this._dialogData}/policy`
      };

      const response: any = await this._groupsFacade.postResource(obj, request);

      if (response.success) {
         this.form.reset();
      }
   }
}
