<div class="contentPage">
  <div class="flex flex-between mb-32 align-center headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
      <h1>{{'analytePackages.title' | translate}}</h1>
      <app-button (click)="openDialogExamPackages()" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="getExamPackage()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop  #autoFocus [placeholder]="'analytePackages.search'" [form]="filter.name"></input-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" [label]="'analytePackages.isActive'" [form]="filter.isActive"></checkbox-prop>
      </div>
      <app-button class="queryBtn " (click)="getExamPackage()" [placeholder]="'analytePackages.search'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <app-table [ClickInItem]="ActionsExtraExams" [Dropdown]="true" (parentFun)="packageActions($event)" [Actions]="['Editar' , 'Excluir']" [Pagination]="true" [SortPagination]="true" (sortPage)="getSortedPage($event)" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index"
    [Itens]="registerExamPackage" (changePage)="getExamPackage($event)">
    <ng-template let-rowDetail #header>
      <div class="flex flex-between">
        <h2>{{'analytePackages.titleExam' | translate}} </h2>
        <app-button (click)="openDialogExamPackagesInclude(rowDetail.id)" [placeholder]="'global.new'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
      </div>
      <app-table [ClickInItem]="ActionsExtraPackageExams" [noShadow]="true" *ngIf="returnListExams(rowDetail.id).length > 0" (parentFun)="examsActions($event, rowDetail.id)"
        [Actions]="['Excluir']" [Itens]="returnListExams(rowDetail.id)"></app-table>
      <h2 *ngIf="returnListExams(rowDetail.id).length == 0" class="flex flex-center mt-32 box">{{'analytePackages.notExam' | translate}}</h2>
    </ng-template>
  </app-table>
</div>

<ng-template #dialogModal >
  <ng-container>
    <div class="flex flex-between">
      <h1>{{titleExamPackages  ? ('analytePackages.edit' | translate) : ('analytePackages.new' | translate)}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
      </svg>
    </div>
    <div class="flex flex-column mt-32 gap-3">
      <input-search  [readonly]="isEditMode" [placeholder]="'analytePackages.pack'" [form]="formExamPack.pack"
        [options]="examPackOptions" (changeForm)="selectAnalyteMaterial(formExamPack.pack.value)"></input-search>
      <input-search [readonly]="isEditMode" [placeholder]="'analytePackages.matPack'" [form]="formExamPack.matPack"
        [options]="formOptionsMaterial"></input-search>
        <select-prop [placeholder]="'analytePackages.report'" [form]="formExamPack.report" [options]="typeExamPackage"></select-prop>
        <select-prop [placeholder]="'analytePackages.cobra'" [form]="formExamPack.cobra"  [options]="typeExamPackage"></select-prop>
        <div class="flex flex-between align-center">
          <span>{{'analytePackages.allowSelection' | translate}}</span>
          <checkbox-prop [form]="formExamPack.allowSelection"></checkbox-prop>
        </div>
      </div>
    <div class="mt-32 flex flex-center">
      <app-button (click)="titleExamPackages? updateExamPackage() : createExamPackage()" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogAddExamPackage let-analytePackageId>
  <ng-container>
    <div class="flex flex-between">
      <h1>{{'analytePackages.titleExams' | translate}}</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z" />
      </svg>
    </div>
    <div class="flex flex-column mt-32 gap-3">
      <input-search [placeholder]="'analytePackages.analyteId'" [form]="formExamPaclInclude.analyteId"
        [options]="examPackOptions"
        (changeForm)="selectAnalyteMaterial(formExamPaclInclude.analyteId.value)"></input-search>
      <input-search (keyup.enter)="addExamPackage(analytePackageId)" [placeholder]="'analytePackages.matPack'"
        [form]="formExamPaclInclude.analyteMaterialId" [options]="formOptionsMaterial"></input-search>
    </div>
    <div class="mt-32 flex flex-center">
      <app-button (click)="addExamPackage(analytePackageId)" [placeholder]="'global.save'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>