<div class="contentPage">
  <div class="flex align-center flex-between w-100 flex-direction-column-mobile">
      <div class="title">
        <h1 [tabindex]="-1">{{title}} - Requisição: <b class="orangeFont">{{requisitionNumber}}</b></h1>
      </div>
      <div class="flex flex-between gap-2 flex-direction-column-mobile align-center">
        <div class="flex align-center gap-2 flex-direction-column-mobile white-space-no-wrap wrap">
          <b [tabindex]="-1">Paciente: <b class="orangeFont">{{customerName}}</b></b>
          <b [tabindex]="-1" *ngIf="customerBirthDate">Nascimento: <b class="orangeFont">{{customerBirthDate}}</b></b>
          <b [tabindex]="-1" *ngIf="customerCpf">CPF: <b class="orangeFont">{{customerCpf}}</b></b>
          <b [tabindex]="-1" *ngIf="!customerCpf">CPF: <b class="orangeFont">Não Informado</b></b>
        </div>
      </div>
  </div>
  <div class="flex flex-center">
    <div class="w-100 mt-32">
      <mat-tab-group #tabs [selectedIndex]="0" (selectedTabChange)="myTabSelectedTabChange($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="flex align-center gap-1">
              <h2>Dados da Requisição</h2>
            </div>
          </ng-template>
          <div>
            <div>
              <ng-container>
                <div>
                  <div class="boxform flex-direction-column-mobile">
                    <div class="box">
                      <div class="w-100">
                        <h2>Dados Gerais</h2>
                      </div>
                      <div *ngIf="hasCustomer" class="mt-32 flex w-100 align-center gp-12">
                        <input-search (changeForm)="requisitionForm.customerName.value ? changeCustomer(requisitionForm.customerName.value) : ''" *ngIf="!alterCustomer" [ngClass]="firstCustomer ? 'disable':'' " class="w-100" placeholder="Alterar cliente" [form]="requisitionForm.customerName" [options]="customerOptions"></input-search>
                        <input-prop *ngIf="alterCustomer" class="w-100" placeholder="Pesquisa Cliente" [form]="form.searchCustomer"></input-prop>
                        <svg *ngIf="alterCustomer" (click)="getCustomers()" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11.6732 9.51212C12.5196 8.25295 12.8669 6.72374 12.6474 5.22249C12.4279 3.72124 11.6574 2.35548 10.4859 1.39134C9.31439 0.427204 7.82591 -0.0662417 6.31047 0.00715382C4.79503 0.0805493 3.3612 0.715528 2.28836 1.78836C1.21553 2.8612 0.580549 4.29503 0.507154 5.81047C0.433758 7.32591 0.927204 8.81439 1.89134 9.98588C2.85548 11.1574 4.22124 11.9279 5.72249 12.1474C7.22374 12.3669 8.75295 12.0196 10.0121 11.1732L13.5253 14.6849C13.7479 14.8923 14.0423 15.0052 14.3465 14.9998C14.6507 14.9945 14.941 14.8712 15.1561 14.6561C15.3712 14.441 15.4945 14.1507 15.4998 13.8465C15.5052 13.5423 15.3923 13.2479 15.1849 13.0253L11.6732 9.51212ZM6.60538 9.86282C5.86223 9.86282 5.13577 9.64245 4.51786 9.22957C3.89996 8.8167 3.41836 8.22987 3.13397 7.54329C2.84957 6.85671 2.77516 6.10121 2.92015 5.37234C3.06513 4.64347 3.42299 3.97396 3.94847 3.44847C4.47396 2.92299 5.14347 2.56513 5.87234 2.42015C6.60121 2.27516 7.35671 2.34957 8.04329 2.63397C8.72987 2.91836 9.3167 3.39996 9.72957 4.01786C10.1424 4.63577 10.3628 5.36223 10.3628 6.10538C10.3628 7.10192 9.96694 8.05763 9.26229 8.76229C8.55763 9.46694 7.60192 9.86282 6.60538 9.86282Z" />
                        </svg>
                        <span>
                          <svg style="cursor: pointer;" (click)="alterCustomer = alterCustomer ? false: true" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.79392 15.6029L9.53118 15.5868L19.8709 5.34576C20.2767 4.93999 20.5 4.4011 20.5 3.82786C20.5 3.25463 20.2767 2.71574 19.8709 2.30996L18.1684 0.607425C17.3569 -0.204126 15.9409 -0.199832 15.1358 0.604204L4.79392 10.8473V15.6029ZM16.6505 2.12533L18.3563 3.82464L16.6419 5.52289L14.9394 3.82142L16.6505 2.12533ZM6.94088 11.7426L13.414 5.33074L15.1165 7.03327L8.64449 13.443L6.94088 13.4484V11.7426Z"/>
                              <path d="M2.64696 19.8828H17.6757C18.8597 19.8828 19.8226 18.9199 19.8226 17.7359V8.43094L17.6757 10.5779V17.7359H6.03701C6.0091 17.7359 5.98011 17.7466 5.9522 17.7466C5.91678 17.7466 5.88135 17.7369 5.84486 17.7359H2.64696V2.70715H9.99707L12.144 0.560192H2.64696C1.46291 0.560192 0.5 1.5231 0.5 2.70715V17.7359C0.5 18.9199 1.46291 19.8828 2.64696 19.8828Z"/>
                          </svg>
                        </span>
                      </div>
                      <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                        <div class="w-100">
                          <input-prop placeholder="Nome" [form]="formCustomer.customerName"></input-prop>
                        </div>
                        <div class="w-100">
                          <select-prop placeholder="Sexo Biológico" [form]="formCustomer.customerGenderType" [options]="genderType"></select-prop>
                        </div>
                        <div class="w-100 date">
                          <input-prop placeholder="Data de nascimento" type="date" [form]="formCustomer.customerBirthDate"></input-prop>
                        </div>
                      </div>
                      <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                        <div class="w-100">
                          <input-prop placeholder="Nome da mãe" [form]="formCustomer.customerMotherName"></input-prop>
                        </div>
                        <div class="w-100">
                          <input-prop placeholder="Nome do responsável" [form]="formCustomer.customerResponsibleName"></input-prop>
                        </div>
                        <div class="w-100">
                          <input-prop placeholder="Nome social" [form]="formCustomer.customerSocialName"></input-prop>
                        </div>
                      </div>
                      <div>
                        <div class="flex gp-12 mt-32">
                          <h2>Documentos de identificação</h2>
                        </div>
                        <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                          <div class="w-100">
                            <input-prop placeholder="CPF" [mask]="'000.000.000-00'" [form]="formCustomer.customerCnpjCpf"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop placeholder="RG" [form]="formCustomer.customerRg"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop placeholder="Passaporte" [form]="formCustomer.customerPassport"></input-prop>
                          </div>
                        </div>
                      </div>
                        <div class="flex gp-12 mt-32">
                          <h2>Contato</h2>
                        </div>
                        <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                          <div class="w-100">
                            <input-prop placeholder="Celular" [mask]="'(00) 0 0000-0000'" [form]="formCustomer.customerCellphone"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop placeholder="Telefone residencial" [form]="formCustomer.customerResidentialPhone"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop placeholder="Email" [form]="formCustomer.customerEmail"></input-prop>
                          </div>
                        </div>
                    </div>
                    <div class="box mt-32">
                      <div class="w-100">
                        <h2>Unidade</h2>
                      </div>
                      <div class="mt-32 w-100">
                        <div>
                          <select-prop *ngIf="title === 'Novo Pedido'" placeholder="Selecione uma unidade" [form]="requisitionForm.unityId" [options]="unityOptions"></select-prop>
                          <select-prop *ngIf="title === 'Editar Pedido'" placeholder="Alterar a unidade" [form]="requisitionForm.unityId" [options]="unityOptions"></select-prop>
                        </div>
                      </div>
                    </div>
                    <div class="box mt-32">
                      <div class="w-100">
                        <h2>Local de Coleta</h2>
                      </div>
                      <div class="mt-32 w-100">
                        <div>
                          <select-prop *ngIf="title === 'Novo Pedido'" placeholder="Selecione um local de coleta" [form]="requisitionForm.collectionPlaceId" [options]="collectionPlaceOptions"></select-prop>
                          <select-prop *ngIf="title === 'Editar Pedido'" placeholder="Alterar o local de coleta" [form]="requisitionForm.collectionPlaceId" [options]="collectionPlaceOptions"></select-prop>
                        </div>
                      </div>
                    </div>
                    <div class="box mt-32">
                      <div class="w-100">
                        <h2>Informação Clínica</h2>
                      </div>
                      <div class="mt-32 w-100">
                        <textareaauto-prop placeholder="Alterar informação clínica" [form]="requisitionForm.clinicalInformation"></textareaauto-prop>
                      </div>
                    </div>
                    <div class="flex flex-center align-center mt-32">
                      <button (click)="nextTab()" class="btn">Ir para o Pedido Médico</button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label tabindex="-1">
            <div class="flex align-center gap-1">
              <h2>Pedido Médico</h2>
            </div>
          </ng-template>
          <div>
            <div>
              <ng-container>
                <div>
                  <div class="boxform">
                    <div class="box">
                      <div>
                        <h2>Convênio</h2>
                      </div>
                      <div class="flex">
                        <div class="flex w-100 mt-22 gp-12 flex-direction-column-mobile">
                          <div class="w-100 flex">
                            <input-search style="width: 100%;" #agreementField (focusout)="agreementRequestForm.description.value ? agreementPlanRow() : ''" [options]="agreementOptions" placeholder="Selecione o convênio" [form]="agreementRequestForm.description"></input-search>
                            <div style="align-items: center;display: flex;">
                              <mat-icon (click)="agreementPlanRow()">refresh</mat-icon>                        
                            </div>
                          </div>
                          <div #planField *ngIf="agreementControlPlan && this.agreementRequestForm.description.value" class="w-100">
                            <input-search  [options]="agreementPlanOptions" placeholder="Selecione o plano" [form]="agreementRequestForm.plan"></input-search>
                          </div>
                        </div>
                      </div>
                      <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                        <div class="w-100">
                          <input-prop [maxlength]="attendanceGuideMaximumLength" [minlength]="attendanceGuideMinimumLength" placeholder="Guia" [form]="agreementRequestForm.code"></input-prop>
                        </div>
                        <div class="w-100">
                          <input-prop [maxlength]="authorizationMaximumLength" [minlength]="authorizationMinimumLength" placeholder="Autorização" [form]="agreementRequestForm.authorization"></input-prop>
                        </div>
                        <div class="w-100">
                          <input-prop [maxlength]="attendanceCardMaximumLength" [minlength]="attendanceCardMinimumLength" placeholder="Número da carteira" [form]="agreementRequestForm.cardNumber"></input-prop>
                        </div>
                        <div class="w-100">
                          <input-prop placeholder="CID" [form]="agreementRequestForm.cid"></input-prop>
                        </div>
                        <div class="w-100">
                          <input-prop placeholder="Validade" type="date" [form]="agreementRequestForm.cardValidity"></input-prop>
                        </div>
                      </div>
                    </div>
                    <div class="mt-32 box">
                      <div>
                        <h2>Solicitante</h2>
                      </div>
                      <div class="w-100 mt-22 flex">
                        <input-search style="width: 100%;" [options]="doctorOptions" placeholder="Selecione o médico solicitante" [form]="agreementRequestForm.name"></input-search>                        
                        <div style="align-items: center;display: flex;">
                          <mat-icon (click)="getByIdDoctor()">refresh</mat-icon>                        
                        </div>
                      </div>
                    </div>
                    <div class="mt-32 box" *ngIf="this.agreementRequestForm.description.value">
                      <div>
                        <h2>Exames</h2>
                      </div>
                      <div>
                        <div class="flex gp-12 align-center mt-22" *ngFor="let pq of newExam; index as i">
                          <div class="w-100 flex gp-12 align-center">
                            <div style="margin-right: calc(20px * var(--proportional))">
                              <svg (click)="removeExamRow(i)" class="svg-exclude" width="17" height="21" viewBox="0 0 17 21" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M2.01111 19.0333C2.01111 19.8556 2.67778 20.5 3.5 20.5H13.5222C14.3444 20.5 15.0111 19.8556 15.0111 19.0333L15.5889 6.94444H1.45556L2.01111 19.0333ZM11.1667 9.36667C11.1667 8.94444 11.4111 8.61111 11.8333 8.61111C12.2556 8.61111 12.5 8.94444 12.5 9.36667V17.7222C12.5 18.1444 12.2556 18.4778 11.8333 18.4778C11.4111 18.4778 11.1667 18.1444 11.1667 17.7222V9.36667ZM7.83333 9.36667C7.83333 8.94444 8.07778 8.61111 8.5 8.61111C8.92222 8.61111 9.16667 8.94444 9.16667 9.36667V17.7222C9.16667 18.1444 8.92222 18.4778 8.5 18.4778C8.07778 18.4778 7.83333 18.1444 7.83333 17.7222V9.36667ZM4.5 9.36667C4.5 8.94444 4.74444 8.61111 5.16667 8.61111C5.58889 8.61111 5.83333 8.94444 5.83333 9.36667V17.7222C5.83333 18.1444 5.58889 18.4778 5.16667 18.4778C4.74444 18.4778 4.5 18.1444 4.5 17.7222V9.36667Z"/>
                              <path
                                  d="M15.8556 3.16667H11.8333V1.18889C11.8333 0.788889 11.6333 0.5 11.2111 0.5H5.83333C5.41111 0.5 5.16667 0.788889 5.16667 1.18889V3.16667H1.18889C0.766667 3.16667 0.5 3.47778 0.5 3.9V4.74444C0.5 5.16667 0.766667 5.61111 1.18889 5.61111H15.8778C16.3 5.61111 16.5222 5.14444 16.5222 4.74444V3.9C16.5 3.47778 16.2778 3.16667 15.8556 3.16667ZM10.5 3.16667H6.5V1.83333H10.5V3.16667Z"/>
                              </svg>
                            </div>
                            <div class="w-100">
                              <input-search style="width: 100%;" [options]="examOptions" [readonly]="pq.id.value ? true : false" placeholder="Selecione o exame" [form]="pq.inp" (changeForm)="pq.inp.value ? getAnalyteMaterial(pq.inp.value, i) : ''"></input-search> <!-- (keydown.Tab)="newExamRow(pq, i)" [readonly]="disable ? true : false" -->                              
                            </div>
                            <div style="align-items: center;display: flex;">
                              <mat-icon (click)="getAnalytes()">refresh</mat-icon>                        
                            </div>
                            <div class="w-100">
                              <input-search [options]="materialExamOptions" [readonly]="disable ? true : false" placeholder="Selecione o material" [form]="pq.mat"></input-search> <!-- (click)="getAnalytesById(pq.inp.value, i)" (focusin)="pq.inp.value ? getAnalyteMaterial(pq.inp.value, i) : ''" --> 
                            </div>
                            <div class="flex align-center gp-12">
                              <span>Urgente</span><checkbox-prop [form]="pq.urg"></checkbox-prop>
                            </div>
                            <div class="flex align-center gp-12">
                              <span>Material Coletado</span><checkbox-prop [form]="pq.col"></checkbox-prop>
                            </div>
                            <div class="w-100" style="padding-top: 1.25em;">
                              <input-prop placeholder="Prazo" type="datetime-local" [form]="pq.prz"></input-prop>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-center gp-12">
                          <button (click)="examForm()">Incluir Novo Exame</button>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-center mt-32 gp-12">
                      <button class="btn-reverse" (click)="back()">Cancelar e Voltar</button>
                      <button [ngStyle]="
                      {
                        'background-color': agreementRequestForm.description.value && agreementRequestForm.name.value ? '' : '#404040',
                        'pointer-events': agreementRequestForm.description.value && agreementRequestForm.name.value ? 'unset' : 'none',
                        'opacity': agreementRequestForm.description.value && agreementRequestForm.name.value ? 1 : 0.4,
                        'border': agreementRequestForm.description.value && agreementRequestForm.name.value ? ' ' : '#FFF'
                      }"
                      (click)="agreementRequestForm.description.value && agreementRequestForm.name.value ? saveAllChanges(false) : ''">{{title === 'Novo Pedido' ? 'Incluir Pedido' : 'Salvar Todas Alterações'}}</button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>