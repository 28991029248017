import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SwAlSetttings } from '@app/util/swal.settings';
import { AgreementsService } from '@app/services/agreements/agreements.service';
import { UnityService } from '@app/services/auth/unity/unity-service.service';
import { AgreementSupportService } from '@app/services/agreement-support/agreement-support.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid } from '@app/util/guid';
import { AgreementSupportParametersService } from '@app/services/agreement-support-parameters/agreement-support-parameters.service';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { MatDialog } from '@angular/material/dialog';
import { AnalyteService } from '@app/services/analyte/analyte.service';
import { AnalyteMaterialService } from '@app/services/analyte-material/analyte-material.service';
import { ExamsAgreementSupportService } from '@app/services/exams-agreement-support/exams-agreement-support.service';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { GetNewAgreementSupport, Actions } from './save-agreement-support';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';

@Component({
  selector: 'app-save-agreement-support',
  templateUrl: './save-agreement-support.component.html',
  styleUrls: ['./save-agreement-support.component.scss']
})
export class SaveAgreementSupportComponent implements OnInit {

  @ViewChild('dialogModalParameter')
  dialogModalParameter!: TemplateRef<any>

  parameterId!: string
  agreementId: Guid | null = null;
  hideParameters: Array<number> = []
  additionalParameters: Array<{
    itens: any,
    sumRecords: number,
    index: number,
    page: number
  }> = [{
    itens: [],
    sumRecords: 0,
    index: 50,
    page: 1
  }]

  additionalParameter = {
    name: new UntypedFormControl(''),
  }

  agreementSupportId: string = "";
  title: string = "Novo apoiado";
  agreementOptions: Array<Object> = [];
  unityOptions: Array<Object> = [];
  parameters: Array<Object> = [];
  fromTo: Array<Object> = [];
  materials: Array<Object> = [];
  analytes: Array<Object> = [];
  fromToId: Guid = new Guid(Guid.getEmpty());
  Search = new UntypedFormControl();
  total: number = 0;
  page: number = 1;
  index: number = 30;

  form = {
    isActive: new UntypedFormControl(null, [Validators.required]),
    agreementId: new UntypedFormControl(null, [Validators.required]),
    unityId: new UntypedFormControl(null, [Validators.required]),
    header: new UntypedFormControl(null, [Validators.required]),
    payment: new UntypedFormControl(null, [Validators.required]),
    integration: new UntypedFormControl(null, [Validators.required]),
    image: new UntypedFormControl(null, [Validators.required]),
    imageName: new UntypedFormControl(null, [Validators.required]),
    parameter: new UntypedFormControl(null)
  }

  formFromTo = {
    analyteId: new UntypedFormControl(null, [Validators.required]),
    externalId: new UntypedFormControl(null, [Validators.required]),
    materialId: new UntypedFormControl(null, [Validators.required]),
    active: new UntypedFormControl(true, [Validators.required])
  }

  typeHeader = [
    {
      value: 0,
      label: 'Padrão logo do laboratorio apoio'
    },
    {
      value: 1,
      label: 'Não imprime logo nenhuma'
    },
    {
      value: 2,
      label: 'Imprime a logo do laboratorio apoiado'
    }
  ];

  typeInvoiceSupport = [
    {
      value: 0,
      label: 'Padrão de faturamento'
    },
    {
      value: 1,
      label: 'Pagamento Antecipado'
    }
  ];

  typeIntegration = [
    {
      value: 0,
      label: 'JSON'
    },
    {
      value: 1,
      label: 'XML'
    },
    {
      value: 2,
      label: 'Alvaro'
    },
    {
      value: 3,
      label: 'CareSys'
    },
    {
      value: 4,
      label: 'TechSallus'
    },
    {
      value: 5,
      label: 'LifeSysNefrodata'
    },
    {
      value: 6,
      label: 'Syslab WS'
    },
    {
      value: 7,
      label: 'DB Toxicologia'
    }
  ];

  typeAgreementParameters = [
    {
      value: 1,
      label: 'Bloqueado para atendimento, não permite cadastro de requisições'
    },
    {
      value: 2,
      label: 'Certifica os resultados após liberado'
    },
    {
      value: 3,
      label: 'Exige conferência para faturamento'
    },
    {
      value: 4,
      label: 'Obrigatório geração de lote'
    },
    {
      value: 5,
      label: 'Permite que o laboratorio atualize suas informações cadastrais'
    },
    {
      value: 6,
      label: 'Permite que o laboratório faça requisição de materiais no estoque'
    },
    {
      value: 7,
      label: 'Permite que o laboratório informe no cadastro que o exame é urgente'
    },
    {
      value: 8,
      label: 'Permite utilizar o cadastro de médicos do laboratorio'
    }
  ];

  resolveParameters = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Parâmetro',
      retrive: '',
      method: 'getParameterName',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ];

  resolveFromTos = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Analito',
      retrive: 'analyteName',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Material',
      retrive: 'materialDescription',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Código Externo',
      retrive: 'analyteExternalId',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ];

  ActionsExtraParameters = {
    "Ativo": 'PutActiveParameters'
  };

  ActionsExtraExams = {
    "Ativo": 'PutActiveExams'
  };

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  constructor(private _location: Location, private _agreementsService: AgreementsService, private _unityService: UnityService,
    private _agreementSupportService: AgreementSupportService, private _route: ActivatedRoute, private _agreementSupportParameters: AgreementSupportParametersService,
    private _router: Router, private _dialog: MatDialog, private _analyteService: AnalyteService, private _analyteMaterialService: AnalyteMaterialService,
    private _examsAgreementSupportService: ExamsAgreementSupportService, private _activePipe: GetActiveFilterPipe, private _localaDatePipe: LocalaDatePipe,
    private _checkboxSvgPipe: CheckboxSvgPipe
  ) { }

  async ngOnInit() {
    await this.getAgreements();
    await this.getUnity();
    let urlParams: string[] = this._route.snapshot.params['id']?.split('$');
    if (urlParams != undefined) {
      this.title = "Editar apoiado";
      let agreementSupportId: string = urlParams[0];
      this.agreementSupportId = agreementSupportId;
      this.getAgreementSupportById();
    }
  }

  back() {
    this._location.back()
  }

  async getAgreements() {
    this.agreementOptions = [];
    await this._agreementsService.getByPath('', '?page=1&registerIndex=99999&resume=true').then(
      (res) => {
        if (res.data.length != 0) {
          res.data.forEach((agreements) => {
            this.agreementOptions.push({
              label: agreements.description + ' - ' + agreements.externalId,
              value: agreements.id,
            });
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async getUnity() {
    this.unityOptions = [];
    await this._unityService.getByPath('', '?page=1&index=9999').then(
      (res) => {
        if (res.data.length != 0) {
          res.data.forEach(unity => {
            this.unityOptions.push({
              value: unity.id,
              label: unity.name
            });
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async postAgreementSupport() {
    const obj = {
      "isActive": this.form.isActive.value,
      "agreementId": this.form.agreementId.value,
      "unityId": this.form.unityId.value,
      "fileImageHeaderId": this.form.image.value,
      "typeHeaderAgreementSupport": this.form.header.value,
      "typeInvoiceSupport": this.form.payment.value,
      "typeIntegrationSupport": this.form.integration.value
    }
    await this._agreementSupportService.post(obj).then(
      (res: any) => {
        SwAlSetttings.Sucesso('Apoiado cadastrado com sucesso!');
        this._router.navigateByUrl(`Cadastros/Concent-Apoio/Apoiados/Novo/${res.id}`)
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async getAgreementSupportById() {
    await this._agreementSupportService.getById(new Guid(this.agreementSupportId)).then(
      (res: any) => {
        this.form.agreementId.setValue(res.data[0].agreementId);
        this.form.unityId.setValue(res.data[0].unityId);
        this.form.image.setValue(res.data[0].fileImageHeaderId);
        this.form.header.setValue(res.data[0].typeHeaderAgreementSupport);
        this.form.payment.setValue(res.data[0].typeInvoiceSupport);
        this.form.integration.setValue(res.data[0].typeIntegrationSupport);
        this.form.isActive.setValue(res.data[0].isActive);
        this.form.imageName.setValue(res.data[0].fileImageName);
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async putAgreementSupport() {
    const obj = {
      "isActive": this.form.isActive.value,
      "agreementId": this.form.agreementId.value,
      "unityId": this.form.unityId.value,
      "fileImageHeaderId": this.form.image.value,
      "typeHeaderAgreementSupport": this.form.header.value,
      "typeInvoiceSupport": this.form.payment.value,
      "typeIntegrationSupport": this.form.integration.value
    }
    await this._agreementSupportService.put(obj, new Guid(this.agreementSupportId)).then(
      (res: any) => {
        SwAlSetttings.Sucesso('Apoiado cadastrado com sucesso!');
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async myTabSelectedTabChange(changeEvent: MatTabChangeEvent) {
    if (changeEvent.index === 1) {
      this.getParameters();
      this.getFromTo();
      this.getAdditionalParameter(this.parameterId);
    }
  }

  resolve(data: any, columns: any) {
    this.parameters = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.parameters.push(obj);
    });
  }

  getActive(item: GetNewAgreementSupport) {
    return this._activePipe.transform(item.isActive)
  }

  getCreated(item: GetNewAgreementSupport) {
    return this._localaDatePipe.transform(item.createdAt);
  }

  getUpdated(item: GetNewAgreementSupport) {
    return this._localaDatePipe.transform(item.updatedAt);
  }

  getParameterName(item: GetNewAgreementSupport) {
    return this.typeAgreementParameters.filter((x) => x.value === item.parametersAgreementSupport)[0].label;
  }

  putActiveParameters(id: Guid, value: string) {
    this._agreementSupportParameters.patchActive(id.toString(), value).then((x: any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.getParameters()
    }).catch(err => SwAlSetttings.printError(err))
  }

  putActiveExams(id: Guid, value: string) {
    this._examsAgreementSupportService.patchActive(id.toString(), value).then((x: any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.getFromTo();
    }).catch(err => SwAlSetttings.printError(err))
  }

  async actions(emit: Actions) {
    switch (emit.action) {
      case 'PutActiveParameters':
        this.putActiveParameters(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break
      case 'PutActiveExams':
        this.putActiveExams(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break
      case 'Editar':
        this.fromToId = emit.object.id;
        await this._examsAgreementSupportService.getById(emit.object.id).then(
          async (res: any) => {
            this.formFromTo.analyteId.setValue(res.data[0].analyteId);
            await this.getMaterials();
            this.formFromTo.materialId.setValue(res.data[0].analyteMaterialId);
            this.formFromTo.externalId.setValue(res.data[0].analyteExternalId);
            await this.openDialogFromTo();
          }
        ).catch(
          (err) => {
            SwAlSetttings.printError(err);
          }
        );
        break;
      case 'Excluir':
        this.delExam(emit.object.id)
        break;
    }
  }

  delExam(id: Guid) {
    this._examsAgreementSupportService.delete(id).then(
      (res: any) => {
        this.getFromTo();
        SwAlSetttings.Sucesso('Exame excluído!')
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  getParameters() {
    this._agreementSupportParameters.get('?AgreementSupportId=' + this.agreementSupportId).then(
      (res: any) => {
        this.resolve(res.data, this.resolveParameters);
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async openDialogFromTo() {
    await this.getAnalytes();

    const myTempDialog = this._dialog.open(this.dialogModal, {
      width: '660px',
    });

    myTempDialog.afterClosed().subscribe((res) => {
      this.reset();
    });
  }

  close() {
    this.fromToId = new Guid(Guid.getEmpty());
    this._dialog.closeAll()
  }

  async postFromTo() {
    const obj = {
      "agreementSupportId": this.agreementSupportId,
      "analyteId": this.formFromTo.analyteId.value,
      "analyteMaterialId": this.formFromTo.materialId.value,
      "analyteExternalId": this.formFromTo.externalId.value,
    }

    await this._examsAgreementSupportService.post(obj).then(
      (res: any) => {
        SwAlSetttings.Sucesso('De/Para cadastrado com sucesso!');
        this.getFromTo();
        this.close();
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async putFromTo() {
    const obj = {
      "analyteExternalId": this.formFromTo.externalId.value,
      "isActive": this.formFromTo.active.value
    }

    await this._examsAgreementSupportService.put(obj, this.fromToId).then(
      (res: any) => {
        SwAlSetttings.Sucesso('De/Para atualizado com sucesso!');
        this.getFromTo();
        this.close();
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  getFromTo(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }
    this._examsAgreementSupportService.getPagination({ page: this.page, index: this.index, name: this.Search.value, AgreementSupportId: this.agreementSupportId }).then(
      (res: any) => {
        this.total = res.sumRecords;
        this.resolveFromTo(res.data, this.resolveFromTos);
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async getAnalytes() {
    this.analytes = [];
    await this._analyteService.getResume().then(
      (res: any) => {
        if (res.data.length != 0) {
          res.data.forEach((analyte: any) => {
            this.analytes.push({
              label: analyte.code + ' - ' + analyte.name,
              value: analyte.id
            });
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async getMaterials() {
    this.materials = [];
    await this._analyteMaterialService.get('?AnalyteId=' + this.formFromTo.analyteId.value).then(
      (res: any) => {
        if (res.data.length != 0) {
          res.data.forEach((material: any) => {
            this.materials.push({
              label: material.description,
              value: material.id
            });
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolveFromTo(data: any, columns: any) {
    this.fromTo = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.fromTo.push(obj);
    });
  }

  reset() {
    this.formFromTo.analyteId.reset();
    this.formFromTo.externalId.reset();
    this.formFromTo.materialId.reset();
  }

  openDialogModalParameter(parameterId: string) {
    this.parameterId = parameterId
    if (this.hideParameters.length === this.typeAgreementParameters.length) {
      SwAlSetttings.warningMessage('Todos os parâmetros já foram incluídos, ative ou inative o que estão listados.')
    } else {
      const myTempDialog = this._dialog.open(this.dialogModalParameter, {
        width: '800px'
      });
      myTempDialog.afterClosed().subscribe((res) => {
        this.parameterId = ''
        this.additionalParameter.name.reset();
        this.hideParameters = []
        this.getAdditionalParameter(this.parameterId);
      });
    }
  }

  includeAgreementParameter() {
    let obj = {
      agreementSupportId: this.agreementSupportId,
      parametersAgreementSupports: this.additionalParameter.name.value,
    }
    this._agreementSupportParameters.post(obj).then(
      (res) => {
        SwAlSetttings.Sucesso("Parâmetro(s) Cadastrado(s) com sucesso!");
        this.close()
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  getAdditionalParameter(parameterId: string) {
    this._agreementSupportParameters.getPagination({ AgreementSupportId: this.agreementSupportId ?? '' }).then((res: any) => {
      this.hideParameters = [];
      res.data.forEach((x: any) => {
        this.hideParameters.push(x.parametersAgreementSupport);
      });
      this.additionalParameters[parameterId as any] = {
        itens: [],
        sumRecords: 0,
        index: 50,
        page: 1
      }
      if (res.data.length > 0) {
        this.additionalParameters[parameterId as any] = {
          itens: this.resolve(res.data, this.resolveParameters),
          sumRecords: res.sumRecords,
          index: 50,
          page: 1
        }
      }
    })
  }
}
