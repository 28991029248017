import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http.service';
import { BaseService } from '@app/services/base.service';
import { HttpPostResponse, HttpResponsePageable } from '@app/services/services.types';
import { Guid } from '@app/util/guid';
import { PutAnswerQuestionRequisition } from './answer-question-requisition.types';

@Injectable({
  providedIn: 'root'
})

export class AnswerQuestionRequisitionService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/movimentation/answerquestionrequisition');
  }

  override put(body: Object, id:Guid, path?:string) {
    return super.put(body, id, path) as Promise<HttpPostResponse<PutAnswerQuestionRequisition>>
  }
}
