import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
   DEFAULT_LANG,
   Language,
   LanguageOptions
} from "./select-language.model";

@Component({
   selector: "select-language",
   templateUrl: "./select-language.component.html",
   styleUrls: ["./select-language.component.scss"]
})
export class SelectLanguageComponent {
   public options: Language[] = LanguageOptions;
   public currentLang = "";
   selectedlanguage = "";

   constructor(private _translate: TranslateService) {
      if (!localStorage.getItem("lang")) {
         const browserLang = this._translate.getBrowserLang();
         if (browserLang === "pt") {
            localStorage.setItem("lang", "pt-BR");
         } else {
            localStorage.setItem("lang", browserLang || DEFAULT_LANG);
         }
      }
      const userLanguageDefault = localStorage.getItem("lang")!;
      this.selectedlanguage = userLanguageDefault;
      const systemLanguages = LanguageOptions.map((language) => language.lang);

      this._translate.addLangs(systemLanguages);
      this._translate.setDefaultLang(userLanguageDefault);
      this._translate.use(
         systemLanguages.includes(userLanguageDefault)
            ? userLanguageDefault
            : DEFAULT_LANG
      );

      this.currentLang = this._translate.currentLang;
   }

   changeLang(lang: any) {
      if (lang.isUserInput == true) {
         localStorage.setItem("lang", lang.source.value);
         this._translate.use(lang.source.value);
         this.selectedlanguage = lang.source.value;
      }
   }
}
