<div [class]="classe">
  <div class="flex align-center">
      <label *ngIf="label">
          {{ label | translate}}
      </label>
      <div class="sd" [ngClass]="{border: border}">
          <svg class="abs" width="13" *ngIf="notSelect && !form.value && !hideSvg"  height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.715988 0.248954C0.998628 -0.0178237 1.45085 -0.0119791 1.72605 0.262009L6.5 5.0149L11.2739 0.262009C11.5492 -0.0119796 12.0014 -0.0178242 12.284 0.248953C12.5667 0.515731 12.5727 0.954109 12.2975 1.2281L6.5 7L0.702521 1.2281C0.427319 0.954109 0.433348 0.515732 0.715988 0.248954Z" fill="#808080"/>
          </svg>
          <input 
            #input 
            [type]="type"
            [disabled]="disabled" 
            [matAutocomplete]="auto"
            [class]="noLabelUp ? 'noLabelUp' : ''"  
            [tabindex]="tabindexcomponent" 
            [required]="requiredInput"
            [placeholder]="placeholder | translate"
            [formControl]="formValue"
            (focusout)="onFocusOut()"
            >
          <label for="">{{placeholder | translate}}</label>
          <span></span>
          <span class="abs" *ngIf="color !== 'unset'" [ngStyle]="{'background': color}"  style="width: 33px; height: 16px; border-radius: 100px; top: 16px;"></span>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getSelectedOption($event)" [displayWith]="displayCorretLabelFn" >
              <mat-option *ngFor="let option of options | searchFilter: this.formValue.value; let index;" [value]="option">
                  {{ option.label }}
              </mat-option>
          </mat-autocomplete>
      </div>
  </div>
</div>
