import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { AgreementMessageService } from "@app/services/agreement-message/agreement-message.service";
import {
   AgreementMessagePostObject,
   AgreementMessagePutObject,
   AgreementTypeMessage
} from "@app/services/agreement-message/agreement-message.types";
import { decodedSessionToken } from "@app/util/Token";
import { Guid } from "@app/util/guid";
import { SwAlSetttings } from "@app/util/swal.settings";
import { JwtHelperService } from "@auth0/angular-jwt";

@Component({
   selector: "app-send-message",
   templateUrl: "./send-message.component.html",
   styleUrls: ["./send-message.component.scss"]
})
export class SendMessageComponent implements OnInit {
   messageSend = new UntypedFormControl(null);

   transportResponse: Array<Object> = [];

   registerMessages: Array<Object> = [];

   resolveChat: Array<Object> = [
      {
         label: "id",
         method: "",
         retrive: "id",
         after: "",
         before: ""
      },
      {
         label: "Mensagem",
         method: "",
         retrive: "message",
         after: "",
         before: ""
      },
      {
         label: "Lido em",
         method: "readDate",
         retrive: "",
         after: "",
         before: ""
      },
      {
         label: "_hide_agreementId",
         method: "",
         retrive: "agreementId",
         after: "",
         before: ""
      }
   ];

   registerResponse: Array<Object> = [];

   resolveResponse: Array<Object> = [
      {
         label: "id",
         method: "",
         retrive: "id",
         after: "",
         before: ""
      },
      {
         label: "Mensagem",
         method: "",
         retrive: "message",
         after: "",
         before: ""
      },
      {
         label: "Lido em",
         method: "readDate",
         retrive: "",
         after: "",
         before: ""
      },
      {
         label: "_hide_agreementId",
         method: "",
         retrive: "agreementId",
         after: "",
         before: ""
      }
   ];

   form = {
      messageContent: new UntypedFormControl(null)
   };

   formSend = new UntypedFormControl(null);

   @ViewChild("dialogModalNewMessage")
   dialogModalNewMessage!: TemplateRef<any>;

   @ViewChild("dialogModalEdit")
   dialogModalEdit!: TemplateRef<any>;

   @ViewChild("dialogModalResponse")
   dialogModalResponse!: TemplateRef<any>;

   @ViewChild("dialogModalEdit")
   dialogModalResponseEdit!: TemplateRef<any>;

   constructor(
      private _dialog: MatDialog,
      private _router: Router,
      private _agreementMessageService: AgreementMessageService,
      private _localeDatePipe: LocalaDatePipe
   ) {}

   ngOnInit() {
      this.getMessages();
   }

   /* #region  Cabeçario*/
   async sendMessage(object?: any) {
      const helper = new JwtHelperService();
      let agreementId = decodedSessionToken.xid;

      let postObject: AgreementMessagePostObject = {
         agreementId: agreementId,
         message: this.formSend.value,
         typeMessage: 1,
         parentId: object ? object.id : null
      };
      await this._agreementMessageService
         .post(postObject)
         .then(async (res) => {
            if (res.success) {
               setTimeout(() => {
                  SwAlSetttings.Sucesso("Mensagem enviada com sucesso!");
               });
               this.formSend.setValue(null);
               this.close();
               await this.getMessages();
            }
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   async getMessages() {
      await this._agreementMessageService
         .getByPath(`agreementId=${decodedSessionToken.xid}`, "")
         .then((res) => {
            this.resolve(res.data, this.resolveChat);
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }
   /* #endregion */

   /* #region  tabela*/
   async sandEditMessage(object: any) {
      let editMassage: AgreementMessagePutObject = {
         isActive: true,
         agreementId: object._hide_agreementId,
         message: this.formSend.value
      };
      await this._agreementMessageService
         .put(editMassage, object.id)
         .then(async (res) => {
            if (res.success) {
               setTimeout(() => {
                  SwAlSetttings.Sucesso("Mensagem editada com sucesso!");
               });
               this.formSend.setValue(null);
               this.close();
               await this.getMessages();
            }
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   readDate(item: any) {
      if (item.readDate) {
         return this._localeDatePipe.transform(item.readDate);
      } else {
         return "";
      }
   }

   resolve(data: any, columns: any) {
      this.registerMessages = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registerMessages.push(obj);
      });
   }
   /* #endregion */

   /* #region  dialog*/
   openDialogModalNewMessage() {
      const myTempDialog = this._dialog.open(this.dialogModalNewMessage, {
         width: "660px"
      });
   }

   openDialogModalEdit(object: object) {
      const myTempDialog = this._dialog.open(this.dialogModalEdit, {
         width: "660px",
         data: object
      });
   }

   openDialogModalResponse(object: object) {
      const myTempDialog = this._dialog.open(this.dialogModalResponse, {
         width: "660px",
         data: object
      });
   }

   openDialogModalResponseEdit(object: object) {
      const myTempDialog = this._dialog.open(this.dialogModalResponseEdit, {
         width: "660px",
         data: object
      });
   }
   /* #endregion */

   close() {
      this._dialog.closeAll();
   }

   back() {
      this._router.navigateByUrl("Externo/Convenio");
   }

   async actions(emit: any) {
      switch (emit.action) {
         case "Responder":
            this.openDialogModalResponse(emit.object);
            break;
         case "Editar":
            this.openDialogModalEdit(emit.object);
            break;
         case "OpenDropdown":
            this.getAgreementMessageById(emit.id);
            break;
      }
   }

   resolve2(data: any, columns: any) {
      this.registerResponse = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.registerResponse.push(obj);
      });
      return this.registerResponse;
   }

   filtro(id: any) {
      //@ts-ignore
      return this.transportResponse.filter((x) => x.parentId === id);
   }

   getAgreementMessageById(id: string) {
      this._agreementMessageService
         .getById(new Guid(id))
         .then((res) => {
            res.data.forEach((x) => {
               this.transportResponse = x.messagesChildren;
               if (x.readDate === null)
                  this.getAgreementVisualized(x.id, x.agreementId);
            });
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   async getAgreementVisualized(id: string, agreementId: string) {
      let obj = {
         agreementId: agreementId
      };
      await this._agreementMessageService
         .put(obj, undefined, `toread/${id}`)
         .then(async (res) => {
            SwAlSetttings.Sucesso("Mensagem marcada como visualizada");
            await this.getMessages();
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   async actions2(emit: any) {
      switch (emit.action) {
         case "Visualizado":
            this.getAgreementMessageById(emit.object.id);
            break;
         case "Editar":
            this.openDialogModalResponseEdit(emit.object);
            break;
      }
   }
}
