<div class="contentPage">
  <div class="flex align-center flex-between w-100 flex-direction-column-mobile">
      <div class="gap-1 flex align-center flex-direction-column-mobile">
        <button (click)="backPage()" class="flex gp-12 align-center btn-reverse">Voltar</button>
        <h1>{{this.title}}</h1>
      </div>
  </div>
  <div class="">
    <div class="w-100 mt-32">
      <mat-tab-group #tabs [selectedIndex]="0">
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="flex align-center gap-1">
              <h2>Dados da Requisição</h2>
            </div>
          </ng-template>
          <div>
            <div>
              <ng-container>
                <div>
                  <div class="boxform flex-direction-column-mobile">
                    <div class="box">
                      <div class="w-100">
                        <h2>Dados Gerais</h2>
                      </div>
                      <div class="mt-32 flex w-100 align-center gp-12">
                        <input-search [onFocus]="true" (changeForm)="requisitionForm.customerName.value ? changeCustomer(requisitionForm.customerName.value) : ''" *ngIf="!alterCustomer" [ngClass]="firstCustomer ? 'disable':'' " class="w-100" placeholder="Selecione o cliente" [form]="requisitionForm.customerName" [options]="customerOptions"></input-search>
                        <input-prop *ngIf="alterCustomer" class="w-100" placeholder="Pesquisa Cliente"  (keydown.enter)="getCustomers()" [form]="form.searchCustomer"></input-prop>
                        <svg *ngIf="alterCustomer" (click)="getCustomers()" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11.6732 9.51212C12.5196 8.25295 12.8669 6.72374 12.6474 5.22249C12.4279 3.72124 11.6574 2.35548 10.4859 1.39134C9.31439 0.427204 7.82591 -0.0662417 6.31047 0.00715382C4.79503 0.0805493 3.3612 0.715528 2.28836 1.78836C1.21553 2.8612 0.580549 4.29503 0.507154 5.81047C0.433758 7.32591 0.927204 8.81439 1.89134 9.98588C2.85548 11.1574 4.22124 11.9279 5.72249 12.1474C7.22374 12.3669 8.75295 12.0196 10.0121 11.1732L13.5253 14.6849C13.7479 14.8923 14.0423 15.0052 14.3465 14.9998C14.6507 14.9945 14.941 14.8712 15.1561 14.6561C15.3712 14.441 15.4945 14.1507 15.4998 13.8465C15.5052 13.5423 15.3923 13.2479 15.1849 13.0253L11.6732 9.51212ZM6.60538 9.86282C5.86223 9.86282 5.13577 9.64245 4.51786 9.22957C3.89996 8.8167 3.41836 8.22987 3.13397 7.54329C2.84957 6.85671 2.77516 6.10121 2.92015 5.37234C3.06513 4.64347 3.42299 3.97396 3.94847 3.44847C4.47396 2.92299 5.14347 2.56513 5.87234 2.42015C6.60121 2.27516 7.35671 2.34957 8.04329 2.63397C8.72987 2.91836 9.3167 3.39996 9.72957 4.01786C10.1424 4.63577 10.3628 5.36223 10.3628 6.10538C10.3628 7.10192 9.96694 8.05763 9.26229 8.76229C8.55763 9.46694 7.60192 9.86282 6.60538 9.86282Z" />
                        </svg>
                        <span>
                          <svg style="cursor: pointer;" (click)="alterCustomer = alterCustomer ? false: true" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.79392 15.6029L9.53118 15.5868L19.8709 5.34576C20.2767 4.93999 20.5 4.4011 20.5 3.82786C20.5 3.25463 20.2767 2.71574 19.8709 2.30996L18.1684 0.607425C17.3569 -0.204126 15.9409 -0.199832 15.1358 0.604204L4.79392 10.8473V15.6029ZM16.6505 2.12533L18.3563 3.82464L16.6419 5.52289L14.9394 3.82142L16.6505 2.12533ZM6.94088 11.7426L13.414 5.33074L15.1165 7.03327L8.64449 13.443L6.94088 13.4484V11.7426Z"/>
                              <path d="M2.64696 19.8828H17.6757C18.8597 19.8828 19.8226 18.9199 19.8226 17.7359V8.43094L17.6757 10.5779V17.7359H6.03701C6.0091 17.7359 5.98011 17.7466 5.9522 17.7466C5.91678 17.7466 5.88135 17.7369 5.84486 17.7359H2.64696V2.70715H9.99707L12.144 0.560192H2.64696C1.46291 0.560192 0.5 1.5231 0.5 2.70715V17.7359C0.5 18.9199 1.46291 19.8828 2.64696 19.8828Z"/>
                          </svg>
                        </span>
                      </div>
                      <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                        <div class="w-100">
                          <input-prop [readonly]='alterFields' placeholder="Nome" [form]="formCustomer.customerName"></input-prop>
                        </div>
                        <div class="w-100">
                          <select-prop [readonlySelect]='alterFields' placeholder="Sexo" [form]="formCustomer.customerGenderType" [options]="genderType"></select-prop>
                        </div>
                        <div class="w-100 date">
                          <input-prop [readonly]='alterFields' placeholder="Data de nascimento" type="date" [form]="formCustomer.customerBirthDate"></input-prop>
                        </div>
                      </div>
                      <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                        <div class="w-100">
                          <input-prop [readonly]='alterFields' placeholder="Nome da mãe" [form]="formCustomer.customerMotherName"></input-prop>
                        </div>
                        <div class="w-100">
                          <input-prop [readonly]='alterFields' placeholder="Nome do responsável" [form]="formCustomer.customerResponsibleName"></input-prop>
                        </div>
                        <div class="w-100">
                          <input-prop [readonly]='alterFields' placeholder="Nome social" [form]="formCustomer.customerSocialName"></input-prop>
                        </div>
                      </div>
                      <div>
                        <div class="flex gp-12 mt-32">
                          <h2>Documentos de identificação</h2>
                        </div>
                        <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="CPF/CNPJ" [form]="formCustomer.customerCnpjCpf"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="RG" [form]="formCustomer.customerRg"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="Passaporte" [form]="formCustomer.customerPassport"></input-prop>
                          </div>
                        </div>
                      </div>
                        <div class="flex gp-12 mt-32">
                          <h2>Contato</h2>
                        </div>
                        <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="Celular" [form]="formCustomer.customerCellphone"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="Telefone residencial" [form]="formCustomer.customerResidentialPhone"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="Email" [form]="formCustomer.customerEmail"></input-prop>
                          </div>
                        </div>
                        <div class="flex gp-12 mt-32">
                          <h2>Endereço</h2>
                        </div>
                        <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                          <div style="align-items: center;" class="w-100 flex">
                            <input-prop [readonly]='alterFields' class="w-100" placeholder="CEP" [form]="formCustomer.addressCEP"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="Endereço" [form]="formCustomer.address"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="Número" [form]="formCustomer.addressNumber"></input-prop>
                          </div>
                        </div>
                        <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="Complemento" [form]="formCustomer.addressComplement"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="Bairro" [form]="formCustomer.addressDistrict"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="Cidade" [form]="formCustomer.addressCity"></input-prop>
                          </div>
                        </div>
                        <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="Estado" [form]="formCustomer.addressState"></input-prop>
                          </div>
                          <div class="w-100">
                            <input-prop [readonly]='alterFields' placeholder="País" [form]="formCustomer.addressCountry"></input-prop>
                          </div>
                          <div class="w-100">
                          </div>
                        </div>
                    </div>                    
                    <div class="box mt-32">
                      <div class="w-100">
                        <h2>Unidade</h2>
                      </div>
                      <div class="mt-32 w-100">
                        <div>
                          <select-prop *ngIf="title === 'Novo Pedido'" placeholder="Selecione uma unidade" [form]="requisitionForm.unityId" [options]="unityOptions"></select-prop>
                          <select-prop *ngIf="title === 'Editar Pedido'" placeholder="Alterar a unidade" [form]="requisitionForm.unityId" [options]="unityOptions"></select-prop>
                        </div>
                      </div>
                    </div>
                    <div class="box mt-32">
                      <div class="w-100">
                        <h2>Local de Coleta</h2>
                      </div>
                      <div class="mt-32 w-100">
                        <div>
                          <select-prop *ngIf="title === 'Novo Pedido'" placeholder="Selecione um local de coleta" [form]="requisitionForm.collectionPlaceId" [options]="collectionPlaceOptions"></select-prop>
                          <select-prop *ngIf="title === 'Editar Pedido'" placeholder="Alterar o local de coleta" [form]="requisitionForm.collectionPlaceId" [options]="collectionPlaceOptions"></select-prop>
                        </div>
                      </div>
                    </div>
                    <div class="box mt-32">
                      <div class="w-100">
                        <h2>Informação Clínica</h2>
                      </div>
                      <div class="mt-32 w-100">
                        <textareaauto-prop placeholder="Informação clínica" [form]="requisitionForm.clinicalInformation"></textareaauto-prop>
                      </div>
                    </div>
                    <div class="flex flex-between gp-12 mt-32 flex-direction-column-mobile align-center">
                      <div class="w-100 flex align-center gp-12">
                        <span>O usuário faz uso de medicamento contínuo?</span>
                        <checkbox-prop [form]="formCustomer.continuousMedication"></checkbox-prop>
                      </div>
                      <div class="w-100">
                        <input-prop placeholder="Informe o medicamento" [form]="formCustomer.medication"></input-prop>
                      </div>
                      <div class="w-100 flex align-center gp-12">
                        <span>Enviar SMS/Whatsapp de aviso quando o resultado for liberado</span>
                        <checkbox-prop [form]="formCustomer.sendSmsEmail"></checkbox-prop>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label tabindex="-1">
            <div class="flex align-center gap-1">
              <h2>Pedido Médico</h2>
            </div>
          </ng-template>
          <div>
            <div>
              <ng-container>
                <div>
                  <div class="boxform">
                    <div class="box">
                      <div>
                        <h2>Convênio</h2>
                      </div>
                      <div class="flex">
                        <div class="flex w-100 mt-22 gp-12 flex-direction-column-mobile">
                          <!-- <div class="w-100">
                            <input-search #agreementField (focusout)="agreementRequestForm.description.value ? agreementPlanRow() : ''" [options]="agreementOptions" placeholder="Selecione o convênio" [form]="agreementRequestForm.description"></input-search>
                          </div> -->
                          <div #planField *ngIf="agreementControlPlan && this.agreementRequestForm.description.value" class="w-100">
                            <input-search  [options]="agreementPlanOptions" placeholder="Selecione o plano" [form]="agreementRequestForm.plan"></input-search>
                          </div>
                        </div>
                      </div>
                      <div class="flex gp-12 mt-32 flex-direction-column-mobile">
                        <div class="w-100">
                          <input-prop  [maxlength]="attendanceGuideMaximumLength" [minlength]="attendanceGuideMinimumLength" placeholder="Guia" [form]="agreementRequestForm.code"></input-prop>
                        </div>
                        <div class="w-100">
                          <input-prop [maxlength]="authorizationMaximumLength" [minlength]="authorizationMinimumLength" placeholder="Autorização" [form]="agreementRequestForm.authorization"></input-prop>
                        </div>
                        <div class="w-100">
                          <input-prop  [maxlength]="attendanceCardMaximumLength" [minlength]="attendanceCardMinimumLength" placeholder="Número da carteira" [form]="agreementRequestForm.cardNumber"></input-prop>
                        </div>
                        <div class="w-100">
                          <input-prop  placeholder="CID" [form]="agreementRequestForm.cid"></input-prop>
                        </div>
                        <div class="w-100">
                          <input-prop  placeholder="Validade" type="date" [form]="agreementRequestForm.cardValidity"></input-prop>
                        </div>
                      </div>
                    </div>
                    <div class="mt-32 box">
                      <div>
                        <h2>Solicitante</h2>
                      </div>
                      <div class="w-100 mt-22 flex">
                        <input-search style="width: 100%;" [options]="doctorOptions" placeholder="Selecione o médico solicitante" [form]="agreementRequestForm.name"></input-search>
                        <div style="align-items: center;display: flex;">
                          <mat-icon (click)="getByIdDoctor()">refresh</mat-icon>                        
                        </div>
                      </div>
                    </div>
                    <div class="mt-32 box">
                      <div>
                        <h2>Exames</h2>
                      </div>
                      <div>
                        <div class="flex gp-12 align-center mt-22" *ngFor="let pq of newExam; index as i">
                          <div class="w-100 flex gp-12 align-center">
                            <div style="margin-right: calc(20px * var(--proportional))">
                              <svg (click)="removeExamRow(i)" class="svg-exclude" width="17" height="21" viewBox="0 0 17 21" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M2.01111 19.0333C2.01111 19.8556 2.67778 20.5 3.5 20.5H13.5222C14.3444 20.5 15.0111 19.8556 15.0111 19.0333L15.5889 6.94444H1.45556L2.01111 19.0333ZM11.1667 9.36667C11.1667 8.94444 11.4111 8.61111 11.8333 8.61111C12.2556 8.61111 12.5 8.94444 12.5 9.36667V17.7222C12.5 18.1444 12.2556 18.4778 11.8333 18.4778C11.4111 18.4778 11.1667 18.1444 11.1667 17.7222V9.36667ZM7.83333 9.36667C7.83333 8.94444 8.07778 8.61111 8.5 8.61111C8.92222 8.61111 9.16667 8.94444 9.16667 9.36667V17.7222C9.16667 18.1444 8.92222 18.4778 8.5 18.4778C8.07778 18.4778 7.83333 18.1444 7.83333 17.7222V9.36667ZM4.5 9.36667C4.5 8.94444 4.74444 8.61111 5.16667 8.61111C5.58889 8.61111 5.83333 8.94444 5.83333 9.36667V17.7222C5.83333 18.1444 5.58889 18.4778 5.16667 18.4778C4.74444 18.4778 4.5 18.1444 4.5 17.7222V9.36667Z"/>
                              <path
                                  d="M15.8556 3.16667H11.8333V1.18889C11.8333 0.788889 11.6333 0.5 11.2111 0.5H5.83333C5.41111 0.5 5.16667 0.788889 5.16667 1.18889V3.16667H1.18889C0.766667 3.16667 0.5 3.47778 0.5 3.9V4.74444C0.5 5.16667 0.766667 5.61111 1.18889 5.61111H15.8778C16.3 5.61111 16.5222 5.14444 16.5222 4.74444V3.9C16.5 3.47778 16.2778 3.16667 15.8556 3.16667ZM10.5 3.16667H6.5V1.83333H10.5V3.16667Z"/>
                              </svg>
                            </div>
                            <div class="w-100">
                              <input-search [options]="examOptions" [readonly]="pq.id.value ? true : false" placeholder="Selecione o exame" [form]="pq.inp" ></input-search> <!-- (keydown.Tab)="newExamRow(pq, i)" [readonly]="disable ? true : false" -->
                            </div>
                            <div style="align-items: center;display: flex;">
                              <mat-icon (click)="getAnalytesById(pq.inp.value, i, true)">refresh</mat-icon>                        
                            </div>
                            <div class="w-100">
                              <input-search [options]="materialExamOptions" (focusin)="pq.inp.value ? getAnalytesById(pq.inp.value, i) : ''" [readonly]="disable ? true : false" placeholder="Selecione o material" [form]="pq.mat"></input-search> <!-- (click)="getAnalytesById(pq.inp.value, i)" -->
                            </div>
                            <div class="flex align-center gp-12">
                              <span>Urgente</span><checkbox-prop [form]="pq.urg"></checkbox-prop>
                            </div>
                            <div class="flex align-center gp-12">
                              <span>Material Coletado</span><checkbox-prop [form]="pq.col"></checkbox-prop>
                            </div>
                            <div class="w-100" style="padding-top: 1.25em;">
                              <input-prop placeholder="Prazo" type="datetime-local" [form]="pq.prz"></input-prop>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-center gp-12">
                          <button (click)="examForm()">Incluir Novo Exame</button>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-center mt-32 gp-12">
                      <button class="btn-reverse" (click)="back()">Cancelar e Voltar</button>
                      <button [ngStyle]="
                      {
                        'background-color': agreementRequestForm.name.value ? '' : '#404040',
                        'pointer-events': agreementRequestForm.name.value ? 'unset' : 'none',
                        'opacity': agreementRequestForm.name.value ? 1 : 0.4,
                        'border': agreementRequestForm.name.value ? ' ' : '#FFF'
                      }"
                      (click)="agreementRequestForm.name.value ? saveAllChanges(false) : ''">{{title === 'Novo Pedido' ? 'Incluir Pedido' : 'Salvar Todas Alterações'}}</button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-tab>
        <!-- <mat-tab>
          <ng-template mat-tab-label>
            <div class="flex align-center gap-1">
              <h2>Resumo da Requisição</h2>
            </div>
          </ng-template>

          <div class="boxform">
            <div class="box">
              <div class="flex flex-between align-center">
                <h2>Perguntas da Requisição</h2>
                <button *ngIf="registerQuestions.length > 0" (click)="openDialogModalAllQuestions()" class="btn">Responder Tudo</button>
              </div>
              <div class="mt-32">
                <div class="flex flex-center">
                  <b *ngIf="registerQuestions.length == 0">Não existem perguntas a serem respondidas para esta requisição!</b>
                </div>
                <app-table *ngIf="registerQuestions.length > 0" [Actions]="['Responder']" [Pagination]="false" [backPagination]="false" [Itens]="registerQuestions" (parentFun)="questionActions($event)"></app-table>
              </div>
            </div>

            <div class="box mt-32">
              <div class="flex flex-between align-center">
                <h2>Financeiro</h2>
              </div>
              <div class="mt-32">
                <app-table *ngIf="registerExams.length > 0" [Actions]="['Editar', 'Excluir']" [Pagination]="false" [Dropdown]="true" [backPagination]="false" [Itens]="registerExams" (parentFun)="actions($event)">
                  <ng-template let-rowDetail #header>
                    <div class="flex align-center gap-2">
                      <h2 *ngIf="registerExams.length > 0">Detalhar Exames</h2>
                      <checkbox-prop [disabledSd]='registerExams.length == 0 ? true : false' [form]="detailedExams"></checkbox-prop>
                    </div>
                    <div *ngIf="detailedExams.value">
                      <app-table [Extras]="Extras" [Pagination]="false" [backPagination]="false" [Itens]="getExamsAgreement(rowDetail.id)"></app-table>
                    </div>
                    <div class="box mt-32">
                      <div class="flex gp-12 w-100 flex-column-mobile">
                        <div class="flex flex-column w-100 values flex-column-mobile">
                          <mat-stepper #stepper>
                            <mat-step label="Selecione a forma de pagamento" state="edit">
                              <mat-label>Formas de Pagamento</mat-label>
                              <input-oc-multiple class="mt-32" [options]="money" [form]="paymentsFormVector[rowDetail.id] ? paymentsFormVector[rowDetail.id].paymentType : ''" required></input-oc-multiple>
                              <div *ngIf="paymentsFormVector[rowDetail.id] ? paymentsFormVector[rowDetail.id].paymentType.invalid && (paymentsFormVector[rowDetail.id].paymentType.dirty || paymentsFormVector[rowDetail.id].paymentType.touched) : ''">
                                <div style="color: red; margin-top: 5px;" *ngIf="paymentsFormVector[rowDetail.id].paymentType.errors?.['required']">
                                  É necessário informar ao menos um método de pagamento!
                                </div>
                              </div>
                              <button *ngIf="paymentsFormVector[rowDetail.id] ? !paymentsFormVector[rowDetail.id].paymentType.invalid && paymentsFormVector[rowDetail.id].paymentType.value : ''" class="mt-32" mat-button matStepperNext (click)="payment(paymentsFormVector[rowDetail.id].paymentType)">Avançar</button>
                            </mat-step>
                            <mat-step label="Informe os valores" state="attach_money">
                              <div *ngFor="let pay of newPayment; index as i">
                                <div class="border-payment flex-wrap">
                                  <h3>{{getPayment(pay.label.value)}}</h3>
                                  <div class="flex gp-12 mt-12 align-center flex-direction-column-mobile">
                                    <input-prop class="w-100" placeholder="Valor Recebido" (change)="checkReceivedValue(pay, rowDetail.id)" [form]="pay.value" required></input-prop>
                                    <button *ngIf="getTypeOfPayment(pay.label.value) === GetETypePayments.PIX" (click)="openDialogModalPIX()" class="btn">Gerar QR Code</button>
                                    <input-prop *ngIf="getTypeOfPayment(pay.label.value) === GetETypePayments.CARTAO_CREDITO || getTypeOfPayment(pay.label.value) === GetETypePayments.CARTAO_DEBITO" class="w-100" placeholder="Transação" [form]="pay.transaction"></input-prop>
                                    <input-prop *ngIf="getTypeOfPayment(pay.label.value) === GetETypePayments.CARTAO_CREDITO || getTypeOfPayment(pay.label.value) === GetETypePayments.CARTAO_DEBITO" class="w-100" placeholder="Nº Parcela" [form]="pay.parcel"></input-prop>
                                  </div>
                                </div>
                              </div>
                              <div class="flex gp-12 mt-32">
                                <button class="btn-reverse" mat-button matStepperPrevious>Voltar</button>
                                
                                <svg (click)="putInvoice(getInvoiceArray[0], requisitionId, newPayment, rowDetail.id)" width="30" height="30" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.6208 0.239933C10.6952 0.163967 10.7841 0.103618 10.8821 0.0624194C10.9802 0.0212207 11.0855 0 11.1919 0C11.2982 0 11.4035 0.0212207 11.5016 0.0624194C11.5996 0.103618 11.6885 0.163967 11.763 0.239933C12.075 0.555212 12.0793 1.06468 11.7739 1.38541L5.32319 9.01102C5.24995 9.09145 5.16108 9.15608 5.062 9.20098C4.96292 9.24587 4.85572 9.27008 4.74697 9.27213C4.63821 9.27417 4.53018 9.254 4.42949 9.21286C4.3288 9.17171 4.23756 9.11046 4.16135 9.03284L0.236178 5.0553C0.084797 4.90092 0 4.69333 0 4.47711C0 4.26089 0.084797 4.0533 0.236178 3.89892C0.31063 3.82295 0.399489 3.7626 0.497552 3.7214C0.595616 3.6802 0.700914 3.65898 0.80728 3.65898C0.913647 3.65898 1.01894 3.6802 1.11701 3.7214C1.21507 3.7626 1.30393 3.82295 1.37838 3.89892L4.70791 7.27317L10.5989 0.263933C10.6057 0.255502 10.613 0.247488 10.6208 0.239933Z" fill="#FF6344"/>
                                </svg>
                                <button (click)="putInvoice(getInvoiceArray[0], requisitionId, newPayment, rowDetail.id)" mat-button matStepperNext type="button">Concluir Pagamento</button> 
                                <button (click)="putInvoice(getInvoiceArray[0], requisitionId, newPayment, rowDetail.id)">Concluir Pagamento</button>
                              </div>
                            </mat-step>
                            <mat-step label="Concluído" state="check">
                              <div class="flex gp-12 align-center">
                                <h2>Concluído com sucesso!</h2>
                                <svg width="30" height="30" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.6208 0.239933C10.6952 0.163967 10.7841 0.103618 10.8821 0.0624194C10.9802 0.0212207 11.0855 0 11.1919 0C11.2982 0 11.4035 0.0212207 11.5016 0.0624194C11.5996 0.103618 11.6885 0.163967 11.763 0.239933C12.075 0.555212 12.0793 1.06468 11.7739 1.38541L5.32319 9.01102C5.24995 9.09145 5.16108 9.15608 5.062 9.20098C4.96292 9.24587 4.85572 9.27008 4.74697 9.27213C4.63821 9.27417 4.53018 9.254 4.42949 9.21286C4.3288 9.17171 4.23756 9.11046 4.16135 9.03284L0.236178 5.0553C0.084797 4.90092 0 4.69333 0 4.47711C0 4.26089 0.084797 4.0533 0.236178 3.89892C0.31063 3.82295 0.399489 3.7626 0.497552 3.7214C0.595616 3.6802 0.700914 3.65898 0.80728 3.65898C0.913647 3.65898 1.01894 3.6802 1.11701 3.7214C1.21507 3.7626 1.30393 3.82295 1.37838 3.89892L4.70791 7.27317L10.5989 0.263933C10.6057 0.255502 10.613 0.247488 10.6208 0.239933Z" fill="#FF6344"/>
                                </svg>
                              </div>
                            </mat-step>
                          </mat-stepper>
                        </div>
                        <div class="flex flex-column w-100 values mobile-width pd-left">
                          <div class="flex gp-12 w-100 align-center">
                            <div class="flex w-inherit gp-12 align-center">
                              <h2 style="min-width: 100px; width: 100px;">Bruto</h2>
                              <input-unset-prop [form]="paymentsFormVector[rowDetail.id].valorBruto" [readonly]="true"></input-unset-prop>
                            </div>
                          </div>
                          <div class="flex mt-22 gp-12 w-100 align-center">
                            <div class="flex gp-12 w-inherit mt-22 align-center">
                              <h2 style="min-width: 100px; width: 100px;">Acréscimo</h2>
                              <input-unset-prop [form]="paymentsFormVector[rowDetail.id].acrescimo"></input-unset-prop>
                              <svg (click)="additionOrDiscountTitle(getInvoiceArray[0], rowDetail.id, paymentsFormVector[rowDetail.id].acrescimo.value, GetEParticularStatus.Addition)" width="30" height="30" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.6208 0.239933C10.6952 0.163967 10.7841 0.103618 10.8821 0.0624194C10.9802 0.0212207 11.0855 0 11.1919 0C11.2982 0 11.4035 0.0212207 11.5016 0.0624194C11.5996 0.103618 11.6885 0.163967 11.763 0.239933C12.075 0.555212 12.0793 1.06468 11.7739 1.38541L5.32319 9.01102C5.24995 9.09145 5.16108 9.15608 5.062 9.20098C4.96292 9.24587 4.85572 9.27008 4.74697 9.27213C4.63821 9.27417 4.53018 9.254 4.42949 9.21286C4.3288 9.17171 4.23756 9.11046 4.16135 9.03284L0.236178 5.0553C0.084797 4.90092 0 4.69333 0 4.47711C0 4.26089 0.084797 4.0533 0.236178 3.89892C0.31063 3.82295 0.399489 3.7626 0.497552 3.7214C0.595616 3.6802 0.700914 3.65898 0.80728 3.65898C0.913647 3.65898 1.01894 3.6802 1.11701 3.7214C1.21507 3.7626 1.30393 3.82295 1.37838 3.89892L4.70791 7.27317L10.5989 0.263933C10.6057 0.255502 10.613 0.247488 10.6208 0.239933Z" fill="#FF6344"/>
                              </svg>
                            </div>
                          </div>
                          <div class="flex mt-22 gp-12 w-100 align-center">
                            <div class="flex w-inherit gp-12 mt-22 align-center" >
                              <h2 style="min-width: 100px; width: 100px;">Desconto</h2>
                              <input-unset-prop [form]="paymentsFormVector[rowDetail.id].desconto" ></input-unset-prop>
                              <svg (click)="additionOrDiscountTitle(getInvoiceArray[0], rowDetail.id, paymentsFormVector[rowDetail.id].desconto.value, GetEParticularStatus.Discount)" width="30" height="30" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.6208 0.239933C10.6952 0.163967 10.7841 0.103618 10.8821 0.0624194C10.9802 0.0212207 11.0855 0 11.1919 0C11.2982 0 11.4035 0.0212207 11.5016 0.0624194C11.5996 0.103618 11.6885 0.163967 11.763 0.239933C12.075 0.555212 12.0793 1.06468 11.7739 1.38541L5.32319 9.01102C5.24995 9.09145 5.16108 9.15608 5.062 9.20098C4.96292 9.24587 4.85572 9.27008 4.74697 9.27213C4.63821 9.27417 4.53018 9.254 4.42949 9.21286C4.3288 9.17171 4.23756 9.11046 4.16135 9.03284L0.236178 5.0553C0.084797 4.90092 0 4.69333 0 4.47711C0 4.26089 0.084797 4.0533 0.236178 3.89892C0.31063 3.82295 0.399489 3.7626 0.497552 3.7214C0.595616 3.6802 0.700914 3.65898 0.80728 3.65898C0.913647 3.65898 1.01894 3.6802 1.11701 3.7214C1.21507 3.7626 1.30393 3.82295 1.37838 3.89892L4.70791 7.27317L10.5989 0.263933C10.6057 0.255502 10.613 0.247488 10.6208 0.239933Z" fill="#FF6344"/>
                              </svg>
                            </div>
                          </div>
                          <div class="flex mt-22 gp-12 w-100 align-center">
                            <div class="flex gp-12 w-inherit mt-22 align-center">
                              <h2 style="min-width: 100px; width: 100px;">Líquido</h2>
                              <input-unset-prop [form]="paymentsFormVector[rowDetail.id].valorLiquido" [readonly]="true"></input-unset-prop>
                            </div>
                          </div>
                          <div class="flex mt-22 gp-12 w-100 align-center">
                            <div class="flex gp-12 w-inherit mt-22 align-center">
                              <h2 style="min-width: 100px; width: 100px;">Total Pago</h2>
                              <input-unset-prop [form]="paymentsFormVector[rowDetail.id].totalPago" [readonly]="true"></input-unset-prop>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </app-table>
                <div *ngIf="registerExams.length == 0" class="flex flex-center mt-32"> <!-- style="margin-top: 60px;" -->
                  <!-- <strong><h1>É necessário ao menos um pedido médico cadastrado em um convênio particular para ser visualizado!</h1></strong>
                </div>
              </div>
            </div> -->

            <!-- <div class="box mt-32">
              <div class="flex align-center w-100">
                <h2>Coleta</h2>
                <div *ngIf="allAgreementRequestExams.length > 0" style="padding-left: calc(32px * var(--proportional))" class="flex flex-start w-inherit">
                  <checkbox-prop [form]="formCollect.hasPriority" label="Coleta Prioritária"></checkbox-prop>
                </div>
                <div *ngIf="allAgreementRequestExams.length > 0" class="flex flex-end w-inherit">
                  <checkbox-prop (click)="includeCustomerToCollect()" [form]="formCollect.includeCustomerToCollect" label="Incluir Paciente para Coleta"></checkbox-prop>
                </div>
              </div>

              <div *ngIf="allAgreementRequestExams.length > 0" class="table">
                <table>
                  <div style="display: table" class="w-100">
                    <thead>
                        <tr>
                            <th>Mnemônico</th>                                    
                            <th>Descrição do Exame</th>
                            <th>Material</th>
                            <th>Urgente</th>
                            <th>Previsão de Entrega</th> 
                            <th>Coletar</th> 
                            <th>Preparo</th> 
                        </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let exam of allAgreementRequestExams; index as i;">
                        <tr>                                            
                            <td>{{exam.code}}</td>                                            
                            <td>{{exam.name}}</td>
                            <td>{{exam.description}}</td>
                            <td>
                              <span [ngClass]="exam.isUrgent ? 'red' : 'green'">{{exam.isUrgent ? "Sim" : "Não"}}</span>
                            </td>
                            <td>{{exam.analyteDeadline | date:'dd/MM/yyyy - HH:mm'}}</td>
                            <td [ngClass]="!formCollect.includeCustomerToCollect.value ? 'disable' : ''">
                              <checkbox-prop [form]="getCollectSituation(exam, i)"></checkbox-prop>
                            </td>
                            <td style="padding-left:30px;">
                              <svg (click)="getAnalyteInstruction(exam)" style="cursor:pointer" width="18" height="20" viewBox="0 0 18 20" fill="none"          xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.83333 7.33333H5.16667V8.66667H3.83333V7.33333Z" fill="#404040"/>
                                <path d="M6.5 7.33333H11.8333V8.66667H6.5V7.33333Z" fill="#404040"/>
                                <path d="M3.83333 10.6667H5.16667V12H3.83333V10.6667Z" fill="#404040"/>
                                <path d="M3.83333 14H5.16667V15.3333H3.83333V14Z" fill="#404040"/>
                                <path d="M1.83333 17.3333V3.33333H3.83333V5.33333H11.8333V3.33333H13.8333V8.66667H15.1667V3.33333C15.1667 2.97971 15.0262 2.64057 14.7761 2.39052C14.5261 2.14048 14.187 2 13.8333 2H11.8333V1.33333C11.8333 0.979711 11.6929 0.640573 11.4428 0.390524C11.1928 0.140476 10.8536 0 10.5 0H5.16667C4.81304 0 4.47391 0.140476 4.22386 0.390524C3.97381 0.640573 3.83333 0.979711 3.83333 1.33333V2H1.83333C1.47971 2 1.14057 2.14048 0.890524 2.39052C0.640476 2.64057 0.5 2.97971 0.5 3.33333V17.3333C0.5 17.687 0.640476 18.0261 0.890524 18.2761C1.14057 18.5262 1.47971 18.6667 1.83333 18.6667H7.83333V17.3333H1.83333ZM5.16667 1.33333H10.5V4H5.16667V1.33333Z" fill="#404040"/>
                                <path d="M9.16667 11.3333V12.942C9.58539 12.2419 10.1804 11.6639 10.8924 11.2656C11.6043 10.8673 12.4083 10.6628 13.224 10.6723C14.0397 10.6819 14.8386 10.9052 15.5411 11.32C16.2435 11.7349 16.8248 12.3267 17.227 13.0364C17.6293 13.7461 17.8383 14.5489 17.8333 15.3647C17.8283 16.1804 17.6094 16.9806 17.1985 17.6853C16.7876 18.39 16.199 18.9746 15.4915 19.3808C14.7841 19.787 13.9824 20.0005 13.1667 20V18.6667C13.7678 18.6675 14.358 18.5057 14.8747 18.1984C15.3914 17.8912 15.8154 17.4499 16.1018 16.9214C16.3882 16.3928 16.5263 15.7967 16.5016 15.196C16.4768 14.5954 16.29 14.0126 15.9611 13.5094C15.6322 13.0063 15.1733 12.6014 14.6331 12.3377C14.0928 12.0741 13.4913 11.9614 12.8923 12.0117C12.2932 12.062 11.7189 12.2733 11.2302 12.6233C10.7414 12.9734 10.3565 13.449 10.116 14H11.8333V15.3333H7.83333V11.3333H9.16667Z" fill="#404040"/>
                              </svg>
                            </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </div>
                </table>
                <div class="flex align-center gap-2">
                  <h2>Verificar todos preparos</h2>
                  <svg (click)="getAllAnalyteInstructions(allAgreementRequestExams)" style="cursor:pointer" width="18" height="20" viewBox="0 0 18 20" fill="none"          xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.83333 7.33333H5.16667V8.66667H3.83333V7.33333Z" fill="#404040"/>
                    <path d="M6.5 7.33333H11.8333V8.66667H6.5V7.33333Z" fill="#404040"/>
                    <path d="M3.83333 10.6667H5.16667V12H3.83333V10.6667Z" fill="#404040"/>
                    <path d="M3.83333 14H5.16667V15.3333H3.83333V14Z" fill="#404040"/>
                    <path d="M1.83333 17.3333V3.33333H3.83333V5.33333H11.8333V3.33333H13.8333V8.66667H15.1667V3.33333C15.1667 2.97971 15.0262 2.64057 14.7761 2.39052C14.5261 2.14048 14.187 2 13.8333 2H11.8333V1.33333C11.8333 0.979711 11.6929 0.640573 11.4428 0.390524C11.1928 0.140476 10.8536 0 10.5 0H5.16667C4.81304 0 4.47391 0.140476 4.22386 0.390524C3.97381 0.640573 3.83333 0.979711 3.83333 1.33333V2H1.83333C1.47971 2 1.14057 2.14048 0.890524 2.39052C0.640476 2.64057 0.5 2.97971 0.5 3.33333V17.3333C0.5 17.687 0.640476 18.0261 0.890524 18.2761C1.14057 18.5262 1.47971 18.6667 1.83333 18.6667H7.83333V17.3333H1.83333ZM5.16667 1.33333H10.5V4H5.16667V1.33333Z" fill="#404040"/>
                    <path d="M9.16667 11.3333V12.942C9.58539 12.2419 10.1804 11.6639 10.8924 11.2656C11.6043 10.8673 12.4083 10.6628 13.224 10.6723C14.0397 10.6819 14.8386 10.9052 15.5411 11.32C16.2435 11.7349 16.8248 12.3267 17.227 13.0364C17.6293 13.7461 17.8383 14.5489 17.8333 15.3647C17.8283 16.1804 17.6094 16.9806 17.1985 17.6853C16.7876 18.39 16.199 18.9746 15.4915 19.3808C14.7841 19.787 13.9824 20.0005 13.1667 20V18.6667C13.7678 18.6675 14.358 18.5057 14.8747 18.1984C15.3914 17.8912 15.8154 17.4499 16.1018 16.9214C16.3882 16.3928 16.5263 15.7967 16.5016 15.196C16.4768 14.5954 16.29 14.0126 15.9611 13.5094C15.6322 13.0063 15.1733 12.6014 14.6331 12.3377C14.0928 12.0741 13.4913 11.9614 12.8923 12.0117C12.2932 12.062 11.7189 12.2733 11.2302 12.6233C10.7414 12.9734 10.3565 13.449 10.116 14H11.8333V15.3333H7.83333V11.3333H9.16667Z" fill="#404040"/>
                  </svg>
                </div>
              </div>
              <div *ngIf="allAgreementRequestExams.length == 0" class="flex flex-center mt-32" >
                <strong><h1>Ainda não há um pedido médico cadastrado para esta requisição!</h1></strong>
              </div>
            </div>
            <div class="flex flex-center">
              <button (click)="finishRequisition()" class="btn mt-32">Finalizar Requisição</button>
            </div>
          </div>
        </mat-tab> -->
      </mat-tab-group>
    </div>
  </div>
</div>