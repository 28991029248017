<div class="contentPage">
    <div class="flex align-center flex-between headerResponsive">
      <div class="flex align-center gap-2 titleResponsive">
        <h1>Convênios Apoiados</h1>
        <app-button (click)="newAnalyte()" [placeholder]="'Novo'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
      </div>
      <app-filter class="filterResponsive" (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
        <div class="query">
          <input-prop #autoFocus [form]="filter.Search" [placeholder]="'Pesquisar'"></input-prop>
          <checkbox-prop class="flex flex-center" [gap]="1" label="Somente Ativos" [form]="filter.isActive"></checkbox-prop>
        </div>
        <app-button class="queryBtn" (click)="get()" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button> -->
      </app-filter>
    </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [ClickInItem]="ActionsExtra" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="agreementSupport" [Actions]="['Editar']" (parentFun)="actions($event)" (changePage)="get($event)"></app-table>
  </div>
</div>
