<div class="contentPage">
  <div class="flex align-center w-100 flex-direction-column-mobile flex-between">
    <h1>Baixar Coleta</h1>
    <app-button (click)="openDialogModalUserValidation()" *ngIf="actualCollectByRecipientsUser === '' && selectedTab.value === 1" [placeholder]="'Autenticar Usuário'" [class]="'flex gap-2 align-center'"></app-button>
    <div *ngIf="actualCollectByRecipientsUser != '' && selectedTab.value === 1" class="flex flex-between align-center gap-2">
      <h1>Usuário Atual:</h1>
      <b>{{actualCollectByRecipientsUser}}</b>
      <app-button (click)="finish()" [placeholder]="'Finalizar'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <div class="flex">
      <app-filter class="filterResponsive" [cleanButton]="true" (beforeClose)="getAllRequisitionRecipients()" (clearFilters)="clearForms()">
        <div class="query">          
          <select-prop [form]="formFilter.companyId" (change)="getUnities()" [placeholder]="'Selecione a Empresa'" [options]="companyOptions"></select-prop>
          <select-prop [form]="formFilter.unityId" placeholder="Selecione a Unidade" [options]="unityOptions" (change)="getAllRequisitionRecipients()"></select-prop>
          <select-prop [form]="formFilter.collectionPlaceId" placeholder="Selecione o Local de Coleta" [options]="collectionPlaceOptions" (change)="getAllRequisitionRecipients()"></select-prop>
          <input-prop [placeholder]="'Requisição'" [form]="formFilter.requisitionNumber"></input-prop>
        </div>
        <app-button (click)="getAllRequisitionRecipients()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
      </app-filter>
    </div>
  </div>
  <mat-tab-group class="mt-32" [selectedIndex]="selectedTab.value" (selectedIndexChange)="myTabSelectedTabChange($event)">
    <mat-tab label="Coleta de Materiais">
      <div class="flex flex-column">
          <app-table [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" dropdownId="requisitionId" [ClickInItem]="ActionsExtra" [Dropdown]="true" [Pagination]="true" [backPagination]="true" [Itens]="registerRequisition" (parentFun)="actions($event)" (changePage)="getAllRequisitionRecipients($event)">
            <ng-template let-rowDetail #header>
              <div class="flex flex-center flex-column">
                <app-table [noShadow]="true" [Pagination]="false" [backPagination]="false" [Itens]="registerExams[rowDetail._hide_requisitionId]"></app-table>
              </div>
              <div class="flex align-center gap-2">
                <h2>Verificar Preparo</h2>
                <svg (click)="openDialogModalAllInstructions(rowDetail._hide_requisitionId)" style="cursor:pointer" width="18" height="20" viewBox="0 0 18 20" fill="none"          xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.83333 7.33333H5.16667V8.66667H3.83333V7.33333Z" fill="#404040"/>
                  <path d="M6.5 7.33333H11.8333V8.66667H6.5V7.33333Z" fill="#404040"/>
                  <path d="M3.83333 10.6667H5.16667V12H3.83333V10.6667Z" fill="#404040"/>
                  <path d="M3.83333 14H5.16667V15.3333H3.83333V14Z" fill="#404040"/>
                  <path d="M1.83333 17.3333V3.33333H3.83333V5.33333H11.8333V3.33333H13.8333V8.66667H15.1667V3.33333C15.1667 2.97971 15.0262 2.64057 14.7761 2.39052C14.5261 2.14048 14.187 2 13.8333 2H11.8333V1.33333C11.8333 0.979711 11.6929 0.640573 11.4428 0.390524C11.1928 0.140476 10.8536 0 10.5 0H5.16667C4.81304 0 4.47391 0.140476 4.22386 0.390524C3.97381 0.640573 3.83333 0.979711 3.83333 1.33333V2H1.83333C1.47971 2 1.14057 2.14048 0.890524 2.39052C0.640476 2.64057 0.5 2.97971 0.5 3.33333V17.3333C0.5 17.687 0.640476 18.0261 0.890524 18.2761C1.14057 18.5262 1.47971 18.6667 1.83333 18.6667H7.83333V17.3333H1.83333ZM5.16667 1.33333H10.5V4H5.16667V1.33333Z" fill="#404040"/>
                  <path d="M9.16667 11.3333V12.942C9.58539 12.2419 10.1804 11.6639 10.8924 11.2656C11.6043 10.8673 12.4083 10.6628 13.224 10.6723C14.0397 10.6819 14.8386 10.9052 15.5411 11.32C16.2435 11.7349 16.8248 12.3267 17.227 13.0364C17.6293 13.7461 17.8383 14.5489 17.8333 15.3647C17.8283 16.1804 17.6094 16.9806 17.1985 17.6853C16.7876 18.39 16.199 18.9746 15.4915 19.3808C14.7841 19.787 13.9824 20.0005 13.1667 20V18.6667C13.7678 18.6675 14.358 18.5057 14.8747 18.1984C15.3914 17.8912 15.8154 17.4499 16.1018 16.9214C16.3882 16.3928 16.5263 15.7967 16.5016 15.196C16.4768 14.5954 16.29 14.0126 15.9611 13.5094C15.6322 13.0063 15.1733 12.6014 14.6331 12.3377C14.0928 12.0741 13.4913 11.9614 12.8923 12.0117C12.2932 12.062 11.7189 12.2733 11.2302 12.6233C10.7414 12.9734 10.3565 13.449 10.116 14H11.8333V15.3333H7.83333V11.3333H9.16667Z" fill="#404040"/>
                </svg>
              </div>
            </ng-template>
          </app-table>
      </div>
    </mat-tab>
    <mat-tab label="Coleta com Baixa por Recipiente">
      <div *ngIf="actualCollectByRecipientsUser != ''" class="flex flex-end mb-24 mt-24">
        <input-prop  (keydown.enter)="form.codeBarFilter.value ? putCodeBarRequisition(form.codeBarFilter.value) : ''" type="number" placeholder="Informe o Código de Barras" [form]="form.codeBarFilter"></input-prop>
      </div>
      <div class="data-table">
        <app-table [Pagination]="false" [backPagination]="false" [Itens]="registerRecipients" (parentFun)="actions($event)"></app-table>          
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #dialogUserValidation let-obj>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h2>Autenticação por Código de Barras</h2>
        <svg class="close-svg" (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="flex w-100 mt-32 gap-2 align-center">
        <div class="w-100">
          <input-prop (keydown.enter)="validateUserCodebar(obj)" type="number" [form]="form.userCodeBar" [placeholder]="'Código de Barras'"></input-prop>
        </div>
      </div>
      <div class="flex flex-center gap-2 mt-32">
        <app-button [placeholder]="'Validar'" (click)="validateUserCodebar(obj)" [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogCollectRecipients let-data>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>Lista de Recipientes</h1>
        <svg class="close-svg" (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="mt-32 flex flex-column">
        <h1>Requisição: <strong>{{data.requisition}} - {{data.customer}}</strong></h1>
      </div>
      <div class="flex w-100 mt-32 gap-2 align-center">
        <div class="w-100 flex-direction-column-mobile">
          <app-table [noShadow]="true" [Pagination]="false" [backPagination]="false" [Itens]="recipientsSelection" [ClickInItem]="ActionsExtraSelect" (parentFun)="ActionsSelect($event)"></app-table>
        </div>
      </div>
      <div class="flex flex-center gap-2 mt-32">        
        <app-button (click)="finishCollect(data)" [placeholder]="'Finalizar coleta dos recipientes selecionados'" [class]="'flex gap-2 align-center btn'"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalAllInstructions let-analytes>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>Preparo</h1>
        <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <ng-container *ngFor="let analyteInstruction of analytes">
        <div class="mt-32">          
          <h2 class="exameFont">Exame: <strong class="orangeFont">{{analyteInstruction.code}}</strong></h2>
          <div class="mt-32">
            <textarea-prop [readonly]="true" [placeholder]="'Informação de Preparo'" [form]="analyteInstruction.form"></textarea-prop>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>