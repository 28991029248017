export type AddressObject = {
    address: string;
    addressNumber: string;
    addressDistrict: string;
    addressCity: string;
    addressState: string;
    addressCEP: string;
    addressComplement: string;
    addressCountry: string;
}

export const ethnicityOptions = [
  {
    label: "Não Informado", value: 0
  },
  {
    label: "Branca", value: 1
  },
  {
    label: "Preta", value: 2
  },
  {
    label: "Amarela", value: 3
  },
  {
    label: "Parda", value: 4
  },
  {
    label: "Indígena", value: 5
  },
]

export const resolveMedication: Array<Object> = [  
    {
      label: 'id',
      retrive: 'idProduto',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Nome',
      retrive: 'nomeProduto',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_RazaoSocial',
      retrive: 'razaoSocial',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Razão Social',
      retrive: 'razaoSocial',
      method: '',
      after: '',
      before: ''
    },    
    {
      label: "extra",
      retrive: "getExtra",
      method: '',
      after: '',
      before: ''
    }
  ];

export const resolveMedicationCustomer: Array<Object> = [ 
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },     
    {
      label: 'customer.register.list.name',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'customer.register.list.active',
      retrive: '',
      method: 'isActive',
      after: '',
      before: ''
    }
  ];  

  export const resolveMedicationCustomerLocally: Array<Object> = [ 
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },     
    {
      label: 'Nome',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    }
  ];  