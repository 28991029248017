import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../http.service';
import { HttpResponsePageable } from '../services.types';
import { Guid } from '@app/util/guid';

@Injectable({
  providedIn: 'root'
})
export class ReportMethodologyService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/report/methodology');
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers);
  }

  override post(body: Object) {
    return super.post(body) as Promise<HttpResponsePageable<any>>
  }

  override put(body: Object, id?:Guid, path?: string) {
    return super.put(body, id, path) as Promise<HttpResponsePageable<any>>
  }

  override delete(id?: Guid | undefined, path?: string | undefined){
    return super.delete(id) as Promise<any>
  }
  
}