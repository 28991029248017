<div class="contentPage">
  <div class="flex flex-between w-100">
    <div class="flex gap-2 align-center">
        <h1>Parâmetros das Mensagens</h1>        
        <button class="btn-reverse" (click)="openDialogModal()">Novo</button>
    </div>
  </div>
  <div class="mt-32">
    <app-table 
      [Itens]="resolvedEndpoints" 
      [Pagination]="true" 
      [backPagination]="true"
      [sumRecords]="paginationInfo.sumRecords"
      [indexNumber]="paginationInfo.numberRegistry" 
      [pageNumber]="paginationInfo.page" 
      [ClickInItem]="ActionsExtra"
      [Actions]="['Editar', 'Excluir']"
      (changePage)="getParameters($event)"
      (parentFun)="actions($event)"
      ></app-table>
  </div>
</div>

<ng-template #dialogModal let-data>
  <div class="flex flex-between w-100 align-center">
    <h1>Novo Parâmetro</h1>
    <svg class="close-svg" (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
    </svg>
  </div>
  <div class="flex gap-form mt-32">
    <div class="w-100">
      <input-prop [placeholder]="'Código'" [form]="form.controls['code']"></input-prop>
    </div>    
    <div class="w-100">
      <input-prop [placeholder]="'Descrição'" [form]="form.controls['description']"></input-prop>
    </div>    
  </div>
  <div class="flex gap-form mt-32">
    <div class="w-100">
      <input-search class="w-100" [options]="tenants" [form]="form.controls['tenantId']" [placeholder]="'Selecione o Cliente'"></input-search>          
    </div>    
    <div class="w-100">
      <input-search class="w-100" [options]="type" [form]="form.controls['typeProtocol']" [placeholder]="'Selecione o Tipo'"></input-search>
    </div>    
  </div>
  <div class="w-100 mt-32 flex flex-center">    
    <button class="btn btn-reverse" (click)="data ? updateParameter(data) : createParameter()">Salvar</button>
  </div>
</ng-template>