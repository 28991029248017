import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
   Component,
   ElementRef,
   EventEmitter,
   Input,
   Output,
   SimpleChanges,
   ViewChild
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from "@angular/material/legacy-autocomplete";
import { MatLegacyChipInputEvent as MatChipInputEvent } from "@angular/material/legacy-chips";
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from "@angular/material/legacy-autocomplete";
import { debounceTime } from "rxjs";

@Component({
   selector: "input-user-multiple-new",
   templateUrl: "./input-user-multiple-new.component.html",
   styleUrls: ["./input-user-multiple-new.component.scss"]
})
export class InputUserMultipleNewComponent {
   separatorKeysCodes: number[] = [ENTER, COMMA];
   formFilter = new UntypedFormControl();
   //@ts-ignore
   @Input() form: formFilterControl;
   filteredFruits: Array<any> = [];
   fruits: string[] = [];

   @Input() options: Array<any> = [];
   @Input() hideOptions: Array<any> = [];
   allOptions: Array<any> = [];
   filteredOptions: Array<any> = this.allOptions;
   removedOptions: Array<any> = [];

   @ViewChild("fruitInput") fruitInput!: ElementRef<HTMLInputElement>;
   @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

   constructor() {}

   ngOnInit() {
      this.formFilter.valueChanges
         .pipe(debounceTime(500))
         .subscribe((value) => {
            this.filteredOptions = this.allOptions.filter((item) =>
               item.label.includes(value)
            );
         });
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes.hasOwnProperty("options")) {
         this.filterOptions();
      }
   }

   filterOptions() {
      this.allOptions = [];
      this.options.forEach((option: any) => {
         if (
            this.hideOptions.findIndex((x) => x == option.value) < 0 &&
            this.removedOptions.findIndex((x) => x.value == option.value) < 0
         ) {
            this.allOptions.push({
               label: option.label,
               value: option.value
            });
         }
      });
      this.filteredOptions = this.allOptions;
   }

   add(event: MatChipInputEvent): void {
      const value = (event.value || "").trim();

      // Add our fruit
      if (value) {
         this.fruits.push(value);
      }

      // Clear the input value
      event.chipInput!.clear();

      this.formFilter.setValue("");
   }

   remove(fruit: string): void {
      const index = this.fruits.indexOf(fruit);
      if (index >= 0) {
         this.fruits.splice(index, 1);
      }

      //@ts-ignore
      const value = this.options.filter((x) => x.label == fruit)[0].value;

      this.form.value.splice(
         this.form.value.findIndex((x: any) => x == value),
         1
      );
      //@ts-ignore
      this.removedOptions.splice(
         this.removedOptions.findIndex((x) => x.value == value),
         1
      );

      this.allOptions = [];
      this.options.forEach((option: any) => {
         if (
            this.removedOptions.filter((x) => x.value == option.value).length ==
               0 &&
            this.hideOptions.findIndex((x) => x == option.value) < 0
         ) {
            this.allOptions.push({
               label: option.label,
               value: option.value
            });
         }
      });
      this.filterOptions();
   }

   selected(event: MatAutocompleteSelectedEvent): void {
      var options = [];
      if (this.form.value && this.form.value.length > 0) {
         options = this.form.value;
      }
      options.push(event.option.value.value);
      this.form.setValue(options);

      this.fruits.push(event.option.viewValue);
      this.fruitInput.nativeElement.value = "";
      this.formFilter.setValue("");

      const index = this.allOptions.findIndex(
         (x) => x.value == event.option.value.value
      );
      this.allOptions.splice(index, 1);

      this.removedOptions.push({
         value: event.option.value.value,
         label: event.option.value.label
      });
      this.filterOptions();
      setTimeout(() => {
         this.autocomplete.openPanel();
      });
   }

   private _filter(value: any): string[] {
      const filterValue = value.label.toLowerCase();
      return this.options.filter((fruit) =>
         fruit.label.toLowerCase().includes(filterValue)
      );
   }

   clearOptions() {
      this.form.value = [];
      this.fruits = [];
      this.removedOptions = [];
      this.filterOptions();
      setTimeout(() => {
         this.autocomplete.openPanel();
      });
   }
}
