import { AsyncPipe } from "@angular/common";
import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { PaginationTableComponent } from "@app/modules_new/shared/components/form/table/pagination/pagination.component";
import { TableFormComponent } from "@app/modules_new/shared/components/form/table/table.component";
import { ContentFilterLayoutComponent } from "@app/modules_new/shared/layouts/content-filter/content-filter.component";
import { TranslateModule } from "@ngx-translate/core";
import { PaginationEvent } from "@app/modules_new/shared/components/form/table/pagination/pagination.model";
import { PageableComponent } from "@app/modules_new/common/components/pageable";
import { tableActions, tableColumns } from "./ip-addresses-released";
import { QueryParams } from "@app/modules_new/data/services/types/http.types";
import { MatDialog } from "@angular/material/dialog";
import { IpAddressesReleasedModalComponent } from "./modal/ip-addresses-released-modal/ip-addresses-released-modal.component";
import { IpAddressesReleasedResponse } from "@app/modules_new/administrativeData/data/services/controle-de-acesso/controle-de-acesso.types";
import { IpAddressesReleasedFacade } from "../../data/facade/ip-addresses-released-facade";

@Component({
   selector: "app-ip-addresses-released",
   templateUrl: "./ip-addresses-released.component.html",
   standalone: true,
   imports: [
      ContentFilterLayoutComponent,
      TranslateModule,
      TableFormComponent,
      PaginationTableComponent,
      AsyncPipe
   ]
})
export class IpAddressesReleasedComponent
   extends PageableComponent<IpAddressesReleasedResponse[]>
   implements OnInit
{
   constructor() {
      super(tableColumns, tableActions);
   }

   private _IpAddressesReleasedFacade = inject(IpAddressesReleasedFacade);
   private _dialog = inject(MatDialog);

   formFilterGroup = new FormGroup({
      code: new FormControl(),
      description: new FormControl(),
      search: new FormControl(),
      ipAddress: new FormControl(),
      checkbox: new FormControl(null)
   });

   formFilter: Array<object> = [
      {
         placeholder: "default.search_ip",
         type: "text",
         form: this.formFilterGroup.controls["ipAddress"],
         click: true
      },
      {
         placeholder: "default.search_description",
         type: "text",
         form: this.formFilterGroup.controls["search"],
         click: true
      },
      {
         placeholder: "default.active-only",
         type: "toggle",
         form: this.formFilterGroup.controls.checkbox
      }
   ];

   @ViewChild("ipAddress") ipAddressTable!: TableFormComponent;

   ngOnInit() {
      this.getIpAdress();
   }

   getIpAdress(search?: boolean) {
      this.page = search === true ? 1 : this.page;
      const queryObject: QueryParams = {
         page: this.page,
         numberRegistry: this.numberRegistry,
         ipAddress: this.formFilterGroup.controls["ipAddress"].value,
         search: this.formFilterGroup.controls["search"].value
      };
      const { data$, total$ } =
         this._IpAddressesReleasedFacade.get(queryObject);
      this.data = data$;
      this.total = total$;
   }

   ngAfterViewInit() {
      this.ipAddressTable.userInteractions$.subscribe(async (response) => {
         switch (response?.eventType) {
            case "IS_ACTIVE_CHANGE":
               const success =
                  await this._IpAddressesReleasedFacade.patchActive(
                     response.payload.data.id,
                     response.payload.data.isActive
                  );

               if (!success) {
                  this.ipAddressTable.changeActive(
                     response.payload.data.id,
                     response.payload.data.isActive
                  );
               }
               break;
            case "ACTIONS_PRESSED":
               switch (response.payload.event) {
                  case "EDITAR":
                     this.openModal(response.payload.data);
                     break;
                  case "DELETE": {
                     this._IpAddressesReleasedFacade.delete(
                        response.payload.data.id
                     );
                     break;
                  }
               }
         }
      });
   }

   changePage(event: PaginationEvent) {
      this.page = event.page;
      this.numberRegistry = event.numberRegistry;
      this.getIpAdress();
   }

   openModal(data?: any) {
      this._dialog.open(IpAddressesReleasedModalComponent, {
         width: "calc(900px * var(--proportional))",
         data: data
      });
   }

   resetFilter() {
      this.formFilterGroup.reset();
   }
}
