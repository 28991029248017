import { Injectable } from '@angular/core';
import { AdminAccesscontrolUserAuth } from 'src/app/services/auth/admin/accesscontrol-user-auth.service';
import { Person } from './select-person.types';
import { PersonService } from 'src/app/services/person.service';

interface PersonImage {
  [properties: string]: string;
}

interface Options {
  value: any,
  label: string,
}


@Injectable({
  providedIn: 'root'
})
export class SelectPersonService {
  lastTenantIdToken: string = '';
  persons: Person[] = [];
  personImages: PersonImage = {};
  personOptions: Options[] = [];
  lastGetDateAsMs: number =  0;

  constructor(
    private _accessControlService: AdminAccesscontrolUserAuth,
    private _personsService: PersonService
  ) { }

  getPersonsAndUserData(tenantId: string){
    const nowAsMs = new Date().getTime();
    const fiveMinutesAsMs = 1000 * 60 * 5;
    if(
      tenantId != this.lastTenantIdToken ||
      (this.lastGetDateAsMs + fiveMinutesAsMs) < nowAsMs
    ){
      this.getPersons();
      this.getUsersData(tenantId);
    }
    this.lastGetDateAsMs = nowAsMs;
    this.lastTenantIdToken = tenantId;
  }

  private getPersons() {
    this._personsService.getAll().then((res: any) => {
      let data = res.data as Person[];
      this.persons = data;
      data.forEach(person => {
        this.personOptions.push({
          value: person.id,
          label: person.name
        })
      })
    });
  }

  private getUsersData(tenantId: string){

    this._accessControlService.getAll(tenantId).then((res: any ) => {
      const data = res.data as {personId: string, imageBase64: string[]}[];
      data.forEach(person => {
        this.personImages[person.personId] = person.imageBase64[0];
      })
    })
  }

}
