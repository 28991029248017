import { inject, Injectable } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import { Guid } from "@app/util/guid";
import { MatDialog } from "@angular/material/dialog";
import { GroupsService } from "../services/groups/groups.service";
import { RequestOptions } from "@app/modules_new/data/services/types/http.types";
import { GroupResponse, GroupsResponse } from "../services/groups/groups.types";
import { lastValueFrom } from "rxjs";
import { ModuleService } from "../services/modules/modules.service";
import { ModuleAdminService } from "../services/resources/resources.service";

@Injectable({
   providedIn: "root"
})
export class GroupsFacade extends MultiObservableFacade {
   private _dialog = inject(MatDialog);

   private _moduleService = inject(ModuleService);

   private _moduleAdminService = inject(ModuleAdminService);

   private _groupsService = inject(GroupsService);

   private _groups = this.createNullableObservableControl<GroupResponse[]>();

   private _groupsAmount = this.createObservableControl<number>(0);

   private controls = this.controlGroup({
      permissionItens: this.emptyObservable(),
      permissionItensAmount: this.emptyObservable()
   });

   createTabsGroup(id: string) {
      this.controls.add(id, {
         permissionItens: this.createNullableObservableControl<any[]>(),
         permissionItensAmount: this.createObservableControl<number>(0)
      });
   }

   async getResourceOptions(search?: RequestOptions) {
      try {
         const response: any = await lastValueFrom(
            this._moduleAdminService.get<any>(search)
         );

         if (response.success == true) {
            return response.data[0].resources.map((x: any) => ({
               value: x.id,
               label: x.description != "" ? x.description : x.name
            }));
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }

      return [];
   }

   async getModulesOptions(search?: RequestOptions) {
      try {
         const response: any = await lastValueFrom(
            this._moduleService.getPagination<any>(1, 999999, search)
         );

         if (response.success == true) {
            return response.data.map((x: any) => ({
               value: x.id,
               label: x.description
            }));
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }

      return [];
   }

   get(request?: RequestOptions) {
      this._groupsService.get<GroupsResponse>(request).subscribe({
         next: (response) => {
            this._groups.subject.next(response.data);
            this._groupsAmount.subject.next(response.sumRecords);
         },
         error: (error) => {
            this._groups.subject.next([]);
            this._groupsAmount.subject.next(0);
            this.ToastErrorGeneric(error);
         }
      });

      return {
         data$: this._groups.observable$,
         total$: this._groupsAmount.observable$
      };
   }

   getById(id: string) {
      this._groupsService.getById<any>(new Guid(id)).subscribe({
         next: (response) => {
            this.controls
               .get(id)
               .permissionItens.subject.next(response.data[0].policies);

            this.controls
               .get(id)
               .permissionItensAmount.subject.next(
                  response.data[0].policies.length
               );
         },
         error: (err) => {
            this.controls.get(id).permissionItens.subject.next([]);
         }
      });
      return {
         data$: this.controls.get(id).permissionItens.observable$,
         total$: this.controls.get(id).permissionItensAmount.observable$
      };
   }

   post(obj: any) {
      return new Promise((resolve) =>
         this._groupsService.post(obj).subscribe({
            next: (res) => {
               this.ToastSuccess("Grupo criado com sucesso!");
               resolve(res);
            },
            error: (error) => {
               this.ToastErrorGeneric(error, "toast-msg.creation.error");
            }
         })
      );
   }

   postUser(obj: any, request: RequestOptions) {
      return new Promise((resolve) =>
         this._groupsService.post(obj, request).subscribe({
            next: (res) => {
               this.ToastSuccess("Usuário adicionado com sucesso!");
               resolve(res);
            },
            error: (error) => {
               this.ToastErrorGeneric(error, "toast-msg.creation.error");
            }
         })
      );
   }

   postResource(obj: any, request: RequestOptions) {
      return new Promise((resolve) =>
         this._groupsService.post(obj, request).subscribe({
            next: (res) => {
               this.ToastSuccess("groups.msgPostPermissions");
               resolve(res);
            },
            error: (error) => {
               this.ToastErrorGeneric(error, "toast-msg.creation.error");
            }
         })
      );
   }

   put(obj: any, id: string) {
      return new Promise((resolve) => {
         this._groupsService.put(obj, new Guid(id)).subscribe({
            next: (res) => {
               this._dialog.closeAll();
               this.ToastSuccess("Grupo alterado com sucesso!");
               resolve(res);
            },
            error: (error) => {
               this.ToastErrorGeneric(error, "toast-msg.creation.error");
            }
         });
      });
   }

   async patchActive(id: string, value: string) {
      let success = false;
      try {
         const response: any = await lastValueFrom(
            this._groupsService.patchToggleIsActive(id, !value)
         );
         if (response.success) {
            this.ToastSuccess(
               !value === true
                  ? "toast-msg.change-to-active.success"
                  : "toast-msg.change-to-inactive.success"
            );
            success = response.success;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      //@ts-ignore
      let data = this._groups.subject._value;
      let index = data.findIndex((x: any) => x.id == id);

      if (success) {
         data[index].isActive = !value;
      } else {
         data[index].isActive = value;
      }
      this._groups.subject.next(data);
      return success;
   }

   delete(id: string) {
      this.ToastPopUp("Deseja realmente excluir esse grupo?")
         .then((response) => {
            if (response.isConfirmed) {
               this._groupsService.delete(new Guid(id)).subscribe({
                  next: () => {
                     var request: RequestOptions = {
                        query: {
                           page: 1,
                           numberRegistry: 30
                        }
                     };
                     this.get(request);
                     this.ToastSuccess("Grupo excluido com sucesso!");
                  },
                  error: (error) => {
                     this.ToastErrorGeneric(error, "toast-msg.creation.error");
                  }
               });
            }
         })
         .catch((error) => {
            this.ToastErrorGeneric(error, "toast-msg.change.error");
         });
   }
}
