import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { RequisitionService } from '@app/services/requisitions/requisition.service';
import { UnityService } from '@app/services/auth/unity/unity-service.service';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Router } from '@angular/router';
import { GetListTraceability, Actions } from './list-traceability.model';

@Component({
  selector: 'app-list-traceability',
  templateUrl: './list-traceability.component.html',
  styleUrls: ['./list-traceability.component.scss']
})
export class ListTraceabilityComponent implements OnInit {
  total: number = 0;
  page: number = 1;
  index: number = 50;
  getParameters: string = (`?page=${this.page}&index=${this.index}`);
  collectionPlaceOptions: Array<any> = [];

  formDate = {
    initialDate: new UntypedFormControl(null, [Validators.required]),
    finalDate: new UntypedFormControl(null, [Validators.required]),
  }

  form = {
    requisitionCollectionPlace: new UntypedFormControl(null, [Validators.required]),
    searchByRequisitionNumber: new UntypedFormControl(null),
    searchByCustomerName: new UntypedFormControl(null),
    searchByCustomerCPF: new UntypedFormControl(null),
    searchByCustomerBirthDate: new UntypedFormControl(null),
    requisitionCollectionPlaceDescription: new UntypedFormControl(null, [Validators.required]),
    requisitionCollectionPlaceId: new UntypedFormControl(null, [Validators.required]),
    searchCustomer: new UntypedFormControl(null, [Validators.required])
  };

  formUnity = {
    requisitionUnity: new UntypedFormControl(null, [Validators.required]),
    requisitionUnityName: new UntypedFormControl(null, [Validators.required]),
    requisitionUnityId: new UntypedFormControl(null, [Validators.required])
  };

  registerRequisitions: Array<any> = [];
  resolveRequisitions = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_customerId',
      retrive: 'customerId',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Número',
      retrive: 'number',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Nome',
      retrive: '',
      method: 'getCustomerName',
      after: '',
      before: ''
    },
    {
      label: 'Data de Nascimento',
      retrive: '',
      method: 'getBirthDate',
      after: '',
      before: ''
    },
    {
      label: '_hide_UnityId',
      retrive: 'unityId',
      method: '',
      after: '',
      before: '',
    },
    {
      label: 'Unidade',
      retrive: '',
      method: 'getUnityDescription',
      after: '',
      before: '',
    },
    {
      label: 'Local de Coleta',
      retrive: '',
      method: 'getCollectionPlaceDescription',
      after: '',
      before: '',
    },
    {
      label: 'Data de Criação',
      retrive: '',
      method: 'getCreateAtDate',
      after: '',
      before: ''
    },
    {
      label: 'Finalizada',
      retrive: '',
      method: 'getRequisitionFinished',
      after: '',
      before: ''
    },
    {
      label: 'Situação',
      retrive: '',
      method: 'convertReqSituation',
      after: '',
      before: ''
    },
    {
      label: 'Rastreabilidade',
      retrive: '',
      method: 'getTraceability',
      after: '',
      before: ''
    }
  ];

  reqSituation = {
    0: "Em Atendimento",
    1: "Final Atendimento",
    3: "Aguardando Coleta",
    4: "Em Coleta",
    5: "Finalização Coleta",
    10: "Triado Parcial",
    11: "Triado",
    15: "Andamento",
    20: "Digitado Parcial",
    21: "Digitado",
    30: "Conferido Parcial",
    31: "Conferido",
    40: "Liberado Parcial",
    41: "Liberado"
  }

  unityOptions: Array<any> = [];

  constructor(
    private _requisitionService: RequisitionService,
    private _unityService: UnityService,
    private _router: Router
  ) { }

  async ngOnInit() {
    this.formDate.initialDate.setValue(this.incrementDays(new Date(), -30));
    this.formDate.finalDate.setValue(new Date());
    await this.getUnity();
    await this.getRequisitions();
  }

  async getRequisitions(paramPage?: number[]) {
    this.registerRequisitions = [];
    let formattedInitialDate = new Date(this.formDate.initialDate.value).toISOString();
    let formattedFinalDate = new Date(this.formDate.finalDate.value).toISOString();
    let collectionPlace = this.form.requisitionCollectionPlace.value === 'Todos' ? '' : this.form.requisitionCollectionPlace.value;


    let filters: { page: number, index: number, isActive: boolean, collectionPlaceId: string, unityId: string, initialDate: string, lastDate: string, requisitionNumber: string | null, name: string | null, customerCPF: string | null, birthDate: string | null }
    filters = { page: this.page, index: this.index, isActive: true, collectionPlaceId: collectionPlace, unityId: this.formUnity.requisitionUnity.value, initialDate: formattedInitialDate, lastDate: formattedFinalDate, requisitionNumber: null, name: null, customerCPF: null, birthDate: null }

    if (this.form.searchByRequisitionNumber.value) filters.requisitionNumber = this.form.searchByRequisitionNumber.value;
    if (this.form.searchByCustomerName.value) filters.name = this.form.searchByCustomerName.value;
    if (this.form.searchByCustomerCPF.value) filters.customerCPF = this.form.searchByCustomerCPF.value;
    if (this.form.searchByCustomerBirthDate.value) filters.birthDate = this.form.searchByCustomerBirthDate.value.toISOString();

    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    if (this.formUnity.requisitionUnity.value) {
      this.total = 0

      filters.index = this.index
      filters.page = this.page

      //@ts-ignore
      await this._requisitionService.getPagination(filters).then(
        (res: any) => {
          if (res.data.length != 0) {
            this.total = res.sumRecords;
            res.data.forEach((x: any) => {
              this.resolve(res.data, this.resolveRequisitions);
            });
          }
        }
      ).catch(
        (err) => {
          SwAlSetttings.printError(err);
        }
      );
    } else {
      setTimeout(() => {
        SwAlSetttings.alertaMsg('É necessário informar uma unidade!')
      }, 100);
    }
  }

  resolve(data: any, columns: any) {
    this.registerRequisitions = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerRequisitions.push(obj);
    });
  }

  async getUnity() {
    await this._unityService.getByPath('', this.getParameters).then(
      (res) => {
        //@ts-ignore
        if (res.data.length != 0) {
          //@ts-ignore
          res.data.forEach(unity => {
            this.unityOptions.push({
              value: unity.id,
              label: unity.name
            });
            //@ts-ignore
            unity.collectionPlace.forEach((collectionPlace) => {
              this.collectionPlaceOptions.push({
                value: collectionPlace.id,
                unityId: unity.id,
                label: collectionPlace.description
              });
            });
            this.formUnity.requisitionUnityId.setValue(unity.id);
            this.formUnity.requisitionUnityName.setValue(unity.name);
          });
          this.form.requisitionCollectionPlace.setValue(this.collectionPlaceOptions[1].value); // carrega o primeiro local de coleta
          this.formUnity.requisitionUnity.setValue(this.unityOptions[0].value); // carrega a primeira unidade
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  async getByIdUnity() {
    this.collectionPlaceOptions = [];
    await this._unityService.getById(this.formUnity.requisitionUnity.value).then(
      (res) => {
        //@ts-ignore
        if (res.data.length != 0) {
          //@ts-ignore
          res.data.forEach(unity => {
            if (unity.collectionPlace.length <= 0) {
              SwAlSetttings.alertaMsg("Essa unidade não possui local de coleta cadastrado!")
              this.form.requisitionCollectionPlace.setValue(null);
            } else {
              this.collectionPlaceOptions.push({
                value: 'Todos',
                label: 'Todos'
              });
              //@ts-ignore
              unity.collectionPlace.forEach((collectionPlace) => {
                this.collectionPlaceOptions.push({
                  value: collectionPlace.id,
                  label: collectionPlace.description
                });
              });
              this.form.requisitionCollectionPlace.setValue(this.collectionPlaceOptions[1].value);
            }
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  clearForms() {
    this.formUnity.requisitionUnity.reset()
    this.form.requisitionCollectionPlace.reset()
    this.formDate.initialDate.setValue(this.incrementDays(new Date(), -30));
    this.formDate.finalDate.setValue(new Date());
    this.form.searchByRequisitionNumber.reset()
    this.form.searchByCustomerName.reset()
    this.form.searchByCustomerCPF.reset()
    this.form.searchByCustomerBirthDate.reset()
  }

  incrementDays(date: Date, days: number) {
    let dateNow: Date = date;
    let dateMilisseconds = dateNow.setDate(dateNow.getDate() + days);
    return new Date(dateMilisseconds);
  }

  getCustomerName(item: GetListTraceability) {
    return item.name.toUpperCase();
  }

  getBirthDate(item: GetListTraceability) {
    var data = new Date(item.birthDate);
    var formattedDay = data.getDate() < 10 ? "0" + data.getDate() : data.getDate().toString();
    var formattedMonth = (data.getMonth() + 1) < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1).toString();
    return formattedDay + "/" + formattedMonth + "/" + data.getFullYear();
  }

  getUnityDescription(item: GetListTraceability) {
    return item.unityDescription.toUpperCase();
  }

  getCollectionPlaceDescription(item: GetListTraceability) {
    return item.collectionPlaceDescription.toUpperCase();
  }

  getCreateAtDate(item: GetListTraceability) {
    var formatHour = `${item.createdAt}Z`
    var data = new Date(formatHour);
    const MINIMUM_YEAR = 1970;

    if (data.getFullYear() < MINIMUM_YEAR) return "";

    var formattedDay = data.getDate() < 10 ? "0" + data.getDate() : data.getDate().toString();
    var formattedMonth = (data.getMonth() + 1) < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1).toString();
    var formattedHour = data.getHours() < 10 ? "0" + data.getHours() : data.getHours().toString();
    var formattedMinute = data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes().toString();
    return formattedDay + "/" + formattedMonth + "/" + data.getFullYear() + " às " + formattedHour + ":" + formattedMinute + " h";
  }

  getRequisitionFinished(item: GetListTraceability) {
    let situation = item.finished;
    return situation ? "<span style='background: #07BF56; color: #FFFFFF;' class='span-styles'>Finalizada</span>" : "<span style=' background: #FF0000; color: #FFFFFF;' class='span-styles'>Não Finalizada</span>";
  }

  convertReqSituation(item: any) {
    //@ts-ignore
    return item.status ? this.reqSituation[item.status] : this.reqSituation[0];
  }

  ActionsExtra = {
    "Rastreabilidade": 'traceability'
  };

  async Actions(action: Actions) {
    switch (action.action) {
      case 'traceability':
        this._router.navigateByUrl('Estatistica/Rastreabilidade/' + action.object.id);
        break;
    }
  }

  getTraceability() {
    return "<div style='width: 100%; display: flex; justify-content: center'><svg width=\"37\" height=\"31\" viewBox=\"0 0 37 31\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0.00146484 3.47402C0.00146484 1.63307 1.49385 0.140686 3.3348 0.140686H33.3348C35.1757 0.140686 36.6681 1.63307 36.6681 3.47402V26.8074C36.6681 28.6483 35.1757 30.1407 33.3348 30.1407H3.3348C1.49385 30.1407 0.00146484 28.6483 0.00146484 26.8074V3.47402ZM33.3348 3.47402H3.3348V26.8074H33.3348V3.47402ZM18.3348 6.80735C19.2553 6.80735 20.0015 7.55354 20.0015 8.47402V21.8074C20.0015 22.7278 19.2553 23.474 18.3348 23.474C17.4143 23.474 16.6681 22.7278 16.6681 21.8074V8.47402C16.6681 7.55354 17.4143 6.80735 18.3348 6.80735ZM11.6681 10.1407C12.5886 10.1407 13.3348 10.8869 13.3348 11.8074V21.8074C13.3348 22.7278 12.5886 23.474 11.6681 23.474C10.7477 23.474 10.0015 22.7278 10.0015 21.8074V11.8074C10.0015 10.8869 10.7477 10.1407 11.6681 10.1407ZM25.0015 14.3074C25.9219 14.3074 26.6681 15.0535 26.6681 15.974V21.8074C26.6681 22.7278 25.9219 23.474 25.0015 23.474C24.081 23.474 23.3348 22.7278 23.3348 21.8074V15.974C23.3348 15.0535 24.081 14.3074 25.0015 14.3074Z\" fill=\"#808080\"/></svg></div>"
  }

}
