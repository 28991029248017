<div class="contentPage">
    <div class="flex flex-between headerResponsive">
        <div class="flex gap-2 align-center titleResponsive">
            <h1>Grupo de Estoques</h1>
            <app-button (click)="openModal(options.CreateGroup)" [placeholder]="'Novo'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
        </div>
        <app-filter class="filterResponsive" (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
            <div class="query">
              <input-prop #autoFocus type="text" placeholder="Pesquisar" [form]="filter.descriptionSearch"></input-prop>
              <checkbox-prop class="flex flex-center" [gap]="1" label="Somente Ativos" [form]="filter.isActive"></checkbox-prop>
            </div>
            <app-button (click)="get()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
          </app-filter>
    </div>
    <div class="mt-32">
        <app-table [Itens]="resolvedStockGroup" [Dropdown]="true" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Actions]="['Editar', 'Excluir']" [ClickInItem]="ActionsExtra" (parentFun)="actions($event)">
            <ng-template let-rowDetail #header>
                <div class="contentPage">
                  <div class="flex align-center gap-2 w-100 flex-between">  
                    <h2>SubGrupo de Estoque</h2>
                    <button class="btn flex gap-2 align-center" (click)="openModal(options.CreateSubGroup, rowDetail.id)"><mat-icon>add</mat-icon>Novo</button>
                  </div>
                  <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [indexNumber]="index" [pageNumber]="page" *ngIf="resolvedStockSubGroup[rowDetail.id]" [noShadow]="true" [Itens]="resolvedStockSubGroup[rowDetail.id]" [Actions]="['Editar', 'Excluir']" [ClickInItem]="ActionsExtra2" (parentFun)="actionsSubGroup($event, rowDetail.id)"></app-table>
                </div>
            </ng-template>
        </app-table>
    </div>
</div>

<ng-template #dialogModal let-data>
    <ng-container>
        <div class="flex flex-between">
            <h2>{{data.option.label}}</h2>
            <span class="close" (click)="_dialog.closeAll();">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.384626 3.0527L18.0769 20.745L20.3846 18.4373L2.69232 0.745004L0.384626 3.0527Z" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M17.6923 0.744995L0 18.4373L2.30769 20.745L20 3.05269L17.6923 0.744995Z" />
                </svg>
            </span>
        </div>
        <div class="flex mt-32 flex-center w-100 flex-column">
            <input-prop (keyup.enter)="submit(data)" [placeholder]="'Descrição'" class="w-100" [form]="description"></input-prop>
        </div>
        <div class="flex mt-32 flex-center">
            <app-button (click)="submit(data)" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
        </div>
    </ng-container>
</ng-template>