import { Guid } from "@util/guid";
import { Store } from "@ngrx/store";
import { Injectable } from "@angular/core";
import {
   HubConnectionBuilder,
   HubConnection,
   LogLevel,
   HttpTransportType
} from "@microsoft/signalr";
import { addNotification } from "@store/notification/notification.actions";
import { environment } from "@env/environment";
import { NotificationMessage } from "./notification.model";

const MILLISECONDS = 1000;

@Injectable()
export class NotificationService {
   public connection!: HubConnection;
   public userId!: string;

   constructor(private Store: Store) {
      this.connection = this.buildHubconnection();

      this.connection.on(
         "notificationAdminMessage",
         (message: NotificationMessage) => {
            this.Store.dispatch(addNotification(message));
         }
      );

      this.connection.on(
         "notificationMessage",
         (message: NotificationMessage) => {
            this.Store.dispatch(addNotification(message));
         }
      );
   }

   connect(idUser: string): void {
      this.userId = idUser;
      this.connection.onclose(() =>
         setTimeout(() => this.connectUser(this.userId), MILLISECONDS * 5)
      );
      this.connectUser(idUser);
   }

   connectUser(idUser: string): void {
      this.connection
         .start()
         .then((response: any) => {
            this.connection.send("register", this.userId);
         })
         .catch((err) => {});
   }

   disconnect() {
      if (this.connection.state === "Connected") {
         this.connection.send("leave", this.userId);
      }
   }

   private buildHubconnection(): HubConnection {
      let hubConnection = new HubConnectionBuilder()
         .configureLogging(LogLevel.None)
         .withUrl(`${environment.baseAuthURL}notification`, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
         })
         .build();

      hubConnection.serverTimeoutInMilliseconds = MILLISECONDS * 60 * 5;

      return hubConnection;
   }
}
