import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataComponent } from "@app/modules_new/common/components/data";

@Component({
   selector: "forms-checkbox",
   templateUrl: "./checkbox.component.html",
   styleUrls: ["./checkbox.component.scss"],
   standalone: true,
   imports: [NgIf, FormsModule, ReactiveFormsModule]
})
export class CheckboxFormComponent extends DataComponent {
   @Input() form!: FormControl;
   @Input() placeholder!: string;
   @Input() disabled = false;
   @Input() row!: any;
   @Output() changed = new EventEmitter();

   withoutFormValue = false;

   onChange(value: string) {
      if (this.form) {
         this.form.setValue(value);
         this.changed.emit(value);
      } else {
         this.changed.emit(this.withoutFormValue);
      }
   }

   changeValue(value: boolean) {
      this.withoutFormValue = value;
   }
}
