import { Injectable } from '@angular/core';
import { Guid } from '@app/util/guid';
import { AdminBaseAuthService } from './base-auth.service';
import { AdminHttpAuthService } from './http-auth.service';

@Injectable({
    providedIn: 'root'
})
export class AdminUserAuth extends AdminBaseAuthService {

  constructor(HttpAuth: AdminHttpAuthService) {
      super(HttpAuth, 'v1/admin/user');
  }

  putWithTenant(body: Object, idUser?: string,  tenantId? : string): Promise<Object> {
    return this.HttpAuthService.put(body, new Guid(idUser), `api/${this.path}`, tenantId)
  }

  postWithTenant(body: Object, tenantId? : string): Promise<Object> {
    return this.HttpAuthService.post(body,`api/${this.path}`, tenantId)
  }

  resetPassword(body: Object, idUser: string) {
    return this.HttpAuthService.put(body, new Guid(idUser),`api/${this.path}/reset`)
  }
}
