import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { HttpService } from '@app/services/http.service';
import { HttpResponsePageable } from '@app/services/services.types';
import { Guid } from 'src/app/util/guid';
import { GetAllProtocols } from './requisition-report-protocol.types';

@Injectable({
  providedIn: 'root'
})

export class RequisitionReportProtocol extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/report/requisition/protocol');
  }
  override getByPath(queryString: string, path: string, headers?: String[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetAllProtocols>>
  }
}


