<div
    class="contentPage"
    [ngStyle]="marginBottom !== null ? { 'margin-bottom': marginBottom } : {}"
    [ngStyle]="padding !== null ? { padding: padding } : {}"
>
    <div *ngIf="requisitioLayout">
        <div class="flex align-center flex-between">
            <h1 *ngIf="requisitioLayout.value.number">
                Editar Requisição: <b> {{ requisitioLayout.value.number }} </b>
            </h1>
            <h1>{{ title }}</h1>
            <div class="flex align-center gap-6">
                <h1 *ngIf="requisitioLayout.value.reqNumber">
                    Requisição: <b> {{ requisitioLayout.value.reqNumber }} </b>
                </h1>
                <h1>
                    Paciente: <b> {{ requisitioLayout.value.patient }} </b>
                </h1>
                <h1 *ngIf="requisitioLayout.value.date">
                    Data de Nascimento:
                    <b>
                        {{ requisitioLayout.value.date | date : "dd/MM/yyyy" }}
                    </b>
                </h1>
                <h1 *ngIf="requisitioLayout.value.cpf">
                    CPF: <b>{{ requisitioLayout.value.cpf }}</b>
                </h1>
                <a
                    class="btn-reverse btn"
                    (click)="returnPage()"
                    *ngIf="!hideButton"
                    >Voltar</a
                >
            </div>
        </div>
    </div>
    <div
        class="flex align-center flex-between"
        *ngIf="!requisitioLayout"
        [ngStyle]="
            marginBottom !== null ? { 'margin-bottom': marginBottom } : {}
        "
    >
        <div class="titulo" *ngIf="!hideTitle">
            <div class="flex align-center gp-12">
                <h1>
                    {{ title }} <b>{{ analyteName }}</b>
                </h1>
                <b>{{ additionalTitle }}</b>
            </div>
        </div>
        <ng-content select="[header]"></ng-content>
        <forms-button
            *ngIf="!hideButton"
            #button
            (click)="returnPage()"
            classExtra="btn btn-primary"
            [text]="'global.back' | translate"
        ></forms-button>
    </div>
    <ng-content></ng-content>
</div>
