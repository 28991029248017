import { Component, OnInit } from "@angular/core";
import {
   AbstractReport,
   HeaderMapping,
   ReportFilter
} from "@app/modules/reports/reports-model";
import { SwAlSetttings } from "@app/util/swal.settings";
import { GetAllAnalytes } from "@app/services/analyte/analyte.types";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DefaultFormats, LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { Util } from "@app/util/util";
import { RegistrationReportComponent } from "@app/components/form/registration-report/registration-report.component";
import { HttpClient } from "@angular/common/http";
import { TenantAuthService } from "@app/services/auth/tenantAuth/tenant-auth.service";
import { ReportAnalyteService } from "@app/services/report-analyte/report-Analyte.service";
import { SectorService } from "@app/services/sector/sector.service";
@Component({
   selector: "app-analyte-report",
   templateUrl: "./analyte-report.component.html",
   styleUrls: ["./analyte-report.component.scss"]
})
export class AnalyteReportComponent extends AbstractReport implements OnInit {
   override sumRecords!: number;
   filterForm = new UntypedFormGroup({
      code: new UntypedFormControl(null),
      name: new UntypedFormControl(null),
      isOutsourced: new UntypedFormControl("todos"),
      department: new UntypedFormControl("todos"),
      isActive: new UntypedFormControl("todos")
   });

   reportObject: Array<Object> = [];
   resolvedAnalytes: Array<Object> = [];
   index: number = 50;
   page: number = 1;
   total: number = 0;

   sectorOptions: Array<{ label: string; value: string | null }> = [
      { label: "Todos", value: "todos" }
   ];
   isActiveOptions: Array<{ label: string; value: string | null | boolean }> = [
      { label: "Todos", value: "todos" },
      { label: "Somente Ativos", value: true },
      { label: "Somente Inativos", value: false }
   ];
   isOutsourcedOptions: Array<{
      label: string;
      value: string | null | boolean;
   }> = [
      { label: "Todos", value: "todos" },
      { label: "Somente Terceirizados", value: true },
      { label: "Somente Internos", value: false }
   ];

   override reportDescription: string = "Relatório de Cadastro de Exames";
   override reportFilters: ReportFilter[][] = [];

   override headerMapping: HeaderMapping = {
      code: { title: "Código", width: "auto" },
      name: { title: "Descrição", width: "*" },
      isOutsourced: { title: "Terc.", width: "auto" },
      nameInvoice: { title: "Nome para Faturamento", width: "auto" },
      sectorId: { title: "Setor", width: "auto" },
      createdAt: { title: "Criado em", width: "auto" },
      updatedAt: { title: "Atualizado em", width: "auto" },
      isActive: { title: "Ativo", width: "auto" }
   };

   resolveAnalytes: Array<Object> = [
      {
         label: "id",
         method: "",
         retrive: "id",
         after: "",
         before: ""
      },
      {
         label: "Código",
         method: "formatField",
         retrive: "",
         after: "",
         before: "",
         fieldName: "code",
         type: "description"
      },
      {
         label: "Descrição",
         method: "formatField",
         retrive: "",
         after: "",
         before: "",
         fieldName: "name",
         type: "description"
      },
      {
         label: "Nome para Faturamento",
         method: "formatField",
         retrive: "",
         after: "",
         before: "",
         fieldName: "nameInvoice",
         type: "description"
      },
      {
         label: "Setor",
         method: "formatField",
         retrive: "",
         after: "",
         before: "",
         fieldName: "sectorName",
         type: "description"
      },
      {
         label: "Terc.",
         method: "formatField",
         retrive: "",
         after: "",
         before: "",
         fieldName: "isOutsourced",
         type: "boolean"
      },
      {
         label: "Criado em",
         method: "formatField",
         retrive: "",
         after: "",
         before: "",
         fieldName: "createdAt",
         type: "date",
         dateFormat: DefaultFormats.full
      },
      {
         label: "Alterado em",
         method: "formatField",
         retrive: "",
         after: "",
         before: "",
         fieldName: "updatedAt",
         type: "date",
         dateFormat: DefaultFormats.full
      },
      {
         label: "Ativo",
         method: "formatField",
         retrive: "",
         after: "",
         before: "",
         fieldName: "isActive",
         type: "boolean"
      }
   ];

   override receivedData!: GetAllAnalytes[];
   receivedDataForPrint!: object[];

   constructor(
      private _reportAnalyteService: ReportAnalyteService,
      private _sectorService: SectorService,
      private _util: Util,
      private _localeDate: LocalaDatePipe,
      private _http: HttpClient,
      private _tenantAuth: TenantAuthService
   ) {
      super();
   }

   async ngOnInit(): Promise<void> {
      await this.getData();
      await this.getSectors();
   }

   resolveGenericLocal(data: any, columns: any) {
      this.resolvedAnalytes = [];
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.resolvedAnalytes.push(obj);
      });
   }

   override async getData(paramPage?: number[]): Promise<void> {
      if (paramPage) {
         this.page = paramPage[0];
         this.index = paramPage[1];
      }

      const queryObject = {
         code: this.filterForm.controls["code"].value,
         name: this.filterForm.controls["name"].value,
         isOutsourced:
            this.filterForm.controls["isOutsourced"].value === "todos"
               ? null
               : JSON.parse(this.filterForm.controls["isOutsourced"].value),
         isActive:
            this.filterForm.controls["isActive"].value === "todos"
               ? null
               : JSON.parse(this.filterForm.controls["isActive"].value),
         sectorId:
            this.filterForm.controls["sectorId"].value === "todos"
               ? null
               : this.filterForm.controls["sectorId"].value,
         page: this.page,
         index: this.index
      };

      await this._reportAnalyteService
         .getPagination<GetAllAnalytes>(queryObject)
         .then((res) => {
            this.total = res.sumRecords;
            this.receivedData = res.data;
            this.resolveGenericLocal(res.data, this.resolveAnalytes);
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   async getDataForPrint() {
      this.reportObject = [];
      const queryObject = {
         code: this.filterForm.controls["code"].value,
         name: this.filterForm.controls["name"].value,
         isOutsourced:
            this.filterForm.controls["isOutsourced"].value === "todos"
               ? null
               : JSON.parse(this.filterForm.controls["isOutsourced"].value),
         isActive:
            this.filterForm.controls["isActive"].value === "todos"
               ? null
               : JSON.parse(this.filterForm.controls["isActive"].value),
         sectorId:
            this.filterForm.controls["sectorId"].value === "todos"
               ? null
               : this.filterForm.controls["sectorId"].value
      };

      await this._reportAnalyteService
         .getPagination<GetAllAnalytes>(queryObject)
         .then((res) => {
            res.data.forEach((x) => {
               const obj = {
                  code: x.code,
                  name: x.name,
                  isOutsourced: x.isOutsourced ? "Sim" : "Não",
                  nameInvoice: x.nameInvoice,
                  sectorName: x.sectorName,
                  createdAt: x.createdAt,
                  updatedAt: x.updatedAt,
                  isActive: x.isActive ? "Ativo" : "Inativo"
               };
               this.reportObject.push(obj);
            });
            this.sumRecords = res.sumRecords;
            this.receivedDataForPrint = this.reportObject;
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }

   async generatePdf() {
      await this.getDataForPrint();
      this.reportFilters = [];
      var filters = [];
      var filterActive = "Todos";

      if (this.filterForm.controls["isActive"].value != "todos") {
         if (this.filterForm.controls["isActive"].value == "true") {
            filterActive = "Somente Ativos";
         } else {
            filterActive = "Somente Inativos";
         }
      }

      var obj = {
         title: "Ativos: ",
         content: filterActive
      };

      filters.push(obj);

      if (this.filterForm.controls["code"].value) {
         var obj2 = {
            title: "Mnemônico: ",
            content: this.filterForm.controls["code"].value
         };

         filters.push(obj2);
      }

      if (this.filterForm.controls["name"].value) {
         var obj2 = {
            title: "Descrição: ",
            content: this.filterForm.controls["name"].value
         };

         filters.push(obj2);
      }

      if (filters.length == 3) {
         this.reportFilters.push(filters);
         filters = [];
      }

      if (this.filterForm.controls["isOutsourced"].value != "todos") {
         if (this.filterForm.controls["isOutsourced"].value == "true") {
            filterActive = "Somente Terceirizados";
         } else {
            filterActive = "Somente Interno";
         }
         var obj = {
            title: "Exame Terceirizado: ",
            content: filterActive
         };

         filters.push(obj);
      }

      if (filters.length == 3) {
         this.reportFilters.push(filters);
         filters = [];
      }

      if (this.filterForm.controls["sectorId"].value != "todos") {
         var obj2 = {
            title: "Setor: ",
            content: this.filterForm.controls["sectorId"].value
         };

         filters.push(obj2);
      }

      if (filters.length > 0) {
         this.reportFilters.push(filters);
         filters = [];
      }
      const reportComponent = new RegistrationReportComponent(
         this._http,
         this._localeDate,
         this._tenantAuth
      );
      reportComponent.headerMapping = this.headerMapping;
      reportComponent.reportFilters = this.reportFilters;
      reportComponent.receivedData = this.receivedDataForPrint;
      reportComponent.reportDescription = this.reportDescription;
      reportComponent.sumRecords = this.sumRecords;

      await reportComponent.ngOnInit();
   }

   clearForms() {
      this.filterForm.controls["code"].reset();
      this.filterForm.controls["name"].reset();
      this.filterForm.controls["isOutsourced"].reset();
      this.filterForm.controls["sectorId"].reset();
      this.filterForm.controls["isActive"].reset();
   }

   async getSectors(): Promise<void> {
      await this._sectorService
         .getPagination()
         .then((res) => {
            res.data.forEach((sector: any) => {
               const option = {
                  label: sector.description,
                  value: sector.name
               };

               this.sectorOptions.push(option);
            });
         })
         .catch((err) => {
            SwAlSetttings.printError(err);
         });
   }
}
