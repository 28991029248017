import { Component, Input, OnInit, IterableDiffer, IterableDiffers } from '@angular/core';
import { NgModel } from '@angular/forms';
import { PersonService } from 'src/app/services/person.service';

@Component({
    selector: 'input-search-create',
    templateUrl: './input-search.component.html',
    styleUrls: ['./input-search.component.scss']
})
export class InputSearchCreateComponent implements OnInit {

    @Input() type: string = 'text';
    //@ts-ignore
    @Input() form: FormControl;
    @Input() placeholder: string = '';
    @Input() label: string = '';
    @Input() mask: any = '';
    @Input() className: string = '';
    @Input() requiredInput: string = '';
    @Input() tabindexcomponent: string = '';
    @Input() pattern: string = '';
    @Input() options: Array<any> = [];

    OptionSelect: Number = -1;
    //@ts-ignore
    @Input() valueOption: String = '';
    PropValue: String = '';
    classe: string = '';
    notSelect: boolean = true;
    border: boolean = false;

    differ: any;

    constructor(private _personService: PersonService, _differs: IterableDiffers
    ) {
        //@ts-ignore
        this.differ = _differs.find([]).create(null);
    }

    validate(s: String) {
        var rgx = /^[0-9]*\.?[0-9]*$/;
        return s.match(rgx);
    }


    ngDoCheck() {
        this.getName()
        // here you can do what you want on array change
        // you can check for forEachAddedItem or forEachRemovedItem on change object to see the added/removed items
        // Attention: ngDoCheck() is triggered at each binded variable on componenet; if you have more than one in your component, make sure you filter here the one you want.
    }

    onChange(value: String) {
        if (value)
            this.form.setValue(value);
        else
            this.form.setValue('');
    }

    ngOnInit(): void {
        this.classe = this.className + ' input-row';
        // this.get();

    }


    SelectOption(label: String, value: String, index: Number) {
        this.border = false;
        this.OptionSelect = index;
        this.form.setValue(value);
        this.notSelect = false;
    }

    SearchIn() {
        this.border = false;
        this.notSelect = true;
        this.form.setValue(this.valueOption);
    }

    CheckValue() {

    }

    getName() {
        if (this.form.value) {
            const object = this.options.find(x => x.value === this.form.value);
            if (object) {
                this.valueOption =  this.options.find(x => x.value === this.form.value).label;
            }
        }
    }

}
