import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpService } from '../http.service';
import { HttpResponsePageable } from '../services.types';

@Injectable({
  providedIn: 'root'
})
export class SubGroupProductService extends BaseService {

  constructor(httpService: HttpService) {
    super(httpService, 'v1/lab/stock/subGroupProduct');
  }

}
