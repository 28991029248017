import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http.service';
import { BaseService } from '@app/services/base.service';
import { HttpResponsePageable } from '@app/services/services.types';
import { Guid } from '@app/util/guid';
import { GetAllControlMessages } from './message-control.types';

@Injectable({ 
    providedIn: 'root' 
})
export class MessageControlService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/laboratory/messagecontrol');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllControlMessages>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetAllControlMessages>>
  }
}