<div class="contentPage">
    <div class="flex align-center flex-between headerResponsive">
        <div class="flex headerResponsive align-center gap-2">
            <app-button
                (click)="back()"
                [placeholder]="'global.back'"
                [class]="'btn-reverse'"
            ></app-button>
            <h1>
                {{ "requisitionEdit.title" | translate }}
                <b class="orangeFont">{{ requisitionNumber }}</b>
            </h1>
        </div>
        <div class="flex headerResponsive align-center gap-2">
            <h1>
                {{ "requisitionEdit.customer" | translate }}
                <b class="orangeFont">{{
                    formGroupCustomer.controls["name"].value != ""
                        ? formGroupCustomer.controls["name"].value
                        : ("requisitionEdit.notInfo" | translate)
                }}</b>
            </h1>
            <h1>
                {{ "requisitionEdit.birthDate" | translate }}
                <b class="orangeFont">{{
                    formGroupCustomer.controls["birthDate"].value
                        | date : "dd/MM/yyyy"
                }}</b>
            </h1>
            <h1>
                {{ "requisitionEdit.cpf" | translate }}
                <b class="orangeFont">{{
                    formGroupCustomer.controls["cpf"].value != "" &&
                    formGroupCustomer.controls["cpf"].value != null
                        ? formGroupCustomer.controls["cpf"].value
                        : ("requisitionEdit.notInfo" | translate)
                }}</b>
            </h1>
        </div>
    </div>
    <div class="w-100 mt-32">
        <mat-tab-group
            [selectedIndex]="actualPage"
            (selectedTabChange)="changePage($event)"
        >
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex align-center">
                        <h2>{{ "requisitionEdit.titleData" | translate }}</h2>
                    </div>
                </ng-template>
                <div>
                    <div class="contextPage">
                        <div class="box">
                            <div class="flex flex-between mt-32">
                                <h2>
                                    {{
                                        "requisitionEdit.titleGeneralData"
                                            | translate
                                    }}
                                </h2>
                                <input-prop
                                    [form]="
                                        formGroupCustomer.controls[
                                            'arrivalTime'
                                        ]
                                    "
                                    [placeholder]="
                                        'requisitionEdit.arrivalTime'
                                    "
                                    type="time"
                                ></input-prop>
                            </div>
                            <div class="flex gap-form mt-32">
                                <input-prop
                                    class="w-100"
                                    [placeholder]="'customer.register.name'"
                                    [form]="formGroupCustomer.controls['name']"
                                ></input-prop>
                                <select-prop
                                    class="w-100"
                                    [placeholder]="
                                        'customer.register.biological-sex'
                                    "
                                    [form]="
                                        formGroupCustomer.controls['genderType']
                                    "
                                    [options]="genderOptions"
                                ></select-prop>
                                <input-prop
                                    class="w-100"
                                    [placeholder]="
                                        'customer.register.birth-date'
                                    "
                                    [disableFutureDate]="true"
                                    mask="00/00/0000"
                                    type="date"
                                    [form]="
                                        formGroupCustomer.controls['birthDate']
                                    "
                                    required
                                ></input-prop>
                            </div>
                            <div class="flex gap-form mt-32">
                                <input-prop
                                    class="w-100"
                                    [placeholder]="
                                        'customer.register.mother-name'
                                    "
                                    [form]="
                                        formGroupCustomer.controls['motherName']
                                    "
                                ></input-prop>
                                <input-prop
                                    class="w-100"
                                    [placeholder]="
                                        'customer.register.name-responsible'
                                    "
                                    [form]="
                                        formGroupCustomer.controls[
                                            'nameResponsible'
                                        ]
                                    "
                                ></input-prop>
                                <input-prop
                                    class="w-100"
                                    [placeholder]="
                                        'customer.register.social-name'
                                    "
                                    [form]="
                                        formGroupCustomer.controls['socialName']
                                    "
                                ></input-prop>
                            </div>
                            <div class="flex gap-form mt-32">
                                <input-prop
                                    class="w-100"
                                    [placeholder]="
                                        'requisitionEdit.formAttendanceExternal'
                                    "
                                    [form]="formAttendanceExternal"
                                ></input-prop>
                                <input-prop
                                    class="w-100"
                                    [placeholder]="
                                        'requisitionEdit.formRequestExternal'
                                    "
                                    [form]="formRequestExternal"
                                ></input-prop>
                                <div class="w-100"></div>
                            </div>
                            <div class="gap-form mt-32">
                                <h2>
                                    {{
                                        "requisitionEdit.titleSector"
                                            | translate
                                    }}
                                </h2>
                                <div class="flex gap-form mt-32">
                                    <input-prop
                                        class="w-50"
                                        [placeholder]="
                                            'requisitionEdit.sectorExternalId'
                                        "
                                        [form]="
                                            formGroupHospitalInformation
                                                .controls['sectorExternalId']
                                        "
                                        (keydown.enter)="
                                            searchSector(
                                                formGroupHospitalInformation
                                                    .controls[
                                                    'sectorExternalId'
                                                ].value,
                                                true
                                            )
                                        "
                                        (keydown.tab)="
                                            searchSector(
                                                formGroupHospitalInformation
                                                    .controls[
                                                    'sectorExternalId'
                                                ].value,
                                                true,
                                                true
                                            )
                                        "
                                    ></input-prop>
                                    <input-search
                                        class="w-100"
                                        [placeholder]="
                                            'requisitionEdit.sectorId'
                                        "
                                        [options]="sectorOption"
                                        [form]="
                                            formGroupHospitalInformation
                                                .controls['sectorId']
                                        "
                                        (changeForm)="
                                            searchSector(
                                                formGroupHospitalInformation
                                                    .controls['sectorId'].value,
                                                false
                                            )
                                        "
                                    ></input-search>
                                </div>
                                <div class="flex gap-form mt-32">
                                    <input-prop
                                        class="w-50"
                                        [placeholder]="
                                            'requisitionEdit.recordHospital'
                                        "
                                        [form]="
                                            formGroupHospitalInformation
                                                .controls['recordHospital']
                                        "
                                    ></input-prop>
                                    <div class="w-100 flex gap-form">
                                        <input-prop
                                            class="w-100"
                                            [placeholder]="
                                                'requisitionEdit.roomHospital'
                                            "
                                            [form]="
                                                formGroupHospitalInformation
                                                    .controls['roomHospital']
                                            "
                                        ></input-prop>
                                        <input-prop
                                            class="w-100"
                                            [placeholder]="
                                                'requisitionEdit.bedHospital'
                                            "
                                            [form]="
                                                formGroupHospitalInformation
                                                    .controls['bedHospital']
                                            "
                                        ></input-prop>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-32 w-100">
                                <h2>
                                    {{
                                        "requisitionEdit.titleIdentificationDocuments"
                                            | translate
                                    }}
                                </h2>
                                <div class="flex gap-2 mt-32">
                                    <input-prop
                                        class="w-100"
                                        [placeholder]="'customer.register.rg'"
                                        [form]="
                                            formGroupCustomer.controls['rg']
                                        "
                                    ></input-prop>
                                    <input-prop
                                        class="w-100"
                                        [placeholder]="'customer.register.cpf'"
                                        mask="000.000.000-00"
                                        [form]="
                                            formGroupCustomer.controls['cpf']
                                        "
                                    ></input-prop>
                                    <div class="flex align-center w-100">
                                        <checkbox-prop
                                            [gap]="'1'"
                                            [label]="
                                                'customer.register.document-responsible'
                                            "
                                            [form]="
                                                formGroupCustomer.controls[
                                                    'cpfIsResponsible'
                                                ]
                                            "
                                        ></checkbox-prop>
                                    </div>
                                </div>
                                <div class="flex gap-2 mt-32">
                                    <input-prop
                                        class="w-100"
                                        [placeholder]="'customer.register.cns'"
                                        [form]="
                                            formGroupCustomer.controls['cns']
                                        "
                                    ></input-prop>
                                    <input-prop
                                        class="w-100"
                                        [placeholder]="
                                            'customer.register.passport'
                                        "
                                        [form]="
                                            formGroupCustomer.controls[
                                                'passportNumber'
                                            ]
                                        "
                                    ></input-prop>
                                    <div class="w-100"></div>
                                </div>
                            </div>
                            <div class="mt-32">
                                <h2>
                                    {{
                                        "requisitionEdit.titleContactDetails"
                                            | translate
                                    }}
                                </h2>
                                <div class="flex gap-form mt-32">
                                    <input-prop
                                        class="w-100"
                                        [placeholder]="
                                            'customer.register.cellphone'
                                        "
                                        mask="(00) 0 0000-0000"
                                        [form]="
                                            formGroupCustomer.controls[
                                                'cellPhone'
                                            ]
                                        "
                                    ></input-prop>
                                    <input-prop
                                        class="w-100"
                                        [placeholder]="
                                            'customer.register.residential-phone'
                                        "
                                        mask="(00) 0000-0000"
                                        [form]="
                                            formGroupCustomer.controls[
                                                'residencePhone'
                                            ]
                                        "
                                    ></input-prop>
                                    <input-prop
                                        class="w-100"
                                        [placeholder]="
                                            'customer.register.email'
                                        "
                                        [form]="
                                            formGroupCustomer.controls['email']
                                        "
                                        [type]="'email'"
                                    ></input-prop>
                                </div>
                            </div>
                            <h2 class="mt-32">
                                {{ "requisitionEdit.titleAddress" | translate }}
                            </h2>
                            <div class="flex gap-form mt-32">
                                <input-prop
                                    class="w-100"
                                    class="w-100"
                                    (focusout)="getCep()"
                                    [placeholder]="'customer.register.zip-code'"
                                    [form]="formGroupCustomer.controls['CEP']"
                                    mask="00000-000"
                                ></input-prop>
                                <input-prop
                                    class="w-100"
                                    [placeholder]="'customer.register.address'"
                                    [form]="
                                        formGroupCustomer.controls['address']
                                    "
                                ></input-prop>
                                <input-prop
                                    class="w-100"
                                    [placeholder]="'customer.register.number'"
                                    [form]="
                                        formGroupCustomer.controls['number']
                                    "
                                    type="number"
                                ></input-prop>
                            </div>
                            <div class="flex gap-form mt-32">
                                <input-prop
                                    class="w-100"
                                    [placeholder]="
                                        'customer.register.complement'
                                    "
                                    [form]="
                                        formGroupCustomer.controls['complement']
                                    "
                                ></input-prop>
                                <input-prop
                                    class="w-100"
                                    [placeholder]="
                                        'customer.register.neighborhood'
                                    "
                                    [form]="
                                        formGroupCustomer.controls['district']
                                    "
                                ></input-prop>
                                <input-prop
                                    class="w-100"
                                    [placeholder]="'customer.register.city'"
                                    [form]="formGroupCustomer.controls['city']"
                                ></input-prop>
                            </div>
                            <div class="flex gap-form mt-32">
                                <input-prop
                                    class="w-100"
                                    [placeholder]="'customer.register.state'"
                                    [form]="formGroupCustomer.controls['state']"
                                ></input-prop>
                                <input-prop
                                    class="w-100"
                                    [placeholder]="'customer.register.country'"
                                    [form]="
                                        formGroupCustomer.controls['country']
                                    "
                                ></input-prop>
                                <div class="w-100"></div>
                            </div>
                        </div>
                        <div class="box mt-32">
                            <h2>
                                {{ "requisitionEdit.titleCompany" | translate }}
                            </h2>
                            <div class="mt-32">
                                <select-prop
                                    [placeholder]="
                                        'requisitionEdit.companyRequisition'
                                    "
                                    [form]="formGroupCompany.controls['id']"
                                    (changeValue)="getUnities()"
                                    [options]="companyOptions"
                                ></select-prop>
                            </div>
                        </div>
                        <div class="box mt-32">
                            <h2>
                                {{ "requisitionEdit.titleUnit" | translate }}
                            </h2>
                            <div class="mt-32">
                                <select-prop
                                    [placeholder]="
                                        'requisitionEdit.unitRequisition'
                                    "
                                    [form]="formGroupUnity.controls['id']"
                                    [options]="unityOptions"
                                ></select-prop>
                            </div>
                        </div>
                        <div class="box mt-32">
                            <h2>
                                {{
                                    "requisitionEdit.titleColectionPlace"
                                        | translate
                                }}
                            </h2>
                            <div class="mt-32">
                                <select-prop
                                    [placeholder]="
                                        'requisitionEdit.colectionPlaceRequisition'
                                    "
                                    [form]="
                                        formGroupCollectionPlace.controls['id']
                                    "
                                    [options]="collectionPlaceOptions"
                                ></select-prop>
                            </div>
                        </div>
                        <div class="box mt-32">
                            <h2>
                                {{
                                    "requisitionEdit.titleClinicalInformation"
                                        | translate
                                }}
                            </h2>
                            <div class="mt-32">
                                <input-prop
                                    [placeholder]="
                                        'requisitionEdit.formClinicalInformation'
                                    "
                                    [form]="formClinicalInformation"
                                ></input-prop>
                            </div>
                        </div>
                        <div class="mt-32 w-100 box">
                            <h2>
                                {{
                                    "customer.register.additional-information"
                                        | translate
                                }}
                            </h2>
                            <div
                                class="flex flex-between mt-32 w-100 gap-2 checkboxResponsive"
                            >
                                <checkbox-prop
                                    [gap]="'1'"
                                    [label]="
                                        'customer.register.send-email-result'
                                    "
                                    [form]="
                                        formGroupCustomer.controls[
                                            'sendEmailResult'
                                        ]
                                    "
                                ></checkbox-prop>
                                <checkbox-prop
                                    [form]="
                                        formGroupCustomer.controls[
                                            'sendSmsEmail'
                                        ]
                                    "
                                    class="flex flex-center"
                                    [gap]="1"
                                    [label]="'customer.register.send-sms-email'"
                                ></checkbox-prop>
                            </div>
                            <h2 class="flex mt-32">
                                {{
                                    "customer.register.medicines-used"
                                        | translate
                                }}
                            </h2>
                            <div class="flex flex-between mt-32">
                                <checkbox-prop
                                    [form]="
                                        formGroupCustomer.controls[
                                            'continuousMedication'
                                        ]
                                    "
                                    class="flex flex-center"
                                    [gap]="1"
                                    [label]="
                                        'customer.register.continuous-medication'
                                    "
                                ></checkbox-prop>
                                <div
                                    *ngIf="
                                        formGroupCustomer.controls[
                                            'continuousMedication'
                                        ].value
                                    "
                                >
                                    <app-button
                                        (click)="openModalMedication()"
                                        [placeholder]="
                                            'customer.register.add-medicines'
                                        "
                                        [class]="'flex gap-2 align-center'"
                                    ></app-button>
                                </div>
                            </div>
                            <app-table
                                *ngIf="
                                    formGroupCustomer.controls[
                                        'continuousMedication'
                                    ].value
                                "
                                [noShadow]="true"
                                [pagination]="true"
                                [backPagination]="true"
                                (changePage)="getMedications($event)"
                                (parentFun)="actions($event)"
                                [ClickInItem]="ActionsExtraMedication"
                                [Actions]="['Editar', 'Excluir']"
                                [Itens]="medicationsCustomer"
                                [sumRecords]="paginationMedicines.sumRecords"
                                [indexNumber]="paginationMedicines.index"
                                [pageNumber]="paginationMedicines.page"
                            ></app-table>
                        </div>
                        <div class="grid grid-center mt-32">
                            <app-button
                                (click)="updateRequisition()"
                                [placeholder]="'requisitionEdit.btnUpdateData'"
                                [class]="'flex gap-2 align-center'"
                            ></app-button>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab class="">
                <ng-template mat-tab-label>
                    <div class="flex align-center">
                        <h2>
                            {{
                                "requisitionMedicalRequest.request" | translate
                            }}
                        </h2>
                    </div>
                </ng-template>
                <div *ngIf="type === EType.VISUALIZAR" class="contextPage">
                    <app-table
                        [noShadow]="true"
                        [Actions]="['Editar', 'Excluir', 'Guia TISS']"
                        [Pagination]="false"
                        [Dropdown]="true"
                        [backPagination]="false"
                        [Itens]="registerAgreementRequest"
                        [ClickInItem]="ActionsExtraRequest"
                        (parentFun)="agreementRequestActions($event)"
                    >
                        <ng-template let-rowDetail #header>
                            <div class="flex flex-column gap-2">
                                <h2>
                                    {{
                                        "requisitionMedicalRequest.exam"
                                            | translate
                                    }}
                                </h2>
                                <app-table
                                    [noShadow]="true"
                                    [Svg]="'error_outline'"
                                    [Pagination]="false"
                                    [backPagination]="false"
                                    [Actions]="['Excluir']"
                                    [ClickInItem]="ActionsExtraExamsRequest"
                                    [Itens]="exams[rowDetail.id]"
                                    (parentFun)="
                                        agreementRequestExamsActions(
                                            $event,
                                            rowDetail.id
                                        )
                                    "
                                ></app-table>
                            </div>
                        </ng-template>
                    </app-table>
                    <div
                        style="background: white !important"
                        class="flex flex-center mt-32"
                    >
                        <app-button
                            (click)="newAgreementRequest()"
                            [placeholder]="
                                'requisitionMedicalRequest.btnNewRequest'
                            "
                            [matIcon]="'add'"
                            [class]="'flex gap-2 align-center'"
                        ></app-button>
                    </div>
                </div>
                <div class="boxform">
                    <div *ngIf="type === EType.EDITAR" class="contextPage">
                        <div class="flex align-center gap-2 form550">
                            <app-button
                                (click)="backToVisualization()"
                                [placeholder]="
                                    'requisitionMedicalRequest.btnBack'
                                "
                                [class]="'flex gap-2 align-center btn-reverse'"
                            ></app-button>
                            <h2>
                                {{
                                    "requisitionMedicalRequest.orderEdit"
                                        | translate
                                }}
                            </h2>
                        </div>
                        <div class="box mt-32">
                            <div class="flex align-center w-100 flex-between">
                                <h2>
                                    {{
                                        "requisitionMedicalRequest.agreement"
                                            | translate
                                    }}
                                </h2>
                                <checkbox-prop
                                    *ngIf="isInvoiced"
                                    [gap]="1"
                                    [label]="
                                        'requisitionMedicalRequest.dataGuide'
                                    "
                                    [form]="tiss"
                                ></checkbox-prop>
                            </div>
                            <div
                                class="flex flex-direction-column-mobile mt-32 gap-2"
                            >
                                <div class="flex align-center w-100 gap-form">
                                    <input-prop
                                        #focusCode
                                        class="w-code"
                                        [placeholder]="
                                            'requisitionMedicalRequest.code'
                                        "
                                        [form]="
                                            formGroupAgreementRequest.controls[
                                                'codeAgreement'
                                            ]
                                        "
                                        (keydown.enter)="
                                            searchAgreement(
                                                formGroupAgreementRequest
                                                    .controls['codeAgreement']
                                                    .value,
                                                true
                                            )
                                        "
                                        (keydown.tab)="
                                            searchAgreement(
                                                formGroupAgreementRequest
                                                    .controls['codeAgreement']
                                                    .value,
                                                true
                                            )
                                        "
                                    ></input-prop>
                                    <input-search
                                        (search)="searchAgreement($event.value)"
                                        [searchApi]="true"
                                        [class]="'w-100'"
                                        [options]="agreementOptions"
                                        [placeholder]="
                                            'requisitionMedicalRequest.selectAgreement'
                                        "
                                        [form]="
                                            formGroupAgreementRequest.controls[
                                                'agreement'
                                            ]
                                        "
                                        (changeForm)="
                                            checkControlPlan(
                                                formGroupAgreementRequest
                                                    .controls['agreement'].value
                                            )
                                        "
                                    ></input-search>
                                </div>
                                <div
                                    *ngIf="agreementControlPlan"
                                    class="flex align-center w-100 gap-2"
                                >
                                    <input-search
                                        [class]="'w-100'"
                                        [options]="agremeentPlanOptions"
                                        [placeholder]="
                                            'requisitionMedicalRequest.plan'
                                        "
                                        [form]="
                                            formGroupAgreementRequest.controls[
                                                'plan'
                                            ]
                                        "
                                    ></input-search>
                                    <mat-icon
                                        (click)="
                                            checkControlPlan(
                                                formGroupAgreementRequest
                                                    .controls['agreement'].value
                                            )
                                        "
                                        >refresh</mat-icon
                                    >
                                </div>
                            </div>
                            <div class="flex mt-32 gap-2 form1250">
                                <div class="flex gap-form w-100">
                                    <div class="w-card">
                                        <input-prop
                                            [maxlength]="
                                                attendanceCardMaximumLength
                                            "
                                            [minlength]="
                                                attendanceCardMinimumLength
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.cardNumber'
                                            "
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls['cardNumber']
                                            "
                                        ></input-prop>
                                    </div>
                                    <div class="w-card-alidity">
                                        <input-prop
                                            [placeholder]="
                                                'requisitionMedicalRequest.cardValidity'
                                            "
                                            type="date"
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls['cardValidity']
                                            "
                                        ></input-prop>
                                    </div>
                                    <div class="w-guide">
                                        <input-prop
                                            [maxlength]="
                                                attendanceGuideMaximumLength
                                            "
                                            [minlength]="
                                                attendanceGuideMinimumLength
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.guide'
                                            "
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls['code']
                                            "
                                        ></input-prop>
                                    </div>
                                    <div class="w-agreement-date">
                                        <input-prop
                                            [placeholder]="
                                                'requisitionMedicalRequest.guideValidity'
                                            "
                                            type="date"
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls['guideValidity']
                                            "
                                        ></input-prop>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-center align-center gap-form"
                                >
                                    <div class="w-authorization">
                                        <input-prop
                                            [maxlength]="
                                                authorizationMaximumLength
                                            "
                                            [minlength]="
                                                authorizationMinimumLength
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.authorization'
                                            "
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls['authorization']
                                            "
                                        ></input-prop>
                                    </div>
                                    <div class="w-cid">
                                        <input-prop
                                            [placeholder]="
                                                'requisitionMedicalRequest.cid'
                                            "
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls['cid']
                                            "
                                        ></input-prop>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="tiss.value" class="mt-32">
                                <h2>{{ "global.action.guide" | translate }}</h2>
                                <div class="w-100 mt-32 gap-2">
                                    <div
                                        class="flex align-center w-100 gap-form mt-32"
                                    >
                                        <input-prop
                                            class="w-100"
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls['mainCode']
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.mainCode'
                                            "
                                        ></input-prop>
                                        <input-prop
                                            class="w-100"
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls['emissionDate']
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.emissionDate'
                                            "
                                            type="date"
                                        ></input-prop>
                                        <input-prop
                                            class="w-100"
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls['healthCareCode']
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.healthCareCode'
                                            "
                                        ></input-prop>
                                        <input-prop
                                            class="w-100"
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls[
                                                    'clinicalIndication'
                                                ]
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.clinicalIndication'
                                            "
                                        ></input-prop>
                                    </div>
                                    <div
                                        class="flex align-center w-100 gap-form mt-32"
                                    >
                                        <select-prop
                                            class="w-100"
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls['typeAttendance']
                                            "
                                            [options]="typeAttendance"
                                            [placeholder]="
                                                'requisitionMedicalRequest.typeAttendance'
                                            "
                                        ></select-prop>
                                        <select-prop
                                            class="w-100"
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls['typeOut']
                                            "
                                            [options]="typeOut"
                                            [placeholder]="
                                                'requisitionMedicalRequest.typeOut'
                                            "
                                        ></select-prop>
                                        <select-prop
                                            class="w-100"
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls[
                                                    'accidentIndication'
                                                ]
                                            "
                                            [options]="accidentIndication"
                                            [placeholder]="
                                                'requisitionMedicalRequest.accidentIndication'
                                            "
                                        ></select-prop>
                                        <select-prop
                                            class="w-100"
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls[
                                                    'typeCharacterService'
                                                ]
                                            "
                                            [options]="typeCharacterService"
                                            [placeholder]="
                                                'requisitionMedicalRequest.typeCharacterService'
                                            "
                                        ></select-prop>
                                    </div>
                                    <div
                                        class="flex align-center w-100 gap-form mt-32"
                                    >
                                        <textarea-prop
                                            class="w-100"
                                            [form]="
                                                formGroupAgreementRequest
                                                    .controls['tissObservation']
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.tissObservation'
                                            "
                                        ></textarea-prop>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-32 box">
                            <h2>
                                {{
                                    "requisitionMedicalRequest.requester.titleRequester"
                                        | translate
                                }}
                            </h2>
                            <div
                                class="w-100 mt-32 flex align-center gap-2 form700"
                            >
                                <div class="w-code">
                                    <input-prop
                                        #focusAdvice
                                        class="w-code"
                                        [placeholder]="
                                            'requisitionMedicalRequest.requester.codeDoctor'
                                        "
                                        [form]="
                                            formGroupAgreementRequest.controls[
                                                'codeDoctor'
                                            ]
                                        "
                                        (keydown.enter)="
                                            searchDoctor(
                                                formGroupAgreementRequest
                                                    .controls['codeDoctor']
                                                    .value,
                                                true
                                            )
                                        "
                                        (keydown.tab)="
                                            searchDoctor(
                                                formGroupAgreementRequest
                                                    .controls['codeDoctor']
                                                    .value,
                                                true
                                            )
                                        "
                                    ></input-prop>
                                </div>
                                <div [class]="'flex gap-1 align-center w-100'">
                                    <input-search
                                        [searchApi]="true"
                                        [class]="'w-100'"
                                        (search)="searchDoctor($event.value)"
                                        [options]="doctorOptions"
                                        [placeholder]="
                                            'requisitionMedicalRequest.requester.doctorId'
                                        "
                                        [form]="
                                            formGroupAgreementRequest.controls[
                                                'doctorId'
                                            ]
                                        "
                                        (changeForm)="
                                            setDoctorCode(
                                                formGroupAgreementRequest
                                                    .controls['doctorId'].value
                                            )
                                        "
                                        (keydown.tab)="
                                            searchDoctor($event.value)
                                        "
                                    ></input-search>
                                    <checkbox-prop
                                        class="checkbox-delivery"
                                        [gap]="1"
                                        [label]="
                                            'requisitionMedicalRequest.requester.deliveryRelease'
                                        "
                                        [form]="
                                            formGroupAgreementRequest.controls[
                                                'deliveryRelease'
                                            ]
                                        "
                                    ></checkbox-prop>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-center mt-32">
                            <app-button
                                (keydown.enter)="
                                    updateRequisitionAgreementRequest()
                                "
                                (click)="updateRequisitionAgreementRequest()"
                                [placeholder]="
                                    'requisitionMedicalRequest.requester.btnUpdateRequest'
                                "
                                [class]="'flex gap-2 align-center'"
                            ></app-button>
                        </div>

                        <div class="mt-32 box">
                            <h2>
                                {{
                                    "requisitionMedicalRequest.addExam.titleExam"
                                        | translate
                                }}
                            </h2>
                            <div class="flex gap-2 w-100 mt-32 form1250">
                                <div class="flex gap-2 form1250 form50">
                                    <div class="flex w-100 gap-2 form850">
                                        <input-prop
                                            #codeAnalyte
                                            class="w-mnemonico"
                                            [placeholder]="
                                                'requisitionMedicalRequest.addExam.code'
                                            "
                                            [form]="
                                                formIncludeAnalyte.controls[
                                                    'code'
                                                ]
                                            "
                                            (change)="
                                                getAnalyteByCode(
                                                    formIncludeAnalyte.controls[
                                                        'code'
                                                    ].value
                                                )
                                            "
                                        ></input-prop>
                                        <input-search
                                            class="w-100"
                                            [searchApi]="true"
                                            (search)="
                                                searchAnalyte($event.value)
                                            "
                                            [options]="examOptions"
                                            [placeholder]="
                                                'requisitionMedicalRequest.addExam.analyteId'
                                            "
                                            [form]="
                                                formIncludeAnalyte.controls[
                                                    'analyteId'
                                                ]
                                            "
                                            (changeForm)="
                                                getCodeByAnalyte(
                                                    formIncludeAnalyte.controls[
                                                        'analyteId'
                                                    ].value,
                                                    i,
                                                    true
                                                )
                                            "
                                        ></input-search>
                                    </div>
                                </div>
                                <div
                                    class="flex gap-2 flex-center w-material form850"
                                >
                                    <input-search
                                        class="w-100"
                                        [options]="
                                            getAnalyteMaterialOptions(
                                                formIncludeAnalyte.controls[
                                                    'analyteId'
                                                ].value
                                            )
                                        "
                                        [placeholder]="
                                            'requisitionMedicalRequest.addExam.materialId'
                                        "
                                        [form]="
                                            formIncludeAnalyte.controls[
                                                'materialId'
                                            ]
                                        "
                                        (keydown.enter)="
                                            createAgreementExamsRequest()
                                        "
                                    ></input-search>
                                </div>
                            </div>
                            <div class="flex gap-2 mt-32 align-center form850">
                                <input-search
                                    class="w-100"
                                    [options]="collectionPlaceOriginOptions"
                                    [placeholder]="
                                        'requisitionMedicalRequest.addExam.collectionPlaceOriginId'
                                    "
                                    [form]="
                                        formIncludeAnalyte.controls[
                                            'collectionPlaceOriginId'
                                        ]
                                    "
                                ></input-search>
                                <input-search
                                    class="w-100"
                                    [options]="
                                        collectionPlaceInstructionOptions
                                    "
                                    [placeholder]="
                                        'requisitionMedicalRequest.addExam.collectionPlaceInstructionId'
                                    "
                                    [form]="
                                        formIncludeAnalyte.controls[
                                            'collectionPlaceInstructionId'
                                        ]
                                    "
                                ></input-search>
                                <checkbox-prop
                                    class="w-deve"
                                    [gap]="1"
                                    [label]="
                                        'requisitionMedicalRequest.addExam.requestPending'
                                    "
                                    [form]="
                                        formIncludeAnalyte.controls[
                                            'requestPending'
                                        ]
                                    "
                                ></checkbox-prop>
                            </div>
                            <div class="flex flex-center mt-32">
                                <app-button
                                    (click)="createAgreementExamsRequest()"
                                    [placeholder]="
                                        'requisitionMedicalRequest.addExam.btnAddExam'
                                    "
                                    [class]="'flex gap-2 align-center'"
                                ></app-button>
                            </div>
                        </div>

                        <div class="mt-32 box">
                            <h2>
                                {{
                                    "requisitionMedicalRequest.exam" | translate
                                }}
                            </h2>
                            <app-table
                                [noShadow]="true"
                                [Actions]="['Editar', 'Excluir']"
                                [Pagination]="true"
                                [backPagination]="false"
                                [Itens]="registerAgreementExamsRequest"
                                (parentFun)="
                                    agreementRequestExamsActionsList($event)
                                "
                            ></app-table>
                        </div>
                    </div>
                </div>
                <div *ngIf="type === EType.NOVO" class="contextPage">
                    <div class="boxform">
                        <div class="flex align-center gap-2 form550">
                            <app-button
                                (click)="backToVisualization()"
                                [placeholder]="
                                    'requisitionMedicalRequest.btnBack'
                                "
                                [class]="'flex gap-2 align-center btn-reverse'"
                            ></app-button>
                            <h2>
                                {{
                                    "requisitionMedicalRequest.btnNewRequest"
                                        | translate
                                }}
                            </h2>
                        </div>
                        <div class="box mt-32">
                            <div class="flex align-center w-100 flex-between">
                                <h2>
                                    {{
                                        "requisitionMedicalRequest.agreement"
                                            | translate
                                    }}
                                </h2>
                                <checkbox-prop
                                    *ngIf="isInvoiced"
                                    [gap]="1"
                                    [label]="
                                        'requisitionMedicalRequest.dataGuide'
                                    "
                                    [form]="tiss"
                                ></checkbox-prop>
                            </div>
                            <div
                                class="flex flex-direction-column-mobile mt-32 gap-2"
                            >
                                <div class="flex align-center w-100 gap-form">
                                    <input-prop
                                        [onFocus]="true"
                                        class="w-code"
                                        [placeholder]="
                                            'requisitionMedicalRequest.code'
                                        "
                                        [form]="
                                            formGroupCreateAgreementRequest
                                                .controls['codeAgreement']
                                        "
                                        (keydown.enter)="
                                            searchAgreement(
                                                formGroupCreateAgreementRequest
                                                    .controls['codeAgreement']
                                                    .value,
                                                true
                                            )
                                        "
                                        (keydown.tab)="
                                            searchAgreement(
                                                formGroupCreateAgreementRequest
                                                    .controls['codeAgreement']
                                                    .value,
                                                true
                                            )
                                        "
                                    ></input-prop>
                                    <input-search
                                        [searchApi]="true"
                                        (search)="searchAgreement($event.value)"
                                        class="w-100"
                                        [options]="agreementOptions"
                                        [placeholder]="
                                            'requisitionMedicalRequest.agreement'
                                        "
                                        (changeForm)="
                                            checkControlPlan(
                                                formGroupCreateAgreementRequest
                                                    .controls['agreement'].value
                                            )
                                        "
                                        [form]="
                                            formGroupCreateAgreementRequest
                                                .controls['agreement']
                                        "
                                    ></input-search>
                                </div>
                                <div
                                    *ngIf="agreementControlPlan"
                                    class="flex align-center w-100 gap-2"
                                >
                                    <input-search
                                        [class]="'w-100'"
                                        [options]="agremeentPlanOptions"
                                        [placeholder]="
                                            'requisitionMedicalRequest.plan'
                                        "
                                        [form]="
                                            formGroupCreateAgreementRequest
                                                .controls['plan']
                                        "
                                    ></input-search>
                                    <mat-icon
                                        matTooltip="Atualizar"
                                        (click)="
                                            checkControlPlan(
                                                formGroupCreateAgreementRequest
                                                    .controls['agreement'].value
                                            )
                                        "
                                        >refresh</mat-icon
                                    >
                                </div>
                            </div>
                            <div class="flex mt-32 gap-2 form1250">
                                <div class="flex gap-form w-100">
                                    <div class="w-card">
                                        <input-prop
                                            [placeholder]="
                                                'requisitionMedicalRequest.cardNumber'
                                            "
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls['cardNumber']
                                            "
                                        ></input-prop>
                                    </div>
                                    <div class="w-card-alidity">
                                        <input-prop
                                            [placeholder]="
                                                'requisitionMedicalRequest.cardValidity'
                                            "
                                            type="date"
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls['cardValidity']
                                            "
                                        ></input-prop>
                                    </div>
                                    <div class="w-guide">
                                        <input-prop
                                            [placeholder]="
                                                'requisitionMedicalRequest.guide'
                                            "
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls['code']
                                            "
                                        ></input-prop>
                                    </div>
                                    <div class="w-agreement-date">
                                        <input-prop
                                            [placeholder]="
                                                'requisitionMedicalRequest.guideValidity'
                                            "
                                            type="date"
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls['guideValidity']
                                            "
                                        ></input-prop>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-center align-center gap-form"
                                >
                                    <div class="w-authorization">
                                        <input-prop
                                            [placeholder]="
                                                'requisitionMedicalRequest.authorization'
                                            "
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls['authorization']
                                            "
                                        ></input-prop>
                                    </div>
                                    <div class="w-cid">
                                        <input-prop
                                            [placeholder]="
                                                'requisitionMedicalRequest.cid'
                                            "
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls['cid']
                                            "
                                        ></input-prop>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="tiss.value" class="mt-32">
                                <h2>{{ "global.action.guide" | translate }}</h2>
                                <div class="w-100 mt-32 gap-2">
                                    <div
                                        class="flex align-center w-100 gap-form mt-32"
                                    >
                                        <input-prop
                                            class="w-100"
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls['mainCode']
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.mainCode'
                                            "
                                        ></input-prop>
                                        <input-prop
                                            class="w-100"
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls['emissionDate']
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.emissionDAte'
                                            "
                                            type="date"
                                        ></input-prop>
                                        <input-prop
                                            class="w-100"
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls['healthCareCode']
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.healthCareCode'
                                            "
                                        ></input-prop>
                                        <input-prop
                                            class="w-100"
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls[
                                                    'clinicalIndication'
                                                ]
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.clinicalIndication'
                                            "
                                        ></input-prop>
                                    </div>
                                    <div
                                        class="flex align-center w-100 gap-form mt-32"
                                    >
                                        <select-prop
                                            class="w-100"
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls['typeAttendance']
                                            "
                                            [options]="typeAttendance"
                                            [placeholder]="
                                                'requisitionMedicalRequest.typeAttendance'
                                            "
                                        ></select-prop>
                                        <select-prop
                                            class="w-100"
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls['typeOut']
                                            "
                                            [options]="typeOut"
                                            [placeholder]="
                                                'requisitionMedicalRequest.typeOut'
                                            "
                                        ></select-prop>
                                        <select-prop
                                            class="w-100"
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls[
                                                    'accidentIndication'
                                                ]
                                            "
                                            [options]="accidentIndication"
                                            [placeholder]="
                                                'requisitionMedicalRequest.accidentIndication'
                                            "
                                        ></select-prop>
                                        <select-prop
                                            class="w-100"
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls[
                                                    'typeCharacterService'
                                                ]
                                            "
                                            [options]="typeCharacterService"
                                            [placeholder]="
                                                'requisitionMedicalRequest.typeCharacterService'
                                            "
                                        ></select-prop>
                                    </div>
                                    <div
                                        class="flex align-center w-100 gap-form mt-32"
                                    >
                                        <textarea-prop
                                            class="w-100"
                                            [form]="
                                                formGroupCreateAgreementRequest
                                                    .controls['tissObservation']
                                            "
                                            [placeholder]="
                                                'requisitionMedicalRequest.tissObservation'
                                            "
                                        ></textarea-prop>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-32 box">
                            <h2>
                                {{
                                    "requisitionMedicalRequest.requester.titleRequester"
                                        | translate
                                }}
                            </h2>
                            <div
                                class="w-100 mt-32 flex align-center gap-2 form700"
                            >
                                <div class="w-code">
                                    <input-prop
                                        #focusAdvice
                                        class="w-code"
                                        [placeholder]="
                                            'requisitionMedicalRequest.requester.codeDoctor'
                                        "
                                        [form]="
                                            formGroupCreateAgreementRequest
                                                .controls['codeDoctor']
                                        "
                                        (keydown.enter)="
                                            searchDoctor(
                                                formGroupCreateAgreementRequest
                                                    .controls['codeDoctor']
                                                    .value,
                                                true
                                            )
                                        "
                                        (keydown.tab)="
                                            searchDoctor(
                                                formGroupCreateAgreementRequest
                                                    .controls['codeDoctor']
                                                    .value,
                                                true
                                            )
                                        "
                                    ></input-prop>
                                </div>
                                <div [class]="'flex gap-1 align-center w-100'">
                                    <input-search
                                        [searchApi]="true"
                                        (search)="searchDoctor($event.value)"
                                        [class]="'w-100'"
                                        [options]="doctorOptions"
                                        [placeholder]="
                                            'requisitionMedicalRequest.requester.doctorId'
                                        "
                                        [form]="
                                            formGroupCreateAgreementRequest
                                                .controls['doctorId']
                                        "
                                        (changeForm)="
                                            setDoctorCode(
                                                formGroupCreateAgreementRequest
                                                    .controls['doctorId'].value
                                            )
                                        "
                                    ></input-search>
                                    <checkbox-prop
                                        class="checkbox-delivery"
                                        [gap]="1"
                                        [label]="
                                            'requisitionMedicalRequest.requester.deliveryRelease'
                                        "
                                        [form]="
                                            formGroupCreateAgreementRequest
                                                .controls['deliveryRelease']
                                        "
                                    ></checkbox-prop>
                                </div>
                            </div>
                        </div>
                        <div class="mt-32 box">
                            <h2>
                                {{
                                    "requisitionMedicalRequest.addExam.titleExam"
                                        | translate
                                }}
                            </h2>
                            <div class="flex gap-2 w-100 mt-32 form1250">
                                <div class="flex gap-2 form1250 form50">
                                    <div class="flex w-100 gap-2 form850">
                                        <input-prop
                                            #codeAnalyte
                                            class="w-mnemonico"
                                            [placeholder]="
                                                'requisitionMedicalRequest.addExam.code'
                                            "
                                            [form]="
                                                formIncludeAnalyte.controls[
                                                    'code'
                                                ]
                                            "
                                            (focusout)="
                                                getAnalyteByCode(
                                                    formIncludeAnalyte.controls[
                                                        'code'
                                                    ].value,
                                                    i,
                                                    true
                                                )
                                            "
                                            (valueChangeEmitter)="
                                                mnemonicChange($event)
                                            "
                                        ></input-prop>
                                        <input-search
                                            class="w-100"
                                            [searchApi]="true"
                                            (search)="
                                                searchAnalyte($event.value)
                                            "
                                            [options]="examOptions"
                                            [placeholder]="
                                                'requisitionMedicalRequest.addExam.analyteId'
                                            "
                                            [form]="
                                                formIncludeAnalyte.controls[
                                                    'analyteId'
                                                ]
                                            "
                                            (changeForm)="
                                                getCodeByAnalyte(
                                                    formIncludeAnalyte.controls[
                                                        'analyteId'
                                                    ].value,
                                                    i,
                                                    true
                                                )
                                            "
                                        ></input-search>
                                    </div>
                                </div>
                                <div
                                    class="flex gap-2 flex-center w-material form850"
                                >
                                    <input-search
                                        class="w-100"
                                        [options]="
                                            getAnalyteMaterialOptions(
                                                formIncludeAnalyte.controls[
                                                    'analyteId'
                                                ].value
                                            )
                                        "
                                        [placeholder]="
                                            'requisitionMedicalRequest.addExam.materialId'
                                        "
                                        [form]="
                                            formIncludeAnalyte.controls[
                                                'materialId'
                                            ]
                                        "
                                        (valueChangeEmitter)="
                                            materialChange($event)
                                        "
                                        (keydown.enter)="
                                            createAgreementExamsRequest()
                                        "
                                    ></input-search>
                                </div>
                            </div>
                            <div class="flex gap-2 mt-32 align-center form850">
                                <input-search
                                    class="w-100"
                                    [options]="collectionPlaceOriginOptions"
                                    [placeholder]="
                                        'requisitionMedicalRequest.addExam.collectionPlaceOriginId'
                                    "
                                    [form]="
                                        formIncludeAnalyte.controls[
                                            'collectionPlaceOriginId'
                                        ]
                                    "
                                ></input-search>
                                <input-search
                                    class="w-100"
                                    [options]="
                                        collectionPlaceInstructionOptions
                                    "
                                    [placeholder]="
                                        'requisitionMedicalRequest.addExam.collectionPlaceInstructionId'
                                    "
                                    [form]="
                                        formIncludeAnalyte.controls[
                                            'collectionPlaceInstructionId'
                                        ]
                                    "
                                ></input-search>
                                <checkbox-prop
                                    class="w-deve"
                                    [gap]="1"
                                    [label]="
                                        'requisitionMedicalRequest.addExam.requestPending'
                                    "
                                    [form]="
                                        formGroupCreateAgreementRequest
                                            .controls['requestPending']
                                    "
                                ></checkbox-prop>
                            </div>
                            <div class="flex flex-center mt-32">
                                <app-button
                                    (click)="createAgreementExamsRequest()"
                                    [placeholder]="
                                        'requisitionMedicalRequest.addExam.btnAddExam'
                                    "
                                    [class]="'flex gap-2 align-center'"
                                ></app-button>
                            </div>
                        </div>
                        <div class="mt-32 box">
                            <h2>
                                {{
                                    "requisitionMedicalRequest.exam" | translate
                                }}
                            </h2>
                            <app-table
                                [noShadow]="true"
                                [Actions]="['Editar', 'Excluir']"
                                [Pagination]="true"
                                [backPagination]="false"
                                [Itens]="registerAgreementExamsRequest"
                                (parentFun)="
                                    agreementRequestExamsActionsList($event)
                                "
                            ></app-table>
                        </div>
                    </div>
                    <div class="flex flex-center mt-32">
                        <app-button
                            (click)="createAgreementRequest()"
                            [placeholder]="
                                'requisitionMedicalRequest.addExam.btnCreateAgreementRequest'
                            "
                            [class]="'flex gap-2 align-center'"
                        ></app-button>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex align-center">
                        <h2>{{ "financial.title" | translate }}</h2>
                    </div>
                </ng-template>
                <div class="contextPage">
                    <div
                        *ngIf="!privateAgreement"
                        class="flex flex-column mt-32 align-center"
                    >
                        <h2>
                            {{ "financial.privateAgreementFalse" | translate }}
                        </h2>
                    </div>
                    <div
                        *ngIf="privateAgreement"
                        class="flex flex-column mt-32"
                    >
                        <div class="flex flex-between align-center gap-form">
                            <h2>
                                {{
                                    "financial.privateAgreementTrue" | translate
                                }}
                            </h2>
                            <app-button
                                (click)="
                                    openDialogModalInvoiceProgressHistory()
                                "
                                [placeholder]="'financial.btnProgressHistory'"
                            ></app-button>
                        </div>
                        <div class="flex gap-form">
                            <div
                                class="flex flex-column w-100"
                                *ngIf="typePaymentsList.length > 0"
                            >
                                <div
                                    class="mt-32"
                                    *ngFor="
                                        let typePayment of typePaymentsList;
                                        let i = index
                                    "
                                >
                                    <div class="flex flex-row">
                                        <div
                                            [formGroup]="
                                                formTypePayment[
                                                    typePayment.value
                                                ]
                                            "
                                            class="flex align-center gap-form w-100"
                                        >
                                            <input-prop
                                                (change)="
                                                    formatCurrencyValue(
                                                        formTypePayment[
                                                            typePayment.value
                                                        ].get('amountReceived')
                                                    )
                                                "
                                                [class]="'w-100'"
                                                [placeholder]="
                                                    typePayment.label
                                                "
                                                [form]="
                                                    formTypePayment[
                                                        typePayment.value
                                                    ].get('amountReceived')
                                                "
                                            ></input-prop>
                                            <app-button
                                                *ngIf="
                                                    typePayment.type ===
                                                    ETypePayments.PIX
                                                "
                                                [placeholder]="'Gerar QR Code'"
                                                [class]="'btn'"
                                                style="
                                                    min-width: calc(
                                                        200px *
                                                            var(--proportional)
                                                    );
                                                "
                                            ></app-button>
                                            <input-prop
                                                *ngIf="
                                                    typePayment.type ===
                                                        ETypePayments.CARTAO_CREDITO ||
                                                    typePayment.type ===
                                                        ETypePayments.CARTAO_DEBITO
                                                "
                                                class="w-100"
                                                [form]="
                                                    formTypePayment[
                                                        typePayment.value
                                                    ].get('transactionId')
                                                "
                                                [placeholder]="
                                                    'ID da Transação'
                                                "
                                            ></input-prop>
                                            <input-prop
                                                *ngIf="
                                                    typePayment.type ===
                                                    ETypePayments.CARTAO_CREDITO
                                                "
                                                [class]="'w-100'"
                                                [form]="
                                                    formTypePayment[
                                                        typePayment.value
                                                    ].get('installmentsNumber')
                                                "
                                                [placeholder]="'N.º Parcelas'"
                                            ></input-prop>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="flex flex-column w-100 flex-center"
                                *ngIf="typePaymentsList.length == 0"
                            >
                                <div class="mt-32 flex flex-center">
                                    <mat-icon>warning</mat-icon>
                                </div>
                                <div class="mt-32 flex flex-center">
                                    <label>{{
                                        "financial.doNotPay" | translate
                                    }}</label>
                                </div>
                                <div class="mt-32 flex flex-center">
                                    <a
                                        href="Cadastros/TiposPagamento"
                                        target="_blank"
                                        ><label>{{
                                            "financial.clickRegister"
                                                | translate
                                        }}</label></a
                                    >
                                </div>
                            </div>
                            <mat-divider [vertical]="true"></mat-divider>
                            <div class="flex flex-column w-100 gap-4 mt-32">
                                <div class="flex w-100 align-center gap-form">
                                    <label>{{
                                        "financial.bruteValue" | translate
                                    }}</label>
                                    <input-prop
                                        class="w-100"
                                        [form]="
                                            formPaymentResume.controls[
                                                'bruteValue'
                                            ]
                                        "
                                    ></input-prop>
                                </div>
                                <div class="flex w-100 align-center gap-form">
                                    <label>{{
                                        "financial.addition" | translate
                                    }}</label>
                                    <div class="w-100 flex align-center gap-2">
                                        <input-prop
                                            class="w-100"
                                            [form]="
                                                formPaymentResume.controls[
                                                    'addition'
                                                ]
                                            "
                                        ></input-prop>
                                        <span
                                            (click)="
                                                openDialogModalOperations(
                                                    EInvoiceStatus[6].value
                                                )
                                            "
                                            class="span-styles addition"
                                            ><mat-icon>add</mat-icon></span
                                        >
                                    </div>
                                </div>
                                <div class="flex w-100 align-center gap-form">
                                    <label>{{
                                        "financial.discount" | translate
                                    }}</label>
                                    <div class="w-100 flex align-center gap-2">
                                        <input-prop
                                            class="w-100"
                                            [form]="
                                                formPaymentResume.controls[
                                                    'discount'
                                                ]
                                            "
                                        ></input-prop>
                                        <span
                                            (click)="
                                                openDialogModalOperations(
                                                    EInvoiceStatus[7].value
                                                )
                                            "
                                            class="span-styles discount"
                                            ><mat-icon>remove</mat-icon></span
                                        >
                                    </div>
                                </div>
                                <div class="flex w-100 align-center gap-form">
                                    <label>{{
                                        "financial.balance" | translate
                                    }}</label>
                                    <input-prop
                                        class="w-100"
                                        [form]="
                                            formPaymentResume.controls[
                                                'balance'
                                            ]
                                        "
                                    ></input-prop>
                                </div>
                                <div class="flex w-100 align-center gap-form">
                                    <label>{{
                                        "financial.totalPay" | translate
                                    }}</label>
                                    <input-prop
                                        class="w-100"
                                        [form]="
                                            formPaymentResume.controls[
                                                'totalPay'
                                            ]
                                        "
                                    ></input-prop>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-center mt-32">
                            <app-button
                                (click)="confirmPayment()"
                                [placeholder]="'financial.btnConfirmPayment'"
                                [class]="'flex gap-2 align-center'"
                            ></app-button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex align-center">
                        <h2>{{ "requestResume.title" | translate }}</h2>
                    </div>
                </ng-template>
                <div class="boxform contextPage">
                    <div class="box">
                        <div class="flex flex-between align-center gap-form">
                            <h2>
                                {{
                                    "requestResume.questions.titleRequestQuestions"
                                        | translate
                                }}
                            </h2>
                            <app-button
                                *ngIf="registerQuestions.length > 0"
                                (click)="openDialogModalAllQuestions($event)"
                                [placeholder]="
                                    'requestResume.questions.btnReplyAll'
                                "
                                [class]="'flex gap-2 align-center'"
                            ></app-button>
                        </div>
                        <div class="">
                            <div class="mt-32 flex flex-center">
                                <h2 *ngIf="registerQuestions.length == 0">
                                    {{
                                        "requestResume.questions.notQuestions"
                                            | translate
                                    }}
                                </h2>
                            </div>
                            <app-table
                                [noShadow]="true"
                                *ngIf="registerQuestions.length > 0"
                                [Actions]="['Responder']"
                                [ClickInItem]="ActionsExtraQuestions"
                                [Pagination]="false"
                                [backPagination]="false"
                                [Itens]="registerQuestions"
                                (parentFun)="questionActions($event)"
                            ></app-table>
                        </div>
                    </div>
                    <div
                        class="box mt-32"
                        *ngIf="agreementExamsSupport.length > 0"
                    >
                        <div class="flex align-center flex-between w-100">
                            <div class="flex align-center gap-2">
                                <h2>
                                    {{
                                        "requestResume.ttileSubmissionSupport"
                                            | translate
                                    }}
                                </h2>
                            </div>
                        </div>
                        <div
                            class="box mt-16"
                            *ngFor="
                                let agreementSupport of agreementExamsSupport;
                                let i = index
                            "
                        >
                            <div class="flex flex-between">
                                <h2>{{ agreementSupport.description }}</h2>
                                <checkbox-prop
                                    [label]="'requestResume.markAll'"
                                    [gap]="1"
                                    (click)="
                                        checkboxAll($event, agreementSupport.id)
                                    "
                                ></checkbox-prop>
                            </div>
                            <app-table
                                [ClickInItem]="
                                    ActionsExtraAgreementExamsSupportLaboratory
                                "
                                (parentFun)="
                                    ActionsAgreementExamsSupportLaboratory(
                                        $event
                                    )
                                "
                                [noShadow]="true"
                                [Pagination]="false"
                                [backPagination]="false"
                                [Itens]="agreementSupport.exams"
                            ></app-table>
                        </div>
                        <div class="flex flex-center">
                            <app-button
                                class="mt-32"
                                (click)="sendAgreementSupport()"
                                [placeholder]="'requestResume.btnSend'"
                                [class]="'flex gap-2 align-center'"
                            ></app-button>
                        </div>
                    </div>
                    <div class="box mt-32">
                        <div class="flex align-center flex-between w-100">
                            <div class="flex align-center gap-2">
                                <h2>
                                    {{
                                        "requestResume.collect.title"
                                            | translate
                                    }}
                                </h2>
                            </div>
                            <div class="flex flex-end checkboxColect">
                                <checkbox-prop
                                    [form]="
                                        formCollectResume.controls[
                                            'hasPriority'
                                        ]
                                    "
                                    [gap]="1"
                                    [label]="
                                        'requestResume.collect.priorityCollection'
                                    "
                                ></checkbox-prop>
                            </div>
                        </div>
                        <div
                            *ngIf="resumeCollectExams.length > 0"
                            class="flex w-100 flex-between align-center box mt-16"
                        >
                            <div
                                class="flex gp-12 align-center pointer"
                                (click)="toggleStatusGlobally()"
                            >
                                <h3>
                                    {{
                                        "requestResume.collect.titleSituation"
                                            | translate
                                    }}
                                </h3>
                                <div *ngIf="usesProcessCollect">
                                    <span
                                        *ngIf="examsGlobalSituation == -1"
                                        class="span-styles"
                                        style="
                                            background: #f8f8ff;
                                            color: #000000;
                                        "
                                        >{{
                                            "requestResume.collect.situationDefine"
                                                | translate
                                        }}</span
                                    >
                                    <span
                                        *ngIf="examsGlobalSituation == 0"
                                        class="span-styles"
                                        style="
                                            background: #f8f8ff;
                                            color: #000000;
                                        "
                                        >{{
                                            "requestResume.collect.situationNotCollect"
                                                | translate
                                        }}</span
                                    >
                                    <span
                                        *ngIf="examsGlobalSituation == 1"
                                        class="span-styles"
                                        style="
                                            background: #f8f8ff;
                                            color: #ff0000;
                                        "
                                        >{{
                                            "requestResume.collect.situationAddCollect"
                                                | translate
                                        }}</span
                                    >
                                    <span
                                        *ngIf="examsGlobalSituation == 3"
                                        class="span-styles"
                                        style="
                                            background: #ff0000;
                                            color: #f8f8ff;
                                        "
                                        >{{
                                            "requestResume.collect.situationCollect"
                                                | translate
                                        }}</span
                                    >
                                    <span
                                        *ngIf="examsGlobalSituation == 6"
                                        class="span-styles"
                                        style="
                                            background: #ffff00;
                                            color: #000000;
                                        "
                                        >{{
                                            "requestResume.collect.situationScreen"
                                                | translate
                                        }}</span
                                    >
                                </div>
                                <div *ngIf="!usesProcessCollect">
                                    <span
                                        *ngIf="examsGlobalSituation == -1"
                                        class="span-styles"
                                        style="
                                            background: #f8f8ff;
                                            color: #000000;
                                        "
                                        >{{
                                            "requestResume.collect.situationDefine"
                                                | translate
                                        }}</span
                                    >
                                    <span
                                        *ngIf="examsGlobalSituation == 0"
                                        class="span-styles"
                                        style="
                                            background: #f8f8ff;
                                            color: #000000;
                                        "
                                        >{{
                                            "requestResume.collect.situationNotCollect"
                                                | translate
                                        }}</span
                                    >
                                    <span
                                        *ngIf="examsGlobalSituation == 1"
                                        class="span-styles"
                                        style="
                                            background: #f8f8ff;
                                            color: #ff0000;
                                        "
                                        >{{
                                            "requestResume.collect.situationAddCollect"
                                                | translate
                                        }}</span
                                    >
                                    <span
                                        *ngIf="examsGlobalSituation == 3"
                                        class="span-styles"
                                        style="
                                            background: #ff0000;
                                            color: #f8f8ff;
                                        "
                                        >{{
                                            "requestResume.collect.situationCollected"
                                                | translate
                                        }}</span
                                    >
                                    <span
                                        *ngIf="examsGlobalSituation == 6"
                                        class="span-styles"
                                        style="
                                            background: #ffff00;
                                            color: #000000;
                                        "
                                        >{{
                                            "requestResume.collect.situationSorted"
                                                | translate
                                        }}</span
                                    >
                                </div>
                                <!-- <h3> Situação: </h3>
                <div>
                  <span *ngIf="examsGlobalSituation == -1" class='span-styles' style='background: #F8F8FF; color: #000000;'>Definir</span>
                  <span *ngIf="examsGlobalSituation == 0" class='span-styles' style='background: #F8F8FF; color: #000000;'>Não Coletar</span>
                  <span *ngIf="examsGlobalSituation == 1" class='span-styles' style='background: #F8F8FF; color: #FF0000;'>Incluir Aguardando Coleta</span>
                  <span *ngIf="examsGlobalSituation == 3" class='span-styles' style='background: #FF0000; color: #F8F8FF;'>Coletar</span>
                  <span *ngIf="examsGlobalSituation == 6" class='span-styles' style='background: #FFFF00; color: #000000;'>Triar</span>                
                </div> -->
                            </div>
                            <div
                                class="flex gp-12 align-center pointer"
                                (click)="openSetAnalyteDeadlineModal()"
                            >
                                <h3>
                                    {{
                                        "requestResume.collect.titleDeliveryForecast"
                                            | translate
                                    }}
                                </h3>
                                <span class="span-styles">{{
                                    examsGlobalDeadline
                                }}</span>
                            </div>
                            <div
                                class="flex gp-12 align-center pointer"
                                (click)="toggleRoutineGlobally()"
                            >
                                <h3>
                                    {{
                                        "requestResume.collect.titleCharacterService"
                                            | translate
                                    }}
                                </h3>
                                <span
                                    *ngIf="examsGlobalRoutine == -1"
                                    class="span-styles"
                                    >{{
                                        "requestResume.collect.define"
                                            | translate
                                    }}</span
                                >
                                <span
                                    *ngIf="examsGlobalRoutine == 0"
                                    class="span-styles"
                                    >{{
                                        "requestResume.collect.normal"
                                            | translate
                                    }}</span
                                >
                                <span
                                    *ngIf="examsGlobalRoutine == 1"
                                    class="span-styles"
                                    >{{
                                        "requestResume.collect.urgency"
                                            | translate
                                    }}</span
                                >
                                <span
                                    *ngIf="examsGlobalRoutine == 2"
                                    class="span-styles"
                                    >{{
                                        "requestResume.collect.emergency"
                                            | translate
                                    }}</span
                                >
                            </div>
                        </div>
                        <div class="table">
                            <app-table
                                [noShadow]="true"
                                [Pagination]="false"
                                [Itens]="resumeCollectExams"
                                (parentFun)="examsActions($event)"
                                [ClickInItem]="ActionsExtra"
                            ></app-table>
                            <div class="flex flex-between w-100">
                                <div class="flex align-center gap-2 w-100">
                                    <h2>
                                        {{
                                            "requestResume.collect.titleCheckPreparation"
                                                | translate
                                        }}
                                    </h2>
                                    <svg
                                        (click)="
                                            openDialogModalAllInstructions()
                                        "
                                        style="cursor: pointer"
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.83333 7.33333H5.16667V8.66667H3.83333V7.33333Z"
                                            fill="#404040"
                                        />
                                        <path
                                            d="M6.5 7.33333H11.8333V8.66667H6.5V7.33333Z"
                                            fill="#404040"
                                        />
                                        <path
                                            d="M3.83333 10.6667H5.16667V12H3.83333V10.6667Z"
                                            fill="#404040"
                                        />
                                        <path
                                            d="M3.83333 14H5.16667V15.3333H3.83333V14Z"
                                            fill="#404040"
                                        />
                                        <path
                                            d="M1.83333 17.3333V3.33333H3.83333V5.33333H11.8333V3.33333H13.8333V8.66667H15.1667V3.33333C15.1667 2.97971 15.0262 2.64057 14.7761 2.39052C14.5261 2.14048 14.187 2 13.8333 2H11.8333V1.33333C11.8333 0.979711 11.6929 0.640573 11.4428 0.390524C11.1928 0.140476 10.8536 0 10.5 0H5.16667C4.81304 0 4.47391 0.140476 4.22386 0.390524C3.97381 0.640573 3.83333 0.979711 3.83333 1.33333V2H1.83333C1.47971 2 1.14057 2.14048 0.890524 2.39052C0.640476 2.64057 0.5 2.97971 0.5 3.33333V17.3333C0.5 17.687 0.640476 18.0261 0.890524 18.2761C1.14057 18.5262 1.47971 18.6667 1.83333 18.6667H7.83333V17.3333H1.83333ZM5.16667 1.33333H10.5V4H5.16667V1.33333Z"
                                            fill="#404040"
                                        />
                                        <path
                                            d="M9.16667 11.3333V12.942C9.58539 12.2419 10.1804 11.6639 10.8924 11.2656C11.6043 10.8673 12.4083 10.6628 13.224 10.6723C14.0397 10.6819 14.8386 10.9052 15.5411 11.32C16.2435 11.7349 16.8248 12.3267 17.227 13.0364C17.6293 13.7461 17.8383 14.5489 17.8333 15.3647C17.8283 16.1804 17.6094 16.9806 17.1985 17.6853C16.7876 18.39 16.199 18.9746 15.4915 19.3808C14.7841 19.787 13.9824 20.0005 13.1667 20V18.6667C13.7678 18.6675 14.358 18.5057 14.8747 18.1984C15.3914 17.8912 15.8154 17.4499 16.1018 16.9214C16.3882 16.3928 16.5263 15.7967 16.5016 15.196C16.4768 14.5954 16.29 14.0126 15.9611 13.5094C15.6322 13.0063 15.1733 12.6014 14.6331 12.3377C14.0928 12.0741 13.4913 11.9614 12.8923 12.0117C12.2932 12.062 11.7189 12.2733 11.2302 12.6233C10.7414 12.9734 10.3565 13.449 10.116 14H11.8333V15.3333H7.83333V11.3333H9.16667Z"
                                            fill="#404040"
                                        />
                                    </svg>
                                </div>
                                <div
                                    style="
                                        justify-content: flex-end;
                                        display: flex;
                                    "
                                    class="w-100"
                                    *ngIf="resumeCollectExams.length > 0"
                                >
                                    <div>
                                        <h2>
                                            {{
                                                "requestResume.collect.bigPromise"
                                                    | translate
                                            }}
                                            {{ biggestPromise }}
                                        </h2>
                                        <h2 [innerHTML]="promises"></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-center">
                        <app-button
                            class="mt-32"
                            (click)="finishRequisition()"
                            [placeholder]="'requestResume.btnFinalizeRequest'"
                            [class]="'flex gap-2 align-center'"
                        ></app-button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<ng-template #dialogModalQuestion let-questionData>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>
                    <strong>{{ questionData.description }}</strong>
                </h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <div class="flex w-100 mt-32 align-center">
                <div class="w-100">
                    <input-prop
                        (keyup.enter)="saveQuestionAnswer(questionData.id)"
                        [form]="formQuestions.controls['answer']"
                        [placeholder]="'requestResume.questions.infoAnswer'"
                    ></input-prop>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalAllQuestions let-allQuestionsData>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>
                    {{
                        "requestResume.questions.titleModalQuestions"
                            | translate
                    }}
                </h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <div></div>
            <div *ngFor="let question of allQuestionsList; let i = index">
                <div class="w-100 mt-32 gap-2 align-center">
                    <div *ngIf="allQuestionsList.length > 0" class="w-100 box">
                        <h2>
                            <strong>{{ question.name }}</strong>
                        </h2>
                        <div class="mt-32">
                            <input-prop
                                (keyup.enter)="saveAllQuestionAnswer()"
                                [form]="
                                    formGroupAllQuestionsAnswer[i].controls[
                                        'answer'
                                    ]
                                "
                                [placeholder]="
                                    'requestResume.questions.infoAnswer'
                                "
                            ></input-prop>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-center mt-32">
                <app-button
                    (click)="saveAllQuestionAnswer()"
                    [placeholder]="'requestResume.questions.btnSaveAll'"
                    [class]="'flex gap-2 align-center btn'"
                ></app-button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalInvoiceProgressHistory let-allInvoiceProgress>
    <ng-container>
        <div class="flex flex-between w-100 align-center">
            <h1>{{ "financial.movementHistory" | translate }}</h1>
            <svg
                class="close-svg"
                (click)="close()"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                />
            </svg>
        </div>
        <app-table
            (parentFun)="invoiceProgressActions($event)"
            [Actions]="['Estornar']"
            [Pagination]="true"
            [backPagination]="false"
            [noShadow]="true"
            [Itens]="
                resolveGenericLocal(
                    invoiceProgressHistory,
                    resolveInvoiceProgressHistory
                )
            "
        ></app-table>
    </ng-container>
</ng-template>

<ng-template #dialogModalSetCollectDate let-analyteObj>
    <ng-container>
        <div class="flex flex-between w-100 align-center">
            <h1>
                {{ "requestResume.collect.titleCollectionDate" | translate }}
            </h1>
            <svg
                class="close-svg"
                (click)="close()"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                />
            </svg>
        </div>
        <div class="w-100 mt-32">
            <input-prop
                class="w-100"
                [placeholder]="'requestResume.collect.dateCollect'"
                [type]="'datetime-local'"
                [form]="formSetCollectDate"
            ></input-prop>
        </div>
        <div class="mt-16 flex flex-center">
            <checkbox-prop
                [gap]="1"
                [form]="formSetCollectDateToAll"
                class="flex"
                [label]="'requestResume.collect.applyCollection'"
            ></checkbox-prop>
        </div>
        <div class="mt-32 flex flex-center">
            <app-button
                (click)="setCollectDate(analyteObj)"
                [placeholder]="'global.save'"
                [class]="'btn'"
            ></app-button>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalSetAnalyteDeadline>
    <ng-container>
        <div class="flex flex-between w-100 align-center">
            <h1>{{ "requestResume.collect.titleDeliveryDate" | translate }}</h1>
            <svg
                class="close-svg"
                (click)="close()"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                />
            </svg>
        </div>
        <div class="w-100 mt-32">
            <input-prop
                [type]="'datetime-local'"
                [placeholder]="'requestResume.collect.infoDeliveryDate'"
                [form]="formSetAnalyteDeadline"
            ></input-prop>
        </div>
        <div class="mt-32 flex flex-center">
            <app-button
                (click)="setAnalyteDeadlineGlobally()"
                [placeholder]="'global.save'"
                [class]="'btn'"
            ></app-button>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalOperations let-operationData>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1 *ngIf="operationData === EInvoiceStatus.Addition">
                    {{ "financial.includeAddition" | translate }}
                </h1>
                <h1 *ngIf="operationData !== EInvoiceStatus.Addition">
                    {{ "financial.includeDiscount" | translate }}
                </h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <div class="mt-32">
                <input-prop
                    (keydown.enter)="paymentOperation(operationData)"
                    (change)="
                        formatCurrencyValue(
                            operationData === EInvoiceStatus.Addition
                                ? formOperation.controls['addition']
                                : formOperation.controls['discount']
                        )
                    "
                    [placeholder]="'financial.enterValue'"
                    class="w-100"
                    [form]="
                        operationData === EInvoiceStatus.Addition
                            ? formOperation.controls['addition']
                            : formOperation.controls['discount']
                    "
                ></input-prop>
            </div>
        </div>
        <div class="mt-32 flex flex-center">
            <app-button
                (click)="paymentOperation(operationData)"
                [placeholder]="'global.confirm'"
                [class]="'btn'"
            ></app-button>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalAllInstructions let-analytes>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>
                    {{
                        "requestResume.collect.titleModalPreparation"
                            | translate
                    }}
                </h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <ng-container *ngFor="let analyteInstruction of analytes">
                <div class="mt-32">
                    <h2 class="exameFont">
                        {{ "requestResume.collect.exam" | translate }}
                        <strong class="orangeFont">{{
                            analyteInstruction.code
                        }}</strong>
                    </h2>
                    <div class="mt-32">
                        <textarea-prop
                            [readonly]="true"
                            [placeholder]="
                                'requestResume.collect.infoPreparation'
                            "
                            [form]="analyteInstruction.form"
                        ></textarea-prop>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalChangeData let-operationData>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>Alterar Dados</h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <div class="flex mt-32 align-center w-100 gap-form">
                <input-search
                    [searchApi]="true"
                    (search)="searchAgreement($event.value)"
                    (changeForm)="
                        getAgreementPlan(
                            formGroupChangeData.controls['agreement'].value
                        )
                    "
                    [class]="'w-100'"
                    [options]="agreementOptions"
                    [placeholder]="'Selecione o Convênio'"
                    [form]="formGroupChangeData.controls['agreement']"
                ></input-search>
                <input-search
                    *ngIf="agremeentPlanOptions.length > 0"
                    [class]="'w-100'"
                    [options]="agremeentPlanOptions"
                    [placeholder]="'Selecione o Plano'"
                    [form]="formGroupChangeData.controls['agreementPlanId']"
                ></input-search>
            </div>
            <div class="flex mt-32 align-center w-100 gap-2">
                <input-search
                    [searchApi]="true"
                    [class]="'w-100'"
                    (search)="searchDoctor($event.value)"
                    [options]="doctorOptions"
                    [placeholder]="'Selecione o Solicitante'"
                    [form]="formGroupChangeData.controls['doctorId']"
                    (keyup.tab)="
                        setDoctorCode(
                            formGroupAgreementRequest.controls['doctorId'].value
                        )
                    "
                ></input-search>
            </div>
            <div class="flex mt-32 align-center w-100 gap-2">
                <input-prop
                    [searchApi]="true"
                    [class]="'w-100'"
                    (search)="searchDoctor($event.value)"
                    [placeholder]="'Guia'"
                    [form]="formGroupChangeData.controls['code']"
                ></input-prop>
            </div>
        </div>
        <div class="mt-32 flex flex-center">
            <app-button
                [placeholder]="'Confirmar'"
                [class]="'btn'"
                (click)="updateAgreementExamsRequestMultiple(operationData)"
            ></app-button>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalPackage let-package>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>Exames do Pacote</h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <app-table
                (parentFun)="packageActions($event)"
                [Itens]="packageExams"
                [ClickInItem]="ActionsExtraPackage"
            ></app-table>
        </div>
        <div class="mt-32 flex flex-center">
            <app-button
                (click)="includeAnalytesPackage()"
                [placeholder]="'Incluir Exames'"
                [class]="'flex gap-2 align-center btn'"
            ></app-button>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalMedication let-Medication>
    <ng-container>
        <div class="flex flex-between w-100 align-center">
            <h1>{{ "requisitionEdit.addMedication" | translate }}</h1>
            <svg
                class="close-svg"
                (click)="close()"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                />
            </svg>
        </div>
        <div class="mt-32 w-100">
            <div class="search">
                <input-prop
                    class="w-100"
                    [placeholder]="'requisitionEdit.medication'"
                    [form]="form.medication"
                    (keydown.enter)="createMedicine()"
                ></input-prop>
            </div>
        </div>
        <div class="w-100 flex flex-center mt-32">
            <app-button
                (click)="createMedicine()"
                [placeholder]="'global.save'"
                [class]="'btn'"
            ></app-button>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalDeadline let-deadline>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>Previsão de Entrega - {{ deadline.object["Código"] }}</h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <div class="mt-32">
                <input-prop
                    [type]="'datetime-local'"
                    [placeholder]="'Informe aqui a Previsão do Exame'"
                    [form]="formDeadline.controls['examDeadline']"
                    (keyup.enter)="
                        updateAgreementExamsRequest(
                            deadline.object.id,
                            this.formDeadline.controls['examDeadline'].value
                        )
                    "
                ></input-prop>
                <!-- <h2 class="mt-32">Simular prazo</h2>
        <div class="flex flex-center gap-2 mt-24">
          <app-button (click)="simulateDeadline(deadline.object, 0)" [placeholder]="'Normal'" [class]="'flex gap-2 align-center btn'"></app-button>
          <app-button (click)="simulateDeadline(deadline.object, 1)" [placeholder]="'Urgência'" [class]="'flex gap-2 align-center btn'"></app-button>
          <app-button (click)="simulateDeadline(deadline.object, 2)" [placeholder]="'Emergência'" [class]="'flex gap-2 align-center btn'"></app-button>
        </div> -->
            </div>
            <div class="flex mt-32 flex-center">
                <app-button
                    (click)="
                        updateAgreementExamsRequest(
                            deadline.object.id,
                            this.formDeadline.controls['examDeadline'].value
                        )
                    "
                    [placeholder]="'Salvar'"
                    [class]="'btn'"
                ></app-button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalManualMedication let-Modal>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>Alterar Medicamento</h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <div class="mt-32 w-100 flex flex-between gap-form">
                <input-prop
                    class="w-100"
                    placeholder="Medicamento"
                    [form]="form.manualMedication"
                    (keydown.enter)="saveManualMedication(Modal.id)"
                ></input-prop>
            </div>
            <div class="mt-32 flex flex-center">
                <app-button
                    (click)="saveManualMedication(Modal.id)"
                    [placeholder]="'Salvar'"
                    [class]="'btn'"
                ></app-button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalAgreementExamsRequest let-agreementExamsRequestId>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>Editar Exame</h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <div class="mt-48">
                <div class="flex mt-32 w-100 gap-form">
                    <input-prop
                        class="w-100"
                        [form]="
                            updateAgreementExamsRequestForm.controls[
                                'analyteName'
                            ]
                        "
                        [placeholder]="'Exame Selecionado'"
                    ></input-prop>
                    <input-search
                        class="w-100"
                        [options]="updateAnalyteMaterialOptions"
                        [form]="
                            updateAgreementExamsRequestForm.controls[
                                'materialId'
                            ]
                        "
                        [placeholder]="'Selecione o Material'"
                    ></input-search>
                    <select-prop
                        class="routineModal"
                        [options]="updateAnalyteRoutineOptions"
                        [form]="
                            updateAgreementExamsRequestForm.controls['routine']
                        "
                        [placeholder]="'Caráter de Atendimento'"
                    ></select-prop>
                </div>
                <div class="flex mt-32 w-100 gap-form">
                    <input-search
                        class="w-100"
                        [options]="collectionPlaceOriginOptions"
                        [placeholder]="'Origem de Coleta'"
                        [form]="
                            updateAgreementExamsRequestForm.controls[
                                'collectionPlaceOriginId'
                            ]
                        "
                    ></input-search>
                    <input-search
                        class="w-100"
                        [options]="collectionPlaceInstructionOptions"
                        [placeholder]="'Orientação de Coleta'"
                        [form]="
                            updateAgreementExamsRequestForm.controls[
                                'collectionPlaceInstructionId'
                            ]
                        "
                    ></input-search>
                    <div class="flex flex-center align-center w-deveModal">
                        <checkbox-prop
                            [gap]="1"
                            [label]="'Deve Guia'"
                            [form]="
                                updateAgreementExamsRequestForm.controls[
                                    'requestPending'
                                ]
                            "
                        ></checkbox-prop>
                    </div>
                </div>
            </div>
            <div class="flex flex-center mt-32">
                <app-button
                    (click)="
                        updateAnalyteAgreementExamsRequest(
                            agreementExamsRequestId
                        )
                    "
                    [placeholder]="'Salvar'"
                    [class]="'flex gap-2 align-center btn'"
                ></app-button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogModalDiscountAuthorization let-value>
    <ng-container>
        <div>
            <div class="flex flex-between w-100 align-center">
                <h1>Solicitação de Desconto</h1>
                <svg
                    class="close-svg"
                    (click)="close()"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
                    />
                </svg>
            </div>
            <div class="flex mt-32 w-100 gap-form">
                <textarea-prop
                    class="w-100"
                    [form]="
                        formDiscountAuthorization.controls['discountReason']
                    "
                    [placeholder]="'Motivo'"
                ></textarea-prop>
            </div>
            <div class="flex flex-center mt-32">
                <app-button
                    (click)="createDiscountSolicitation(value)"
                    [placeholder]="'Salvar'"
                    [class]="'flex gap-2 align-center btn'"
                ></app-button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #dialogShowAgreementInformation let-data>
    <div class="flex flex-between w-100 align-center">
        <h1>Observações do {{ data }}</h1>
        <svg
            class="close-svg"
            (click)="close()"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"
            />
        </svg>
    </div>
    <div class="mt-32">
        <textarea-prop
            [readonly]="true"
            [placeholder]="'Observações'"
            [form]="information"
        ></textarea-prop>
    </div>
</ng-template>
