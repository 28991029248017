import { Injectable, inject } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import { LoginService } from "../services/login/login.service";
import { lastValueFrom } from "rxjs";
import { ForgotService } from "../services/forgot/forgot.service";

@Injectable({
   providedIn: "root"
})
export class LoginFacade extends MultiObservableFacade {
   private _loginService = inject(LoginService);
   private _forgotService = inject(ForgotService);

   async forgot(obj: any) {
      try {
         const response: any = await lastValueFrom(
            this._forgotService.post(obj)
         );

         return response.success;
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      return false;
   }

   async login(obj: any) {
      try {
         const response: any = await lastValueFrom(
            this._loginService.post(obj)
         );

         return response;
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      return {};
   }
}
