<div class="sd">
    <select [formControl]="form" [name]="name" (change)="onChange(form.value)" [ngClass]="readonlySelect ? 'readonly' : ''" [tabindex]="tabindexcomponent">
        <option [disabled]="!nullable" *ngIf="placeholder">
            {{placeholder | translate}}
        </option>
        <ng-container *ngIf="!states">
            <option *ngFor="let option of options" [value]="option.value">{{option.label | translate}}</option>
        </ng-container>
        <ng-container *ngIf="states">
          <option value="AC">Acre</option>
          <option value="AL">Alagoas</option>
          <option value="AP">Amapá</option>
          <option value="AM">Amazonas</option>
          <option value="BA">Bahia</option>
          <option value="CE">Ceará</option>
          <option value="DF">Distrito Federal</option>
          <option value="ES">Espírito Santo</option>
          <option value="GO">Goiás</option>
          <option value="MA">Maranhão</option>
          <option value="MT">Mato Grosso</option>
          <option value="MS">Mato Grosso do Sul</option>
          <option value="MG">Minas Gerais</option>
          <option value="PA">Pará</option>
          <option value="PB">Paraíba</option>
          <option value="PR">Paraná</option>
          <option value="PE">Pernambuco</option>
          <option value="PI">Piauí</option>
          <option value="RJ">Rio de Janeiro</option>
          <option value="RN">Rio Grande do Norte</option>
          <option value="RS">Rio Grande do Sul</option>
          <option value="RO">Rondônia</option>
          <option value="RR">Roraima</option>
          <option value="SC">Santa Catarina</option>
          <option value="SP">São Paulo</option>
          <option value="SE">Sergipe</option>
          <option value="TO">Tocantins</option>
        </ng-container>
    </select>
    <label [ngClass]="whiteLabel ? 'whiteLabel' : ''">{{placeholder | translate}}</label>
    <span></span>
</div>
