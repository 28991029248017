import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Guid } from "@app/util/guid";
import { DialogComponent } from "@app/components/form/dialog/dialog.component";
import { Util } from "@app/util/util";
import { ToastService } from "@app/services/translate/toast.service";
import { TypeOcurrencyService } from "@app/services/typeOcurrency/typeOcurrency.service";
import { resolveOccurrency } from "./occurencyTypes.model";
import { SwAlSetttings } from "@app/util/swal.settings";
import { CheckboxSvgPipe } from "@app/pipe/checkbox-Svg.pipe";

@Component({
   selector: "app-occurencyTypes",
   templateUrl: "./occurencyTypes.component.html",
   styleUrls: ["./occurencyTypes.component.scss"]
})
export class OccurencyTypesComponent implements OnInit {
   orderBy: { sort: string; order: string } = { sort: "", order: "asc" };

   total: number = 0;
   page: number = 1;
   index: number = 50;

   getParameters: string = `?page=${this.page}&index=${this.index}`;

   occurrencyTypeId: string = "";

   @ViewChild(DialogComponent) dialog!: DialogComponent;

   occurrencyTypes: Array<any> = [];

   ActionsExtra = {
      Ativo: "PutActive"
   };

   form = {
      description: new FormControl("", [Validators.required])
   };

   constructor(
      private _checkboxSvgPipe: CheckboxSvgPipe,
      private _typeOccurrencyService: TypeOcurrencyService,
      private _util: Util,
      private _toastService: ToastService
   ) {}

   async ngOnInit() {
      await this.get();
   }

   async post() {
      let occurrency = {
         description: this.form.description.value
      };

      await this._typeOccurrencyService
         .post(occurrency)
         .then((res: any) => {
            if (res.sucess)
               this._toastService.fireSuccess(
                  "Tipo de Ocorrência criado com sucesso!"
               );
            this.dialog.closeDialog();
            this.get();
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async put() {
      let occurrency = {
         description: this.form.description.value
      };

      await this._typeOccurrencyService
         .put(occurrency, new Guid(this.occurrencyTypeId))
         .then((res: any) => {
            if (res.sucess)
               this._toastService.fireSuccess(
                  "Tipo de ocorrência alterado com sucesso!"
               );
            this.dialog.closeDialog();
            this.get();
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   async get(paramPage?: number[]) {
      if (paramPage) {
         this.page = paramPage[0];
         this.index = paramPage[1];
      }
      this.occurrencyTypes = [];
      await this._typeOccurrencyService
         .getPagination({
            page: this.page,
            index: this.index,
            orderBy: this.orderBy.sort
               ? `${this.orderBy.sort},${this.orderBy.order}`
               : ""
         })
         .then((res: any) => {
            if (res.data.lenght != 0) {
               this.total = res.sumRecords;
               this.resolve(res.data, resolveOccurrency);
            } else {
               this._toastService.warningMessage(
                  "Não foram encontrados tipos de ocorrências!"
               );
            }
         });
   }

   resolve(data: any, columns: any) {
      data.forEach((x: any) => {
         let obj: any = {};
         columns.forEach((y: any) => {
            if (!y.retrive) {
               obj[y.label] =
                  y.before +
                  this._util.formatField(
                     x,
                     y.fieldName,
                     y.type,
                     y.interpolationFields,
                     y.dateFormat
                  ) +
                  y.after;
            } else {
               obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
            }
         });
         this.occurrencyTypes.push(obj);
      });
   }

   afterClose() {
      this.form.description.reset();
      this.occurrencyTypeId = "";
   }

   Actions(emit: any) {
      switch (emit.action) {
         case "PutActive":
            this.putActive(
               emit.object.id,
               this._checkboxSvgPipe.transform(emit.object.Ativo)
            );
            break;
         case "Excluir":
            SwAlSetttings.ALERT_CONFIRM.fire(
               "Deseja excluir esse tipo de ocorrência?"
            ).then(async (r) => {
               if (r.isConfirmed) {
                  await this._typeOccurrencyService.delete(emit.object.id);
                  this.get();
                  SwAlSetttings.Sucesso(
                     "Tipo de ocorrência excluído com sucesso!"
                  );
               }
            });
            break;
         case "Editar":
            this.dialog.openDialogModal(true, emit);
            this.occurrencyTypeId = emit.object.id;
            this.form.description.setValue(emit.object["Descrição"]);
            break;
      }
   }

   putActive(id: string, value: string) {
      this._typeOccurrencyService
         .patchActive(id, value)
         .then((x: any) => {
            SwAlSetttings.Sucesso("Situação alterada!");
            this.get();
         })
         .catch((err) => SwAlSetttings.printError(err));
   }
}
