import { DefaultFormats } from "@app/pipe/localeDate.pipe";
export interface RegisterExams {
  "Selecionar": string
  "Requisição": string
  "Nome": string
  "Exame": string
  "Código no Apoio": string
  "Material no Apoio": string
  "Data da Criação": string  
}

export const resolveSends = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Selecionar',
    retrive: '',
    method: 'returnCheckBox',
    after: '',
    before: ''
  },
  {
    label: 'Protocolo Enviado para Apoio',
    retrive: 'code',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Data e Hora de Envio para o Apoio',
    retrive: 'date',
    method: '',
    after: '',
    before: ''
  }
];

export const resolveExams = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Selecionar',
    retrive: '',
    method: 'returnCheckBox',
    after: '',
    before: ''
  },
  {
    label: 'Requisição',
    retrive: 'requisitionNumber',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Nome',
    retrive: 'customerName',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Exame',
    retrive: 'analyteCode',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Código no Apoio',
    retrive: 'agreementExamsSupportCode',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Material no Apoio',
    retrive: 'externalMaterialCode',
    method: '',
    after: '',
    before: ''
  }/*,
  {
    label: 'Situação',
    retrive: '',
    method: 'getSituation',
    after: '',
    before: ''
  }*/,  
  {
    label: 'Data de Criação',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full
  }
];

export const resolveRecipients = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Requisição',
    retrive: 'number',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Nome',
    retrive: 'name',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Exame',
    retrive: 'exam',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Código no Apoio',
    retrive: 'code',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Material no Apoio',
    retrive: 'material',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Data da Criação',
    retrive: 'date',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Etiqueta',
    retrive: 'tag',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'Remover',
    retrive: '',
    method: 'returnDelete',
    after: '',
    before: ''
  }
];

export const situation = [
  {
    value: 0,
    label: 'Não Validado'
  },
  {
    value: 1,
    label: 'Validado'
  },
  {
    value: 2,
    label: 'Não Enviado'
  },
  {
    value: 3,
    label: 'Enviado'
  },
  {
    value: 4,
    label: 'Retornado'
  }
]