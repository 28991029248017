import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../http.service';
import { HttpResponsePageable } from '../services.types';
import { Guid } from '@app/util/guid';
import { ExamPackesGetAll, ExamPackesPost, ExamPackesPut } from '@app/modules/registers/components/analyte-packages/analyte-packages.model';

@Injectable({
  providedIn: 'root'
})
export class ExamPackService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/analytepackage');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<ExamPackesGetAll>>
  }

  override post(body: Object) {
    return super.post(body) as Promise<HttpResponsePageable<ExamPackesPost>>
  }

  override put(body: Object, id?:Guid, path?: string) {
    return super.put(body, id, path) as Promise<HttpResponsePageable<ExamPackesPut>>
  }

  override delete(id?: Guid | undefined, path?: string | undefined){
    return super.delete(id) as Promise<any>
  }
}
