import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../http.service';
import { CreateSector, GetPaginationSector, UpdateSector } from './sector.service.module';
import { Guid } from '@app/util/guid';
import { QueryParams } from '../interfaces/services.types';
import { HttpResponsePageable } from '../services.types';

@Injectable({
  providedIn: 'root'
})
export class SectorService extends BaseService {

constructor(
  private http: HttpClient,
  httpService: HttpService
) { 
  super(httpService, 'v1/laboratory/attendance/sector');
}

override post(body: CreateSector) {
  return super.post(body);
}

override put(body: UpdateSector, id?: Guid | undefined, path?: string | undefined) {
  return super.put(body, id, path);
}

override getPagination<GetPaginationSector>(queryObject?: QueryParams | undefined){
  return super.getPagination(queryObject) as Promise<HttpResponsePageable<GetPaginationSector>> 
}

}
