<modal-layout [title]="placeholder">
    <form [formGroup]="formGroup" class="flex flex-col mt-[32px]">
        <div class="flex w-full">
            <forms-input-select
                class="w-full"
                [options]="type"
                [placeholder]="'Selecione o tipo'"
                [form]="formGroup.controls.type"
                (valueChanged)="changeType($event)"
            ></forms-input-select>
        </div>
        <div class="flex w-full mt-[32px]">
            <forms-input-select
                *ngIf="searchPlaceHolder != ''"
                [searchBtn]="true"
                (searchEmit)="search($event)"
                class="w-full"
                [placeholder]="searchPlaceHolder"
                [form]="formGroup.controls.externalAccessId"
                [options]="options"
            ></forms-input-select>
        </div>
        <div class="flex w-full mt-[32px]">
            <forms-input-text
                class="w-full"
                [placeholder]="'Nome'"
                [form]="formGroup.controls.name"
            ></forms-input-text>
        </div>
        <div class="flex w-full mt-[32px]">
            <div class="flex w-full gap-form">
                <forms-input-text
                    class="w-full"
                    [placeholder]="'Código de Acesso'"
                    [form]="formGroup.controls.code"
                    (keydown.enter)="save()"
                ></forms-input-text>
                <forms-input-text
                    mask="(00) 00000-0000"
                    class="w-full"
                    [placeholder]="'Celular'"
                    [form]="formGroup.controls.cellphone"
                    (keydown.enter)="save()"
                ></forms-input-text>
            </div>
        </div>
        <div class="flex w-full mt-[32px]">
            <div class="flex w-full gap-form">
                <forms-input-text
                    [type]="'password'"
                    class="w-full"
                    [placeholder]="'Senha'"
                    [form]="formGroup.controls.password"
                    (keydown.enter)="save()"
                ></forms-input-text>
                <forms-input-text
                    [type]="'password'"
                    class="w-full"
                    [placeholder]="'Confirmar senha'"
                    [form]="formGroup.controls.confirmPassword"
                    (keydown.enter)="save()"
                ></forms-input-text>
            </div>
        </div>
    </form>
    <div class="flex justify-center">
        <forms-button
            #button
            class="self-center mt-[32px]"
            (click)="save()"
            [validate]="formGroup"
            classExtra="btn btn-primary"
            [text]="'customer.register.save' | translate"
        ></forms-button>
    </div>
</modal-layout>
