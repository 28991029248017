import { Injectable } from '@angular/core';
import { BaseAuthService } from "./base-auth.service";
import { HttpAuthService } from './http-auth.service';

@Injectable({
    providedIn: 'root'
})
export class ModuleAuthService extends BaseAuthService {
    constructor(HttpAuth: HttpAuthService) {
        super(HttpAuth, 'v1/admin/module');
    }
}
