import { MenuAdminComponent } from "./components/dashboard-admin/header/menu/menu.component";
import { UserMenuAdminComponent } from "./components/dashboard-admin/header/user-menu/user-menu.component";
import { HeaderAdminComponent } from "./components/dashboard-admin/header/header.component";
import { DashboardAdminComponent } from "./components/dashboard-admin/dashboard.component";
import { MenuComponent } from "./components/dashboard/header/menu/menu.component";
import { UserMenuComponent } from "./components/dashboard/header/user-menu/user-menu.component";
import { HeaderComponent } from "./components/dashboard/header/header.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { AppRoutingModule } from "./app-routing.module";
import { Interceptor } from "./app.interceptor.module";
import { AppComponent } from "./app.component";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MainMenuComponent } from "./components/main-menu/main-menu.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { CommonComponentModule } from "./components/common-component.module";
import { StoreModule } from "@ngrx/store";
import { notificationReducer } from "./store/notification/notification.reducer";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { InputMaskModule } from "@ngneat/input-mask";
import { MatIconModule } from "@angular/material/icon";
import { ExternalAccessModule } from "./external-access/external-access.module";
import { NgChartsModule } from "ng2-charts";
import { RedirectComponent } from "./redirect/redirect.component";
import {
   MatRippleModule,
   MAT_RIPPLE_GLOBAL_OPTIONS
} from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { InputSelectFormComponent } from "./modules_new/shared/components/form/input-select/base/input-select.component";
import { ModalLayoutComponent } from "./modules_new/shared/components/form/modal/modal.component";
import { ButtonFormComponent } from "./modules_new/shared/components/form/button/button.component";
import { LoginComponent } from "./modules_new/login/login.component";

const maskConfig: Partial<IConfig> = {
   validation: false
};

export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http);
}

@NgModule({
   declarations: [
      AppComponent,
      DashboardComponent,
      DashboardAdminComponent,
      HeaderAdminComponent,
      MenuAdminComponent,
      UserMenuAdminComponent,
      HeaderComponent,
      UserMenuComponent,
      MenuComponent,
      MainMenuComponent,
      RedirectComponent
   ],
   imports: [
      MatDialogModule,
      MatRippleModule,
      FormsModule,
      ReactiveFormsModule,
      BrowserModule,
      CommonModule,
      CommonComponentModule,
      MatTabsModule,
      MatIconModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      HttpClientModule,
      ExternalAccessModule,
      NgChartsModule,
      StoreModule.forRoot({
         userNotifications: notificationReducer
      }),
      NgxMaskModule.forRoot(maskConfig),
      TranslateModule.forRoot({
         loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
         },
         defaultLanguage: "pt-BR"
      }),
      Interceptor,
      InputMaskModule,
      InputSelectFormComponent,
      ModalLayoutComponent,
      ButtonFormComponent,
      LoginComponent
   ],
   providers: [
      { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } }
   ],
   exports: [AppRoutingModule],
   bootstrap: [AppComponent]
})
export class AppModule {}
