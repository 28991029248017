import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { MaterialService } from '@app/services/materials/material.service';
import { Guid } from '@app/util/guid';
import { GetMaterial, Actions } from './materials.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { DialogComponent } from '@app/components/form/dialog/dialog.component';
import { ToastService } from '@app/services/translate/toast.service';


@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent implements OnInit {

  @ViewChild(DialogComponent) dialog!: DialogComponent

  total: number = 0;
  page: number = 1;
  index: number = 50;

  materialId: Guid = new Guid(Guid.getEmpty());

  getParameters: string = (`?page=${this.page}&index=${this.index}`);

  editId: string = '';

  filterForm = {
    description: new UntypedFormControl(null),
    externalId: new UntypedFormControl(null),
    active: new UntypedFormControl(null),
  }

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  materials: Array<any> = [];

  resolveMaterials = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'material.code',
      retrive: '',
      method: 'getExternalId',
      after: '',
      before: ''
    },
    {
      label: 'material.description',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'material.createdAt',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'material.updatedAt',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ];

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  form = {
    materialName: new UntypedFormControl(false, [Validators.required]),
    externalId: new UntypedFormControl(null),
    isActive: new UntypedFormControl(false, [Validators.required])
  }

  constructor(
    private _materialService: MaterialService,
    private _dialog: MatDialog,
    private _createdAt: LocalaDatePipe,
    private _updatedAt: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _toastService: ToastService
  ) { }

  async ngOnInit() {
    await this.getMaterials();
  }

  searchMaterials() {
    this.page = 1
    this.getMaterials()
  }

  async getMaterials(paramPage?: number[]) {
    this.materials = [];
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let filterObject = {
      index: this.index,
      page: this.page,
      isActive: this.filterForm.active.value ? this.filterForm.active.value : null,
      search: this.filterForm.description.value ?? null,
      externalId: this.filterForm.externalId.value ?? null
    }
    await this._materialService.getPagination(filterObject).then(
      (res: any) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolve(res.data, this.resolveMaterials);
        }
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    );
  }

  async post() {
    const obj = {
      description: this.form.materialName.value,
      externalId: this.form.externalId.value,
      isActive: this.form.isActive.value
    }
    await this._materialService.post(obj).then((res: any) => {
      if (res.success) {
        this.getMaterials();
        this._toastService.fireSuccess('material.msgPost')
        this.dialog.closeDialog()
      }
    }).catch(
      (err) => {
        this._toastService.fireError(err);
      });
  }

  async put() {
    const obj = {
      description: this.form.materialName.value,
      externalId: this.form.externalId.value,
      isActive: this.form.isActive.value
    }
    await this._materialService.put(obj, new Guid(this.materialId.toString())).then(
      async (res) => {
        await this.getMaterials();
        this._dialog.closeAll()
        this._toastService.fireSuccess('material.msgPut')
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.materials = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.materials.push(obj);
    });
  }

  getExternalId(item: GetMaterial) {
    return item.externalId ?? ''
  }

  getCreated(item: GetMaterial) {
    return this._createdAt.transform(item.createdAt);
  }

  getUpdated(item: GetMaterial) {
    return this._updatedAt.transform(item.updatedAt);
  }

  getActive(item: GetMaterial) {
    return this._activePipe.transform(item.isActive)
  }

  putActive(id: Guid, value: string) {
    this._materialService.patchActive(id.toString(), value).then((x: any) => {
      this._toastService.fireSuccess('global.msgSituationSuccess');
      this.getMaterials();
    }).catch(err => this._toastService.fireError(err))
  }

  Actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.editId = emit.object.id.toString() ?? ''
        this.dialog.openDialogModal(true, emit);
        this.materialId = emit.object.id;
        this.form.materialName.setValue(emit.object['material.description'])
        this.form.externalId.setValue(emit.object['material.code'])
        break;
      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  resetFilter() {
    this.filterForm.description.reset();
    this.filterForm.externalId.reset();
    this.filterForm.active.reset();
  }

  afterClose() {
    this.form.externalId.reset()
    this.form.materialName.reset()
    this.editId = ''
  }
}
