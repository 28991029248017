<div class="flex btn-group">
    <button
        style="padding: 12px; font-size: 12px"
        *ngFor="let btn of options"
        [ngClass]="{ selected: btn.value === this.form.value }"
        (click)="changeValue(btn.value)"
    >
        {{ btn.label | translate }}
    </button>
</div>
