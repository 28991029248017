import { DefaultFormats } from "@app/pipe/localeDate.pipe";

export enum Gender {
  'Não Informado' = 0,
  'Feminino' = 1,
  'Masculino' = 2
}

export interface CustomerResolve {
  label: string,
  retrive: string,
  method: string,
  after: string,
  before: string
}

export const customerResolve: Array<Object> = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'global.title-list.code',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'externalId',
    type: 'description',
    sort: 'externalId'
  },
  {
    label: 'global.title-list.name',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'name',
    type: 'name',
    sort: 'name'
  },
  {
    label: 'global.title-list.biological-sex',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'genderType',
    type: 'genderType',
    sort: 'genderType'
  },
  {
    label: 'global.title-list.birth',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'birthDate',
    type: 'date',
    dateFormat: DefaultFormats.short,
    sort: 'birthDate'
  },
  {
    label: 'global.title-list.mother-name',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'motherName',
    type: 'name',
    sort: 'motherName'
  },
  {
    label: 'global.title-list.cpf',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'cpf',
    type: 'description',
    sort: 'cpf'
  },
  {
    label: 'global.title-list.created-at',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'createdAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'createdAt'
  },
  {
    label: 'global.title-list.updated-at',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'updatedAt',
    type: 'date',
    dateFormat: DefaultFormats.full,
    sort: 'updatedAt'
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'formatField',
    after: '',
    before: '',
    fieldName: 'isActive',
    type: 'checkbox',
    sort: 'isActive'
  },
];

export interface CustomerActions {
  action: string,
  context: string | null,
  object: ResolvedCustomerObject
}

interface ResolvedCustomerObject {
  "id": string,
  "Nome": string,
  "Sexo": string,
  "Nascimento": string,
  "Nome da Mãe": string,
  "CNPJ/CPF": string,
  "Celular": string,
  "E-mail": string,
  "Data de Cadastro": string
  "Ativo": string
}

export interface CustomerFilterOptions {
  label: string,
  value: string
}

export const filterOptions: Array<CustomerFilterOptions> = [
  {
    label: 'Filtrar por CPF',
    value: 'Filtrar por CPF'
  },
  {
    label: 'Filtrar por Nome/Nome Social',
    value: 'Filtrar por Nome/Nome Social'
  },
  {
    label: 'Filtrar por Data de Nascimento',
    value: 'Filtrar por Data de Nascimento'
  },
  {
    label: 'Filtrar por Nome da Mãe',
    value: 'Filtrar por Nome da Mãe'
  }
];