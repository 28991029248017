import {
   Component,
   HostListener,
   OnDestroy,
   OnInit,
   QueryList,
   TemplateRef,
   ViewChild,
   ViewChildren
} from "@angular/core";
import { SaveRequisitionFacade } from "./save-requisition.facade";
import {
   AbstractControl,
   UntypedFormControl,
   UntypedFormGroup
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
   EType,
   SelectOptions,
   resolveInvoiceProgressHistory,
   resolveMedication,
   sectorOption
} from "./save-requisition.model";
import { Subscription } from "rxjs";
import {
   InvoiceProgress,
   Question
} from "@app/services/requisitions/requisition.types";
import { InputPropComponent } from "@app/components/form/input-prop/input-prop.component";
import { Guid } from "@app/util/guid";
import { MatDialog } from "@angular/material/dialog";
import { SwAlSetttings } from "@app/util/swal.settings";
import { InvoiceStatus } from "@app/util/EInvoiceStatus";
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from "@angular/material/legacy-tabs";
import { InputSearchComponent } from "@app/components/form/input-search/input-search.component";
import { MedicinesService } from "@app/services/medicines/medicines.service";
import { GetActiveFilterPipe } from "@app/pipe/active.pipe";
import { formatCurrency } from "@angular/common";
import { DiscountAuthorizationService } from "@app/services/discountAuthorization/discountAuthorization.service";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { CollectionPlaceOriginService } from "@app/services/collection-place-origin/collection-place-origin.service";
import { CollectionPlaceInstructionService } from "@app/services/collection-place-instruction/collection-place-instruction.service";
import { SectorService } from "@app/services/sector/sector.service";
import { CollectionPlaceInstructionPagination } from "@app/services/collection-place-instruction/collection-place-instruction.service.module";
import { CollectionPlaceOriginPagination } from "@app/services/collection-place-origin/collection-place-origin.service.module";
import { settings } from "cluster";
import { EnumObject } from "@app/services/enum/enum.service.model";
import { Util } from "@app/util/util";
import { QueryParams } from "@app/services/interfaces/services.types";
import { ToastService } from "@app/services/translate/toast.service";
@Component({
   selector: "app-save-requisition",
   templateUrl: "./save-requisition.component.html",
   styleUrls: ["./save-requisition.component.scss"]
})
export class SaveRequisitionComponent implements OnInit, OnDestroy {
   tiss = new UntypedFormControl(null);
   information = new UntypedFormControl(null);
   openNewAgreement!: boolean;
   resetControlPlan!: boolean;
   useCollect: boolean = true;
   allExamsCollect: boolean = false;
   privateAgreement: boolean = false;
   cleanAgreement: boolean = true;
   cleanDoctor: boolean = true;
   requisitionId!: Guid;
   actualPage!: number;
   requisitionNumber!: number;
   checkAllExams: Boolean = false;
   EType = EType;
   ETypePayments: Array<EnumObject> = [];
   EInvoiceStatus: Array<EnumObject> = [];
   agreementControlPlan: boolean = false;
   biggestPromise: string = "";
   promises: string = "";
   exams: any = {};
   paginationMedicines = {
      index: 50,
      page: 1,
      sumRecords: 0
   };

   resolveAgreementExams: Array<Object> = [
      {
         label: "id",
         method: "",
         retrive: "id",
         after: "",
         before: ""
      },
      {
         label: "Selecionar",
         retrive: "",
         method: "returnCheckBox",
         after: "",
         before: ""
      }
   ];

   examsGlobalSituation: number = -1;
   examsGlobalRoutine: number = -1;
   examsGlobalDeadline: string | null = "Definir";

   type: EType = EType.VISUALIZAR;
   resolveInvoiceProgressHistory = resolveInvoiceProgressHistory;

   optionRoutine = [
      {
         value: 0,
         label: "Normal"
      },
      {
         value: 1,
         label: "Urgência"
      },
      {
         value: 2,
         label: "Emergência"
      }
   ];

   typeCharacterService = [
      {
         value: 0,
         label: "Eletiva"
      },
      {
         value: 1,
         label: "Emergência/Urgência"
      }
   ];

   typeAttendance = [
      {
         value: 1,
         label: "01 - Remoção"
      },
      {
         value: 2,
         label: "02 - Pequena Cirurgia"
      },
      {
         value: 3,
         label: "03 - Terapias"
      },
      {
         value: 4,
         label: "04 - Consulta"
      },
      {
         value: 5,
         label: "05 - Exames"
      },
      {
         value: 6,
         label: "06 - Atendimento Domiciliar"
      },
      {
         value: 7,
         label: "07 - SADT/Internado"
      },
      {
         value: 8,
         label: "08 - Quimioterapia"
      },
      {
         value: 9,
         label: "09 - Terapia"
      },
      {
         value: 10,
         label: "10 - Terapia Renal Substitutiva (TRS"
      },
      {
         value: 11,
         label: "11 - Pronto Socorro"
      },
      {
         value: 12,
         label: "12 - Ocupacional"
      },
      {
         value: 13,
         label: "13 - Pequenos Atendimentos"
      },
      {
         value: 14,
         label: "14 - Saúde Ocupacional - Admissional"
      },
      {
         value: 15,
         label: "15 - Saúde Ocupacional - Demissiona"
      },
      {
         value: 16,
         label: "16 - Saúde Ocupacional - Periódico"
      },
      {
         value: 17,
         label: "17 - Saúde Ocupacional - Retorno ao Trabalho"
      },
      {
         value: 18,
         label: "18 - Saúde Ocupacional - Mudança de Função"
      },
      {
         value: 19,
         label: "19 - Saúde Ocupacional - Promoção a Saúde"
      },
      {
         value: 20,
         label: "20 - Saúde Ocupacional - Beneficiário Novo"
      },
      {
         value: 21,
         label: "21 - Saúde Ocupacional - Assistência a Demitidos"
      },
      {
         value: 22,
         label: "22 - Telessaude"
      },
      {
         value: 23,
         label: "23 - Exame"
      }
   ];

   typeOut = [
      {
         value: 1,
         label: "01 - Retorno"
      },
      {
         value: 2,
         label: "02 - Retorno SADT"
      },
      {
         value: 3,
         label: "03 - Referência"
      },
      {
         value: 4,
         label: "04 - Internação"
      },
      {
         value: 5,
         label: "05 - Alta"
      },
      {
         value: 6,
         label: "06 - Óbito"
      }
   ];

   accidentIndication = [
      {
         value: 0,
         label: "0 - Acidente ou doença relacionado ao trabalho"
      },
      {
         value: 1,
         label: "1 - Trânsito"
      },
      {
         value: 2,
         label: "2 - Outros"
      },
      {
         value: null,
         label: "Não Acidente"
      }
   ];

   formGroupCustomer!: UntypedFormGroup;
   formGroupUnity!: UntypedFormGroup;
   formGroupCompany!: UntypedFormGroup;
   formGroupHospitalInformation!: UntypedFormGroup;
   formGroupCollectionPlace!: UntypedFormGroup;
   formGroupAgreementRequest!: UntypedFormGroup;
   formGroupChangeData!: UntypedFormGroup;
   formIncludeAnalyte!: UntypedFormGroup;
   formQuestions!: UntypedFormGroup;
   formGroupCreateAgreementRequest!: UntypedFormGroup;
   formClinicalInformation!: UntypedFormControl;
   formAttendanceExternal!: UntypedFormControl;
   formRequestExternal!: UntypedFormControl;
   formTypePayment!: { [key: number]: UntypedFormGroup };
   formPaymentResume!: UntypedFormGroup;
   formCollectResume!: UntypedFormGroup;
   formOperation = new UntypedFormGroup({
      addition: new UntypedFormControl(null),
      discount: new UntypedFormControl(null)
   });

   formSetRoutine = new UntypedFormControl();
   formSetStatus = new UntypedFormControl();
   formSetCollectDate = new UntypedFormControl();
   formSetCollectDateToAll = new UntypedFormControl();
   formSetAnalyteDeadline = new UntypedFormControl();

   formDeadline = new UntypedFormGroup({
      examDeadline: new UntypedFormControl(null),
      urgentDeadline: new UntypedFormControl(false),
      emergencyDeadline: new UntypedFormControl(false)
   });
   formDiscountAuthorization = new UntypedFormGroup({
      discountReason: new UntypedFormControl(null)
   });
   registerAgreementExamsRequest: Array<Object> = [];
   registerAgreementRequest: Array<Object> = [];
   registerQuestions: Array<Object> = [];
   registerFinancial: Array<Object> = [];
   packageExams: Array<Object> = [];
   allPackageExams: Array<Object> = [];
   genderOptions: Array<EnumObject> = [];
   unityOptions: Array<Object> = [];
   companyOptions: Array<Object> = [];
   collectionPlaceOptions: Array<SelectOptions> = [];
   agreementOptions: Array<{
      value: string;
      label: string;
      controlPlan: boolean;
      typeInvoice?: number;
   }> = [];
   agremeentPlanOptions: Array<SelectOptions> = [];
   doctorOptions: Array<SelectOptions> = [];
   examOptions: Array<any> = [];
   collectionPlaceOriginOptions: Array<SelectOptions> = [];
   collectionPlaceInstructionOptions: Array<SelectOptions> = [];
   mnemonicOptions: Array<SelectOptions> = [];
   materialExamsOptions: Array<SelectOptions> = [];
   updateAnalyteMaterialOptions: Array<any> = [];
   updateAnalyteRoutineOptions: Array<any> = [
      {
         value: 0,
         label: "Normal"
      },
      {
         value: 1,
         label: "Urgência"
      },
      {
         value: 2,
         label: "Emergência"
      }
   ];

   allQuestionsList: Array<Question> = [];
   allAgreementRequestExams: Array<Object> = [];
   formGroupAllQuestionsAnswer: Array<UntypedFormGroup> = [];
   typePaymentsList: Array<Object> = [];
   invoiceProgressHistory: Array<InvoiceProgress> = [];
   resumeCollectExams: Array<Object> = [];
   updateAgreementExamsRequestForm!: UntypedFormGroup;
   examsSubscription!: Subscription;
   continuousMedicationSubscription!: Subscription;
   agreementSubscription!: Subscription;
   agreementPlanSubscription!: Subscription;
   agreementRequestSubscription!: Subscription;
   collectionPlaceSubscription!: Subscription;
   typeSubscription!: Subscription;
   doctorSubscription!: Subscription;
   agreementRequestExamsFormListSubscription!: Subscription;
   includeAnalyteFormSubscription!: Subscription;
   questionSubscription!: Subscription;
   allQuestionsSubscription!: Subscription;
   formGroupAllQuestionAnswersSubscription!: Subscription;
   formGroupCreateAgreementRequestSubscription!: Subscription;
   typePaymentsListSubscription!: Subscription;
   formTypePaymentSubscription!: Subscription;
   formPaymentResumeSubscription!: Subscription;
   collectRequestExams: Array<Object> = [];
   invoiceProgressHistorySubscription!: Subscription;
   cleanAgreementSubscription!: Subscription;
   cleanDoctorSubscription!: Subscription;
   resumeCollectExamsSubscription!: Subscription;
   focusInputCode!: Subscription;
   biggestPromiseSubscription!: Subscription;
   promisesSubscription!: Subscription;
   companiesSubscription!: Subscription;
   unitiesSubscription!: Subscription;
   focusInputAdvice!: Subscription;
   agreementExamsSupportRequest!: Subscription;
   updateAgreementExamsRequestFormSubscription!: Subscription;
   collectExamsPaginationSubscription!: Subscription;
   examOptionsSubscription!: Subscription;
   agreementExamsSubscription!: Subscription;
   form = {
      medication: new UntypedFormControl(null),
      manualMedication: new UntypedFormControl(null)
   };

   total: number = 0;
   page: number = 1;
   index: number = 50;
   customerId: string = "";
   allcustomerMedicines: Array<any> = [];
   medicationsCustomer: Array<any> = [];
   agreementExamsSupport: Array<any> = [];
   isInvoiced: boolean = false;

   collectExamsPagination = {
      sumRecords: 0,
      page: 1,
      index: 50
   };

   Extras: Array<Object> = [
      {
         type: "svg",
         action: "Select",
         content: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2_97)">
        <rect x="0.501465" y="0.0512695" width="21" height="21" rx="10.5" fill="#808080"/>
        <rect x="3.50146" y="3.05127" width="15" height="15" rx="7.5" fill="#F5F5F5"/>
        <rect x="5.50146" y="9.05127" width="11" height="3" rx="1.5" fill="#808080"/>
        <rect x="9.50146" y="5.05127" width="3" height="11" rx="1.5" fill="#808080"/>
        </g>
        <defs>
        <clipPath id="clip0_2_97">
        <rect width="21" height="21" fill="white" transform="translate(0.501465 0.0512695)"/>
        </clipPath>
        </defs>
      </svg>`
      }
   ];

   colectionPlaceOption: Array<any> = [];

   sectorOption: Array<sectorOption> = [];

   @ViewChild("codeAnalyte", { static: false })
   codeAnalyte!: InputPropComponent;
   @ViewChildren(InputSearchComponent) comp!: QueryList<InputSearchComponent>;
   @ViewChildren(InputSearchComponent)
   inputSearchComponents!: QueryList<InputSearchComponent>;
   @ViewChildren(InputPropComponent)
   inputPropComponents!: QueryList<InputPropComponent>;
   @ViewChild("dialogModalDiscountAuthorization")
   dialogModalDiscountAuthorization!: TemplateRef<any>;
   @ViewChild("dialogModalPackage") dialogModalPackage!: TemplateRef<any>;
   @ViewChild("dialogModalMedication") dialogModalMedication!: TemplateRef<any>;
   @ViewChild("dialogModalManualMedication")
   dialogModalManualMedication!: TemplateRef<any>;
   @ViewChild("dialogModalQuestion") dialogModalQuestion!: TemplateRef<any>;
   @ViewChild("dialogModalAllQuestions")
   dialogModalAllQuestions!: TemplateRef<any>;
   @ViewChild("dialogModalAllInstructions")
   dialogModalAllInstructions!: TemplateRef<any>;
   @ViewChild("dialogModalInvoiceProgressHistory")
   dialogModalInvoiceProgressHistory!: TemplateRef<any>;
   @ViewChild("dialogModalOperations") dialogModalOperations!: TemplateRef<any>;
   @ViewChild("dialogModalChangeData") dialogModalChangeData!: TemplateRef<any>;
   @ViewChild("dialogModalDeadline") dialogModalDeadline!: TemplateRef<any>;
   @ViewChild("focusCode", { static: false }) focusCode!: InputPropComponent;
   @ViewChild("focusAdvice", { static: false })
   focusAdvice!: InputPropComponent;
   @ViewChild("dialogModalAgreementExamsRequest")
   dialogModalAgreementExamsRequest!: TemplateRef<any>;
   @ViewChild("dialogModalSetCollectDate")
   dialogModalSetCollectDate!: TemplateRef<any>;
   @ViewChild("dialogModalSetRoutine") dialogModalSetRoutine!: TemplateRef<any>;
   @ViewChild("dialogModalSetStatus") dialogModalSetStatus!: TemplateRef<any>;
   @ViewChild("dialogModalSetAnalyteDeadline")
   dialogModalSetAnalyteDeadline!: TemplateRef<any>;

   @ViewChild("dialogShowAgreementInformation")
   dialogShowAgreementInformation!: TemplateRef<any>;

   ActionsExtra = {
      Ativo: "toggleActive",
      Ação: "check",
      "requestResume.resolveCollectResumeExams.analyteDeadLine":
         "DeadlineDialog",
      "requestResume.resolveCollectResumeExams.actionCollect":
         "toggleSituation",
      "requestResume.resolveCollectResumeExams.routine": "toggleRoutine",
      "requestResume.resolveCollectResumeExams.collectDate": "setCollectDate"
   };

   ActionsExtraQuestions = {
      Ativo: "toggleActive"
   };

   ActionsExtraRequest = {
      Ativo: "toggleActive"
   };

   ActionsExtraExamsRequest = {
      Ativo: "toggleActive"
   };

   ActionsExtraMedication = {
      Ativo: "PutActive"
   };

   ActionsExtraPackage = {
      Selecionar: "check"
   };

   ActionsExtraAgreementExamsSupportLaboratory = {
      Selecionar: "check"
   };

   constructor(
      private _saveRequisitionFacade: SaveRequisitionFacade,
      private _dialog: MatDialog,
      private _router: ActivatedRoute,
      private _route: Router,
      private _medicinesService: MedicinesService,
      private _activePipe: GetActiveFilterPipe,
      private _localaDatePipe: LocalaDatePipe,
      private _collectionPlaceOriginService: CollectionPlaceOriginService,
      private _util: Util,
      private _collectionPlaceInstructionService: CollectionPlaceInstructionService,
      private _sectorService: SectorService,
      private _toastService: ToastService
   ) {}

   async getSector() {
      const queryString = { IsActive: true };
      await this._sectorService
         .getPagination(queryString)
         .then((response: any) => {
            response.data.forEach((data: any) => {
               this.sectorOption.push({
                  value: data.id,
                  label: data.description,
                  externalId: data.externalId
               });
            });
         });
   }

   async ngOnInit() {
      this.getSector();
      let urlParams: string[] = this._router.snapshot.params["id"].split("$");
      this.requisitionId = new Guid(urlParams[0]);

      if (urlParams[1] != undefined) {
         switch (urlParams[1]) {
            case "D":
               this.actualPage = 0;
               break;
            case "P":
               this.actualPage = 1;
               break;
            case "R":
               this.actualPage = 2;
               break;
         }
      }
      this.openNewAgreement = false;
      if (urlParams[2] != undefined && urlParams[2] == "NP") {
         this.openNewAgreement = true;
      }

      this.formGroupCustomer = this._saveRequisitionFacade.getCustomerForm;
      this.formGroupUnity = this._saveRequisitionFacade.getUnityForm;
      this.formGroupCompany = this._saveRequisitionFacade.getCompanyForm;
      this.formGroupHospitalInformation =
         this._saveRequisitionFacade.getHospitalForm;
      this.formGroupCollectionPlace =
         this._saveRequisitionFacade.getCollectionPlaceForm;
      this.formGroupAgreementRequest =
         this._saveRequisitionFacade.getAgreementRequestForm;
      this.formGroupChangeData = this._saveRequisitionFacade.getChangeDataForm;
      this.formIncludeAnalyte =
         this._saveRequisitionFacade.getIncludeAnalyteForm;
      this.formQuestions = this._saveRequisitionFacade.getQuestionsForm;
      this.formCollectResume = this._saveRequisitionFacade.getCollectForm;

      //#region Subscriptions
      this.updateAgreementExamsRequestFormSubscription =
         this._saveRequisitionFacade.updateAgreementExamsRequestForm$.subscribe(
            (agreementExamsRequestForm) => {
               this.updateAgreementExamsRequestForm = agreementExamsRequestForm;
            }
         );

      this.agreementExamsSupportRequest =
         this._saveRequisitionFacade.agreementExamsRequest$.subscribe(
            (agreementExamsRequest) => {
               this.registerAgreementExamsRequest = agreementExamsRequest;
            }
         );

      this.cleanAgreementSubscription =
         this._saveRequisitionFacade.cleanAgreement$.subscribe(
            (cleanAgreement) => {
               this.cleanAgreement = cleanAgreement;
            }
         );

      this.cleanDoctorSubscription =
         this._saveRequisitionFacade.clearDoctor$.subscribe((cleanDoctor) => {
            this.cleanDoctor = cleanDoctor;
         });

      this.agreementRequestSubscription =
         this._saveRequisitionFacade.registerAgreementRequest$.subscribe(
            (agreementRequests) => {
               this.registerAgreementRequest = agreementRequests;
               this.registerAgreementRequest.length === 0
                  ? (this.actualPage = 0)
                  : (this.actualPage = 1);
               this.indexMatTabGroup(this.registerAgreementRequest.length);
            }
         );

      this.questionSubscription =
         this._saveRequisitionFacade.questions$.subscribe((questions) => {
            this.registerQuestions = questions;
         });

      this.collectionPlaceSubscription =
         this._saveRequisitionFacade.collectionPlace$.subscribe(
            (collectionPlace) => {
               this.collectionPlaceOptions = collectionPlace;
            }
         );

      this.allQuestionsSubscription =
         this._saveRequisitionFacade.allQuestions$.subscribe((allQuestions) => {
            this.allQuestionsList = allQuestions;
         });

      this.formGroupAllQuestionAnswersSubscription =
         this._saveRequisitionFacade.formGroupAllQuestionAnswers$.subscribe(
            (formGroupAllQuestionAnswers) => {
               this.formGroupAllQuestionsAnswer = formGroupAllQuestionAnswers;
            }
         );

      this.typeSubscription = this._saveRequisitionFacade.type$.subscribe(
         (type) => {
            this.type = type;
         }
      );

      this.includeAnalyteFormSubscription =
         this._saveRequisitionFacade.includeAnalyte$.subscribe(
            (newAnalyteFormGroup) => {
               this.formIncludeAnalyte = newAnalyteFormGroup;
            }
         );

      this.formGroupCreateAgreementRequestSubscription =
         this._saveRequisitionFacade.formGroupCreateAgreementRequest$.subscribe(
            (formGroupCreateAgreementRequest) => {
               this.formGroupCreateAgreementRequest =
                  formGroupCreateAgreementRequest;
            }
         );

      this.typePaymentsListSubscription =
         this._saveRequisitionFacade.typePaymentsList$.subscribe(
            (typePaymentsList) => {
               this.typePaymentsList = typePaymentsList;
            }
         );

      this.formTypePaymentSubscription =
         this._saveRequisitionFacade.formTypePayment$.subscribe(
            (typePaymentForm) => {
               this.formTypePayment = typePaymentForm;
            }
         );

      this.formPaymentResumeSubscription =
         this._saveRequisitionFacade.formPaymentResume$.subscribe(
            (formPaymentResume) => {
               this.formPaymentResume = formPaymentResume;
            }
         );

      this._saveRequisitionFacade.allAgreementRequestExams$.subscribe(
         (allAgreementRequestExams) => {
            this.allAgreementRequestExams = allAgreementRequestExams;
         }
      );

      this.invoiceProgressHistorySubscription =
         this._saveRequisitionFacade.invoiceHistoryProgress$.subscribe(
            (invoiceProgress) => {
               this.invoiceProgressHistory = invoiceProgress;
            }
         );

      this.resumeCollectExamsSubscription =
         this._saveRequisitionFacade.resumeCollectExams$.subscribe(
            (resumeCollectExams) => {
               this.resumeCollectExams = resumeCollectExams;
            }
         );

      this.biggestPromiseSubscription =
         this._saveRequisitionFacade.biggestPromise$.subscribe(
            (biggestPromise) => {
               this.biggestPromise = biggestPromise;
            }
         );

      this.promisesSubscription =
         this._saveRequisitionFacade.promise$.subscribe((promises) => {
            this.promises = promises;
         });

      this.unitiesSubscription =
         this._saveRequisitionFacade.unityOptions$.subscribe((unityOptions) => {
            this.unityOptions = unityOptions;
         });

      this.companiesSubscription =
         this._saveRequisitionFacade.companyOptions$.subscribe(
            (companyOptions) => {
               this.companyOptions = companyOptions;
            }
         );

      this.continuousMedicationSubscription = this.formGroupCustomer.controls[
         "continuousMedication"
      ].valueChanges.subscribe(() => {
         this.getMedications();
      });

      this.examsSubscription =
         this._saveRequisitionFacade.agreementRequestExamsOptions$.subscribe(
            (AllexamsOptions) => {
               this.exams = AllexamsOptions;
            }
         );

      this.examOptionsSubscription =
         this._saveRequisitionFacade.examsOptions$.subscribe((examsOptions) => {
            this.examOptions = examsOptions;
         });

      this.agreementExamsSubscription =
         this._saveRequisitionFacade.agreementExamsSupportRequestOptions$.subscribe(
            (agreementExams) => {
               this.agreementExamsSupport = agreementExams;
            }
         );

      this.focusInputCode =
         this._saveRequisitionFacade.focusInputCode$.subscribe(() => {
            this.setFocusToCodeInputCode();
         });

      this.focusInputAdvice =
         this._saveRequisitionFacade.focusInputAdvice$.subscribe(() => {
            this.setFocusToCodeInputAdvice();
         });
      //#endregion
      await this.getGetCollectionPlaceOrigin();
      this.genderOptions = await this._util.getEnum("EGenderType");
      this.EInvoiceStatus = await this._util.getEnum("EInvoiceStatus");
      this.ETypePayments = await this._util.getEnum("ETypePayment");
      await this.getCollectionInstruction();

      const obj = {
         companyId: this.formGroupCompany.value.id,
         unityId: this.formGroupUnity.value.id,
         collectionPlaceId: this.formGroupCollectionPlace.value.id,
         requisitionNumber: this.requisitionNumber
      };
      localStorage.setItem("lastRequisition", JSON.stringify(obj));
   }

   async getGetCollectionPlaceOrigin() {
      const queryObj = { isActive: true };
      await this._collectionPlaceOriginService
         .getPagination<CollectionPlaceOriginPagination>(queryObj)
         .then((response) => {
            response.data.forEach((origin) => {
               const collectionObj = {
                  value: origin.id,
                  label: origin.description
               };
               this.collectionPlaceOriginOptions.push(collectionObj);
            });
         });
   }

   async getCollectionInstruction() {
      const queryObj = { isActive: true };
      await this._collectionPlaceInstructionService
         .getPagination<CollectionPlaceInstructionPagination>(queryObj)
         .then((response) => {
            response.data.forEach((instruction) => {
               const collectionObj = {
                  value: instruction.id,
                  label: instruction.description
               };
               this.collectionPlaceInstructionOptions.push(collectionObj);
            });
         });
   }

   async indexMatTabGroup(indexControl?: any) {
      return indexControl === 0 ? 0 : 1;
   }

   async confirmPayment() {
      await this._saveRequisitionFacade.confirmPayment();
   }

   async paymentOperation(typeOperation: InvoiceStatus, hashProcess?: string) {
      const discountValue = this.formOperation.controls["discount"].value;
      let control = { success: true };
      switch (typeOperation) {
         case InvoiceStatus.Addition:
            await this._saveRequisitionFacade.paymentOperation(
               typeOperation,
               this.formOperation.controls["addition"].value
            );
            break;
         case InvoiceStatus.Discount:
            await this._saveRequisitionFacade.paymentOperation(
               typeOperation,
               this.formOperation.controls["discount"].value,
               undefined,
               control
            );
            break;
         case InvoiceStatus.Reversed:
            await this._saveRequisitionFacade.paymentOperation(
               typeOperation,
               null,
               hashProcess
            );
            break;
         default:
            break;
      }

      this.close();
      this.formOperation.reset();
      if (!control.success) {
         this.openDialogModalDiscount(discountValue);
      }
   }

   formatCurrencyValue(control: UntypedFormControl) {
      let value = control.value;
      value = value
         .replace(/[^0-9,\.]/g, "")
         .replace(/,(?=.*?,)/g, "")
         .replace(/(.*)(?=,)/g, "$1")
         .replace(/,/, ".");

      return control.setValue(
         formatCurrency(value > 0 ? value : 0, "pt-BR", "")
      );
   }

   async back() {
      await this._saveRequisitionFacade.backToVisualization();
      this._route.navigateByUrl(
         localStorage.getItem("autosave://lastURL") ?? ""
      );
   }

   async ngAfterViewInit() {
      this._saveRequisitionFacade.getCollectionPlace();
      await this._saveRequisitionFacade.getRequisitionById(this.requisitionId);
      this._saveRequisitionFacade.getCompanies();

      this.requisitionNumber = this._saveRequisitionFacade.getRequisitionNumber;
      this.formClinicalInformation =
         this._saveRequisitionFacade.getClinicalInformation;

      this.formAttendanceExternal =
         this._saveRequisitionFacade.getAttendanceExternal;
      this.formRequestExternal = this._saveRequisitionFacade.getRequestExternal;

      this.unityOptions = this._saveRequisitionFacade.getUnityOptions;
      this.companyOptions = this._saveRequisitionFacade.getCompanyOptions;
      this.mnemonicOptions = this._saveRequisitionFacade.getMnemonicOptions;
      this.materialExamsOptions =
         this._saveRequisitionFacade.getMaterialExamsOptions;

      this.customerId = this._saveRequisitionFacade.getCustumerId;
      this.getMedications();

      if (this.openNewAgreement) {
         this.agreementSubscription =
            this._saveRequisitionFacade.agreement$.subscribe((agreements) => {
               this.agreementOptions = agreements;
            });

         this.agreementPlanSubscription =
            this._saveRequisitionFacade.agreementPlan$.subscribe(
               (agreementPlans) => {
                  this.agremeentPlanOptions = agreementPlans;
               }
            );

         this.doctorSubscription =
            this._saveRequisitionFacade.doctor$.subscribe((doctors) => {
               this.doctorOptions = doctors;
            });

         this.newAgreementRequest();
      }
   }

   async getTypePayments() {
      await this._saveRequisitionFacade.getTypePayments();
   }

   resolveGenericLocal(data: any, columns: any) {
      return this._saveRequisitionFacade.resolveGenericLocal(data, columns);
   }

   setCollectDate(emit: any) {
      this._saveRequisitionFacade.setCollectDate(
         emit,
         this.formSetCollectDate.value,
         this.formSetCollectDateToAll.value
      );
      this.formSetCollectDateToAll.reset();
      this.formSetCollectDate.reset();
      this._dialog.closeAll();
   }

   async getUnities() {
      await this._saveRequisitionFacade.getUnities();
   }

   close() {
      this._dialog.closeAll();
   }

   async openDialogModalAllInstructions(analyteId?: string) {
      const analytesPreparation: Array<Object> =
         await this._saveRequisitionFacade.getAllAnalyteInstructions(analyteId);

      if (analytesPreparation.length != 0) {
         const myTempDialog = this._dialog.open(
            this.dialogModalAllInstructions,
            {
               width: "660px",
               maxHeight: "660px",
               data: analytesPreparation
            }
         );
      } else {
         this._toastService.warningMessage("requestResume.msgInfo");
      }
   }

   openDialogModalAgreementExamsRequest(id: string) {
      const myTempDialog = this._dialog.open(
         this.dialogModalAgreementExamsRequest,
         {
            width: "700px",
            maxHeight: "660px",
            data: id
         }
      );
   }

   openDialogModalPackage(): void {
      const myTempDialog = this._dialog.open(this.dialogModalPackage, {
         width: "660px"
      });
   }

   openDialogModalDiscount(value?: string): void {
      this.formDiscountAuthorization.controls["discountReason"].reset();
      const myTempDialog = this._dialog.open(
         this.dialogModalDiscountAuthorization,
         {
            width: "660px",
            data: value
         }
      );
   }

   openSetAnalyteDeadlineModal(): void {
      const myTempDialog = this._dialog.open(
         this.dialogModalSetAnalyteDeadline,
         {
            width: "660px"
         }
      );
   }

   openCollectDateModal(emit: any): void {
      this.formSetCollectDate.setValue(emit.object["_hide_collectDate"]);
      const myTempDialog = this._dialog.open(this.dialogModalSetCollectDate, {
         width: "660px",
         data: emit
      });
   }

   openSetStatusModal(): void {
      const myTempDialog = this._dialog.open(this.dialogModalSetStatus, {
         width: "660px"
      });
   }

   openSetRoutineModal(): void {
      const myTempDialog = this._dialog.open(this.dialogModalSetRoutine, {
         width: "660px"
      });
   }

   openDialogModalDeadline(emit: any): void {
      const myTempDialog = this._dialog.open(this.dialogModalDeadline, {
         width: "660px",
         data: emit
      });
   }

   openDialogModalChangeData(emit: Object): void {
      const myTempDialog = this._dialog.open(this.dialogModalChangeData, {
         width: "660px",
         data: emit
      });
   }

   openDialogModalQuestion(description: string, id: Guid): void {
      const myTempDialog = this._dialog.open(this.dialogModalQuestion, {
         width: "660px",
         data: { description, id }
      });
   }

   openDialogModalInvoiceProgressHistory(): void {
      const myTempDialog = this._dialog.open(
         this.dialogModalInvoiceProgressHistory,
         {
            width: "960px",
            maxHeight: "660px"
         }
      );
   }

   openDialogModalOperations(operationType: any): void {
      const myTempDialog = this._dialog.open(this.dialogModalOperations, {
         width: "660px",
         data: operationType
      });
   }

   openDialogModalAllQuestions(event: any): void {
      const myTempDialog = this._dialog.open(this.dialogModalAllQuestions, {
         width: "660px",
         maxHeight: event.view.innerHeight > 764 ? "750px" : "500px"
      });
   }

   questionActions(emit: any) {
      switch (emit.action) {
         case "Responder":
            this.formQuestions.controls["answer"].setValue(
               emit.object["Resposta"]
            );
            this.openDialogModalQuestion(
               emit.object["requestResume.questions.name"],
               emit.object["id"]
            );
            break;
      }
   }

   async invoiceProgressActions(emit: any) {
      switch (emit.action) {
         case "Estornar":
            await this.paymentOperation(
               InvoiceStatus.Reversed,
               emit.object["Hash Transação"]
            );
            break;
      }
   }

   setDoctorCode(doctorId: string) {
      this._saveRequisitionFacade.setDoctorCode(doctorId);
   }

   toggleRoutineGlobally(): void {
      this.examsGlobalRoutine =
         this.examsGlobalRoutine >= 2 ? 0 : this.examsGlobalRoutine + 1;
      this._saveRequisitionFacade.toggleRoutineGlobally(
         this.examsGlobalRoutine
      );
   }

   setAnalyteDeadlineGlobally(): void {
      this._dialog.closeAll();
      this._saveRequisitionFacade.setAnalyteDeadlineGlobally(
         this.formSetAnalyteDeadline.value
      );
   }

   toggleStatusGlobally(): void {
      const situations = [0, 1, 3, 6];
      const index = situations.findIndex((x) => x == this.examsGlobalSituation);
      this.examsGlobalSituation = situations[index >= 3 ? 0 : index + 1];
      this._saveRequisitionFacade.toggleStatusGlobally(
         this.examsGlobalSituation
      );
   }

   async checkControlPlan(agreementId: string) {
      if (agreementId) {
         const agreementObject = this.agreementOptions.find(
            (agreement) => agreement.value === agreementId
         );
         if (agreementObject) {
            agreementObject.typeInvoice === 2
               ? (this.isInvoiced = true)
               : (this.isInvoiced = false);
            this.agreementControlPlan = agreementObject.controlPlan;
            if (this.agreementControlPlan)
               await this._saveRequisitionFacade.getAgreementPlan(agreementId);
         } else {
            this._toastService.fireError("requisitionMedicalRequest.msgInfo");
         }
         this._saveRequisitionFacade.setAgreementCode(agreementId);
         this.information.setValue(
            this.formGroupCreateAgreementRequest.value.information
         );
         if (this.information.value != null)
            this.openModalAgreementInformation();
      }
   }

   openModalAgreementInformation() {
      const myTempDialog = this._dialog.open(
         this.dialogShowAgreementInformation,
         {
            data: "Convênio",
            width: "660px"
         }
      );
      myTempDialog.afterClosed().subscribe((res) => {
         this.setFocusToPlan();
      });
   }

   async updateAnalyteAgreementExamsRequest(agreementExamsRequestId: string) {
      const updateAgreementExamsRequestObject = {
         materialId:
            this.updateAgreementExamsRequestForm.controls["materialId"].value,
         routine: JSON.parse(
            this.updateAgreementExamsRequestForm.controls["routine"].value
         ),
         requestPending:
            this.updateAgreementExamsRequestForm.controls["requestPending"]
               .value,
         collectionPlaceOriginId:
            this.updateAgreementExamsRequestForm.controls[
               "collectionPlaceOriginId"
            ].value,
         collectionPlaceInstructionId:
            this.updateAgreementExamsRequestForm.controls[
               "collectionPlaceInstructionId"
            ].value
      };

      await this._saveRequisitionFacade.updateAnalyteAgreementExamsRequest(
         agreementExamsRequestId,
         updateAgreementExamsRequestObject
      );
   }

   async agreementRequestExamsActionsList(emit: any) {
      switch (emit.action) {
         case "Excluir":
            await this.deleteAnalyteAgreementExamsRequest(emit.object.id);
            break;
         case "Editar":
            await this._saveRequisitionFacade.getAnalyteMaterial(
               emit.object._hide_analyteId
            );
            const materialOptions =
               this._saveRequisitionFacade.findAnalyteMaterialOptions(
                  emit.object._hide_analyteId
               );
            if (materialOptions)
               this.updateAnalyteMaterialOptions = materialOptions;

            this.updateAgreementExamsRequestForm.controls[
               "materialId"
            ].setValue(emit.object._hide_materialId);
            this.updateAgreementExamsRequestForm.controls[
               "analyteName"
            ].setValue(emit.object._hide_analyteName);
            this.updateAgreementExamsRequestForm.controls["routine"].setValue(
               JSON.parse(emit.object._hide_routine)
            );
            this.updateAgreementExamsRequestForm.controls[
               "collectionPlaceInstructionId"
            ].setValue(emit.object._hide_collectionPlaceInstructionId);
            this.updateAgreementExamsRequestForm.controls[
               "requestPending"
            ].setValue(emit.object["_hide_requestPending"] === "true");
            this.updateAgreementExamsRequestForm.controls[
               "collectionPlaceOriginId"
            ].setValue(emit.object._hide_collectionPlaceOriginId);
            this.openDialogModalAgreementExamsRequest(emit.object.id);
            break;
      }
   }

   async agreementRequestActions(emit: any) {
      if (emit.action === "Editar") {
         this.formGroupCustomer.value.agreementRequest.forEach(
            (agreement: any) => {
               if (emit.object._hide_agreementId === agreement.id)
                  agreement.TypeInvoice === 2
                     ? (this.isInvoiced = true)
                     : (this.isInvoiced = false);
            }
         );
      }
      this._saveRequisitionFacade.agreementRequestActions(emit);
      if (this.agreementSubscription == undefined) {
         this.agreementSubscription =
            this._saveRequisitionFacade.agreement$.subscribe((agreements) => {
               this.agreementOptions = agreements;
            });

         this.agreementPlanSubscription =
            this._saveRequisitionFacade.agreementPlan$.subscribe(
               (agreementPlans) => {
                  this.agremeentPlanOptions = agreementPlans;
               }
            );

         this.doctorSubscription =
            this._saveRequisitionFacade.doctor$.subscribe((doctors) => {
               this.doctorOptions = doctors;
            });
      }
   }

   async agreementRequestExamsActions(emit: any, parentId: string) {
      await this._saveRequisitionFacade.agreementRequestExamsActions(
         emit,
         parentId
      );
   }

   examsActions(emit: any) {
      switch (emit.action) {
         case "DeadlineDialog":
            const deadline = emit.object["_hide_analyteDeadline"]
               ? new Date(emit.object["_hide_analyteDeadline"])
               : new Date(Date.now());
            const value = deadline.toString().replace(/\+00:00$/, "");
            let date = new Date(Date.parse(value + "Z"));
            if (date instanceof Date) {
               if (
                  date
                     .toISOString()
                     .split("T")[1]
                     .replace(/[^1-9]/g, "") == ""
               )
                  date = new Date(
                     date.getUTCFullYear(),
                     date.getUTCMonth(),
                     date.getUTCDate()
                  );
            }
            this.formDeadline.controls["examDeadline"].setValue(date);
            this.openDialogModalDeadline(emit);
            break;
         // case 'check':
         //   this._saveRequisitionFacade.changeCollectAction(emit);
         //   break;
         case "toggleSituation":
            this.examsGlobalSituation = -1;
            this._saveRequisitionFacade.toggleSituationAction(emit);
            break;

         case "toggleRoutine":
            this.examsGlobalRoutine = -1;
            this._saveRequisitionFacade.toggleRoutineAction(emit);
            break;

         case "setCollectDate":
            this.openCollectDateModal(emit);
            break;

         default:
            break;
      }
   }

   async updateAgreementExamsRequest(id: string, analyteDeadline: Date) {
      this.examsGlobalDeadline = "Definir";
      await this._saveRequisitionFacade.updateAgreementExamsRequest(
         id,
         analyteDeadline
      );
   }

   async updateAgreementExamsRequestMultiple(emit: any) {
      const body = {
         doctorId: this.formGroupChangeData.controls["doctorId"].value,
         agreementId: this.formGroupChangeData.controls["agreement"].value,
         agreementPlanId:
            this.formGroupChangeData.controls["agreementPlanId"].value,
         code: this.formGroupChangeData.controls["code"].value,
         agreementExamsRequestId: [emit.object.object.id]
      };

      await this._saveRequisitionFacade.updateAgreementExamsMultiple(
         emit.agreementRequest.id,
         body
      );
   }

   async newAgreementRequest() {
      await this._saveRequisitionFacade.newAgreementRequest();
   }

   async getAgreementExamsRequest() {
      await this._saveRequisitionFacade.getAgreementExamsRequest();
   }

   async createAgreementExamsRequest() {
      await this._saveRequisitionFacade.createAgreementExamsRequest();
      if (this.codeAnalyte) this.codeAnalyte.focusInput();
   }

   async deleteAnalyteAgreementExamsRequest(agreementExamsRequestId: string) {
      await this._saveRequisitionFacade.deleteAnalyteAgreementExamsRequest(
         agreementExamsRequestId
      );
   }

   async createAgreementRequest() {
      await this._saveRequisitionFacade.createAgreementRequest();
   }

   async saveAllQuestionAnswer() {
      this._saveRequisitionFacade.saveAllQuestionAnswer();
   }

   async saveQuestionAnswer(questionId: string) {
      await this._saveRequisitionFacade.saveQuestionAnswer(questionId);
   }

   setFocusToCodeInputCode() {
      if (this.focusCode) this.focusCode.focusInput();
   }

   setFocusToCodeInputAdvice() {
      if (this.focusAdvice) this.focusAdvice.focusInput();
   }

   setFocusToCodeInputSearch() {
      const queryListArray: Array<InputSearchComponent> = this.comp.toArray();
      const codeInputProp = queryListArray.find(
         (inputProp) => inputProp.placeholder === "Código"
      );
      if (codeInputProp) {
         codeInputProp.input.nativeElement.focus();
      }
   }

   setFocusToMaterial() {
      const queryListArray: Array<InputSearchComponent> =
         this.inputSearchComponents.toArray();
      const codeInputProp = queryListArray.find(
         (inputProp) =>
            inputProp.placeholder ===
            "requisitionMedicalRequest.addExam.materialId"
      );
      if (codeInputProp) {
         setTimeout(() => {
            codeInputProp.input.nativeElement.focus();
            codeInputProp.input.nativeElement.click();
         }, 10);
      }
   }

   setFocusToDoctor() {
      const queryListArray: Array<InputSearchComponent> =
         this.inputSearchComponents.toArray();
      const codeInputProp = queryListArray.find(
         (inputProp) =>
            inputProp.placeholder ===
            "requisitionMedicalRequest.requester.doctorId"
      );
      if (codeInputProp) {
         codeInputProp.input.nativeElement.focus();
      }
   }

   setFocusToAgreement() {
      const queryListArray: Array<InputSearchComponent> =
         this.inputSearchComponents.toArray();
      const codeInputProp = queryListArray.find(
         (inputProp) =>
            inputProp.placeholder ===
            "requisitionMedicalRequest.selectAgreement"
      );
      if (codeInputProp) {
         codeInputProp.input.nativeElement.focus();
      }
   }
   /**
    * Foca em um `InputProp` ou `InputSearch` baseado no `FormControl` passado como parâmetro
    */
   setFocusToControl(form: AbstractControl) {
      const queryListArray: Array<InputSearchComponent | InputPropComponent> = [
         ...this.inputSearchComponents.toArray(),
         ...this.inputPropComponents.toArray()
      ];
      const input = queryListArray.find(
         (inputComponent) => inputComponent.form == form
      );
      if (input) {
         input.input.nativeElement.focus();
      }
   }

   setFocusToPlan() {
      const queryListArray: Array<InputSearchComponent> =
         this.inputSearchComponents.toArray();
      const codeInputProp = queryListArray.find(
         (inputProp) =>
            inputProp.placeholder === "requisitionMedicalRequest.plan"
      );
      setTimeout(() => {
         if (codeInputProp) {
            codeInputProp.input.nativeElement.focus();
            codeInputProp.input.nativeElement.click();
         }
      });
   }

   setFocusSector(tab: boolean | undefined) {
      if (!tab) {
         const queryListArray: Array<InputSearchComponent> =
            this.inputSearchComponents.toArray();
         const codeInputProp = queryListArray.find(
            (inputProp) => inputProp.placeholder === "requisitionEdit.sectorId"
         );
         if (codeInputProp) codeInputProp.input.nativeElement.focus();
      }
   }

   async getDoctor() {
      await this._saveRequisitionFacade.getDoctor();
   }

   async searchDoctor(search?: string, searchByCode: boolean = false) {
      if (search)
         await this._saveRequisitionFacade.searchDoctor(search, searchByCode);

      if (this.doctorOptions.length > 0) {
         this.setFocusToDoctor();
         return;
      }
      if (searchByCode) {
         this.formGroupAgreementRequest.controls["codeDoctor"].reset();
         this.formGroupCreateAgreementRequest.controls["codeDoctor"].reset();
         if (this.type == EType.EDITAR)
            this.setFocusToControl(
               this.formGroupAgreementRequest.controls["codeDoctor"]
            );
         if (this.type == EType.NOVO)
            this.setFocusToControl(
               this.formGroupCreateAgreementRequest.controls["codeDoctor"]
            );
      }
   }

   async getAgreements() {
      await this._saveRequisitionFacade.getAgreements();
   }

   async searchSector(search: any, searchByCode: boolean, tab?: boolean) {
      if (searchByCode) {
         this.formGroupHospitalInformation.controls["sectorId"].reset();
         this.sectorOption.filter((x: any) => {
            if (search === x.externalId) {
               this.formGroupHospitalInformation.controls["sectorId"].setValue(
                  x.value
               );
            }
         });
         this.formGroupHospitalInformation.controls["sectorId"].value === null
            ? SwAlSetttings.warningMessage(
                 `Não encontramos Setor Hospitalar com Código ${search}`
              )
            : this.setFocusSector(tab);
      } else {
         this.formGroupHospitalInformation.controls["sectorExternalId"].reset();
         this.sectorOption.filter((x: any) => {
            if (search === x.value) {
               this.formGroupHospitalInformation.controls[
                  "sectorExternalId"
               ].setValue(x.externalId);
            }
         });
      }
   }

   async searchAgreement(search?: string, searchByCode?: boolean) {
      searchByCode && !search
         ? null
         : await this._saveRequisitionFacade.searchAgreement(
              search,
              searchByCode
           );

      if (this.agreementOptions.length === 0 && searchByCode) {
         this.formGroupCreateAgreementRequest.controls["codeAgreement"].reset();
         this.formGroupAgreementRequest.controls["codeAgreement"].reset();
         if (this.type === EType.NOVO)
            this.setFocusToControl(
               this.formGroupCreateAgreementRequest.controls["codeAgreement"]
            );
         if (this.type === EType.EDITAR)
            this.setFocusToControl(
               this.formGroupAgreementRequest.controls["codeAgreement"]
            );
      }
      if (!searchByCode) {
         this.setFocusToAgreement();
      } else {
         this.type === EType.EDITAR
            ? await this.checkControlPlan(
                 this.formGroupAgreementRequest.controls["agreement"].value
              )
            : await this.checkControlPlan(
                 this.formGroupCreateAgreementRequest.controls["agreement"]
                    .value
              );
         if (
            this.agreementControlPlan &&
            this.agreementOptions.length > 0 &&
            (this.formGroupCreateAgreementRequest.controls["agreement"].value ||
               this.formGroupAgreementRequest.controls["agreement"].value)
         ) {
            this.setFocusToPlan();
         }
      }
   }

   getTypeMedication(item: any) {
      if (item.inclusionType == 2) {
         return "<span class='span-styles' style='background: #006E9D; color: #F8F8FF;'>Integração</span>";
      } else if (item.inclusionType == 1) {
         return "<span class='span-styles' style='background: #07BF56; color: #F8F8FF;'>Manual</span>";
      }
      return "";
   }

   getActive(item: any) {
      return this._activePipe.transform(item.isActive);
   }

   clearAgreement() {
      this.formGroupCreateAgreementRequest.controls["agreement"].reset();
      this._saveRequisitionFacade.clearAgreement();
   }

   clearDoctor() {
      this.formGroupCreateAgreementRequest.controls["doctorId"].reset();
      this._saveRequisitionFacade.clearDoctor();
   }

   async getAgreementPlan(agreementId: string) {
      if (agreementId)
         this._saveRequisitionFacade.getAgreementPlan(agreementId);
   }

   async getAnalyteByCode(analyteId: string) {
      await this._saveRequisitionFacade.getAnalyteByCode(analyteId);

      if (this.formIncludeAnalyte.controls["code"].value) {
         const materials = this.getAnalyteMaterialOptions(
            this.formIncludeAnalyte.controls["analyteId"].value
         );
         materials?.forEach((x: any) => {
            if (x.isDefaultMaterial) {
               this.formIncludeAnalyte.controls["materialId"].setValue(x.value);
               return;
            }
         });
      }
      this.information.setValue(
         this._saveRequisitionFacade.getIncludeAnalyteForm.value.information
      );
      if (this.information.value) {
         this.openModalInformation();
      } else if (this.formIncludeAnalyte.controls["code"].value) {
         this.setFocusToMaterial();
      }
   }

   getAnalyteMaterialOptions(analyteId: string): any[] | null {
      return this._saveRequisitionFacade.findAnalyteMaterialOptions(analyteId);
   }

   async backToVisualization() {
      this._saveRequisitionFacade.backToVisualization();
      this.agreementControlPlan = false;
      this._saveRequisitionFacade.clearOptions();
   }

   getCep() {
      this._saveRequisitionFacade.getCep();
   }

   async getCodeByAnalyte(
      analyteId: string,
      index: number,
      newAnalyte?: boolean
   ) {
      await this._saveRequisitionFacade.getCodeByAnalyte(
         analyteId,
         index,
         newAnalyte
      );
      this.information.setValue(
         this._saveRequisitionFacade.getIncludeAnalyteForm.value.information
      );
      if (this.information.value) {
         this.openModalInformation();
      } else {
         this.setFocusToMaterial();
      }
   }

   openModalInformation() {
      const myTempDialog = this._dialog.open(
         this.dialogShowAgreementInformation,
         {
            data: "Exame",
            width: "660px"
         }
      );
      myTempDialog.afterClosed().subscribe(() => {
         this.setFocusToMaterial();
      });
   }

   async updateRequisition() {
      await this._saveRequisitionFacade.updateRequisition();
   }

   async updateRequisitionAgreementRequest() {
      await this._saveRequisitionFacade.updateRequisitionAgreementRequest();
   }

   ngOnDestroy() {
      this.agreementSubscription?.unsubscribe();
      this.doctorSubscription?.unsubscribe();
      this.agreementRequestSubscription?.unsubscribe();
      this.collectionPlaceSubscription?.unsubscribe();
      this.typeSubscription?.unsubscribe();
      this.includeAnalyteFormSubscription?.unsubscribe();
      this.questionSubscription?.unsubscribe();
      this.allQuestionsSubscription?.unsubscribe();
      this.agreementRequestExamsFormListSubscription?.unsubscribe();
      this.formGroupAllQuestionAnswersSubscription?.unsubscribe();
      this.formGroupCreateAgreementRequestSubscription?.unsubscribe();
      this.typePaymentsListSubscription?.unsubscribe();
      this.formTypePaymentSubscription?.unsubscribe();
      this.formPaymentResumeSubscription?.unsubscribe();
      this.biggestPromiseSubscription?.unsubscribe();
      this.formGroupCustomer.reset();
      this.formGroupCustomer.controls["genderType"].value === null
         ? this.formGroupCustomer.controls["genderType"].setValue(0)
         : null;
      this.formGroupCustomer.controls["sendEmailResult"].value === null
         ? this.formGroupCustomer.controls["sendEmailResult"].setValue(false)
         : null;
      this.formGroupCustomer.controls["sendSmsEmail"].value === null
         ? this.formGroupCustomer.controls["sendSmsEmail"].setValue(false)
         : null;
      this.formGroupCustomer.controls["continuousMedication"].value === null
         ? this.formGroupCustomer.controls["continuousMedication"].setValue(
              false
           )
         : null;
   }

   async finishRequisition() {
      await this._saveRequisitionFacade.finishRequisition();
   }

   includeCustomerToCollect(event: any = null) {
      let checkBox = null;
      event != null
         ? (checkBox = event.srcElement.checked != undefined)
         : (checkBox = true);

      if (!event || event.srcElement.checked != undefined) {
         this.checkAllExams = checkBox;
         this._saveRequisitionFacade.includeCustomerToCollect(checkBox);
         this.collectRequestExams =
            this._saveRequisitionFacade.getCollectRequestExams;
      }
   }

   async changePage(changeEvent: MatTabChangeEvent) {
      switch (changeEvent.index) {
         case 0:
            this._route.navigateByUrl(
               "MovimentacaoOld/Recepcao/Requisicoes/" +
                  this.requisitionId +
                  "$D"
            );
            break;
         case 1:
            this._saveRequisitionFacade.setDefaultValues();
            this._route.navigateByUrl(
               "MovimentacaoOld/Recepcao/Requisicoes/" +
                  this.requisitionId +
                  "$P"
            );

            this.agreementSubscription =
               this._saveRequisitionFacade.agreement$.subscribe(
                  (agreements) => {
                     this.agreementOptions = agreements;
                  }
               );

            this.agreementPlanSubscription =
               this._saveRequisitionFacade.agreementPlan$.subscribe(
                  (agreementPlans) => {
                     this.agremeentPlanOptions = agreementPlans;
                  }
               );

            this.doctorSubscription =
               this._saveRequisitionFacade.doctor$.subscribe((doctors) => {
                  this.doctorOptions = doctors;
               });

            break;
         case 2:
            await this._saveRequisitionFacade.getTypePayments();
            await this._saveRequisitionFacade.getFinancial();
            if (
               parseFloat(
                  this.formPaymentResume.controls["bruteValue"].value
                     .replace("R$", "")
                     .trim()
                     .replace(",", ".")
               ) > 0
            ) {
               this.privateAgreement = true;
            } else {
               this.privateAgreement = false;
            }
            this._route.navigateByUrl(
               "MovimentacaoOld/Recepcao/Requisicoes/" +
                  this.requisitionId +
                  "$R"
            );
            break;
         case 3:
            this._saveRequisitionFacade.getCollectResume();
            this._route.navigateByUrl(
               "MovimentacaoOld/Recepcao/Requisicoes/" +
                  this.requisitionId +
                  "$R"
            );
            break;
      }
   }

   async mnemonicChange(event: any) {
      if (event.data == "") {
         this._saveRequisitionFacade.resetNewExamForm();
      }
   }

   async materialChange(event: any) {
      if (event.data == "") {
         this._saveRequisitionFacade.resetMaterialForm();
      }
   }

   openModalMedication() {
      this.form.medication.reset();
      const myTempDialog = this._dialog.open(this.dialogModalMedication, {
         width: "660px"
      });
   }

   resolveCustomerMedication(data: any) {
      if (!data) return;
      this.medicationsCustomer = data.map((medication: any) => ({
         id: medication.id,
         Nome: medication.description,
         Ativo: medication.isActive ? "checkbox|true" : "checkbox|"
      }));
   }

   createMedicine() {
      const obj = {
         customerId: this.customerId,
         description: this.form.medication.value,
         inclusionType: 1
      };

      this._medicinesService.post(obj).then(async (res: any) => {
         if (res.success) {
            await this.getMedications();
            this.form.medication.reset();
            this._toastService.fireSuccess("requisitionEdit.msgPostMedication");
         }
      });
   }

   async actions(emit: any, medication: any) {
      switch (emit.action) {
         case "Select":
            var obj = {
               description: emit.object.Nome,
               customerId: this.customerId,
               socialReason: emit.object._hide_RazaoSocial,
               inclusionType: 2,
               isActive: true
            };
            if (medication) {
               await this._medicinesService
                  .put(obj, medication.id)
                  .then((res: any) => {
                     if (res.id) {
                        this.getMedications();
                        this._toastService.fireSuccess(
                           "requisitionEdit.msgPutMedication"
                        );
                     }
                  })
                  .catch((err) => {
                     this._toastService.fireError(err);
                  });
            } else {
               await this._medicinesService
                  .post(obj)
                  .then((res: any) => {
                     if (res.id) {
                        var obj = {
                           description: emit.object.Nome,
                           customerId: this.customerId,
                           socialReason: emit.object._hide_RazaoSocial,
                           inclusionType: 2,
                           isActive: true,
                           id: res.id
                        };
                        this._toastService.fireSuccess(
                           "requisitionEdit.msgPostMedication"
                        );
                        this.allcustomerMedicines.push(obj);
                        this.medicationsCustomer = [];
                        this.resolveCustomerMedication(
                           this.allcustomerMedicines
                        );
                        this.getMedications();
                     }
                  })
                  .catch((err) => {
                     this._toastService.fireError(err);
                  });
            }
            break;
         case "Excluir":
            await this._medicinesService
               .delete(emit.object.id)
               .then((res: any) => {
                  this._toastService.fireSuccess("requisitionEdit.msgDelete");
                  var ind = this.medicationsCustomer.findIndex(
                     (x: any) => x.id == emit.object.id
                  );
                  this.medicationsCustomer.splice(ind, 1);
                  var ind = this.allcustomerMedicines.findIndex(
                     (x: any) => x.id == emit.object.id
                  );
                  this.allcustomerMedicines.splice(ind, 1);
               })
               .catch((err) => {
                  this._toastService.fireError(err);
               });
            break;
         case "Editar":
            this.form.manualMedication.setValue(emit.object["Nome"]);
            const myTempDialog = this._dialog.open(
               this.dialogModalManualMedication,
               {
                  width: "660px",
                  data: {
                     id: emit.object.id
                  }
               }
            );
            break;
         case "PutActive":
            this._medicinesService
               .patchActive(emit.object.id, emit.object.Ativo)
               .then((x: any) => {
                  this._toastService.fireSuccess("global.msgSituationSuccess");
               })
               .catch((err) => this._toastService.fireError(err));
            break;
         default:
            break;
      }
   }

   async getMedications(params?: number[]) {
      if (params) {
         this.paginationMedicines.index = params[1];
         this.paginationMedicines.page = params[0];
      }
      const query = {
         page: this.paginationMedicines.page,
         numberRegistry: this.paginationMedicines.index,
         customerId: this.customerId
      };
      if (
         this.formGroupCustomer.controls["continuousMedication"].value ==
            true &&
         this.customerId
      ) {
         await this._medicinesService.getPagination(query).then((res: any) => {
            this.resolveCustomerMedication(res.data);
            this.paginationMedicines.sumRecords = res.sumRecords;
         });
      }
   }

   saveManualMedication(medicationId: Guid) {
      var obj = {
         description: this.form.manualMedication.value,
         customerId: this.customerId,
         inclusionType: 1
      };
      this._medicinesService.put(obj, medicationId).then((res: any) => {
         if (res.id) {
            this._toastService.fireSuccess("requisitionEdit.msgPutMedication");
            this.getMedications();
            this.close();
         }
      });
   }

   selectExamPackage() {
      return "|checkbox|false";
   }

   checkMaterial() {
      const materials = this.getAnalyteMaterialOptions(
         this.formIncludeAnalyte.controls["analyteId"].value
      );
      if (materials) {
         const material = materials.filter(
            (material) =>
               material.value ===
               this.formIncludeAnalyte.controls["materialId"].value
         );

         if (material.length > 0) {
            return true;
         }
      }

      return false;
   }

   async searchAnalyte(search: string) {
      const query: QueryParams = { search };
      await this._saveRequisitionFacade.searchAnalyte(query);
   }

   async ActionsAgreementExamsSupportLaboratory(action: any) {
      this._saveRequisitionFacade.ActionsAgreementExamsSupportLaboratory(
         action
      );
   }

   checkboxAll(event: any, item: any) {
      if (event.srcElement.checked != undefined) {
         this._saveRequisitionFacade.checkboxAll(event, item);
      }
   }

   sendAgreementSupport() {
      this._saveRequisitionFacade.sendAgreementSupport();
   }

   createDiscountSolicitation(value?: string) {
      this._saveRequisitionFacade.createDiscountSolicitation(
         this.formDiscountAuthorization.controls["discountReason"].value,
         value
      );
   }
}
