<div style="overflow: auto; max-height: 90vh" class="p-[24px]">
    <div
        class="flex items-center mb-[24px]"
        [ngClass]="{
            'justify-between': title !== '',
            'justify-end': title === ''
        }"
    >
        <ng-content *ngIf="title === ''" select="[header]"></ng-content>

        <h2 *ngIf="title !== ''" class="text-2xl font-bold">
            {{ title | translate }}
        </h2>

        <span *ngIf="closeButton" class="close">
            <ui-svg-selector
                [mat-dialog-close]="[]"
                [hover]="'var(--primary-color)'"
                [fill]="'#BFBFBF'"
                [svg]="'icon-menu-close'"
            ></ui-svg-selector>
        </span>
    </div>

    <ng-content></ng-content>
</div>
