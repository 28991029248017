import { Injectable, inject } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import { lastValueFrom } from "rxjs";
import { UserTenantsService } from "./userTenants.service";
import { QueryParams } from "@app/modules_new/data/services/types/http.types";

@Injectable({
   providedIn: "root"
})
export class UserTenantsFacade extends MultiObservableFacade {
   private _userTenantsService = inject(UserTenantsService);

   async post(obj: any) {
      try {
         const response: any = await lastValueFrom(
            this._userTenantsService.post(obj)
         );

         return response.success;
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      return false;
   }

   async get(page: number, numberRegistry: number, search?: QueryParams) {
      try {
         const response: any = await lastValueFrom(
            this._userTenantsService.getPagination<any>(page, numberRegistry, {
               query: search
            })
         );

         return response;
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      return null;
   }
}
