<div class="contentPage">
  <div class="flex flex-between align-center gap-2 headerResponsive">
    <div class="flex gap-2 align-center titleResponsive">
      <h1>Relatório de Tabela de Preços</h1>
      <app-button (click)="generatePdf()" [placeholder]="'Gerar Relatório'" [matIcon]="'insert_drive_file'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="getPriceTables()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus (keyup.enter)="getPriceTables()" [form]="filter.search" [placeholder]="'Pesquisar'"></input-prop>        
        <select-prop [placeholder]="'Situação'" [form]="filter.isActive" [options]="isActiveOptions"></select-prop>
      </div>
      <app-button (click)="getPriceTables()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [indexNumber]="index" [pageNumber]="page" [Itens]="registerTable" (changePage)="getPriceTables($event)"></app-table>
  </div>
</div>