<div class="contentPage">
  <div class="flex flex-between w-100">
    <div class="flex gap-2 align-center">
        <h1>URL de Redirecionamento</h1>        
        <button class="btn-reverse" (click)="openDialogModal()">Novo</button>
    </div>
    <!-- <app-filter>
      <div class="query">
        <input-prop #autoFocus [placeholder]="'Pesquisar por URL'" (keydown.enter)="getEndpoints([1, paginationInfo.numberRegistry])" [form]="formGroupSearchFilter.controls['search']"></input-prop>
      </div>
      <app-button class="queryBtn mt-10" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'" (click)="getEndpoints([1, paginationInfo.numberRegistry])"></app-button>
    </app-filter> -->
  </div>
  <div class="mt-32">

    <app-table 
      [Itens]="resolvedEndpoints" 
      [Pagination]="true" 
      [backPagination]="true"
      [sumRecords]="paginationInfo.sumRecords"
      [indexNumber]="paginationInfo.numberRegistry" 
      [pageNumber]="paginationInfo.page" 
      [ClickInItem]="ActionsExtra"
      [Actions]="['Editar', 'Excluir']"
      (changePage)="getEndpoints($event)"
      (parentFun)="actions($event)"
      ></app-table>
  </div>
</div>

<ng-template #dialogModal>
  <div class="flex flex-between w-100 align-center">
    <h1>{{formGroupEndpoint.controls['endpointId'].value ? 'Editar' : 'Nova'}} URL de Redirecionamento</h1>
    <svg class="close-svg" (click)="closeModal()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
    </svg>
  </div>
  <div class="flex gap-form mt-32">
    <div class="w-100">
      <input-prop [placeholder]="'URL'" [form]="formGroupEndpoint.controls['urlRedirect']"></input-prop>
    </div>
    <div class="w-100">
      <select-prop [placeholder]="'Tipo'" [options]="urlTypeOptions" [form]="formGroupEndpoint.controls['type']"></select-prop>
    </div>
  </div>
  <div class="w-100 mt-32 flex flex-center">    
    <button class="btn btn-reverse" (click)="formGroupEndpoint.controls['endpointId'].value ? updateEndpoint() : createEndpoint()">Salvar</button>
  </div>
</ng-template>