<div class="header">
    <div class="left">
        <i class="fas fa-times-circle closeIcon" (click)="fechar()"></i>
    </div>
</div>

<div style="overflow: auto; height: 90%; width: 100%;">
    <div oncontextmenu="return false">
        <embed class="no-print"
               id="pdfframe"
               width="100%"
               height="825"
               type="application/pdf"
               [src]="linkDoPDF"
               oncontextmenu="return false;"/>
    </div>
    <!--<div *ngIf="tipoDoDocumento == 'jpg' || tipoDoDocumento == 'png'">
        <div *ngFor="let imagen of imagens; let i = index" class="imgSize">
            <img [id]="'pdf' + i" [src]="imagen" ondragstart="return false;" ondrop="return false;" />
        </div>
    </div>-->
</div>
