<div class="contentPage">
  <div class="flex flex-between align-center gap-2 headerResponsive">
    <div class="flex gap-2 align-center titleResponsive">
      <h1>Relatório de Convênios</h1>
      <app-button (click)="generatePdf()" [placeholder]="'Gerar Relatório'" [matIcon]="'insert_drive_file'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" [advanced]="true" (beforeClose)="getAgreements()" [cleanButton]="true" (clearFilters)="clearForms()">
      <div class="query">
        <input-prop #autoFocus [placeholder]="'Pesquisar por Código'" [form]="searchForm.externalCode"></input-prop>
        <input-prop [placeholder]="'Pesquisar por Descrição'" [form]="searchForm.description"></input-prop>
        <select-prop [options]="isActiveOptions" [form]="formGroup.controls['isActive']" [placeholder]="'Situação'" class="w-100"></select-prop>  
      </div>
      <div class="advancedQuery">
        <checkbox-prop [label]="'Controla Plano'" [form]="searchForm.controlPlan"></checkbox-prop>
        <select-prop [placeholder]="'Pesquisa por Tipo'" [form]="searchForm.typeInvoice" [options]="typeInvoiceOptions"></select-prop>
        <input-prop [placeholder]="'Pesquisar por Índice'" [form]="searchForm.index"></input-prop>
      </div>
      <app-button (click)="getAgreements()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [indexNumber]="index" [pageNumber]="page" [Itens]="registerAgreement" (changePage)="getAgreements($event)"></app-table>
  </div>
</div>