import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { RecipientsService } from '@app/services/recipients/recipients.service';
import { Guid } from '@app/util/guid';
import { GetRecipients, ResolveRecipients, Actions } from './recipients.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { DialogComponent } from '@app/components/form/dialog/dialog.component';
import { ToastService } from '@app/services/translate/toast.service';

@Component({
  selector: 'app-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss']
})

export class RecipientsComponent implements OnInit {
  total: number = 0;
  page: number = 1;
  index: number = 50;
  getParameters: string = (`?page=${this.page}&index=${this.index}`);
  recipientId: Guid = new Guid(Guid.getEmpty());

  orderBy: { sort: string, order: string } = { sort: '', order: 'asc' }

  editId: string = '';

  @ViewChild(DialogComponent) dialog!: DialogComponent


  recipients: Array<ResolveRecipients> = [];
  resolveRecipients = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'recipients.externalId',
      retrive: '',
      method: 'getExternalId',
      after: '',
      before: '',
      sort: 'externalId'
    },
    {
      label: 'recipients.description',
      retrive: 'description',
      method: '',
      after: '',
      before: '',
      sort: 'description'
    },
    {
      label: 'recipients.type',
      retrive: '',
      method: 'getTypeColor',
      after: '',
      before: '',
      sort: 'type'
    },
    {
      label: 'recipients.createdAt',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: '',
      sort: 'createdAt'
    },
    {
      label: 'recipients.updatedAt',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: '',
      sort: 'updatedAt'
    },
    {
      label: '_hide_TypeColor',
      retrive: 'type',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: '',
      sort: 'isActive'
    }
  ];

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  getType = [
    {
      value: 0,
      label: 'Coleta'
    },
    {
      value: 1,
      label: 'Triagem'
    },
    {
      value: 2,
      label: 'Lamina'
    },
    {
      value: 3,
      label: 'Seringa'
    }
  ];

  form = {
    recipientName: new UntypedFormControl(null),
    typeSorting: new UntypedFormControl(null),
    typeCollect: new UntypedFormControl(null),
    typeLamina: new UntypedFormControl(null),
    typeSeringa: new UntypedFormControl(null),
    externalId: new UntypedFormControl(null),
    isActive: new UntypedFormControl(false)
  }

  filterForm = {
    description: new UntypedFormControl(null),
    typeRecipient: new UntypedFormControl(null),
    externalId: new UntypedFormControl(null),
    typeCollect: new UntypedFormControl(null)
  }

  constructor(
    private _recipientsService: RecipientsService,
    private _dialog: MatDialog,
    private _createdAt: LocalaDatePipe,
    private _updatedAt: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _toastService: ToastService
  ) { }

  async ngOnInit() {
    await this.get();
  }

  getTypeColor(item: GetRecipients) {
    const typeColor: Array<String> = [
      '<span class="span-styles" style="background:#EB5757; color:#FFF;">Coleta</span>',
      '<span class="span-styles" style="background:#FFC000; color:#FFF;">Triagem</span>',
      '<span class="span-styles" style="background:#006E9D; color:#FFF;">Lamina</span>',
      '<span class="span-styles" style="background:#07BF56; color:#FFF;">Seringa</span>'
    ]
    return typeColor[item.type]
  }

  async post() {
    let obj = {
      description: this.form.recipientName.value,
      externalId: this.form.externalId.value,
      type: this.form.typeCollect.value
    }
    await this._recipientsService.post(obj).then(
      (res) => {
        if (res.success) {
          this.get();
          this._toastService.fireSuccess('recipients.msgPost')
          this.dialog.closeDialog()
        }
      }).catch(
        (err) => {
          this._toastService.fireError(err);
        });
  }


  resetForms() {
    this.form.recipientName.reset();
    this.form.externalId.reset();
    this.form.typeCollect.reset();
  }

  async put() {
    const obj = {
      description: this.form.recipientName.value,
      externalId: this.form.externalId.value,
      type: this.form.typeCollect.value
    }
    await this._recipientsService.put(obj, new Guid(this.editId)).then(
      async (res) => {
        await this.get();
        this.dialog.closeDialog()
        this._toastService.fireSuccess('recipients.msgPut')
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    );
  }

  async get(paramPage?: number[]) {
    this.recipients = [];
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let filterObject = {
      isActive: this.form.isActive.value ? this.form.isActive.value : null,
      numberRegistry: this.index,
      page: this.page,
      description: this.filterForm.description.value ?? null,
      typeRecipient: this.filterForm.typeRecipient.value ?? null,
      externalId: this.filterForm.externalId.value ?? null,
      orderBy: this.orderBy.sort ? `${this.orderBy.sort},${this.orderBy.order}` : null
    };


    await this._recipientsService.getPagination(filterObject).then(
      (res: any) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolve(res.data, this.resolveRecipients);
        }
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err);
      }
    );
  }


  async getSortedPage(event: { label: string, index: number, order: string }) {
    const value = this.resolveRecipients.find((x) => x.label == event.label)
    if (value && value.sort) {
      this.orderBy.sort = value.sort
      this.orderBy.order = event.order
    }
    await this.get();
  }


  resolve(data: any, columns: any) {
    this.recipients = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.recipients.push(obj);
    });
  }

  getCreated(item: GetRecipients) {
    return this._createdAt.transform(item.createdAt);
  }

  getExternalId(item: GetRecipients) {
    return item.externalId ?? ''
  }

  getUpdated(item: GetRecipients) {
    return this._updatedAt.transform(item.updatedAt);
  }

  getActive(item: GetRecipients) {
    return this._activePipe.transform(item.isActive)
  }

  close() {
    this.recipientId = new Guid(Guid.getEmpty());
    this._dialog.closeAll()
  }

  clickSorting() {
    this.form.typeCollect.setValue("");
  }

  clickCollect() {
    this.form.typeSorting.setValue("");
  }

  Actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.editId = emit.object.id ?? ''
        this.dialog.openDialogModal(true, emit);
        this.form.recipientName.setValue(emit.object['recipients.description'])
        this.form.externalId.setValue(emit.object['recipients.externalId'])
        this.form.typeCollect.setValue(emit.object._hide_TypeColor)
        break;
      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
      case 'Excluir':
        this.delete(emit.object.id)
        this.get();
        break;
    };
  }

  delete(id: string) {
    this._toastService.fireConfirmation("recipients.msgDelete").then(
      async (resposta) => {
        if (resposta.isConfirmed) {
          await this._recipientsService.delete(new Guid(id)).then(
            async (res) => {
              if (res.success) {
                await this.get();
                this._toastService.fireSuccess("recipients.msgDeleteSuccess");
              }
            });
        }
      }).catch(
        (err) => {
          this._toastService.fireError(err);
        });
  }

  async putActive(id: string, value: string) {
    await this._recipientsService.patchActive(id, value).then(async (x: any) => {
      await this.get();
      this._toastService.fireSuccess('global.msgSituationSuccess');
    }).catch(err => this._toastService.fireError(err))
  }

  resetFilter() {
    this.filterForm.description.reset();
    this.filterForm.typeRecipient.reset();
    this.filterForm.externalId.reset();
    this.form.isActive.reset();
  }

  afterClose() {
    this.resetForms()
    this.editId = ''
  }
}
