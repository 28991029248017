import { Injectable } from '@angular/core';
import { Guid } from '@app/util/guid';
import { HttpResponsePageable } from '../services.types';
import { BaseAuthService } from "./base-auth.service";
import { HttpAuthService } from './http-auth.service';
import { GetPersonById } from './person-auth.types';

@Injectable({
  providedIn: 'root'
})
export class PersonAuthService extends BaseAuthService {

  constructor(HttpAuth: HttpAuthService) {
    super(HttpAuth, 'v1/profile/person');
  }

  override getById(id: Guid) {
    return super.getById(id) as Promise<HttpResponsePageable<GetPersonById>>
  }
}
