<div>
  <div class="right-button" (click)="clearOptions()">Limpar Todos</div>
  <mat-form-field class="example-chip-list" appearance="fill">
    <mat-label>Favorite Fruits</mat-label>
    <mat-chip-list #chipList [formControl]="form" aria-label="Fruit selection">
      <mat-chip        
        *ngFor="let fruit of fruits"
        (removed)="remove(fruit)">
        {{fruit}}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        placeholder="Novo..."
        #fruitInput
        [formControl]="formFilter"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let fruit of filteredOptions" [value]="fruit">
        {{fruit.label}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>  
</div>
