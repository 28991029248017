import { Injectable, inject } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import { ModuleAuthService } from "../services/modulesAuthNew/module-auth-new.service";
import {
   HttpResponse,
   QueryParams,
   RequestOptions
} from "../../../data/types/http.types";
import {
   CreateResourceRequest,
   ModuleResponse,
   ModulesResponse,
   ResourcesResponse,
   UpdateResourceRequest
} from "../services/modulesAuthNew/module-auth-new.types";
import { Guid } from "@app/util/guid";
import { MatDialog } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";

@Injectable({
   providedIn: "root"
})
export class ModulesFacade extends MultiObservableFacade {
   private _modulesService = inject(ModuleAuthService);
   private _users = this.createNullableObservableControl<ModuleResponse[]>();
   private _usersAmount = this.createObservableControl<number>(0);
   private _dialog = inject(MatDialog);

   private controls = this.controlGroup({
      modules: this.emptyObservable(),
      modulesAmount: this.emptyObservable(),
      resource: this.emptyObservable(),
      resourcesAmount: this.emptyObservable()
   });

   async getModuleOptions(obj: RequestOptions): Promise<any[]> {
      try {
         const response = await lastValueFrom(
            this._modulesService.getPagination<ModuleResponse>(1, 999999, obj)
         );

         if (response.data.length > 0) {
            let data: any = [];
            response.data.forEach((y: any) => {
               data.push({
                  value: y.id,
                  label: y.name
                  // disable:
                  //    this.rolePolicies.findIndex(
                  //       (z: any) => z.name === y.name
                  //    ) != -1
               });
            });

            return data;
         } else {
            return [];
         }
      } catch (err) {
         console.error(err);
         return [];
      }
   }

   getModules(page: number, numberRegistry: number, search?: QueryParams) {
      this._users.subject.next(null);

      this._modulesService
         .getPagination<ModuleResponse>(page, numberRegistry, {
            query: search
         })
         .subscribe({
            next: (response: ModuleResponse) => {
               this._users.subject.next(response.data);
               this._usersAmount.subject.next(response.sumRecords);
            },
            error: (error) => {
               this.ToastErrorGeneric(error);
            }
         });

      return {
         data$: this._users.observable$,
         total$: this._usersAmount.observable$
      };
   }

   createModule(createObj: any) {
      this._modulesService.post(createObj).subscribe({
         next: () => {
            this.ToastSuccess("toast-msg.change.success");
            this.getModules(1, 30);
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }

   updadeModules(updateObj: any, id: string) {
      this._modulesService.put(updateObj, new Guid(id)).subscribe({
         next: () => {
            this.ToastSuccess("toast-msg.change.success");
            this.getModules(1, 30);
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }

   createTabsGroup(id: string) {
      this.controls.add(id, {
         modules: this.createNullableObservableControl<ModulesResponse[]>(),
         modulesAmount: this.createObservableControl<number>(0),
         resource: this.createNullableObservableControl<ModulesResponse[]>(),
         resourcesAmount: this.createObservableControl<number>(0)
      });
   }

   getResources(
      moduleId: string,
      page: number,
      numberRegistry: number,
      search?: QueryParams
   ) {
      this.controls.get(moduleId).modules.subject.next(null);

      this._modulesService
         .getPagination<ResourcesResponse>(page, numberRegistry, {
            path: `/${moduleId}/resource`,
            query: search
         })
         .subscribe({
            next: (response) => {
               let data: any = [];
               if (response.data[0]?.resources) {
                  data = response.data[0]?.resources;
               }

               this.controls.get(moduleId).modules.subject.next(data);
               this.controls
                  .get(moduleId)
                  .modulesAmount.subject.next(data?.length);
            },
            error: (error) => {
               this.controls.get(moduleId).modules.subject.next([]);
            }
         });

      return {
         data$: this.controls.get(moduleId).modules.observable$,
         total$: this.controls.get(moduleId).modulesAmount.observable$
      };
   }

   createResourceToModule(request: CreateResourceRequest) {
      this._modulesService
         .post<HttpResponse>(request, {
            path: `/${request.moduleId}/resource`
         })
         .subscribe({
            next: () => {
               this.ToastSuccess("toast-msg.creation.success!");
               this.getResources(request.moduleId, 1, 30);
               this.closeModal();
            },
            error: (error) => {
               this.ToastErrorGeneric(error, "toast-msg.creation.error");
            }
         });
   }

   updateResourceToModule(request: UpdateResourceRequest) {
      const obj = {
         description: request.description,
         name: request.name
      };
      this._modulesService
         .put<HttpResponse>(obj, new Guid(request.id), {
            path: `/${request.moduleId}/resource`
         })
         .subscribe({
            next: () => {
               this.ToastSuccess("toast-msg.change.success");
               this.getResources(request.moduleId, 1, 30);
               this.closeModal();
            },
            error: (error) => {
               this.ToastErrorGeneric(error, "toast-msg.creation.error");
            }
         });
   }

   closeModal() {
      this._dialog.closeAll();
   }

   patch(situation: boolean, id: string) {
      this._modulesService.patchToggleIsActive(id, !situation).subscribe({
         next: () => {
            this.ToastSuccess("toast-msg.change.success");
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }

   patchActive(customerId: string, value: string, moduleID: string) {
      this._modulesService.patchToggleIsActive(customerId, !value).subscribe({
         next: () => {
            this.ToastSuccess("toast-msg.change.success");
            this.getModules(1, 30);
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }
}
