import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CollectionPlaceService } from '@app/services/collection-places/collection-place.service';
import { Util } from '@app/util/util';
import { PlaceListEmit, PlaceListObject, customerResolve } from './collection-place.model';
import { Guid } from '@app/util/guid';
import { ToastService } from '@app/services/translate/toast.service';

@Component({
  selector: 'app-collection-place-list',
  templateUrl: './collection-place-list.component.html',
  styleUrls: ['./collection-place-list.component.scss']
})
export class CollectionPlaceListComponent implements OnInit {

  constructor(
    private _dialog: MatDialog,
    private _collectionPlaceService: CollectionPlaceService,
    private _util: Util,
    private _toastService: ToastService
  ) { }

  @ViewChild('dialogPlaceList')
  dialogPlaceList!: TemplateRef<any>

  total: number = 0;
  page: number = 1;
  index: number = 50;

  colectionPlaceRegisters: Array<object> = []

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  formFilter = {
    Search: new UntypedFormControl(null),
    description: new UntypedFormControl(null),
    externalId: new UntypedFormControl(null),
    IsActive: new UntypedFormControl(null),
  }

  collectionPlaceForm = new UntypedFormGroup({
    description: new UntypedFormControl(null),
    externalId: new UntypedFormControl(null),
    awaitCollectAutomatic: new UntypedFormControl(false),
    arrivalTimeRequired: new UntypedFormControl(false)
  });

  ngOnInit() {
    this.getCollectionPlace();
  }

  getCollectionPlace(paramPage?: Array<number>){
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    const filterObj = {
      page: this.page, 
      index: this.index,
      ExternalId: this.formFilter.externalId.value,
      Description: this.formFilter.description.value,
      IsActive: this.formFilter.IsActive.value ? this.formFilter.IsActive.value : null
    }

    this._collectionPlaceService.getPagination(filterObj).then(
      (res) => {
        this.total = res.sumRecords
        this.resolve(res.data, customerResolve)
      }
    ).catch(
      (err) => {
        this._toastService.fireError(err)
      }
    ); 
  }

  resolve(data: any, columns: any) {
    this.colectionPlaceRegisters = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          obj[y.label] = y.before + this._util.formatField(x, y.fieldName, y.type, y.interpolationFields, y.dateFormat) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.colectionPlaceRegisters.push(obj);
    });
  }

  createCollectionPlace(){
    const postObj = {
      description: this.collectionPlaceForm.controls['description'].value,
      externalId: this.collectionPlaceForm.controls['externalId'].value,
      awaitCollectAutomatic: this.collectionPlaceForm.controls['awaitCollectAutomatic'].value ? this.collectionPlaceForm.controls['awaitCollectAutomatic'].value === true : false,
      arrivalTimeRequired : this.collectionPlaceForm.controls['arrivalTimeRequired'].value ? this.collectionPlaceForm.controls['arrivalTimeRequired'].value === true : false,
    }
    this._collectionPlaceService.post(postObj).then(() => {
      this.closeModal();
      this._toastService.fireSuccess('collectionLocation.msgPost');
      this.getCollectionPlace();
    })
  }

  updateCollectionPlace(id : string){
    const updateObj = {
      description: this.collectionPlaceForm.controls['description'].value,
      externalId: this.collectionPlaceForm.controls['externalId'].value,
      awaitCollectAutomatic: this.collectionPlaceForm.controls['awaitCollectAutomatic'].value ? this.collectionPlaceForm.controls['awaitCollectAutomatic'].value === true : false,
      arrivalTimeRequired : this.collectionPlaceForm.controls['arrivalTimeRequired'].value ? this.collectionPlaceForm.controls['arrivalTimeRequired'].value === true : false,
    }
    this._collectionPlaceService.put(updateObj, new Guid(id)).then(() => {
      this.closeModal();
      this._toastService.fireSuccess('collectionLocation.msgPut');
      this.getCollectionPlace();
    })
  }

  openDialogPlaceList(emit?: PlaceListObject){
    const myTempDialog = this._dialog.open(this.dialogPlaceList, {
      width: '700px',
      data: emit
    });
    myTempDialog.afterClosed().subscribe(() => {
      this.collectionPlaceForm.reset();
    });
  }

  closeModal() {
    this._dialog.closeAll();
  }

  actions(emit: PlaceListEmit) {
    switch (emit.action) {
      case 'Editar':
        this.openDialogPlaceList(emit.object);
        this.collectionPlaceForm.controls['externalId'].setValue(emit.object['collectionLocation.code']);
        this.collectionPlaceForm.controls['description'].setValue(emit.object['collectionLocation.description']);
        this.collectionPlaceForm.controls['arrivalTimeRequired'].setValue(true ? emit.object._hide_arrivalTimeRequired.includes('true') : false);
        this.collectionPlaceForm.controls['awaitCollectAutomatic'].setValue(true ? emit.object._hide_awaitCollectAutomatic.includes('true') : false);
        break;
      case 'Excluir':
        this.deleteColectionPlace(emit.object.id);
        break;
      case 'PutActive':
        this.putActive(emit.object.id, emit.object.Ativo);
        break
    }
  }

  deleteColectionPlace(id: string){
    this._toastService.fireConfirmation("collectionLocation.msgDelete").then(
      async (res) => {
        if (res.isConfirmed) {
          await this._collectionPlaceService.delete(new Guid(id))
          this.getCollectionPlace();
          this._toastService.fireSuccess('collectionLocation.msgDeleteSuccess');
        }
      }
    ).catch(
      (error) => {
        this._toastService.fireError(error)
      }
    );
  }

  putActive(id: string, value: string) {
    this._collectionPlaceService.patchActive(id, value).then(() => {
      this._toastService.fireSuccess('global.msgSituationSuccess');
      this.getCollectionPlace();
    })
  }

  resetFilter(){
    this.formFilter.IsActive.reset(null)
    this.formFilter.Search.reset(null)
    this.formFilter.description.reset(null)
    this.formFilter.externalId.reset(null)
  }

}
