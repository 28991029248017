import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GetAllControlMessages } from '@app/services/message-control/message-control.types';
import { MessageParameterService } from '@app/services/message-parameter/message-parameter.service';
import { GetAllMessageParameters } from '@app/services/message-parameter/message-parameter.types';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';

@Component({
  selector: 'sms-email-parameters',
  templateUrl: './sms-email-parameters.html',
  styleUrls: ['./sms-email-parameters.scss']
})

export class SmsEmailParameters implements OnInit {
  constructor(
    private _router: Router,
    private _controlParameters: MessageParameterService
  ) { }

  //#region Global Variables / Variáveis Globais
  total: number = 0;
  page: number = 1;
  index: number = 50;
  filterOptions = [
    {
      label: 'Filtrar por Tipo',
      value: 'Filtrar por Tipo'
    },
    {
      label: 'Filtrar por Destino',
      value: 'Filtrar por Destino'
    },
    {
      label: 'Filtrar por Título',
      value: 'Filtrar por Título'
    },
    {
      label: 'Filtrar por Situação',
      value: 'Filtrar por Situação'
    }
  ];
  //#endregion Variáveis globais

  //#region Register and Resolve
  registerControlMessages: Array<Object> = [];
  resolveControlMessages: Array<Object> = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Descrição',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_messageType',
      retrive: '',
      method: 'convertType',
      after: '',
      before: ''
    },
    {
      label: '_hide_messageUser',
      retrive: 'messageUser',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_messagePassword',
      retrive: 'messagePassword',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_smtpHost',
      retrive: 'smtpHost',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_smtpPassword',
      retrive: 'smtpPassword',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_smtpPort',
      retrive: 'smtpPort',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_smtpUser',
      retrive: 'smtpUser',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_typeAuthentication',
      retrive: '',
      method: 'convertTypeAuthentication',
      after: '',
      before: ''
    },
    {
      label: 'Data de Criação',
      retrive: '',
      method: 'getCreateAtDate',
      after: '',
      before: ''
    },
    {
      label: 'Última Alteração',
      retrive: '',
      method: 'getCreateAtDate',
      after: '',
      before: ''
    },
    {
      label: 'Situação',
      retrive: '',
      method: 'getSituation',
      after: '',
      before: ''
    }
  ];
  //#endregion

  //#region Forms / Formulários
  form = {
    filter: new UntypedFormControl(null, [Validators.required]),
    search: new UntypedFormControl(null, [Validators.required]),
  };

  formParameters = {
    description: new UntypedFormControl(null, [Validators.required]),
    isActive: new UntypedFormControl(false, [Validators.required]),
    messagePassword: new UntypedFormControl(null),
    messageType: new UntypedFormControl(null, [Validators.required]),
    messageUser: new UntypedFormControl(null),
    smtpHost: new UntypedFormControl(null),
    smtpPassword: new UntypedFormControl(null),
    smtpUser: new UntypedFormControl(null),
    smtpPort: new UntypedFormControl(null),
    typeAuthentication: new UntypedFormControl(null, [Validators.required])
  }
  //#endregion

  //#region Message Variables / Variáveis referentes à Mensagem
  type = {
    0: 'Nenhum',
    1: 'Ziad - SMS',
    2: 'E-mail',
    3: 'Zoe - WhatsApp'
  };

  typeAuthentication = {
    0: 'Nenhum',
    1: 'SSL',
    2: 'SSL_TLS',
    3: 'TLS',
    4: 'TLS2'
  }

  //#endregion

  async ngOnInit() {
    await this.getControlMessages();
  }

  async getControlMessages(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let getParameters: string;
    let param: string = this.form.search.value;

    if (this.form.filter.value == "Filtrar por Tipo") {
      getParameters = (`?Type=${param}&page=${this.page}&index=${this.index}`);
    } else if (this.form.filter.value == "Filtrar por Destino") {
      getParameters = (`?Destination=${param}&page=${this.page}&index=${this.index}`);
    } else if (this.form.filter.value == "Filtrar por Título") {
      getParameters = (`?Title=${param}&page=${this.page}&index=${this.index}`);
    } else if (this.form.filter.value == "Filtrar por Situação") {
      getParameters = (`?IsProcessed=${param}&page=${this.page}&index=${this.index}`);
    } else {
      getParameters = (`?page=${this.page}&index=${this.index}`);
    }

    await this._controlParameters.getByPath('', getParameters).then(
      (res) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolve(res.data, this.resolveControlMessages);

          res.data.forEach((messageParameters) => {
            this.formParameters.isActive.setValue(messageParameters.isActive);
            this.formParameters.messagePassword.setValue(messageParameters.messagePassword);
            this.formParameters.messageType.setValue(messageParameters.messageType);
            this.formParameters.messageUser.setValue(messageParameters.messageUser);
            this.formParameters.smtpHost.setValue(messageParameters.smtpHost);
            this.formParameters.smtpPassword.setValue(messageParameters.smtpPassword);
            this.formParameters.smtpPort.setValue(messageParameters.smtpPort);
            this.formParameters.smtpUser.setValue(messageParameters.smtpUser);
            this.formParameters.typeAuthentication.setValue(messageParameters.typeAuthentication);
          });
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.registerControlMessages = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerControlMessages.push(obj);
    });
  }

  convertType(item: any) {
    //@ts-ignore
    return item.type ? this.type[item.type] : this.type[0];
  }

  convertTypeAuthentication(item: any) {
    //@ts-ignore
    return item.typeAuthentication ? this.typeAuthentication[item.type] : this.typeAuthentication[0];
  }

  getSituation(item: GetAllMessageParameters) {
    let stylizedSpanForActiveSituation: string = "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Ativo</span>";
    let stylizedSpanForInactiveSituation: string = "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Inativo</span>";
    return item.isActive ? stylizedSpanForActiveSituation : stylizedSpanForInactiveSituation;
  }

  getCreateAtDate(item: any) {
    var data = new Date(item.createdAt);
    const MINIMUM_YEAR = 1970;

    if (data.getFullYear() < MINIMUM_YEAR) return "";

    var formattedDay = data.getDate() < 10 ? "0" + data.getDate() : data.getDate().toString();
    var formattedMonth = data.getMonth() < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1).toString();
    var formattedHour = data.getHours() < 10 ? "0" + data.getHours() : data.getHours().toString();
    var formattedMinute = data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes().toString();
    return formattedDay + "/" + formattedMonth + "/" + data.getFullYear() + " às " + formattedHour + ":" + formattedMinute + " h";
  }

  actions(emit: Object) {
    //@ts-ignore
    switch (emit.action) {
      case "View":
      //@ts-ignore
      //this.router.navigateByUrl('Cadastros/Clientes/' + emit.object.id + '$View');
      //break;
      case 'Editar':
      //@ts-ignore
      //this.router.navigateByUrl('Cadastros/Clientes/' + emit.object.id + '$Edit');
      //break;
      case 'Excluir':
      //@ts-ignore
      //this.customerId = emit.object.id;
      //this.excluir();
      //break;
    };
  }

  /*
  excluir() {
    SwAlSetttings.ALERT_CONFIRM.fire().then(
      async (r) => {
        if(r.isConfirmed) {
          SwAlSetttings.loader();
          let guidCustomerId : Guid = new Guid(this.customerId);
          await this.customerService.delete(guidCustomerId);
          this.ngOnInit();
        }
        SwAlSetttings.Sucesso('Cliente excluído com sucesso!');
        SwAlSetttings.closeLoader();
      }
    ).catch((error) => {
        SwAlSetttings.printError(error);
        SwAlSetttings.closeLoader();
      }
    );
  }
  */
}
