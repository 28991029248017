import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-cards',
  templateUrl: './dashboard-cards.component.html',
  styleUrls: ['./dashboard-cards.component.scss']
})
export class DashboardCardsComponent implements OnInit {
  aberto = false;
  cardsAbertos: Array<boolean> = [false, false, false, false];
  atualizacao : Date | undefined

  @Input() columns: Array<any> =  [];

  constructor() { }

  ngOnInit(): void {
    
  } 

  

}
