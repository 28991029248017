import { inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import {
   UserAuthenticateResponse,
   UsersAuthenticatesResponse
} from "../userAuthenticate/userAuthenticate.types";
import { QueryParams } from "@app/modules_new/data/services/types/http.types";
import { UserAuthenticateService } from "../userAuthenticate/userAuthenticate.service";
import { lastValueFrom } from "rxjs";
import { Guid } from "@app/util/guid";

@Injectable({
   providedIn: "root"
})
export class UserAuthenticateFacade extends MultiObservableFacade {
   private _dialog = inject(MatDialog);
   private _userAuthenticateService = inject(UserAuthenticateService);

   private _userAuthenticates =
      this.createNullableObservableControl<UserAuthenticateResponse[]>();
   private _userAuthenticatesAmount = this.createObservableControl<number>(0);

   async getById(id: Guid) {
      try {
         const response: any = await lastValueFrom(
            this._userAuthenticateService.getById(id)
         );

         if (response.success == true) {
            return response;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      return false;
   }

   async createAccess(request: any) {
      try {
         const response: any = await lastValueFrom(
            this._userAuthenticateService.post(request)
         );

         if (response.success == true) {
            this.ToastSuccess("Acesso criado com sucesso!");
            return true;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      return false;
   }

   async updateAccess(request: any, id: string) {
      try {
         const response: any = await lastValueFrom(
            this._userAuthenticateService.put(request, new Guid(id))
         );

         if (response.success == true) {
            this.ToastSuccess("Acesso atualizado com sucesso!");
            return true;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      return false;
   }

   get(page: number, numberRegistry: number, search?: QueryParams) {
      this._userAuthenticateService
         .getPagination<UsersAuthenticatesResponse>(page, numberRegistry, {
            query: search
         })
         .subscribe({
            next: (response) => {
               this._userAuthenticates.subject.next(response.data);
               this._userAuthenticatesAmount.subject.next(response.sumRecords);
            },
            error: (error) => {
               this._userAuthenticates.subject.next([]);
               this._userAuthenticatesAmount.subject.next(0);
               this.ToastErrorGeneric(error);
            }
         });

      return {
         data$: this._userAuthenticates.observable$,
         total$: this._userAuthenticatesAmount.observable$
      };
   }

   async patchActive(id: string, value: string) {
      let success = false;
      try {
         const response: any = await lastValueFrom(
            this._userAuthenticateService.patchToggleIsActive(id, !value)
         );
         if (response.success) {
            this.ToastSuccess(
               !value === true
                  ? "toast-msg.change-to-active.success"
                  : "toast-msg.change-to-inactive.success"
            );
            success = response.success;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      //@ts-ignore
      let data = this._userAuthenticates.subject._value;
      let index = data.findIndex((x: any) => x.id == id);

      if (success) {
         data[index].isActive = !value;
      } else {
         data[index].isActive = value;
      }
      this._userAuthenticates.subject.next(data);
      return success;
   }
}
