import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export type ValidatorPrintType = 'focusout' | 'span'

export type ValidatorErrorMessage = {
    title: string,
    message: string,
    type: ValidatorPrintType
}

export class DefaultValidators {

    static birthDateValidator = (): ValidatorFn => {
        return (control: AbstractControl) : ValidationErrors | null => {
      
            const inputDate = new Date(control.value);
            const currentDate = new Date();
      
            if(isNaN(inputDate.getDate())) {
                return { title: 'Data inválida!', message: "Data informada está inválida!", type: 'focusout' } as ValidatorErrorMessage
            }
            
            if(inputDate > currentDate) {
                return { title: 'Data inválida!', message: "Data deve ser anterior a data de hoje!", type: 'focusout' } as ValidatorErrorMessage
            }

            if(inputDate.getFullYear() < 1900) {
                return {  title: 'Data inválida!', message: "O ano informado deve precisa ser posterior à 1900", type: 'focusout' } as ValidatorErrorMessage
            }

            return  null
        }
    }
}
