import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http.service';
import { BaseService } from '@app/services/base.service';
import { HttpResponsePageable } from '@app/services/services.types';
import { AnalyteParameters } from './analyte-parameters.service.module';

@Injectable({
  providedIn: 'root'
})

export class AnalyteParametersService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/analyteparameters');
  }

  override get(queryString: string){
    return super.get(queryString) as Promise<HttpResponsePageable<AnalyteParameters>>
  }
}