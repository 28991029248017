import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'select-prop',
  templateUrl: './select-prop.component.html',
  styleUrls: ['./select-prop.component.scss']
})
export class SelectPropComponent implements OnInit {
  @Input() type: string = 'text';
  @Input() form!: UntypedFormControl;
  @Input() placeholder: string = '';
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() mask: any = '';
  @Input() nullable: boolean = false;
  @Input() readonlySelect: boolean = false;
  @Input() whiteLabel: boolean = false;
  @Input() states: boolean = false;
  @Input() className: string = '';
  @Input() requiredInput: string = '';
  @Input() pattern: string = '';
  @Input() tabindexcomponent: string = '';
  @Input() optionAll: boolean = false;
  @Input() optionAllNull: boolean = false;
  @Input() options: Array<{ value: string, label: string }> = [];
  @Output() changeValue: EventEmitter<any> = new EventEmitter<string>();

  constructor() { }

  onChange(value: any) {
    if (this.type == 'number') {
      this.form.setValue(parseInt(value));
    } else {
      this.form.setValue(value);
    }

    if (value == "null") this.form.setValue(null);
    this.changeValue?.emit(value)
  }

  ngOnInit(): void {
    if (this.optionAll) {
      let obj = {
        label: 'Todos',
        value: 'Todos'
      }
      this.options.push(obj);
    }

    if (this.optionAllNull) {
      let obj = {
        label: 'Todos',
        value: null
      }
      //@ts-ignore
      this.options.push(obj);
    }

  }

}
