import { Guid } from "@app/util/guid"

export interface Actions {
  action: string,
  id: string,
  context: string | null,
  object: ObjectAgreement
}

export const ResolveAgreements = [
  {
    label: 'id',
    retrive: 'id',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreements.code',
    retrive: '',
    method: 'getExternalCode',
    after: '',
    before: ''
  },
  {
    label: 'agreements.description',
    retrive: 'description',
    method: '',
    after: '',
    before: ''
  },
  {
    label: 'agreements.type',
    retrive: '',
    method: 'getInvoice',
    after: '',
    before: ''
  },
  {
    label: 'agreements.controlPlan',
    retrive: '',
    method: 'getControlPlan',
    after: '',
    before: ''
  },
  {
    label: 'agreements.index',
    retrive: '',
    method: 'getIndex',
    after: '',
    before: ''
  },
  {
    label: 'agreements.createdAt',
    retrive: '',
    method: 'createdAt',
    after: '',
    before: ''
  },
  {
    label: 'agreements.updatedAt',
    retrive: '',
    method: 'updatedAt',
    after: '',
    before: ''
  },
  {
    label: 'Ativo',
    retrive: '',
    method: 'getActive',
    after: '',
    before: ''
  },
];

export interface ObjectAgreement{
  'Alterado em': string 
  Ativo: string
  'Controla Plano': string
  'Criado em': string
  'Código Externo': string
  Descrição: string
  Tipo: string
  id: Guid
  Índice : string
}

export interface Get{
  description:string,
  createdAt: string,
  updatedAt: string,
  isActive: boolean,
  externalId: string,
  index: number,
  typeInvoice: number,
  controlPlan: boolean
}