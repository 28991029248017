import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SwAlSetttings } from 'src/app/util/swal.settings';
import { GActions } from "../../../util/GActions";
import { switchAll } from "rxjs";
import { FileDocumentAuthService } from 'src/app/services/auth/fileDocument-auth.service';


@Component({
  selector: 'file-prop-auth-style',
  templateUrl: './file-prop.component.html',
  styleUrls: ['./file-prop.component.scss']
})
export class FilePropAuthStyleComponent implements OnInit {
  constructor(
      private _fileDocumentService: FileDocumentAuthService,
      private _GActions : GActions,
  ) {

  }
  //@ts-ignore
  fileUpload;
  @Input() multiple: boolean = false;
  @Input() disable: boolean = true;
  @Input() dashed: boolean = true;
  @Input() single: boolean = false;
  //@ts-ignore
  @Input() form: UntypedFormControl = new UntypedFormControl([]);
    //@ts-ignore
  @Input() formName: UntypedFormControl = new UntypedFormControl([]);;
  //@ts-ignore
  fileInit = true;
  
  ngOnInit(): void {
    if(this.form.value) {
      this.formName.value.forEach((element:any)=> {
        this.files.push({name:element})
      });
    }
  } 

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef | undefined;
  files: any[] = [];

  /**
   * on file drop handler
   */
  //@ts-ignore
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  //@ts-ignore
  fileBrowseHandler(event : ChangeEvent) {
    this.prepareFilesList(event.target.files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      return;
    }
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    SwAlSetttings.loader();
    this.fileInit = false;
    for (const item of files) {
      const reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onload = () => {
        //@ts-ignore
        let base64 = reader.result.toString();
        base64 = base64.substring(base64.lastIndexOf(',') + 1);
        const fileUpload = {
          base64: base64,
          name: item.name
        };

        this.upload(fileUpload)

        SwAlSetttings.closeLoader();
      };
      this.files.push(item);
    }
    //@ts-ignore
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  //@ts-ignore
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  onChange(value : String) { 
    this.form.setValue(value);
  }

  upload(fileUpload: { base64: string; name: any; } | undefined): void {
      //@ts-ignore
      this._fileDocumentService.post(fileUpload).then(
        (res) => {
          let files = this.form.value ? this.form.value : []
          //@ts-ignore
          files.push(res.id)
          //@ts-ignore
          this.form.setValue(files);
        }
      ).catch(
        (err) => {  
          SwAlSetttings.printError(err);
        }
      );
    }

  async download(id:any) {
      SwAlSetttings.loader();
      await this._GActions.downloadTypeFileAuth(id);
      SwAlSetttings.closeLoader();
  }

  Excluir(id:any, index:number) {
    SwAlSetttings.ALERT_CONFIRM.fire().then((r) => {
      if(r.isConfirmed) {
        this._GActions.deleteFileAuthId(id).then((x) => {
          if(!this.fileInit){
            this.files.splice(index,1)
          }else {
            this.form.value.splice(index,1)
            this.formName.value.splice(index,1)
          }
          SwAlSetttings.Sucesso('Arquivo excluido!');
        }).catch(error => SwAlSetttings.printError(error));
      }
    })
  }
}
