import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { SwAlSetttings } from "@app/util/swal.settings";
import { Guid } from "@app/util/guid";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, FormGroup } from "@angular/forms";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { Actions, ResolvedEndpoints } from "./message-parameters.model";
import { ZoeMachineService } from "@app/services/zoemachine/zoemachine.service";
import { Util } from "@app/util/util";
import { TenantAuthService } from "@app/services/auth/tenantAuth/tenant-auth.service";

@Component({
   selector: "app-message-parameters",
   templateUrl: "./message-parameters.component.html",
   styleUrls: ["./message-parameters.component.scss"]
})
export class MessageParametersComponent implements OnInit {
   resolvedEndpoints: Array<ResolvedEndpoints> = [];
   paginationInfo = {
      page: 1,
      sumRecords: 0,
      numberRegistry: 50
   };

   type: any;
   tenants: any;

   form = new FormGroup({
      typeProtocol: new FormControl(),
      description: new FormControl(),
      code: new FormControl(),
      tenantId: new FormControl<string | null>(null)
   });

   @ViewChild("dialogModal") dialogModal!: TemplateRef<any>;

   constructor(
      private _zoeMachineService: ZoeMachineService,
      private _dialog: MatDialog,
      private _util: Util,
      private _localaDatePipe: LocalaDatePipe,
      private _tenantService: TenantAuthService
   ) {}

   async ngOnInit() {
      this.type = await this._util.getEnumAuth("ETypeProtocol");
      await this.getParameters();
   }

   async getParameters(params?: number[]) {
      if (params) {
         this.paginationInfo.page = params[0];
         this.paginationInfo.numberRegistry = params[1];
      }

      await this._zoeMachineService.getPagination().then((response) => {
         if (response.success) {
            this.resolvedEndpoints = response.data.map((endpoint: any) => ({
               id: endpoint.id,
               Descrição: endpoint.description,
               Código: endpoint.code,
               Tipo: this.type[endpoint.typeProtocol].label,
               Data: this._localaDatePipe.transform(endpoint.createdAt) ?? "",
               Ativo: endpoint.isActive ? "checkbox|true" : "checkbox|",
               _hide_tenantId: endpoint.tenantId
            }));
         }
         this.paginationInfo.sumRecords = response.sumRecords;
      });
   }

   ActionsExtra = {
      Ativo: "patchActive"
   };

   actions(emit: Actions) {
      switch (emit.action) {
         case "Editar":
            this.setFormsAndOpenModal(emit.object);
            break;
         case "Excluir":
            this.deleteParameter(emit.object.id);
            break;
         case "patchActive":
            this.patchActive(emit.object.id, emit.object["Ativo"]);
            break;
      }
   }

   patchActive(id: string, value: string) {
      this._zoeMachineService.patchActive(id, value).then(async (response) => {
         //@ts-ignore
         if (response.success) {
            await this.getParameters();
            SwAlSetttings.Sucesso(
               `Parâmetro ${
                  value === "active" ? "inativada" : "ativada"
               } com sucesso!`
            );
         }
      });
   }

   setFormsAndOpenModal(data: any) {
      this.form.controls["typeProtocol"].setValue(
         this.type.filter((x: any) => x.label == data["Tipo"])[0].value
      );
      this.form.controls["code"].setValue(data["Código"]);
      this.form.controls["description"].setValue(data["Descrição"]);
      this.openDialogModal(data["_hide_tenantId"]);
   }

   async openDialogModal(tenantId: string | null = null) {
      await this._tenantService
         .getResume()
         .then((res: any) => {
            if (res.data.length != 0) {
               res.data.forEach((element: any) => {
                  this.tenants = res.data.map((tenant: any) => ({
                     value: tenant.id,
                     label: tenant.socialReason
                  }));
               });
            } else {
               SwAlSetttings.alertaMsg(
                  "Ainda não há clientes cadastrados!",
                  true
               );
            }
         })
         .catch((err: any) => {
            SwAlSetttings.printError(err);
         });

      if (tenantId) {
         this.form.controls["tenantId"].setValue(tenantId);
      }

      this._dialog
         .open(this.dialogModal, {
            width: "660px",
            data: tenantId
         })
         .afterClosed()
         .subscribe(() => this.form.reset());
   }

   deleteParameter(id: string) {
      this._zoeMachineService
         .delete(new Guid(id))
         .then(async (response: any) => {
            if (response.success) {
               await this.getParameters();
               SwAlSetttings.Sucesso("Parâmetro excluído com sucesso!");
            }
         });
   }

   updateParameter(id: Guid) {
      const obj = {
         typeProtocol: this.form.controls["typeProtocol"].value,
         description: this.form.controls["description"].value,
         code: this.form.controls["code"].value,
         tenantId: this.form.controls["tenantId"].value
      };

      this._zoeMachineService.put(obj, id).then(async (response) => {
         if (response.success) {
            await this.getParameters();
            SwAlSetttings.Sucesso("Parâmetro atualizado com sucesso!");
            this._dialog.closeAll();
         }
      });
   }

   createParameter() {
      const obj = {
         typeProtocol: this.form.controls["typeProtocol"].value,
         description: this.form.controls["description"].value,
         code: this.form.controls["code"].value,
         tenantId: this.form.controls["tenantId"].value
      };
      this._zoeMachineService.post(obj).then(async (response) => {
         if (response.success) {
            await this.getParameters();
            SwAlSetttings.Sucesso("Parâmetro criado com sucesso!");
            this._dialog.closeAll();
         }
      });
   }

   closeModal() {
      this._dialog.closeAll();
   }
}
