import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { ProcessesComponent } from "./components/processes/processes.component";
import { ConferenceComponent } from "./components/conference/conference.component";
import { LiberationComponent } from "./components/liberation/liberation.component";
import { PrintingComponent } from "./components/printing/printing.component";

const routes: Routes = [
   {
      path: "MovimentacaoOld",
      children: [
         { path: "", redirectTo: "Movimentacao", pathMatch: "full" },
         { path: "Processos", component: ProcessesComponent },
         { path: "Conferencia", component: ConferenceComponent },
         { path: "Liberacao", component: LiberationComponent },
         { path: "Impressao", component: PrintingComponent }
      ]
   }
];

@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule]
})
export class MovimentationRoutingModule {}
