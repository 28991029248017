import { formatCurrency } from "@angular/common";
import {
   Component,
   OnInit,
   QueryList,
   TemplateRef,
   ViewChild,
   ViewChildren
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BudgetService } from "@app/services/budget/budget.service";
import { Guid } from "@app/util/guid";
import { SelectOptions, resolveValue } from "./save-budget.model";
import { AnalyteService } from "@app/services/analyte/analyte.service";
import { AnalyteMaterialService } from "@app/services/analyte-material/analyte-material.service";
import { AgreementsService } from "@app/services/agreements/agreements.service";
import { AgreementPlanService } from "@app/services/agreement-plan/agreement-plan.service";
import { AgreementPlan } from "@app/services/agreements/agreements.types";
import { DoctorsService } from "@app/services/doctors/doctors.service";
import { CustomersService } from "@app/services/customers/customers.service";
import { LocalaDatePipe } from "@app/pipe/localeDate.pipe";
import { Util } from "@app/util/util";
import { MatDialog } from "@angular/material/dialog";
import { InputSearchComponent } from "@app/components/form/input-search/input-search.component";
import { BudgetAnalyteService } from "@app/services/budget/budgetAnalyte.service";
import { QueryParams } from "@app/services/interfaces/services.types";
import { UnityParametersService } from "@app/services/unity-parameters/unity-parameters.service";
import decodedToken from "@app/util/Token";
import { InputPropComponent } from "@app/components/form/input-prop/input-prop.component";
import { CollectionPlaceService } from "@app/services/collection-places/collection-place.service";
import { CompanyService } from "@app/services/company/company.service";
import { SectorService } from "@app/services/sector/sector.service";
import { UnityGet } from "@app/services/shared/unity.types";
import { UnityService } from "@app/services/shared/unity.service";
import { CollectionPlaceOriginService } from "@app/services/collection-place-origin/collection-place-origin.service";
import { CollectionPlaceInstructionService } from "@app/services/collection-place-instruction/collection-place-instruction.service";
import { ToastService } from "@app/services/translate/toast.service";
@Component({
   selector: "app-save-budget",
   templateUrl: "./save-budget.component.html",
   styleUrls: ["./save-budget.component.scss"]
})
export class SaveBudgetComponent implements OnInit {
   resolveValue = resolveValue;
   resolveExam = [
      {
         label: "id",
         retrive: "id",
         method: "",
         after: "",
         before: ""
      },
      {
         label: "budgetEdit.resolveExam.check",
         retrive: "",
         method: "returnCheckBox",
         after: "",
         before: ""
      },
      {
         label: "budgetEdit.resolveExam.analyteName",
         retrive: "analyteName",
         method: "",
         after: "",
         before: ""
      }
   ];
   @ViewChild("dialogModal")
   dialogModal!: TemplateRef<any>;

   data: Array<Object> = [];
   registerChecked: Array<Guid> = [];
   checkBoxAll: boolean = false;
   agreementControlPlan: boolean = false;
   budgetNumber: number = 0;
   requisitionNumber: number = 0;
   customerName: string = "";
   customerBirthDate: Date | null = null;
   customerCpf: string = "";
   type: string = "";
   budgetId: string = "";
   companyId: string = "";
   unityId: string = "";
   codeUserOptions: Array<SelectOptions> = [];
   customerOptions: Array<any> = [];
   exams: Array<any> = [];
   values: Array<any> = [];
   doctorOptions: Array<any> = [];
   examOptions: Array<SelectOptions> = [];
   materialExamsOptions: Array<any> = [];
   agreementOptions: Array<{
      value: string;
      label: string;
      controlPlan: boolean;
      externalId: string;
   }> = [];
   agreementPlanOptions: Array<any> = [];
   @ViewChildren(InputSearchComponent)
   inputsSearchs!: QueryList<InputSearchComponent>;
   @ViewChildren(InputPropComponent) inputsProp!: QueryList<InputPropComponent>;

   ActionsExtraValue = {
      "budgetEdit.resolveValue.action": "approve"
   };

   ActionsExtra = {
      Selecionar: "check"
   };

   printOptions = [
      //TODO
      {
         value: 0,
         label: "Impressão Analítica"
      },
      {
         value: 1,
         label: "Impressão Analítica (s/ vlr unit)"
      },
      {
         value: 2,
         label: "Comparativo de Convenios"
      },
      {
         value: 3,
         label: "Comparativo de Convenios (s/ vlr unit)"
      },
      {
         value: 4,
         label: "Impressão Padrão"
      }
   ];

   form = new UntypedFormGroup({
      printType: new UntypedFormControl(null),
      letterhead: new UntypedFormControl(false),
      customer: new UntypedFormControl(""),
      codeCustomer: new UntypedFormControl(null),
      doctor: new UntypedFormControl(""),
      codeDoctor: new UntypedFormControl(null),
      code: new UntypedFormControl(null),
      analyte: new UntypedFormControl(null),
      material: new UntypedFormControl(null),
      codeAgreement: new UntypedFormControl(null),
      agreement: new UntypedFormControl(null),
      codePlan: new UntypedFormControl(null),
      plan: new UntypedFormControl(null)
   });

   formValues = {
      acrescim: new UntypedFormControl(null),
      discountValue: new UntypedFormControl(null),
      discountPercentage: new UntypedFormControl(null),
      liquid: new UntypedFormControl(null)
   };

   formReq = {
      collectionPlaceId: new UntypedFormControl(null),
      companyId: new UntypedFormControl(null),
      unityId: new UntypedFormControl(null),
      requestExternal: new UntypedFormControl(null),
      attendanceExternal: new UntypedFormControl(null),
      bedHospital: new UntypedFormControl(null),
      roomHospital: new UntypedFormControl(null),
      sectorId: new UntypedFormControl(null),
      collectionPlaceOriginId: new UntypedFormControl(null),
      collectionPlaceInstructionId: new UntypedFormControl(null)
   };

   collectionPlaceOptions: Array<any> = [];
   companyOptions: Array<any> = [];
   unityOptions: Array<any> = [];
   sectorOptions: Array<any> = [];
   collectionPlaceOriginOptions: Array<any> = [];
   collectionPlaceInstructionOptions: Array<any> = [];

   @ViewChild("dialogModalRequisition")
   dialogModalRequisition!: TemplateRef<any>;

   constructor(
      private _router: Router,
      private _route: ActivatedRoute,
      private _budgetService: BudgetService,
      private _util: Util,
      private _collectionPlaceService: CollectionPlaceService,
      private analyteService: AnalyteService,
      private analyteMaterialService: AnalyteMaterialService,
      private _unityParametersService: UnityParametersService,
      private _sectorService: SectorService,
      private agreementService: AgreementsService,
      private agreementPlanService: AgreementPlanService,
      private doctorsService: DoctorsService,
      private _companyService: CompanyService,
      private customerService: CustomersService,
      private _dialog: MatDialog,
      private _localeDatePipe: LocalaDatePipe,
      private _budgetAnalyteService: BudgetAnalyteService,
      private _toastService: ToastService,
      private _unityService: UnityService,
      private _collectionPlaceOriginService: CollectionPlaceOriginService,
      private _collectionPlaceInstructionService: CollectionPlaceInstructionService
   ) {}

   async ngOnInit() {
      const id = this._route.snapshot.params["id"].split("$")[0];
      if (id != "Novo") {
         this.budgetId = id;
         await this.getBudgetById();
      } else {
         this.getDefaultAgreement();
      }
   }

   async getBudgetById() {
      this.resolveExam = [
         {
            label: "id",
            retrive: "id",
            method: "",
            after: "",
            before: ""
         },
         {
            label: "budgetEdit.resolveExam.check",
            retrive: "",
            method: "returnCheckBox",
            after: "",
            before: ""
         },
         {
            label: "budgetEdit.resolveExam.analyteName",
            retrive: "analyteName",
            method: "",
            after: "",
            before: ""
         }
      ];
      await this._budgetService
         .getById(new Guid(this.budgetId))
         .then(async (res) => {
            //@ts-ignore
            const budget = res.data[0];
            this.companyId = budget.companyId;
            this.unityId = budget.unityId;
            if (budget.requisitionNumber) {
               this.requisitionNumber = budget.requisitionNumber;
            }
            this.budgetNumber = budget.number;
            this.customerName = budget.customerName
               ? budget.customerName.toUpperCase()
               : budget.customerName;
            this.customerCpf = budget.cpf;
            this.customerBirthDate = budget.customerBirthDate;

            this.customerOptions = [];
            const customerObject = {
               value: budget.customerId,
               label: budget.customerName
            };
            this.customerOptions.push(customerObject);

            this.doctorOptions = [];
            const doctorObject = {
               value: budget.doctorId,
               label: budget.doctorName
            };
            this.doctorOptions.push(doctorObject);

            if (budget.doctorRegistrationNumber) {
               this.form.controls["codeDoctor"].setValue(
                  budget.doctorRegistrationNumber
               );
            }
            this.form.controls["doctor"].setValue(this.doctorOptions[0].value);

            if (budget.customerExternalId) {
               this.form.controls["codeCustomer"].setValue(
                  budget.customerExternalId
               );
            }
            this.form.controls["customer"].setValue(
               this.customerOptions[0].value
            );
            await this.resolveValues(budget.budgetAgreement, this.resolveValue);
            this.data = budget.budgetAnalyte;
            this.agreementOptions = [];

            const agreementObject = {
               externalId: budget.budgetAgreement[0].externalId,
               value: budget.budgetAgreement[0].agreementId,
               label: budget.budgetAgreement[0].description,
               controlPlan: budget.budgetAgreement[0].agreementPlanId
                  ? true
                  : false
            };
            this.form.controls["agreement"].setValue(
               budget.budgetAgreement[0].agreementId
            );
            this.agreementOptions.push(agreementObject);
            if (this.agreementOptions.length == 0) {
               await this.checkControlPlan(
                  this.form.controls["agreement"].value
               );
            }
            if (budget.budgetAgreement[0].agreementPlanId) {
               this.form.controls["plan"].setValue(
                  budget.budgetAgreement[0].agreementPlanId
               );
            }
            let index = 0;
            await budget.budgetAgreement.forEach(async (x: any) => {
               this.resolveExam.push({
                  label: x.description,
                  retrive: "",
                  method: "getAgreement_" + index,
                  after: "",
                  before: ""
               });
               index++;
            });

            this.resolveExam.push({
               label: "Dias",
               retrive: "",
               method: "getDays",
               after: "",
               before: ""
            });

            this.resolveExam.push({
               label: "Material",
               retrive: "materialDescription",
               method: "",
               after: "",
               before: ""
            });

            await this.resolve(this.data, this.resolveExam);
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   back() {
      this._router.navigateByUrl("Movimentacao/Recepcao/Orcamentos");
   }

   async getAnalytes(query: QueryParams) {
      this.form.controls["analyte"].reset();
      this.examOptions = [];
      await this.analyteService.getPagination(query).then(async (res: any) => {
         res.data.forEach((analyte: any) => {
            const selectObject = {
               value: analyte.id,
               label: analyte.name,
               code: analyte.code
            };
            this.examOptions.push(selectObject);
         });
         if (this.examOptions.length == 1) {
            this.form.controls["analyte"].setValue(this.examOptions[0].value);
            await this.getAnalyteMaterial(this.form.controls["analyte"].value);
            const materialList = await this.getAnalyteMaterialOptions(
               this.form.controls["analyte"].value
            );
            this.setFocus("budgetEdit.material");
            materialList && materialList.length === 1
               ? this.form.controls["material"].setValue(materialList[0].value)
               : this.form.controls["material"].setValue(materialList);
            materialList?.forEach((x: any) => {
               if (x.isDefaultMaterial) {
                  this.form.controls["material"].setValue(x.value);
               }
            });
         } else {
            this.setFocus("budgetEdit.analyte");
         }
      });
   }

   async getCodeByAnalyte() {
      const analyteId = this.form.controls["analyte"].value;
      const selectedAnalyte: any = this.examOptions.find(
         (analyte) => analyte.value === analyteId
      );
      if (selectedAnalyte) {
         this.form.controls["code"].setValue(selectedAnalyte.code);
         this.form.controls["analyte"].setValue(selectedAnalyte.value);
         await this.getAnalyteMaterial(selectedAnalyte.value);

         const materialList = await this.getAnalyteMaterialOptions(
            selectedAnalyte.value
         );

         materialList && materialList.length === 1
            ? this.form.controls["material"].setValue(materialList[0].value)
            : this.form.controls["material"].setValue(materialList);
         materialList?.forEach((x: any) => {
            if (x.isDefaultMaterial) {
               this.form.controls["material"].setValue(x.value);
            }
         });
      } else {
         this._toastService.warningMessage("budgetEdit.msgNotExam");
         this.form.controls["code"].setValue("");
         this.form.controls["analyte"].setValue("");
         this.form.controls["material"].setValue("");
      }
   }

   async getAnalyteByCode() {
      const code = this.form.controls["code"].value;
      if (!code) return;
      const selectedAnalyte = this.examOptions.find(
         (exam: any) => exam.code === code
      );
      if (selectedAnalyte == undefined) {
         await this.getAnalytes({ code });
      }
   }

   async getAnalyteMaterial(analyteId: string) {
      const materialExam: Array<SelectOptions> = [];

      const queryParams = {
         isActive: true,
         isDeleted: false,
         analyteId: analyteId
      };

      if (analyteId) {
         await this.analyteMaterialService
            .getPagination(queryParams)
            .then((res) => {
               res.data.forEach((material: any) => {
                  const selectObject = {
                     value: material.materialId,
                     label: material.description,
                     isDefaultMaterial: material.isDefaultMaterial
                  };
                  materialExam.push(selectObject);
               });

               const analyteMaterialObject = {
                  analyteId: analyteId,
                  analyteMaterials: materialExam
               };

               this.materialExamsOptions.push(analyteMaterialObject);
            });
      }
   }

   getAnalyteMaterialOptions(analyteId: string): any[] | null {
      let analyteMaterialOptions: any[] = [];

      if (this.materialExamsOptions.length > 0) {
         analyteMaterialOptions = this.materialExamsOptions.filter(
            (analyteMaterial) => analyteMaterial.analyteId === analyteId
         );
      }

      if (analyteMaterialOptions.length > 0) {
         return analyteMaterialOptions[0].analyteMaterials;
      }

      return null;
   }

   async searchAgreement(search?: string, searchByCode?: boolean) {
      if (search != null) {
         this.form.controls["agreement"].setValue("");
         this.form.controls["plan"].setValue("");
         this.agreementOptions = [];
         let query: QueryParams = {
            search: search
         };
         if (searchByCode) {
            query = {
               ExternalCode: search
            };
         }
         await this.agreementService.getPagination(query).then((res) => {
            res.data.forEach((agreement: any) => {
               const agreementObject = {
                  value: agreement.id,
                  label: agreement.description,
                  controlPlan: agreement.controlPlan,
                  externalId: agreement.externalId
               };
               this.agreementOptions.push(agreementObject);
            });
            if (res.data.length == 0) {
               this._toastService.warningMessage("budgetEdit.msgNotPlan");
            }
         });
         this.setFocus("budgetEdit.agreement");

         if (searchByCode) {
            if (this.agreementOptions.length == 1) {
               this.form.controls["agreement"].setValue(
                  this.agreementOptions[0].value
               );
            }
         }
      }
   }

   async checkControlPlan(agreementId: string, focus: boolean = true) {
      await this.setAgreementCode(agreementId);
      if (agreementId) {
         const agreementObject = await this.agreementOptions.find(
            (agreement) => agreement.value === agreementId
         );
         if (agreementObject) {
            this.agreementControlPlan = agreementObject.controlPlan;
            if (this.agreementControlPlan)
               await this.getAgreementPlan(agreementId, focus);
         } else {
            this._toastService.fireError("budgetEdit.msgErrorPlan");
         }
      }
   }

   setAgreementCode(agreementId: string) {
      if (this.agreementOptions.length > 0 && agreementId) {
         //@ts-ignore
         const externalId = this.agreementOptions
            .filter((agreement: any) => agreement.value == agreementId)[0]
            .externalId?.replace(".", "");
         this.form.controls["codeAgreement"].setValue(externalId);
      }
   }

   async getAgreementPlan(agreementId: string, focus: boolean) {
      this.agreementPlanOptions = [];
      await this.agreementPlanService
         .getPagination<AgreementPlan>({ AgreementId: agreementId })
         .then((res) => {
            res.data.forEach((agreementPlan) => {
               const agreementPlanObject: SelectOptions =
                  this.generateSelectOption(
                     agreementPlan.id,
                     agreementPlan.description
                  );
               this.agreementPlanOptions.push(agreementPlanObject);
               if (res.data.length === 1) {
                  this.form.controls["plan"].setValue(agreementPlan.id);
               } else {
                  if (focus) {
                     this.setFocus("budgetEdit.plan");
                  }
               }
            });
         });
   }

   private generateSelectOption(value: string, label: string) {
      const selectObject = {
         value: value,
         label: label
      };
      return selectObject;
   }

   async searchDoctor(search?: string, searchByCode?: boolean) {
      if (search != null) {
         this.form.controls["doctor"].setValue(undefined);
         this.doctorOptions = [];
         let obj: any = {
            name: search
         };
         if (searchByCode) {
            obj = {
               RegistrationNumber: search
            };
         }
         await this.doctorsService.getPagination(obj).then((res) => {
            res.data.forEach((doctor: any) => {
               const nameConselho = `${doctor.name} - ${doctor.council}/${doctor.registrationState}: ${doctor.registrationNumber}`;
               const doctorObject = {
                  value: doctor.id,
                  label: nameConselho,
                  registrationNumber: doctor.registrationNumber
               };
               this.doctorOptions.push(doctorObject);
            });
            if (res.data.length == 0) {
               this._toastService.warningMessage("budgetEdit.msgNotDoctor");
            }
         });
         this.setFocus("budgetEdit.doctor");

         if (searchByCode) {
            if (this.doctorOptions.length == 1) {
               this.form.controls["doctor"].setValue(
                  this.doctorOptions[0].value
               );
            }
         }
      }
   }

   setDoctorCode(doctorId: string) {
      if (this.doctorOptions.length > 0) {
         this.form.controls["codeDoctor"].setValue(
            this.doctorOptions.filter(
               (doctor: any) => doctor.value == doctorId
            )[0].registrationNumber
         );
      }
   }

   setCustomerCode(customerId: string) {
      if (this.customerOptions.length > 0) {
         this.form.controls["codeCustomer"].setValue(
            this.customerOptions.filter(
               (customer: any) => customer.value == customerId
            )[0].externalId
         );
      }
   }

   async searchCustomer(search?: string, searchByCode?: boolean) {
      if (search != null) {
         this.form.controls["customer"].setValue("");
         this.customerOptions = [];
         let obj: any = {
            name: search,
            resume: true
         };
         if (searchByCode) {
            obj = {
               ExternalId: search
            };
         }
         await this.customerService.getPagination(obj).then((res) => {
            res.data.forEach((customer: any) => {
               const customerObject = {
                  value: customer.id,
                  label: customer.name,
                  externalId: customer.externalId
               };
               this.customerOptions.push(customerObject);
            });
            if (res.data.length == 0) {
               this._toastService.warningMessage("budgetEdit.msgNotCustomer");
            }
         });
         this.setFocus("budgetEdit.customer");

         if (searchByCode) {
            if (this.customerOptions.length == 1) {
               this.form.controls["customer"].setValue(
                  this.customerOptions[0].value
               );
            }
         }
      }
   }

   async resolve(data: any, columns: any) {
      this.exams = [];
      await data.forEach(async (x: any) => {
         let obj: any = {};
         await columns.forEach((y: any) => {
            switch (y.method) {
               case "formatField":
                  obj[y.label] =
                     y.before +
                     this._util.formatField(
                        x,
                        y.fieldName,
                        y.type,
                        y.interpolationFields,
                        y.dateFormat
                     ) +
                     y.after;
                  break;
               case "":
                  obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
                  break;
               default:
                  //@ts-ignore
                  obj[y.label] = y.before + this[y.method](x) + y.after;
                  break;
            }
         });
         this.exams.push(obj);
      });
   }

   async resolveValues(data: any, columns: any) {
      this.values = [];
      await data.forEach(async (x: any) => {
         let obj: any = {};
         await columns.forEach((y: any) => {
            if (
               y.label == "budgetEdit.resolveValue.action" ||
               y.label == "budgetEdit.resolveValue.plan"
            ) {
               //@ts-ignore
               obj[y.label] = y.before + this[y.method](x) + y.after;
            } else {
               if (!y.retrive) {
                  obj[y.label] =
                     y.before +
                     this._util.formatField(
                        x,
                        y.fieldName,
                        y.type,
                        y.interpolationFields,
                        y.dateFormat
                     ) +
                     y.after;
               } else {
                  obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
               }
            }
         });
         this.values.push(obj);
      });
   }

   returnCheckBox() {
      if (this.checkBoxAll) {
         return "|checkbox|true";
      } else {
         return "|checkbox";
      }
   }

   async Actions(action: any) {
      switch (action.action) {
         case "Excluir":
            this._toastService
               .fireConfirmation("budgetEdit.msgDeleteExam")
               .then(async (x) => {
                  if (x.isConfirmed) {
                     this._budgetAnalyteService
                        .delete(action.object.id)
                        .then((res: any) => {
                           this._toastService.fireSuccess(
                              "budgetEdit.msgDeleteSuccess"
                           );
                           this.getBudgetById();
                        })
                        .catch((err) => {
                           this._toastService.fireError(err);
                        });
                  }
               })
               .catch((err: any) => {
                  this._toastService.fireError(err);
               });
            break;
         case "check":
            if (action.$event.srcElement.checked != undefined) {
               var ind = this.registerChecked.findIndex(
                  (x: any) => x == action.object.id
               );
               if (action.$event.srcElement.checked) {
                  if (ind == -1) {
                     this.registerChecked.push(action.object.id);
                  }
               } else {
                  if (ind > -1) {
                     this.registerChecked.splice(ind, 1);
                  }
               }
            }
            break;
      }
   }

   async ActionsValue(action: any) {
      switch (action.action) {
         case "Acréscimo/Desconto":
            this.openDialogModal();
            break;
         case "approve":
            this.openDialogModalRequisition(action.object.id);
            break;
         case "Excluir":
            break;
      }
   }

   checkboxAllClick(event: any) {
      if (event.srcElement.checked != undefined) {
         this.checkBoxAll = event.srcElement.checked;
         this.registerChecked = [];
         if (this.checkBoxAll) {
            this.data.forEach((exam: any) => {
               this.registerChecked.push(exam.id);
            });
         }

         this.resolve(this.data, this.resolveExam);
      }
   }

   getActionButton(item: any) {
      if (item.approved) {
         return "<span class='span-styles' style='background: #07BF56; color: #F8F8FF;'>Aprovado</span>";
      } else {
         return "Aprovar|button|reverse";
      }
   }

   openDialogModal() {
      const myTempDialog = this._dialog.open(this.dialogModal, {
         width: "660px"
      });
   }

   async openDialogModalRequisition(id: String) {
      if (this.form.controls["customer"].value == undefined) {
         this._toastService.warningMessage("Selecione um cliente!");
         this.setFocus("budgetEdit.customer");
      }

      if (
         this.form.controls["customer"].value != undefined &&
         this.form.controls["doctor"].value == undefined
      ) {
         this._toastService.warningMessage("Selecione um solicitante!");
         this.setFocus("budgetEdit.doctor");
      }

      if (
         this.form.controls["customer"].value != undefined &&
         this.form.controls["doctor"].value != undefined
      ) {
         this.companyOptions = [];
         await this._companyService
            .getResume()
            .then((res) => {
               if (res.data.length != 0) {
                  res.data.forEach((x) => {
                     const optionObj = {
                        value: x.id,
                        label: x.socialReason
                     };
                     this.companyOptions.push(optionObj);
                  });
               }
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });

         this.formReq.companyId.setValue(this.companyId);

         await this.getUnities();
         this.formReq.unityId.setValue(this.unityId);

         this.collectionPlaceOptions = [];
         this._collectionPlaceService
            .getResume()
            .then((res: any) => {
               res.data.forEach((x: any) => {
                  const optionObj = {
                     value: x.id,
                     label: x.description
                  };
                  this.collectionPlaceOptions.push(optionObj);
               });
            })
            .catch((err) => {
               this._toastService.fireError(err);
            });

         this.sectorOptions = [];
         this._sectorService.getResume().then((response: any) => {
            response.data.forEach((data: any) => {
               this.sectorOptions.push({
                  value: data.id,
                  label: data.description,
                  externalId: data.externalId
               });
            });
         });

         this.getCollectionInstruction();
         this.getCollectionPlaceOrigin();

         const myTempDialog = this._dialog.open(this.dialogModalRequisition, {
            width: "660px",
            data: id
         });
      }
   }

   close() {
      this._dialog.closeAll();
   }

   async createExam() {
      let budget = {
         budgetId: this.budgetId ? this.budgetId : null,
         customerId:
            this.form.controls["customer"].value != ""
               ? this.form.controls["customer"].value
               : null,
         doctorId:
            this.form.controls["doctor"].value != ""
               ? this.form.controls["doctor"].value
               : null,
         analyteId: this.form.controls["analyte"].value,
         materialId: this.form.controls["material"].value
      };

      await this._budgetAnalyteService
         .post(budget)
         .then((res: any) => {
            if (res.success) {
               this.examOptions = [];
               this.form.controls["analyte"].reset();
               this.form.controls["material"].reset();
               this.form.controls["code"].reset();
               if (this.budgetId == "") {
                  //@ts-ignore
                  this.budgetId = res.id;
                  this._router.navigateByUrl(
                     "/MovimentacaoOld/Recepcao/Orcamentos/" + this.budgetId
                  );
                  this._toastService.fireSuccess("budgetEdit.msgPostBudget");
               } else {
                  this._toastService.fireSuccess("budgetEdit.msgPostExam");
               }
               const queryListArray: Array<InputPropComponent> =
                  this.inputsProp.toArray();
               const codeInputProp = queryListArray.find(
                  (inputProp) => inputProp.placeholder === "Código"
               );
               if (codeInputProp) {
                  setTimeout(() => {
                     codeInputProp.input.nativeElement.focus();
                     codeInputProp.input.nativeElement.click();
                  });
               }
               this.getBudgetById();
            }
         })
         .catch(
            //@ts-ignore
            (err) => {
               this._toastService.fireError(err);
            }
         );
   }

   getAgreement(item: any) {
      return formatCurrency(
         item.budGetResponseAgreements[0].balance ?? 0,
         "pt-BR",
         "R$"
      );
   }

   getDays(item: any) {
      if (item.deadline == 1) {
         return "1 útil";
      } else {
         return item.deadline + " úteis";
      }
   }

   changeValues() {}

   async changeAgreement() {
      await this._budgetService
         .putWithPath(
            {
               agreementId: this.form.controls["agreement"].value,
               agreeementPlanId:
                  this.form.controls["plan"].value != ""
                     ? this.form.controls["plan"].value
                     : null
            },
            new Guid(this.budgetId),
            "updateagreement"
         )
         .then(async () => {
            this._toastService.fireSuccess("budgetEdit.msgPutPlan");
            await this.getBudgetById();
         });
   }

   async searchAnalyte(search: string) {
      const query: QueryParams = { search };
      await this.getAnalytes(query);
   }

   setFocus(field: string) {
      const queryListArray: Array<InputSearchComponent> =
         this.inputsSearchs.toArray();
      const codeInputProp = queryListArray.find(
         (inputProp) => inputProp.placeholder === field
      );
      if (codeInputProp) {
         setTimeout(() => {
            codeInputProp.input.nativeElement.focus();
            codeInputProp.input.nativeElement.click();
         });
      }
   }

   async getDefaultAgreement() {
      const query: QueryParams = {
         CompanyId: decodedToken.cid,
         UnityId: decodedToken.uid
      };
      await this._unityParametersService
         .getPagination(query)
         .then((response: any) => {
            const agreementObject = {
               externalId: response.data[0].externalId,
               value: response.data[0].agreementDefaultId,
               label: response.data[0].agreementDefaultDescription,
               controlPlan: response.data[0].agreementPlanDefaultId
                  ? true
                  : false
            };
            this.form.controls["agreement"].setValue(
               response.data[0].agreementDefaultId
            );
            this.agreementOptions.push(agreementObject);
            this.checkControlPlan(this.form.controls["agreement"].value, false);
         });
   }

   getPlan(item: any) {
      return item.agreementPlanDescription ? item.agreementPlanDescription : "";
   }

   async createRequisition(budgetRequestId: any) {
      const obj = {
         budgetRequestId: budgetRequestId,
         companyId: this.formReq.companyId.value,
         unityId: this.formReq.unityId.value,
         collectionPlaceId: this.formReq.collectionPlaceId.value,
         requestExternal: this.formReq.requestExternal.value,
         attendanceExternal: this.formReq.attendanceExternal.value,
         bedHospital: this.formReq.bedHospital.value,
         roomHospital: this.formReq.roomHospital.value,
         sectorId: this.formReq.sectorId.value
      };
      await this._budgetService
         .putWithPath(obj, new Guid(this.budgetId), "createrequisition")
         .then((res: any) => {
            this._toastService.fireSuccess("budgetEdit.msgPostRequisition");
            this._dialog.closeAll();
            this._router.navigateByUrl(
               `MovimentacaoOld/Recepcao/Requisicoes/${res.id}`
            );
         })
         .catch((err) => {
            this._toastService.fireError(err);
         });
   }

   getAgreement_0(item: any) {
      return this.formatValue(item.agreementData[0].balance);
   }

   getAgreement_1(item: any) {
      return this.formatValue(item.agreementData[1].balance);
   }

   getAgreement_2(item: any) {
      return this.formatValue(item.agreementData[2].balance);
   }

   getAgreement_3(item: any) {
      return this.formatValue(item.agreementData[3].balance);
   }

   async getUnities() {
      const query: QueryParams = {
         resume: true,
         isActive: true,
         companyId: this.formReq.companyId.value
      };
      await this._unityService.getPagination<UnityGet>(query).then((res) => {
         this.unityOptions = res.data.map((unity) => ({
            label: unity.name,
            value: unity.id
         }));
      });
   }

   formatValue(fieldValue: any) {
      const options = {
         style: "currency",
         currency: "BRL",
         minimumFractionDigits: 2
      };
      return fieldValue.toLocaleString("pt-BR", options);
   }

   async getCollectionPlaceOrigin() {
      this.collectionPlaceOriginOptions = [];
      await this._collectionPlaceOriginService
         .getResume(true)
         .then((response) => {
            response.data.forEach((origin: any) => {
               const collectionObj = {
                  value: origin.id,
                  label: origin.description
               };
               this.collectionPlaceOriginOptions.push(collectionObj);
            });
         });
   }

   async getCollectionInstruction() {
      this.collectionPlaceInstructionOptions = [];
      await this._collectionPlaceInstructionService
         .getResume(true)
         .then((response) => {
            response.data.forEach((instruction: any) => {
               const collectionObj = {
                  value: instruction.id,
                  label: instruction.description
               };
               this.collectionPlaceInstructionOptions.push(collectionObj);
            });
         });
   }
}
