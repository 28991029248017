import { Injectable, NgModule } from "@angular/core";
import {
   HttpEvent,
   HttpInterceptor,
   HttpHandler,
   HttpRequest,
   HttpResponse
} from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
   BehaviorSubject,
   filter,
   map,
   Observable,
   switchMap,
   take,
   throwError
} from "rxjs";
import { catchError } from "rxjs/operators";
import { RefreshService } from "./services/user/refresh.service";
import { SwAlSetttings } from "./util/swal.settings";
import { LoadingService } from "./loading.service";
import { UserUtil } from "./util/user";

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
   private isRefreshing = false;
   private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
      null
   );

   constructor(
      private _authService: RefreshService,
      private _loading: LoadingService,
      private _UserUtil: UserUtil
   ) {}

   intercept(
      req: HttpRequest<any>,
      next: HttpHandler
   ): Observable<HttpEvent<any>> {
      var token: string | null;
      var accessType: string | null;
      if (localStorage.getItem("token")) {
         token = localStorage.getItem("token");
         accessType = "";
      } else {
         token = sessionStorage.getItem("token");
         accessType = sessionStorage.getItem("accessType");
      }

      //VERIFICA SE NÃO É O REQUEST DO LOGIN
      if (req.body != null) {
         if (req.url.includes("login")) {
            this._loading.setLoading(true, req.url, req.method);
            return next
               .handle(req)
               .pipe(
                  catchError((error: any) => {
                     this._loading.setLoading(false, req.url, req.method);
                     return throwError(error);
                  })
               )
               .pipe(
                  map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
                     if (evt instanceof HttpResponse) {
                        this._loading.setLoading(false, req.url, req.method);
                     }
                     return evt;
                  })
               );
         }
      }

      let userLang;
      if (localStorage.getItem("lang") === "es") {
         userLang = `${localStorage.getItem("lang")}-ES`;
      } else if (localStorage.getItem("lang") === "en") {
         userLang = `${localStorage.getItem("lang")}-US`;
      } else {
         userLang = localStorage.getItem("lang");
      }

      const dupReq = req.clone({
         headers: req.headers
            .set("authorization", token ? token : "")
            .set("front-route", window.location.pathname)
            .set("Accept-Language", `${userLang}`)
            .set("Cache-Control", "no-cache")
            .set("Pragma", "no-cache")
            .set("Expires", "Sat, 01 Jan 2000 00:00:00 GMT")
      });

      if (dupReq.url.search("/saas.concentsistemas.pix/") > 0) {
         this._loading.setLoading(true, dupReq.url, dupReq.method);
         return next.handle(req).pipe(
            map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
               if (evt instanceof HttpResponse) {
                  this._loading.setLoading(false, dupReq.url, dupReq.method);
               }
               return evt;
            })
         );
      }

      this._loading.setLoading(true, dupReq.url, dupReq.method);
      return next
         .handle(dupReq)
         .pipe(
            catchError((error: any) => {
               this._loading.setLoading(false, dupReq.url, dupReq.method, true);
               //    if (error.status === 401) {
               //       return this.handle401Error(dupReq, next);
               //    }

               if (error.status === 500) {
                  if (error.error.type === "abort") {
                     SwAlSetttings.ALERT_ERROR.fire({
                        title: "O servidor não conseguiu processar sua solicitação. Estamos trabalhando para resolver este problema.",
                        html: 'Por favor, tenha paciência. Se isso persistir, entre em contato <a href="http://concentsistemas.selfip.com:8181/producao/servlet/hsd8000">conosco</a>'
                     });
                  } else {
                     SwAlSetttings.ALERT_ERROR.fire({
                        title: "Ocorreu um erro!",
                        html: 'Se o erro persistir, entre em contato <a href="http://concentsistemas.selfip.com:8181/producao/servlet/hsd8000">conosco</a>'
                     });
                  }

                  return throwError(error);
               }

               if (error.status === 504) {
                  if (error.error.type === "abort") {
                     SwAlSetttings.ALERT_ERROR.fire({
                        title: "A solicitação não pôde ser concluída devido a um atraso na comunicação com o servidor.",
                        html: 'Verifique sua conexão de internet e, caso necessário, entre em contato <a href="http://concentsistemas.selfip.com:8181/producao/servlet/hsd8000">conosco</a>'
                     });
                  } else {
                     SwAlSetttings.ALERT_ERROR.fire({
                        title: "Ocorreu um erro!",
                        html: 'Se o erro persistir, entre em contato <a href="http://concentsistemas.selfip.com:8181/producao/servlet/hsd8000">conosco</a>'
                     });
                  }

                  return throwError(error);
               }
               // if (error.status === 500 || error.status === 0 || error.status == 503 || error.status == 504) {
               //     if (error.error.type === "abort") {
               //         SwAlSetttings.ALERT_ERROR.fire({
               //             title: 'Ops... deu ruim!',
               //             text: "Algo interrompeu a comunicação, tente novamente!"
               //         });

               //     } else {
               //         SwAlSetttings.ALERT_ERROR.fire({
               //             title: 'Ops... deu ruim!',
               //             text: "Um erro interno ocorreu (50x). Mas não se preocupe, estamos trabalhando nisso."
               //         });
               //     }
               //     return throwError(error);
               // }
               SwAlSetttings.printError(error);
               return throwError(error);
            })
         )
         .pipe(
            map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
               if (evt instanceof HttpResponse) {
                  this._loading.setLoading(false, dupReq.url, dupReq.method);
               }
               return evt;
            })
         );
   }

   private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
      // if (!this.isRefreshing) {
      //     this.isRefreshing = true;
      //     this.refreshTokenSubject.next(null);
      //     return this._authService.refreshToken().pipe(
      //         switchMap((token: any) => {
      //             this.isRefreshing = false;
      //             if (token.accessToken)
      //                 this.refreshTokenSubject.next(token.accessToken);
      //             this._loading.setLoading(true, request.url);
      //             if (token.accessToken) {
      //                 // @ts-ignore
      //                 return next.handle(this.addTokenHeader(request, token.accessToken));
      //             }
      //             return next.handle(request);
      //         }),
      //         catchError((err) => {
      //             err.httpStatusCode ? this._loading.setLoading(true, request.url) : this._loading.setLoading(false, request.url)
      //             this.isRefreshing = false;
      //             SwAlSetttings.closeLoader();
      //             SwAlSetttings.ALERT_ERROR.fire({
      //                 title: 'Seu usuário não possui permissão para acessar este recurso!',
      //                 text: 'Por favor, faça o Login com credenciais válidas ou solicite permissão ao Administrador!'
      //             });
      //             return throwError(err);
      //         })
      //     );
      // }
      // if (request.body) {
      //     if (request.body.hasOwnProperty('accessToken')) {
      //         SwAlSetttings.ALERT_ERROR.fire({
      //             title: 'Seu acesso expirou!',
      //             text: "Para sua segurança você será redirecionado para o Login!"
      //         });
      //         setTimeout(() => {
      //             this._loading.setLoading(true, request.url);
      //             this._UserUtil.ExpirouSessao()
      //             this._loading.setLoading(false, request.url);
      //         }, 3000);
      //     }
      // }
      // return this.refreshTokenSubject.pipe(
      //     filter(token => token !== null),
      //     take(1),
      //     // @ts-ignore
      //     switchMap((token) => next.handle(this.addTokenHeader(request, token)))
      // );
   }

   private addTokenHeader(request: HttpRequest<any>, token: string) {
      if (token && token != "null") {
         localStorage.setItem("token", token);
         /* for Spring Boot back-end */
         // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
         /* for Node.js Express back-end */
         return request.clone({
            headers: request.headers.set("authorization", token)
         });
      } else {
         return null;
      }
   }
}

@NgModule({
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useClass: HttpsRequestInterceptor,
         multi: true
      }
   ]
})
export class Interceptor {}
