<div class="contentPage">
  <div class="flex align-center flex-between">
      <div class="titulo">
          <div class="flex align-center gap-2">              
            <app-button (click)="back()" [placeholder]="'global.back'" [class]="'btn-reverse'"></app-button>
              <h1 *ngIf="!this.companyId">Nova Empresa</h1>
              <h1 *ngIf="this.companyId">Editar Empresa</h1>
          </div>
      </div>
  </div>
  <div class="contextPage mt-32">
    <div class="flex w-100 gap-3">
      <div class="flex flex-col box pd-12 w-100 gap-form">
        <h3>Dados Gerais</h3>
        <input-prop
          [placeholder]="'Código'"        
          [form]="form.externalId"
        ></input-prop>
        <input-prop
        type="text"
        [placeholder]="'Nome'"        
        [form]="form.empresa"
      ></input-prop>
        <input-prop
          type="text"
          [placeholder]="'Razão Social'"        
          [form]="form.socialReason"
        ></input-prop>
        <input-prop
          type="text"
          [mask]="'00.000.000/0000-00'"
          [placeholder]="'CNPJ'"        
          [form]="form.cnpj"
        ></input-prop>      
      </div>
      <div class="flex flex-col box pd-12 w-100 gap-form">
        <h3>Dados Endereço</h3>
        <input-prop
          type="text"
          [placeholder]="'CEP'"
          [mask]="'00000-000'"
          [form]="form.cep"
          (focusout)="getCep()"
        ></input-prop>
        <input-prop
          type="text"
          [placeholder]="'Logradouro'"
          [form]="form.Logradouro"
        ></input-prop>
        <input-prop
          type="text"
          [placeholder]="'Número'"
          [form]="form.Numero"
        ></input-prop>
        <input-prop
          type="text"
          [placeholder]="'Complemento'"
          [form]="form.Complemento"
        ></input-prop>
        <input-prop
          type="text"
          [placeholder]="'Bairro'"
          [form]="form.Bairro"
        ></input-prop>
        <input-prop
          type="text"
          [placeholder]="'Cidade'"
          [form]="form.Cidade"
        ></input-prop>
        <input-prop
          type="text"
          [placeholder]="'Estado'"
          [form]="form.Estado"
        ></input-prop>
        <input-prop
          type="text"
          [placeholder]="'País'"
          [form]="form.country"
        ></input-prop>
      </div>    
    </div>  
    <div class="flex flex-center mt-32">
      <app-button (click)="this.companyId ? editCompany() : postCompany()" [placeholder]="'Salvar'" [class]="'btn'"></app-button>
    </div>
  </div>
</div>