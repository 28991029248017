import { Injectable } from '@angular/core';
import { Guid } from '@app/util/guid';
import { BaseAuthService } from '@services/auth/base-auth.service';
import { HttpAuthService } from '@services/auth/http-auth.service';
import { UnityGet } from './unity.types';
import { GetAllUnities, GetByIdUnity } from '../auth/unity/unity.types';
import { HttpResponsePageable } from '../services.types';
import { QueryParams } from '../interfaces/services.types';

@Injectable({
    providedIn: 'root'
})
export class UnityService extends BaseAuthService {

    constructor(HttpAuthService: HttpAuthService) {
        super(HttpAuthService, 'v1/shared/Unity');
    }    

    override getResume(active?: boolean){
        return super.getResume(active) as Promise<HttpResponsePageable<UnityGet>>
    }
    override getAll() {
        return super.getAll() as Promise<HttpResponsePageable<GetAllUnities>>
    }

    override getByPath(queryString: string, path: string, headers?: String[]) {
        return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetAllUnities>>
    }

    override getById(id: Guid) {
        return super.getById(id) as Promise<HttpResponsePageable<GetByIdUnity>>
    }

    override getPagination<T>(queryString?: QueryParams) {
        return super.getPagination(queryString) as Promise<HttpResponsePageable<T>>
    }
}
