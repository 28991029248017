import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { Guid } from 'src/app/util/guid';
import { AgreementPostObject, GetAllAgreements, GetByIdAgreements } from './agreements.types';
import { QueryParams } from '../interfaces/services.types';

@Injectable({
  providedIn: 'root'
})

export class AgreementsService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/agreement');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllAgreements>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<GetAllAgreements>>
  }

  override getById(id: Guid){
    return super.getById(id) as Promise<HttpResponsePageable<GetByIdAgreements>>
  }
  
  override post(body: AgreementPostObject) {
    return super.post(body) as Promise<HttpResponsePageable<AgreementPostObject>>
  }

  override getPagination<GetPaginationAgreement>(queryObject?: QueryParams | undefined){
    return super.getPagination(queryObject) as Promise<HttpResponsePageable<GetPaginationAgreement>> 
  }
}