import { inject, Injectable } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import { IpAddressesReleasedService } from "../services/controle-de-acesso/controle-de-acesso.service";
import { QueryParams } from "@app/modules_new/data/services/types/http.types";
import {
   IpAddressesReleasedResponse,
   IpAddressesReleasedsResponse
} from "../services/controle-de-acesso/controle-de-acesso.types";
import { MatDialog } from "@angular/material/dialog";
import { Guid } from "@app/util/guid";

@Injectable({
   providedIn: "root"
})
export class IpAddressesReleasedFacade extends MultiObservableFacade {
   private _IpAddressesReleasedService = inject(IpAddressesReleasedService);
   private _matDialog = inject(MatDialog);

   private _IpAddresses =
      this.createNullableObservableControl<IpAddressesReleasedResponse[]>();
   private _IpAddressesAmount = this.createObservableControl<number>(0);

   private _sectorPage = this.createObservableControl<number>(1);
   private _sectorsNumberRegistry = this.createObservableControl<number>(30);

   get(query: QueryParams = {}) {
      if (query["page"] && query["numberRegistry"]) {
         this._sectorPage.subject.next(query["page"] as number);
         this._sectorsNumberRegistry.subject.next(
            query["numberRegistry"] as number
         );
      }

      query["page"] = this._sectorPage.subject.value;
      query["numberRegistry"] = this._sectorsNumberRegistry.subject.value;

      this._IpAddressesReleasedService
         .get<IpAddressesReleasedsResponse>({ query })
         .subscribe({
            next: (response) => {
               this._IpAddresses.subject.next(response.data);
               this._IpAddressesAmount.subject.next(response.sumRecords);
            },
            error: (err) => {
               this._IpAddresses.subject.next([]);
               this._IpAddressesAmount.subject.next(0);
            }
         });
      return {
         data$: this._IpAddresses.observable$,
         total$: this._IpAddressesAmount.observable$
      };
   }

   post(obj: any) {
      this.ToastPopUp("ip_address.register-confirmation")
         .then((response) => {
            if (response.isConfirmed) {
               this._IpAddressesReleasedService.post(obj).subscribe({
                  next: () => {
                     this.ToastSuccess("toast-msg.creation.success");
                     this._matDialog.closeAll();
                     this.get();
                  },
                  error: (error) => {
                     this.ToastErrorGeneric(error);
                  }
               });
            }
         })
         .catch((error) => {
            this.ToastErrorGeneric(error, "toast-msg.change.error");
         });
   }

   put(obj: any, id: string) {
      this._IpAddressesReleasedService.put(obj, new Guid(id)).subscribe({
         next: () => {
            this.ToastSuccess("toast-msg.change.success");
            this._matDialog.closeAll();
            this.get();
         },
         error: (error) => {
            this.ToastErrorGeneric(error);
         }
      });
   }

   patchActive(id: string, value: string) {
      this._IpAddressesReleasedService
         .patchToggleIsActive(id, !value)
         .subscribe({
            next: () => {
               this.ToastSuccess(
                  value
                     ? "toast-msg.change-to-inactive.success"
                     : "toast-msg.change-to-active.success"
               );
               this.get();
            },
            error: (error) => {
               this.ToastErrorGeneric(error, "toast-msg.creation.error");
            }
         });
   }

   delete(id: string) {
      this.ToastPopUp("ip_address.deletion-confirmation")
         .then((response) => {
            if (response.isConfirmed) {
               this._IpAddressesReleasedService.delete(new Guid(id)).subscribe({
                  next: () => {
                     this.get();
                     this.ToastSuccess("ip_address.delete");
                  },
                  error: (error) => {
                     this.ToastErrorGeneric(error, "toast-msg.creation.error");
                  }
               });
            }
         })
         .catch((error) => {
            this.ToastErrorGeneric(error, "toast-msg.change.error");
         });
   }
}
