<div class="contentPage">
  <div class="flex align-center w-100 flex-direction-column-mobile gap-4">
    <div class="flex align-center gap-1 flex-direction-column-mobile">
      <button (click)="back()" class="btn-reverse">Voltar</button>
      <h1>Mensagens</h1>
    </div>
    <button (click)="openDialogModalNewMessage()">Nova Mensagem</button>
  </div>
  <div class="mt-32">
    <app-table [Dropdown]="true" *ngIf="registerMessages.length > 0" [Actions]="['Responder', 'Editar']" [Pagination]="false" [backPagination]="false" [Itens]="registerMessages" (parentFun)="actions($event)">
      <ng-template let-rowDetail #header>
        <app-table [Actions]="['Visualizado', 'Editar']" [Itens]="resolve2(filtro(rowDetail.id),resolveResponse)" (parentFun)="actions2($event)"></app-table>
      </ng-template>
    </app-table>
  </div>
</div>

<ng-template #dialogModalNewMessage let-message>
<ng-container>
  <div>
    <div class="flex flex-between w-100 align-center">
      <h1>Nova Mensagem</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="mt-32">
      <textarea-prop [placeholder]="'Informe aqui o conteúdo da mensagem'" [form]="formSend"></textarea-prop>
    </div>
    <div class="mt-32 flex flex-center">
      <button (click)="sendMessage()" class="btn">Enviar Mensagem</button>
    </div>
  </div>
</ng-container>
</ng-template>

<ng-template #dialogModalResponse let-messageId>
<ng-container>
  <h1>Responder</h1>
  <div class="mt-32">
    <textarea-b-prop [placeholder]="'Informe aqui o conteúdo da mensagem'" [form]="formSend"></textarea-b-prop>
  </div>
  <div class="mt-32 flex flex-center">
    <button (click)="sendMessage(messageId)" class="btn">Responder Mensagem</button>
  </div>
</ng-container>
</ng-template>

<ng-template #dialogModalEdit let-editMessage>
<ng-container>
  <h1>Editar</h1>
  <div class="mt-32">
    <textarea-b-prop [placeholder]="'Edite aqui o conteúdo da mensagem'" [form]="formSend"></textarea-b-prop>
  </div>
  <div class="mt-32 flex flex-center">
    <button (click)="sandEditMessage(editMessage)" class="btn">Editar Mensagem</button>
  </div>
</ng-container>
</ng-template>

<ng-template #dialogModalEdit let-editResponseMessage>
  <ng-container>
    <h1>Editar</h1>
    <div class="mt-32">
      <textarea-b-prop [placeholder]="'Edite aqui o conteúdo da mensagem'" [form]="formSend"></textarea-b-prop>
    </div>
    <div class="mt-32 flex flex-center">
      <button (click)="sandEditMessage(editMessage)" class="btn">Editar Mensagem</button>
    </div>
  </ng-container>
</ng-template>