import { Injectable } from '@angular/core';
import { BaseAuthService } from '../base-auth.service';
import { HttpAuthService } from '../http-auth.service';

export interface LoginUserApiHash {
  tokenType: number | null
  accessToken: string | null
  refreshToken: string | null
  multipleTenant: boolean | null
  multipleCompanyUnities: boolean | null
  id: string
  errors: Array<string>
  success: boolean
  sumRecords: number
  totalPagins: number
  httpStatusCode: number
}

@Injectable({
  providedIn: 'root'
})

export class PublicApiHashService extends BaseAuthService {
  constructor(HttpAuth: HttpAuthService) {
    super(HttpAuth, 'v1/public/apihash');
  }

  override postToPath(body: Object, path: string) {
    return super.postToPath(body, path) as Promise<LoginUserApiHash>;
  }
}
