import { DefaultFormats } from "@app/pipe/localeDate.pipe";

export const resolveOccurrencies = [
   {
      label: "occurrencies.observation",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "description",
      type: "name"
   },
   {
      label: "occurrencies.type",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "typeOccurrencyName",
      type: "name"
   },
   {
      label: "occurrencies.date",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "dateOccurrency",
      type: "date",
      dateFormat: DefaultFormats.full
   }
];

export const resolveResumeExams = [
   {
      label: "requisition.resolveResumeExams.code",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "code",
      type: "name"
   },
   {
      label: "requisition.resolveResumeExams.name",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "name",
      type: "name"
   },
   {
      label: "requisition.resolveResumeExams.description",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "description",
      type: "description"
   },
   {
      label: "requisition.resolveResumeExams.routine",
      retrive: "",
      after: "",
      method: "formatField",
      before: "",
      fieldName: "routine",
      type: "routine"
   },
   {
      label: "requisition.resolveResumeExams.analyteDeadline",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "analyteDeadline",
      type: "date",
      dateFormat: DefaultFormats.full
   },
   {
      label: "requisition.resolveResumeExams.situation",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "situation",
      type: "examSituation"
   }
];

export const resolveRequisitions = [
   {
      label: "id",
      retrive: "id",
      method: "",
      after: "",
      before: ""
   },
   {
      label: "_hide_customerId",
      retrive: "customerId",
      method: "",
      after: "",
      before: ""
   },
   {
      label: "requisition.number",
      retrive: "number",
      method: "",
      after: "",
      before: ""
   },
   {
      label: "requisition.name",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "name",
      type: "description"
   },
   {
      label: "requisition.companyName",
      retrive: "companyName",
      method: "",
      after: "",
      before: ""
   },
   {
      label: "_hide_UnityId",
      retrive: "unityId",
      method: "",
      after: "",
      before: ""
   },
   {
      label: "requisition.unityDescription",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "unityDescription",
      type: "description"
   },
   {
      label: "requisition.collectionPlaceDescription",
      retrive: "",
      method: "",
      after: "",
      before: "",
      fieldName: "collectionPlaceDescription",
      type: "description"
   },
   {
      label: "requisition.createdAt",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "createdAt",
      type: "date",
      dateFormat: DefaultFormats.full
   },
   {
      label: "requisition.finished",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "finished",
      type: "finished"
   },
   {
      label: "Divergências",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "finished",
      type: "occurrency"
   },
   {
      label: "requisition.situation",
      retrive: "",
      method: "formatField",
      after: "",
      before: "",
      fieldName: "situation",
      type: "situation_enum"
   },
   {
      label: "_hide_tooltip",
      method: "getOccurrencyTooltip",
      retrive: "",
      after: "",
      before: ""
   }
];

export interface Actions {
   action: string;
   id: string;
   context: string | null;
   object: any;
}
