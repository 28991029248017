import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "validPhoneToTable"
})
export class ValidPhoneToTableFilterPipe implements PipeTransform {
  transform(value: string): string {
    let validValue = value;

    validValue = validValue.replace(/[^0-9]/g, '');

    if(validValue.length >= 8) {
      //99999999
      //8 caracteres

      //999999999
      //9 caracteres

      //4399999999
      //10 caracteres

      //43999999999
      //11 caracteres

      //554399999999
      //12 caracteres

      //5543999999999
      //13 caracteres

      validValue = validValue.length > 13 ? validValue.substring(validValue.length - 13) : validValue;//13 || 12 || 11 || 10 || 9 || 8

      validValue = validValue.length < 10 ? `00${validValue}` : validValue;//13 || 12 || 11 || 10

      validValue = validValue.length > 11 ? validValue.slice(2,13) : validValue; //11 || 10

      let phone = {
        //99999999999 || 9999999999
        ddd: validValue.slice(0,2), //99
        first: validValue.length/2 === 0 ? validValue.slice(2,6) : validValue.slice(2,7), // 99999 || 9999
        second: validValue.substring(validValue.length - 4) // 9999
      }

      //(99) 99999||9999 - 9999
      validValue = `(${phone.ddd}) ${phone.first}-${phone.second}`;

      return validValue;
    } else {
      return '';
    }

  }
}
