<div [class]="classe">
  <mat-form-field class="w-100">
      <mat-select class="mat-select"
              [formControl]="form" [disabled]="tabindexcomponent === '-1' || forcingDisable"
              (ngModelChane)="form" placeholder="Selecione" multiple>
              <input-prop [form]="formSearch.search" noLabelUp="true" placeholder="Digite aqui para pesquisar"></input-prop>
          <mat-select-trigger>
              <div class="flex gap8">
                  <div class="user" *ngFor="let user of form.value">
                      <div class="flex align-center gap8" >
                          <div class="user">
                              <div class="userImgDiv"  *ngIf="checkUserImg(user)">
                                  <img [src]="'data:image/jpg;base64,'+ checkUser(user).userImg" class="userIMG" />
                              </div>
                              <div class="userImgDivNoting" *ngIf="!checkUserImg(user)">
                                  {{checkUser(user)?.label.charAt(0)}}
                              </div>
                          </div>
                          {{checkUser(user)?.label}}
                      </div>
                  </div>
              </div>
          </mat-select-trigger>
          <mat-option *ngFor="let option of options | searchFilter: formSearch.search.value" [value]="option.value" (onSelectionChange)="onSelectionChange($event)">
              <div class="flex align-center gap8">
                  <div class="user" *ngIf="option.hasOwnProperty('userImg')">
                      <div class="userImgDiv" *ngIf="option.userImg">
                          <img [src]="'data:image/jpg;base64,'+option.userImg" class="userIMG" />
                      </div>
                      <div class="userImgDivNoting" *ngIf="!option.userImg">
                          {{option.label.charAt(0)}}
                      </div>
                  </div>
                  {{option.label}}
              </div>
          </mat-option>
      </mat-select>
  </mat-form-field>
</div>
