<div [class]="classe">
    <div class="flex align-center">
        <label *ngIf="label">
            {{label}}
        </label>
        <div class="sd">
            <input [type]="type"  (keyup.enter)="AddItem()" [placeholder]="placeholder" [(ngModel)]="String" [required]=requiredInput [mask]="mask" [tabindex]="tabindexcomponent">
            <input type="hidden" value="" [formControl]="form" (change)="onChange(form.value)">
            <label for="">{{placeholder}}</label>
            <span></span>
        </div>
        <button class="btn btn-reverse" (click)="AddItem()" type="button">Adicionar</button>
    </div> 
    <ul class="itens flex flex-wrap" *ngIf="form.value">
        <ng-container *ngFor="let item of form.value.split(','); let i = index">
            <li (click)="RemItem(item)">{{item}}</li> 
       </ng-container>
    </ul>
</div>