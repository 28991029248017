import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'cadastros-clientes-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  length: Array<any> = [];

  //#region Dados DashBoard

    //#region Clientes Atendidos
      customersServed: number = 10; // clientes atendidos pelo usuário no dia
      totalCustomersServed: number = 100; // total de clientes atendidos no dia
      customerProgressBar: string = `calc(${this.customersServed / this.totalCustomersServed} * calc(252px * var(--proportional)))`;
    //#endregion

    //#region Exames Cadastrados
      registeredExams: number = 30; // exames cadastrados pelo usuário no dia
      totalRegisteredExams: number = 100; // total de exames cadastrados no dia
      examsProgressBar: string = `calc(${this.registeredExams / this.totalRegisteredExams} * calc(252px * var(--proportional)))`;
    //#endregion

    //#region Valores Recebidos Hoje
      amountReceived: number = 50; // valores recebidos pelo usuário no dia (total do preço dos exames das requisições * quantidade de requisições cadastradas pelo usuário(Person))
      totalAmountReceived: number = 100; // total de valores recebidos no dia (total do preço dos exames das requisições * quantidade de requisições cadastradas no dia para a unidade(Unity))
      amountProgressBar: string = `calc(${this.amountReceived / this.totalAmountReceived} * calc(252px * var(--proportional)))`;
      formattedAmountReceived : string = `R$ ${this.amountReceived.toFixed(2)}`
    //#endregion

    //#region Devedores Atendidos
      // devedores atendidos pelo usuário no dia (total do preço dos exames das requisições de convênios particulares com saldo devedor * quantidade de requisições cadastradas pelo usuário(Person))
      debtorsServed: number = 70;
      // total devedores atendidos no dia (total do preço dos exames das requisições de convênios particulares com saldo devedor * quantidade de requisições cadastradas no dia para a unidade(Unity))
      totalDebtorsServed: number = 100;
      debtorsProgressBar: string = `calc(${this.debtorsServed / this.totalDebtorsServed} * calc(252px * var(--proportional)))`;
      formattedDebtorsServed : string = `R$ ${this.debtorsServed.toFixed(2)}`
    //#endregion

  //#endregion

  columns: Array<any> = [
    {
      titulo: 'Clientes Atendidos',
      view: new UntypedFormControl(null),
      type: 1,
      latestDay: new Date().toLocaleDateString(),
      latestTime: new Date().toLocaleTimeString(),
      value: this.customersServed
    },
    {
      titulo: 'Exames Cadastrados',
      view: new UntypedFormControl(null),
      type: 2,
      latestDay: new Date().toLocaleDateString(),
      latestTime: new Date().toLocaleTimeString(),
      value: this.registeredExams
    },
    {
      titulo: 'Valores Recebidos Hoje',
      view: new UntypedFormControl(null),
      type: 3,
      value: this.formattedAmountReceived
    },
    {
      titulo: 'Devedores Atendidos',
      view: new UntypedFormControl(null),
      type: 4,
      value: this.formattedDebtorsServed
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
