import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { HttpResponsePageable } from '../services.types';
import { Guid } from '@app/util/guid';
import { AgreementExamsSupportLaboratoryGetAll, AgreementExamsSupportLaboratoryGetById } from './agreement-exams-support-laboratory.types';
import { QueryParams } from '../interfaces/services.types';

@Injectable({
  providedIn: 'root'
})
export class AgreementExamsSupportLaboratoryService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/laboratory/attendance/agreementexamssupportlaboratory');
  } 

  override getById(id: Guid) {
    return super.getById(id) as Promise<HttpResponsePageable<AgreementExamsSupportLaboratoryGetById>>
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<AgreementExamsSupportLaboratoryGetAll>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<any>>
  }
  
}
