import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {
  @Input() titulo: string | undefined;
  //@ts-ignore
  @Input() contador: number;
  //@ts-ignore
  @Input() labelRevisao: string;
  //@ts-ignore
  @Input() revisao: string;
  //@ts-ignore
  @Input() cor: string;
  //@ts-ignore
  @Input() imprecisao: number;
  //@ts-ignore
  @Input() usuFoto: string;
  //@ts-ignore
  @Input() headerOrange = false;
  //@ts-ignore
  @Input() hideLines = false;
  //@ts-ignore
  @Input() isTitulo = false;
  //@ts-ignore
  @Input() modulo: string;
  //@ts-ignore
  @Input() header: string;
  //@ts-ignore
  @Input() linha0: string;
  //@ts-ignore
  @Input() linha1: string;
  //@ts-ignore
  @Input() linha2: string;
  //@ts-ignore
  @Input() linha5: string;
  //@ts-ignore
  @Input() linha6: string;
  //@ts-ignore
  @Input() linha2col1: string;
  //@ts-ignore
  @Input() linha3: string;
  //@ts-ignore
  @Input() linha3col2: string;
  //@ts-ignore
  @Input() linha3col2Class: string;
  //@ts-ignore
  @Input() linha4: string;
  //@ts-ignore
  @Input() linha4col1: string;
  //@ts-ignore
  @Input() linha4col2: string;
  //@ts-ignore
  @Input() cardStatus: string;
  //@ts-ignore
  @Input() definirTamanho: string;

  @Input() regrasVioladas: Array<string> = [];

  @Input() columnValue: Number = 0;

  datediff : number = 0;
  hour: String = '';
  
  constructor(private _router:Router, private _sanitizer:DomSanitizer) { }

  ngOnInit() {
    this.Datediff();
  }

  checkDate() {
  const date = new Date(this.linha4col2+'Z').toLocaleTimeString();
  this.hour = 'Horário - ' + date;

  /*const DateCard = new Date(this.linha4col2+'Z');
    var DateCardOnlyDay = new Date(DateCard.getFullYear()+'-'+(DateCard.getMonth()+1)+'-'+DateCard.getDate()).getTime();

    const today = new Date();
    var dateOnlyDay = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()).getTime();

  if(DateCardOnlyDay > dateOnlyDay) {
      return 'active';
    } else if(DateCardOnlyDay == dateOnlyDay) {
      return 'warning';
    } else {
      return 'caution';
    }*/
  }

  Datediff() {
    const DateCard = new Date(this.linha4col2);
    var DateCardOnlyDay = new Date(DateCard.getFullYear()+'-'+(DateCard.getMonth()+1)+'-'+DateCard.getDate());

    const today = new Date();
    var dateOnlyDay = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate());
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    //@ts-ignore
    return this.leftPad(Math.round((DateCardOnlyDay-dateOnlyDay)/(1000*60*60*24)), 2);
  }
  
  //@ts-ignore
  leftPad(value, totalWidth) {
    var length = totalWidth - value.toString().length + 1;
    return Array(length).join('0') + value;
  };

  result(id:string,level:string){
    this._router.navigateByUrl('/ControleInterno/RealizarAnalise/'+id+'$'+level)
  }
}
