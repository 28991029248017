import { ActionDefition } from "./../table.model";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgForOf, NgIf } from "@angular/common";
import { OutsideDirective } from "@app/outside.directive";
import { TranslateModule } from "@ngx-translate/core";
import { SvgSelectorUIComponent } from "../../../ui/svg-selector/svg-selector.component";
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

/**
 * Componente responsável por exibir todos os Actions (Ações para linha)
 */
@Component({
    selector: "table-actions",
    templateUrl: "./actions.component.html",
    styleUrls: ["./actions.component.scss"],
    standalone: true,
    imports: [
        NgForOf,
        NgIf,
        OutsideDirective,
        SvgSelectorUIComponent,
        TranslateModule,
        MatMenuModule,
        MatButtonModule
    ]
})
export class TableActionsComponent {
    /**
     * Ações que serão exibidas em linha
     */
    @Input() actions: ActionDefition[] = [];
    /**
     * Evento disparado por clique em Ação
     */
    @Output() clicked = new EventEmitter<string>();

    select(event: string) {
        this.clicked.emit(event);
    }
}
