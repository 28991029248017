import {
   Component,
   OnInit,
   Output,
   EventEmitter,
   HostListener,
   ViewChild,
   TemplateRef,
   ElementRef,
   ViewChildren,
   QueryList
} from "@angular/core";
import { UserUtil } from "../../../util/user";
import { DomSanitizer } from "@angular/platform-browser";
import decodedToken, { decodedSessionToken } from "@app/util/Token";
import { UntypedFormControl, Validators } from "@angular/forms";
import { RefreshService } from "@app/services/user/refresh.service";
import { UnityService } from "@app/services/shared/unity.service";
import { UserService } from "@app/services/user/user.service";
import {
   objectAlterTenant,
   objectUnity,
   resume,
   unities
} from "./header.model";
import { CompanyService } from "@app/services/company/company.service";
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { Notifications } from "@app/store/notification/notification.model";
import { NotificationService } from "@app/services/auth/profile-notification/notification.service";
import { PersonNotificationService } from "@app/services/auth/profile-notification/person-notification.service";
import { Store } from "@ngrx/store";
import { selectNotifications } from "@app/store/notification/notification.selectors";
import { SwAlSetttings } from "@app/util/swal.settings";
import { InputSelectFormComponent } from "@app/modules_new/shared/components/form/input-select/base/input-select.component";

@Component({
   selector: "app-header",
   templateUrl: "./header.component.html",
   styleUrls: ["./header.component.scss"],
   providers: [NotificationService]
})
export class HeaderComponent implements OnInit {
   @ViewChild("userName") userName!: ElementRef;
   @ViewChild("menuIcon") menuIcon!: ElementRef;
   @ViewChild("userImgDivNoting") userImgDivNoting!: ElementRef;
   @ViewChild("userImgDiv") userImgDiv!: ElementRef;

   @ViewChild("notificationModal") notificationModal!: ElementRef;

   menuopen: boolean = false;
   menuuseropen: boolean = false;
   inTransition: boolean = false;
   triggerEvent: boolean = false;
   modalOpen: boolean = false;
   showMenu: boolean = false;
   firstTimeModal: boolean = false;
   read: boolean = false;
   messages!: Array<any>;
   notifications!: Notifications;
   userId!: string;
   countNotifications: number = 0;
   unityName: string = "";
   companyName: string = "";
   accessCode: string = "";
   companyOptions: Array<object> = [];
   unityOptions: Array<object> = [];
   showNotification: boolean = true;

   tenantsOfUser: Array<object> = JSON.parse(
      //@ts-ignore
      localStorage.getItem("tenantsOfUser")
   );

   @Output() changeMenuOpen: EventEmitter<any> = new EventEmitter();
   @Output() changeMenuUserOpen: EventEmitter<any> = new EventEmitter();

   @ViewChild("dialogModal")
   dialogModal!: TemplateRef<any>;

   @HostListener("document:keydown", ["$event"])
   handleKeyboardEvent(event: KeyboardEvent): void {
      if ((event.key === "m" || event.key === "M") && event.ctrlKey)
         this.OpenMenu();
   }

   @ViewChildren(InputSelectFormComponent)
   InputSelectFormComponent!: QueryList<InputSelectFormComponent>;

   form = {
      read: new UntypedFormControl(false, [Validators.required]),
      chooseTenant: new UntypedFormControl(null, [Validators.required]),
      chooseGroup: new UntypedFormControl(null, [Validators.required]),
      chooseUnity: new UntypedFormControl(null, [Validators.required])
   };

   constructor(
      public _UserUtil: UserUtil,
      private _sanitizer: DomSanitizer,
      private _dialog: MatDialog,
      private _refreshService: RefreshService,
      private _unityService: UnityService,
      private _userService: UserService,
      private _companyService: CompanyService,
      private _location: Location,
      private _store: Store,
      private _personNotificationService: PersonNotificationService,
      private _notificationService: NotificationService
   ) {
      this._store.select(selectNotifications).subscribe((data) => {
         this.notifications = data;
         this.countNotifications = data.amount;
      });
   }

   async ngOnInit() {
      this.showMenu = false;
      await this.userImage();
      let token = decodedSessionToken;
      this.showMenu = true;

      if (token) {
         this.showNotification = false;
      }

      this.actualUnity();
      this.actualTenant();
      if (this.showNotification) {
         this.getNotifications(false);
         this._notificationService.connect(this.userId);
      }
   }

   imagePath: any = "";

   async userImage() {
      await this._UserUtil
         .getImage()
         .then((x) => {
            if (x.image != null) {
               this.imagePath = x.image;
            }
            this.accessCode = x.accessCode;
         })
         .catch((err) => SwAlSetttings.printError(err));
   }

   closeMenu(event: any) {
      this.OpenMenu();
   }

   actualTenant() {
      if (this.tenantsOfUser) {
         const companyData = localStorage.getItem("company");
         if (companyData) {
            const parsedData = JSON.parse(companyData);
            this.companyName = parsedData.name;
         }

         // let tenant: resume = this.tenantsOfUser.filter(
         //    (x: any) => x.value === localStorage.getItem("TenantId")
         // )[0] as resume;
         // this.companyName = tenant?.label;
      } else {
         if (decodedToken) {
            if (decodedToken.cid) {
               this._companyService
                  .getById(decodedToken.cid)
                  .then((res: any) => {
                     let response = res.data[0];

                     this.companyName = response.companyName;
                  });
            } else {
               const company = localStorage.getItem("company");
               if (company) {
                  this.companyName = JSON.parse(company).name;
               }
            }
         }
      }
   }

   actualUnity() {
      if (decodedToken) {
         if (decodedToken.uid) {
            this._unityService.getById(decodedToken.uid).then((x: any) => {
               this.unityName = x.data[0].name;
            });
         } else {
            const unity = localStorage.getItem("unity");
            if (unity) {
               this.unityName = JSON.parse(unity).label;
            }
         }
      }
   }

   OpenMenu(): void {
      if (!this.inTransition)
         if (this.menuuseropen) {
            this.inTransition = true;
            this.changeMenuOpen.emit({ changeMenuOpen: this.menuopen });
            this.menuuseropen = false;
            setTimeout(() => {
               this.menuopen = true;
               this.inTransition = false;
               this.triggerEvent = this.triggerEvent ? false : true;
            }, 300);
         } else {
            this.menuopen = this.menuopen ? false : true;
         }
   }

   OpenUserMenu(): void {
      if (!this.inTransition)
         if (this.menuopen) {
            this.menuopen = false;
            this.changeMenuOpen.emit({ changeMenuOpen: this.menuopen });
            this.inTransition = true;
            setTimeout(() => {
               this.menuuseropen = true;
               this.triggerEvent = this.triggerEvent ? false : true;
               this.inTransition = false;
            }, 300);
         } else {
            if (this.menuuseropen) {
               this.inTransition = true;
               setTimeout(() => {
                  this.triggerEvent = this.triggerEvent ? false : true;
                  this.inTransition = false;
               }, 300);
            } else {
               this.triggerEvent = this.triggerEvent ? false : true;
            }
            this.menuuseropen = this.menuuseropen ? false : true;
         }
   }

   parentFunc($event: any) {
      if ($event.close) {
         this.OpenUserMenu();
      }
   }

   openDialogModal(): void {
      if (this.form.chooseGroup.value) {
         this.getCompany();
      }

      if (!this.firstTimeModal) {
         this.firstTimeModal = true;

         const myTempDialog = this._dialog.open(this.dialogModal, {
            autoFocus: this.tenantsOfUser.length > 0 ? false : true,
            width: "calc(660px * var(--proportional))"
         });
         myTempDialog.afterClosed().subscribe((res) => {
            this.firstTimeModal = false;
            this.form.chooseTenant.setValue(null);
            this.form.chooseGroup.setValue(null);
            this.form.chooseUnity.setValue(null);
            this.unityOptions = [];
            this.companyOptions = [];
         });
      }

      if (this.tenantsOfUser.length === 1) {
         this.tenantsOfUser.forEach((tenant: any) =>
            setTimeout(() => this.form.chooseGroup.setValue(tenant.value))
         );
         setTimeout(() => {
            this.getCompany();
            this.InputSelectFormComponent.toArray()[1].focusInput();
         });
      }
   }

   closeModal() {
      this._dialog.closeAll();
   }

   async alterTenant() {
      localStorage.removeItem("TenantId");
      localStorage.setItem("TenantId", this.form.chooseTenant.value);

      const obj: objectAlterTenant = {
         refreshToken: localStorage.getItem("refreshToken")!,
         tenantId: this.form.chooseGroup.value,
         companyId: this.form.chooseTenant.value,
         unityId: this.form.chooseUnity.value
      };

      await this._refreshService.alterTenant(obj).then((res: any) => {
         localStorage.removeItem("token");
         localStorage.setItem("token", res.accessToken);
         localStorage.removeItem("refreshToken");
         localStorage.setItem("refreshToken", res.refreshToken);
         window.location.href = "/";

         localStorage.setItem("TenantId", decodedToken.tid.replace(";", ""));
         const unityInfos = this.unityOptions.filter(
            (x: any) => x.value === this.form.chooseUnity.value
         )[0];
         localStorage.setItem("unity", JSON.stringify(unityInfos));
      });

      this.closeModal();
   }

   async getCompany() {
      this.companyOptions = [];
      if (this.form.chooseGroup.value) {
         await this._userService
            .getCompanies(this.accessCode, this.form.chooseGroup.value)
            .then((res: any) => {
               if (res.data.length > 0) {
                  res.data.map((item: unities) => {
                     this.companyOptions.push({
                        value: item.id,
                        label: item.companyName,
                        unities: item.unities
                     });
                  });
               }
            });
      }
      if (this.companyOptions.length === 1) {
         this.companyOptions.forEach((company: any) =>
            setTimeout(() => this.form.chooseTenant.setValue(company.value))
         );
         setTimeout(() => {
            this.filterUnitys();
            this.InputSelectFormComponent.toArray()[2].focusInput();
         });
      }
   }

   filterUnitys() {
      this.unityOptions = [];

      if (this.form.chooseTenant.value) {
         let companys: unities = this.companyOptions.filter(
            (x: any) => x.value === this.form.chooseTenant.value
         )[0] as unities;
         if (companys.unities.length > 0) {
            companys.unities.map((res: objectUnity) => {
               this.unityOptions.push({ value: res.id, label: res.name });
            });
         }
      }
      if (this.unityOptions.length === 1) {
         this.unityOptions.forEach((unity: any) =>
            setTimeout(() => this.form.chooseUnity.setValue(unity.value))
         );
      }
   }

   forceCloseMenu() {
      if (this.menuopen) {
         this.OpenMenu();
      }
   }

   forceCloseUserMenu() {
      if (this.menuuseropen) {
         this.OpenUserMenu();
      }
   }

   hoverEffect(isHovered: boolean) {
      if (isHovered) {
         this.menuIcon.nativeElement.style.fill = "#FF6344";
         this.userName.nativeElement.style.color = "#FF6344";
         this.userName.nativeElement.style.fontWeight = "500";
         if (this.userImgDivNoting) {
            this.userImgDivNoting.nativeElement.style.border =
               "1px solid #FF6344";
            this.userImgDivNoting.nativeElement.style.color = "#FF6344";
         } else {
            this.userImgDiv.nativeElement.style.border = "1px solid #FF6344";
            this.userImgDiv.nativeElement.style.color = "#FF6344";
            this.userImgDiv.nativeElement.style.borderRadius = "50%";
         }
      } else {
         this.menuIcon.nativeElement.style.fill = "initial";
         this.userName.nativeElement.style.color = "initial";
         this.userName.nativeElement.style.fontWeight = "initial";
         if (this.userImgDivNoting) {
            this.userImgDivNoting.nativeElement.style.border = "initial";
            this.userImgDivNoting.nativeElement.style.color = "initial";
         } else {
            this.userImgDiv.nativeElement.style.border = "initial";
            this.userImgDiv.nativeElement.style.color = "initial";
         }
      }
   }

   getNotifications(read: boolean, count: boolean = false) {
      const query = `?read=${read}${count ? "&count=true" : ""}`;
      this._personNotificationService.getAlternative(query);
   }

   toggleNotificationsModal(event?: MouseEvent) {
      this.read = !this.read;
      this.modalOpen = !this.modalOpen;
      event?.stopPropagation();
      this.form.read.reset();
      this.getNotifications(false);
   }

   @HostListener("document:click", ["$event"])
   onClick(event: MouseEvent) {
      const targetElement = event.target as HTMLElement;
      const menuDiv = document.getElementById("notificationModal");
      if (this.modalOpen && !menuDiv?.contains(targetElement)) {
         this.toggleNotificationsModal();
         this.modalOpen = false;
      }
   }

   Read() {
      if (this.form.read.value !== this.read) {
         const notificationValue = this.form.read.value ? false : true;
         this.getNotifications(notificationValue);
         this.read = this.form.read.value;
      }
   }
}
