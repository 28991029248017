import { Injectable } from '@angular/core';
import { QueryParams } from '../interfaces/services.types';
import { HttpResponsePageable } from '../services.types';
import { BaseAuthService } from '../auth/base-auth.service';
import { HttpAuthService } from '../auth/http-auth.service';

@Injectable({
  providedIn: 'root'
})
export class EnumAuthService extends BaseAuthService{

constructor(HttpService: HttpAuthService) {
  super(HttpService, 'v1/enum');
 }

 override getPagination<T>(queryObject?: QueryParams | undefined){
  return super.getPagination(queryObject) as Promise<HttpResponsePageable<T>> 
}

}
