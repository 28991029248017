import {
   Directive,
   ElementRef,
   EventEmitter,
   HostListener,
   Output
} from "@angular/core";
import decodedToken, { decodedSessionToken } from "@app/util/Token";

@Directive({
   selector: "[appCloseUserMenu]"
})
export class CloseUserMenuDirective {
   @Output() clickOutside = new EventEmitter<MouseEvent>();

   constructor(private elementRef: ElementRef) {}

   @HostListener("document:click", ["$event"])
   onClick(event: any) {
      const clickedInside = this.elementRef.nativeElement.contains(
         event.target as Node
      );
      let name = "";
      if (decodedToken) {
         name = decodedToken.name;
      } else {
         name = decodedSessionToken.name;
      }

      if (
         !clickedInside &&
         event.target.outerHTML.search(name) == -1 &&
         event.target.outerHTML.search("svg-open-menu-user") == -1 &&
         event.target.outerHTML.search("userImgDivNoting") == -1 &&
         event.target.outerHTML.search(
            'd="M1.05956 17.0706C0.675564 16.6763 0.683977 16.0453 1.07835 15.6613L7.91963 9.00001L1.07836 2.33877C0.683978 1.95477 0.675565 1.32377 1.05956 0.929395C1.44356 0.535018 2.07456 0.526605 2.46894 0.910604L10.777 9.00001L2.46894 17.0894C2.07456 17.4734 1.44356 17.465 1.05956 17.0706Z"'
         ) == -1 &&
         event.target.outerHTML.search('<span class="mat-option-text">') ==
            -1 &&
         event.srcElement.currentSrc?.search("flag") != -1
      ) {
         this.clickOutside.emit(event);
      }
   }
}
