import {
   Component,
   EventEmitter,
   Input,
   OnDestroy,
   OnInit,
   Output,
   ViewChildren,
   inject
} from "@angular/core";
import {
   CreateButtonInput,
   FilterInput,
   formFilter
} from "./content-filter.model";
import { CommonModule, NgIf, Location } from "@angular/common";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { DataComponent } from "@app/modules_new/common/components/data";
import { InputFilterFormComponent } from "../../components/form/input-filter/input-filter.component";
import { InputFilterEvent } from "../../components/form/input-filter/input-filter.model";
import { FilterFormComponent } from "../../components/form/filter/filter.component";
import { CloseFilterDirective } from "@app/directives/close-filter/close-filter.directive";
import { ChangeComponent } from "../../components/form/change/change.component";
import { TableRegistryService } from "@app/tableRegistry.service";

@Component({
   selector: "content-filter-layout",
   templateUrl: "./content-filter.component.html",
   styleUrls: ["./content-filter.component.scss"],
   standalone: true,
   imports: [
      NgIf,
      RouterLink,
      InputFilterFormComponent,
      CommonModule,
      FilterFormComponent,
      CloseFilterDirective,
      ChangeComponent
   ]
})
export class ContentFilterLayoutComponent
   extends DataComponent
   implements OnInit, OnDestroy
{
   @Output() clearFilters: EventEmitter<any> = new EventEmitter<any>();
   @Output() searchFilters: EventEmitter<any> = new EventEmitter<any>();
   @Output() clearFiltersPartial: EventEmitter<any> = new EventEmitter<any>();
   @Output() changeFont: EventEmitter<any> = new EventEmitter<any>();
   @ViewChildren(FilterFormComponent) filterForm!: FilterFormComponent;
   isResize: boolean = false;
   isResizeButton: boolean = false;

   private _location = inject(Location);
   private _activatedRoute = inject(ActivatedRoute);
   private _router = inject(Router);
   private _tableRegistryService = inject(TableRegistryService);
   isAdm: boolean = this._router.url.includes("/Admin") ? true : false;

   @Input() filtersRight: boolean = false;
   @Input() hideButton: boolean = false;
   @Input() marginBottom = null;
   @Input() removePadding = false;
   @Input() initFilterFromURLQuery = false;
   @Input() title = "";
   @Input() orangeTitle = "";
   @Input() createButton!: CreateButtonInput;
   @Input() createSecondButton!: CreateButtonInput;
   @Input() filterPlaceholder!: FilterInput;
   @Input() reverseButton = false;
   @Input() h2 = false;
   @Input() formFilter?: formFilter;
   @Input() formFilterHeader?: any;
   @Input() backPath = "";
   @Input() showFilter: boolean = true;
   @Input() showBackButton: boolean = false;
   @Input() changeSizeFont: boolean = false;

   @Output() select1 = new EventEmitter<any>();
   @Output() select2 = new EventEmitter<any>();
   @Output() changelabelForm = new EventEmitter<string>();
   @Output() searchText = new EventEmitter<any>();

   @Output() clickButton = new EventEmitter<any>();
   @Output() clickSecondButton = new EventEmitter<any>();
   @Output() filter = new EventEmitter<InputFilterEvent>();

   public initialFilterValue = window.location?.search.replace("?q=", "");

   ngOnInit(): void {
      if (this.initFilterFromURLQuery) {
         this.initialFilterValue = window.location?.search.replace("?q=", "");
         this.addSubscriptions([
            this._activatedRoute.queryParams.subscribe((response) => {
               const queryFilter = response["q"];
               if (queryFilter) {
                  this.initialFilterValue = queryFilter;
               }
            })
         ]);
      } else {
         this.initialFilterValue = "";
      }
   }

   @Input() timerHideHeader: boolean = false;

   ngAfterViewInit() {
      if (this.timerHideHeader) this.iniciarControleDeBotaoInativo();
   }

   iniciarControleDeBotaoInativo() {
      let timeoutId: ReturnType<typeof setTimeout> | null = null;
      const header = document.getElementById("header");

      if (!header) {
         console.warn(`Div com id header não encontrado.`);
         return;
      }

      function esconderBotao(botao: HTMLElement) {
         botao.classList.add("oculto");
         setTimeout(() => {
            botao.style.display = "none";
         }, 100);
      }

      function mostrarBotao(botao: HTMLElement) {
         botao.style.display = "block";
         botao.classList.remove("oculto");

         if (timeoutId) {
            clearTimeout(timeoutId);
         }

         timeoutId = setTimeout(() => {
            esconderBotao(botao);
         }, 2500);
      }

      ["mousemove", "click", "scroll", "keydown", "touchstart"].forEach(
         (evento) => {
            window.addEventListener(evento, () => mostrarBotao(header));
         }
      );
      mostrarBotao(header);
   }

   changeSelect1() {
      this.select1.emit();
   }
   changeSelect2() {
      this.select2.emit();
   }

   sendChangelabelForm(event: any) {
      if (event) {
         this.changelabelForm.emit(event);
      }
   }

   change(event: any) {
      this.searchText.emit(event);
   }

   ngOnDestroy(): void {
      this.unsubscribeAll();
   }

   setFont(bigger: boolean) {
      this.changeFont.emit(bigger);
   }

   onFilter(event: InputFilterEvent) {
      this.filter.emit(event);
   }

   onButtonClicked() {
      this.clickButton.emit({});
   }
   onSecondButtonClicked() {
      this.clickSecondButton.emit({});
   }

   resetFiltersPartial() {
      this.clearFiltersPartial.emit();
   }

   resetFilter() {
      this.clearFilters.emit();
   }

   search() {
      const table = this._tableRegistryService.getTable();
      table?.setLoader();
      this.searchFilters.emit();
   }

   closeFilter() {
      //@ts-ignore
      this.filterForm.first.closeFilter();
   }

   returnPage() {
      this._router.navigateByUrl(this.backPath);
   }

   back() {
      this._location.back();
   }
}
