import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { Guid } from '@app/util/guid';
import { BudgetGetAll, BudgetGetById, BudgetPost, BudgetPostObject, BudgetPut } from './budget.types';

@Injectable({
  providedIn: 'root'
})

export class BudgetService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/laboratory/attendance/budget');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<BudgetGetAll>>
  }

  override getByPath(queryString: string, path: string, headers?: any[]) {
    return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<BudgetGetById>>
  }

  override getById(id: Guid) {
    return super.getById(id) as Promise<BudgetGetById>
  }

  override post(body: BudgetPostObject) {
    return super.post(body) as Promise<HttpResponsePageable<BudgetPost>>
  }

  override put(body: object, id?: Guid | undefined, path?: string | undefined) {
    return super.put(body, id, path) as Promise<HttpResponsePageable<BudgetPut>>    
  }
}

  
