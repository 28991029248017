<div class="contentPage">
  <div class="flex flex-between headerResponsive">
    <div class="flex align-center gap-2 headerResponsive">
      <h1>Vacina</h1>
      <app-button (click)="openDialogModal()" [placeholder]="'Novo'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus [form]="filter.Search" (keyup.enter)="get()" [placeholder]="'Pesquisar'"></input-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" label="Somente Ativos" [form]="filter.isActive"></checkbox-prop>
      </div>
      <app-button (click)="get()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [ClickInItem]="ActionsExtra" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="itens" [Actions]="['Editar', 'Excluir']" (parentFun)="actions($event)"></app-table>
  </div>
</div>

<ng-template #dialogModal>
  <div class="flex flex-between w-100 align-center">
    <h2>{{editId ? 'Editar' : 'Nova'}} Vacina</h2>
    <svg width="21" (click)="closeDialog()" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
    </svg>
  </div>
  <div class="mt-32 flex gap-form">
    <div class="w-100">
      <input-search [form]="form.acronym" placeholder="Sigla" [options]="sipni"></input-search>
    </div>
    <div class="w-100">
      <input-search [form]="form.product" placeholder="Produto" [options]="products"></input-search>
    </div>
  </div>
  <div class="mt-32 w-100">
    <textarea-prop [form]="form.adverseEvent" placeholder="Evento Adverso"></textarea-prop>
  </div>
  <div class="mt-32 w-100">
    <textarea-prop [form]="form.orientation" placeholder="Orientação"></textarea-prop>
  </div>
  <div class="mt-32 w-100">
    <input-multiple [form]="form.administration" placeholder="Administração" [options]="administration"></input-multiple>
  </div>
  <div class="mt-32 w-100">
    <input-multiple [form]="form.application" placeholder="Aplicação" [options]="application"></input-multiple>
  </div>
  <div class="mt-32 w-100">
    <input-multiple [form]="form.schemma" placeholder="Esquema" [options]="schemmas"></input-multiple>
  </div>
  <div class="flex flex-center mt-32 w-100">
    <app-button (click)="editId? put() : post()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
  </div>
</ng-template>
