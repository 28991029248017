import { inject, Injectable } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import {
   QueryParams,
   RequestOptions
} from "@app/modules_new/data/types/http.types";
import { CompanyService } from "@app/modules_new/administrativeData/data/services/company/company.service";
import { CompaniesResponse } from "@app/modules_new/administrativeData/data/services/company/company.types";
import { Guid } from "@app/util/guid";
import { companyObj } from "../../pages/company/company-modal/company-modal";
import { MatDialog } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";
import decodedToken, { decodedSessionToken } from "@app/util/Token";
import { UsersPublicService } from "@app/modules_new/admin/data/services/auth-users.service";

@Injectable({
   providedIn: "root"
})
export class CompanyFacade extends MultiObservableFacade {
   private _companyService = inject(CompanyService);
   private _userService = inject(UsersPublicService);

   private _companys =
      this.createNullableObservableControl<CompaniesResponse[]>();
   private _companysAmount = this.createObservableControl<number>(0);

   private _materialPage = this.createObservableControl<number>(1);
   private _materialNumberRegistry = this.createObservableControl<number>(30);

   private _dialog = inject(MatDialog);

   get(query: QueryParams = {}) {
      if (query["page"] && query["numberRegistry"]) {
         this._materialPage.subject.next(query["page"] as number);
         this._materialNumberRegistry.subject.next(
            query["numberRegistry"] as number
         );
      }

      query["page"] = this._materialPage.subject.value;
      query["numberRegistry"] = this._materialNumberRegistry.subject.value;

      this._companyService
         .get<CompaniesResponse>({
            query
         })
         .subscribe({
            next: (response: any) => {
               this._companys.subject.next(response.data);
               this._companysAmount.subject.next(response.sumRecords);
            },
            error: (error) => {
               this._companys.subject.next([]);
               this._companysAmount.subject.next(0);
               this.ToastErrorGeneric(error);
            }
         });
      return {
         data$: this._companys.observable$,
         total$: this._companysAmount.observable$
      };
   }

   async getCompanyOptions(
      isAddAll: boolean = false,
      requestOptions: object = {}
   ) {
      let request: RequestOptions = {};
      if (Object.keys(requestOptions).length > 0) {
         request = requestOptions;
      } else {
         request = {
            path: "/companies",
            query: {
               accessCode: decodedToken
                  ? decodedToken.email
                  : decodedSessionToken.email,
               isActive: true
            }
         };
      }
      const companies: Array<object> = [];
      if (isAddAll)
         companies.push({
            value: null,
            label: "Todos",
            unities: [
               {
                  value: null,
                  label: "Todos"
               }
            ]
         });
      try {
         const res: any = await lastValueFrom(this._userService.get(request));
         if (res.data.length > 0) {
            res.data.forEach((res: any) => {
               const company = {
                  value: res.id,
                  label: res.companyName,
                  unities: res.unities.map((units: any) => ({
                     value: units.id,
                     label: units.name
                  }))
               };

               companies.push(company);
            });

            return companies;
         } else {
            return [];
         }
      } catch (err) {
         console.error(err);
         return [];
      }
   }

   post(obj: companyObj) {
      this._companyService.post(obj).subscribe({
         next: () => {
            this.get();
            this.ToastSuccess("Empresas Cadastrada!");
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }

   put(obj: companyObj, id: string) {
      this._companyService.put(obj, new Guid(id)).subscribe({
         next: () => {
            this.get();
            this.ToastSuccess("Empresa atualizada com sucesso!");
            this._dialog.closeAll();
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }

   async patchActive(id: string, value: string) {
      let success = false;
      try {
         const response: any = await lastValueFrom(
            this._companyService.patchToggleIsActive(id, !value)
         );
         if (response.success) {
            this.ToastSuccess(
               !value === true
                  ? "toast-msg.change-to-active.success"
                  : "toast-msg.change-to-inactive.success"
            );
            success = response.success;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      //@ts-ignore
      let data = this._companys.subject._value;
      let index = data.findIndex((x: any) => x.id == id);

      if (success) {
         data[index].isActive = !value;
      } else {
         data[index].isActive = value;
      }
      this._companys.subject.next(data);
      return success;
   }
}
