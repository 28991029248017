import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { Guid } from '@app/util/guid';
import { HttpResponsePageable } from '../services.types';
import { BudgetPrintGetById } from './budget-print.types';

@Injectable({
  providedIn: 'root'
})

export class BudgetPrintService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/laboratory/attendance/budget/print');
  }

  override getById(id: Guid) {
    return super.getById(id) as Promise<HttpResponsePageable<BudgetPrintGetById>>
  }
}

  
