import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { RegistrationReportComponent } from '@app/components/form/registration-report/registration-report.component';
import { ResolveDoctors } from '@app/modules/registers/components/doctors/doctors.model';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { TenantAuthService } from '@app/services/auth/tenantAuth/tenant-auth.service';
import { DoctorsService } from '@app/services/doctors/doctors.service';
import { GetAllDoctors } from '@app/services/doctors/doctors.types';
import { SwAlSetttings } from '@app/util/swal.settings';
import { HeaderMapping, ReportFilter } from '@app/modules/reports/reports-model';


@Component({
  selector: 'app-doctor-report',
  templateUrl: './doctor-report.component.html',
  styleUrls: ['./doctor-report.component.scss']
})
export class DoctorReportComponent implements OnInit {

  page: number = 1;
  index: number = 50;
  total: number = 0;

  sumRecords!: number;
  receivedDataForPrint!: object[];

  headerMapping: HeaderMapping = {
    name: { title: 'Nome', width: 'auto' },
    council: { title: 'Conselho', width: '*' },
    officePhone: { title: 'Telefone', width: 'auto' },
    email: { title: 'E-mail', width: 'auto' },
    createdAt: { title: 'Criado em', width: 'auto' },
    updatedAt: { title: 'Atualizado em', width: 'auto' },
    isActive: { title: 'Ativo', width: 'auto' }
  };

  isActiveOptions: Array<{ label: string, value: string | null | boolean }> = [{ label: 'Todos', value: 'todos' }, { label: 'Somente Ativos', value: true }, { label: 'Somente Inativos', value: false }]

  reportDescription: string = 'Relatório de Cadastro de Solicitantes';
  reportFilters: ReportFilter[][] = [];

  reportObject: Array<Object> = [];

  form = {
    filter: new UntypedFormControl(null, [Validators.required]),
    search: new UntypedFormControl(null, [Validators.required]),
    isActive: new UntypedFormControl('todos'),
  };

  filterOptions: Array<Object> = [
    {
      label: "Filtrar por Número do Registro",
      value: "Filtrar por Registro"
    },
    {
      label: "Filtrar por Nome",
      value: "Filtrar por Nome"
    },
    {
      label: "Filtrar por Telefone",
      value: "Filtrar por Telefone"
    },
    {
      label: "Filtrar por E-mail",
      value: "Filtrar por Email"
    }
  ];

  registerDoctors: Array<ResolveDoctors> = [];

  resolveDoctors: Array<Object> = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Nome',
      retrive: 'name',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Conselho',
      retrive: '',
      method: 'getCrm',
      after: '',
      before: ''
    },
    {
      label: 'Telefone',
      retrive: '',
      method: 'officePhone',
      after: '',
      before: ''
    },
    {
      label: 'E-mail',
      retrive: 'email',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ];

  constructor(
    private _doctorsService: DoctorsService,
    private _createdAt: LocalaDatePipe,
    private _updatedAt: LocalaDatePipe,
    private _localeDate: LocalaDatePipe,
    private _http: HttpClient,
    private _tenantAuth: TenantAuthService
  ) { }

  ngOnInit() {
    this.getDoctors();
    this.form.filter.setValue("Filtrar por Nome")
  }

  getCrm(item: GetAllDoctors) {
    return item.council + ' - ' + item.registrationNumber + '/' + item.registrationState;
  }

  officePhone(item: GetAllDoctors) {
    return item.officePhone ? item.officePhone : '()';
  }

  getCreated(item: GetAllDoctors) {
    return this._createdAt.transform(item.createdAt);
  }

  getUpdated(item: GetAllDoctors) {
    return this._updatedAt.transform(item.updatedAt);
  }

  getActive(item: GetAllDoctors) {
    return item.isActive ? "<span class='span-styles' style='background: #07BF56; color: #FFFFFF;'>Sim</span>" : "<span class='span-styles' style='background: #FF0000; color: #FFFFFF;'>Não</span>";
  }


  async getDoctors(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let filters: { page: number, index: number, isActive: boolean, RegistrationNumber: string, Name: string, OfficePhone: string, Email: string } = { page: this.page, index: this.index, isActive: this.form.isActive.value, RegistrationNumber: '', Name: '', OfficePhone: '', Email: '' }
    switch (this.form.filter.value) {
      case 'Filtrar por Registro':
        filters.RegistrationNumber = this.form.search.value.replaceAll('-', '');
        break;
      case 'Filtrar por Nome':
        filters.Name = this.form.search.value;
        break;
      case 'Filtrar por Telefone':
        filters.OfficePhone = this.form.search.value;
        break;
      case 'Filtrar por Email':
        filters.Email = this.form.search.value;
        break;
    }

    filters.isActive = this.form.isActive.value === 'todos' ? null : JSON.parse(this.form.isActive.value);
    filters.index = this.index;
    filters.page = this.page;

    await this._doctorsService.getPagination(filters).then(
      (res: any) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolve(res.data, this.resolveDoctors);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.registerDoctors = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.registerDoctors.push(obj);
    });
  }

  async getDataForPrint() {
    this.reportObject = []

    let filters: { isActive: boolean, RegistrationNumber: string, Name: string, OfficePhone: string, Email: string } = { isActive: this.form.isActive.value, RegistrationNumber: '', Name: '', OfficePhone: '', Email: '' }
    switch (this.form.filter.value) {
      case 'Filtrar por Registro':
        filters.RegistrationNumber = this.form.search.value.replaceAll('-', '');
        break;
      case 'Filtrar por Nome':
        filters.Name = this.form.search.value;
        break;
      case 'Filtrar por Telefone':
        filters.OfficePhone = this.form.search.value;
        break;
      case 'Filtrar por Email':
        filters.Email = this.form.search.value;
        break;
    }

    filters.isActive = this.form.isActive.value === 'todos' ? null : JSON.parse(this.form.isActive.value);

    await this._doctorsService.getPagination<GetAllDoctors>(filters).then(
      (res) => {
        res.data.forEach((x) => {
          const obj = {
            name: x.name,
            council: x.council + ' - ' + x.registrationNumber + '/' + x.registrationState,
            officePhone: x.officePhone ? x.officePhone : '()',
            email: x.email,
            createdAt: x.createdAt,
            updatedAt: x.updatedAt,
            isActive: x.isActive ? 'Ativo' : 'Inativo',
          }
          this.reportObject.push(obj)
        })
        this.sumRecords = res.sumRecords;
        this.receivedDataForPrint = this.reportObject;
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    )
  }

  async generatePdf() {
    await this.getDataForPrint();
    this.reportFilters = [];
    var filters = [];
    var filterActive = 'Todos';
    if (this.form.isActive.value != 'todos') {
      if (this.form.isActive.value == 'true') {
        filterActive = 'Somente Ativos';
      } else {
        filterActive = 'Somente Inativos';
      }
    }

    var obj = {
      title: 'Ativos: ', content: filterActive
    }

    filters.push(obj);

    if (this.form.search.value) {
      var typeSearch = '';
      switch (this.form.filter.value) {
        case "Filtrar por Número do Registro":
          typeSearch = 'Número do Registro: ';
          break;
        case "Filtrar por Nome":
          typeSearch = 'Nome: ';
          break;
        case "Filtrar por Telefone":
          typeSearch = 'Telefone: ';
          break;
        case "Filtrar por E-mail":
          typeSearch = 'E-mail: ';
          break;
      }

      obj = {
        title: typeSearch, content: this.form.search.value
      }
      filters.push(obj)
    }

    this.reportFilters = [filters];

    const reportComponent = new RegistrationReportComponent(this._http, this._localeDate, this._tenantAuth);
    reportComponent.headerMapping = this.headerMapping;
    reportComponent.reportFilters = this.reportFilters;
    reportComponent.receivedData = this.receivedDataForPrint;
    reportComponent.reportDescription = this.reportDescription;
    reportComponent.sumRecords = this.sumRecords;

    await reportComponent.ngOnInit();
  }

  resetFilter() {
    this.form.filter.setValue("Filtrar por Nome");
    this.form.search.reset();
    this.form.isActive.setValue('todos');
  }
}