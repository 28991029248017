<div [class]="classe">
    <div class="flex align-center">
        <label *ngIf="label">
            {{label}}
        </label>
        <div class="sd" [ngClass]="dark ? 'dark' : ''">
            <textarea [dropSpecialCharacters]="false" [placeholder]="placeholder | translate" [tabindex]="tabindexcomponent"
                   [readOnly]="readonly" [required]=requiredInput [formControl]="form" (change)="onChange(form.value)"
                   [mask]="mask" [maxlength]="maxlenght"></textarea>
            <label for="">{{placeholder | translate}}</label>
            <span></span>
        </div>
    </div>
</div>
