import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { TypeIncomeExpenseService } from '@app/services/type-income-expense/type-income-expense.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Actions, PaginationInfo, ResolvedTypeIncomeExpense, TypeIncomeExpense, incomeExpenseTypesOptions, spanOptions } from './type-income-expense.model';
import { QueryParams } from '@app/services/interfaces/services.types';

@Component({
  selector: 'app-type-income-expense-list',
  templateUrl: './type-income-expense-list.component.html',
  styleUrls: ['./type-income-expense-list.component.scss']
})
export class TypeIncomeExpenseListComponent implements OnInit {

  resolvedTypesIncomeExpense: Array<ResolvedTypeIncomeExpense> = []
  @ViewChild('dialogModal') dialogModal!: TemplateRef<any>

  paginationInfo: PaginationInfo = {
    page: 1,
    sumRecords: 0,
    index: 50,
  }

  formGroupIncomeExpense = new UntypedFormGroup({
    externalId: new UntypedFormControl(),
    description: new UntypedFormControl(),
    type: new UntypedFormControl(),
    typeIncomeExpenseId: new UntypedFormControl()
  })

  formGroupSearchFilter = new UntypedFormGroup({
    description: new UntypedFormControl(),
    IsActive: new UntypedFormControl()
  })
  
  incomeExpenseTypesOptions = incomeExpenseTypesOptions

  constructor(
    private _dialog: MatDialog,
    private _typeIncomeExpenseService: TypeIncomeExpenseService,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _localeDatePipe: LocalaDatePipe,
  ) { }

  ngOnInit(): void {
    this.getTypesIncomeExpenses()
  }

  async createTypeIncomeExpense() {
    const typeIncomeExpenseObject = {
      description: this.formGroupIncomeExpense.controls['description'].value,
      externalId: this.formGroupIncomeExpense.controls['externalId'].value,
      type: this.formGroupIncomeExpense.controls['type'].value
    }
    await this._typeIncomeExpenseService.post(typeIncomeExpenseObject).then(async (response) => {
      if (response.success) {
        await this.getTypesIncomeExpenses()
        SwAlSetttings.Sucesso("Tipo de Receita/Despesa criado com sucesso!")
        this._dialog.closeAll()
      }
    })
  }

  async updateTypeIncomeExpense() {
    const id = new Guid(this.formGroupIncomeExpense.controls['typeIncomeExpenseId'].value)
    const typeIncomeExpenseObject = {
      description: this.formGroupIncomeExpense.controls['description'].value,
      externalId: this.formGroupIncomeExpense.controls['externalId'].value,
      type: this.formGroupIncomeExpense.controls['type'].value
    } 
    await this._typeIncomeExpenseService.put(typeIncomeExpenseObject, id).then(async (response) => {
      if (response.success) {
        await this.getTypesIncomeExpenses()
        SwAlSetttings.Sucesso("Tipo de Receita/Despesa editado com sucesso!")
        this._dialog.closeAll()
      }
    })
  }

  ActionsExtra = {
    Ativo: 'toggleActive',
  };

  async getTypesIncomeExpenses(params?: number[]) {

    if (params) {
      this.paginationInfo.page = params[0]
      this.paginationInfo.index = params[1]
    }

    const queryObject: QueryParams = {
      search: this.formGroupSearchFilter.controls["description"].value,
      IsActive: this.formGroupSearchFilter.controls["IsActive"].value,
      numberRegistry: this.paginationInfo.index,
      page: this.paginationInfo.page
    }

    await this._typeIncomeExpenseService.getPagination<TypeIncomeExpense>(queryObject).then(response => {
      this.resolvedTypesIncomeExpense = response.data.map((typeIncomeExpense) => (
        {
          id: typeIncomeExpense.id,
          "Código": typeIncomeExpense.externalId ?? "",
          "Descrição": typeIncomeExpense.description,
          "Tipo": spanOptions[typeIncomeExpense.type],
          "Criado em": this._localeDatePipe.transform(typeIncomeExpense.createdAt) ?? '',
          "Alterado em": this._localeDatePipe.transform(typeIncomeExpense.updatedAt) ?? '',
          "Ativo": typeIncomeExpense.isActive ? 'checkbox|true' : 'checkbox|',
          "_hide_type": typeIncomeExpense.type
        }
      ))
      this.paginationInfo.sumRecords = response.sumRecords
    })
  }

  typeIncomeExpenseActions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.openDialogModalEdit(emit.object)
        break;
      case 'Excluir':
        this.deleteTypeIncomeExpense(emit.object.id)
        break;
      case 'toggleActive':
        this.patchActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object["Ativo"]))
        break;
    }
  }

  async patchActive(id: string, value: string) {
    this._typeIncomeExpenseService.patchActive(id, value).then(async (response) => {
      if (response.success) {
        await this.getTypesIncomeExpenses()
        SwAlSetttings.Sucesso(`Tipo de Receita/Despesa ${value === 'active' ? 'inativada' : 'ativada'} com sucesso!`)
      }
    })
  }

  async deleteTypeIncomeExpense(id: string) {
    this._typeIncomeExpenseService.delete(new Guid(id)).then(async (response) => {
      if (response.success) {
        await this.getTypesIncomeExpenses()
        SwAlSetttings.Sucesso('Tipo de Receita/Despesa excluída com sucesso!')
      } 
    })
  }

  openDialogModalEdit(object: ResolvedTypeIncomeExpense) {
    this.formGroupIncomeExpense.controls['typeIncomeExpenseId'].setValue(object.id),
    this.formGroupIncomeExpense.controls['description'].setValue(object["Descrição"]),
    this.formGroupIncomeExpense.controls['externalId'].setValue(object["Código"]),
    this.formGroupIncomeExpense.controls['type'].setValue(object["_hide_type"])
    this.openDialogModal()
  }

  openDialogModal() {
    this._dialog.open(this.dialogModal, {
      width: '660px'
    }).afterClosed()
      .subscribe(() => this.formGroupIncomeExpense.reset())
  }

  closeModal() {
    this._dialog.closeAll()
  }

  resetFilter(){
    this.formGroupSearchFilter.controls['description'].reset();
    this.formGroupSearchFilter.controls['IsActive'].reset();
  }
}

