import { Pipe, PipeTransform } from "@angular/core";
import { validate as isValidUUID } from "uuid";
import { OptionSelect } from "../components/form/input-select/input-select.types";

const filterValue = (args: any, item: OptionSelect) => {
   return JSON.stringify(item.value)
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(args);
};

const filterLabel = (args: any, item: OptionSelect) => {
   return JSON.stringify(item.label)
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(args);
};

@Pipe({
   name: "searchFilter",
   standalone: true
})
export class SearchFilterPipe implements PipeTransform {
   transform(value: any, args?: any): any {
      if (!value) return "";
      if (!args) return value;

      args = args
         .toString()
         .toLowerCase()
         .normalize("NFD")
         .replace(/[\u0300-\u036f]/g, "");

      const filter = isValidUUID(args) ? filterValue : filterLabel;

      return value.filter((item: any) => filter(args, item));
   }
}
