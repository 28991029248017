import { Component, inject } from "@angular/core";
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UsersFacade } from "@app/modules_new/admin/data/facades/users-admin.facade";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ModalLayoutComponent } from "@app/modules_new/shared/components/form/modal/modal.component";
import { ToggleFormComponent } from "@app/modules_new/shared/components/form/toggle/toggle.component";

@Component({
   selector: "user-password-modal",
   templateUrl: "./user-password-modal.component.html",
   standalone: true,
   imports: [
      ModalLayoutComponent,
      InputTextFormComponent,
      ReactiveFormsModule,
      ButtonFormComponent,
      ToggleFormComponent
   ]
})
export class UserPasswordModalComponent {
   public _usrObj: any = inject(MAT_DIALOG_DATA);

   private _usersFacade = inject(UsersFacade);
   public form = new FormGroup({
      accessCode: new FormControl<string | null>(this._usrObj.accessCode, [
         Validators.required
      ]),
      previousPassword: new FormControl<string | null>(null, [
         Validators.required
      ]),
      confirmNewPassword: new FormControl<string | null>(null, [
         Validators.required
      ]),
      newPassword: new FormControl<string | null>(null, [Validators.required]),
      isActive: new FormControl<boolean | null>(false, [Validators.required]),
      isVerified: new FormControl<boolean | null>(false, [Validators.required])
   });

   resetPassword() {
      const object = {
         accessCode: this.form.controls.accessCode.value,
         newPassword: this.form.controls.newPassword.value,
         confirmPassword: this.form.controls.confirmNewPassword.value,
         isActive: this.form.controls.isActive.value,
         isVerified: this.form.controls.isVerified.value
      };
      this._usersFacade.resetPassword(object, this._usrObj.id);
   }
}
