import { DefaultFormats } from "@app/pipe/localeDate.pipe";

export const resolveCollectionInstruction: Array<Object> = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: "collectionGuidance.description",
      method: 'formatField',
      retrive: '',
      after: '',
      before: '',
      fieldName: 'description',
      type: 'description',
    },
    {

      label: 'collectionGuidance.createdAt',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'createdAt',
      type: 'date',
      dateFormat: DefaultFormats.full,
      sort: 'createdAt'
    },
    {
      label: 'collectionGuidance.updatedAt',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'updatedAt',
      type: 'date',
      dateFormat: DefaultFormats.full,
      sort: 'updatedAt'
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'formatField',
      after: '',
      before: '',
      fieldName: 'isActive',
      type: 'checkbox',
      sort: 'isActive'
    },
  ];
export interface Actions {
  action: string,
  id: string,
  context: string | null,
  object: any
}