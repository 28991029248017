import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { TypeDocumentService } from '@app/services/type-document/type-document.service';
import { Guid } from '@app/util/guid';
import { SwAlSetttings } from '@app/util/swal.settings';
import { Actions, PaginationInfo, ResolvedTypeDocument, TypeDocument, documentTypeOptions, spanOptions } from './type-document.model';
import { QueryParams } from '@app/services/interfaces/services.types';

@Component({
  selector: 'app-type-document-list',
  templateUrl: './type-document-list.component.html',
  styleUrls: ['./type-document-list.component.scss']
})
export class TypeDocumentListComponent implements OnInit {

  documentTypeOptions = documentTypeOptions

  resolvedDocumentTypes: Array<ResolvedTypeDocument> = []
  @ViewChild('dialogModal') dialogModal!: TemplateRef<any>

  paginationInfo: PaginationInfo = {
    page: 1,
    sumRecords: 0,
    index: 50,
  }

  formGroupDocument = new UntypedFormGroup({
    externalId: new UntypedFormControl(),
    description: new UntypedFormControl(),
    type: new UntypedFormControl(),
    documentId: new UntypedFormControl()
  })

  formGroupSearchFilter = new UntypedFormGroup({
    search: new UntypedFormControl(),
    isActive: new UntypedFormControl()
  })

  constructor(
    private _dialog: MatDialog,
    private _typeDocumentService: TypeDocumentService,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _localeDatePipe: LocalaDatePipe,
  ) { }

  ngOnInit(): void {
    this.getDocuments()
  }

  async createDocumentType() {
    const documentObject = {
      description: this.formGroupDocument.controls['description'].value,
      externalId: this.formGroupDocument.controls['externalId'].value,
      type: this.formGroupDocument.controls['type'].value
    }
    await this._typeDocumentService.post(documentObject).then(async (response) => {
      if (response.success) {
        await this.getDocuments()
        SwAlSetttings.Sucesso("Novo Tipo de Documento criado com sucesso!")
        this._dialog.closeAll()
      }
    })
  }

  async updateDocumentType() {
    const id = new Guid(this.formGroupDocument.controls['documentId'].value)
    const documentObject = {
      description: this.formGroupDocument.controls['description'].value,
      externalId: this.formGroupDocument.controls['externalId'].value,
      type: this.formGroupDocument.controls['type'].value,
    }

    await this._typeDocumentService.put(documentObject, id).then(async (response) => {
      if (response.success) {
        await this.getDocuments()
        SwAlSetttings.Sucesso("Tipo de documento atualizado com sucesso!")
        this._dialog.closeAll()
      }
    })
  }

  ActionsExtra = {
    Ativo: 'toggleActive',
  };

  async getDocuments(params?: number[]) {
    if (params) {
      this.paginationInfo.page = params[0]
      this.paginationInfo.index = params[1]
    }

    const queryObject: QueryParams = {
      search: this.formGroupSearchFilter.controls["search"].value,
      isActive: this.formGroupSearchFilter.controls["isActive"].value ? this.formGroupSearchFilter.controls["isActive"].value : null,
      numberRegistry: this.paginationInfo.index,
      page: this.paginationInfo.page
    }

    await this._typeDocumentService.getPagination<TypeDocument>(queryObject).then(response => {
      this.resolvedDocumentTypes = response.data.map((typeDocument) => (
        {
          id: typeDocument.id,
          "Código": typeDocument.externalId,
          "Descrição": typeDocument.description,
          "Tipo": spanOptions[typeDocument.type],
          "Criado em": this._localeDatePipe.transform(typeDocument.createdAt) ?? '',
          "Alterado em": this._localeDatePipe.transform(typeDocument.updatedAt) ?? '',
          "Ativo": typeDocument.isActive ? 'checkbox|true' : 'checkbox|',
          "_hide_type": typeDocument.type
        }
      ))
      this.paginationInfo.sumRecords = response.sumRecords
    })
  }

  async documentTypeActions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.openDialogModalEdit(emit.object)
        break;
      case 'Excluir':
        await this.deleteDocument(emit.object.id)
        break;
      case 'toggleActive':
        await this.patchActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object["Ativo"]))
        break;
    }
  }

  async patchActive(id: string, value: string) {
    await this._typeDocumentService.patchActive(id, value).then(async (response) => {
      if (response.success) {
        await this.getDocuments()
        SwAlSetttings.Sucesso(`Tipo de Documento ${value === 'active' ? 'inativado' : ' ativado'} com sucesso!`)
      }
    })
  }

  async deleteDocument(id: string) {
    await this._typeDocumentService.delete(new Guid(id)).then(async (response) => {
      if (response.success) {
        await this.getDocuments()
        SwAlSetttings.Sucesso('Tipo de Documento excluído com sucesso!')
      }
    })
  }

  openDialogModalEdit(object: ResolvedTypeDocument) {
    this.formGroupDocument.controls['documentId'].setValue(object.id),
      this.formGroupDocument.controls['description'].setValue(object["Descrição"]),
      this.formGroupDocument.controls['externalId'].setValue(object["Código"]),
      this.formGroupDocument.controls['type'].setValue(object["_hide_type"])
    this.openDialogModal()
  }

  openDialogModal() {
    this._dialog.open(this.dialogModal, {
      width: '660px'
    }).afterClosed()
      .subscribe(() => this.formGroupDocument.reset())
  }

  closeModal() {
    this._dialog.closeAll()
  }

  resetFilter(){
    this.formGroupSearchFilter.controls['search'].reset();
    this.formGroupSearchFilter.controls['isActive'].reset();
  }
}
