import { Injectable } from "@angular/core";
import decodedToken from "./Token";
import decodedTokenResult from "./TokenResult";
import { FileDocumentAuthService } from "../services/auth/fileDocument-auth.service";
import { AccessControlUserAuth } from "../services/auth/acesscontrol-user-auth.service";
import { ProfileUserAuth } from "../services/auth/profile-user-auth.service";
import { AuthLogoutService } from "../services/auth/auth-logout.service";
import { UsersPublicService } from "@app/modules_new/admin/data/services/auth-users.service";
import { RequestOptions } from "@app/modules_new/data/services/types/http.types";
import { lastValueFrom } from "rxjs";

@Injectable({
   providedIn: "root"
})
export class UserUtil {
   Users = [];
   accessCode: string = "";
   constructor(
      private _usersPublicService: UsersPublicService,
      private _userService: ProfileUserAuth,
      private _usersService: AccessControlUserAuth,
      private _fileDocument: FileDocumentAuthService,
      private _logoutService: AuthLogoutService
   ) {}

   getName() {
      if (localStorage.getItem("token")) {
         return decodedToken.name;
      } else {
         return decodedTokenResult.name;
      }
   }

   async getImage() {
      let obj = {
         accessCode: decodedToken.email,
         image: null
      };
      if (localStorage.getItem("token")) {
         const sd: any = await this._userService.getAll();
         const user = sd.data[0];
         this.accessCode = user.accessCode;
         obj = {
            accessCode: user.accessCode,
            image: null
         };
         var userImage = localStorage.getItem("userImage");
         //@ts-ignore
         let validImage = await this.checkLinkValidity(userImage);

         try {
            if (userImage != "undefined" && userImage && validImage) {
               //@ts-ignore
               obj.image = userImage;
               return obj;
            } else {
               const request: RequestOptions = {
                  path: "/" + user.id + "/image"
               };

               const image = await lastValueFrom(
                  this._usersPublicService.get(request)
               );
               //@ts-ignore
               if (image.data.length > 0) {
                  //@ts-ignore
                  localStorage.setItem("userImage", image.data[0].url);
                  //@ts-ignore
                  obj.image = image.data[0].url;
                  return obj;
               }
               return obj;
            }
         } catch (e) {
            return obj;
         }
      } else {
         return obj;
      }
   }

   getUserId() {
      //@ts-ignore
      return decodedToken.sub;
   }

   Sair() {
      if (localStorage.getItem("token")) {
         this._logoutService
            .postLogout(decodedToken.sub)
            .then((x: any) => {
               localStorage.removeItem("TenantId");
               localStorage.removeItem("user");
               localStorage.removeItem("userImage");
               localStorage.removeItem("token");
               localStorage.removeItem("refreshToken");
               localStorage.removeItem("admin");
               localStorage.removeItem("tenantsOfUser");
               localStorage.removeItem("lastRequisition");
               window.location.reload();
               window.location.href = "/";
            })
            .catch((x) => {});
      } else {
         sessionStorage.removeItem("token");
         sessionStorage.removeItem("refreshToken");
         sessionStorage.removeItem("accessType");
         sessionStorage.removeItem("userImage");
         sessionStorage.removeItem("TenantId");
         sessionStorage.removeItem("tenantsOfUser");
         localStorage.removeItem("lastRequisition");
         window.location.href = "resultados";
      }
   }

   ExpirouSessao() {
      localStorage.removeItem("TenantId");
      localStorage.removeItem("user");
      localStorage.removeItem("userImage");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("admin");
      localStorage.removeItem("lastRequisition");
      window.location.href = "/";
      window.location.reload();
   }

   async getUserbyFileDocumentId(fileImageId: string) {
      //@ts-ignore
      var image = await this._fileDocument.getById(fileImageId);
      //@ts-ignore
      if (image.data.length > 0) {
         //@ts-ignore
         return image.data[0].fileBase64;
      }
   }

   getUserImg(id: string, userObject: any) {
      let userFilter = userObject.filter((x: any) => x.personId == id);

      let data = "";

      if (userFilter.length > 0) {
         if (userFilter[0].hasOwnProperty("imageBase64")) {
            data = userFilter[0].imageBase64[0]
               ? '<div class="userImgDiv"><img src="data:image/jpg;base64,' +
                 userFilter[0].imageBase64[0] +
                 '" class="userIMG" /></div>'
               : '<div class="userImgDivNoting">' +
                 userFilter[0].name.charAt(0) +
                 "</div>";
         }
      }
      // @ts-ignore
      return data;
   }

   getUserNoImg(id: string, userObject: any) {
      let userFilter = userObject.filter((x: any) => x.personId == id);

      let data = "";

      if (userFilter.length > 0) {
         if (userFilter[0].hasOwnProperty("imageBase64")) {
            data =
               '<div class="userImgDivNoting">' +
               userFilter[0].name.charAt(0) +
               "</div>";
         }
      }
      // @ts-ignore
      return data;
   }

   getUserImgAndName(id: string, userObject: any) {
      let userFilter = userObject.filter((x: any) => x.personId == id);

      let data = "";

      if (userFilter.length > 0) {
         if (userFilter[0].hasOwnProperty("imageBase64")) {
            data = userFilter[0].imageBase64[0]
               ? '<div class="userImgDiv"><img src="data:image/jpg;base64,' +
                 userFilter[0].imageBase64[0] +
                 '" class="userIMG" /></div>'
               : '<div class="userImgDivNoting">' +
                 userFilter[0].name.charAt(0) +
                 "</div>";
         }
      }
      // @ts-ignore
      return (
         "<div class='flex gap-1 align-center'>" +
         data +
         userFilter[0].name +
         "</div>"
      );
   }

   getUserImgByFileImageId(
      fileImageId: string,
      userObject: any,
      personName: string
   ) {
      let userFilter = userObject.filter(
         (x: any) => x.fileImageId == fileImageId
      );

      let data = "";

      if (userFilter.length > 0) {
         if (userFilter[0].hasOwnProperty("imageBase64")) {
            data = userFilter[0].imageBase64[0]
               ? '<div class="userImgDiv"><img src="data:image/jpg;base64,' +
                 userFilter[0].imageBase64[0] +
                 '" class="userIMG" /></div>'
               : '<div class="userImgDivNoting">' +
                 personName.charAt(0) +
                 "</div>";
         }
      }
      // @ts-ignore
      return data;
   }

   async getUserImgUsers(id: string) {
      if (this.Users.length == 0) {
         const x: any = await this._usersService.getAll();
         this.Users = x.data;
      }

      let userFilter: any = this.Users.filter((x: any) => x.personId == id);
      let data = "";

      if (userFilter.length > 0) {
         if (userFilter[0].hasOwnProperty("imageBase64")) {
            data = userFilter[0].imageBase64[0]
               ? '<div class="userImgDiv"><img src="data:image/jpg;base64,' +
                 userFilter[0].imageBase64[0] +
                 '" class="userIMG" /></div>'
               : '<div class="userImgDivNoting">' +
                 userFilter[0].name.charAt(0) +
                 "</div>";
         }
      }

      return data;
   }

   async getUserImgUsersWithName(id: string) {
      if (this.Users.length == 0) {
         const x = await this._usersService.getAll();
         // @ts-ignore
         this.Users = x.data;
      }

      let userFilter = this.Users.filter((x: any) => x.personId == id);
      let data = [];

      if (userFilter.length > 0) {
         // @ts-ignore
         if (userFilter[0].hasOwnProperty("imageBase64")) {
            // @ts-ignore
            data[0] = userFilter[0].imageBase64[0];
            // @ts-ignore
            data[1] = userFilter[0].name;
         }
      }
      // @ts-ignore
      return data;
   }

   async getAccessCode() {
      let infos = await this._userService.getAll();
      if (this.accessCode) return this.accessCode;
      //@ts-ignore
      else return infos.data[0].accessCode;
   }

   checkLinkValidity(link: string): Promise<boolean> {
      return new Promise((resolve) => {
         const img = new Image();
         img.onload = () => resolve(true); // Se a imagem carregar, o link é válido
         img.onerror = () => resolve(false); // Se houver erro, o link é inválido
         img.src = link;
      });
   }
}
