import {
   ActionDefition,
   ColumnDefinition
} from "@app/modules_new/shared/components/form/table/table.model";

export const tableActions: ActionDefition[] = [
   {
      label: "default.delete",
      svg: "action-trash",
      event: "DELETE"
   }
];

export const tableColumns: ColumnDefinition[] = [
   {
      label: "Nome",
      key: "name"
   },
   {
      label: "Empresa",
      key: "companyName"
   },
   {
      label: "Unidade",
      key: "unityName"
   }
];
