import { Injectable, inject } from "@angular/core";
import { MultiObservableFacade } from "@app/modules_new/common/data/facades/multi-observable";
import { LoginService } from "./login.service";
import { lastValueFrom } from "rxjs";

@Injectable({
   providedIn: "root"
})
export class LoginFacade extends MultiObservableFacade {
   private _loginService = inject(LoginService);

   async post(obj: any) {
      let response = null;
      try {
         response = await lastValueFrom(this._loginService.post(obj));
      } catch (error) {
         this.ToastErrorGeneric(error);
         return error;
      }
      return response;
   }
}
