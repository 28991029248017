import { Component, inject, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FormGroup, FormControl } from "@angular/forms";
import { InputTextFormComponent } from "@app/modules_new/shared/components/form/input-text/input-text.component";
import { ButtonFormComponent } from "@app/modules_new/shared/components/form/button/button.component";
import { SwAlSetttings } from "@app/util/swal.settings";
import { ForgotPasswordFacade } from "./data/forgotPassword/forgotPassword.facade";
import { JwtHelperService } from "@auth0/angular-jwt";
import { InputSearchFormComponent } from "../shared/components/form/input-select/search/input-search.component";
const helper = new JwtHelperService();
@Component({
   selector: "forgotPassword",
   templateUrl: "./forgotPassword.component.html",
   styleUrls: ["./forgotPassword.component.scss"],
   standalone: true,
   imports: [
      TranslateModule,
      CommonModule,
      InputTextFormComponent,
      ButtonFormComponent,
      InputSearchFormComponent
   ]
})
export class ForgotPasswordComponent implements OnInit {
   form = new FormGroup({
      idEmailCpf: new FormControl<string | null>(null),
      token: new FormControl<string | null>(null),
      accessCode: new FormControl<string | null>(null),
      password: new FormControl<string | null>(null),
      confirmPassword: new FormControl<string | null>(null)
   });
   validation: boolean = false;
   forgotPassword: boolean = false;
   hasCode: boolean = false;
   private _forgotPasswordFacade = inject(ForgotPasswordFacade);

   ngOnInit(): void {}

   async PasswordForgot() {
      const form = {
         typeForgotPassword: 0,
         identification: this.form.controls.idEmailCpf.value,
         isChronos: true
      };

      const success = await this._forgotPasswordFacade.post(form);

      if (success) {
         SwAlSetttings.Sucesso("Código enviado por SMS!");
         this.hasCode = true;
      }
   }

   async validarToken() {
      let object = {
         typeForgotPassword: 1,
         token: this.form.controls.token.value,
         identification: this.form.controls.accessCode.value,
         newPassword: this.form.controls.password.value,
         confirmPassword: this.form.controls.confirmPassword.value
      };

      const success = await this._forgotPasswordFacade.post(object);

      if (success) {
         SwAlSetttings.Sucesso("Senha Alterada com Sucesso!");
         window.location.href = "";
      }
   }

   navigateToForgotPassword() {
      this.hasCode = !this.hasCode;
   }

   back() {
      window.location.href = "/";
   }
}
