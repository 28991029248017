import {
   Component,
   OnInit,
   Input,
   ViewChild,
   NgZone,
   ElementRef
} from "@angular/core";
import {
   UntypedFormControl,
   FormsModule,
   ReactiveFormsModule,
   Validators
} from "@angular/forms";
import { CdkTextareaAutosize, TextFieldModule } from "@angular/cdk/text-field";
import { take } from "rxjs/operators";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { CommonModule, NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ErrorTranslatePipe } from "@app/modules_new/shared/pipe/error-translate.pipe";

@Component({
   selector: "forms-textarea",
   templateUrl: "./textarea.component.html",
   styleUrls: ["./textarea.component.scss"],
   standalone: true,
   imports: [
      NgIf,
      MatLegacyFormFieldModule,
      MatLegacyInputModule,
      TextFieldModule,
      FormsModule,
      ReactiveFormsModule,
      TranslateModule,
      CommonModule,
      ErrorTranslatePipe
   ]
})
export class TextAreaFormComponent implements OnInit {
   @ViewChild("autosize") autosize!: CdkTextareaAutosize;

   @ViewChild("inputElement") inputElement!: ElementRef;

   @Input() readonly: boolean = false;
   @Input() form!: UntypedFormControl;
   @Input() placeholder!: string;
   @Input() label!: string;
   @Input() className!: string;
   @Input() requiredInput!: string;
   @Input() tabindexcomponent!: string;
   @Input() maxRows: number = 5;

   classe = "";

   constructor(private _ngZone: NgZone) {}

   onChange(value: string) {
      this.form.setValue(value);
   }

   ngOnInit(): void {
      this.classe = this.className + " input-row";

      if (this.form?.hasValidator(Validators.required)) {
         this.requiredInput = "true";
         this.form.markAsTouched({ onlySelf: true });
      }
   }

   triggerResize() {
      // Wait for changes to be applied, then trigger textarea resize.
      this._ngZone.onStable
         .pipe(take(1))
         .subscribe(() => this.autosize.resizeToFitContent(true));
   }

   showError(): boolean {
      if (this.form.touched && this.form?.hasValidator(Validators.required)) {
         return true;
      }

      return false;
   }

   focusInput() {
      this.inputElement.nativeElement.focus();
      setTimeout(() => {
         this.form.markAsTouched({ onlySelf: true });
      });
   }
}
