import { Injectable } from '@angular/core';
import { BaseAuthService } from './base-auth.service';
import { HttpAuthService } from './http-auth.service';

@Injectable({ 
  providedIn: 'root' 
})
export class DepartmentAuthService extends BaseAuthService {
  constructor(HttpAuthService: HttpAuthService) {
    super(HttpAuthService, 'v1/accesscontrol/department');
  }
}