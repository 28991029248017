import {
   AfterViewInit,
   Component,
   EventEmitter,
   inject,
   OnInit,
   Output,
   QueryList,
   ViewChildren
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ToggleFormComponent } from "../../toggle/toggle.component";
import { InputSelectFormComponent } from "../../input-select/base/input-select.component";
import { FormSelectPropComponent } from "../../select-prop/select-prop.component";
import { InputTextFormComponent } from "../../input-text/input-text.component";
import { CommonModule } from "@angular/common";
import { InputSearchFormComponent } from "../../input-select/search/input-search.component";
import { CustomersFacade } from "@app/modules_new/registers/data/facades/customers-facade";
import {
   QueryParams,
   RequestOptions
} from "@app/modules_new/data/services/types/http.types";
import { ToastService } from "@app/services/translate/toast.service";
import { AgreementsFacade } from "@app/modules_new/registers/data/facades/agreements-facade";
import { UsersAdminUsersFacade } from "@app/modules_new/admin/data/facades/users-admin-users.facade";
import { query } from "@angular/animations";

@Component({
   selector: "app-filter-modal",
   templateUrl: "./filter-modal.component.html",
   styleUrls: ["./filter-modal.component.css"],
   standalone: true,
   imports: [
      TranslateModule,
      MatIconModule,
      MatTooltipModule,
      InputTextFormComponent,
      FormSelectPropComponent,
      InputSelectFormComponent,
      InputSearchFormComponent,
      ToggleFormComponent,
      CommonModule
   ]
})
export class FilterModalComponent implements OnInit, AfterViewInit {
   constructor() {}

   @Output() clearInputs = new EventEmitter<void>();

   @Output() clearFilters: EventEmitter<any> = new EventEmitter<any>();
   @Output() searchFilters: EventEmitter<any> = new EventEmitter<any>();
   @Output() clearFiltersPartial: EventEmitter<any> = new EventEmitter<any>();
   @Output() changeOption: EventEmitter<any> = new EventEmitter<any>();
   @ViewChildren(InputTextFormComponent)
   InputTextFormComponent!: QueryList<InputTextFormComponent>;
   private _matDialog = inject(MatDialog);
   private _dataModal: any = inject(MAT_DIALOG_DATA);
   private _customersFacade = inject(CustomersFacade);
   private _usersFacade = inject(UsersAdminUsersFacade);
   private _toast = inject(ToastService);
   private _agreementFacade = inject(AgreementsFacade);
   formFilter!: any;
   mask: string = "";
   type: string = "text";

   ngOnInit() {
      this.formFilter = this._dataModal.formFilter;
      if (this.formFilter[0].options)
         this.searchMask(
            this.formFilter[0].options,
            this.formFilter[0].form.value
         );
   }

   ngAfterViewInit() {
      setTimeout(() => {
         const inputSelectArray: Array<InputTextFormComponent> =
            this.InputTextFormComponent.toArray();
         const codeInputSelect = inputSelectArray[0];
         codeInputSelect ? codeInputSelect.focusInput() : null;
      }, 200);
   }

   searchMask(input: any, form: any, placeholder?: string) {
      if (
         placeholder == "Empresa" &&
         this.formFilter[0].placeholder == "Empresa" &&
         this.formFilter[1].placeholder == "Filial"
      ) {
         let data = this._dataModal.formFilter;
         data[1].options = data[0].options
            .filter((x: any) => x.value == data[0].form.value)[0]
            .unities.map((res: any) => ({
               value: res.value,
               label: res.label
            }));
         this.formFilter = data;
      }
      input.forEach((input: any) => {
         if (input.value == form) {
            input.mask ? (this.mask = input.mask) : (this.mask = "");
            input.type ? (this.type = input.type) : (this.type = "text");
         }
      });
      this.resetFiltersPartial();
   }

   resetFiltersPartial() {
      this.clearFiltersPartial.emit();
   }

   clearFiltersEmit() {
      this.clearFilters.emit();
      this.mask = "";
      this.type = "text";
   }

   search() {
      this.searchFilters.emit();
      this.close();
   }

   async searchGET(search: any, type: any, index: any) {
      let data = this._dataModal.formFilter;
      switch (type) {
         case "roles.user":
            const queryObjectUser: RequestOptions = {
               query: {
                  resume: true,
                  Search: search
               }
            };

            data[index].options = await this._usersFacade.getUsersOptions(
               queryObjectUser
            );
            if (data[index].options.length == 1) {
               setTimeout(() => {
                  data[index].form.setValue(data[index].options[0].value);
               });
            }
            if (data[index].options.length == 0) {
               this._toast.warningMessage("Usuário não encontrado!");
            }
            break;
         case "material-shipment.client":
            const queryObject: QueryParams = {
               resume: true,
               name: search
            };

            data[index].options =
               await this._customersFacade.getCustomersOptions(queryObject);
            if (data[index].options.length == 1) {
               setTimeout(() => {
                  data[index].form.setValue(data[index].options[0].value);
               });
            }
            if (data[index].options.length == 0) {
               this._toast.warningMessage("customer.msgNoCustomers");
            }
            break;
         case "default.agreement":
            const queryObjectAgreement: QueryParams = {
               resume: true,
               search: search
            };

            data[index].options = await this._agreementFacade.getOptionsByQuery(
               queryObjectAgreement
            );

            if (data[index].options.length == 1) {
               setTimeout(() => {
                  data[index].form.setValue(data[index].options[0].value);
               });
            }
            if (data[index].options.length == 0) {
               this._toast.warningMessage(
                  "requisitionMedicalRequest.msgNotAgreement"
               );
            }
            break;
      }

      this.formFilter = data;
   }

   close() {
      this._matDialog.closeAll();
   }
}
