export type ResolvedEndpoints = {
    id: string
    "Tipo": string
    "URL de Redirecionamento": string
    "Data de Criação": string
    "Data de Alteração": string
    "Ativo": string
    "_hide_type": number
}


export enum ETypeEndPoint {
    "Orçamento" = 1,
    "Requisição" = 2,
    "Cliente" = 3,
    "Médico" = 4,
    "Convênio" = 5,
    "Exame" = 6
}

export type Actions = {
    action: string,
    id: string,
    context: string | null,
    object: ResolvedEndpoints
  }

export const urlTypeOptions = [
    {
        value: 1,
        label: 'Orçamento'
    },
    {
        value: 2,
        label: 'Requisição'
    },
    {
        value: 3,
        label: 'Cliente'
    },
    {
        value: 4,
        label: 'Médico'
    },
    {
        value: 5,
        label: 'Convênio'
    },
    {
        value: 6,
        label: 'Exame'
    },
]
