import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { TypePaymentsService } from '@app/services/payments/payments.service';
import { Guid } from '@app/util/guid';
import { Actions, ObjectActions } from './types-payment.model'
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { ToastService } from '@app/services/translate/toast.service';

@Component({
  selector: 'app-types-payment',
  templateUrl: './types-payment.component.html',
  styleUrls: ['./types-payment.component.scss']
})
export class TypesPaymentComponent implements OnInit {

  total: number = 0;
  page: number = 1;
  index: number = 50;

  paymentTypes: Array<object> = [];

  Search = new UntypedFormControl();
  actives = new UntypedFormControl();
  typeId: string = ''

  form = {
    description: new UntypedFormControl(),
    type: new UntypedFormControl(),
    external: new UntypedFormControl(),
    active: new UntypedFormControl(false)
  }

  @ViewChild('dialogModal')
  dialogModal!: TemplateRef<any>

  tiposPagamento = [
    {
      value: 0,
      label: 'Não Identificado'
    },
    {
      value: 1,
      label: 'Dinheiro'
    },
    {
      value: 2,
      label: 'Cheque'
    },
    {
      value: 3,
      label: 'Pix'
    },
    {
      value: 4,
      label: 'Cartão Crédito'
    },
    {
      value: 5,
      label: 'Cartão Débito'
    },
    {
      value: 6,
      label: 'Antecipação'
    }
  ]

  resolvePaymentTypes = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'typesPayment.externalId',
      retrive: 'externalId',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'typesPayment.description',
      retrive: 'description',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'typesPayment.typeDescription',
      retrive: 'typeDescription',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'typesPayment.createdAt',
      retrive: '',
      method: 'getCreated',
      after: '',
      before: ''
    },
    {
      label: 'typesPayment.updatedAt',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    },
    {
      label: '_hide_type',
      retrive: 'type',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_external',
      retrive: 'externalId',
      method: '',
      after: '',
      before: ''
    }
  ];

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  constructor(
    private _dialog: MatDialog,
    private _typePaymentsService: TypePaymentsService,
    private _createdAt: LocalaDatePipe,
    private _updatedAt: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _toastService: ToastService
  ) { }

  ngOnInit() {
    this.get();
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let search: string = this.Search.value ? this.Search.value : null;

    let isActive: string = this.actives.value ? this.actives.value : null;

    await this._typePaymentsService.getPagination({ page: this.page, index: this.index, search, isActive }).then(
      (res: any) => {
        this.paymentTypes = [];
        this.total = res.sumRecords;
        if (res.data.length != 0) {
          this.resolve(res.data, this.resolvePaymentTypes);
        }
      }
    ).catch(
      (err: any) => {
        this._toastService.fireError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.paymentTypes.push(obj);
    });
  }

  getCreated(item: ObjectActions) {
    return this._createdAt.transform(item.createdAt);
  }

  getUpdated(item: ObjectActions) {
    return this._updatedAt.transform(item.updatedAt);
  }

  getActive(item: ObjectActions) {
    return this._activePipe.transform(item.isActive)
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.typeId = emit.object.id;
        this.form.description.setValue(emit.object['typesPayment.description']);
        this.form.active.setValue(emit.object.Ativo.includes('active') ? true : false);
        this.form.type.setValue(parseFloat(emit.object._hide_type));
        this.form.external.setValue(emit.object._hide_external);
        this.openDialogModal(emit.object);
        break;
      case 'PutActive':
        this.putActive(emit.object.id.toString(), this._checkboxSvgPipe.transform(emit.object.Ativo));
    }
  }

  putActive(id: string, value: string) {
    this._typePaymentsService.patchActive(id, value).then((x: any) => {
      this._toastService.fireSuccess('global.msgSituationSuccess')
      this.get();
    }).catch(err => this._toastService.fireError(err))
  }

   openDialogModal(emit?: any) {
    const myTempDialog = this._dialog.open(this.dialogModal, {
      width: '700px',
      data: emit
    });
    myTempDialog.afterClosed().subscribe(() => {
      this.typeId = '';
      this.form.active.setValue(false);
      this.form.description.setValue('');
      this.form.type.setValue('');
      this.form.external.setValue('');
    });
  }

  closeModal() {
    this._dialog.closeAll();
  }

  postPaymentType() {
    const obj = {
      description: this.form.description.value,
      type: this.form.type.value,
      externalId: this.form.external.value
    }

    this._typePaymentsService.post(obj).then((x: any) => {
      this._toastService.fireSuccess('typesPayment.msgPost');
      this.get();
      this.closeModal();
    }).catch(
      (err) => {
        this._toastService.fireError(err)
      }
    );
  }

  putPaymentType() {
    const obj = {
      description: this.form.description.value,
      type: this.form.type.value,
      externalId: this.form.external.value,
      isActive: this.form.active.value
    }

    this._typePaymentsService.put(obj, new Guid(this.typeId)).then((x: any) => {
      this._toastService.fireSuccess('typesPayment.msgPut');
      this.get();
      this.closeModal();
    }).catch(
      (err) => {
        this._toastService.fireError(err)
      }
    );
  }

  resetFilter() {
    this.Search.reset();
    this.actives.reset();
  }

}
