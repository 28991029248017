<div class="contentPage">
  <div class="flex gap-2 align-center">
    <app-button (click)="back()" [placeholder]="'Voltar'" [class]="'btn-reverse'"></app-button>
    <h1>{{title}}</h1>
  </div>
  <div class="mt-32">
    <mat-tab-group (selectedTabChange)="myTabSelectedTabChange($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex align-center">
            <h2>Apoiado</h2>
          </div>
        </ng-template>
        <div class="contextPage">
          <div class="flex flex-between align-center">
            <h2>Dados Gerais</h2>      
          </div>
          <div class="flex flex-between align-center mt-32 gap-form">      
            <div class="w-100">
              <input-search #agreementField [options]="agreementOptions" placeholder="Selecione o convênio" [form]="form.agreementId"></input-search>
            </div>
            <div class="w-100">
              <input-search [options]="unityOptions" placeholder="Selecione a unidade" [form]="form.unityId"></input-search>
            </div>    
            <div class="w-100"></div>
          </div>
          <div class="flex flex-between align-center mt-32 gap-form">      
            <div class="w-100">
              <select-prop placeholder="Pagamento" [form]="form.payment" [options]="typeInvoiceSupport"></select-prop>
            </div>
            <div class="w-100">
              <select-prop placeholder="Cabeçalho" [form]="form.header" [options]="typeHeader"></select-prop>
            </div>      
            <div class="w-100">
              <select-prop placeholder="Integração" [form]="form.integration" [options]="typeIntegration"></select-prop>
            </div>      
          </div>
          <div class="flex flex-center mt-32">
            <div>
              <span>Imagem Para Cabeçalho</span>
              <file-prop dashed="true" [form]="form.image" [formName]="form.imageName"></file-prop>      
            </div>
          </div>
          <div class="flex flex-center mt-32">
            <app-button (click)="agreementSupportId? putAgreementSupport():postAgreementSupport()" [placeholder]="'Salvar'"></app-button>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="title == 'Editar apoiado'">
        <ng-template mat-tab-label>
          <div class="flex align-center">
            <h2>Parâmetros</h2>
          </div>
        </ng-template>
        <div class="data-content">
          <div class="contextPage">
            <div class="box">
              <div class="flex-center">
                <div class="w-100 flex flex-between align-center">
                  <h2>Parâmetros do Apoiado</h2>
                  <app-button (click)="openDialogModalParameter()" [placeholder]="'Incluir Parâmetro'" [class]="'flex gap-2 align-center'"></app-button>
                </div>
              </div>
              <div class="mt-24">
                <app-table [Pagination]="true" [backPagination]="false" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [noShadow]="true" [ClickInItem]="ActionsExtraParameters" (parentFun)="actions($event)" [Itens]="parameters"></app-table>
              </div>
            </div>
            <div>
              <div class="box mt-32">
                <div class="flex align-center flex-between gap-form ">
                  <div class="flex align-center ">
                    <h2>De/Para de Exames</h2>
                  </div>
                  <app-button (click)="openDialogFromTo()" [placeholder]="'Incluir Exames'" [class]="'flex gap-2 align-center'"></app-button>
                </div>
                <div class="mt-24">
                  <app-table [ClickInItem]="ActionsExtraExams" [noShadow]="true" *ngIf="fromTo.length > 0" (parentFun)="actions($event)" 
                    (changePage)="getFromTo($event)" [Actions]="['Editar', 'Excluir']" [Pagination]="true" [backPagination]="false" 
                    [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="fromTo">
                  </app-table>
                </div>
              </div>  
            </div>
          </div>
        </div>        
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #dialogModal>
  <ng-container>
    <div class="flex flex-between">
      <h1 class="mb-32">{{fromToId != '00000000-0000-0000-0000-000000000000' ? 'Editar' : 'Criar'}} De/Para</h1>
      <svg class="close-svg" (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div style="align-items: center;" class="flex gap-form">
      <div class="w-100">
        <input-search [readOnly]="fromToId != '00000000-0000-0000-0000-000000000000' ? true : false" [placeholder]="'Analito'" (changeForm)="formFromTo.analyteId.value ? getMaterials() : ''" [options]="analytes" [form]="formFromTo.analyteId"></input-search>
      </div>
      <div class="w-100">
        <input-search [readOnly]="fromToId != '00000000-0000-0000-0000-000000000000' ? true : false" [placeholder]="'Material'" [options]="materials" [form]="formFromTo.materialId"></input-search>
      </div>                     
    </div>    
    <div class="flex gap-form mt-32">
      <div class="w-100">
        <input-prop (keyup.enter)="fromToId != '00000000-0000-0000-0000-000000000000' ? putFromTo() : postFromTo()" [placeholder]="'Código Externo'" [form]="formFromTo.externalId"></input-prop>
      </div>
    </div>
    <div class="flex flex-center mt-32">
      <app-button (click)="fromToId != '00000000-0000-0000-0000-000000000000' ? putFromTo() : postFromTo()" [placeholder]="'Salvar'" [class]="'btn'"></app-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #dialogModalParameter>
  <ng-container>
    <div class="flex flex-between w-100 align-center">
      <h1>Novos Parâmetros do Apoiado</h1>
      <svg (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
      </svg>
    </div>
    <div class="mt-32">
      <input-user-multiple-new [form]="additionalParameter.name" [hideOptions]="hideParameters" [options]="typeAgreementParameters"></input-user-multiple-new>
    </div>
    <div class="w-100 mt-32 flex flex-center">
      <app-button (click)="includeAgreementParameter()" [placeholder]="'Salvar'" [class]="'flex gap-2 align-center btn'"></app-button>
    </div>
  </ng-container>
</ng-template>