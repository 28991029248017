import { Location } from "@angular/common";
import { Injectable, inject } from "@angular/core";
import { MultiObservableFacade } from "../../../common/data/facades/multi-observable";

import { QueryParams } from "../../../data/services/types/http.types";
import { Guid } from "@app/util/guid";
import { Util } from "@app/util/util";

import { AgreementsService } from "@app/modules_new/parameters/data/services/agreements/agreements.service";
import {
   AgreementInvoicingRequest,
   AgreementRequest,
   AgreementResponse,
   AgreementsResponse
} from "@app/modules_new/parameters/data/services/agreements/agreements.types";
import { lastValueFrom, map } from "rxjs";
import { PriceTableService } from "../services/price-table/price-table.service";
import { RequestOptions } from "@app/modules_new/data/types/http.types";
import { PriceTablesResponse } from "../services/price-table/price-table.types";
import { Router } from "@angular/router";
import { AgreementInvoicingService } from "../services/agreement-invoicing/agreement-invoicing.service";

@Injectable({
   providedIn: "root"
})
export class AgreementsFacade extends MultiObservableFacade {
   private _route = inject(Router);
   private _location = inject(Location);
   private _agreementsService = inject(AgreementsService);
   private _agreementsInvoicingService = inject(AgreementInvoicingService);

   private _priceTableService = inject(PriceTableService);
   private _agreements =
      this.createNullableObservableControl<AgreementResponse[]>();
   private _agreement =
      this.createNullableObservableControl<AgreementResponse>();
   private _agreementsAmount = this.createObservableControl<number>(0);
   private _isInvoiceModule = this.createObservableControl<boolean>(false);

   getAgreements(page: number, numberRegistry: number, search?: QueryParams) {
      this._agreements.subject.next(null);
      this._agreementsService
         .getPagination<AgreementsResponse>(page, numberRegistry, {
            query: search
         })
         .subscribe({
            next: (response) => {
               this._agreements.subject.next(response.data);
               this._agreementsAmount.subject.next(response.sumRecords);
               this._isInvoiceModule.subject.next(response.isInvoiceModule);
            },
            error: (err) => {
               this._agreements.subject.next([]);
               this._agreementsAmount.subject.next(0);
            }
         });
      return {
         data$: this._agreements.observable$,
         total$: this._agreementsAmount.observable$,
         isInvoiceModule: this._isInvoiceModule.observable$
      };
   }

   getAgreementsOptions() {
      return new Promise((resolve) => {
         const filter: RequestOptions = {
            query: {
               resume: true,
               isActive: true
            }
         };
         this._agreementsService.get<AgreementsResponse>(filter).subscribe({
            next: (response) => {
               const obj = response.data.map((agreement) => ({
                  value: agreement.id,
                  label: agreement.description
               }));
               resolve(obj);
            },
            error: (error) => {
               this.ToastErrorGeneric(error);
            }
         });
      });
   }

   getAgreementsList(query: QueryParams = {}) {
      return this._agreementsService.get<AgreementsResponse>({ query });
   }

   async getOptionsByQuery(query: QueryParams) {
      try {
         const response: any = await lastValueFrom(
            this._agreementsService.get<AgreementsResponse>({ query })
         );
         const obj: any = [];
         response.data.forEach((agreement: any) => {
            obj.push({
               value: agreement.id,
               label: agreement.description,
               externalId: agreement.externalId,
               controlPlan: agreement.controlPlan,
               information: agreement.information,
               typeInvoice: agreement.typeInvoice
            });
         });
         if (response.data.length === 0)
            this.warningMessage(
               "Convênio não encontrado! Verifique se o mesmo está inativo!"
            );
         return obj;
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }
   }

   async getOptionById(id: string) {
      try {
         const response: any = await lastValueFrom(
            this._agreementsService.getById(new Guid(id))
         );
         const obj: any = [];
         if (response.data) {
            obj.push({
               value: response.data[0].id,
               label: response.data[0].description,
               externalId: response.data[0].externalId,
               controlPlan: response.data[0].controlPlan,
               information: response.data[0].information,
               typeInvoice: response.data[0].typeInvoice
            });
            return obj;
         }
         return [];
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }
   }

   async getAgreementById(id: string) {
      await this._agreementsService
         .getById<AgreementsResponse>(new Guid(id))
         .subscribe({
            next: (response) => {
               this._agreement.subject.next(response.data[0]);
            }
         });

      return {
         data$: this._agreement.observable$
      };
   }

   getAgreementInvoicingById(id: string) {
      this._agreementsInvoicingService
         .getById<AgreementsResponse>(new Guid(id))
         .subscribe({
            next: (response) => {
               this._agreement.subject.next(response.data[0]);
            }
         });

      return {
         data$: this._agreement.observable$
      };
   }

   getAgreementInvoicingData(id: string) {
      return lastValueFrom(
         this._agreementsInvoicingService
            .getById<AgreementsResponse>(new Guid(id))
            .pipe(map((res) => res.data[0]))
      );
   }

   async patchActive(id: string, value: string) {
      let success = false;
      try {
         const response: any = await lastValueFrom(
            this._agreementsService.patchToggleIsActive(id, !value)
         );
         if (response.success) {
            this.ToastSuccess(
               !value === true
                  ? "toast-msg.change-to-active.success"
                  : "toast-msg.change-to-inactive.success"
            );
            success = response.success;
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
      }

      //@ts-ignore
      let data = this._agreements.subject._value;
      let index = data.findIndex((x: any) => x.id == id);

      if (success) {
         data[index].isActive = !value;
      } else {
         data[index].isActive = value;
      }
      this._agreements.subject.next(data);
      return success;
   }

   async deleteAgreement(agreementId: string): Promise<any> {
      return new Promise((resolve) =>
         this._agreementsService
            .delete(new Guid(agreementId))
            .subscribe({
               next: (res) => {
                  this.ToastSuccess("toast-msg.delete.success");
                  resolve(res);
               },
               error: (error) => {
                  this.ToastErrorGeneric(error, "toast-msg.delete.error");
               }
            })
      );
   }

   createAgreement(request: AgreementRequest) {
      this._agreementsService.post(request).subscribe({
         next: (response: any) => {
            this.ToastSuccess("agreementsEdit.msgPostAgreement");

            if (response.isInvoiceModule) {
               this._route.navigateByUrl(
                  "/Cadastros/Convênios/Convenio-Faturamento/" + response.id
               );
            } else {
               this._route.navigateByUrl(
                  "/Cadastros/Convênios/Convênio/" + response.id
               );
            }
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.creation.error");
         }
      });
   }

   updateAgreement(id: string, request: AgreementRequest) {
      this._agreementsService.put(request, new Guid(id)).subscribe({
         next: (response: any) => {
            this.ToastSuccess("agreementsEdit.msgPutAgreement");
            if (response.isInvoiceModule) {
               this._route.navigateByUrl(
                  "/Cadastros/Convênios/Convenio-Faturamento/" + id
               );
            }
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.change.error");
         }
      });
   }

   updateAgreementInvoicing(id: string, request: AgreementInvoicingRequest) {
      this._agreementsService.put(request, new Guid(id)).subscribe({
         next: () => {
            this.ToastSuccess("agreementsEdit.msgPutAgreement");
         },
         error: (error) => {
            this.ToastErrorGeneric(error, "toast-msg.change.error");
         }
      });
   }

   async getPriceTable(): Promise<any[]> {
      const request: RequestOptions = {
         query: { isActive: true }
      };
      try {
         const response = await lastValueFrom(
            this._priceTableService.getPagination<PriceTablesResponse>(
               1,
               999999,
               request
            )
         );

         if (response.data.length > 0) {
            const priceTables = response.data.map((priceTable: any) => ({
               value: priceTable.id,
               label: priceTable.description,
               index: priceTable.index
            }));
            return priceTables;
         } else {
            return [];
         }
      } catch (error) {
         this.ToastErrorGeneric(error);
         return [];
      }
   }

   backPage() {
      this._location.back();
   }
}
