<div class="contentPage">
  <div class="w-100 flex flex-between align-center headerResponsive">
    <div class="flex align-center gap-2 titleResponsive">
      <h1>Equipamentos Interfaceados</h1>
      <app-button (click)="redirect()" [placeholder]="'Novo'" [matIcon]="'add'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" (beforeClose)="get()" [cleanButton]="true" (clearFilters)="resetFilter()">
      <div class="query">
        <input-prop #autoFocus type="text" placeholder="Pesquisar" (keyup.enter)="get()" [form]="Search"></input-prop>
        <checkbox-prop class="flex flex-center" [gap]="1" [form]="active" label="Somente Ativos"></checkbox-prop>
      </div>
      <app-button (click)="get()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [ClickInItem]="ActionsExtra" [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="equipments" (parentFun)="actions($event)" [Actions]="['Editar']" (changePage)="get($event)"></app-table>
  </div>
</div>
