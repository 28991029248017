<div class="contentPage">
  <div class="flex align-center w-100 flex-direction-column-mobile">
    <div class="flex align-center w-100 gp-12 flex-direction-column-mobile">
      <h1>Acessos</h1>
      <button class="btn flex gp-12 align-center" (click)="newAccess()" mat-button>
        <mat-icon>add</mat-icon>
        Criar acesso
      </button>
      <div class="flex gp-12 align-center">
        <select-prop [form]="formSearch.type" placeholder="Selecione o tipo do acesso" [options]="typeOptions" (change)="get()"></select-prop>
      </div>
    </div>
    <app-filter [advanced]="false">
      <div class="query aniOpen">
        <input-prop class="w-100" [placeholder]="'Nome'" [form]="formSearch.name"></input-prop>
        <input-prop *ngIf="formSearch.type.value == 3" class="w-100" [placeholder]="'Registro'" [form]="formSearch.register"></input-prop>
        <input-prop *ngIf="formSearch.type.value == 1" class="w-100" [placeholder]="'Data de Nascimento'" type="date-local" [form]="formSearch.birthDate"></input-prop>
      </div>
      <div class="queryBtn">
        <button class="btn flex gp-12 align-center" (click)="get()">Pesquisar <mat-icon>search</mat-icon></button>
      </div>
    </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [Itens]="access" [Actions]="['Editar']" (parentFun)="Actions($event)" (changePage)="get($event)"></app-table>
  </div>
</div>

<ng-template #dialogModal>
  <ng-container>
    <div>
      <div class="flex flex-between w-100 align-center">
        <h1>{{accessId == '00000000-0000-0000-0000-000000000000' ? 'Novo acesso' : 'Editar acesso'}}</h1>
        <svg (click)="close()" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999982 2.3077L18.6923 20L21 17.6923L3.30767 9.33714e-06L0.999982 2.3077Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3077 0L0.615356 17.6923L2.92305 20L20.6153 2.30769L18.3077 0Z"/>
        </svg>
      </div>
      <div class="flex align-center w-100 gp-12">
        <span>Selecione o tipo: </span>
        <div class="flex align-center">
          <span>Cliente</span>
          <checkbox-prop (click)="clickType1()" [radio]="true" [form]="form.type1"></checkbox-prop>
        </div>
        <div class="flex align-center">
          <span>Solicitante</span>
          <checkbox-prop (click)="clickType3()" [radio]="true" [form]="form.type3"></checkbox-prop>
        </div>
        <div class="flex align-center">
          <span>Convênio</span>
          <checkbox-prop (click)="clickType4()" [radio]="true" [form]="form.type4"></checkbox-prop>
        </div>
      </div>
      <div *ngIf="form.type1.value || form.type3.value || form.type4.value" class="flex align-center w-100 gp-12">
        <input-search *ngIf="!alterCustomer" class="w-100" placeholder="Selecione o usuário" [form]="form.externalId" [options]="users"></input-search>
        <input-prop (keydown.enter)="getExternalId()" *ngIf="alterCustomer" class="w-100" [placeholder]=searchPlaceHolder [form]="form.searchCustomer"></input-prop>
        <svg *ngIf="alterCustomer" (click)="getExternalId()" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.6732 9.51212C12.5196 8.25295 12.8669 6.72374 12.6474 5.22249C12.4279 3.72124 11.6574 2.35548 10.4859 1.39134C9.31439 0.427204 7.82591 -0.0662417 6.31047 0.00715382C4.79503 0.0805493 3.3612 0.715528 2.28836 1.78836C1.21553 2.8612 0.580549 4.29503 0.507154 5.81047C0.433758 7.32591 0.927204 8.81439 1.89134 9.98588C2.85548 11.1574 4.22124 11.9279 5.72249 12.1474C7.22374 12.3669 8.75295 12.0196 10.0121 11.1732L13.5253 14.6849C13.7479 14.8923 14.0423 15.0052 14.3465 14.9998C14.6507 14.9945 14.941 14.8712 15.1561 14.6561C15.3712 14.441 15.4945 14.1507 15.4998 13.8465C15.5052 13.5423 15.3923 13.2479 15.1849 13.0253L11.6732 9.51212ZM6.60538 9.86282C5.86223 9.86282 5.13577 9.64245 4.51786 9.22957C3.89996 8.8167 3.41836 8.22987 3.13397 7.54329C2.84957 6.85671 2.77516 6.10121 2.92015 5.37234C3.06513 4.64347 3.42299 3.97396 3.94847 3.44847C4.47396 2.92299 5.14347 2.56513 5.87234 2.42015C6.60121 2.27516 7.35671 2.34957 8.04329 2.63397C8.72987 2.91836 9.3167 3.39996 9.72957 4.01786C10.1424 4.63577 10.3628 5.36223 10.3628 6.10538C10.3628 7.10192 9.96694 8.05763 9.26229 8.76229C8.55763 9.46694 7.60192 9.86282 6.60538 9.86282Z" />
        </svg>
        <span>
          <svg style="cursor: pointer;margin-left:10px;" (click)="alterCustomer = !alterCustomer" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.79392 15.6029L9.53118 15.5868L19.8709 5.34576C20.2767 4.93999 20.5 4.4011 20.5 3.82786C20.5 3.25463 20.2767 2.71574 19.8709 2.30996L18.1684 0.607425C17.3569 -0.204126 15.9409 -0.199832 15.1358 0.604204L4.79392 10.8473V15.6029ZM16.6505 2.12533L18.3563 3.82464L16.6419 5.52289L14.9394 3.82142L16.6505 2.12533ZM6.94088 11.7426L13.414 5.33074L15.1165 7.03327L8.64449 13.443L6.94088 13.4484V11.7426Z"/>
              <path d="M2.64696 19.8828H17.6757C18.8597 19.8828 19.8226 18.9199 19.8226 17.7359V8.43094L17.6757 10.5779V17.7359H6.03701C6.0091 17.7359 5.98011 17.7466 5.9522 17.7466C5.91678 17.7466 5.88135 17.7369 5.84486 17.7359H2.64696V2.70715H9.99707L12.144 0.560192H2.64696C1.46291 0.560192 0.5 1.5231 0.5 2.70715V17.7359C0.5 18.9199 1.46291 19.8828 2.64696 19.8828Z"/>
          </svg>
        </span>
      </div>
      <div class="mt-32 flex w-100" >
        <input-prop class="w-100" placeholder="Nome" [form]="form.name"></input-prop>
        <div *ngIf="accessId != '00000000-0000-0000-0000-000000000000'" style="max-width: 100px;" class="flex">
          <span style="margin-top: 15px;margin-right: 9px;margin-left: 10px;">Ativo</span><checkbox-prop placeholder="Ativo" [form]="form.active"></checkbox-prop>
        </div>
      </div>
      <div style="gap:10px;" class="mt-32 flex w-100" >
        <input-prop class="w-100" placeholder="Código de Acesso" [form]="form.accessCode"></input-prop>
        <input-prop class="w-100" placeholder="Celular" [form]="form.cellPhone"></input-prop>
      </div>
      <div *ngIf="accessId.guid == '00000000-0000-0000-0000-000000000000'" class="gp-12 mt-32 flex w-100" >
        <input-prop class="w-100" type="password" placeholder="Senha" [form]="form.password"></input-prop>
        <input-prop class="w-100" type="password" placeholder="Confirmar senha" [form]="form.confirmPassword"></input-prop>
      </div>
      <div class="flex flex-center mt-10">
        <button (click)="saveAccess()" class="btn">Salvar acesso</button>
      </div>
    </div>
  </ng-container>
</ng-template>
