import {
   Component,
   Input,
   OnInit,
   ViewChildren,
   inject,
   QueryList,
   OnDestroy,
   ContentChild,
   TemplateRef,
   Output,
   EventEmitter
} from "@angular/core";
import {
   ActionDefition,
   ColumnDefinition,
   ColumnEvent,
   FilterRowsRealtime,
   TableEvent,
   TableUserEvent
} from "./table.model";
import { BehaviorSubject, Observable, first, tap } from "rxjs";
import {
   AsyncPipe,
   JsonPipe,
   NgClass,
   NgFor,
   NgForOf,
   NgIf,
   NgStyle,
   NgTemplateOutlet
} from "@angular/common";
import { TableColumnComponent } from "./column/column.component";
import { FormControl } from "@angular/forms";
import { TableActionsComponent } from "./actions/actions.component";
import { TranslateModule } from "@ngx-translate/core";
import { DataComponent } from "@app/modules_new/common/components/data";
import { Guid } from "@app/modules_new/shared/data/types/guid";
import { TableRowsFilterPipe } from "@app/modules_new/shared/pipe/table-filter.pipe";
import { EventChildPropagationService } from "@app/modules_new/shared/data/services/event-propagation.service";
import { CheckboxFormComponent } from "../checkbox/checkbox.component";
import { SvgSelectorUIComponent } from "../../ui/svg-selector/svg-selector.component";
import { LoadingUIComponent } from "../../ui/loading/loading.component";
import { TableRegistryService } from "@app/tableRegistry.service";
import { Router } from "@angular/router";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

/**
 * Componente para exibir valores de Array em tabela
 */
@Component({
   selector: "forms-table[columns]",
   templateUrl: "./table.component.html",
   styleUrls: ["./table.component.scss"],
   standalone: true,
   imports: [
      TableColumnComponent,
      TableActionsComponent,
      CheckboxFormComponent,
      NgForOf,
      NgFor,
      NgIf,
      NgClass,
      SvgSelectorUIComponent,
      NgTemplateOutlet,
      AsyncPipe,
      TableRowsFilterPipe,
      LoadingUIComponent,
      TranslateModule,
      NgStyle,
      MatProgressSpinnerModule
   ]
})
export class TableFormComponent
   extends DataComponent
   implements OnInit, OnDestroy
{
   private _router = inject(Router);

   private _tableEvent = new BehaviorSubject<TableEvent | null>(null);
   private tableEvent$ = this._tableEvent.asObservable();

   private _sortKey = "";
   private _defaultSort = -1;

   private _tableRegistryService = inject(TableRegistryService);

   private _userEventService = inject(
      EventChildPropagationService<TableUserEvent>
   );

   /**
    * Identificador único para cada tabela
    */
   tableId = new Guid();

   /**
    * Controla filtro em tempo real de valores
    */
   @Input() filterRows!: FilterRowsRealtime;
   /**
    * Controla id da tabela de envio para apoio
    */
   @Input() shippingId = null;
   /**
    * Controla se tabela ignorará padding padrão
    */
   @Input() ignorePadding = false;

   /**
    * Controla se a tabela terá `bordas arredondadas`
    */
   @Input() roundBorder = true;

   /**
    * Controla se a tabela terá `box-shadow`
    */
   @Input() shadow = true;

   /**
    * Nome de atributo que será o identificador de linha (objeto)
    */
   @Input() rowPropertyIdentifier = "id";
   /**
    * Controla se exibirá `checkbox` para selecionar colunas
    */
   @Input() canSelectRows = false;
   /**
    * Controla linhas terão `dropdown`
    */
   @Input() hasDropdown = false;
   /**
    * Define se permite selecionar apenas um registro
    */
   @Input() selectOnlyOne = false;
   /**
    * Definição das Ações que serão exibidas em linhas
    */
   @Input() actions: ActionDefition[] = [];
   /**
    * Definição das colunas que serão exibidas em linha
    */
   @Input() columns: ColumnDefinition[] = [];
   /**
    * Observable<any[]> com os valores que serão exibidos em cada linha
    */
   @Input() rows!: Observable<any[]>;
   /**
    * Controla se as linhas permanecerão selecionadas após os itens serem atualizados
    */
   @Input() persistSelectedRows = false;
   @Input() doubleTable: boolean = false;
   @ContentChild("dropdown", { static: false })
   dropdownTemplateRef!: TemplateRef<any>;

   @ViewChildren(CheckboxFormComponent)
   checkboxRows!: QueryList<CheckboxFormComponent>;

   @ViewChildren(TableColumnComponent)
   tableColumns!: QueryList<TableColumnComponent>;

   formSelectAll = new FormControl(false);
   /**
    * Linhas que estão selecionadas por `checkbox`
    */
   selectedRows: any = [];
   @Input() selectedRowsAux: any = [];
   /**
    * Linhas abertas por `dropdown`
    */
   openedRows: any[] = [];

   /**
    * Ordenação atual
    *  */
   sort = { property: "", order: "ASC" };

   /**
    * BehaviorSubject para enviar comandos para manipular as linhas selecionadas
    * @todo implementar forma refatorada de enviar comandos à tabela
    */
   @Input() selectedRowsController?: BehaviorSubject<{
      event: "erase_all" | "select_one";
      id?: string;
   }>;

   @Input() extra: boolean = false;

   @Output() extraEmit = new EventEmitter();

   @Output() selectedRowsChange = new EventEmitter<any>();

   @Output() selectedExam = new EventEmitter<any[]>();

   @Output() sortApi = new EventEmitter<string>();

   loadingTable = true;

   @Input() tableName = "";
   @Input() greenRow: boolean = false;

   isAdm: boolean = this._router.url.includes("/Admin") ? true : false;

   emit(id: any) {
      this.extraEmit.emit(id);
   }

   ngOnInit(): void {
      let event = undefined;
      this.subscribeToController();
      this._tableRegistryService.registerTable(this, this.tableName);
      this.addSubscriptions([
         this.tableEvent$.subscribe((response) => {
            event = response?.parentEvent;
            switch (response?.parentEvent) {
               case "SELECT_ALL":
                  if (!this.selectOnlyOne) {
                     this.selectedRows = [];
                     if (response.payload.event) {
                        this.rows.forEach((item) => {
                           item?.forEach((x: any) => {
                              this.selectedRows.push(x);
                           });
                        });
                     } else {
                        this.selectedRows = [];
                     }
                     this.checkboxRows.forEach(
                        (item) =>
                           (item.withoutFormValue = response.payload.event)
                     );
                  }
                  break;
               case "SELECT_ONE":
                  if (response.payload.event) {
                     if (this.selectOnlyOne) {
                        setTimeout(() => {
                           this.selectedRows = [];
                           this.selectedRowsAux = [];

                           this.checkboxRows.forEach((x: any) => {
                              if (
                                 x.row &&
                                 x.row[this.rowPropertyIdentifier] !=
                                    response.payload.data[
                                       this.rowPropertyIdentifier
                                    ]
                              ) {
                                 if (x.form) {
                                    x.form.setValue(false);
                                 } else {
                                    x.withoutFormValue = false;
                                 }
                              }
                           });
                        });
                     }
                     const findValue = this.selectedRows.find(
                        (item: any) =>
                           item[this.rowPropertyIdentifier] ===
                           response.payload.data[this.rowPropertyIdentifier]
                     );
                     if (!findValue) {
                        this.selectedRows.push(response.payload.data);
                        if (this.selectOnlyOne) {
                           this.selectedExam.emit(
                              response.payload.data.analyteId
                           );
                        }
                     }
                  } else {
                     const findValue = this.selectedRows.find(
                        (item: any) =>
                           item[this.rowPropertyIdentifier] ===
                           response.payload.data[this.rowPropertyIdentifier]
                     );
                     if (findValue) {
                        this.selectedRows = this.selectedRows.filter(
                           (item: any) =>
                              item[this.rowPropertyIdentifier] !==
                              response.payload.data[this.rowPropertyIdentifier]
                        );
                     }
                  }
                  break;
            }
            if (event) {
               this.selectedRowsAux = [...this.selectedRows];
               if (this.shippingId) {
                  this.selectedRowsChange.emit({
                     shippingId: this.shippingId,
                     exams: this.selectedRows
                  });
               } else {
                  this.selectedRowsChange.emit(this.selectedRows);
               }
            }
         })
      ]);
      this.addSubscriptions([
         this.rows?.subscribe((rows) => {
            if (rows) {
               this.loadingTable = false;
               if (this.canSelectRows) {
                  setTimeout(() => {
                     this.checkboxRows.forEach((item, index) => {
                        if (item.row && (index != 0 || this.doubleTable)) {
                           if (
                              this.selectedRowsAux.filter(
                                 (x: any) =>
                                    x.id == item.row[this.rowPropertyIdentifier]
                              ).length > 0
                           ) {
                              item.withoutFormValue = true;
                           } else {
                              item.withoutFormValue = false;
                           }
                        }
                     });
                     this.selectedRows = [...this.selectedRowsAux];
                     this.selectedRowsChange.emit(this.selectedRows);
                  });
               }
            } else {
               if (!this.persistSelectedRows) {
                  this.selectedRowsAux = [];
               }
               this.selectedRows = [];
               this.loadingTable = true;
            }
         })
      ]);
   }

   subscribeToController() {
      if (this.selectedRowsController)
         this.addSubscription(
            this.selectedRowsController.subscribe((command) => {
               switch (command?.event) {
                  case "erase_all":
                     this.checkboxRows?.forEach(
                        (item) => (item.withoutFormValue = false)
                     );
                     this.formSelectAll.setValue(false);
                     this.selectedRows = [];
                     this.selectedRowsAux = [];
                     break;
                  case "select_one":
                     this.selectedRows = [];
                     this.checkboxRows?.forEach((item) => {
                        item.withoutFormValue = item.row?.id == command.id;
                        if (item.row?.id == command.id)
                           this.selectedRows.push(item.row);
                     });
                     break;
               }
            })
         );
   }

   ngOnDestroy(): void {
      this._tableRegistryService.unregisterTable();
      this.unsubscribeAll();
   }

   selectSingleRow(row: any) {
      if (row) {
         let checkbox = this.checkboxRows.find((item) => item.row == row);
         if (checkbox) {
            checkbox.withoutFormValue = true;
            if (this.selectedRows.includes(row)) return;
            this.selectedRows.push(row);
         }
      }
   }

   selectRows(event: boolean, row: any) {
      if (row) {
         this._tableEvent.next({
            parentEvent: "SELECT_ONE",
            payload: {
               event,
               data: row
            }
         });
      } else {
         this._tableEvent.next({
            parentEvent: "SELECT_ALL",
            payload: {
               event
            }
         });
      }
   }

   emitSort(selectedKey: string) {
      this.sort = {
         property: selectedKey.replace(/[^a-zA-Z]/g, ""),
         order: this.sort.order == "ASC" ? "DESC" : "ASC"
      };
      this.sortApi.emit(`${this.sort.property},${this.sort.order}`);
   }

   wasSorted(column: ColumnDefinition) {
      return this._sortKey === this._getColumnSelectedKey(column);
   }

   sortColumn(column: ColumnDefinition) {
      const selectedKey = this._getColumnSelectedKey(column);
      this.emitSort(selectedKey);
      this._sortKey = selectedKey;
      this.openedRows = [];
      if (column.key) {
         this.rows
            .pipe(
               first(),
               tap((rows) => {
                  if (!rows) return;
                  let sorted = rows;

                  if (typeof rows[0][selectedKey] === "string") {
                     sorted = rows.sort(
                        (first: any, second: any) =>
                           (first[selectedKey] > second[selectedKey] ? -1 : 1) *
                           this._defaultSort
                     );
                  }

                  if (typeof rows[0][selectedKey] === "boolean") {
                     sorted = rows.sort((first: any, second: any) =>
                        first[selectedKey] === second[selectedKey]
                           ? 0
                           : (first[selectedKey] ? -1 : 1) * this._defaultSort
                     );
                  }

                  this._defaultSort = this._defaultSort < 0 ? 1 : -1;

                  return sorted;
               })
            )
            .subscribe();
      } else if (column.formatter) {
         let typeUsed: "int" | "string" | "float" | null = null;
         this.rows
            .pipe(
               first(),
               tap((rows) => {
                  if (!rows) return;

                  const sorted = rows.sort((first: any, second: any) => {
                     let previewValue = column.formatter!(first);
                     let nextValue = column.formatter!(second);

                     if (typeUsed === null) {
                        if (typeof previewValue === "string") {
                           if (
                              typeof parseInt(previewValue) === "number" &&
                              !isNaN(parseInt(previewValue))
                           ) {
                              if (
                                 previewValue.includes(".") ||
                                 previewValue.includes(",")
                              ) {
                                 typeUsed = "float";
                              } else {
                                 typeUsed = "int";
                              }
                           } else {
                              typeUsed = "string";
                           }
                        }
                     } else {
                        if (typeUsed === "string") {
                           previewValue =
                              previewValue === null ? "" : previewValue;
                           nextValue = nextValue === null ? "" : nextValue;
                        } else {
                           previewValue =
                              previewValue === null
                                 ? ""
                                 : typeUsed === "int"
                                 ? parseInt(previewValue)
                                 : parseFloat(previewValue);
                           nextValue =
                              nextValue === null
                                 ? ""
                                 : typeUsed === "int"
                                 ? parseInt(nextValue)
                                 : parseFloat(nextValue);
                        }
                     }

                     return (
                        (previewValue > nextValue ? -1 : 1) * this._defaultSort
                     );
                  });

                  this._defaultSort = this._defaultSort < 0 ? 1 : -1;

                  return sorted;
               })
            )
            .subscribe();
      }
   }

   columnClick(event: ColumnEvent) {
      this._userEventService
         .dispatcher(this.tableId)
         .emmit(event.parentEvent, event.payload);
   }

   toggleDropdown(row: any) {
      const isDropdownVisible = this._controlDropdown(row);
      this._userEventService
         .dispatcher(this.tableId)
         .emmit("DROPDOWN_PRESSED", {
            event: isDropdownVisible,
            data: row
         });
   }

   extraAction(event: string, row: any) {
      this._userEventService.dispatcher(this.tableId).emmit("EXTRA", {
         event: event,
         data: row
      });
   }

   actionClick(event: string, row: any) {
      this._userEventService.dispatcher(this.tableId).emmit("ACTIONS_PRESSED", {
         event: event,
         data: row
      });
   }

   isDropdownOpen(row: any) {
      if (this.openedRows.length === 0) return false;
      const findValue = this.openedRows.find(
         (item) =>
            item[this.rowPropertyIdentifier] === row[this.rowPropertyIdentifier]
      );
      return findValue ? true : false;
   }

   rowIdentity(index: number, item: any) {
      return item[this.rowPropertyIdentifier];
   }

   get userInteractions$() {
      return this._userEventService.handleEvents$(this.tableId);
   }

   get hasDropdownOrActions() {
      return this.hasDropdown || this.actions?.length > 0;
   }

   private _getColumnSelectedKey(column: ColumnDefinition) {
      if (column.key) {
         const selectedKey = column.key;
         return selectedKey;
      } else if (column.formatter) {
         const indexKey = column.formatter.toString().lastIndexOf("[");
         const selectedKey = column.formatter
            .toString()
            .substring(indexKey)
            .replace("['", "")
            .replace("']", "")
            .replace(";", "");
         return selectedKey;
      } else {
         return "";
      }
   }

   private _controlDropdown(row: any) {
      if (this.isDropdownOpen(row)) {
         const findedValue = this.openedRows.find(
            (item) =>
               item[this.rowPropertyIdentifier] ===
               row[this.rowPropertyIdentifier]
         );
         this.openedRows = this.openedRows.filter(
            (item) =>
               item[this.rowPropertyIdentifier] !==
               findedValue[this.rowPropertyIdentifier]
         );
         return false;
      }
      this.openedRows.push(row);

      return true;
   }

   public setLoader() {
      this.loadingTable = true;
   }

   clearSelectedExams() {
      this.selectedRows = [];
      this.selectedRowsAux = [];
      this.checkboxRows.forEach((item) =>
         item.form ? item.form.setValue(false) : (item.withoutFormValue = false)
      );
      this.selectedRowsChange.emit(this.selectedRows);
   }

   changeActive(id: string, situation: boolean) {
      //@ts-ignore
      const isActive = this.tableColumns
         .toArray()
         .find(
            (comp: TableColumnComponent) =>
               comp.row[this.rowPropertyIdentifier] === id &&
               comp.column.label === "default.active"
         );

      if (isActive) {
         isActive.changeActive(situation);
      }
   }

   get rowsList(): any[] {
      let list: any = [];
      if (this.rows instanceof Observable) {
         this.rows.subscribe((val) => (list = val || []));
      } else {
         list = this.rows || [];
      }
      return list;
   }

   get rowsLeft(): any[] {
      return this.rowsList.filter((_, i) => i % 2 === 0);
   }

   get rowsRight(): any[] {
      return this.rowsList.filter((_, i) => i % 2 !== 0);
   }
}
