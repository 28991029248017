import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http.service';
import { BaseService } from '@app/services/base.service';
import { HttpResponsePageable } from '@app/services/services.types';
import { AtributeAnalytePost, AtributeAnalytePostObject } from './atribute-analyte.service.module';

@Injectable({
  providedIn: 'root'
})

export class AtributeAnalyteService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/atributeanalyte');
  }

  override post(body: AtributeAnalytePostObject) {
    return super.post(body) as Promise<HttpResponsePageable<AtributeAnalytePost>>
  }
}