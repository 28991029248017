export enum InvoiceStatus {
    NotImplanted = 0,
    Implanted = 1,
    Checked = 2,
    Invoiced = 3,
    Received = 4,
    Reversed = 5,
    Addition = 6,
    Discount = 7,
    Finish = 9
}

export enum InvoiceStatusPTBR {
    'Não Implantado' = 0,
    'Implantação' = 1,
    'Conferência' = 2,
    'Faturamento' = 3,
    'Recebimento' = 4,
    'Estorno' = 5,
    'Acréscimo' = 6,
    'Desconto' = 7,
    'Finalização' = 9
}

export enum ParticularStatus {
    NotImplanted = 0,
    Implanted = 1,
    Received = 4,
    Addition = 6,
    Discount = 7,
    Finish = 9
}