<div class="content">
    <h1>
        Bem Vindo, <b>{{ UserName }}</b
        >.
    </h1>
    <div class="mt-8 flex flex-column">
        <h2>Acessar:</h2>
        <div class="flex flex-between gap-form mt-4">
            <div
                class="boxLink flex flex-between align-center"
                routerLink="/Movimentacao/Recepcao/Requisicoes"
            >
                <div class="flex gap-2 align-center">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10 22.5H20V20H10V22.5ZM10 17.5H20V15H10V17.5ZM7.5 27.5C6.8125 27.5 6.22396 27.2552 5.73438 26.7656C5.24479 26.276 5 25.6875 5 25V5C5 4.3125 5.24479 3.72396 5.73438 3.23438C6.22396 2.74479 6.8125 2.5 7.5 2.5H17.5L25 10V25C25 25.6875 24.7552 26.276 24.2656 26.7656C23.776 27.2552 23.1875 27.5 22.5 27.5H7.5ZM16.25 11.25V5H7.5V25H22.5V11.25H16.25Z"
                            fill="#FE7734"
                        />
                    </svg>
                    <h2>Requisição</h2>
                </div>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H12V5H5V19H19V12H21V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM9.7 15.7L8.3 14.3L17.6 5H14V3H21V10H19V6.4L9.7 15.7Z"
                        fill="#FE7734"
                    />
                </svg>
            </div>
            <div
                class="boxLink flex flex-between align-center"
                routerLink="/Movimentacao/Recepcao/Orcamentos"
            >
                <div class="flex gap-2 align-center">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10 22.5H11.875V20H14.375V18.125H11.875V15.625H10V18.125H7.5V20H10V22.5ZM16.25 21.5625H22.5V19.6875H16.25V21.5625ZM16.25 18.4375H22.5V16.5625H16.25V18.4375ZM17.625 13.6875L19.375 11.9375L21.125 13.6875L22.4375 12.375L20.6875 10.5625L22.4375 8.8125L21.125 7.5L19.375 9.25L17.625 7.5L16.3125 8.8125L18.0625 10.5625L16.3125 12.375L17.625 13.6875ZM7.8125 11.5H14.0625V9.625H7.8125V11.5ZM6.25 26.25C5.5625 26.25 4.97396 26.0052 4.48438 25.5156C3.99479 25.026 3.75 24.4375 3.75 23.75V6.25C3.75 5.5625 3.99479 4.97396 4.48438 4.48438C4.97396 3.99479 5.5625 3.75 6.25 3.75H23.75C24.4375 3.75 25.026 3.99479 25.5156 4.48438C26.0052 4.97396 26.25 5.5625 26.25 6.25V23.75C26.25 24.4375 26.0052 25.026 25.5156 25.5156C25.026 26.0052 24.4375 26.25 23.75 26.25H6.25ZM6.25 23.75H23.75V6.25H6.25V23.75Z"
                            fill="#FE7734"
                        />
                    </svg>

                    <h2>Orçamento</h2>
                </div>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H12V5H5V19H19V12H21V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM9.7 15.7L8.3 14.3L17.6 5H14V3H21V10H19V6.4L9.7 15.7Z"
                        fill="#FE7734"
                    />
                </svg>
            </div>
            <div
                class="boxLink flex flex-between align-center"
                routerLink="/Movimentacao/Financeiro/Titulos"
            >
                <div class="flex gap-2 align-center">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17.5 16.25C16.4583 16.25 15.5729 15.8854 14.8438 15.1562C14.1146 14.4271 13.75 13.5417 13.75 12.5C13.75 11.4583 14.1146 10.5729 14.8438 9.84375C15.5729 9.11458 16.4583 8.75 17.5 8.75C18.5417 8.75 19.4271 9.11458 20.1562 9.84375C20.8854 10.5729 21.25 11.4583 21.25 12.5C21.25 13.5417 20.8854 14.4271 20.1562 15.1562C19.4271 15.8854 18.5417 16.25 17.5 16.25ZM8.75 20C8.0625 20 7.47396 19.7552 6.98438 19.2656C6.49479 18.776 6.25 18.1875 6.25 17.5V7.5C6.25 6.8125 6.49479 6.22396 6.98438 5.73438C7.47396 5.24479 8.0625 5 8.75 5H26.25C26.9375 5 27.526 5.24479 28.0156 5.73438C28.5052 6.22396 28.75 6.8125 28.75 7.5V17.5C28.75 18.1875 28.5052 18.776 28.0156 19.2656C27.526 19.7552 26.9375 20 26.25 20H8.75ZM11.25 17.5H23.75C23.75 16.8125 23.9948 16.224 24.4844 15.7344C24.974 15.2448 25.5625 15 26.25 15V10C25.5625 10 24.974 9.75521 24.4844 9.26562C23.9948 8.77604 23.75 8.1875 23.75 7.5H11.25C11.25 8.1875 11.0052 8.77604 10.5156 9.26562C10.026 9.75521 9.4375 10 8.75 10V15C9.4375 15 10.026 15.2448 10.5156 15.7344C11.0052 16.224 11.25 16.8125 11.25 17.5ZM25 25H3.75C3.0625 25 2.47396 24.7552 1.98438 24.2656C1.49479 23.776 1.25 23.1875 1.25 22.5V8.75H3.75V22.5H25V25Z"
                            fill="#FE7734"
                        />
                    </svg>
                    <h2>Caixa</h2>
                </div>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H12V5H5V19H19V12H21V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM9.7 15.7L8.3 14.3L17.6 5H14V3H21V10H19V6.4L9.7 15.7Z"
                        fill="#FE7734"
                    />
                </svg>
            </div>
            <div
                class="boxLink flex flex-between align-center"
                routerLink="/Movimentacao/Coleta"
            >
                <div class="flex gap-2 align-center">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3.75 26.25C3.0625 26.25 2.47396 26.0052 1.98438 25.5156C1.49479 25.026 1.25 24.4375 1.25 23.75V17.5C1.25 16.8125 1.49479 16.224 1.98438 15.7344C2.47396 15.2448 3.0625 15 3.75 15V9.65625C3.375 9.42708 3.07292 9.13021 2.84375 8.76562C2.61458 8.40104 2.5 7.97917 2.5 7.5V6.25C2.5 5.5625 2.74479 4.97396 3.23438 4.48438C3.72396 3.99479 4.3125 3.75 5 3.75H11.25C11.9375 3.75 12.526 3.99479 13.0156 4.48438C13.5052 4.97396 13.75 5.5625 13.75 6.25V7.5C13.75 7.97917 13.6354 8.40104 13.4062 8.76562C13.1771 9.13021 12.875 9.42708 12.5 9.65625V15H17.5V9.65625C17.125 9.42708 16.8229 9.13021 16.5938 8.76562C16.3646 8.40104 16.25 7.97917 16.25 7.5V6.25C16.25 5.5625 16.4948 4.97396 16.9844 4.48438C17.474 3.99479 18.0625 3.75 18.75 3.75H25C25.6875 3.75 26.276 3.99479 26.7656 4.48438C27.2552 4.97396 27.5 5.5625 27.5 6.25V7.5C27.5 7.97917 27.3854 8.40104 27.1562 8.76562C26.9271 9.13021 26.625 9.42708 26.25 9.65625V15C26.9375 15 27.526 15.2448 28.0156 15.7344C28.5052 16.224 28.75 16.8125 28.75 17.5V23.75C28.75 24.4375 28.5052 25.026 28.0156 25.5156C27.526 26.0052 26.9375 26.25 26.25 26.25H3.75ZM18.75 7.5H25V6.25H18.75V7.5ZM5 7.5H11.25V6.25H5V7.5ZM20 15H23.75V10H20V15ZM6.25 15H10V10H6.25V15ZM3.75 23.75H26.25V17.5H3.75V23.75Z"
                            fill="#FE7734"
                        />
                    </svg>
                    <h2>Fila de Coleta</h2>
                </div>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H12V5H5V19H19V12H21V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM9.7 15.7L8.3 14.3L17.6 5H14V3H21V10H19V6.4L9.7 15.7Z"
                        fill="#FE7734"
                    />
                </svg>
            </div>
            <div
                class="boxLink flex flex-between align-center"
                routerLink="/Cadastros/Atendimento/Clientes"
            >
                <div class="flex gap-2 align-center">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_14807_4876)">
                            <path
                                d="M15.625 14.9375C16.2292 14.2708 16.6927 13.5104 17.0156 12.6562C17.3385 11.8021 17.5 10.9167 17.5 10C17.5 9.08333 17.3385 8.19792 17.0156 7.34375C16.6927 6.48958 16.2292 5.72917 15.625 5.0625C16.875 5.22917 17.9167 5.78125 18.75 6.71875C19.5833 7.65625 20 8.75 20 10C20 11.25 19.5833 12.3438 18.75 13.2812C17.9167 14.2188 16.875 14.7708 15.625 14.9375ZM22.5 25V21.25C22.5 20.5 22.3333 19.7865 22 19.1094C21.6667 18.4323 21.2292 17.8333 20.6875 17.3125C21.75 17.6875 22.7344 18.1719 23.6406 18.7656C24.5469 19.3594 25 20.1875 25 21.25V25H22.5ZM25 16.25V13.75H22.5V11.25H25V8.75H27.5V11.25H30V13.75H27.5V16.25H25ZM10 15C8.625 15 7.44792 14.5104 6.46875 13.5312C5.48958 12.5521 5 11.375 5 10C5 8.625 5.48958 7.44792 6.46875 6.46875C7.44792 5.48958 8.625 5 10 5C11.375 5 12.5521 5.48958 13.5312 6.46875C14.5104 7.44792 15 8.625 15 10C15 11.375 14.5104 12.5521 13.5312 13.5312C12.5521 14.5104 11.375 15 10 15ZM0 25V21.5C0 20.7917 0.182292 20.1406 0.546875 19.5469C0.911458 18.9531 1.39583 18.5 2 18.1875C3.29167 17.5417 4.60417 17.0573 5.9375 16.7344C7.27083 16.4115 8.625 16.25 10 16.25C11.375 16.25 12.7292 16.4115 14.0625 16.7344C15.3958 17.0573 16.7083 17.5417 18 18.1875C18.6042 18.5 19.0885 18.9531 19.4531 19.5469C19.8177 20.1406 20 20.7917 20 21.5V25H0ZM10 12.5C10.6875 12.5 11.276 12.2552 11.7656 11.7656C12.2552 11.276 12.5 10.6875 12.5 10C12.5 9.3125 12.2552 8.72396 11.7656 8.23438C11.276 7.74479 10.6875 7.5 10 7.5C9.3125 7.5 8.72396 7.74479 8.23438 8.23438C7.74479 8.72396 7.5 9.3125 7.5 10C7.5 10.6875 7.74479 11.276 8.23438 11.7656C8.72396 12.2552 9.3125 12.5 10 12.5ZM2.5 22.5H17.5V21.5C17.5 21.2708 17.4427 21.0625 17.3281 20.875C17.2135 20.6875 17.0625 20.5417 16.875 20.4375C15.75 19.875 14.6146 19.4531 13.4688 19.1719C12.3229 18.8906 11.1667 18.75 10 18.75C8.83333 18.75 7.67708 18.8906 6.53125 19.1719C5.38542 19.4531 4.25 19.875 3.125 20.4375C2.9375 20.5417 2.78646 20.6875 2.67188 20.875C2.55729 21.0625 2.5 21.2708 2.5 21.5V22.5Z"
                                fill="#FE7734"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_14807_4876">
                                <rect width="30" height="30" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <h2>Clientes</h2>
                </div>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H12V5H5V19H19V12H21V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM9.7 15.7L8.3 14.3L17.6 5H14V3H21V10H19V6.4L9.7 15.7Z"
                        fill="#FE7734"
                    />
                </svg>
            </div>
        </div>
    </div>
</div>
