import {v4 as uuidv4} from 'uuid';

export class Guid {
  private guid: string;

  constructor(guid?: string) {
    this.guid = guid || Guid.getNewGUIDString();
  }

  public toString() {
    return this.guid;
  }

  private static getNewGUIDString(): string {
    return uuidv4();
  }

  public static getEmpty(): string {
    return '00000000-0000-0000-0000-000000000000';
  }
}
 