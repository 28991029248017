<div class="flex align-center">
  <div class="flex align-center justify-center relative">
    <input
      type="checkbox"
      [disabled]="disabled"
      *ngIf="form; else withoutForm"
      class="custom-radio box-shadow cursor-pointer"
      [formControl]="form"
      (change)="onChange(form.value)"
    />
    <ng-template #withoutForm>
      <input
        type="checkbox"
        [disabled]="disabled"
        class="custom-radio box-shadow cursor-pointer"
        [(ngModel)]="withoutFormValue"
        (change)="onChange($event)"
      />
    </ng-template>
    <svg
      *ngIf="form?.value || withoutFormValue"
      class="absolute pointer-events-none"
      width="8"
      height="5"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6222 0.327579C10.6967 0.251614 10.7855 0.191265 10.8836 0.150066C10.9817 0.108867 11.087 0.0876465 11.1933 0.0876465C11.2997 0.0876465 11.405 0.108867 11.503 0.150066C11.6011 0.191265 11.69 0.251614 11.7644 0.327579C12.0764 0.642859 12.0808 1.15232 11.7753 1.47306L5.32466 9.09867C5.25142 9.17909 5.16254 9.24373 5.06346 9.28862C4.96439 9.33352 4.85719 9.35773 4.74843 9.35977C4.63968 9.36181 4.53165 9.34165 4.43095 9.3005C4.33026 9.25936 4.23902 9.1981 4.16281 9.12049L0.237643 5.14295C0.0862619 4.98857 0.00146484 4.78097 0.00146484 4.56476C0.00146484 4.34854 0.0862619 4.14095 0.237643 3.98656C0.312094 3.9106 0.400954 3.85025 0.499017 3.80905C0.597081 3.76785 0.702379 3.74663 0.808745 3.74663C0.915112 3.74663 1.02041 3.76785 1.11847 3.80905C1.21654 3.85025 1.3054 3.9106 1.37985 3.98656L4.70937 7.36081L10.6004 0.35158C10.6072 0.343149 10.6145 0.335134 10.6222 0.327579Z"
        fill="#FFFFFF"
      />
    </svg>
  </div>
  <label *ngIf="placeholder" class="ml-3">{{ placeholder }}</label>
</div>