import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http.service';
import { BaseService } from '@app/services/base.service';
import { HttpResponsePageable } from '@app/services/services.types';
import { AttributeOptions } from './atribute-options-list.service.module';

@Injectable({
  providedIn: 'root'
})

export class AttributeOptionsListService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/attributeoptionslist');    
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<AttributeOptions>>
  }

  override get(queryString: string){
    return super.get(queryString) as Promise<HttpResponsePageable<AttributeOptions>>
  }
}