import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { SupportLaboratoryGetAll, SupportLaboratoryGetById, SupportLaboratoryPost, SupportLaboratoryPostObject, SupportLaboratoryPut, SupportLaboratoryPutObject } from './support-laboratory';
import { HttpResponsePageable } from '../services.types';
import { Guid } from '@app/util/guid';

@Injectable({
  providedIn: 'root'
})
export class SupportLaboratoryService extends BaseService {

constructor(private http: HttpClient, httpService: HttpService) {
  super(httpService, 'v1/lab/parameters/supportlaboratory');
 }

 override post(body: SupportLaboratoryPostObject) {
  return super.post(body) as Promise< HttpResponsePageable<SupportLaboratoryPost>>
}

override put(body: SupportLaboratoryPutObject, id?: Guid | undefined, path?: string | undefined) {
  return super.put(body, id, path) as Promise<HttpResponsePageable<SupportLaboratoryPut>>
}

override getById(id: Guid) {
  return super.getById(id) as Promise<HttpResponsePageable<SupportLaboratoryGetById>>
}


override getAll() {
  return super.getAll() as Promise<HttpResponsePageable<SupportLaboratoryGetAll>>
}

override getByPath(queryString: string, path: string, headers?: any[]) {
  return super.getByPath(queryString, path, headers) as Promise<HttpResponsePageable<SupportLaboratoryGetById>>
}

}
