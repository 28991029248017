import { Guid } from "@app/util/guid";

export interface Actions {
    action: string,
    id: Guid
    object: ResolveListBudgets,
}
      
export interface ResolveListBudgets{
    id: string,
    Ativo:any,
    number: number,
    customerId: string,
    name: string,
    cellPhone: string,
    unityId: string,
    description: string,
    collectionPlaceId: string,
    collectionPlaceDescription: string,
    situation: number,
    createdAt: string,
    updatedAt: string,
    isActive: boolean,
}

export interface Budgets {
  "id": string
  "_hide_customerId": string
  "_hide_requisitionId": string
  "N.º":string
  "Nome": string
  "N.º da Requisição": string
  "Situação": string
  "Criado em": string
  "Alterado em": string  
}

export interface Requests {
  "id": string
  "Convênio": string
  "Solicitante": string
  "Conselho": string
  "Criado em": string
  "Alterado em": string
}

export interface Exams {
  "id": string
  "Código": string
  "Exame": string
  "Material": string
}

export const resolveBudgets = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_customerId',
      retrive: 'customerId',
      method: '',
      after: '',
      before: ''
    },
    {
      label: '_hide_requisitionId',
      retrive: 'requisitionId',
      method: '',
      after: '',
      before: ''
    },    
    {
      label: 'N.º',
      retrive: 'number',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'budgets.name',
      retrive: 'customerName',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'budgets.company',
      retrive: 'companyName',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'budgets.unit',
      retrive: 'unityName',
      method: '',
      after: '',
      before: ''
    },        
    {
      label: 'budgets.createdAt',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'budgets.updatedAt',
      retrive: '',
      method: 'updatedAt',
      after: '',
      before: ''
    },
  ];  

  export const resolvExams: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "budgets.code",
      method: '',
      retrive: "analyteCode",
      after: '',
      before: '',
    },
    {
      label: "budgets.exam",
      method: '',
      retrive: "analyteName",
      after: '',
      before: '',
    },
    {
      label: "budgets.material",
      method: '',
      retrive: "materialDescription",
      after: '',
      before: '',
    }
  ];

