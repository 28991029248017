import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { QueryParams } from '../interfaces/services.types';
import { HttpResponse, HttpResponsePageable } from '../services.types';
import { TypeDocument, TypeDocumentPost, TypeDocumentPut } from './type-document.types';
import { Guid } from '@app/util/guid';

@Injectable({
  providedIn: 'root'
})
export class TypeDocumentService extends BaseService {

  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/financial/typedocument');
  }
  
  override post(body: TypeDocumentPost): Promise<HttpResponse> {
    return super.post(body) as Promise<HttpResponse>
  }

  override getPagination<T>(queryObject?: QueryParams | undefined): Promise<HttpResponsePageable<T>> {
    return super.getPagination<T>(queryObject) as Promise<HttpResponsePageable<T>>
  }

  override put(body: TypeDocumentPut, id?: Guid | undefined, path?: string | undefined): Promise<HttpResponse> {
    return super.put(body, id, path) as Promise<HttpResponse>
  }

  override patchActive(id: string, value: string): Promise<HttpResponse> {
    return super.patchActive(id, value) as Promise<HttpResponse>
  }
  override delete(id?: Guid | undefined): Promise<HttpResponse> {
    return super.delete(id) as Promise<HttpResponse>
  }
}
