import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {

  @HostListener('window:resize')
  onResize(): void {
    this.setWidth();
  }

  @Input() title: string = '';
  @Input() urlButton: string = '';

  @Output() openModal: EventEmitter<any> = new EventEmitter<any>();

  limit: number = 0;
  width: number = 0;

  constructor(
    private _router: Router,
    private _elementRef: ElementRef,
  ) { }

  ngAfterViewInit() {
    this.setWidth();
  }

  private setWidth(): void {
    this.width = window.innerWidth;
    this.limit = this._elementRef.nativeElement.querySelector('#title').offsetWidth + 400;
  }

  openModalEmit() {
    this.openModal.emit(true)
  }

  navigate() {
    this._router.navigateByUrl(this.urlButton);
  }
}