import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { AgreementSupportService } from '@app/services/agreement-support/agreement-support.service';
import { SwAlSetttings } from '@app/util/swal.settings';
import { AgreementObject, Actions } from './agreement-support.model'
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';


@Component({
  selector: 'app-agreement-support',
  templateUrl: './agreement-support.component.html',
  styleUrls: ['./agreement-support.component.scss']
})
export class AgreementSupportComponent implements OnInit {
  total: number = 0;
  page: number = 1;
  index: number = 50;
  getParameters: string = (`?page=${this.page}&index=${this.index}`);

  agreementSupport: Array<AgreementObject> = [];
  resolveAgreementSupport = [
    {
      label: 'id',
      retrive: 'id',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Convênio',
      retrive: 'agreementDescription',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Unidade',
      retrive: 'unityName',
      method: '',
      after: '',
      before: ''
    },
    {
      label: 'Pagamento',
      retrive: '',
      method: 'getPayment',
      after: '',
      before: ''
    },
    {
      label: 'Integração',
      retrive: '',
      method: 'getIntegration',
      after: '',
      before: ''
    },
    {
      label: 'Criado em',
      retrive: '',
      method: 'getFormDate',
      after: '',
      before: ''
    },
    {
      label: 'Alterado em',
      retrive: '',
      method: 'getUpdated',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }
  ];

  typeHeader = [
    {
      value: 0,
      label: 'Padrão logo do laboratorio apoio'
    },
    {
      value: 1,
      label: 'Não imprime logo nenhuma'
    },
    {
      value: 2,
      label: 'Imprime a logo do laboratorio apoiado'
    }
  ];

  typeIntegration = [
    {
      value: 0,
      label: 'JSON'
    },
    {
      value: 1,
      label: 'XML'
    },
    {
      value: 2,
      label: 'Alvaro'
    },
    {
      value: 3,
      label: 'CareSys'
    },
    {
      value: 4,
      label: 'TechSallus'
    },
    {
      value: 5,
      label: 'LifeSysNefrodata'
    },
    {
      value: 6,
      label: 'Syslab WS'
    },
    {
      value: 7,
      label: 'DB Toxicologia'
    }
  ];

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  filterClearForm = {
    name: new UntypedFormControl(null),
    code: new UntypedFormControl(null),
    isOutsourced: new UntypedFormControl(null),
    department: new UntypedFormControl(null)
  }

  filter = {
    Search: new UntypedFormControl(),
    isActive: new UntypedFormControl(false)
  }

  constructor(
    private _agreementSupportService: AgreementSupportService,
    private _localDatePipe: LocalaDatePipe,
    private _router: Router,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe
  ) { }

  async ngOnInit() {
    await this.get();
  }

  newAnalyte() {
    this._router.navigateByUrl('Cadastros/Concent-Apoio/Apoiados/Novo');
  }

  async get(paramPage?: number[]) {
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let filterObject = {
      search: this.filter.Search.value ?? null,
      isActive: this.filter.isActive.value ? this.filter.isActive.value : null,
      page: this.page,
      numberRegistry: this.index
    }

    await this._agreementSupportService.getPagination(filterObject).then(
      (res: any) => {
        if (res.data.length != 0) {
          this.total = res.sumRecords;
          this.resolve(res.data, this.resolveAgreementSupport);
        }
      }
    ).catch(
      (err) => {
        SwAlSetttings.printError(err);
      }
    );
    this.clearForm();
  }

  clearForm() {
    this.filterClearForm.code.setValue(null),
      this.filterClearForm.name.setValue(null),
      this.filterClearForm.isOutsourced.setValue(null),
      this.filterClearForm.department.setValue(null)
  }

  resolve(data: any, columns: any) {
    this.agreementSupport = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.agreementSupport.push(obj);
    });
  }

  getOutSourced(item: AgreementObject) {
    return !item.isOutsourced ? "<svg style=\"max-width: calc(34px * var(--proportional));\" width=\"34\" height=\"21\" viewBox=\"0 0 34 21\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <rect x=\"0.475647\" y=\"0.647827\" width=\"33.3333\" height=\"20\" rx=\"10\" fill=\"#808080\"/> <path d=\"M10.4756 18.6478C14.8939 18.6478 18.4756 15.0661 18.4756 10.6478C18.4756 6.22955 14.8939 2.64783 10.4756 2.64783C6.05737 2.64783 2.47565 6.22955 2.47565 10.6478C2.47565 15.0661 6.05737 18.6478 10.4756 18.6478Z\" fill=\"white\"/> </svg>" : "<svg style='max-width: calc(34px * var(--proportional));height: auto' width=\"34\" height=\"20\" viewBox=\"0 0 34 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"> <rect x=\"0.384644\" width=\"33.3333\" height=\"20\" rx=\"10\" fill=\"#FE6344\"/> <path d=\"M23.3846 18C27.8029 18 31.3846 14.4183 31.3846 10C31.3846 5.58172 27.8029 2 23.3846 2C18.9664 2 15.3846 5.58172 15.3846 10C15.3846 14.4183 18.9664 18 23.3846 18Z\" fill=\"white\"/> </svg>";
  }

  getActive(item: AgreementObject) {
    return this._activePipe.transform(item.isActive)
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case "Editar":
        this._router.navigateByUrl('Cadastros/Concent-Apoio/Apoiados/Novo/' + emit.object.id);
        break;
      case 'PutActive':
        this.putActive(emit.object.id, this._checkboxSvgPipe.transform(emit.object.Ativo));
        break;
    }
  }

  putActive(id: string, value: string) {
    this._agreementSupportService.patchActive(id, value).then((x: any) => {
      SwAlSetttings.Sucesso('Situação alterada!')
      this.get();
    }).catch(err => SwAlSetttings.printError(err))
  }

  getIntegration(item: AgreementObject) {
    return this.typeIntegration[item.typeIntegrationSupport].label;
  }

  getPayment(item: AgreementObject) {
    let type = []
    type[0] = `<span class="span-styles" style="background:#006E9D; color:#FFF;">Padrão de Faturamento</span>`;
    type[1] = `<span class="span-styles" style="background:#07BF56; color:#FFF;">Pagamento Antecipado</span>`;
    return type[item.typeInvoiceSupport]
  }

  getFormDate(item: AgreementObject) {
    return this._localDatePipe.transform(item.createdAt)
  }
  getUpdated(item: AgreementObject) {
    return this._localDatePipe.transform(item.updatedAt);
  }

  resetFilter() {
    this.filter.Search.reset();
    this.filter.isActive.reset();
  }
}
