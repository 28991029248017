<ng-container *ngIf="column.component">
    <ng-container [ngSwitch]="column.component" class="max-w-[30%]">
        <forms-toggle
            *ngSwitchCase="'checkbox'"
            [form]="formAction"
            (changed)="checkbox($event, column.key)"
            class="self-center"
        ></forms-toggle>
        <forms-checkbox
            *ngSwitchCase="'checkboxTwo'"
            [form]="formAction"
            (changed)="checkbox($event)"
            class="self-center"
        ></forms-checkbox>
        <div
            *ngSwitchCase="'svg'"
            mat-raised-button
            [matTooltip]="
                column.componentExtra.tooltipFormatter ?

                column.componentExtra.tooltipFormatter(row)
                  :
                column.componentExtra.tooltip
                    ? column.componentExtra.tooltip[row.id]
                    : null
            "
            matTooltipClass="tooltipTable"
        >
            <ui-svg-selector
                *ngIf="isSvgKeyObject()"
                (click)="svgClicked()"
                [svg]="
                    column.componentExtra.svgkey[row[column.key]]
                        ? column.componentExtra.svgkey[row[column.key]]
                        : column.componentExtra.svgkey.null
                "
                [fill]="column.componentExtra.svgfill[row[column.key]]"
                class="self-center cursor-pointer"
            ></ui-svg-selector>
            <ui-svg-selector
                *ngIf="!isSvgKeyObject()"
                (click)="svgClicked()"
                [svg]="column.componentExtra.svgkey"
                [fill]="column.componentExtra.svgfill"
                [hover]="column.componentExtra.colorHover"
                [tooltip]="column.componentExtra.tooltip"
                class="self-center cursor-pointer"
            ></ui-svg-selector>
        </div>
        <forms-button
            style="
                height: 30px;
                width: 115px;
                display: flex;
                align-items: center;
                justify-content: center;
            "
            *ngSwitchCase="'button'"
            (click)="buttonClicked()"
            [text]="column.componentExtra.buttonText"
            class="btn self-center"
        ></forms-button>
    </ng-container>
</ng-container>
<ng-container *ngIf="column.pipe">
    <ng-container [ngSwitch]="column.pipe.name" class="max-w-[30%]">
        <div *ngSwitchCase="'localeDate'">
            {{ row[column.key] | dynamicPipe : column.pipe }}
        </div>
        <div
            *ngSwitchCase="'span'"
            [ngClass]="column.pipe.click ? 'span' : ''"
            [innerHTML]="row[column.key] | dynamicPipe : column.pipe"
            (click)="column.pipe.click ? spanClicked() : ''"
        ></div>
        <div
            *ngSwitchCase="'innerHtml'"
            style="cursor: pointer"
            [innerHTML]="column.formatter(row) | htmlSanitizer"
            (click)="column.pipe.click ? spanClicked() : ''"
        ></div>
    </ng-container>
</ng-container>
<ng-container *ngIf="!column.component && !column.pipe">
    <div *ngIf="column.formatter; else outFormat">
        {{ column.formatter(row) }}
    </div>
    <ng-template #outFormat>
        <div
            *ngIf="column.redirectTo; else normal"
            class="underline cursor-pointer"
            (click)="redirect()"
        >
            {{ row[column.key] }}
        </div>
        <ng-template #normal>
            {{ row[column.key] }}
        </ng-template>
    </ng-template>
</ng-container>
