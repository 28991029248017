import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appCloseMenu]'
})
export class CloseMenuDirective {

  @Output() clickOutside = new EventEmitter<MouseEvent>();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {    
    const clickedInside = this.elementRef.nativeElement.contains(event.target as Node);            
    //@ts-ignore
    if (!clickedInside && event.target.outerHTML.search('d="M0 0H20V2.5H0V0ZM0 6.25H20V8.75H0V6.25ZM0 12.5H20V15H0V12.5Z" fill="white"></path>') == -1 && event.target.outerHTML.search('d="M0 0H20V2.5H0V0ZM0 6.25H20V8.75H0V6.25ZM0 12.5H20V15H0V12.5Z" fill="white"></path></svg></a>') == -1) {      
      this.clickOutside.emit(event);
    }
  }
}
