<div class="contentPage">
  <div class="flex flex-between align-center gap-2 headerResponsive">
    <div class="flex gap-2 align-center titleResponsive">
      <h1>Relatório de Títulos</h1>
      <app-button (click)="generatePdf()" [placeholder]="'Gerar Relatório'" [matIcon]="'insert_drive_file'" [class]="'flex gap-2 align-center'"></app-button>
    </div>
    <app-filter class="filterResponsive" [cleanButton]="true" (beforeClose)="getData()" (clearFilters)="clearForms()">
      <div class="query">
        <input-prop #autoFocus type="text" placeholder="Número de Documento" [form]="filter.document"></input-prop>
        <input-prop type="text" placeholder="Emitente" [form]="filter.issuer"></input-prop>
        <input-search [placeholder]="'Situação'" [form]="filter.situation" [options]="titleSituationOptions"></input-search>
        <div class="flex align-center gap-2">
          <input-prop type="date" placeholder="Período de" [form]="filter.dateStart"></input-prop>
          <input-prop type="date" placeholder="até" [form]="filter.dateFinish"></input-prop>
        </div>        
        <select-prop [placeholder]="'Situação'" [form]="filter.actives" [options]="isActiveOptions"></select-prop>
      </div>
      <app-button (click)="getData()" class="queryBtn" [placeholder]="'Pesquisar'" [matIcon]="'search'" [class]="'flex gap-2 align-center'"></app-button>
    </app-filter>
  </div>
  <div class="mt-32">
    <mat-tab-group [(selectedIndex)]="tabIndex" (selectedIndexChange)="tabChange()">
      <mat-tab *ngFor="let title of titles; let i = index">
        <ng-template mat-tab-label>
          <div class="flex align-center gap-2">
            <h2>{{moduleTabs[i]}}</h2>
          </div>
        </ng-template>  
        <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [indexNumber]="index" [pageNumber]="page" [Itens]="resolvedAnalytes" (changePage)="getData($event)"></app-table>        
      </mat-tab>
    </mat-tab-group>
  </div>
</div>