<div class="contentPage w-100">
  <div class="flex flex-between align-center flex-direction-column-mobile">
      <h1>Concent Apoio</h1>
      <app-filter [advanced]="true">
        <div class="query w-100">
          <input-prop class="input" [placeholder]="'Data Inicial'" type="datetime-local" [form]="form.initialDate"></input-prop>
          <input-prop [placeholder]="'Data Final'" type="datetime-local" [form]="form.finalDate"></input-prop>
          <input-prop (keydown.enter)="getResults()" [placeholder]="'Nº. Requisição'" [form]="form.requisitionNumber"></input-prop>
        </div>
        <div class="advancedQuery">
          <input-prop (keydown.enter)="getResults()" [placeholder]="'Nome do Cliente'" [form]="form.customerName"></input-prop>
          <input-prop (keydown.enter)="getResults()" [placeholder]="'Data de Nascimento'" [form]="form.customerBirthDate"></input-prop>
          <input-prop (keydown.enter)="getResults()" [placeholder]="'CPF do Cliente'" [form]="form.customerCpf"></input-prop>
        </div>
        <div class="queryBtn">
          <button (click)="getResults()">Filtrar</button>
        </div>
      </app-filter>
  </div>
  <div class="mt-32">
    <app-table [Pagination]="true" [backPagination]="true" [sumRecords]="total" [pageNumber]="page" [indexNumber]="index" [ClickInItem]="ActionsExtra" [Itens]="registerResults" (parentFun)="Actions($event)" (changePage)="getResults($event)"></app-table>
  </div>
</div>