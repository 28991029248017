import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'checkbox-prop',
  templateUrl: './checkbox-prop.component.html',
  styleUrls: ['./checkbox-prop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxPropComponent implements OnInit {
  @Input() formTrue: boolean = false;
  //@ts-ignore
  @Input() form: UntypedFormControl = new UntypedFormControl(null);
  @Input() placeholder: string = '';
  @Input() radio: boolean = false;
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() mask: any = '';
  @Input() className: string = '';
  @Input() requiredInput: string = '';
  @Input() tabindexcomponent: string = '';
  @Input() pattern: string = '';
  @Input() dark: boolean = false;
  @Input() gap = 0;

  @Input() disabledSd = false;

  timeout: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if (this.formTrue) {
      this.form.setValue(this.formTrue);
    }
  }

  onChange() {
    this.timeout = true
    setTimeout(() => {
      this.timeout = false
    }, 1000);
  }

}
