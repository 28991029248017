import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpService } from '../http.service';

@Injectable({
    providedIn: 'root'
})
export class FileDocumentRecordService extends BaseService {

    constructor(httpService: HttpService) {
        super(httpService, 'v1/accesscontrol/filedocument');
    }

}
