import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { BaseService } from '../base.service';
import { HttpResponsePageable } from '../services.types';
import { Guid } from 'src/app/util/guid';
import { GetAllTypePayments } from './payments.types';
import { QueryParams } from '../interfaces/services.types';

@Injectable({ 
    providedIn: 'root' 
})
export class TypePaymentsService extends BaseService {
  constructor(private http: HttpClient, httpService: HttpService) {
    super(httpService, 'v1/lab/parameters/typepayment');
  }

  override getAll() {
    return super.getAll() as Promise<HttpResponsePageable<GetAllTypePayments>>
  }

  override getPagination<T>(queryObject?: QueryParams | undefined): Promise<HttpResponsePageable<T>> {
    return super.getPagination(queryObject) as Promise<HttpResponsePageable<T>>
  }
}