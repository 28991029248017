<a
    *ngIf="variant === 'clean'"
    class="pointer mt-10"
    [className]="classExtra"
    [disabled]="false"
>
    {{ text }}
</a>
<button
    *ngIf="variant !== 'clean'"
    class="pointer mt-10"
    [className]="classExtra"
    [disabled]="isDisabled"
>
    {{ text }}
</button>
