import { UnitMeasureService } from '@app/services/stock/unit-measure.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GetActiveFilterPipe } from '@app/pipe/active.pipe';
import { LocalaDatePipe } from '@app/pipe/localeDate.pipe';
import { Guid } from '@app/util/guid';
import { GetUnitMeasure, ObjectUnitMeasure, Actions } from './unit-measure.model';
import { CheckboxSvgPipe } from '@app/pipe/checkbox-Svg.pipe';
import { DialogComponent } from '@app/components/form/dialog/dialog.component';
import { ToastService } from '@app/services/translate/toast.service';


@Component({
  selector: 'app-unit-measure',
  templateUrl: './unit-measure.component.html',
  styleUrls: ['./unit-measure.component.scss']
})
export class UnitMeasureComponent implements OnInit {

  @ViewChild(DialogComponent) dialog!: DialogComponent

  editId: string = '';

  total: number = 0;
  page: number = 1;
  index: number = 50;

  itens: Array<object> = []

  filter = {
    Search: new UntypedFormControl(),
    isActive: new UntypedFormControl(false)
  }

  form = {
    name: new UntypedFormControl(''),
    active: new UntypedFormControl(false)
  }

  resolveLaboratory: Array<Object> = [
    {
      label: "id",
      method: '',
      retrive: "id",
      after: '',
      before: '',
    },
    {
      label: "unitMeasure.name",
      method: '',
      retrive: "name",
      after: '',
      before: '',
    },
    {
      label: 'unitMeasure.createdAt',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'unitMeasure.updatedAt',
      retrive: '',
      method: 'getDate',
      after: '',
      before: ''
    },
    {
      label: 'Ativo',
      retrive: '',
      method: 'getActive',
      after: '',
      before: ''
    }

  ]

  ActionsExtra = {
    "Ativo": "PutActive"
  }

  constructor(
    private _unitMeasureService: UnitMeasureService,
    private _dialog: MatDialog,
    private _dataPipe: LocalaDatePipe,
    private _activePipe: GetActiveFilterPipe,
    private _checkboxSvgPipe: CheckboxSvgPipe,
    private _toastService: ToastService
  ) { }


  ngOnInit() {
    this.get()
  }

  async get(paramPage?: number[]) {
    this.itens = [];
    if (paramPage) {
      this.page = paramPage[0];
      this.index = paramPage[1];
    }

    let search: string = this.filter.Search.value ? this.filter.Search.value : null;

    let isActive = this.filter.isActive.value ? this.filter.isActive.value: null;

    await this._unitMeasureService.getPagination({page: this.page, index: this.index, search, isActive}).then(
      (res:any) => {
        this.total = res.sumRecords;
        if(res.data.length > 0) {
          this.resolve(res.data, this.resolveLaboratory);
        }
      }
    ).catch(
      (err: any) => {
        this._toastService.fireError(err);
      }
    );
  }

  resolve(data: any, columns: any) {
    this.itens = [];
    data.forEach((x: any) => {
      let obj: any = {};
      columns.forEach((y: any) => {
        if (!y.retrive) {
          //@ts-ignore
          obj[y.label] = y.before + this[y.method](x) + y.after;
        } else {
          obj[y.label] = y.before + x[y.retrive.toString()] + y.after;
        }
      });
      this.itens.push(obj);
    });
  }

  getActive(item: GetUnitMeasure) {
    return this._activePipe.transform(item.isActive)
  }

  getDate(item: GetUnitMeasure) {
    return this._dataPipe.transform(item.updatedAt);
  }

  post() {
    let obj = {
      name: this.form.name.value
    }

    this._unitMeasureService.post(obj).then((res:any) => {
      this._toastService.fireSuccess('unitMeasure.msgPost')
      this.get()
      this.dialog.closeDialog()
    })
  }

  put() {
    let obj = {
      name: this.form.name.value,
      isActive: this.form.active.value
    }

    this._unitMeasureService.put(obj, new Guid(this.editId)).then((res:any) => {
      this._toastService.fireSuccess('unitMeasure.msgPut')
      this.get()
      this.dialog.closeDialog()
    })
  }

  delete(idToDelete: string) {
    this._toastService.fireConfirmation('unitMeasure.msgDelete').then(
      async (r) => {
        if(r.isConfirmed) {
          this._unitMeasureService.delete(new Guid(idToDelete)).then((res:any) => {
          this._toastService.fireSuccess('unitMeasure.msgDeleteSuccess')
          this.get()
          this.dialog.closeDialog()
        }
      )
    }
    }).catch(err => this._toastService.fireError(err))
  }

  setValueOnForms(itens:ObjectUnitMeasure) {
    this.form.name.setValue(itens['unitMeasure.name'])
  }

  resetForms() {
    this.form.name.setValue('')
    this.form.active.setValue(false)
  }

  actions(emit: Actions) {
    switch (emit.action) {
      case 'Editar':
        this.editId = emit.object.id ?? ''
        this.dialog.openDialogModal(true, emit);
        this.setValueOnForms(emit.object)
        break;
      case 'Excluir':
        this.delete(emit.object.id)
        break;
      case 'PutActive':
        this.putActive(emit.object.id.toString(), this._checkboxSvgPipe.transform(emit.object.Ativo));
    }
  }

  putActive(id: string, value: string) {
    this._unitMeasureService.patchActive(id, value).then((x:any) => {
      this._toastService.fireSuccess('global.msgSituationSuccess')
      this.get();
    }).catch(err => this._toastService.fireError(err))
  }

  resetFilter(){
    this.filter.Search.reset();
    this.filter.isActive.reset();
  }

  afterClose() {
    this.resetForms()
    this.editId = ''
  }

}
